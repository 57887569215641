import {gql} from "graphql-request"

export const GET_MATERIAL_TYPES = gql`
  query GetAllMaterialTypes {
    materialTypes {
      id
      name
      code
    }
  }
`

export const GET_PROVENANCE_CATEGORIES = gql`
  query GetAllProvenanceCategories {
    provenanceCategories {
      codeId
      name
      ordering
    }
  }
`

export const CREATE_ACCESSION = gql`
  mutation CreateAccession($accession: CreateAccession!) {
    createAccession(accession: $accession) {
      id
      accessionNumber
    }
  }
`

const COUNTRY_FIELDS = gql`
  fragment CountryFields on Country {
    codeId
    name
  }
`

export const GET_COUNTRIES = gql`
  ${COUNTRY_FIELDS}
  query GetCountries {
    countries {
      ...CountryFields
    }
  }
`

export const GET_MANUAL_ACCESSION_NUMBER_ERRORS = gql`
  query GetManualAccessionNumberErrors(
    $organisationSubdomain: String!
    $collectionSiteSlug: String!
    $accessionNumber: String!
  ) {
    org: organisationBySubdomain(subdomain: $organisationSubdomain) {
      site: collectionSiteBySlug(slug: $collectionSiteSlug) {
        errors: manualAccessionNumberErrors(accessionNumber: $accessionNumber)
      }
    }
  }
`
