import type {AxiosResponse, AxiosProgressEvent} from "axios"
import config from "../../../../../config"
import type {
  MaterialImageFieldsFragment,
  MaterialImageUpload,
  UploadMaterialImageMutation,
} from "../../../../../../generated/graphql"
import type {GraphQLResponse} from "../../../../../utils/gql-client"
import {client, NO_DATA_RETURNED} from "../../../../../utils/gql-client"
import withCommonHeaders from "../../../../../utils/with-common-headers"
import {UPLOAD_MATERIAL_IMAGE} from "../../graphql"

function extractErrorMessage<T>({errors, status}: GraphQLResponse<T>): string {
  if (errors && errors.length > 0 && errors[0] !== undefined) {
    return errors[0].message
  }
  return `GraphQLError (code: ${status})`
}

export async function mutateUploadMaterialImage({
  accessToken,
  plantMaterialId,
  imageId,
  file,
  onProgress,
  onComplete,
  onError,
}: {
  accessToken: string
  plantMaterialId: string
  imageId: string
  file: Omit<MaterialImageUpload, "file"> & {file: File}
  onProgress?: (progressEvent: AxiosProgressEvent, imageId: string) => void
  onComplete?: (result: MaterialImageFieldsFragment, imageId: string) => void
  onError?: (error: string, imageId: string) => void
}): Promise<void> {
  const {file: image, ...fileMetadata} = file
  const operations = JSON.stringify({
    operationName: "UploadMaterialImage",
    query: UPLOAD_MATERIAL_IMAGE,
    variables: {plantMaterialId, image: {...fileMetadata, file: null}},
  })

  const formData = new FormData()
  formData.append("operations", operations)
  formData.append("map", `{"${0}": ["variables.image.file"]}`)
  formData.append("0", image)

  try {
    const {
      data: result,
    }: AxiosResponse<GraphQLResponse<UploadMaterialImageMutation>> =
      await client.post(config.publicApiUrl, formData, {
        ...withCommonHeaders({
          Authorization: `Bearer ${accessToken}`,
          "Apollo-Require-Preflight": "true", // See: https://www.apollographql.com/docs/apollo-server/security/cors/#preventing-cross-site-request-forgery-csrf
        }),
        onUploadProgress: (e: AxiosProgressEvent) => {
          if (onProgress != null) {
            onProgress(e, imageId)
          }
        },
      })

    const {errors, data} = result

    if (onError != null && errors !== undefined) {
      onError(extractErrorMessage<UploadMaterialImageMutation>(result), imageId)
    }
    if (onError != null && data === undefined) {
      onError(NO_DATA_RETURNED, imageId)
    }

    if (
      onComplete != null &&
      data != null &&
      data.uploadMaterialImage?.__typename === "MaterialImage"
    ) {
      onComplete(data.uploadMaterialImage, imageId)
    }
  } catch (error: unknown) {
    if (onError != null) {
      onError(
        `Upload request failed: ${error instanceof Error ? error.message : ""}`,
        imageId,
      )
    }
  }
}
