import {Fragment} from "react"
import {FilterChip} from "../filter-chip-group"
import type {OpenFilterArgs} from "../plant-materials/filters"
import {extractFamilyFilterData} from "./extract-family-filter-data"
import type {LocalFamilyFilter} from "./schema"

export const FamilyChipGroup = <T extends string>({
  id,
  filter,
  hidden,
  ...props
}: {
  id: string
  handleOpenFilter: (args: OpenFilterArgs<T>) => void
  handleDelete: (id: string) => void
  filter: LocalFamilyFilter | undefined
  filterType: T
  hidden?: boolean
}) =>
  filter == null || hidden === true ? (
    <Fragment />
  ) : (
    <>
      {filter.is != null && filter.is.length > 0 && (
        <FilterChip
          filterLabel="Family"
          filterId={id}
          filterData={extractFamilyFilterData(filter, "is")}
          operation="is"
          {...props}
        />
      )}
      {filter.isNot != null && filter.isNot.length > 0 && (
        <FilterChip
          filterLabel="Family"
          filterId={id}
          filterData={extractFamilyFilterData(filter, "isNot")}
          operation="is not"
          {...props}
        />
      )}
      {filter.hasNoValue === true && (
        <FilterChip
          filterLabel="Family"
          filterId={id}
          filterData={extractFamilyFilterData(filter, "hasNoValue")}
          operation="has no value"
          {...props}
        />
      )}
    </>
  )
