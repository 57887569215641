import type {IconProps} from "./types"

export const SlChevronUpIcon = ({
  style,
  width,
  height,
  color,
  className,
}: IconProps): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? "24"}
      height={height ?? "24"}
      viewBox="0 0 24 24"
      fill="none"
      className={className}
      style={style}
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M8.70557 14.3998L12.432 9.60857C12.4881 9.53646 12.5599 9.4781 12.642 9.43796C12.724 9.39782 12.8142 9.37695 12.9056 9.37695C12.9969 9.37695 13.0871 9.39782 13.1692 9.43796C13.2512 9.4781 13.3231 9.53646 13.3792 9.60857L17.1056 14.3998"
        stroke={color ?? "#424242"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
