import {Button} from "@hortis/ui/button"
import {Plus} from "@hortis/ui/icons"
import {SheetBody, SheetHeader, SheetTitle} from "@hortis/ui/sheet"
import {useNavigate} from "@tanstack/react-router"
import {useCallback} from "react"
import {useCollectionSiteHrefs} from "../../../../../../utils/hooks/hrefs"
import {sortMaterials} from "../../../../../../utils/material"
import {createMaterialNumber} from "../../../../../collection/components/plant-materials/material-number"
import {OverviewCard} from "../../../../components/overview-card"
import {countMaterialDetailsFields} from "../../../../material/components/edit-drawer/material-record-overview"
import type {BaseEditStageProps} from "../types"
import {AccessionEditStageCallback} from "../types"

const WrappedOverviewCard = ({
  accessionNumber,
  materialQualifier,
  subtitle,
}: {
  accessionNumber: string
  materialQualifier: string
  subtitle: string
}) => {
  const navigate = useNavigate({
    from: "/sites/$siteSlug/accessions/$accessionNumber",
  })
  const plantMaterials = useCollectionSiteHrefs()?.plantMaterials

  const handleClick = useCallback(() => {
    if (plantMaterials != null) {
      void navigate({
        to: "/sites/$siteSlug/materials/$materialNumber",
        params: {
          materialNumber: createMaterialNumber(
            accessionNumber,
            materialQualifier,
          ),
        },
        search: {tab: "material"},
      })
    }
  }, [navigate, plantMaterials, accessionNumber, materialQualifier])

  return (
    <OverviewCard
      title={createMaterialNumber(accessionNumber, materialQualifier, "/")}
      subtitle={subtitle}
      onClick={handleClick}
    />
  )
}

export const PlantMaterialOverview = ({
  accession,
  transitionStage,
}: BaseEditStageProps) => (
  <>
    <SheetHeader>
      <SheetTitle>Material</SheetTitle>
    </SheetHeader>
    <SheetBody className="gap-4">
      {sortMaterials(accession.plantMaterial)?.map((material) => {
        const materialDetailsFields = countMaterialDetailsFields(material)
        return (
          <WrappedOverviewCard
            key={material.id}
            accessionNumber={accession.accessionNumber}
            materialQualifier={material.qualifier}
            subtitle={`${materialDetailsFields.count} / ${materialDetailsFields.total} fields complete`}
          />
        )
      })}
      <Button
        testId="new-material-button"
        onClick={AccessionEditStageCallback.useAddPlantMaterial(
          transitionStage,
        )}
        className="self-start"
        startIcon={Plus}
        size="sm"
      >
        New material
      </Button>
    </SheetBody>
  </>
)
