import type {TaxaFilter, TaxaSearchTerm} from "generated/graphql"
import type {ReactElement} from "react"
import {useCallback, useMemo, useRef, useState} from "react"
import {IconButton} from "@hortis/ui/button"
import {DotsHorizontal, Plus} from "@hortis/ui/icons"
import {DownloadCsvMenuItem} from "src/features/collection/components/download-csv-menu-item"
import {useAccessRole} from "src/features/permissions/use-access-role"
import {Menu} from "../../../../components/menu"

const anchorOrigin = {
  vertical: "bottom",
  horizontal: "right",
} as const

const transformOrigin = {
  vertical: "top",
  horizontal: "right",
} as const

interface TaxaMoreButtonProps {
  searchTerm: TaxaSearchTerm | undefined
  filter: TaxaFilter | undefined
  sendOrg?: boolean
}

export const TaxaMoreButton = ({
  searchTerm,
  filter,
  sendOrg,
}: TaxaMoreButtonProps): ReactElement => {
  const [open, setOpen] = useState(false)
  const {canEdit} = useAccessRole()
  const menuOptions = useMemo(
    () => [
      canEdit
        ? {
            label: (
              <div className="flex items-center gap-2">
                <Plus size="16px" />
                <p className="text-sm">New taxon</p>
              </div>
            ),
            value: "new-taxon",
            href: `/taxonomy/new`,
          }
        : null,
      {
        label: (
          <DownloadCsvMenuItem
            body={{searchTerm, filter}}
            csvName="taxa"
            path={`/download/${sendOrg === true ? "" : "site-"}taxa`}
            sendOrg={sendOrg}
          />
        ),
        value: "download",
      },
    ],
    [canEdit, filter, searchTerm, sendOrg],
  )
  const buttonRef = useRef(null)

  const closeMenu = useCallback(() => {
    setOpen(false)
  }, [])
  const toggleMenu = useCallback(() => {
    setOpen((x) => !x)
  }, [])

  return (
    <div ref={buttonRef} data-cy="taxa-more-button">
      <IconButton
        ref={buttonRef}
        ariaLabel="More"
        onClick={toggleMenu}
        size="sm"
        icon={DotsHorizontal}
      />
      <Menu
        open={open}
        onClose={closeMenu}
        anchorEl={buttonRef}
        options={menuOptions}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      />
    </div>
  )
}
