import type {FollowUp} from "generated/graphql"
import {Period} from "generated/graphql"

export const followUpOptions: Array<{value: FollowUp; label: string}> = [
  {label: "Daily", value: {count: 1, period: Period.Days}},
  {label: "Weekly", value: {count: 1, period: Period.Weeks}},
  {label: "Monthly", value: {count: 1, period: Period.Months}},
  {label: "3 months", value: {count: 3, period: Period.Months}},
  {label: "6 months", value: {count: 6, period: Period.Months}},
  {label: "Yearly", value: {count: 1, period: Period.Years}},
  {label: "3 years", value: {count: 3, period: Period.Years}},
  {label: "5 years", value: {count: 5, period: Period.Years}},
]
