import {flow} from "fp-ts/function"
import * as O from "fp-ts/Option"
import {useParams} from "@tanstack/react-router"

export type UseCollectionSiteSlugResult = ReturnType<
  typeof useCollectionSiteSlug
>

/**
 * Get collection site slug from URL.
 * @remarks
 * Assumed to be /[collection-site]/accessions/create
 * but this is dependent on the next route structure.
 * Doesn't validate the collection site slug in any way:
 * this should be subsequently validated.
 */
export const useCollectionSiteSlug = flow(
  () => {
    const params = useParams({strict: false})
    return O.fromNullable("siteSlug" in params ? params["siteSlug"] : undefined)
  },
  O.map((option) => O.some(option)),
)
