import {useAuth0} from "@auth0/auth0-react"
import type {GetPublicOrganisationQuery} from "generated/graphql"
import {useCallback, useEffect, useState} from "react"
import {usePersistedDomain} from "src/features/organisation/persist-user-domain"
import {useSearch} from "@tanstack/react-router"
import type {RedirectType} from "../routes"

export const useRedirectToAuth0 = ({
  subdomain,
  org,
  searchParamError,
  fetching,
}: {
  subdomain: string | undefined
  org: GetPublicOrganisationQuery | undefined
  searchParamError?: string
  fetching: boolean
}) => {
  const [redirectError, setRedirectError] = useState<string | null>(null)
  const {logout, loginWithRedirect, error: authError} = useAuth0()
  const [redirectLoading, setRedirectLoading] = useState<RedirectType | null>(
    null,
  )
  const persistedDomain = usePersistedDomain()
  const {redirect} = useSearch({from: "/auth/login"})

  const redirectToAuth0 = useCallback(
    async (type: RedirectType, domain?: string): Promise<void> => {
      if (fetching) {
        return
      }

      // Perform a logout when the last org logged into does not match the current
      if (
        persistedDomain != null &&
        domain == null &&
        persistedDomain.domain !== subdomain
      ) {
        await logout({
          logoutParams: {
            returnTo: `https://${
              location.hostname
            }/auth/login?redirect=${encodeURIComponent(type)}`,
          },
        })
        return
      }

      if (authError != null && searchParamError != null) {
        await logout({
          logoutParams: {
            returnTo: `https://${
              location.hostname
            }/auth/login?redirect=${encodeURIComponent(type)}`,
          },
        })
        return
      }

      setRedirectLoading(type)
      if (org == null || org.publicOrganisation == null) {
        setRedirectError("Unable to find organisation")
        setRedirectLoading(null)
      } else {
        await loginWithRedirect({
          authorizationParams: {
            screen_hint: type === "signup" ? "signup" : undefined,
          },
        })
      }
    },
    [
      loginWithRedirect,
      org,
      logout,
      persistedDomain,
      subdomain,
      authError,
      searchParamError,
      fetching,
    ],
  )

  /*
   * Checks if a valid redirect URL param exists, if so begins Auth0 login
   */
  useEffect(() => {
    if (persistedDomain != null && redirect != null && !fetching) {
      void redirectToAuth0(redirect, persistedDomain.domain)
    }
  }, [persistedDomain, redirect, redirectToAuth0, fetching])

  return {redirectLoading, redirectToAuth0, redirectError}
}
