import withAuthenticationRequired from "src/utils/with-authentication-required"
import {TaxaList} from "./taxa-list"

const trashList = (
  <TaxaList isTrash={true} persistenceKey="taxa-trash-page-filters" />
)

export const Component = withAuthenticationRequired(() => trashList, {
  onRedirecting: function OnRedirect() {
    return trashList
  },
})
