import {zodResolver} from "@hookform/resolvers/zod"
import {Button} from "@hortis/ui/button"
import {
  Sheet,
  SheetBody,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from "@hortis/ui/sheet"
import {
  MaterialQualifierFormat,
  useCreateCollectionSiteMutation,
} from "generated/graphql"
import type {SyntheticEvent} from "react"
import {useCallback, useEffect} from "react"
import {FormProvider, useForm} from "react-hook-form"
import {FormSelect} from "src/components/select"
import {useSnackbarStore} from "src/components/snackbar-controller/snackbar-store"
import {FormTextField} from "src/components/text-field"
import {onFailure} from "src/notification-snack-utils"
import type {Nullable} from "src/types/nullable"
import {mapSlugToHrefs} from "src/utils/hooks/hrefs"
import {match} from "ts-pattern"
import {SiteMapPositionField} from "./map-position-field"
import {CollectionSiteSchema} from "./schema"

export type InitialValues = Nullable<CollectionSiteSchema>

const createDefaultValues = (): InitialValues => ({
  name: null,
  urlSlug: null,
  materialQualifierFormat: MaterialQualifierFormat.BijectiveBase_26,
  centerPoint: null,
})

const resolver = zodResolver(CollectionSiteSchema)

type NewSiteDrawerProps = {
  open: boolean
  onOpenChange: (open: boolean) => void
}

export const NewSiteDialog = ({open, onOpenChange}: NewSiteDrawerProps) => {
  const {setSnack} = useSnackbarStore()
  const formMethods = useForm<InitialValues>({
    defaultValues: createDefaultValues(),
    criteriaMode: "all",
    resolver,
  })
  const [, createCollectionSite] = useCreateCollectionSiteMutation()

  useEffect(() => {
    formMethods.reset()
  }, [open, formMethods])

  const handleSubmit = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault()
      return formMethods.handleSubmit(async (_data) => {
        try {
          const {centerPoint, ...input} = _data as CollectionSiteSchema
          const res = await createCollectionSite({
            input: {
              ...input,
              mapCenterLatitude: centerPoint.latitude,
              mapCenterLongitude: centerPoint.longitude,
              mapZoomLevel: centerPoint.zoom,
            },
          })
          if (res.data?.createCollectionSite?.success === true) {
            const hrefs = mapSlugToHrefs(input.urlSlug)
            setSnack({
              type: "action",
              data: {
                text: "Site created",
                body: input.name,
                action: {
                  text: "View site",
                  href: `/${hrefs.dashboard}`,
                },
              },
            })
            onOpenChange(false)
            return
          }
          const errors = res.data?.createCollectionSite?.errors
          if (errors != null && errors.length > 0) {
            errors.forEach((error) => {
              match(error.__typename)
                .with("DuplicateNameError", () => {
                  formMethods.setError("name", {
                    message: error.message,
                  })
                })
                .with("DuplicateUrlSlugError", () => {
                  formMethods.setError("urlSlug", {
                    message: error.message,
                  })
                })
                .with("GenericError", () => {
                  throw new Error("Failed to create site")
                })
                .exhaustive()
            })
            return
          }
          throw new Error("Failed to create site")
        } catch {
          onFailure(setSnack)(new Error(`Failed to create collection site`))
        }
      })()
    },
    [formMethods, onOpenChange, createCollectionSite, setSnack],
  )

  // Require URL slug to be lowercase and hyphenated
  const name = formMethods.watch(`name`)
  useEffect(() => {
    if (name != null) {
      formMethods.setValue(
        "urlSlug",
        name
          .toLowerCase()
          .replaceAll(" ", "-")
          .replaceAll(/[^a-z-]/g, ""),
      )
    }
  }, [name, formMethods])
  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <FormProvider {...formMethods}>
        <SheetContent asChild>
          <form onSubmit={handleSubmit}>
            <SheetHeader>
              <SheetTitle>New collection site</SheetTitle>
              <SheetDescription>
                Create a new site to manage accessions and plant materials at
                another location.
              </SheetDescription>
            </SheetHeader>
            <SheetBody className="gap-6">
              <FormTextField
                fullWidth
                required
                label="Name"
                testId="name"
                name={`name`}
              />
              <FormTextField
                fullWidth
                required
                label="URL name"
                testId="url-name"
                name={`urlSlug`}
                helperText="Can only contain letters and hyphens"
              />
              <FormSelect
                name="materialQualifierFormat"
                options={[
                  {
                    value: MaterialQualifierFormat.BijectiveBase_26,
                    label: "Alphabetic (ie. A, B...)",
                  },
                  {
                    value: MaterialQualifierFormat.Numeric_99,
                    label: "Numeric (ie. 01, 02...)",
                    testId: "numeric-material-qualifier-format-option",
                  },
                ]}
                label="Material qualifier format"
                testId="material-qualifier-format"
                required
                fullWidth
              />
              <SiteMapPositionField />
            </SheetBody>
            <SheetFooter>
              <Button
                onClick={() => {
                  onOpenChange(false)
                }}
                testId="cancel-new-site-button"
              >
                Discard
              </Button>
              <Button
                variant="primary"
                type="submit"
                loading={formMethods.formState.isSubmitting}
                testId="create-site-button"
              >
                Create site
              </Button>
            </SheetFooter>
          </form>
        </SheetContent>
      </FormProvider>
    </Sheet>
  )
}
