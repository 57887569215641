import {Fragment} from "react"
import {redListStatusLabelMap} from "@hortis/ui/iucn-red-list-tag"
import {FilterChip} from "../filter-chip-group"
import type {OpenFilterArgs} from "../plant-materials/filters"
import type {LocalRedListStatusFilter} from "./modal"

export const RedListStatusChipGroup = <T extends string>({
  id,
  filter,
  hidden,
  ...props
}: {
  id: string
  handleOpenFilter: (args: OpenFilterArgs<T>) => void
  handleDelete: (id: string) => void
  filter: LocalRedListStatusFilter | undefined
  filterType: T
  hidden?: boolean
}) =>
  filter == null || hidden === true ? (
    <Fragment />
  ) : (
    <>
      {filter.is != null && filter.is.length > 0 && (
        <FilterChip
          filterLabel="IUCN Red List"
          filterId={id}
          filterData={filter.is.map((status) => redListStatusLabelMap[status])}
          operation="is"
          testIdPrefix="red-list-status-is"
          {...props}
        />
      )}
      {filter.isNot != null && filter.isNot.length > 0 && (
        <FilterChip
          filterLabel="IUCN Red List"
          filterId={id}
          filterData={filter.isNot.map(
            (status) => redListStatusLabelMap[status],
          )}
          operation="is not"
          testIdPrefix="red-list-status-is-not"
          {...props}
        />
      )}
    </>
  )
