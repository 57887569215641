import type {DialogActionsProps as CoreDialogActionsProps} from "@mui/material/DialogActions"
import CoreDialogActions from "@mui/material/DialogActions"
import {styled} from "@mui/material/styles"
import {applyMui} from "../../utils/apply-mui"
import {withTestId} from "../../utils/with-test-id"

const StyledDialogActions = styled(CoreDialogActions)(({theme}) => ({
  padding: 0,
  paddingTop: theme.spacing(5),
}))

export const DialogActions = applyMui(
  withTestId,
  StyledDialogActions as (props: CoreDialogActionsProps) => JSX.Element,
)
export type DialogActionsProps = CoreDialogActionsProps
