import {memo} from "react"
import {Box} from "../../../../components/box"
import {
  materialsTableResponsiveMixin,
  tableBodyMixin,
  tableContainerMixin,
  tableMixin,
} from "../table-styling"
import type {Column} from "./skeleton-body"
import {SkeletonBody} from "./skeleton-body"
import type {HeaderColumnConfig} from "./skeleton-header"
import {SkeletonHeader} from "./skeleton-header"

interface SkeletonTableProps {
  rows: number
  columns: number
  withCheckbox?: boolean
  bodyColumnConfig?: Array<Column>
  headerColumnConfig?: Record<number, HeaderColumnConfig>
}

export const SkeletonTable = memo(function SkeletonTable({
  rows,
  columns,
  bodyColumnConfig,
  headerColumnConfig,
  withCheckbox = true,
}: SkeletonTableProps): JSX.Element {
  return (
    <Box sx={tableContainerMixin}>
      <Box role="table" sx={[tableMixin, materialsTableResponsiveMixin]}>
        <div role="rowgroup" style={{display: "contents"}}>
          <SkeletonHeader
            columns={columns}
            withCheckbox={withCheckbox}
            columnConfig={headerColumnConfig}
          />
        </div>
        <Box role="rowgroup" sx={tableBodyMixin}>
          <SkeletonBody
            columns={columns}
            rows={rows}
            columnConfig={bodyColumnConfig}
            withCheckbox={withCheckbox}
          />
        </Box>
      </Box>
    </Box>
  )
})
