import type {PropsWithChildren, ReactElement} from "react"
import {memo} from "react"
import {colors} from "src/colors"

interface IconCircularContainerProps {
  backgroundColor?: string
  color?: string
}

export const IconCircularContainer = memo<
  PropsWithChildren<IconCircularContainerProps>
>(function IconCircularContainer({
  children,
  backgroundColor,
  color,
}): ReactElement {
  return (
    <div
      className="flex h-6 w-6 cursor-default items-center justify-center rounded-[100%]"
      style={{
        color: color === undefined ? colors.primary[600] : color,
        backgroundColor:
          backgroundColor === undefined ? colors.grey[100] : backgroundColor,
      }}
    >
      {children}
    </div>
  )
})
