import type {IconProps} from "./types"

export const SlSeedIcon = ({
  style,
  width,
  height,
  color,
  className,
}: IconProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width ?? "24"}
    height={height ?? "24"}
    viewBox="0 0 24 24"
    fill="none"
    style={style}
    className={className}
  >
    <path
      d="M23.15 11.48a1.5 1.5 0 0 1-.85 1.94l-9.78 3.83-1.1-2.79-2.79 1.1-1.1-2.8-2.79 1.1-2.18-5.59a1.5 1.5 0 0 1 .84-1.94L17.38.85a1.5 1.5 0 0 1 1.94.85l3.84 9.78ZM1.98 22.06a.37.37 0 0 1 .37.37m-.75 0a.37.37 0 0 1 .37-.37m.01.74a.37.37 0 0 1-.38-.37m.75 0a.37.37 0 0 1-.37.38m3.75-.75a.37.37 0 0 1 .37.37m-.75 0a.37.37 0 0 1 .38-.37m0 .74a.37.37 0 0 1-.38-.37m.75 0a.37.37 0 0 1-.38.38m3.76-.75a.37.37 0 0 1 .37.37m-.75 0a.37.37 0 0 1 .37-.37m.01.74a.37.37 0 0 1-.38-.37m.75 0a.37.37 0 0 1-.37.38m-1.5-4.51a.37.37 0 0 1 .37.38m-.75 0a.37.37 0 0 1 .37-.37m.01.75a.37.37 0 0 1-.38-.38m.75 0a.37.37 0 0 1-.37.38m-3.75-.76a.37.37 0 0 1 .37.38m-.75 0a.37.37 0 0 1 .38-.37m0 .75a.37.37 0 0 1-.38-.38"
      stroke={color ?? "#345855"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.6 18.68a.37.37 0 0 1-.38.38"
      stroke={color ?? "#345855"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
