import type {ReactNode} from "react"
import {twMerge} from "tailwind-merge"
import {colors} from "../../theme/colors"

export type AvatarVariant = "circle" | "rounded"
export type AvatarSize =
  | "xxxs"
  | "xxs"
  | "xs"
  | "sm"
  | "md"
  | "lg"
  | "xl"
  | "2xl"

const variantClassnames: Record<AvatarVariant, string> = {
  circle: "!rounded-[999px]",
  rounded: "",
}

const sizeClassnames: Record<AvatarSize, string> = {
  xxxs: "w-4 h-4 rounded",
  xxs: "w-5 h-5 rounded",
  xs: "w-6 h-6 rounded",
  sm: "w-8 h-8 rounded",
  md: "w-10 h-10 rounded",
  lg: "w-12 h-12 rounded-lg",
  xl: "w-14 h-14 rounded-lg",
  "2xl": "w-16 h-16 rounded-lg",
}

export type AvatarProps = {
  textContent?: string
  size?: AvatarSize
  src?: string
  alt?: string
  children?: ReactNode
  className?: string
  variant?: AvatarVariant
  testId?: string
}

export const Avatar = ({
  textContent,
  children,
  className,
  src,
  alt,
  size = "sm",
  variant = "circle",
  testId,
  ...props
}: AvatarProps) => {
  return (
    <div
      {...props}
      data-cy={testId}
      className={twMerge(
        "flex shrink-0 items-center justify-center overflow-hidden",
        variantClassnames[variant],
        sizeClassnames[size],
        (src == null || variant === "rounded") &&
          "border-grey-200 border bg-white",
        className,
      )}
    >
      {src == null ? ( // This is a method for creating scalable text based on container size
        textContent == null ? (
          children
        ) : (
          <svg width="100%" height="100%" viewBox="0 0 35 35">
            <text
              x="50%"
              y="54%"
              dominantBaseline="middle"
              textAnchor="middle"
              fill={colors.grey[500]}
              fontWeight="500"
              fontSize="0.875rem"
            >
              {textContent.slice(0, 1).toUpperCase()}
            </text>
          </svg>
        )
      ) : (
        <img
          alt={alt}
          src={src}
          className="h-full w-full object-cover text-center indent-96"
        />
      )}
    </div>
  )
}
