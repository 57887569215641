import {useCallback} from "react"
import {useController, useFormContext} from "react-hook-form"
import {Select} from "./select"
import type {SelectProps} from "./types"

type FormSelectProps<T> = Omit<SelectProps<T>, "value" | "onChange"> & {
  name: string
  onChange?: (value: T | null) => void
}

export const FormSelect = <T,>({
  name,
  onChange: _onChange,
  ...props
}: FormSelectProps<T>) => {
  const {control, trigger} = useFormContext()

  const {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    field: {value, onChange: fieldOnChange},
    fieldState: {error},
  } = useController({
    name,
    control,
  })

  const onChange = useCallback(
    (val: T | null) => {
      fieldOnChange(val)
      if (_onChange != null) {
        _onChange(val)
      }
      if (error != null) {
        void trigger(name)
      }
    },
    [_onChange, fieldOnChange, error, name, trigger],
  )

  return (
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    <Select {...props} onChange={onChange} error={error} value={value} />
  )
}
