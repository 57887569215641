import {pipe} from "fp-ts/function"
import type {Dispatch, SetStateAction} from "react"
import {useCallback} from "react"
import {useDropzone} from "react-dropzone"
import type {FileRejection} from "react-dropzone"
import * as uuid from "uuid"
import type {AxiosProgressEvent} from "axios"
import type {MaterialImageFieldsFragment} from "generated/graphql"
import * as OE from "../../../../../utils/option-either"
import {useAccessToken} from "../../../../../utils/use-access-token"
import type {LocalImage, StagedImage} from "./types"
import {mutateUploadMaterialImage} from "./upload-material-image"
import {processDropzoneErrors} from "./utils"

export interface UseFileSelectArgs {
  materialId: string
  images: Array<StagedImage>
  setImages:
    | Dispatch<SetStateAction<Array<StagedImage>>>
    | ((images: Array<StagedImage>) => void)
  handleProgress?: (e: AxiosProgressEvent, imageId: string) => void
  handleError?: (error: string, imageId: string) => void
  handleComplete: (result: MaterialImageFieldsFragment, imageId: string) => void
  handleFileRejection?: (errors: Array<string>) => void
  maxFiles?: number
}

export const useFileSelect = ({
  handleProgress,
  handleComplete,
  handleError,
  images,
  setImages,
  materialId,
  maxFiles,
  handleFileRejection,
}: UseFileSelectArgs) => {
  const maxSize = 10_000_000
  const accessToken = pipe(useAccessToken(), OE.noneAndErrorToUndefined)

  const handleFileSelection = useCallback(
    (acceptedFiles: Array<File>) => {
      if (acceptedFiles.length > 0) {
        const localImages: Array<LocalImage> = acceptedFiles
          .map((file) => {
            const id = uuid.v4()
            return {
              localId: id,
              id,
              file,
              thumb: URL.createObjectURL(file),
              progress: 0,
              complete: false,
            }
          })
          .map((file) => {
            if (accessToken != null) {
              void mutateUploadMaterialImage({
                accessToken,
                plantMaterialId: materialId,
                imageId: file.id,
                file: {file: file.file},
                onProgress: handleProgress,
                onComplete: handleComplete,
                onError: handleError,
              })
              return file
            }
            return {...file, error: "No access token found"}
          })
        setImages([...images, ...localImages])
      }
    },
    [
      setImages,
      images,
      accessToken,
      handleProgress,
      materialId,
      handleError,
      handleComplete,
    ],
  )

  const onDropRejected = useCallback(
    (errors: Array<FileRejection>) => {
      handleFileRejection == null
        ? undefined
        : handleFileRejection(
            processDropzoneErrors(errors, maxFiles ?? 10, maxSize),
          )
    },
    [handleFileRejection, maxFiles],
  )

  const dropzoneArgs = useDropzone({
    onDropRejected,
    onDrop: handleFileSelection,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg"],
      "image/jpg": [".jpg"],
      "image/gif": [".gif"],
    },
    maxFiles: maxFiles ?? 10,
    maxSize,
  })

  return {
    ...dropzoneArgs,
    errors: processDropzoneErrors(
      dropzoneArgs.fileRejections,
      maxFiles ?? 10,
      maxSize,
    ),
  }
}
