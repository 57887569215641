import {type MaterialFieldsFragment} from "generated/graphql"
import {useCallback, useMemo, useState} from "react"
import {ActivityItem} from "src/features/records/components/record-activity/activity-item"
import {parseMaterialHistory} from "src/features/records/components/record-activity/parse-material-history"
import {ACTIVITY_PAGE_SIZE} from "src/features/records/components/record-activity/utils"
import {useDateStringFormatter} from "src/utils/hooks/language"

export const useMaterialHistory = (
  material: MaterialFieldsFragment | undefined,
) => {
  const [activityPage, setActivityPage] = useState(1)

  const showMoreActivity = useCallback(() => {
    setActivityPage((x) => x + 1)
  }, [])

  const showLessActivity = useCallback(() => {
    setActivityPage(1)
  }, [])

  const formatDateString = useDateStringFormatter()

  const fullHistory = useMemo(
    () =>
      material?.historyConnection?.nodes == null
        ? null
        : parseMaterialHistory(
            material.historyConnection.nodes,
            formatDateString,
            undefined,
          ).map((props, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <ActivityItem key={i} {...props} />
          )),
    [material, formatDateString],
  )
  const materialHistory = useMemo(
    () =>
      material?.historyConnection?.nodes == null
        ? null
        : parseMaterialHistory(
            material.historyConnection.nodes,
            formatDateString,
            activityPage * ACTIVITY_PAGE_SIZE,
          ).map((props, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <ActivityItem key={i} {...props} />
          )),
    [material, activityPage, formatDateString],
  )

  return {
    activityPage,
    showMoreActivity,
    showLessActivity,
    fullHistory,
    materialHistory,
  }
}
