import {D, F, flow} from "@mobily/ts-belt"
import type {TextFieldProps as MuiTextFieldProps} from "@mui/material/TextField"
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers"
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns"

import {
  lastDayOfMonth,
  lastDayOfYear,
  startOfMonth,
  startOfYear,
  subDays,
  subMonths,
  subWeeks,
  subYears,
} from "date-fns/fp"
import type {DateComparator} from "generated/graphql"
import {useCallback, useMemo, useState, type FocusEventHandler} from "react"
import type {DateRange} from "react-day-picker"
import {FormProvider, useForm} from "react-hook-form"
import {
  dateFilterResolver,
  type LocalDateFilter,
} from "src/features/filters/components/date-filter/schema"
import {
  FilterModal,
  type FilterModalProps,
} from "src/features/filters/components/modal/filter-modal"
import {FilterTitle} from "src/features/filters/components/modal/filter-title"
import {useMobile} from "src/utils/hooks/media-query"
import {Button} from "@hortis/ui/button"
import {Calendar, areDatesEqual} from "@hortis/ui/calendar"
import {useLocaleConfig} from "../date-picker"
import {TextField} from "../text-field/text-field"
import {DateComparatorFilterMobile} from "./date-comparator-filter-mobile"
import {DatePickerListItem} from "./date-picker-list-item"
import {DatePresets} from "./utils"

interface DateComparatorFilterProps {
  filterTitle: string
  required?: boolean
  initialValue?: LocalDateFilter | null
  onDateChange?: (date: Date | null, keyboardInputValue?: string) => void
  onClose: () => void
  addModifyFilter: (changes: DateComparator) => void
  deleteFilter?: () => void
  presets?: Record<
    DatePresets,
    {
      from: (date: Date) => Date
      to: (date: Date) => Date
    }
  >
}

const presetConfig: Record<
  DatePresets,
  {
    from: (date: Date) => Date
    to: (date: Date) => Date
  }
> = {
  [DatePresets.Today]: {
    from: F.identity,
    to: F.identity,
  },
  [DatePresets.Yesterday]: {
    from: subDays(1),
    to: subDays(1),
  },
  [DatePresets.Last7Days]: {
    from: subWeeks(1),
    to: F.identity,
  },
  [DatePresets.ThisMonth]: {
    from: startOfMonth,
    to: F.identity,
  },
  [DatePresets.LastMonth]: {
    from: flow(startOfMonth, subMonths(1)),
    to: flow(lastDayOfMonth, subMonths(1)),
  },
  [DatePresets.ThisYear]: {
    from: startOfYear,
    to: F.identity,
  },
  [DatePresets.LastYear]: {
    from: flow(startOfYear, subYears(1)),
    to: flow(lastDayOfYear, subYears(1)),
  },
}

function isValidDate(date: Date): boolean {
  return date.toString() !== "Invalid Date"
}

const minDate = new Date("1500-01-01")

export const DateComparatorFilter = ({
  required,
  initialValue,
  presets = presetConfig,
  filterTitle: title,
  deleteFilter,
  addModifyFilter,
  onClose,
}: DateComparatorFilterProps) => {
  const formMethods = useForm<LocalDateFilter>({
    defaultValues: initialValue ?? {
      from: undefined,
      to: undefined,
    },
    resolver: dateFilterResolver,
  })

  const {
    setValue,
    formState,
    watch,
    reset,
    handleSubmit,
    setError,
    clearErrors,
  } = formMethods
  const value = watch()

  const isMobile = useMobile()
  const [currentPreset, setCurrentPreset] = useState<DatePresets | undefined>()
  const error = formState.errors.from ?? formState.errors.to
  const [capturedFromValue, setCapturedFromValue] = useState<Date | null>()
  const [capturedToValue, setCapturedToValue] = useState<Date | null>()
  const [hasInvalidInput, setHasInvalidInput] = useState({
    from: false,
    to: false,
  }) // this is used as a 'soft error' state. It acts as a buffer betweeen the input field error and the form error

  const localeConfig = useLocaleConfig()

  const handleBlur = useCallback(
    ({
      fieldName,
      capturedValue,
      persistedFormValue,
      otherValue,
      minDate,
    }: {
      fieldName: keyof LocalDateFilter
      capturedValue: Date | null | undefined
      persistedFormValue: Date | null | undefined
      otherValue: Date | null | undefined //this refers to the other field in the form
      minDate: Date
    }) => {
      if (capturedValue == null && persistedFormValue != null) {
        //This occurs when the user has clicked a preset or selected a range from the calendar, interacted with the input field and left without entering a value
        return
      }
      if (
        capturedValue == null ||
        !isValidDate(capturedValue) ||
        capturedValue < minDate
      ) {
        setHasInvalidInput((prev) => {
          return {...prev, [fieldName]: true}
        })
        if (fieldName === "from") {
          setCapturedFromValue(undefined)
        } else {
          setCapturedToValue(undefined)
        }
        return
      }

      if (
        //set the actual form value depending on what's been captured and what's already in the form
        otherValue != null &&
        ((fieldName === "from" && capturedValue > otherValue) ||
          (fieldName === "to" && capturedValue < otherValue))
      ) {
        setValue(fieldName, otherValue)
      } else {
        setValue(fieldName, capturedValue)
      }
      clearErrors(fieldName)
    },
    [clearErrors, setValue],
  )

  const onBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> =
    useCallback(
      (e) => {
        const {from, to} = value
        const fieldName: string = e.target.name

        if (fieldName === "from") {
          handleBlur({
            fieldName: "from",
            capturedValue: capturedFromValue,
            persistedFormValue: from,
            otherValue: to,
            minDate,
          })
        }

        if (fieldName === "to") {
          handleBlur({
            fieldName: "to",
            capturedValue: capturedToValue,
            persistedFormValue: to,
            otherValue: from,
            minDate,
          })
        }
      },
      [capturedFromValue, capturedToValue, handleBlur, value],
    )

  const onFromDateInputChange = useCallback(
    (date: Date | null) => {
      setCurrentPreset(undefined)
      if (date != null && isValidDate(date)) {
        clearErrors("from")
        setHasInvalidInput((prev) => {
          return {...prev, from: false}
        })
        setCapturedFromValue(date)
      } else if (date != null && !isValidDate(date)) {
        setHasInvalidInput((prev) => {
          return {...prev, from: true}
        })
      } else if (date == null) {
        //This occurs when a user has intentionally cleared the input field. We reset/clear the form values and error states
        setValue("from", null)
        setCapturedFromValue(date)
        setHasInvalidInput((prev) => {
          return {...prev, from: false}
        })
      }
    },
    [clearErrors, setValue],
  )

  const onToDateInputChange = useCallback(
    (date: Date | null) => {
      setCurrentPreset(undefined)
      if (date != null && isValidDate(date)) {
        clearErrors("to")
        setHasInvalidInput((prev) => {
          return {...prev, to: false}
        })
        setCapturedToValue(date)
      } else if (date != null && !isValidDate(date)) {
        setHasInvalidInput((prev) => {
          return {...prev, to: true}
        })
      } else if (date == null) {
        //This occurs when a user has intentionally cleared the input field. We reset/clear the form values and error states
        setValue("to", null)
        setCapturedToValue(date)
        setHasInvalidInput((prev) => {
          return {...prev, to: false}
        })
      }
    },
    [clearErrors, setValue],
  )

  const handlePresetClick = useCallback(
    (preset: DatePresets) => {
      setCurrentPreset(preset)
      const {from, to} = presets[preset]
      setValue("from", from(new Date()))
      setValue("to", to(new Date()))
      clearErrors()
      setHasInvalidInput({
        from: false,
        to: false,
      })
    },
    [clearErrors, presets, setValue],
  )

  const handleCancelButtonClick = useCallback(() => {
    reset()
    setCurrentPreset(undefined)
    onClose()
  }, [onClose, reset])

  const submitForm = useMemo(
    () =>
      handleSubmit((formValues) => {
        const {from, to} = formValues

        if (from != null && to != null) {
          if (areDatesEqual(from, to)) {
            // If the dates are equal, we can just use eq
            addModifyFilter({
              eq: from.toISOString(),
            })
            onClose()
          } else {
            addModifyFilter({
              gte: from.toISOString(),
              lte: to.toISOString(),
            })
            onClose()
          }
        } else if (from != null && to == null) {
          addModifyFilter({
            eq: from.toISOString(),
          })
          onClose()
        } else if (from == null && to != null) {
          addModifyFilter({
            eq: to.toISOString(),
          })
          onClose()
        }
        setCurrentPreset(undefined)
      }),
    [addModifyFilter, handleSubmit, onClose],
  )

  const handleApplyButtonClick = useCallback(() => {
    if (hasInvalidInput.from) {
      setError("from", {message: "Please select a valid date"})
      return
    }
    if (hasInvalidInput.to) {
      setError("to", {message: "Please select a valid date"})
      return
    }

    void submitForm()
  }, [hasInvalidInput.from, hasInvalidInput.to, setError, submitForm])

  const handleSelectRange = useCallback(
    (range: DateRange | undefined) => {
      setCurrentPreset(undefined)
      setValue("from", range?.from)
      setValue("to", range?.to)
      clearErrors()
      setHasInvalidInput({
        from: false,
        to: false,
      })
    },
    [clearErrors, setValue],
  )

  const cachedRenderInput = useCallback(
    (params: Omit<MuiTextFieldProps, "name" | "required">) => {
      return (
        // @ts-expect-error Hack to put the (outlined input) botsoft-frontend text field in here instead of @mui/material/TextField
        // inputProps is concatinated with rest as it contains the data-cy attribute from withTestId
        <TextField
          {...params}
          sx={{
            "& .MuiInputBase-input": {
              height: "36px",
              padding: 0,
              textAlign: "center",
              width: "124px",
            },
            borderRadius: "8px !important",
          }}
          inputProps={{
            ...params.inputProps,
            placeholder: params.InputProps?.placeholder, //work around to specify placeholder
          }}
          hiddenLabel
          required={required}
          testId="date-filter-input"
          onBlur={onBlur}
        />
      )
    },
    [onBlur, required],
  )

  return (
    <FormProvider {...formMethods}>
      <form>
        <div className="flex w-fit flex-col">
          {isMobile ? (
            <DateComparatorFilterMobile
              title={title}
              deleteFilter={deleteFilter}
              handleSelectRange={handleSelectRange}
              currentPreset={currentPreset}
              handleCancelButtonClick={handleCancelButtonClick}
              handleApplyButtonClick={handleApplyButtonClick}
              shouldShowAlert={error != null}
              onPresetSelected={handlePresetClick}
            />
          ) : (
            <>
              <div className="flex w-full items-center justify-between rounded-t-lg px-6 py-4">
                <FilterTitle
                  title={title}
                  error={error}
                  deleteFilter={deleteFilter}
                />
              </div>
              <div className="flex rounded-b-lg border-t border-grey-200">
                <div
                  className="flex w-40 flex-col gap-1 border-r border-grey-200 p-6 pt-5"
                  data-cy="date-filter-presets-menu"
                >
                  {D.keys(presets).map((key) => (
                    <DatePickerListItem
                      key={key}
                      onClick={handlePresetClick}
                      title={key}
                      isSelected={currentPreset === key}
                    />
                  ))}
                </div>
                <div className="flex flex-col">
                  <Calendar
                    selected={{
                      from: value.from ?? undefined,
                      to: value.to ?? undefined,
                    }}
                    mode="range"
                    onSelect={handleSelectRange}
                    numberOfMonths={2}
                    className="border-b border-grey-200"
                  />
                  <div className="flex items-center justify-between p-4">
                    <div className="flex h-9 items-center gap-2">
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        adapterLocale={localeConfig?.locale}
                      >
                        <DatePicker
                          mask={localeConfig?.mask}
                          label="Date start"
                          value={value.from ?? null} // nullish coalescing required to prevent default value of today's date appearing in text field
                          InputProps={{
                            name: "from",
                            placeholder: "Date start",
                            error: formState.errors.from != null,
                          }}
                          minDate={minDate}
                          onChange={onFromDateInputChange}
                          renderInput={cachedRenderInput}
                          disableOpenPicker
                        />
                        -
                        <DatePicker
                          mask={localeConfig?.mask}
                          label="Date end"
                          value={value.to ?? null} // nullish coalescing required to prevent default value of today's date appearing in text field
                          InputProps={{
                            name: "to",
                            placeholder: "Date end",
                            error: formState.errors.to != null,
                          }}
                          minDate={minDate}
                          onChange={onToDateInputChange}
                          renderInput={cachedRenderInput}
                          disableOpenPicker
                        />
                      </LocalizationProvider>
                    </div>
                    <div className="flex h-9 w-[280px] gap-3 ">
                      <Button
                        className="h-full w-full"
                        type="button"
                        onClick={handleCancelButtonClick}
                        testId="date-filter-cancel-button"
                      >
                        Cancel
                      </Button>
                      <Button
                        type="button"
                        variant="primary"
                        className="h-full w-full"
                        onClick={handleApplyButtonClick}
                        testId="date-filter-apply-button"
                      >
                        Apply
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </form>
    </FormProvider>
  )
}

// Seperated from form component to make sure popover unmount causes state reset
export const DateComparatorFilterModal = ({
  open,
  onClose,
  anchorEl,
  ...props
}: DateComparatorFilterProps & FilterModalProps) => {
  return (
    <FilterModal
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      className="w-fit max-w-none !p-0"
    >
      <DateComparatorFilter onClose={onClose} {...props} />
    </FilterModal>
  )
}
