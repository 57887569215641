import {fromSxFn} from "src/utils/sx"
import {SwitchFormField} from "../../../../../components/switch"
import {FormTextField as TextField} from "../../../../../components/text-field/text-field"

const switchLabelStyles = fromSxFn(() => ({
  width: "100%",
  display: "flex",
  flexFlow: "row-reverse",
  margin: 0,
  gap: "8px",
  padding: `0 6px`,
  "& .MuiFormControlLabel-label": {
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
    fontWeight: 500,
  },
  position: "absolute",
  top: "calc(100% + 6px)",
}))

const accessionNumberId = "accession-number"

const accessionNumberErrorStyles = {
  [`.${accessionNumberId} + .MuiBox-root`]: {
    position: "relative",
  },
}

interface AccessionNumberFieldProps {
  isGenerated: boolean
  onToggleChange: () => void
}

export const AccessionNumberField = ({
  isGenerated,
  onToggleChange,
}: AccessionNumberFieldProps) => {
  return (
    <div className="relative flex flex-col gap-1.5">
      <TextField
        controlSx={accessionNumberErrorStyles}
        fullWidth
        id={accessionNumberId}
        className={accessionNumberId}
        label="Accession Number"
        testId={accessionNumberId}
        name="accessionNumber"
        disabled={isGenerated}
        required
      />
      <SwitchFormField
        sx={switchLabelStyles}
        onChange={onToggleChange}
        value={isGenerated}
        name="generateAccessionNumber"
        label="Automatic accession number"
        testId="generateAccessionNumber"
        className="!text-sm !font-medium text-grey-500"
      />
    </div>
  )
}
