import * as E from "fp-ts/Either"
import {toError} from "fp-ts/Either"
import {constant, flow, pipe} from "fp-ts/function"
import * as O from "fp-ts/Option"
import {getOrgAndSiteFromNames} from "../fetch-collection-site"
import {getOrganisationFromName} from "../fetch-organisation"
import {flowInto} from "../flow"
import * as OE from "../option-either"
import {createWrappedSWRHook} from "../swr"
import {createAuthedHookWithMap, createAuthedHookWithMappedStruct} from "./hook"
import {useOrganisationSubdomainName} from "./organisation-subdomain-name"
import {extractOrg, usePlace} from "./place"

const InvalidOrgSubdomain = {
  type: "NO_ORG_SUBDOMAIN_FOUND" as const,
}
type InvalidOrgSubdomain = typeof InvalidOrgSubdomain

const useExpectedOrganisationSubdomainName = flow(
  useOrganisationSubdomainName,
  O.map(E.fromOption(constant(InvalidOrgSubdomain))),
  OE.fromOptionEither,
)

export const useOrganisation = pipe(
  createWrappedSWRHook(getOrganisationFromName, toError),
  flowInto(OE.chainW(extractOrg)),
  createAuthedHookWithMappedStruct,
  (useOrganisation) => () => {
    const organisationSubdomain = useExpectedOrganisationSubdomainName()

    return useOrganisation(
      {organisationSubdomain},
      ({organisationSubdomain}) => [
        organisationSubdomain === undefined
          ? undefined
          : {
              organisationSubdomain,
            },
      ],
    )
  },
)

export const useValidOrganisation = flow(
  useOrganisation,
  OE.noneAndErrorToUndefined,
)

export const useOrgWithSite = pipe(
  createWrappedSWRHook(getOrgAndSiteFromNames, toError),
  flowInto(OE.chainW(extractOrg)),
  createAuthedHookWithMap,
  (useOrgAndSite) => () =>
    useOrgAndSite(usePlace(), (place) => [
      place === undefined
        ? undefined
        : {
            organisationSubdomain: place.orgName,
            collectionSiteSlug: place.siteSlug,
          },
    ]),
)
