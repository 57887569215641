import {useRestoreMaterialMutation} from "generated/graphql"
import {type MouseEventHandler, useCallback} from "react"
import {Modal} from "src/components"

import {
  useSnackbarStore,
  type SetSnack,
} from "src/components/snackbar-controller/snackbar-store"

interface RestoreMaterialDialogProps {
  materialId: string
  open: boolean
  onClose: () => void
  testId?: string
}

const showErrorSnack = (setSnack: SetSnack) => () => {
  setSnack({
    type: "alert",
    data: {
      severity: "error",
      text: "Failed to restore plant material, please try again",
    },
  })
}

const showSuccessSnack = (setSnack: SetSnack) => () => {
  setSnack({
    type: "action",
    data: {
      text: "Successfully restored plant material",
    },
  })
}

export const RestoreMaterialDialog = ({
  materialId,
  open,
  onClose,
  testId,
}: RestoreMaterialDialogProps) => {
  const {setSnack} = useSnackbarStore()
  const [_, restoreMaterial] = useRestoreMaterialMutation()
  const handleSubmit = useCallback<MouseEventHandler<HTMLButtonElement>>(
    async (e) => {
      e.stopPropagation()
      try {
        const restoreArchiveResult = await restoreMaterial({
          materialId,
        })
        const res = restoreArchiveResult.data?.restorePlantMaterial
        if (res == null || res.__typename === "GenericError") {
          throw new Error("Failed to restore plant material")
        }
        showSuccessSnack(setSnack)()
      } catch {
        showErrorSnack(setSnack)()
      }
      onClose()
    },
    [onClose, restoreMaterial, materialId, setSnack],
  )

  return (
    <Modal
      onClose={onClose}
      title={"Restore plant material?"}
      open={open}
      primaryButtonProps={{
        children: "Restore",
        onClick: handleSubmit,
      }}
      secondaryButtonProps={{
        children: "Cancel",
      }}
      body={"This will restore 1 plant material record."}
      testId={testId ?? "restore-material-modal"}
    />
  )
}
