import {useCallback, useEffect, useState} from "react"
import {useFormContext, useWatch} from "react-hook-form"
import {useClusters} from "src/components/map/material-clusters/use-clusters"
import {MapPositionField} from "src/components/map/position-field/map-position-field"
import type {FormFields} from "src/components/map/position-field/utils"
import {useGeometryLayers} from "src/features/collection-map/components/use-geometry-layers"
import {useUpdateGeography} from "src/features/collection-map/fetchers"
import {PositionFields} from "./position-fields"

interface MaterialMapPositionFieldProps {
  namePrefix: string
}

export const MaterialMapPositionField = ({
  namePrefix,
}: MaterialMapPositionFieldProps) => {
  const [showFields, setShowFields] = useState(false)
  const {setValue, getValues, trigger, clearErrors} =
    useFormContext<FormFields>()
  const position = useWatch<FormFields, `${string}position`>({
    name: `${namePrefix}position`,
  })

  // If both longitude and latitude are null, clear errors, this is required
  // due to form validation not re-validating an adjacent field after it
  // becomes valid
  useEffect(() => {
    if (
      position !== null &&
      position.longitude == null &&
      position.latitude == null
    ) {
      clearErrors(`${namePrefix}position`)
    }
  }, [
    position?.longitude,
    position?.latitude,
    clearErrors,
    namePrefix,
    position,
  ])

  const toggleFields = useCallback(async () => {
    const position = getValues(`${namePrefix}position`)

    if (position?.latitude == null && position?.longitude == null) {
      setShowFields((x) => !x)
      return
    }

    const valid = await trigger(`${namePrefix}position`)
    if (valid) {
      setShowFields((x) => !x)
    }
  }, [namePrefix, trigger, getValues])

  const onCoordinatePaste = useCallback(async () => {
    const currentLat = getValues(`${namePrefix}position.latitude`)
    const currentLong = getValues(`${namePrefix}position.longitude`)
    const clipboardText = await navigator.clipboard.readText()
    const [clipboardLat, clipboardLong] = clipboardText
      .replace(" ", "")
      .split(",")
    if (
      clipboardLat == null ||
      clipboardLong == null ||
      Number.isNaN(Number(clipboardLat)) ||
      Number.isNaN(Number(clipboardLong)) ||
      currentLat != null ||
      currentLong != null
    ) {
      return
    } else {
      setValue(`${namePrefix}position.latitude`, String(clipboardLat))
      setValue(`${namePrefix}position.longitude`, String(clipboardLong))
    }
  }, [getValues, namePrefix, setValue])

  const handleConfirm = useCallback(
    ({latitude, longitude}: {latitude: number; longitude: number}) => {
      setValue(`${namePrefix}position`, {
        latitude: String(latitude),
        longitude: String(longitude),
        elevation: null,
      })
    },
    [setValue, namePrefix],
  )

  const handleDelete = useCallback(() => {
    setValue(`${namePrefix}position`, null)
  }, [setValue, namePrefix])

  const [geometry] = useUpdateGeography(undefined, {
    excludeAbsentMaterial: true,
    withCoordinates: true,
  })

  const {
    clusterLayer: [clusterLayer, clusterConfig],
    clusterCountLayer: [clusterCountLayer],
    unclusteredPointLayer: [unclusteredPointLayer, unclusteredPointConfig],
  } = useClusters({
    mapId: "preview-map",
    mode: "editMaterial",
  })
  const [geometryLayers] = useGeometryLayers({
    geometry,
    layers: [clusterLayer, clusterCountLayer, unclusteredPointLayer],
    layerConfigs: [unclusteredPointConfig, clusterConfig],
  })

  return (
    <MapPositionField
      position={position}
      toggleFields={toggleFields}
      showFields={showFields}
      fieldsComponent={
        <PositionFields
          namePrefix={namePrefix}
          onCoordinatePaste={onCoordinatePaste}
        />
      }
      onDelete={handleDelete}
      onConfirm={handleConfirm}
      label="Map position"
      modalProps={{
        geometryLayers,
        title: "Positioning material",
        description: "Drag the map to set the position of your plant material.",
        useSiteCoordinates: position == null,
      }}
    />
  )
}
