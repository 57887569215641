import {pipe} from "fp-ts/function"
import {mapType} from "../io-ts"
import {getTwoTupleFromDelimitedString} from "./delimited"

const toRecord = ([accessionNumber, materialQualifier]: [
  accessionNumber: string,
  materialQualifier: string,
]) => ({
  accessionNumber,
  materialQualifier,
})

const fromRecord = ({
  accessionNumber,
  materialQualifier,
}: {
  accessionNumber: string
  materialQualifier: string
}): [accessionNumber: string, materialQualifier: string] => [
  accessionNumber,
  materialQualifier,
]

export const MaterialNumberQueryParam = pipe(
  getTwoTupleFromDelimitedString("_"),
  mapType(toRecord, fromRecord),
)

export const MaterialNumberSlash = pipe(
  getTwoTupleFromDelimitedString("/"),
  mapType(toRecord, fromRecord),
)
