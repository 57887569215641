import TimeAgo from "react-timeago"
import type {Formatter} from "react-timeago"
import {Tooltip} from "src/components/tooltip"
import {Typography} from "../../../../../components/typography"
import {useDateStringFormatter} from "../../../../../utils/hooks/language"
import type {ImageAuthor} from "./material-image-author"
import {MaterialImageAuthor} from "./material-image-author"

export const timeAgoFormatter: Formatter = (
  value,
  unit,
  suffix,
  epochMilliseconds,
  nextFormatter,
) =>
  unit === "second"
    ? "Just now"
    : nextFormatter == null
    ? undefined
    : nextFormatter(value, unit, suffix, epochMilliseconds)

interface MaterialImageSubinfoProps {
  author?: ImageAuthor
  uploadedAt: string | undefined
  manualImport: boolean
}

export const MaterialImageSubinfo = ({
  author,
  uploadedAt,
  manualImport,
}: MaterialImageSubinfoProps) => {
  const formatDateTime = useDateStringFormatter({
    dateStyle: "full",
    timeStyle: "long",
  })

  return (
    <div className="mt-2 flex items-center justify-between">
      <MaterialImageAuthor author={author} manualImport={manualImport} />
      {uploadedAt != null && (
        <Typography
          variant="subtitle2"
          color="text.secondary"
          testId="image-card-date"
        >
          <Tooltip title={formatDateTime(uploadedAt) ?? false} placement="top">
            <span>
              <TimeAgo
                date={new Date(uploadedAt)}
                formatter={timeAgoFormatter}
                style={{display: "inline-block"}}
              />
            </span>
          </Tooltip>
        </Typography>
      )}
    </div>
  )
}
