import {pipe} from "fp-ts/function"
import * as O from "fp-ts/Option"
import * as OE from "../../utils/option-either"
import type {
  GetUserPermissionsQuery,
  GetUserPermissionsQueryVariables,
} from "../../../generated/graphql"
import {authedFetcher} from "../../utils/gql-client"
import {createAuthedHookWithMap} from "../../utils/hooks/hook"
import type {UseCollectionSiteSlugResult} from "../../utils/hooks/collection-site-slug"
import {useOrganisationSubdomainNameWhenPresent} from "../../utils/hooks/organisation-subdomain-name"
import {useCollectionSiteSlug} from "../../utils/hooks/collection-site-slug"
import {toError} from "../../utils/option-either"
import {createWrappedSWRHook} from "../../utils/swr"
import {GET_PERMISSIONS} from "./graphql"

const extractCollectionSite = (
  collectionSiteSlug: UseCollectionSiteSlugResult,
) => pipe(collectionSiteSlug, O.flatten, O.toUndefined)

export const fetchPermissions = authedFetcher<
  GetUserPermissionsQueryVariables,
  GetUserPermissionsQuery
>(GET_PERMISSIONS)("GetUserPermissions")

// This is a bit of a hack because there's no 'authed hook with vars' utility yet
const nothing = OE.of(undefined)

export const usePermissions = pipe(
  createWrappedSWRHook(fetchPermissions, toError),
  createAuthedHookWithMap,
  (usePermissions) => () => {
    const subdomain = useOrganisationSubdomainNameWhenPresent()
    const collectionSiteSlug = extractCollectionSite(useCollectionSiteSlug())

    return usePermissions(nothing, () => [
      subdomain === undefined
        ? undefined
        : {
            subdomain,
            collectionSiteSlug,
          },
    ])
  },
)
