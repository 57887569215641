import type {HTMLAttributes} from "react"
import {twMerge} from "tailwind-merge"

const EmptyState = ({className, ...props}: HTMLAttributes<HTMLDivElement>) => (
  <div
    className={twMerge(
      "flex flex-col items-stretch gap-6 md:items-center",
      className,
    )}
    data-cy="empty-state"
    {...props}
  />
)

const EmptyStateHeader = ({
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) => (
  <div
    className={twMerge(
      "flex flex-col items-center gap-1 text-center",
      className,
    )}
    {...props}
  />
)

const EmptyStateTitle = ({
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) => (
  <div
    className={twMerge(
      "text-base font-medium [&:not(:first-child)]:mt-4",
      className,
    )}
    {...props}
  />
)

const EmptyStateBody = ({
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) => (
  <p className={twMerge("text-sm text-grey-500", className)} {...props} />
)

const EmptyStateActions = ({
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) => (
  <div
    className={twMerge("flex flex-1 flex-col gap-3 md:flex-row", className)}
    {...props}
  />
)

export {
  EmptyState,
  EmptyStateActions,
  EmptyStateBody,
  EmptyStateHeader,
  EmptyStateTitle,
}
