import type {Interpolation, Theme} from "@emotion/react"
import {twMerge} from "tailwind-merge"

const createSpacing = (spacing: number) => `calc(${spacing} * 4px)`

export type Direction = "horizontal" | "vertical"

const dividerClasses: Record<Direction, string> = {
  vertical: `shrink-0 border-r border-grey-200 w-[1px] min-w-[1px]`,
  horizontal: `shrink-0 border-b border-grey-200 h-[1px] min-h-[1px]`,
}

const dividerSpacing = (spacing: number) => ({
  vertical: {
    marginTop: createSpacing(spacing),
    paddingLeft: createSpacing(spacing),
  },
  horizontal: {
    marginBottom: createSpacing(spacing),
    paddingTop: createSpacing(spacing),
  },
})

export interface DividerProps {
  direction?: Direction
  css?: Interpolation<Theme>
  spacing?: number
  className?: string
}

export const Divider = ({
  direction = "horizontal",
  spacing = 0,
  css,
  className,
}: DividerProps) => (
  <div
    className={twMerge(dividerClasses[direction], className)}
    css={[dividerSpacing(spacing)[direction], css]}
  />
)
