import {pipe, flow} from "fp-ts/function"
import {useMemo} from "react"
import type {SWRConfiguration, SWRResponse, MutatorCallback} from "swr"
import useSWR, {mutate as globalMutate} from "swr"
import type {AuthedFetcher, StringLiteral} from "./gql-client"
import wrapSWR from "./wrap-swr"
import type * as OE from "./option-either"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ArrayAny = Array<any>

type Prefetcher<Args extends ArrayAny, Result> = {
  prefetch: (
    ...args: Args | [...Args, {shouldRevalidate?: boolean}]
  ) => Promise<Result>
  optimisticUpdate: (
    keyArgs: Args,
    data: MutatorCallback<Result>,
    shouldRevalidate?: boolean,
  ) => Promise<Result>
}

type FetchingHook<Args extends ArrayAny, Result, Err> = (
  ...args: Args | [...Args, SWRConfiguration<Result, Err> | undefined]
) => SWRResponse<Result, Err>

type WrappedFetchingHook<Args extends ArrayAny, Result, Err> = (
  ...args: Args | [...Args, SWRConfiguration<Result, Err> | undefined]
) => OE.OptionEither<Err, Result>

type FetchingHookAndPrefetcher<
  Args extends ArrayAny,
  Result,
  Err,
> = FetchingHook<Args, Result, Err> & Prefetcher<Args, Result>

type WrappedFetchingHookAndPrefetcher<
  Args extends ArrayAny,
  Result,
  Err,
> = WrappedFetchingHook<Args, Result, Err> & Prefetcher<Args, Result>

type AllFetchingHookAndPrefetchers<Args extends ArrayAny, Result, Err> =
  | FetchingHookAndPrefetcher<Args, Result, Err>
  | WrappedFetchingHookAndPrefetcher<Args, Result, Err>

export type GetSWRConfiguration<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  F extends AllFetchingHookAndPrefetchers<any, any, any>,
> =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  F extends AllFetchingHookAndPrefetchers<any, infer Result, infer Err>
    ? SWRConfiguration<Result, Err>
    : never

/**
 * This is a bit too specific to the current file to be made its own utility
 * and it's exported only for unit testing.
 * It splits a function's args, separating the last one from the rest.
 */
export const splitInitAndLastFrom = <Args extends ArrayAny>(
  fn: (...args: Args) => unknown,
): (<Last>(args: Args | [...Args, Last]) => {
  init: Args
  last: Last | undefined
}) =>
  pipe(
    fn,
    (fn) => fn.length,
    (expectedLength) => (values) => {
      const hasMore = values.length > expectedLength

      return {
        init: (hasMore ? values.slice(0, -1) : values) as Args,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- hard to convince TS this is fine
        last: hasMore ? values.slice(-1).at(0) : undefined,
      }
    },
  )

export const _createSWRHook =
  (_useSWR: typeof useSWR) =>
  <
    Args extends ArrayAny,
    Result,
    Name extends StringLiteral<Name>,
    Err = unknown,
  >(
    fetcher: AuthedFetcher<Args, Result, Name>,
    toError?: (error: unknown, args: Args) => Err,
  ): FetchingHookAndPrefetcher<Args, Result, Err> => {
    const split = splitInitAndLastFrom<Args>(fetcher.fetch)
    const prefetch: Prefetcher<Args, Result>["prefetch"] = async (
      ...args: Args | [...Args, {shouldRevalidate?: boolean}]
    ) => {
      const {init: fetchArgs, last: config} = split(args)

      const key = fetcher.deriveKey(...fetchArgs)

      // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- hard to prove this is fine due to the typing of SWR's mutate
      return await globalMutate(
        key,
        await fetcher.fetch(...fetchArgs),
        config?.shouldRevalidate,
      )
    }

    const optimisticUpdate = async (
      keyArgs: Args,
      data: MutatorCallback<Result>,
      shouldRevalidate?: boolean,
    ): Promise<Result> => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- hard to prove this is fine due to the typing of SWR's mutate
      return await globalMutate(
        fetcher.deriveKey(...keyArgs),
        data,
        shouldRevalidate,
      )
    }

    const useFetch: FetchingHookAndPrefetcher<Args, Result, Err> = (
      ...args
    ) => {
      const {init: fetchArgs, last: config} = split(args)

      // eslint-disable-next-line react-hooks/exhaustive-deps
      const key = useMemo(() => fetcher.deriveKey(...fetchArgs), fetchArgs)

      return _useSWR<Result, Err>(
        key,
        toError === undefined
          ? () => fetcher.fetch(...fetchArgs)
          : () =>
              fetcher
                .fetch(...fetchArgs)
                // eslint-disable-next-line promise/no-return-wrap
                .catch((error) => Promise.reject(toError(error, fetchArgs))),
        config,
      )
    }
    useFetch.prefetch = prefetch
    useFetch.optimisticUpdate = optimisticUpdate

    return useFetch
  }

export const createSWRHook = _createSWRHook(useSWR)

export const wrapSWRResult = <Args extends ArrayAny, Result, Err>(
  useSWRHook: FetchingHookAndPrefetcher<Args, Result, Err>,
): WrappedFetchingHookAndPrefetcher<Args, Result, Err> => {
  const fn = flow(useSWRHook, wrapSWR) as WrappedFetchingHookAndPrefetcher<
    Args,
    Result,
    Err
  >
  fn.prefetch = useSWRHook.prefetch
  fn.optimisticUpdate = useSWRHook.optimisticUpdate
  return fn
}

export const createWrappedSWRHook = flow(createSWRHook, wrapSWRResult)
