import {FormTextField} from "src/components/text-field"

interface PositionFieldsProps {
  namePrefix?: string
}

export const PositionFields = ({namePrefix = ""}: PositionFieldsProps) => (
  <div className="flex flex-col gap-8 rounded-lg border border-grey-200 bg-grey-50 px-4 py-6 shadow-xs md:px-8 md:py-8">
    <FormTextField
      label="Latitude"
      name={`${namePrefix}centerPoint.latitude`}
      testId="latitude"
      type="text"
      signedNumeric
      decimal
    />
    <FormTextField
      label="Longitude"
      name={`${namePrefix}centerPoint.longitude`}
      testId="longitude"
      type="text"
      signedNumeric
      decimal
    />
    <FormTextField
      label="Zoom level"
      name={`${namePrefix}centerPoint.zoom`}
      testId="zoom"
      type="text"
      helperText="Most sites fit between 16-19 zoom level"
      numeric
      decimal
    />
  </div>
)
