import {useAuth0} from "@auth0/auth0-react"
import {Button} from "@hortis/ui/button"
import {AlertCircle} from "@hortis/ui/icons"
import Alert from "@mui/material/Alert"
import type {ReactElement, ReactNode} from "react"
import {Helmet} from "react-helmet-async"
import {
  EmptyState,
  EmptyStateActions,
  EmptyStateBody,
  EmptyStateHeader,
  EmptyStateTitle,
} from "src/components/empty-state"
import {FeaturedIcon} from "src/components/featured-icon/featured-icon"
import {PageContent} from "src/components/page-content"
import {Typography} from "src/components/typography"
import {OrganisationTile} from "src/features/organisation/components/organisation-tile"
import {CollectionSiteTile} from "src/features/organisation/components/site-tile"
import type {GetUserPermissionsQuery} from "../../../../generated/graphql"
import {useGetMeOrgWithSitesQuery} from "../../../../generated/graphql"
import {RenderError} from "../../../components/error"

type Permissions = GetUserPermissionsQuery["permissions"]

interface Props {
  children: ReactNode
  permissions?: Permissions | null
}

const invalidMessageMap = {
  isInvalidOrganisation: "Looks like you have entered an invalid subdomain",
  isInvalidCollectionSite: "Looks like you have entered an invalid garden name",
  deactivated:
    "Your account has been deactivated, please contact an organization administrator",
}
type BooleanKeys<T> = Exclude<
  {
    [K in keyof T]: T[K] extends boolean ? K : never
  }[keyof T],
  undefined
>

const getBooleanKeys = <T extends object>(obj: T): Array<BooleanKeys<T>> => {
  const keys = Object.keys(obj) as Array<keyof T>
  return keys.filter((k) => (obj[k] as unknown) === true) as Array<
    BooleanKeys<T>
  >
}

export const InvalidPermissions = ({
  children,
  permissions,
}: Props): ReactElement => {
  const {logout} = useAuth0()
  const isDeactivated = permissions?.deactivated === true
  const [{data}] = useGetMeOrgWithSitesQuery({pause: isDeactivated})
  if (isDeactivated) {
    return (
      <div className="flex h-screen justify-center bg-grey-50 py-12 md:py-20">
        <EmptyState
          className="max-w-sm rounded-lg border border-grey-200 bg-white p-6 shadow-xs"
          data-cy="deactivated-error"
        >
          <EmptyStateHeader>
            <FeaturedIcon color="warning">
              <AlertCircle className="text-warning-600" />
            </FeaturedIcon>
            <EmptyStateTitle>Your account has been deactivated</EmptyStateTitle>
            <EmptyStateBody>
              Please contact an organization administrator.
            </EmptyStateBody>
          </EmptyStateHeader>
          <EmptyStateActions>
            <Button
              variant="primary"
              onClick={() => {
                void logout({
                  logoutParams: {
                    returnTo: `${window.location.origin}/auth/login`,
                  },
                })
              }}
            >
              Log out
            </Button>
          </EmptyStateActions>
        </EmptyState>
      </div>
    )
  }

  if (
    permissions != null &&
    (permissions.isInvalidCollectionSite || permissions.isInvalidOrganisation)
  ) {
    const booleanKeys = getBooleanKeys(permissions)

    const organisation = data?.me?.organisation ?? undefined
    const collectionSites = data?.me?.collectionSites ?? undefined

    if (organisation == null || collectionSites == null) {
      return (
        <RenderError title="No organisation found">
          No organization or collection sites
        </RenderError>
      )
    }

    return (
      <>
        <Helmet>
          <title>{organisation.name} | Hortis</title>
          <meta
            property="og:title"
            content={`${organisation.name} | Hortis`}
            key="title"
          />
        </Helmet>
        <PageContent responsivePadding>
          <div className="mx-auto flex w-full max-w-[600px] flex-col gap-6 py-6 md:py-16 lg:pt-20">
            <Typography variant="h5" mb={2}>
              Collection sites
            </Typography>
            <Alert data-cy="error-alert" severity="error">
              {booleanKeys.map((k) => invalidMessageMap[k])}
            </Alert>
            <OrganisationTile organisation={organisation} />
            <div className="space-y-3">
              {collectionSites.map((site) => (
                <CollectionSiteTile key={site.id} collectionSite={site} />
              ))}
            </div>
          </div>
        </PageContent>
      </>
    )
  }

  return <>{children}</>
}
