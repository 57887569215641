import {twMerge} from "tailwind-merge"
import type {IconProps} from "./types"

export const MarkerPinOff1 = ({
  size,
  color,
  className,
  ...props
}: IconProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    className={twMerge(color == null && "h-5 w-5 stroke-current", className)}
    stroke={color}
    fill="none"
    {...props}
  >
    <path
      d="M21 21L16.9014 16.9014M2 2L5.43173 5.43173M9.12854 9.12854C9.5019 7.89669 10.6462 7 12 7C13.6569 7 15 8.34315 15 10C15 11.3538 14.1033 12.4981 12.8715 12.8715M9.12854 9.12854L12.8715 12.8715M9.12854 9.12854L5.43173 5.43173M12.8715 12.8715L16.9014 16.9014M5.43173 5.43173C6.87714 3.3574 9.28005 2 12 2C16.4183 2 20 5.58172 20 10C20 12.4915 18.7281 14.717 16.9014 16.9014M14.391 19.6169C13.616 20.4011 12.808 21.192 12 22C8 18 4 14.4183 4 10C4 9.75042 4.01105 9.5083 4.03379 9.25976"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
