import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@hortis/ui/breadcrumb"
import {IconButton} from "@hortis/ui/button"
import {useMediaQuery} from "@hortis/ui/hooks/media-query"
import {Download1, Plus} from "@hortis/ui/icons"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@hortis/ui/select"
import {Tab, TabHeader} from "@hortis/ui/tab"
import {
  Link,
  useNavigate,
  useParams,
  useRouteContext,
  useSearch,
} from "@tanstack/react-router"
import {useGetCollectionSiteFromNamesQuery} from "generated/graphql"
import {useCallback} from "react"
import {Helmet} from "react-helmet-async"
import {
  TopBar,
  TopBarActionButtons,
  TopBarContent,
} from "src/components/nav-layout/topbar"
import {useTopbarStore} from "src/components/nav-layout/topbar/topbar-store"
import {Tooltip, TooltipContent, TooltipTrigger} from "@hortis/ui/tooltip"
import {useAccessRole} from "src/features/permissions/use-access-role"
import {useCollectionSiteSettings} from "src/utils/hooks/collection-site-from-url"
import {useCollectionSiteHrefs} from "src/utils/hooks/hrefs"
import {useToggle} from "src/utils/hooks/toggle"
import withAuthenticationRequired from "src/utils/with-authentication-required"
import {match} from "ts-pattern"
import {Skeleton} from "src/components/skeleton"
import {AccessionsList} from "./accessions/site-accessions-table"
import {MaterialsList} from "./plant-materials/site-materials-table"
import type {CollectionTab} from "./route"
import {TaxaList} from "./taxa/site-taxa-table"

// eslint-disable-next-line sonarjs/cognitive-complexity
export const SiteList = ({isTrash = false}: {isTrash?: boolean}) => {
  const isDesktop = useMediaQuery("lg")
  const isSmallMobile = useMediaQuery("(min-width: 321px)")
  const collectionSiteHrefs = useCollectionSiteHrefs()
  const {canEdit} = useAccessRole()
  const setTitle = useTopbarStore((state) => state.setTitle)
  const collectionSiteSettings = useCollectionSiteSettings()
  const [downloadRequested, toggleDownloadRequested] = useToggle()

  const {subdomain} = useRouteContext({
    from: isTrash
      ? // eslint-disable-next-line sonarjs/no-duplicate-string
        "/_layout/sites/$siteSlug/trash"
      : // eslint-disable-next-line sonarjs/no-duplicate-string
        "/_layout/sites/$siteSlug/collection",
  })

  const {siteSlug} = useParams({
    from: isTrash
      ? "/_layout/sites/$siteSlug/trash"
      : "/_layout/sites/$siteSlug/collection",
  })

  const search = useSearch({
    from: isTrash
      ? "/_layout/sites/$siteSlug/trash"
      : "/_layout/sites/$siteSlug/collection",
  })

  const [{data: siteData}] = useGetCollectionSiteFromNamesQuery({
    variables: {
      organisationSubdomain: subdomain,
      collectionSiteSlug: siteSlug,
    },
  })
  const site = siteData?.org?.site

  const tab = "tab" in search ? search.tab : undefined
  const navigate = useNavigate({
    from: isTrash ? "/sites/$siteSlug/trash" : "/sites/$siteSlug/collection",
  })

  const setTab = useCallback(
    (value: CollectionTab) => {
      void navigate({
        search: (search) => ({
          rows: "rows" in search ? search.rows : undefined,
          tab: value,
        }),
      })
    },
    [navigate],
  )

  const pageTitle = isTrash ? "Trash" : "List"
  setTitle(pageTitle)

  const toolbarActions = (
    <>
      <Tooltip>
        <TooltipTrigger asChild>
          <IconButton
            icon={Plus}
            variant="secondaryGray"
            ariaLabel={`New ${match(tab)
              .with("accessions", () => "accession")
              .with("taxa", () => "taxon")
              .otherwise(() => "material")}`}
            size="xs"
            className="h-8 w-8"
            asChild
          >
            <Link
              to={match(tab)
                .with(
                  "accessions",
                  () => `/${collectionSiteHrefs?.accessionsCreate ?? ""}`,
                )
                .with("taxa", () => "/taxonomy/new")
                .otherwise(() => `/${collectionSiteHrefs?.materialsNew ?? ""}`)}
            />
          </IconButton>
        </TooltipTrigger>
        <TooltipContent>{`New ${match(tab)
          .with("accessions", () => "accession")
          .with("taxa", () => "taxon")
          .otherwise(() => "material")}`}</TooltipContent>
      </Tooltip>
      <Tooltip>
        <TooltipTrigger asChild>
          <IconButton
            size="xs"
            icon={Download1}
            variant="secondaryGray"
            ariaLabel="Download CSV"
            className="h-8 w-8"
            onClick={toggleDownloadRequested}
          />
        </TooltipTrigger>
        <TooltipContent>Download CSV</TooltipContent>
      </Tooltip>
    </>
  )

  return (
    <>
      {isDesktop && (
        <TopBar>
          <TopBarContent>
            <Breadcrumb>
              <BreadcrumbList>
                <BreadcrumbItem data-cy="site-name">
                  {site?.name ?? <Skeleton width="48px" height="24px" />}
                </BreadcrumbItem>
                <BreadcrumbSeparator />
                <BreadcrumbItem data-cy="page-title">
                  {pageTitle}
                </BreadcrumbItem>
              </BreadcrumbList>
            </Breadcrumb>
            <Tab value={tab ?? "materials"} onValueChange={setTab}>
              <TabHeader value="materials" key="materials">
                Materials
              </TabHeader>
              <TabHeader value="accessions" key="accessions">
                Accessions
              </TabHeader>
              {!isTrash && (
                <TabHeader value="taxa" key="taxa">
                  Taxa
                </TabHeader>
              )}
            </Tab>
          </TopBarContent>
          {canEdit && !isTrash && (
            <TopBarActionButtons>{toolbarActions}</TopBarActionButtons>
          )}
        </TopBar>
      )}
      <div className="flex h-full flex-1 flex-col overflow-hidden">
        <Helmet>
          <title>Collection | Hortis</title>
        </Helmet>
        {!isDesktop && (
          <div className="flex flex-row items-center justify-between gap-2 border-b border-grey-200 p-4 px-3">
            {isSmallMobile ? (
              <Tab value={tab ?? "materials"} onValueChange={setTab}>
                <TabHeader value="materials" key="materials">
                  Materials
                </TabHeader>
                <TabHeader value="accessions" key="accessions">
                  Accessions
                </TabHeader>
                {!isTrash && (
                  <TabHeader value="taxa" key="taxa">
                    Taxa
                  </TabHeader>
                )}
              </Tab>
            ) : (
              <Select
                value={tab ?? "materials"}
                onValueChange={(val) => {
                  setTab(val as CollectionTab)
                }}
              >
                <SelectTrigger className="h-8">
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="materials">Materials</SelectItem>
                  <SelectItem value="accessions">Accessions</SelectItem>
                  {!isTrash && <SelectItem value="taxa">Taxa</SelectItem>}
                </SelectContent>
              </Select>
            )}
            <div className="flex gap-2">{!isTrash && toolbarActions}</div>
          </div>
        )}
        {match(tab)
          .with("accessions", () => (
            <AccessionsList
              downloadRequested={downloadRequested}
              resetDownloadRequested={toggleDownloadRequested}
              collectionSiteHrefs={collectionSiteHrefs}
              collectionSiteSettings={collectionSiteSettings}
              isTrash={isTrash}
            />
          ))
          .with("taxa", () => (
            <TaxaList
              downloadRequested={downloadRequested}
              resetDownloadRequested={toggleDownloadRequested}
            />
          ))
          .otherwise(() => (
            <MaterialsList
              downloadRequested={downloadRequested}
              resetDownloadRequested={toggleDownloadRequested}
              collectionSiteHrefs={collectionSiteHrefs}
              collectionSiteSettings={collectionSiteSettings}
              isTrash={isTrash}
            />
          ))}
      </div>
    </>
  )
}

export const Component = withAuthenticationRequired(SiteList, {
  onRedirecting: function OnRedirect() {
    return <SiteList />
  },
})
