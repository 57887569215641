import {twMerge} from "tailwind-merge"
import type {IconProps} from "./types"

export const MarkerPinPlus1 = ({
  size,
  color,
  className,
  ...props
}: IconProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    className={twMerge(color == null && "h-5 w-5 stroke-current", className)}
    stroke={color}
    fill="none"
    {...props}
  >
    <path
      d="M20 8V5M20 5V2M20 5H17M20 5H23M19.7263 12C18.7593 15.5393 15.3796 18.6204 12 22C8 18 4 14.4183 4 10C4 5.58172 7.58172 2 12 2C12.5668 2 13.1197 2.05893 13.6532 2.17101M15 10C15 11.6569 13.6569 13 12 13C10.3431 13 9 11.6569 9 10C9 8.34315 10.3431 7 12 7C13.6569 7 15 8.34315 15 10Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
