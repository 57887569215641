import {grey} from "@mui/material/colors"
import {createTheme} from "@mui/material/styles"
import type {ThemeOptions} from "@mui/material/styles"
// eslint-disable-next-line no-restricted-imports -- Need to go deeper into material path
import type {Shadows} from "@mui/material/styles/shadows"

declare module "@mui/material/styles" {
  interface PaletteColor {
    lightBg?: string
    background?: string
    darkText?: string
    border?: string
  }
  interface Palette {
    other: {
      border: string
    }
    gray: {
      25: string
      50: string
      100: string
      200: string
      300: string
      400: string
      500: string
      600: string
      700: string
      800: string
      900: string
    }
  }
  interface SimplePaletteColorOptions {
    lightBg?: string
    background?: string
    darkText?: string
    border?: string
  }
}

declare module "@mui/system/createTheme/shape" {
  interface Shape {
    borderRadius: number
    borderRadiusString: string
    mobileBottomNavHeight: string
  }
}

const colors = {
  primary: {
    main: "#0E9384",
    background: "rgba(52, 88, 85, 0.08)",
  },
  secondary: {
    main: "#F87A54",
  },
  other: {
    border: "rgba(0,0,0,0.23)",
  },
  text: {
    primary: "#384250", // Gray 700
    secondary: "#6C737F", // Gray 500
  },
  error: {
    main: "#F44336",
    lightBg: "#F443361A",
  },
  success: {
    main: "#4CAF50",
    lightBg: "#4CAF501A",
  },
  warning: {
    main: "#FF9800",
    darkText: "#663D01",
    border: "rgba(255, 152, 0, 0.5)",
    lightBg: "#FF98001A",
  },
  gray: {
    25: "#FCFCFD",
    50: "#F9FAFB",
    100: "#F3F4F6",
    200: "#E5E7EB",
    300: "#D2D6DB",
    400: "#9DA4AE",
    500: "#6C737F",
    600: "#4D5761",
    700: "#384250",
    800: "#1F2A37",
    900: "#111927",
  },
} as const

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },
  typography: {
    fontFamily: ["Inter", "Helvetica", "Arial", "sans-serif"].join(","),
    overline: {
      fontWeight: 600,
      color: colors.text.secondary,
    },
    body2: {
      color: colors.text.secondary,
    },
    h6: {
      color: colors.text.primary,
      fontWeight: 600,
    },
    h5: {
      color: colors.text.primary,
      fontWeight: 600,
    },
    h4: {
      color: colors.text.primary,
      fontWeight: 600,
    },
    button: {
      fontWeight: 500,
    },
    subtitle1: {
      fontWeight: 500,
    },
    subtitle2: {
      fontWeight: 500,
    },
  },
  shadows: (() => {
    const shadows = [
      `none`,
      `0px 8px 16px 0px hsla(0, 0%, 0%, 0.06), 0px 1px 3px 0px hsla(0, 0%, 0%, 0.08)`,
      `0px 8px 24px 0px hsla(0, 0%, 0%, 0.16), 0px 1px 3px 0px hsla(0, 0%, 0%, 0.08)`,
    ]

    return [
      ...shadows,
      // MUI expects 25 shadows, so fill up to 25 the last element specified shadow
      ...Array.from({length: 25 - shadows.length}, () => shadows.slice(-1)),
    ] as Shadows
  })(),
  palette: colors as NonNullable<ThemeOptions["palette"]>,
  shape: {
    borderRadius: 8,
    borderRadiusString: "8px",
    mobileBottomNavHeight: "56px",
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: "8px",
        },
        outlined: {
          borderColor: grey[300],
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${grey[300]}`,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: grey[300],
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderColor: grey[300],
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          "& .MuiTypography-root": {fontWeight: 600},
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        /* inter-regular - latin */
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          src: local(''),
               url('/fonts/inter-v12-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
               url('/fonts/inter-v12-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
        }
        /* inter-500 - latin */
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 500;
          src: local(''),
               url('/fonts/inter-v12-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
               url('/fonts/inter-v12-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
        }
        /* inter-600 - latin */
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 600;
          src: local(''),
               url('/fonts/inter-v12-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
               url('/fonts/inter-v12-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
        }
        /* inter-700 - latin */
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 700;
          src: local(''),
               url('/fonts/inter-v12-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
               url('/fonts/inter-v12-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
        }
      `,
    },
  },
})

export default theme
