import {TaxonomicRank} from "generated/graphql"
import {useCallback, useEffect, useState} from "react"
import {useFormContext} from "react-hook-form"
import {IconButton} from "@hortis/ui/button"
import {Dialog, type DialogProps} from "src/components/dialog-mui"
import {HelpCircle, XClose} from "@hortis/ui/icons"
import {FormTextField} from "src/components/text-field"
import {Toggle} from "src/components/toggle"
import {usePreviousSync} from "src/utils/hooks/previous"
import {useToggle} from "src/utils/hooks/toggle"
import {type TaxonFields} from "./schema"

const AuthorshipHelpModal = ({
  onClose,
  ...props
}: DialogProps & {onClose: () => void}) => (
  <Dialog
    onClose={onClose}
    PaperProps={{className: "!max-w-[440px] !rounded-[12px]"}}
    {...props}
  >
    <div className="flex flex-col gap-5" data-cy="name-validation-modal">
      <div className="pr-10 text-lg font-medium text-grey-900">
        Override authorship
      </div>
      <IconButton
        ariaLabel="Close"
        className="!absolute !right-3 !top-3"
        variant="tertiaryGray"
        onClick={onClose}
        icon={XClose}
      />
      <p>
        Enable this option if you need to manually specify the authorship of a
        plant, particularly for cultivars or other cases not covered by our
        validated taxonomic database.{" "}
      </p>
      <p>
        By default, the overridden authorship will be shown in the data table.
        On the taxon record you will be able to see the overridden authorship as
        well as the validated authorship from our taxonomic database.
      </p>
    </div>
  </Dialog>
)

export const AuthorshipField = () => {
  const [helpModalOpen, toggleHelpModalOpen] = useToggle(false)
  const {watch, setValue} = useFormContext<TaxonFields>()
  const sharedScientificName = watch("sharedScientificName")
  const authorship = watch("authorship")
  const previousAuthorship = usePreviousSync(authorship)
  const nonGenusSharedName =
    sharedScientificName?.authorship != null &&
    sharedScientificName.rank !== TaxonomicRank.Genus
  const [overrideAuthorship, setOverrideAuthorship] = useState(
    authorship != null,
  )

  const toggleOverrideAuthorship = useCallback(() => {
    if (overrideAuthorship) {
      setValue("authorship", null)
    }
    setOverrideAuthorship(!overrideAuthorship)
  }, [overrideAuthorship, setValue])

  useEffect(() => {
    if (authorship != null || previousAuthorship != null) {
      return
    }
    setOverrideAuthorship(!nonGenusSharedName)
  }, [
    sharedScientificName,
    setValue,
    nonGenusSharedName,
    authorship,
    previousAuthorship,
  ])

  return (
    <div className="flex flex-col gap-1.5">
      <FormTextField
        fullWidth
        name="authorship"
        id="authorship"
        label="Authorship"
        testId="authorship"
        disabled={!overrideAuthorship && nonGenusSharedName}
        InputProps={
          !overrideAuthorship && sharedScientificName?.authorship != null
            ? {value: sharedScientificName.authorship}
            : undefined
        }
        sx={{
          "&.MuiOutlinedInput-root": {
            paddingRight: "6px !important",
          },
        }}
        endAdornment={
          nonGenusSharedName && (
            <IconButton
              icon={<HelpCircle size="18px" />}
              onClick={toggleHelpModalOpen}
              ariaLabel="Help"
              variant="tertiaryGray"
              size="xs"
              testId="authorship-help-button"
            />
          )
        }
      />
      <AuthorshipHelpModal open={helpModalOpen} onClose={toggleHelpModalOpen} />
      {nonGenusSharedName && (
        /* eslint-disable-next-line jsx-a11y/label-has-associated-control -- Using child input */
        <label className="flex items-center gap-2 text-sm font-medium">
          <Toggle
            checked={overrideAuthorship}
            onChange={toggleOverrideAuthorship}
            testId="override-authorship-toggle"
          />
          Override authorship
        </label>
      )}
    </div>
  )
}
