import * as _Ord from "fp-ts/Ord"
import {flow} from "fp-ts/function"
import * as t from "io-ts"
import type {PositiveInteger} from "../positive-integer"
import {throwOnValidationError} from "../validation"

interface MaterialQualifierBrand {
  readonly MaterialQualifier: unique symbol
}
const materialQualifierRegex = /^\d{2}$/
export const MaterialQualifier = t.brand(
  t.string,
  (s): s is t.Branded<string, MaterialQualifierBrand> =>
    materialQualifierRegex.test(s) && s !== "00",
  "MaterialQualifier",
)
export type MaterialQualifier = t.TypeOf<typeof MaterialQualifier>
export const assertAsMaterialQualifier = flow(
  MaterialQualifier.decode,
  throwOnValidationError,
)

export const fromPositiveInteger = (n: PositiveInteger): MaterialQualifier => {
  if (n < 10) {
    return `0${n}` as MaterialQualifier
  }

  if (n < 100) {
    return n.toString() as MaterialQualifier
  }

  return "99" as MaterialQualifier
}
