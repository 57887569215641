import {isFuture} from "date-fns"
import {z} from "zod"
import {Condition, Period} from "generated/graphql"
import type {Nullable} from "src/types/nullable"

export const followUpSchema = z.object({
  count: z.number().min(1),
  period: z.nativeEnum(Period),
})

export const makeObservationSchema = z.object({
  lastObserved: z
    .date({
      required_error: "Please select a valid date",
      invalid_type_error: "Please select a valid date",
    })
    .refine((date) => !isFuture(date), {
      message: "Date cannot be in the future",
    }),
  condition: z.nativeEnum(Condition).optional().nullable(),
  followUp: followUpSchema,
})

export type MakeObservationSchema = z.TypeOf<typeof makeObservationSchema>
export type MakeObservationFormSchema = Nullable<MakeObservationSchema>
