import {zodResolver} from "@hookform/resolvers/zod"
import {pipe} from "@mobily/ts-belt"
import {useEffect, useMemo} from "react"
import {FormProvider, useForm} from "react-hook-form"
import TimeAgo from "react-timeago"
import type {PlantMaterial} from "generated/graphql"
import {Period, useUpdatePlantMaterialMutation} from "generated/graphql"
import {Button} from "@hortis/ui/button"
import {BasicDatePicker} from "src/components/date-picker"
import {useSnackbarStore} from "src/components/snackbar-controller/snackbar-store"
import {Tooltip} from "src/components/tooltip"
import {onFailure, onSuccess} from "src/notification-snack-utils"
import {timeAgoFormatter} from "src/features/records/material/components/material-images-tab/material-image-subinfo"
import type {Nullable} from "src/types/nullable"
import {convertDatesToISO} from "src/utils/convert-dates-to-iso"
import {useDateStringFormatter} from "src/utils/hooks/language"
import {ClockRewind} from "@hortis/ui/icons"
import {colors} from "src/colors"
import {conditionLabelMap} from "src/utils/label-maps/condition"
import {DialogContent} from "@hortis/ui/dialog"
import {FormSelect} from "../../select"
import {makeObservationSchema} from "./schema"
import type {MakeObservationFormSchema} from "./schema"
import {OverdueFollowupAlert} from "./overdue-followup"
import {followUpOptions} from "./follow-up-options"
import {conditionOptions} from "./condition-options"

type MakeObservationModalProps = {
  onClose: () => void
  materialId: string
  defaultValues?: Nullable<
    Partial<Pick<PlantMaterial, "followUp" | "lastObserved" | "condition">>
  >
  onSubmit?: () => void
}

export const MakeObservationDialogContent = ({
  onClose,
  materialId,
  defaultValues,
  onSubmit,
}: MakeObservationModalProps) => {
  const {setSnack} = useSnackbarStore()
  const [, updateMaterial] = useUpdatePlantMaterialMutation()
  const formatDateTime = useDateStringFormatter({
    dateStyle: "medium",
  })
  const formMethods = useForm<MakeObservationFormSchema>({
    defaultValues: {
      followUp: {
        count: defaultValues?.followUp?.count ?? 1,
        period: defaultValues?.followUp?.period ?? Period.Years,
      },
      lastObserved: new Date(),
    },
    resolver: zodResolver(makeObservationSchema),
  })

  useEffect(() => {
    formMethods.reset({
      followUp: {
        count: defaultValues?.followUp?.count ?? 1,
        period: defaultValues?.followUp?.period ?? Period.Years,
      },
      lastObserved: new Date(),
    })
  }, [defaultValues, formMethods])

  const handleSubmit = useMemo(
    () =>
      formMethods.handleSubmit(async (data) => {
        try {
          const res = await updateMaterial({
            materialId,
            updates: pipe(data, convertDatesToISO),
          })

          if (res.data?.updatePlantMaterial?.__typename === "PlantMaterial") {
            onSuccess(setSnack)("Successfully submitted observation")
            if (onSubmit != null) {
              onSubmit()
            }
            onClose()
          } else {
            throw new Error("Failed to submit observation")
          }
        } catch {
          onFailure(setSnack)(new Error("Failed to submit observation"))
        }
      }),
    [formMethods, updateMaterial, materialId, onClose, setSnack, onSubmit],
  )

  return (
    <DialogContent>
      <FormProvider {...formMethods}>
        <form
          className="flex flex-col gap-8"
          onSubmit={handleSubmit}
          data-cy="make-observation-form"
        >
          <div className="flex flex-col gap-5">
            <div className="absolute right-4 top-4 sm:right-6 sm:top-5">
              <OverdueFollowupAlert
                lastObserved={defaultValues?.lastObserved}
                followUp={defaultValues?.followUp}
              />
            </div>
            <div className="flex flex-col gap-2">
              <div className="pr-24 text-lg font-medium text-grey-900">
                New observation
              </div>
              {defaultValues?.lastObserved != null && (
                <p
                  className="text-sm text-grey-500"
                  data-cy="last-observed-date"
                >
                  <Tooltip
                    title={formatDateTime(defaultValues.lastObserved)}
                    placement="top"
                  >
                    <div className="flex gap-2">
                      <ClockRewind size="20" color={colors.grey[500]} />
                      <span>
                        <TimeAgo
                          date={defaultValues.lastObserved}
                          formatter={timeAgoFormatter}
                          style={{display: "inline-block"}}
                        />
                        {` (${
                          defaultValues.condition == null
                            ? "no condition"
                            : conditionLabelMap[defaultValues.condition]
                        })`}
                      </span>
                    </div>
                  </Tooltip>
                </p>
              )}
            </div>
            <div className=" flex flex-col gap-4">
              <div className="flex flex-col gap-4 sm:flex-row">
                <FormSelect
                  name="condition"
                  options={conditionOptions}
                  testId="condition-select"
                  id="condition-select"
                  label="Condition"
                  className="flex-1"
                />
                <BasicDatePicker
                  name={"lastObserved"}
                  label={"Date"}
                  testId="last-observed-picker"
                  className="flex-1"
                  required
                />
              </div>

              <FormSelect
                options={followUpOptions}
                name="followUp"
                label="Follow-up"
                id="observation-period"
                testId="follow-up-select"
              />
            </div>
          </div>
          <div className="flex items-center gap-4">
            <Button css={{flex: 1}} onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="primary"
              containerCss={{flex: 1}}
              type="submit"
              loading={formMethods.formState.isSubmitting}
              data-cy="submit-observation"
            >
              Submit
            </Button>
          </div>
        </form>
      </FormProvider>
    </DialogContent>
  )
}
