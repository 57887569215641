import * as Apply from "fp-ts/Apply"
import * as E from "fp-ts/Either"
import {constant, flow, pipe} from "fp-ts/function"
import * as O from "fp-ts/Option"
import * as OE from "../option-either"
import {useCollectionSiteSlug} from "./collection-site-slug"
import {useOrganisationSubdomainName} from "./organisation-subdomain-name"

const seqOption = Apply.sequenceS(O.Apply)

const InvalidPlace = {
  type: "NO_PLACE_IDENTIFIED" as const,
}
type InvalidPlace = typeof InvalidPlace

// TODO: indicate which part of the route is missing - org subdomain or collection site path?
export const expectPlace = flow(
  O.map(seqOption),
  O.map(E.fromOption(constant(InvalidPlace))),
  OE.fromOptionEither,
)

export const usePlace = () =>
  expectPlace(
    seqOption({
      orgName: useOrganisationSubdomainName(),
      siteSlug: useCollectionSiteSlug(),
    }),
  )

export const usePlaceBasic = () => pipe(usePlace(), OE.noneAndErrorToUndefined)
export const usePlaceStruct = () =>
  pipe(
    usePlace(),
    OE.mapLeft((err) => new Error(err.type)),
    OE.toStruct,
  )

const InvalidOrgSubdomain = {
  type: "NO_ORG_SUBDOMAIN_IDENTIFIED" as const,
}
type InvalidOrgSubdomain = typeof InvalidOrgSubdomain

export const expectOrganisation = flow(
  O.map(E.fromOption(constant(InvalidOrgSubdomain))),
  OE.fromOptionEither,
)

export const useOrganisationSubdomain = () =>
  expectOrganisation(useOrganisationSubdomainName())

export const useOrganisationSubdomainBasic = () =>
  pipe(useOrganisationSubdomain(), OE.noneAndErrorToUndefined)
export const useOrganisationSubdomainStruct = () =>
  pipe(
    useOrganisationSubdomain(),
    OE.mapLeft((err) => new Error(err.type)),
    OE.toStruct,
  )

export const OrganisationSiteNotFound = {
  type: "ORGANISATION_NOT_FOUND" as const,
}
type OrganisationSiteNotFound = typeof OrganisationSiteNotFound

export const CollectionSiteNotFound = {
  type: "COLLECTION_SITE_NOT_FOUND" as const,
}
export type CollectionSiteNotFound = typeof CollectionSiteNotFound

type StringLiteralInferred<Name extends string> = Name extends Exclude<
  string,
  Name
>
  ? Name
  : never

const noOrg = OE.fromNullableToError(OrganisationSiteNotFound)
export const extractOrgFromKey =
  <Key extends string>(key: StringLiteralInferred<Key>) =>
  <Org>(val: {[K in Key]?: Org | null}) =>
    noOrg(val[key])
export const extractOrg = extractOrgFromKey("org")

const noSite = OE.fromNullableToError(CollectionSiteNotFound)
export const extractSiteFromKey =
  <Key extends string>(key: StringLiteralInferred<Key>) =>
  <Site>(val: {[K in Key]?: Site | null}) =>
    noSite(val[key])
export const extractSite = extractSiteFromKey("site")

export const extractSiteFromOrgWithKeys = <
  OrgKey extends string,
  SiteKey extends string,
>(
  orgKey: StringLiteralInferred<OrgKey>,
  siteKey: StringLiteralInferred<SiteKey>,
): (<Site>(val: {
  [K in OrgKey]?: {[K in SiteKey]?: Site | null} | null
}) => OE.OptionEither<
  OrganisationSiteNotFound | CollectionSiteNotFound,
  Site
>) =>
  flow(
    extractOrgFromKey<OrgKey>(orgKey),
    OE.chainW(extractSiteFromKey<SiteKey>(siteKey)),
  )

export const extractSiteFromOrg = extractSiteFromOrgWithKeys("org", "site")
