import type {ProvenanceCategoryCode, ProvenanceFilter} from "generated/graphql"
import {provenanceLabelMap} from "src/utils/label-maps/provenance"
import type {FilterOperation} from "../../plant-materials"

export const extractProvenanceFilterData = (
  filter: NonNullable<ProvenanceFilter>,
  operation: FilterOperation,
): Array<string> | undefined => {
  const isFilters = filter.or
    ?.flatMap(({eq}) => eq)
    .filter((val): val is ProvenanceCategoryCode => val != null)
  const isNotFilters = filter.or
    ?.flatMap(({neq}) => neq)
    .filter((val): val is ProvenanceCategoryCode => val != null)

  switch (operation) {
    case "is": {
      return isFilters?.map((status) => provenanceLabelMap[status])
    }
    case "isNot": {
      return isNotFilters?.map((status) => provenanceLabelMap[status])
    }
    default: {
      return undefined
    }
  }
}
