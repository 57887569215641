import {Button, IconButton} from "@hortis/ui/button"
import {colors} from "@hortis/ui/colors"
import {Edit5} from "@hortis/ui/icons"
import {Link} from "@tanstack/react-router"
import {MaterialGroup, type MaterialFieldsFragment} from "generated/graphql"
import {Layer, Source} from "react-map-gl"
import {
  circleRadiusStyle,
  circleStrokeWidthStyle,
} from "src/components/map/material-clusters/material-cluster-layers"
import {createMaterialNumber} from "src/features/collection/components/plant-materials/material-number"
import {
  RecordField,
  RecordFieldLabel,
  RecordFieldValue,
} from "src/features/records/components/record-field/new-record-field"
import {
  RecordSection,
  RecordSectionContent,
  RecordSectionContentGroup,
  RecordSectionHeader,
  RecordSectionHeaderButtons,
  RecordSectionTitle,
} from "src/features/records/components/record-section/new-record-section"
import {MaterialEditStageCallback} from "src/features/records/material/components/edit-drawer/types"
import {ControlledMap} from "src/features/records/material/components/material-title-card/controlled-map"
import {twMerge} from "tailwind-merge"

export const MaterialRecordLocation = ({
  material,
  hideMap,
  requestEdit,
  canEdit,
  onShowOnMap,
}: {
  material: MaterialFieldsFragment
  hideMap?: boolean
  requestEdit?: () => void
  canEdit: boolean
  onShowOnMap?: () => void
}) => {
  const editDetails = MaterialEditStageCallback.useEditDetails(
    requestEdit == null ? () => null : requestEdit,
  )
  return material.materialGroup === MaterialGroup.Plant ? (
    <RecordSection id="location" className="material-record-section">
      <RecordSectionHeader>
        <RecordSectionTitle>Location</RecordSectionTitle>
        <RecordSectionHeaderButtons>
          {material.position != null &&
            material.accession != null &&
            onShowOnMap == null && (
              <Button
                size="xs"
                testId="view-on-map"
                variant="tertiaryGray"
                asChild
              >
                <Link
                  to="/sites/$siteSlug/map/material/$materialNumber"
                  from="/sites/$siteSlug"
                  params={{
                    materialNumber: createMaterialNumber(
                      material.accession.accessionNumber,
                      material.qualifier,
                    ),
                  }}
                  search={{
                    latLng: {
                      lng: material.position.longitude,
                      lat: material.position.latitude,
                    },
                    zoom: 19,
                  }}
                >
                  Open on map
                </Link>
              </Button>
            )}
          {onShowOnMap != null && (
            <Button
              size="xs"
              testId="view-on-map"
              variant="tertiaryGray"
              onClick={onShowOnMap}
            >
              Open on map
            </Button>
          )}
          {canEdit && !material.archived && (
            <IconButton
              onClick={editDetails}
              ariaLabel="Edit section"
              data-cy="section-edit-button"
              size="xs"
              variant="tertiaryGray"
              icon={Edit5}
            />
          )}
        </RecordSectionHeaderButtons>
      </RecordSectionHeader>

      <RecordSectionContent>
        <RecordSectionContentGroup
          className={twMerge(hideMap !== true && "grid")}
        >
          <>
            <RecordSectionContentGroup>
              <RecordField data-cy="record-collectionSiteLocation">
                <RecordFieldLabel>Name</RecordFieldLabel>
                <RecordFieldValue>
                  {material.collectionSiteLocation?.name ?? "–"}
                </RecordFieldValue>
              </RecordField>
              <RecordField>
                <RecordFieldLabel>Description</RecordFieldLabel>
                <RecordFieldValue>
                  {material.collectionSiteLocation?.description ?? "–"}
                </RecordFieldValue>
              </RecordField>
              <RecordField data-cy="record-coordinates">
                <RecordFieldLabel>Coordinates</RecordFieldLabel>
                <RecordFieldValue>
                  {material.position?.latitude != null &&
                  material.position.longitude
                    ? `${material.position.latitude.toFixed(
                        5,
                      )}, ${material.position.longitude.toFixed(5)}`
                    : "-"}
                </RecordFieldValue>
              </RecordField>
            </RecordSectionContentGroup>
            {hideMap !== true && material.position != null && (
              <div className="h-44" data-cy="record-map">
                <ControlledMap
                  latitude={material.position.latitude}
                  longitude={material.position.longitude}
                  staticMap
                  cursor="default"
                >
                  <Source
                    id="map-geometry-2"
                    type="geojson"
                    data={{
                      type: "Feature",
                      properties: {},
                      geometry: {
                        type: "Point",
                        coordinates: [
                          Number(material.position.longitude),
                          Number(material.position.latitude),
                        ],
                      },
                    }}
                  >
                    <Layer
                      id="unclustered-point-orange"
                      type="circle"
                      source="map-geometry-2"
                      filter={["!", ["has", "point_count"]]}
                      paint={{
                        "circle-opacity": 1,
                        "circle-stroke-opacity": 0.9,
                        "circle-color": colors.yellow[400],
                        "circle-radius": circleRadiusStyle,
                        "circle-stroke-width": circleStrokeWidthStyle,
                        "circle-stroke-color": colors.white,
                      }}
                    />
                  </Source>
                </ControlledMap>
              </div>
            )}
          </>
        </RecordSectionContentGroup>
      </RecordSectionContent>
    </RecordSection>
  ) : null
}
