import * as E from "fp-ts/Either"
import type * as Eq from "fp-ts/Eq"
import {pipe} from "fp-ts/function"
import type * as IO from "fp-ts/IO"
import type * as IOO from "fp-ts/IOOption"
import * as O from "fp-ts/Option"
import type * as TE from "fp-ts/TaskEither"
import {useEffect, useReducer, useState} from "react"
import type * as OE from "./option-either"

/**
 * Turns a side effecting nullary function into a hook
 * that runs once using useEffect, on mount.
 * Returns an Option, which will be empty on first render, before the effect runs.
 * @example
 * ```typescript
 * const useDate = stateFromEffect(() => new Date())
 * // () => Option<Date>
 * ```
 */
export const stateFromEffect = <A,>(effect: IO.IO<A>): IOO.IOOption<A> =>
  function useStateFromEffect() {
    const [state, setState] = useState<O.Option<A>>(O.none)
    useEffect(() => {
      setState(O.some(effect()))
    }, [])
    return state
  }

const shallowEq: Eq.Eq<unknown> = {equals: Object.is}

/**
 * This reducer compares the previous state and the new one using the provided Eq,
 * and if they match, exploits React's identity equality to bail out of a re-render
 */
const pickNewStateGiven = <A,>(eq: Eq.Eq<A>) =>
  pipe(
    O.getEq(eq),
    (eq) => (state: O.Option<A>, newValue: O.Option<A>) =>
      eq.equals(state, newValue) ? state : newValue,
  )

/**
 * Turns an n-ary function that returns a side effecting function into a hook
 * that runs using useEffect, whenever the args change identity.
 * Returns an Option, which will be empty on first render, before the effect runs.
 * @example
 * ```typescript
 * const useDate = stateFromEffectK((key: string) => () => window.localStorage.get(key))
 * // (key: string) => Option<string>
 * ```
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const stateFromEffectK = <Args extends Array<any>, B>(
  effectK: (...args: Args) => IO.IO<B>,
  eq: Eq.Eq<B> = shallowEq,
) => {
  const pickNewState = pickNewStateGiven(eq)
  return function useStateFromEffectK(...args: Args) {
    const [state, setState] = useReducer(pickNewState, O.none)
    useEffect(() => {
      setState(O.some(effectK(...args)()))
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, args)
    return state
  }
}

/**
 * Turns an n-ary impure function into a hook
 * that runs using useEffect, whenever the args change identity.
 * Returns an Option, which will be empty on first render, before the effect runs.
 * @example
 * ```typescript
 * const useDate = stateFromImpure((key: string) => window.localStorage.get(key))
 * // (key: string) => Option<string>
 * ```
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const stateFromImpure = <Args extends Array<any>, B>(
  impure: (...args: Args) => B,
  eq: Eq.Eq<B> = shallowEq,
) => {
  const pickNewState = pickNewStateGiven(eq)
  return function useStateFromImpure(...args: Args) {
    const [state, setState] = useReducer(pickNewState, O.none)
    useEffect(() => {
      setState(O.some(impure(...args)))
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, args)
    return state
  }
}

const shallowEitherEq = E.getEq(shallowEq, shallowEq)

/**
 * Turns a side effecting nullary function that returns a Promise that can fail into a hook
 * that runs once using useEffect, on mount.
 * Returns an Option of an Either, which will be empty on first render, before the effect runs.
 * @example
 * ```typescript
 * const useFetch = stateFromAsyncEffect(() => makeRequest('https://example.org'))
 * // () => Option<Either<FailedRequest, Response>>
 * ```
 */
export const stateFromAsyncEffect = <L, R>(
  asyncEffect: TE.TaskEither<L, R>,
  eq: Eq.Eq<E.Either<L, R>> = shallowEitherEq,
): IO.IO<OE.OptionEither<L, R>> => {
  const pickNewState = pickNewStateGiven(eq)
  return function useStateFromTaskEither() {
    const [state, setState] = useReducer(pickNewState, O.none)

    useEffect(() => {
      // Fixes the following:
      // Warning: Can't perform a React state update on an unmounted component.
      let extant = true

      void asyncEffect().then((value) => {
        if (extant) {
          setState(O.some(value))
        }
        return undefined
      })
      return () => {
        extant = false
      }
    }, [])
    return state
  }
}

/**
 * Turns an n-ary function that returns a side effecting function that returns a Promise that can fail into a hook
 * that runs once using useEffect, whenever the args change identity.
 * Returns an Option of an Either, which will be empty on first render, before the effect runs.
 * @example
 * ```typescript
 * const useFetch = stateFromAsyncEffectK((url: string) => () => makeRequest(url))
 * // (url: string) => Option<Either<FailedRequest, Response>>
 * ```
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const stateFromAsyncEffectK = <Args extends Array<any>, L, R>(
  asyncEffectK: (...args: Args) => TE.TaskEither<L, R>,
  eq: Eq.Eq<E.Either<L, R>> = shallowEitherEq,
) => {
  const pickNewState = pickNewStateGiven(eq)
  return function useStateFromTaskEitherK(...args: Args) {
    const [state, setState] = useReducer(pickNewState, O.none)

    useEffect(() => {
      // Fixes the following:
      // Warning: Can't perform a React state update on an unmounted component.
      let extant = true

      void asyncEffectK(...args)().then((value) => {
        if (extant) {
          setState(O.some(value))
        }
        return undefined
      })
      return () => {
        extant = false
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, args)
    return state
  }
}
