import {Tag} from "@hortis/ui/tag"
import type {ScientificNameFieldsFragment} from "generated/graphql"
import {stopPropagation} from "src/features/collection/components/stop-event-propagation"
import {NameSourceButton} from "src/features/taxonomy/components/taxon-page/taxonomy/source-buttons"
import {
  taxonomicStatusColorMap,
  taxonomicStatusTextColorMap,
} from "src/features/taxonomy/components/taxon-page/taxonomy/taxonomy"
import {ValuePair} from "src/features/taxonomy/components/taxon-page/value-pair"
import {taxonomicRankLabelMap} from "src/utils/label-maps/taxonomic-rank"
import {CheckVerified1} from "@hortis/ui/icons"
import {Popover, PopoverContent, PopoverTrigger} from "."

export interface NameValidationPopoverProps {
  sharedScientificName: Pick<
    ScientificNameFieldsFragment,
    | "authorship"
    | "nameSource"
    | "nameSourceUrl"
    | "rank"
    | "scientificName"
    | "status"
  >
  fullyValidated: boolean
}

export const NameValidationPopover = ({
  sharedScientificName,
  fullyValidated,
}: NameValidationPopoverProps) => {
  return (
    <Popover>
      <PopoverTrigger onClick={stopPropagation} className="w-fit">
        <Tag
          data-cy="name-validation-tag"
          size="sm"
          className={taxonomicStatusTextColorMap[sharedScientificName.status]}
          spacing="icon"
        >
          <CheckVerified1
            size="16px"
            color={taxonomicStatusColorMap[sharedScientificName.status]}
          />
          {taxonomicRankLabelMap[sharedScientificName.rank]}
        </Tag>
      </PopoverTrigger>
      <PopoverContent
        className="z-20 flex !min-w-[316px] !max-w-[400px] flex-col gap-2 overflow-hidden"
        align="start"
        onClick={stopPropagation}
      >
        <div className="flex flex-1 items-center justify-between gap-2 p-4 pb-2 text-sm font-medium text-grey-900">
          <div className="flex items-center gap-2">
            <CheckVerified1 size="18px" className="stroke-grey-500" />
            Validation
          </div>
          <NameSourceButton
            nameSource={sharedScientificName.nameSource}
            url={sharedScientificName.nameSourceUrl}
          />
        </div>
        <div className="max-w-full overflow-y-auto whitespace-pre-line break-words p-4 pt-0 text-sm">
          <div className="flex flex-col gap-3 truncate">
            <ValuePair label="Scientific name" breakPoint="mobile">
              <p className="truncate" data-cy="validated-scientific-name">
                {sharedScientificName.scientificName}
              </p>
            </ValuePair>
            <ValuePair label="Authorship" breakPoint="mobile">
              <p className="truncate" data-cy="validated-authorship">
                {sharedScientificName.authorship}
              </p>
            </ValuePair>
            <ValuePair label="Validation level" breakPoint="mobile">
              <p className="truncate" data-cy="validation-level">
                {fullyValidated ? "Full" : "Partial"}
              </p>
            </ValuePair>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  )
}
