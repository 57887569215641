import {Breadcrumb, BreadcrumbItem, BreadcrumbList} from "@hortis/ui/breadcrumb"
import {IconButton} from "@hortis/ui/button"
import {ContextMenu, ContextMenuTrigger} from "@hortis/ui/context-menu"
import {useMediaQuery} from "@hortis/ui/hooks/media-query"
import {Download1, FilterLines, Plus} from "@hortis/ui/icons"
import {R, pipe} from "@mobily/ts-belt"
import {Link, useNavigate, useSearch} from "@tanstack/react-router"
import {useGetTaxaQuery} from "generated/graphql"
import type {ReactElement} from "react"
import {useCallback, useMemo, useRef, useState} from "react"
import {Helmet} from "react-helmet-async"
import {List} from "src/components"
import {
  DataTable,
  DataTableFooter,
  DataTableRow,
  DataTableToolbar,
} from "src/components/data-table"
import {
  taxaColumns,
  trashTaxaColumns,
} from "src/components/data-table/columns/taxa-columns"
import {useTable} from "src/components/data-table/use-table"
import {ViewButton} from "src/components/data-table/view-button"
import {MobileDrawer} from "src/components/mobile-drawer"
import {
  TopBar,
  TopBarActionButtons,
  TopBarContent,
} from "src/components/nav-layout/topbar"
import {useTopbarStore} from "src/components/nav-layout/topbar/topbar-store"
import {Tooltip} from "src/components/tooltip"
import TaxaActionMenu from "src/features/actions-menu/variants/taxa-action-menu"
import {TrashTaxaDialog} from "src/features/collection/components/archive-records/trash-taxa-dialog"
import {useDownloadOrgCsv} from "src/features/collection/components/download-accessions/use-download-org-csv"
import {SearchBar} from "src/features/collection/components/search-bar/search-bar"
import {showErrorMessage} from "src/features/collection/components/show-error-message"
import {TaxaContextMenuContent} from "src/features/context-menu/variants/taxa-context-menu"
import {AddTaxaFilter} from "src/features/filters/taxa/add-taxa-filter"
import {TaxaMobileFilters} from "src/features/filters/taxa/mobile-filters"
import {TaxaFilterModals} from "src/features/filters/taxa/modals/filter-modals"
import {TaxaSortSelect} from "src/features/filters/taxa/sort-select"
import {TaxaFilters} from "src/features/filters/taxa/taxa-filters"
import {useTaxaFilters} from "src/features/filters/taxa/use-taxa-filters"
import {useAccessRole} from "src/features/permissions/use-access-role"
import {useMobile} from "src/utils/hooks/media-query"
import {useOrganisationSubdomainStruct} from "src/utils/hooks/place"
import {useFilterState} from "src/utils/hooks/tables/filter-state"
import {usePagination} from "src/utils/hooks/tables/pagination"
import {useRowClick} from "src/utils/hooks/tables/row-click"
import {useTableSearch} from "src/utils/hooks/tables/search"
import {getSelectedRowIds, useSelect} from "src/utils/hooks/tables/select-mode"
import {useSort} from "src/utils/hooks/tables/sort"
import {useToggle} from "src/utils/hooks/toggle"
import {createRelayParams} from "src/utils/pagination"
import {useAccessToken} from "src/utils/use-access-token"
import withAuthenticationRequired from "src/utils/with-authentication-required"
import {EmptyTaxaList} from "./empty-list"
import {TaxaMobileList} from "./mobile-list"
import {TaxaMoreButton} from "./more-button"
import {processTaxaQuery} from "./process-taxa-query"
import {taxaSearchMenuLabels} from "./search-labels"
import {
  defaultColumnVisibility,
  filterColumnMap,
  sortColumnMap,
} from "./table-config"

export const TaxaList = ({
  isTrash = false,
  persistenceKey = "taxa-page-filters",
}: {
  isTrash?: boolean
  persistenceKey?: string
  // eslint-disable-next-line sonarjs/cognitive-complexity
}): ReactElement => {
  const isMobile = useMobile({defaultMatches: false})
  const isDesktop = useMediaQuery("lg")
  const setTitle = useTopbarStore((state) => state.setTitle)
  const {canEdit} = useAccessRole()

  const pageTitle = isTrash ? "Taxa trash" : "Taxa"
  setTitle(pageTitle)

  const {
    filters,
    preparedFilters,
    dispatchFilters,
    filterCounts,
    resetFilters,
  } = useTaxaFilters({
    persistenceKey,
    initialState: isTrash
      ? {
          archived: {eq: true},
        }
      : undefined,
  })

  const accessToken = useAccessToken()
  const search = useSearch({strict: false})
  const cursor = "cursor" in search ? search.cursor : undefined
  const dir = "dir" in search ? search.dir : undefined
  const orderBy = "orderBy" in search ? search.orderBy : undefined
  const rows = "rows" in search ? search.rows : undefined
  const searchTerm = "search" in search ? search.search : undefined

  const navigate = useNavigate({
    from: isTrash ? "/_layout/taxonomy/trash" : "_layout/taxonomy",
  })
  const rowsPerPage = rows ?? 25

  const organisationSubdomain = useOrganisationSubdomainStruct()
  const pause =
    organisationSubdomain.data == null || accessToken._tag === "None"
  const [taxaQuery] = useGetTaxaQuery({
    variables: {
      ...createRelayParams({cursor, dir, rowsPerPage}),
      organisationSubdomain: organisationSubdomain.data ?? "",
      searchTerm,
      orderBy,
      includeArchived: isTrash,
      filter: preparedFilters,
    },
    pause,
  })

  const loading = (taxaQuery.fetching && taxaQuery.data == null) || pause

  const {error, data} = pipe(
    processTaxaQuery({taxaQuery, organisationSubdomain}),
    R.match(
      (data): {data: typeof data | null; error: string | null} => ({
        data,
        error: null,
      }),
      (error) => ({error, data: null}),
    ),
  )

  const handleRowsChange = useCallback(
    (rows: number | null) => {
      void navigate({search: (prev) => ({...prev, rows: rows ?? undefined})})
    },
    [navigate],
  )

  const dataMemo = useMemo(() => data?.nodes ?? [], [data?.nodes])

  const {table, ref, tableRef, mobileListRef} = useTable({
    columns: isTrash ? trashTaxaColumns : taxaColumns,
    data: dataMemo,
    tableId: "taxonomy-table",
    defaultColumnVisibility,
  })

  const {nextPageLoading, previousPageLoading, onPreviousPage, onNextPage} =
    usePagination({
      navigate,
      pageInfo: data?.pageInfo,
      cursor,
      fetching: taxaQuery.fetching,
      tableEl: tableRef,
      mobileListEl: mobileListRef.current,
    })

  const {selectMode, toggleSelectMode} = useSelect({
    resetRowSelection: table.resetRowSelection,
    filterCounts,
    sort: table.getState().sorting,
    search: searchTerm,
  })

  const {handleSearchClear, handleSearchSubmit, handleClearSearchAndFilters} =
    useTableSearch({
      navigate,
      search: searchTerm,
      resetSelectedRows: table.resetRowSelection,
      resetFilters,
    })

  const {
    updateOrderBy,
    viewMenuOpen,
    toggleViewMenuOpen,
    updateOrderByFromViewMenu,
  } = useSort({
    navigate,
    sorting: table.getState().sorting,
    resetSorting: table.resetSorting,
    setSorting: table.setSorting,
    orderBy,
    sortColumnMap,
  })

  const [trashDialogOpen, setTrashDialogOpen] = useState(false)
  const [actionsMenuOpen, toggleActionsMenuOpen] = useToggle()
  const trashButtonRef = useRef<HTMLButtonElement | null>(null)

  const selectedRecordsIDs = getSelectedRowIds(table.getState().rowSelection)

  const onRowClick = useRowClick<(typeof dataMemo)[number]>({
    urlFn: ({id}) => `/taxonomy/${id}`,
  })
  const handleOrgDownload = useDownloadOrgCsv({
    csvName: "taxa",
    path: "/download/taxa",
    body: {
      searchTerm,
      filter: {
        ...preparedFilters,
        id: {
          in: selectedRecordsIDs,
        },
      },
    },
  })

  const {
    openedFilter,
    filterButtonRef,
    handleFilterModalClose,
    setOpenFilter,
    openedFilterToolbar,
  } = useFilterState({
    filterColumnMap,
    resetColumnFilters: table.resetColumnFilters,
    columnFilters: table.getState().columnFilters,
  })

  const [mobileFiltersExpanded, toggleMobileFiltersExpanded] = useToggle()

  const emptyState = useMemo(
    () => (
      <EmptyTaxaList
        isTrash={isTrash}
        list={data?.nodes ?? undefined}
        searchTerm={searchTerm}
        clearSearch={handleClearSearchAndFilters}
        filtersActive={filterCounts.total > 0}
      />
    ),
    [
      isTrash,
      data?.nodes,
      searchTerm,
      handleClearSearchAndFilters,
      filterCounts.total,
    ],
  )

  return (
    <>
      {isDesktop && (
        <TopBar>
          <TopBarContent>
            <Breadcrumb>
              <BreadcrumbList>
                <BreadcrumbItem data-cy="page-title">
                  {pageTitle}
                </BreadcrumbItem>
              </BreadcrumbList>
            </Breadcrumb>
          </TopBarContent>
          <TopBarActionButtons>
            <Tooltip title="Download all taxa">
              <IconButton
                ariaLabel={"Download selected rows"}
                variant="secondaryGray"
                size="xs"
                className="disabled:bg-opacity-0"
                onClick={handleOrgDownload}
                icon={Download1}
                testId="download-selected-rows-button"
              />
            </Tooltip>
            {canEdit && !isTrash && (
              <Tooltip title="New taxon">
                <IconButton
                  icon={Plus}
                  variant="secondaryGray"
                  ariaLabel="New taxon"
                  size="xs"
                  asChild
                >
                  <Link to="/taxonomy/new" />
                </IconButton>
              </Tooltip>
            )}
          </TopBarActionButtons>
        </TopBar>
      )}

      <>
        <Helmet>
          <title>Taxonomy | Hortis</title>
        </Helmet>
        {error != null && !loading ? (
          showErrorMessage(new Error(error))
        ) : isMobile ? (
          <div className="flex h-full flex-col">
            <div className="flex flex-row items-center justify-end gap-2 border-b border-grey-200 p-4 px-3">
              <Tooltip title="Download all taxa">
                <IconButton
                  ariaLabel={"Download selected rows"}
                  variant="secondaryGray"
                  size="xs"
                  className="disabled:bg-opacity-0"
                  onClick={handleOrgDownload}
                  icon={Download1}
                  testId="download-selected-rows-button"
                />
              </Tooltip>
              {canEdit && !isTrash && (
                <Tooltip title="New taxon">
                  <IconButton
                    icon={Plus}
                    variant="secondaryGray"
                    ariaLabel="New taxon"
                    size="xs"
                    asChild
                  >
                    <Link to="/taxonomy/new" />
                  </IconButton>
                </Tooltip>
              )}
            </div>
            <List.Toolbar>
              <div className="flex items-center gap-2">
                <SearchBar
                  testId="search-form"
                  menuLabels={taxaSearchMenuLabels}
                  handleSubmitCallback={handleSearchSubmit}
                  handleClearCallback={handleSearchClear}
                  searchTermOverride={searchTerm}
                  className="flex-1"
                />
                <IconButton
                  icon={FilterLines}
                  ariaLabel="Filters"
                  testId="filters-button-mobile"
                  onClick={toggleMobileFiltersExpanded}
                  size="sm"
                />
                <MobileDrawer
                  testId="mobile-drawer"
                  open={mobileFiltersExpanded}
                  onClose={toggleMobileFiltersExpanded}
                  content={
                    <TaxaMobileFilters
                      filters={filters}
                      dispatchFilters={dispatchFilters}
                      resetFilters={resetFilters}
                      filterCount={filterCounts.total}
                      taxaType="org"
                      updateOrderBy={updateOrderBy}
                      orderBy={orderBy}
                    />
                  }
                />
                <TaxaMoreButton
                  searchTerm={searchTerm}
                  filter={preparedFilters}
                />
              </div>
            </List.Toolbar>
            <TaxaMobileList
              table={table}
              selectMode={selectMode}
              emptyState={emptyState}
            />
            <List.Footer
              selectedRowCount={selectedRecordsIDs.length}
              handleDownload={handleOrgDownload}
              onTrashRecords={() => {
                setTrashDialogOpen(true)
              }}
              selectAllRows={() => {
                const currentPageRecordsCount =
                  table.getSelectedRowModel().rows.length
                table.toggleAllPageRowsSelected(currentPageRecordsCount <= 0)
              }}
              onActionsMenu={toggleActionsMenuOpen}
              trashButtonRef={trashButtonRef}
              selectMode={selectMode}
              toggleSelectMode={toggleSelectMode}
              onPreviousPage={onPreviousPage}
              onNextPage={onNextPage}
              total={data?.taxaTotal}
              hasPreviousPage={data?.pageInfo.hasPreviousPage}
              hasNextPage={data?.pageInfo.hasNextPage}
              previousPageLoading={previousPageLoading}
              nextPageLoading={nextPageLoading}
              isTrash={isTrash}
            />
          </div>
        ) : (
          <div className="flex h-full flex-1 flex-col overflow-hidden">
            <TaxaFilterModals
              openFilter={openedFilterToolbar ?? openedFilter?.id ?? false}
              handleFilterModalClose={handleFilterModalClose}
              filterModalAnchorEl={
                openedFilterToolbar == null
                  ? openedFilter?.value
                  : filterButtonRef
              }
              dispatchFilters={dispatchFilters}
              taxaType="org"
            />
            <DataTableToolbar className="flex flex-col gap-4">
              <div className="flex flex-col gap-5">
                <div className="flex flex-1 items-center justify-between gap-3">
                  <SearchBar
                    testId="search-form"
                    menuLabels={taxaSearchMenuLabels}
                    handleSubmitCallback={handleSearchSubmit}
                    handleClearCallback={handleSearchClear}
                    searchTermOverride={searchTerm}
                    className="max-w-md flex-1"
                  />
                  <div className="flex items-center gap-3">
                    <AddTaxaFilter
                      setOpenFilter={setOpenFilter}
                      align="end"
                      buttonProps={{
                        size: "sm",
                        className: "",
                        testId: "add-filter-button",
                        startIcon: FilterLines,
                        ref: filterButtonRef,
                        children: "Filter",
                      }}
                    />
                    <ViewButton
                      table={table}
                      defaultColumnVisibility={defaultColumnVisibility}
                      open={viewMenuOpen}
                      toggleOpen={toggleViewMenuOpen}
                      aboveMenu={
                        <div className="border-b border-grey-200 px-4 py-4">
                          <TaxaSortSelect
                            fullWidth
                            buttonProps={{size: "xs"}}
                            orderBy={orderBy}
                            updateOrderBy={updateOrderByFromViewMenu}
                          />
                        </div>
                      }
                    />
                  </div>
                </div>
              </div>
            </DataTableToolbar>
            {filterCounts.total > 0 && (
              <DataTableToolbar className="flex border-t border-grey-200">
                <TaxaFilters
                  filters={filters}
                  dispatchFilters={dispatchFilters}
                  resetFilters={resetFilters}
                  filterCount={filterCounts.total}
                  updateOrderBy={updateOrderBy}
                  orderBy={orderBy}
                  taxaType="org"
                />
              </DataTableToolbar>
            )}
            <DataTable
              table={table}
              ref={ref}
              loading={loading}
              emptyState={emptyState}
            >
              {table.getRowModel().rows.map((row) => (
                <ContextMenu key={row.id}>
                  <ContextMenuTrigger asChild>
                    <DataTableRow
                      data-cy="table-row"
                      key={row.id}
                      row={row}
                      onClick={(e) => {
                        onRowClick(e, row.original)
                      }}
                    />
                  </ContextMenuTrigger>
                  <TaxaContextMenuContent
                    isOrg
                    isTrash={isTrash}
                    taxSearch={searchTerm}
                    preparedFilters={preparedFilters}
                    clickedRow={row.original}
                    selectedRecordsIDs={selectedRecordsIDs}
                    onRequestDownload={handleOrgDownload}
                  />
                </ContextMenu>
              ))}
            </DataTable>
            <DataTableFooter
              table={table}
              onPreviousPage={onPreviousPage}
              onNextPage={onNextPage}
              total={data?.taxaTotal}
              hasPreviousPage={data?.pageInfo.hasPreviousPage}
              hasNextPage={data?.pageInfo.hasNextPage}
              onRequestDownload={handleOrgDownload}
              onActionsMenu={toggleActionsMenuOpen}
              onTrashRecords={() => {
                setTrashDialogOpen(true)
              }}
              trashButtonRef={trashButtonRef}
              previousPageLoading={previousPageLoading}
              nextPageLoading={nextPageLoading}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleRowsChange}
              testIdPrefix="taxa"
              fetching={taxaQuery.fetching}
            />
          </div>
        )}
        {selectedRecordsIDs.length > 0 && (
          <>
            <TrashTaxaDialog
              setOpen={setTrashDialogOpen}
              open={trashDialogOpen}
              taxaIds={selectedRecordsIDs}
              triggerRef={trashButtonRef}
              onSuccess={() => {
                table.resetRowSelection()
                toggleSelectMode()
              }}
            />
            <TaxaActionMenu
              isOrg
              selectedRecordsIDs={selectedRecordsIDs}
              open={actionsMenuOpen}
              toggleOpen={toggleActionsMenuOpen}
              onRequestDownload={handleOrgDownload}
              openTrashRecordModal={setTrashDialogOpen}
            />
          </>
        )}
      </>
    </>
  )
}

export const Component = withAuthenticationRequired(TaxaList, {
  onRedirecting: function OnRedirect() {
    return <TaxaList />
  },
})
