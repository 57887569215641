import {type TaxonOption} from "src/features/taxonomy/components/new-taxon/schema"

export const mapTaxonOptionToId = <
  T extends {
    taxonOption: TaxonOption
  },
>({
  taxonOption,
  ...rest
}: T) => ({
  ...rest,
  taxonId: taxonOption.id,
})
