import {Slot} from "@radix-ui/react-slot"
import {forwardRef} from "react"
import {twMerge} from "tailwind-merge"
import {ChevronRight, DotsHorizontal} from "../icons"

const Breadcrumb = forwardRef<
  HTMLElement,
  React.ComponentPropsWithoutRef<"nav"> & {
    separator?: React.ReactNode
  }
>(({...props}, ref) => <nav ref={ref} aria-label="breadcrumb" {...props} />)
// @ts-expect-error -- Reclared forwardRef does not have a displayName
Breadcrumb.displayName = "Breadcrumb"

const BreadcrumbList = forwardRef<
  HTMLOListElement,
  React.ComponentPropsWithoutRef<"ol">
>(({className, ...props}, ref) => (
  <ol
    ref={ref}
    className={twMerge(
      "text-grey-500 flex flex-wrap items-center gap-x-1 gap-y-2 text-sm font-medium",
      className,
    )}
    {...props}
  />
))
// @ts-expect-error -- Reclared forwardRef does not have a displayName
BreadcrumbList.displayName = "BreadcrumbList"

const BreadcrumbItem = forwardRef<
  HTMLLIElement,
  React.ComponentPropsWithoutRef<"li">
>(({className, ...props}, ref) => (
  <li
    ref={ref}
    className={twMerge(
      "[&>*]:!text-grey-500 cursor-default items-center gap-1.5 truncate [&>*]:!text-sm [&>*]:!font-medium",
      className,
    )}
    {...props}
  />
))
// @ts-expect-error -- Reclared forwardRef does not have a displayName
BreadcrumbItem.displayName = "BreadcrumbItem"

const BreadcrumbLink = forwardRef<
  HTMLAnchorElement,
  React.ComponentPropsWithoutRef<"a"> & {
    asChild?: boolean
  }
>(({asChild, className, ...props}, ref) => {
  const Comp = asChild === true ? Slot : "a"
  return (
    <Comp
      ref={ref}
      className={twMerge("hover:text-grey-700 transition-colors", className)}
      {...props}
    />
  )
})
// @ts-expect-error -- Reclared forwardRef does not have a displayName
BreadcrumbLink.displayName = "BreadcrumbLink"

const BreadcrumbSeparator = ({
  className,
  children,
  ...props
}: React.ComponentProps<"li">) => (
  <li aria-hidden="true" className={className} {...props}>
    {children ?? <ChevronRight className="text-grey-400 h-3 w-3" />}
  </li>
)
BreadcrumbSeparator.displayName = "BreadcrumbSeparator"

const BreadcrumbEllipsis = ({
  className,
  ...props
}: React.ComponentProps<"span">) => (
  <span
    aria-hidden="true"
    className={twMerge("flex items-center justify-center", className)}
    {...props}
  >
    <DotsHorizontal className="h-4 w-4" />
  </span>
)
BreadcrumbEllipsis.displayName = "BreadcrumbElipssis"

export {
  Breadcrumb,
  BreadcrumbEllipsis,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
}
