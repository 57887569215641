import {useCallback, useMemo} from "react"
import type {Dispatch} from "react"
import type {PlantMaterialFilterAction} from "../filter-reducer"
import {FilterActionTypes} from "../filter-reducer"
import type {LocalMaterialFilter} from "../types"

export const useDispatchFilter = ({
  dispatchFilters,
  id,
  onClose,
}: {
  dispatchFilters: Dispatch<PlantMaterialFilterAction>
  id: string | undefined
  onClose: () => void
}) => {
  const addModifyFilter = useCallback(
    (filter: LocalMaterialFilter) => {
      if (id == null) {
        dispatchFilters({
          type: FilterActionTypes.ADD_FILTER,
          payload: filter,
        })
      } else {
        dispatchFilters({
          type: FilterActionTypes.MODIFY_FILTER,
          payload: {...filter, id},
        })
      }
    },
    [dispatchFilters, id],
  )

  const deleteFilter = useMemo(
    () =>
      id == null
        ? undefined
        : () => {
            dispatchFilters({
              type: FilterActionTypes.DELETE_FILTER,
              payload: {id},
            })
            onClose()
          },
    [id, dispatchFilters, onClose],
  )

  return {addModifyFilter, deleteFilter}
}
