import {z} from "zod"
import {
  AccessionsColumn,
  AccessionsSearchColumn,
  PlantMaterialsColumn,
  PlantMaterialsSearchColumn,
  SortDirection,
  TaxaColumn,
  TaxaSearchColumn,
} from "generated/graphql"
import {PageDirection} from "src/utils/pagination"

const tab = z
  .enum(["materials", "accessions", "taxa"])
  .optional()
  .catch("materials")
export type CollectionTab = z.infer<typeof tab>

export const collectionSchema = z.object({
  tab,
  matCursor: z.coerce.string().optional().catch(undefined),
  matDir: z.nativeEnum(PageDirection).optional().catch(PageDirection.Next),
  matSearch: z
    .object({
      value: z.coerce.string(),
      field: z.nativeEnum(PlantMaterialsSearchColumn),
    })
    .optional()
    .catch(undefined),
  matOrderBy: z
    .object({
      field: z.nativeEnum(PlantMaterialsColumn),
      direction: z.nativeEnum(SortDirection),
    })
    .optional()
    .catch(undefined),
  accCursor: z.coerce.string().optional().catch(undefined),
  accDir: z.nativeEnum(PageDirection).optional().catch(PageDirection.Next),
  accSearch: z
    .object({
      value: z.coerce.string(),
      field: z.nativeEnum(AccessionsSearchColumn),
    })
    .optional()
    .catch(undefined),
  accOrderBy: z
    .object({
      field: z.nativeEnum(AccessionsColumn),
      direction: z.nativeEnum(SortDirection),
    })
    .optional()
    .catch(undefined),
  taxCursor: z.coerce.string().optional().catch(undefined),
  taxDir: z.nativeEnum(PageDirection).optional().catch(PageDirection.Next),
  taxSearch: z
    .object({
      value: z.coerce.string(),
      field: z.nativeEnum(TaxaSearchColumn),
    })
    .optional()
    .catch(undefined),
  taxOrderBy: z
    .object({
      field: z.nativeEnum(TaxaColumn),
      direction: z.nativeEnum(SortDirection),
    })
    .optional()
    .catch(undefined),
  rows: z.coerce.number().optional().catch(undefined),
})
