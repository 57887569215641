import {type ReactNode} from "react"
import create from "zustand"

interface TopBarState {
  title: ReactNode | null
  setTitle: (title: ReactNode) => void
}

export const useTopbarStore = create<TopBarState>((set) => ({
  title: null,
  // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
  setTitle: (title) => set({title}),
}))
