import {flow} from "fp-ts/function"
import * as RA from "fp-ts/ReadonlyArray"
import {PlantMaterialStatus} from "../../../../../../generated/graphql"

const isNonAbsent = (status: PlantMaterialStatus) =>
  status !== PlantMaterialStatus.Absent

export const countNonAbsent = flow(RA.filter(isNonAbsent), RA.size)

export const getMaterialSwitchLabel = flow(countNonAbsent, (count) =>
  count === 1
    ? "Change 1 plant material to absent"
    : `Change ${count} plant materials to absent`,
)
