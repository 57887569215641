import type {IconProps} from "./types"

export const SlPlantIcon = ({
  style,
  width,
  height,
  color,
  className,
}: IconProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width ?? "24"}
    height={height ?? "24"}
    viewBox="0 0 24 24"
    fill="none"
    style={style}
    className={className}
  >
    <path
      d="M14.26 7.76c0 2.31-2.26 4.19-2.26 4.19s-2.25-1.88-2.25-4.19C9.75 5.45 12 3.57 12 3.57s2.26 1.88 2.26 4.2ZM18.14 18.15c-2.33 2.33-6.14 2.3-6.14 2.3s-.04-3.81 2.3-6.14c2.33-2.34 6.14-2.3 6.14-2.3s.03 3.81-2.3 6.14Z"
      stroke={color ?? "#345855"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.86 18.15c2.33 2.33 6.14 2.3 6.14 2.3s.04-3.81-2.3-6.14C7.37 11.97 3.56 12 3.56 12s-.03 3.81 2.3 6.14ZM12 11.95v8.5"
      stroke={color ?? "#345855"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
