import {ButtonGroup} from "@hortis/ui/button/button-group"
import {LoadingPage} from "@hortis/ui/loading"
import {Outlet, createFileRoute} from "@tanstack/react-router"
import {
  PlantMaterialsSearchColumn,
  useGetMapConfigQuery,
  type PlantMaterialsOrderByInput,
} from "generated/graphql"
import {useEffect, useMemo, useState} from "react"
import {Helmet} from "react-helmet-async"
import {MapProvider} from "react-map-gl"
import {CenterCollection} from "src/components/map/actions/center-collection"
import {CenterCurrentPosition} from "src/components/map/actions/center-current-position"
import {MapZoomButtons} from "src/components/map/actions/map-zoom-buttons"
import {SelectLayers} from "src/components/map/actions/select-layers"
import {CypressMapObserver} from "src/components/map/cypress-map-observer"
import {BaseMapLayers} from "src/components/map/layers/base-map-layers"
import {CoordinateMenu} from "src/components/map/material-clusters/coordinate-menu"
import {themedHighlightedUnclusteredPointLayer} from "src/components/map/material-clusters/material-cluster-layers"
import {MaterialPopup} from "src/components/map/material-clusters/material-popup"
import {useClusters} from "src/components/map/material-clusters/use-clusters"
import {MapOverlay} from "src/components/map/overlays/map-overlay"
import {NoInitialViewMapOverlay} from "src/components/map/overlays/no-initial-view-map-overlay"
import {SetInitialViewMapOverlay} from "src/components/map/overlays/set-initial-view-map-overlay"
import {useThemedLayer} from "src/components/map/theme-layer-hook"
import {useMaplibre} from "src/components/map/utils"
import {CenterOverlay} from "src/features/collection-map/components/center-overlay"
import CustomOverlay from "src/features/collection-map/components/custom-overlay"
import {EditDetailsOverlay} from "src/features/collection-map/components/edit-details-overlay"
import {FiltersOverlay} from "src/features/collection-map/components/filters-overlay/filters-overlay"
import {MaterialListOverlay} from "src/features/collection-map/components/material-list/material-list-overlay"
import {ShowListButton} from "src/features/collection-map/components/overlay-ui/buttons"
import {NewMaterialDrawer} from "src/features/collection-map/components/overlay-ui/new-material-drawer"
import {OverlayWithSearch} from "src/features/collection-map/components/overlay-ui/search-row"
import {useGeometryLayers} from "src/features/collection-map/components/use-geometry-layers"
import {useLatLongZoom} from "src/features/collection-map/components/use-lat-long-zoom"
import {useMapPadding} from "src/features/collection-map/components/use-map-padding"
import {useMapSearch} from "src/features/collection-map/components/use-map-search"
import {MapContext, useMapStore} from "src/features/collection-map/map-store"
import {useUpdateGeography} from "src/features/collection-map/fetchers"
import {mapId} from "src/features/collection-map/utils"
import {createMaterialNumber} from "src/features/collection/components/plant-materials/material-number"
import {useMaterialFilters} from "src/features/filters/plant-materials/use-material-filters"
import {useAccessRole} from "src/features/permissions/use-access-role"
import {useMobile} from "src/utils/hooks/media-query"
import withAuthenticationRequired from "src/utils/with-authentication-required"
import {match} from "ts-pattern"
import {z} from "zod"
import {Map} from "../../../../components/map"

const schema = z.object({
  searchValue: z.coerce.string().optional(),
  searchField: z
    .nativeEnum(PlantMaterialsSearchColumn)
    .optional()
    .catch(PlantMaterialsSearchColumn.ScientificName),
  latLng: z
    .object({lat: z.number(), lng: z.number()})
    .optional()
    .catch(undefined),
  zoom: z.number().optional().catch(undefined),
})

export const Route = createFileRoute("/_layout/sites/$siteSlug/map")({
  component: withAuthenticationRequired(WrappedComponent, {
    onRedirecting: function OnRedirect() {
      return <WrappedComponent />
    },
  }),
  validateSearch: (search) => schema.parse(search),
})

function WrappedComponent() {
  return (
    <MapProvider>
      <Component />
    </MapProvider>
  )
}

function Component() {
  const {[mapId]: ourMap} = useMaplibre()
  const isMobile = useMobile()
  const {searchTerm, handleSubmit, handleClear} = useMapSearch()
  const materialFilters = useMaterialFilters({
    initialState: {
      excludeAbsentMaterial: true,
    },
  })
  const [geometry, updateGeography] = useUpdateGeography(
    searchTerm,
    materialFilters.preparedFilters,
  )
  const {subdomain} = Route.useRouteContext()
  const {siteSlug} = Route.useParams()
  const navigate = Route.useNavigate()
  const [{data, fetching, error}, refetchConfig] = useGetMapConfigQuery({
    variables: {organisationSubdomain: subdomain, collectionSiteSlug: siteSlug},
  })

  const key = data?.org?.map?.key
  const site = data?.org?.site
  const initialViewState = useMemo(
    () =>
      site?.mapCenterLongitude != null && site.mapCenterLatitude != null
        ? {
            latitude: site.mapCenterLatitude,
            longitude: site.mapCenterLongitude,
            zoom: site.mapZoomLevel ?? undefined,
          }
        : undefined,
    [site],
  )
  const baseMap = data?.org?.site?.baseMap ?? null

  const store = useMapStore({
    updateGeography,
    refetchSite: refetchConfig,
  })

  useEffect(() => {
    if (site != null && initialViewState == null) {
      store.openMissingInitialView()
    }
  }, [site, initialViewState, store])

  const {
    popupProps,
    handleClick,
    clusterLayer: [clusterLayer, clusterConfig],
    clusterCountLayer: [clusterCountLayer, clusterCountConfig],
    cursor,
    onMouseMove,
    onMouseEnter,
    onMouseLeave,
  } = useClusters({
    mapId,
    onPointClick: (material) => {
      if (material != null) {
        void navigate({
          // eslint-disable-next-line sonarjs/no-duplicate-string
          from: "/sites/$siteSlug/map",
          to: "/sites/$siteSlug/map/material/$materialNumber",
          params: {
            materialNumber: createMaterialNumber(
              material.accessionNumber,
              material.materialQualifier,
            ),
          },
        })
      }
    },
    closeMaterialDetails: () => {
      void navigate({to: "/sites/$siteSlug/map", from: "/sites/$siteSlug/map"})
    },
    ...store,
  })

  const {updateLatLng} = useLatLongZoom({ourMap})

  const [highlightedLayer, highlightedProps] = useThemedLayer(
    useMemo(() => {
      return themedHighlightedUnclusteredPointLayer(
        store.matchedRoute.route === "material" &&
          store.matchedRoute.number != null
          ? {
              accessionNumber: store.matchedRoute.number.accessionNumber,
              materialQualifier: store.matchedRoute.number.qualifier,
            }
          : undefined,
      )
    }, [store.matchedRoute]),
    "highlighted-layer",
    store.mode === "editMaterial" ? 0.5 : undefined,
  )

  const [geometryLayers, interactiveLayerIds] = useGeometryLayers({
    geometry,
    layers: [clusterLayer, clusterCountLayer, highlightedLayer],
    layerConfigs: [clusterConfig, clusterCountConfig, highlightedProps],
    coordinateMenuProps: store.coordinateMenuProps,
  })

  const padding = useMapPadding({
    drawerCollapsed: !store.sidebarOpen,
    mode: store.mode,
  })
  const [orderBy, setOrderBy] = useState<
    PlantMaterialsOrderByInput | undefined
  >()

  const {canEdit} = useAccessRole()
  const [listCursor, setListCursor] = useState<string | null>(null)

  const hasValidData = site != null && key != null

  return (
    <MapContext.Provider value={store}>
      <div
        className="relative flex h-[calc(calc(var(--vh,_1vh)_*_100)_-_56px)] min-h-full flex-1 flex-col lg:h-[calc(var(--vh,_1vh)_*_100)] [&_.mapboxgl-map]:flex-1 [&_.mapboxgl-map]:overflow-visible [&_.mapboxgl-popup-content]:!rounded-xl [&_.mapboxgl-popup-content]:bg-[none] [&_.mapboxgl-popup-content]:p-0 [&_.mapboxgl-popup-content]:shadow-none [&_.mapboxgl-popup-content]:animate-in [&_.mapboxgl-popup-content]:fade-in-10 [&_.mapboxgl-popup-content]:slide-in-from-bottom-2 [&_.mapboxgl-popup-tip]:opacity-0 [&_.mapboxgl-popup]:!max-w-sm [&_.mapboxgl-popup]:bg-[none] [&_.maplibregl-control-container]:font-sans [&_.maplibregl-control-container]:font-medium [&_.maplibregl-ctrl-attrib-button]:bottom-0 [&_.maplibregl-ctrl-attrib-button]:top-auto [&_.maplibregl-ctrl-attrib]:max-w-44 [&_.maplibregl-ctrl-attrib]:rounded-full [&_.maplibregl-ctrl-attrib]:!bg-white/70 [&_.maplibregl-ctrl-attrib]:backdrop-blur [&_.maplibregl-ctrl-attrib]:sm:max-w-none [&_.maplibregl-ctrl-bottom-right]:bottom-1 [&_.maplibregl-ctrl-bottom-right]:right-1"
        data-cy="map-container"
      >
        <Helmet>
          <title>Map | Hortis</title>
        </Helmet>
        {!hasValidData && fetching && <LoadingPage />}
        {!hasValidData && error != null && <>Error: {error.message}</>}
        {hasValidData && (
          <Map
            mapStyle={`https://api.maptiler.com/maps/basic/style.json?key=${key}`}
            attributionControl={false}
            initialViewState={{
              padding,
              ...initialViewState,
              ...(store.latLng == null
                ? {}
                : {latitude: store.latLng.lat, longitude: store.latLng.lng}),
              ...(store.zoom == null ? {} : {zoom: store.zoom}),
            }}
            id={mapId}
            onClick={handleClick}
            interactiveLayerIds={interactiveLayerIds}
            onMouseMove={isMobile ? undefined : onMouseMove}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            cursor={cursor}
            onMoveEnd={updateLatLng}
          >
            <CypressMapObserver testIdPrefix="collection-map-observer" />
            {popupProps != null && <MaterialPopup {...popupProps} />}
            <BaseMapLayers baseMap={baseMap} />
            {(store.mode === "view" || store.mode === "editMaterial") &&
              geometryLayers}
            {match(store.mode)
              // eslint-disable-next-line sonarjs/cognitive-complexity
              .with("view", () => (
                <MapOverlay>
                  {store.coordinateMenuProps != null && (
                    <CoordinateMenu
                      requestNewMaterial={store.openNewMaterial}
                      closeCoordinateMenu={store.closeCoordinateMenu}
                      {...store.coordinateMenuProps}
                    />
                  )}
                  <MaterialListOverlay
                    open={
                      store.matchedRoute.route !== "material" &&
                      store.sidebarOpen
                    }
                    cursor={listCursor}
                    setCursor={setListCursor}
                    searchTerm={searchTerm}
                    handleSearchSubmit={(value, field) => {
                      setListCursor(null)
                      handleSubmit(value, field)
                    }}
                    handleSearchClear={(field) => {
                      setListCursor(null)
                      handleClear(field)
                    }}
                    setDrawerCollapsed={(collapsed) => {
                      if (collapsed) {
                        store.collapseSidebar()
                      } else {
                        store.expandSidebar()
                      }
                    }}
                    materialFilters={materialFilters}
                    orderBy={orderBy}
                    setOrderBy={setOrderBy}
                  />
                  <FiltersOverlay
                    setOpen={(open) => {
                      if (open) {
                        store.openFilters()
                      } else {
                        store.closeFilters()
                      }
                    }}
                    materialFilters={materialFilters}
                    open={store.filtersOpen}
                  />
                  {store.matchedRoute.route === "material" && <Outlet />}
                  <div className="flex flex-1 flex-col p-3 lg:p-4">
                    <div className="pointer-events-none flex flex-1 flex-col items-stretch gap-4 lg:flex-row lg:items-start lg:justify-between">
                      {isMobile && (
                        <OverlayWithSearch
                          searchTerm={searchTerm}
                          handleSearchSubmit={handleSubmit}
                          handleSearchClear={handleClear}
                          toggleFilters={() => {
                            if (store.filtersOpen) {
                              store.closeFilters()
                            } else {
                              store.openFilters()
                            }
                          }}
                          materialFilters={materialFilters}
                        />
                      )}
                      {!isMobile && !store.sidebarOpen && (
                        <ShowListButton
                          onClick={() => {
                            if (store.sidebarOpen) {
                              store.collapseSidebar()
                            } else {
                              store.expandSidebar()
                            }
                          }}
                          testId="show-list-button"
                        />
                      )}
                      <div className="hidden flex-1 lg:block" />
                      <div className="flex flex-col gap-2 self-end md:gap-4 lg:self-auto">
                        <SelectLayers baseMap={baseMap} />
                        <ButtonGroup
                          direction="column"
                          className="shadow-mg pointer-events-auto border-none"
                        >
                          {initialViewState == null ? undefined : (
                            <CenterCollection {...initialViewState} />
                          )}
                          <CenterCurrentPosition />
                        </ButtonGroup>
                        <MapZoomButtons />
                      </div>
                    </div>
                    <div className="flex justify-between gap-3">
                      {isMobile && (
                        <ShowListButton
                          onClick={() => {
                            if (store.sidebarOpen) {
                              store.collapseSidebar()
                            } else {
                              store.expandSidebar()
                            }
                          }}
                          testId="show-list-button-mobile"
                        />
                      )}
                    </div>
                  </div>
                  {canEdit && (
                    <NewMaterialDrawer
                      open={store.newMaterialDrawerOpen}
                      onOpenChange={(open: boolean) => {
                        if (!open) {
                          store.cancelNewMaterial()
                        }
                      }}
                      // eslint-disable-next-line react/jsx-handler-names
                      onSubmit={store.confirmNewMaterial}
                      initialValues={{
                        position:
                          store.newMaterialCoordinates == null
                            ? null
                            : {
                                latitude: String(
                                  store.newMaterialCoordinates.latitude,
                                ),
                                longitude: String(
                                  store.newMaterialCoordinates.longitude,
                                ),
                              },
                      }}
                    />
                  )}
                </MapOverlay>
              ))
              .with("editMaterial", () => (
                <MapOverlay className="flex-1 p-3 md:flex-col lg:p-4">
                  <CustomOverlay>
                    <CenterOverlay />
                  </CustomOverlay>
                  <div className="pointer-events-none flex flex-1 flex-col items-end gap-2 self-end md:gap-4 lg:self-auto">
                    <SelectLayers baseMap={baseMap} />
                    <ButtonGroup
                      direction="column"
                      className="shadow-mg border-none"
                    >
                      {initialViewState == null ? undefined : (
                        <CenterCollection {...initialViewState} />
                      )}
                      <CenterCurrentPosition />
                    </ButtonGroup>
                    <MapZoomButtons />
                  </div>
                  {store.editingMaterial != null && (
                    <EditDetailsOverlay
                      editingMaterial={store.editingMaterial}
                      cancelEdit={store.cancelEdit}
                      confirmEdit={store.confirmEdit}
                    />
                  )}
                </MapOverlay>
              ))
              .with("noInitialView", () => (
                <NoInitialViewMapOverlay
                  setInitialView={store.setInitialView}
                />
              ))
              .with("setInitialView", () => (
                <SetInitialViewMapOverlay
                  confirmInitialView={() => {
                    void store.confirmInitialView(site.id)
                  }}
                  baseMap={baseMap}
                />
              ))
              .exhaustive()}
          </Map>
        )}
      </div>
    </MapContext.Provider>
  )
}
