import {AttributionControl} from "maplibre-gl"
import {useEffect, useRef} from "react"
import {useMaplibre} from "src/components/map/utils"
import {match} from "ts-pattern"
import {useGetMapConfigQuery} from "generated/graphql"
import {useParams, useRouteContext} from "@tanstack/react-router"
import {mapId} from "../utils"
import {useMapOptionsStore} from "./map-options-store"

export const useMapAttribution = () => {
  const {subdomain} = useRouteContext({from: "/_layout/sites/$siteSlug"})
  const {siteSlug} = useParams({from: "/_layout/sites/$siteSlug"})
  const [{data}] = useGetMapConfigQuery({
    variables: {organisationSubdomain: subdomain, collectionSiteSlug: siteSlug},
  })
  const {[mapId]: ourMap} = useMaplibre()
  const attributonControl = useRef(new AttributionControl())
  const baseMap = data?.org?.site?.baseMap

  const {layerType} = useMapOptionsStore()
  useEffect(() => {
    if (ourMap != null) {
      if (ourMap.hasControl(attributonControl.current)) {
        ourMap.removeControl(attributonControl.current)
      }
      attributonControl.current = new AttributionControl({
        customAttribution: match(layerType)
          .with("bing", () => "Microsoft® Bing™ Maps")
          .with("custom", () => baseMap?.attribution ?? undefined)
          .otherwise(() => undefined),
      })
      ourMap.addControl(attributonControl.current, "bottom-right")
    }
  }, [ourMap, layerType, baseMap])
}
