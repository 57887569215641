import type {NonEmptyString} from "io-ts-types"
import {useCallback, useReducer} from "react"
import {useFormContext} from "react-hook-form"
import {createPlaceholderAccessionId} from "../../../utils/generate-accession-number"
import type {Fields} from "../accession-data-form"

const toggle = (state: boolean) => !state

export const useGeneratedAccessionNumber = () => {
  const {getValues, setValue, clearErrors} = useFormContext<Fields>()

  const [isGenerated, setIsGenerated] = useReducer(
    toggle,
    getValues("generateAccessionNumber"),
  )

  const onToggleChange = useCallback(() => {
    setIsGenerated()
    setValue("generateAccessionNumber", !isGenerated)

    // clear accessionNumber value when opting out of generated number
    // this will visually prompt the user that the field is now active
    const defaultAccessionNumber = isGenerated
      ? ("" as NonEmptyString)
      : // The cast is required here as the front end is handling the validation
        // against non-empty accession numbers. However, the rest of the validation
        // is handled by the API. Ideally we would move the non-empty validation to
        // the API as well, and the accession number can just be of type `string`.
        createPlaceholderAccessionId(getValues("accessionDate"))

    setValue("accessionNumber", defaultAccessionNumber)
    clearErrors("accessionNumber")
  }, [clearErrors, isGenerated, setValue, getValues])

  const onDateChange = useCallback(
    (currentAccessionDate: Date | null) => {
      // Only set accessionNumber value when use selected generated accessionNumber
      // this value will be omitted when sent to the server as isGenerated is true
      if (isGenerated && currentAccessionDate != null) {
        setValue(
          "accessionNumber",
          createPlaceholderAccessionId(currentAccessionDate),
        )
      }
    },
    [setValue, isGenerated],
  )

  return {isGenerated, onToggleChange, onDateChange}
}
