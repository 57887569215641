import {gql} from "graphql-request"
import {CORE_PAGE_INFO_FIELDS} from "../collection/graphql"
import {CORE_MATERIAL_IMAGE_FIELDS} from "../records/material/graphql"

export const BASE_MAP_FIELDS = gql`
  fragment BaseMapFields on BaseMap {
    id
    name
    url
    attribution
    minZoom
    maxZoom
  }
`

export const GET_MAP_CONFIG = gql`
  ${BASE_MAP_FIELDS}
  query GetMapConfig(
    $organisationSubdomain: String!
    $collectionSiteSlug: String!
  ) {
    org: organisationBySubdomain(subdomain: $organisationSubdomain) {
      map {
        key
      }
      site: collectionSiteBySlug(slug: $collectionSiteSlug) {
        id
        name
        urlSlug
        mapCenterLatitude
        mapCenterLongitude
        mapZoomLevel
        baseMap {
          ...BaseMapFields
        }
      }
    }
  }
`

export const GET_COLLECTION_GEOMETRY = gql`
  ${BASE_MAP_FIELDS}
  query GetCollectionGeometry(
    $organisationSubdomain: String!
    $collectionSiteSlug: String!
    $filters: PlantMaterialFilter
    $searchTerm: PlantMaterialsSearchTerm
  ) {
    org: organisationBySubdomain(subdomain: $organisationSubdomain) {
      site: collectionSiteBySlug(slug: $collectionSiteSlug) {
        name
        baseMap {
          ...BaseMapFields
        }

        plantMaterialsGeometry(searchTerm: $searchTerm, filters: $filters) {
          type
          features {
            type
            geometry
            properties {
              id
              adHoc
            }
          }
        }
      }
    }
  }
`

export const GET_COLLECTION_GEOMETRY_WITHIN_CIRCLE = gql`
  query GetCollectionGeometryWithinCircle(
    $organisationSubdomain: String!
    $collectionSiteSlug: String!
    $searchTerm: PlantMaterialsSearchTerm
    $bounds: Circle!
  ) {
    org: organisationBySubdomain(subdomain: $organisationSubdomain) {
      site: collectionSiteBySlug(slug: $collectionSiteSlug) {
        name
        plantMaterialsGeometryWithinCircleWIP(
          searchTerm: $searchTerm
          bounds: $bounds
        ) {
          type
          features {
            type
            geometry
            properties {
              adHoc
            }
          }
        }
      }
    }
  }
`

export const MAP_MATERIAL_LIST_FIELDS = gql`
  ${CORE_MATERIAL_IMAGE_FIELDS}
  fragment MapMaterialListFields on PlantMaterial {
    id
    qualifier
    status
    materialGroup
    public
    collectionSiteLocation {
      id
      name
      code
      archived
    }
    position {
      latitude
      longitude
      elevation
    }
    accession {
      accessionNumber
      scientificName
      taxon {
        scientificName
      }
      provenanceCategoryCode
    }
    tagsConnection {
      nodes {
        id
        name
      }
    }
    primaryImage {
      ...MaterialImageFields
    }
    images {
      ...MaterialImageFields
    }
  }
`

export const GET_MAP_LIST_MATERIALS = gql`
  ${MAP_MATERIAL_LIST_FIELDS}
  ${CORE_PAGE_INFO_FIELDS}
  query MapListMaterials(
    $organisationSubdomain: String!
    $collectionSiteSlug: String!
    $orderBy: PlantMaterialsOrderByInput
    $before: String
    $after: String
    $first: Int
    $last: Int
    $filters: PlantMaterialFilter
    $searchTerm: PlantMaterialsSearchTerm
  ) {
    org: organisationBySubdomain(subdomain: $organisationSubdomain) {
      site: collectionSiteBySlug(slug: $collectionSiteSlug) {
        total: plantMaterialTotal(filters: $filters, searchTerm: $searchTerm)
        result: plantMaterials(
          orderBy: $orderBy
          before: $before
          after: $after
          first: $first
          last: $last
          filters: $filters
          searchTerm: $searchTerm
        ) {
          nodes {
            ...MapMaterialListFields
          }
          pageInfo {
            ...PageInfoFields
          }
        }
      }
    }
  }
`
