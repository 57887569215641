import type {RelationExistsComparator} from "generated/graphql"

export const countRelationExistsComparator = (
  filter: NonNullable<RelationExistsComparator>,
) => {
  let count = 0
  if (filter.eq === true) {
    count += 1
  }
  if (filter.neq === true) {
    count += 1
  }
  return count
}
