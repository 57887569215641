import CircularProgress from "@mui/material/CircularProgress"
import {constant, pipe} from "fp-ts/function"
import {cloneElement} from "react"
import type {LayerProps} from "react-map-gl"
import {Layer, Source} from "react-map-gl"
import {colors} from "src/colors"
import type {CoordinateMenuProps} from "src/components/map/material-clusters/coordinate-menu"
import {
  circleRadiusStyle,
  circleStrokeWidthStyle,
} from "src/components/map/material-clusters/material-cluster-layers"
import type {Mutable} from "../../../utils/mutable"
import {filterNonNullable} from "../../../utils/nullable"
import * as OE from "../../../utils/option-either"
import type {useUpdateGeography} from "../fetchers"
import {CurrentPositionLayer} from "./current-position-layer"
import {useMapOptionsStore} from "./map-options-store"
import {useHoveredListMaterialStore} from "./hovered-list-material-store"

const loading = (
  <div className="absolute bottom-16 right-3 z-50 flex items-center gap-3 rounded-lg border border-grey-100 bg-white px-4 py-3 text-sm font-medium shadow-sm md:bottom-4 md:right-4">
    <CircularProgress size="16px" />
    Loading points
  </div>
)

type GeometryLayer = [layer: JSX.Element, interactiveLayerIds: Array<string>]
const geometryLoading: () => GeometryLayer = constant([loading, []])

const geometryFailed: (error: unknown) => GeometryLayer = constant([
  // eslint-disable-next-line react/jsx-key -- false positive!
  <div>Geometry could not be loaded!</div>,
  [],
])

const coordinateLayerProps: LayerProps = {
  id: "coordinate-point",
  type: "circle",
  paint: {
    "circle-stroke-opacity": 0.9,
    "circle-color": colors.grey[400],
    "circle-radius": circleRadiusStyle,
    "circle-stroke-width": circleStrokeWidthStyle,
    "circle-stroke-color": colors.white,
  },
}

const dehortusLayer = (layer: "1" | "2" | "3" | "4" | "5") =>
  // eslint-disable-next-line no-secrets/no-secrets
  `https://gis.botanicalsoftware.com/arcgisadmin/rest/services/AMD/DeHortus202105/MapServer/${layer}/query?outFields=*&where=1%3D1&f=geojson`

const HardcodedDehortus = ({visible}: {visible: boolean}) => (
  <Source
    id={`dehortus-extra-geometry`}
    type="geojson"
    data={dehortusLayer("5")}
  >
    <Layer
      {...{
        id: "dehortus-grass",
        type: "fill",
        source: "dehortus",
        paint: {
          "fill-color": "#EAEAEA",
          "fill-opacity": 1,
        },
        filter: ["==", "$type", "Polygon"],
        layout: {
          visibility: visible ? "visible" : "none",
        },
      }}
    />
  </Source>
)

export const useGeometryLayers = ({
  geometry,
  layers,
  layerConfigs,
  beforeId,
  coordinateMenuProps,
  cluster = true,
}: {
  geometry: ReturnType<typeof useUpdateGeography>[0]
  layers: Array<JSX.Element | undefined>
  layerConfigs: Array<LayerProps | undefined>
  beforeId?: string
  cluster?: boolean
  coordinateMenuProps?: CoordinateMenuProps | null
}) => {
  const {devicePositionLayerEnabled, layerType} = useMapOptionsStore()
  const {hoveredMaterial} = useHoveredListMaterialStore()
  const [geometryLayers, interactiveLayerIds]: GeometryLayer = pipe(
    geometry,
    OE.match(geometryLoading, geometryFailed, ({siteName, geometry}) => [
      <>
        <HardcodedDehortus
          visible={
            (siteName === "De Hortus" || siteName === "Demo") &&
            layerType === "osm"
          }
        />
        <Source
          id="geometry"
          type="geojson"
          data={geometry as Mutable<typeof geometry>}
          cluster={cluster}
          clusterRadius={50}
          generateId
        >
          <Layer
            id="unclustered-point"
            type="circle"
            source="geometry"
            filter={["!", ["has", "point_count"]]}
            paint={{
              "circle-color": [
                "case",
                [
                  "any",
                  ["boolean", ["feature-state", "hover"], false],
                  ["==", ["get", "id"], hoveredMaterial?.id ?? null],
                ],
                colors.primary[400],
                colors.primary[700],
              ],
              "circle-radius": circleRadiusStyle,
              "circle-stroke-width": circleStrokeWidthStyle,
              "circle-stroke-color": colors.white,
            }}
          />
          {layers.map((layer) =>
            layer == null ? null : cloneElement(layer, {beforeId}),
          )}
        </Source>
        <Source
          id="coordinate-point"
          type="geojson"
          data={{
            type: "FeatureCollection",
            features:
              coordinateMenuProps == null
                ? []
                : [
                    {
                      type: "Feature",
                      properties: {},
                      geometry: {
                        type: "Point",
                        coordinates: [
                          coordinateMenuProps.longitude,
                          coordinateMenuProps.latitude,
                        ],
                      },
                    },
                  ],
          }}
        >
          <Layer {...coordinateLayerProps} />
        </Source>
        {devicePositionLayerEnabled && <CurrentPositionLayer />}
      </>,
      [
        ...filterNonNullable(layerConfigs.map((config) => config?.id)),
        "unclustered-point",
      ],
    ]),
  )

  return [geometryLayers, interactiveLayerIds] as const
}
