import * as LabelPrimitive from "@radix-ui/react-label"
import {cva, type VariantProps} from "class-variance-authority"
import {forwardRef} from "react"
import {twMerge} from "tailwind-merge"

const labelVariants = cva(
  "block text-sm font-medium text-grey-700 peer-disabled:cursor-not-allowed peer-disabled:text-grey-400",
)

const Label = forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> &
    VariantProps<typeof labelVariants>
>(({className, ...props}, ref) => (
  <LabelPrimitive.Root
    ref={ref}
    className={twMerge(labelVariants(), className)}
    {...props}
  />
))
// @ts-expect-error -- Reclared forwardRef does not have a displayName
Label.displayName = LabelPrimitive.Root.displayName

export {Label}
