import {Tag} from "@hortis/ui/tag"
import Grow from "@mui/material/Grow"
import {Star1} from "@hortis/ui/icons"

export const FeaturedChip = ({featured}: {featured: boolean}) => {
  return (
    <Grow in={featured} className="absolute left-2 top-2 z-10">
      <div>
        <Tag className="border-none text-xs shadow-md" spacing="icon" size="sm">
          <Star1 className="h-4 w-4 fill-yellow-400 stroke-yellow-400" />
          Featured
        </Tag>
      </div>
    </Grow>
  )
}
