import {ChevronRight} from "@hortis/ui/icons"
import type {ReactNode} from "react"

export interface OverviewCardProps {
  title: ReactNode
  subtitle: ReactNode
  onClick?: () => void
  testIdPrefix?: string
}

export const OverviewCard = ({
  title,
  subtitle,
  onClick,
  testIdPrefix: _testIdPrefix,
}: OverviewCardProps) => {
  const testIdPrefix = `${_testIdPrefix == null ? "" : `${_testIdPrefix}-`}`
  return (
    <button
      className="flex items-center justify-between rounded-xl border border-grey-200 p-4 pl-6 text-left shadow-xs transition-colors hover:border-grey-300 hover:bg-grey-50"
      onClick={onClick}
      type="button"
      data-cy={`${testIdPrefix}card`}
    >
      <div className="mr-4 flex-1">
        <div
          data-cy={`${testIdPrefix}card-title`}
          className="font-medium text-grey-900"
        >
          {title}
        </div>
        <div
          data-cy={`${testIdPrefix}card-subtitle`}
          className="text-sm text-grey-500"
        >
          {subtitle}
        </div>
      </div>
      <ChevronRight className="h-5 w-5 text-grey-500" />
    </button>
  )
}
