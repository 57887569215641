import {zodResolver} from "@hookform/resolvers/zod"
import {Button} from "@hortis/ui/button"
import {
  SheetBody,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from "@hortis/ui/sheet"
import {useEffect} from "react"
import type {UseFormProps} from "react-hook-form"
import {FormProvider, useForm} from "react-hook-form"
import {FormTextField} from "src/components/text-field"
import {z} from "zod"
import {useUpdateLocationMutation} from "../../../../../generated/graphql"
import {useSnackbarStore} from "../../../../components/snackbar-controller/snackbar-store"
import {onFailure, onSuccess} from "../../../../notification-snack-utils"
import type {CurrentLocation} from "./drawer-context"
import type {LocationFieldsProps} from "./types"

const errorMap: z.ZodErrorMap = (error, ctx) => {
  if (error.path[0] === "name") {
    return {message: "Please enter a location name"}
  }
  if (error.path[0] === "description") {
    return {message: "Please enter a location description"}
  }
  if (error.message != null) {
    return {message: error.message}
  }
  return {message: ctx.defaultError}
}

export const EditLocationSchema = z.object({
  id: z.string().min(1),
  name: z.string().min(1),
  description: z.string().min(1).nullable(),
})
export type EditLocationSchema = z.TypeOf<typeof EditLocationSchema>

const resolver: UseFormProps<EditLocationSchema>["resolver"] = zodResolver(
  EditLocationSchema,
  {errorMap},
)

export const EditLocationSheetContent = ({
  onClose,
  initialValue,
  onLocationCreation,
}: LocationFieldsProps<CurrentLocation>) => {
  const {setSnack} = useSnackbarStore()
  const [, updateLocation] = useUpdateLocationMutation()
  const formMethods = useForm<EditLocationSchema>({
    defaultValues: {
      name: initialValue?.name,
      description: initialValue?.description,
      id: initialValue?.id,
    },
    criteriaMode: "all",
    resolver,
  })

  useEffect(() => {
    formMethods.reset({
      name: initialValue?.name,
      description: initialValue?.description,
      id: initialValue?.id,
    })
  }, [formMethods, initialValue])

  const onSubmit = formMethods.handleSubmit(async ({id, ...updates}, e) => {
    e?.preventDefault()
    const res = await updateLocation({
      id,
      input: updates,
    })
    if (res.data?.updateLocation.success === true) {
      onSuccess(setSnack)(`Location updated`)
      if (res.data.updateLocation.location != null) {
        onLocationCreation(res.data.updateLocation.location)
      }
    } else {
      onFailure(setSnack)(new Error("Failed to update location"))
    }
  })

  return (
    <FormProvider {...formMethods}>
      <SheetContent asChild data-cy="location-drawer">
        <form onSubmit={onSubmit}>
          <SheetHeader>
            <SheetTitle>Edit location</SheetTitle>
            <SheetDescription>Edit a site location</SheetDescription>
          </SheetHeader>
          <SheetBody className="gap-6">
            <FormTextField
              name="name"
              label="Name"
              required
              testId="location-name-input"
            />
            <FormTextField
              name="description"
              label="Description"
              testId="location-description-input"
            />
          </SheetBody>
          <SheetFooter>
            <Button onClick={onClose} testId="cancel-button">
              Cancel
            </Button>
            <Button
              variant="primary"
              type="submit"
              loading={formMethods.formState.isSubmitting}
              testId="edit-location-button"
            >
              Save
            </Button>
          </SheetFooter>
        </form>
      </SheetContent>
    </FormProvider>
  )
}
