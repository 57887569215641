import {A} from "@mobily/ts-belt"
import * as R from "fp-ts/Reader"
import {flow, pipe} from "fp-ts/function"
import type {
  AccessionsListFieldsFragment,
  MapMaterialListFieldsFragment,
  TaxaListFieldsFragment,
} from "generated/graphql"
import * as t from "io-ts"
import {useEffect, useMemo, useState} from "react"
import {PageDirection} from "src/utils/pagination"
import {mapType, optional} from "../../../../utils/io-ts"
import * as OE from "../../../../utils/option-either"
import type {
  UseMapListMaterialsArgs,
  UseMapListMaterialsResult,
  UsePlantMaterialsArgs,
  UsePlantMaterialsResult,
} from "../../fetchers"
import {useMapListMaterials, usePlantMaterials} from "../../fetchers"

export const DirParam = pipe(
  optional(t.keyof({prev: null, next: null})),
  mapType(
    (param) =>
      param === "prev"
        ? PageDirection.Prev
        : param === "next"
        ? PageDirection.Next
        : param,
    (dir) =>
      dir === PageDirection.Prev
        ? "prev"
        : dir === PageDirection.Next
        ? "next"
        : dir,
  ),
)

export const PlantMaterialsNotFound = {
  type: "PLANT_MATERIALS_NOT_FOUND" as const,
}
export type PlantMaterialsNotFound = typeof PlantMaterialsNotFound

const getResult = flow(
  R.ask<UsePlantMaterialsResult>(),
  OE.chainNullableToErrorKW(PlantMaterialsNotFound)(({result}) => result),
)

export const processPlantMaterials = flow(
  getResult,
  OE.map(({nodes}) => nodes),
  OE.noneToUndefined,
)
export type PlantMaterialsErrors = OE.ExtractLeft<ReturnType<typeof getResult>>

export const getPageInfo = flow(
  getResult,
  OE.map(({pageInfo}) => pageInfo),
  OE.noneAndErrorToUndefined,
)

export const usePlantMaterialsQuery = (args: UsePlantMaterialsArgs) => {
  const plantMaterialsRes = usePlantMaterials(args)
  const pageInfo = getPageInfo(plantMaterialsRes)

  return {
    plantMaterials: processPlantMaterials(plantMaterialsRes),
    pageInfo,
    totalMaterials:
      OE.noneAndErrorToUndefined(plantMaterialsRes)?.total ?? undefined,
    endCursor: pageInfo?.endCursor,
    startCursor: pageInfo?.startCursor,
  }
}

const getMapListResult = flow(
  R.ask<UseMapListMaterialsResult>(),
  OE.chainNullableToErrorKW(PlantMaterialsNotFound)(({result}) => result),
)

export const getMapListPageInfo = flow(
  getMapListResult,
  OE.map(({pageInfo}) => pageInfo),
  OE.noneAndErrorToUndefined,
)

export const processMapListMaterials = flow(
  getMapListResult,
  OE.map(({nodes}) => nodes),
  OE.noneToUndefined,
)

export const useMapListMaterialsQuery = (args: UseMapListMaterialsArgs) => {
  const plantMaterialsRes = useMapListMaterials(args)
  const pageInfo = getMapListPageInfo(plantMaterialsRes)

  return {
    plantMaterials: processMapListMaterials(plantMaterialsRes),
    pageInfo,
    totalMaterials:
      OE.noneAndErrorToUndefined(plantMaterialsRes)?.total ?? undefined,
    endCursor: pageInfo?.endCursor,
    startCursor: pageInfo?.startCursor,
  }
}

export const useActiveRecordTags = <
  T extends
    | MapMaterialListFieldsFragment
    | TaxaListFieldsFragment
    | AccessionsListFieldsFragment,
>(
  selectedRows: ReadonlyArray<T>,
  isOpen: boolean,
) => {
  const res = useMemo(
    () =>
      pipe(
        selectedRows,
        A.map((record) => {
          const tags = record.tagsConnection?.nodes
          return {
            recordId: record.id,
            tagIds: tags?.map((tag) => tag.id) ?? [],
          }
        }),
      ),
    [selectedRows],
  )
  const [activeRecordTags, setActiveRecordTags] = useState(res)

  useEffect(() => {
    if (!isOpen) {
      setActiveRecordTags(res)
    }
  }, [isOpen, res])

  return [activeRecordTags, setActiveRecordTags] as const
}
