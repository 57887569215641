import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@hortis/ui/dropdown-menu"
import {ChevronDown, ExternalLink1, Eye, EyeOff, Tag3} from "@hortis/ui/icons"
import {Link, useParams} from "@tanstack/react-router"
import {
  MaterialGroup,
  PlantMaterialStatus,
  type MaterialFieldsFragment,
} from "generated/graphql"
import {useState} from "react"
import {RecordPageHeaderPropertiesSummary} from "src/components/record-page-header"
import {StatusChip} from "src/components/status-chip"
import {MakePublicDialog} from "src/features/material-page/header/make-public-dialog"
import {materialGroupLabelMap} from "src/utils/label-maps/material-group"
import {useCollectionSiteSettings} from "src/utils/hooks/collection-site-from-url"
import {MakePrivateDialog} from "./make-private-dialog"

export const MaterialRecordPropertySummary = ({
  material,
  canEdit,
  refetchMaterial,
}: {
  material: MaterialFieldsFragment
  refetchMaterial: () => void
  canEdit: boolean
}) => {
  const {isPublic: isSitePublic} = useCollectionSiteSettings()
  const {siteSlug} = useParams({from: "/_layout/sites/$siteSlug"})
  const isPublic = material.public
  const [publicDropdownOpen, setPublicDropdownOpen] = useState(false)
  const [makePublicDialogOpen, setMakePublicDialogOpen] = useState(false)
  const [makePrivateDialogOpen, setMakePrivateDialogOpen] = useState(false)

  return (
    <RecordPageHeaderPropertiesSummary>
      <StatusChip status={material.status} />
      <div className="flex items-center gap-1 py-[3px] ">
        <Tag3 className="h-4 w-4 text-grey-400" />
        <p className="text-sm font-medium text-grey-500">
          {materialGroupLabelMap[material.materialGroup]}
        </p>
      </div>
      {material.status === PlantMaterialStatus.Present &&
        material.materialGroup === MaterialGroup.Plant &&
        isSitePublic && (
          <DropdownMenu
            open={publicDropdownOpen}
            onOpenChange={
              canEdit && !material.archived ? setPublicDropdownOpen : undefined
            }
          >
            <DropdownMenuTrigger
              data-cy="public-property-trigger"
              className="group flex items-center gap-1 rounded-md py-[3px]"
            >
              {isPublic ? (
                <Eye className="h-4 w-4 text-grey-400 transition-colors group-hover:text-grey-500" />
              ) : (
                <EyeOff className="h-4 w-4 text-grey-400 transition-colors group-hover:text-grey-500" />
              )}
              <p className="text-sm font-medium text-grey-500 transition-colors group-hover:text-grey-600">
                {isPublic ? "Public" : "Private"}
              </p>
              {canEdit && !material.archived && (
                <ChevronDown className="h-4 w-4 text-grey-400 transition-colors group-hover:text-grey-500" />
              )}
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              {isPublic ? (
                <>
                  <DropdownMenuItem
                    asChild
                    data-cy="open-public-record-menu-item"
                  >
                    <Link
                      target="_blank"
                      to={`/public/site/${siteSlug}/map/plant/${material.id}`}
                    >
                      <ExternalLink1 className="h-4 w-4 text-grey-500" />
                      Open public record
                    </Link>
                  </DropdownMenuItem>

                  <DropdownMenuSeparator />
                  <DropdownMenuItem
                    data-cy="make-private-menu-item"
                    onClick={() => {
                      setMakePrivateDialogOpen(true)
                    }}
                  >
                    <EyeOff className="h-4 w-4 text-grey-500" />
                    Make private
                  </DropdownMenuItem>
                </>
              ) : (
                <DropdownMenuItem
                  data-cy="make-public-menu-item"
                  onClick={() => {
                    setMakePublicDialogOpen(true)
                  }}
                >
                  <Eye className="h-4 w-4 text-grey-500" />
                  Make public
                </DropdownMenuItem>
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        )}
      <MakePrivateDialog
        open={makePrivateDialogOpen}
        onOpenChange={setMakePrivateDialogOpen}
        id={material.id}
        onSuccess={refetchMaterial}
      />
      <MakePublicDialog
        open={makePublicDialogOpen}
        onOpenChange={setMakePublicDialogOpen}
        id={material.id}
        onSuccess={refetchMaterial}
      />
    </RecordPageHeaderPropertiesSummary>
  )
}
