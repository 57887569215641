import {Fragment} from "react"
import {FilterChip} from "src/features/filters/filter-chip-group"
import type {OpenFilterArgs} from "../filters"
import type {MaterialFilterType, LocalMaterialFilter} from "../types"

export const QuantityFilterChipGroup = ({
  id,
  filter,
  hidden,
  ...props
}: {
  id: string
  handleOpenFilter: (args: OpenFilterArgs<MaterialFilterType>) => void
  handleDelete: (id: string) => void
  filter: LocalMaterialFilter["massPlanting"]
  hidden?: boolean
}) =>
  filter == null || hidden === true ? (
    <Fragment />
  ) : (
    <>
      {filter.eq === true && (
        <FilterChip
          filterLabel="Quantity"
          filterId={id}
          filterType="quantity"
          filterData={["mass planting"]}
          operation="is"
          {...props}
        />
      )}
      {filter.neq != null && (
        <FilterChip
          filterLabel="Quantity"
          filterId={id}
          filterType="quantity"
          filterData={["mass planting"]}
          operation="is not"
          {...props}
        />
      )}
    </>
  )
