import {identity} from "fp-ts/function"
import {forwardRef} from "react"
import type {ReactFn} from "./apply-mui"

/**
 * This is a bit of a hack
 * and perhaps we should just embrace every component returning ReactElement | null
 * but for now this keeps the types compatible with how things were before ref forwarding
 * and a lot of MUI components specifically return JSX.Element, too
 */
const coerceToJustJSXElement = <Props,>(
  Component: (props: Props) => JSX.Element | null,
) => Component as (props: Props) => JSX.Element

/**
 * Promap over a React component - modify the props and resulting JSX.
 *
 * @param useContramap - contramap the input props from `In` to `Out` - this function is treated as a hook
 * @param useMap - map over the JSX element - this function is treated as a hook
 * @returns a component that accepts props `In`
 *
 * @remarks
 * This is a superset of {@link contramapComponent} and {@link mapComponent},
 * so you'll probably want to use either of those instead.
 *
 * @see {@link contramapComponent}/{@link mapComponent}
 */
export const promapComponent: <In, Out = In>(
  useContramap: (props: In) => Out,
  useMap: (el: JSX.Element) => JSX.Element,
) => (
  Component: Out extends never ? never : ReactFn<Out>,
) => (props: In) => JSX.Element =
  <In, Out = In>(
    useContramap: (props: In) => Out,
    useMap: (el: JSX.Element) => JSX.Element,
  ) =>
  // annotation here overrides the `never` guard above
  (Component: ReactFn<Out>) =>
    coerceToJustJSXElement(
      forwardRef(function PromapWrapper(props, ref): JSX.Element {
        const newProps = useContramap(props)
        const result = <Component {...newProps} ref={ref} />
        return useMap(result)
      }),
    ) as (props: In) => JSX.Element

/**
 * Contramap over a React component - modify the props.
 *
 * @param useContramap - contramap the input props from `In` to `Out` - this function is treated as a hook
 * @returns a component that accepts props `In`
 *
 * @remarks Useful for thin wrappers around components, like providing
 * a default value to a prop,
 * or always providing a prop value and omitting it from In
 *
 * @example
 * ```
 * contramapComponent(({ foo = 'fallback', bar }: { foo?: string, bar: number }) => ({ foo, bar }))(({ foo, bar }) => <p>{foo}, {bar}</p>)
 * ```
 *
 * @example
 * ```
 * type Props = {foo: string; bar: number}
 * const MyComponent = ({ foo, bar }: Props) => <p>{foo}, {bar}</p>
 * const overrideFoo = contramapComponent<Omit<Props, "foo">, Props>((props) => ({
 *   ...props,
 *   foo: "override",
 * }))
 * const Overridden = overrideFoo(MyComponent)
 * ```
 */
export const contramapComponent: <In, Out = In>(
  useContramap: (props: In) => Out,
) => (
  Component: Out extends never ? never : ReactFn<Out>,
) => (props: In) => JSX.Element = (useContramap) =>
  promapComponent(useContramap, identity)

/**
 * Map over a React component - modify the resulting JSX.
 *
 * @param useMap - map over the JSX element - this function is treated as a hook
 * @returns a component that accepts props `In`
 *
 * @remarks
 * Useful for wrapping a pre-existing Component without having to explicitly pass props through.
 *
 * @example
 * ```
 * mapComponent(el => <div>{el}</div>)(({ foo, bar }) => <p>{foo}, {bar}</p>)
 * ```
 *
 * @example
 * ```
 * mapComponent(flexGrow)(MyComponent)
 * ```
 *
 * @example
 * ```
 * const themedBox = (el: JSX.Element) => {
 *   const theme = useTheme()
 *   return <Box color={theme.primary}>{el}</Box>
 * }
 *
 * export default wrapInThemedBox = mapComponent(themedBox)
 * ```
 */
export const mapComponent: (
  useMap: (el: JSX.Element) => JSX.Element,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => <Component extends ReactFn<any>>(Component: Component) => Component = (
  useMap,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-explicit-any
) => promapComponent(identity, useMap) as any
