import Typography from "@mui/material/Typography"

const truncateDataLabel = (data: Array<string>): string => {
  let dataLabel = ""
  if (data.length > 0 && data[0] != null) {
    dataLabel += ` ${data[0]}`
  }
  if (data.length > 1) {
    dataLabel += `, +${data.length - 1} more`
  }
  return dataLabel
}

interface ChipLabelProps {
  column: string
  operation: string
  data: string | Array<string>
}

export const ChipLabel = ({column, operation, data}: ChipLabelProps) => {
  return (
    <Typography<"span"> component="span" fontSize="0.875rem">
      {column}{" "}
      <Typography<"span">
        component="span"
        color="text.secondary"
        fontSize="0.875rem"
      >
        {operation}
      </Typography>
      {Array.isArray(data) ? truncateDataLabel(data) : data}
    </Typography>
  )
}
