// eslint-disable-next-line no-restricted-imports
import {useLayoutEffect, useState} from "react"
import {breakpoints, type Breakpoint} from "../../utils/breakpoints"

export function useMediaQuery(query: string | Breakpoint) {
  const [value, setValue] = useState(false)

  useLayoutEffect(() => {
    function onChange(event: MediaQueryListEvent) {
      setValue(event.matches)
    }

    const result = matchMedia(
      query in breakpoints
        ? `(min-width: ${breakpoints[query as Breakpoint]}px)`
        : query,
    )
    result.addEventListener("change", onChange)
    setValue(result.matches)

    return () => {
      result.removeEventListener("change", onChange)
    }
  }, [query])

  return value
}
