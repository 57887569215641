import type {
  GetCollectionSiteFromNamesQuery,
  GetCollectionSiteFromNamesQueryVariables,
} from "../../generated/graphql"
import {authedFetcher} from "./gql-client"
import {GET_ORG_AND_SITE_FROM_NAMES} from "./graphql"

export const getOrgAndSiteFromNames = authedFetcher<
  GetCollectionSiteFromNamesQueryVariables,
  GetCollectionSiteFromNamesQuery
>(GET_ORG_AND_SITE_FROM_NAMES)("GetCollectionSiteFromNames")
