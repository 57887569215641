import {R} from "@mobily/ts-belt"
import type {
  GetManualAccessionNumberErrorsQuery,
  GetManualAccessionNumberErrorsQueryVariables,
} from "generated/graphql"
import {urqlClient} from "src/gql/urql-provider"
import {getUrlContext} from "src/utils/hooks/url-context"
import {GET_MANUAL_ACCESSION_NUMBER_ERRORS} from "../../graphql"

// Placeholder numbers look like: 2022-#### - see createPlaceholderAccessionId()
const placeholderNumber = /\d{4}-#{4}/

export const makeValidationReq = async (accessionNumber: string) => {
  try {
    if (placeholderNumber.test(accessionNumber)) {
      return R.Ok([])
    }

    const place = getUrlContext()

    if (R.isError(place)) {
      // eslint-disable-next-line unicorn/prefer-type-error
      throw new Error("Could not get valid place")
    }

    if (urqlClient == null) {
      throw new Error("Urql client is not initialised")
    }

    const res = await urqlClient
      .query<
        GetManualAccessionNumberErrorsQuery,
        GetManualAccessionNumberErrorsQueryVariables
      >(GET_MANUAL_ACCESSION_NUMBER_ERRORS, {
        accessionNumber,
        organisationSubdomain: place._0.orgSubdomain,
        collectionSiteSlug: place._0.siteSlug,
      })
      .toPromise()

    return R.Ok(res.data?.org?.site?.errors ?? [])
  } catch (error: unknown) {
    return R.Error(
      error instanceof Error
        ? error
        : new Error("Unknown error during makeValidationReq"),
    )
  }
}
