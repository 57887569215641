export const ProvenanceCell = ({
  value,
}: {
  value: {name?: string; codeId: string} | undefined | null
}) => (
  <>
    {value == null ? (
      "-"
    ) : (
      <div className="flex items-center gap-3">
        <div className="flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full bg-grey-50 text-xs font-medium">
          {value.codeId}
        </div>
        {value.name}
      </div>
    )}
  </>
)
