import {Badge} from "@hortis/ui/badge"
import {Button, IconButton} from "@hortis/ui/button"
import {Dialog, DialogTrigger} from "@hortis/ui/dialog"
import {useMediaQuery} from "@hortis/ui/hooks/media-query"
import {Plus} from "@hortis/ui/icons"
import {Link} from "@tanstack/react-router"
import type {AccessionFieldsFragment} from "generated/graphql"
import {useCallback, useState, type ReactElement} from "react"
import {RelatedRecordsDialog} from "src/components/dialog/variants/related-records"
import {createMaterialNumber} from "src/features/collection/components/plant-materials/material-number"
import MaterialRecordCard from "src/features/records/components/new-record-card/variants/material-record-card"
import {
  RecordSection,
  RecordSectionContent,
  RecordSectionContentGroup,
  RecordSectionHeader,
  RecordSectionHeaderButtons,
  RecordSectionTitle,
} from "src/features/records/components/record-section/new-record-section"
import {getThumbnailPhotoUrl} from "src/utils/photos"

export interface AccessionRecordMaterialsProps {
  accession: AccessionFieldsFragment
  handleCreateNewMaterial: () => void
  canEdit: boolean
}

export const AccessionRecordMaterials = ({
  canEdit,
  accession,
  handleCreateNewMaterial,
}: AccessionRecordMaterialsProps): ReactElement => {
  const isNotMobile = useMediaQuery("sm")

  const [viewAllRecords, setViewAllRecords] = useState(false)
  const closeViewAllRecordsDialog = useCallback(() => {
    setViewAllRecords(false)
  }, [])

  return (
    <RecordSection id={"materials"}>
      <RecordSectionHeader>
        <RecordSectionTitle>Materials</RecordSectionTitle>
        <Badge testId="accession-materials-count" color="primary">
          {accession.plantMaterial?.length}
        </Badge>
        <RecordSectionHeaderButtons>
          <Dialog open={viewAllRecords} onOpenChange={setViewAllRecords}>
            {accession.plantMaterial && accession.plantMaterial.length > 0 && (
              <DialogTrigger asChild>
                <Button
                  data-cy="view-all-accession-materials"
                  size="xs"
                  variant="tertiaryGray"
                  className="font-medium"
                >
                  View all
                </Button>
              </DialogTrigger>
            )}
            <RelatedRecordsDialog setOpen={setViewAllRecords}>
              {accession.plantMaterial?.map((material) => (
                <Link
                  onClick={closeViewAllRecordsDialog}
                  key={material.id}
                  to="/sites/$siteSlug/materials/$materialNumber"
                  from="/sites/$siteSlug/materials/$materialNumber"
                  params={{
                    materialNumber: createMaterialNumber(
                      accession.accessionNumber,
                      material.qualifier,
                    ),
                  }}
                >
                  <MaterialRecordCard
                    className="-ml-3"
                    materialNumber={`${accession.accessionNumber}/${material.qualifier}`}
                    scientificName={accession.scientificName ?? "-"}
                    photoUrl={getThumbnailPhotoUrl(material)}
                    group={material.materialGroup}
                    status={material.status}
                    size={isNotMobile ? "md" : "sm"}
                    variant="Row"
                    location={material.collectionSiteLocation}
                  />
                </Link>
              ))}
            </RelatedRecordsDialog>
          </Dialog>

          {canEdit &&
            !accession.archived &&
            accession.deaccessionDate == null && (
              <IconButton
                testId="new-material-button-header"
                onClick={handleCreateNewMaterial}
                icon={Plus}
                variant="tertiaryGray"
                size="xs"
                ariaLabel="New material"
              />
            )}
        </RecordSectionHeaderButtons>
      </RecordSectionHeader>

      <RecordSectionContent>
        <RecordSectionContentGroup className="grid w-full grid-cols-1 md:grid-cols-2">
          {accession.plantMaterial && accession.plantMaterial.length > 0 ? (
            accession.plantMaterial.slice(0, 4).map((material) => (
              <Link
                key={material.id}
                to="/sites/$siteSlug/materials/$materialNumber"
                from="/sites/$siteSlug/materials/$materialNumber"
                params={{
                  materialNumber: createMaterialNumber(
                    accession.accessionNumber,
                    material.qualifier,
                  ),
                }}
              >
                <MaterialRecordCard
                  className="-ml-3 -mt-3"
                  materialNumber={`${accession.accessionNumber}/${material.qualifier}`}
                  scientificName={accession.scientificName ?? "-"}
                  photoUrl={getThumbnailPhotoUrl(material)}
                  group={material.materialGroup}
                  status={material.status}
                  size={isNotMobile ? "md" : "sm"}
                  variant="Row"
                  location={material.collectionSiteLocation}
                />
              </Link>
            ))
          ) : (
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-1">
                <p className="text-base font-medium text-grey-900">
                  No materials
                </p>
                <p className="text-sm font-normal text-grey-500">
                  Add plant materials to start mapping, taking photographs, and
                  assigning bed locations.
                </p>
              </div>
              {canEdit &&
                !accession.archived &&
                accession.deaccessionDate == null && (
                  <Button
                    testId="new-material-button"
                    className="w-fit"
                    onClick={handleCreateNewMaterial}
                    startIcon={Plus}
                    size="sm"
                  >
                    New material
                  </Button>
                )}
            </div>
          )}
        </RecordSectionContentGroup>
      </RecordSectionContent>
    </RecordSection>
  )
}
