import type {ReactNode} from "react"
import {forwardRef} from "react"

type Props = {
  children: ReactNode
}

export const FillSpace = forwardRef<HTMLDivElement, Props>(
  function FillSpaceWithRef({children}, ref): JSX.Element {
    return (
      <div ref={ref} className="flex w-full flex-1 flex-col">
        {children}
      </div>
    )
  },
)
