import {Button} from "@hortis/ui/button"
import {Grid1, Plus, SearchMd, Trash3} from "@hortis/ui/icons"
import {Link} from "@tanstack/react-router"
import type {
  AccessionsSearchTerm,
  PlantMaterialsSearchTerm,
  TaxaSearchTerm,
} from "generated/graphql"
import {Fragment} from "react"
import {
  EmptyState,
  EmptyStateActions,
  EmptyStateBody,
  EmptyStateHeader,
  EmptyStateTitle,
} from "src/components/empty-state"
import {FeaturedIcon} from "src/components/featured-icon/featured-icon"
import {useAccessRole} from "src/features/permissions/use-access-role"

const getEmptyStateBody = (
  searchTerm:
    | PlantMaterialsSearchTerm
    | AccessionsSearchTerm
    | TaxaSearchTerm
    | undefined,
  filtersActive: boolean | undefined,
  canEdit: boolean,
) =>
  searchTerm != null && searchTerm.value !== ""
    ? `Your search “${
        searchTerm.value
      }” did not match any records. Please try again${
        canEdit ? ` or create a new taxon` : ""
      }.`
    : filtersActive === true
    ? `Your filters did not match any records. Please try again${
        canEdit ? ` or create a new taxon` : ""
      }.`
    : `Your taxa will live here.${
        canEdit ? ` Start creating your own by clicking “New taxon”.` : ""
      }`

interface EmptyMaterialListProps<T> {
  list: Array<T> | ReadonlyArray<T> | undefined
  searchTerm: TaxaSearchTerm | undefined
  clearSearch: () => void
  filtersActive?: boolean
  isTrash?: boolean
}

export const EmptyTaxaList = <T,>({
  isTrash = false,
  list,
  searchTerm,
  clearSearch,
  filtersActive,
}: EmptyMaterialListProps<T>) => {
  const {canEdit} = useAccessRole()
  const searchActive =
    (searchTerm != null && searchTerm.value !== "") || filtersActive === true

  return list != null && list.length === 0 ? (
    <div
      className="mx-auto my-12 max-w-md justify-self-center p-6 md:my-24"
      data-cy="taxa-empty-list"
    >
      {isTrash ? (
        <EmptyState>
          <EmptyStateHeader>
            <FeaturedIcon>
              <Trash3 className="h-5 w-5 text-primary-600" />
            </FeaturedIcon>
            <EmptyStateTitle>No taxa in the trash</EmptyStateTitle>
            <EmptyStateBody>
              Your taxa trash is currently empty. Any taxa moved to the trash
              will appear here.
            </EmptyStateBody>
          </EmptyStateHeader>
          <EmptyStateActions>
            <Button asChild variant="primary">
              <Link to="/taxonomy">Go to all taxa</Link>
            </Button>
          </EmptyStateActions>
        </EmptyState>
      ) : (
        <EmptyState>
          <EmptyStateHeader>
            <FeaturedIcon>
              {searchActive ? (
                <SearchMd className="h-5 w-5 text-primary-600" />
              ) : (
                <Grid1 className="h-5 w-5 text-primary-600" />
              )}
            </FeaturedIcon>
            <EmptyStateTitle>No taxa found</EmptyStateTitle>
            <EmptyStateBody>
              {getEmptyStateBody(searchTerm, filtersActive, canEdit)}
            </EmptyStateBody>
          </EmptyStateHeader>
          <EmptyStateActions>
            {searchActive && (
              <Button onClick={clearSearch}>Reset search & filters</Button>
            )}
            {canEdit && (
              <Button asChild variant="primary" startIcon={Plus}>
                <Link to="/taxonomy/new">New taxon</Link>
              </Button>
            )}
          </EmptyStateActions>
        </EmptyState>
      )}
    </div>
  ) : (
    <Fragment />
  )
}
