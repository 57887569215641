import {Badge} from "@hortis/ui/badge"
import {Button, IconButton} from "@hortis/ui/button"
import {Drawer, DrawerContent, DrawerHandle} from "@hortis/ui/drawer"
import {XClose} from "@hortis/ui/icons"
import {useCallback, useMemo, useRef, useState} from "react"
import {ToggleChip} from "src/components/toggle/toggle-chip"
import type {MaterialFilterType} from "src/features/filters/plant-materials"
import {AddPlantMaterialFilter} from "src/features/filters/plant-materials"
import {MaterialFilterChipGroup} from "src/features/filters/plant-materials/filter-chip-group"
import {FilterActionTypes} from "src/features/filters/plant-materials/filter-reducer"
import type {OpenFilterArgs} from "src/features/filters/plant-materials/filters"
import {MaterialFilterModals} from "src/features/filters/plant-materials/modals/filter-modals"
import type {MaterialFilters} from "src/features/filters/plant-materials/use-material-filters"
import {useMobile} from "src/utils/hooks/media-query"

interface FiltersOverlayProps {
  setOpen: (open: boolean) => void
  materialFilters: MaterialFilters
  open: boolean
}

export const FiltersOverlay = ({
  setOpen,
  materialFilters,
  open,
}: FiltersOverlayProps) => {
  const [openFilter, setOpenFilter] = useState<false | MaterialFilterType>(
    false,
  )
  const [editFilterId, setEditFilterId] = useState<string | null>(null)
  const filterModalAnchorEl = useRef<HTMLElement | null>(null)
  const editFilter = useMemo(
    () =>
      editFilterId == null
        ? undefined
        : materialFilters.filters.and.find(({id}) => id === editFilterId),
    [materialFilters.filters, editFilterId],
  )

  const setExcludeMaterialFilter = useCallback(
    (checked: boolean) => {
      materialFilters.dispatchFilters({
        type: FilterActionTypes.MODIFY_ROOT_FILTER,
        payload: {excludeAbsentMaterial: checked},
      })
    },
    [materialFilters],
  )

  const handleOpenFilter = useCallback(
    ({
      filterType,
      anchorElRef,
      editFilterId,
    }: OpenFilterArgs<MaterialFilterType>) => {
      setEditFilterId(editFilterId ?? null)
      if (anchorElRef != null) {
        filterModalAnchorEl.current = anchorElRef
      }
      setOpenFilter(filterType)
    },
    [],
  )

  const resetAllFilters = useCallback(() => {
    materialFilters.resetFilters()
  }, [materialFilters])

  const handleFilterModalClose = useCallback(() => {
    setOpenFilter(false)
  }, [])

  const closeFilters = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const isMobile = useMobile()

  return isMobile ? (
    <Drawer open={open} onOpenChange={setOpen}>
      <DrawerContent
        className="overflow-hidden p-4 pt-0 shadow-md-up"
        data-cy="mobile-drawer"
      >
        <DrawerHandle />
        <div
          className="pointer-events-auto w-full self-start rounded-2xl lg:max-w-xs"
          data-cy="filters-overlay"
        >
          <div className="flex flex-col gap-4 pb-6 lg:px-6 lg:py-4">
            <div className="flex items-center gap-2">
              <div className="flex flex-1 items-center gap-2">
                <span className="text-lg font-semibold text-grey-900">
                  Filters
                </span>
                <Badge testId="filter-count">
                  {materialFilters.filterCounts.total}
                </Badge>
              </div>
              <Button
                variant="secondaryColor"
                size="sm"
                onClick={resetAllFilters}
                testId="reset-filters-button"
              >
                Reset
              </Button>
              <IconButton
                onClick={closeFilters}
                ariaLabel="Close filters"
                variant="tertiaryGray"
                size="sm"
                icon={XClose}
              />
            </div>
            <div className="flex flex-wrap gap-2 lg:flex-col">
              <ToggleChip
                onChange={setExcludeMaterialFilter}
                checked={materialFilters.filters.excludeAbsentMaterial === true}
                testId="absent-material-switch"
              >
                Exclude &apos;absent&apos; material
              </ToggleChip>
              <MaterialFilterChipGroup
                filters={materialFilters.filters.and}
                dispatchFilter={materialFilters.dispatchFilters}
                handleOpenFilter={handleOpenFilter}
              />
            </div>
            <div>
              <AddPlantMaterialFilter
                setOpenFilter={handleOpenFilter}
                side="top"
              />
            </div>
          </div>
          <MaterialFilterModals
            editFilter={editFilter}
            openFilter={openFilter}
            handleFilterModalClose={handleFilterModalClose}
            filterModalAnchorEl={undefined}
            dispatchFilters={materialFilters.dispatchFilters}
          />
        </div>
      </DrawerContent>
    </Drawer>
  ) : (
    <></>
  )
}
