import {PhotoPlaceholder} from "@hortis/ui/photo-placeholder"
import type {Row} from "@tanstack/react-table"
import {TwicImg} from "@twicpics/components/react"
import {type MaterialsListFieldsFragment} from "generated/graphql"
import {Lightbox, LightboxTrigger} from "src/components/lightbox/lightbox"
import {stopPropagation} from "src/features/collection/components/stop-event-propagation"
import {getThumbnailPhoto} from "src/utils/photos"

interface DataTableMaterialNumberCellProps {
  row: Row<MaterialsListFieldsFragment>
}

export function DataTablePhotoCell({row}: DataTableMaterialNumberCellProps) {
  const photosLength = row.original.images?.length ?? 0
  const thumbnailPhoto = getThumbnailPhoto(row.original)

  return (
    <div className="relative flex items-center justify-between">
      <div //eslint-disable-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        className="relative flex items-center pr-[18px]"
        onClick={thumbnailPhoto == null ? undefined : stopPropagation}
      >
        {thumbnailPhoto == null ? (
          <PhotoPlaceholder size="xs" />
        ) : (
          <Lightbox
            photo={thumbnailPhoto}
            photos={row.original.images}
            title={row.original.accession?.scientificName ?? ""}
          >
            <LightboxTrigger className="group/lightbox flex items-center">
              <TwicImg
                focus="auto"
                mode="cover"
                className="size-10 flex-shrink-0 overflow-hidden rounded transition-transform duration-200 group-hover/lightbox:scale-105"
                src={`${thumbnailPhoto.bucketName}/${thumbnailPhoto.fileName}`}
                eager
              />
              {photosLength > 0 && (
                <div className="absolute right-0 flex h-6 w-6 items-center justify-center overflow-hidden rounded-[99px] bg-grey-100 text-xs font-medium text-grey-600 ring-2 ring-white ring-opacity-60">
                  {photosLength}
                </div>
              )}
            </LightboxTrigger>
          </Lightbox>
        )}
      </div>
    </div>
  )
}
