import type {PaddingOptions} from "maplibre-gl"
import create from "zustand"

export type SetMapPadding = (padding: PaddingOptions) => void

interface MapPaddingState {
  mapPadding: PaddingOptions
  setMapPadding: SetMapPadding
}

export const useMapPaddingStore = create<MapPaddingState>((set) => ({
  mapPadding: {top: 0, bottom: 0, left: 0, right: 0},
  setMapPadding: (mapPadding) => {
    set({mapPadding})
  },
}))
