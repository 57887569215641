import {match} from "ts-pattern"
import * as uuid from "uuid"
import type {AccessionFilter} from "generated/graphql"
import {FilterActionTypes} from "../plant-materials/filter-reducer"
import type {
  AccessionFilterType,
  LocalAccessionFilter,
  LocalAccessionFilterWithId,
} from "./types"

export interface AccessionFilterState extends Omit<AccessionFilter, "and"> {
  and: Array<LocalAccessionFilterWithId>
}

export const defaultState: AccessionFilterState = {
  and: [],
}

export type AccessionFilterAction =
  | {
      type: FilterActionTypes.ADD_FILTER
      payload: LocalAccessionFilter
    }
  | {type: FilterActionTypes.DELETE_FILTER; payload: {id: string}}
  | {
      type: FilterActionTypes.MODIFY_FILTER
      payload: LocalAccessionFilterWithId
    }
  | {
      type: FilterActionTypes.MODIFY_ROOT_FILTER
      payload: Omit<AccessionFilter, "and">
    }
  | {
      type: FilterActionTypes.RESET_FILTERS
      payload?: Partial<AccessionFilterState>
    }
  | {
      type: FilterActionTypes.RESET_FILTER_TYPE
      payload: AccessionFilterType
    }

export function filterReducer(
  state: AccessionFilterState,
  action: AccessionFilterAction,
) {
  return match(action)
    .with({type: FilterActionTypes.ADD_FILTER}, ({payload}) => ({
      ...state,
      and: [...state.and, {...payload, id: uuid.v4()}],
    }))
    .with({type: FilterActionTypes.DELETE_FILTER}, ({payload}) => ({
      ...state,
      and: state.and.filter(({id}) => id !== payload.id),
    }))
    .with({type: FilterActionTypes.MODIFY_FILTER}, ({payload}) => ({
      ...state,
      and: state.and.map((filter) =>
        filter.id === payload.id ? payload : filter,
      ),
    }))
    .with({type: FilterActionTypes.MODIFY_ROOT_FILTER}, ({payload}) => ({
      ...state,
      ...payload,
    }))
    .with({type: FilterActionTypes.RESET_FILTERS}, ({payload}) => ({
      ...defaultState,
      ...payload,
    }))
    .with(
      {type: FilterActionTypes.RESET_FILTER_TYPE},
      ({payload: filterType}) => ({
        ...state,
        and: state.and.filter((filter) =>
          match(filterType)
            .with("notes", () => filter.notes == null)
            .with("tags", () => filter.tags == null)
            .with("provenance", () => filter.provenance == null)
            .with("donor", () => filter.donor == null)
            .with("materialReceived", () => filter.materialType == null)
            .with("ipenNumber", () => filter.ipenNumber == null)
            .with("family", () => filter.family == null)
            .with("commonName", () => filter.taxon?.commonName == null)
            .with("accessionDate", () => filter.accessionDate == null)
            .with("deaccessionDate", () => filter.deaccessionDate == null)
            .with("redListStatus", () => filter.redListStatus == null)
            .with("nativeDistribution", () => filter.nativeDistribution == null)
            .with("lifeForms", () => filter.lifeForms == null)
            .with("climate", () => filter.climate == null)
            .with("creator", () => filter.creator == null)
            .with("lastEditor", () => filter.lastEditor == null)
            .exhaustive(),
        ),
      }),
    )
    .run()
}
