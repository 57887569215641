export const HortisTextLogoSvg = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="114"
    height="32"
    viewBox="0 0 114 32"
    fill="none"
  >
    <path
      d="M16.0402 6.37626C11.1568 6.37626 7.12181 10.0094 6.49019 14.7205C7.58551 14.8616 8.63666 15.1438 9.62486 15.5482C9.79998 12.1564 12.6052 9.46016 16.0402 9.46016C17.3826 9.46016 18.6288 9.872 19.6595 10.5762L21.8848 8.35074C20.2635 7.11204 18.2378 6.37626 16.0402 6.37626Z"
      fill="#15B79E"
    />
    <path
      d="M19.24 16C19.24 17.7673 17.8073 19.2 16.04 19.2C14.2727 19.2 12.84 17.7673 12.84 16C12.84 14.2327 14.2727 12.8 16.04 12.8C17.8073 12.8 19.24 14.2327 19.24 16Z"
      fill="#15B79E"
    />
    <path
      d="M23.7017 10.168L21.4503 12.4196C22.0352 13.3309 22.3961 14.3997 22.4554 15.5483C23.4436 15.1439 24.4947 14.8617 25.59 14.7205C25.3625 13.0232 24.6932 11.4659 23.7017 10.168Z"
      fill="#15B79E"
    />
    <path
      d="M14.7832 2.28882e-08C6.96233 0.605495 0.712084 6.81381 0.0400391 14.6168H3.26716C3.91858 8.58944 8.73749 3.81169 14.7832 3.22464V2.28882e-08Z"
      fill="#15B79E"
    />
    <path
      d="M17.2969 3.22464C23.3426 3.81169 28.1615 8.58944 28.8129 14.6168H32.04C31.368 6.81382 25.1178 0.605506 17.2969 0V3.22464Z"
      fill="#15B79E"
    />
    <path
      d="M32.0257 17.4102C31.3645 25.1571 25.1885 31.3322 17.4365 32V27.1869C17.4365 21.7874 21.8132 17.4102 27.2121 17.4102H32.0257Z"
      fill="#15B79E"
    />
    <path
      d="M14.6437 32C6.89174 31.3322 0.715747 25.1571 0.0545009 17.4102H4.86806C10.267 17.4102 14.6437 21.7874 14.6437 27.1869V32Z"
      fill="#15B79E"
    />
    <path
      d="M41 25.0377V7.1666H44.282V14.5958H52.333V7.1666H55.615V25.0377H52.333V17.2509H44.282V25.0377H41Z"
      fill="#1F2A37"
    />
    <path
      d="M65.1289 25.344C63.8982 25.344 62.7872 25.0632 61.7957 24.5015C60.8214 23.9398 60.0436 23.1654 59.4625 22.1783C58.8984 21.1741 58.6163 20.0168 58.6163 18.7062C58.6163 17.3956 58.9069 16.2468 59.4881 15.2596C60.0692 14.2555 60.8471 13.4725 61.8213 12.9108C62.8128 12.3492 63.9238 12.0684 65.1545 12.0684C66.3682 12.0684 67.4623 12.3492 68.4366 12.9108C69.4279 13.4725 70.2057 14.2555 70.7698 15.2596C71.351 16.2468 71.6416 17.3956 71.6416 18.7062C71.6416 20.0168 71.351 21.1741 70.7698 22.1783C70.2057 23.1654 69.4279 23.9398 68.4366 24.5015C67.4451 25.0632 66.3426 25.344 65.1289 25.344ZM65.1289 22.5102C65.9836 22.5102 66.7271 22.1953 67.3596 21.5656C67.9922 20.9188 68.3083 19.9657 68.3083 18.7062C68.3083 17.4467 67.9922 16.5021 67.3596 15.8724C66.7271 15.2256 65.9922 14.9023 65.1545 14.9023C64.2829 14.9023 63.5307 15.2256 62.8983 15.8724C62.2829 16.5021 61.9751 17.4467 61.9751 18.7062C61.9751 19.9657 62.2829 20.9188 62.8983 21.5656C63.5307 22.1953 64.2743 22.5102 65.1289 22.5102Z"
      fill="#1F2A37"
    />
    <path
      d="M74.576 25.0377V12.3747H77.499L77.8067 14.7491C78.2681 13.932 78.8921 13.2852 79.6784 12.8087C80.4818 12.3152 81.4219 12.0684 82.4988 12.0684V15.5149H81.5758C80.8578 15.5149 80.2168 15.6256 79.6527 15.8468C79.0886 16.0681 78.6442 16.4511 78.3195 16.9957C78.0118 17.5403 77.8579 18.2977 77.8579 19.2678V25.0377H74.576Z"
      fill="#1F2A37"
    />
    <path
      d="M90.9213 25.0377C89.588 25.0377 88.5197 24.7142 87.7162 24.0675C86.9129 23.4208 86.5111 22.2719 86.5111 20.6209V15.1064H84.3318V12.3747H86.5111L86.8957 8.97923H89.7931V12.3747H93.2289V15.1064H89.7931V20.6465C89.7931 21.2591 89.9213 21.6847 90.1776 21.9229C90.4512 22.1443 90.9128 22.2548 91.5622 22.2548H93.152V25.0377H90.9213Z"
      fill="#1F2A37"
    />
    <path
      d="M98.3287 10.4089C97.7304 10.4089 97.2347 10.2302 96.8416 9.87279C96.4655 9.51537 96.2775 9.06433 96.2775 8.51969C96.2775 7.97505 96.4655 7.53253 96.8416 7.19213C97.2347 6.83471 97.7304 6.656 98.3287 6.656C98.927 6.656 99.4141 6.83471 99.7902 7.19213C100.183 7.53253 100.38 7.97505 100.38 8.51969C100.38 9.06433 100.183 9.51537 99.7902 9.87279C99.4141 10.2302 98.927 10.4089 98.3287 10.4089ZM96.6877 25.0377V12.3747H99.9696V25.0377H96.6877Z"
      fill="#1F2A37"
    />
    <path
      d="M108.652 25.344C107.523 25.344 106.532 25.1653 105.677 24.8078C104.823 24.4334 104.139 23.9228 103.626 23.276C103.113 22.6292 102.805 21.8804 102.703 21.0294H106.011C106.113 21.5229 106.387 21.9485 106.831 22.3059C107.293 22.6463 107.882 22.8165 108.6 22.8165C109.318 22.8165 109.839 22.6719 110.164 22.3825C110.506 22.0932 110.677 21.7613 110.677 21.3869C110.677 20.8422 110.438 20.4763 109.959 20.289C109.481 20.0849 108.814 19.889 107.959 19.7019C107.412 19.5827 106.857 19.4381 106.293 19.2678C105.729 19.0976 105.207 18.8849 104.729 18.6296C104.267 18.3572 103.891 18.0169 103.6 17.6084C103.31 17.1828 103.165 16.6638 103.165 16.0511C103.165 14.9277 103.609 13.9831 104.498 13.2173C105.404 12.4513 106.669 12.0684 108.293 12.0684C109.797 12.0684 110.993 12.4173 111.882 13.1151C112.788 13.8129 113.327 14.7745 113.498 16H110.395C110.207 15.0639 109.498 14.5958 108.267 14.5958C107.652 14.5958 107.173 14.715 106.831 14.9532C106.506 15.1916 106.344 15.4894 106.344 15.8468C106.344 16.2213 106.592 16.5192 107.087 16.7404C107.583 16.9617 108.241 17.1658 109.062 17.3531C109.951 17.5574 110.763 17.7871 111.498 18.0424C112.25 18.2807 112.848 18.6467 113.292 19.1402C113.737 19.6168 113.959 20.306 113.959 21.2082C113.976 21.991 113.771 22.6973 113.344 23.3271C112.916 23.9569 112.301 24.4504 111.498 24.8078C110.694 25.1653 109.746 25.344 108.652 25.344Z"
      fill="#1F2A37"
    />
  </svg>
)

export const HortisTextLogo = () => (
  <div className="flex items-center">
    <HortisTextLogoSvg />
  </div>
)
