import {R, pipe} from "@mobily/ts-belt"
import {useGetCollectionSiteFromNamesQuery} from "generated/graphql"
import {useAccessTokenStruct} from "../use-access-token"
import {usePlaceStruct} from "./place"

export const useCollectionSiteFromUrl = () => {
  const accessToken = useAccessTokenStruct()
  const place = usePlaceStruct()
  const pause = place.data == null || accessToken.data == null
  const [{data, fetching, error}] = useGetCollectionSiteFromNamesQuery({
    variables: {
      organisationSubdomain: place.data?.orgName ?? "",
      collectionSiteSlug: place.data?.siteSlug ?? "",
    },
    pause,
  })

  if (accessToken.loading || place.loading || fetching) {
    return undefined
  }

  if (accessToken.error != null || accessToken.data == null) {
    return R.Error(accessToken.error ?? new Error("No access token"))
  }
  if (place.error != null || place.data == null) {
    return R.Error(place.error ?? new Error("Place not found"))
  }
  if (error != null || data == null) {
    return R.Error(error ?? new Error("Query data not found"))
  }

  const site = data.org?.site
  if (site == null) {
    return R.Error(new Error("Site missing from query response"))
  }

  return R.Ok(site)
}

export const useCollectionSiteSettings = () =>
  pipe(
    useCollectionSiteFromUrl() ?? R.Error(new Error("loading")),
    R.match(
      ({
        tagNumbersEnabled,
        ipenNumbersEnabled,
        identificationFieldsEnabled,
        public: isPublic,
        sexEnabled,
      }) => ({
        ipenNumbersEnabled,
        tagNumbersEnabled,
        identificationFieldsEnabled,
        isPublic,
        sexEnabled,
      }),
      () => ({
        ipenNumbersEnabled: false,
        tagNumbersEnabled: false,
        identificationFieldsEnabled: false,
        isPublic: false,
        sexEnabled: false,
      }),
    ),
  )

export type CollectionSiteSettings = ReturnType<
  typeof useCollectionSiteSettings
>
