import type {MutableRefObject} from "react"
import {useEffect, useCallback, useRef} from "react"

/**
 * Use with `useCallback` in order to expose a `mounted` ref that will
 * indicate if it's safe to call `setState` in an async context.
 * If this check isn't performed, a React component may be long gone,
 * unmounted before the awaited Promise resolves.
 * This will give you (sometimes) errors in the dev tools console
 * @example
 * ```typescript
 * const [state, setState] = useState<number>()
 * useMountAware(
 *   useCallback(
 *     async (mounted, foo: string, bar: number) => {
 *        await someAsyncCall(foo)
 *        if (mounted.current) {
 *          setState(bar)
 *        }
 *     }
 *   )
 * )
 * ```
 * An alternative would be an `useIsMounted` hook that exposes the ref in almost the same way.
 * Despite https://reactjs.org/blog/2015/12/16/ismounted-antipattern.html,
 * it's not clear how to deal with `await`ing in `useCallback`s without this function.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useMountAware = <Args extends Array<any>, Result>(
  callback: (mounted: MutableRefObject<boolean>, ...args: Args) => Result,
): ((...callback: Args) => Result) => {
  const mounted = useRef(false)

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  return useCallback((...args: Args) => callback(mounted, ...args), [callback])
}
