/*
 * Code is originally from this package:
 * https://github.com/haldarmahesh/use-mobile-detect-hook
 */
const getMobileDetect = (userAgent: string) => {
  const isAndroid = () => /android/i.test(userAgent)
  const isIos = () => /iphone|ipod/i.test(userAgent)
  const isIpad = () => /ipad/i.test(userAgent)
  const isOpera = () => /opera mini/i.test(userAgent)
  const isWindows = () => /iemobile/i.test(userAgent)
  const isSSR = () => /ssr/i.test(userAgent)

  const isMobile = () => isAndroid() || isIos() || isOpera() || isWindows()
  const isDesktop = () => !isMobile() && !isSSR()
  return {
    isMobile,
    isDesktop,
    isAndroid,
    isIpad,
    isIos,
    isSSR,
  }
}
export const useMobileDetect = () => {
  const userAgent =
    typeof navigator === "undefined" ? "SSR" : navigator.userAgent
  return getMobileDetect(userAgent)
}
