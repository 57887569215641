import type {BaseMapFieldsFragment} from "generated/graphql"
import {BingLayer} from "src/components/map/layers/bing-layer"
import {CustomBaseMapLayer} from "src/components/map/layers/custom-base-map-layer"
import {CurrentPositionLayer} from "src/features/collection-map/components/current-position-layer"
import {useMapOptionsStore} from "src/features/collection-map/components/map-options-store"

export const BaseMapLayers = ({
  baseMap,
}: {
  baseMap?: BaseMapFieldsFragment | null
}) => {
  const {layerType, devicePositionLayerEnabled} = useMapOptionsStore()
  return (
    <>
      {baseMap != null && (
        <CustomBaseMapLayer baseMap={baseMap} show={layerType === "custom"} />
      )}
      <BingLayer show={layerType === "bing"} />
      {devicePositionLayerEnabled && <CurrentPositionLayer />}
    </>
  )
}
