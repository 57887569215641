import {Button} from "@hortis/ui/button"
import {
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@hortis/ui/dialog"
import {useArchiveMaterialMutation} from "generated/graphql"
import {useSnackbarStore} from "src/components/snackbar-controller/snackbar-store"
import {onFailure, onSuccess} from "src/notification-snack-utils"

export const TrashMaterialDialogContent = ({
  id,
  onSuccess: onSuccessProp,
}: {
  id: string
  onSuccess?: () => void
}) => {
  const {setSnack} = useSnackbarStore()
  const [{fetching}, archiveMaterial] = useArchiveMaterialMutation()

  const handleSubmit = async () => {
    const res = await archiveMaterial({
      materialId: id,
    })

    if (res.data?.archivePlantMaterial?.__typename !== "PlantMaterial") {
      onFailure(setSnack)(new Error(`Failed to trash material`))
      return
    }

    onSuccess(setSnack)("Successfully trashed material")
    onSuccessProp?.()
  }

  return (
    <DialogContent data-cy="trash-material-dialog">
      <DialogHeader>
        <DialogTitle>Move to trash?</DialogTitle>
        <DialogDescription>
          This will move 1 plant material record to your site trash.
        </DialogDescription>
      </DialogHeader>
      <DialogFooter>
        <DialogClose asChild>
          <Button size="lg" fullWidth testId="trash-material-cancel">
            Cancel
          </Button>
        </DialogClose>
        <Button
          size="lg"
          onClick={handleSubmit}
          loading={fetching}
          variant="warning"
          fullWidth
          testId="trash-material-button"
        >
          Move to trash
        </Button>
      </DialogFooter>
    </DialogContent>
  )
}
