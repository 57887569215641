import {zodResolver} from "@hookform/resolvers/zod"
import {Button, IconButton} from "@hortis/ui/button"
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@hortis/ui/dialog"
import {XClose} from "@hortis/ui/icons"
import {
  useCreateTaxonMutation,
  useGetOrganisationFromNameQuery,
} from "generated/graphql"
import {useMemo} from "react"
import {FormProvider, useForm} from "react-hook-form"
import {TaxonScientificNameAutocomplete} from "src/components/autocomplete/scientific-names/taxon-scientific-name-autocomplete"
import {useSnackbarStore} from "src/components/snackbar-controller/snackbar-store"
import {
  baseTaxonFieldsSchema,
  type BaseTaxonFields,
} from "src/features/taxonomy/components/new-taxon/schema"
import {onFailure, onSuccess} from "src/notification-snack-utils"
import type {Nullable} from "src/types/nullable"
import {useOrganisationSubdomainStruct} from "src/utils/hooks/place"
import {match} from "ts-pattern"

export interface NewTaxonFormProps {
  scientificName?: string | null
  onSuccess: (newTaxon: {id: string; scientificName: string}) => void
  onOpenChange: (open: boolean) => void
}

const NewTaxonForm = ({
  onOpenChange,
  scientificName,
  onSuccess: onSubmit,
}: NewTaxonFormProps) => {
  const subdomain = useOrganisationSubdomainStruct()
  const [{data: orgData}] = useGetOrganisationFromNameQuery({
    variables: {organisationSubdomain: subdomain.data ?? ""},
    pause: subdomain.data == null,
  })
  const {setSnack} = useSnackbarStore()
  const [, createTaxon] = useCreateTaxonMutation()
  const formMethods = useForm<Nullable<BaseTaxonFields>>({
    defaultValues: {
      scientificName: scientificName ?? null,
      sharedScientificName: null,
    },
    criteriaMode: "all",
    resolver: zodResolver(baseTaxonFieldsSchema),
  })
  const sharedScientificName = formMethods.watch("sharedScientificName")

  const organisationId = orgData?.org?.id

  const handleSubmit = useMemo(
    () =>
      formMethods.handleSubmit(async (_data) => {
        if (organisationId == null) {
          onFailure(setSnack)(
            new Error(`Failed to create taxon, organization was not found`),
          )
          return
        }
        try {
          const {sharedScientificName, ...data} = _data as BaseTaxonFields
          const res = await createTaxon({
            input: {
              ...data,
              sharedScientificNameId: sharedScientificName?.id ?? null,
              organisationId,
            },
          })
          if (
            res.data?.createTaxon.success === true &&
            res.data.createTaxon.taxon != null
          ) {
            onSuccess(setSnack)(`Successfully created taxon`)
            onOpenChange(false)
            onSubmit(res.data.createTaxon.taxon)
            return
          }

          const errors = res.data?.createTaxon.errors
          if (errors != null && errors.length > 0) {
            errors.forEach((error) => {
              match(error.__typename)
                .with("DuplicateScientificNameError", () => {
                  formMethods.setError("scientificName", {
                    message: error.message,
                  })
                })
                .exhaustive()
            })
            return
          }
          throw new Error("Failed to create taxon")
        } catch {
          onFailure(setSnack)(new Error(`Failed to create taxon`))
        }
      }),
    [
      createTaxon,
      formMethods,
      setSnack,
      onSubmit,
      organisationId,
      onOpenChange,
    ],
  )

  return (
    <FormProvider {...formMethods}>
      <form className="flex flex-col gap-6">
        <DialogHeader>
          <DialogTitle>New taxon</DialogTitle>
          <DialogDescription>
            Validate your scientific name by selecting an option from the drop
            down list.
          </DialogDescription>
        </DialogHeader>
        <TaxonScientificNameAutocomplete
          name="scientificName"
          testId="new-taxon-scientific-name"
          focusOnMount
          required
        />
        <DialogFooter>
          <Button
            loading={
              formMethods.formState.isSubmitting &&
              formMethods.getValues("sharedScientificName") == null
            }
            containerCss={{flex: 1}}
            size="lg"
            onClick={handleSubmit}
            testId="new-taxon-without-validation-button"
            disabled={sharedScientificName != null}
          >
            Create unvalidated
          </Button>
          <Button
            loading={
              formMethods.formState.isSubmitting &&
              formMethods.getValues("sharedScientificName") != null
            }
            containerCss={{flex: 1}}
            size="lg"
            variant="primary"
            onClick={handleSubmit}
            testId="new-taxon-submit"
            disabled={sharedScientificName == null}
          >
            Create
          </Button>
        </DialogFooter>
      </form>
    </FormProvider>
  )
}

export const NewTaxonModal = ({
  onOpenChange,
  onSuccess,
  scientificName,
  open,
}: {
  open: boolean
  onOpenChange: (open: boolean) => void
} & NewTaxonFormProps) => (
  <Dialog open={open} onOpenChange={onOpenChange}>
    <DialogContent className="max-w-md">
      <NewTaxonForm
        onSuccess={onSuccess}
        onOpenChange={onOpenChange}
        scientificName={scientificName}
      />
      <DialogClose asChild>
        <IconButton
          ariaLabel="Close"
          className="!absolute !right-4 !top-4"
          size="xs"
          variant="tertiaryGray"
          testId="close-new-taxon-modal"
          icon={XClose}
        />
      </DialogClose>
    </DialogContent>
  </Dialog>
)
