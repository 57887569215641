import {useMemo, useRef} from "react"
import type {RecordTagFieldsFragment} from "generated/graphql"
import {IconButton} from "@hortis/ui/button"
import {Menu} from "src/components/menu"
import {colors} from "src/colors"
import {useToggle} from "src/utils/hooks/toggle"
import {Edit3, Trash3, DotsVertical} from "@hortis/ui/icons"
import {renderTagRowWithComponent} from "../fixed-list-tag-row"
import type {TagRowProps} from "../fixed-list-tag-row"
import {useDrawContext} from "../drawer-context"

const anchorOrigin = {
  vertical: "bottom",
  horizontal: "right",
} as const

const transformOrigin = {
  vertical: "top",
  horizontal: "right",
} as const

export const TagActionsMenu = ({tag}: {tag: RecordTagFieldsFragment}) => {
  const anchorRef = useRef(null)
  const [open, toggleOpen] = useToggle()
  const drawerContext = useDrawContext()

  const options = useMemo(
    () => [
      {
        testId: "rename-tag-menu-item",
        value: "rename",
        onClick: () => {
          drawerContext?.openEditDrawer(tag)
          toggleOpen()
        },
        label: (
          <div className="flex items-center gap-3">
            <Edit3 size="16px" />
            Edit
          </div>
        ),
      },
      {
        testId: "delete-tag-menu-item",
        value: "delete",
        onClick: () => {
          drawerContext?.openDeleteModal(tag)
          toggleOpen()
        },
        label: (
          <div className="flex items-center gap-3">
            <Trash3 size="16px" />
            Delete
          </div>
        ),
      },
    ],
    [tag, toggleOpen, drawerContext],
  )

  return (
    <>
      <IconButton
        ref={anchorRef}
        ariaLabel={`edit tag ${tag.name}`}
        testId="open-tag-action-menu"
        variant="tertiaryGray"
        size="sm"
        containerCss={{
          backgroundColor: "transparent !important",
          "&:hover": {backgroundColor: `${colors.grey[100]} !important`},
        }}
        onClick={toggleOpen}
        icon={DotsVertical}
      />
      <Menu
        TransitionProps={{unmountOnExit: true}}
        anchorEl={anchorRef}
        onClose={toggleOpen}
        open={open}
        options={options}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      />
    </>
  )
}

export const TagMobileRow = ({tag, canEdit}: TagRowProps) => {
  return (
    <div
      key={tag.id}
      className="flex h-16 max-w-full items-center gap-3 border-b border-grey-200 px-4 md:px-6"
    >
      <div
        className="flex-1 truncate text-sm font-medium text-grey-900"
        data-cy="tag-name-mobile"
      >
        {tag.name}
      </div>
      {canEdit && <TagActionsMenu tag={tag} />}
    </div>
  )
}

export const renderTagListRow = renderTagRowWithComponent(TagMobileRow)
