import {zodResolver} from "@hookform/resolvers/zod"
import {Button} from "@hortis/ui/button"
import {DatePicker} from "@hortis/ui/date-picker"
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@hortis/ui/dialog"
import {Dot} from "@hortis/ui/dot"
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@hortis/ui/form"
import {A, pipe} from "@mobily/ts-belt"
import {
  PlantMaterialStatus,
  useUpdatePlantMaterialsMutation,
} from "generated/graphql"
import {useState} from "react"
import {useForm} from "react-hook-form"
import {useSnackbarStore} from "src/components/snackbar-controller/snackbar-store"
import {
  onFailure,
  onSuccess as onSuccessSnack,
} from "src/notification-snack-utils"
import {createDateAsUTC} from "src/utils/convert-dates-to-iso"
import {twMerge} from "tailwind-merge"
import {z} from "zod"
import {CommandEmpty, CommandGroup, CommandItem} from "@hortis/ui/command"

const FormSchema = z.object({
  dateRemoved: z.date({
    required_error: "Date removed is required",
  }),
})

interface CommandMenuStatusPageProps {
  selectedMaterials: ReadonlyArray<{id: string; status: PlantMaterialStatus}>
  onSuccess: () => void
}

const CommandMenuStatusPage = ({
  selectedMaterials,
  onSuccess,
}: CommandMenuStatusPageProps) => {
  const {setSnack} = useSnackbarStore()
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {dateRemoved: new Date()},
  })
  const [_, updatePlantMaterials] = useUpdatePlantMaterialsMutation()
  const [loading, setLoading] = useState<PlantMaterialStatus | false>(false)
  const [absentDialogOpen, setAbsentDialogOpen] = useState(false)

  const handleStatusSelect = async (
    status: PlantMaterialStatus,
    dateRemoved?: Date,
  ) => {
    const ids = pipe(
      selectedMaterials,
      A.filter((m) => m.status !== status),
      A.map(({id}) => id),
    )
    if (ids.length === 0) {
      onSuccess()
      return
    }
    if (status === PlantMaterialStatus.Absent && dateRemoved == null) {
      onFailure(setSnack)(new Error("Date removed is required"))
      return
    }
    setLoading(status)
    const res = await updatePlantMaterials({
      ids,
      input: {
        status,
        firstAbsent:
          status === PlantMaterialStatus.Absent
            ? dateRemoved == null
              ? undefined
              : createDateAsUTC(dateRemoved)
            : null,
      },
    })
    setLoading(false)
    if (res.data?.updatePlantMaterials.success !== true) {
      onFailure(setSnack)(new Error("Failed to update materials"))
      return
    }
    onSuccessSnack(setSnack)(
      `Successfully updated ${
        res.data.updatePlantMaterials.plantMaterials.length
      } material${
        res.data.updatePlantMaterials.plantMaterials.length > 1 ? "s" : ""
      }`,
    )
    onSuccess()
  }

  const onSubmit = (data: z.infer<typeof FormSchema>) =>
    handleStatusSelect(PlantMaterialStatus.Absent, data.dateRemoved)

  return (
    <>
      <CommandGroup data-cy="command-menu-status-page">
        <CommandItem
          onSelect={() => handleStatusSelect(PlantMaterialStatus.Present)}
          loading={loading === PlantMaterialStatus.Present}
          disabled={loading !== false}
          data-cy="present-status-menu-item"
          shortcut="P"
        >
          <Dot
            size="lg"
            color="primary"
            // eslint-disable-next-line sonarjs/no-duplicate-string
            className={twMerge(loading !== false && "opacity-50")}
          />
          Present
        </CommandItem>
        <CommandItem
          onSelect={() => handleStatusSelect(PlantMaterialStatus.Transient)}
          loading={loading === PlantMaterialStatus.Transient}
          disabled={loading !== false}
          data-cy="transient-status-menu-item"
          shortcut="T"
        >
          <Dot
            size="lg"
            color="warning"
            className={twMerge(loading !== false && "opacity-50")}
          />
          Transient
        </CommandItem>
        <CommandItem
          onSelect={() => handleStatusSelect(PlantMaterialStatus.Unknown)}
          loading={loading === PlantMaterialStatus.Unknown}
          disabled={loading !== false}
          data-cy="unknown-status-menu-item"
          shortcut="U"
        >
          <Dot
            size="lg"
            color="error"
            className={twMerge(loading !== false && "opacity-50")}
          />
          Unknown
        </CommandItem>
        <CommandItem
          onSelect={() => {
            setAbsentDialogOpen(true)
          }}
          loading={loading === PlantMaterialStatus.Absent}
          disabled={loading !== false}
          data-cy="absent-status-menu-item"
          shortcut="A"
        >
          <Dot
            size="lg"
            color="grey"
            className={twMerge(loading !== false && "opacity-50")}
          />
          Absent
        </CommandItem>
      </CommandGroup>
      <CommandEmpty>No results</CommandEmpty>
      <Dialog open={absentDialogOpen} onOpenChange={setAbsentDialogOpen}>
        <DialogContent data-cy="make-absent-dialog">
          <Form {...form}>
            <form className="space-y-8" onSubmit={form.handleSubmit(onSubmit)}>
              <div className="space-y-6">
                <DialogHeader>
                  <DialogTitle>Make materials absent</DialogTitle>
                  <DialogDescription>
                    Please enter the date the materials were removed.
                  </DialogDescription>
                </DialogHeader>
                <FormField
                  control={form.control}
                  name="dateRemoved"
                  render={({field}) => (
                    <FormItem>
                      <FormLabel>Date removed</FormLabel>
                      <DatePicker data-cy="date-removed-picker" {...field} />
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <DialogFooter>
                <DialogClose asChild>
                  <Button size="lg" fullWidth>
                    Cancel
                  </Button>
                </DialogClose>
                <Button
                  size="lg"
                  variant="primary"
                  type="submit"
                  loading={loading === PlantMaterialStatus.Absent}
                  fullWidth
                  testId="make-absent-confirm"
                >
                  Confirm
                </Button>
              </DialogFooter>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </>
  )
}

export {CommandMenuStatusPage}
