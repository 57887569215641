import type {IconProps} from "./types"

export const SlChevronDownIcon = ({
  style,
  width,
  height,
  color,
  className,
}: IconProps): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? "24"}
      height={height ?? "24"}
      viewBox="0 0 24 24"
      fill="none"
      className={className}
      style={style}
    >
      <path
        d="M6 9L12 15L18 9"
        stroke={color ?? "#757575"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const ChevronDown = ({
  style,
  width,
  height,
  color,
  className,
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width ?? "24"}
    height={height ?? "24"}
    viewBox="0 0 24 24"
    fill="none"
    className={className}
    style={style}
  >
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M16.2 9.59998L12.4736 14.3912C12.4176 14.4633 12.3457 14.5216 12.2637 14.5618C12.1816 14.6019 12.0914 14.6228 12 14.6228C11.9087 14.6228 11.8185 14.6019 11.7364 14.5618C11.6544 14.5216 11.5825 14.4633 11.5264 14.3912L7.80005 9.59998"
      stroke={color ?? "#424242"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
