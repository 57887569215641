import {Button} from "@hortis/ui/button"
import {Dialog} from "@hortis/ui/dialog"
import {getRouteApi} from "@tanstack/react-router"
import * as RA from "fp-ts/ReadonlyArray"
import {pipe} from "fp-ts/function"
import type {RecordTagFieldsFragment} from "generated/graphql"
import {
  useArchiveRecordTagMutation,
  useOrganisationTagsQuery,
} from "generated/graphql"
import {useCallback, useMemo, useState} from "react"
import {Helmet} from "react-helmet-async"
import {colors} from "src/colors"
import {DeleteModal} from "src/components/dialog-mui/delete-dialog/delete-dialog"
import {SlAddIcon} from "src/components/icons/streamline/add"
import {useSnackbarStore} from "src/components/snackbar-controller/snackbar-store"
import {useAccessRole} from "src/features/permissions/use-access-role"
import {EditTagDialogContent} from "src/features/tags/edit-modal/edit-modal"
import {onFailure, onSuccess} from "src/notification-snack-utils"
import {useSmallMobile} from "src/utils/hooks/media-query"
import {useOrganisationSubdomainBasic} from "src/utils/hooks/place"
import withAuthenticationRequired from "src/utils/with-authentication-required"
import {twMerge} from "tailwind-merge"
import {SearchBar} from "../../components/search-bar"
import {TableTitle} from "../../components/table-title"
import {TagsDesktop} from "./desktop/tags-desktop"
import type {CurrentTag} from "./drawer-context"
import {DrawerContext} from "./drawer-context"
import {TagsMobile} from "./mobile/tags-mobile"
import type {OrgTagTab} from "./routes"
import {orgTabs} from "./routes"
import {TagTabs} from "./tabs"

const routeApi = getRouteApi("/settings/org/tags")

export function OrgTags() {
  const [searchTerm, setSearchTerm] = useState("")
  const organisationName = useOrganisationSubdomainBasic()
  const {setSnack} = useSnackbarStore()
  const {canEdit} = useAccessRole()
  const {tab} = routeApi.useSearch()
  const navigate = routeApi.useNavigate()

  const [currentTag, setCurrentTag] = useState<CurrentTag>()
  const [deleteTag, setDeleteTag] = useState<RecordTagFieldsFragment | null>(
    null,
  )
  const [editDrawerOpen, setEditDrawerOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [, archiveTag] = useArchiveRecordTagMutation()

  const openEditDrawer = useCallback((props?: CurrentTag) => {
    setCurrentTag(props)
    setEditDrawerOpen(true)
  }, [])
  const openAddModal = useCallback(() => {
    setCurrentTag(undefined)
    setEditDrawerOpen(true)
  }, [])
  const closeEditDrawer = useCallback(() => {
    setEditDrawerOpen(false)
  }, [])

  const onTabChange = useCallback(
    (tab: OrgTagTab) => {
      void navigate({search: {tab}})
    },
    [navigate],
  )

  const [{data}, refetchTags] = useOrganisationTagsQuery({
    variables: {
      organisationSubdomain: organisationName ?? "",
      filters: {
        hasMaterialRelations: tab === "materials" ? {eq: true} : undefined,
        hasAccessionRelations: tab === "accessions" ? {eq: true} : undefined,
        hasTaxaRelations: tab === "taxa" ? {eq: true} : undefined,
        hasCollectionSiteRelation: {neq: true},
      },
    },
    pause: organisationName == null,
  })
  const tags = data?.org?.tags?.nodes

  const handleEditSubmit = useCallback(() => {
    setEditDrawerOpen(false)
    refetchTags()
  }, [refetchTags])

  const handleClearSearch = useCallback(() => {
    setSearchTerm("")
  }, [])

  const handleSearchChange = useCallback((val: string) => {
    setSearchTerm(val)
  }, [])

  const openDeleteModal = useCallback((tag: RecordTagFieldsFragment) => {
    setDeleteTag(tag)
    setDeleteModalOpen(true)
  }, [])
  const closeDeleteModal = useCallback(() => {
    setDeleteModalOpen(false)
  }, [])

  const handleDeleteTag = useCallback(async () => {
    if (deleteTag != null) {
      try {
        const res = await archiveTag({
          id: deleteTag.id,
        })
        if (res.data?.archiveRecordTag?.success === true) {
          onSuccess(setSnack)("Tag deleted")
          refetchTags()
        } else {
          throw new Error("Failed to delete tag")
        }
      } catch {
        onFailure(setSnack)(new Error(`Failed to delete tag`))
      }
      closeDeleteModal()
      setDeleteTag(null)
    }
  }, [archiveTag, refetchTags, deleteTag, closeDeleteModal, setSnack])

  const filteredTags = useMemo(() => {
    return tags == null
      ? tags
      : pipe(
          tags,
          RA.filter(({name}) =>
            name.toLowerCase().includes(searchTerm.toLowerCase()),
          ),
        )
  }, [searchTerm, tags])

  const context = useMemo(
    () => ({openEditDrawer, openDeleteModal}),
    [openEditDrawer, openDeleteModal],
  )

  return (
    <div className="flex h-full max-h-full w-full flex-1 flex-col items-center p-0 pt-4 lg:p-6 lg:pt-24">
      <Helmet>
        <title>Tags | Hortis</title>
      </Helmet>
      <div
        data-cy="tags-card"
        className="flex max-h-full w-full flex-1 flex-col lg:max-w-2xl"
      >
        <div
          className={twMerge(
            "flex flex-1 flex-col",
            canEdit && "mb-[65px] md:mb-0",
          )}
        >
          <TableTitle
            title="Record tags"
            subtitle="You can manage your organization tags here."
            totalLabel="tag"
            total={tags?.length}
            pluralTotalLabel
          />
          <div className="px-4 pb-4 md:px-6 lg:px-0">
            <div className="flex gap-5 py-2 sm:py-4">
              <SearchBar
                testId="tag-search"
                value={searchTerm}
                onChange={handleSearchChange}
                onClearSearch={handleClearSearch}
                placeholder="Search tags"
              />
              {canEdit && (
                <Button
                  testId="new-tag-button"
                  onClick={openAddModal}
                  startIcon={<SlAddIcon color={colors.white} />}
                  variant="primary"
                  className="hidden shrink-0 md:flex"
                >
                  New tag
                </Button>
              )}
            </div>
            <TagTabs
              currentTab={tab}
              onTabChange={onTabChange}
              tabs={orgTabs}
            />
          </div>
          <DrawerContext.Provider value={context}>
            {useSmallMobile({defaultMatches: false}) ? (
              <TagsMobile tags={filteredTags} canEdit={canEdit} />
            ) : (
              <TagsDesktop tags={filteredTags} canEdit={canEdit} />
            )}
          </DrawerContext.Provider>
          {canEdit && (
            <div className="fixed bottom-0 left-0 right-0 flex w-full border-t border-grey-200 bg-white px-4 py-3 md:hidden">
              <Button
                testId="new-tag-button-mobile"
                onClick={openAddModal}
                fullWidth
                startIcon={<SlAddIcon color={colors.white} />}
                variant="primary"
              >
                New tag
              </Button>
              <Dialog open={editDrawerOpen} onOpenChange={setEditDrawerOpen}>
                <EditTagDialogContent
                  open={editDrawerOpen}
                  onClose={closeEditDrawer}
                  onSubmit={handleEditSubmit}
                  tag={currentTag}
                  existingTags={tags}
                  site={null}
                />
              </Dialog>
              <DeleteModal
                title="Delete tag"
                body={`Are you sure you want to delete this tag? This will remove ${
                  deleteTag?.name == null ? "the tag" : `“${deleteTag.name}”`
                } from existing records.`}
                onSubmit={handleDeleteTag}
                open={deleteModalOpen}
                onClose={closeDeleteModal}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export const Component = withAuthenticationRequired(OrgTags, {
  onRedirecting: function OnRedirect() {
    return <OrgTags />
  },
})
