import Alert from "@mui/material/Alert"
import type {ReactElement, ReactNode} from "react"
import {Helmet} from "react-helmet-async"
import {fromSxFn} from "../utils/sx"

interface Props {
  children: ReactNode
  title: string
}

const alertStyle = fromSxFn((theme) => ({
  width: "100%",
  marginBottom: theme.spacing(2),
  maxWidth: theme.spacing(38),
}))

export const RenderError = ({children, title}: Props): ReactElement => {
  return (
    <>
      <Helmet>
        <title>{title} | Hortis</title>
        <meta property="og:title" content={`${title} | Hortis`} key="title" />
      </Helmet>
      <div className="flex items-center justify-center p-6">
        <Alert data-cy="error-alert" severity="error" sx={alertStyle}>
          {children}
        </Alert>
      </div>
    </>
  )
}
