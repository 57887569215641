import {zodResolver} from "@hookform/resolvers/zod"
import {Button} from "@hortis/ui/button"
import {
  SheetBody,
  SheetFooter,
  SheetForm,
  SheetHeader,
  SheetTitle,
} from "@hortis/ui/sheet"
import * as E from "fp-ts/Either"
import * as TE from "fp-ts/TaskEither"
import {flow, pipe} from "fp-ts/function"
import {useEffect, useMemo} from "react"
import {FormProvider, useForm} from "react-hook-form"
import type {CollectionData} from "src/features/create-accession/components/collection-data-form/schema"
import {CollectionDataSchemaZod} from "src/features/create-accession/components/collection-data-form/schema"
import {parseUtcDate} from "src/utils/dates/parse-utc-date"
import type {AccessionFieldsFragment} from "../../../../../../../generated/graphql"
import {useSnackbarStore} from "../../../../../../components/snackbar-controller/snackbar-store"
import {convertDatesToISO} from "../../../../../../utils/convert-dates-to-iso"
import * as OE from "../../../../../../utils/option-either"
import {removeEmptyValues} from "../../../../../../utils/remove-empty-values"
import {useAccessToken} from "../../../../../../utils/use-access-token"
import {CollectionDataFields} from "../../../../../create-accession/components/collection-data-form/collection-data-fields"
import {showErrorMessage} from "../../../../../create-accession/components/create-accession/utils"
import {updateCollectionData} from "../../../fetchers"
import type {BaseEditStageProps} from "../types"
import {onUpdateAccessionFailure, onUpdateAccessionSuccess} from "../utils"

export const mapAutocompleteOptions = (data: CollectionData) => {
  const {countryOption, ...rest} = data
  return {
    ...rest,
    collectionCountryCode: countryOption ? countryOption.codeId : null,
  }
}

const cleanFormData = flow(
  mapAutocompleteOptions,
  convertDatesToISO,
  removeEmptyValues,
)

const updateCollectionDataTask = TE.tryCatchK(updateCollectionData, E.toError)

export const extractCollectionDataFormFields = (
  accession: AccessionFieldsFragment,
) => ({
  collectionDate:
    accession.collectionDate == null
      ? null
      : parseUtcDate(accession.collectionDate),
  collectionCollectors: accession.collectionCollectors ?? null,
  collectionCollectorsFieldNumber:
    accession.collectionCollectorsFieldNumber ?? null,
  collectionHabitat: accession.collectionHabitat ?? null,
  collectionAssociatedTaxa: accession.collectionAssociatedTaxa ?? null,
  collectionRemarks: accession.collectionRemarks ?? null,
  countryOption:
    accession.collectionCountry == null
      ? null
      : {
          codeId: accession.collectionCountry.codeId,
          name: accession.collectionCountry.name,
        },
  collectionStateProvince: accession.collectionStateProvince ?? null,
  collectionLocality: accession.collectionLocality ?? null,
  collectionLatitude: accession.collectionLatitude ?? null,
  collectionLongitude: accession.collectionLongitude ?? null,
  collectionCoordinateUncertainty:
    accession.collectionCoordinateUncertainty ?? null,
  collectionElevation: accession.collectionElevation ?? null,
})

const resolver = zodResolver(CollectionDataSchemaZod)

export const EditCollectionData = ({
  accession,
  requestClose,
  refetch,
  open,
}: BaseEditStageProps): JSX.Element => {
  const {setSnack} = useSnackbarStore()
  const formMethods = useForm<CollectionData>({
    defaultValues: extractCollectionDataFormFields(accession),
    criteriaMode: "all",
    resolver,
  })
  const accessToken = useAccessToken()

  useEffect(() => {
    formMethods.reset(extractCollectionDataFormFields(accession))
  }, [open, formMethods, accession])

  const onSubmit = useMemo(
    () =>
      pipe(
        OE.Do,
        OE.apSW("accessToken", accessToken),
        OE.map(({accessToken}) =>
          formMethods.handleSubmit(async (data) => {
            // eslint-disable-next-line no-console
            console.log("Submitting form:", data)

            await pipe(
              updateCollectionDataTask(accessToken, {
                accessionId: accession.id,
                updates: cleanFormData(data),
              }),
              TE.match(
                onUpdateAccessionFailure(setSnack),
                onUpdateAccessionSuccess(
                  setSnack,
                  "Collection data updated",
                  () => {
                    refetch()
                    requestClose()
                  },
                ),
              ),
            )()
          }),
        ),
      ),
    [formMethods, accessToken, accession, setSnack, refetch, requestClose],
  )

  return pipe(
    OE.sequenceNoneToUndefined({
      onSubmit,
    }),
    E.match(
      showErrorMessage,
      ({onSubmit}): JSX.Element => (
        <FormProvider {...formMethods}>
          <SheetForm onSubmit={onSubmit}>
            <SheetHeader>
              <SheetTitle>Wild collection</SheetTitle>
            </SheetHeader>
            <SheetBody>
              <CollectionDataFields />
            </SheetBody>
            <SheetFooter>
              <Button testId="cancel-button" onClick={requestClose}>
                Cancel
              </Button>
              <Button
                testId={"confirm-button"}
                loading={formMethods.formState.isSubmitting}
                type="submit"
                variant="primary"
              >
                Save
              </Button>
            </SheetFooter>
          </SheetForm>
        </FormProvider>
      ),
    ),
  )
}
