import type {HTMLAttributes} from "react"
import type {LocationFieldsFragment} from "../../../generated/graphql"
import {Typography} from "../../components/typography"

export const renderLocationAutoCompleteItem = (
  props: HTMLAttributes<HTMLLIElement>,
  option: LocationFieldsFragment | null,
) => {
  const {...rest} = props
  return (
    <>
      {option != null && (
        <li
          style={{
            borderRadius: "8px",
            paddingTop: "6px",
            paddingBottom: "6px",
          }}
          key={option.id}
          {...rest}
        >
          <div className="flex-grow">
            {option.name}
            <br />
            <Typography color="text.secondary">
              {option.description ?? "-"}
            </Typography>
          </div>
        </li>
      )}
    </>
  )
}

export const getLocationOptionLabel = (
  option: LocationFieldsFragment | null,
) => (option === null ? "" : option.name)

export {default as isOptionEqual} from "fast-deep-equal/es6"
