import {LoadingPage} from "@hortis/ui/loading"
import {pipe, R} from "@mobily/ts-belt"
import {type ReactElement} from "react"
import {mapSlugToHrefs} from "src/utils/hooks/hrefs"
import {useStickyState} from "src/utils/hooks/sticky-state"
import {useAccessToken} from "src/utils/use-access-token"
import {
  useGetCollectionSiteQuery,
  useGetMeOrgWithSitesQuery,
} from "../../../../generated/graphql"
import {RenderError} from "../../../components/error"
import {useOrganisationSubdomainNameWhenPresent} from "../../../utils/hooks/organisation-subdomain-name"
import withAuthenticationRequired from "../../../utils/with-authentication-required"
import {processSitesData} from "./process-site-data"
import {Redirect} from "./redirect"

const showErrorMessage = (error: string) => (
  <RenderError title="Something when wrong">{error}</RenderError>
)

const RootRedirect = (): ReactElement => {
  const subdomain = useOrganisationSubdomainNameWhenPresent()
  const accessToken = useAccessToken()
  const [lastVisitedSiteId, _] = useStickyState("", "last-visited-siteId")
  const [{data: recentlyVisitedCollectionSite}] = useGetCollectionSiteQuery({
    variables: {id: lastVisitedSiteId},
    pause: lastVisitedSiteId === "",
  })
  const [{data, error, fetching}] = useGetMeOrgWithSitesQuery()

  if ((fetching && data?.me == null) || accessToken._tag === "None") {
    return <LoadingPage />
  }

  return pipe(
    processSitesData({data, error, subdomain}),
    R.match(
      ({collectionSites}) => {
        // Redirect if user lands on this page being a member of only one site
        const firstSite = collectionSites[0]
        if (collectionSites.length === 1 && firstSite != null) {
          const hrefs = mapSlugToHrefs(firstSite.urlSlug)
          return <Redirect url={`/${hrefs.dashboard}`} />
        }

        if (recentlyVisitedCollectionSite?.collectionSite?.urlSlug != null) {
          return (
            <Redirect
              url={`/${
                mapSlugToHrefs(
                  recentlyVisitedCollectionSite.collectionSite.urlSlug,
                ).dashboard
              }`}
            />
          )
        }
        return <Redirect url={`/sites`} />
      },
      (errorMessage) => showErrorMessage(errorMessage),
    ),
  )
}

export default withAuthenticationRequired(RootRedirect, {
  onRedirecting: function OnRedirect() {
    return <LoadingPage />
  },
})
