import type {ReactElement} from "react"
import {Link} from "@tanstack/react-router"
import type {OrganisationFieldsFragment} from "generated/graphql"
import {Avatar} from "@hortis/ui/avatar"
import {Typography} from "src/components/typography"

interface Props {
  organisation: OrganisationFieldsFragment
}

export const OrganisationTile = ({organisation}: Props): ReactElement => {
  return (
    <div className="mb-4 flex items-center md:mb-0">
      <Avatar
        size="md"
        variant="rounded"
        src={organisation.avatarUrl ?? undefined}
        textContent={
          organisation.name
            ? organisation.name
                .split(" ")
                .map((n) => n[0])
                .join("")
            : undefined
        }
      />
      <div className="ml-4">
        <Link to={`https://${organisation.subdomain}.hortis.com`}>
          <Typography variant="subtitle1" color="text.secondary">
            {organisation.name}
          </Typography>
        </Link>
      </div>
    </div>
  )
}
