import {PlantMaterialStatusToLabel} from "src/components/status-chip"
import type {FilterOperation, LocalMaterialFilter} from "../types"

export const extractStatusFilterData = (
  filter: NonNullable<LocalMaterialFilter["status"]>,
  operation: FilterOperation,
): Array<string> | undefined => {
  switch (operation) {
    case "is": {
      return filter.is?.map((status) => PlantMaterialStatusToLabel[status])
    }
    case "isNot": {
      return filter.isNot?.map((status) => PlantMaterialStatusToLabel[status])
    }
    default: {
      return undefined
    }
  }
}
