import type {
  MapMaterialListFieldsFragment,
  MaterialsListFieldsFragment,
} from "generated/graphql"
import {
  Command,
  CommandDialog,
  CommandHeader,
  CommandInput,
  CommandList,
} from "@hortis/ui/command"
import {CommandMenuLocationPage} from "src/components/command-menu/command-menu-pages/location"

interface LocationDialogProps {
  selectedRows: ReadonlyArray<
    MaterialsListFieldsFragment | MapMaterialListFieldsFragment
  >
  open: boolean
  onOpenChange: (open: boolean) => void
  title: string
  handleSuccess: () => void
}

const LocationDialog = ({
  title,
  selectedRows,
  open,
  onOpenChange,
  handleSuccess,
}: LocationDialogProps) => {
  return (
    <CommandDialog open={open} onOpenChange={onOpenChange}>
      <Command data-cy="context-menu-location-dialog" size="lg">
        <CommandHeader title={title} />
        <CommandInput
          placeholder="Change location..."
          data-cy="context-menu-search-filter"
        />
        <CommandList>
          <CommandMenuLocationPage
            onSuccess={handleSuccess}
            selectedMaterials={selectedRows}
          />
        </CommandList>
      </Command>
    </CommandDialog>
  )
}

export {LocationDialog}
