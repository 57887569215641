import type {RecordTagFilter} from "generated/graphql"
import type {LocalTagsFilter} from "./schema"

export const flattenTagsFilter = (
  filter: LocalTagsFilter | undefined,
): RecordTagFilter | undefined =>
  filter == null
    ? undefined
    : {
        id: {
          in: filter.is?.map(({id}) => id),
          nin: filter.isNot?.map(({id}) => id),
        },
        name: {
          contains: filter.contains,
        },
      }
