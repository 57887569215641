import * as E from "fp-ts/Either"
import {flow} from "fp-ts/function"
import * as t from "io-ts"
import type {Config} from "../../config"

export const staticHeaders = {
  "x-hortis-client": "hortis-app",
} as const

type BuildVariables = Pick<Config, "buildNumber" | "buildHash">

const getCommonHeaders = flow(
  (props: BuildVariables) =>
    props.buildNumber != null && props.buildHash != null
      ? E.right(props)
      : E.left({}),
  E.match(
    () => staticHeaders,
    ({buildNumber, buildHash}) => ({
      ...staticHeaders,
      "x-hortis-build-number": buildNumber,
      "x-hortis-version": buildHash,
    }),
  ),
)

const commonHeaders = getCommonHeaders

const IORequiredHeaders = t.type({
  "x-hortis-client": t.literal(staticHeaders["x-hortis-client"]),
})
export type RequiredHeaders = t.TypeOf<typeof IORequiredHeaders>

const IOCommonHeaders = t.partial({
  "x-hortis-build-number": t.string,
  "x-hortis-version": t.string,
})

const IOOptionalHeaders = t.partial({
  Authorization: t.string,
  "Apollo-Require-Preflight": t.literal("true"),
})

export const IOHeadersIntersection = t.intersection([
  IORequiredHeaders,
  IOCommonHeaders,
  IOOptionalHeaders,
])

const IOHeaders = t.type({
  headers: IOHeadersIntersection,
})

export type Headers = t.TypeOf<typeof IOHeaders>

type OptionalHeaders = t.TypeOf<typeof IOOptionalHeaders>

const mergeHeaders =
  (config: Config) =>
  (optional?: OptionalHeaders): Headers => ({
    headers: {
      ...optional,
      ...commonHeaders(config),
    },
  })

export const withCommonHeaders =
  (config: Config) =>
  (optional?: t.TypeOf<typeof IOOptionalHeaders>): Headers =>
    mergeHeaders(config)(optional)
