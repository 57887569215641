import {useCallback} from "react"
import {IconButton} from "@hortis/ui/button"
import type {DialogProps} from "src/components/dialog-mui"
import {Dialog} from "src/components/dialog-mui"
import {CheckVerified1, XClose} from "@hortis/ui/icons"

export const NameValidationModal = ({
  onClose,
  ...props
}: DialogProps & {onClose: () => void}) => {
  const handleClose = useCallback(() => {
    onClose()
  }, [onClose])
  return (
    <Dialog
      onClose={onClose}
      PaperProps={{className: "!max-w-[440px] !rounded-[12px]"}}
      {...props}
    >
      <div className="flex flex-col gap-5" data-cy="name-validation-modal">
        <div className="pr-10 text-lg font-medium text-grey-900">
          What is taxonomic validation?
        </div>
        <IconButton
          ariaLabel="Close"
          className="!absolute !right-3 !top-3"
          variant="tertiaryGray"
          onClick={handleClose}
          icon={XClose}
        />
        <p>
          Taxonomic validation ensures alignment with the shared taxonomic
          backbone in Hortis, <i>Plants of the World Online</i>, and{" "}
          <i>Catalogue of Life</i>. The datasets provide taxonomic details for
          naturally-occurring species, including family, classification, and
          name status across various taxonomic ranks.
        </p>
        <p>
          Each taxon record will indicate one of three statuses for the
          scientific name used:
        </p>
        <div className="flex flex-col gap-3 rounded-lg border border-grey-200 bg-white px-3 py-4 shadow-xs">
          <div className="flex flex-col gap-1 text-sm">
            <div className="flex items-center justify-between gap-1 font-medium text-primary-600">
              Accepted
              <CheckVerified1 size="20px" className="stroke-primary-500" />
            </div>
            <p>Accepted in the scientific community.</p>
          </div>
          <div className="flex flex-col gap-1 border-t border-dashed border-grey-200 pt-3 text-sm">
            <div className="flex items-center justify-between gap-1 font-medium text-warning-600">
              Synonym
              <CheckVerified1 size="20px" className="stroke-warning-500" />
            </div>
            <p>An alternate of an accepted name.</p>
          </div>
          <div className="flex flex-col gap-1 border-t border-dashed border-grey-200 pt-3 text-sm">
            <div className="flex items-center justify-between gap-1 font-medium text-grey-600">
              Unplaced
              <CheckVerified1 size="20px" className="stroke-grey-500" />
            </div>
            <p>Taxonomic position unclear or under review.</p>
          </div>
        </div>
        <p>
          Additional taxonomic data such as IUCN Red List category, native
          distribution, and life form are provided for taxa whose names are
          fully validated to at least the species level. A name is fully
          validated when you use a scientific name that precisely matches a name
          in the shared taxonomic backbone.
        </p>
        <div className="flex flex-col gap-1.5">
          <p className="font-medium">Data Source Acknowledgement</p>
          <p>Our data is sourced from:</p>
          <ul className="list-outside list-disc pl-5">
            <li>
              <a
                href="http://www.plantsoftheworldonline.org/"
                target="_blank"
                rel="noreferrer"
                className="text-primary-600"
              >
                Plants of the World Online, facilitated by the Royal Botanic
                Gardens, Kew↗
              </a>{" "}
              (CC-BY License)
            </li>
            <li>
              <a
                href="https://www.catalogueoflife.org/"
                target="_blank"
                rel="noreferrer"
                className="text-primary-600"
              >
                Catalogue of Life↗
              </a>{" "}
              (CC 4.0 International License)
            </li>
            <li>
              <a
                href="https://www.iucnredlist.org/"
                target="_blank"
                rel="noreferrer"
                className="text-primary-600"
              >
                IUCN, The IUCN Red List of Threatened Species↗
              </a>{" "}
              (Version 2023-1).
            </li>
          </ul>
        </div>
      </div>
    </Dialog>
  )
}
