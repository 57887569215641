import {createColumnHelper} from "@tanstack/react-table"
import {type AccessionsListFieldsFragment} from "generated/graphql"
import {Button} from "@hortis/ui/button"
import {Dialog, DialogTrigger} from "@hortis/ui/dialog"
import {File6, Tag1} from "@hortis/ui/icons"
import {stopPropagation} from "src/features/collection/components/stop-event-propagation"
import {RestoreAccessionDialogContent} from "src/components/dialog/variants/restore-accession"
import {IUCNRedListTag} from "@hortis/ui/iucn-red-list-tag"
import {DerivedStatus} from "../../derived-status"
import {DataTableCheckboxCell} from "../data-table-checkbox-cell"
import {DataTableCheckboxHeader} from "../data-table-checkbox-header"
import {DataTableColumnHeader} from "../data-table-column-header"
import {DataTableNullableCell} from "../data-table-nullable-cell"
import {DataTablePreviewCell} from "../data-table-preview-cell"
import {DataTableTagsCell} from "../data-table-tags-cell"
import {DataTableMemberCell} from "../data-table-member-cell"
import {ProvenanceCell} from "./provenance-cell"

export type AccessionsColumnIds =
  | "Number"
  | "Scientific name"
  | "Common name"
  | "Provenance"
  | "Material status"
  | "IUCN Red List"
  | "Native distribution"
  | "Climate"
  | "Material received"
  | "Date accessioned"
  | "Date deaccessioned"
  | "Donor / supplier"
  | "IPEN number"
  | "Life forms"
  | "Accession tags"
  | "Last edited"
  | "Notes"
  | "Creator"
  | "Last editor"

const columnHelper = createColumnHelper<AccessionsListFieldsFragment>()

export const baseColumns = [
  columnHelper.accessor("scientificName", {
    id: "Scientific name" satisfies AccessionsColumnIds,
    header: ({column}) => <DataTableColumnHeader column={column} />,
    cell: (info) => (
      <div className="truncate font-medium text-grey-900">
        {info.getValue()}
      </div>
    ),
    enableColumnFilter: false,
    minSize: 164,
    maxSize: 344,
  }),
  columnHelper.accessor("taxon.commonName", {
    id: "Common name" satisfies AccessionsColumnIds,
    header: ({column}) => <DataTableColumnHeader column={column} />,
    cell: ({getValue}) => <DataTableNullableCell value={getValue()} />,
    minSize: 164,
    maxSize: 344,
  }),
  columnHelper.accessor("provenanceCategory", {
    id: "Provenance" satisfies AccessionsColumnIds,
    header: ({column}) => <DataTableColumnHeader column={column} />,
    cell: ({getValue}) => <ProvenanceCell value={getValue()} />,
    minSize: 116,
    maxSize: 218,
  }),
  columnHelper.accessor("plantMaterial", {
    id: "Material status" satisfies AccessionsColumnIds,
    header: ({column}) => <DataTableColumnHeader column={column} />,
    cell: (info) => (
      <DerivedStatus
        statuses={info.getValue()?.map(({status}) => status) ?? []}
      />
    ),
    enableSorting: false,
    enableColumnFilter: false,
    minSize: 160,
  }),
  columnHelper.accessor(
    // eslint-disable-next-line no-secrets/no-secrets -- not a secret
    "taxon.sharedScientificName.taxon.globalRedListStatus",
    {
      id: "IUCN Red List" satisfies AccessionsColumnIds,
      header: ({column}) => <DataTableColumnHeader column={column} />,
      cell: ({getValue, row}) => {
        const fullyValidated = row.original.taxon?.fullyValidated
        const value = getValue()
        return (
          <DataTableNullableCell value={fullyValidated === true ? value : null}>
            {value == null ? null : <IUCNRedListTag status={value} />}
          </DataTableNullableCell>
        )
      },
      minSize: 164,
      maxSize: 344,
    },
  ),
  columnHelper.accessor("taxon.nativeDistribution", {
    id: "Native distribution" satisfies AccessionsColumnIds,
    header: ({column}) => <DataTableColumnHeader column={column} />,
    cell: ({getValue, row, table}) => {
      const fullyValidated = row.original.taxon?.fullyValidated
      const sharedNativeDistribution =
        row.original.taxon?.sharedScientificName?.taxon?.nativeDistribution
      const interpretationEnabled =
        table.options.meta?.flags?.["taxaInterpretationEnabled"]

      const value = getValue()
      return (
        <DataTableNullableCell
          value={
            value == null || interpretationEnabled !== true
              ? fullyValidated === true
                ? sharedNativeDistribution
                : null
              : value
          }
        />
      )
    },
    minSize: 164,
    maxSize: 344,
    enableSorting: false,
  }),
  columnHelper.accessor("taxon.sharedScientificName.taxon.lifeForms", {
    id: "Life forms" satisfies AccessionsColumnIds,
    header: ({column}) => <DataTableColumnHeader column={column} />,
    cell: ({getValue, row}) => {
      const fullyValidated = row.original.taxon?.fullyValidated
      const value = getValue()
      const nodes = value?.nodes
      return (
        <DataTableNullableCell
          value={
            fullyValidated === true && nodes != null && nodes.length > 0
              ? nodes.map((lifeForm) => lifeForm.name).join(", ")
              : null
          }
        />
      )
    },
    minSize: 164,
    maxSize: 344,
    enableSorting: false,
  }),
  columnHelper.accessor("taxon.sharedScientificName.taxon.climate", {
    id: "Climate" satisfies AccessionsColumnIds,
    header: ({column}) => <DataTableColumnHeader column={column} />,
    cell: ({getValue, row}) => {
      const fullyValidated = row.original.taxon?.fullyValidated
      return (
        <DataTableNullableCell
          value={fullyValidated === true ? getValue() : null}
        />
      )
    },
    minSize: 164,
    maxSize: 344,
    enableSorting: false,
  }),
  columnHelper.accessor("materialType.name", {
    id: "Material received" satisfies AccessionsColumnIds,
    header: ({column}) => <DataTableColumnHeader column={column} />,
    enableSorting: false,
    minSize: 174,
    maxSize: 174,
  }),
  columnHelper.accessor("accessionDate", {
    id: "Date accessioned" satisfies AccessionsColumnIds,
    header: ({column}) => <DataTableColumnHeader column={column} />,
    cell: ({getValue, table}) => {
      const value = getValue()
      return value == null
        ? "-"
        : table.options.meta?.formatDateTime(value) ?? "-"
    },
    minSize: 178,
    maxSize: 178,
  }),
  columnHelper.accessor("deaccessionDate", {
    id: "Date deaccessioned" satisfies AccessionsColumnIds,
    header: ({column}) => <DataTableColumnHeader column={column} />,
    cell: ({getValue, table}) => {
      const value = getValue()
      return value == null
        ? "-"
        : table.options.meta?.formatDateTime(value) ?? "-"
    },
    enableSorting: false,
    minSize: 178,
    maxSize: 178,
  }),
  columnHelper.accessor("donor", {
    id: "Donor / supplier" satisfies AccessionsColumnIds,
    header: ({column}) => <DataTableColumnHeader column={column} />,
  }),
  columnHelper.accessor("ipenNumber", {
    id: "IPEN number" satisfies AccessionsColumnIds,
    header: ({column}) => <DataTableColumnHeader column={column} />,
    cell: ({getValue}) => <DataTableNullableCell value={getValue()} />,
    minSize: 164,
    maxSize: 224,
    enableColumnFilter: false,
  }),
  columnHelper.accessor("tagsConnection.nodes", {
    id: "Accession tags" satisfies AccessionsColumnIds,
    header: ({column}) => <DataTableColumnHeader column={column} />,
    cell: ({getValue}) => (
      <DataTableNullableCell value={getValue()}>
        <DataTablePreviewCell
          title="Tags"
          icon={Tag1}
          popoverContent={<DataTableTagsCell tags={getValue()} wrap />}
        >
          <DataTableTagsCell tags={getValue()} />
        </DataTablePreviewCell>
      </DataTableNullableCell>
    ),
    enableSorting: false,
    minSize: 140,
    maxSize: 280,
  }),
  columnHelper.accessor("createdBy", {
    id: "Creator" satisfies AccessionsColumnIds,
    header: ({column}) => <DataTableColumnHeader column={column} />,
    cell: ({getValue}) => <DataTableMemberCell member={getValue()} />,
    enableSorting: false,
    minSize: 128,
    maxSize: 178,
  }),
  columnHelper.accessor("updatedBy", {
    id: "Last editor" satisfies AccessionsColumnIds,
    header: ({column}) => <DataTableColumnHeader column={column} />,
    cell: ({getValue}) => <DataTableMemberCell member={getValue()} />,
    enableSorting: false,
    minSize: 128,
    maxSize: 178,
  }),
  columnHelper.accessor("updatedAt", {
    id: "Last edited" satisfies AccessionsColumnIds,
    header: ({column}) => <DataTableColumnHeader column={column} />,
    cell: ({getValue, table}) => {
      const value = getValue()
      return value == null || table.options.meta == null
        ? "-"
        : table.options.meta.formatDateTime(value)
    },
    enableColumnFilter: false,
    minSize: 128,
    maxSize: 178,
  }),
  columnHelper.accessor("notes", {
    id: "Notes" satisfies AccessionsColumnIds,
    header: ({column}) => <DataTableColumnHeader column={column} />,
    cell: ({getValue}) => (
      <DataTableNullableCell value={getValue()}>
        <DataTablePreviewCell value={getValue()} title="Notes" icon={File6} />
      </DataTableNullableCell>
    ),
    minSize: 100,
    enableSorting: false,
  }),
]
export const accessionColumns = [
  columnHelper.accessor("accessionNumber", {
    id: "Number" satisfies AccessionsColumnIds,
    header: ({table, column}) => (
      <DataTableCheckboxHeader table={table}>
        <DataTableColumnHeader column={column} />
      </DataTableCheckboxHeader>
    ),
    cell: ({row, table, getValue}) => (
      <DataTableCheckboxCell table={table} row={row}>
        <div className="font-medium text-grey-900" data-cy="accession-number">
          {getValue()}
        </div>
      </DataTableCheckboxCell>
    ),
    meta: {
      sticky: true,
    },
    enableColumnFilter: false,
    enableHiding: false,
    minSize: 156,
    maxSize: 200,
  }),
  ...baseColumns,
]

export const trashAccessionColumns = [
  columnHelper.accessor("accessionNumber", {
    id: "Number" satisfies AccessionsColumnIds,
    header: ({column}) => <DataTableColumnHeader column={column} />,
    cell: ({getValue, row}) => {
      const accessionNumber = getValue()
      return (
        <div
          className="flex gap-4 font-medium text-grey-900"
          data-cy="accession-number"
        >
          <div>{accessionNumber}</div>
          <Dialog>
            <RestoreAccessionDialogContent id={row.id} />
            <DialogTrigger asChild onClick={stopPropagation}>
              <Button
                variant="linkcolor"
                size="sm"
                className="w-full"
                testId="restore-accession-button"
              >
                Restore
              </Button>
            </DialogTrigger>
          </Dialog>
        </div>
      )
    },
    meta: {
      sticky: true,
    },
    enableColumnFilter: false,
    enableHiding: false,
    minSize: 156,
    maxSize: 200,
  }),

  ...baseColumns,
]
