import {z} from "zod"

export const authenticatingSchema = z.object({
  error_description: z.string().optional(),
})

const RedirectType = z.enum(["login", "signup"])
export type RedirectType = z.infer<typeof RedirectType>

export const loginSchema = z.object({
  error_description: z.string().optional().catch(undefined),
  error: z.string().optional().catch(undefined),
  redirect: RedirectType.optional().catch(undefined),
})
