import {
  Command,
  CommandDialog,
  CommandHeader,
  CommandInput,
  CommandList,
  CommandLoading,
} from "@hortis/ui/command"
import {useParams, useRouteContext} from "@tanstack/react-router"
import {
  type AccessionsListFieldsFragment,
  type GetAccessionsQuery,
  type MapMaterialListFieldsFragment,
  type MaterialsListFieldsFragment,
  type RecordTag,
  type TaxaListFieldsFragment,
  type PlantMaterialsQuery,
  useCollectionSiteTagsQuery,
  useGetSiteTaxaQuery,
  useGetTaxaQuery,
  useOrganisationTagsQuery,
} from "generated/graphql"
import {useState} from "react"
import {CommandMenuTagsPage} from "src/components/command-menu/command-menu-pages/tags"
import {ErrorAlert} from "src/components/command-menu/command-menu-pages/tags/taxon-tags"
import {useActiveRecordTags} from "src/features/collection/components/plant-materials/utils"
import {type CombinedError} from "urql"

interface TagsDialogProps {
  selectedRows: ReadonlyArray<
    | MaterialsListFieldsFragment
    | AccessionsListFieldsFragment
    | TaxaListFieldsFragment
    | MapMaterialListFieldsFragment
  >
  open: boolean
  onOpenChange: (open: boolean) => void
  allTags: ReadonlyArray<Pick<RecordTag, "id" | "name">>
  title: string
  children?: React.ReactNode
  removeTagFromSelectedRecords: (tagId: string) => Promise<boolean>
  applyTagToSelectedRecords: (tagId: string) => Promise<boolean>
}

const filterCreateListItem = (item: string, query: string) => {
  if (item === "+add+") {
    return 1
  }
  if (item.toLowerCase().includes(query.trim().toLowerCase())) {
    return 1
  }
  return 0
}
const TagsDialog = ({
  title,
  selectedRows,
  open,
  allTags,
  onOpenChange,
  applyTagToSelectedRecords,
  removeTagFromSelectedRecords,
  children,
}: TagsDialogProps) => {
  const [search, setSearch] = useState("")
  const clearSearch = () => {
    setSearch("")
  }

  const [activeRecordTags, setActiveRecordTags] = useActiveRecordTags(
    selectedRows,
    open,
  )

  const hasTags = allTags.length > 0

  const handleOnValueChange = (value: string) => {
    const trimmedValue = value.trim()
    setSearch(trimmedValue === "" ? trimmedValue : value)
  }

  return (
    <CommandDialog open={open} onOpenChange={onOpenChange}>
      <Command
        filter={filterCreateListItem}
        data-cy="context-menu-tags-dialog"
        size="lg"
      >
        <CommandHeader title={title} />
        <CommandInput
          placeholder="Add / remove tags..."
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          value={search}
          onValueChange={handleOnValueChange}
          data-cy="context-menu-search-filter"
        />
        <CommandList>
          {children == null ? (
            <CommandMenuTagsPage
              allTags={allTags}
              shouldShowHeading={true}
              selectedRecordsWithTags={activeRecordTags}
              setSelectedRecordsWithTags={setActiveRecordTags}
              hasTags={hasTags}
              search={search}
              clearSearch={clearSearch}
              applyTagToSelectedRecords={applyTagToSelectedRecords}
              removeTagFromSelectedRecords={removeTagFromSelectedRecords}
            />
          ) : (
            children
          )}
        </CommandList>
      </Command>
    </CommandDialog>
  )
}

const CollectionSiteTaxaTagsDialog = ({
  open,
  selectedRecordsIDs,
  ...props
}: Pick<
  TagsDialogProps,
  | "open"
  | "onOpenChange"
  | "title"
  | "applyTagToSelectedRecords"
  | "removeTagFromSelectedRecords"
> & {selectedRecordsIDs: ReadonlyArray<string>}) => {
  // eslint-disable-next-line sonarjs/no-duplicate-string
  const {siteSlug} = useParams({from: "/_layout/sites/$siteSlug"})
  const {subdomain} = useRouteContext({from: "/_layout/sites/$siteSlug"})

  const [{data, fetching, error}] = useGetSiteTaxaQuery({
    variables: {
      organisationSubdomain: subdomain,
      collectionSiteSlug: siteSlug,
      last: selectedRecordsIDs.length,
      filter: {
        id: {
          in: selectedRecordsIDs,
        },
      },
    },
  })

  const selectedRows = data?.org?.site?.result?.nodes ?? []

  const [
    {data: tagsData, fetching: fetchingTags, error: tagsError},
    // refetchTags,
  ] = useCollectionSiteTagsQuery({
    variables: {
      organisationSubdomain: subdomain,
      collectionSiteSlug: siteSlug,
      orgFilters: {
        hasCollectionSiteRelation: {
          eq: false,
        },
      },
    },
    pause: !open,
  })

  const orgTags = tagsData?.org?.tags?.nodes ?? []
  const siteTags = tagsData?.org?.site?.tags?.nodes ?? []

  const allTags = [...orgTags, ...siteTags].sort((a, b) =>
    a.name.localeCompare(b.name),
  )

  if (data == null && error != null) {
    return <ErrorAlert message="Something went wrong." />
  }
  if (tagsData == null && tagsError != null) {
    return <ErrorAlert message="Failed to load tags." />
  }

  if ((data == null && fetching) || (tagsData == null && fetchingTags)) {
    return (
      <TagsDialog
        {...props}
        open={open}
        allTags={allTags}
        selectedRows={selectedRows}
      >
        <CommandLoading />
      </TagsDialog>
    )
  }

  return (
    <TagsDialog
      {...props}
      open={open}
      allTags={allTags}
      selectedRows={selectedRows}
    />
  )
}
const CollectionSiteTagsDialog = ({
  open,
  query,
  ...props
}: Pick<
  TagsDialogProps,
  | "open"
  | "onOpenChange"
  | "title"
  | "applyTagToSelectedRecords"
  | "removeTagFromSelectedRecords"
  | "selectedRows"
> & {
  query: {
    data: GetAccessionsQuery | PlantMaterialsQuery | undefined
    error: CombinedError | undefined
    fetching: boolean
  }
}) => {
  const {siteSlug} = useParams({from: "/_layout/sites/$siteSlug"})
  const {subdomain} = useRouteContext({from: "/_layout/sites/$siteSlug"})
  const {data, fetching, error} = query

  const [{data: tagsData, fetching: fetchingTags, error: tagsError}] =
    useCollectionSiteTagsQuery({
      variables: {
        organisationSubdomain: subdomain,
        collectionSiteSlug: siteSlug,
        orgFilters: {
          hasCollectionSiteRelation: {
            eq: false,
          },
        },
      },
      pause: !open,
    })

  const orgTags = tagsData?.org?.tags?.nodes ?? []
  const siteTags = tagsData?.org?.site?.tags?.nodes ?? []

  const allTags = [...orgTags, ...siteTags].sort((a, b) =>
    a.name.localeCompare(b.name),
  )

  if (data == null && error != null) {
    return (
      <TagsDialog {...props} open={open} allTags={allTags}>
        <ErrorAlert message="Something went wrong." />
      </TagsDialog>
    )
  }
  if (tagsData == null && tagsError != null) {
    return (
      <TagsDialog {...props} open={open} allTags={allTags}>
        <ErrorAlert message="Failed to load tags." />
      </TagsDialog>
    )
  }

  if ((data == null && fetching) || (tagsData == null && fetchingTags)) {
    return (
      <TagsDialog {...props} open={open} allTags={allTags}>
        <CommandLoading />
      </TagsDialog>
    )
  }

  return <TagsDialog {...props} open={open} allTags={allTags} />
}

const OrganisationTaxaTagsDialog = ({
  open,
  selectedRecordsIDs,
  ...props
}: Pick<
  TagsDialogProps,
  | "open"
  | "onOpenChange"
  | "title"
  | "applyTagToSelectedRecords"
  | "removeTagFromSelectedRecords"
> & {selectedRecordsIDs: ReadonlyArray<string>}) => {
  const {subdomain} = useRouteContext({strict: false})

  const [{data, fetching, error}] = useGetTaxaQuery({
    variables: {
      organisationSubdomain: subdomain,
      last: selectedRecordsIDs.length,
      filter: {
        id: {
          in: selectedRecordsIDs,
        },
      },
    },
  })

  const [{data: tagsData, fetching: fetchingTags, error: tagsError}] =
    useOrganisationTagsQuery({
      variables: {
        organisationSubdomain: subdomain,
      },
      pause: !open,
    })

  const selectedRows = data?.org?.result?.nodes ?? []
  const allTags = tagsData?.org?.tags?.nodes ?? []

  if (data == null && error != null) {
    return (
      <TagsDialog
        {...props}
        open={open}
        allTags={allTags}
        selectedRows={selectedRows}
      >
        <ErrorAlert message="Something went wrong." />
      </TagsDialog>
    )
  }
  if (tagsData == null && tagsError != null) {
    return (
      <TagsDialog
        {...props}
        open={open}
        allTags={allTags}
        selectedRows={selectedRows}
      >
        <ErrorAlert message="Failed to load tags." />
      </TagsDialog>
    )
  }
  if ((data == null && fetching) || (tagsData == null && fetchingTags)) {
    return (
      <TagsDialog
        {...props}
        open={open}
        allTags={allTags}
        selectedRows={selectedRows}
      >
        <CommandLoading />
      </TagsDialog>
    )
  }

  return (
    <TagsDialog
      {...props}
      open={open}
      allTags={allTags}
      selectedRows={selectedRows}
    />
  )
}

export {
  CollectionSiteTagsDialog,
  CollectionSiteTaxaTagsDialog,
  OrganisationTaxaTagsDialog,
  TagsDialog,
}
