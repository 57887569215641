import * as E from "fp-ts/Either"
import {flow} from "fp-ts/function"
import type * as t from "io-ts"
import {formatValidationErrors} from "io-ts-reporters"

export const throwOnValidationError = E.getOrElseW((e: t.Errors) => {
  throw new Error(formatValidationErrors(e).join(", "))
})

/**
 * @param fn - an io-ts type's `is` function
 * @returns a inference-informing asserting equivalent
 * that throws an exception on failure
 */
export const assertsIs = <A, B extends A>(
  fn: (a: A) => a is B,
): ((a: A) => asserts a is B) =>
  flow(fn, (success) => {
    if (!success) {
      // TODO: this isn't a very useful error message...
      throw new Error("value is not predicate")
    }
  })
