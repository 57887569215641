import SwipeableDrawer from "@mui/material/SwipeableDrawer"
import type {ReactElement} from "react"
import {Puller} from "./puller"

interface MobileDrawerProps {
  open: boolean
  onClose: () => void
  content: ReactElement
  testId?: string
}

const noop = () => null

export const MobileDrawer = ({
  open,
  onClose,
  content,
  testId,
  ...props
}: MobileDrawerProps): ReactElement => {
  return (
    <SwipeableDrawer
      disableDiscovery
      disableSwipeToOpen
      onOpen={noop}
      onClose={onClose}
      data-cy={testId}
      {...props}
      anchor="bottom"
      open={open}
      PaperProps={{
        style: {
          borderRadius: "16px 16px 0 0",
          maxHeight: "90vh",
        },
      }}
      className="!z-10"
    >
      <Puller />
      <div className="px-6 py-3">{content}</div>
    </SwipeableDrawer>
  )
}
