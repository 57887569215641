import {Avatar} from "@hortis/ui/avatar"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@hortis/ui/dropdown-menu"
import {GitBranch1, Grid1, Settings1, Trash3} from "@hortis/ui/icons"
import {Link} from "@tanstack/react-router"
import {useGetOrganisationFromNameQuery} from "generated/graphql"
import {useState} from "react"
import {Skeleton} from "src/components/skeleton"
import {useSettingsReturnToStore} from "src/features/settings/return-to-store"
import {useOrganisationSubdomainStruct} from "src/utils/hooks/place"
import {useLocation} from "src/utils/hooks/router"
import {useAccessToken} from "src/utils/use-access-token"
import {NavItem, NavItemLabel} from "../nav-list/nav-item"
import NavGroupHeader from "./nav-group-header"

interface OrgNavGroupProps {
  onNavigate?: () => void
}

export const OrgNavGroup = ({onNavigate}: OrgNavGroupProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const place = useOrganisationSubdomainStruct()
  const accessToken = useAccessToken()
  const [{data, fetching, error}] = useGetOrganisationFromNameQuery({
    variables: {organisationSubdomain: place.data ?? ""},
    pause: place.data == null,
  })

  const location = useLocation()
  const {setReturnTo} = useSettingsReturnToStore()

  const org = data?.org

  if ((fetching && org == null) || accessToken._tag === "None") {
    return (
      <div className="flex flex-col gap-1">
        <div className="px-2 py-1">
          <Skeleton
            width="28px"
            height="28px"
            variant="rounded"
            animation="wave"
          />
        </div>
        <div className="relative flex w-full items-center gap-2 rounded-[6px] px-3 py-2 text-left hover:bg-grey-50">
          <Skeleton width="120px" height="20px" animation="wave" />
        </div>
      </div>
    )
  }

  // TODO: Show error
  if (error != null) {
    return null
  }
  if (org == null) {
    return null
  }

  return (
    <div className="flex flex-col gap-1">
      <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
        <DropdownMenuTrigger className="rounded-lg outline-none ring-grey-200/70 focus-visible:ring">
          <NavGroupHeader className="py-1.5 pl-2 pr-3">
            <div className="flex items-center gap-2 overflow-hidden text-left text-sm font-medium">
              <Avatar
                className="!h-[28px] !w-[28px]"
                variant="rounded"
                textContent={org.name
                  .split(" ")
                  .map((n) => n[0])
                  .join("")
                  .toUpperCase()
                  .slice(0, 1)}
                src={org.avatarUrl ?? undefined}
              />
              <span className="truncate">{org.name}</span>
            </div>
          </NavGroupHeader>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="z-[9999] w-[200px]">
          <DropdownMenuItem asChild>
            <Link
              to={"/settings/org/general"}
              onClick={() => {
                setReturnTo(location.pathname)
                setIsOpen(false)
              }}
              className="flex w-full"
            >
              <Settings1 className="h-4 w-4 text-grey-500" />
              <span className="text-sm">Org settings</span>
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem asChild>
            <Link
              to={"/taxonomy/trash"}
              onClick={() => {
                setReturnTo(location.pathname)
                setIsOpen(false)
              }}
              className="flex w-full"
            >
              <Trash3 className="h-4 w-4 text-grey-500" />
              <span className="text-sm">Taxa trash</span>
            </Link>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <nav className="flex flex-col gap-0.5">
        <NavItem href={`/sites`} onClick={onNavigate}>
          <Grid1 />
          <NavItemLabel label={"Sites"} />
        </NavItem>
        <NavItem href={`/taxonomy`} onClick={onNavigate}>
          <GitBranch1 />
          <NavItemLabel label={"Taxa"} />
        </NavItem>
      </nav>
    </div>
  )
}
