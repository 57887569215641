import Popover from "@mui/material/Popover"
import {
  useCallback,
  useRef,
  type MouseEventHandler,
  type ReactElement,
  type ReactNode,
} from "react"
import {useToggle} from "src/utils/hooks/toggle"
import {Button} from "@hortis/ui/button"
import type {IconProps} from "@hortis/ui/icons"

export interface DataTablePreviewCellProps {
  value?: string | null | undefined
  title: ReactNode
  icon?: (props: IconProps) => ReactElement
  children?: ReactNode
  popoverContent?: ReactNode
}

export function DataTablePreviewCell({
  value,
  icon: Icon,
  title,
  children,
  popoverContent,
}: DataTablePreviewCellProps) {
  const anchorEl = useRef<HTMLButtonElement | null>(null)
  const [open, toggleOpen] = useToggle()

  const handleToggle = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      e.stopPropagation()
      toggleOpen()
    },
    [toggleOpen],
  )
  const onClick = useCallback<MouseEventHandler<HTMLDivElement>>((e) => {
    e.stopPropagation()
  }, [])

  return (
    <div className="flex">
      <Button
        variant="linkcolor"
        onClick={handleToggle}
        size="sm"
        ref={anchorEl}
      >
        {children ?? "Preview"}
      </Button>
      <Popover
        open={open}
        onClose={handleToggle}
        onClick={onClick}
        anchorEl={anchorEl.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          className:
            "!min-w-[316px] !max-w-[400px] !shadow-xl !max-h-[248px] border border-grey-100 !overflow-hidden flex flex-col !my-3",
        }}
      >
        <div className="flex flex-col gap-2 overflow-hidden">
          <div className="flex flex-1 items-center gap-2 p-4 pb-2">
            {Icon == null ? null : (
              <Icon size="18px" className="stroke-grey-500" />
            )}
            <div className="flex-1 text-sm font-medium text-grey-900">
              {title}
            </div>
          </div>
          <div className="max-w-full overflow-y-auto whitespace-pre-line break-words p-4 pt-0 text-sm">
            {popoverContent ?? value}
          </div>
        </div>
      </Popover>
    </div>
  )
}
