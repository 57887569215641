import type {ClassificationFilter} from "generated/graphql"
import type {LocalFamilyFilter} from "./schema"

export const flattenFamilyFilter = (
  filter: LocalFamilyFilter | undefined,
): ClassificationFilter | undefined =>
  filter == null
    ? undefined
    : {
        family: {
          in: filter.is?.map(({scientificName}) => scientificName),
          nin: filter.isNot?.map(({scientificName}) => scientificName),
          null: filter.hasNoValue,
        },
      }
