import {useCallback, useRef} from "react"
import {Button, IconButton} from "@hortis/ui/button"
import {XClose} from "@hortis/ui/icons"
import {twMerge} from "tailwind-merge"
import type {OpenFilterArgs} from "../plant-materials/filters"
import {ChipLabel} from "./chip-label"

interface FilterChipProps<T extends string> {
  filterData?: Array<string>
  filterType: T
  filterLabel: string
  operation: string
  filterId: string
  handleDelete: (id: string) => void
  handleOpenFilter: (args: OpenFilterArgs<T>) => void
  contentClassname?: string
  containerClassname?: string
  testIdPrefix?: string
}

export const FilterChip = <T extends string>({
  filterData,
  filterType,
  filterLabel,
  operation,
  filterId,
  handleOpenFilter,
  contentClassname,
  containerClassname,
  handleDelete,
  testIdPrefix: _testIdPrefix,
}: FilterChipProps<T>) => {
  const ref = useRef(null)

  const handleOpenEdit = useCallback(() => {
    handleOpenFilter({
      filterType,
      anchorElRef: ref.current,
      editFilterId: filterId,
    })
  }, [handleOpenFilter, filterId, filterType])

  const testIdPrefix =
    _testIdPrefix ??
    `${filterType.toLowerCase()}-${operation.replaceAll(" ", "-")}`

  return (
    <div
      ref={ref}
      key={`${filterId}-${operation}`}
      data-cy={`${testIdPrefix}-chip`}
      className={twMerge(
        "flex max-h-[38px] max-w-full items-stretch",
        containerClassname,
      )}
    >
      <Button
        size="sm"
        onClick={handleOpenEdit}
        className={twMerge("max-w-full !rounded-r-none", contentClassname)}
        style={{borderRight: "none"}}
      >
        <ChipLabel
          column={filterLabel}
          operation={operation}
          data={filterData ?? ""}
        />
      </Button>
      <IconButton
        size="md"
        icon={<XClose size="16px" className="stroke-grey-500" />}
        ariaLabel="Delete filter"
        onClick={() => {
          handleDelete(filterId)
        }}
        data-cy={`${testIdPrefix}-chip-delete`}
        className="!rounded-l-none"
      />
    </div>
  )
}
