import {twMerge} from "tailwind-merge"
import type {ReactNode} from "react"

export type FeaturedIconColor =
  | "primary"
  | "error"
  | "warning"
  | "success"
  | "gray"
export type FeaturedIconSize = "lg" | "md" | "sm"

const baseClasses = `flex items-center justify-center rounded-[999px] flex-shrink-0`

const colorClasses: Record<FeaturedIconColor, string> = {
  primary: `bg-primary-100 border-primary-50 ring-primary-50`,
  error: `bg-error-100 border-error-50 ring-error-50`,
  warning: `bg-warning-100 border-warning-50 ring-warning-50`,
  success: `bg-success-100 border-success-50 ring-success-50`,
  gray: `bg-grey-100 border-grey-50 ring-grey-50`,
}

const sizeClasses: Record<FeaturedIconSize, string> = {
  sm: `ring-[4px] size-[30px] m-[2px]`,
  md: `ring-[6px] w-[37px] h-[37px] m-[3px]`,
  lg: `ring-8 w-11 h-11 m-1`,
}

export interface FeaturedIconProps {
  color?: FeaturedIconColor
  size?: FeaturedIconSize
  children?: ReactNode
  className?: string
}

export const FeaturedIcon = ({
  children,
  color = "primary",
  size = "md",
  className,
}: FeaturedIconProps) => (
  <div
    className={twMerge(
      baseClasses,
      colorClasses[color],
      sizeClasses[size],
      className,
    )}
  >
    {children}
  </div>
)
