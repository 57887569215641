import {useCallback, type MouseEvent} from "react"
import {useNavigate} from "@tanstack/react-router"

export const useRowClick = <Item>({urlFn}: {urlFn: (item: Item) => string}) => {
  const navigate = useNavigate()
  return useCallback(
    (e: MouseEvent<HTMLElement>, item: Item) => {
      const url = urlFn(item)
      // This is required to simulate default anchor behaviour of opening in new tab
      if (e.ctrlKey || e.metaKey) {
        window.open(url, "_blank", "rel=noopener noreferrer")
      } else {
        void navigate({to: url})
      }
    },
    [navigate, urlFn],
  )
}
