import * as t from "io-ts"
import {withMessage} from "io-ts-types/withMessage"
import type {RefinementCtx} from "zod"
import * as z from "zod"
import type {GeographicInput} from "generated/graphql"
import {
  MaterialGroup,
  MaterialSex,
  PlantMaterialStatus,
} from "generated/graphql"
import {makeObservationSchema} from "src/components/modals/make-observation/schema"
import {validDate} from "../../../../utils/valid-date"
import {locationOption} from "../../../locations/types"
import {StringOrNull} from "../schema/shared"

export const transformStringToFloat = (val: string, ctx: RefinementCtx) => {
  const parsed = Number.parseFloat(val)
  if (Number.isNaN(parsed)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Not a number",
    })
  }
  return parsed
}

export const checkFloatMinMax =
  ({min, max}: {min?: number; max?: number}) =>
  (val: number, ctx: RefinementCtx) => {
    if (min != null && val < min) {
      ctx.addIssue({
        code: z.ZodIssueCode.too_small,
        minimum: min,
        inclusive: true,
        type: "number",
      })
    }
    if (max != null && val > max) {
      ctx.addIssue({
        code: z.ZodIssueCode.too_big,
        maximum: max,
        inclusive: true,
        type: "number",
      })
    }
    return val
  }

export const locationOptionZod = z.object(
  {
    id: z.string().uuid(),
    name: z.string().min(1, {message: "Location name must be greater than 1"}),
    description: z.string().nullable().optional(),
  },
  {invalid_type_error: "Please select a location"},
)

export type LocationOption = z.TypeOf<typeof locationOptionZod>
export type CollectionSiteLocationOption = z.TypeOf<typeof locationOptionZod>

export const tagOptionZod = z.object(
  {
    id: z.string().uuid(),
    name: z.string(),
  },
  {invalid_type_error: "Please select a tag"},
)

export type TagOptionZod = z.TypeOf<typeof tagOptionZod>

export const position = z
  .object({
    latitude: z
      .string()
      .transform(transformStringToFloat)
      .transform(checkFloatMinMax({min: -90, max: 90}))
      .nullable(),
    longitude: z
      .string()
      .transform(transformStringToFloat)
      .transform(checkFloatMinMax({min: -180, max: 180}))
      .nullable(),
    elevation: z
      .string()
      .transform(transformStringToFloat)
      .nullable()
      .optional(),
  })
  .nullable()
  // Used to transform the root object into null if both latitude and longitude are null,
  // this allows the form to be submitted with no position
  .transform((val, ctx) => {
    if (val === null || (val.latitude == null && val.longitude == null)) {
      return null
    }
    if (val.latitude == null || val.longitude == null) {
      ctx.addIssue({
        path: [val.latitude == null ? "latitude" : "longitude"],
        code: z.ZodIssueCode.invalid_type,
        expected: "number",
        received: "null",
      })
      return null
    }
    return val as GeographicInput | null
  })

export type PositionZodInput = z.input<typeof position>
export type PositionZod = z.TypeOf<typeof position>

export const PlantMaterialSchemaZod = z
  .object({
    firstPresent: z.date({invalid_type_error: "Please select a valid date"}),
    firstAbsent: z
      .date({invalid_type_error: "Please select a valid date"})
      .nullish(),
    materialGroup: z.nativeEnum(MaterialGroup, {
      invalid_type_error: "Please select a material group",
    }),
    status: z.nativeEnum(PlantMaterialStatus, {
      invalid_type_error: "Please select a status",
    }),
    materialQualifier: z.string().min(1).optional(),
    collectionSiteLocationOption: locationOptionZod,
    weight: z.string().nullable(),
    quantity: z.string().nullable(),
    massPlanting: z.boolean(),
    sex: z.nativeEnum(MaterialSex).optional().nullable(),
    tagNumber: z.string().nullable(),
    notes: z.string().nullable(),
    position: position,
    tags: z.array(tagOptionZod),
  })
  .merge(makeObservationSchema)
  .refine(
    ({status, firstAbsent}) =>
      !(status === PlantMaterialStatus.Absent && firstAbsent == null),
    {
      message: "Please select a date removed",
      path: ["firstAbsent"],
    },
  )
  .refine(
    ({massPlanting, materialGroup}) =>
      !(massPlanting && materialGroup === MaterialGroup.Seed),
    {
      message: "Mass planting is not permitted for seeds",
      path: ["quantity"],
    },
  )

export type PlantMaterialSchemaZod = z.TypeOf<typeof PlantMaterialSchemaZod>
export type PlantMaterialSchemaZodInput = z.input<typeof PlantMaterialSchemaZod>

export const PlantMaterialSchema = t.type({
  firstPresent: withMessage(validDate, () => "Please enter a valid date"),
  firstAbsent: withMessage(validDate, () => "Please enter a valid date"),
  materialGroup: withMessage(t.string, () => "Please select a material group"),
  status: withMessage(
    t.keyof({PRESENT: null, ABSENT: null, UNKNOWN: null, TRANSIENT: null}), // Matches enum 'PlantMaterialStatus'
    () => "Please select a status",
  ),
  materialQualifier: withMessage(
    t.string,
    () => "Please enter a material qualifier",
  ),
  collectionSiteLocationOption: withMessage(
    locationOption,
    () => "Please select a garden location",
  ),
  weight: StringOrNull,
  quantity: StringOrNull,
  sex: StringOrNull,
  tagNumber: StringOrNull,
  notes: StringOrNull,
})

export type PlantMaterialSchemaIn = z.input<typeof PlantMaterialSchemaZod>
export type PlantMaterialSchema = PlantMaterialSchemaZod

export type PlantMaterialData = PlantMaterialSchemaZod & {
  tempId?: string
}

export type PlantMaterials = Array<PlantMaterialSchemaZod>
