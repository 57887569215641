import {CommandLoading} from "@hortis/ui/command"
import {useParams, useRouteContext} from "@tanstack/react-router"
import {
  type AccessionsListFieldsFragment,
  useAddTagToAccessionsMutation,
  useCollectionSiteTagsQuery,
  useRemoveTagFromAccessionsMutation,
} from "generated/graphql"
import {useCallback} from "react"
import {useSnackbarStore} from "src/components/snackbar-controller/snackbar-store"
import {useActiveRecordTags} from "src/features/collection/components/plant-materials/utils"
import {onFailure, onSuccess} from "src/notification-snack-utils"
import {CommandMenuTagsPage} from "../tags"
import {ErrorAlert} from "./taxon-tags"

interface CommandMenuAccessionTagsPageProps {
  open: boolean
  selectedRows: ReadonlyArray<AccessionsListFieldsFragment>
  menuPage: string
  search: string
  selectedRecordsIDs: ReadonlyArray<string>
  clearSearch: () => void
}

const CommandMenuAccessionTagsPage = ({
  open,
  selectedRows,
  menuPage,
  search,
  clearSearch,
  selectedRecordsIDs,
}: CommandMenuAccessionTagsPageProps) => {
  const {subdomain} = useRouteContext({from: "/_layout/sites/$siteSlug"})
  const {siteSlug} = useParams({from: "/_layout/sites/$siteSlug"})
  const {setSnack} = useSnackbarStore()
  const [_, addTagToAccessions] = useAddTagToAccessionsMutation()
  const [__, removeTagFromAccessions] = useRemoveTagFromAccessionsMutation()

  const [{data: tagsData, fetching, error}, refetchTags] =
    useCollectionSiteTagsQuery({
      variables: {
        organisationSubdomain: subdomain,
        collectionSiteSlug: siteSlug,
        orgFilters: {
          hasCollectionSiteRelation: {
            eq: false,
          },
        },
      },
    })

  const [activeRecordTags, setActiveRecordTags] = useActiveRecordTags(
    selectedRows,
    open,
  )
  const orgTags = tagsData?.org?.tags?.nodes ?? []
  const siteTags = tagsData?.org?.site?.tags?.nodes ?? []

  const allTags = [...orgTags, ...siteTags].sort((a, b) =>
    a.name.localeCompare(b.name),
  )

  const applyTagToSelectedRecords = useCallback(
    async (tagId: string) => {
      const res = await addTagToAccessions({
        tagId,
        ids: selectedRecordsIDs,
      })

      const addTagSuccessful = res.data?.addTagAccessions.success ?? false
      if (addTagSuccessful) {
        onSuccess(setSnack)(
          `Tag added to ${selectedRecordsIDs.length} accessions`,
        )
        refetchTags()
      } else {
        onFailure(setSnack)(new Error("Failed to add tag to accessions"))
      }
      return addTagSuccessful
    },

    [addTagToAccessions, refetchTags, selectedRecordsIDs, setSnack],
  )

  const removeTagFromSelectedRecords = useCallback(
    async (tagId: string) => {
      const res = await removeTagFromAccessions({
        tagId,
        ids: selectedRecordsIDs,
      })

      const removeTagSuccessful = res.data?.removeTagAccessions.success ?? false

      if (removeTagSuccessful) {
        onSuccess(setSnack)(
          `Tag removed from ${selectedRecordsIDs.length} accessions`,
        )
        refetchTags()
      } else {
        onFailure(setSnack)(new Error("Failed to remove tag from accessions"))
      }
      return removeTagSuccessful
    },
    [refetchTags, removeTagFromAccessions, selectedRecordsIDs, setSnack],
  )

  const hasTags = allTags.length > 0
  const isTagsPage = menuPage === "tags"
  const shouldShowHeading = !isTagsPage || hasTags

  if (tagsData == null && fetching) {
    return <CommandLoading />
  }

  if (error != null) {
    return <ErrorAlert message="Something went wrong." />
  }

  return (
    <CommandMenuTagsPage
      allTags={allTags}
      shouldShowHeading={shouldShowHeading}
      selectedRecordsWithTags={activeRecordTags}
      setSelectedRecordsWithTags={setActiveRecordTags}
      hasTags={hasTags}
      search={search}
      clearSearch={clearSearch}
      applyTagToSelectedRecords={applyTagToSelectedRecords}
      removeTagFromSelectedRecords={removeTagFromSelectedRecords}
    />
  )
}

export default CommandMenuAccessionTagsPage
