import {R, pipe} from "@mobily/ts-belt"
import {useGetOrganisationFromNameQuery} from "generated/graphql"
import {useAccessTokenStruct} from "../use-access-token"
import {useOrganisationSubdomainStruct} from "./place"

export const useOrganisationFromDomain = () => {
  const accessToken = useAccessTokenStruct()
  const subdomain = useOrganisationSubdomainStruct()
  const pause = subdomain.data == null || accessToken.data == null
  const [{data, fetching, error}] = useGetOrganisationFromNameQuery({
    variables: {
      organisationSubdomain: subdomain.data ?? "",
    },
    pause,
  })

  if (accessToken.loading || subdomain.loading || fetching) {
    return undefined
  }

  if (accessToken.error != null || accessToken.data == null) {
    return R.Error(accessToken.error ?? new Error("No access token"))
  }
  if (subdomain.error != null || subdomain.data == null) {
    return R.Error(subdomain.error ?? new Error("Subdomain not found"))
  }
  if (error != null || data == null) {
    return R.Error(error ?? new Error("Query data not found"))
  }

  const site = data.org
  if (site == null) {
    return R.Error(new Error("Org missing from query response"))
  }

  return R.Ok(site)
}

export const useOrganisationSettings = () =>
  pipe(
    useOrganisationFromDomain() ?? R.Error(new Error("loading")),
    R.match(
      ({taxaInterpretationEnabled}) => ({
        taxaInterpretationEnabled,
      }),
      () => ({
        taxaInterpretationEnabled: false,
      }),
    ),
  )

export type OrganisationSettings = ReturnType<typeof useOrganisationSettings>
