import {keyframes} from "@emotion/react"
import * as DialogPrimitive from "@radix-ui/react-dialog"
import {
  forwardRef,
  type ComponentPropsWithoutRef,
  type ElementRef,
  type HTMLAttributes,
  type RefObject,
} from "react"
import {twMerge} from "tailwind-merge"

const Dialog = DialogPrimitive.Root

const DialogTrigger = DialogPrimitive.Trigger

const DialogPortal = DialogPrimitive.Portal

const DialogClose = DialogPrimitive.Close

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`
const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`

const DialogOverlay = forwardRef<
  ElementRef<typeof DialogPrimitive.Overlay>,
  ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({className, ...props}, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={twMerge(
      "bg-grey-900/30 fixed inset-0 z-50 backdrop-blur-md",
      className,
    )}
    css={{
      '&[data-state="open"]': {animation: `0.1s ${fadeIn} ease`},
      '&[data-state="closed"]': {animation: `0.1s ${fadeOut} ease`},
    }}
    {...props}
  />
))
// @ts-expect-error -- Reclared forwardRef does not have a displayName
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName

const fadeInGrow = keyframes`
  from {
    opacity: 0;
    transform: scale(0.92) translateX(-50%) translateY(-50%);
  }
  to {
    opacity: 1;
    transform: scale(1) translateX(-50%) translateY(-50%);
  }
`
const fadeOutShrink = keyframes`
  from {
    opacity: 1;
    transform: scale(1) translateX(-50%) translateY(-50%);
  }
  to {
    opacity: 0;
    transform: scale(0.92) translateX(-50%) translateY(-50%);
  }
`

const DialogContent = forwardRef<
  ElementRef<typeof DialogPrimitive.Content>,
  ComponentPropsWithoutRef<typeof DialogPrimitive.Content> & {
    triggerRef?: RefObject<HTMLElement>
  }
>(({className, children, triggerRef, ...props}, ref) => (
  <DialogPortal>
    <DialogOverlay />
    <DialogPrimitive.Content
      ref={ref}
      className={twMerge(
        "fixed left-[50%] top-[50%] z-50 grid w-[90vw] max-w-sm origin-[0%_0%] translate-x-[-50%] translate-y-[-50%] gap-6 rounded-lg bg-white p-4 pt-5 shadow-lg outline-none sm:gap-8 sm:rounded-lg sm:p-6 sm:pt-6",
        className,
      )}
      onCloseAutoFocus={
        triggerRef == null
          ? undefined
          : () => {
              if (triggerRef.current) {
                triggerRef.current.focus()
              }
            }
      }
      css={{
        '&[data-state="open"]': {animation: `0.1s ${fadeInGrow} ease`},
        '&[data-state="closed"]': {animation: `0.1s ${fadeOutShrink} ease`},
      }}
      {...props}
    >
      {children}
    </DialogPrimitive.Content>
  </DialogPortal>
))
// @ts-expect-error -- Reclared forwardRef does not have a displayName
DialogContent.displayName = DialogPrimitive.Content.displayName

const DialogHeader = ({
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) => (
  <div
    className={twMerge("flex flex-col space-y-2 text-left", className)}
    {...props}
  />
)
DialogHeader.displayName = "DialogHeader"

const DialogFooter = ({
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) => (
  <div
    className={twMerge(
      "flex flex-col-reverse justify-end gap-3 sm:flex-row",
      className,
    )}
    {...props}
  />
)
DialogFooter.displayName = "DialogFooter"

const DialogTitle = forwardRef<
  ElementRef<typeof DialogPrimitive.Title>,
  ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({className, ...props}, ref) => (
  <DialogPrimitive.Title
    ref={ref}
    className={twMerge("text-grey-900 text-lg font-medium", className)}
    {...props}
  />
))
// @ts-expect-error -- Reclared forwardRef does not have a displayName
DialogTitle.displayName = DialogPrimitive.Title.displayName

const DialogDescription = forwardRef<
  ElementRef<typeof DialogPrimitive.Description>,
  ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({className, ...props}, ref) => (
  <DialogPrimitive.Description
    ref={ref}
    className={twMerge("text-grey-500 text-sm", className)}
    {...props}
  />
))
// @ts-expect-error -- Reclared forwardRef does not have a displayName
DialogDescription.displayName = DialogPrimitive.Description.displayName

export {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
}
