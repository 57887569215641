import {twMerge} from "tailwind-merge"
import type {OrganisationMemberFieldsFragment} from "generated/graphql"
import type {AvatarProps} from "@hortis/ui/avatar"
import {Avatar} from "@hortis/ui/avatar"

type GenerateNameOptions = {givenNameOnly: boolean}
const generateName = (
  givenName: string | null | undefined,
  familyName: string | null | undefined,
  options?: GenerateNameOptions,
) =>
  givenName == null
    ? null
    : `${givenName}${
        familyName == null || options?.givenNameOnly === true
          ? ""
          : ` ${familyName}`
      }`

export interface UserDetailsProps {
  user: Pick<
    OrganisationMemberFieldsFragment,
    "givenName" | "familyName" | "avatarUrl" | "initials" | "deactivatedAt"
  >
  showAvatar?: boolean
  avatarProps?: AvatarProps
  className?: string
  nameOptions?: GenerateNameOptions
}

export const UserDetails = ({
  user: {avatarUrl, initials, givenName, familyName, deactivatedAt},
  className,
  avatarProps,
  nameOptions,
  showAvatar = false,
}: UserDetailsProps) => {
  const name = generateName(givenName, familyName, nameOptions) ?? initials

  if (!showAvatar) {
    return (
      <span
        className={twMerge(deactivatedAt != null && "line-through", className)}
      >
        {name}
      </span>
    )
  }

  return (
    <div
      className={twMerge(
        "flex items-center gap-3",
        deactivatedAt != null && "line-through",
        className,
      )}
    >
      <Avatar
        src={avatarUrl ?? undefined}
        textContent={initials}
        {...avatarProps}
      />
      <span>{name}</span>
    </div>
  )
}
