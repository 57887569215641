import {NonEmptyString} from "io-ts-types"
import {useCallback, useState} from "react"
import type {FieldValues, Path} from "react-hook-form"
import {useFormContext} from "react-hook-form"

/* OLD DOCS
Setting the default state of notesFieldVisible.

When adding new material the form state has a different structure to that of editing.
To mitigate this we use the formNamePrefix (a dot notation representation of the form plantMaterials state).

When adding: 
`${formNamePrefix}notes` = 'plantMaterials.0.notes'

formState:

{
  plantMaterials: [{
    notes: "abc"
  }]
}

When editing: 
`${formNamePrefix}notes` = 'notes'

formState: 

{
  notes: "abc"
}
*/

export const useOptionalNotes = <T extends FieldValues>(
  noteFormKey: Path<T>,
) => {
  const {getValues} = useFormContext<T>()

  const [notesFieldVisible, setNotesFieldVisible] = useState(
    // value of notes can be and empty when adding or null when editing
    // this is due to the API discarding empty string values on insert
    NonEmptyString.is(getValues(noteFormKey)),
  )
  const handleShowNotes = useCallback(() => {
    setNotesFieldVisible(true)
  }, [])

  return [notesFieldVisible, handleShowNotes] as const
}
