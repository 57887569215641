import type {IconProps} from "./types"

export const SlNoResultsIcon = ({
  style,
  width,
  height,
  color,
  className,
}: IconProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width ?? "80"}
    height={height ?? "80"}
    viewBox="0 0 80 80"
    fill="none"
    style={style}
    className={className}
  >
    <path
      d="M18.3998 46.56C20.6274 51.8014 24.8458 55.9432 30.127 58.0743C35.4083 60.2054 41.3199 60.1512 46.5612 57.9237C51.8025 55.6962 55.9443 51.4778 58.0754 46.1965C60.2065 40.9152 60.1524 35.0037 57.9248 29.7623C55.6973 24.521 51.4789 20.3792 46.1976 18.2481C40.9164 16.117 35.0048 16.1712 29.7635 18.3987C24.5221 20.6262 20.3803 24.8446 18.2492 30.1259C16.1182 35.4072 16.1723 41.3187 18.3998 46.56V46.56Z"
      stroke={color ?? "#345855"}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M53.3447 53.3437L69.9987 70"
      stroke={color ?? "#345855"}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.7144 45.7143L45.7144 29.7143"
      stroke={color ?? "#345855"}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M45.7144 45.7143L29.7144 29.7143"
      stroke={color ?? "#345855"}
      strokeWidth="3"
      strokeLinecap="round"
    />
  </svg>
)
