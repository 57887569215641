import {LoadingPage} from "@hortis/ui/loading"
import {Outlet, createRootRouteWithContext} from "@tanstack/react-router"
import {Suspense} from "react"
import {SnackbarController} from "src/components/snackbar-controller"

export const Route = createRootRouteWithContext<{
  subdomain: string
}>()({
  component: () => (
    <>
      <SnackbarController />
      <Suspense fallback={<LoadingPage />}>
        <Outlet />
      </Suspense>
    </>
  ),
})
