import {GitBranch1} from "@hortis/ui/icons"
import {IUCNRedListTag} from "@hortis/ui/iucn-red-list-tag"
import {type RedListStatus} from "generated/graphql"
import {colors} from "src/colors"
import {twMerge} from "tailwind-merge"
import {
  RecordCard,
  RecordCardContent,
  RecordCardDetails,
  RecordCardOverline,
  RecordCardTitle,
  type RecordCardProps,
} from "../record-card"

type TaxonRecordCardProps = {
  family?: string | null
  commonName?: string | null
  scientificName: string
  iucnRedListStatus?: RedListStatus | null
  className?: string
} & Pick<RecordCardProps, "variant" | "selected" | "size">

function TaxonRecordCard({
  family,
  commonName,
  scientificName,
  iucnRedListStatus,
  ...rest
}: TaxonRecordCardProps) {
  return (
    <RecordCard className="pl-4" {...rest}>
      <RecordCardContent>
        <RecordCardOverline data-cy="common-name">
          {commonName ?? "-"}
        </RecordCardOverline>
        <RecordCardTitle data-cy="scientific-name">
          {scientificName}
        </RecordCardTitle>

        <RecordCardDetails>
          {iucnRedListStatus && <IUCNRedListTag status={iucnRedListStatus} />}

          {family != null && (
            <div
              className={twMerge(
                "flex items-center gap-1 py-[3px]  pr-2",
                iucnRedListStatus && "pl-1.5",
              )}
            >
              <GitBranch1 color={colors.grey[400]} className="h-4 w-4 " />
              <p className="text-sm font-medium text-grey-500">{family}</p>
            </div>
          )}

          {family == null && iucnRedListStatus == null && "-"}
        </RecordCardDetails>
      </RecordCardContent>
    </RecordCard>
  )
}

export default TaxonRecordCard
