import type * as S from "fp-ts/Semigroup"
import {flow} from "fp-ts/function"
import * as t from "io-ts"
import {throwOnValidationError} from "./validation"

/**
 * Copy paste of material-qualifier.ts from api repo
 */

interface PositiveBrand {
  readonly Positive: unique symbol // use `unique symbol` here to ensure uniqueness across modules / packages
}
export const Positive = t.brand(
  t.number,
  (n): n is t.Branded<number, PositiveBrand> => 0 < n,
  "Positive",
)
export type Positive = t.TypeOf<typeof Positive>

export const PositiveInteger = t.intersection([Positive, t.Int])
export type PositiveInteger = t.TypeOf<typeof PositiveInteger>
export const assertAsPositiveInteger = flow(
  PositiveInteger.decode,
  throwOnValidationError,
)

export const PositiveIntegerSemigroupSum: S.Semigroup<PositiveInteger> = {
  concat: (first, second) => (first + second) as PositiveInteger,
}
export const one = assertAsPositiveInteger(1)
export const increment = (num: PositiveInteger): PositiveInteger =>
  PositiveIntegerSemigroupSum.concat(num, one)
