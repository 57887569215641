import type {DialogContentTextProps} from "@mui/material/DialogContentText"
import DialogContentText from "@mui/material/DialogContentText"
import {type LinkOptions} from "@tanstack/react-router"
import {BaseDialog} from "./base-dialog"

const DialogContentTextLocal: (props: DialogContentTextProps) => JSX.Element =
  DialogContentText

interface AlertDialogProps {
  title: string
  body: string
  cancelLabel: string
  continueLabel: string
  open: boolean
  continueTo?: LinkOptions["to"] | string
  onContinue?: () => void
  onCancel?: () => void
}

export const AlertDialog = ({
  title,
  body,
  open,
  onCancel,
  cancelLabel,
  continueLabel,
  continueTo,
  onContinue,
}: AlertDialogProps): JSX.Element => {
  return (
    <BaseDialog
      title={title}
      open={open}
      onClose={onCancel}
      actionButtons={[
        {
          key: 1,
          children: cancelLabel,
          variant: "primary",
          onClick: onCancel,
          testId: "dialog-cancel-button",
        },
        {
          key: 2,
          children: continueLabel,
          onClick: onContinue,
          href: continueTo,
          testId: "dialog-continue-button",
        },
      ]}
    >
      <DialogContentTextLocal>{body}</DialogContentTextLocal>
    </BaseDialog>
  )
}
