import Checkbox from "@mui/material/Checkbox"
import type {SkeletonProps} from "@mui/material/Skeleton"
import type {HTMLAttributes} from "react"
import {memo} from "react"
import {Box} from "../../../../components/box"
import {Skeleton} from "../../../../components/skeleton"
import {tableHeaderRowMixinDefault} from "../table-styling"

export interface HeaderColumnConfig {
  skeletonProps?: SkeletonProps
  cellProps?: HTMLAttributes<HTMLDivElement>
}

interface SkeletonHeaderProps {
  columns: number
  withCheckbox?: boolean
  columnConfig?: Record<number, HeaderColumnConfig>
}

const checkboxInputProps = {"aria-label": "select all"}

const emptyObject = {}
export const SkeletonHeader = memo(function SkeletonHeader({
  columns,
  columnConfig = emptyObject,
  withCheckbox = true,
}: SkeletonHeaderProps): JSX.Element {
  const totalColumns = withCheckbox ? columns - 1 : columns
  return (
    <Box role="row" sx={tableHeaderRowMixinDefault}>
      {withCheckbox ? (
        <div role="columnheader" className="priority-1 checkbox-padding">
          <Checkbox inputProps={checkboxInputProps} />
        </div>
      ) : null}
      {Array.from({length: totalColumns}).map((_, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <div role="columnheader" key={idx} {...columnConfig[idx]?.cellProps}>
          <Skeleton animation={false} {...columnConfig[idx]?.skeletonProps} />
        </div>
      ))}
    </Box>
  )
})
