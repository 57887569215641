import {Fragment} from "react"
import {FilterChip} from "src/features/filters/filter-chip-group"
import type {OpenFilterArgs} from "../filters"
import type {LocalMaterialFilter, MaterialFilterType} from "../types"
import {extractSexFilterData} from "./extract-filter-data"

export const SexFilterChipGroup = ({
  id,
  filter,
  hidden,
  ...props
}: {
  id: string
  handleOpenFilter: (args: OpenFilterArgs<MaterialFilterType>) => void
  handleDelete: (id: string) => void
  filter: LocalMaterialFilter["sex"]
  hidden?: boolean
}) => {
  const isFilters = filter?.or
    ?.flatMap(({eq}) => eq)
    .filter((val) => val != null)
  const isNotFilters = filter?.or
    ?.flatMap(({neq}) => neq)
    .filter((val) => val != null)

  const hasNoValueFilter = filter?.null?.eq === true

  return filter == null || hidden === true ? (
    <Fragment />
  ) : (
    <>
      {isFilters != null && isFilters.length > 0 && (
        <FilterChip
          filterLabel="Sex"
          filterId={id}
          filterData={extractSexFilterData(filter, "is")}
          operation="is"
          filterType="sex"
          {...props}
        />
      )}
      {isNotFilters != null && isNotFilters.length > 0 && (
        <FilterChip
          filterLabel="Sex"
          filterType="sex"
          filterId={id}
          filterData={extractSexFilterData(filter, "isNot")}
          operation="is not"
          {...props}
        />
      )}
      {hasNoValueFilter && (
        <FilterChip
          filterLabel="Sex"
          filterType="sex"
          filterId={id}
          operation="has no value"
          {...props}
        />
      )}
    </>
  )
}
