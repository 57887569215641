import {Plus} from "@hortis/ui/icons"
import {pipe} from "@mobily/ts-belt"
import {
  useCreateRecordTagMutation,
  type RecordTag,
  type RecordTagFieldsFragment,
  type RecordTagInput,
} from "generated/graphql"
import {useCallback, useState, type MouseEventHandler} from "react"
import {
  useSnackbarStore,
  type SetSnack,
} from "src/components/snackbar-controller/snackbar-store"
import {ToggleGroup, ToggleGroupItem} from "src/components/toggle-group"
import type {SelectedRecordWithTags} from "src/features/actions-menu/actions-menu"
import {onFailure} from "src/notification-snack-utils"
import {useCollectionSite} from "src/utils/hooks/collection-site"
import {
  CommandCheckboxItem,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  type CommandMenuGroup,
} from "@hortis/ui/command"
import * as OE from "../../../utils/option-either"

export type TagFilter = "all" | "applied"
interface TagsProps {
  shouldShowHeading: boolean
  allTags: ReadonlyArray<Pick<RecordTag, "id" | "name">>
  selectedRecordsWithTags: ReadonlyArray<SelectedRecordWithTags>
  setSelectedRecordsWithTags: (
    tags: ReadonlyArray<SelectedRecordWithTags>,
  ) => void
  hasTags: boolean
  search: string
  applyTagToSelectedRecords: (tagId: string) => Promise<boolean>
  removeTagFromSelectedRecords: (tagId: string) => Promise<boolean>
  clearSearch: () => void
}

const handleCreateTag = async ({
  input,
  createTag,
  setSnack,
  onSubmit,
}: {
  input: RecordTagInput
  createTag: ReturnType<typeof useCreateRecordTagMutation>[1]
  setSnack: SetSnack
  onSubmit: (newTag: RecordTagFieldsFragment) => void
}) => {
  const _res = await createTag({
    input: input,
  })
  const res = _res.data?.createRecordTag
  if (res?.success === true && res.recordTag != null) {
    onSubmit(res.recordTag)
  } else if (
    res?.errors?.[0]?.__typename === "DuplicateNameError" ||
    res?.errors?.[0]?.__typename === "SiteTagMergeError"
  ) {
    onFailure(setSnack)(new Error("Tag with this name already exists"))
  } else {
    onFailure(setSnack)(new Error("A problem occurred while creating the tag"))
  }
}

const CommandMenuTagsPage = ({
  allTags,
  shouldShowHeading,
  hasTags,
  selectedRecordsWithTags,
  setSelectedRecordsWithTags,
  search,
  applyTagToSelectedRecords,
  removeTagFromSelectedRecords,
  clearSearch,
}: TagsProps) => {
  const group: CommandMenuGroup<"tags"> = {
    heading: "Tags",
    value: "tags",
    items: allTags.map(({id, name}) => ({
      value: id,
      label: name,
    })),
  }

  const site = pipe(useCollectionSite(), OE.toStruct)
  const [currentTagsFilter, setCurrentTagsFilter] = useState<TagFilter>("all")
  const {setSnack} = useSnackbarStore()
  const hasSearch = search.length > 0
  const [loadingStates, setLoadingStates] = useState<Record<string, boolean>>(
    {},
  )

  const [, createTag] = useCreateRecordTagMutation()

  const checkBoxStatus = useCallback(
    (tagId: string) => {
      const isLoading = loadingStates[tagId] ?? false
      const appliedCount = selectedRecordsWithTags.reduce(
        (count, record) => (record.tagIds.includes(tagId) ? count + 1 : count),
        0,
      )

      return {
        checked: appliedCount === selectedRecordsWithTags.length,
        indeterminate:
          appliedCount > 0 && appliedCount < selectedRecordsWithTags.length,
        isLoading,
      }
    },
    [selectedRecordsWithTags, loadingStates],
  )

  let modifiedGroup = group

  if (currentTagsFilter === "applied") {
    const appliedTags = group.items.filter((item) => {
      const {checked, indeterminate} = checkBoxStatus(item.value)
      return checked || indeterminate
    })

    modifiedGroup = {...group, items: appliedTags}
  }

  const onCreateNewTag = async () => {
    await handleCreateTag({
      input: {name: search, collectionSiteId: site.data?.id},
      createTag,
      setSnack,
      onSubmit: async (newTag) => {
        setSelectedRecordsWithTags(
          selectedRecordsWithTags.map(({recordId, tagIds}) => ({
            recordId,
            tagIds: [...tagIds, newTag.id],
          })),
        )
        await applyTagToSelectedRecords(newTag.id)
      },
    })
    clearSearch()
  }

  const handleSelect = useCallback(
    async (value: string) => {
      setLoadingStates((prevState) => ({...prevState, [value]: true}))

      if (
        selectedRecordsWithTags
          .flatMap((record) => record.tagIds)
          .includes(value)
      ) {
        setSelectedRecordsWithTags(
          selectedRecordsWithTags.map(({recordId, tagIds}) => ({
            recordId,
            tagIds: tagIds.filter((tag) => tag !== value),
          })),
        )
        const res = await removeTagFromSelectedRecords(value)
        if (!res) {
          setLoadingStates((prevState) => ({...prevState, [value]: false}))
          return
        }
      } else {
        setSelectedRecordsWithTags(
          selectedRecordsWithTags.map(({recordId, tagIds}) => ({
            recordId,
            tagIds: [...tagIds, value],
          })),
        )
        const res = await applyTagToSelectedRecords(value)
        if (!res) {
          setLoadingStates((prevState) => ({...prevState, [value]: false}))
          return
        }
      }
      setLoadingStates((prevState) => ({...prevState, [value]: false}))
    },
    [
      applyTagToSelectedRecords,
      removeTagFromSelectedRecords,
      selectedRecordsWithTags,
      setSelectedRecordsWithTags,
    ],
  )
  const handleAllTagsSelect: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (currentTagsFilter === "all") {
      e.preventDefault()
      return
    }
    setCurrentTagsFilter("all")
  }
  const handleAppliedTagsSelect: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (currentTagsFilter === "applied") {
      e.preventDefault()
      return
    }
    setCurrentTagsFilter("applied")
  }

  return (
    <>
      <CommandGroup
        data-cy="tags-menu-dialog"
        key={modifiedGroup.value}
        heading={
          <div className="flex items-center justify-between">
            {shouldShowHeading && modifiedGroup.heading}
            {hasTags && (
              <ToggleGroup
                className="w-fit gap-0 rounded-lg border border-grey-300"
                value={currentTagsFilter}
                type="single"
                size="sm"
              >
                <ToggleGroupItem
                  onClick={handleAllTagsSelect}
                  variant={"default"}
                  value="all"
                  data-cy="tags-menu-filter-all"
                >
                  All tags
                </ToggleGroupItem>
                <ToggleGroupItem
                  onClick={handleAppliedTagsSelect}
                  variant={"default"}
                  value="applied"
                  data-cy="tags-menu-filter-applied"
                >
                  Applied tags
                </ToggleGroupItem>
              </ToggleGroup>
            )}
          </div>
        }
      >
        {modifiedGroup.items.map((groupItem) => {
          const {checked, indeterminate, isLoading} = checkBoxStatus(
            groupItem.value,
          )

          const handleRowSelect = async () => {
            if (isLoading) {
              return
            }
            await handleSelect(groupItem.value)
          }

          return (
            <CommandCheckboxItem
              key={groupItem.value}
              onChange={handleRowSelect}
              onSelect={handleRowSelect} // both required as internally the onSelect is used to handle the click event and onChange is used to handle checkbox state
              checked={checked}
              indeterminate={indeterminate}
              isLoading={isLoading}
              data-cy={`tags-menu-checkbox-item-${groupItem.label}`}
            >
              {groupItem.label}
            </CommandCheckboxItem>
          )
        })}
        {hasSearch &&
          !allTags.some(
            (tag) =>
              tag.name.trim().toLocaleLowerCase() ===
              search.trim().toLocaleLowerCase(),
          ) && (
            <CommandItem
              value="+add+"
              data-cy="tags-menu-add-new-tag"
              onSelect={onCreateNewTag}
            >
              <Plus />
              Add new tag &ldquo;{search}&rdquo;
            </CommandItem>
          )}
      </CommandGroup>
      <CommandEmpty data-cy="tags-menu-empty">
        <span className="py-1 text-sm font-medium text-grey-900">
          No tags found
        </span>
        <span className="text-sm font-normal text-grey-500">
          To add a new tag, enter its name in the filter bar and click
          &rsquo;Add new tag&rsquo;.
        </span>
      </CommandEmpty>
    </>
  )
}

export {CommandMenuTagsPage}
