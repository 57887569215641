import type {ReactElement, SyntheticEvent} from "react"
import {memo, useCallback} from "react"
import {fromSxFn, fromSxValues} from "../../utils/sx"
import {Tab, Tabs} from "./tabs"

export interface CustomTabsProps<TabValue extends string | number> {
  /**
   * Indicates whether it should expose a testId or not - for example, if it's appearing
   * inside a possibly-redirecting auth higher order component.
   */
  loading?: boolean
  currentTab: TabValue | false
  onTabChange: (val: TabValue) => void
  tabs:
    | Array<{
        label: string
        value: TabValue
      }>
    | ReadonlyArray<{label: string; value: TabValue}>
}
type CustomTabs = <TabValue extends string | number>(
  props: CustomTabsProps<TabValue>,
) => ReactElement

const tabsStyle = fromSxFn((theme) => ({
  "&": {
    minHeight: {xs: theme.spacing(5), sm: theme.spacing(6)},
  },
  "& .MuiTabs-scroller": {
    // This is a hack to prevent container pushing the page width into overflow
    maxWidth: {
      xs: `calc(100vw - ${theme.spacing(4)})`,
      md: `calc(100vw - ${theme.spacing(6)})`,
    },
  },
  "& :not(.MuiButtonBase-root.MuiTab-root:last-of-type)": {
    marginRight: theme.spacing(3),
  },
  "& .MuiButtonBase-root.MuiTab-root": {
    minHeight: {xs: theme.spacing(5), sm: theme.spacing(6)},
    minWidth: "40px",
    padding: {xs: `0px`, sm: `12px 0`},
    fontSize: "0.9375rem",
  },
  "& .MuiTabs-indicator": {
    minWidth: "40px",
    width: "100%",
  },
}))

const tabStyle = fromSxValues({
  textTransform: "none",
  fontWeight: "500",
})

export const CustomTabs = memo(function MemoisedCustomTabs<
  TabValue extends string | number,
>({
  currentTab,
  onTabChange,
  tabs,
  loading = false,
}: CustomTabsProps<TabValue>): ReactElement {
  const onChange = useCallback(
    (_e: SyntheticEvent, value: TabValue): void => {
      onTabChange(value)
    },
    [onTabChange],
  )
  return (
    <Tabs
      value={currentTab}
      onChange={onChange}
      variant="scrollable"
      scrollButtons="auto"
      aria-label="scrollable tabs"
      sx={tabsStyle}
    >
      {tabs.map((tab) => (
        <Tab
          tabIndex={0}
          key={tab.value}
          label={tab.label}
          value={tab.value}
          sx={tabStyle}
          testId={`${loading ? "loading-" : ""}${tab.value}-tab`}
          disableTouchRipple
          focusRipple
        />
      ))}
    </Tabs>
  )
}) as CustomTabs
