import {Button} from "@hortis/ui/button"
import {Dialog, DialogTrigger} from "@hortis/ui/dialog"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@hortis/ui/dropdown-menu"
import {
  DotsHorizontal,
  Edit5,
  ExternalLink1,
  Eye,
  EyeOff,
  MarkerPin4,
  RefreshCw5,
  Trash3,
} from "@hortis/ui/icons"
import {Grid3} from "@hortis/ui/icons/grid-3"
import {Link, useParams} from "@tanstack/react-router"
import {
  MaterialGroup,
  PlantMaterialStatus,
  type MaterialFieldsFragment,
} from "generated/graphql"
import {useCallback, useState} from "react"
import {TrashMaterialDialogContent} from "src/components/dialog/variants/trash-material"
import {MakeObservationDialogContent} from "src/components/modals/make-observation"
import {RestoreMaterialDialog} from "src/features/collection/components/plant-materials/restore-material-dialog"
import {MaterialEditStage} from "src/features/records/material/components/edit-drawer/types"
import {useCollectionSiteSettings} from "src/utils/hooks/collection-site-from-url"
import {useThumbnailPhoto} from "src/utils/photos"
import {createMaterialNumber} from "src/features/collection/components/plant-materials/material-number"
import {
  RecordPageHeader,
  RecordPageHeaderButtons,
  RecordPageHeaderContent,
  RecordPageHeaderSubtitle,
  RecordPageHeaderThumbnail,
  RecordPageHeaderTitle,
  RecordPageHeaderTitleGroup,
} from "../../../components/record-page-header"
import {MakePrivateDialog} from "./make-private-dialog"
import {MakePublicDialog} from "./make-public-dialog"
import {MaterialCopyDropdownMenuSub} from "./material-copy-dropdown-menu-sub"
import {MaterialRecordPropertySummary} from "./material-record-property-summary"

type MaterialRecordPageHeaderProps = {
  material: MaterialFieldsFragment
  refetchMaterial: () => void
  requestEdit: (editStage?: MaterialEditStage) => void
  canEdit: boolean
  openUploadPhotoDialog: () => void
}

// eslint-disable-next-line sonarjs/cognitive-complexity
function MaterialRecordPageHeader({
  material,
  refetchMaterial,
  canEdit,
  requestEdit,
  openUploadPhotoDialog,
}: MaterialRecordPageHeaderProps) {
  const thumbnailImage = useThumbnailPhoto(material)
  const isPublic = material.public
  const {isPublic: isSitePublic} = useCollectionSiteSettings()
  const {siteSlug} = useParams({
    from: "/_layout/sites/$siteSlug/materials/$materialNumber",
  })

  const openEdit = useCallback(() => {
    requestEdit(MaterialEditStage.editDetails)
  }, [requestEdit])

  const [trashDialogOpen, setTrashDialogOpen] = useState(false)
  const [restoreDialogOpen, setRestoreDialogOpen] = useState(false)
  const [makePublicDialogOpen, setMakePublicDialogOpen] = useState(false)
  const [makePrivateDialogOpen, setMakePrivateDialogOpen] = useState(false)
  const [observationModalOpen, setObservationModalOpen] = useState(false)

  const isPresentPlant =
    material.status === PlantMaterialStatus.Present &&
    material.materialGroup === MaterialGroup.Plant

  return (
    <RecordPageHeader className="flex flex-col gap-4 lg:flex-row lg:gap-12 xl:grid xl:grid-cols-[188px_auto] [&_.record-page-header-thumbnail]:lg:max-w-[188px]">
      <RecordPageHeaderThumbnail
        grayscale={material.archived || material.firstAbsent !== null}
        thumbnail={thumbnailImage}
        additionalPhotos={material.images}
        photoTitle={material.accession?.scientificName ?? ""}
        handleUploadImage={openUploadPhotoDialog}
      />
      <RecordPageHeaderContent>
        <MaterialRecordPropertySummary
          canEdit={canEdit}
          material={material}
          refetchMaterial={refetchMaterial}
        />

        <RecordPageHeaderTitleGroup>
          <RecordPageHeaderTitle>
            {material.accession?.scientificName ?? "-"}
          </RecordPageHeaderTitle>
          <RecordPageHeaderSubtitle>
            {material.accession?.taxon?.commonName ?? "-"}
          </RecordPageHeaderSubtitle>
        </RecordPageHeaderTitleGroup>

        <RecordPageHeaderButtons className="lg:order-first lg:self-end">
          {canEdit && !material.archived && (
            <>
              {material.materialGroup === MaterialGroup.Plant &&
                material.status !== PlantMaterialStatus.Absent && (
                  <Dialog
                    open={observationModalOpen}
                    onOpenChange={setObservationModalOpen}
                  >
                    <DialogTrigger asChild>
                      <Button
                        data-cy="make-observation-button"
                        size="xs"
                        className="!rounded-md"
                        onClick={() => {
                          setObservationModalOpen(true)
                        }}
                      >
                        <Grid3 className="h-4 w-4 text-grey-700" />
                        New Observation
                      </Button>
                    </DialogTrigger>
                    <MakeObservationDialogContent
                      onSubmit={() => {
                        refetchMaterial()
                        setObservationModalOpen(false)
                      }}
                      onClose={() => {
                        setObservationModalOpen(false)
                      }}
                      materialId={material.id}
                      defaultValues={material}
                    />
                  </Dialog>
                )}
              <Button
                size={"xs"}
                onClick={openEdit}
                startIcon={Edit5}
                className="!rounded-md"
                data-cy="edit-material-button"
              >
                Edit
              </Button>
            </>
          )}

          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button
                data-cy="action-menu-button"
                endIcon={DotsHorizontal}
                size={"xs"}
                className="!rounded-md !p-2"
              />
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {material.position != null && material.accession != null && (
                <>
                  <DropdownMenuItem asChild data-cy="show-on-map-menu-item">
                    <Link
                      to="/sites/$siteSlug/map/material/$materialNumber"
                      from="/sites/$siteSlug"
                      params={{
                        materialNumber: createMaterialNumber(
                          material.accession.accessionNumber,
                          material.qualifier,
                        ),
                      }}
                      search={{
                        latLng: {
                          lng: material.position.longitude,
                          lat: material.position.latitude,
                        },
                        zoom: 19,
                      }}
                    >
                      <MarkerPin4 className="h-4 w-4 text-grey-500" />
                      Open on map
                    </Link>
                  </DropdownMenuItem>
                  <DropdownMenuSeparator />
                </>
              )}
              {isPublic && isPresentPlant && isSitePublic && (
                <>
                  <DropdownMenuItem
                    data-cy="open-public-record-menu-item"
                    asChild
                  >
                    <Link
                      target="_blank"
                      to={`/public/site/${siteSlug}/map/plant/${material.id}`}
                    >
                      <ExternalLink1 className="h-4 w-4 text-grey-500" />
                      Open public record
                    </Link>
                  </DropdownMenuItem>
                  <DropdownMenuSeparator />
                </>
              )}
              {material.accession == null ? undefined : (
                <DropdownMenuItem asChild testId="go-to-accession-menu-item">
                  <Link
                    to="/sites/$siteSlug/accessions/$accessionNumber"
                    from="/sites/$siteSlug/materials/$materialNumber"
                    params={{
                      accessionNumber: material.accession.accessionNumber,
                    }}
                  >
                    <ExternalLink1 className="h-4 w-4 text-grey-500" />
                    Open accession
                  </Link>
                </DropdownMenuItem>
              )}
              {material.accession?.taxon == null ? undefined : (
                <DropdownMenuItem asChild testId="go-to-taxon-menu-item">
                  <Link
                    to="/taxonomy/$taxonId"
                    params={{taxonId: material.accession.taxon.id}}
                  >
                    <ExternalLink1 className="h-4 w-4 text-grey-500" />
                    Open taxon
                  </Link>
                </DropdownMenuItem>
              )}
              <DropdownMenuSeparator />
              <MaterialCopyDropdownMenuSub material={material} />
              {canEdit && (
                <>
                  {isPresentPlant && !material.archived && isSitePublic && (
                    <>
                      {isPublic && (
                        <DropdownMenuItem
                          data-cy="make-private-menu-item"
                          onClick={() => {
                            setMakePrivateDialogOpen(true)
                          }}
                        >
                          <EyeOff className="h-4 w-4 text-grey-500" />
                          Make private
                        </DropdownMenuItem>
                      )}
                      {!isPublic && (
                        <DropdownMenuItem
                          data-cy="make-public-menu-item"
                          onClick={() => {
                            setMakePublicDialogOpen(true)
                          }}
                        >
                          <Eye className="h-4 w-4 text-grey-500" />
                          Make public
                        </DropdownMenuItem>
                      )}
                      <DropdownMenuSeparator />
                    </>
                  )}
                  {!material.archived && (
                    <DropdownMenuItem
                      onClick={() => {
                        setTrashDialogOpen(true)
                      }}
                      testId="archive-material-menu-item"
                    >
                      <Trash3 className="h-4 w-4 text-grey-500" />
                      Delete
                    </DropdownMenuItem>
                  )}
                  {material.archived && (
                    <DropdownMenuItem
                      onClick={() => {
                        setRestoreDialogOpen(true)
                      }}
                      testId="restore-material-menu-item"
                    >
                      <RefreshCw5 className="h-4 w-4 text-grey-500" />
                      Restore
                    </DropdownMenuItem>
                  )}
                </>
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        </RecordPageHeaderButtons>
      </RecordPageHeaderContent>
      {canEdit && (
        <>
          {
            <>
              <Dialog open={trashDialogOpen} onOpenChange={setTrashDialogOpen}>
                <TrashMaterialDialogContent
                  id={material.id}
                  onSuccess={() => {
                    setTrashDialogOpen(false)
                    refetchMaterial()
                  }}
                />
              </Dialog>
              <RestoreMaterialDialog
                open={restoreDialogOpen}
                onClose={() => {
                  refetchMaterial()
                  setRestoreDialogOpen(false)
                }}
                materialId={material.id}
              />

              <MakePrivateDialog
                open={makePrivateDialogOpen}
                onOpenChange={setMakePrivateDialogOpen}
                id={material.id}
                onSuccess={refetchMaterial}
              />
              <MakePublicDialog
                open={makePublicDialogOpen}
                onOpenChange={setMakePublicDialogOpen}
                id={material.id}
                onSuccess={refetchMaterial}
              />
            </>
          }
        </>
      )}
    </RecordPageHeader>
  )
}

export default MaterialRecordPageHeader
