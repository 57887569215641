import {IconButton} from "@hortis/ui/button"
import {XClose} from "@hortis/ui/icons"
import {Sheet, SheetContent} from "@hortis/ui/sheet"
import {memo} from "react"
import {Divider} from "src/components/custom/divider"
import {TrialAlert} from "src/components/untitled/alert/trial-alert"
import {GlobalNavList} from "../nav-list"
import {OrgNavGroup} from "../site-context/org-nav-group"
import {SiteNavGroup} from "../site-context/site-nav-group"
import {UserProfileRow} from "../user-profile-row/user-profile-row"

export interface NavDrawerProps {
  open: boolean
  toggleOpen: () => void
  isPermanent?: boolean
}

export const NavDrawer = memo(function PermanentNavDrawer({
  open,
  toggleOpen,
  isPermanent = false,
}: NavDrawerProps) {
  const content = (
    <>
      <div className="flex flex-col gap-6 px-3">
        <div className="flex flex-col">
          {isPermanent ? null : (
            <IconButton
              onClick={toggleOpen}
              ariaLabel="Close mobile navigation"
              data-cy="close-mobile-nav-drawer"
              className="mx-0.5 self-end p-2"
              variant="tertiaryGray"
              size="xs"
              icon={XClose}
            />
          )}
          <OrgNavGroup onNavigate={toggleOpen} />
        </div>
        <SiteNavGroup onNavigate={toggleOpen} />
      </div>
      <div className="flex-1" />
      <div className="flex flex-col gap-2 px-3 pb-[18px]">
        <TrialAlert breakpoint="mobile" testId="nav-trial-alert" />
        <GlobalNavList onNavigate={toggleOpen} />
        <div className="px-2">
          <Divider />
        </div>
        <UserProfileRow />
      </div>
    </>
  )
  if (isPermanent) {
    return (
      <div
        data-cy="nav-drawer"
        className="flex h-full w-56 flex-col border-r border-grey-200 pt-2"
      >
        {content}
      </div>
    )
  }
  return (
    <Sheet open={open} onOpenChange={toggleOpen} data-cy="mobile-nav-drawer">
      <SheetContent className="flex h-full w-56 flex-col p-0 pt-3" side="left">
        {content}
      </SheetContent>
    </Sheet>
  )
})
