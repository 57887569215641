import {Download1} from "@hortis/ui/icons"
import {Typography} from "../../../components/typography"
import {useDownloadCsv} from "./download-accessions/use-download-csv"
import {useDownloadOrgCsv} from "./download-accessions/use-download-org-csv"

export const DownloadCsvMenuItem = ({
  body,
  csvName,
  path,
  sendOrg,
}: {
  body?: Record<string, unknown>
  csvName: string
  path: string
  sendOrg?: boolean
}) => {
  const handleDownload = useDownloadCsv({
    csvName,
    path,
    body,
  })
  const handleOrgDownload = useDownloadOrgCsv({
    csvName,
    path,
    body,
  })

  return (
    <button
      type="button"
      className="inline-flex min-h-[none] items-center gap-2"
      onClick={sendOrg === true ? handleOrgDownload : handleDownload}
    >
      <Download1 size="16px" />
      <Typography variant="body2" color="text.primary">
        Download CSV
      </Typography>
    </button>
  )
}
