import {useEffect} from "react"
import {useMobile, useSmallDesktop} from "src/utils/hooks/media-query"
import {useMapPaddingStore} from "./map-padding-store"
import type {MapMode} from "./types"

type UseMapPaddingArgs = {
  mode: MapMode
  drawerCollapsed: boolean
}

export const useMapPadding = ({mode, drawerCollapsed}: UseMapPaddingArgs) => {
  const isMobile = useMobile()
  const isSmallDesktop = useSmallDesktop()
  const {mapPadding, setMapPadding} = useMapPaddingStore()

  useEffect(() => {
    setMapPadding({
      // Offset the map to the right when the info drawer is open
      left:
        mode === "view" && !drawerCollapsed && !isMobile
          ? isSmallDesktop
            ? // These values relate to the width values of the drawer
              344
            : 416
          : 0,
      top: 0,
      bottom: 0,
      right: 0,
    })
  }, [mode, drawerCollapsed, isMobile, isSmallDesktop, setMapPadding])

  return mapPadding
}
