import type {NotificationActionProps} from "src/components/snackbar-controller/notification/notification"
import type {SetSnack} from "./components/snackbar-controller/snackbar-store"

export const onFailure = (setSnack: SetSnack) => (error: Error) => {
  setSnack({
    type: "alert",
    data: {
      severity: "error",
      text: error.message,
    },
  })
}

export const onSuccess =
  (setSnack: SetSnack) =>
  (message: string, action?: NotificationActionProps) => {
    setSnack({
      type: "action",
      data: {
        action: action,
        text: message,
      },
    })
  }
