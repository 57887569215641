import {useCallback, useState} from "react"
import {useFormContext, useWatch} from "react-hook-form"
import {colors} from "src/colors"
import {MapPositionField} from "src/components/map/position-field/map-position-field"
import type {Nullable} from "src/types/nullable"
import {PositionFields} from "./position-fields"
import type {CollectionSiteSchemaIn} from "./schema"

type FormValues = Nullable<CollectionSiteSchemaIn>
type FormFields = {
  [Key in keyof FormValues as `${string}${Key}`]: FormValues[Key]
}

interface MaterialMapPositionFieldProps {
  namePrefix?: string
  hideLabel?: boolean
}

export const SiteMapPositionField = ({
  namePrefix = "",
  hideLabel,
}: MaterialMapPositionFieldProps) => {
  const [showFields, setShowFields] = useState(false)
  const {setValue, getValues, trigger, clearErrors, formState} =
    useFormContext<FormFields>()
  const centerPoint = useWatch<FormFields, `${string}centerPoint`>({
    name: `${namePrefix}centerPoint`,
  })
  const error = formState.errors[`${namePrefix}centerPoint`]

  const toggleFields = useCallback(async () => {
    const centerPoint = getValues(`${namePrefix}centerPoint`)

    if (
      centerPoint?.latitude == null &&
      centerPoint?.longitude == null &&
      centerPoint?.zoom == null
    ) {
      setShowFields((x) => !x)
      return
    }

    const valid = await trigger(`${namePrefix}centerPoint`)
    if (valid) {
      setShowFields((x) => !x)
    }
  }, [namePrefix, trigger, getValues])

  const handleConfirm = useCallback(
    ({
      latitude,
      longitude,
      zoom,
    }: {
      latitude: number
      longitude: number
      zoom: number
    }) => {
      setValue(`${namePrefix}centerPoint`, {
        latitude: String(latitude),
        longitude: String(longitude),
        zoom: String(zoom),
      })
      clearErrors(`${namePrefix}centerPoint`)
    },
    [setValue, namePrefix, clearErrors],
  )

  const handleDelete = useCallback(() => {
    setValue(`${namePrefix}centerPoint`, null)
  }, [setValue, namePrefix])

  return (
    <MapPositionField
      position={centerPoint}
      toggleFields={toggleFields}
      showFields={showFields}
      fieldsComponent={<PositionFields namePrefix={namePrefix} />}
      onDelete={handleDelete}
      onConfirm={handleConfirm}
      zoom={centerPoint?.zoom == null ? 2 : Number.parseFloat(centerPoint.zoom)}
      label={hideLabel === true ? undefined : "Center point"}
      required
      pointColor={colors.blue[600]}
      modalProps={{
        title: "Setting site center",
        description:
          "Drag the map to choose the center point for your site, zoom level will also be saved.",
        centerOverlayType: "target",
      }}
      error={error?.message}
    />
  )
}
