import {Button} from "@hortis/ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "@hortis/ui/dropdown-menu"
import {
  Check,
  Folder,
  Map2,
  Plus,
  Settings1,
  SwitchHorizontal1,
  Tag1,
  Trash3,
} from "@hortis/ui/icons"
import {Link, useNavigate} from "@tanstack/react-router"
import {
  useGetCollectionSiteFromNamesQuery,
  useGetCollectionSiteQuery,
  useGetMeWithSitesQuery,
} from "generated/graphql"
import {useCallback, useEffect, useState} from "react"
import {
  Command,
  CommandEmpty,
  CommandInput,
  CommandItem,
  CommandList,
} from "@hortis/ui/command"
import {useAccessRole} from "src/features/permissions/use-access-role"
import {mapSlugToHrefs} from "src/utils/hooks/hrefs"
import {usePlaceStruct} from "src/utils/hooks/place"
import {useLocation} from "src/utils/hooks/router"
import {useStickyState} from "src/utils/hooks/sticky-state"
import {useSettingsReturnToStore} from "../../../features/settings/return-to-store"
import {SiteNavList} from "../nav-list"
import NavGroupHeader from "./nav-group-header"
import {useNavSiteStore} from "./nav-site-store"

interface SiteNavGroupProps {
  onNavigate?: () => void
}

const SiteLabel = ({name, active}: {name: string; active: boolean}) => (
  <div className="flex items-center gap-3">
    <span>{name}</span>
    {active && <Check className="h-4 w-4 shrink-0 text-primary-500" />}
  </div>
)

const getCurrentPage = () => {
  const [currentPath] = window.location.pathname.split("/").slice(-1)
  const currentPathQueryParams = window.location.search.split("?")[1] ?? ""
  return {currentPath, currentPathQueryParams}
}

const generateSiteOptionsUrl = (siteUrlSlug: string) => {
  const {currentPath, currentPathQueryParams} = getCurrentPage()
  let redirect: string
  switch (currentPath) {
    case "dashboard": {
      redirect = mapSlugToHrefs(siteUrlSlug).dashboard
      break
    }
    case "collection": {
      const queryParamsMap: Record<string, string> = {
        "tab=materials": mapSlugToHrefs(siteUrlSlug).plantMaterialCollection,
        "tab=accessions": mapSlugToHrefs(siteUrlSlug).accessionCollection,
        "tab=taxa": mapSlugToHrefs(siteUrlSlug).taxaCollection,
      }
      redirect =
        queryParamsMap[currentPathQueryParams] ??
        mapSlugToHrefs(siteUrlSlug).plantMaterialCollection // default to plant material collection when no query params
      break
    }
    case "map": {
      redirect = mapSlugToHrefs(siteUrlSlug).map
      break
    }
    default: {
      redirect = mapSlugToHrefs(siteUrlSlug).dashboard
      break
    }
  }

  return `/${redirect}`
}

export const SiteNavGroup = ({onNavigate}: SiteNavGroupProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const {setNavSite, navSite} = useNavSiteStore()

  const location = useLocation()
  const navigate = useNavigate()
  const {setReturnTo} = useSettingsReturnToStore()
  const [{data}] = useGetMeWithSitesQuery()
  const sites = data?.me?.collectionSites

  const [lastVisitedSiteId, _] = useStickyState("", "last-visited-siteId")
  const [{data: recentlyVisitedCollectionSite}] = useGetCollectionSiteQuery({
    variables: {id: lastVisitedSiteId},
    pause: lastVisitedSiteId === "",
  })

  const {canEdit} = useAccessRole()
  const place = usePlaceStruct()
  const [{data: collectionSiteData}] = useGetCollectionSiteFromNamesQuery({
    variables: {
      organisationSubdomain: place.data?.orgName ?? "",
      collectionSiteSlug: place.data?.siteSlug ?? "",
    },
    pause: place.data == null,
  })

  const fetchedSite = collectionSiteData?.org?.site

  useEffect(() => {
    if (fetchedSite != null) {
      setNavSite(fetchedSite)
    }
  }, [fetchedSite, navSite, setNavSite])

  const site = fetchedSite ?? recentlyVisitedCollectionSite?.collectionSite

  const hrefs = site?.urlSlug == null ? null : mapSlugToHrefs(site.urlSlug)

  const handleItemClick = useCallback(() => {
    setIsOpen(false)
    onNavigate?.()
  }, [onNavigate])

  return site == null ? null : (
    <div className="flex flex-col gap-2">
      <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
        <DropdownMenuTrigger className="rounded-lg outline-none ring-grey-200/70 focus-visible:ring">
          <NavGroupHeader className="px-3 py-2">
            <div className="flex items-center gap-3 overflow-hidden text-left text-sm font-medium">
              <Map2 size="20px" className="shrink-0 text-grey-500 " />
              <span className="truncate">{site.name}</span>
            </div>
          </NavGroupHeader>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="z-[9999] w-[200px]">
          {sites != null && sites.length > 0 && (
            <DropdownMenuSub>
              <DropdownMenuSubTrigger className="flex w-full gap-2 rounded-md px-3 py-2.5 text-sm font-medium">
                <SwitchHorizontal1 className="h-4 w-4 text-grey-500" />
                <span>Switch site</span>
              </DropdownMenuSubTrigger>
              <DropdownMenuSubContent className="w-[200px] p-0">
                <Command>
                  <CommandInput
                    placeholder="Filter sites..."
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus={true} //unable to manually focus on input with keyboard
                  />
                  <CommandEmpty>No results</CommandEmpty>
                  <CommandList>
                    {sites.map((site) => (
                      <CommandItem
                        key={site.id}
                        value={site.name}
                        onSelect={() => {
                          void navigate({
                            to: generateSiteOptionsUrl(site.urlSlug),
                          })
                          onNavigate?.()
                          setIsOpen(false)
                        }}
                      >
                        <Link to={generateSiteOptionsUrl(site.urlSlug)}>
                          <SiteLabel
                            name={site.name}
                            active={location.pathname.includes(
                              `/${site.urlSlug}/`,
                            )}
                          />
                        </Link>
                      </CommandItem>
                    ))}
                  </CommandList>
                </Command>
              </DropdownMenuSubContent>
            </DropdownMenuSub>
          )}
          {hrefs && (
            <>
              <DropdownMenuItem asChild>
                <Link
                  to={"/settings/sites/$siteSlug/general"}
                  params={{siteSlug: site.urlSlug}}
                  onClick={() => {
                    setReturnTo(location.pathname)
                    handleItemClick()
                  }}
                  className="flex w-full"
                >
                  <Settings1 className="h-4 w-4 text-grey-500" />
                  <span className="text-sm">Site settings</span>
                </Link>
              </DropdownMenuItem>
              <DropdownMenuItem asChild>
                <Link
                  to={"/sites/$siteSlug/trash"}
                  params={{siteSlug: site.urlSlug}}
                  onClick={() => {
                    setReturnTo(location.pathname)
                    handleItemClick()
                  }}
                  className="flex w-full"
                >
                  <Trash3 className="h-4 w-4 text-grey-500" />
                  <span className="text-sm">Site trash</span>
                </Link>
              </DropdownMenuItem>
            </>
          )}
        </DropdownMenuContent>
      </DropdownMenu>

      <div className="flex flex-col gap-2">
        <div className="w-full">
          {canEdit && (
            <DropdownMenu>
              <DropdownMenuTrigger
                asChild
                className="w-full justify-start !gap-3 !px-3"
              >
                <Button
                  size="sm"
                  className="[&_svg]:text-grey-500"
                  startIcon={Plus}
                >
                  New
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-[200px]">
                <DropdownMenuItem asChild>
                  <Link
                    to={"/sites/$siteSlug/materials/new"}
                    params={{siteSlug: site.urlSlug}}
                    onClick={onNavigate}
                    className="flex w-full"
                  >
                    <div className="flex items-center gap-3">
                      <Tag1 className="h-4 w-4 text-grey-500" />
                      New material
                    </div>
                  </Link>
                </DropdownMenuItem>
                <DropdownMenuItem asChild>
                  <Link
                    to={"/sites/$siteSlug/accessions/create"}
                    params={{siteSlug: site.urlSlug}}
                    onClick={onNavigate}
                    className="flex w-full"
                  >
                    <div className="flex items-center gap-3">
                      <Folder className="h-4 w-4 text-grey-500" />
                      New accession
                    </div>
                  </Link>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          )}
        </div>
        <SiteNavList onNavigate={onNavigate} site={site} />
      </div>
    </div>
  )
}
