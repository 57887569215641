import {colors} from "src/colors"
import type {IconProps} from "./types"

export const UserPlus1 = ({color = colors.grey[700], ...props}: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M10.0003 12.9167H6.25033C5.08736 12.9167 4.50587 12.9167 4.03271 13.0602C2.96737 13.3834 2.13369 14.217 1.81053 15.2824C1.66699 15.7555 1.66699 16.337 1.66699 17.5M15.8337 17.5V12.5M13.3337 15H18.3337M12.0837 6.25C12.0837 8.32107 10.4047 10 8.33366 10C6.26259 10 4.58366 8.32107 4.58366 6.25C4.58366 4.17893 6.26259 2.5 8.33366 2.5C10.4047 2.5 12.0837 4.17893 12.0837 6.25Z"
        stroke={color}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
