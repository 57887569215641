import * as Str from "fp-ts/string"
import * as Rec from "fp-ts/Record"
import * as A from "fp-ts/Array"
import {flow} from "fp-ts/function"
import stableStringify from "fast-json-stable-stringify"

const collectInOrder = Rec.collect(Str.Ord)

export const stringifyObjectInOrder = flow(
  collectInOrder((key, value) => [`key: ${key}`, stableStringify(value)]),
  A.flatten,
)
