import {useMemo} from "react"
import type {MaterialFieldsFragment} from "../../../../../../../generated/graphql"
import {SlHashIcon} from "../../../../../../components/icons/streamline/hash"
import {Stack} from "../../../../../../components/stack"
import {Typography} from "../../../../../../components/typography"
import {createMaterialNumber} from "../../../../../collection/components/plant-materials/material-number"
import {countFields} from "../../../../accession/components/edit-drawer/utils"
import {EditTitle} from "../../../../components/edit-title"
import {OverviewCard} from "../../../../components/overview-card"
import {extractMaterialDetailsFormFields} from "../edit-details"
import type {BaseEditStageProps} from "../types"
import {MaterialEditStageCallback} from "../types"

export const countMaterialDetailsFields = (
  material: MaterialFieldsFragment,
) => {
  const materialDetailsFields = Object.values(
    extractMaterialDetailsFormFields(material),
  )

  return {
    count: countFields(materialDetailsFields),
    total: materialDetailsFields.length,
  }
}

export const MaterialRecordOverview = ({
  material,
  transitionStage,
  requestClose,
}: BaseEditStageProps) => {
  const materialDetailsFields = useMemo(
    () => countMaterialDetailsFields(material),
    [material],
  )

  return (
    <>
      <EditTitle
        requestClose={requestClose}
        title="Record overview"
        overline={
          <Stack spacing={1} direction="row">
            <SlHashIcon height="18" width="18" />
            <Typography variant="body2">
              {material.accession != null &&
                createMaterialNumber(
                  material.accession.accessionNumber,
                  material.qualifier,
                  "/",
                )}
            </Typography>
          </Stack>
        }
      />
      <Stack pt={4} pb={4} spacing={2}>
        <OverviewCard
          title="Material details"
          subtitle={`${materialDetailsFields.count} / ${materialDetailsFields.total} fields complete`}
          onClick={MaterialEditStageCallback.useEditDetails(transitionStage)}
          testIdPrefix="details"
        />
      </Stack>
    </>
  )
}
