import {gql} from "graphql-request"
import {COLLECTION_SITE_MEMBERSHIP_FIELDS} from "../organisation/graphql"

export const CORE_ORGANISATION_FIELDS = gql`
  fragment OrganisationFields on Organisation {
    id
    name
    subdomain
    avatarUrl
    taxaInterpretationEnabled
  }
`

export const CORE_COLLECTION_SITES_FIELDS = gql`
  ${CORE_ORGANISATION_FIELDS}
  fragment CollectionSitesFields on CollectionSite {
    id
    name
    urlSlug
    organisation {
      ...OrganisationFields
    }
  }
`

export const GET_ME_WITH_SITES = gql`
  ${CORE_COLLECTION_SITES_FIELDS}
  ${COLLECTION_SITE_MEMBERSHIP_FIELDS}
  query GetMeWithSites {
    me {
      id
      initials
      email
      givenName
      familyName
      avatarUrl
      askMeAbout
      role
      accessRole
      organisation {
        ...OrganisationFields
      }
      collectionSites {
        ...CollectionSitesFields
      }
      collectionSiteMemberships {
        ...CollectionSiteMembershipFields
      }
      preferences {
        experimentalFeaturesEnabled
      }
    }
  }
`

export const UPDATE_PROFILE = gql`
  mutation UpdateProfile($profileChanges: ProfileChangesInput!) {
    updateProfile(profileChanges: $profileChanges) {
      familyName
      givenName
      askMeAbout
      role
    }
  }
`

export const UPDATE_PREFERENCES = gql`
  mutation UpdatePreferences($preferenceUpdates: PreferenceUpdatesInput!) {
    updatePreferences(preferenceUpdates: $preferenceUpdates) {
      preferences {
        experimentalFeaturesEnabled
      }
    }
  }
`

export const VERIFY_EMAIL = gql`
  mutation VerifyEmail {
    verifyEmail {
      __typename

      ... on GenericError {
        message
      }
      ... on VerifyEmailResult {
        status
      }
    }
  }
`

export const CREATE_COLLECTION_SITE = gql`
  mutation CreateCollectionSite($input: CreateCollectionSiteInput!) {
    createCollectionSite(input: $input) {
      success
      collectionSite {
        id
        name
        urlSlug
      }
      errors {
        __typename
        ... on DuplicateUrlSlugError {
          message
        }
        ... on DuplicateNameError {
          message
        }
        ... on GenericError {
          message
        }
      }
    }
  }
`
