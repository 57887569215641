export const colors = {
  primary: {
    main: "#345855",
    dark: "#243D3B",
    light: "#5C7977",
    bg: "#EFF2F1",
    border: "rgba(52, 88, 85, 0.5)",
    contrast: "#FFFFFF",
    25: "#F6FEFC",
    50: "#F0FDF9",
    100: "#CCFBEF",
    200: "#99F6E0",
    300: "#5FE9D0",
    400: "#2ED3B7",
    500: "#15B79E",
    600: "#0E9384",
    700: "#107569",
    800: "#125D56",
    900: "#134E48",
  },
  other: {
    stroke: "rgba(0,0,0,0.23)",
  },
  blue: {
    25: "#F5FAFF",
    50: "#EFF8FF",
    100: "#D1E9FF",
    200: "#B2DDFF",
    300: "#84CAFF",
    400: "#53B1FD",
    500: "#2E90FA",
    600: "#1570EF",
    700: "#175CD3",
    800: "#1849A9",
    900: "#194185",
  },
  "blue-light": {
    25: "#F5FBFF",
    50: "#F0F9FF",
    100: "#E0F2FE",
    200: "#B9E6FE",
    300: "#7CD4FD",
    400: "#36BFFA",
    500: "#0BA5EC",
    600: "#0086C9",
    700: "#026AA2",
    800: "#065986",
    900: "#0B4A6F",
  },
  warning: {
    main: "#FF9800",
    light: "#FFB547",
    25: "#FFFCF5",
    50: "#FFFAEB",
    100: "#FEF0C7",
    200: "#FEDF89",
    300: "#FEC84B",
    400: "#FDB022",
    500: "#F79009",
    600: "#DC6803",
    700: "#B54708",
    800: "#93370D",
    900: "#7A2E0E",
  },
  orange: {
    25: "#FEFAF5",
    50: "#FEF6EE",
    100: "#FDEAD7",
    200: "#F9DBAF",
    300: "#F7B27A",
    400: "#F38744",
    500: "#EF6820",
    600: "#E04F16",
    700: "#B93815",
    800: "#932F19",
    900: "#772917",
  },
  yellow: {
    25: "#FEFDF0",
    50: "#FEFBE8",
    100: "#FEF7C3",
    200: "#FEEE95",
    300: "#FDE272",
    400: "#FAC515",
    500: "#EAAA08",
    600: "#CA8504",
    700: "#A15C07",
    800: "#854A0E",
    900: "#713B12",
  },
  teal: {
    25: "#F6FEFC",
    50: "#F0FDF9",
    100: "#CCFBEF",
    200: "#99F6E0",
    300: "#5FE9D0",
    400: "#2ED3B7",
    500: "#15B79E",
    600: "#0E9384",
    700: "#107569",
    800: "#125D56",
    900: "#134E48",
  },
  "green-light": {
    25: "#FAFEF5",
    50: "#F3FEE7",
    100: "#E3FBCC",
    200: "#D0F8AB",
    300: "#A6EF67",
    400: "#85E13A",
    500: "#66C61C",
    600: "#4CA30D",
    700: "#3B7C0F",
    800: "#326212",
    900: "#2B5314",
  },
  error: {
    25: "#FFFBFA",
    50: "#FEF3F2",
    100: "#FEE4E2",
    200: "#FECDCA",
    300: "#FDA29B",
    400: "#F97066",
    500: "#F04438",
    600: "#D92D20",
    700: "#B42318",
    800: "#912018",
    900: "#7A271A",
  },
  success: {
    main: "#32C8BB",
    bg: "#D6F4F1",
    text: "#1E766E",
    25: "#F6FEF9",
    50: "#ECFDF3",
    100: "#D1FADF",
    200: "#A6F4C5",
    300: "#6CE9A6",
    400: "#32D583",
    500: "#12B76A",
    600: "#039855",
    700: "#027A48",
    800: "#05603A",
    900: "#054F31",
  },
  text: {
    primary: "#424242",
    secondary: "rgba(0, 0, 0, 0.6)",
  },
  paper: "#FFFFFF",
  white: "#FFFFFF",
  black: "#000",
  grey: {
    25: "#FCFCFD",
    50: "#F9FAFB",
    100: "#F3F4F6",
    200: "#E5E7EB",
    300: "#D2D6DB",
    400: "#9DA4AE",
    500: "#6C737F",
    600: "#4D5761",
    700: "#384250",
    800: "#1F2A37",
    900: "#111927",
  },
}
