import * as _Apply from "fp-ts/Apply"
import * as E from "fp-ts/Either"
import * as ET from "fp-ts/EitherT"
import type {Lazy} from "fp-ts/function"
import {constant, constUndefined, flow, identity, pipe} from "fp-ts/function"
import * as O from "fp-ts/Option"
import * as Rec from "fp-ts/Record"

/*
What is this file?

Like ramda, fp-ts exposes its functionality as a set of pure functions.

Unlike ramda, which is largely flat (e.g. map is just a function exposed globally as is ap)
fp-ts orients itself around several types, exposing the functions that act on them under namespaces.
(e.g. all the functions that act on arrays are organised under fp-ts/Array, including map and ap.)

Whereas ramda has polymorphic functions which are largely fixed in behaviour and type signature
fp-ts lets you define unique instances of a given function that operate on a specific data structure,
trading implicit conciseness for explicit type safety.
If you had an array of an array (like a 2D matrix) and you wanted to define a map that would let you
modify the individual values of the matrix, it's hard to do that with ramda.
You'd either have to create a brand new datastructure (either an object or a class) that has a map method on it
and implement it there (as ramda's map delegates to a method if it finds one) and forgo type safety entirely
(there's no easy way to type the return type of this in TS)

or you'd have to abandon using the ramda built in and define a new function in terms of the old one, e.g.
```
const mapMatrix = compose(map, map)
mapMatrix(x => x + 1)([[1, 2, 3], [4, 5, 6]])
// [[2, 3, 4], [5, 6, 7]]
```

fp-ts takes the latter, explicit choice by default, and provides an idiomatic way
for describing a collection of these functions that operate on a new, compound datastructure.

In this case, OptionEither represents a value that may not be there, but if it is, it may have failed or may have succeeded.

If you wanted to modify the happy path without losing information about whether it's loaded or failed, you could do
```
pipe(
  myNetworkResponse,
  Option.map(Either.map(x => x + 1))
)
```
or build it yourself, like
```
const map = flow(E.map, O.map)
// <A, B>(f: (a: A) => B) => (fa: O.Option<E.Either<unknown, A>>) =>
//   O.Option<E.Either<unknown, B>>
```
though due to weaknesses in TS' inference, it loses some information about the failure state.

hence instead it's using something called a monad transformer:
(think a utility designed to make it easier to build correctly typed compound datastructures)
```
export const map = ET.map(O.Functor)
// <A, B>(f: (a: A) => B) => <E>(fa: O.Option<E.Either<E, A>>) =>
//   O.Option<E.Either<E, B>>
```
In that respect, ap is defined in the same way it is for the built-in fp-ts TaskEither
(a function that returns a Promise of an Either)
```
export const ap = ET.ap(T.ApplyPar)
```
vs the one in this file:
```
export const ap = ET.ap(O.Apply)
```
*/

export const URI = "OptionEither"

export type OptionEither<E, A> = O.Option<E.Either<E, A>>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ExtractLeft<OE extends OptionEither<any, any>> =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  OE extends O.Some<E.Either<infer E, any>> ? E : never
// this seemingly unnecessary nesting is a fix for TS' inference across unions - because there is
// a member of the union that lacks the generic type, extending the union would result in unknown.
// so we have to break the leaky abstraction to extract the type!

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ExtractRight<OE extends OptionEither<any, any>> =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  OE extends O.Some<E.Either<any, infer A>> ? A : never

export type URI = typeof URI
declare module "fp-ts/HKT" {
  interface URItoKind2<E, A> {
    readonly OptionEither: OptionEither<E, A>
  }
}

/**
 * This seemingly pointless function is an efficient
 * way to cast from `Option<Either<E, A>>` to `OptionEither<E, A>`
 * (a sop to the type system)
 */
export const fromOptionEither: <E, A>(
  oe: OptionEither<E, A>,
) => OptionEither<E, A> = identity

/**
 * Maps over a the right hand side of an Option of an Either.
 */
export const map: <A, B>(
  f: (a: A) => B,
) => <E>(fa: O.Option<E.Either<E, A>>) => O.Option<E.Either<E, B>> = ET.map(
  O.Functor,
)
export const bimap: <E, G, A, B>(
  f: (e: E) => G,
  g: (a: A) => B,
) => (fea: O.Option<E.Either<E, A>>) => O.Option<E.Either<G, B>> = ET.bimap(
  O.Functor,
)
export const mapLeft: <E, G>(
  f: (e: E) => G,
) => <A>(fea: O.Option<E.Either<E, A>>) => O.Option<E.Either<G, A>> =
  ET.mapLeft(O.Functor)

export const expandLeft: <E2>() => <E, A>(
  option: OptionEither<E, A>,
) => OptionEither<E | E2, A> = constant(identity)

export const toError = E.toError

/**
 * Gets the inner value of an Option of an Either.
 * Converts the left hand side of the Either to undefined.
 */
export const getValue: <A>(
  ma: O.Option<E.Either<unknown, A>>,
) => A | undefined = flow(O.chain(O.fromEither), O.toUndefined)

/**
 * Constructs an Option of a (Right) Either.
 */
export const right: <E = never, A = never>(a: A) => OptionEither<E, A> = flow(
  ET.right(O.Pointed),
  fromOptionEither,
)

export const of: typeof right = right

/**
 * Constructs an Option of a (Left) Either.
 */
export const left: <E = never, A = never>(e: E) => OptionEither<E, A> = flow(
  ET.left(O.Pointed),
  fromOptionEither,
)

export const none = O.none as OptionEither<never, never>

export const fromOption: <E = never, A = never>(
  fa: O.Option<A>,
) => O.Option<E.Either<E, A>> = O.map(E.right)
export const fromEither: <E, A>(e: E.Either<E, A>) => OptionEither<E, A> = O.of

export const fromNullable: <A>(
  a: A,
) => O.Option<E.Either<never, NonNullable<A>>> = flow(
  O.fromNullable,
  fromOption,
)
export const fromNullableWithError: <E>() => <A>(
  a: A,
) => OptionEither<E, A> = () => fromNullable
// annotation prevents orphaned generic
export const fromNullableToError: <E>(
  e: E,
) => <A>(a: A) => OptionEither<E, NonNullable<A>> = flow(E.fromNullable, (fn) =>
  flow(fn, fromEither),
)

export const fromNullableK: <A extends ReadonlyArray<unknown>, B>(
  f: (...a: A) => B | null | undefined,
) => (...a: A) => O.Option<E.Either<never, NonNullable<B>>> = flow(
  O.fromNullableK,
  (fn) => flow(fn, O.map(E.right)),
)

/**
 * Applicative do for Option Either.
 * Converts an optional to undefined and everts it.
 */
export const noneToUndefined: <E, A>(
  ta: O.Option<E.Either<E, A>>,
) => E.Either<E, A | undefined> = flow(
  O.sequence(E.Monad),
  E.map(O.toUndefined),
)

export const discardError: <A>(
  ma: O.Option<E.Either<unknown, A>>,
) => O.Option<A> = O.chain(O.fromEither)

export const errorToOption: <A>(
  ma: O.Option<E.Either<unknown, A>>,
) => O.Option<O.Option<A>> = O.map(O.fromEither)

export const errorToUndefined: <A>(
  ma: O.Option<E.Either<unknown, A>>,
) => O.Option<A | undefined> = flow(errorToOption, O.map(O.toUndefined))

export const noneAndErrorToUndefined: <A>(
  ma: O.Option<E.Either<unknown, A>>,
) => A | undefined = flow(discardError, O.toUndefined)
export const noneAndErrorToUndefinedWith: <A, B>(
  fn: (a: A) => B,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => (oe: OptionEither<any, A>) => B | undefined = (fn) =>
  flow(O.map(E.matchW(constUndefined, fn)), O.toUndefined)

export const toUnion: <E, A>(
  fa: O.Option<E.Either<E, A>>,
) => E | A | undefined = flow(ET.toUnion(O.Functor), O.toUndefined)

export const ap = ET.ap(O.Apply)
export const apW = ap as <E2, A>(
  fa: OptionEither<E2, A>,
) => <E1, B>(fab: OptionEither<E1, (a: A) => B>) => OptionEither<E1 | E2, B>

export const matchW: <D, E, B, A, C>(
  onNone: () => D,
  onLeft: (e: E) => B,
  onRight: (a: A) => C,
) => (ma: OptionEither<E, A>) => D | B | C = (onNone, onLeft, onRight) =>
  flow(O.map(E.matchW(onLeft, onRight)), O.getOrElseW(onNone))

export const match: <E, A, B>(
  onNone: () => B,
  onLeft: (e: E) => B,
  onRight: (a: A) => B,
) => (ma: OptionEither<E, A>) => B = matchW

export const altOption: <E, A>(
  second: Lazy<OptionEither<E, A>>,
) => (first: OptionEither<E, A>) => OptionEither<E, A> = O.alt

export const altOptionW = altOption as <E2, B>(
  second: Lazy<OptionEither<E2, B>>,
) => <E1, A>(first: OptionEither<E1, A>) => OptionEither<E1 | E2, A | B>

export const altEither: <E, A>(
  second: Lazy<OptionEither<E, A>>,
) => (first: OptionEither<E, A>) => OptionEither<E, A> = ET.alt(O.Monad)
export const altEitherW = altEither as <E2, B>(
  second: Lazy<OptionEither<E2, B>>,
) => <E1, A>(first: OptionEither<E1, A>) => OptionEither<E2, A | B>

/**
 * Deconstructs to an SWR & apollo-client like struct
 */
export const toStruct: <E, A>(
  oe: OptionEither<E, A>,
) => {data: A | undefined; error: E | undefined; loading: boolean} = matchW(
  () => ({data: undefined, error: undefined, loading: true}),
  (error) => ({data: undefined, error, loading: false}),
  (data) => ({data, error: undefined, loading: false}),
)

export const chain = ET.chain(O.Monad)
export const chainW = ET.chain(O.Monad) as <A, E, B>(
  f: (a: A) => OptionEither<E, B>,
) => <E2>(ma: OptionEither<E2, A>) => OptionEither<E | E2, B>
export const chainOptionK = <A, B, L>(
  fn: (a: A) => O.Option<B>,
): ((ma: OptionEither<L, A>) => OptionEither<L, B>) =>
  chain<A, L, B>(flow(fn, O.map(E.of)))
export const chainNullableK = <A, B, L>(
  fn: (a: A) => B | undefined | null,
): ((ma: OptionEither<L, A>) => OptionEither<L, B>) =>
  chain<A, L, B>(flow(O.fromNullableK(fn), O.map(E.of)))

const constLeft = flow(left, constant)
/**
 * Useful for treating a nullable value as an error case.
 * The error has to be the same type as the incoming OptionEither,
 * @see {@link chainNullableToErrorKW} for a more permissive version
 * @param l - an error picked if `fn` returns a nullable value (null or undefined)
 */
export const chainNullableToErrorK = <L>(l: L) => {
  const handleEmpty = constLeft(l)
  /**
   * @param fn - a function that returns a nullable value
   */
  return <A, B>(
    fn: (a: A) => B | undefined | null,
  ): ((ma: OptionEither<L, A>) => OptionEither<L, B>) =>
    chain<A, L, B>(flow(O.fromNullableK(fn), O.matchW(handleEmpty, right)))
}
/**
 * Useful for treating a nullable value as an error case.
 * The error does not have to be the same type as the incoming OptionEither.
 * @see {@link chainNullableToErrorK} for a more stricter version
 * @param l - an error picked if `fn` returns a nullable value (null or undefined)
 */
export const chainNullableToErrorKW = <L2>(l: L2) => {
  const handleEmpty = constLeft(l)
  /**
   * @param fn - a function that returns a nullable value
   */
  return <A, B, L>(
    fn: (a: A) => B | undefined | null,
  ): ((ma: OptionEither<L, A>) => OptionEither<L | L2, B>) =>
    chainW<A, L2, B>(flow(O.fromNullableK(fn), O.matchW(handleEmpty, right)))
}

export const chainOptionToErrorK = <L>(l: L) => {
  const handleEmpty = constLeft(l)
  /**
   * @param fn - a function that returns a nullable value
   */
  return <A, B>(
    fn: (a: A) => O.Option<B>,
  ): ((ma: OptionEither<L, A>) => OptionEither<L, B>) =>
    chain<A, L, B>(flow(fn, O.matchW(handleEmpty, right)))
}

export const chainOptionToErrorKW = <L2>(l: L2) => {
  const handleEmpty = constLeft(l)
  /**
   * @param fn - a function that returns a nullable value
   */
  return <A, B, L>(
    fn: (a: A) => O.Option<B>,
  ): ((ma: OptionEither<L, A>) => OptionEither<L | L2, B>) =>
    chainW<A, L2, B>(flow(fn, O.matchW(handleEmpty, right)))
}

/**
 * Propagates the none or error from the first value,
 * but otherwise picks the second value.
 */
export const propagateNoneOrError: <E, F, A, B>(
  first: OptionEither<F, B>,
  second: OptionEither<E, A>,
) => OptionEither<E | F, A> = (first, second) =>
  pipe(
    first,
    chainW((_first) => second),
  )

export const Apply: _Apply.Apply2<URI> = {
  URI,
  ap: (fab, fa) => ap(fa)(fab),
  map: (fa, f) => map(f)(fa),
}

export const sequenceS = _Apply.sequenceS(Apply)
export const sequenceT = _Apply.sequenceT(Apply)

const emptyRecord = {}

// eslint-disable-next-line @typescript-eslint/ban-types
export const Do: OptionEither<never, {}> = of(emptyRecord)

export const apS = _Apply.apS(Apply)

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const apSW: <A, N extends string, E2, B>(
  name: Exclude<N, keyof A>,
  fb: OptionEither<E2, B>,
) => <E1>(fa: OptionEither<E1, A>) => OptionEither<
  E1 | E2,
  {readonly [K in keyof A | N]: K extends keyof A ? A[K] : B}
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
> = apS as any

type EnforceNonEmptyRecord<R> = keyof R extends never ? never : R

const allValid = _Apply.sequenceS(E.Apply)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RecOfOE = Record<string, OptionEither<any, any>>

/**
 * Converts a Record of OptionEithers into an Record of Eithers of a union of undefined.
 */
export const noneToUndefinedStruct = Rec.map(noneToUndefined) as <
  NER extends RecOfOE,
>(
  record: EnforceNonEmptyRecord<NER>,
) => {
  [K in keyof NER]: NER[K] extends OptionEither<infer L, infer R>
    ? E.Either<L, R | undefined>
    : never
}

type RecOptionToUndefined<NER extends RecOfOE> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [K in keyof NER]: NER[K] extends OptionEither<any, infer R>
    ? R | undefined
    : never
}

export const noneAndErrorToUndefinedStruct = Rec.map(
  noneAndErrorToUndefined,
) as <NER extends RecOfOE>(
  record: EnforceNonEmptyRecord<NER>,
) => RecOptionToUndefined<NER>

export const sequenceNoneToUndefined = <NER extends RecOfOE>(
  record: EnforceNonEmptyRecord<NER>,
): E.Either<
  {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [K in keyof NER]: NER[K] extends OptionEither<infer L, any> ? L : never
  }[keyof NER],
  RecOptionToUndefined<NER>
> =>
  pipe(
    record,
    noneToUndefinedStruct,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    allValid as (val: any) => E.Either<
      {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [K in keyof NER]: NER[K] extends OptionEither<infer L, any> ? L : never
      }[keyof NER],
      {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [K in keyof NER]: NER[K] extends OptionEither<any, infer R>
          ? R | undefined
          : never
      }
    >,
  )

export type SeqWithValues<NER extends RecOfOE> = [
  result: OptionEither<
    {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [K in keyof NER]: NER[K] extends OptionEither<infer L, any> ? L : never
    }[keyof NER],
    {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [K in keyof NER]: NER[K] extends OptionEither<any, infer R> ? R : never
    }
  >,
  values: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [K in keyof NER]: NER[K] extends OptionEither<any, infer R>
      ? R | undefined
      : never
  },
]

/**
 * Sequences a record, but exposes the empty or failed keys as a union with undefined
 * as the second position of a tuple.
 *
 * @example
 * ```typescript
 * function useBazWithFooAndBar() {
 *   const [result, values] = toSequenceAndUndefinedValues({ foo: useFoo(), bar: useBar() })
 *
 *   const result = useBaz(values.foo, values.bar ?? 42)
 *
 *   return pickSecond(result, values)
 * }
 * ```
 */
export const toSequenceAndUndefinedValues = <NER extends RecOfOE>(
  record: EnforceNonEmptyRecord<NER>,
): SeqWithValues<NER> =>
  [
    sequenceS(record),
    noneAndErrorToUndefinedStruct(record),
  ] as SeqWithValues<NER>
