import type {Exact} from "../../generated/graphql"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const emptyArray: ReadonlyArray<any> = []
/**
 * Instead of creating new empty arrays as (immutable) defaults
 * let's reuse one we can cast to a given type
 */
export const emptyArrayAs = <A>(): ReadonlyArray<A> =>
  emptyArray as ReadonlyArray<A>

/**
 * If you get an error when trying to use this, it's because the GQL query
 * does actually accept variables, but this function intentionally throws them away.
 * You'll want to either create a custom key deriving function or
 * use no key deriving function at all and let it fall back to the default!
 */
export const noGQLVars = (
  // eslint-disable-next-line @typescript-eslint/consistent-indexed-object-style
  _vars: Exact<{
    [key: string]: never
  }>,
) => emptyArrayAs()
