/* eslint-disable prefer-named-capture-group */
import {A, F, O, S, flow, pipe} from "@mobily/ts-belt"

const multipleSpaces = / +/g
const hybridLikeSymbols = /^[Xx] /
const inlineHybridLikeSymbols = / [Xx] /g
export const genusRegex = /^(\+ |× )?(\w|-)+/
export const speciesRegex = /^(\+ |× )?(\w|-)+ (\+ |× )?(\w|-)+/
export const infraspeciesRegex = /^((\+ |× )?(\w|-)+ ){2}(\w+\.? )?(\w|-)+/

export const prependScientificNamePart = (
  scientificNamePart: string | null | undefined,
  input: string | null | undefined,
) =>
  pipe(
    scientificNamePart,
    O.fromNullable,
    O.map(S.trim),
    O.map(S.replaceByRe(multipleSpaces, " ")),
    O.match(
      input == null || input === ""
        ? F.identity
        : flow(
            S.append(" "),
            S.append(pipe(input, S.trim, S.replaceByRe(multipleSpaces, " "))),
          ),
      () => input ?? "",
    ),
  )

export const processScientificName = flow(
  S.trim,
  S.replaceByRe(multipleSpaces, " "),
  S.replaceByRe(hybridLikeSymbols, "× "),
  S.replaceByRe(inlineHybridLikeSymbols, " × "),
)

export const removeScientificNameSubstring = (
  input: string,
  scientificName: string,
) => {
  let matchEnd = false

  return pipe(
    input,
    processScientificName,
    flow(
      S.toArray,
      A.filterWithIndex((i, c) => {
        const match =
          scientificName[i] == null ||
          c.toLowerCase() !== scientificName[i]?.toLowerCase()

        if (match) {
          matchEnd = true
        }

        return match || matchEnd
      }),
      A.filterWithIndex((i, c) => i !== 0 || c !== " "),
      A.join(""),
    ),
  )
}

export const removeScientificName = (input: string, scientificName: string) =>
  pipe(
    input,
    processScientificName,
    S.split(" "),
    (arr) =>
      pipe(
        arr,
        A.sliceToEnd(Math.min(scientificName.split(" ").length, arr.length)),
      ),
    A.join(" "),
  )

const processScientificNameForSearch =
  (regex: RegExp) => (scientificName: string) =>
    pipe(
      scientificName,
      processScientificName,
      S.match(regex),
      O.flatMap(A.head),
      O.getWithDefault<string>(scientificName),
    )

export const processScientificNameGenusForSearch =
  processScientificNameForSearch(genusRegex)

export const processScientificNameSpeciesForSearch =
  processScientificNameForSearch(speciesRegex)

export const processScientificNameInfraspeciesForSearch =
  processScientificNameForSearch(infraspeciesRegex)
