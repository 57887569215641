import type {MaterialFilters} from "src/features/filters/plant-materials/use-material-filters"
import {IconButton} from "@hortis/ui/button"
import type {PlantMaterialsSearchTerm} from "../../../../../generated/graphql"
import {PlantMaterialsSearchColumn} from "../../../../../generated/graphql"
import {SearchBar} from "../../../collection/components/search-bar/search-bar"
import {searchMenuLabels} from "../../utils"
import {FilterButtonContent} from "../filters-overlay/filter-button-content"
import {mapShadowMixin} from "../styles"

type SearchField = keyof typeof searchMenuLabels

type MapOverlayWithSearchProps = {
  handleSearchSubmit: (searchValue: string, searchField: SearchField) => void
  handleSearchClear: (field: PlantMaterialsSearchColumn) => void
  searchTerm: PlantMaterialsSearchTerm | undefined
  toggleFilters: () => void
  materialFilters: MaterialFilters
}

export const OverlayWithSearch = ({
  handleSearchClear,
  handleSearchSubmit,
  searchTerm,
  toggleFilters,
  materialFilters,
}: MapOverlayWithSearchProps) => {
  return (
    <div className="flex-0 pointer-events-auto flex w-full items-start gap-3 lg:w-[480px] lg:flex-[3]">
      <div className="pointer-events-auto flex-1">
        <SearchBar
          testId="search-form"
          menuLabels={searchMenuLabels}
          handleSubmitCallback={handleSearchSubmit}
          handleClearCallback={handleSearchClear}
          searchTermOverride={searchTerm}
          containerStyles={mapShadowMixin}
          searchFieldIfNumber={PlantMaterialsSearchColumn.MaterialNumber}
          transparentBorder
        />
      </div>
      <IconButton
        ariaLabel="Open filters"
        size={{xs: "sm", md: "md"}}
        className="border-none shadow-lg"
        onClick={toggleFilters}
        icon={
          <FilterButtonContent
            count={
              materialFilters.filterCounts.total -
              materialFilters.filterCounts.excludeAbsentMaterial
            }
          />
        }
      />
    </div>
  )
}
