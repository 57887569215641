import {useTheme} from "@mui/material/styles"
import type {ReactElement} from "react"
import {twMerge} from "tailwind-merge"
import {Avatar} from "@hortis/ui/avatar"
import type {PublicOrganisation} from "../../../../generated/graphql"
import {Skeleton} from "../../../components/skeleton"

interface OrgInfoProps {
  org: PublicOrganisation | null
  horizontal?: boolean
}

const AvatarSkeleton = () => {
  return (
    <Skeleton
      variant="rounded"
      animation="wave"
      height="72px"
      width="72px"
      style={{marginBottom: "4px"}}
    />
  )
}
const avatarSkeleton = <AvatarSkeleton />

const OrgNameSkeleton = () => {
  const theme = useTheme()
  return <Skeleton height={theme.spacing(4)} width="160px" animation="wave" />
}
const orgNameSkeleton = <OrgNameSkeleton />

const SubdomainSkeleton = () => {
  const theme = useTheme()
  return <Skeleton height={theme.spacing(2.5)} width="120px" animation="wave" />
}
const subdomainSkeleton = <SubdomainSkeleton />

export const OrgInfo = ({org, horizontal}: OrgInfoProps): ReactElement => {
  return (
    <div
      className={twMerge(
        "flex items-center",
        horizontal === true ? "flex-row" : "flex-col",
      )}
    >
      {org ? (
        <Avatar
          variant="rounded"
          className={
            horizontal === true
              ? "mr-3 !h-12 !w-12 !rounded-lg"
              : "mb-3 !h-[72px] !w-[72px] !rounded-lg"
          }
          src={org.avatarUrl ?? undefined}
          textContent={org.name
            .split(" ")
            .map((n) => n[0])
            .join("")}
        />
      ) : (
        avatarSkeleton
      )}
      <div
        className={twMerge(
          "flex flex-col",
          horizontal === true ? "" : "items-center",
        )}
      >
        {org ? (
          <div
            data-cy="org-name"
            className={twMerge(
              "font-medium",
              horizontal === true ? "text-left" : "text-center",
            )}
          >
            {org.name}
          </div>
        ) : (
          orgNameSkeleton
        )}
        {org ? (
          <div
            data-cy="org-url"
            className={twMerge(
              "text-sm text-grey-500",
              horizontal === true ? "text-left" : "text-center",
            )}
          >
            {`${org.subdomain ?? ""}.hortis.com`}
          </div>
        ) : (
          subdomainSkeleton
        )}
      </div>
    </div>
  )
}
