import {TaxaSearchColumn} from "generated/graphql"

export const taxaSearchMenuLabels = {
  [TaxaSearchColumn.ScientificName]: {
    labels: {
      shortName: "Name",
      name: "Scientific name",
    },
    placeholders: {
      shortName: "Search by name",
      name: "Search by scientific name",
    },
  },
} as const
