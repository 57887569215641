import type {ReactElement, SyntheticEvent} from "react"
import type {ButtonProps} from "@hortis/ui/button"
import {Button} from "@hortis/ui/button"
import {Box} from "../../../../components/box"
import type {SystemStyleObject} from "../../../../utils/sx"
import {fromSxFn} from "../../../../utils/sx"

const containerStyles = fromSxFn((theme) => ({
  zIndex: 1500,
  background: theme.palette.background.paper,
  padding: {xs: theme.spacing(4, 2), sm: theme.spacing(4, 6)},
  borderTop: `1px solid ${theme.palette.gray[200]}`,
  bottom: 0,
  position: "sticky",
  display: "flex",
  alignItems: {xs: "stretch", sm: "center"},
  flexWrap: "wrap-reverse",
  flexDirection: {xs: "column", sm: "row"},
  gap: theme.spacing(2),
}))

interface DrawerButtonProps {
  loading: boolean
  confirmLabel?: string
  cancelLabel?: string
  onConfirmClick?: (e: SyntheticEvent) => void
  onCancelClick: () => void
  confirmButtonType?: "button" | "submit"
  confirmTestId?: string
  containerSx?: SystemStyleObject
  size?: ButtonProps["size"]
}

export const DrawerButtons = ({
  loading,
  confirmLabel,
  cancelLabel,
  confirmButtonType,
  onConfirmClick,
  onCancelClick,
  confirmTestId,
  containerSx,
  size,
}: DrawerButtonProps): ReactElement => {
  return (
    <Box sx={[containerStyles, containerSx == null ? () => null : containerSx]}>
      <Button
        testId="cancel-button"
        size={size}
        onClick={onCancelClick}
        className="flex-1"
      >
        {cancelLabel ?? "Cancel"}
      </Button>
      <Button
        testId={confirmTestId ?? "confirm-button"}
        className="flex-1"
        size={size}
        loading={loading}
        onClick={onConfirmClick}
        type={confirmButtonType ?? "submit"}
        variant="primary"
      >
        {confirmLabel ?? "Confirm"}
      </Button>
    </Box>
  )
}
