import type {NullableStringComparator} from "generated/graphql"

export const countStringComparator = (
  filter: NonNullable<NullableStringComparator>,
) => {
  let count = 0
  if (filter.null === true) {
    count += 1
  }
  if (filter.eq != null) {
    count += 1
  }
  if (filter.contains != null) {
    count += 1
  }
  return count
}
