import type {PlantMaterialFilter} from "generated/graphql"
import type {LocalMaterialFilter} from "../types"

export const flattenLocationFilter = (
  filter: LocalMaterialFilter["location"],
): PlantMaterialFilter["location"] =>
  filter == null
    ? undefined
    : {
        is: filter.is?.map(({id}) => id),
        isNot: filter.isNot?.map(({id}) => id),
        contains: filter.contains,
      }
