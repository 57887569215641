import {Button, IconButton} from "@hortis/ui/button"
import {ChevronLeft, ChevronRight, Download1, Trash3} from "@hortis/ui/icons"
import type {RefObject} from "react"
import TableFooterSelectionMenu from "src/features/collection/components/table-footer-selection-menu"
import {useAccessRole} from "src/features/permissions/use-access-role"
import {twMerge} from "tailwind-merge"

export interface ListFooterProps {
  hasNextPage?: boolean
  hasPreviousPage?: boolean
  onPreviousPage?: () => void
  onNextPage?: () => void
  previousPageLoading?: boolean
  nextPageLoading?: boolean
  total?: number | null
  className?: string
  selectMode?: boolean
  toggleSelectMode?: () => void
  selectAllRows?: () => void
  selectedRowCount?: number
  handleDownload?: () => void
  onTrashRecords?: () => void
  testIdPrefix?: string
  isTrash?: boolean
  trashButtonRef?: RefObject<HTMLButtonElement>
  onActionsMenu?: () => void
}

export const ListFooter = ({
  onPreviousPage,
  onNextPage,
  hasPreviousPage,
  hasNextPage,
  total,
  previousPageLoading,
  nextPageLoading,
  className,
  toggleSelectMode,
  selectMode = false,
  isTrash = false,
  selectAllRows,
  selectedRowCount,
  handleDownload,
  onTrashRecords,
  testIdPrefix: _testIdPrefix,
  trashButtonRef,
  onActionsMenu, // eslint-disable-next-line sonarjs/cognitive-complexity
}: ListFooterProps) => {
  const {canEdit} = useAccessRole()
  const testIdPrefix = _testIdPrefix == null ? "" : `${_testIdPrefix}-`
  return (
    <div
      className={twMerge(
        "flex flex-col items-stretch gap-0 border-t border-grey-200 px-4 lg:px-6",
        className,
      )}
    >
      {selectMode && selectAllRows != null ? (
        <TableFooterSelectionMenu
          testId={`${testIdPrefix}mobile-select-mode-menu`}
          menuButtons={
            <div className="flex gap-2">
              <IconButton
                ariaLabel={"Download selected rows"}
                variant="tertiaryGray"
                disabled={selectedRowCount == null || selectedRowCount === 0}
                size="sm"
                className="disabled:bg-opacity-0"
                onClick={handleDownload}
                icon={Download1}
                testId="download-selected-rows-button"
              />
              {onTrashRecords && canEdit && (
                <IconButton
                  icon={Trash3}
                  variant="tertiaryGray"
                  size="sm"
                  ariaLabel="Move selected rows to trash"
                  disabled={selectedRowCount == null || selectedRowCount === 0}
                  onClick={onTrashRecords}
                  testId="trash-selected-rows-button"
                  ref={trashButtonRef}
                />
              )}
              {canEdit && (
                <Button
                  onClick={onActionsMenu}
                  size={"sm"}
                  testId="actions-menu-button"
                  disabled={selectedRowCount == null || selectedRowCount === 0}
                >
                  Actions
                </Button>
              )}
            </div>
          }
          className="!px-0 !py-0"
          totalRowsCount={total ?? 0}
          selectedRowsCount={selectedRowCount ?? 0}
          hasNoSelections={selectedRowCount === 0}
          handleSelectAll={selectAllRows}
        />
      ) : null}
      <div
        className={twMerge(
          "flex items-center justify-between pb-3 pt-3",
          selectMode && "pt-1",
        )}
      >
        {!isTrash && (
          <Button
            data-cy="select-mode-button"
            onClick={toggleSelectMode}
            size="sm"
          >
            {selectMode ? "Cancel" : "Select"}
          </Button>
        )}
        {total != null && (
          <div
            className="text-sm font-medium"
            data-cy={`${testIdPrefix}list-total`}
          >
            {total} record{total === 0 ? "" : "s"}
          </div>
        )}
        <div className="flex items-center gap-2">
          <IconButton
            ariaLabel="Previous page"
            size="sm"
            testId="pagination-prev-button"
            icon={ChevronLeft}
            onClick={onPreviousPage}
            disabled={hasPreviousPage !== true || nextPageLoading === true}
            loading={previousPageLoading === true}
          />
          <IconButton
            ariaLabel="Next page"
            size="sm"
            testId="pagination-next-button"
            icon={ChevronRight}
            onClick={onNextPage}
            disabled={hasNextPage !== true || previousPageLoading === true}
            loading={nextPageLoading === true}
          />
        </div>
      </div>
    </div>
  )
}
