import {css} from "@emotion/react"
import SwitchUnstyled, {switchUnstyledClasses} from "@mui/base/SwitchUnstyled"
import type {SwitchUnstyledProps} from "@mui/base/SwitchUnstyled"
import {twMerge} from "tailwind-merge"
import {colors} from "src/colors"
import {shadows} from "src/shadows"

export type ToggleSize = "xs" | "sm"

const styles = (size: ToggleSize) => css`
  position: relative;
  display: inline-block;
  cursor: pointer;
  flex-shrink: 0;
  height: ${size === "sm" ? "20px" : "16px"};
  width: ${size === "sm" ? "36px" : "28px"};

  & .${switchUnstyledClasses.track} {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    background-color: ${colors.grey[200]};
  }

  & .${switchUnstyledClasses.thumb} {
    display: block;
    position: relative;
    top: 2px;
    left: 2px;
    border-radius: 16px;
    background-color: ${colors.white};
    transition: all 200ms ease;
    box-shadow: ${shadows.sm};
    width: ${size === "sm" ? "16px" : "12px"};
    height: ${size === "sm" ? "16px" : "12px"};
  }

  &.${switchUnstyledClasses.focusVisible} .${switchUnstyledClasses.track} {
    box-shadow: 0 0 0 4px ${colors.primary[100]};
  }

  &.${switchUnstyledClasses.checked} {
    .${switchUnstyledClasses.thumb} {
      left: ${size === "sm" ? "18px" : "14px"};
    }

    .${switchUnstyledClasses.track} {
      background-color: ${colors.primary[600]};
    }
  }

  &.${switchUnstyledClasses.disabled} {
    .${switchUnstyledClasses.track} {
      background-color: ${colors.grey[200]};
    }
  }

  & .${switchUnstyledClasses.input} {
    cursor: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 10;
    margin: 0;
  }
`

export type ToggleProps = SwitchUnstyledProps & {
  ariaLabel?: string
  size?: ToggleSize
  testId?: string
}

export const Toggle = ({
  ariaLabel,
  className,
  size = "sm",
  disabled,
  testId,
  ...props
}: ToggleProps) => {
  const label = {
    input: {
      "aria-label": ariaLabel ?? undefined,
    },
  }
  return (
    <SwitchUnstyled
      css={styles(size)}
      slotProps={label}
      className={twMerge(className)}
      disabled={disabled}
      data-cy={testId}
      {...props}
    />
  )
}
