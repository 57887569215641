import {F} from "@mobily/ts-belt"
import {
  ScientificNameSortColumn,
  SortDirection,
  TaxonomicRank,
  useGetOrgSharedScientificNamesQuery,
  useGetSiteSharedScientificNamesQuery,
} from "generated/graphql"
import type {SyntheticEvent} from "react"
import {useCallback, useMemo, useState} from "react"
import {FormAutocomplete} from "src/components/autocomplete"
import {useControlledDebounce} from "src/utils/hooks/debounce"
import {
  useOrganisationSubdomainStruct,
  usePlaceStruct,
} from "src/utils/hooks/place"
import {
  getOptionLabel,
  isOptionEqual,
  renderAutoCompleteItem,
  renderAutocompleteGroup,
  renderTags,
  type ScientificNameOption,
} from "./utils"

interface ScientificNameAutocompleteProps {
  name: string
  onInputChange: (newValue: string) => void
  testId?: string
  showAuthorship?: boolean
  options: ReadonlyArray<ScientificNameOption>
  loading?: boolean
}

export const ScientificNameAutocomplete = ({
  name,
  testId,
  showAuthorship,
  onInputChange: _onInputChange,
  options,
  loading,
}: ScientificNameAutocompleteProps) => {
  const {schedule} = useControlledDebounce(
    (_: SyntheticEvent, newValue: string) => {
      _onInputChange(newValue)
    },
  )

  const onInputChange = useCallback(
    (_: SyntheticEvent, newValue: string) => {
      schedule(_, newValue)
    },
    [schedule],
  )

  const renderOption = useMemo(
    () => renderAutoCompleteItem({showAuthorship}),
    [showAuthorship],
  )

  return (
    <FormAutocomplete
      renderOption={renderOption}
      options={options}
      label={null}
      multiple
      renderGroup={renderAutocompleteGroup}
      renderTags={renderTags}
      loading={loading === true && options.length === 0}
      onInputChange={onInputChange}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqual}
      filterOptions={F.identity}
      fullWidth
      testId={testId}
      name={name}
      componentsProps={{popper: {disablePortal: true}}}
    />
  )
}

export const OrgFamilyAutocomplete = ({
  name,
  testId,
}: {
  name: string
  testId?: string
}) => {
  const [inputValue, setInputValue] = useState("")
  const organisationSubdomain = useOrganisationSubdomainStruct()
  const [{data, fetching}] = useGetOrgSharedScientificNamesQuery({
    variables: {
      last: 100,
      searchTerm: inputValue,
      organisationSubdomain: organisationSubdomain.data ?? "",
      filter: {
        rank: {
          eq: TaxonomicRank.Family,
        },
      },
      orderBy: {
        direction: SortDirection.Asc,
        field: ScientificNameSortColumn.Status,
      },
    },
    pause: organisationSubdomain.data == null,
  })

  return (
    <ScientificNameAutocomplete
      name={name}
      testId={testId}
      onInputChange={setInputValue}
      options={data?.org?.sharedScientificNames?.nodes ?? []}
      loading={fetching || organisationSubdomain.loading}
    />
  )
}

export const SiteFamilyAutocomplete = ({
  name,
  testId,
}: {
  name: string
  testId?: string
}) => {
  const [inputValue, setInputValue] = useState("")
  const place = usePlaceStruct()
  const [{data, fetching}] = useGetSiteSharedScientificNamesQuery({
    variables: {
      last: 100,
      searchTerm: inputValue,
      organisationSubdomain: place.data?.orgName ?? "",
      collectionSiteSlug: place.data?.siteSlug ?? "",
      filter: {
        rank: {
          eq: TaxonomicRank.Family,
        },
      },
      orderBy: {
        direction: SortDirection.Asc,
        field: ScientificNameSortColumn.Status,
      },
    },
    pause: place.data == null,
  })

  return (
    <ScientificNameAutocomplete
      name={name}
      testId={testId}
      onInputChange={setInputValue}
      options={data?.org?.site?.sharedScientificNames?.nodes ?? []}
      loading={fetching || place.loading}
    />
  )
}
