import {flow} from "fp-ts/function"
import * as RNEA from "fp-ts/ReadonlyNonEmptyArray"
import type {HTMLAttributes} from "react"
import type {
  CountryFieldsFragment,
  GetCountriesQuery,
} from "../../../../../../generated/graphql"
import {FormAutocomplete} from "../../../../../components/autocomplete/autocomplete"
import {TextField} from "../../../../../components/text-field/text-field"
import * as OE from "../../../../../utils/option-either"
import {isOptionEqual} from "../../../../locations/utils"
import {useCountries} from "../../../fetchers"

const showErrorMessage = (error: string) => <>{error}</>

const renderCountryOption = (
  props: HTMLAttributes<HTMLLIElement>,
  option: CountryFieldsFragment,
) => <li {...props}>{option.name}</li>

const getCountryOptionLabel = (option: CountryFieldsFragment | null) =>
  option?.name ?? ""

const countryPlaceholder = (
  <TextField
    label="Country"
    name="country-placeholder"
    disabled
    placeholder="Loading countries"
  />
)

const displayCountriesAutocomplete = flow(
  OE.chainNullableToErrorK(new Error("Countries property didn't exist"))(
    (res: GetCountriesQuery) => res.countries,
  ),
  OE.chainOptionToErrorK(new Error("Countries property was empty"))(
    RNEA.fromReadonlyArray,
  ),
  OE.mapLeft(() => "Unable to load countries"),
  OE.match(
    () => countryPlaceholder,
    showErrorMessage,
    (countries) => (
      <FormAutocomplete
        name="countryOption"
        label="Country"
        testId="country"
        options={countries}
        isOptionEqualToValue={isOptionEqual}
        renderOption={renderCountryOption}
        getOptionLabel={getCountryOptionLabel}
        sx={{
          "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
            padding: "2.5px",
            paddingLeft: "8px",
          },
        }}
      />
    ),
  ),
)

export const CountriesAutocomplete = flow(
  useCountries,
  displayCountriesAutocomplete,
)
