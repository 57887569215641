import {zodResolver} from "@hookform/resolvers/zod"
import {Button} from "@hortis/ui/button"
import {
  SheetBody,
  SheetDescription,
  SheetFooter,
  SheetForm,
  SheetHeader,
  SheetTitle,
} from "@hortis/ui/sheet"
import {useEffect, useMemo} from "react"
import {FormProvider, useForm} from "react-hook-form"
import {useSnackbarStore} from "src/components/snackbar-controller/snackbar-store"
import {onFailure, onSuccess} from "src/notification-snack-utils"
import {match} from "ts-pattern"
import type {TaxonFieldsFragment} from "../../../../../../generated/graphql"
import {useUpdateTaxonMutation} from "../../../../../../generated/graphql"
import {taxonFieldsSchema, type TaxonFields} from "../../new-taxon/schema"
import {TaxonDataFields} from "../../new-taxon/taxon-data-fields"

export interface TaxonEditDrawerProps {
  taxon: TaxonFieldsFragment
  open: boolean
  requestClose: () => void
  refetchTaxon: () => void
}

export const EditTaxonSheetForm = ({
  taxon,
  requestClose,
  refetchTaxon,
  open,
}: TaxonEditDrawerProps) => {
  const formMethods = useForm<TaxonFields>({
    defaultValues: {
      ...taxon,
      tags: taxon.tagsConnection?.nodes.map(({id, name}) => ({id, name})) ?? [],
    },
    resolver: zodResolver(taxonFieldsSchema),
  })

  useEffect(() => {
    formMethods.reset({
      ...taxon,
      tags: taxon.tagsConnection?.nodes.map(({id, name}) => ({id, name})) ?? [],
    })
  }, [open, formMethods, taxon])

  const {setSnack} = useSnackbarStore()
  const [, updateTaxon] = useUpdateTaxonMutation()

  const taxaInterpretationEnabled =
    taxon.organisation?.taxaInterpretationEnabled

  const handleSubmit = useMemo(
    () =>
      formMethods.handleSubmit(async (_data) => {
        try {
          const {sharedScientificName, ...data} = _data
          const res = await updateTaxon({
            id: taxon.id,
            input: {
              ...data,
              sharedScientificNameId: sharedScientificName?.id ?? null,
              tags: data.tags.map(({id}) => id),
            },
          })
          if (res.data?.updateTaxon.success === true) {
            onSuccess(setSnack)(`Successfully updated taxon`)
            requestClose()
            refetchTaxon()
            return
          }
          const errors = res.data?.updateTaxon.errors
          if (errors != null && errors.length > 0) {
            errors.forEach((error) => {
              match(error.__typename)
                .with("DuplicateScientificNameError", () => {
                  formMethods.setError("scientificName", {
                    message: error.message,
                  })
                })
                .exhaustive()
            })
            return
          }
          throw new Error("Failed to update taxon")
        } catch {
          onFailure(setSnack)(new Error(`Failed to update taxon`))
        }
      }),
    [formMethods, updateTaxon, taxon.id, setSnack, requestClose, refetchTaxon],
  )

  return (
    <FormProvider {...formMethods}>
      <SheetForm onSubmit={handleSubmit}>
        <SheetHeader>
          <SheetTitle>Edit taxon</SheetTitle>
          <SheetDescription>Make changes to taxon data</SheetDescription>
        </SheetHeader>
        <SheetBody>
          <TaxonDataFields
            interpretationFieldsEnabled={taxaInterpretationEnabled}
          />
        </SheetBody>
        <SheetFooter>
          <Button
            testId="cancel-edit-button"
            onClick={() => {
              requestClose()
            }}
          >
            Cancel
          </Button>
          <Button testId="save-taxon-button" type="submit" variant="primary">
            Save
          </Button>
        </SheetFooter>
      </SheetForm>
    </FormProvider>
  )
}
