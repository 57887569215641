import {useEffect, useMemo} from "react"
import {useFormContext} from "react-hook-form"
import {FormSelectField} from "src/components/select-field"
import {TagsAutocompleteAlt} from "src/features/tags/tags-autocomplete-alt/tags-autocomplete-alt"
import {useCollectionSiteSettings} from "src/utils/hooks/collection-site-from-url"
import {
  MaterialGroup,
  MaterialSex,
  PlantMaterialStatus,
} from "../../../../../generated/graphql"
import {
  ChipGridStylingPreset,
  FormChipGrid,
} from "../../../../components/chip-grid"
import {BasicDatePicker} from "../../../../components/date-picker/date-picker"
import {FormTextField} from "../../../../components/text-field/text-field"
import {LabeledSwitch} from "../labeled-switch"
import {ObservationFields} from "./observation-fields"
import {PositionFields} from "./position-fields"
import {QuantityWeightField} from "./quantity-weight-field/quantity-weight-field"
import type {PlantMaterialData} from "./schema"

export const materialGroupChips = [
  {label: "Plant", value: MaterialGroup.Plant},
  {label: "Seed", value: MaterialGroup.Seed},
]

export const statusChips = [
  {label: "Present", value: PlantMaterialStatus.Present},
  {label: "Absent", value: PlantMaterialStatus.Absent},
  {label: "Transient", value: PlantMaterialStatus.Transient},
  {label: "Unknown", value: PlantMaterialStatus.Unknown},
]

export const materialSexOptions = [
  {label: "Agamosperm", value: MaterialSex.Agamosperm},
  {label: "Female", value: MaterialSex.Female},
  {label: "Hermaphrodite", value: MaterialSex.Hermaphrodite},
  {label: "Male", value: MaterialSex.Male},
  {label: "Unknown", value: MaterialSex.Unknown},
]

export interface PlantMaterialDataFieldsProps {
  formNamePrefix?: string
  responsiveChips?: boolean
  hidePositionFields?: boolean
  hideObservationFields?: boolean
}

type EditableFields = Omit<PlantMaterialData, "tempId">
export type FormFields = {
  [Key in keyof EditableFields as `${string}${Key}`]: EditableFields[Key]
}

export const PlantMaterialDataFields = ({
  formNamePrefix = "",
  responsiveChips,
  hidePositionFields,
  hideObservationFields,
}: PlantMaterialDataFieldsProps) => {
  const {getValues, watch, setValue} = useFormContext<FormFields>()
  const materialGroup = watch(`${formNamePrefix}materialGroup`)

  const {tagNumbersEnabled, sexEnabled} = useCollectionSiteSettings()

  useEffect(() => {
    if (materialGroup === MaterialGroup.Seed) {
      setValue(`${formNamePrefix}massPlanting`, false)
    }
  }, [materialGroup, formNamePrefix, setValue])

  const statusOption = watch(`${formNamePrefix}status`)
  const hideDateRemovedField = useMemo(() => {
    return statusOption !== PlantMaterialStatus.Absent
  }, [statusOption])

  useEffect(() => {
    if (hideDateRemovedField) {
      setValue(`${formNamePrefix}firstAbsent`, null)
    } else if (getValues(`${formNamePrefix}firstAbsent`) == null) {
      setValue(`${formNamePrefix}firstAbsent`, new Date())
    }
  }, [hideDateRemovedField, getValues, setValue, formNamePrefix])

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-6">
        <LabeledSwitch
          name={`${formNamePrefix}materialQualifier`}
          primaryLabel="Material number"
        />
        <BasicDatePicker
          name={`${formNamePrefix}firstPresent`}
          initialValue={getValues(`${formNamePrefix}firstPresent`)}
          label="Date added"
          testId="date-added"
          required
        />
        <FormChipGrid
          chips={materialGroupChips}
          label="Type"
          name={`${formNamePrefix}materialGroup`}
          initialValue={getValues(`${formNamePrefix}materialGroup`)}
          stylingPreset={
            responsiveChips === undefined
              ? ChipGridStylingPreset.BASIC_GRID
              : ChipGridStylingPreset.RESPONSIVE_GRID
          }
          testId="material-group"
          required
        />
        <FormChipGrid
          chips={statusChips}
          label="Status"
          name={`${formNamePrefix}status`}
          initialValue={getValues(`${formNamePrefix}status`)}
          stylingPreset={
            responsiveChips === undefined
              ? ChipGridStylingPreset.BASIC_GRID
              : ChipGridStylingPreset.RESPONSIVE_GRID
          }
          testId="status"
          required
        />
        {!hideDateRemovedField && (
          <BasicDatePicker
            name={`${formNamePrefix}firstAbsent`}
            label="Date removed"
            testId="date-removed"
            required
          />
        )}
        <QuantityWeightField formNamePrefix={formNamePrefix} />

        {sexEnabled && (
          <FormSelectField
            label="Sex"
            name={`${formNamePrefix}sex`}
            testId="sex"
          >
            <option aria-label="None" value={""} />
            {materialSexOptions.map(({value, label}) => (
              <option value={value} key={value}>
                {label}
              </option>
            ))}
          </FormSelectField>
        )}
        {tagNumbersEnabled && (
          <FormTextField
            fullWidth
            label="Tag number"
            testId="tagNumber"
            name={`${formNamePrefix}tagNumber`}
          />
        )}
        <TagsAutocompleteAlt
          name={`${formNamePrefix}tags`}
          testId="material-tags"
        />
        <FormTextField
          name={`${formNamePrefix}notes`}
          label="Notes"
          placeholder="Add notes"
          multiline
          minRows={2}
          maxRows={6}
          testId="material-notes"
          fullWidth
        />
      </div>
      {hideObservationFields !== true && (
        <ObservationFields namePrefix={formNamePrefix} />
      )}
      <PositionFields
        namePrefix={formNamePrefix}
        hideMap={hidePositionFields}
      />
    </div>
  )
}
