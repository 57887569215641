import Collapse from "@mui/material/Collapse"
import type {PropsWithChildren} from "react"
import {useCallback, useState} from "react"
import {colors} from "src/colors"
import {ChevronDown} from "src/components/icons/streamline/chevron-down"
import {SlChevronUpIcon} from "src/components/icons/streamline/chevron-up"

interface CollapsableNavSectionProps {
  label: string
  initialState?: boolean
  testId?: string
}

export const CollapsableNavSection = ({
  label,
  children,
  testId,
  initialState = false,
}: PropsWithChildren<CollapsableNavSectionProps>) => {
  const [open, setOpen] = useState(initialState)

  const toggleOpen = useCallback(() => {
    setOpen((x) => !x)
  }, [])

  return (
    <div className="flex flex-col gap-0.5">
      <button
        onClick={toggleOpen}
        type="button"
        aria-expanded={open}
        data-cy={testId}
        className="flex w-full items-center gap-0.5 rounded-[6px] px-3 py-2 pl-12 text-sm font-medium outline-none ring-primary-100 transition-colors hover:bg-grey-50 hover:text-grey-900 focus-visible:ring-4"
      >
        <span className="truncate">{label}</span>
        <div className="shrink-0">
          {open ? (
            <ChevronDown color={colors.grey[400]} width="20px" height="20px" />
          ) : (
            <SlChevronUpIcon
              color={colors.grey[400]}
              width="20px"
              height="20px"
            />
          )}
        </div>
      </button>
      <Collapse in={open}>
        <ul className="flex flex-col gap-0.5">{children}</ul>
      </Collapse>
    </div>
  )
}
