import {keyframes} from "@emotion/react"
import type {CSSProperties} from "react"
import {twMerge} from "tailwind-merge"

const SIZE = 44

const circularRotateKeyframe = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`

const circularDashKeyframe = keyframes`
  0% {
    stroke-dasharray: 1px, 200px;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -15px;
  }

  100% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -125px;
  }
`

export interface CircularProgressProps {
  className?: string
  size?: number
  thickness?: number
  style?: CSSProperties
}

export const CircularProgress = ({
  className,
  size = 40,
  thickness = 4.2,
  style,
}: CircularProgressProps) => {
  return (
    <div
      className={twMerge("text-primary-500 progress inline-block", className)}
      style={{width: size, height: size, ...style}}
      role="progressbar"
      css={{
        animation: `${circularRotateKeyframe} 1.4s linear infinite`,
      }}
    >
      <svg viewBox={`${SIZE / 2} ${SIZE / 2} ${SIZE} ${SIZE}`}>
        <circle
          css={{
            animation: `${circularDashKeyframe} 1.4s ease-in-out infinite`,
          }}
          className="stroke-current transition-all"
          style={{strokeDasharray: "80px, 200px", strokeDashoffset: 0}}
          cx={SIZE}
          cy={SIZE}
          r={(SIZE - thickness) / 2}
          fill="none"
          strokeWidth={thickness}
        />
      </svg>
    </div>
  )
}
