import {pipe, R} from "@mobily/ts-belt"
import {useCallback} from "react"
import type {ReactElement} from "react"
import {MapProvider} from "react-map-gl"
import {IconButton} from "@hortis/ui/button"
import {useMaplibre} from "src/components/map/utils"
import {useSnackbarStore} from "src/components/snackbar-controller/snackbar-store"
import type {CenterOverlayType} from "src/features/collection-map/components/center-overlay"
import {CenterOverlay} from "src/features/collection-map/components/center-overlay"
import CustomOverlay from "src/features/collection-map/components/custom-overlay"
import {onFailure} from "src/notification-snack-utils"
import {DrawerButtons} from "src/features/records/components/drawer-buttons"
import {
  ControlledMap,
  useFetchMapData,
} from "src/features/records/material/components/material-title-card/controlled-map"
import {XClose} from "@hortis/ui/icons"
import {Dialog, DialogContent} from "@hortis/ui/dialog"
import {MapActionButtons} from "../actions/map-action-buttons"
import {BaseMapLayers} from "../layers/base-map-layers"
import type {EditableFields} from "./utils"
import {parseNullableStrToNum} from "./utils"

export interface MapPositionModalProps {
  open: boolean
  onOpenChange: (open: boolean) => void
  onConfirm: (args: {latitude: number; longitude: number; zoom: number}) => void
  initialPosition: EditableFields["position"]
  zoom?: number
  geometryLayers?: ReactElement
  title?: string
  description?: string
  centerOverlayType?: CenterOverlayType
  useSiteCoordinates?: boolean
}

const MapPositionModalContent = ({
  open: _,
  onOpenChange,
  onConfirm,
  initialPosition,
  zoom,
  geometryLayers,
  title,
  description,
  centerOverlayType,
  useSiteCoordinates,
}: MapPositionModalProps) => {
  const {setSnack} = useSnackbarStore()
  const {["preview-map"]: ourMap} = useMaplibre()
  const mapData = useFetchMapData()

  const handleConfirm = useCallback(() => {
    if (ourMap == null) {
      onFailure(setSnack)(new Error("Failed to get map instance"))
      return
    }
    const center = ourMap.getCenter()
    const zoom = ourMap.getZoom()
    onConfirm({latitude: center.lat, longitude: center.lng, zoom: zoom})
    onOpenChange(false)
  }, [ourMap, onConfirm, onOpenChange, setSnack])

  return (
    <ControlledMap
      zoom={zoom ?? 16}
      latitude={parseNullableStrToNum(initialPosition?.latitude)}
      longitude={parseNullableStrToNum(initialPosition?.longitude)}
      useSiteCoordinates={useSiteCoordinates}
      mapProps={{
        attributionControl: undefined,
        customAttribution:
          pipe(
            mapData,
            R.match(
              ({baseMap}) => baseMap?.attribution ?? null,
              () => null,
            ),
          ) ?? undefined,
      }}
    >
      <BaseMapLayers
        baseMap={
          pipe(
            mapData,
            R.match(
              ({baseMap}) => baseMap ?? null,
              () => null,
            ),
          ) ?? undefined
        }
      />
      {geometryLayers}
      <CustomOverlay>
        <CenterOverlay type={centerOverlayType} />
      </CustomOverlay>
      <div className="flex h-full w-full flex-col justify-between">
        <div className="flex flex-col gap-6 self-end p-3 md:gap-8 md:p-4">
          <IconButton
            testId="close-map-button"
            ariaLabel="Close map"
            size={{xs: "xs", md: "sm"}}
            className="border-none !shadow-lg"
            onClick={() => {
              onOpenChange(false)
            }}
            icon={XClose}
          />
          <MapActionButtons
            baseMap={pipe(
              mapData,
              R.match(
                ({baseMap}) => baseMap ?? null,
                () => null,
              ),
            )}
            mapId="preview-map"
            initialViewState={pipe(
              mapData,
              R.match(
                ({siteZoomLevel, siteLongitude, siteLatitude}) =>
                  siteLongitude == null ||
                  siteLatitude == null ||
                  siteZoomLevel == null
                    ? undefined
                    : {
                        latitude: siteLatitude,
                        longitude: siteLongitude,
                        zoom: siteZoomLevel,
                      },
                () => undefined,
              ),
            )}
          />
        </div>
        <div className="z-10 overflow-hidden rounded-lg bg-white shadow-lg md:m-4 md:max-w-md md:self-start">
          <div className="flex flex-col gap-2 p-6">
            <div className="text-base font-medium text-grey-900">{title}</div>
            <div className="text-sm text-grey-500">{description}</div>
          </div>
          <DrawerButtons
            loading={false}
            containerSx={{
              padding: {xs: 2, sm: 3},
              flexDirection: {xs: "row", sm: "row"},
            }}
            onCancelClick={() => {
              onOpenChange(false)
            }}
            onConfirmClick={handleConfirm}
            cancelLabel="Cancel"
            confirmTestId="confirm-update-position"
          />
        </div>
      </div>
    </ControlledMap>
  )
}

export const MapPositionModal = ({
  open,
  onOpenChange,
  ...props
}: MapPositionModalProps) => (
  <MapProvider>
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="h-[97%] w-[97%] !max-w-none overflow-hidden !rounded-2xl !p-0">
        <MapPositionModalContent
          open={open}
          onOpenChange={onOpenChange}
          {...props}
        />
      </DialogContent>
    </Dialog>
  </MapProvider>
)
