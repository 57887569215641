import {useEffect} from "react"
import {useFormContext} from "react-hook-form"
import {
  selectDefaultMaterialType,
  useMaterialTypes as useMaterialTypes_,
} from "../../create-accession/utils"
import type {Fields} from "../accession-data-form"

export const useMaterialTypes = () => {
  const {getValues, setValue} = useFormContext<Fields>()
  const materialTypes = useMaterialTypes_()
  const firstMaterialTypeId = selectDefaultMaterialType(materialTypes)

  useEffect(() => {
    const materialTypeId = getValues("materialTypeId")
    if (
      firstMaterialTypeId != null &&
      (materialTypeId === "" ||
        // This could be null in edit flows, need to tweak form types
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        materialTypeId == null)
    ) {
      setValue("materialTypeId", firstMaterialTypeId)
    }
  }, [firstMaterialTypeId, getValues, setValue])

  return materialTypes
}
