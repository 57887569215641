import type {ReactNode} from "react"
import {twMerge} from "tailwind-merge"

export interface DataTableToolbarProps {
  children: ReactNode
  className?: string
}

export const DataTableToolbar = ({
  children,
  className,
}: DataTableToolbarProps) => {
  return <div className={twMerge("flex px-6 py-4", className)}>{children}</div>
}
