import type {Condition, ConditionFilter} from "generated/graphql"
import {conditionLabelMap} from "src/utils/label-maps/condition"
import type {FilterOperation} from "../types"

export const extractConditionFilterData = (
  filter: NonNullable<ConditionFilter>,
  operation: FilterOperation,
): Array<string> | undefined => {
  const isFilters = filter.or
    ?.flatMap(({eq}) => eq)
    .filter((val): val is Condition => val != null)
  const isNotFilters = filter.or
    ?.flatMap(({neq}) => neq)
    .filter((val): val is Condition => val != null)

  switch (operation) {
    case "is": {
      return isFilters?.map((status) => conditionLabelMap[status])
    }
    case "isNot": {
      return isNotFilters?.map((status) => conditionLabelMap[status])
    }
    default: {
      return undefined
    }
  }
}
