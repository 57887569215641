import {IconButton} from "@hortis/ui/button"
import {ExternalLink1} from "@hortis/ui/icons"
import {IUCNRedListTag} from "@hortis/ui/iucn-red-list-tag"
import {Link} from "@tanstack/react-router"
import {type AccessionFieldsFragment} from "generated/graphql"

import {
  RecordField,
  RecordFieldLabel,
  RecordFieldValue,
} from "src/features/records/components/record-field/new-record-field"

import {
  RecordSection,
  RecordSectionContent,
  RecordSectionContentGroup,
  RecordSectionHeader,
  RecordSectionHeaderButtons,
  RecordSectionTitle,
} from "src/features/records/components/record-section/new-record-section"

interface AccessionRecordTaxonProps {
  accession: AccessionFieldsFragment
}

export const AccessionRecordTaxon = ({
  accession,
}: AccessionRecordTaxonProps) => {
  return (
    <RecordSection id="taxon">
      <RecordSectionHeader>
        <RecordSectionTitle>Taxon</RecordSectionTitle>
        <RecordSectionHeaderButtons>
          <IconButton
            ariaLabel="Go to taxon"
            data-cy="go-to-taxon-button"
            size="xs"
            variant="tertiaryGray"
            icon={ExternalLink1}
            asChild
          >
            <Link
              to="/taxonomy/$taxonId"
              params={{taxonId: accession.taxon?.id ?? ""}}
            />
          </IconButton>
        </RecordSectionHeaderButtons>
      </RecordSectionHeader>
      <RecordSectionContent>
        <RecordSectionContentGroup className="grid gap-4 lg:grid-cols-2">
          <RecordField data-cy="taxon-family">
            <RecordFieldLabel>Family</RecordFieldLabel>
            <RecordFieldValue>
              {accession.taxon?.sharedScientificName?.classification?.family ??
                "-"}
            </RecordFieldValue>
          </RecordField>
          <RecordField data-cy="native-distribution">
            <RecordFieldLabel>Native distribution</RecordFieldLabel>
            <RecordFieldValue>
              {accession.taxon?.nativeDistribution ?? "-"}
            </RecordFieldValue>
          </RecordField>
          <RecordField data-cy="iucn-status">
            <RecordFieldLabel>IUCN Red List</RecordFieldLabel>
            <RecordFieldValue>
              {accession.taxon?.sharedScientificName?.taxon
                ?.globalRedListStatus ? (
                <IUCNRedListTag
                  status={
                    accession.taxon.sharedScientificName.taxon
                      .globalRedListStatus
                  }
                />
              ) : (
                "-"
              )}
            </RecordFieldValue>
          </RecordField>
        </RecordSectionContentGroup>
      </RecordSectionContent>
    </RecordSection>
  )
}
