import {useState, useEffect} from "react"

type OptionalLocation = Location | undefined

/**
 * Gets the current window.location.
 * This is a side effect that won't be present at build time or on first render.
 */
export const useLocation = (): OptionalLocation => {
  const [location, setLocation] = useState<Location | undefined>(undefined)
  useEffect(() => {
    const onPopState = (_event: unknown) => {
      setLocation(window.location)
    }
    window.addEventListener("onpopstate", onPopState)
    setLocation(window.location)

    return () => {
      window.removeEventListener("onpopstate", onPopState)
    }
  }, [])
  return location
}
