import {Alert, AlertContent, AlertText} from "@hortis/ui/alert"
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@hortis/ui/breadcrumb"
import {Button} from "@hortis/ui/button"
import {useMediaQuery} from "@hortis/ui/hooks/media-query"
import {
  AlertCircle,
  ArrowLeft,
  Folder,
  GitBranch1,
  InfoCircle,
  Tag1,
} from "@hortis/ui/icons"
import {Sheet, SheetContent} from "@hortis/ui/sheet"
import {Skeleton} from "@hortis/ui/skeleton"
import {Link, createFileRoute} from "@tanstack/react-router"
import {
  useGetAccessionQuery,
  useGetCollectionSiteFromNamesQuery,
} from "generated/graphql"
import {Fragment, useCallback, useState} from "react"
import {Helmet} from "react-helmet-async"
import {TopBar, TopBarContent} from "src/components/nav-layout/topbar"
import {useTopbarStore} from "src/components/nav-layout/topbar/topbar-store"
import {PageContent} from "src/components/page-content"
import {createMaterialNumber} from "src/features/collection/components/plant-materials/material-number"
import {AccessionContents} from "src/features/records/accession/components/accession-contents"
import {EditAccessionSheetContent} from "src/features/records/accession/components/edit-drawer"

import {AccessionEditStage} from "src/features/records/accession/components/edit-drawer/types"
import {accessionRouteSchema} from "src/features/records/accession/components/route"
import {
  RecordField,
  RecordLabelSkeleton,
  RecordValueSkeleton,
} from "src/features/records/components/record-field/new-record-field"
import {RecordSectionContentGroup} from "src/features/records/components/record-section/new-record-section"
import {
  HierarchyContent,
  HierarchyListGroup,
  HierarchyPopover,
  HierarchyTrigger,
} from "src/features/records/material/components/hierarchy-popover"
import HierarchyListItem from "src/features/records/material/components/hierarchy-popover/hierachy-list-item"
import {twMerge} from "tailwind-merge"

export const Route = createFileRoute(
  "/_layout/sites/$siteSlug/accessions/$accessionNumber",
)({
  component: Component,
  validateSearch: (search) => accessionRouteSchema.parse(search),
})

const NotFoundAlert = () => (
  <Alert color="grey" data-cy="not-found-alert">
    <InfoCircle />
    <AlertContent>
      <AlertText>Accession not found</AlertText>
    </AlertContent>
  </Alert>
)

const ErrorAlert = () => (
  <Alert color="error" data-cy="error-alert">
    <AlertCircle />
    <AlertContent>
      <AlertText>Something went wrong</AlertText>
    </AlertContent>
  </Alert>
)

const PageSkeleton = () => (
  <PageContent responsivePadding className="overflow-y-auto overflow-x-hidden">
    <div className="flex flex-col gap-6 px-4 lg:mt-[57px] lg:gap-8 lg:px-8">
      {/* Record Page Header skeleton */}
      <div className="flex flex-col gap-4 bg-white lg:flex-row lg:gap-12 xl:grid xl:grid-cols-[188px_auto]">
        <div className="h-[188px] w-full lg:max-w-[188px]">
          <Skeleton className="h-full w-full" />
        </div>
        <div className="flex w-full flex-col gap-3">
          <div data-cy="record-header-summary" className="flex flex-row gap-2">
            <Skeleton className="h-6 w-12" />
            <Skeleton className="h-6 w-12" />
            <Skeleton className="h-6 w-12" />
          </div>
          <div
            data-cy={"record-header-title"}
            className="mb-1 flex flex-col gap-2"
          >
            <p className="text-2xl font-semibold text-grey-900 lg:text-3xl">
              <Skeleton className="h-9 w-48" />
            </p>
            <p className="text-base font-normal text-grey-500 lg:text-lg">
              <Skeleton className="h-7 w-32" />
            </p>
          </div>
          <div className="flex gap-3 lg:order-first lg:self-end">
            <Skeleton className="h-10 w-48" />
          </div>
        </div>
      </div>
      {/* Accession table of contents*/}
      <div className="grid lg:gap-12 xl:grid-cols-[188px_auto]">
        <div className="sticky top-3 hidden self-start xl:block">
          <div className="flex w-[188px] flex-col gap-2 text-sm font-medium text-grey-500">
            <Skeleton className="h-9 w-48" />
            <Skeleton className="h-9 w-48" />
            <Skeleton className="h-9 w-48" />
            <Skeleton className="h-9 w-48" />
            <Skeleton className="h-9 w-48" />
            <Skeleton className="h-9 w-48" />
            <Skeleton className="h-9 w-48" />
          </div>
        </div>

        <div className="flex flex-col gap-8">
          {/* Accession Details section */}
          <div>
            <div className="mb-4 border-b border-grey-200 pb-2">
              <Skeleton className="h-5 w-48" />
            </div>
            <div className="flex flex-col gap-6 lg:gap-8">
              <RecordSectionContentGroup className="grid gap-4 lg:grid-cols-2">
                <RecordField>
                  <RecordLabelSkeleton className="h-5" />
                  <RecordValueSkeleton className="h-6" />
                </RecordField>
                <RecordField>
                  <RecordLabelSkeleton className="h-5" />
                  <RecordValueSkeleton className="h-6" />
                </RecordField>
                <RecordField>
                  <RecordLabelSkeleton className="h-5" />
                  <RecordValueSkeleton className="h-6" />
                </RecordField>
                <RecordField>
                  <RecordLabelSkeleton className="h-5" />
                  <RecordValueSkeleton className="h-6" />
                </RecordField>
              </RecordSectionContentGroup>
            </div>
          </div>
          {/* Tags section */}
          <div>
            <div className="mb-4 border-b border-grey-200 pb-2">
              <Skeleton className="h-5 w-48" />
            </div>
            <div className="flex flex-col gap-6 lg:gap-8">
              <Skeleton className="h-5 w-48" />
            </div>
          </div>
          {/* Notes section */}
          <div>
            <div className="mb-4 border-b border-grey-200 pb-2">
              <Skeleton className="h-5 w-48" />
            </div>
            <div className="flex flex-col gap-6 lg:gap-8">
              <Skeleton className="h-5 w-48" />
            </div>
          </div>

          {/* Accession Wild collection section */}
          <div>
            <div className="mb-4 border-b border-grey-200 pb-2">
              <Skeleton className="h-5 w-48" />
            </div>
            <div className="flex flex-col gap-6 lg:gap-8">
              <RecordSectionContentGroup className="grid gap-4 lg:grid-cols-2">
                <RecordField>
                  <RecordLabelSkeleton className="h-5" />
                  <RecordValueSkeleton className="h-6" />
                </RecordField>
                <RecordField>
                  <RecordLabelSkeleton className="h-5" />
                  <RecordValueSkeleton className="h-6" />
                </RecordField>
                <RecordField>
                  <RecordLabelSkeleton className="h-5" />
                  <RecordValueSkeleton className="h-6" />
                </RecordField>
                <RecordField>
                  <RecordLabelSkeleton className="h-5" />
                  <RecordValueSkeleton className="h-6" />
                </RecordField>
                <RecordField>
                  <RecordLabelSkeleton className="h-5" />
                  <RecordValueSkeleton className="h-6" />
                </RecordField>
                <RecordField>
                  <RecordLabelSkeleton className="h-5" />
                  <RecordValueSkeleton className="h-6" />
                </RecordField>
              </RecordSectionContentGroup>
            </div>
          </div>
          {/* Related Records */}
          <div>
            <div className="mb-4 border-b border-grey-200 pb-2">
              <Skeleton className="h-5 w-48" />
            </div>
            <RecordSectionContentGroup className="grid w-full grid-cols-1 md:grid-cols-2">
              <Skeleton className="h-28" />
              <Skeleton className="h-28" />
              <Skeleton className="h-28" />
              <Skeleton className="h-28" />
            </RecordSectionContentGroup>
          </div>
        </div>
      </div>
    </div>
  </PageContent>
)

function Component() {
  const {accessionNumber, siteSlug} = Route.useParams()
  const {subdomain} = Route.useRouteContext()
  const isDesktop = useMediaQuery("lg")
  const setTitle = useTopbarStore((state) => state.setTitle)
  const [editOpen, setEditOpen] = useState(false)
  const [editStage, setEditStage] = useState(AccessionEditStage.overview)

  const [{data, error, fetching}, refetch] = useGetAccessionQuery({
    variables: {
      accessionNumber: accessionNumber,
      collectionSiteSlug: siteSlug,
      organisationSubdomain: subdomain,
    },
  })

  const handleOpenEdit = useCallback((stage?: AccessionEditStage) => {
    setEditOpen(true)
    if (stage != null) {
      setEditStage(stage)
    }
  }, [])

  const handleAddNewMaterial = useCallback(() => {
    handleOpenEdit(AccessionEditStage.addPlantMaterial)
  }, [handleOpenEdit])

  const accession = data?.org?.site?.result

  const [{data: siteData}] = useGetCollectionSiteFromNamesQuery({
    variables: {
      organisationSubdomain: subdomain,
      collectionSiteSlug: siteSlug,
    },
  })
  const site = siteData?.org?.site

  if (fetching && accession == null) {
    return <PageSkeleton />
  }

  if (accession == null || error != null) {
    return (
      <div className="max-w-[412px] space-y-4 px-4 lg:px-6">
        <Button
          className="!rounded-lg"
          size="xs"
          startIcon={ArrowLeft}
          onClick={refetch}
        >
          Retry
        </Button>
        {error == null ? <NotFoundAlert /> : <ErrorAlert />}
      </div>
    )
  }

  const pageTitle = (
    <HierarchyPopover key={accession.id}>
      <HierarchyTrigger>{accession.accessionNumber}</HierarchyTrigger>
      <HierarchyContent>
        <HierarchyListItem
          root
          to="/taxonomy/$taxonId"
          params={{taxonId: accession.taxon?.id}}
        >
          <GitBranch1 className="h-4 w-4 text-grey-500" />
          <span className="text-sm font-medium text-grey-900">
            {accession.taxon?.scientificName ?? "-"}
          </span>
        </HierarchyListItem>
        <HierarchyListGroup indent>
          <HierarchyListItem
            selected={true}
            to="/sites/$siteSlug/accessions/$accessionNumber"
            params={{accessionNumber: accession.accessionNumber}}
          >
            <Folder className="h-4 w-4 text-grey-500" />
            <span className="text-sm font-medium text-grey-900">
              {accession.accessionNumber}
            </span>
          </HierarchyListItem>
          <HierarchyListGroup indent>
            {accession.plantMaterial?.map((plantMaterial) => {
              return (
                <HierarchyListItem
                  to="/sites/$siteSlug/materials/$materialNumber"
                  params={{
                    materialNumber: createMaterialNumber(
                      accession.accessionNumber,
                      plantMaterial.qualifier,
                    ),
                  }}
                  key={plantMaterial.id}
                >
                  <Tag1 className={twMerge("h-4 w-4 text-grey-500")} />
                  <span
                    className={twMerge("text-sm font-medium text-grey-900")}
                  >
                    {`Material ${plantMaterial.qualifier}`}
                  </span>
                </HierarchyListItem>
              )
            })}
          </HierarchyListGroup>
        </HierarchyListGroup>
      </HierarchyContent>
    </HierarchyPopover>
  )
  setTitle(pageTitle)

  return (
    <Fragment>
      {isDesktop && (
        <TopBar>
          <TopBarContent>
            <Breadcrumb>
              <BreadcrumbList>
                <BreadcrumbItem>{site?.name}</BreadcrumbItem>
                <BreadcrumbSeparator />
                <BreadcrumbItem>
                  <Link
                    to="/sites/$siteSlug/collection"
                    params={{
                      siteSlug,
                    }}
                    search={{
                      tab: "accessions",
                    }}
                  >
                    Accessions
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbSeparator />
                <BreadcrumbItem>{pageTitle}</BreadcrumbItem>
              </BreadcrumbList>
            </Breadcrumb>
          </TopBarContent>
        </TopBar>
      )}
      <PageContent
        responsivePadding
        className="overflow-y-auto overflow-x-hidden"
      >
        <Helmet>
          <title>{accessionNumber} | Hortis</title>
        </Helmet>
        <AccessionContents
          accession={accession}
          requestEdit={handleOpenEdit}
          refetch={refetch}
          addNewMaterial={handleAddNewMaterial}
        />
        <Sheet
          open={editOpen}
          onOpenChange={(open) => {
            setEditOpen(open)
          }}
        >
          <SheetContent data-cy="edit-accession-drawer">
            <EditAccessionSheetContent
              setStage={setEditStage}
              stage={editStage}
              open={editOpen}
              requestClose={() => {
                setEditOpen(false)
              }}
              accession={accession}
              refetch={refetch}
            />
          </SheetContent>
        </Sheet>
      </PageContent>
    </Fragment>
  )
}
