import {authExchange as coreAuthExchange} from "@urql/exchange-auth"
import {pipe} from "fp-ts/function"
import * as OE from "../utils/option-either"
import type {useAccessToken} from "../utils/use-access-token"

export const authExchange = (accessToken: ReturnType<typeof useAccessToken>) =>
  // eslint-disable-next-line @typescript-eslint/require-await
  coreAuthExchange(async (utils) => {
    const token = pipe(
      accessToken,
      OE.matchW(
        () => null,
        () => ({type: "ACCESS_TOKEN_UNAVAILABLE" as const}),
        (token) => ({type: "ACCESS_TOKEN_AVAILABLE" as const, token}),
      ),
    )
    return {
      didAuthError(error) {
        return error.graphQLErrors.some(
          (e) => e.extensions.code === "UNAUTHORIZED",
        )
      },
      // eslint-disable-next-line @typescript-eslint/require-await
      refreshAuth: async () => {
        return
      },
      addAuthToOperation(operation) {
        return token?.type === "ACCESS_TOKEN_AVAILABLE"
          ? utils.appendHeaders(operation, {
              Authorization: `Bearer ${token.token}`,
            })
          : operation
      },
    }
  })
