import type {MenuProps as CoreMenuProps} from "@mui/material/Menu"
import CoreMenu from "@mui/material/Menu"
import type {MenuItemProps} from "@mui/material/MenuItem"
import CoreMenuItem from "@mui/material/MenuItem"
import {useTheme} from "@mui/material/styles"
import type {ReactNode, RefObject} from "react"
import {forwardRef} from "react"
import {Link} from "@tanstack/react-router"
import {colors} from "src/colors"
import {shadows} from "src/shadows"
import {assignSxProps, fromSxFn} from "../../utils/sx"
import {Divider} from "../custom/divider"

export interface MenuOption {
  label: ReactNode
  value: string
  onClick?: () => void
  testId?: string
  href?: string
}

export interface MenuProps {
  open: boolean
  onClose: () => void
  options: ReadonlyArray<MenuOption | null | string>
  anchorEl: RefObject<HTMLElement>
  aboveMenu?: ReactNode
}

const handleItemClick = (func: () => void) => () => {
  func()
}

const menuStyle = fromSxFn(() => ({
  transform: `translateY(16px)`,
}))

const listItemStyle = fromSxFn(() => ({
  minHeight: 0,
  fontSize: "14px",
  "&:hover": {
    backgroundColor: colors.grey[50],
  },
}))

const MenuItem: (props: MenuItemProps) => JSX.Element = CoreMenuItem

const paperStyle = fromSxFn(() => ({
  borderRadius: "8px",
  boxShadow: shadows.lg,
  "& .MuiList-root.MuiMenu-list": {
    padding: 0,
  },
  border: `1px solid ${colors.grey[100]}`,
}))

const anchorOrigin = {
  vertical: "bottom",
  horizontal: "left",
} as const

const transformOrigin = {
  vertical: "top",
  horizontal: "left",
} as const

export const Menu = forwardRef<
  HTMLDivElement,
  Omit<CoreMenuProps, "ref" | "anchorEl"> & MenuProps
>(function FiltersMenu(
  {open, onClose, options, anchorEl, PaperProps, aboveMenu, ...props},
  ref,
) {
  const theme = useTheme()
  return (
    <CoreMenu
      open={open}
      onClose={onClose}
      anchorEl={anchorEl.current}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      sx={menuStyle}
      PaperProps={{
        ref,
        sx: assignSxProps(PaperProps?.sx, paperStyle),
      }}
      // this type is huge and really inefficient, so let's try and ignore it
      {...(props as Record<string, unknown>)}
    >
      {aboveMenu ?? null}
      {options.map(
        (option, idx): JSX.Element =>
          option == null ? (
            // eslint-disable-next-line react/no-array-index-key
            <Divider key={idx} />
          ) : typeof option === "string" ? (
            <div className="px-4 pb-1.5 pt-3 text-xs font-medium text-grey-500">
              {option}
            </div>
          ) : option.href === undefined ? (
            <MenuItem
              disableRipple
              key={option.value}
              onClick={option.onClick && handleItemClick(option.onClick)}
              data-cy={option.testId}
              sx={[listItemStyle, {padding: theme.spacing(1.25, 2)}]}
            >
              {option.label}
            </MenuItem>
          ) : (
            <Link
              to={option.href}
              key={option.value}
              onClick={option.onClick && handleItemClick(option.onClick)}
              data-cy={option.testId}
              className="flex w-full items-center px-4 py-2.5 text-left text-sm hover:bg-grey-50"
            >
              {option.label}
            </Link>
          ),
      )}
    </CoreMenu>
  )
})
