import {useMediaQuery} from "@hortis/ui/hooks/media-query"
import {Link} from "@tanstack/react-router"
import type {RowData, Table} from "@tanstack/table-core"
import {type MaterialsListFieldsFragment} from "generated/graphql"
import {forwardRef, type ForwardedRef, type ReactElement} from "react"
import {List} from "src/components"
import MaterialRecordCard from "src/features/records/components/new-record-card/variants/material-record-card"
import {getThumbnailPhotoUrl} from "src/utils/photos"
import {createMaterialNumber} from "./material-number"

export interface MaterialsMobileListProps<TData extends RowData> {
  selectMode: boolean
  table: Table<TData>
  emptyState?: ReactElement
}

export const MaterialsMobileList = forwardRef(function MaterialsMobileListInner<
  TData extends MaterialsListFieldsFragment,
>(
  {selectMode, table, emptyState}: MaterialsMobileListProps<TData>,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const isNotMobile = useMediaQuery("sm")
  return (
    <List.List
      items={table.getRowModel().rows}
      emptyState={emptyState}
      ref={ref}
    >
      {table.getRowModel().rows.map(({original: material, ...row}) => {
        const content = (
          <MaterialRecordCard
            materialNumber={`${material.accession?.accessionNumber ?? "-"}/${
              material.qualifier
            }`}
            scientificName={material.accession?.scientificName ?? "-"}
            photoUrl={getThumbnailPhotoUrl(material)}
            group={material.materialGroup}
            status={material.status}
            size={isNotMobile ? "md" : "sm"}
            variant="Row"
            location={material.collectionSiteLocation}
            selected={row.getIsSelected()}
          />
        )

        return selectMode ? (
          <button
            key={material.id}
            type="button"
            className="w-full text-left"
            onClick={() => {
              row.toggleSelected()
            }}
            data-cy="materials-list-row"
          >
            {content}
          </button>
        ) : (
          <Link
            key={material.id}
            from="/sites/$siteSlug"
            to="/sites/$siteSlug/materials/$materialNumber"
            params={{
              materialNumber: createMaterialNumber(
                material.accession?.accessionNumber ?? "",
                material.qualifier,
              ),
            }}
            data-cy="materials-list-row"
          >
            {content}
          </Link>
        )
      })}
    </List.List>
  )
})
