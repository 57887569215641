import {z} from "zod"

export const tabs = [
  {value: "all", label: "All"},
  {value: "accessions", label: "Accessions"},
  {value: "materials", label: "Plant materials"},
] as const
const tab = z.enum(["all", "accessions", "materials"]).catch("all")
export type TagTab = z.infer<typeof tab>

export const tagsSchema = z.object({
  tab,
})

export const orgTabs = [...tabs, {value: "taxa", label: "Taxa"}] as const
const orgTab = z.enum(["all", "accessions", "materials", "taxa"]).catch("all")
export type OrgTagTab = z.infer<typeof orgTab>

export const orgTagsSchema = z.object({
  tab: orgTab,
})
