import type {NullableStringComparator} from "generated/graphql"
import type {FilterOperation} from "../plant-materials/types"

export const extractStringComparatorData = (
  filter: NonNullable<NullableStringComparator>,
  operation: FilterOperation,
): Array<string> | undefined => {
  switch (operation) {
    case "contains": {
      return filter.contains == null ? [] : [filter.contains]
    }
    case "hasNoValue": {
      return ["has no value"]
    }
    default: {
      return undefined
    }
  }
}
