import {zodResolver} from "@hookform/resolvers/zod"
import * as A from "fp-ts/Array"
import type {PlantMaterialFilter} from "generated/graphql"
import {MaterialSex} from "generated/graphql"
import {useMemo} from "react"
import {FormProvider} from "react-hook-form"
import {ChipGridStylingPreset, FormChipGrid} from "src/components/chip-grid"
import {
  CollapsableRadioButton,
  RadioButton,
  RadioGroup,
} from "src/components/radio"
import {FilterTitle} from "src/features/filters/components/modal/filter-title"
import {match} from "ts-pattern"
import {z} from "zod"
import {materialSexOptions} from "../../../create-accession/components/add-plant-material-form/plant-material-data-fields"
import type {FilterModalProps} from "../../components/modal/filter-modal"
import {FilterModal} from "../../components/modal/filter-modal"
import {FilterModalButtons} from "../../components/modal/modal-buttons"
import {FilterModalError} from "../../components/modal/modal-error"
import {useFilterForm} from "../../components/modal/use-filter-form"
import {determineInitialOperation} from "../modals/determine-initial-operation"
import type {FilterFormProps} from "../modals/types"
import {useDispatchFilter} from "../modals/use-dispatch-filter"

const createDefaultValues = (
  initialValue?: NonNullable<PlantMaterialFilter["sex"]>,
) => {
  const defaultValues = {
    is:
      initialValue?.or == null
        ? []
        : initialValue.or.flatMap(({eq}) => eq).filter((val) => val != null),
    isNot:
      initialValue?.or == null
        ? []
        : initialValue.or.flatMap(({neq}) => neq).filter((val) => val != null),
    hasNoValue: initialValue?.null?.eq === true,
  }
  return {
    defaultValues,
    initialOperation: determineInitialOperation(defaultValues, "is"),
  }
}

const plantMaterialSex = z.nativeEnum(MaterialSex)
const resolver = zodResolver(
  z.object({
    is: z.array(plantMaterialSex),
    isNot: z.array(plantMaterialSex),
    hasNoValue: z.boolean(),
  }),
)

export const SexFilterForm = ({
  initialValue,
  onClose,
  dispatchFilters,
}: FilterFormProps) => {
  const {error, setError, operation, handleRadioChange, formMethods} =
    useFilterForm({
      resolver,
      ...createDefaultValues(initialValue?.sex ?? undefined),
    })
  const {addModifyFilter, deleteFilter} = useDispatchFilter({
    dispatchFilters,
    id: initialValue?.id,
    onClose,
  })

  const onSubmit = useMemo(
    () =>
      formMethods.handleSubmit((values) => {
        match(operation)
          .with("is", () => {
            if (A.isNonEmpty(values.is)) {
              addModifyFilter({
                sex: {
                  or: values.is.map((val) => ({
                    eq: val,
                  })),
                },
              })
              onClose()
            } else {
              setError("Select a sex to continue")
            }
          })
          .with("isNot", () => {
            if (A.isNonEmpty(values.isNot)) {
              addModifyFilter({
                sex: {
                  or: values.isNot.map((val) => ({
                    neq: val,
                  })),
                },
              })
              onClose()
            } else {
              setError("Select a sex to continue")
            }
          })
          .with("hasNoValue", () => {
            addModifyFilter({
              sex: {
                null: {eq: true},
              },
            })
            onClose()
          })
          .run()
      }),
    [formMethods, operation, onClose, addModifyFilter, setError],
  )

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={onSubmit}>
        <div className="mb-4 flex flex-col gap-4">
          <FilterTitle title="Sex" deleteFilter={deleteFilter} />
          <FilterModalError error={error} />
          <RadioGroup
            value={operation}
            onChange={handleRadioChange}
            label="Operation"
          >
            {(props) => (
              <>
                <CollapsableRadioButton
                  selectedValue="is"
                  testId="sex-is-radio"
                  label="Is"
                  {...props}
                >
                  <FormChipGrid
                    name="is"
                    stylingPreset={ChipGridStylingPreset.FLEX_WRAP}
                    testId="sex-is-chip-grid"
                    chips={materialSexOptions}
                    multiple
                    sx={{
                      gridTemplateColumns: `repeat(auto-fit, minmax(125px, 1fr))`,
                    }}
                  />
                </CollapsableRadioButton>
                <CollapsableRadioButton
                  selectedValue="isNot"
                  testId="sex-is-not-radio"
                  label="Is not"
                  {...props}
                >
                  <FormChipGrid
                    name="isNot"
                    stylingPreset={ChipGridStylingPreset.FLEX_WRAP}
                    testId="sex-is-not-chip-grid"
                    chips={materialSexOptions}
                    multiple
                    sx={{
                      gridTemplateColumns: `repeat(auto-fit, minmax(125px, 1fr))`,
                    }}
                  />
                </CollapsableRadioButton>
                <RadioButton
                  label="Has no value"
                  selectedValue="hasNoValue"
                  testId="sex-has-no-value-radio"
                  {...props}
                />
              </>
            )}
          </RadioGroup>
        </div>
        <FilterModalButtons onCancel={onClose} testIdPrefix="sex" />
      </form>
    </FormProvider>
  )
}

// Seperated from form component to make sure popover unmount causes state reset
export const SexFilterModal = ({
  open,
  onClose,
  anchorEl,
  ...props
}: FilterFormProps & FilterModalProps) => (
  <FilterModal anchorEl={anchorEl} open={open} onClose={onClose}>
    <SexFilterForm onClose={onClose} {...props} />
  </FilterModal>
)
