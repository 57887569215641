import Bugsnag from "@bugsnag/js"
import {Button} from "@hortis/ui/button"
import {AlertCircle} from "@hortis/ui/icons"
import {
  EmptyState,
  EmptyStateActions,
  EmptyStateBody,
  EmptyStateHeader,
  EmptyStateTitle,
} from "src/components/empty-state"
import {FeaturedIcon} from "src/components/featured-icon/featured-icon"
import type {PlantMaterialsErrors} from "./plant-materials/utils"

export const showErrorMessage = (error: PlantMaterialsErrors) => {
  if (error instanceof Error) {
    Bugsnag.notify(error)
  }
  return (
    <div className="mx-auto my-12 max-w-md justify-self-center p-6 md:my-24">
      <EmptyState>
        <EmptyStateHeader>
          <FeaturedIcon color="warning">
            <AlertCircle className="text-warning-600" />
          </FeaturedIcon>
          <EmptyStateTitle>Something went wrong...</EmptyStateTitle>
          <EmptyStateBody>
            We had some trouble loading this page. Please refresh the page to
            try again or get in touch if the problem sticks around!
          </EmptyStateBody>
        </EmptyStateHeader>
        <EmptyStateActions>
          <Button asChild>
            <a
              rel="noreferrer"
              href="https://support.hortis.com/"
              target="_blank"
            >
              Contact support
            </a>
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              location.reload()
            }}
          >
            Refresh page
          </Button>
        </EmptyStateActions>
      </EmptyState>
    </div>
  )
}
