import {
  ContextMenuGroup,
  ContextMenuItem,
  ContextMenuSub,
  ContextMenuSubContent,
  ContextMenuSubTrigger,
} from "@hortis/ui/context-menu"

import {Copy1} from "@hortis/ui/icons/copy-1"
import {
  type MapMaterialListFieldsFragment,
  type MaterialsListFieldsFragment,
} from "generated/graphql"
import {useSnackbarStore} from "src/components/snackbar-controller/snackbar-store"
import {createMaterialNumber} from "src/features/collection/components/plant-materials/material-number"
import {onFailure, onSuccess} from "src/notification-snack-utils"
import {copyToClipboard} from "src/utils/dom/copy-to-clipboard"

type MaterialListFragmentWithAccession = Omit<
  MaterialsListFieldsFragment,
  "accession"
> & {
  accession: NonNullable<MaterialsListFieldsFragment["accession"]>
}

function joinMaterialProperties<T>(
  materials: ReadonlyArray<MaterialListFragmentWithAccession>,
  propertyExtractor: (
    material: MaterialListFragmentWithAccession,
  ) => T | null | undefined,
): string {
  return materials
    .map(propertyExtractor)
    .filter((property): property is T => property != null)
    .join("\n")
}

export const MaterialCopyContextMenuSub = ({
  materials,
}: {
  materials: ReadonlyArray<
    MaterialsListFieldsFragment | MapMaterialListFieldsFragment
  >
}) => {
  const {setSnack} = useSnackbarStore()

  const filteredMaterials = materials.filter(
    (material): material is MaterialListFragmentWithAccession =>
      material.accession != null,
  )

  const materialNumbers = filteredMaterials
    .map((material) =>
      createMaterialNumber(
        material.accession.accessionNumber,
        material.qualifier,
        "/",
      ),
    )
    .join("\n")

  const accessionNumbers = joinMaterialProperties(
    filteredMaterials,
    (material) => material.accession.accessionNumber,
  )

  const scientificNames = joinMaterialProperties(
    filteredMaterials,
    (material) => material.accession.taxon?.scientificName,
  )

  const commonNames = joinMaterialProperties(
    filteredMaterials,
    (material) => material.accession.taxon?.commonName,
  )

  return (
    <ContextMenuSub>
      <ContextMenuSubTrigger data-cy="copy-menu-item">
        <Copy1 className="h-4 w-4 text-grey-500" />
        Copy
      </ContextMenuSubTrigger>
      <ContextMenuSubContent alignOffset={-46}>
        <ContextMenuGroup>
          <ContextMenuItem
            onSelect={() => {
              void copyToClipboard(
                materialNumbers,
                () => {
                  onSuccess(setSnack)(
                    materials.length > 1
                      ? `${materials.length} material numbers copied to clipboard`
                      : `"${materialNumbers}" copied to clipboard`,
                  )
                },
                () => {
                  //eslint-disable-next-line sonarjs/no-duplicate-string
                  onFailure(setSnack)(new Error("Failed to copy"))
                },
              )
            }}
          >
            <Copy1 className="h-4 w-4 text-grey-500" />
            {`Copy material number${materials.length > 1 ? "s" : ""}`}
          </ContextMenuItem>
          <ContextMenuItem
            onSelect={() => {
              void copyToClipboard(
                accessionNumbers,
                () => {
                  onSuccess(setSnack)(
                    materials.length > 1
                      ? `${materials.length} accession numbers copied to clipboard`
                      : `"${accessionNumbers}" copied to clipboard`,
                  )
                },
                () => {
                  onFailure(setSnack)(new Error("Failed to copy"))
                },
              )
            }}
          >
            <Copy1 className="h-4 w-4 text-grey-500" />
            {`Copy accession number${materials.length > 1 ? "s" : ""}`}
          </ContextMenuItem>
          <ContextMenuItem
            onSelect={() => {
              void copyToClipboard(
                scientificNames,
                () => {
                  onSuccess(setSnack)(
                    materials.length > 1
                      ? `${materials.length} scientific names copied to clipboard`
                      : `"${scientificNames}" copied to clipboard`,
                  )
                },
                () => {
                  onFailure(setSnack)(new Error("Failed to copy"))
                },
              )
            }}
          >
            <Copy1 className="h-4 w-4 text-grey-500" />
            {`Copy scientific name${materials.length > 1 ? "s" : ""}`}
          </ContextMenuItem>

          <ContextMenuItem
            onSelect={() => {
              void copyToClipboard(
                commonNames,
                () => {
                  onSuccess(setSnack)(
                    materials.length > 1
                      ? `${materials.length} common names copied to clipboard`
                      : `"${commonNames}" copied to clipboard`,
                  )
                },
                () => {
                  onFailure(setSnack)(new Error("Failed to copy"))
                },
              )
            }}
          >
            <Copy1 className="h-4 w-4 text-grey-500" />
            {`Copy common name${materials.length > 1 ? "s" : ""}`}
          </ContextMenuItem>
        </ContextMenuGroup>
      </ContextMenuSubContent>
    </ContextMenuSub>
  )
}
