import {memo} from "react"
import {colors} from "src/colors"
import {SlNoResultsIcon} from "./icons/streamline/no-results"
import {Typography} from "./typography"

interface Props {
  list?: Array<unknown> | ReadonlyArray<unknown> | null
  title?: string
  message?: string
}

const DisplayEmptyList = memo(function MemoisedDisplayEmptyList({
  title,
  message,
}: Omit<Props, "list">) {
  return (
    <div className="m-auto flex-1 items-center justify-center py-4 sm:px-6">
      <div className="mt-[-48px] flex flex-col items-center gap-2 sm:mt-[56px] md:mt-[72px]">
        <div className="flex flex-col items-center gap-3">
          <SlNoResultsIcon
            width="64px"
            height="64px"
            color={colors.primary[500]}
          />
          <Typography testId="error-message" variant="subtitle1">
            {title}
          </Typography>
        </div>
        <Typography variant="body2">{message}</Typography>
      </div>
    </div>
  )
})

export const EmptyList = memo(function MemoisedEmptyList({
  list,
  title = "No results found",
  message = "Please try another query or adjust your filters",
}: Props) {
  return list != null && list.length === 0 ? (
    <DisplayEmptyList title={title} message={message} />
  ) : null
})
