import {CheckCircle} from "@hortis/ui/icons"
import {PhotoPlaceholder} from "@hortis/ui/photo-placeholder"
import {TwicImg} from "@twicpics/components/react"
import {cva} from "class-variance-authority"
import {type HTMLAttributes, createContext, useContext, useMemo} from "react"
import {twMerge} from "tailwind-merge"

type RecordCardVariant = "Stacked" | "Row"
type RecordCardSize = "sm" | "md"

export interface RecordCardProps {
  className?: string
  variant: RecordCardVariant
  size: RecordCardSize
  selected?: boolean
  children: React.ReactNode
  testId?: string
}

const RecordCardContext = createContext<
  Pick<RecordCardProps, "selected" | "size" | "variant">
>({
  variant: "Row",
  size: "md",
})

const RecordCard = ({
  variant,
  size,
  className,
  selected = false,
  children,
  testId,
}: RecordCardProps) => {
  const contextValue = useMemo(
    () => ({variant, size, selected}),
    [variant, size, selected],
  )
  return (
    <RecordCardContext.Provider value={contextValue}>
      <div
        data-cy={testId}
        className={twMerge(
          cva(
            [
              "flex rounded-xl bg-white p-3 text-grey-700 transition-colors",
              selected ? "bg-primary-50 filter-none" : "hover:bg-grey-50",
            ],
            {
              variants: {
                variant: {
                  Row: "flex-row",
                  Stacked: "flex-col items-start gap-3",
                },
                size: {
                  sm: "items-start gap-3",
                  md: "gap-4",
                },
              },
            },
          )({size, variant}),
          className,
        )}
      >
        {children}
      </div>
    </RecordCardContext.Provider>
  )
}

interface RecordCardPhotoProps {
  photo: string | null | undefined
}

const RecordCardPhoto: React.FC<RecordCardPhotoProps> = ({photo}) => {
  const {size, variant, selected} = useContext(RecordCardContext)

  const photoVariants = cva(
    ["rounded-lg", selected === true && "bg-primary-400/25"],
    {
      variants: {
        variant: {
          Stacked: undefined,
          Row: undefined,
        },
        size: {
          sm: "h-12 w-12",
          md: "h-20 w-20",
        },
      },
      compoundVariants: [
        {
          size: "sm",
          variant: "Stacked",
          className: "h-[88px] w-full",
        },
        {
          size: "md",
          variant: "Stacked",
          className: "h-[166px] w-full",
        },
      ],
    },
  )

  return (
    <div
      className={twMerge(
        cva(undefined, {
          variants: {
            variant: {
              Row: "w-fit",
              Stacked: "w-full",
            },
          },
        })({variant}),
      )}
    >
      {selected === true ? (
        <div className={twMerge(photoVariants({size, variant}))}>
          <div
            className={twMerge(
              "flex items-center justify-center rounded-[99px]",
              "h-full w-full",
            )}
          >
            <CheckCircle
              className={twMerge(
                "text-primary-400",
                size === "md" && "h-6 w-6",
              )}
            />
          </div>
        </div>
      ) : photo == null ? (
        <PhotoPlaceholder
          size={size}
          className={twMerge(photoVariants({size, variant}))}
        />
      ) : (
        <div
          className={twMerge(photoVariants({size, variant}), "overflow-hidden")}
        >
          <TwicImg src={photo} eager />
        </div>
      )}
    </div>
  )
}

const RecordCardContent = ({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={twMerge("flex w-full flex-col pt-1", className)} {...props}>
      {children}
    </div>
  )
}

const RecordCardTitle = ({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLParagraphElement>) => {
  return (
    <p
      className={twMerge(
        "mt-0.5 flex items-center gap-2 text-base font-medium",
        className,
      )}
      {...props}
    >
      {children}
    </p>
  )
}
const RecordCardOverline = ({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLParagraphElement>) => {
  return (
    <p
      className={twMerge("text-sm font-medium text-grey-500", className)}
      {...props}
    >
      {children}
    </p>
  )
}

const RecordCardDetails = ({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      className={twMerge(
        "flex flex-wrap items-center gap-x-2 gap-y-0.5 pt-1",
        className,
      )}
      {...props}
    >
      {children}
    </div>
  )
}

export {
  RecordCard,
  RecordCardContent,
  RecordCardDetails,
  RecordCardOverline,
  RecordCardPhoto,
  RecordCardTitle,
}
