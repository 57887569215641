import {flow, pipe} from "fp-ts/function"
import * as R from "fp-ts/Reader"
import {createContext, useCallback, useContext, useMemo, useState} from "react"
import {useLayoutEffect} from "./isomorphic-layout-effect"

export const LanguageContext = createContext<Array<string> | undefined>(
  undefined,
)

const useNavigatorLanguages = () => {
  const [languages, setLanguages] = useState<ReadonlyArray<string>>()
  useLayoutEffect(() => {
    const onChange = () => {
      setLanguages(window.navigator.languages)
    }
    window.addEventListener("languagechange", onChange)

    onChange()

    return () => {
      window.removeEventListener("languagechange", onChange)
    }
  }, [])

  return languages
}

export const _useLanguages = pipe(
  R.ask<{useLanguages: () => ReadonlyArray<string> | undefined}>(),
  R.map(({useLanguages}) => (): ReadonlyArray<string> | undefined => {
    const languages = useLanguages()
    const languageContext = useContext(LanguageContext)

    return languages === undefined ? undefined : languageContext ?? languages
  }),
)

/**
 * TODO: prefix this array with the preferred language from the API!
 * @returns The BCP 47 preferred languages list
 * @see {@link https://en.wikipedia.org/wiki/IETF_language_tag}
 */
export const useLanguages = _useLanguages({useLanguages: useNavigatorLanguages})

// eslint-disable-next-line no-secrets/no-secrets
/**
 * @param options - specify `{ timeZone: "UTC" }` to avoid automatic translation to the system timezone
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat}
 */
export type UseDateTimeFormat = (
  options?: Intl.DateTimeFormatOptions,
) => Intl.DateTimeFormat | undefined

export const _useDateTimeFormat = flow(
  _useLanguages,
  (useLanguages): UseDateTimeFormat =>
    (options) => {
      const languages = useLanguages()

      return useMemo(
        () =>
          languages === undefined
            ? undefined
            : new Intl.DateTimeFormat(languages as Array<string>, options),
        [languages, options],
      )
    },
)

export const useDateTimeFormat = _useDateTimeFormat({
  useLanguages: useNavigatorLanguages,
})

export const _useFormattedDate = flow(
  _useDateTimeFormat,
  (useDateTimeFormat) =>
    (date: Date | undefined, options?: Intl.DateTimeFormatOptions) => {
      const formatter = useDateTimeFormat(options)

      return formatter !== undefined && date !== undefined
        ? formatter.format(date)
        : undefined
    },
)
export const useFormattedDate = _useFormattedDate({
  useLanguages: useNavigatorLanguages,
})

export const _useFormattedDateString = flow(
  _useDateTimeFormat,
  (useDateTimeFormat) =>
    /**
     * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/parse#date_time_string_format
     * @param dateString - a string representation of a UTC Date
     */
    (dateString: string | undefined, options?: Intl.DateTimeFormatOptions) => {
      const formatter = useDateTimeFormat(options)

      return formatter !== undefined && dateString !== undefined
        ? formatter.format(Date.parse(dateString))
        : undefined
    },
)
export const useFormattedDateString = _useFormattedDateString({
  useLanguages: useNavigatorLanguages,
})

/**
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/parse#date_time_string_format
 * @param dateString - a string representation of a UTC Date
 */
export type DateStringFormatter = (
  dateString: string | undefined,
) => string | undefined

export const _useDateStringFormatter = flow(
  _useDateTimeFormat,
  (useDateTimeFormat) =>
    (options?: Intl.DateTimeFormatOptions): DateStringFormatter => {
      const formatter = useDateTimeFormat({timeZone: "UTC", ...options})

      return useCallback(
        function formatDateString(dateString: string | undefined) {
          return formatter !== undefined && dateString !== undefined
            ? formatter.format(Date.parse(dateString))
            : undefined
        },
        [formatter],
      )
    },
)
export const useDateStringFormatter = _useDateStringFormatter({
  useLanguages: useNavigatorLanguages,
})
