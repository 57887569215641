import {IconButton} from "@hortis/ui/button"
import {Divider} from "src/components/custom/divider"
import {CheckSquare, MinusSquare, Square} from "@hortis/ui/icons"
import {twMerge} from "tailwind-merge"

interface TableFooterSelectionMenuProps {
  selectedRowsCount: number
  hasNoSelections: boolean
  totalRowsCount: number
  menuButtons: JSX.Element
  testId?: string
  handleSelectAll: () => void
  className?: string
}

const TableFooterSelectionMenu = ({
  hasNoSelections,
  selectedRowsCount,
  totalRowsCount,
  menuButtons,
  testId,
  handleSelectAll,
  className,
}: TableFooterSelectionMenuProps) => {
  return (
    <div
      className={twMerge(
        "flex h-14 items-center justify-between bg-white px-4 text-sm font-medium text-grey-700 sm:px-6",
        className,
      )}
      data-cy={testId}
    >
      <p>
        {hasNoSelections ? "Select Records" : `${selectedRowsCount} selected`}
      </p>
      <div className="flex flex-row items-center">
        <IconButton
          aria-label="Forward"
          onClick={handleSelectAll}
          className="p-2.5"
          size="sm"
          variant="tertiaryGray"
          ariaLabel={"toggle select all"}
          icon={
            selectedRowsCount === totalRowsCount ? (
              CheckSquare
            ) : selectedRowsCount > 0 && selectedRowsCount < totalRowsCount ? (
              MinusSquare
            ) : selectedRowsCount === 0 ? (
              Square
            ) : (
              <></>
            )
          }
        />
        <Divider className="mx-2 h-6" direction="vertical" />
        {menuButtons}
      </div>
    </div>
  )
}

export default TableFooterSelectionMenu
