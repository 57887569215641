import {useEffect} from "react"

export const useResize = (
  handler: (this: Window, ev: UIEvent | Event) => void,
) => {
  useEffect(() => {
    window.addEventListener("resize", handler)

    return () => {
      window.removeEventListener("resize", handler)
    }
  }, [handler])
}
