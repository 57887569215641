import {TaxaColumn} from "generated/graphql"
import {type TaxaColumnIds} from "src/components/data-table/columns/taxa-columns"
import type {TaxaFilterType} from "src/features/filters/taxa/types"

export const defaultColumnVisibility: Record<TaxaColumnIds, boolean> = {
  "Scientific name": true,
  "Common name": true,
  Authorship: true,
  Validation: true,
  Family: true,
  "IUCN Red List": true,
  "Native distribution": true,
  "Life forms": true,
  Climate: true,
  "Taxon tags": true,
  Notes: true,
  "Last edited": false,
  Creator: false,
  "Last editor": false,
}

export const sortColumnMap: Partial<Record<TaxaColumn, TaxaColumnIds>> = {
  [TaxaColumn.ScientificName]: "Scientific name",
  [TaxaColumn.CommonName]: "Common name",
  [TaxaColumn.Authorship]: "Authorship",
  [TaxaColumn.LastUpdated]: "Last edited",
  [TaxaColumn.FamilyName]: "Family",
  [TaxaColumn.GlobalRedListStatus]: "IUCN Red List",
}

export const filterColumnMap: Partial<Record<TaxaColumnIds, TaxaFilterType>> = {
  Notes: "notes",
  Family: "family",
  Authorship: "authorship",
  "Common name": "commonName",
  "Taxon tags": "tags",
  Validation: "validation",
  "IUCN Red List": "redListStatus",
  "Native distribution": "nativeDistribution",
  "Life forms": "lifeForms",
  Climate: "climate",
  "Last editor": "lastEditor",
  Creator: "creator",
}
