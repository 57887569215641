import {Avatar} from "@hortis/ui/avatar"
import CoreTypography from "@mui/material/Typography"
import {useTheme} from "@mui/material/styles"
import type {Formatter} from "react-timeago"
import TimeAgo from "react-timeago"
import {colors} from "src/colors"
import {useDateStringFormatter} from "src/utils/hooks/language"
import {Tooltip} from "../../../../components/tooltip"

const timeAgoFormatter: Formatter = (
  value,
  unit,
  suffix,
  epochMilliseconds,
  nextFormatter,
) =>
  unit === "second"
    ? "just now"
    : nextFormatter == null
    ? undefined
    : nextFormatter(value, unit, suffix, epochMilliseconds)

export interface ActivityItemProps {
  avatarUrl?: string
  initials?: string
  message: JSX.Element
  icon?: JSX.Element
  timestamp: string
  isLastItem?: boolean
}

export const ActivityItem = ({
  avatarUrl,
  initials,
  message,
  timestamp,
  icon,
  isLastItem,
}: ActivityItemProps) => {
  const theme = useTheme()

  const formatDateTime = useDateStringFormatter({
    dateStyle: "full",
    timeStyle: "long",
    timeZone: undefined,
  })

  return (
    <div className="flex items-stretch gap-2" data-cy="activity-row">
      <div className="flex flex-col">
        {icon == null ? (
          <Avatar src={avatarUrl} textContent={initials} size="xs" />
        ) : (
          icon
        )}
        {isLastItem != null && isLastItem ? undefined : (
          <div
            style={{
              position: "relative",
              transform: `translateX(-50%)`,
              margin: 4,
              minHeight: 8,
              flex: 1,
              borderRight: `1px solid ${colors.grey[200]}`,
            }}
          />
        )}
      </div>
      <CoreTypography<"span">
        component="span"
        variant="body2"
        color="text.secondary"
        pb={1}
        pt={0.25}
      >
        {message}
        <CoreTypography<"span">
          component="span"
          fontSize="0.625rem"
          color="grey.300"
        >
          <span style={{marginLeft: theme.spacing(1)}} />•
          <span style={{marginLeft: theme.spacing(1)}} />
        </CoreTypography>
        <Tooltip title={formatDateTime(timestamp) ?? false} placement="top">
          <span>
            <TimeAgo
              date={timestamp}
              formatter={timeAgoFormatter}
              style={{display: "inline-block"}}
            />
          </span>
        </Tooltip>
      </CoreTypography>
    </div>
  )
}
