import {zodResolver} from "@hookform/resolvers/zod"
import {z} from "zod"

export const dateFilterSchema = z
  .object({
    from: z.date().nullish(),
    to: z.date().nullish(),
  })
  .transform((val, ctx) => {
    if (val.from == null && val.to == null) {
      ctx.addIssue({
        path: ["from"],
        code: z.ZodIssueCode.custom,
        message: "Please select a valid date",
      })
    }
    return val
  })

export const dateFilterResolver = zodResolver(dateFilterSchema)

export type LocalDateFilter = z.TypeOf<typeof dateFilterSchema>
