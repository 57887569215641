import {z} from "zod"
import {MaterialQualifierFormat} from "generated/graphql"
import {
  checkFloatMinMax,
  transformStringToFloat,
} from "src/features/create-accession/components/add-plant-material-form/schema"

const VALID_NUMBER_ERROR_MESSAGE = "Please enter a valid number"

export const SiteCenterPoint = z.object({
  latitude: z
    .string({invalid_type_error: VALID_NUMBER_ERROR_MESSAGE})
    .transform(transformStringToFloat)
    .transform(checkFloatMinMax({min: -90, max: 90})),
  longitude: z
    .string({invalid_type_error: VALID_NUMBER_ERROR_MESSAGE})
    .transform(transformStringToFloat)
    .transform(checkFloatMinMax({min: -180, max: 180})),
  zoom: z
    .string({invalid_type_error: VALID_NUMBER_ERROR_MESSAGE})
    .transform(transformStringToFloat)
    .transform(checkFloatMinMax({min: 0, max: 22})),
})

export type SiteCenterPoint = z.infer<typeof SiteCenterPoint>

export const CollectionSiteSchema = z.object({
  name: z.string().nonempty(),
  urlSlug: z
    .string()
    .regex(/^[a-z-]+$/i)
    .nonempty(),
  centerPoint: SiteCenterPoint,
  materialQualifierFormat: z.nativeEnum(MaterialQualifierFormat),
})
export type CollectionSiteSchema = z.infer<typeof CollectionSiteSchema>
export type CollectionSiteSchemaIn = z.input<typeof CollectionSiteSchema>
