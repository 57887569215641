import {useEffect, useReducer} from "react"
import deepEqual from "fast-deep-equal/es6"
import {usePrevious} from "./previous"

export function usePersistedReducer<State, Action>(
  reducer: (state: State, action: Action) => State,
  initialState: State,
  storageKey?: string,
) {
  function init(): State {
    if (storageKey == null || typeof localStorage === "undefined") {
      return initialState
    }

    const stringState = localStorage.getItem(storageKey)
    if (stringState == null) {
      return initialState
    }

    try {
      // TODO: Add decoder
      return JSON.parse(stringState) as State
    } catch {
      return initialState
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState, init)
  const prevState = usePrevious(state)

  useEffect(() => {
    const stateEqual = deepEqual(prevState, state)
    if (!stateEqual && storageKey != null) {
      const stringifiedState = JSON.stringify(state)
      localStorage.setItem(storageKey, stringifiedState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  return [state, dispatch] as const
}
