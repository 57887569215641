import {Sheet, SheetContent} from "@hortis/ui/sheet"
import type {PropsWithChildren, ReactElement} from "react"
import type {MaterialFieldsFragment} from "../../../../../../generated/graphql"
import {EditMaterialSheetForm} from "./edit-details"
import {MaterialEditStage} from "./types"

export interface MaterialEditDrawerProps {
  stage: MaterialEditStage
  material: MaterialFieldsFragment
  open: boolean
  onOpenChange: (open: boolean) => void
  refetch: () => void
  title?: string
  subtitle?: string
}

export const MaterialEditDrawer = ({
  stage,
  material,
  open,
  onOpenChange,
  refetch,
  title,
  subtitle,
}: PropsWithChildren<MaterialEditDrawerProps>): ReactElement => {
  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      {stage === MaterialEditStage.editDetails && (
        <SheetContent>
          <EditMaterialSheetForm
            title={title}
            subtitle={subtitle}
            material={material}
            requestClose={() => {
              onOpenChange(false)
            }}
            onSubmit={refetch}
            open={open}
          />
        </SheetContent>
      )}
    </Sheet>
  )
}
