import {Fragment} from "react"
import type {NullableStringComparator} from "generated/graphql"
import type {OpenFilterArgs} from "../../plant-materials/filters"
import {extractStringComparatorData} from "../extract-filter-data-as-strings"
import {FilterChip} from "../filter-chip"

export const StringComparatorChipGroup = <T extends string>({
  id,
  filter,
  hidden,
  ...props
}: {
  id: string
  handleOpenFilter: (args: OpenFilterArgs<T>) => void
  handleDelete: (id: string) => void
  filter: NullableStringComparator | undefined | null
  filterType: T
  filterLabel: string
  hidden?: boolean
}) =>
  filter == null || hidden === true ? (
    <Fragment />
  ) : (
    <>
      {filter.contains != null && (
        <FilterChip
          filterId={id}
          filterData={extractStringComparatorData(filter, "contains")}
          operation="contains"
          {...props}
        />
      )}
      {filter.null === true && (
        <FilterChip filterId={id} operation="has no value" {...props} />
      )}
    </>
  )
