import type {FilterOperation, LocalMaterialFilter} from "../types"

export const extractLocationFilterData = (
  filter: NonNullable<LocalMaterialFilter["location"]>,
  operation: FilterOperation,
): Array<string> | undefined => {
  switch (operation) {
    case "is": {
      return filter.is?.map(({name}) => name)
    }
    case "isNot": {
      return filter.isNot?.map(({name}) => name)
    }
    case "contains": {
      return filter.contains
    }
    default: {
      return undefined
    }
  }
}
