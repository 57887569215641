import type {
  MapMaterialListFieldsFragment,
  MaterialsListFieldsFragment,
} from "generated/graphql"
import {
  Command,
  CommandDialog,
  CommandHeader,
  CommandInput,
  CommandList,
} from "@hortis/ui/command"
import {CommandMenuStatusPage} from "src/components/command-menu/command-menu-pages/status"

interface StatusDialogProps {
  selectedRows: ReadonlyArray<
    MaterialsListFieldsFragment | MapMaterialListFieldsFragment
  >
  open: boolean
  onOpenChange: (open: boolean) => void
  title: string
  handleSuccess: () => void
}

const StatusDialog = ({
  title,
  selectedRows,
  open,
  onOpenChange,
  handleSuccess,
}: StatusDialogProps) => {
  return (
    <CommandDialog open={open} onOpenChange={onOpenChange}>
      <Command data-cy="context-menu-status-dialog" size="lg">
        <CommandHeader title={title} />
        <CommandInput
          placeholder="Change status..."
          data-cy="context-menu-search-filter"
        />
        <CommandList>
          <CommandMenuStatusPage
            onSuccess={handleSuccess}
            selectedMaterials={selectedRows}
          />
        </CommandList>
      </Command>
    </CommandDialog>
  )
}

export {StatusDialog}
