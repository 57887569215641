import {Auth0Provider} from "@auth0/auth0-react"
import {ThemeProvider} from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import {installTwicPics} from "@twicpics/components/react"
// eslint-disable-next-line import/no-unassigned-import
import "./styles/global.css"
// eslint-disable-next-line import/no-unassigned-import
import "@twicpics/components/style.css"
import * as E from "fp-ts/Either"
import {HelmetProvider} from "react-helmet-async"
import {PersistUserDomain} from "src/features/organisation/persist-user-domain"
import GlobalStyles from "src/styles/global-styles"
import {RouterProvider} from "@tanstack/react-router"
import {TooltipProvider} from "@hortis/ui/tooltip"
import {InlineNoScriptWithConfig} from "./components/inline-js"
import {NewVersionPrompt} from "./components/new-version-prompt"
import config from "./config"
import {EnvironmentProvider} from "./environment"
import {UrqlProvider} from "./gql/urql-provider"
import {useBugsnagErrorBoundary} from "./helpers/use-bugsnag-error-boundary"
import {router} from "./router"
import theme from "./theme"
import type {OrgNameStrategy} from "./utils/hooks/organisation-subdomain-name"
import {OrganisationSubdomainContext} from "./utils/hooks/organisation-subdomain-name"

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router
  }
}

const gtmInlineNoScript = (gtmApiKey: string) =>
  `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmApiKey}" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>`

const cssBaseline = <CssBaseline />

const auth0CacheLocation = "localstorage"

const orgNameFromHostname: OrgNameStrategy = E.left("hostname")

installTwicPics({
  domain: "https://ipp4fe79.twic.pics",
})

export const App = () => {
  const ErrorBoundary = useBugsnagErrorBoundary()

  return (
    <ErrorBoundary>
      <HelmetProvider>
        <TooltipProvider delayDuration={200}>
          <InlineNoScriptWithConfig inject={gtmInlineNoScript} />
          <EnvironmentProvider>
            <Auth0Provider
              domain={config.publicAuthDomain}
              clientId={config.publicAuthClientId}
              authorizationParams={{
                redirect_uri: window.location.origin,
                audience: config.publicAuthAudience,
              }}
              cacheLocation={auth0CacheLocation}
              useRefreshTokens
              useRefreshTokensFallback // See: https://community.auth0.com/t/auth0-spa-2-x-returning-missing-refresh-token/98999/29
            >
              <PersistUserDomain>
                <UrqlProvider>
                  <OrganisationSubdomainContext.Provider
                    value={orgNameFromHostname}
                  >
                    <ThemeProvider theme={theme}>
                      {cssBaseline}
                      <GlobalStyles />
                      <RouterProvider router={router} />
                      <NewVersionPrompt />
                    </ThemeProvider>
                  </OrganisationSubdomainContext.Provider>
                </UrqlProvider>
              </PersistUserDomain>
            </Auth0Provider>
          </EnvironmentProvider>
        </TooltipProvider>
      </HelmetProvider>
    </ErrorBoundary>
  )
}
