import {toError} from "fp-ts/Either"
import type {
  GetAllMaterialTypesQuery,
  GetAllMaterialTypesQueryVariables,
  GetAllProvenanceCategoriesQuery,
  GetAllProvenanceCategoriesQueryVariables,
  GetCountriesQuery,
  GetCountriesQueryVariables,
  GetManualAccessionNumberErrorsQuery,
  GetManualAccessionNumberErrorsQueryVariables,
} from "../../../generated/graphql"
import {noGQLVars} from "../../utils/empty"
import {authedFetcher} from "../../utils/gql-client"
import {createAuthedHook} from "../../utils/hooks/hook"
import {createWrappedSWRHook} from "../../utils/swr"
import {
  GET_COUNTRIES,
  GET_MANUAL_ACCESSION_NUMBER_ERRORS,
  GET_MATERIAL_TYPES,
  GET_PROVENANCE_CATEGORIES,
} from "./graphql"

export const fetchMaterialTypes = authedFetcher<
  GetAllMaterialTypesQueryVariables,
  GetAllMaterialTypesQuery
>(GET_MATERIAL_TYPES)("GetAllMaterialTypes", noGQLVars)

export const useMaterialTypes = createAuthedHook(
  createWrappedSWRHook(fetchMaterialTypes, toError),
)

export const fetchProvenanceCategories = authedFetcher<
  GetAllProvenanceCategoriesQueryVariables,
  GetAllProvenanceCategoriesQuery
>(GET_PROVENANCE_CATEGORIES)("GetAllProvenanceCategories", noGQLVars)

export const useProvenanceCategories = createAuthedHook(
  createWrappedSWRHook(fetchProvenanceCategories, toError),
)

export const fetchCountries = authedFetcher<
  GetCountriesQueryVariables,
  GetCountriesQuery
>(GET_COUNTRIES)("GetCountries", noGQLVars)

export const useCountries = createAuthedHook(
  createWrappedSWRHook(fetchCountries, toError),
)

export const fetchManualAccessionNumberErrors = authedFetcher<
  GetManualAccessionNumberErrorsQueryVariables,
  GetManualAccessionNumberErrorsQuery
>(GET_MANUAL_ACCESSION_NUMBER_ERRORS)("GetManualAccessionNumberErrors").fetch
