import {type HTMLAttributes} from "react"
import {twMerge} from "tailwind-merge"

export const MapOverlay = ({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      className={twMerge(
        "pointer-events-none absolute bottom-0 top-0 z-10 flex h-full min-h-full w-full flex-col justify-between font-sans md:flex-row",
        className,
      )}
      {...props}
    >
      {children}
    </div>
  )
}
