import type {Dispatch} from "react"
import {Fragment, useCallback} from "react"
import {StringComparatorChipGroup} from "../filter-chip-group/groups/string-comparator"
import type {OpenFilterArgs} from "../plant-materials/filters"
import {TagsChipGroup} from "../tags-filter/chip-group"
import {FamilyChipGroup} from "../family-filter/chip-group"
import {RedListStatusChipGroup} from "../red-list-status/chip-group"
import {LifeFormsChipGroup} from "../life-forms/chip-group"
import {UserChipGroup} from "../user-filter/user-chip-group"
import type {TaxaFilterAction} from "./filter-reducer"
import {FilterActionTypes} from "./filter-reducer"
import type {LocalTaxaFilterWithId, TaxaFilterType} from "./types"
import {ValidationChipGroup} from "./validation-filter/chip-group"

export interface FilterChipGroupProps {
  handleOpenFilter: (args: OpenFilterArgs<TaxaFilterType>) => void
  dispatchFilter: Dispatch<TaxaFilterAction>
}

export const TaxaFilterChipGroup = ({
  filters,
  selectFilter,
  dispatchFilter,
  ...props
}: FilterChipGroupProps & {
  selectFilter?: TaxaFilterType
  filters: Array<LocalTaxaFilterWithId>
}) => {
  const deleteFilter = useCallback(
    (id: string) => {
      dispatchFilter({
        type: FilterActionTypes.DELETE_FILTER,
        payload: {id},
      })
    },
    [dispatchFilter],
  )

  const filterGroupHidden = (type: TaxaFilterType) =>
    selectFilter != null && selectFilter !== type

  return (
    <>
      {filters.map((filter) => (
        <Fragment key={filter.id}>
          <StringComparatorChipGroup
            filter={filter.commonName}
            filterType="commonName"
            id={filter.id}
            handleDelete={deleteFilter}
            hidden={filterGroupHidden("commonName")}
            filterLabel="Common name"
            {...props}
          />
          <StringComparatorChipGroup
            filter={filter.authorship}
            filterType="authorship"
            id={filter.id}
            handleDelete={deleteFilter}
            hidden={filterGroupHidden("authorship")}
            filterLabel="Authorship"
            {...props}
          />
          <StringComparatorChipGroup
            filter={filter.notes}
            filterType="notes"
            id={filter.id}
            handleDelete={deleteFilter}
            hidden={filterGroupHidden("notes")}
            filterLabel="Notes"
            {...props}
          />
          <StringComparatorChipGroup
            filter={filter.nativeDistribution}
            filterType="nativeDistribution"
            id={filter.id}
            handleDelete={deleteFilter}
            hidden={filterGroupHidden("nativeDistribution")}
            filterLabel="Native distribution"
            {...props}
          />
          <LifeFormsChipGroup
            filterType="lifeForms"
            filter={filter.lifeForms}
            id={filter.id}
            handleDelete={deleteFilter}
            hidden={filterGroupHidden("lifeForms")}
            {...props}
          />
          <StringComparatorChipGroup
            filter={filter.climate}
            filterType="climate"
            id={filter.id}
            handleDelete={deleteFilter}
            hidden={filterGroupHidden("climate")}
            filterLabel="Climate"
            {...props}
          />
          <UserChipGroup
            filter={filter.creator}
            filterType="creator"
            id={filter.id}
            handleDelete={deleteFilter}
            hidden={filterGroupHidden("creator")}
            filterLabel="Creator"
            {...props}
          />
          <UserChipGroup
            filter={filter.lastEditor}
            filterType="lastEditor"
            id={filter.id}
            handleDelete={deleteFilter}
            hidden={filterGroupHidden("lastEditor")}
            filterLabel="Last editor"
            {...props}
          />
          <RedListStatusChipGroup
            filterType="redListStatus"
            filter={filter.redListStatus}
            id={filter.id}
            handleDelete={deleteFilter}
            hidden={filterGroupHidden("redListStatus")}
            {...props}
          />
          <TagsChipGroup
            filterType="tags"
            filter={filter.tags}
            id={filter.id}
            handleDelete={deleteFilter}
            hidden={filterGroupHidden("tags")}
            {...props}
          />
          <FamilyChipGroup
            filterType="family"
            filter={filter.family}
            id={filter.id}
            handleDelete={deleteFilter}
            hidden={filterGroupHidden("family")}
            {...props}
          />
          <ValidationChipGroup
            filterType="validation"
            filter={filter.validation}
            id={filter.id}
            handleDelete={deleteFilter}
            hidden={filterGroupHidden("validation")}
            {...props}
          />
        </Fragment>
      ))}
    </>
  )
}
