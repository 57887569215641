import {Button} from "@hortis/ui/button"
import {SadFace} from "@hortis/ui/icons"
import {
  EmptyStateActions,
  EmptyStateBody,
  EmptyStateHeader,
  EmptyStateTitle,
} from "src/components/empty-state"
import {FeaturedIcon} from "src/components/featured-icon/featured-icon"
import type {ReactNode} from "react"
import {EmptyState} from "./empty-state"

interface PageErrorProps {
  testId?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any
}

export const PageError = ({
  testId,
  title,
  body,
  icon,
  error,
}: PageErrorProps & {icon?: ReactNode; title?: string; body?: string}) => {
  return (
    <div className="flex h-screen justify-center bg-grey-50 py-12 md:py-20">
      <div className="flex flex-col gap-8">
        <EmptyState
          className="max-w-sm rounded-lg border border-grey-200 bg-white p-6 shadow-xs"
          data-cy={testId}
        >
          <EmptyStateHeader>
            <FeaturedIcon color="warning">
              {icon ?? <SadFace className="text-warning-600" />}
            </FeaturedIcon>
            <EmptyStateTitle>
              {title ?? "Oops! We had some trouble loading this page"}
            </EmptyStateTitle>
            <EmptyStateBody>
              {body ??
                "Try refreshing, or contact us if the issue persists. We're here to help!"}
            </EmptyStateBody>
          </EmptyStateHeader>
          <EmptyStateActions>
            <Button asChild>
              <a
                rel="noreferrer"
                href="https://support.hortis.com/"
                target="_blank"
              >
                Contact support
              </a>
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                location.reload()
              }}
            >
              Refresh page
            </Button>
          </EmptyStateActions>
        </EmptyState>
        {error instanceof Error && (
          <div className="max-w-sm rounded-lg border border-grey-200 bg-white p-6 shadow-xs">
            <div className="pb-2 font-medium">
              Report this message to support
            </div>
            <div className="text-grey-500">{error.message}</div>
          </div>
        )}
      </div>
    </div>
  )
}
