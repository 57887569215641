import type {AccessionFilter} from "generated/graphql"
import * as R from "ramda"
import {countDateComparator} from "../count/comparators/date"
import {countIdComparator} from "../count/comparators/id"
import {countStringComparator} from "../count/comparators/string"
import {countClassificationFilter} from "../family-filter/count-family-filter"
import {countRedListStatusFilter} from "../red-list-status/count-filter"
import {countTagsFilter} from "../tags-filter/count-filter"
import {countUserFilter} from "../user-filter/count-user-filter"
import {countMaterialReceivedFilter} from "./material-received/count-filter"
import {countProvenanceFilter} from "./provenance-filter/count-filter"
import type {AccessionFilterType} from "./types"

export type AccessionFilterCounts = Record<AccessionFilterType, number> & {
  total: number
  excludeDeaccessioned: number
}

const createDefaultFilterCounts = (): AccessionFilterCounts => ({
  donor: 0,
  provenance: 0,
  notes: 0,
  materialReceived: 0,
  total: 0,
  tags: 0,
  excludeDeaccessioned: 0,
  ipenNumber: 0,
  family: 0,
  commonName: 0,
  accessionDate: 0,
  deaccessionDate: 0,
  redListStatus: 0,
  nativeDistribution: 0,
  lifeForms: 0,
  climate: 0,
  creator: 0,
  lastEditor: 0,
})

// eslint-disable-next-line sonarjs/cognitive-complexity
export const countAccessionFilter = (filter: AccessionFilter) => {
  const count = createDefaultFilterCounts()
  if (filter.excludeDeaccessioned === true) {
    count.excludeDeaccessioned += 1
    count.total += 1
  }
  if (filter.donor != null) {
    count.notes += countStringComparator(filter.donor)
    count.total += 1
  }
  if (filter.ipenNumber != null) {
    count.notes += countStringComparator(filter.ipenNumber)
    count.total += 1
  }
  if (filter.taxon?.commonName != null) {
    count.commonName += countStringComparator(filter.taxon.commonName)
    count.total += 1
  }
  if (filter.provenance != null) {
    count.provenance += countProvenanceFilter(filter.provenance)
    count.total += 1
  }
  if (filter.materialType != null) {
    count.materialReceived += countMaterialReceivedFilter(filter.materialType)
    count.total += 1
  }
  if (filter.notes != null) {
    count.notes += countStringComparator(filter.notes)
    count.total += 1
  }
  if (filter.taxon?.sharedScientificName?.classification != null) {
    count.family += countClassificationFilter(
      filter.taxon.sharedScientificName.classification,
    )
    count.total += 1
  }
  if (filter.taxon?.sharedScientificName?.taxon?.globalRedListStatus != null) {
    count.redListStatus += countRedListStatusFilter(
      filter.taxon.sharedScientificName.taxon.globalRedListStatus,
    )
    count.total += 1
  }
  if (filter.accessionDate != null) {
    count.accessionDate += countDateComparator(filter.accessionDate)
    count.total += 1
  }
  if (filter.deaccessionDate != null) {
    count.deaccessionDate += countDateComparator(filter.deaccessionDate)
    count.total += 1
  }
  const tagsAndFilter = filter.and?.[0]?.tags
  if (tagsAndFilter != null) {
    count["tags"] += countTagsFilter(tagsAndFilter)
    count.total += 1
  }
  const tagsOrFilter = filter.or?.[0]?.tags
  if (tagsOrFilter != null) {
    count["tags"] += countTagsFilter(tagsOrFilter)
    count.total += 1
  }
  const nativeDistribution = filter.taxon?.or?.[0]?.nativeDistribution
  if (nativeDistribution != null || filter.taxon?.nativeDistribution != null) {
    count.nativeDistribution += 1
    count.total += 1
  }
  if (filter.taxon?.sharedScientificName?.taxon?.lifeForms?.id != null) {
    count.lifeForms += countIdComparator(
      filter.taxon.sharedScientificName.taxon.lifeForms.id,
    )
    count.total += 1
  }
  if (filter.taxon?.sharedScientificName?.taxon?.climate != null) {
    count.lifeForms += countStringComparator(
      filter.taxon.sharedScientificName.taxon.climate,
    )
    count.total += 1
  }
  if (filter.createdBy != null) {
    count.creator += countUserFilter(filter.createdBy)
    count.total += 1
  }
  if (filter.updatedBy != null) {
    count.lastEditor += countUserFilter(filter.updatedBy)
    count.total += 1
  }
  return count
}

const sumObjectValues = <T>(...args: Array<T>) =>
  R.reduce(R.mergeWith(R.add), {} as T)(args)

export const countAccessionFilters = (
  filters: AccessionFilter,
): AccessionFilterCounts => {
  let count = createDefaultFilterCounts()
  count = sumObjectValues(countAccessionFilter(filters), count)
  if (filters.and != null) {
    for (const filter of filters.and) {
      count = sumObjectValues(countAccessionFilter(filter), count)
    }
  }
  return count
}
