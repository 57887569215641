import {LoadingPage} from "@hortis/ui/loading"
import type {ReactElement} from "react"
import {useEffect} from "react"
import {useNavigate} from "@tanstack/react-router"

interface Props {
  url: string
}

export const Redirect = ({url}: Props): ReactElement => {
  const navigate = useNavigate()
  useEffect(() => {
    void navigate({to: url})
  }, [navigate, url])
  return <LoadingPage />
}
