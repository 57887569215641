import * as A from "fp-ts/Array"
import {useMemo} from "react"
import {FormProvider} from "react-hook-form"
import {
  OrgFamilyAutocomplete,
  SiteFamilyAutocomplete,
} from "src/components/autocomplete/scientific-names/scientific-name-autocomplete"
import {
  CollapsableRadioButton,
  RadioButton,
  RadioGroup,
} from "src/components/radio"
import {FilterTitle} from "src/features/filters/components/modal/filter-title"
import {match} from "ts-pattern"
import type {FilterFormProps} from "../accessions/types"
import type {FilterModalProps} from "../components/modal/filter-modal"
import {FilterModal} from "../components/modal/filter-modal"
import {FilterModalButtons} from "../components/modal/modal-buttons"
import {FilterModalError} from "../components/modal/modal-error"
import {useFilterForm} from "../components/modal/use-filter-form"
import {determineInitialOperation} from "../plant-materials/modals/determine-initial-operation"
import {familyFilterResolver} from "./schema"
import type {LocalFamilyFilter} from "./schema"

const createDefaultValues = (initialValue?: NonNullable<LocalFamilyFilter>) => {
  const defaultValues = {
    is: initialValue?.is == null ? [] : [...initialValue.is],
    isNot: initialValue?.isNot == null ? [] : [...initialValue.isNot],
    hasNoValue: initialValue?.hasNoValue ?? undefined,
  }
  return {
    defaultValues,
    initialOperation: determineInitialOperation(defaultValues, "is"),
  }
}

interface FamilyFilterFormProps {
  addModifyFilter: (changes: {family?: LocalFamilyFilter}) => void
  deleteFilter: (() => void) | undefined
  initialValue?: {family?: LocalFamilyFilter | null}
  familiesAssociatedWith: "site" | "org"
}

export const FamilyFilterForm = ({
  onClose,
  initialValue,
  addModifyFilter,
  deleteFilter,
  familiesAssociatedWith,
}: Omit<FilterFormProps, "dispatchFilters" | "initialValue"> &
  FamilyFilterFormProps) => {
  const {error, setError, operation, handleRadioChange, formMethods} =
    useFilterForm({
      resolver: familyFilterResolver,
      ...createDefaultValues(initialValue?.family ?? undefined),
    })

  const onSubmit = useMemo(
    () =>
      formMethods.handleSubmit((values) => {
        match(operation)
          .with("is", () => {
            if (A.isNonEmpty(values.is)) {
              addModifyFilter({
                family: {
                  is: values.is,
                },
              })
              onClose()
            } else {
              setError("Select a family to continue")
            }
          })
          .with("isNot", () => {
            if (A.isNonEmpty(values.isNot)) {
              addModifyFilter({
                family: {
                  isNot: values.isNot,
                },
              })
              onClose()
            } else {
              setError("Select a family to continue")
            }
          })
          .with("hasNoValue", () => {
            addModifyFilter({
              family: {
                hasNoValue: true,
              },
            })
            onClose()
          })
          .run()
      }),
    [formMethods, operation, onClose, addModifyFilter, setError],
  )

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={onSubmit}>
        <div className="mb-4 flex flex-col gap-4">
          <FilterTitle title="Family" deleteFilter={deleteFilter} />
          <FilterModalError error={error} />
          <RadioGroup
            value={operation}
            onChange={handleRadioChange}
            label="Operation"
          >
            {(props) => (
              <>
                <CollapsableRadioButton
                  selectedValue="is"
                  testId="family-is-radio"
                  label="Is"
                  {...props}
                >
                  {familiesAssociatedWith === "org" ? (
                    <OrgFamilyAutocomplete name="is" testId="family-is-input" />
                  ) : (
                    <SiteFamilyAutocomplete
                      name="is"
                      testId="family-is-input"
                    />
                  )}
                </CollapsableRadioButton>
                <CollapsableRadioButton
                  selectedValue="isNot"
                  testId="family-is-not-radio"
                  label="Is not"
                  {...props}
                >
                  {familiesAssociatedWith === "org" ? (
                    <OrgFamilyAutocomplete
                      name="isNot"
                      testId="family-is-not-input"
                    />
                  ) : (
                    <SiteFamilyAutocomplete
                      name="isNot"
                      testId="family-is-not-input"
                    />
                  )}
                </CollapsableRadioButton>
                <RadioButton
                  label="Has no value"
                  selectedValue="hasNoValue"
                  testId="family-has-no-value-radio"
                  {...props}
                />
              </>
            )}
          </RadioGroup>
        </div>
        <FilterModalButtons onCancel={onClose} testIdPrefix="family" />
      </form>
    </FormProvider>
  )
}

// Seperated from form component to make sure popover unmount causes state reset
export const FamilyFilterModal = ({
  open,
  onClose,
  anchorEl,
  ...props
}: Omit<FilterFormProps, "initialValue" | "dispatchFilters"> &
  FilterModalProps &
  FamilyFilterFormProps) => (
  <FilterModal anchorEl={anchorEl} open={open} onClose={onClose}>
    <FamilyFilterForm onClose={onClose} {...props} />
  </FilterModal>
)
