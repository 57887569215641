import config from "src/config"

export const fetchCsv = ({
  body,
  accessToken,
  fileName,
  path,
}: {
  body: Record<string, unknown>
  accessToken: string
  fileName: string
  path: string
}) =>
  fetch(`${config.publicApiUrl.replace("/graphql", "")}${path}`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body),
  })
    .then((res) => {
      if (
        res.status === 200 &&
        res.headers.get("content-type")?.includes("text/csv") === true
      ) {
        return res.blob()
      } else {
        throw new Error("Request for CSV failed")
      }
    })
    .then((blob) => {
      // Create fake anchor link to execute download
      const file = window.URL.createObjectURL(blob)
      const anchor = document.createElement("a")

      document.body.append(anchor)
      anchor.href = file

      anchor.download = `${fileName}.csv`
      // Execute download
      anchor.click()

      // Clean up
      URL.revokeObjectURL(file)
      return null
    })
    .catch((error: unknown) => {
      throw new Error(String(error))
    })
