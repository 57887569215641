import {Checkbox} from "@hortis/ui/checkbox"
import {type RowData, type Table} from "@tanstack/react-table"
import type {ReactNode} from "react"

export interface DataTableCheckboxHeaderProps<TData extends RowData> {
  table: Table<TData>
  children?: ReactNode
}

export function DataTableCheckboxHeader<TData extends RowData>({
  table,
  children,
}: DataTableCheckboxHeaderProps<TData>) {
  return (
    <div className="flex items-center gap-3">
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        onChange={(e) => {
          const currentPageRecordsCount =
            table.getSelectedRowModel().rows.length

          if (currentPageRecordsCount > 0) {
            table.toggleAllPageRowsSelected(false)
          } else {
            table.toggleAllPageRowsSelected(e.target.checked)
          }
        }}
        indeterminate={table.getIsSomePageRowsSelected()}
        aria-label="Select all"
      />
      {children}
    </div>
  )
}
