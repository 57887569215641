import type {HTMLAttributes, PropsWithChildren} from "react"
import {twMerge} from "tailwind-merge"
import {type RecordSectionId} from "../table-of-contents/use-records-table-of-contents"

interface RecordSectionProps {
  testId?: string
  id: RecordSectionId
}

const RecordSection = ({
  children,
  testId,
  id,
  ...props
}: PropsWithChildren<RecordSectionProps> & HTMLAttributes<HTMLDivElement>) => {
  return (
    <div data-cy={testId ?? `${id}-record-section`} id={id} {...props}>
      {children}
    </div>
  )
}

const RecordSectionHeader = ({children}: {children: React.ReactNode}) => (
  <div className="mb-4 flex min-h-11 items-center gap-x-2 border-b border-grey-200 pb-2 text-base text-grey-900">
    {children}
  </div>
)

const RecordSectionTitle = ({children}: {children: React.ReactNode}) => (
  <div className="font-medium text-grey-900">{children}</div>
)

const RecordSectionHeaderButtons = ({
  children,
}: {
  children: React.ReactNode
}) => (
  <span className="ml-auto flex items-center text-xs text-grey-500">
    {children}
  </span>
)

const RecordSectionContent = ({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) => (
  <div className={twMerge("flex flex-col gap-4", className)} {...props}>
    {children}
  </div>
)
const RecordSectionContentGroup = ({
  children,
  className,
}: {
  children: React.ReactNode
  className?: string
}) => (
  <div
    className={twMerge(
      "record-section-content-group flex flex-col gap-x-6 gap-y-4",
      className,
    )}
  >
    {children}
  </div>
)

export {
  RecordSection,
  RecordSectionContent,
  RecordSectionContentGroup,
  RecordSectionHeader,
  RecordSectionHeaderButtons,
  RecordSectionTitle,
}
