import {useCallback, useMemo} from "react"
import type {AccessionFilter} from "generated/graphql"
import {usePersistedReducer} from "src/utils/hooks/persisted-reducer"
import {FilterActionTypes} from "../plant-materials/filter-reducer"
import {flattenTagsFilter} from "../tags-filter"
import {flattenFamilyFilter} from "../family-filter/flatten-family-filter"
import {transformRedListStatusFilter} from "../red-list-status/transform"
import {transformNativeDistributionFilter} from "../native-distribution/transform"
import {transformLifeFormsFilter} from "../life-forms/transform"
import {transformClimateFilter} from "../climate/transform"
import {transformUserFilter} from "../user-filter/transform-user-filter"
import type {LocalTagsFilter} from "../tags-filter/schema"
import type {AccessionFilterState} from "./filter-reducer"
import {defaultState, filterReducer} from "./filter-reducer"
import type {AccessionFilterType} from "./types"
import {countAccessionFilters} from "./count-filters"
import {flattenMaterialReceivedFilter} from "./material-received/flatten-material-received-filter"

const transformTagsFilter = (tags: LocalTagsFilter | undefined) => {
  if (tags == null) {
    return undefined
  }

  const multiRecordFilters = [
    {
      tags: flattenTagsFilter(tags),
    },
    {
      taxaTags: flattenTagsFilter(tags),
    },
  ]

  return {
    or:
      tags.is != null || tags.contains != null ? multiRecordFilters : undefined,
    and: tags.isNot == null ? undefined : multiRecordFilters,
  }
}

export const useAccessionFilters = (args?: {
  initialState?: Partial<AccessionFilterState>
  persistenceKey?: string
}) => {
  const {initialState, persistenceKey} = args ?? {}
  const [filters, dispatchFilters] = usePersistedReducer(
    filterReducer,
    {
      ...defaultState,
      ...initialState,
    },
    persistenceKey,
  )

  const preparedFilters = useMemo<AccessionFilter>(
    () => ({
      ...filters,
      and: filters.and.map(
        ({
          id: _,
          tags,
          taxon,
          family,
          materialType,
          redListStatus,
          nativeDistribution,
          lifeForms,
          climate,
          creator,
          lastEditor,
          ...rest
        }) => ({
          ...rest,
          taxon: {
            ...taxon,
            sharedScientificName: {
              ...taxon?.sharedScientificName,
              classification: flattenFamilyFilter(family),
            },
            ...transformRedListStatusFilter(redListStatus),
            ...transformNativeDistributionFilter(nativeDistribution),
            ...transformLifeFormsFilter(lifeForms),
            ...transformClimateFilter(climate),
          },
          createdBy: transformUserFilter(creator),
          updatedBy: transformUserFilter(lastEditor),
          materialType: flattenMaterialReceivedFilter(materialType),
          ...transformTagsFilter(tags),
        }),
      ),
    }),
    [filters],
  )

  const filterCounts = useMemo(
    () => countAccessionFilters(preparedFilters),
    [preparedFilters],
  )

  const resetFilters = useCallback(
    (filterType?: AccessionFilterType) => {
      if (filterType == null || typeof filterType !== "string") {
        dispatchFilters({
          type: FilterActionTypes.RESET_FILTERS,
          payload: initialState,
        })
      } else {
        dispatchFilters({
          type: FilterActionTypes.RESET_FILTER_TYPE,
          payload: filterType,
        })
      }
    },
    [initialState, dispatchFilters],
  )

  return {
    filters,
    preparedFilters,
    filterCounts,
    resetFilters,
    dispatchFilters,
  }
}

export type AccessionFilters = ReturnType<typeof useAccessionFilters>
