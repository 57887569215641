import type {FC, ReactElement, ReactNode} from "react"

export type ReactFn<Props> = (props: Props) => ReactElement | null

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FCToFn<_FC extends FC<React.PropsWithChildren<any>>> = _FC extends FC<
  React.PropsWithChildren<infer Props>
>
  ? (props: Props & {children?: ReactNode}) => JSX.Element
  : never

type HasMuiName = {muiName: string}
type MaybeHasMuiName = {muiName?: string}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ConditionallyHasMuiName<Component extends ReactFn<any> & MaybeHasMuiName> =
  Component extends MaybeHasMuiName
    ? Component extends HasMuiName
      ? HasMuiName
      : MaybeHasMuiName
    : unknown

type ConditionallyFCHasMuiName<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Component extends FC<React.PropsWithChildren<any>> & MaybeHasMuiName,
> = Component extends MaybeHasMuiName
  ? Component extends HasMuiName
    ? HasMuiName
    : MaybeHasMuiName
  : unknown

/**
 * applyMui applies a higher order component `fn` to a given `Component`,
 * applying any `muiName` if present on `Component` and propagating the type.
 *
 * It should be applied to any mui component or wrapped/applied mui component.
 * @see {@link https://next.material-ui.com/guides/composition/} for explanation
 *
 * @param fn - higher order component
 * @param Component - mui component that may or may not have a muiName field
 * @returns the result of `fn`, with the muiName from `Component`
 *
 * @example
 * ```
 * applyMui(withTestId, CoreCloseIcon)
 * ```
 *
 * @example
 * ```
 * applyMui(Component => (props) <div><Component {...props} /></div>, MyComponent)
 * ```
 *
 * @example
 * ```
 * export const Bar = applyMui(
 *   flow(
 *     contramapComponent<FooProps>(...),
 *     withTestId,
 *   ),
 *   Foo,
 * )
 * ```
 */
export const applyMui: <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Component extends ReactFn<any> & MaybeHasMuiName,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Wrapper extends ReactFn<any>,
>(
  fn: (value: Component) => Wrapper,
  Component: Component,
) => Wrapper & ConditionallyHasMuiName<Component> = (fn, Component) => {
  const Wrapper = fn(Component)
  if ("muiName" in Component && Component.muiName !== undefined) {
    ;(Wrapper as MaybeHasMuiName).muiName = Component.muiName
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-return
  return Wrapper as any
}

/**
 * {@link applyMui} for components typed with React.FC
 * which are sufficiently incompatible to require a separate function
 * (overloading or generics had various problems telling the difference reliably)
 *
 * @see {@link applyMui}
 */
export const applyMuiFC = applyMui as <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Component extends FC<React.PropsWithChildren<any>> & MaybeHasMuiName,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Wrapper extends ReactFn<any>,
>(
  fn: (value: FCToFn<Component>) => Wrapper,
  Component: Component,
) => Wrapper & ConditionallyFCHasMuiName<Component>

/**
 * Curried, data-first version of {@link applyMui}
 * Similar to Ramda's applyTo or fp-ts' pipe
 *
 * @see {@link applyMui}
 * @see {@link https://ramdajs.com/docs/#applyTo}
 * @see {@link https://gcanti.github.io/fp-ts/modules/function.ts.html#pipe}
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const applyToMui: <Component extends ReactFn<any> & MaybeHasMuiName>(
  Component: Component,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => <Wrapper extends ReactFn<any>>(
  fn: (Component: Component) => Wrapper,
) => Wrapper & ConditionallyHasMuiName<Component> = (Component) => (fn) =>
  applyMui(fn, Component)

/**
 * {@link applyToMui} for components typed with React.FC
 * which are sufficiently incompatible to require a separate function
 * (overloading or generics had various problems telling the difference reliably)
 *
 * @see {@link applyToMui}
 */
export const applyToMuiFC = applyToMui as <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Component extends FC<React.PropsWithChildren<any>> & MaybeHasMuiName,
>(
  Component: Component,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => <Wrapper extends ReactFn<any>>(
  fn: (Component: FCToFn<Component>) => Wrapper,
) => Wrapper & ConditionallyFCHasMuiName<Component>
