import {pipe, D} from "@mobily/ts-belt"

export type Breakpoint = "xs" | "sm" | "md" | "lg" | "xl"

export const breakpoints = {
  xs: 0,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
} satisfies Record<Breakpoint, number>

export const mediaBp = pipe(
  breakpoints,
  D.map((bp) => `@media (min-width: ${bp}px)`),
)
