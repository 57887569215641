import {useAuth0} from "@auth0/auth0-react"
import {Avatar, type AvatarSize} from "@hortis/ui/avatar"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@hortis/ui/dropdown-menu"
import {Logout1, Settings1} from "@hortis/ui/icons"
import {Link} from "@tanstack/react-router"
import * as R from "fp-ts/Reader"
import {flow} from "fp-ts/function"
import type {ReactElement} from "react"
import {memo, useCallback} from "react"
import {useSettingsReturnToStore} from "src/features/settings/return-to-store"
import {useLocation} from "src/utils/hooks/router"
import {twMerge} from "tailwind-merge"
import * as OE from "../../../utils/option-either"
import {useMe} from "./fetchers"

const missingMe = new Error("Me property doesn't exist")

export const useMeDetails = flow(
  useMe,
  OE.chainNullableToErrorK(missingMe)(({me}) => me),
)
const meDetails = R.ask<ReturnType<typeof useMeDetails>>()

const deriveName = flow(
  meDetails,
  OE.map(({givenName, initials}) =>
    givenName == null ? initials : `${givenName}`,
  ),
  OE.noneAndErrorToUndefined,
)

const deriveAvatarDetails = flow(
  meDetails,
  OE.map(({avatarUrl, initials}) => ({
    url: avatarUrl ?? undefined,
    name: initials.slice(0, 1),
  })),
  OE.noneAndErrorToUndefined,
)

interface UserProfileRowProps {
  size?: AvatarSize
  showName?: boolean
}

export const UserProfileRow = memo(function UserProfileRow({
  showName = true,
  size = "xxs",
}: UserProfileRowProps): ReactElement {
  const {logout} = useAuth0()
  const meDetails = useMeDetails()
  const name = deriveName(meDetails)
  const avatar = deriveAvatarDetails(meDetails)
  const location = useLocation()
  const {setReturnTo} = useSettingsReturnToStore()

  const handleLogout = useCallback(() => {
    void logout({
      logoutParams: {returnTo: `${window.location.origin}/auth/login`},
    })
  }, [logout])

  return (
    <div>
      <DropdownMenu>
        <DropdownMenuTrigger
          className={twMerge(
            "flex w-full items-center gap-3 rounded-[6px] p-0 text-sm font-medium transition-colors hover:bg-grey-50",
            showName && "px-3 py-2",
          )}
        >
          <Avatar
            alt={
              name == null
                ? avatar?.name == null
                  ? "Profile image"
                  : avatar.name
                : name
            }
            size={size}
            src={avatar?.url}
            textContent={avatar?.name}
          />
          {showName && name}
        </DropdownMenuTrigger>
        <DropdownMenuContent className="z-[9999] w-[200px]" align="end">
          <Link
            to={"/settings/account/profile"}
            onClick={() => {
              setReturnTo(location.pathname)
            }}
          >
            <DropdownMenuItem>
              <Settings1 className="h-4 w-4 text-grey-500" />
              <span className="text-sm">Profile settings</span>
            </DropdownMenuItem>
          </Link>

          <DropdownMenuItem onClick={handleLogout}>
            <Logout1 className="h-4 w-4 text-grey-500" />
            <span className="text-sm">Log out</span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
})
