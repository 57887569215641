import {Button} from "@hortis/ui/button"
import {ChevronDown} from "@hortis/ui/icons"
import {Popover, PopoverContent, PopoverTrigger} from "@hortis/ui/popover"
import {useEffect, useRef, useState} from "react"
import {useToggle} from "src/utils/hooks/toggle"
import {twMerge} from "tailwind-merge"

const HierarchyPopover = ({children}: {children: React.ReactNode}) => {
  const [open, toggleOpen] = useToggle()

  return (
    <Popover open={open} onOpenChange={toggleOpen}>
      {children}
    </Popover>
  )
}

const HierarchyTrigger = ({children}: {children: React.ReactNode}) => {
  return (
    <PopoverTrigger className="mx-auto w-fit bg-white" asChild>
      <Button
        variant="linkgray"
        size={"lg"}
        endIcon={<ChevronDown className="h-4 w-4 text-grey-400" />}
        className="text-grey-900"
        data-cy="hierarchy-popover-button"
      >
        {children}
      </Button>
    </PopoverTrigger>
  )
}

const HierarchyContent = ({children}: {children: React.ReactNode}) => {
  return (
    <PopoverContent className="max-h-[450px] w-[270px] overflow-scroll overflow-x-hidden rounded-lg border border-grey-100 bg-white pb-2 shadow-lg data-[state=closed]:!animate-none data-[state=open]:!animate-none">
      <div className="p-[12px_8px_4px_16px] pt-3 text-xs font-medium text-grey-500">
        Hierarchy
      </div>
      <div data-cy="hierarchy-popover-content" className="relative px-2.5">
        {children}
      </div>
    </PopoverContent>
  )
}

const HierarchyListGroup = ({
  children,
  indent = false,
}: {
  children: React.ReactNode
  indent?: boolean
} & React.HTMLAttributes<HTMLDivElement>) => {
  const parentRef = useRef<HTMLDivElement>(null)
  const [lastChildHeight, setLastChildHeight] = useState(0)

  useEffect(() => {
    const parent = parentRef.current

    const handleResize = () => {
      if (parentRef.current) {
        const parentElement = parentRef.current

        const elements = parentElement.querySelectorAll(
          ":scope > .hierarchy-list-item",
        )
        // eslint-disable-next-line unicorn/prefer-at
        const lastElement = elements[elements.length - 1]

        const refElement =
          lastElement == null
            ? parentElement.lastElementChild?.querySelector(
                ":scope > .hierarchy-list-item",
              )
            : lastElement

        if (!refElement) {
          setLastChildHeight(0)
          return
        }

        const topHeight = refElement.getBoundingClientRect().top

        const parentTop = parentElement.getBoundingClientRect().top

        const diff = topHeight - parentTop

        setLastChildHeight(diff)
      }
    }
    const resizeObserver = new ResizeObserver(handleResize)
    window.addEventListener("resize", handleResize)

    if (parent != null) {
      resizeObserver.observe(parent)
    }

    handleResize()
    return () => {
      window.removeEventListener("resize", handleResize)
      if (parent != null) {
        resizeObserver.unobserve(parent)
      }
    }
  }, [])

  return (
    <div
      className="relative z-0"
      ref={parentRef}
      style={
        {
          marginLeft: indent && "34px",
          "--last-child-height": `${lastChildHeight}px`,
        } as React.CSSProperties
      }
    >
      {
        <div
          className={twMerge(
            indent &&
              "absolute -left-[13px] z-20 h-[var(--last-child-height)] w-[4px] border-l-[1.5px] border-grey-200",
          )}
        />
      }
      {children}
    </div>
  )
}

export {
  HierarchyContent,
  HierarchyListGroup,
  HierarchyPopover,
  HierarchyTrigger,
}
