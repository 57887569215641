import {memo} from "react"
import {BasicDatePicker} from "../../../../components/date-picker/date-picker"
import {FormTextField} from "../../../../components/text-field/text-field"
import {CountriesAutocomplete} from "./countries-autocomplete"

export const CollectionDataFields = memo(function CollectionDataFields() {
  return (
    <div className="flex flex-col gap-8">
      <BasicDatePicker
        name="collectionDate"
        label="Collection date"
        testId="collection-date"
      />

      <FormTextField
        label="Collector(s)"
        name="collectionCollectors"
        testId="collection-collectors"
      />
      <FormTextField
        label="Collector(s) reference"
        name="collectionCollectorsFieldNumber"
        testId="collection-collectors-reference"
      />

      <FormTextField
        label="Habitat"
        name="collectionHabitat"
        testId="collection-habitat"
      />

      <FormTextField
        label="Associated taxa"
        name="collectionAssociatedTaxa"
        testId="collection-associated-taxa"
      />

      <FormTextField
        label="Remarks"
        name="collectionRemarks"
        testId="collection-remarks"
        multiline
        minRows={2}
        maxRows={6}
      />
      <div className="border-b border-dashed border-grey-200" />

      <CountriesAutocomplete />
      <FormTextField
        label="Province / region"
        name="collectionStateProvince"
        testId="province-region"
      />
      <FormTextField
        label="Locality"
        name="collectionLocality"
        testId="locality"
      />
      <FormTextField
        label="Latitude"
        name="collectionLatitude"
        testId="latitude"
      />
      <FormTextField
        label="Longitude"
        name="collectionLongitude"
        testId="longitude"
      />

      <FormTextField
        label="Uncertainty (m)"
        name="collectionCoordinateUncertainty"
        testId="uncertainty"
        decimal
        numeric
        saveAsNumber
      />
      <FormTextField
        label="Elevation (m)"
        name="collectionElevation"
        testId="elevation"
        type="text"
        signedNumeric
        decimal
      />
    </div>
  )
})
