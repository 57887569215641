import type {CSSObject} from "@mui/material/styles"
import {styled} from "@mui/material/styles"
import {constant, flow} from "fp-ts/function"
import * as O from "fp-ts/Option"
import {colors} from "src/colors"
import type {SxProps} from "../../../utils/sx"
import {fromSxFn} from "../../../utils/sx"

export const tableHeaderRowMixin = (stickyPosition = "0") =>
  fromSxFn((theme) => ({
    display: "contents",
    '& div[role="columnheader"]': {
      position: "sticky",
      // The sticky position has to increase if the filters are open,
      // so we have to mimic the animation on the filters drawer.
      transition: "top 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      top: stickyPosition,
      color: "grey.800",
      fontWeight: "500",
      zIndex: 10,
      background: theme.palette.background.paper,
      padding: theme.spacing(2),
      textAlign: "left",
      fontSize: theme.typography.fontSize,
      cursor: "default",
    },
    '& div[role="columnheader"] > div': {
      display: "flex",
      alignItems: "center",
      gap: 0.5,
      height: "100%",
    },
    // Used to hide column header more button
    '& div[role="columnheader"] > div > .column-menu-button:not(.sorted, .open)':
      {
        opacity: 0,
      },
    '& div[role="columnheader"]:is(:focus-within, :hover) > div > .column-menu-button:not(.sorted, .open)':
      {
        opacity: 1,
      },
  }))
export const tableHeaderRowMixinDefault = tableHeaderRowMixin()

export const tableBodyMixin = fromSxFn((theme) => ({
  display: "contents",
  '& div[role="cell"]:first-of-type': {
    borderTopLeftRadius: theme.shape.borderRadiusString,
    borderBottomLeftRadius: theme.shape.borderRadiusString,
  },
  '& div[role="cell"]:last-child': {
    borderTopRightRadius: theme.shape.borderRadiusString,
    borderBottomRightRadius: theme.shape.borderRadiusString,
    maxWidth: theme.spacing(6),
    width: theme.spacing(6),
  },
  '& div[role="cell"].created-by': {
    paddingTop: 0,
    paddingBottom: 0,
  },
}))

export const tableMixin = fromSxFn((theme) => ({
  '& div[role="cell"]': {
    fontWeight: "400",
    color: theme.palette.text.primary,
    padding: theme.spacing(2),
    textAlign: "left",
    fontSize: theme.typography.fontSize,
    alignSelf: "stretch",
    display: "flex",
    alignItems: "center",
  },
  '& :is(div[role="cell"], div[role="columnheader"]).checkbox-padding': {
    width: theme.spacing(6),
    padding: "1px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: theme.spacing(1),
  },
  '& div[role="row"]:hover': {
    cursor: "pointer",
  },
}))

export const materialsTableResponsiveMixin = fromSxFn((theme) => ({
  /*
   * To add a new column, add a width value to the gridTemplateColumns
   * at each breakpoint (ie 1fr, auto, 50px), as well as any responsive display
   * properties. If you're adding a reponsive column before or inbetween any current
   * you'll have to nudge any above that breakpoint along to accommodate
   */
  display: "grid",
  gridTemplateColumns: "auto auto 1fr auto auto auto auto",
  "& div.location, div.quantity, div.donor-supplier, div.date-added": {
    display: "none",
  },
  [theme.breakpoints.up(1000)]: {
    gridTemplateColumns: "auto auto 2fr auto auto auto auto auto",
    "& div.location": {
      // eslint-disable-next-line sonarjs/no-duplicate-string
      display: "flex !important",
    },
  },
  [theme.breakpoints.up(1150)]: {
    gridTemplateColumns: "auto auto 2fr auto auto auto auto auto auto",
    "& div.quantity": {
      display: "flex !important",
    },
  },
  [theme.breakpoints.up(1440)]: {
    gridTemplateColumns: "auto auto 1.5fr auto auto auto auto auto 1.5fr auto",
    "& div.donor-supplier": {
      display: "flex !important",
    },
  },
  [theme.breakpoints.up(1536)]: {
    gridTemplateColumns:
      "auto auto 1.5fr auto auto auto auto auto 1.5fr auto auto !important",
    "& div.date-added": {
      display: "flex !important",
    },
  },
}))

export const accessionsTableResponsiveMixin = fromSxFn((theme) => ({
  /*
   * To add a new column, add a width value to the gridTemplateColumns
   * at each breakpoint (ie 1fr, auto, 50px), as well as any responsive display
   * properties. If you're adding a reponsive column before or inbetween any current
   * you'll have to nudge any above that breakpoint along to accommodate
   */
  display: "grid",
  gridTemplateColumns: "auto auto auto 1.5fr auto auto auto",
  "& div.created-by, div.donor-supplier": {
    display: "none",
  },
  [theme.breakpoints.up(1280)]: {
    gridTemplateColumns: "auto auto auto 1.5fr auto auto auto auto",
    "& div.created-by": {
      display: "flex !important",
    },
  },
  [theme.breakpoints.up(1440)]: {
    gridTemplateColumns: "auto auto auto 1.5fr auto auto auto 1fr auto",
    "& div.donor-supplier": {
      display: "flex !important",
    },
  },
}))

export const taxaTableResponsiveMixin = fromSxFn((theme) => ({
  /*
   * To add a new column, add a width value to the gridTemplateColumns
   * at each breakpoint (ie 1fr, auto, 50px), as well as any responsive display
   * properties. If you're adding a reponsive column before or inbetween any current
   * you'll have to nudge any above that breakpoint along to accommodate
   */
  display: "grid",
  gridTemplateColumns: "auto 2fr 1fr auto",
  "& div.common-name": {
    display: "none",
  },
  "& div.family-name": {
    display: "none",
  },
  [theme.breakpoints.up(1280)]: {
    gridTemplateColumns: "auto 1.5fr 1fr 1.5fr auto",
    "& div.common-name": {
      display: "flex !important",
    },
  },
  [theme.breakpoints.up(1380)]: {
    gridTemplateColumns: "auto 1.5fr 1fr 1.5fr 1fr auto",
    "& div.family-name": {
      display: "flex !important",
    },
  },
}))

export const tableContainerMixin: SxProps = constant({
  overflowX: "initial",
})

const extractId = O.map(({id}: {id: string}) => id)
const getOrFalse = O.getOrElseW(() => false)
export const rowSelectionChecker = (
  rowObjects: ReadonlyArray<{id: string}>,
  selectedRowsById: Record<string, boolean>,
): ((id: number) => boolean) =>
  flow(
    O.fromNullableK((id: number) => rowObjects[id]),
    extractId,
    O.chainNullableK((id) => selectedRowsById[id]),
    getOrFalse,
  )

export const StyledTableRow = styled("div", {
  shouldForwardProp: (prop) =>
    prop !== "prevRowSelected" &&
    prop !== "nextRowSelected" &&
    prop !== "rowSelected",
})<{rowSelected: boolean; prevRowSelected: boolean; nextRowSelected: boolean}>(
  ({theme, rowSelected, prevRowSelected, nextRowSelected}): CSSObject => ({
    display: "contents",
    '&:hover > div[role="cell"]': {
      cursor: "pointer",
      transition: "background-color 0ms ease",
      backgroundColor: rowSelected
        ? colors.primary[50]
        : theme.palette.gray[50],
    },
    '& div[role="cell"]': {
      backgroundColor: rowSelected ? colors.primary[50] : "transparent",
    },
    '& div[role="cell"]:first-of-type': {
      borderTopLeftRadius:
        prevRowSelected && rowSelected ? 0 : theme.shape.borderRadiusString,
      borderBottomLeftRadius:
        nextRowSelected && rowSelected ? 0 : theme.shape.borderRadiusString,
    },
    '& div[role="cell"]:last-child': {
      borderTopRightRadius:
        prevRowSelected && rowSelected ? 0 : theme.shape.borderRadiusString,
      borderBottomRightRadius:
        nextRowSelected && rowSelected ? 0 : theme.shape.borderRadiusString,
      padding: 0,
    },
    "& .chevron-box": {
      display: "flex",
      height: "100%",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      color: theme.palette.text.secondary,
      opacity: 0,
      transform: "translateX(-14px)",
      transition: "transform 0ms ease, opacity 0ms ease",
    },
    '& div[role="cell"]:first-of-type:hover ~ div[role="cell"] > .chevron-box':
      {
        transition: "transform 250ms ease, opacity 200ms ease",
      },
    // Only show chevron when not hovering the checkbox (first child)
    '& div[role="cell"]:not(:first-of-type):hover ~ div[role="cell"] > .chevron-box, div[role="cell"]:not(:first-of-type):hover > .chevron-box':
      {
        transition: "transform 250ms ease, opacity 200ms ease",
        opacity: 1,
        transform: "translateX(0px)",
      },
  }),
)
