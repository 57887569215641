import type {IdComparator} from "generated/graphql"
import {isNonEmpty} from "../is-non-empty"

export const countIdComparator = (filter: NonNullable<IdComparator>) => {
  let count = 0
  if (filter.eq != null) {
    count += 1
  }
  if (isNonEmpty(filter.in)) {
    count += 1
  }
  if (filter.neq != null) {
    count += 1
  }
  if (isNonEmpty(filter.nin)) {
    count += 1
  }
  return count
}
