import CircularProgress from "@mui/material/CircularProgress"
import type {CombinedError} from "urql"
import {useLocation} from "src/utils/hooks/router"
import {CollapsableNavSection} from "./collapsable-nav-section"
import {NavItemBase} from "./nav-item-base"

interface CollectionSitesNavProps {
  sites:
    | ReadonlyArray<{
        name: string
        id: string
        urlSlug: string
        public: boolean
      }>
    | undefined
    | null
  error: CombinedError | undefined
  onNavigate?: () => void
  canEdit: boolean
}

export const CollectionSitesNav = ({
  sites,
  error,
  onNavigate,
  canEdit,
}: CollectionSitesNavProps) => {
  const location = useLocation()
  if (sites === undefined) {
    return (
      <div className="mx-auto py-2">
        <CircularProgress size="18px" />
      </div>
    )
  }

  if (error != null || sites === null) {
    return (
      <div className="py-2 text-error-700">
        Could not fetch collection sites
      </div>
    )
  }

  const pathName = location.pathname

  return (
    <>
      {sites.map(({id, urlSlug, name}) => (
        <CollapsableNavSection
          key={id}
          label={name}
          // Does not match the index page without trailing slash ie "/settings/urlSlug" as it's not used currently
          initialState={new RegExp(`/settings/sites/${urlSlug}/.*`).test(
            pathName,
          )}
          testId={`${urlSlug}-site-header`}
        >
          {canEdit && (
            <NavItemBase
              href={`/settings/sites/${urlSlug}/general`}
              label="General"
              onNavigate={onNavigate}
              testId={`${urlSlug}-general`}
              indent={1}
            />
          )}
          {canEdit && (
            <NavItemBase
              href={`/settings/sites/${urlSlug}/public-site`}
              label="Public site"
              onNavigate={onNavigate}
              testId={`${urlSlug}-public-site`}
              indent={1}
            />
          )}
          <NavItemBase
            href={`/settings/sites/${urlSlug}/members`}
            label="Members"
            onNavigate={onNavigate}
            testId={`${urlSlug}-members`}
            indent={1}
          />
          <NavItemBase
            href={`/settings/sites/${urlSlug}/locations`}
            label="Locations"
            onNavigate={onNavigate}
            testId={`${urlSlug}-locations`}
            indent={1}
          />
          <NavItemBase
            href={`/settings/sites/${urlSlug}/tags`}
            label="Tags"
            onNavigate={onNavigate}
            testId={`${urlSlug}-tags`}
            indent={1}
          />
        </CollapsableNavSection>
      ))}
    </>
  )
}
