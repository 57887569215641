import {IconButton} from "@hortis/ui/button"
import {DialogContent, DialogHeader, DialogTitle} from "@hortis/ui/dialog"
import {XClose} from "@hortis/ui/icons"
import {type RefObject} from "react"

interface RecordActivityDialogProps {
  setOpen: (open: boolean) => void
  recordHistory: Array<React.ReactNode> | null
  triggerRef?: RefObject<HTMLButtonElement>
}

export const RecordActivityDialog = ({
  setOpen,
  recordHistory,
  triggerRef,
}: RecordActivityDialogProps) => {
  return (
    <DialogContent
      data-cy="record-activity-dialog"
      className="max-w-[650px] !gap-0"
      triggerRef={triggerRef}
    >
      <DialogHeader>
        <DialogTitle className="flex justify-between border-b border-grey-200 pb-2 ">
          Activity
          <div className="flex gap-2">
            <IconButton
              ariaLabel={"close-dialog"}
              data-cy={"close-dialog-button"}
              variant="tertiaryGray"
              onClick={() => {
                setOpen(false)
              }}
              size={"xs"}
              icon={XClose}
            />
          </div>
        </DialogTitle>
      </DialogHeader>
      <div className="max-h-[350px] overflow-y-scroll py-6">
        {recordHistory?.map((activity, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={i}>{activity}</div>
        ))}
      </div>
    </DialogContent>
  )
}
