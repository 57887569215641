import type {TaxaFilter} from "generated/graphql"
import type {LocalRedListStatusFilter} from "./modal"

export const transformRedListStatusFilter = (
  filter: LocalRedListStatusFilter | undefined,
): TaxaFilter =>
  filter == null
    ? {}
    : {
        fullyValidated:
          filter.is != null && filter.is.length > 0 ? {eq: true} : undefined,
        sharedScientificName: {
          taxon: {globalRedListStatus: {in: filter.is, nin: filter.isNot}},
        },
      }
