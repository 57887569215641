import {IconButton} from "@hortis/ui/button"
import {DialogContent, DialogHeader, DialogTitle} from "@hortis/ui/dialog"
import {XClose} from "@hortis/ui/icons"
import {type RefObject} from "react"

interface RelatedRecordsDialogProps {
  setOpen: (open: boolean) => void
  children: React.ReactNode
  triggerRef?: RefObject<HTMLButtonElement>
}

export const RelatedRecordsDialog = ({
  setOpen,
  children,
  triggerRef,
}: RelatedRecordsDialogProps) => {
  return (
    <DialogContent
      data-cy="related-records-dialog"
      className="max-w-[512px] !gap-0 !p-0"
      triggerRef={triggerRef}
    >
      <DialogHeader className="p-4 !pb-0 sm:p-6 sm:pt-6">
        <DialogTitle className="flex justify-between border-b border-grey-200">
          Related records
          <div className="flex gap-2">
            <IconButton
              ariaLabel={"close-dialog"}
              data-cy={"close-dialog-button"}
              variant="tertiaryGray"
              size={"xs"}
              onClick={() => {
                setOpen(false)
              }}
              icon={XClose}
            />
          </div>
        </DialogTitle>
      </DialogHeader>
      <div className="max-h-[350px] overflow-y-scroll p-4 sm:p-6">
        {children}
      </div>
    </DialogContent>
  )
}
