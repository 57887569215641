import {useChildMatches, useNavigate, useSearch} from "@tanstack/react-router"
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import type {CoordinateMenuProps} from "src/components/map/material-clusters/coordinate-menu"
import {useSnackbarStore} from "src/components/snackbar-controller/snackbar-store"
import {onFailure} from "src/notification-snack-utils"
import {useMobile} from "src/utils/hooks/media-query"
import {match} from "ts-pattern"
import {
  useUpdateCollectionSiteMutation,
  useUpdatePlantMaterialMutation,
  type MaterialFieldsFragment,
} from "../../../generated/graphql"
import {useMaplibre} from "../../components/map/utils"
import {
  createMaterialNumber,
  parseMaterialNumber,
} from "../collection/components/plant-materials/material-number"
import type {MapMode, MapPositioningMaterial} from "./components/types"
import {useMapAttribution} from "./components/use-map-attribution"
import {useMapResize} from "./components/use-map-resize"
import type {UpdateGeography} from "./fetchers"
import {mapId} from "./utils"

const EDITING_ZOOM = 19

type State = {
  sidebarOpen: boolean
  filtersOpen: boolean
  newMaterialDrawerOpen: boolean
  mode: MapMode
  editingMaterial: MapPositioningMaterial | null
  coordinateMenuProps: CoordinateMenuProps | null
  newMaterialCoordinates: Pick<
    CoordinateMenuProps,
    "latitude" | "longitude"
  > | null
}

export const useMapRouteMatch = () => {
  const matched = useChildMatches()
  const route = matched[0]

  return useMemo(
    () =>
      match(route)
        .with(
          {routeId: "/_layout/sites/$siteSlug/map/material/$materialNumber"},
          (route) => {
            return {
              ...route,
              route: "material",
              number:
                "materialNumber" in route.params
                  ? parseMaterialNumber(route.params.materialNumber)
                  : undefined,
            } as const
          },
        )
        .otherwise(() => ({route: "map"}) as const),
    [route],
  )
}

export const useMapStore = ({
  updateGeography,
  refetchSite,
}: {
  updateGeography: UpdateGeography
  refetchSite: () => void
}) => {
  const {setSnack} = useSnackbarStore()
  const {[mapId]: map} = useMaplibre()
  const navigate = useNavigate({from: "/sites/$siteSlug/map"})
  const isMobile = useMobile()
  const matchedRoute = useMapRouteMatch()
  const [__, updatePlantMaterial] = useUpdatePlantMaterialMutation()
  const [_, updateSite] = useUpdateCollectionSiteMutation()
  const search = useSearch({
    from: "/_layout/sites/$siteSlug/map",
  })
  const [shownNoInitialView, setShownNoInitialView] = useState(false)
  const [state, setState] = useState<State>({
    sidebarOpen: true,
    filtersOpen: false,
    newMaterialDrawerOpen: false,
    mode: "view",
    editingMaterial: null,
    coordinateMenuProps: null,
    newMaterialCoordinates: null,
  })

  const collapseSidebar = useCallback(() => {
    setState((state) => ({...state, sidebarOpen: false}))
  }, [])

  const expandSidebar = useCallback(() => {
    setState((state) => ({...state, sidebarOpen: true}))
  }, [])

  const cancelEdit = useCallback(() => {
    setState((state) => ({...state, editingMaterial: null, mode: "view"}))
  }, [])

  const openFilters = useCallback(() => {
    setState((state) => ({...state, filtersOpen: true}))
  }, [])

  const closeFilters = useCallback(() => {
    setState((state) => ({...state, filtersOpen: false}))
  }, [])

  const openNewMaterial = useCallback(() => {
    setState((state) => ({
      ...state,
      newMaterialDrawerOpen: true,
      newMaterialCoordinates: state.coordinateMenuProps,
    }))
  }, [])

  const confirmNewMaterial = useCallback(
    async (newMaterial: MaterialFieldsFragment) => {
      const position = newMaterial.position
      const accession = newMaterial.accession
      if (
        updateGeography != null &&
        position != null &&
        accession?.scientificName != null
      ) {
        await updateGeography(
          {
            ...newMaterial,
            position,
            accession: {
              ...accession,
              scientificName: accession.scientificName,
            },
          },
          {appendToGeometry: true},
        )
        await navigate({
          to: "/sites/$siteSlug/map/material/$materialNumber",
          params: {
            materialNumber: createMaterialNumber(
              accession.accessionNumber,
              newMaterial.qualifier,
            ),
          },
        })
      }
      setState((state) => ({
        ...state,
        mode: "view",
        newMaterialDrawerOpen: false,
        coordinateMenuProps: null,
      }))
    },
    [updateGeography, navigate],
  )

  const cancelNewMaterial = useCallback(() => {
    setState((state) => ({
      ...state,
      newMaterialDrawerOpen: false,
      coordinateMenuProps: null,
    }))
  }, [])

  const requestEdit = useCallback(
    (material: MapPositioningMaterial) => {
      if (material.position == null) {
        map?.flyTo({
          zoom: EDITING_ZOOM,
          padding: {left: 0, top: 0, right: 0, bottom: 0},
        })
      } else {
        map?.flyTo({
          center: {
            lat: material.position.latitude,
            lng: material.position.longitude,
          },
          zoom: EDITING_ZOOM,
          padding: {left: 0, top: 0, right: 0, bottom: 0},
        })
      }

      setState((state) => ({
        ...state,
        mode: "editMaterial",
        editingMaterial: material,
      }))
    },
    [map],
  )

  const confirmEdit = useCallback(async () => {
    if (map && state.editingMaterial != null) {
      const newCoords = map.getCenter()

      const {data} = await updatePlantMaterial({
        materialId: state.editingMaterial.id,
        updates: {
          position: {latitude: newCoords.lat, longitude: newCoords.lng},
        },
      })

      if (data?.updatePlantMaterial?.__typename === "PlantMaterial") {
        const material = data.updatePlantMaterial
        const {accession, position} = material

        if (
          accession?.scientificName != null &&
          position != null &&
          updateGeography !== undefined
        ) {
          await updateGeography({
            ...material,
            accession: {
              ...accession,
              scientificName: accession.scientificName,
            },
            position,
          })
        }
      }
      setState((state) => ({
        ...state,
        editingMaterial: null,
        mode: "view",
      }))
    }
  }, [map, state.editingMaterial, updatePlantMaterial, updateGeography])

  const closeCoordinateMenu = useCallback(() => {
    setState((state) => ({...state, coordinateMenuProps: null}))
  }, [])
  const openCoordinateMenu = useCallback((props: CoordinateMenuProps) => {
    setState((state) => ({...state, coordinateMenuProps: props}))
  }, [])

  const setInitialView = useCallback(() => {
    setState((state) => ({...state, mode: "setInitialView"}))
  }, [])
  const confirmInitialView = useCallback(
    async (siteId: string) => {
      if (map == null) {
        onFailure(setSnack)(
          new Error("Failed to set initial view, map not loaded"),
        )
        return
      }
      const center = map.getCenter()
      const zoom = map.getZoom()
      await updateSite({
        id: siteId,
        updates: {
          mapCenterLongitude: center.lng,
          mapCenterLatitude: center.lat,
          mapZoomLevel: zoom,
        },
      })
      refetchSite()
      setState((state) => ({...state, mode: "view"}))
    },
    [map, setSnack, updateSite, refetchSite],
  )
  const openMissingInitialView = useCallback(() => {
    setShownNoInitialView(true)
    if (!shownNoInitialView) {
      setState((state) => ({...state, mode: "noInitialView"}))
    }
  }, [shownNoInitialView])

  useEffect(() => {
    if (isMobile) {
      setState((state) => ({
        ...state,
        sidebarOpen: matchedRoute.route === "material",
      }))
    }
  }, [isMobile, matchedRoute])

  useMapAttribution()
  useMapResize(map)

  return {
    ...search,
    ...state,
    matchedRoute,
    closeCoordinateMenu,
    openCoordinateMenu,
    collapseSidebar,
    expandSidebar,
    cancelEdit,
    openFilters,
    closeFilters,
    confirmNewMaterial,
    cancelNewMaterial,
    openNewMaterial,
    requestEdit,
    confirmEdit,
    setInitialView,
    confirmInitialView,
    openMissingInitialView,
  }
}

export const MapContext = createContext<
  ReturnType<typeof useMapStore> | undefined
>(undefined)

export const useMapContext = () => {
  const context = useContext(MapContext)
  if (context === undefined) {
    throw new Error("useMapContext must be used within a MapContext")
  }
  return context
}
