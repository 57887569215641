import type {CardProps as CoreCardProps} from "@mui/material/Card"
import CoreCard from "@mui/material/Card"
import {assignSxProps, fromSxFn} from "src/utils/sx"
import {applyMui} from "src/utils/apply-mui"
import {withTestId} from "src/utils/with-test-id"
import {SlChevronRightIcon} from "../icons/streamline/chevron-right"
import {Box} from "../box"

const cardSx = fromSxFn((theme) => ({
  display: "flex",
  alignItems: "center",
  borderRadius: theme.shape.borderRadiusString,
  border: `1px solid ${theme.palette.gray[200]}`,
  padding: `${theme.spacing(2.5)} ${theme.spacing(4)}`,
  transition: "background-color 200ms ease, border 200ms ease",
  boxShadow: "none",

  "&.interactive:hover": {
    border: `1px solid ${theme.palette.gray[300]}`,
    cursor: "pointer",
  },
  "&.interactive .chevron": {
    transition: "transform 200ms ease",
  },
  "&.interactive:hover .chevron": {
    transform: `translateX(${theme.spacing(1)})`,
  },
}))

export type CardProps = CoreCardProps & {href?: string; interactive?: boolean}

export const CardLink = ({
  href,
  sx,
  children,
  className,
  interactive,
  ...props
}: CardProps) => (
  <a
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
    href={href as any}
    className={`${
      interactive === true || href !== undefined ? "interactive " : ""
    }${className ?? ""}`}
  >
    <Box sx={assignSxProps(cardSx, sx)} {...props}>
      <div style={{flex: 1}}>{children}</div>
      {interactive === true || href !== undefined ? (
        <SlChevronRightIcon className="chevron" />
      ) : null}
    </Box>
  </a>
)

export const Card = applyMui(withTestId, CoreCard)
