import {twMerge} from "tailwind-merge"
import {Camera3, CameraPlus} from "../icons"
import {colors} from "../../theme/colors"
import {IconButton} from "../button"

interface PhotoPlaceholderProps {
  size: "xs" | "sm" | "md" | "lg"
  className?: string
  handleUploadImage?: () => void
}

export const PhotoPlaceholder = ({
  size,
  className,
  handleUploadImage,
}: PhotoPlaceholderProps) => {
  return (
    <div
      className={twMerge(
        "border-grey-100 flex flex-shrink-0 items-center justify-center border bg-[#F9FAFB]",
        size === "xs" && "h-10 w-10 rounded",
        size === "sm" && "h-16 w-16 rounded",
        size === "md" && "h-20 w-20 rounded",
        size === "lg" && "h-[172px] w-[172px] rounded-lg",
        className,
        handleUploadImage && "relative",
      )}
    >
      <div
        className={twMerge(
          "flex items-center justify-center rounded-[99px]",
          size === "lg" ? "bg-grey-100 h-12 w-12" : "h-10 w-10",
        )}
      >
        <Camera3
          size={size === "lg" ? "24px" : "20px"}
          color={colors.grey[300]}
        />
      </div>
      {handleUploadImage && (
        <IconButton
          onClick={handleUploadImage}
          ariaLabel={"upload-image"}
          icon={CameraPlus}
          size={"xs"}
          variant="secondaryGray"
          className="absolute bottom-3 right-3"
        />
      )}
    </div>
  )
}
