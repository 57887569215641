import * as R from "ramda"
import {followUpOptions} from "src/components/modals/make-observation/follow-up-options"
import {conditionLabelMap} from "src/utils/label-maps/condition"
import {materialSexLabelMap} from "src/utils/label-maps/material-sex"
import {twMerge} from "tailwind-merge"
import type {MaterialHistoryFieldsFragment} from "../../../../../generated/graphql"
import {PlantMaterialStatusToLabel} from "../../../../components/status-chip"
import type {DateStringFormatter} from "../../../../utils/hooks/language"
import type {ActivityItemProps} from "./activity-item"
import {
  BoldTypography,
  getCoreProps,
  getUserDisplayName,
  parseHistory,
  Typography,
} from "./utils"

const parsers: Array<
  (
    history: MaterialHistoryFieldsFragment,
    formatDateString: DateStringFormatter,
  ) => ActivityItemProps | null
> = [
  (history) =>
    history.madePublic != null && history.madePublic
      ? {
          ...getCoreProps(history),
          message: (
            <Typography>
              <BoldTypography>{getUserDisplayName(history)}</BoldTypography>{" "}
              made the material public
            </Typography>
          ),
        }
      : null,
  (history) =>
    history.madePrivate != null && history.madePrivate
      ? {
          ...getCoreProps(history),
          message: (
            <Typography>
              <BoldTypography>{getUserDisplayName(history)}</BoldTypography>{" "}
              made the material private
            </Typography>
          ),
        }
      : null,
  (history) =>
    history.wasArchived != null && history.wasArchived
      ? {
          ...getCoreProps(history),
          message: (
            <Typography>
              <BoldTypography>{getUserDisplayName(history)}</BoldTypography>{" "}
              archived the record
            </Typography>
          ),
        }
      : null,
  (history) =>
    history.wasRestored != null && history.wasRestored
      ? {
          ...getCoreProps(history),
          message: (
            <Typography>
              <BoldTypography>{getUserDisplayName(history)}</BoldTypography>{" "}
              restored the record
            </Typography>
          ),
        }
      : null,
  (history) =>
    history.fromStatus == null && history.toStatus == null
      ? null
      : {
          ...getCoreProps(history),
          message: (
            <Typography>
              <BoldTypography>{getUserDisplayName(history)}</BoldTypography>{" "}
              changed the status from{" "}
              <BoldTypography>
                {history.fromStatus == null
                  ? "Empty"
                  : PlantMaterialStatusToLabel[history.fromStatus]}
              </BoldTypography>{" "}
              to{" "}
              <BoldTypography>
                {" "}
                {history.toStatus == null
                  ? "Empty"
                  : PlantMaterialStatusToLabel[history.toStatus]}
              </BoldTypography>
            </Typography>
          ),
        },
  (history) =>
    history.fromMaterialGroup == null && history.toMaterialGroup == null
      ? null
      : {
          ...getCoreProps(history),
          message: (
            <Typography>
              <BoldTypography>{getUserDisplayName(history)}</BoldTypography>{" "}
              changed the material type from{" "}
              <BoldTypography>
                {history.fromMaterialGroup == null
                  ? "Empty"
                  : history.fromMaterialGroup}
              </BoldTypography>{" "}
              to{" "}
              <BoldTypography>
                {" "}
                {history.toMaterialGroup == null
                  ? "Empty"
                  : history.toMaterialGroup}
              </BoldTypography>
            </Typography>
          ),
        },
  (history) =>
    history.fromCollectionSiteLocation == null &&
    history.toCollectionSiteLocation == null
      ? null
      : {
          ...getCoreProps(history),
          message: (
            <Typography>
              <BoldTypography>{getUserDisplayName(history)}</BoldTypography>{" "}
              changed the location from{" "}
              <BoldTypography>
                {history.fromCollectionSiteLocation == null ? (
                  "Empty"
                ) : (
                  <span
                    className={twMerge(
                      history.fromCollectionSiteLocation.archived &&
                        "line-through",
                    )}
                  >
                    {history.fromCollectionSiteLocation.code}
                  </span>
                )}
              </BoldTypography>{" "}
              to{" "}
              <BoldTypography>
                {" "}
                {history.toCollectionSiteLocation == null ? (
                  "Empty"
                ) : (
                  <span
                    className={twMerge(
                      history.toCollectionSiteLocation.archived &&
                        "line-through",
                    )}
                  >
                    {history.toCollectionSiteLocation.code}
                  </span>
                )}
              </BoldTypography>
            </Typography>
          ),
        },
  (history) =>
    history.fromQuantity == null && history.toQuantity == null
      ? null
      : {
          ...getCoreProps(history),
          message: (
            <Typography>
              <BoldTypography>{getUserDisplayName(history)}</BoldTypography>{" "}
              changed the quantity from{" "}
              <BoldTypography>
                {history.fromQuantity == null ? "Empty" : history.fromQuantity}
              </BoldTypography>{" "}
              to{" "}
              <BoldTypography>
                {" "}
                {history.toQuantity == null ? "Empty" : history.toQuantity}
              </BoldTypography>
            </Typography>
          ),
        },
  (history) =>
    history.fromMassPlanting == null && history.toMassPlanting == null
      ? null
      : {
          ...getCoreProps(history),
          message: (
            <Typography>
              <BoldTypography>{getUserDisplayName(history)}</BoldTypography>{" "}
              {history.toMassPlanting === true
                ? "set mass planting"
                : "unset mass planting"}
            </Typography>
          ),
        },
  (history) =>
    history.fromWeight == null && history.toWeight == null
      ? null
      : {
          ...getCoreProps(history),
          message: (
            <Typography>
              <BoldTypography>{getUserDisplayName(history)}</BoldTypography>{" "}
              changed the weight from{" "}
              <BoldTypography>
                {history.fromWeight == null ? "Empty" : history.fromWeight}
              </BoldTypography>{" "}
              to{" "}
              <BoldTypography>
                {" "}
                {history.toWeight == null ? "Empty" : history.toWeight}
              </BoldTypography>
            </Typography>
          ),
        },
  (history, formatDateString) =>
    history.fromFirstPresent == null && history.toFirstPresent == null
      ? null
      : {
          ...getCoreProps(history),
          message: (
            <Typography>
              <BoldTypography>{getUserDisplayName(history)}</BoldTypography>{" "}
              changed the date added from{" "}
              <BoldTypography>
                {history.fromFirstPresent == null
                  ? "Empty"
                  : formatDateString(history.fromFirstPresent)}
              </BoldTypography>{" "}
              to{" "}
              <BoldTypography>
                {" "}
                {history.toFirstPresent == null
                  ? "Empty"
                  : formatDateString(history.toFirstPresent)}
              </BoldTypography>
            </Typography>
          ),
        },
  (history, formatDateString) =>
    history.fromFirstAbsent == null && history.toFirstAbsent == null
      ? null
      : {
          ...getCoreProps(history),
          message: (
            <Typography>
              <BoldTypography>{getUserDisplayName(history)}</BoldTypography>{" "}
              changed the date removed from{" "}
              <BoldTypography>
                {history.fromFirstAbsent == null
                  ? "Empty"
                  : formatDateString(history.fromFirstAbsent)}
              </BoldTypography>{" "}
              to{" "}
              <BoldTypography>
                {" "}
                {history.toFirstAbsent == null
                  ? "Empty"
                  : formatDateString(history.toFirstAbsent)}
              </BoldTypography>
            </Typography>
          ),
        },
  (history) =>
    history.fromPosition == null && history.toPosition == null
      ? null
      : {
          ...getCoreProps(history),
          message: (
            <Typography>
              <BoldTypography>{getUserDisplayName(history)}</BoldTypography>{" "}
              changed the coordinates
            </Typography>
          ),
        },
  (history) =>
    history.fromSex == null && history.toSex == null
      ? null
      : {
          ...getCoreProps(history),
          message: (
            <Typography>
              <BoldTypography>{getUserDisplayName(history)}</BoldTypography>{" "}
              changed the sex from{" "}
              <BoldTypography>
                {history.fromSex == null
                  ? "Empty"
                  : materialSexLabelMap[history.fromSex]}
              </BoldTypography>{" "}
              to{" "}
              <BoldTypography>
                {" "}
                {history.toSex == null
                  ? "Empty"
                  : materialSexLabelMap[history.toSex]}
              </BoldTypography>
            </Typography>
          ),
        },
  (history) =>
    history.fromTagNumber == null && history.toTagNumber == null
      ? null
      : {
          ...getCoreProps(history),
          message: (
            <Typography>
              <BoldTypography>{getUserDisplayName(history)}</BoldTypography>{" "}
              changed the tag number from{" "}
              <BoldTypography>
                {history.fromTagNumber == null
                  ? "Empty"
                  : history.fromTagNumber}
              </BoldTypography>{" "}
              to{" "}
              <BoldTypography>
                {" "}
                {history.toTagNumber == null ? "Empty" : history.toTagNumber}
              </BoldTypography>
            </Typography>
          ),
        },
  (history) =>
    history.fromNotes == null && history.toNotes == null
      ? null
      : {
          ...getCoreProps(history),
          message: (
            <Typography>
              <BoldTypography>{getUserDisplayName(history)}</BoldTypography>{" "}
              changed the notes
            </Typography>
          ),
        },
  (history) =>
    history.fromFollowUp == null && history.toFollowUp == null
      ? null
      : {
          ...getCoreProps(history),
          message: (
            <Typography>
              <BoldTypography>{getUserDisplayName(history)}</BoldTypography>{" "}
              changed the follow-up period from{" "}
              <BoldTypography>
                {history.fromFollowUp == null
                  ? "None"
                  : followUpOptions.find(({value}) =>
                      R.equals(
                        value,
                        R.pick(["period", "count"], history.fromFollowUp),
                      ),
                    )?.label}
              </BoldTypography>{" "}
              to{" "}
              <BoldTypography>
                {history.toFollowUp == null
                  ? "None"
                  : followUpOptions.find(({value}) =>
                      R.equals(
                        value,
                        R.pick(["period", "count"], history.toFollowUp),
                      ),
                    )?.label ?? "None"}
              </BoldTypography>
            </Typography>
          ),
        },
  (history, formatDateString) =>
    history.fromLastObserved == null && history.toLastObserved == null
      ? null
      : {
          ...getCoreProps(history),
          message: (
            <Typography>
              <BoldTypography>{getUserDisplayName(history)}</BoldTypography>{" "}
              changed the last observed date from{" "}
              <BoldTypography>
                {history.fromLastObserved == null
                  ? "Empty"
                  : formatDateString(history.fromLastObserved)}
              </BoldTypography>{" "}
              to{" "}
              <BoldTypography>
                {" "}
                {history.toLastObserved == null
                  ? "Empty"
                  : formatDateString(history.toLastObserved)}
              </BoldTypography>
            </Typography>
          ),
        },
  (history) =>
    history.fromCondition == null && history.toCondition == null
      ? null
      : {
          ...getCoreProps(history),
          message: (
            <Typography>
              <BoldTypography>{getUserDisplayName(history)}</BoldTypography>{" "}
              changed the condition from{" "}
              <BoldTypography>
                {history.fromCondition == null
                  ? "Empty"
                  : conditionLabelMap[history.fromCondition]}
              </BoldTypography>{" "}
              to{" "}
              <BoldTypography>
                {" "}
                {history.toCondition == null
                  ? "Empty"
                  : conditionLabelMap[history.toCondition]}
              </BoldTypography>
            </Typography>
          ),
        },
]

export const parseMaterialHistory = parseHistory(parsers)
