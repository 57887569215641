import {Alert, AlertActions, AlertContent, AlertText} from "@hortis/ui/alert"
import {Button} from "@hortis/ui/button"
import {Dialog, DialogTrigger} from "@hortis/ui/dialog"
import {useMediaQuery} from "@hortis/ui/hooks/media-query"
import {Trash3} from "@hortis/ui/icons"
import {Link} from "@tanstack/react-router"
import {useCallback, useState, type ReactElement} from "react"
import {RestoreMaterialDialogContent} from "src/components/dialog/variants/restore-material"
import MaterialRecordPageHeader from "src/features/material-page/header/material-record-page-header"
import {MaterialContentsTable} from "src/features/material-page/material-contents-table"
import {MaterialRecordAccession} from "src/features/material-page/sections/material-record-accession"
import {MaterialRecordActivity} from "src/features/material-page/sections/material-record-activity"
import {MaterialRecordDetails} from "src/features/material-page/sections/material-record-details"
import {MaterialRecordObservations} from "src/features/material-page/sections/material-record-observations"
import {MaterialRecordPhotos} from "src/features/material-page/sections/material-record-photos"
import {MaterialRecordRelatedRecords} from "src/features/material-page/sections/material-record-related-records"
import {MaterialRecordTaxon} from "src/features/material-page/sections/material-record-taxon"
import {useAccessRole} from "src/features/permissions/use-access-role"
import {MaterialRecordLocation} from "src/features/material-page/sections/material-record-location"
import {RecordTagSection} from "src/features/records/components/record-section/variants/record-tag-section"
import {RecordNotesSection} from "src/features/records/components/record-section/variants/record-notes-section"
import {
  MaterialGroup,
  type MaterialFieldsFragment,
} from "../../../../../../generated/graphql"
import {
  MaterialEditStageCallback,
  type MaterialEditStage,
} from "../edit-drawer/types"

export interface MaterialContentsProps {
  requestEdit: (editStage?: MaterialEditStage) => void
  material: MaterialFieldsFragment
  refetchMaterial: () => void
}

export const MaterialTrashRecordAlert = ({
  materialId,
  canEdit,
}: {
  materialId: string
  canEdit: boolean
}) => {
  const [open, setOpen] = useState(false)

  return (
    <Alert color="warning" data-cy="trash-record-alert">
      <Trash3 />
      <AlertContent>
        <AlertText>This record is in the trash</AlertText>
        <AlertActions>
          <Button variant="linkgray">
            <Link
              to="/sites/$siteSlug/trash"
              from="/sites/$siteSlug"
              search={{tab: "materials"}}
            >
              View trash
            </Link>
          </Button>
          {canEdit && (
            <Dialog open={open} onOpenChange={setOpen}>
              <DialogTrigger asChild>
                <Button testId="restore-record-button" variant="linkgray">
                  Restore
                </Button>
              </DialogTrigger>
              <RestoreMaterialDialogContent
                onSuccess={() => {
                  setOpen(false)
                }}
                id={materialId}
              />
            </Dialog>
          )}
        </AlertActions>
      </AlertContent>
    </Alert>
  )
}
export const MaterialContents = ({
  requestEdit,
  material,
  refetchMaterial,
}: MaterialContentsProps): ReactElement => {
  const roleUtils = useAccessRole()
  const isXlScreen = useMediaQuery("xl")
  const [uploadPhotoDialogOpen, setUploadPhotoDialogOpen] = useState(false)

  const openUploadPhotoDialog = useCallback(() => {
    setUploadPhotoDialogOpen(true)
  }, [])

  const canEdit = roleUtils.canEdit && !material.archived

  return (
    <div className="flex flex-col gap-6 px-4 lg:gap-8 lg:px-8">
      {material.archived && (
        <MaterialTrashRecordAlert
          materialId={material.id}
          canEdit={roleUtils.canEdit}
        />
      )}
      <MaterialRecordPageHeader
        refetchMaterial={refetchMaterial}
        material={material}
        requestEdit={requestEdit}
        canEdit={canEdit}
        openUploadPhotoDialog={openUploadPhotoDialog}
      />

      <div className="grid lg:gap-12 xl:grid-cols-[188px_auto]">
        {isXlScreen && (
          <div className="sticky top-3 self-start">
            <MaterialContentsTable
              isSeed={material.materialGroup === MaterialGroup.Seed}
              isOverdue={
                material.daysOverdueFollowUp != null &&
                material.daysOverdueFollowUp > 0
              }
            />
          </div>
        )}
        <div className="flex flex-col gap-6 [&_.record-section-content-group]:sm:grid-cols-2">
          <MaterialRecordDetails
            material={material}
            requestEdit={requestEdit}
            canEdit={canEdit}
          />
          <MaterialRecordLocation
            material={material}
            requestEdit={requestEdit}
            canEdit={canEdit}
          />
          <RecordTagSection
            tags={material.tagsConnection?.nodes}
            requestEdit={MaterialEditStageCallback.useEditDetails(requestEdit)}
            canEdit={canEdit}
          />
          <RecordNotesSection
            notes={material.notes}
            requestEdit={MaterialEditStageCallback.useEditDetails(requestEdit)}
            canEdit={canEdit}
          />
          <div className="grid w-full gap-6 lg:grid-cols-2">
            <MaterialRecordAccession material={material} />
            <MaterialRecordTaxon material={material} />
          </div>
          <MaterialRecordPhotos
            canEdit={canEdit}
            material={material}
            refetchMaterial={refetchMaterial}
            uploadDialogOpen={uploadPhotoDialogOpen}
            setUploadDialogOpen={setUploadPhotoDialogOpen}
          />
          {material.materialGroup === MaterialGroup.Plant && (
            <MaterialRecordObservations
              material={material}
              canEdit={canEdit}
              refetchMaterial={refetchMaterial}
            />
          )}
          <MaterialRecordActivity material={material} />
          <MaterialRecordRelatedRecords material={material} />
        </div>
      </div>
    </div>
  )
}
