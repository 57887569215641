import * as RA from "fp-ts/ReadonlyArray"
import * as Record from "fp-ts/Record"
import * as Semi from "fp-ts/Semigroup"
import type {Dispatch, ReactElement, SyntheticEvent} from "react"
import {useCallback} from "react"
import {useNavigate} from "@tanstack/react-router"
import {Checkbox} from "@hortis/ui/checkbox"
import type {MaterialFilterType} from "src/features/filters/plant-materials"
import type {FilterCounts} from "src/features/filters/plant-materials/count-filters"
import type {PlantMaterialFilterAction} from "src/features/filters/plant-materials/filter-reducer"
import {MaterialFilterModals} from "src/features/filters/plant-materials/modals/filter-modals"
import type {
  MaterialsListFieldsFragment,
  PlantMaterialsOrderByInput,
  PlantMaterialsSearchTerm,
} from "../../../../../generated/graphql"
import {
  PlantMaterialsColumn,
  PlantMaterialsSearchColumn,
} from "../../../../../generated/graphql"
import {Box} from "../../../../components/box"
import {TableContainer} from "../../../../components/table"
import {useCollectionSiteHrefs} from "../../../../utils/hooks/hrefs"
import {assignSxProps} from "../../../../utils/sx"
import {SkeletonBody, materialsBodyColumnConfig} from "../skeleton-table"
import {
  materialsTableResponsiveMixin,
  rowSelectionChecker,
  tableBodyMixin,
  tableContainerMixin,
  tableHeaderRowMixin,
  tableMixin,
} from "../table-styling"
import {ColumnHeaderMenu} from "./column-header-menu"
import {EmptyList} from "./empty-list"
import {createMaterialNumber} from "./material-number"
import {MaterialRow} from "./material-row"
import {useOpenFilterModal} from "./use-open-filter-modal"

interface MaterialsTableProps {
  plantMaterials?: ReadonlyArray<MaterialsListFieldsFragment>
  headerStickyPosition?: string
  page: number
  updateOrderBy: (orderBy: PlantMaterialsOrderByInput | undefined) => void
  orderBy: PlantMaterialsOrderByInput | undefined
  dispatchFilters: Dispatch<PlantMaterialFilterAction>
  focusSearchBar: (field: PlantMaterialsSearchColumn) => void
  resetFilters: (filterType?: MaterialFilterType) => void
  filterCounts: FilterCounts
  searchTerm: PlantMaterialsSearchTerm | undefined
  handleClear: (column: PlantMaterialsSearchColumn) => void
  handleRowSelection: (
    _: SyntheticEvent,
    plantMaterialId: MaterialsListFieldsFragment["id"],
  ) => void
  handleSelectAll: (e: SyntheticEvent) => void
  recordCount?: number
  selectedMaterials: Record<string, boolean>
  currentlySelectedRowIds: ReadonlyArray<string>
  isSelectMode: boolean
}

export const fromBooleanEntries = Record.fromFoldableMap(
  Semi.last<boolean>(),
  RA.Foldable,
)

const skeletonBody = (
  <SkeletonBody
    rows={15}
    columns={11}
    withCheckbox
    columnConfig={materialsBodyColumnConfig}
  />
)

const tableStyle = assignSxProps(tableMixin, materialsTableResponsiveMixin)

export const MaterialsTable = ({
  page,
  plantMaterials,
  orderBy,
  updateOrderBy,
  headerStickyPosition,
  dispatchFilters,
  focusSearchBar,
  resetFilters,
  filterCounts,
  searchTerm,
  handleClear,
  selectedMaterials,
  handleRowSelection,
  handleSelectAll,
  currentlySelectedRowIds: currentlySelectedRows,
  recordCount,
  isSelectMode,
}: MaterialsTableProps): ReactElement => {
  const navigate = useNavigate({from: "/sites/$siteSlug/collection"})

  const collectionSiteHrefs = useCollectionSiteHrefs()
  const materialsHref = collectionSiteHrefs?.plantMaterials

  const handleRowClick = useCallback(
    (_: SyntheticEvent, plantMaterial: MaterialsListFieldsFragment) => {
      // TODO: error handling if materials href isn't present? can we use context to ensure
      // this component is only ever mounted in a context where accessions href does exist?
      if (materialsHref !== undefined) {
        void navigate({
          to: "/sites/$siteSlug/materials/$materialNumber",
          params: {
            materialNumber: createMaterialNumber(
              plantMaterial.accession?.accessionNumber ?? "",
              plantMaterial.qualifier,
            ),
          },
        })
      }
    },
    [navigate, materialsHref],
  )

  const clearSearch = useCallback(() => {
    if (searchTerm != null) {
      handleClear(searchTerm.field)
    }
    resetFilters()
  }, [searchTerm, handleClear, resetFilters])

  const isRowSelected = rowSelectionChecker(
    plantMaterials ?? [],
    selectedMaterials,
  )

  const {openFilter, closeFilter, openFilterModal, anchorRef} =
    useOpenFilterModal<MaterialFilterType>()

  const columnHeaderMenuProps = {
    orderBy: orderBy,
    updateOrderBy: updateOrderBy,
    focusSearchBar: focusSearchBar,
    resetFilters: resetFilters,
    filterCounts: filterCounts,
    openFilter: openFilter,
  }

  const allSelected = recordCount === currentlySelectedRows.length
  const isIndeterminate = currentlySelectedRows.length > 0 && !allSelected

  /*
   * When adding or removing columns be sure to reflect changes in
   * the tableResponsiveMixin, especially gridTemplateColumns if
   * items are misaligned
   *
   * Note: 'display: contents' currently has a bug on Safari, meaning
   * the ARIA role is not picked up properly. Keep tabs on this at:
   * https://caniuse.com/css-display-contents
   */
  return (
    <>
      <MaterialFilterModals
        openFilter={openFilterModal}
        dispatchFilters={dispatchFilters}
        handleFilterModalClose={closeFilter}
        filterModalAnchorEl={anchorRef}
      />
      <TableContainer sx={tableContainerMixin}>
        <Box role="table" sx={tableStyle}>
          <div style={{display: "contents"}}>
            <Box
              role="row"
              sx={tableHeaderRowMixin(headerStickyPosition)}
              testId="materials-column-header-row"
            >
              <div role="columnheader" className="checkbox-padding">
                <Checkbox
                  indeterminate={isIndeterminate}
                  checked={allSelected}
                  onChange={handleSelectAll}
                  ariaLabel="select all plant materials"
                />
              </div>
              <div role="columnheader" className="material-number">
                <div className="gap-2! flex items-center">
                  Number
                  <ColumnHeaderMenu
                    {...columnHeaderMenuProps}
                    sortColumn={PlantMaterialsColumn.AccessionNum}
                    sortType="NUMERIC"
                    searchColumn={PlantMaterialsSearchColumn.MaterialNumber}
                  />
                </div>
              </div>
              <div role="columnheader" className="scientific-name">
                <div className="gap-2! flex items-center">
                  Scientific name
                  <ColumnHeaderMenu
                    {...columnHeaderMenuProps}
                    sortColumn={PlantMaterialsColumn.ScientificName}
                    sortType="ALPHABETIC"
                    searchColumn={PlantMaterialsSearchColumn.ScientificName}
                  />
                </div>
              </div>
              <div
                role="columnheader"
                aria-label="Material group"
                className="material-group"
              />
              <div
                role="columnheader"
                aria-label="Provenance category code"
                className="provenance"
              />
              <div role="columnheader" className="status">
                <div className="gap-2! flex items-center">
                  Status
                  <ColumnHeaderMenu
                    {...columnHeaderMenuProps}
                    sortType="ALPHABETIC"
                    sortColumn={PlantMaterialsColumn.Status}
                    filterType="status"
                    filterOpen={openFilterModal === "status"}
                  />
                </div>
              </div>
              <div role="columnheader" className="location">
                <div className="gap-2! flex items-center">
                  Location
                  <ColumnHeaderMenu
                    {...columnHeaderMenuProps}
                    sortType="ALPHABETIC"
                    sortColumn={PlantMaterialsColumn.LocationCode}
                    filterType="location"
                    filterOpen={openFilterModal === "location"}
                  />
                </div>
              </div>
              <div role="columnheader" className="quantity">
                <div className="gap-2! flex items-center">
                  Quantity
                  <ColumnHeaderMenu
                    {...columnHeaderMenuProps}
                    sortType="NUMERIC"
                    sortColumn={PlantMaterialsColumn.Quantity}
                  />
                </div>
              </div>
              <div role="columnheader" className="donor-supplier">
                <div className="gap-2! flex items-center">
                  Donor / supplier
                  <ColumnHeaderMenu
                    {...columnHeaderMenuProps}
                    sortType="ALPHABETIC"
                    sortColumn={PlantMaterialsColumn.DonorSupplier}
                  />
                </div>
              </div>
              <div role="columnheader" className="date-added">
                <div className="gap-2! flex items-center">
                  Date added
                  <ColumnHeaderMenu
                    {...columnHeaderMenuProps}
                    sortType="DATE"
                    sortColumn={PlantMaterialsColumn.DateAdded}
                  />
                </div>
              </div>
              <div role="columnheader" />
            </Box>
          </div>
          <Box sx={tableBodyMixin}>
            {plantMaterials === undefined
              ? skeletonBody
              : plantMaterials.map((plantMaterial, idx) => {
                  const prevRowSelected = isRowSelected(idx - 1)
                  const nextRowSelected = isRowSelected(idx + 1)
                  const rowSelected = isRowSelected(idx)
                  return (
                    <MaterialRow
                      testId={`table-row-page-${page}`}
                      key={`${plantMaterial.id}${String(
                        prevRowSelected,
                      )}${String(nextRowSelected)}${String(rowSelected)}`}
                      plantMaterial={plantMaterial}
                      prevRowSelected={isRowSelected(idx - 1)}
                      nextRowSelected={isRowSelected(idx + 1)}
                      rowSelected={isRowSelected(idx)}
                      handleRowSelection={handleRowSelection}
                      handleRowClick={handleRowClick}
                      isSelectMode={isSelectMode}
                    />
                  )
                })}
          </Box>
        </Box>
      </TableContainer>
      <EmptyList
        type="material"
        list={plantMaterials}
        searchTerm={searchTerm}
        clearSearch={clearSearch}
        filtersActive={
          filterCounts.total - filterCounts.excludeAbsentMaterial > 0
        }
      />
    </>
  )
}
