import {zodResolver} from "@hookform/resolvers/zod"
import {useParams, useRouteContext} from "@tanstack/react-router"
import * as A from "fp-ts/Array"
import {
  type LocationFieldsFragment,
  useGetLocationsQuery,
} from "generated/graphql"
import {useMemo} from "react"
import {FormProvider} from "react-hook-form"
import {FormAutocomplete} from "src/components/autocomplete"
import {CollapsableRadioButton, RadioGroup} from "src/components/radio"
import {locationOptionZod} from "src/features/create-accession/components/add-plant-material-form/schema"
import {FilterTitle} from "src/features/filters/components/modal/filter-title"
import {match} from "ts-pattern"
import {z} from "zod"
import {createFilterOptions} from "@mui/material/Autocomplete"
import {
  getLocationOptionLabel,
  isOptionEqual,
  renderLocationAutoCompleteItem,
} from "../../../locations/utils"
import type {FilterModalProps} from "../../components/modal/filter-modal"
import {FilterModal} from "../../components/modal/filter-modal"
import {FilterModalButtons} from "../../components/modal/modal-buttons"
import {FilterModalError} from "../../components/modal/modal-error"
import {useFilterForm} from "../../components/modal/use-filter-form"
import {determineInitialOperation} from "../modals/determine-initial-operation"
import type {FilterFormProps} from "../modals/types"
import {useDispatchFilter} from "../modals/use-dispatch-filter"
import type {LocalMaterialFilter} from "../types"

export const filterOptions = createFilterOptions({
  stringify: (option: LocationFieldsFragment) =>
    option.name + (option.description ?? ""),
})

const createDefaultValues = (
  initialValue?: NonNullable<LocalMaterialFilter["location"]>,
) => {
  const defaultValues = {
    is: initialValue?.is == null ? [] : [...initialValue.is],
    isNot: initialValue?.isNot == null ? [] : [...initialValue.isNot],
    contains: initialValue?.contains == null ? [] : [...initialValue.contains],
  }
  return {
    defaultValues,
    initialOperation: determineInitialOperation(defaultValues, "is"),
  }
}

const resolver = zodResolver(
  z.object({
    is: z.array(locationOptionZod),
    isNot: z.array(locationOptionZod),
    contains: z.array(z.string()),
  }),
)

export const LocationFilterForm = ({
  dispatchFilters,
  initialValue,
  onClose,
}: FilterFormProps) => {
  const {error, setError, operation, handleRadioChange, formMethods} =
    useFilterForm({
      resolver,
      ...createDefaultValues(initialValue?.location ?? undefined),
    })
  const {addModifyFilter, deleteFilter} = useDispatchFilter({
    dispatchFilters,
    id: initialValue?.id,
    onClose,
  })

  const {subdomain} = useRouteContext({from: "/_layout/sites/$siteSlug"})
  const {siteSlug} = useParams({from: "/_layout/sites/$siteSlug"})
  const [{data}] = useGetLocationsQuery({
    variables: {
      subdomain,
      siteSlug,
    },
  })
  const collectionSiteLocations = data?.org?.site?.locationsConnection?.nodes

  const onSubmit = useMemo(
    () =>
      formMethods.handleSubmit((values) => {
        match(operation)
          .with("is", () => {
            if (A.isNonEmpty(values.is)) {
              addModifyFilter({location: {is: values.is}})
              onClose()
            } else {
              setError("Select a location to continue")
            }
          })
          .with("isNot", () => {
            if (A.isNonEmpty(values.isNot)) {
              addModifyFilter({location: {isNot: values.isNot}})
              onClose()
            } else {
              setError("Select a location to continue")
            }
          })
          .with("contains", () => {
            if (A.isNonEmpty(values.contains)) {
              addModifyFilter({location: {contains: values.contains}})
              onClose()
            } else {
              setError("Enter a value and press enter to continue")
            }
          })
          .run()
      }),
    [formMethods, operation, onClose, addModifyFilter, setError],
  )

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={onSubmit}>
        <div className="mb-4 flex flex-col gap-4">
          <FilterTitle title="Location" deleteFilter={deleteFilter} />
          <FilterModalError error={error} />
          <RadioGroup
            value={operation}
            onChange={handleRadioChange}
            label="Operation"
          >
            {(props) => (
              <>
                <CollapsableRadioButton
                  selectedValue="is"
                  testId="location-is-radio"
                  label="Is"
                  {...props}
                >
                  <FormAutocomplete
                    componentsProps={{popper: {disablePortal: true}}}
                    multiple
                    renderOption={renderLocationAutoCompleteItem}
                    options={collectionSiteLocations ?? []}
                    label={"Select garden locations"}
                    filterOptions={filterOptions}
                    getOptionLabel={getLocationOptionLabel}
                    isOptionEqualToValue={isOptionEqual}
                    fullWidth
                    name={`is`}
                    testId="location-is-input"
                  />
                </CollapsableRadioButton>
                <CollapsableRadioButton
                  selectedValue="isNot"
                  testId="location-is-not-radio"
                  label="Is not"
                  {...props}
                >
                  <FormAutocomplete
                    multiple
                    componentsProps={{popper: {disablePortal: true}}}
                    renderOption={renderLocationAutoCompleteItem}
                    options={collectionSiteLocations ?? []}
                    label={"Select garden locations"}
                    filterOptions={filterOptions}
                    getOptionLabel={getLocationOptionLabel}
                    isOptionEqualToValue={isOptionEqual}
                    fullWidth
                    name={`isNot`}
                    testId="location-is-not-input"
                  />
                </CollapsableRadioButton>
                <CollapsableRadioButton
                  selectedValue="contains"
                  testId="location-contains-radio"
                  label="Contains"
                  {...props}
                >
                  <FormAutocomplete
                    multiple
                    freeSolo
                    options={[]}
                    label={"Select name contents"}
                    isOptionEqualToValue={isOptionEqual}
                    fullWidth
                    name={`contains`}
                    testId="location-contains-input"
                  />
                </CollapsableRadioButton>
              </>
            )}
          </RadioGroup>
        </div>
        <FilterModalButtons onCancel={onClose} testIdPrefix="location" />
      </form>
    </FormProvider>
  )
}

// Seperated from form component to make sure popover unmount causes state reset
export const LocationFilterModal = ({
  open,
  onClose,
  anchorEl,
  ...props
}: FilterFormProps & FilterModalProps) => {
  return (
    <FilterModal anchorEl={anchorEl} open={open} onClose={onClose}>
      <LocationFilterForm onClose={onClose} {...props} />
    </FilterModal>
  )
}
