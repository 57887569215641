import {useCallback} from "react"

interface UseSearchArgs<Params, SearchField> {
  search: {field: SearchField; value: string} | undefined
  navigate: (arg: {search: (args: Params) => void}) => void
  resetSelectedRows: () => void
  resetFilters: () => void
  searchKey?: string
  cursorKey?: string
  dirKey?: string
}

export const useTableSearch = <Params, SearchField>({
  search,
  navigate,
  resetFilters,
  resetSelectedRows,
  searchKey = "search",
  cursorKey = "cursor",
  dirKey = "dir",
}: UseSearchArgs<Params, SearchField>) => {
  const handleSearchClear = useCallback(
    (field: SearchField) => {
      navigate({
        search: (params) => ({
          ...params,
          [cursorKey]: undefined,
          [dirKey]: undefined,
          [searchKey]: {value: "", field},
        }),
      })
      resetSelectedRows()
    },
    [resetSelectedRows, navigate, searchKey, cursorKey, dirKey],
  )

  const handleSearchSubmit = useCallback(
    (searchValue: string, searchField: SearchField) => {
      navigate({
        search: (params) => ({
          ...params,
          [cursorKey]: undefined,
          [dirKey]: undefined,
          [searchKey]: {value: searchValue, field: searchField},
        }),
      })
      resetSelectedRows()
    },
    [navigate, resetSelectedRows, searchKey, cursorKey, dirKey],
  )

  const handleClearSearchAndFilters = useCallback(() => {
    if (search != null) {
      handleSearchClear(search.field)
    }
    resetFilters()
  }, [search, handleSearchClear, resetFilters])

  return {handleSearchClear, handleSearchSubmit, handleClearSearchAndFilters}
}
