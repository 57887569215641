import * as RA from "fp-ts/ReadonlyArray"
import * as RNEA from "fp-ts/ReadonlyNonEmptyArray"
import * as O from "fp-ts/Option"
import * as Reader from "fp-ts/Reader"
import {absurd, flow} from "fp-ts/function"
import {NonEmptyString} from "io-ts-types"
import {Link, type UseNavigateResult} from "@tanstack/react-router"
import {Button} from "@hortis/ui/button"
import type {CreateAccessionMutation} from "../../../../../generated/graphql"
import type {SetSnack} from "../../../../components/snackbar-controller/snackbar-store"
import type {UseCollectionSiteError} from "../../../../utils/hooks/collection-site"
import {isNonNullable} from "../../../../utils/nullable"
import * as OE from "../../../../utils/option-either"
import * as Path from "../../../../utils/path"
import {
  useMaterialTypes as _useMaterialTypes,
  useProvenanceCategories as _useProvenanceCategories,
} from "../../fetchers"
import {processProvenanceCategories} from "../../utils/provenance-categories"
import FormStageName from "../form-stage-name"
import type {TransitionStep} from "../step-config"

export const onAccessionCreatedFailure =
  (transitionStep: TransitionStep, setSnack: SetSnack) =>
  (error: Error): Promise<void> => {
    setSnack({
      type: "alert",
      data: {
        severity: "error",
        text: error.message,
      },
    })
    transitionStep(FormStageName.plantMaterialData, {
      submitLoading: false,
    })

    // This is simply to match onAccessionCreatedSuccess
    return Promise.resolve(undefined)
  }

export function SuccessSnackButton({href}: {href: string}) {
  return (
    <Button variant="linkgray" size="sm" asChild>
      <Link to={`/${href}`}>View</Link>
    </Button>
  )
}

export const onAccessionCreatedSuccess =
  (
    collectionHref: string | undefined,
    accessionsHref: string | undefined,
    navigate: UseNavigateResult<string>,
    setSnack: SetSnack,
  ) =>
  (success: CreateAccessionMutation) => {
    if (collectionHref !== undefined && accessionsHref !== undefined) {
      // TODO: error handling if collectionSiteName is undefined...?

      void navigate({to: `/${collectionHref}`})

      const accessionNumber = success.createAccession?.accessionNumber
      if (accessionNumber === undefined) {
        setSnack({
          type: "alert",
          data: {
            severity: "error",
            text: "Created accession number unknown",
          },
        })
      } else {
        setSnack({
          type: "action",
          data: {
            text: "Accession saved",
            body: accessionNumber,
            action: {
              text: "View accession",
              href: `/${Path.concat(
                accessionsHref,
                encodeURIComponent(accessionNumber),
              )}`,
            },
          },
        })
      }
    }
  }

export const useProvenanceCategories = flow(
  _useProvenanceCategories,
  processProvenanceCategories,
)

const idIsNonEmpty = <A extends {id: string}>(
  value: A,
): value is A & {
  id: NonEmptyString
} => NonEmptyString.is(value.id)

export const useMaterialTypes = flow(
  _useMaterialTypes,
  OE.chainNullableToErrorK(new Error("Could not fetch material types"))(
    ({materialTypes}) => materialTypes,
  ),
  OE.map(RA.filter(isNonNullable)),
  OE.map(RA.filter(idIsNonEmpty)),
  OE.chainNullableToErrorK(new Error("No viable material types found"))(
    flow(RNEA.fromReadonlyArray, O.toNullable),
  ),
)

/**
 * Picks the first material type if possible.
 * @param processedMaterialTypes - expects the result of {@link processMaterialTypes}
 * @returns the first material type, or undefined if they haven't loaded yet or failed to load
 */
export const selectDefaultMaterialType = flow(
  Reader.ask<ReturnType<typeof useMaterialTypes>>(),
  OE.map((materialTypes) => materialTypes[0].id),
  OE.getValue,
)

const showCollectionSiteError = (
  error: Exclude<UseCollectionSiteError, Error>,
): string =>
  error.type === "NO_PLACE_IDENTIFIED"
    ? "URL is invalid"
    : error.type === "ORGANISATION_NOT_FOUND"
    ? "No organization found that matches"
    : error.type === "COLLECTION_SITE_NOT_FOUND" // eslint-disable-line @typescript-eslint/no-unnecessary-condition
    ? "No collection site found that matches"
    : absurd(error)

export const showErrorMessage = flow(
  (error: string | UseCollectionSiteError): string =>
    typeof error === "string"
      ? error
      : error instanceof Error
      ? error.message
      : "type" in error
      ? showCollectionSiteError(error)
      : absurd(error),
  (error) => <>{error}</>,
)
