import * as TabsPrimitive from "@radix-ui/react-tabs"
import {tv} from "tailwind-variants"
import {
  createContext,
  forwardRef,
  useContext,
  useMemo,
  type ForwardedRef,
} from "react"

interface TabProps {
  size?: "sm" | "md"
  fullWidth?: boolean
  children?: React.ReactNode
}

const TabContext = createContext<Pick<TabProps, "fullWidth" | "size">>({
  size: "sm",
  fullWidth: false,
})

export const Tab = forwardRef(
  <T extends string>(
    {
      size = "sm",
      fullWidth = false,
      children,
      onValueChange,
      ...props
    }: Omit<
      React.ComponentPropsWithoutRef<typeof TabsPrimitive.Root>,
      "value" | "defaultValue" | "onValueChange"
    > &
      TabProps & {
        value: T
        defaultValue?: T
        onValueChange: (value: T) => void
      },
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const contextValue = useMemo(() => ({size, fullWidth}), [size, fullWidth])
    return (
      <TabContext.Provider value={contextValue}>
        <TabsPrimitive.Root
          ref={ref}
          className={tv({
            base: "border-grey-300 shadow-xs flex w-fit flex-col truncate rounded-lg border",
            variants: {
              fullWidth: {
                true: "w-full",
                false: "w-fit",
              },
            },
          })({fullWidth})}
          orientation="horizontal"
          onValueChange={onValueChange as (value: string) => void}
          {...props}
        >
          <TabsPrimitive.List className="flex shrink-0">
            {children}
          </TabsPrimitive.List>
        </TabsPrimitive.Root>
      </TabContext.Provider>
    )
  },
)
// @ts-expect-error -- Reclared forwardRef does not have a displayName
Tab.displayName = "Tab"

type TabHeaderProps = {
  children: React.ReactNode
  value: string
}
export const TabHeader = forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger> & TabHeaderProps
>(({value, children, ...props}, ref) => {
  const {size, fullWidth} = useContext(TabContext)
  if (size === undefined || fullWidth === undefined) {
    throw new Error("TabHeader must be used within a Tab component")
  }
  return (
    <TabsPrimitive.Trigger
      value={value}
      ref={ref}
      data-cy={`${value}-tab`}
      className={tv({
        base: "text-grey-500 data-[state=active]:text-grey-900 data-[state=active]:bg-grey-50 curor-default border-grey-300 hover:bg-grey-50 hover:text-grey-900 flex items-center justify-center gap-2 truncate border-l bg-white text-sm font-medium outline-none transition-colors duration-150 first:rounded-bl-lg first:rounded-tl-lg first:border-x-0 last:rounded-br-lg last:rounded-tr-lg",
        variants: {
          size: {
            sm: "px-3 py-1.5",
            md: "px-3.5 py-3",
          },
          fullWidth: {
            true: "w-full",
            false: "w-fit",
          },
        },
      })({size, fullWidth})}
      {...props}
    >
      {children}
    </TabsPrimitive.Trigger>
  )
})
// @ts-expect-error -- Reclared forwardRef does not have a displayName
TabHeader.displayName = "TabHeader"

type TabContentProps = {
  children: React.ReactNode
  value: string
}
export const TabContent = forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content> & TabContentProps
>(({children, value, ...props}, ref) => {
  const {size, fullWidth} = useContext(TabContext)
  if (size === undefined || fullWidth === undefined) {
    throw new Error("TabContent must be used within a Tab component")
  }
  return (
    <TabsPrimitive.Content ref={ref} value={value} {...props}>
      {children}
    </TabsPrimitive.Content>
  )
})
// @ts-expect-error -- Reclared forwardRef does not have a displayName
TabContent.displayName = "TabContent"
