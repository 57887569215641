import {twMerge} from "tailwind-merge"
import type {PropsWithChildren} from "react"

interface PageContentProps {
  maxWidth?: string
  responsivePadding?: boolean
  className?: string
}

export const PageContent = ({
  children,
  responsivePadding = false,
  maxWidth,
  className,
}: PropsWithChildren<PageContentProps>) => {
  return (
    <div
      className={twMerge(
        "flex flex-1 flex-col items-center overflow-auto",
        className,
      )}
    >
      <div
        className={twMerge(
          "relative flex w-full max-w-[1024px] flex-1 flex-col",
          responsivePadding && "py-4 pb-12 pt-4 lg:pb-20 lg:pt-8",
        )}
        style={{maxWidth}}
      >
        {children}
      </div>
    </div>
  )
}
