import {colors} from "src/colors"
import type {IconProps} from "./types"

export const SlExitIcon = ({
  color = colors.grey[700],
  ...props
}: IconProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M21 12.0032H8.40088"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.4007 15.003L8.40088 12.0032L11.4007 9.00342"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.0004 15.5998V19.1995C15.0141 19.5032 14.907 19.7999 14.7025 20.0247C14.498 20.2496 14.2128 20.3843 13.9093 20.3995H4.09153C3.78812 20.3841 3.50311 20.2493 3.2988 20.0245C3.09449 19.7996 2.9875 19.503 3.00121 19.1995V4.80051C2.98729 4.49696 3.09421 4.20026 3.29856 3.97537C3.50291 3.75048 3.78804 3.61571 4.09153 3.60059H13.9093C14.2128 3.61572 14.498 3.75045 14.7025 3.97532C14.907 4.20018 15.0141 4.49688 15.0004 4.80051V8.40026"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
