import type {
  GetOrganisationFromNameQuery,
  GetOrganisationFromNameQueryVariables,
} from "../../generated/graphql"
import {authedFetcher} from "./gql-client"
import {GET_ORGANISATION_FROM_NAME} from "./graphql"

export const getOrganisationFromName = authedFetcher<
  GetOrganisationFromNameQueryVariables,
  GetOrganisationFromNameQuery
>(GET_ORGANISATION_FROM_NAME)("GetOrganisationFromName")
