import type {ReactElement, ReactNode, ComponentType} from "react"
import * as E from "fp-ts/Either"
import {pipe} from "fp-ts/function"
import * as OE from "../../../utils/option-either"
import {usePermissions} from "../fetchers"
import {RenderError} from "../../../components/error"
import {InvalidPermissions} from "./invalid-permissions"

interface Props {
  children: ReactNode
}

const showErrorMessage = (error: Error) => (
  <RenderError title="Something when wrong">{error.message}</RenderError>
)

const AuthGuard = ({children}: Props): ReactElement => {
  return pipe(
    usePermissions(),
    OE.map(({permissions}) => permissions),
    OE.noneToUndefined,
    E.match(showErrorMessage, (permissions) => (
      <InvalidPermissions permissions={permissions}>
        {children}
      </InvalidPermissions>
    )),
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyRecord = Record<string | number | symbol, any>

type ComponentWithChildren = ({children}: Props) => ReactElement

const wrapWithPermissions =
  (PermissionsWrapper: ComponentWithChildren) =>
  <P extends AnyRecord>(
    Component: ComponentType<React.PropsWithChildren<P>>,
  ): ComponentType<React.PropsWithChildren<P>> =>
    function WithPermissions(props) {
      return (
        <PermissionsWrapper>
          <Component {...props} />
        </PermissionsWrapper>
      )
    }

export const withPermissions = wrapWithPermissions(AuthGuard)
