import {useCallback} from "react"
import type {PlantMaterialsOrderByInput} from "generated/graphql"
import {PlantMaterialsColumn, SortDirection} from "generated/graphql"
import {Select} from "src/components/select"
import type {SelectProps} from "src/components/select"
import type {PlantMaterialsFiltersProps} from "./types"

export const SortLabel = ({
  label,
  subLabel,
}: {
  label: string
  subLabel: string
}) => (
  <div className="flex items-center gap-1 truncate">
    <span className="truncate">{label}</span>
    <span className="text-primary-600" style={{whiteSpace: "nowrap"}}>
      {subLabel}
    </span>
  </div>
)

const options = [
  {
    value: null,
    label: "None",
  },
  {
    value: {
      field: PlantMaterialsColumn.AccessionNum,
      direction: SortDirection.Asc,
    },
    label: <SortLabel label="Material number" subLabel="(0 to 9)" />,
  },
  {
    value: {
      field: PlantMaterialsColumn.AccessionNum,
      direction: SortDirection.Desc,
    },
    label: <SortLabel label="Material number" subLabel="(9 to 0)" />,
  },
  {
    value: {
      field: PlantMaterialsColumn.ScientificName,
      direction: SortDirection.Asc,
    },
    label: <SortLabel label="Scientific name" subLabel="(A to Z)" />,
  },
  {
    value: {
      field: PlantMaterialsColumn.ScientificName,
      direction: SortDirection.Desc,
    },
    label: <SortLabel label="Scientific name" subLabel="(Z to A)" />,
  },
  {
    value: {
      field: PlantMaterialsColumn.CommonName,
      direction: SortDirection.Asc,
    },
    label: <SortLabel label="Common name" subLabel="(A to Z)" />,
  },
  {
    value: {
      field: PlantMaterialsColumn.CommonName,
      direction: SortDirection.Desc,
    },
    label: <SortLabel label="Common name" subLabel="(Z to A)" />,
  },
  {
    value: {
      field: PlantMaterialsColumn.ProvenanceCategoryCode,
      direction: SortDirection.Asc,
    },
    label: <SortLabel label="Provenance" subLabel="(A to Z)" />,
  },
  {
    value: {
      field: PlantMaterialsColumn.ProvenanceCategoryCode,
      direction: SortDirection.Desc,
    },
    label: <SortLabel label="Provenance" subLabel="(Z to A)" />,
  },
  {
    value: {
      field: PlantMaterialsColumn.MaterialGroup,
      direction: SortDirection.Asc,
    },
    label: <SortLabel label="Material type" subLabel="(A to Z)" />,
  },
  {
    value: {
      field: PlantMaterialsColumn.MaterialGroup,
      direction: SortDirection.Desc,
    },
    label: <SortLabel label="Material type" subLabel="(Z to A)" />,
  },
  {
    value: {
      field: PlantMaterialsColumn.Status,
      direction: SortDirection.Asc,
    },
    label: <SortLabel label="Status" subLabel="(A to Z)" />,
  },
  {
    value: {
      field: PlantMaterialsColumn.Status,
      direction: SortDirection.Desc,
    },
    label: <SortLabel label="Status" subLabel="(Z to A)" />,
  },
  {
    value: {
      field: PlantMaterialsColumn.LocationCode,
      direction: SortDirection.Asc,
    },
    label: <SortLabel label="Location" subLabel="(A to Z)" />,
  },
  {
    value: {
      field: PlantMaterialsColumn.LocationCode,
      direction: SortDirection.Desc,
    },
    label: <SortLabel label="Location" subLabel="(Z to A)" />,
  },
  {
    value: {
      field: PlantMaterialsColumn.Quantity,
      direction: SortDirection.Asc,
    },
    label: <SortLabel label="Quantity" subLabel="(0 to 9)" />,
  },
  {
    value: {
      field: PlantMaterialsColumn.Quantity,
      direction: SortDirection.Desc,
    },
    label: <SortLabel label="Quantity" subLabel="(9 to 0)" />,
  },
  {
    value: {
      field: PlantMaterialsColumn.DonorSupplier,
      direction: SortDirection.Asc,
    },
    label: <SortLabel label="Donor / supplier" subLabel="(A to Z)" />,
  },
  {
    value: {
      field: PlantMaterialsColumn.DonorSupplier,
      direction: SortDirection.Desc,
    },
    label: <SortLabel label="Donor / supplier" subLabel="(Z to A)" />,
  },
  {
    value: {
      field: PlantMaterialsColumn.LastObserved,
      direction: SortDirection.Asc,
    },
    label: <SortLabel label="Last observed" subLabel="(Oldest first)" />,
  },
  {
    value: {
      field: PlantMaterialsColumn.LastObserved,
      direction: SortDirection.Desc,
    },
    label: <SortLabel label="Last observed" subLabel="(Newest first)" />,
  },
  {
    value: {
      field: PlantMaterialsColumn.DaysOverdueFollowUp,
      direction: SortDirection.Asc,
    },
    label: <SortLabel label="Days overdue" subLabel="(0 to 9)" />,
  },
  {
    value: {
      field: PlantMaterialsColumn.DaysOverdueFollowUp,
      direction: SortDirection.Desc,
    },
    label: <SortLabel label="Days overdue" subLabel="(9 to 0)" />,
  },
  {
    value: {
      field: PlantMaterialsColumn.DateAdded,
      direction: SortDirection.Asc,
    },
    label: <SortLabel label="Date added" subLabel="(Oldest first)" />,
  },
  {
    value: {
      field: PlantMaterialsColumn.DateAdded,
      direction: SortDirection.Desc,
    },
    label: <SortLabel label="Date added" subLabel="(Newest first)" />,
  },
  {
    value: {
      field: PlantMaterialsColumn.LastUpdated,
      direction: SortDirection.Asc,
    },
    label: <SortLabel label="Last edited" subLabel="(Oldest first)" />,
  },
  {
    value: {
      field: PlantMaterialsColumn.LastUpdated,
      direction: SortDirection.Desc,
    },
    label: <SortLabel label="Lasted edited" subLabel="(Newest first)" />,
    testId: "last-edited-desc-sort-option",
  },
]

export const MaterialsSortSelect = ({
  orderBy,
  updateOrderBy,
  ...props
}: Pick<PlantMaterialsFiltersProps, "orderBy" | "updateOrderBy"> &
  Partial<SelectProps<PlantMaterialsOrderByInput | null>>) => {
  const onOrderByChange = useCallback(
    (val: PlantMaterialsOrderByInput | null) => {
      updateOrderBy(val ?? undefined)
    },
    [updateOrderBy],
  )

  return (
    <Select
      {...props}
      options={options}
      onChange={onOrderByChange}
      value={orderBy ?? null}
      placeholder="Sort by"
      testId="material-sort-select"
    />
  )
}
