import type {ReactElement} from "react"
import {memo} from "react"
import type {PlantMaterialStatus} from "../../../generated/graphql"
import {withTestId} from "../../utils/with-test-id"
import {Badge} from "../../../../../packages/ui/src/components/badge"
import {Tooltip} from "../tooltip"

export interface StatusChipProps {
  status: PlantMaterialStatus
  label?: string
  tooltip?: string
  statusAsTooltip?: boolean
  "data-cy"?: string
  className?: string
}

export enum PlantMaterialStatusToLabel {
  ABSENT = "Absent",
  PRESENT = "Present",
  TRANSIENT = "Transient",
  UNKNOWN = "Unknown",
}

export enum PlantMaterialStatusToColor {
  ABSENT = "grey",
  PRESENT = "success",
  TRANSIENT = "warning",
  UNKNOWN = "error",
}

export const StatusChip = memo(
  withTestId<StatusChipProps>(function StatusChip({
    status,
    label,
    tooltip,
    statusAsTooltip = false,
    className,
    ...props
  }: StatusChipProps): ReactElement {
    return (
      <Tooltip
        title={
          tooltip === undefined
            ? statusAsTooltip
              ? PlantMaterialStatusToLabel[status]
              : ""
            : tooltip
        }
        placement="top"
        PopperProps={{
          style: {
            display: tooltip != null || statusAsTooltip ? "block" : "none",
          },
        }}
        className={className}
      >
        <Badge
          color={PlantMaterialStatusToColor[status]}
          type="dot"
          testId={props["data-cy"]}
          {...props}
        >
          {label === undefined ? PlantMaterialStatusToLabel[status] : label}
        </Badge>
      </Tooltip>
    )
  }),
)
