import type {ReactElement} from "react"
import {Divider} from "src/components/custom/divider"
import {twMerge} from "tailwind-merge"

interface SettingsSectionHeaderProps {
  title: string
  subtitle?: string
  hideDivider?: boolean
  className?: string
}

export const SettingsSectionHeader = ({
  title,
  subtitle,
  hideDivider,
  className,
}: SettingsSectionHeaderProps): ReactElement => (
  <div className={className}>
    <div
      className={twMerge("flex flex-col gap-1", hideDivider !== true && "pb-5")}
    >
      <h1
        className="text-lg font-medium text-grey-900"
        data-cy="settings-page-title"
      >
        {title}
      </h1>
      {subtitle != null && (
        <div className="text-sm text-grey-500">{subtitle}</div>
      )}
    </div>
    {hideDivider !== true && <Divider />}
  </div>
)
