import * as O from "fp-ts/Option"
import type {SWRResponse} from "swr"
import * as OE from "./option-either"

/**
 * Returns the optional data and error fields of an SWR response into
 * a more structured Option of an Either.
 * @param response - a potentially still loading SWR Response
 * @returns
 * If the `response` is still loading, `None`.
 * If the `response` has loaded but errored, `Some<Left<Error>>`
 * If the `response` has loaded and succeeded, `Some<Right<Error>>`
 */
const wrapSWR = <Err, Data>(
  response: SWRResponse<Data, Err>,
): OE.OptionEither<Err, Data> =>
  response.error === undefined
    ? response.data === undefined
      ? O.none
      : OE.right(response.data)
    : OE.left(response.error)

export default wrapSWR
