import type {PropsWithChildren, ReactElement} from "react"
import {match} from "ts-pattern"
import type {UseQueryExecute} from "urql"
import {type AccessionFieldsFragment} from "../../../../../../generated/graphql"
import {AccessionRecordOverview} from "./accession-record-overview"
import {AddPlantMaterial} from "./add-plant-material"
import {EditAccessionData} from "./edit-accession-data"
import {EditCollectionData} from "./edit-collection-data"
import {PlantMaterialOverview} from "./plant-material-overview"
import {AccessionEditStage} from "./types"

export interface EditDrawerProps {
  setStage: (stage: AccessionEditStage) => void
  stage: AccessionEditStage
  accession: AccessionFieldsFragment
  open: boolean
  refetch: UseQueryExecute
  requestClose: () => void
}

export const EditAccessionSheetForm = ({
  stage,
  setStage,
  accession,
  open,
  requestClose,
  refetch,
}: PropsWithChildren<EditDrawerProps>): ReactElement => {
  return (
    <>
      {match(stage)
        .with(AccessionEditStage.overview, () => (
          <AccessionRecordOverview
            transitionStage={setStage}
            accession={accession}
            requestClose={requestClose}
            refetch={refetch}
            open={open}
          />
        ))
        .with(AccessionEditStage.editAccessionData, () => (
          <EditAccessionData
            transitionStage={setStage}
            accession={accession}
            requestClose={requestClose}
            refetch={refetch}
            open={open}
          />
        ))
        .with(AccessionEditStage.editCollectionData, () => (
          <EditCollectionData
            transitionStage={setStage}
            accession={accession}
            requestClose={requestClose}
            refetch={refetch}
            open={open}
          />
        ))
        .with(AccessionEditStage.plantMaterial, () => (
          <PlantMaterialOverview
            transitionStage={setStage}
            accession={accession}
            requestClose={requestClose}
            refetch={refetch}
            open={open}
          />
        ))
        .with(AccessionEditStage.addPlantMaterial, () => (
          <AddPlantMaterial
            transitionStage={setStage}
            accession={accession}
            requestClose={requestClose}
            refetch={refetch}
            open={open}
          />
        ))
        .run()}
    </>
  )
}
