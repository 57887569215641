import {createFilterOptions} from "@mui/material/useAutocomplete"
import type {HTMLAttributes} from "react"
import {Fragment} from "react"
import type {RecordTagFieldsFragment} from "../../../generated/graphql"
import {Typography} from "../../components/typography"

export const filterOptions = createFilterOptions({
  stringify: (option: RecordTagFieldsFragment) => option.name,
})

export const renderTagAutoCompleteItem = (
  props: HTMLAttributes<HTMLLIElement>,
  option: RecordTagFieldsFragment | null,
) => {
  const {...rest} = props
  return option == null ? (
    <Fragment />
  ) : (
    <li key={option.id} {...rest}>
      <div className="flex-1">
        <Typography variant="body2" color="text.primary">
          {option.name}
        </Typography>
      </div>
    </li>
  )
}

export const getTagOptionLabel = (option: RecordTagFieldsFragment | null) =>
  option === null ? "" : option.name

export const isTagOptionEqual = (
  option: RecordTagFieldsFragment,
  value: RecordTagFieldsFragment,
) => option.id === value.id
