import {type badgeVariants} from "@hortis/ui/badge"
import type {ActiveOptions} from "@tanstack/react-router"
import {Link} from "@tanstack/react-router"
import {cva, type VariantProps} from "class-variance-authority"
import type {HTMLAttributeAnchorTarget} from "react"
import {useLocation} from "src/utils/hooks/router"

import {twMerge} from "tailwind-merge"

type navListElementColor = Extract<
  VariantProps<typeof badgeVariants>["color"],
  "primary" | "grey"
>

interface NavListElementProps {
  color?: navListElementColor
  href?: string
  active?: boolean
  onClick?: () => void
  target?: HTMLAttributeAnchorTarget
  children: React.ReactNode
  "data-cy"?: string
  activeOptions?: ActiveOptions
}

export const NavItemLabel = ({label}: {label: string}) => (
  <div className="flex-1">{label}</div>
)
export const NavItem = ({
  color = "grey",
  href,
  onClick,
  target,
  active,
  children,
  activeOptions,
  "data-cy": dataCy,
}: NavListElementProps) => {
  const router = useLocation()

  const selected = active ?? router.pathname.split("?").shift() === href

  const buttonClasses = twMerge(
    cva(
      [
        "group relative flex max-w-[272px] items-center gap-3 rounded-[6px] [&_svg]:text-grey-500 [&_svg]:transition transition px-3 py-2 hover:bg-grey-50 border-none shadow-none text-left text-sm font-medium outline-none ring-grey-200/70 focus-visible:ring",
      ],
      {
        variants: {
          selected: {
            true: undefined,
            false: undefined,
          },
          color: {
            grey: "hover:bg-grey-50 focus-visible:shadow-grey-100 hover:text-grey-900 [&_svg]:hover:text-grey-700",
            primary:
              "hover:bg-grey-50 hover:focus-visible:ring-grey-200/70 [&_svg]:hover:text-grey-600",
          },
        },
        compoundVariants: [
          {
            selected: true,
            color: "primary",
            className:
              "bg-primary-50 hover:bg-primary-50 hover:text-primary-700 text-primary-700 focus-visible:ring-primary-200/70 [&_svg]:text-primary-400 [&_svg]:hover:text-primary-400",
          },
          {
            selected: true,
            color: "grey",
            className:
              "bg-grey-50 hover:bg-grey-100 focus-visible:ring-grey-200/70 focus-visible:shadow-[0_0_0_2px] text-grey-800 [&_svg]:hover:text-grey-900",
          },
        ],
      },
    )({selected, color}),
  )

  return href == null ? (
    <button
      type="button"
      onClick={onClick}
      className={buttonClasses}
      data-cy={dataCy}
    >
      {children}
    </button>
  ) : (
    <Link
      onClick={onClick}
      to={href}
      target={target}
      className={buttonClasses}
      data-cy={dataCy}
      activeProps={{
        className:
          "bg-grey-50 hover:bg-grey-50 hover:text-grey-900 text-grey-900 focus-visible:ring-grey-200/70 [&_svg]:text-grey-700 [&_svg]:hover:text-grey-700",
      }}
      activeOptions={activeOptions ?? {exact: true}}
    >
      {children}
    </Link>
  )
}
