import {zodResolver} from "@hookform/resolvers/zod"
import {createFilterOptions} from "@mui/material/Autocomplete"
import * as A from "fp-ts/Array"
import type {HTMLAttributes} from "react"
import {Fragment, useMemo} from "react"
import {FormProvider} from "react-hook-form"
import {match} from "ts-pattern"
import {z} from "zod"
import {renderTags} from "src/features/tags/tags-autocomplete"
import {FilterTitle} from "src/features/filters/components/modal/filter-title"
import {materialReceivedOption} from "src/features/create-accession/components/accession-data-form/schema"
import type {MaterialReceivedOption} from "src/features/create-accession/components/accession-data-form/schema"
import {CollapsableRadioButton, RadioGroup} from "src/components/radio"
import {FormAutocomplete} from "src/components/autocomplete"
import {useGetAllMaterialTypesQuery} from "generated/graphql"
import type {MaterialType} from "generated/graphql"
import {isOptionEqual} from "../../../locations/utils"
import type {FilterModalProps} from "../../components/modal/filter-modal"
import {FilterModal} from "../../components/modal/filter-modal"
import {FilterModalButtons} from "../../components/modal/modal-buttons"
import {FilterModalError} from "../../components/modal/modal-error"
import {useFilterForm} from "../../components/modal/use-filter-form"
import {determineInitialOperation} from "../../plant-materials/modals/determine-initial-operation"
import {useDispatchFilter} from "../filter-modals/use-dispatch-filter"
import type {FilterFormProps, LocalAccessionFilter} from "../types"

export const renderMaterialAutoCompleteItem = (
  props: HTMLAttributes<HTMLLIElement>,
  option: MaterialType | null,
) => {
  const {...rest} = props
  return option == null ? (
    <Fragment />
  ) : (
    <li key={option.id} {...rest}>
      <div className="flex-1 text-sm">{option.name}</div>
    </li>
  )
}

const filterOptions = createFilterOptions({
  stringify: (option: MaterialType) => option.code + option.name,
})

export const getMaterialReceivedOptionLabel = (
  option: MaterialReceivedOption | null,
) => (option === null ? "" : option.name ?? option.code)

const createDefaultValues = (
  initialValue?: NonNullable<LocalAccessionFilter["materialType"]>,
) => {
  const defaultValues = {
    is: initialValue?.is == null ? [] : initialValue.is,
    isNot: initialValue?.isNot == null ? [] : initialValue.isNot,
  }
  return {
    defaultValues,
    initialOperation: determineInitialOperation(defaultValues, "is"),
  }
}

const resolver = zodResolver(
  z.object({
    is: z.array(materialReceivedOption),
    isNot: z.array(materialReceivedOption),
  }),
)

export const MaterialReceivedFilterForm = ({
  dispatchFilters,
  initialValue,
  onClose,
}: FilterFormProps) => {
  const {error, setError, operation, handleRadioChange, formMethods} =
    useFilterForm({
      resolver,
      ...createDefaultValues(initialValue?.materialType ?? undefined),
    })
  const {addModifyFilter, deleteFilter} = useDispatchFilter({
    dispatchFilters,
    id: initialValue?.id,
    onClose,
  })

  const [{data, fetching}] = useGetAllMaterialTypesQuery()

  const materialTypes = data?.materialTypes

  const onSubmit = useMemo(
    () =>
      formMethods.handleSubmit((values) => {
        match(operation)
          .with("is", () => {
            if (A.isNonEmpty(values.is)) {
              addModifyFilter({materialType: {is: values.is}})
              onClose()
            } else {
              setError("Select a material type to continue")
            }
          })
          .with("isNot", () => {
            if (A.isNonEmpty(values.isNot)) {
              addModifyFilter({materialType: {isNot: values.isNot}})
              onClose()
            } else {
              setError("Select a material type to continue")
            }
          })
          .run()
      }),
    [formMethods, operation, onClose, addModifyFilter, setError],
  )

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={onSubmit}>
        <div className="mb-4 flex flex-col gap-4">
          <FilterTitle title="Material received" deleteFilter={deleteFilter} />
          <FilterModalError error={error} />
          <RadioGroup
            value={operation}
            onChange={handleRadioChange}
            label="Operation"
          >
            {(props) => (
              <>
                <CollapsableRadioButton
                  selectedValue="is"
                  testId="material-received-is-radio"
                  label="Is"
                  {...props}
                >
                  <FormAutocomplete
                    multiple
                    renderOption={renderMaterialAutoCompleteItem}
                    options={materialTypes ?? []}
                    label={null}
                    loading={fetching}
                    filterOptions={filterOptions}
                    getOptionLabel={getMaterialReceivedOptionLabel}
                    isOptionEqualToValue={isOptionEqual}
                    fullWidth
                    name={`is`}
                    testId="material-received-is-input"
                    renderTags={renderTags}
                    componentsProps={{popper: {disablePortal: true}}}
                  />
                </CollapsableRadioButton>
                <CollapsableRadioButton
                  selectedValue="isNot"
                  testId="material-received-is-not-radio"
                  label="Is not"
                  {...props}
                >
                  <FormAutocomplete
                    multiple
                    renderOption={renderMaterialAutoCompleteItem}
                    options={materialTypes ?? []}
                    loading={fetching}
                    label={null}
                    filterOptions={filterOptions}
                    getOptionLabel={getMaterialReceivedOptionLabel}
                    isOptionEqualToValue={isOptionEqual}
                    fullWidth
                    name={`isNot`}
                    testId="material-received-is-not-input"
                    renderTags={renderTags}
                    componentsProps={{popper: {disablePortal: true}}}
                  />
                </CollapsableRadioButton>
              </>
            )}
          </RadioGroup>
        </div>
        <FilterModalButtons
          onCancel={onClose}
          testIdPrefix="material-received"
        />
      </form>
    </FormProvider>
  )
}

// Seperated from form component to make sure popover unmount causes state reset
export const MaterialReceivedFilterModal = ({
  open,
  onClose,
  anchorEl,
  ...props
}: FilterFormProps & FilterModalProps) => {
  return (
    <FilterModal anchorEl={anchorEl} open={open} onClose={onClose}>
      <MaterialReceivedFilterForm onClose={onClose} {...props} />
    </FilterModal>
  )
}
