import type {ReactNode} from "react"
import {Box} from "../../../components/box"
import {concatAllSxProps, fromSxFn, fromSxValues} from "../../../utils/sx"

const authPageStyle = concatAllSxProps([
  fromSxValues({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    minHeight: "100vh",
    backgroundPosition: "center bottom",
    backgroundRepeat: "no-repeat",
    backgroundImage: {xs: "none", sm: `url(/images/blurred-colors.jpg)`},
    padding: 2,
  }),
  fromSxFn((theme) => ({
    backgroundColor: {
      xs: theme.palette.background.paper,
      sm: theme.palette.primary.background,
    },
  })),
])

export const AuthPageLayout = ({
  children,
}: {
  children: ReactNode
}): JSX.Element => {
  return <Box sx={authPageStyle}>{children}</Box>
}
