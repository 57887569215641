import flat from "flat"
import {useCallback, useEffect, useState} from "react"
import type {FieldValues, UseFormReturn} from "react-hook-form"

export const useScrollToError = <T extends FieldValues>(
  methods: UseFormReturn<T>,
) => {
  // using a state here to make the "scroll & focus" happen once per submission
  const [canFocus, setCanFocus] = useState(false)

  const onError = useCallback(() => {
    setCanFocus(true)
  }, [])

  useEffect(() => {
    if (Object.keys(methods.formState.errors).length > 0 && canFocus) {
      // Sort inputs based on their position on the page. (the order will be based on validaton order otherwise)
      const elements = Object.keys(flat(methods.formState.errors))
        // Each react-hook-form error has a message prop, so use it to indicate the fields of the flattened paths
        .filter((a) => a.includes(".message"))
        .map((a) => a.split(".message")[0])
        .filter((a): a is string => a != null)
        .map(
          (nameOrId) =>
            document.getElementsByName(nameOrId)[0] ??
            // eslint-disable-next-line unicorn/prefer-query-selector
            document.getElementById(nameOrId),
        )
        .filter((el): el is HTMLElement => el != null)
        .sort(
          (a, b) =>
            a.getBoundingClientRect().top - b.getBoundingClientRect().top,
        )

      const errorElement = elements[0]
      if (errorElement != null) {
        errorElement.scrollIntoView({
          block: "center",
          // Don't use smooth scrolling in chromium browsers, it stops halfway intermittently
          // eslint-disable-next-line no-extra-boolean-cast
          behavior: Boolean((window as {chrome?: unknown}).chrome)
            ? "auto"
            : "smooth",
        })
        errorElement.focus({preventScroll: true})
      }
      setCanFocus(false)
    }
  }, [methods.formState, canFocus])

  return {onError}
}
