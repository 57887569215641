import {useCallback, useMemo} from "react"
import type {PlantMaterialFilter} from "generated/graphql"
import {usePersistedReducer} from "src/utils/hooks/persisted-reducer"
import {flattenTagsFilter} from "../tags-filter"
import {flattenFamilyFilter} from "../family-filter/flatten-family-filter"
import {transformRedListStatusFilter} from "../red-list-status/transform"
import {transformNativeDistributionFilter} from "../native-distribution/transform"
import {transformLifeFormsFilter} from "../life-forms/transform"
import {transformClimateFilter} from "../climate/transform"
import {transformUserFilter} from "../user-filter/transform-user-filter"
import type {LocalTagsFilter} from "../tags-filter/schema"
import type {PlantMaterialFilterState} from "./filter-reducer"
import {defaultState, FilterActionTypes, filterReducer} from "./filter-reducer"
import {flattenLocationFilter} from "./location-filter/flatten-location-filter"
import type {MaterialFilterType} from "./types"
import {countMaterialFilters} from "./count-filters"

const transformTagsFilter = (tags: LocalTagsFilter | undefined) => {
  if (tags == null) {
    return undefined
  }

  const multiRecordFilters = [
    {
      tags: flattenTagsFilter(tags),
    },
    {
      taxaTags: flattenTagsFilter(tags),
    },
    {
      accessionTags: flattenTagsFilter(tags),
    },
  ]

  return {
    or:
      tags.is != null || tags.contains != null ? multiRecordFilters : undefined,
    and: tags.isNot == null ? undefined : multiRecordFilters,
  }
}

export const useMaterialFilters = (args?: {
  initialState?: Partial<PlantMaterialFilterState>
  persistenceKey?: string
}) => {
  const {initialState, persistenceKey} = args ?? {}
  const [filters, dispatchFilters] = usePersistedReducer(
    filterReducer,
    {
      ...defaultState,
      ...initialState,
    },
    persistenceKey,
  )

  const preparedFilters = useMemo<PlantMaterialFilter>(
    () => ({
      ...filters,
      and: filters.and.map(
        ({
          id: _,
          location,
          tags,
          family,
          accession,
          redListStatus,
          nativeDistribution,
          lifeForms,
          climate,
          creator,
          lastEditor,
          ...rest
        }) => ({
          ...rest,
          location: flattenLocationFilter(location),
          createdBy: transformUserFilter(creator),
          updatedBy: transformUserFilter(lastEditor),
          accession: {
            ...accession,
            taxon: {
              ...accession?.taxon,
              sharedScientificName: {
                ...accession?.taxon?.sharedScientificName,
                classification: flattenFamilyFilter(family),
              },
              ...transformRedListStatusFilter(redListStatus),
              ...transformNativeDistributionFilter(nativeDistribution),
              ...transformLifeFormsFilter(lifeForms),
              ...transformClimateFilter(climate),
            },
          },
          ...transformTagsFilter(tags),
        }),
      ),
    }),
    [filters],
  )

  const filterCounts = useMemo(
    () => countMaterialFilters(preparedFilters),
    [preparedFilters],
  )

  const resetFilters = useCallback(
    (filterType?: MaterialFilterType) => {
      if (filterType == null || typeof filterType !== "string") {
        dispatchFilters({
          type: FilterActionTypes.RESET_FILTERS,
          payload: initialState,
        })
      } else {
        dispatchFilters({
          type: FilterActionTypes.RESET_FILTER_TYPE,
          payload: filterType,
        })
      }
    },
    [initialState, dispatchFilters],
  )

  return {
    filters,
    preparedFilters,
    filterCounts,
    resetFilters,
    dispatchFilters,
  }
}

export type MaterialFilters = ReturnType<typeof useMaterialFilters>
