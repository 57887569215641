import {gql} from "graphql-request"
import {CORE_ORGANISATION_MEMBERS_FIELDS} from "src/features/settings/components/org/graphql"

export const TAXA_LIST_FIELDS = gql`
  ${CORE_ORGANISATION_MEMBERS_FIELDS}
  fragment TaxaListFields on Taxon {
    id
    scientificName
    authorship
    commonName
    fullyValidated
    nativeDistribution
    updatedBy {
      ...OrganisationMemberFields
    }
    createdBy {
      ...OrganisationMemberFields
    }
    sharedScientificName {
      id
      scientificName
      parentPrimaryRank {
        id
        scientificName
      }
      status
      nameSourceUrl
      nameSource
      rank
      authorship
      classification {
        family
      }
      taxon {
        nativeDistribution
        climate
        globalRedListStatus
        lifeForms {
          nodes {
            id
            name
          }
        }
      }
    }
    tagsConnection {
      nodes {
        id
        name
      }
    }
    notes
    updatedAt
  }
`

export const GET_TAXA = gql`
  ${TAXA_LIST_FIELDS}
  query GetTaxa(
    $before: String
    $after: String
    $first: Int
    $last: Int
    $organisationSubdomain: String!
    $searchTerm: TaxaSearchTerm
    $orderBy: TaxaOrderByInput
    $filter: TaxaFilter
    $includeArchived: Boolean
  ) {
    org: organisationBySubdomain(subdomain: $organisationSubdomain) {
      total: taxaTotal(
        searchTerm: $searchTerm
        filter: $filter
        includeArchived: $includeArchived
      )
      result: taxa(
        before: $before
        after: $after
        first: $first
        last: $last
        searchTerm: $searchTerm
        orderBy: $orderBy
        filter: $filter
        includeArchived: $includeArchived
      ) {
        nodes {
          ...TaxaListFields
        }
        pageInfo {
          ...PageInfoFields
        }
      }
    }
  }
`

export const GET_ORG_LIFE_FORMS = gql`
  query GetOrgLifeForms($organisationSubdomain: String!) {
    org: organisationBySubdomain(subdomain: $organisationSubdomain) {
      lifeForms {
        nodes {
          id
          name
        }
        pageInfo {
          ...PageInfoFields
        }
      }
    }
  }
`
export const GET_SITE_LIFE_FORMS = gql`
  query GetSiteLifeForms(
    $organisationSubdomain: String!
    $collectionSiteSlug: String!
  ) {
    org: organisationBySubdomain(subdomain: $organisationSubdomain) {
      site: collectionSiteBySlug(slug: $collectionSiteSlug) {
        lifeForms {
          nodes {
            id
            name
          }
          pageInfo {
            ...PageInfoFields
          }
        }
      }
    }
  }
`

export const GET_ORG_RED_LIST_STATUS_COUNTS = gql`
  query GetOrgRedListStatusCounts($organisationSubdomain: String!) {
    org: organisationBySubdomain(subdomain: $organisationSubdomain) {
      redListStatusCounts {
        redListStatus
        count
      }
    }
  }
`

export const GET_SITE_RED_LIST_STATUS_COUNTS = gql`
  query GetSiteRedListStatusCounts(
    $organisationSubdomain: String!
    $collectionSiteSlug: String!
  ) {
    org: organisationBySubdomain(subdomain: $organisationSubdomain) {
      site: collectionSiteBySlug(slug: $collectionSiteSlug) {
        redListStatusCounts {
          redListStatus
          count
        }
      }
    }
  }
`
