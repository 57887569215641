import {createContext, useContext, useMemo} from "react"
import {twMerge} from "tailwind-merge"
import useRecordTableOfContents, {
  type RecordSectionId,
} from "./use-records-table-of-contents"

const ContentsContext = createContext<{
  selected: RecordSectionId
  handleSelect: (href: RecordSectionId) => void
}>({
  selected: "details",
  handleSelect: () => null,
})
const ContentsTable = ({
  children,
  sectionAnchors,
}: {
  children: React.ReactNode
  sectionAnchors: Array<RecordSectionId>
}) => {
  const [selected, handleSelect] = useRecordTableOfContents(sectionAnchors)

  const contextValue = useMemo(
    () => ({selected, handleSelect}),
    [selected, handleSelect],
  )
  return (
    <ContentsContext.Provider value={contextValue}>
      <div className="flex w-[188px] flex-col gap-1 text-sm font-medium text-grey-500">
        {children}
      </div>
    </ContentsContext.Provider>
  )
}

const ContentsTableItem = ({
  children,
  className,
  href,
}: {
  children: React.ReactNode
  className?: string
  href: RecordSectionId
}) => {
  const {selected, handleSelect} = useContext(ContentsContext)
  const isActive = selected === href

  return (
    <a
      className={twMerge(
        "flex h-9 items-center gap-2 rounded-md px-3 text-sm font-medium mix-blend-multiply transition-colors hover:bg-grey-50 hover:text-grey-900",
        className,
        isActive && "bg-grey-50 text-grey-900",
      )}
      href={`#${href}`}
      data-cy={`table-of-contents-${href}`}
      onClick={(e) => {
        e.preventDefault()
        handleSelect(href)
      }}
    >
      {children}
    </a>
  )
}

export {ContentsTable, ContentsTableItem}
