import {useEffect, useRef, useState} from "react"
import {useWindowResize} from "./window-resize"

export const useLayoutHeight = () => {
  const listContainerRef = useRef<HTMLDivElement>(null)
  const listHeaderRef = useRef<HTMLDivElement>(null)
  const [listHeight, setListHeight] = useState(0)
  const {dimensions} = useWindowResize()

  useEffect(() => {
    const listContainerNode = listContainerRef.current
    const listHeaderNode = listHeaderRef.current
    if (listContainerNode != null && listHeaderNode != null) {
      // we don't need positive changes in height just negative changes
      // so we clamp the change in height to 0
      // then use Math.abs to change a negative value to a positive
      const clampedChangeInWindowHeight = Math.abs(
        Math.min(0, dimensions.deltaHeight),
      )

      const newHeight =
        listContainerNode.offsetHeight -
        listHeaderNode.offsetHeight -
        clampedChangeInWindowHeight -
        4

      setListHeight(newHeight)
    }
  }, [dimensions.deltaHeight])

  return {listHeight, listContainerRef, listHeaderRef}
}
