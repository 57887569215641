import {twMerge} from "tailwind-merge"
import type {TagRowProps} from "../fixed-list-tag-row"
import {renderTagRowWithComponent} from "../fixed-list-tag-row"
import {TagActionsMenu} from "../mobile/tags-mobile-row"

export const TagsTableRow = ({tag, canEdit}: TagRowProps) => {
  return (
    <div
      className={twMerge(
        "tag-row",
        "grid h-16 grid-cols-12 border-b border-grey-200",
      )}
    >
      <div className="col-span-12 flex items-center gap-3 px-6 py-4">
        <div
          data-cy="tag-name"
          className="flex-1 truncate text-sm font-medium text-grey-900"
        >
          {tag.name}
        </div>
        {canEdit && <TagActionsMenu tag={tag} />}
      </div>
    </div>
  )
}

export const renderTagTableRow = renderTagRowWithComponent(TagsTableRow)
