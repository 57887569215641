import {gql} from "graphql-request"
import {CORE_ORGANISATION_FIELDS} from "../../graphql"

export const CORE_ORGANISATION_MEMBERS_FIELDS = gql`
  fragment OrganisationMemberFields on OrganisationMember {
    id
    initials
    email
    givenName
    familyName
    avatarUrl
    isActivated
    accessRole
    deactivatedAt
  }
`

export const GET_ORGANISATION_MEMBERS = gql`
  ${CORE_ORGANISATION_MEMBERS_FIELDS}
  query GetOrganisationMembers(
    $organisationSubdomain: String!
    $includeDeactivated: Boolean
  ) {
    org: organisationBySubdomain(subdomain: $organisationSubdomain) {
      members(includeDeactivated: $includeDeactivated) {
        ...OrganisationMemberFields
      }
    }
  }
`

export const GET_SITE_MEMBERS = gql`
  ${CORE_ORGANISATION_MEMBERS_FIELDS}
  query SiteMembers(
    $organisationSubdomain: String!
    $collectionSiteSlug: String!
    $includeDeactivated: Boolean
  ) {
    org: organisationBySubdomain(subdomain: $organisationSubdomain) {
      site: collectionSiteBySlug(slug: $collectionSiteSlug) {
        id
        name
        members(includeDeactivated: $includeDeactivated) {
          ...OrganisationMemberFields
        }
      }
    }
  }
`

export const INVITE_MEMBERS = gql`
  ${CORE_ORGANISATION_MEMBERS_FIELDS}
  mutation InviteMembers(
    $organisationId: ID!
    $members: [InvitedMember!]!
    $addToAllSites: Boolean!
  ) {
    inviteMembers(
      organisationId: $organisationId
      members: $members
      addToAllSites: $addToAllSites
    ) {
      __typename
      ... on InviteMembersSuccess {
        members {
          ...OrganisationMemberFields
        }
      }
      ... on GenericError {
        message
      }
      ... on DuplicateEmailsError {
        message
        duplicateEmails
      }
    }
  }
`

export const SUBSCRIPTION_FIELDS = gql`
  fragment SubscriptionFields on Subscription {
    id
    createdAt
    updatedAt
    createdManually
    flexibleDays
    seats
    canceledAt
    renewsAt
    cycle
    plan
  }
`

export const GET_SUBSCRIPTION = gql`
  ${SUBSCRIPTION_FIELDS}
  query GetSubscription($organisationSubdomain: String!) {
    org: organisationBySubdomain(subdomain: $organisationSubdomain) {
      billingAccountStatus
      trialEnd
      subscription {
        ...SubscriptionFields
      }
    }
  }
`

export const UPDATE_USER_ACCESS_ROLE = gql`
  mutation UpdateUserAccessRole($userId: ID!, $accessRole: AccessRole!) {
    updateUserAccessRole(userId: $userId, accessRole: $accessRole) {
      success
    }
  }
`

export const UPDATE_ORGANISATION = gql`
  ${CORE_ORGANISATION_FIELDS}
  mutation UpdateOrganisation($id: ID!, $input: OrganisationUpdateInput!) {
    updateOrganisation(id: $id, input: $input) {
      success
      organisation {
        ...OrganisationFields
      }
    }
  }
`
export const UPLOAD_ORGANISATION_LOGO = gql`
  ${CORE_ORGANISATION_FIELDS}
  mutation UploadOrganisationLogo($id: ID!, $file: Upload!) {
    uploadOrganisationLogo(id: $id, file: $file) {
      success
      organisation {
        ...OrganisationFields
      }
    }
  }
`

export const DEACTIVATE_USER = gql`
  mutation DeactivateUser($userId: ID!) {
    deactivateUser(userId: $userId) {
      success
    }
  }
`
export const REACTIVATE_USER = gql`
  mutation ReactivateUser($userId: ID!) {
    reactivateUser(userId: $userId) {
      success
    }
  }
`
