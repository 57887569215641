import withAuthenticationRequired from "src/utils/with-authentication-required"
import {SiteList} from "./site-list"

export const trashSiteList = <SiteList isTrash={true} />

export const Component = withAuthenticationRequired(() => trashSiteList, {
  onRedirecting: function OnRedirect() {
    return trashSiteList
  },
})
