import type {DateComparator} from "generated/graphql"
import {isNonEmpty} from "../is-non-empty"

export const countDateComparator = (filter: NonNullable<DateComparator>) => {
  let count = 0
  if (filter.eq != null) {
    count += 1
  }
  if (filter.gt != null) {
    count += 1
  }
  if (filter.gte != null) {
    count += 1
  }
  if (filter.lt != null) {
    count += 1
  }
  if (filter.lte != null) {
    count += 1
  }
  if (filter.neq != null) {
    count += 1
  }
  if (isNonEmpty(filter.in)) {
    count += 1
  }
  if (isNonEmpty(filter.nin)) {
    count += 1
  }

  return count
}
