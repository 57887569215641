import type * as LabelPrimitive from "@radix-ui/react-label"
import {Slot} from "@radix-ui/react-slot"
import {
  Controller,
  type ControllerProps,
  type FieldPath,
  type FieldValues,
  FormProvider,
  useFormContext,
} from "react-hook-form"
import {createContext, forwardRef, useContext, useId, useMemo} from "react"
import {twMerge} from "tailwind-merge"
import {Label} from "../label"

const Form = FormProvider

type FormFieldContextValue<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  name: TName
}

const FormFieldContext = createContext<FormFieldContextValue>(
  {} as FormFieldContextValue,
)

const FormField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  ...props
}: ControllerProps<TFieldValues, TName>) => {
  return (
    <FormFieldContext.Provider
      value={useMemo(() => ({name: props.name}), [props.name])}
    >
      <Controller {...props} />
    </FormFieldContext.Provider>
  )
}

type FormItemContextValue = {
  id: string
}

const FormItemContext = createContext<FormItemContextValue>(
  {} as FormItemContextValue,
)

const FormItem = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({className, ...props}, ref) => {
  const id = useId()

  return (
    <FormItemContext.Provider value={useMemo(() => ({id}), [id])}>
      <div
        ref={ref}
        className={twMerge("flex flex-col space-y-1.5", className)}
        {...props}
      />
    </FormItemContext.Provider>
  )
})
// @ts-expect-error -- Reclared forwardRef does not have a displayName
FormItem.displayName = "FormItem"

const useFormField = () => {
  const fieldContext = useContext(FormFieldContext)
  const itemContext = useContext(FormItemContext)
  const {getFieldState, formState} = useFormContext()

  const fieldState = getFieldState(fieldContext.name, formState)

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition, @typescript-eslint/strict-boolean-expressions -- Not always the case
  if (!fieldContext) {
    throw new Error("useFormField should be used within <FormField>")
  }

  const {id} = itemContext

  return {
    id,
    name: fieldContext.name,
    formItemId: `${id}-form-item`,
    formDescriptionId: `${id}-form-item-description`,
    formMessageId: `${id}-form-item-message`,
    ...fieldState,
  }
}

const FormLabel = forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> & {
    required?: boolean
  }
>(({className, children, ...props}, ref) => {
  const {formItemId} = useFormField()

  return (
    <Label
      ref={ref}
      className={twMerge(className)}
      htmlFor={formItemId}
      {...props}
    >
      <span className="mr-1 text-orange-500">*</span>
      {children}
    </Label>
  )
})
// @ts-expect-error -- Reclared forwardRef does not have a displayName
FormLabel.displayName = "FormLabel"

const FormControl = forwardRef<
  React.ElementRef<typeof Slot>,
  React.ComponentPropsWithoutRef<typeof Slot> & {error?: boolean}
>(({...props}, ref) => {
  const {error, formItemId, formDescriptionId, formMessageId} = useFormField()

  return (
    <Slot
      ref={ref}
      id={formItemId}
      aria-describedby={
        error ? `${formDescriptionId} ${formMessageId}` : `${formDescriptionId}`
      }
      aria-invalid={Boolean(error)}
      error={Boolean(error)}
      {...props}
    />
  )
})
// @ts-expect-error -- Reclared forwardRef does not have a displayName
FormControl.displayName = "FormControl"

const FormDescription = forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({className, ...props}, ref) => {
  const {formDescriptionId, error} = useFormField()

  if (error != null) {
    return null
  }

  return (
    <p
      ref={ref}
      id={formDescriptionId}
      className={twMerge("text-grey-500 text-sm", className)}
      {...props}
    />
  )
})
// @ts-expect-error -- Reclared forwardRef does not have a displayName
FormDescription.displayName = "FormDescription"

const FormMessage = forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({className, children, ...props}, ref) => {
  const {error, formMessageId} = useFormField()
  const body = error ? String(error.message) : children

  if (body == null) {
    return null
  }

  return (
    <p
      ref={ref}
      id={formMessageId}
      className={twMerge("text-error-500 text-sm", className)}
      {...props}
    >
      {body}
    </p>
  )
})
// @ts-expect-error -- Reclared forwardRef does not have a displayName
FormMessage.displayName = "FormMessage"

export {
  useFormField,
  Form,
  FormItem,
  FormLabel,
  FormControl,
  FormDescription,
  FormMessage,
  FormField,
}
