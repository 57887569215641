import type {MaterialGroup} from "generated/graphql"
import {materialGroupLabelMap} from "src/utils/label-maps/material-group"
import type {FilterOperation, LocalMaterialFilter} from "../types"

export const extractMaterialGroupFilterData = (
  filter: NonNullable<LocalMaterialFilter["materialGroup"]>,
  operation: FilterOperation,
): Array<string> | undefined => {
  const isFilters = filter.or
    ?.flatMap(({eq}) => eq)
    .filter((val): val is MaterialGroup => val != null)
  const isNotFilters = filter.or
    ?.flatMap(({neq}) => neq)
    .filter((val): val is MaterialGroup => val != null)

  switch (operation) {
    case "is": {
      return isFilters?.map((status) => materialGroupLabelMap[status])
    }
    case "isNot": {
      return isNotFilters?.map((status) => materialGroupLabelMap[status])
    }
    default: {
      return undefined
    }
  }
}
