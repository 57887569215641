import {pipe} from "fp-ts/function"
import {useCallback} from "react"
import {useValidOrganisation} from "src/utils/hooks/organisation"
import {useSnackbarStore} from "../../../../components/snackbar-controller/snackbar-store"
import * as OE from "../../../../utils/option-either"
import {useAccessToken} from "../../../../utils/use-access-token"
import {fetchCsv} from "./fetch-csv"

export const useDownloadOrgCsv = ({
  body,
  csvName,
  path,
}: {
  body?: Record<string, unknown>
  csvName: string
  path: string
}) => {
  const organisation = useValidOrganisation()
  const {setSnack} = useSnackbarStore()
  const accessToken = pipe(useAccessToken(), OE.noneAndErrorToUndefined)

  return useCallback(async () => {
    setSnack({
      type: "action",
      data: {
        text: "Preparing download",
      },
    })
    try {
      if (accessToken === undefined) {
        throw new Error("No access token")
      }
      if (organisation === undefined) {
        throw new Error("No valid organization")
      }
      await fetchCsv({
        body: {organisationId: organisation.id, ...body},
        accessToken,
        fileName: `${organisation.name
          .toLowerCase()
          .replaceAll(" ", "-")}-${csvName}_${new Date().toISOString()}`,
        path,
      })
    } catch {
      setSnack({
        type: "alert",
        data: {
          text: "Unable to retrieve csv for download",
          severity: "error",
        },
      })
    }
    setSnack(null)
  }, [accessToken, setSnack, organisation, body, csvName, path])
}
