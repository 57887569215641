import * as _Semigroup from "fp-ts/Semigroup"
import * as Str from "fp-ts/string"
import * as NEA from "fp-ts/NonEmptyArray"

export const Semigroup = _Semigroup.intercalate("/")(Str.Semigroup)

export const concat = Semigroup.concat

export const concatAll = _Semigroup.concatAll(Semigroup)
export const concatAllNEA = NEA.concatAll(Semigroup)
