import {zodResolver} from "@hookform/resolvers/zod"
import {z} from "zod"
import {tagOptionZod} from "src/features/create-accession/components/add-plant-material-form/schema"

export const tagFilterSchema = z.object({
  is: z.array(tagOptionZod).optional(),
  isNot: z.array(tagOptionZod).optional(),
  contains: z.string().optional(),
})

export const tagFilterResolver = zodResolver(tagFilterSchema)

export type LocalTagsFilter = z.TypeOf<typeof tagFilterSchema>
