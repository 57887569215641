import {zodResolver} from "@hookform/resolvers/zod"
import {Button} from "@hortis/ui/button"
import {
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@hortis/ui/dialog"
import * as E from "fp-ts/Either"
import * as TE from "fp-ts/TaskEither"
import {pipe} from "fp-ts/function"
import type {ReactElement} from "react"
import {useEffect, useMemo} from "react"
import {FormProvider, useForm} from "react-hook-form"
import type {UseQueryExecute} from "urql"
import type {PlantMaterialStatus} from "../../../../../../generated/graphql"
import {BasicDatePicker} from "../../../../../components/date-picker/date-picker"
import {useSnackbarStore} from "../../../../../components/snackbar-controller/snackbar-store"
import {SwitchFormField} from "../../../../../components/switch"
import {deaccessionAccession} from "../../fetchers"
import {
  onUpdateAccessionFailure,
  onUpdateAccessionSuccess,
} from "../edit-drawer/utils"
import type {DeaccessionForm} from "./deaccession-form-schema"
import {DeaccessionFormSchema, errorMap} from "./deaccession-form-schema"
import {countNonAbsent, getMaterialSwitchLabel} from "./utils"

const resolver = zodResolver(DeaccessionFormSchema, {errorMap})

const deaccessionAccessionTask = TE.tryCatchK(deaccessionAccession, E.toError)

interface DeaccessionDialogProps {
  accessionId: string
  accessToken: string
  materialStatuses: ReadonlyArray<PlantMaterialStatus>
  refetch: UseQueryExecute
  open: boolean
  onClose: () => void
}

export const DeaccessionDialogContent = ({
  open,
  accessionId,
  accessToken,
  materialStatuses,
  refetch,
  onClose,
}: DeaccessionDialogProps): ReactElement => {
  const formMethods = useForm<DeaccessionForm>({
    defaultValues: {
      deaccessionDate: new Date(),
      setPlantMaterialsToAbsent: false,
    },
    criteriaMode: "all",
    resolver,
  })

  const {setSnack} = useSnackbarStore()

  useEffect(() => {
    formMethods.reset()
  }, [open, formMethods])

  const onSubmit = useMemo(
    () =>
      formMethods.handleSubmit(async (data) => {
        // eslint-disable-next-line no-console
        console.log("Submitting form:", data)

        await pipe(
          deaccessionAccessionTask(accessToken, {
            accessionId,
            deaccessionDate: data.deaccessionDate.toISOString(),
            setPlantMaterialsToAbsent: data.setPlantMaterialsToAbsent,
          }),
          TE.match(
            onUpdateAccessionFailure(setSnack),
            onUpdateAccessionSuccess(
              setSnack,
              "Accession has been deaccessioned",
              refetch,
            ),
          ),
        )()

        onClose()
        formMethods.reset()
      }),
    [accessToken, formMethods, onClose, accessionId, setSnack, refetch],
  )

  return (
    <DialogContent data-cy="deaccession-dialog">
      <FormProvider {...formMethods}>
        <form onSubmit={onSubmit}>
          <DialogHeader>
            <DialogTitle>Deaccession accession?</DialogTitle>
            <DialogDescription>
              This accession will no longer be considered part of the
              collection. However, you can reinstate it at any time.
            </DialogDescription>
          </DialogHeader>
          <div className="flex flex-col gap-4 py-6">
            <BasicDatePicker
              name="deaccessionDate"
              label="Deaccession date"
              testId="deaccession-date"
              PopperProps={{disablePortal: true}}
            />
            {countNonAbsent(materialStatuses) === 0 ? (
              <DialogDescription>
                All associated plant materials are absent. Their status will not
                change.
              </DialogDescription>
            ) : (
              <SwitchFormField
                name="setPlantMaterialsToAbsent"
                label={getMaterialSwitchLabel(materialStatuses)}
                labelPlacement="start"
                sx={{
                  px: 2,
                  gap: 1,
                  "& .MuiTypography-root": {
                    fontSize: "14px",
                    fontWeight: 500,
                  },
                }}
                testId="absent-plant-material-switch"
              />
            )}
          </div>
          <DialogFooter>
            <Button onClick={onClose} testId="cancel-deaccession-dialog">
              Cancel
            </Button>
            <Button
              variant="primary"
              type="submit"
              loading={formMethods.formState.isSubmitting}
              data-cy="submit-deaccession-dialog"
            >
              Deaccession
            </Button>
          </DialogFooter>
        </form>
      </FormProvider>
    </DialogContent>
  )
}
