import {zodResolver} from "@hookform/resolvers/zod"
import * as A from "fp-ts/Array"
import {useMemo} from "react"
import {FormProvider} from "react-hook-form"
import {match} from "ts-pattern"
import {z} from "zod"
import {CollapsableRadioButton, RadioGroup} from "src/components/radio"
import {FilterTitle} from "src/features/filters/components/modal/filter-title"
import {ChipGridStylingPreset, FormChipGrid} from "src/components/chip-grid"
import {ProvenanceCategoryCode} from "generated/graphql"
import type {ProvenanceFilter} from "generated/graphql"
import type {FilterModalProps} from "../../components/modal/filter-modal"
import {FilterModal} from "../../components/modal/filter-modal"
import {FilterModalButtons} from "../../components/modal/modal-buttons"
import {FilterModalError} from "../../components/modal/modal-error"
import {useFilterForm} from "../../components/modal/use-filter-form"
import {determineInitialOperation} from "../../plant-materials/modals/determine-initial-operation"

export const provenanceChips = [
  {label: "Wild", value: ProvenanceCategoryCode.W},
  {label: "Garden", value: ProvenanceCategoryCode.G},
  {label: "Garden - Wild origin", value: ProvenanceCategoryCode.Z},
  {label: "Uncertain", value: ProvenanceCategoryCode.U},
]

const createDefaultValues = (initialValue?: NonNullable<ProvenanceFilter>) => {
  const defaultValues = {
    is:
      initialValue?.or == null
        ? []
        : initialValue.or.flatMap(({eq}) => eq).filter((val) => val != null),
    isNot:
      initialValue?.or == null
        ? []
        : initialValue.or.flatMap(({neq}) => neq).filter((val) => val != null),
  }
  return {
    defaultValues,
    initialOperation: determineInitialOperation(defaultValues, "is"),
  }
}

const provenanceCategory = z.nativeEnum(ProvenanceCategoryCode)
const resolver = zodResolver(
  z.object({
    is: z.array(provenanceCategory),
    isNot: z.array(provenanceCategory),
  }),
)

interface ProveanceFilterFormProps {
  addModifyFilter: (changes: ProvenanceFilter) => void
  deleteFilter: (() => void) | undefined
  initialValue?: ProvenanceFilter | null | undefined
  onClose: () => void
}

export const ProvenanceFilterForm = ({
  initialValue,
  onClose,
  addModifyFilter,
  deleteFilter,
}: ProveanceFilterFormProps) => {
  const {error, setError, operation, handleRadioChange, formMethods} =
    useFilterForm({
      resolver,
      ...createDefaultValues(initialValue ?? undefined),
    })

  const onSubmit = useMemo(
    () =>
      formMethods.handleSubmit((values) => {
        match(operation)
          .with("is", () => {
            if (A.isNonEmpty(values.is)) {
              addModifyFilter({
                or: values.is.map((val) => ({
                  eq: val,
                })),
              })
              onClose()
            } else {
              setError("Select a provenance to continue")
            }
          })
          .with("isNot", () => {
            if (A.isNonEmpty(values.isNot)) {
              addModifyFilter({
                or: values.isNot.map((val) => ({
                  neq: val,
                })),
              })
              onClose()
            } else {
              setError("Select a provenance to continue")
            }
          })
          .run()
      }),
    [formMethods, operation, onClose, addModifyFilter, setError],
  )

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={onSubmit}>
        <div className="mb-4 flex flex-col gap-4">
          <FilterTitle title="Provenance" deleteFilter={deleteFilter} />
          <FilterModalError error={error} />
          <RadioGroup
            value={operation}
            onChange={handleRadioChange}
            label="Operation"
          >
            {(props) => (
              <>
                <CollapsableRadioButton
                  selectedValue="is"
                  testId="provenance-is-radio"
                  label="Is"
                  {...props}
                >
                  <FormChipGrid
                    name="is"
                    testId="provenance-is-chip-grid"
                    stylingPreset={ChipGridStylingPreset.BASIC_GRID}
                    chips={provenanceChips}
                    multiple
                  />
                </CollapsableRadioButton>
                <CollapsableRadioButton
                  selectedValue="isNot"
                  testId="provenance-is-not-radio"
                  label="Is not"
                  {...props}
                >
                  <FormChipGrid
                    name="isNot"
                    testId="provenance-is-not-chip-grid"
                    stylingPreset={ChipGridStylingPreset.BASIC_GRID}
                    chips={provenanceChips}
                    multiple
                  />
                </CollapsableRadioButton>
              </>
            )}
          </RadioGroup>
        </div>
        <FilterModalButtons onCancel={onClose} testIdPrefix="provenance" />
      </form>
    </FormProvider>
  )
}

// Seperated from form component to make sure popover unmount causes state reset
export const ProvenanceFilterModal = ({
  open,
  onClose,
  anchorEl,
  ...props
}: FilterModalProps & ProveanceFilterFormProps) => (
  <FilterModal anchorEl={anchorEl} open={open} onClose={onClose}>
    <ProvenanceFilterForm onClose={onClose} {...props} />
  </FilterModal>
)
