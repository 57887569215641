import type {IconProps} from "./types"

export const SlLandscapeIcon = ({
  style,
  width,
  height,
  color,
  className,
}: IconProps): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? "24"}
      height={height ?? "24"}
      viewBox="0 0 24 24"
      fill="none"
      className={className}
      style={style}
    >
      <path
        d="M8.27783 3.26709C8.22305 3.18493 8.14883 3.11756 8.06176 3.07097C7.97469 3.02438 7.87747 3 7.77872 3C7.67997 3 7.58275 3.02438 7.49568 3.07097C7.40861 3.11756 7.33439 3.18493 7.27961 3.26709L4.17935 8.39899H5.97903L3.65944 11.7008C3.60736 11.792 3.58013 11.8952 3.58045 12.0002C3.58077 12.1052 3.60863 12.2082 3.66126 12.2991C3.71389 12.3899 3.78943 12.4654 3.88035 12.5179C3.97126 12.5704 4.07436 12.5981 4.17935 12.5983H11.3781C11.4831 12.5982 11.5863 12.5706 11.6774 12.5182C11.7684 12.4657 11.8441 12.3903 11.8968 12.2994C11.9495 12.2086 11.9774 12.1054 11.9778 12.0004C11.9781 11.8953 11.9509 11.792 11.8988 11.7008L9.57841 8.39899H11.3781L8.27783 3.26709Z"
        stroke={color ?? "#424242"}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.77869 12.5984V16.2634"
        stroke={color ?? "#424242"}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.7865 3.21824C17.7416 3.15109 17.6809 3.09605 17.6097 3.05798C17.5385 3.01992 17.4589 3 17.3782 3C17.2974 3 17.2179 3.01992 17.1467 3.05798C17.0755 3.09605 17.0147 3.15109 16.9699 3.21824L14.4327 7.19915H15.9052L13.8432 10.0642C13.8004 10.1389 13.778 10.2235 13.7782 10.3095C13.7784 10.3955 13.8013 10.48 13.8445 10.5544C13.8877 10.6288 13.9497 10.6905 14.0243 10.7334C14.0989 10.7762 14.1835 10.7987 14.2695 10.7985H20.4844C20.5705 10.7987 20.655 10.7762 20.7297 10.7334C20.8043 10.6905 20.8663 10.6288 20.9095 10.5544C20.9527 10.48 20.9755 10.3955 20.9758 10.3095C20.976 10.2235 20.9536 10.1389 20.9108 10.0642L18.8503 7.19915H20.3229L17.7865 3.21824Z"
        stroke={color ?? "#424242"}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.3779 10.7986V14.026"
        stroke={color ?? "#424242"}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.02356 16.7976C5.28362 16.0122 7.74037 15.9987 10.0089 16.7591C12.2775 17.5196 14.2297 19.0111 15.5598 21"
        stroke={color ?? "#424242"}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.1533 17.2119C13.4869 15.0153 16.5719 13.794 19.7766 13.7981C20.1813 13.7981 20.5813 13.8168 20.9764 13.8541"
        stroke={color ?? "#424242"}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
