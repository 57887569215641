import type {NullableScientificNameFilter} from "generated/graphql"
import type {LocalValidationFilter} from "./schema"

export const flattenValidationFilter = (
  filter: LocalValidationFilter | undefined,
): NullableScientificNameFilter | undefined =>
  filter == null
    ? undefined
    : {
        null: {eq: filter.isUnvalidated},
        rank: {or: filter.isRank?.map((rank) => ({eq: rank}))},
        status: {or: filter.isStatus?.map((status) => ({eq: status}))},
      }
