import {Fragment} from "react"
import type {ProvenanceFilter} from "generated/graphql"
import {FilterChip} from "../../filter-chip-group"
import type {OpenFilterArgs} from "../../plant-materials/filters"
import {extractProvenanceFilterData} from "./extract-provenance-filter-data"

export const ProvenanceChipGroup = <FilterType extends "provenance">({
  id,
  filter,
  hidden,
  ...props
}: {
  id: string
  handleOpenFilter: (args: OpenFilterArgs<FilterType>) => void
  handleDelete: (id: string) => void
  filter: ProvenanceFilter | null | undefined
  hidden?: boolean
}) => {
  const isFilters = filter?.or
    ?.flatMap(({eq}) => eq)
    .filter((val) => val != null)
  const isNotFilters = filter?.or
    ?.flatMap(({neq}) => neq)
    .filter((val) => val != null)

  return filter == null || hidden === true ? (
    <Fragment />
  ) : (
    <>
      {isFilters != null && isFilters.length > 0 && (
        <FilterChip
          filterId={id}
          filterType={"provenance" as FilterType}
          filterData={extractProvenanceFilterData(filter, "is")}
          operation="is"
          filterLabel="Provenance"
          {...props}
        />
      )}
      {isNotFilters != null && isNotFilters.length > 0 && (
        <FilterChip
          filterId={id}
          filterType={"provenance" as FilterType}
          filterData={extractProvenanceFilterData(filter, "isNot")}
          operation="is not"
          filterLabel="Provenance"
          {...props}
        />
      )}
    </>
  )
}
