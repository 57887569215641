import type {NullableStringComparator, TaxaFilter} from "generated/graphql"

export const transformNativeDistributionFilter = (
  filter: NullableStringComparator | undefined,
): TaxaFilter =>
  filter == null
    ? {}
    : {
        or:
          filter.contains == null
            ? null
            : [
                {
                  nativeDistribution: {contains: filter.contains},
                },
                {
                  fullyValidated: {eq: true},
                  nativeDistribution: {null: true},
                  sharedScientificName: {
                    taxon: {nativeDistribution: {contains: filter.contains}},
                  },
                },
              ],
        ...(filter.null === true
          ? {
              or: [
                {
                  fullyValidated: {eq: false},
                  nativeDistribution: {null: true},
                },
                {
                  fullyValidated: {eq: true},
                  sharedScientificName: {
                    taxon: {nativeDistribution: {null: true}},
                  },
                },
              ],
            }
          : {}),
      }
