import {Fragment} from "react"
import {taxonomicRankLabelMap} from "src/utils/label-maps/taxonomic-rank"
import {taxonomicStatusLabelMap} from "src/utils/label-maps/taxonomic-status"
import {FilterChip} from "../../filter-chip-group"
import type {OpenFilterArgs} from "../../plant-materials/filters"
import type {LocalValidationFilter} from "./schema"

export const ValidationChipGroup = <T extends string>({
  id,
  filter,
  hidden,
  ...props
}: {
  id: string
  handleOpenFilter: (args: OpenFilterArgs<T>) => void
  handleDelete: (id: string) => void
  filter: LocalValidationFilter | undefined
  filterType: T
  hidden?: boolean
}) =>
  filter == null || hidden === true ? (
    <Fragment />
  ) : (
    <>
      {filter.isRank != null && filter.isRank.length > 0 && (
        <FilterChip
          filterLabel="Validation rank"
          filterId={id}
          filterData={filter.isRank.map((rank) => taxonomicRankLabelMap[rank])}
          operation="is"
          {...props}
        />
      )}
      {filter.isStatus != null && filter.isStatus.length > 0 && (
        <FilterChip
          filterLabel="Validation status"
          filterId={id}
          filterData={filter.isStatus.map(
            (status) => taxonomicStatusLabelMap[status],
          )}
          operation="is"
          {...props}
        />
      )}
      {filter.isUnvalidated === true && (
        <FilterChip
          filterLabel="Validation"
          filterId={id}
          filterData={["unvalidated"]}
          operation="is"
          {...props}
        />
      )}
    </>
  )
