import {toError} from "fp-ts/Either"
import {flow, pipe} from "fp-ts/function"
import * as O from "fp-ts/Option"
import * as Rec from "fp-ts/Record"
import * as t from "io-ts"
import {JsonFromString} from "io-ts-types"
import type {TypeOf} from "ts-expect"
import {expectType} from "ts-expect"
import type {List} from "ts-toolbelt"
import type {
  GeographicPosition,
  GetCollectionGeometryQuery,
  GetCollectionGeometryQueryVariables,
  GetCollectionGeometryWithinCircleQuery,
  GetCollectionGeometryWithinCircleQueryVariables,
  GetMapConfigQuery,
  GetMapConfigQueryVariables,
  MaterialFieldsFragment,
  PlantMaterialsQueryVariables,
  PlantMaterialsSearchTerm,
} from "../../../generated/graphql"
import {GeoJsonFeatureType} from "../../../generated/graphql"
import {flowInto} from "../../utils/flow"
import {authedFetcher} from "../../utils/gql-client"
import {createAuthedHookWithMappedStruct} from "../../utils/hooks/hook"
import {extractOrg, usePlace} from "../../utils/hooks/place"
import * as OE from "../../utils/option-either"
import type {GetSWRConfiguration} from "../../utils/swr"
import {createDeepMemoisedValue} from "../../utils/hooks/memoise-value"
import {createWrappedSWRHook} from "../../utils/swr"
import {useAccessToken} from "../../utils/use-access-token"
import {
  GET_COLLECTION_GEOMETRY,
  GET_COLLECTION_GEOMETRY_WITHIN_CIRCLE,
  GET_MAP_CONFIG,
} from "./graphql"
import {addFeature, setFeature} from "./utils"

const mapConfig = authedFetcher<GetMapConfigQueryVariables, GetMapConfigQuery>(
  GET_MAP_CONFIG,
)("GetMapConfig")

const _useMapConfig = createWrappedSWRHook(mapConfig, toError)

export const useMapConfig = flow(
  pipe(
    _useMapConfig,
    flowInto(OE.chainW(extractOrg)),
    createAuthedHookWithMappedStruct,
    (useCollectionGeometry) =>
      (config?: GetSWRConfiguration<typeof _useMapConfig>) =>
        useCollectionGeometry({place: usePlace()}, ({place}) => [
          place === undefined
            ? undefined
            : {
                organisationSubdomain: place.orgName,
                collectionSiteSlug: place.siteSlug,
              },
          config,
        ]),
  ),
  createDeepMemoisedValue(
    OE.chainOptionToErrorKW(new Error("Missing map config"))((data) =>
      pipe(
        O.Do,
        O.apS("key", O.fromNullable(data.map?.key)),
        O.apS("site", O.fromNullable(data.site)),
        O.bind("siteName", ({site}) => O.fromNullable(site.name)),
        O.bind("baseMap", ({site}) => O.of(site.baseMap ?? null)),
        O.bind(
          "initialViewState",
          ({site: {mapCenterLatitude, mapCenterLongitude, mapZoomLevel}}) =>
            O.of(
              mapCenterLatitude != null &&
                mapCenterLongitude != null &&
                mapZoomLevel != null
                ? {
                    latitude: mapCenterLatitude,
                    longitude: mapCenterLongitude,
                    zoom: mapZoomLevel,
                  }
                : undefined,
            ),
        ),
      ),
    ),
  ),
)

export const useRefetchMapConfig = () => {
  const accessToken = useAccessToken()
  const place = usePlace()

  return pipe(
    OE.Do,
    OE.apSW("accessToken", accessToken),
    OE.apSW("place", place),
    OE.matchW(
      () => null,
      () => null,
      ({accessToken, place}) => {
        return async () => {
          await _useMapConfig.prefetch(accessToken, {
            organisationSubdomain: place.orgName,
            collectionSiteSlug: place.siteSlug,
          })
        }
      },
    ),
  )
}

const collectionGeometry = authedFetcher<
  GetCollectionGeometryQueryVariables,
  GetCollectionGeometryQuery
>(GET_COLLECTION_GEOMETRY)("GetCollectionGeometry")

const _useCollectionGeometry = createWrappedSWRHook(collectionGeometry, toError)

export const useCollectionGeometry = flow(
  pipe(
    _useCollectionGeometry,
    flowInto(OE.chainW(extractOrg)),
    createAuthedHookWithMappedStruct,
    (useCollectionGeometry) =>
      (
        searchTerm?: PlantMaterialsQueryVariables["searchTerm"],
        filters?: PlantMaterialsQueryVariables["filters"],
        config?: GetSWRConfiguration<typeof _useCollectionGeometry>,
      ) =>
        useCollectionGeometry({place: usePlace()}, ({place}) => [
          place === undefined
            ? undefined
            : {
                organisationSubdomain: place.orgName,
                collectionSiteSlug: place.siteSlug,
                filters: filters ?? null,
                searchTerm: searchTerm?.value === "" ? null : searchTerm,
              },
          config,
        ]),
  ),
  OE.chainOptionToErrorKW(new Error("Missing geometry"))((data) =>
    pipe(
      O.Do,
      O.apS("site", O.fromNullable(data.site)),
      O.bind("geometry", ({site}) =>
        O.fromNullable(site.plantMaterialsGeometry),
      ),
      O.bind("siteName", ({site}) => O.fromNullable(site.name)),
      O.bind("baseMap", ({site}) => O.of(site.baseMap ?? null)),
    ),
  ),
)

const collectionGeometryWithinCircle = authedFetcher<
  GetCollectionGeometryWithinCircleQueryVariables,
  GetCollectionGeometryWithinCircleQuery
>(GET_COLLECTION_GEOMETRY_WITHIN_CIRCLE)("GetCollectionGeometryWithinCircle")

const _useCollectionGeometryWithinCircle = createWrappedSWRHook(
  collectionGeometryWithinCircle,
  toError,
)

export const useCollectionGeometryWithinCircle = flow(
  pipe(
    _useCollectionGeometryWithinCircle,
    flowInto(OE.chainW(extractOrg)),
    createAuthedHookWithMappedStruct,
    (useCollectionGeometryWithinCircle) =>
      (
        bounds?: GetCollectionGeometryWithinCircleQueryVariables["bounds"],
        searchTerm?: PlantMaterialsQueryVariables["searchTerm"],
        config?: GetSWRConfiguration<typeof _useCollectionGeometryWithinCircle>,
      ) =>
        useCollectionGeometryWithinCircle({place: usePlace()}, ({place}) => [
          place !== undefined && bounds !== undefined
            ? {
                organisationSubdomain: place.orgName,
                collectionSiteSlug: place.siteSlug,
                searchTerm: searchTerm?.value === "" ? null : searchTerm,
                bounds,
              }
            : undefined,
          config,
        ]),
  ),
  OE.chainOptionToErrorKW(new Error("Missing bounded geometry"))((data) =>
    pipe(
      O.Do,
      O.apS("site", O.fromNullable(data.site)),
      O.bind("geometry", ({site}) =>
        O.fromNullable(site.plantMaterialsGeometryWithinCircleWIP),
      ),
      O.bind("siteName", ({site}) => O.fromNullable(site.name)),
    ),
  ),
)

export const useUpdateGeography = (
  searchTerm?: PlantMaterialsSearchTerm,
  filters?: PlantMaterialsQueryVariables["filters"],
): [
  UseCollectionGeometryResult,
  (
    | undefined
    | ((
        material: Omit<MaterialFieldsFragment, "accession"> & {
          position: GeographicPosition
          accession: NonNullable<
            Required<MaterialFieldsFragment>["accession"]
          > & {scientificName: string}
        },
        config?: {appendToGeometry?: boolean},
      ) => Promise<undefined>)
  ),
] => [
  useCollectionGeometry(searchTerm, filters),
  pipe(
    OE.Do,
    OE.apSW("accessToken", useAccessToken()),
    OE.apSW("place", usePlace()),
    OE.matchW(
      () => undefined,
      () => undefined,
      ({accessToken, place}) => {
        const vars = {
          organisationSubdomain: place.orgName,
          collectionSiteSlug: place.siteSlug,
          searchTerm,
          filters,
        }

        return async (material, config) => {
          const feature = {
            type: GeoJsonFeatureType.Feature,
            geometry: {
              type: "Point" as const,
              coordinates: [
                material.position.longitude,
                material.position.latitude,
              ],
            },
            properties: {
              id: material.id,
              adHoc: {
                id: material.id,
                qualifier: material.qualifier,
                scientificName: material.accession.scientificName,
                accessionNumber: material.accession.accessionNumber,
              },
            },
          }
          const updateGeometry =
            config?.appendToGeometry === true
              ? addFeature(feature)
              : setFeature(material.id, feature)

          await _useCollectionGeometry.optimisticUpdate(
            [accessToken, vars],
            (query) => (query == null ? undefined : updateGeometry(query)),
          )

          return undefined
        }
      },
    ),
  ),
]
export type UpdateGeography = ReturnType<typeof useUpdateGeography>[1]

export type UseCollectionGeometryResult = ReturnType<
  typeof useCollectionGeometry
>
export type UseCollectionGeometrySuccess =
  OE.ExtractRight<UseCollectionGeometryResult>

export type Properties = List.UnionOf<
  NonNullable<
    NonNullable<UseCollectionGeometrySuccess["site"]>["plantMaterialsGeometry"]
  >["features"]
>["properties"]

// maplibre JSON.stringifies deeply nested properties!
const ParseJSON = t.string.pipe(JsonFromString)

type _Properties = Omit<Properties, "__typename" | "id">
export const getGeoJsonProperties = <
  Fields extends Record<keyof _Properties, t.Mixed>,
>(
  fields: NonNullable<_Properties> extends {
    [Key in keyof Fields]?:
      | null
      | undefined
      | t.TypeOf<Fields[Key] extends t.Mixed ? Fields[Key] : never>
  }
    ? Fields
    : never,
): t.PartialC<{
  [Key in keyof Fields & keyof _Properties]: t.UnionC<[t.NullC, Fields[Key]]>
}> =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  t.partial(
    pipe(
      fields,
      Rec.map((Codec) => t.union([t.null, ParseJSON.pipe(Codec)])),
    ),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- hard to convince TS this is fine
  ) as any

export const IOPlantMaterialGeoJsonProperties = getGeoJsonProperties({
  adHoc: t.type({
    id: t.string,
    accessionNumber: t.string,
    qualifier: t.string,
    scientificName: t.string,
  }),
})
// assert that the codec is at most a subset of the graphql response (that we assume this is using)
expectType<
  TypeOf<t.TypeOf<typeof IOPlantMaterialGeoJsonProperties>, Properties>
>(true)
