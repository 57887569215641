import type {FormControlLabelProps} from "@mui/material/FormControlLabel"
import CoreFormControlLabel from "@mui/material/FormControlLabel"
import CoreSwitch from "@mui/material/Switch"
import {flow} from "fp-ts/function"
import {useController} from "react-hook-form"
import {applyMui} from "../../utils/apply-mui"
import {contramapComponent} from "../../utils/profunctor"
import {withTestId} from "../../utils/with-test-id"
import {Toggle} from "../toggle"

export const Switch = applyMui(withTestId, CoreSwitch)

const controlSwitch = <Toggle />

export const SwitchField = applyMui(
  flow(
    contramapComponent<
      Omit<FormControlLabelProps, "control">,
      FormControlLabelProps
    >((props) => ({
      ...props,
      control: controlSwitch,
    })),
    withTestId,
  ),
  CoreFormControlLabel,
)

interface FormFieldProps extends Omit<FormControlLabelProps, "control"> {
  name: string
  label: string
  initialValue?: boolean
}
// best we can do because of limitations of react hook form
// and generic string 'literals'
type FormValues = {[Key in string]: boolean}

export const SwitchFormField = withTestId<FormFieldProps>(
  ({name, label, initialValue, ...rest}) => {
    const {
      field: {value, onChange},
    } = useController<FormValues>({
      name,
      defaultValue: initialValue,
    })

    return (
      <SwitchField
        label={label}
        checked={value}
        onChange={onChange}
        {...rest}
      />
    )
  },
)
