import {useCallback} from "react"
import {AccessionsColumn, SortDirection} from "generated/graphql"
import type {AccessionsOrderByInput} from "generated/graphql"
import type {SelectProps} from "src/components/select"
import {Select} from "src/components/select"
import {SortLabel} from "../plant-materials/sort-select"
import type {AccessionsFiltersProps} from "./types"

const options = [
  {
    value: null,
    label: "None",
  },
  {
    value: {
      field: AccessionsColumn.AccessionNum,
      direction: SortDirection.Asc,
    },
    label: <SortLabel label="Accession number" subLabel="(0 to 9)" />,
  },
  {
    value: {
      field: AccessionsColumn.AccessionNum,
      direction: SortDirection.Desc,
    },
    label: <SortLabel label="Accession number" subLabel="(9 to 0)" />,
  },
  {
    value: {
      field: AccessionsColumn.ScientificName,
      direction: SortDirection.Asc,
    },
    label: <SortLabel label="Scientific name" subLabel="(A to Z)" />,
  },
  {
    value: {
      field: AccessionsColumn.ScientificName,
      direction: SortDirection.Desc,
    },
    label: <SortLabel label="Scientific name" subLabel="(Z to A)" />,
  },
  {
    value: {
      field: AccessionsColumn.CommonName,
      direction: SortDirection.Asc,
    },
    label: <SortLabel label="Common name" subLabel="(A to Z)" />,
  },
  {
    value: {
      field: AccessionsColumn.CommonName,
      direction: SortDirection.Desc,
    },
    label: <SortLabel label="Common name" subLabel="(Z to A)" />,
  },
  {
    value: {
      field: AccessionsColumn.ProvenanceCategoryCode,
      direction: SortDirection.Asc,
    },
    label: <SortLabel label="Provenance" subLabel="(A to Z)" />,
  },
  {
    value: {
      field: AccessionsColumn.ProvenanceCategoryCode,
      direction: SortDirection.Desc,
    },
    label: <SortLabel label="Provenance" subLabel="(Z to A)" />,
  },
  {
    value: {
      field: AccessionsColumn.DonorSupplier,
      direction: SortDirection.Asc,
    },
    label: <SortLabel label="Donor / supplier" subLabel="(A to Z)" />,
  },
  {
    value: {
      field: AccessionsColumn.DonorSupplier,
      direction: SortDirection.Desc,
    },
    label: <SortLabel label="Donor / supplier" subLabel="(Z to A)" />,
  },
  {
    value: {
      field: AccessionsColumn.AccessionDate,
      direction: SortDirection.Asc,
    },
    label: <SortLabel label="Accession date" subLabel="(Oldest first)" />,
  },
  {
    value: {
      field: AccessionsColumn.AccessionDate,
      direction: SortDirection.Desc,
    },
    label: <SortLabel label="Accession date" subLabel="(Newest first)" />,
  },
  {
    value: {
      field: AccessionsColumn.LastUpdated,
      direction: SortDirection.Asc,
    },
    label: <SortLabel label="Last edited" subLabel="(Oldest first)" />,
  },
  {
    value: {
      field: AccessionsColumn.LastUpdated,
      direction: SortDirection.Desc,
    },
    label: <SortLabel label="Last edited" subLabel="(Newest first)" />,
  },
]

export const AccessionsSortSelect = ({
  orderBy,
  updateOrderBy,
  ...props
}: Pick<AccessionsFiltersProps, "orderBy" | "updateOrderBy"> &
  Partial<SelectProps<AccessionsOrderByInput | null>>) => {
  const onOrderByChange = useCallback(
    (val: AccessionsOrderByInput | null) => {
      updateOrderBy(val ?? undefined)
    },
    [updateOrderBy],
  )

  return (
    <Select
      {...props}
      options={options}
      onChange={onOrderByChange}
      value={orderBy ?? null}
      placeholder="Sort by"
    />
  )
}
