import {
  Alert,
  AlertContent,
  AlertSupportText,
  AlertText,
} from "@hortis/ui/alert"
import {
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuSeparator,
  useContextMenuCloseFix,
} from "@hortis/ui/context-menu"
import {
  AlertTriangle,
  ArrowUpRight,
  Download1,
  Edit4,
  Tag1,
  Trash3,
} from "@hortis/ui/icons"
import {Browser} from "@hortis/ui/icons/browser"
import {CircularProgress} from "@hortis/ui/progress"
import {useParams, useRouteContext} from "@tanstack/react-router"
import {
  type AccessionFilter,
  type AccessionsListFieldsFragment,
  type AccessionsSearchTerm,
  useAddTagToAccessionsMutation,
  useGetAccessionQuery,
  useGetAccessionsQuery,
  useRemoveTagFromAccessionsMutation,
} from "generated/graphql"
import {useCallback, useState} from "react"

import {Sheet, SheetContent} from "@hortis/ui/sheet"
import {useSnackbarStore} from "src/components/snackbar-controller/snackbar-store"
import {TrashAccessionsDialog} from "src/features/collection/components/archive-records/trash-accessions-dialog"
import {useDownloadCsv} from "src/features/collection/components/download-accessions/use-download-csv"
import {useAccessRole} from "src/features/permissions/use-access-role"
import {EditAccessionSheetForm} from "src/features/records/accession/components/edit-drawer/edit-drawer"
import {AccessionEditStage} from "src/features/records/accession/components/edit-drawer/types"
import {onFailure, onSuccess} from "src/notification-snack-utils"
import {AccessionCopyContextMenuSub} from "../copy-submenu/variants/accession-copy-context-menu-sub"
import {CollectionSiteTagsDialog} from "../dialogs/tags-dialog"

interface AccessionsContextMenuProps {
  onRequestDownload?: () => void
  isTrash: boolean
  selectedRecordsIDs: ReadonlyArray<string>
  clickedRow: AccessionsListFieldsFragment
  accSearch: AccessionsSearchTerm | undefined
  preparedFilters: AccessionFilter
}

const AccessionsContextMenuContent = ({
  selectedRecordsIDs,
  isTrash,
  clickedRow,
  onRequestDownload,
  accSearch,
  preparedFilters,
}: AccessionsContextMenuProps) => {
  // eslint-disable-next-line sonarjs/no-duplicate-string
  const {subdomain} = useRouteContext({from: "/_layout/sites/$siteSlug"})
  const {siteSlug} = useParams({from: "/_layout/sites/$siteSlug"})

  const [{data, error, fetching}] = useGetAccessionsQuery({
    variables: {
      organisationSubdomain: subdomain,
      collectionSiteSlug: siteSlug,
      last: selectedRecordsIDs.length,
      filters: {
        id: {
          in: selectedRecordsIDs,
        },
      },
    },
  })

  const selectedRows = data?.org?.site?.result?.nodes ?? []

  const selectedAccessions =
    selectedRows.length > 0 ? selectedRows : [clickedRow]

  const [trashDialogOpen, setTrashDialogOpen] = useState(false)
  const [tagsDialogOpen, setTagsDialogOpen] = useState(false)

  const [editOpen, setEditOpen] = useState(false)

  const {setSnack} = useSnackbarStore()
  const {canEdit} = useAccessRole()

  const {handleClose} = useContextMenuCloseFix()

  const showEditDrawer = selectedRows.length <= 1

  const [_, addTagToAccessions] = useAddTagToAccessionsMutation()
  const [__, removeTagFromAccessions] = useRemoveTagFromAccessionsMutation()

  const applyTagToSelectedRecords = useCallback(
    async (tagId: string) => {
      const res = await addTagToAccessions({
        tagId,
        ids:
          selectedRecordsIDs.length > 0 ? selectedRecordsIDs : [clickedRow.id],
      })

      const addTagSuccessful = res.data?.addTagAccessions.success ?? false
      if (addTagSuccessful) {
        onSuccess(setSnack)(
          `Tag added to ${
            selectedRecordsIDs.length > 0
              ? `${selectedRecordsIDs.length} accessions`
              : "accession"
          }`,
        )
      } else {
        onFailure(setSnack)(new Error("Failed to add tag to accessions"))
      }
      return addTagSuccessful
    },

    [addTagToAccessions, clickedRow.id, selectedRecordsIDs, setSnack],
  )

  const onRequestSingleDownload = useDownloadCsv({
    csvName: "accessions",
    path: "/download/accessions",
    body: {
      searchTerm: accSearch,
      filters: {
        ...preparedFilters,
        id: {
          eq: clickedRow.id,
        },
      },
    },
  })

  const removeTagFromSelectedRecords = useCallback(
    async (tagId: string) => {
      const res = await removeTagFromAccessions({
        tagId,
        ids:
          selectedRecordsIDs.length > 0 ? selectedRecordsIDs : [clickedRow.id],
      })

      const removeTagSuccessful = res.data?.removeTagAccessions.success ?? false

      if (removeTagSuccessful) {
        onSuccess(setSnack)(
          `Tag removed from ${
            selectedRecordsIDs.length > 0
              ? `${selectedRecordsIDs.length} accessions`
              : "accession"
          }`,
        )
      } else {
        onFailure(setSnack)(new Error("Failed to remove tag from accessions"))
      }
      return removeTagSuccessful
    },
    [clickedRow.id, removeTagFromAccessions, selectedRecordsIDs, setSnack],
  )

  return (
    <>
      <ContextMenuContent data-cy="accession-list-context-menu">
        {!isTrash && canEdit && (
          <>
            <ContextMenuItem
              onSelect={() => {
                setTagsDialogOpen(true)
              }}
              data-cy="tags-menu-item"
            >
              <Tag1 className="h-4 w-4 text-grey-500" />
              Add / remove tags
            </ContextMenuItem>
            <ContextMenuSeparator />
          </>
        )}
        {selectedRows.length <= 1 && !isTrash && canEdit && (
          <>
            <ContextMenuItem
              onSelect={() => {
                setEditOpen(true)
              }}
              data-cy="edit-menu-item"
            >
              <Edit4 className="h-4 w-4 text-grey-500" />
              Edit
            </ContextMenuItem>
            <ContextMenuSeparator />
          </>
        )}

        {onRequestDownload != null && !isTrash && (
          <>
            <ContextMenuItem
              onSelect={() => {
                if (selectedRows.length > 0) {
                  onRequestDownload()
                } else {
                  void onRequestSingleDownload()
                }
              }}
              data-cy="download-menu-item"
            >
              <Download1 className="h-4 w-4 text-grey-500" />
              Download
            </ContextMenuItem>
            <ContextMenuSeparator />
          </>
        )}
        {selectedAccessions.length > 0 && (
          <AccessionCopyContextMenuSub accessions={selectedAccessions} />
        )}

        {!isTrash && canEdit && (
          <>
            <ContextMenuSeparator />
            <ContextMenuItem
              onSelect={() => {
                setTrashDialogOpen(true)
              }}
              data-cy="trash-menu-item"
            >
              <Trash3 className="h-4 w-4 text-grey-500" />
              Move to trash
            </ContextMenuItem>
          </>
        )}

        {selectedRows.length <= 1 && (
          <>
            <ContextMenuSeparator />
            <ContextMenuItem
              onClick={() => {
                window.open(
                  `/sites/${siteSlug}/accessions/${clickedRow.accessionNumber}`,
                  "_blank",
                )
              }}
              data-cy="open-new-tab-menu-item"
            >
              <ArrowUpRight className="h-4 w-4 text-grey-500" />
              Open in new tab
            </ContextMenuItem>
            <ContextMenuItem
              onClick={() => {
                window.open(
                  `/sites/${siteSlug}/accessions/${clickedRow.accessionNumber}`,
                  "_blank",
                  "width=full,height=full",
                )
              }}
              data-cy="open-new-window-menu-item"
            >
              <Browser className=" h-4 w-4 text-grey-500" />
              Open in new window
            </ContextMenuItem>
          </>
        )}
      </ContextMenuContent>
      <TrashAccessionsDialog
        open={trashDialogOpen}
        setOpen={(open) => {
          setTrashDialogOpen(open)
          if (!open) {
            handleClose()
          }
        }}
        accessionsIds={
          selectedRecordsIDs.length > 0 ? selectedRecordsIDs : [clickedRow.id]
        }
        onSuccess={() => {
          setTrashDialogOpen(false)
          handleClose()
        }}
      />
      <CollectionSiteTagsDialog
        query={{data, error, fetching}}
        selectedRows={selectedRows}
        title={
          selectedRecordsIDs.length > 1
            ? `${selectedRecordsIDs.length} accessions selected`
            : `${clickedRow.accessionNumber}`
        }
        open={tagsDialogOpen}
        onOpenChange={(open) => {
          setTagsDialogOpen(open)
          if (!open) {
            handleClose()
          }
        }}
        applyTagToSelectedRecords={applyTagToSelectedRecords}
        removeTagFromSelectedRecords={removeTagFromSelectedRecords}
      />

      {showEditDrawer && (
        <EditAccessionSheet
          clickedRow={clickedRow}
          open={editOpen}
          onOpenChange={(open) => {
            setEditOpen(open)
            if (!open) {
              handleClose()
            }
          }}
        />
      )}
    </>
  )
}

const EditAccessionSheet = ({
  open,
  onOpenChange,
  clickedRow,
}: {
  open: boolean
  onOpenChange: (open: boolean) => void
  clickedRow: AccessionsListFieldsFragment
}) => {
  const {siteSlug} = useParams({from: "/_layout/sites/$siteSlug"})
  const {subdomain} = useRouteContext({from: "/_layout/sites/$siteSlug"})
  const [
    {data: accessionData, error: accessionError, fetching: accessionFetching},
    refetchAccession,
  ] = useGetAccessionQuery({
    variables: {
      organisationSubdomain: subdomain,
      collectionSiteSlug: siteSlug,
      accessionNumber: clickedRow.accessionNumber,
    },
    pause: !open,
  })
  const accession = accessionData?.org?.site?.result

  const [editStage, setEditStage] = useState(AccessionEditStage.overview)

  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetContent data-cy="edit-accession-drawer">
        {accession == null && accessionFetching && (
          <div className="flex h-full w-full items-center justify-center">
            <CircularProgress className="h-5 w-5 text-primary-500" />
          </div>
        )}

        {accession == null && accessionError != null && (
          <div className="p-3">
            <Alert
              color="warning"
              className="mb-4"
              data-cy="accession-edit-alert"
            >
              <AlertTriangle />
              <AlertContent>
                <AlertText>An error occurred</AlertText>
                <AlertSupportText>{accessionError.message}</AlertSupportText>
              </AlertContent>
            </Alert>
          </div>
        )}

        {accession != null && (
          <EditAccessionSheetForm
            setStage={setEditStage}
            stage={editStage}
            accession={accession}
            refetch={refetchAccession}
            requestClose={() => {
              onOpenChange(false)
            }}
            open={open}
          />
        )}
      </SheetContent>
    </Sheet>
  )
}

export {AccessionsContextMenuContent}
