import type {FieldError} from "react-hook-form"
import {IconButton} from "@hortis/ui/button"
import {AlertCircle, Trash3} from "@hortis/ui/icons"
import {Alert} from "src/components/untitled/alert"

export const FilterTitle = ({
  title,
  deleteFilter,
  error,
}: {
  title: string
  deleteFilter?: (() => void) | undefined
  error?: FieldError
}) => (
  <div className="flex w-full items-center justify-between gap-3 ">
    <p className="font-semibold text-grey-900" data-cy="filter-modal-title">
      {title}
    </p>
    <div className="flex items-center justify-center">
      {error != null && (
        <Alert
          testId={`filter-alert`}
          color="error"
          contentContainerClassName="gap-0 items-center w-full"
          className="flex h-9 w-64 items-center justify-center p-2 px-4 align-middle"
          icon={AlertCircle}
          title={error.message ?? "Invalid input values"}
        />
      )}

      {deleteFilter != null && (
        <IconButton
          size="sm"
          ariaLabel="Delete filter"
          variant="tertiaryGray"
          onClick={deleteFilter}
          icon={Trash3}
        />
      )}
    </div>
  </div>
)
