import {SortDirection, TaxaColumn, TaxaSearchColumn} from "generated/graphql"
import {PageDirection} from "src/utils/pagination"
import {z} from "zod"

export const taxonomySchema = z.object({
  cursor: z.coerce.string().optional().catch(undefined),
  dir: z.nativeEnum(PageDirection).optional().catch(PageDirection.Next),
  search: z
    .object({value: z.coerce.string(), field: z.nativeEnum(TaxaSearchColumn)})
    .optional()
    .catch(undefined),
  orderBy: z
    .object({
      field: z.nativeEnum(TaxaColumn),
      direction: z.nativeEnum(SortDirection),
    })
    .optional()
    .catch(undefined),
  rows: z.coerce.number().optional().catch(undefined),
})
