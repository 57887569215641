import type {RedListStatusComparator} from "generated/graphql"

export const countRedListStatusFilter = (filter: RedListStatusComparator) => {
  let count = 0
  if (filter.in != null && filter.in.length > 0) {
    count += 1
  }
  if (filter.nin != null && filter.nin.length > 0) {
    count += 1
  }
  return count
}
