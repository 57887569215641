import {useMediaQuery} from "@hortis/ui/hooks/media-query"
import type {PropsWithChildren, ReactElement} from "react"
import {useToggle} from "src/utils/hooks/toggle"
import {MobileTopBar} from "./mobile-topbar"
import {NavDrawer} from "./nav-drawer/nav-drawer"
import {useTopbarStore} from "./topbar/topbar-store"
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface NavLayoutProps {}
export const NavLayout = ({
  children,
}: PropsWithChildren<NavLayoutProps>): ReactElement => {
  const [open, toggleOpen] = useToggle()
  const mobileTopBarTitle = useTopbarStore((state) => state.title)
  const isDesktop = useMediaQuery("lg")

  return (
    <div
      className="flex h-full flex-col lg:flex-row"
      style={{minHeight: "calc(var(--vh, 1vh) * 100)"}}
    >
      {!isDesktop && (
        <div className="top-0">
          <MobileTopBar onMenuClick={toggleOpen} drawerOpen={open}>
            {mobileTopBarTitle}
          </MobileTopBar>
        </div>
      )}

      <NavDrawer open={open} toggleOpen={toggleOpen} isPermanent={isDesktop} />

      <main
        className="flex h-full flex-1 flex-col overflow-hidden" // Overflow-hidden for testing, breaks other pages
        id="scrollable-page-content"
      >
        {children}
      </main>
    </div>
  )
}
