import {css} from "@emotion/react"
import {OptionUnstyled, PopperUnstyled, SelectUnstyled} from "@mui/base"
import type {
  SelectUnstyledOwnerState,
  SelectUnstyledOwnProps,
  SelectUnstyledPopperSlotProps,
} from "@mui/base/SelectUnstyled"
import Grow from "@mui/material/Grow"
import {twMerge} from "tailwind-merge"
import type {PropsWithChildren, ReactNode} from "react"
import {forwardRef, useCallback, useState} from "react"
import * as uuid from "uuid"
import {colors} from "src/colors"
import {ChevronUp, ChevronDown} from "@hortis/ui/icons"
import {Button} from "@hortis/ui/button"
import {ThemedInputLabel} from "../text-field"
import {check} from "./check"
import type {SelectProps} from "./types"

const Popper: React.ComponentType<SelectUnstyledPopperSlotProps<string>> = ({
  open,
  children,
  ...props
}) => (
  <PopperUnstyled
    {...props}
    open={open}
    className="z-[999] w-full min-w-min"
    transition
  >
    {({TransitionProps}) => (
      <Grow {...TransitionProps} timeout={125}>
        <div>{typeof children !== "function" && children}</div>
      </Grow>
    )}
  </PopperUnstyled>
)

const Root = forwardRef<
  HTMLButtonElement,
  {
    // eslint-disable-next-line @typescript-eslint/ban-types
    ownerState: SelectUnstyledOwnerState<{}>
    placeholder?: string
    fullWidth?: boolean
    children: ReactNode | null
    className?: string
  }
>(function Listbox(
  {children, ownerState, placeholder, fullWidth, ...props},
  ref,
) {
  return (
    <Button
      type="button"
      ref={ref}
      endIcon={ownerState.open ? ChevronUp : ChevronDown}
      fullWidth={fullWidth}
      {...props}
      className={twMerge(props.className, "justify-between")}
    >
      {children !== "" && children != null ? (
        children
      ) : placeholder == null ? (
        <div />
      ) : (
        placeholder
      )}
    </Button>
  )
})

const Listbox = forwardRef<
  HTMLUListElement,
  {ownerState: unknown; className?: string}
>(function Listbox({ownerState: _, className, ...props}, ref) {
  return (
    <ul
      className={twMerge(
        "right-0 my-1 max-h-64 overflow-y-auto rounded-lg border border-grey-100 bg-white shadow-md outline-none",
        className,
      )}
      ref={ref}
      {...props}
    />
  )
})

const optionStyles = css`
  &:hover {
    background-color: ${colors.grey[100]};
  }
  &.Mui-selected {
    background-color: ${colors.grey[100]};
  }
  &.MuiOptionUnstyled-highlighted {
    background-color: ${colors.grey[100]};
  }
  &.MuiOption-highlighted {
    background-color: ${colors.grey[100]};
  }
`

const Option = <T,>({
  children,
  selected,
  className,
  ...props
}: PropsWithChildren<{value: T; selected: boolean; className?: string}>) => {
  return (
    <OptionUnstyled
      {...props}
      css={optionStyles}
      className={twMerge(
        "relative flex w-full cursor-pointer items-center justify-between gap-2 px-4 py-[10px] text-sm",
        className,
      )}
    >
      {children}
      <div style={{flex: 1}} />
      {selected && <div>{check}</div>}
    </OptionUnstyled>
  )
}

/*
 * NOTE: Currently MUI/Base's useSelect hook does shallow equality
 * on values so objects are non-viable. This means serialising/deserialising
 * the value when interacting with the form state
 * See: https://github.com/mui/material-ui/blob/v5.10.0/packages/mui-base/src/SelectUnstyled/useSelect.ts#L215
 */
export const Select = <T,>({
  options,
  id: _id,
  testId,
  onChange: _onChange,
  error,
  value,
  buttonProps,
  placeholder,
  fullWidth,
  label,
  required,
  placement,
  className,
  dropDownClassName,
}: SelectProps<T>) => {
  // eslint-disable-next-line react/hook-use-state
  const [id] = useState(_id ?? uuid.v4())
  const onChange = useCallback<
    NonNullable<SelectUnstyledOwnProps<string>["onChange"]>
  >(
    (_, val) => {
      _onChange(val == null ? null : (JSON.parse(val) as T))
    },
    [_onChange],
  )

  return (
    <div
      className={twMerge(
        `relative inline-flex max-w-full flex-col`,
        fullWidth === true ? `flex w-full` : undefined,
        className,
      )}
    >
      {label != null && (
        <ThemedInputLabel id={id} required={required ?? false} label={label} />
      )}
      <SelectUnstyled
        listboxId={id}
        slots={{
          listbox: Listbox,
          popper: Popper,
          root: Root,
        }}
        slotProps={{
          popper: {
            placement: placement ?? "bottom-end",
          },
          listbox: {
            className: dropDownClassName,
          },
          // eslint-disable-next-line unicorn/no-useless-spread -- Types don't line up so can't pass fullWidth
          root: {...{...buttonProps, placeholder, fullWidth}},
        }}
        value={JSON.stringify(value)}
        onChange={onChange}
        data-cy={testId}
      >
        {options
          .filter(
            ({value: optionValue}) => !(optionValue == null && value == null),
          )
          .map((option) => (
            <Option
              key={JSON.stringify(option.value)}
              value={JSON.stringify(option.value)}
              selected={JSON.stringify(value) === JSON.stringify(option.value)}
              data-cy={option.testId}
            >
              <div className="flex gap-2">
                {option.label}
                <span className="text-grey-500">{option.supportText}</span>
              </div>
            </Option>
          ))}
      </SelectUnstyled>
      {error?.message != null && (
        <div
          className="mt-1.5"
          data-cy={testId == null ? undefined : `${testId}-error`}
        >
          <p className="text-sm text-error-500  ">{error.message}</p>
        </div>
      )}
    </div>
  )
}
