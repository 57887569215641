import {z} from "zod"
import {tagOptionZod} from "src/features/create-accession/components/add-plant-material-form/schema"
import {scientificNameOption} from "src/features/filters/family-filter/schema"

export const taxonOption = z.object(
  {
    id: z.string().uuid(),
    scientificName: z
      .string()
      .min(3, {message: "Scientific name must be at least 3 characters"}),
  },
  {invalid_type_error: "Please select a taxon"},
)

export type TaxonOption = z.TypeOf<typeof taxonOption>

export const baseTaxonFieldsSchema = z.object({
  scientificName: z
    .string()
    .min(3, {message: "Scientific name must be at least 3 characters"}),
  sharedScientificName: scientificNameOption.nullable(),
})
export type BaseTaxonFields = z.TypeOf<typeof baseTaxonFieldsSchema>

export const taxonFieldsSchema = baseTaxonFieldsSchema.merge(
  z.object({
    commonName: z
      .string()
      .min(3, {message: "Common name must be at least 3 characters"})
      .nullable(),
    authorship: z.string().nullable(),
    notes: z.string().nullable(),
    tags: z.array(tagOptionZod),
    interpretationDescription: z.string().nullable(),
    nativeDistribution: z.string().nullable(),
  }),
)
export type TaxonFields = z.TypeOf<typeof taxonFieldsSchema>
