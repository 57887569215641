import {pipe, R} from "@mobily/ts-belt"
import {getForceOrgSubdomainCookie} from "../organisation-subdomain-cookie"

const hostnameRegex = /^(?<org>[^.]+)\.(?<domain>[^.]+)\.(?<ext>.+)$/

export interface UrlContext {
  orgSubdomain: string
  siteSlug: string
}

export const getUrlContext = () => {
  try {
    const forceSubdomain = getForceOrgSubdomainCookie()
    const location = window.location

    const orgSubdomain =
      forceSubdomain ?? hostnameRegex.exec(location.hostname)?.groups?.org
    const siteSlug = location.pathname.split("/")[2]

    return pipe(
      R.fromNullable(
        orgSubdomain,
        new Error("Organisation subdomain could not be found"),
      ),
      R.flatMap((orgSubdomain) =>
        siteSlug == null
          ? R.Error(new Error("Site slug could not be found"))
          : R.Ok<UrlContext>({orgSubdomain, siteSlug}),
      ),
    )
  } catch (error: unknown) {
    return R.Error(
      new Error(
        `Unexpected error: ${
          error instanceof Error ? error.message : "not error instance"
        }`,
      ),
    )
  }
}
