import type {Interpolation, Theme} from "@emotion/react"
import {twMerge} from "tailwind-merge"
import type {PropsWithChildren} from "react"
import {Children} from "react"
import {Divider} from "../divider"

export type ButtonGroupDirection = "row" | "column"

type ButtonGroupProps = PropsWithChildren<{
  containerStyles?: Interpolation<Theme>
  direction?: ButtonGroupDirection
  spacing?: number
  className?: string
}>

const baseClasses = `bg-white py-1 px-1 rounded-lg inline-flex relative items-stretch border border-grey-300 shadow-sm`

const styles: Record<ButtonGroupDirection, string> = {
  row: baseClasses,
  column: twMerge(baseClasses, `flex-col`),
}

export const ButtonGroup = ({
  children,
  containerStyles,
  className,
  spacing = 0,
  direction = "row",
}: ButtonGroupProps) => {
  return (
    <div
      css={containerStyles}
      className={twMerge(styles[direction], className)}
      style={{padding: `${spacing * 4}px`}}
    >
      {Children.map(children, (child, idx) => (
        <>
          {idx !== 0 && (
            <div className="px-1">
              <Divider
                direction={direction === "row" ? "vertical" : "horizontal"}
                spacing={0}
              />
            </div>
          )}
          {child}
        </>
      ))}
    </div>
  )
}
