import create from "zustand"

export type SetReturnTo = (returnTo: string | null) => void

interface SettingsReturnToState {
  returnTo: string | null
  setReturnTo: SetReturnTo
}

export const useSettingsReturnToStore = create<SettingsReturnToState>(
  (set) => ({
    returnTo: null,
    setReturnTo: (returnTo) => {
      set({returnTo})
    },
  }),
)
