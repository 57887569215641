import type {Map as MaplibreMap} from "maplibre-gl"
// eslint-disable-next-line no-restricted-imports -- importing to fix type
import {useMap} from "react-map-gl"

// keep this updates with node_modules/react-map-gl/dist/esm/components/use-map.d.ts
declare const skipMethods: readonly [
  "setMaxBounds",
  "setMinZoom",
  "setMaxZoom",
  "setMinPitch",
  "setMaxPitch",
  "setRenderWorldCopies",
  "setProjection",
  "setStyle",
  "addSource",
  "removeSource",
  "addLayer",
  "removeLayer",
  "setLayerZoomRange",
  "setFilter",
  "setPaintProperty",
  "setLayoutProperty",
  "setLight",
  "setTerrain",
  "setFog",
  "remove",
]
export type MaplibreRef = {
  // eslint-disable-next-line @typescript-eslint/method-signature-style
  getMap(): MaplibreMap
} & Omit<MaplibreMap, (typeof skipMethods)[number]>

export type OptionalMaplibreRef = MaplibreRef | undefined

export type UseMapLibre = () => {
  [id: string]: MaplibreRef | undefined
  current?: MaplibreRef | undefined
}

/**
 * Version of `react-map-gl`'s `useMap` but returns `MaplibreRef`s, not (mapbox) `MapRefs`
 */
export const useMaplibre: UseMapLibre = useMap as unknown as UseMapLibre
