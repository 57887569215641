import {IconButton} from "@hortis/ui/button"
import {Edit5} from "@hortis/ui/icons"
import {format} from "date-fns"
import type {AccessionFieldsFragment} from "generated/graphql"
import type {ReactElement} from "react"
import {
  RecordSection,
  RecordSectionContent,
  RecordSectionContentGroup,
  RecordSectionHeader,
  RecordSectionHeaderButtons,
  RecordSectionTitle,
} from "src/features/records/components/record-section/new-record-section"
import {parseUtcDate} from "src/utils/dates/parse-utc-date"
import type {AccessionEditStage} from "../../../records/accession/components/edit-drawer/types"
import {AccessionEditStageCallback} from "../../../records/accession/components/edit-drawer/types"
import {
  RecordField,
  RecordFieldLabel,
  RecordFieldValue,
} from "../../../records/components/record-field/new-record-field"

export interface AccessionRecordIdentificationProps {
  accession: AccessionFieldsFragment
  requestEdit: (stage?: AccessionEditStage) => void
  canEdit: boolean
}

export const AccessionRecordIdentification = ({
  accession,
  canEdit,
  requestEdit,
}: AccessionRecordIdentificationProps): ReactElement => {
  const editAccessionData =
    AccessionEditStageCallback.useEditAccessionData(requestEdit)

  return (
    <RecordSection id={"identification"}>
      <RecordSectionHeader>
        <RecordSectionTitle>Identification</RecordSectionTitle>
        <RecordSectionHeaderButtons>
          {canEdit && !accession.archived && (
            <IconButton
              onClick={editAccessionData}
              ariaLabel="Edit section"
              data-cy="section-edit-button"
              size="xs"
              variant="tertiaryGray"
              icon={Edit5}
            />
          )}
        </RecordSectionHeaderButtons>
      </RecordSectionHeader>

      <RecordSectionContent>
        <RecordSectionContentGroup className="grid gap-4 lg:grid-flow-col lg:grid-rows-2">
          <RecordField data-cy="identification-date">
            <RecordFieldLabel>Identification date</RecordFieldLabel>
            <RecordFieldValue>
              {accession.dateIdentified == null
                ? "-"
                : format(parseUtcDate(accession.dateIdentified), "PPP")}
            </RecordFieldValue>
          </RecordField>

          <RecordField data-cy="identification-verification">
            <RecordFieldLabel>Verification</RecordFieldLabel>
            <RecordFieldValue>
              {accession.identificationVerificationStatus ?? "-"}
            </RecordFieldValue>
          </RecordField>
          <RecordField data-cy="identification-by">
            <RecordFieldLabel>Identified by</RecordFieldLabel>
            <RecordFieldValue>{accession.identifiedBy ?? "-"}</RecordFieldValue>
          </RecordField>
          <RecordField data-cy="identification-qualifier">
            <RecordFieldLabel>Identified qualifier</RecordFieldLabel>
            <RecordFieldValue>
              {accession.identificationQualifier ?? "-"}
            </RecordFieldValue>
          </RecordField>
        </RecordSectionContentGroup>
        <RecordSectionContentGroup>
          <RecordField data-cy="identification-remarks">
            <RecordFieldLabel>Remarks</RecordFieldLabel>
            <RecordFieldValue>
              {accession.identificationRemarks ?? "-"}
            </RecordFieldValue>
          </RecordField>
        </RecordSectionContentGroup>
      </RecordSectionContent>
    </RecordSection>
  )
}
