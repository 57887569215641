import type {ReactElement, ReactNode} from "react"
import type {ButtonProps} from "@hortis/ui/button"
import {Button, IconButton} from "@hortis/ui/button"
import type {IconProps} from "src/components/icons/streamline/types"
import {
  AlertCircle,
  AlertTriangle,
  ArrowRight,
  CheckCircle,
  InfoCircle,
  XClose,
} from "@hortis/ui/icons"
import {twMerge} from "tailwind-merge"
import {Link} from "@tanstack/react-router"

export type AlertColor = "primary" | "gray" | "error" | "success" | "warning"

const titleClasses: Record<AlertColor, string> = {
  primary: "text-primary-700",
  gray: "text-grey-700",
  error: "text-error-700",
  success: "text-success-700",
  warning: "text-warning-700",
}

const buttonClasses: Record<AlertColor, string> = {
  primary: "text-primary-700 hover:text-primary-800",
  gray: "text-grey-700 hover:text-grey-800",
  error: "text-error-700 hover:text-error-800",
  success: "text-success-700 hover:text-success-800",
  warning: "text-warning-700 hover:text-warning-800",
}

const containerClasses: Record<AlertColor, string> = {
  primary: "text-primary-600 bg-primary-25 border-primary-300",
  gray: "text-grey-600 bg-grey-25 border-grey-300",
  error: "text-error-600 bg-error-25 border-error-300",
  success: "text-success-600 bg-success-25 border-success-300",
  warning: "text-warning-600 bg-warning-25 border-warning-300",
}

const closeButtonClasses: Record<AlertColor, string> = {
  primary: "stroke-primary-500 hover:stroke-primary-600",
  gray: "stroke-grey-500 hover:stroke-grey-600",
  error: "stroke-error-500 hover:stroke-error-600",
  success: "stroke-success-500 hover:stroke-success-600",
  warning: "stroke-warning-500 hover:stroke-warning-600",
}

const icons: Record<AlertColor, (props: IconProps) => ReactElement> = {
  primary: InfoCircle,
  gray: InfoCircle,
  error: AlertCircle,
  success: CheckCircle,
  warning: AlertTriangle,
}

export interface AlertProps {
  title: string
  body?: ReactNode
  buttonProps?: ButtonProps & {href?: string; target?: string}
  secondaryButtonProps?: ButtonProps & {href?: string; target?: string}
  color?: AlertColor
  icon?: (props: IconProps) => ReactElement
  onClose?: () => void
  className?: string
  innerClassName?: string
  contentContainerClassName?: string
  breakpoint?: "mobile" | "desktop"
  testId?: string
}

export const Alert = ({
  title,
  body,
  secondaryButtonProps,
  buttonProps,
  color = "primary",
  onClose,
  icon,
  className,
  breakpoint,
  testId,
  innerClassName,
  contentContainerClassName,
}: AlertProps) => {
  const Icon = icon ?? icons[color]
  return (
    <div
      data-cy={testId}
      className={twMerge(
        "relative flex flex-col gap-3 rounded-lg border border-primary-300 bg-primary-25 p-4",
        breakpoint === "mobile" && "flex-col items-start",
        breakpoint === "desktop" && "flex-row items-start",
        breakpoint == null && "flex-col items-start md:flex-row md:items-start",
        containerClasses[color],
        className,
      )}
    >
      <Icon size="20" className="flex-shrink-0 stroke-current" />
      <div
        className={twMerge(
          "flex flex-1 flex-col items-start gap-3",
          innerClassName,
        )}
      >
        <div
          className={twMerge("flex flex-col gap-1", contentContainerClassName)}
        >
          <div className={twMerge("text-sm font-medium", titleClasses[color])}>
            {title}
          </div>
          {body !== null && <div className="text-sm font-normal">{body}</div>}
        </div>
        {(secondaryButtonProps != null || buttonProps != null) && (
          <div className="flex items-center gap-3">
            {secondaryButtonProps != null && (
              <Button
                variant="linkcolor"
                className={buttonClasses[color]}
                {...secondaryButtonProps}
                asChild={secondaryButtonProps.href != null}
              >
                {secondaryButtonProps.href == null ? (
                  secondaryButtonProps.children
                ) : (
                  <Link
                    to={secondaryButtonProps.href}
                    target={secondaryButtonProps.target}
                  >
                    {secondaryButtonProps.children}
                  </Link>
                )}
              </Button>
            )}
            {buttonProps != null && (
              <Button
                variant="linkcolor"
                endIcon={ArrowRight}
                className={buttonClasses[color]}
                {...buttonProps}
                asChild={buttonProps.href != null}
              >
                {buttonProps.href == null ? (
                  buttonProps.children
                ) : (
                  <Link to={buttonProps.href} target={buttonProps.target}>
                    {buttonProps.children}
                  </Link>
                )}
              </Button>
            )}
          </div>
        )}
      </div>
      {onClose != null && (
        <IconButton
          ariaLabel="Close"
          onClick={onClose}
          icon={<XClose size="20" className={closeButtonClasses[color]} />}
          variant="linkgray"
          className={twMerge(
            breakpoint === "mobile" && "absolute right-4 top-4",
            breakpoint == null &&
              "absolute right-4 top-4 md:relative md:right-auto md:top-auto",
          )}
        />
      )}
    </div>
  )
}
