import {zodResolver} from "@hookform/resolvers/zod"
import * as A from "fp-ts/Array"
import {useMemo} from "react"
import {FormProvider} from "react-hook-form"
import {CollapsableRadioButton, RadioGroup} from "src/components/radio"
import {FilterTitle} from "src/features/filters/components/modal/filter-title"
import type {FilterFormProps} from "src/features/filters/plant-materials/modals/types"
import {match} from "ts-pattern"
import {z} from "zod"
import {
  OrgLifeFormAutocomplete,
  SiteLifeFormAutocomplete,
} from "src/components/autocomplete/life-form-autocomplete"
import type {FilterModalProps} from "../components/modal/filter-modal"
import {FilterModal} from "../components/modal/filter-modal"
import {FilterModalButtons} from "../components/modal/modal-buttons"
import {FilterModalError} from "../components/modal/modal-error"
import {useFilterForm} from "../components/modal/use-filter-form"
import {determineInitialOperation} from "../determine-initial-operation"

const lifeFormOption = z.object({
  id: z.string(),
  name: z.string(),
})

const schema = z.object({
  is: z.array(lifeFormOption).nullish(),
  isNot: z.array(lifeFormOption).nullish(),
})

export type LocalLifeFormsFilter = z.infer<typeof schema>

interface LifeFormFilterFormProps {
  addModifyFilter: (changes: {lifeForms: LocalLifeFormsFilter}) => void
  deleteFilter: (() => void) | undefined
  initialValue?: LocalLifeFormsFilter
  filterBy?: "org" | "site"
}

export const LifeFormsFilterForm = ({
  initialValue = {},
  addModifyFilter,
  deleteFilter,
  onClose,
  filterBy = "org",
}: Omit<FilterFormProps, "dispatchFilters" | "initialValue"> &
  LifeFormFilterFormProps) => {
  const {error, setError, operation, handleRadioChange, formMethods} =
    useFilterForm({
      resolver: zodResolver(schema),
      defaultValues: initialValue,
      initialOperation: determineInitialOperation<keyof LocalLifeFormsFilter>(
        initialValue,
        "is",
      ),
    })

  const onSubmit = useMemo(
    () =>
      formMethods.handleSubmit((values) => {
        match(operation)
          .with("is", () => {
            if (values.is != null && A.isNonEmpty(values.is)) {
              addModifyFilter({lifeForms: {is: values.is}})
              onClose()
            } else {
              setError("Select a life form to continue")
            }
          })
          .with("isNot", () => {
            if (values.isNot != null && A.isNonEmpty(values.isNot)) {
              addModifyFilter({lifeForms: {isNot: values.isNot}})
              onClose()
            } else {
              setError("Select a life form to continue")
            }
          })
          .run()
      }),
    [formMethods, operation, onClose, addModifyFilter, setError],
  )

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={onSubmit}>
        <div className="mb-4 flex flex-col gap-4">
          <FilterTitle title="Life forms" deleteFilter={deleteFilter} />
          <FilterModalError error={error} />
          <RadioGroup
            value={operation}
            onChange={handleRadioChange}
            label="Operation"
          >
            {(props) => (
              <>
                <CollapsableRadioButton
                  selectedValue="is"
                  testId="life-forms-is-radio"
                  label="Is"
                  {...props}
                >
                  {filterBy === "org" ? (
                    <OrgLifeFormAutocomplete
                      name="is"
                      testId="life-forms-is-input"
                    />
                  ) : (
                    <SiteLifeFormAutocomplete
                      name="is"
                      testId="life-forms-is-input"
                    />
                  )}
                </CollapsableRadioButton>
                <CollapsableRadioButton
                  selectedValue="isNot"
                  testId="life-forms-is-not-radio"
                  label="Is not"
                  {...props}
                >
                  {filterBy === "org" ? (
                    <OrgLifeFormAutocomplete
                      name="isNot"
                      testId="life-forms-is-not-input"
                    />
                  ) : (
                    <SiteLifeFormAutocomplete
                      name="isNot"
                      testId="life-forms-is-not-input"
                    />
                  )}
                </CollapsableRadioButton>
              </>
            )}
          </RadioGroup>
        </div>
        <FilterModalButtons onCancel={onClose} testIdPrefix="life-forms" />
      </form>
    </FormProvider>
  )
}

// Seperated from form component to make sure popover unmount causes state reset
export const LifeFormsFilterModal = ({
  open,
  onClose,
  anchorEl,
  ...props
}: Omit<FilterFormProps, "initialValue" | "dispatchFilters"> &
  FilterModalProps &
  LifeFormFilterFormProps) => {
  return (
    <FilterModal anchorEl={anchorEl} open={open} onClose={onClose}>
      <LifeFormsFilterForm onClose={onClose} {...props} />
    </FilterModal>
  )
}
