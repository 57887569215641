import {pipe} from "fp-ts/function"
import * as A from "fp-ts/Array"
import * as S from "fp-ts/string"
import type {FileRejection} from "react-dropzone"

function notNullOrUndefined<T>(
  maybeNullOrUndefined?: T | null,
): maybeNullOrUndefined is T {
  return maybeNullOrUndefined != null
}

export const processDropzoneErrors = (
  fileRejections: Array<FileRejection>,
  maxFiles: number,
  maxSize: number,
) =>
  pipe(
    fileRejections,
    A.chain(({errors}: {errors: Array<{code: string}>}) =>
      errors.map((e) => e.code),
    ),
    A.uniq(S.Eq),
    A.map((code) => {
      switch (code) {
        case "file-too-large": {
          return `File too large, max ${maxSize / 1_000_000}MB`
        }
        case "too-many-files": {
          return `Too many files, max ${maxFiles}`
        }
        case "file-invalid-type": {
          return "Invalid file type, accepted: png, jpg, gif"
        }
        default: {
          return undefined
        }
      }
    }),
    A.filter(notNullOrUndefined),
  )
