import {AccessionsSearchColumn} from "generated/graphql"

export const accessionsSearchMenuLabels = {
  [AccessionsSearchColumn.ScientificName]: {
    labels: {
      shortName: "Name",
      name: "Name",
    },
    placeholders: {
      shortName: "Search names",
      name: "Search names",
    },
  },
  [AccessionsSearchColumn.AccessionNum]: {
    labels: {
      shortName: "Number",
      name: "Number",
    },
    placeholders: {
      shortName: "Search numbers",
      name: "Search numbers",
    },
  },
} as const
