"use client"

import * as ToggleGroupPrimitive from "@radix-ui/react-toggle-group"
import {
  forwardRef,
  type ComponentPropsWithoutRef,
  type ElementRef,
  createContext,
  useContext,
  useMemo,
} from "react"
import {twMerge} from "tailwind-merge"
import {cva, type VariantProps} from "class-variance-authority"

const toggleVariants = cva(
  "inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors hover:bg-muted hover:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=on]:bg-accent data-[state=on]:text-accent-foreground",
  {
    variants: {
      variant: {
        default: "bg-transparent",
        outline:
          "border border-input bg-transparent hover:bg-accent hover:text-accent-foreground",
      },
      size: {
        default: "h-10 px-3",
        sm: "h-9 px-2.5",
        lg: "h-11 px-5",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
)

const ToggleGroupContext = createContext<VariantProps<typeof toggleVariants>>({
  size: "default",
  variant: "default",
})

const ToggleGroup = forwardRef<
  ElementRef<typeof ToggleGroupPrimitive.Root>,
  ComponentPropsWithoutRef<typeof ToggleGroupPrimitive.Root> &
    VariantProps<typeof toggleVariants>
>(({className, children, variant, size, ...props}, ref) => {
  const contextValue = useMemo(() => ({variant, size}), [variant, size])

  return (
    <ToggleGroupPrimitive.Root
      ref={ref}
      className={twMerge("flex items-center justify-center gap-1", className)}
      {...props}
    >
      <ToggleGroupContext.Provider value={contextValue}>
        {children}
      </ToggleGroupContext.Provider>
    </ToggleGroupPrimitive.Root>
  )
})
// @ts-expect-error -- Reclared forwardRef does not have a displayName
ToggleGroup.displayName = ToggleGroupPrimitive.Root.displayName

const ToggleGroupItem = forwardRef<
  ElementRef<typeof ToggleGroupPrimitive.Item>,
  ComponentPropsWithoutRef<typeof ToggleGroupPrimitive.Item> &
    VariantProps<typeof toggleVariants>
>(({className, children, variant, size, ...props}, ref) => {
  const context = useContext(ToggleGroupContext)

  return (
    <ToggleGroupPrimitive.Item
      ref={ref}
      className={twMerge(
        toggleVariants({
          variant: context.variant ?? variant,
          size: context.size ?? size,
        }),
        "flex items-center justify-center rounded-none border-l border-grey-300 bg-white text-sm font-medium text-grey-800 first:border-l-0 first-of-type:rounded-l-lg last-of-type:rounded-r-lg data-[state=on]:bg-grey-50",
        className,
      )}
      {...props}
    >
      {children}
    </ToggleGroupPrimitive.Item>
  )
})
// @ts-expect-error -- Reclared forwardRef does not have a displayName
ToggleGroupItem.displayName = ToggleGroupPrimitive.Item.displayName

export {ToggleGroup, ToggleGroupItem}
