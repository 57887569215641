import {getEntries} from "src/utils/entries"

export const determineInitialOperation = <K extends string>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filter: Partial<Record<K, any>>,
  defaultOperation: K,
): K => {
  for (const [key, val] of getEntries(filter)) {
    if (Array.isArray(val) && val.length > 0) {
      return key
    }
    if (val != null && Boolean(val)) {
      return key
    }
  }
  return defaultOperation
}
