import {F} from "@mobily/ts-belt"
import type {HTMLAttributes, SyntheticEvent} from "react"
import {Fragment, useCallback, useRef, useState} from "react"
import {usePlaceBasic} from "src/utils/hooks/place"
import {AccessionsSearchColumn, useGetAccessionsQuery} from "generated/graphql"
import {colors} from "src/colors"
import {shadows} from "src/shadows"
import {ChevronDown} from "@hortis/ui/icons"
import {Typography} from "../typography"
import {FormAutocomplete} from "../autocomplete"

type Option = {id: string; accessionNumber: string; scientificName: string}

export const isOptionEqual = (option: Option, value: Option) =>
  option.id === value.id

export const renderAutoCompleteItem = (
  props: HTMLAttributes<HTMLLIElement>,
  option: Option | null,
) => {
  const {...rest} = props
  return option == null ? (
    <Fragment />
  ) : (
    <li key={option.id} {...rest}>
      <div className="flex flex-1 items-center gap-2 py-1">
        <Typography variant="body2" color="text.primary">
          {option.accessionNumber}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {option.scientificName}
        </Typography>
      </div>
    </li>
  )
}

export const getOptionLabel = (option: Option | null) =>
  option === null ? "" : option.accessionNumber

const STARTS_WITH_NUMBER = /^\d.*$/
interface AccessionsAutocompleteProps {
  name: string
  testId?: string
}

export const AccessionAutocomplete = ({
  name,
  testId,
}: AccessionsAutocompleteProps) => {
  const place = usePlaceBasic()
  const [inputValue, setInputValue] = useState("")
  const [{data, fetching}] = useGetAccessionsQuery({
    variables: {
      organisationSubdomain: place?.orgName ?? "",
      collectionSiteSlug: place?.siteSlug ?? "",
      last: 50,
      searchTerm: {
        field: STARTS_WITH_NUMBER.test(inputValue)
          ? AccessionsSearchColumn.AccessionNum
          : AccessionsSearchColumn.ScientificName,
        value: inputValue,
      },
      filters: {excludeDeaccessioned: true},
    },
    pause: place == null,
  })
  const ref = useRef(
    F.makeControlledDebounce({delay: 200, leading: true})(
      (_: SyntheticEvent, newValue: string) => {
        setInputValue(newValue)
      },
    ),
  )

  const onInputChange = useCallback((_: SyntheticEvent, newValue: string) => {
    ref.current.schedule(_, newValue)
  }, [])

  const options =
    data?.org?.site?.result?.nodes.map(({id, accessionNumber, taxon}) => ({
      id,
      accessionNumber,
      // TODO: Handle null taxon
      scientificName: taxon?.scientificName ?? "",
    })) ?? []

  return (
    <FormAutocomplete
      renderOption={renderAutoCompleteItem}
      options={options}
      label={"Accession"}
      loading={fetching}
      onInputChange={onInputChange}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqual}
      filterOptions={F.identity}
      placeholder="Find an accession"
      clearOnBlur
      fullWidth
      required
      testId={testId}
      name={name}
      popupIcon={<ChevronDown />}
      sx={{
        "& .MuiOutlinedInput-root": {py: "2.5px"},
        '& .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"]':
          {
            backgroundColor: colors.grey[50],
          },
        "& .MuiOutlinedInput-root .MuiAutocomplete-endAdornment": {
          right: "16px",
        },
      }}
      componentsProps={{
        popper: {
          sx: {
            marginTop: "2px !important",
            marginBottom: "2px !important",
            "& .MuiAutocomplete-listbox": {
              padding: "4px 0",
            },
            "& .MuiPaper-root": {
              border: `1px solid ${colors.grey[100]}`,
              boxShadow: shadows.lg,
            },
            '& .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"]':
              {
                backgroundColor: colors.grey[50],
              },
            "& .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused": {
              backgroundColor: colors.grey[100],
            },
          },
        },
      }}
    />
  )
}
