import * as Eq from "fp-ts/Eq"
import * as O from "fp-ts/Option"
import {useRef} from "react"
import fastDeepEqual from "fast-deep-equal/es6"

const shallowEq: Eq.Eq<unknown> = {equals: Object.is}

export const useMemoisedValue = <A, B>(
  fn: (a: A) => B,
  input: A,
  eq: Eq.Eq<A> = shallowEq,
): B => {
  const prevInput = useRef<A>(input)
  const prevOutput = useRef<O.Option<B>>(O.none)

  if (eq.equals(input, prevInput.current)) {
    if (O.isNone(prevOutput.current)) {
      const value = fn(prevInput.current)
      prevOutput.current = O.some(value)
      return value
    } else {
      return prevOutput.current.value
    }
  } else {
    const value = fn(input)
    prevInput.current = input
    prevOutput.current = O.some(value)
    return value
  }
}

const deepEq: Eq.Eq<unknown> = Eq.fromEquals(fastDeepEqual)

export const useDeepMemoisedValue = <A, B>(fn: (a: A) => B, input: A) =>
  useMemoisedValue(fn, input, deepEq)

export const createMemoisedValue = <A, B>(
  fn: (a: A) => B,
  eq: Eq.Eq<A> = shallowEq,
) =>
  function useCurriedMemoisedValue(input: A): B {
    return useMemoisedValue(fn, input, eq)
  }

export const createDeepMemoisedValue = <A, B>(fn: (a: A) => B) =>
  createMemoisedValue(fn, deepEq)

/**
 * Useful for debugging identity vs value equality.
 * Only for debugging, don't leave a call in production code!
 */
export const useComparisonDebug = <A>(label: string, input: A) => {
  const prev = useRef<A>(input)

  if (input !== prev.current && deepEq.equals(input, prev.current)) {
    // eslint-disable-next-line no-console
    console.debug(
      label,
      "input",
      input,
      "has deep equality with",
      prev.current,
      "but not identity equality",
    )
  }
  if (input === prev.current) {
    // eslint-disable-next-line no-console
    console.debug(
      label,
      "input",
      input,
      "has identity equality with",
      prev.current,
    )
  } else {
    // eslint-disable-next-line no-console
    console.debug(
      label,
      "input",
      input,
      "has no identity equality with",
      prev.current,
    )
  }
  prev.current = input
  return input
}
