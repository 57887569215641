import {IconButton} from "@hortis/ui/button"
import {ExternalLink1} from "@hortis/ui/icons"
import {useNavigate} from "@tanstack/react-router"
import {format} from "date-fns"
import {type MaterialFieldsFragment} from "generated/graphql"
import {useCallback} from "react"

import {
  RecordField,
  RecordFieldLabel,
  RecordFieldValue,
} from "src/features/records/components/record-field/new-record-field"
import {
  RecordSection,
  RecordSectionContent,
  RecordSectionContentGroup,
  RecordSectionHeader,
  RecordSectionHeaderButtons,
  RecordSectionTitle,
} from "src/features/records/components/record-section/new-record-section"
import {parseUtcDate} from "src/utils/dates/parse-utc-date"
import {provenanceLabelMap} from "src/utils/label-maps/provenance"

interface MaterialRecordAccessionProps {
  material: MaterialFieldsFragment
}

export const MaterialRecordAccession = ({
  material,
}: MaterialRecordAccessionProps) => {
  const navigate = useNavigate({
    from: "/sites/$siteSlug/materials/$materialNumber",
  })

  const routeToAccession = useCallback(() => {
    if (material.accession != null) {
      void navigate({
        to: "/sites/$siteSlug/accessions/$accessionNumber",
        params: {accessionNumber: material.accession.accessionNumber},
      })
    }
  }, [material, navigate])

  return (
    <RecordSection id="accession-taxon">
      <RecordSectionHeader>
        <RecordSectionTitle>Accession</RecordSectionTitle>
        <RecordSectionHeaderButtons>
          <IconButton
            onClick={routeToAccession}
            ariaLabel="Go to accession"
            data-cy="go-to-accession-button"
            size="xs"
            variant="tertiaryGray"
            icon={ExternalLink1}
          />
        </RecordSectionHeaderButtons>
      </RecordSectionHeader>

      <RecordSectionContent>
        <RecordSectionContentGroup className="flex flex-col gap-4">
          <RecordField data-cy="accession-date">
            <RecordFieldLabel>Accession date</RecordFieldLabel>
            <RecordFieldValue>
              {material.accession?.accessionDate == null
                ? "-"
                : format(parseUtcDate(material.accession.accessionDate), "PPP")}
            </RecordFieldValue>
          </RecordField>
          <RecordField data-cy="provenance">
            <RecordFieldLabel>Provenance</RecordFieldLabel>
            <RecordFieldValue>
              {material.accession?.provenanceCategory
                ? `${material.accession.provenanceCategory.codeId}, ${
                    provenanceLabelMap[
                      material.accession.provenanceCategory.codeId
                    ]
                  }`
                : "-"}
            </RecordFieldValue>
          </RecordField>
          <RecordField data-cy="donor-supplier">
            <RecordFieldLabel>Donor/supplier</RecordFieldLabel>
            <RecordFieldValue>
              {material.accession?.donor ?? "-"}
            </RecordFieldValue>
          </RecordField>
        </RecordSectionContentGroup>
      </RecordSectionContent>
    </RecordSection>
  )
}
