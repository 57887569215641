import type {Client} from "@bugsnag/js"
import Bugsnag from "@bugsnag/js"
import BugsnagPluginReact from "@bugsnag/plugin-react"
import config from "../config"

let client: Client | undefined

export default function getBugsnag(): Client | null {
  const apiKey = config.bugsnagApiKey

  if (apiKey == null || typeof window === "undefined") {
    return null
  }

  if (client == null) {
    client = Bugsnag.start({
      releaseStage: config.envStage === "PRODUCTION" ? "production" : "staging",
      apiKey,
      plugins: [new BugsnagPluginReact()],
      generateAnonymousId: true,
    })
  }

  return client
}
