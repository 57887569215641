import type {ReactElement} from "react"
import {useCallback, useState} from "react"
import {CheckCircle, AlertCircle} from "@hortis/ui/icons"
import {useLayoutEffect} from "../../utils/hooks/isomorphic-layout-effect"
import {Notification} from "./notification/notification"
import type {Snack} from "./snackbar-store"
import {useSnackbarStore} from "./snackbar-store"

export const SnackbarController = (): ReactElement => {
  const {snack, setSnack} = useSnackbarStore()

  // These cached snacks are used to prevent a flash
  // of missing text on animate out due to the
  // snack data disappearing (being set to null)
  const [cachedSnack, setCachedSnack] = useState<Snack | null>(null)

  const handleClose = useCallback(
    (_?: unknown, reason?: string) => {
      if (reason === "clickaway") {
        return
      }

      setSnack(null)
    },
    [setSnack],
  )

  useLayoutEffect(() => {
    if (snack !== null) {
      setCachedSnack(snack)
    }
  }, [snack])

  return (
    <>
      <Notification
        open={snack !== null}
        onClose={handleClose}
        testId={cachedSnack?.type === "alert" ? "alert-snack" : "action-snack"}
        text={cachedSnack?.data.text ?? ""}
        supportingText={cachedSnack?.data.body}
        iconProps={{
          icon:
            cachedSnack?.type === "action" ? (
              <CheckCircle size="20px" className="stroke-success-600" />
            ) : (
              <AlertCircle size="20px" className="stroke-error-600" />
            ),
          color: cachedSnack?.type === "action" ? "success" : "error",
        }}
        primaryAction={
          cachedSnack?.type === "action" ? cachedSnack.data.action : undefined
        }
      />
    </>
  )
}
