import * as E from "fp-ts/Either"
import {flow, identity, pipe} from "fp-ts/function"
import * as O from "fp-ts/Option"
import * as TE from "fp-ts/TaskEither"
import * as t from "io-ts"
import type {GeoJSONSource, Source} from "maplibre-gl"
import type {MaplibreRef} from "../utils"
import {decodeToLngLat} from "./lng-lat"
import type {SafeFeature, SafePoint} from "./types"

const ClusterProperties = t.type({
  cluster: t.boolean,
  cluster_id: t.number,
})
type ClusterProperties = t.TypeOf<typeof ClusterProperties>

const getClusterId = flow(
  ClusterProperties.decode,
  O.fromEither,
  O.filter(({cluster}) => cluster),
  O.map(({cluster_id}) => cluster_id),
)

export const isCluster = (feature: SafeFeature) =>
  pipe(
    feature.properties,
    ClusterProperties.decode,
    O.fromEither,
    O.map(({cluster}) => cluster),
    O.getOrElseW(() => false),
  )

const getClusterExpansionZoom = TE.tryCatchK(
  ({source, clusterId}: {source: GeoJSONSource; clusterId: number}) =>
    new Promise<number | null | undefined>((resolve, reject) => {
      source.getClusterExpansionZoom(clusterId, (error, zoom) => {
        if (error) {
          reject(error)
        }
        resolve(zoom)
      })
    }),
  E.toError,
)

export const zoomOnCluster = (
  map: MaplibreRef | undefined,
  feature: SafePoint,
) =>
  pipe(
    O.Do,
    O.apS("clusterId", getClusterId(feature.properties)),
    O.apS("map", O.fromNullable(map)),
    TE.fromOption(() => new Error("Missing information")),
    TE.apSW(
      "coordinates",
      TE.fromEither(decodeToLngLat(feature.geometry.coordinates)),
    ),
    TE.bindW(
      "source",
      flow(
        TE.fromNullableK(new Error('Missing map source: "geometry"'))(({map}) =>
          map.getSource("geometry"),
        ),
        TE.chain(
          TE.fromPredicate(
            (source: Source): source is GeoJSONSource =>
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              "getClusterExpansionZoom" in (source as any),
            () => new Error('"geometry" source invalid'),
          ),
        ),
      ),
    ),
    TE.bindW("zoom", (args) =>
      pipe(
        getClusterExpansionZoom(args),
        TE.chainNullableK(new Error("Zoom is nullable"))(identity),
      ),
    ),
    TE.chainIOK(({map, coordinates, zoom}) => () => {
      map.easeTo({
        center: coordinates,
        zoom,
        duration: 500,
      })
    }),
    // this error is essentially swallowed... TODO: should `onPointClick` be predicated on the success of this TaskEither?
    TE.getOrElseW(
      TE.fromIOK((error) => () => {
        // eslint-disable-next-line no-console -- TODO: does this indicate a larger problem? should this be reported to bugsnag?
        console.warn("Error during mouse click", error)
      }),
    ),
  )().catch((error) => {
    // eslint-disable-next-line no-console -- catch and warn instead of await here, for convenience
    console.warn("Handle click failed with", error)
  })
