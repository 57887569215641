import {Tag3} from "@hortis/ui/icons"
import {MarkerPinOff1} from "@hortis/ui/icons/marker-pin-off-1"
import {MaterialGroup, PlantMaterialStatus} from "generated/graphql"
import {StatusChip} from "src/components/status-chip"
import {materialGroupLabelMap} from "src/utils/label-maps/material-group"
import {twMerge} from "tailwind-merge"
import {MarkerPin1} from "@hortis/ui/icons/marker-pin-1"
import {
  RecordCard,
  RecordCardContent,
  RecordCardDetails,
  RecordCardOverline,
  RecordCardPhoto,
  RecordCardTitle,
  type RecordCardProps,
} from "../record-card"

type MaterialRecordCardProps = {
  group: MaterialGroup
  status: PlantMaterialStatus
  materialNumber: string
  scientificName: string
  photoUrl?: string
  className?: string
  testId?: string
  noLocation?: boolean
  location:
    | {name: string; description?: string | null | undefined}
    | undefined
    | null
} & Pick<RecordCardProps, "variant" | "selected" | "size">

function MaterialRecordCard({
  group,
  status,
  materialNumber,
  scientificName,
  photoUrl,
  className,
  testId,
  noLocation = false,
  location,
  ...rest
}: MaterialRecordCardProps) {
  return (
    <RecordCard
      {...rest}
      className={twMerge(
        status === PlantMaterialStatus.Absent && "grayscale",
        className,
      )}
      testId={testId ?? "material-record-card"}
    >
      <RecordCardPhoto photo={photoUrl} />
      <RecordCardContent className="truncate">
        <RecordCardOverline data-cy="material-number">
          {materialNumber}
        </RecordCardOverline>
        <RecordCardTitle>
          <span className="whitespace-normal">{scientificName}</span>
          {noLocation && (
            <MarkerPinOff1
              className="h-4 w-4 shrink-0 text-grey-500"
              data-cy="missing-position-icon"
            />
          )}
        </RecordCardTitle>
        <RecordCardDetails className="flex-nowrap truncate pt-0.5">
          <StatusChip status={status} data-cy="status-chip" />
          <div className="flex items-center gap-1 py-[3px]">
            <Tag3 className="h-4 w-4 text-grey-400 " />
            <p className="text-sm font-medium text-grey-500">
              {materialGroupLabelMap[group]}
            </p>
          </div>
          {group === MaterialGroup.Plant && location != null && (
            <div className="flex items-center gap-1 truncate py-[3px]">
              <MarkerPin1 className="h-4 w-4 shrink-0 text-grey-400" />
              <span className="truncate text-sm font-medium text-grey-500">
                {location.name}
              </span>
            </div>
          )}
        </RecordCardDetails>
      </RecordCardContent>
    </RecordCard>
  )
}

export default MaterialRecordCard
