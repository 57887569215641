import {gql} from "graphql-request"
import {
  CORE_COLLECTION_SITES_FIELDS,
  CORE_ORGANISATION_FIELDS,
} from "../features/organisation/graphql"

export const GET_ORG_SITES_FROM_SUBDOMAIN = gql`
  ${CORE_ORGANISATION_FIELDS}
  ${CORE_COLLECTION_SITES_FIELDS}
  query GetOrgCollectionSites($organisationSubdomain: String!) {
    org: organisationBySubdomain(subdomain: $organisationSubdomain) {
      collectionSites {
        ...MeCollectionSitesFields
      }
    }
  }
`

export const GET_ORG_AND_SITE_FROM_NAMES = gql`
  ${CORE_ORGANISATION_FIELDS}
  ${CORE_COLLECTION_SITES_FIELDS}
  query GetCollectionSiteFromNames(
    $organisationSubdomain: String!
    $collectionSiteSlug: String!
  ) {
    org: organisationBySubdomain(subdomain: $organisationSubdomain) {
      ...MeOrganisationFields
      site: collectionSiteBySlug(slug: $collectionSiteSlug) {
        ...MeCollectionSitesFields
      }
    }
  }
`

export const GET_ORGANISATION_FROM_NAME = gql`
  ${CORE_ORGANISATION_FIELDS}
  query GetOrganisationFromName($organisationSubdomain: String!) {
    org: organisationBySubdomain(subdomain: $organisationSubdomain) {
      ...MeOrganisationFields
    }
  }
`
