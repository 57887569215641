import type {FC} from "react"
import {HortisLogo} from "@hortis/ui/icons"
import {UserDetails} from "src/components/user-details"
import type {DateStringFormatter} from "../../../../utils/hooks/language"
import type {ActivityItemProps} from "./activity-item"

export const ACTIVITY_PAGE_SIZE = 10

interface BaseHistoryItem {
  readonly createdBy?:
    | {
        readonly initials: string
        readonly givenName?: string | null | undefined
        readonly avatarUrl?: string | null | undefined
        readonly deactivatedAt?: string | null | undefined
      }
    | null
    | undefined
  readonly createdAt: string
  readonly wasManualImport?: boolean
}

export const getUserDisplayName = (history: BaseHistoryItem) =>
  history.createdBy == null ? (
    "Unknown user"
  ) : (
    <UserDetails user={history.createdBy} />
  )

export const getCoreProps = (history: BaseHistoryItem) => ({
  avatarUrl: history.createdBy?.avatarUrl ?? undefined,
  initials: history.createdBy?.initials ?? undefined,
  timestamp: history.createdAt,
})

export const Typography: FC<React.PropsWithChildren> = ({children}) => (
  <span className="text-sm text-grey-500">{children}</span>
)

export const BoldTypography: FC<React.PropsWithChildren> = ({children}) => (
  <span className="text-sm font-medium text-grey-700">{children}</span>
)

export const isActivityItemProps = (
  props: ActivityItemProps | null,
): props is ActivityItemProps => props != null

const parseSingleHistory =
  <T extends BaseHistoryItem>(
    formatDateString: DateStringFormatter,
    parsers: Array<
      (
        history: T,
        formatDateString: DateStringFormatter,
      ) => ActivityItemProps | null
    >,
  ) =>
  (history: T, index: number): Array<ActivityItemProps> => {
    if (index === 0) {
      return [
        {
          ...getCoreProps(history),
          icon:
            history.wasManualImport === true || history.createdBy == null ? (
              <div className="flex h-6 w-6 items-center justify-center">
                <HortisLogo size="22px" />
              </div>
            ) : undefined,
          message:
            history.wasManualImport === true || history.createdBy == null ? (
              <Typography>The record was imported into Hortis</Typography>
            ) : (
              <Typography>
                <BoldTypography>{getUserDisplayName(history)}</BoldTypography>{" "}
                created the record
              </Typography>
            ),
        },
      ]
    }

    return parsers
      .map((parser) => parser(history, formatDateString))
      .filter(isActivityItemProps)
  }

export const parseHistory =
  <T extends BaseHistoryItem>(
    parsers: Array<
      (
        history: T,
        formatDateString: DateStringFormatter,
      ) => ActivityItemProps | null
    >,
  ) =>
  (
    history: ReadonlyArray<T>,
    formatDateString: DateStringFormatter,
    limit: number | undefined,
  ): Array<ActivityItemProps> => {
    const props = history
      .flatMap(parseSingleHistory(formatDateString, parsers))
      .reverse()
      .slice(0, limit)

    // eslint-disable-next-line unicorn/prefer-at
    const lastItem = props[props.length - 1]

    if (lastItem) {
      lastItem.isLastItem = true
    }

    return props
  }
