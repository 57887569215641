import match from "autosuggest-highlight/match"
import parse from "autosuggest-highlight/parse"
import {A, F, O, pipe} from "@mobily/ts-belt"
import type {AutocompleteRenderInputParams} from "@mui/material/Autocomplete"
import Autocomplete, {createFilterOptions} from "@mui/material/Autocomplete"
import type {FilterOptionsState} from "@mui/material/useAutocomplete"
import type {TaxonFieldsFragment} from "generated/graphql"
import {TaxaSearchColumn, useGetTaxaQuery} from "generated/graphql"
import type {HTMLAttributes, SyntheticEvent} from "react"
import {Fragment, useCallback, useRef, useState} from "react"
import {useController, useFormContext} from "react-hook-form"
import {colors} from "src/colors"
import {FormControl} from "src/components/form-control"
import {ChevronDown, Plus} from "@hortis/ui/icons"
import {useSnackbarStore} from "src/components/snackbar-controller/snackbar-store"
import {TextField} from "src/components/text-field"
import {NewTaxonModal} from "src/features/create-accession/components/new-taxon-modal/new-taxon-modal"
import {onFailure} from "src/notification-snack-utils"
import {shadows} from "src/shadows"
import {useOrganisationSubdomainStruct} from "src/utils/hooks/place"
import {twMerge} from "tailwind-merge"
import CircularProgress from "@mui/material/CircularProgress"

type Option = Pick<TaxonFieldsFragment, "scientificName" | "id">

export const filterOptions = createFilterOptions({
  stringify: (option: Option) => option.scientificName,
})

export const isOptionEqual = (option: Option, value: Option) => option === value

export const renderAutoCompleteItem = (
  props: HTMLAttributes<HTMLLIElement> & {"data-option-index"?: number},
  option: Option | null,
  {inputValue}: {inputValue: string},
) => {
  if (option == null) {
    return <Fragment />
  }
  const {...rest} = props
  const matches = match(option.scientificName, inputValue, {
    insideWords: true,
  })
  const parts = parse(option.scientificName, matches)
  const isAddOption = option.id.includes("ADD_NEW")

  return (
    <li
      key={option.id}
      {...rest}
      style={{padding: 0}}
      data-cy={
        isAddOption ? "autocomplete-option-add-new" : "autocomplete-option"
      }
    >
      <div
        className={twMerge(
          "flex flex-1 items-center gap-2 px-[14px] py-2.5",
          isAddOption && props["data-option-index"] !== 0
            ? `border-t border-grey-200`
            : undefined,
        )}
      >
        {isAddOption && <Plus size="16px" />}
        <div color="text.primary" className="text-sm">
          {isAddOption
            ? option.scientificName
            : parts.map((part, index) => (
                <span
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  className={twMerge(part.highlight && "font-semibold")}
                >
                  {part.text}
                </span>
              ))}
        </div>
      </div>
    </li>
  )
}

export const getOptionLabel = (option: Option | null) =>
  option === null ? "" : option.scientificName

interface ScientificNameAutocompleteProps {
  name: string
  testId?: string
  id: string
  required?: boolean
}

export const AccessionScientificNameAutocomplete = ({
  name,
  testId,
  id,
  required,
}: ScientificNameAutocompleteProps) => {
  const [newTaxonScientificName, setNewTaxonScientificName] = useState<
    string | null
  >(null)
  const [newTaxonModalOpen, setNewTaxonModalOpen] = useState(false)
  const {setSnack} = useSnackbarStore()
  const subdomain = useOrganisationSubdomainStruct()
  const [inputValue, setInputValue] = useState("")
  const [{data, fetching}, refetchTaxa] = useGetTaxaQuery({
    variables: {
      organisationSubdomain: subdomain.data ?? "",
      last: 50,
      searchTerm: {
        field: TaxaSearchColumn.ScientificName,
        value: inputValue,
      },
    },
    pause: subdomain.data == null,
  })
  const {trigger} = useFormContext()
  const {
    field,
    fieldState: {error},
  } = useController({
    name,
  })

  const ref = useRef(
    F.makeControlledDebounce({delay: 200, leading: true})(
      (_: SyntheticEvent, newValue: string) => {
        setInputValue(newValue)
      },
    ),
  )

  const onNewTaxonCreated = useCallback(
    (newTaxon: {scientificName: string; id: string}) => {
      refetchTaxa()
      field.onChange(newTaxon)
    },
    [field, refetchTaxa],
  )

  const filterOptionsWithNew = useCallback(
    (options: Array<Option>, params: FilterOptionsState<Option>) => {
      const filtered = filterOptions(options, params)

      const {inputValue} = params
      // Suggest the creation of a new value
      const isExisting = options.some(
        (option) =>
          inputValue.toLowerCase() === option.scientificName.toLowerCase(),
      )
      if (
        inputValue !== "" &&
        !isExisting &&
        (!fetching || options.length > 0)
      ) {
        filtered.push({
          id: `ADD_NEW:${inputValue}`,
          scientificName: `Add new taxon “${inputValue}”`,
        })
      }

      return filtered
    },
    [fetching],
  )

  const handleChange = useCallback(
    (_: SyntheticEvent, value: Option | null) => {
      if (value == null || !value.id.includes("ADD_NEW")) {
        field.onChange(value)
        if (error != null) {
          void trigger(name)
        }
        return
      }

      pipe(
        value.id.split("ADD_NEW:"),
        A.last,
        O.match(
          (scientificName) => {
            setNewTaxonModalOpen(true)
            setNewTaxonScientificName(scientificName)
          },
          () => {
            // Reset value if something fails
            field.onChange(null)
            onFailure(setSnack)(new Error("Failed to create taxon"))
          },
        ),
      )
      if (error != null) {
        void trigger(name)
      }
    },
    [setNewTaxonModalOpen, field, setSnack, trigger, error, name],
  )

  const cachedRenderInput = useCallback(
    ({inputProps, InputProps}: AutocompleteRenderInputParams) => (
      <TextField
        fullWidth
        name={name}
        inputProps={{"data-cy": testId, ...inputProps}}
        InputProps={InputProps}
        endAdornment={InputProps.endAdornment}
        placeholder="Pick a taxon"
        errorMessage={error?.message}
        error={Boolean(error)}
        required={required}
        label="Taxon"
        id={id}
      />
    ),
    [name, id, required, testId, error],
  )

  const onInputChange = useCallback((_: SyntheticEvent, newValue: string) => {
    ref.current.schedule(_, newValue)
  }, [])

  const options =
    data?.org?.result?.nodes.map(({scientificName, id}) => ({
      scientificName,
      id,
    })) ?? []

  return (
    <FormControl error={Boolean(error)}>
      <NewTaxonModal
        scientificName={newTaxonScientificName}
        open={newTaxonModalOpen}
        onOpenChange={setNewTaxonModalOpen}
        onSuccess={onNewTaxonCreated}
      />
      <Autocomplete
        renderOption={renderAutoCompleteItem}
        options={options}
        filterOptions={filterOptionsWithNew}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={isOptionEqual}
        onInputChange={onInputChange}
        popupIcon={<ChevronDown />}
        loading={fetching}
        clearOnBlur
        selectOnFocus
        handleHomeEndKeys
        loadingText={
          <div className="flex items-center justify-center">
            <CircularProgress
              size="18px"
              sx={{color: colors.primary[500]}}
              thickness={4}
            />
          </div>
        }
        componentsProps={{
          popper: {
            disablePortal: true,
            sx: {
              marginTop: "2px !important",
              marginBottom: "2px !important",
              "& .MuiAutocomplete-listbox": {
                padding: "4px 0",
              },
              "& .MuiPaper-root": {
                border: `1px solid ${colors.grey[100]}`,
                boxShadow: shadows.lg,
              },
              '& .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"]':
                {
                  backgroundColor: colors.grey[50],
                },
              "& .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused":
                {
                  backgroundColor: colors.grey[100],
                },
            },
          },
        }}
        fullWidth
        sx={{
          "& .MuiOutlinedInput-root": {py: "4px"},
          "& .MuiOutlinedInput-root:focus-within": {border: "none"},
          "& .MuiOutlinedInput-notchedOutline": {
            transition: `all 125ms ease`,
            border: `1px solid ${colors.grey[300]}`,
            boxShadow: `0 0 0 0px ${colors.primary[100]}`,
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: `1px solid ${colors.primary[300]}`,
            boxShadow: `0 0 0 4px ${colors.primary[100]}`,
          },
          "& .MuiOutlinedInput-root .MuiAutocomplete-endAdornment": {
            right: "12px",
          },

          '& .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"]':
            {
              backgroundColor: colors.grey[50],
            },
        }}
        onChange={handleChange}
        value={field.value as Option}
        renderInput={cachedRenderInput}
        id={id}
      />
    </FormControl>
  )
}
