import {Button} from "@hortis/ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@hortis/ui/dropdown-menu"
import {
  ArrowNarrowDown,
  ArrowNarrowUp,
  Check,
  ChevronSelectorVertical,
  EyeOff,
  FilterLines,
} from "@hortis/ui/icons"
import type {Column} from "@tanstack/react-table"
import {useRef, useState, type HTMLAttributes} from "react"
import {preventDefault} from "src/features/collection/components/stop-event-propagation"
import {twMerge} from "tailwind-merge"

interface DataTableColumnHeaderProps<TData, TValue>
  extends HTMLAttributes<HTMLDivElement> {
  column: Column<TData, TValue>
  altColumn?: Column<TData, TValue>
  title?: string
}

export const DataTableColumnHeader = <TData, TValue>({
  column,
  altColumn,
  title,
  className, // eslint-disable-next-line sonarjs/cognitive-complexity
}: DataTableColumnHeaderProps<TData, TValue>) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null)
  const [open, setOpen] = useState(false)

  const sorted = column.getIsSorted()
  const altSorted = altColumn?.getIsSorted()

  if (!column.getCanSort() && !column.getCanHide()) {
    return <div className={twMerge(className)}>{title}</div>
  }

  return (
    <>
      <DropdownMenu open={open} onOpenChange={setOpen} key={column.id}>
        <DropdownMenuTrigger asChild>
          <Button
            variant="linkgray"
            size="xs"
            onClick={() => {
              setOpen((prev) => !prev)
            }}
            ref={buttonRef}
            endIcon={
              column.getIsSorted() === "desc" ||
              altColumn?.getIsSorted() === "desc"
                ? ArrowNarrowDown
                : column.getIsSorted() === "asc" ||
                  altColumn?.getIsSorted() === "asc"
                ? ArrowNarrowUp
                : ChevronSelectorVertical
            }
            className={twMerge("justify-start", className)}
          >
            {title ?? column.id}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          onCloseAutoFocus={(e) =>
            // This is to prevent conflicting focus events when attempting to open
            // a follow up filter dialog after closing the dropdown menu
            {
              column.getFilterValue() == null ? undefined : preventDefault(e)
            }
          }
        >
          {column.getCanSort() && (
            <DropdownMenuGroup>
              {altColumn?.getCanSort() === true && (
                <DropdownMenuLabel>{column.id}</DropdownMenuLabel>
              )}
              <DropdownMenuItem
                onSelect={() => {
                  if (sorted !== false && sorted === "asc") {
                    column.clearSorting()
                  } else {
                    column.toggleSorting(false)
                  }
                  setOpen(false)
                }}
              >
                <ArrowNarrowUp className="h-4 w-4 text-grey-500" />
                <span className="text-sm">Sort asc</span>
                {sorted !== false && sorted === "asc" && (
                  <Check className="h-4 w-4 stroke-primary-500" />
                )}
              </DropdownMenuItem>
              <DropdownMenuItem
                onSelect={() => {
                  if (sorted !== false && sorted === "desc") {
                    column.clearSorting()
                  } else {
                    column.toggleSorting(true)
                  }
                  setOpen(false)
                }}
              >
                <ArrowNarrowDown className="h-4 w-4 text-grey-500" />
                <span className="text-sm">Sort desc</span>
                {sorted !== false && sorted === "desc" && (
                  <Check className="h-4 w-4 stroke-primary-500" />
                )}
              </DropdownMenuItem>
            </DropdownMenuGroup>
          )}
          {altColumn?.getCanSort() === true && (
            <DropdownMenuGroup>
              <DropdownMenuSeparator />
              <DropdownMenuLabel>{altColumn.id}</DropdownMenuLabel>
              <DropdownMenuItem
                onSelect={() => {
                  if (altSorted !== false && altSorted === "asc") {
                    altColumn.clearSorting()
                  } else {
                    altColumn.toggleSorting(false)
                  }
                  setOpen(false)
                }}
              >
                <ArrowNarrowUp className="h-4 w-4 text-grey-500" />
                <span className="text-sm">Sort asc</span>
                {altSorted !== false && altSorted === "asc" && (
                  <Check className="h-4 w-4 stroke-primary-500" />
                )}
              </DropdownMenuItem>
              <DropdownMenuItem
                onSelect={() => {
                  if (altSorted !== false && altSorted === "desc") {
                    altColumn.clearSorting()
                  } else {
                    altColumn.toggleSorting(true)
                  }
                  setOpen(false)
                }}
              >
                <ArrowNarrowDown className="h-4 w-4 text-grey-500" />
                <span className="text-sm">Sort desc</span>
                {altSorted !== false && altSorted === "desc" && (
                  <Check className="h-4 w-4 stroke-primary-500" />
                )}
              </DropdownMenuItem>
            </DropdownMenuGroup>
          )}
          {column.getCanFilter() && (
            <DropdownMenuGroup>
              {(column.getCanSort() || altColumn?.getCanSort() === true) && (
                <DropdownMenuSeparator />
              )}
              <DropdownMenuItem
                onSelect={() => {
                  column.setFilterValue(buttonRef)
                  setOpen(false)
                }}
              >
                <FilterLines className="h-4 w-4 text-grey-500" />
                <span className="text-sm">Filter</span>
              </DropdownMenuItem>
            </DropdownMenuGroup>
          )}
          {column.getCanHide() && (
            <DropdownMenuGroup>
              {(column.getCanSort() ||
                altColumn?.getCanSort() === true ||
                column.getCanFilter()) && <DropdownMenuSeparator />}
              <DropdownMenuItem
                onSelect={() => {
                  column.toggleVisibility(false)
                  setOpen(false)
                }}
              >
                <EyeOff className="h-4 w-4 text-grey-500" />
                <span className="text-sm">Hide</span>
              </DropdownMenuItem>
            </DropdownMenuGroup>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  )
}
