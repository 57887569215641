import SwipeableDrawer from "@mui/material/SwipeableDrawer"
import {useCallback, useState} from "react"
import {useNavigate} from "@tanstack/react-router"
import {IconButton, Button} from "@hortis/ui/button"
import {Menu1} from "@hortis/ui/icons"
import {SlExitIcon} from "../../../components/icons/streamline/exit"
import {DrawerContent} from "./drawer-content"

interface MobileLayoutProps {
  returnTo: string
}

export const MobileLayout = ({returnTo}: MobileLayoutProps) => {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)

  const navigateToReturnTo = useCallback(() => {
    void navigate({to: returnTo})
  }, [returnTo, navigate])

  const toggleDrawer = useCallback(
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions, @typescript-eslint/no-unnecessary-condition
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return
      }

      setOpen((x) => !x)
    },
    [setOpen],
  )

  const closeDrawer = useCallback(() => {
    setOpen(false)
  }, [])

  const openDrawer = useCallback(() => {
    setOpen(true)
  }, [])

  return (
    <div className="sticky top-0 z-50 flex w-full justify-between bg-white px-4 py-2 lg:hidden">
      <IconButton
        testId="mobile-settings-menu-button"
        onClick={openDrawer}
        ariaLabel="Open menu"
        variant="tertiaryGray"
        size="sm"
        icon={Menu1}
      />
      <Button
        aria-label="Exit settings"
        startIcon={<SlExitIcon width="20px" height="20px" />}
        size="xs"
        onClick={navigateToReturnTo}
      >
        Exit settings
      </Button>
      <SwipeableDrawer
        className="flex lg:hidden [&_.MuiDrawer-paper]:w-full [&_.MuiDrawer-paper]:max-w-[280px] [&_.MuiDrawer-paper]:bg-white"
        open={open}
        onClose={toggleDrawer}
        onOpen={toggleDrawer}
        data-cy="mobile-nav-drawer"
      >
        <DrawerContent onClose={closeDrawer} returnTo={returnTo} mobile />
      </SwipeableDrawer>
    </div>
  )
}
