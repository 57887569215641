import type {AutocompleteRenderGetTagProps} from "@mui/material/Autocomplete"
import {useOrganisationTagsQuery} from "generated/graphql"
import type {RecordTagFilters} from "generated/graphql"
import {colors} from "src/colors"
import {FormAutocomplete} from "src/components/autocomplete"
import {useOrganisationSubdomainStruct} from "src/utils/hooks/place"
import {Tag} from "@hortis/ui/tag"
import {
  filterOptions,
  getTagOptionLabel,
  isTagOptionEqual,
  renderTagAutoCompleteItem,
} from "./utils"

export const renderTags = (
  tags: Array<{id: string; name: string}>,
  getTagProps: AutocompleteRenderGetTagProps,
) => (
  <div className="flex flex-wrap">
    {tags.map((tag, index) => (
      <Tag {...getTagProps({index})} key={tag.id}>
        {tag.name}
      </Tag>
    ))}
  </div>
)

interface TagsAutocompleteProps {
  name: string
  testId?: string
  filters?: RecordTagFilters
}

export const TagsAutocomplete = ({
  name,
  testId,
  filters,
}: TagsAutocompleteProps) => {
  const subdomain = useOrganisationSubdomainStruct()
  const pause = subdomain.data == null
  const [{data, fetching}] = useOrganisationTagsQuery({
    variables: {
      organisationSubdomain: subdomain.data ?? "",
      filters,
    },
    pause,
  })
  const tags = data?.org?.tags?.nodes

  return (
    <FormAutocomplete
      multiple
      testId={testId}
      renderOption={renderTagAutoCompleteItem}
      options={tags ?? []}
      label={null}
      loading={fetching || subdomain.loading}
      filterOptions={filterOptions}
      sx={{
        "& label": {display: "none"},
        "& .MuiOutlinedInput-root": {py: "4px"},
        "& .MuiOutlinedInput-notchedOutline": {
          transition: `all 125ms ease`,
          border: `1px solid ${colors.grey[300]}`,
          boxShadow: `0 0 0 0px ${colors.primary[100]}`,
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          border: `1px solid ${colors.primary[300]}`,
          boxShadow: `0 0 0 4px ${colors.primary[100]}`,
        },
      }}
      renderTags={renderTags}
      getOptionLabel={getTagOptionLabel}
      isOptionEqualToValue={isTagOptionEqual}
      fullWidth
      name={name}
      componentsProps={{popper: {disablePortal: true}}}
    />
  )
}
