import {Alert, AlertContent, AlertText} from "@hortis/ui/alert"
import {CommandGroup, CommandLoading} from "@hortis/ui/command"
import {AlertTriangle} from "@hortis/ui/icons"
import {A} from "@mobily/ts-belt"
import {useParams, useRouteContext} from "@tanstack/react-router"
import {pipe} from "fp-ts/function"
import {
  type RecordTag,
  type TaxaListFieldsFragment,
  useAddTagToTaxaMutation,
  useCollectionSiteTagsQuery,
  useGetSiteTaxaQuery,
  useGetTaxaQuery,
  useOrganisationTagsQuery,
  useRemoveTagFromTaxaMutation,
} from "generated/graphql"
import {useCallback, useEffect, useMemo, useState} from "react"
import {useSnackbarStore} from "src/components/snackbar-controller/snackbar-store"
import {onFailure, onSuccess} from "src/notification-snack-utils"
import {CommandMenuTagsPage} from "../tags"
interface CommandMenuTaxonTagsPageProps {
  selectedRows: ReadonlyArray<TaxaListFieldsFragment>
  search: string
  selectedRecordsIDs: ReadonlyArray<string>
  clearSearch: () => void
  allTags: ReadonlyArray<Pick<RecordTag, "id" | "name">>
  refetchTags: () => void
}

export const ErrorAlert = ({message}: {message: string}) => (
  <CommandGroup>
    <div className="p-3">
      <Alert color="warning" className="mb-4" data-cy="taxon-tags-alert">
        <AlertTriangle />
        <AlertContent>
          <AlertText>{message}</AlertText>
        </AlertContent>
      </Alert>
    </div>
  </CommandGroup>
)

const CommandMenuTaxonTagsPage = ({
  allTags,
  refetchTags,
  selectedRows,
  search,
  clearSearch,
  selectedRecordsIDs,
}: CommandMenuTaxonTagsPageProps) => {
  const {setSnack} = useSnackbarStore()
  const [_, addTagToTaxa] = useAddTagToTaxaMutation()
  const [__, removeTagFromTaxa] = useRemoveTagFromTaxaMutation()

  const res = useMemo(
    () =>
      pipe(
        selectedRows,
        A.map((record) => {
          const tags = record.tagsConnection?.nodes
          return {
            recordId: record.id,
            tagIds: tags?.map((tag) => tag.id) ?? [],
          }
        }),
      ),
    [selectedRows],
  )

  const [activeRecordTags, setActiveRecordTags] = useState(res)

  useEffect(() => {
    setActiveRecordTags(res)
  }, [res])

  const applyTagToSelectedRecords = useCallback(
    async (tagId: string) => {
      const res = await addTagToTaxa({
        tagId,
        ids: selectedRecordsIDs,
      })

      const addTagSuccessful = res.data?.addTagTaxa.success ?? false
      if (addTagSuccessful) {
        onSuccess(setSnack)(`Tag added to ${selectedRecordsIDs.length} taxa`)
      } else {
        onFailure(setSnack)(new Error("Failed to add tag to taxa"))
      }
      return addTagSuccessful
    },

    [addTagToTaxa, selectedRecordsIDs, setSnack],
  )

  const removeTagFromSelectedRecords = useCallback(
    async (tagId: string) => {
      const res = await removeTagFromTaxa({
        tagId,
        ids: selectedRecordsIDs,
      })

      const removeTagSuccessful = res.data?.removeTagTaxa.success ?? false

      if (removeTagSuccessful) {
        onSuccess(setSnack)(
          `Tag removed from ${selectedRecordsIDs.length} taxa`,
        )
        refetchTags()
      } else {
        onFailure(setSnack)(new Error("Failed to remove tag from taxa"))
      }
      return removeTagSuccessful
    },
    [refetchTags, removeTagFromTaxa, selectedRecordsIDs, setSnack],
  )

  const hasTags = allTags.length > 0

  return (
    <CommandMenuTagsPage
      allTags={allTags}
      shouldShowHeading={allTags.length > 0}
      selectedRecordsWithTags={activeRecordTags}
      setSelectedRecordsWithTags={setActiveRecordTags}
      hasTags={hasTags}
      search={search}
      clearSearch={clearSearch}
      applyTagToSelectedRecords={applyTagToSelectedRecords}
      removeTagFromSelectedRecords={removeTagFromSelectedRecords}
    />
  )
}

const CommandMenuCollectionSiteTagsPage = ({
  selectedRecordsIDs,
  ...props
}: Pick<
  CommandMenuTaxonTagsPageProps,
  "selectedRecordsIDs" | "search" | "clearSearch"
>) => {
  const {siteSlug} = useParams({from: "/_layout/sites/$siteSlug"})
  const {subdomain} = useRouteContext({from: "/_layout/sites/$siteSlug"})

  const [{data, fetching, error}] = useGetSiteTaxaQuery({
    variables: {
      organisationSubdomain: subdomain,
      collectionSiteSlug: siteSlug,
      last: selectedRecordsIDs.length,
      filter: {
        id: {
          in: selectedRecordsIDs,
        },
      },
    },
  })

  const selectedRows = data?.org?.site?.result?.nodes ?? []

  const [
    {data: tagsData, fetching: fetchingTags, error: tagsError},
    refetchTags,
  ] = useCollectionSiteTagsQuery({
    variables: {
      organisationSubdomain: subdomain,
      collectionSiteSlug: siteSlug,
      orgFilters: {
        hasCollectionSiteRelation: {
          eq: false,
        },
      },
    },
  })

  const orgTags = tagsData?.org?.tags?.nodes ?? []
  const siteTags = tagsData?.org?.site?.tags?.nodes ?? []

  const allTags = [...orgTags, ...siteTags].sort((a, b) =>
    a.name.localeCompare(b.name),
  )

  if (error != null || tagsError != null) {
    return <ErrorAlert message="Something went wrong." />
  }

  if ((data == null && fetching) || (tagsData == null && fetchingTags)) {
    return <CommandLoading />
  }

  return (
    <CommandMenuTaxonTagsPage
      {...props}
      selectedRecordsIDs={selectedRecordsIDs}
      selectedRows={selectedRows}
      allTags={allTags}
      refetchTags={refetchTags}
    />
  )
}

const CommandMenuOrganisationTagsPage = ({
  selectedRecordsIDs,
  ...props
}: Pick<
  CommandMenuTaxonTagsPageProps,
  "selectedRecordsIDs" | "search" | "clearSearch"
>) => {
  const {subdomain} = useRouteContext({strict: false})
  const [{data, fetching, error}] = useGetTaxaQuery({
    variables: {
      organisationSubdomain: subdomain,
      last: selectedRecordsIDs.length,
      filter: {
        id: {
          in: selectedRecordsIDs,
        },
      },
    },
  })

  const [
    {data: tagsData, fetching: fetchingTags, error: tagsError},
    refetchTags,
  ] = useOrganisationTagsQuery({
    variables: {
      organisationSubdomain: subdomain,
    },
  })

  const selectedRows = data?.org?.result?.nodes ?? []
  const allTags = tagsData?.org?.tags?.nodes ?? []

  if (error != null || tagsError != null) {
    return <ErrorAlert message="Something went wrong." />
  }

  if ((data == null && fetching) || (tagsData == null && fetchingTags)) {
    return <CommandLoading />
  }

  return (
    <CommandMenuTaxonTagsPage
      {...props}
      selectedRecordsIDs={selectedRecordsIDs}
      selectedRows={selectedRows}
      allTags={allTags}
      refetchTags={refetchTags}
    />
  )
}

export {
  CommandMenuCollectionSiteTagsPage,
  CommandMenuOrganisationTagsPage,
  CommandMenuTaxonTagsPage,
}
