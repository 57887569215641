import {IconButton} from "@hortis/ui/button"
import {Map1} from "@hortis/ui/icons"
import {LayersTwo1} from "@hortis/ui/icons/layers-two-1"
import {Popover, PopoverContent, PopoverTrigger} from "@hortis/ui/popover"
import type {BaseMapFieldsFragment} from "generated/graphql"
import type {ChangeEventHandler, HTMLAttributes} from "react"
import {memo, useCallback, useRef, useState} from "react"
import {Toggle} from "src/components/toggle"
import {Tooltip, TooltipContent, TooltipTrigger} from "@hortis/ui/tooltip"
import {twMerge} from "tailwind-merge"
import {Label} from "@hortis/ui/label"
import {useMapOptionsStore} from "src/features/collection-map/components/map-options-store"

const BaseMapButton = ({
  className,
  selected,
  ...props
}: {selected?: boolean} & HTMLAttributes<HTMLButtonElement>) => (
  <button
    className={twMerge(
      "mx-[-4px] flex items-center gap-3 truncate rounded-lg p-1 text-sm font-medium text-grey-700 outline-none ring-grey-200/70 transition-colors hover:bg-grey-50 focus-visible:ring",
      selected === true &&
        "bg-primary-50 text-primary-700 ring-primary-200/70  hover:bg-primary-50",
      className,
    )}
    type="button"
    {...props}
  />
)

interface SelectLayersProps {
  baseMap: BaseMapFieldsFragment | null
}

export const SelectLayers = memo<SelectLayersProps>(function SelectLayers({
  baseMap,
}) {
  const {
    devicePositionLayerEnabled,
    setDevicePositionLayerEnabled,
    layerType,
    setMapLayerType,
  } = useMapOptionsStore()
  const anchorEl = useRef(null)
  const [open, setOpen] = useState(false)

  const handleSetDevicePositionLayerEnabled = useCallback<
    ChangeEventHandler<HTMLInputElement>
  >(
    (e) => {
      setDevicePositionLayerEnabled(e.target.checked)
    },
    [setDevicePositionLayerEnabled],
  )

  return (
    <>
      <Popover open={open} onOpenChange={setOpen}>
        <Tooltip>
          <PopoverTrigger asChild>
            <TooltipTrigger asChild>
              <IconButton
                ref={anchorEl}
                ariaLabel="Open map options"
                size={{xs: "sm", md: "md"}}
                testId="map-options-button"
                icon={LayersTwo1}
                className="shadow-mg pointer-events-auto border-none !p-[9px] md:!p-[11px]"
              />
            </TooltipTrigger>
          </PopoverTrigger>
          <TooltipContent side="left">Layers</TooltipContent>
        </Tooltip>
        <PopoverContent align="end" className="max-w-[224px] p-4">
          <div className="space-y-3">
            <div className="flex flex-col gap-0.5">
              <p className="text-xs font-medium text-grey-500">Base</p>
              <BaseMapButton
                selected={layerType === "osm"}
                onClick={() => {
                  setMapLayerType("osm")
                }}
                data-cy="default-base-map-button"
              >
                <img
                  alt="Default map"
                  src="/images/default-map-thumbnail.jpg"
                  className="size-8 shrink-0 overflow-hidden rounded-md"
                />
                Default
              </BaseMapButton>
              <BaseMapButton
                selected={layerType === "bing"}
                onClick={() => {
                  setMapLayerType("bing")
                }}
                data-cy="satellite-base-map-button"
              >
                <img
                  alt="Satellite map"
                  src="/images/satellite-map-thumbnail.jpg"
                  className="size-8 shrink-0 overflow-hidden rounded-md"
                />
                Satellite
              </BaseMapButton>
              {baseMap != null && (
                <BaseMapButton
                  data-cy="custom-base-map-button"
                  selected={layerType === "custom"}
                  onClick={() => {
                    setMapLayerType("custom")
                  }}
                >
                  <div
                    className={twMerge(
                      "flex size-8 shrink-0 items-center justify-center rounded-md bg-grey-100 text-grey-400 transition-colors",
                      layerType === "custom" &&
                        "bg-primary-100 text-primary-400",
                    )}
                  >
                    <Map1 className="h-4 w-4" />
                  </div>
                  <span className="truncate">{baseMap.name}</span>
                </BaseMapButton>
              )}
            </div>
            <div className="space-y-0.5">
              <p className="text-xs font-medium text-grey-500">Layers</p>
              <Label className="flex items-center gap-2 py-2">
                <Toggle
                  size="xs"
                  id="device-position-layer-toggle"
                  checked={devicePositionLayerEnabled}
                  onChange={handleSetDevicePositionLayerEnabled}
                  className="text-sm font-medium"
                />
                Device position
              </Label>
            </div>
          </div>
        </PopoverContent>
      </Popover>
    </>
  )
})
