import type {DialogTitleProps as CoreDialogTitleProps} from "@mui/material/DialogTitle"
import CoreDialogTitle from "@mui/material/DialogTitle"
import Typography from "@mui/material/Typography"
import {styled} from "@mui/material/styles"
import {XClose} from "@hortis/ui/icons"
import {IconButton} from "@hortis/ui/button"
import {applyMui} from "../../utils/apply-mui"
import {withTestId} from "../../utils/with-test-id"

const StyledDialogTitle = styled(CoreDialogTitle)(() => ({
  padding: 0,
}))

export const BaseDialogTitle = applyMui(
  withTestId,
  StyledDialogTitle as (props: CoreDialogTitleProps) => JSX.Element,
)
export type DialogTitleProps = CoreDialogTitleProps

export const DialogTitle = ({
  onClose,
  children,
  ...props
}: CoreDialogTitleProps & {onClose?: () => void}) => {
  return (
    <BaseDialogTitle
      component="div"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: 2,
      }}
      {...props}
    >
      <Typography<"h1"> variant="h5" component="h1" color="text.primary">
        {children}
      </Typography>
      {onClose == null ? null : (
        <IconButton ariaLabel="close" onClick={onClose} icon={XClose} />
      )}
    </BaseDialogTitle>
  )
}
