import {useCallback, useMemo, useRef, useState} from "react"
import {Button} from "@hortis/ui/button"
import type {OpenFilterArgs} from "../plant-materials/filters"
import {AddTaxaFilter} from "./add-taxa-filter"
import {TaxaFilterChipGroup} from "./chip-group"
import {TaxaFilterModals} from "./modals/filter-modals"
import type {TaxaFiltersProps, TaxaFilterType} from "./types"

export const TaxaFilters = ({
  filters,
  dispatchFilters,
  resetFilters,
  filterCount,
  taxaType,
}: TaxaFiltersProps) => {
  const [openFilter, setOpenFilter] = useState<false | TaxaFilterType>(false)
  const [editFilterId, setEditFilterId] = useState<string | null>(null)
  const filterModalAnchorEl = useRef<HTMLElement | null>(null)
  const editFilter = useMemo(
    () =>
      editFilterId == null
        ? undefined
        : filters.and.find(({id}) => id === editFilterId),
    [filters, editFilterId],
  )

  const resetAllFilters = useCallback(() => {
    resetFilters()
  }, [resetFilters])

  const handleOpenFilter = useCallback(
    ({
      filterType,
      anchorElRef,
      editFilterId,
    }: OpenFilterArgs<TaxaFilterType>) => {
      setEditFilterId(editFilterId ?? null)
      if (anchorElRef != null) {
        filterModalAnchorEl.current = anchorElRef
      }
      setOpenFilter(filterType)
    },
    [],
  )
  const handleFilterModalClose = useCallback(() => {
    setOpenFilter(false)
  }, [])

  return (
    <div className="flex flex-1 items-start justify-between gap-3">
      <div className="flex flex-1 flex-wrap items-center gap-3">
        <TaxaFilterChipGroup
          handleOpenFilter={handleOpenFilter}
          filters={filters.and}
          dispatchFilter={dispatchFilters}
        />
        <AddTaxaFilter setOpenFilter={handleOpenFilter} iconButton />
        <TaxaFilterModals
          editFilter={editFilter}
          openFilter={openFilter}
          handleFilterModalClose={handleFilterModalClose}
          filterModalAnchorEl={filterModalAnchorEl}
          dispatchFilters={dispatchFilters}
          taxaType={taxaType}
        />
      </div>
      <div className="flex items-center gap-3">
        {filterCount > 0 && (
          <Button
            onClick={resetAllFilters}
            className="flex-shrink-0"
            variant="tertiaryColor"
            size="sm"
          >
            Clear filters
          </Button>
        )}
      </div>
    </div>
  )
}
