import {match} from "ts-pattern"
import {useGetMeWithSitesQuery, AccessRole} from "generated/graphql"

export const useAccessRole = () => {
  const [{data, error}] = useGetMeWithSitesQuery()
  const role = data?.me?.accessRole
  const userId = data?.me?.id
  return {
    role,
    userId,
    error,
    min: (minRole: AccessRole) =>
      role == null
        ? undefined
        : match(minRole)
            .with(AccessRole.ReadOnly, () => true)
            .with(AccessRole.Editor, () => role !== "READ_ONLY")
            .with(AccessRole.Admin, () => role === "ADMIN")
            .exhaustive(),
    canEdit: role != null && role !== "READ_ONLY",
    is: (eqRole: AccessRole) => (role == null ? undefined : role === eqRole),
    AccessRole,
  }
}

export type AccessRoleUtils = ReturnType<typeof useAccessRole>
