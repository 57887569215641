import create from "zustand"
import type {MeCollectionSitesFieldsFragment} from "generated/graphql"

export type SetNavSite = (
  navSite: MeCollectionSitesFieldsFragment | null,
) => void

interface NavSiteState {
  navSite: MeCollectionSitesFieldsFragment | null
  setNavSite: SetNavSite
}

export const useNavSiteStore = create<NavSiteState>((set) => ({
  navSite: null,
  setNavSite: (navSite) => {
    set({navSite})
  },
}))
