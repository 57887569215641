import Bugsnag from "@bugsnag/js"
import type {BugsnagErrorBoundary} from "@bugsnag/plugin-react"
// eslint-disable-next-line no-restricted-imports, unicorn/import-style -- we need to import * as React for axe
import * as React from "react"
import {Fragment} from "react"
import getBugsnag from "./bugsnag"

const createBugsnagErrorBoundary = () => {
  const bugsnag = getBugsnag()

  return (
    (bugsnag == null
      ? null
      : Bugsnag.getPlugin("react")?.createErrorBoundary(React)) ?? Fragment
  )
}

export const useBugsnagErrorBoundary = () => {
  const bugsnagErrorBoundaryRef = React.useRef<BugsnagErrorBoundary | null>(
    null,
  )

  if (bugsnagErrorBoundaryRef.current === null) {
    bugsnagErrorBoundaryRef.current = createBugsnagErrorBoundary()
  }

  return bugsnagErrorBoundaryRef.current
}
