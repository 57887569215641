import {colors} from "src/colors"
import type {IconProps} from "./types"

export const MultipleUsers = ({
  color = colors.grey[500],
  ...props
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M5.09961 8.6999C5.09961 9.57512 5.44729 10.4145 6.06616 11.0334C6.68503 11.6522 7.5244 11.9999 8.39961 11.9999C9.27482 11.9999 10.1142 11.6522 10.7331 11.0334C11.3519 10.4145 11.6996 9.57512 11.6996 8.6999C11.6996 7.82469 11.3519 6.98532 10.7331 6.36645C10.1142 5.74758 9.27482 5.3999 8.39961 5.3999C7.5244 5.3999 6.68503 5.74758 6.06616 6.36645C5.44729 6.98532 5.09961 7.82469 5.09961 8.6999V8.6999Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 18.6C3 17.1678 3.56893 15.7943 4.58162 14.7816C5.59432 13.7689 6.96783 13.2 8.4 13.2C9.83217 13.2 11.2057 13.7689 12.2184 14.7816C13.2311 15.7943 13.8 17.1678 13.8 18.6"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.8818 10.4998C13.8818 11.2159 14.1663 11.9026 14.6726 12.409C15.179 12.9153 15.8658 13.1998 16.5818 13.1998C17.2979 13.1998 17.9847 12.9153 18.491 12.409C18.9974 11.9026 19.2818 11.2159 19.2818 10.4998C19.2818 9.78372 18.9974 9.09696 18.491 8.59062C17.9847 8.08427 17.2979 7.7998 16.5818 7.7998C15.8658 7.7998 15.179 8.08427 14.6726 8.59062C14.1663 9.09696 13.8818 9.78372 13.8818 10.4998V10.4998Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.0508 14.4544C15.7188 14.2082 16.4365 14.127 17.1427 14.2177C17.8489 14.3083 18.5227 14.5683 19.1069 14.9753C19.6911 15.3824 20.1683 15.9245 20.498 16.5556C20.8276 17.1866 21 17.888 21.0004 18.6"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
