import {Button, IconButton} from "@hortis/ui/button"
import {Dialog, DialogTrigger} from "@hortis/ui/dialog"
import {Edit5} from "@hortis/ui/icons"
import {useState} from "react"
import {RecordNotesDialog} from "src/components/dialog/variants/record-notes"
import {
  RecordSection,
  RecordSectionContent,
  RecordSectionHeader,
  RecordSectionHeaderButtons,
  RecordSectionTitle,
} from "src/features/records/components/record-section/new-record-section"

export const RecordNotesSection = ({
  notes,
  requestEdit,
  canEdit,
}: {
  notes: string | undefined | null
  requestEdit?: () => void
  canEdit: boolean
}) => {
  const [recordNotesDialogOpen, setRecordNotesDialogOpen] = useState(false)

  const closeDialog = () => {
    setRecordNotesDialogOpen(false)
  }

  return (
    <RecordSection
      id="notes"
      className="material-record-section"
      data-cy="record-notes"
    >
      <RecordSectionHeader>
        <RecordSectionTitle>Notes</RecordSectionTitle>
        <RecordSectionHeaderButtons>
          {canEdit && (
            <IconButton
              onClick={requestEdit}
              ariaLabel="Edit section"
              data-cy="section-edit-button"
              size="xs"
              variant="tertiaryGray"
              icon={Edit5}
            />
          )}
        </RecordSectionHeaderButtons>
      </RecordSectionHeader>

      <RecordSectionContent className="items-start gap-2 break-all text-sm">
        {notes == null || notes.length === 0 ? (
          "-"
        ) : notes.length > 250 ? (
          <>
            <p>{notes.slice(0, 250).trim()}...</p>
            <Dialog
              open={recordNotesDialogOpen}
              onOpenChange={setRecordNotesDialogOpen}
            >
              <DialogTrigger>
                <Button
                  data-cy="view-all-notes-button"
                  size="sm"
                  variant="linkgray"
                  className="font-medium text-primary-700"
                >
                  View all
                </Button>
              </DialogTrigger>
              <RecordNotesDialog
                notes={notes}
                editNotes={requestEdit}
                closeDialog={closeDialog}
                canEdit={canEdit}
              />
            </Dialog>
          </>
        ) : (
          <p>{notes}</p>
        )}
      </RecordSectionContent>
    </RecordSection>
  )
}
