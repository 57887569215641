import {type Condition} from "generated/graphql"
import {
  conditionLabelDotColorMap,
  conditionLabelMap,
} from "src/utils/label-maps/condition"
import {twMerge} from "tailwind-merge"

interface ConditionRatingProps {
  condition: Condition
}

/* Feels like this should be inside Hortis/UI but not sure about importing the enums/generatedGQL */
export const ConditionRating = ({condition}: ConditionRatingProps) => {
  const circles = Array.from({length: 5}).fill(null)
  const conditionOrder = Object.keys(conditionLabelMap) as Array<Condition>
  const conditionIndex = 5 - conditionOrder.indexOf(condition)

  return (
    <div className="flex items-center gap-2">
      <span>{conditionLabelMap[condition]}</span>
      <div className="flex gap-1">
        {circles.map((_, index) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className={twMerge(
              "h-1.5 w-1.5 rounded-full",
              index < conditionIndex
                ? conditionLabelDotColorMap[condition]
                : "border border-grey-200",
            )}
          />
        ))}
      </div>
    </div>
  )
}
