import {IconButton} from "@hortis/ui/button"
import {ContextMenu, ContextMenuTrigger} from "@hortis/ui/context-menu"
import {FilterLines} from "@hortis/ui/icons"
import {pipe, R} from "@mobily/ts-belt"
import {
  useNavigate,
  useSearch,
  type UseNavigateResult,
} from "@tanstack/react-router"
import {AccessionsSearchColumn, useGetAccessionsQuery} from "generated/graphql"
import {useCallback, useEffect, useMemo, useRef, useState} from "react"
import {List} from "src/components"
import {
  DataTable,
  DataTableFooter,
  DataTableRow,
  DataTableToolbar,
} from "src/components/data-table"
import {
  accessionColumns,
  trashAccessionColumns,
  type AccessionsColumnIds,
} from "src/components/data-table/columns/accession-columns"
import {useTable} from "src/components/data-table/use-table"
import {ViewButton} from "src/components/data-table/view-button"
import {MobileDrawer} from "src/components/mobile-drawer"
import {Toggle} from "src/components/toggle/toggle"
import AccessionsActionMenu from "src/features/actions-menu/variants/accessions-action-menu"
import {SearchBar} from "src/features/collection/components/search-bar/search-bar"
import {showErrorMessage} from "src/features/collection/components/show-error-message"
import {AccessionsContextMenuContent} from "src/features/context-menu/variants/accessions-context-menu"
import {AccessionsFilters} from "src/features/filters/accessions/accessions-filters"
import {AccessionsMobileFilters} from "src/features/filters/accessions/accessions-mobile-filters"
import {AddAccessionFilter} from "src/features/filters/accessions/add-accession-filter"
import {AccessionFilterModals} from "src/features/filters/accessions/filter-modals/filter-modals"
import {AccessionsSortSelect} from "src/features/filters/accessions/sort-select"
import {useAccessionFilters} from "src/features/filters/accessions/use-accession-filters"
import {FilterActionTypes} from "src/features/filters/plant-materials/filter-reducer"
import {type CollectionSiteSettings} from "src/utils/hooks/collection-site-from-url"
import type {CollectionSiteHrefs} from "src/utils/hooks/hrefs"
import {useMobile} from "src/utils/hooks/media-query"
import {usePlaceStruct} from "src/utils/hooks/place"
import {useFilterState} from "src/utils/hooks/tables/filter-state"
import {usePagination} from "src/utils/hooks/tables/pagination"
import {useRowClick} from "src/utils/hooks/tables/row-click"
import {useTableSearch} from "src/utils/hooks/tables/search"
import {getSelectedRowIds, useSelect} from "src/utils/hooks/tables/select-mode"
import {useSort} from "src/utils/hooks/tables/sort"
import {useToggle} from "src/utils/hooks/toggle"
import {createRelayParams} from "src/utils/pagination"
import {useAccessToken} from "src/utils/use-access-token"
import * as Path from "../../../../utils/path"
import {TrashAccessionsDialog} from "../archive-records/trash-accessions-dialog"
import {useDownloadCsv} from "../download-accessions/use-download-csv"
import {EmptyList} from "../plant-materials/empty-list"
import {AccessionsMobileList} from "./accessions-mobile-list-new"
import {AccessionsUtilityMoreButton} from "./accessions-more-utility-button"
import {processAccessionsQuery} from "./process-accessions-query"
import {accessionsSearchMenuLabels} from "./search-labels"
import {
  defaultColumnVisibility,
  filterColumnMap,
  sortColumnMap,
} from "./table-config"

interface AccessionsListProps {
  downloadRequested: boolean
  resetDownloadRequested: () => void
  collectionSiteHrefs: CollectionSiteHrefs | undefined
  collectionSiteSettings: CollectionSiteSettings
  isTrash?: boolean
}

export const AccessionsList = ({
  downloadRequested,
  resetDownloadRequested,
  collectionSiteHrefs,
  collectionSiteSettings,
  isTrash = false,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
AccessionsListProps) => {
  const isMobile = useMobile({defaultMatches: false})
  const persistenceKey = isTrash
    ? "accessions-trash-list-filters"
    : "accessions-list-filters"

  const {
    filters,
    preparedFilters,
    dispatchFilters,
    filterCounts,
    resetFilters,
  } = useAccessionFilters({
    initialState: {
      excludeDeaccessioned: !isTrash,
      archived: isTrash ? {eq: true} : undefined,
    },
    persistenceKey,
  })
  const setExcludeDeaccessionedAccessionsFilter = useCallback(
    (checked: boolean) => {
      dispatchFilters({
        type: FilterActionTypes.MODIFY_ROOT_FILTER,
        payload: {excludeDeaccessioned: checked},
      })
    },
    [dispatchFilters],
  )

  const accessToken = useAccessToken()
  const navigate = useNavigate({
    from: isTrash ? "/sites/$siteSlug/trash" : "/sites/$siteSlug/collection",
  }) as UseNavigateResult<string> // A workaround, there may need to be a refactor to prevent the need for this conditional `from`.
  const search = useSearch({
    strict: false,
  })
  const rows = "rows" in search ? search.rows : undefined
  const accSearch = "accSearch" in search ? search.accSearch : undefined
  const accCursor = "accCursor" in search ? search.accCursor : undefined
  const accDir = "accDir" in search ? search.accDir : undefined
  const accOrderBy = "accOrderBy" in search ? search.accOrderBy : undefined
  const rowsPerPage = rows ?? 25

  const place = usePlaceStruct()
  const pause = place.data == null || accessToken._tag === "None"
  const [accessionsQuery] = useGetAccessionsQuery({
    variables: {
      ...createRelayParams({cursor: accCursor, dir: accDir, rowsPerPage}),
      organisationSubdomain: place.data?.orgName ?? "",
      collectionSiteSlug: place.data?.siteSlug ?? "",
      searchTerm: accSearch,
      orderBy: accOrderBy,
      filters: preparedFilters,
      includeArchived: isTrash,
      plantMaterialFilters: {},
    },
    pause,
  })

  const loading =
    (accessionsQuery.fetching && accessionsQuery.data == null) || pause

  const {error, data} = pipe(
    processAccessionsQuery({accessionsQuery, place}),
    R.match(
      (data): {data: typeof data | null; error: string | null} => ({
        data,
        error: null,
      }),
      (error) => ({error, data: null}),
    ),
  )

  const handleRowsChange = useCallback(
    (rows: number | null) => {
      void navigate({search: (prev) => ({...prev, rows: rows ?? undefined})})
    },
    [navigate],
  )

  const dataMemo = useMemo(() => data?.nodes ?? [], [data?.nodes])

  const disabledColumnIds = useMemo<Array<AccessionsColumnIds>>(
    () => (collectionSiteSettings.ipenNumbersEnabled ? [] : ["IPEN number"]),
    [collectionSiteSettings.ipenNumbersEnabled],
  )

  const {table, ref, tableRef, mobileListRef} = useTable({
    columns: isTrash ? trashAccessionColumns : accessionColumns,
    data: dataMemo,
    tableId: "site-accessions-table",
    defaultColumnVisibility,
    disabledColumnIds,
  })

  const {nextPageLoading, previousPageLoading, onPreviousPage, onNextPage} =
    usePagination({
      navigate,
      pageInfo: data?.pageInfo,
      cursor: accCursor,
      fetching: accessionsQuery.fetching,
      tableEl: tableRef,
      mobileListEl: mobileListRef.current,
      cursorKey: "accCursor",
      dirKey: "accDir",
    })

  const {selectMode, toggleSelectMode} = useSelect({
    resetRowSelection: table.resetRowSelection,
    filterCounts,
    sort: table.getState().sorting,
    search: accSearch,
  })

  const {handleSearchClear, handleSearchSubmit, handleClearSearchAndFilters} =
    useTableSearch({
      navigate,
      search: accSearch,
      resetSelectedRows: table.resetRowSelection,
      resetFilters,
      searchKey: "accSearch",
      cursorKey: "accCursor",
      dirKey: "accDir",
    })

  const {
    updateOrderBy,
    viewMenuOpen,
    toggleViewMenuOpen,
    updateOrderByFromViewMenu,
  } = useSort({
    navigate,
    sorting: table.getState().sorting,
    resetSorting: table.resetSorting,
    setSorting: table.setSorting,
    orderBy: accOrderBy,
    sortParamKey: "accOrderBy",
    cursorKey: "accCursor",
    dirKey: "accDir",
    sortColumnMap,
  })

  const [trashDialogOpen, setTrashDialogOpen] = useState(false)
  const [actionsMenuOpen, toggleActionsMenuOpen] = useToggle()
  const trashButtonRef = useRef<HTMLButtonElement | null>(null)

  const selectedRecordsIDs = getSelectedRowIds(table.getState().rowSelection)

  const onRowClick = useRowClick<(typeof dataMemo)[number]>({
    urlFn: ({accessionNumber}) =>
      `/${Path.concat(
        collectionSiteHrefs?.accessions ?? "", // TODO: Think about how to handle this
        encodeURIComponent(accessionNumber),
      )}`,
  })
  const handleDownload = useDownloadCsv({
    csvName: "accessions",
    path: "/download/accessions",
    body: {
      searchTerm: accSearch,
      filters: {
        ...preparedFilters,
        id: {
          in: selectedRecordsIDs,
        },
      },
    },
  })

  useEffect(() => {
    if (downloadRequested) {
      void handleDownload()
      resetDownloadRequested()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- due to body prop, handleDownload does not have stable identity
  }, [downloadRequested, resetDownloadRequested])

  const {
    openedFilter,
    filterButtonRef,
    handleFilterModalClose,
    setOpenFilter,
    openedFilterToolbar,
  } = useFilterState({
    filterColumnMap,
    resetColumnFilters: table.resetColumnFilters,
    columnFilters: table.getState().columnFilters,
  })

  const [mobileFiltersExpanded, toggleMobileFiltersExpanded] = useToggle()

  const emptyState = useMemo(
    () => (
      <EmptyList
        isTrash={isTrash}
        type="accession"
        list={data?.nodes ?? undefined}
        searchTerm={accSearch}
        clearSearch={handleClearSearchAndFilters}
        filtersActive={
          filterCounts.total - filterCounts.excludeDeaccessioned > 0
        }
      />
    ),
    [
      isTrash,
      data?.nodes,
      accSearch,
      handleClearSearchAndFilters,
      filterCounts.total,
      filterCounts.excludeDeaccessioned,
    ],
  )

  return (
    <>
      {error != null && !loading ? (
        showErrorMessage(new Error(error))
      ) : isMobile ? (
        <div className="flex h-full flex-col overflow-hidden">
          <List.Toolbar>
            <div className="flex items-center gap-2">
              <SearchBar
                testId="search-form"
                menuLabels={accessionsSearchMenuLabels}
                handleSubmitCallback={handleSearchSubmit}
                handleClearCallback={handleSearchClear}
                searchTermOverride={accSearch}
                className="flex-1"
                searchFieldIfNumber={AccessionsSearchColumn.AccessionNum}
              />
              <IconButton
                icon={FilterLines}
                ariaLabel="Filters"
                testId="filters-button-mobile"
                onClick={toggleMobileFiltersExpanded}
                size="sm"
              />
              <MobileDrawer
                testId="mobile-drawer"
                open={mobileFiltersExpanded}
                onClose={toggleMobileFiltersExpanded}
                content={
                  <AccessionsMobileFilters
                    excludeDeaccessionedAccessions={
                      filters.excludeDeaccessioned === true
                    }
                    onChange={setExcludeDeaccessionedAccessionsFilter}
                    filters={filters}
                    dispatchFilters={dispatchFilters}
                    resetFilters={resetFilters}
                    filterCount={filterCounts.total}
                    updateOrderBy={updateOrderBy}
                    orderBy={accOrderBy}
                  />
                }
              />
              <AccessionsUtilityMoreButton
                searchTerm={accSearch}
                filters={preparedFilters}
              />
            </div>
          </List.Toolbar>
          <AccessionsMobileList
            table={table}
            selectMode={selectMode}
            emptyState={emptyState}
          />
          <List.Footer
            selectedRowCount={selectedRecordsIDs.length}
            handleDownload={handleDownload}
            selectAllRows={() => {
              const currentPageRecordsCount =
                table.getSelectedRowModel().rows.length
              table.toggleAllPageRowsSelected(currentPageRecordsCount <= 0)
            }}
            selectMode={selectMode}
            toggleSelectMode={toggleSelectMode}
            onPreviousPage={onPreviousPage}
            onNextPage={onNextPage}
            onActionsMenu={toggleActionsMenuOpen}
            onTrashRecords={() => {
              setTrashDialogOpen(true)
            }}
            trashButtonRef={trashButtonRef}
            total={data?.accessionsTotal}
            hasPreviousPage={data?.pageInfo.hasPreviousPage}
            hasNextPage={data?.pageInfo.hasNextPage}
            previousPageLoading={previousPageLoading}
            nextPageLoading={nextPageLoading}
            testIdPrefix="accessions"
            isTrash={isTrash}
          />
        </div>
      ) : (
        <>
          <AccessionFilterModals
            openFilter={openedFilterToolbar ?? openedFilter?.id ?? false}
            handleFilterModalClose={handleFilterModalClose}
            filterModalAnchorEl={
              openedFilterToolbar == null
                ? openedFilter?.value
                : filterButtonRef
            }
            dispatchFilters={dispatchFilters}
          />
          <DataTableToolbar className="flex flex-col gap-4">
            <div className="flex flex-col gap-5">
              <div className="flex flex-1 items-center justify-between gap-3">
                <SearchBar
                  testId="search-form"
                  menuLabels={accessionsSearchMenuLabels}
                  handleSubmitCallback={handleSearchSubmit}
                  handleClearCallback={handleSearchClear}
                  searchTermOverride={accSearch}
                  className="max-w-md flex-1"
                  searchFieldIfNumber={AccessionsSearchColumn.AccessionNum}
                />
                <div className="flex items-center gap-3">
                  <AddAccessionFilter
                    setOpenFilter={setOpenFilter}
                    align="end"
                    buttonProps={{
                      size: "sm",
                      testId: "add-filter-button",
                      startIcon: FilterLines,
                      ref: filterButtonRef,
                      children: "Filter",
                    }}
                  />
                  <ViewButton
                    table={table}
                    testId="accessions-view-button"
                    defaultColumnVisibility={defaultColumnVisibility}
                    open={viewMenuOpen}
                    toggleOpen={toggleViewMenuOpen}
                    aboveMenu={
                      <div>
                        <div className="border-b border-grey-200 px-4 py-4">
                          <AccessionsSortSelect
                            fullWidth
                            buttonProps={{size: "xs"}}
                            orderBy={accOrderBy}
                            updateOrderBy={updateOrderByFromViewMenu}
                          />
                        </div>
                        <label // eslint-disable-line jsx-a11y/label-has-associated-control
                          className="flex items-center justify-between border-b border-grey-200 px-3.5 py-4"
                        >
                          Show deaccessioned
                          <Toggle
                            size="xs"
                            testId="show-deaccessioned-toggle"
                            onChange={(e) => {
                              setExcludeDeaccessionedAccessionsFilter(
                                !e.target.checked,
                              )
                            }}
                            checked={filters.excludeDeaccessioned !== true}
                          />
                        </label>
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
          </DataTableToolbar>
          {filterCounts.total - filterCounts.excludeDeaccessioned > 0 && (
            <DataTableToolbar className="flex border-t border-grey-200">
              <AccessionsFilters
                hideDeaccessionedFilter
                excludeDeaccessionedAccessions={
                  filters.excludeDeaccessioned === true
                }
                onChange={setExcludeDeaccessionedAccessionsFilter}
                filters={filters}
                dispatchFilters={dispatchFilters}
                resetFilters={resetFilters}
                filterCount={filterCounts.total}
                updateOrderBy={updateOrderBy}
                orderBy={accOrderBy}
              />
            </DataTableToolbar>
          )}
          <DataTable
            table={table}
            ref={ref}
            loading={loading}
            emptyState={emptyState}
          >
            {table.getRowModel().rows.map((row) => {
              return (
                <ContextMenu key={row.id}>
                  <ContextMenuTrigger asChild>
                    <DataTableRow
                      data-cy="accessions-table-row"
                      key={row.id}
                      row={row}
                      onClick={(e) => {
                        onRowClick(e, row.original)
                      }}
                    />
                  </ContextMenuTrigger>
                  <AccessionsContextMenuContent
                    isTrash={isTrash}
                    accSearch={accSearch}
                    preparedFilters={preparedFilters}
                    clickedRow={row.original}
                    selectedRecordsIDs={selectedRecordsIDs}
                    onRequestDownload={handleDownload}
                  />
                </ContextMenu>
              )
            })}
          </DataTable>
          <DataTableFooter
            table={table}
            onPreviousPage={onPreviousPage}
            onNextPage={onNextPage}
            total={data?.accessionsTotal}
            hasPreviousPage={data?.pageInfo.hasPreviousPage}
            onTrashRecords={() => {
              setTrashDialogOpen(true)
            }}
            trashButtonRef={trashButtonRef}
            hasNextPage={data?.pageInfo.hasNextPage}
            onRequestDownload={handleDownload}
            onActionsMenu={toggleActionsMenuOpen}
            previousPageLoading={previousPageLoading}
            nextPageLoading={nextPageLoading}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsChange}
            testIdPrefix="accessions"
            fetching={accessionsQuery.fetching}
          />
        </>
      )}
      {selectedRecordsIDs.length > 0 && (
        <>
          <TrashAccessionsDialog
            setOpen={setTrashDialogOpen}
            open={trashDialogOpen}
            accessionsIds={selectedRecordsIDs}
            triggerRef={trashButtonRef}
            onSuccess={() => {
              table.resetRowSelection()
              toggleSelectMode()
            }}
          />

          <AccessionsActionMenu
            selectedRecordsIDs={selectedRecordsIDs}
            open={actionsMenuOpen}
            toggleOpen={toggleActionsMenuOpen}
            onRequestDownload={handleDownload}
            openTrashRecordModal={setTrashDialogOpen}
          />
        </>
      )}
    </>
  )
}
