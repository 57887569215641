const currentOs = (userAgent: string) => {
  const isWindows = () => /windows/i.test(userAgent)
  const isMac = () => /mac/i.test(userAgent)
  const isLinux = () => /linux/i.test(userAgent)
  return {
    isWindows,
    isMac,
    isLinux,
  }
}
export const useOperatingSystemDetect = () => {
  const userAgent =
    typeof navigator === "undefined" ? "SSR" : navigator.userAgent
  return currentOs(userAgent)
}
export const getOperatingSystem = () => {
  const userAgent =
    typeof navigator === "undefined" ? "SSR" : navigator.userAgent
  const {isWindows, isMac, isLinux} = currentOs(userAgent)
  if (isWindows()) {
    return "windows"
  }
  if (isMac()) {
    return "mac"
  }
  if (isLinux()) {
    return "linux"
  }
  return "unknown"
}
