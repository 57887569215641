import {useCallback} from "react"
import type {ButtonProps} from "@hortis/ui/button"
import {Button} from "@hortis/ui/button"
import {FeaturedIcon} from "src/components/featured-icon/featured-icon"
import {SlDeleteIcon} from "src/components/icons/streamline/delete"
import {colors} from "src/colors"
import type {DialogProps} from "../dialog"
import {Dialog} from "../dialog"

export const DeleteModal = ({
  onClose,
  onSubmit,
  body,
  title,
  submitButtonProps,
  cancelButtonProps,
  ...props
}: DialogProps & {
  onClose: () => void
  onSubmit: () => void
  body: string
  title: string
  submitButtonProps?: ButtonProps
  cancelButtonProps?: ButtonProps
  testId?: string
}) => {
  const handleClose = useCallback(() => {
    onClose()
  }, [onClose])

  return (
    <Dialog
      {...props}
      onClose={onClose}
      sx={{"& .MuiDialog-paper": {maxWidth: "400px"}}}
      TransitionProps={{unmountOnExit: true}}
    >
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-5">
          <FeaturedIcon color="error">
            <SlDeleteIcon color={colors.error[600]} />
          </FeaturedIcon>
          <div className="flex flex-col gap-2">
            <div className="text-lg font-medium text-grey-900">{title}</div>
            <p className="text-sm text-grey-500">{body}</p>
          </div>
        </div>
        <div className="flex flex-col gap-3 sm:flex-row">
          <Button
            containerCss="flex-1"
            size="lg"
            onClick={handleClose}
            testId="delete-dialog-cancel"
            {...cancelButtonProps}
          >
            Cancel
          </Button>
          <Button
            containerCss="flex-1"
            size="lg"
            variant="destructive"
            testId="delete-dialog-submit"
            onClick={onSubmit}
            {...submitButtonProps}
          >
            Delete
          </Button>
        </div>
      </div>
    </Dialog>
  )
}
