import {colors} from "../../theme/colors"
import type {IconProps} from "./types"

export const HortisLogo = ({
  size,
  color = colors.primary[500],
  ...props
}: IconProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 100 100"
    fill="none"
    {...props}
  >
    <path
      d="M50.0004 19.9258C34.7397 19.9258 22.1305 31.2793 20.1567 46.0016C23.5796 46.4424 26.8644 47.3244 29.9526 48.588C30.4998 37.9888 39.2662 29.563 50.0004 29.563C54.1956 29.563 58.09 30.85 61.3108 33.0507L68.2648 26.0961C63.1984 22.2251 56.868 19.9258 50.0004 19.9258Z"
      fill={color}
    />
    <path
      d="M60 50C60 55.5228 55.5228 60 50 60C44.4772 60 40 55.5228 40 50C40 44.4772 44.4772 40 50 40C55.5228 40 60 44.4772 60 50Z"
      fill={color}
    />
    <path
      d="M73.9428 31.775L66.9072 38.8112C68.7348 41.6592 69.8628 44.9992 70.048 48.5884C73.136 47.3248 76.4208 46.4428 79.8436 46.0016C79.1328 40.6976 77.0412 35.8311 73.9428 31.775Z"
      fill={color}
    />
    <path
      d="M46.0724 7.15256e-08C21.6322 1.89217 2.10014 21.2932 0 45.6776H10.0848C12.1204 26.842 27.1795 11.9115 46.0724 10.077V7.15256e-08Z"
      fill={color}
    />
    <path
      d="M53.9276 10.077C72.8204 11.9115 87.8796 26.842 89.9152 45.6776H100C97.9 21.2932 78.368 1.8922 53.9276 0V10.077Z"
      fill={color}
    />
    <path
      d="M99.9552 54.4068C97.8888 78.616 78.5888 97.9132 54.364 100V84.9592C54.364 68.0856 68.0412 54.4068 84.9128 54.4068H99.9552Z"
      fill={color}
    />
    <path
      d="M45.6364 100C21.4116 97.9132 2.11159 78.616 0.0451932 54.4068H15.0876C31.9591 54.4068 45.6364 68.0856 45.6364 84.9592V100Z"
      fill={color}
    />
  </svg>
)
