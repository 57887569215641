import type {Refinement} from "fp-ts/Refinement"
import {pipe, flow, identity, untupled} from "fp-ts/function"
import * as RA from "fp-ts/ReadonlyArray"
import * as Record from "fp-ts/Record"

export type Ref<A> = Refinement<unknown, A>

/**
 * Refinement transformer, combining a refinement and a transforming function
 *
 * @see {@link https://ramdajs.com/docs/#cond}
 * @see {@link https://clojuredocs.org/clojure.core/cond}
 */
export type RefTrans<Type, Result> = {
  ref: Ref<Type>
  fn: (a: Type) => Result
}

/**
 * Create a reference transformer by first specifying the refinement,
 * and then the (input type inferred) transformer.
 */
export const refTrans =
  <Type>(ref: Ref<Type>) =>
  <Result>(fn: (a: Type) => Result): RefTrans<Type, Result> => ({
    ref,
    fn,
  })

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RefTransSet = Array<RefTrans<any, any>>

type RefMapStruct<_RefTransSet extends RefTransSet> = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Struct extends Record<string | number | symbol, any>,
>(
  struct: Struct,
) => {
  [Key in keyof Struct]: ApplyRefTrans<_RefTransSet, Struct[Key]>
}

type ApplyRefTrans<
  _RefTransSet extends RefTransSet,
  Value,
> = _RefTransSet extends [RefTrans<infer Type, infer Result>, ...infer Rest]
  ? Value extends Type
    ? Result
    : Rest extends RefTransSet
    ? ApplyRefTrans<Rest, Value>
    : never
  : Value

const concatRefTrans = RA.reduceRight(
  identity,
  (refTrans: RefTrans<unknown, unknown>, next: (value: unknown) => unknown) =>
    (value: unknown) =>
      refTrans.ref(value) ? refTrans.fn(value) : next(value),
)

/**
 * Map one or more refinement transformer pairs {@link RefTrans} over a Struct,
 * transforming any fields that match and preserving any unmatched fields.
 *
 * @remarks
 * Similar to Ramda's cond mapped over an object, but type aware
 *
 * @see {@link https://ramdajs.com/docs/#cond}
 * @see {@link https://clojuredocs.org/clojure.core/cond}
 */
export const refMap = flow(untupled(concatRefTrans), Record.map) as <
  _RefTransSet extends RefTransSet,
>(
  ...refMaps: _RefTransSet
) => RefMapStruct<_RefTransSet>

export interface Unary<In, Out> {
  input: In
  output: Out
}

type StringMapper = Unary<string, string>
export interface Prefixer<Prefix extends string> extends StringMapper {
  output: `${Prefix}${this["input"]}`
}

export interface CapitaliseFn extends Unary<string, string> {
  output: Capitalize<this["input"]>
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ApplyUnary<UnaryFn extends Unary<any, any>, Input> = (UnaryFn & {
  input: Input
})["output"]

export type MapObj<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Obj extends Record<string, any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Map extends Unary<string, any>,
> = {
  [Key in keyof Obj as ApplyUnary<Map, Key>]: Obj[Key]
}

export const prefixKeys =
  <Prefix extends string>(prefix: Prefix) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  <In extends Record<string, any>>(obj: In): MapObj<In, Prefixer<Prefix>> =>
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    pipe(
      obj,
      Object.entries,
      RA.map(([key, value]) => [`${prefix}${key}`, value] as const),
      Object.fromEntries,
    )

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const capitaliseKeys = <In extends Record<string, any>>(
  obj: In,
): MapObj<In, CapitaliseFn> =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  pipe(
    obj,
    Object.entries,
    RA.map(
      ([key, value]) =>
        [`${key.slice(0, 1).toUpperCase()}${key.slice(1)}`, value] as const,
    ),
    Object.fromEntries,
  )

export const camelCasePrefix: <Prefix extends string>(
  prefix: Prefix,
) => // eslint-disable-next-line @typescript-eslint/no-explicit-any
<In extends Record<string, any>>(
  obj: In,
) => MapObj<MapObj<In, CapitaliseFn>, Prefixer<Prefix>> = (prefix) =>
  flow(capitaliseKeys, prefixKeys(prefix))
