import {IconButton} from "@hortis/ui/button"
import {Edit5} from "@hortis/ui/icons"
import {format} from "date-fns"
import {
  PlantMaterialStatus,
  type MaterialFieldsFragment,
} from "generated/graphql"
import {type ReactElement} from "react"
import {
  RecordField,
  RecordFieldLabel,
  RecordFieldValue,
} from "src/features/records/components/record-field/new-record-field"
import {
  RecordSection,
  RecordSectionContent,
  RecordSectionContentGroup,
  RecordSectionHeader,
  RecordSectionHeaderButtons,
  RecordSectionTitle,
} from "src/features/records/components/record-section/new-record-section"
import {
  MaterialEditStageCallback,
  type MaterialEditStage,
} from "src/features/records/material/components/edit-drawer/types"
import {useCollectionSiteSettings} from "src/utils/hooks/collection-site-from-url"
import {parseUtcDate} from "src/utils/dates/parse-utc-date"
import {materialSexLabelMap} from "src/utils/label-maps/material-sex"

export interface MaterialRecordDetailsProps {
  material: MaterialFieldsFragment
  requestEdit?: (stage?: MaterialEditStage) => void
  canEdit: boolean
}

export const MaterialRecordDetails = ({
  requestEdit,
  canEdit,
  material,
}: MaterialRecordDetailsProps): ReactElement => {
  const {tagNumbersEnabled, sexEnabled} = useCollectionSiteSettings()

  const editDetails = MaterialEditStageCallback.useEditDetails(
    requestEdit == null ? () => null : requestEdit,
  )

  return (
    <RecordSection id="details" className="material-record-section">
      <RecordSectionHeader>
        <RecordSectionTitle>Details</RecordSectionTitle>
        <RecordSectionHeaderButtons>
          {canEdit && !material.archived && (
            <IconButton
              onClick={editDetails}
              ariaLabel="Edit section"
              data-cy="section-edit-button"
              size="xs"
              variant="tertiaryGray"
              icon={Edit5}
            />
          )}
        </RecordSectionHeaderButtons>
      </RecordSectionHeader>

      <RecordSectionContent>
        <RecordSectionContentGroup className="grid grid-cols-2">
          <RecordField data-cy="record-date-added">
            <RecordFieldLabel>Date added</RecordFieldLabel>
            <RecordFieldValue>
              {material.firstPresent == null
                ? "-"
                : format(parseUtcDate(material.firstPresent), "PPP")}
            </RecordFieldValue>
          </RecordField>

          {material.weight == null ? (
            <RecordField data-cy="record-quantity">
              <RecordFieldLabel>Quantity</RecordFieldLabel>
              <RecordFieldValue>
                {material.quantity == null
                  ? material.massPlanting
                    ? "Mass"
                    : null
                  : material.quantity}
              </RecordFieldValue>
            </RecordField>
          ) : (
            <RecordField data-cy="record-weight">
              <RecordFieldLabel>Weight</RecordFieldLabel>
              <RecordFieldValue>{material.weight} grams</RecordFieldValue>
            </RecordField>
          )}

          {material.status === PlantMaterialStatus.Absent && (
            <RecordField data-cy="date-removed">
              <RecordFieldLabel>Date removed</RecordFieldLabel>
              <RecordFieldValue>
                {material.firstAbsent == null
                  ? "-"
                  : format(parseUtcDate(material.firstAbsent), "PPP")}
              </RecordFieldValue>
            </RecordField>
          )}

          {tagNumbersEnabled && (
            <RecordField data-cy="record-tagNumber">
              <RecordFieldLabel>Tag number</RecordFieldLabel>
              <RecordFieldValue>{material.tagNumber ?? "-"}</RecordFieldValue>
            </RecordField>
          )}

          {sexEnabled && (
            <RecordField data-cy="record-sex">
              <RecordFieldLabel>Sex</RecordFieldLabel>
              <RecordFieldValue>
                {material.sex == null ? "-" : materialSexLabelMap[material.sex]}
              </RecordFieldValue>
            </RecordField>
          )}
        </RecordSectionContentGroup>
      </RecordSectionContent>
    </RecordSection>
  )
}
