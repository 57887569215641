import {TaxonScientificNameAutocomplete} from "src/components/autocomplete/scientific-names/taxon-scientific-name-autocomplete"
import {SettingsSectionHeader} from "src/features/settings/components/settings-section-header"
import {OrgTagsAutocomplete} from "src/features/tags/tags-autocomplete-alt/tags-autocomplete-alt"
import {FormTextField} from "../../../../components/text-field/text-field"
import {AuthorshipField} from "./authorship-field"
import {NativeDistributionField} from "./native-distribution-field"

interface TaxonDataFieldsProps {
  formNamePrefix?: string
  interpretationFieldsEnabled?: boolean
}

export const TaxonDataFields = ({
  formNamePrefix,
  interpretationFieldsEnabled,
}: TaxonDataFieldsProps) => {
  return (
    <>
      <div className="flex flex-col gap-6">
        <TaxonScientificNameAutocomplete
          name="scientificName"
          testId="scientific-name"
          required
        />
        <AuthorshipField />
        <FormTextField
          fullWidth
          name="commonName"
          id="common-name"
          label="Preferred common name"
          testId="common-name"
        />
        <OrgTagsAutocomplete name="tags" testId="taxon-tags" />
        <FormTextField
          name={`${formNamePrefix ?? ""}notes`}
          label="Notes"
          placeholder="Add notes"
          multiline
          minRows={2}
          maxRows={6}
          testId="taxon-notes"
          fullWidth
        />
        {interpretationFieldsEnabled === true && (
          <>
            <SettingsSectionHeader
              title="Interpretation"
              subtitle="Description and native distribution"
            />
            <FormTextField
              name="interpretationDescription"
              label="Description"
              placeholder="Write a description"
              multiline
              minRows={2}
              maxRows={6}
              testId="interpretation-description"
              fullWidth
            />
            <NativeDistributionField />
          </>
        )}
      </div>
    </>
  )
}
