import {flow} from "fp-ts/function"

/**
 * Curried binary flow from fp-ts, useful for avoiding awkward glue
 * @example
 * ```typescript
 * const inputToOutput = flow(a => b => output, flowFrom(input => b))
 * ```
 * @see {@link https://gcanti.github.io/fp-ts/modules/Reader.ts.html#local} Reader.local
 * @remarks Equivalent to R.local for unary functions
 */
export const flowFrom: <A extends ReadonlyArray<unknown>, B>(
  ab: (...a: A) => B,
) => <C>(bc: (b: B) => C) => (...a: A) => C = (ab) => (bc) => flow(ab, bc)

/**
 * A version of `flowFrom` that supports generic higher order inference
 *
 * @remarks
 * The return type of this function must be given type annotations
 * or embedded in a left-to-right pipeline (e.g. using fp-ts' pipe)
 * for inference to work properly, or A will fall back to `unknown`
 */
export const flowFromG: <A extends ReadonlyArray<unknown>, B, C>(
  ab: (...a: A) => B,
) => (bc: (b: B) => C) => (...a: A) => C = flowFrom

/**
 * Curried binary reversed flow from fp-ts.
 *
 * @see {@link https://ramdajs.com/docs/#o} Ramda's o operator
 * @see {@link https://gcanti.github.io/fp-ts/modules/Reader.ts.html#functor} Reader.map
 * @remarks
 * Equivalent to Ramda's o or Reader.map for unary functions
 */
export const flowInto: <B, C>(
  bc: (b: B) => C,
) => <A extends ReadonlyArray<unknown>>(ab: (...a: A) => B) => (...a: A) => C =
  (bc) => (ab) =>
    flow(ab, bc)

/**
 * A version of `flowInto` that supports generic higher order inference
 * @example
 * ```typescript
 * flowIntoG(<A>(a: A) => a)
 * // type is <A>(ab: (...a: readonly unknown[]) => A) => (...a: readonly unknown[]) => A
 * ```
 * @remarks
 * The return type of this function must be given type annotations
 * or embedded in a left-to-right pipeline (e.g. using fp-ts' pipe)
 * for inference to work properly, or A will fall back to `unknown`
 */
export const flowIntoG: <A extends ReadonlyArray<unknown>, B, C>(
  bc: (b: B) => C,
) => (ab: (...a: A) => B) => (...a: A) => C = flowInto
