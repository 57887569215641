import {Button} from "@hortis/ui/button"
import {XClose} from "@hortis/ui/icons"
import {MarkerPin1} from "@hortis/ui/icons/marker-pin-1"
import {MarkerPinPlus1} from "@hortis/ui/icons/marker-pin-plus-1"
import type {ReactElement} from "react"
import {useCallback, useState} from "react"
import {Source} from "react-map-gl"
import {colors} from "src/colors"
import {SwitchHorizontal1} from "src/components/icons/streamline/switch-hortizontal"
import {themedUnclusteredPointLayerOrange} from "src/components/map/material-clusters/material-cluster-layers"
import {useThemedLayer} from "src/components/map/theme-layer-hook"
import {ControlledMap} from "src/features/records/material/components/material-title-card/controlled-map"
import {twMerge} from "tailwind-merge"
import type {MapPositionModalProps} from "./map-position-modal"
import {MapPositionModal} from "./map-position-modal"
import {parseNullableStrToNum} from "./utils"

export interface MapPositionFieldProps {
  fieldsComponent: ReactElement
  showFields: boolean
  toggleFields: () => void
  position: {latitude: string | null; longitude: string | null} | null
  onConfirm: (args: {latitude: number; longitude: number; zoom: number}) => void
  onDelete: () => void
  zoom?: number
  label?: string
  required?: boolean
  modalProps?: Partial<MapPositionModalProps>
  pointColor?: string
  error?: string
}

export const MapPositionField = ({
  showFields,
  toggleFields,
  fieldsComponent,
  position,
  onConfirm,
  onDelete,
  zoom,
  label,
  modalProps,
  pointColor,
  error,
  required = false,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
MapPositionFieldProps) => {
  const [modalOpen, setModalOpen] = useState(false)

  const [currentPointLayer] = useThemedLayer(
    themedUnclusteredPointLayerOrange,
    "unclustered-point-orange",
    1,
    pointColor,
  )

  const openModal = useCallback(() => {
    setModalOpen(true)
  }, [])

  const positioned = position?.latitude != null && position.longitude != null

  return (
    <div>
      <div className="flex flex-col gap-1.5">
        {label != null && (
          <div className="text-sm font-medium">
            {required && <span className="text-orange-600">* </span>}
            {label}
          </div>
        )}
        {showFields ? (
          fieldsComponent
        ) : (
          <div className="relative h-52">
            <ControlledMap
              useSiteCoordinates={position == null}
              zoom={zoom ?? 16}
              cursor="default"
              latitude={parseNullableStrToNum(position?.latitude) ?? 0}
              longitude={parseNullableStrToNum(position?.longitude) ?? 0}
              staticMap
            >
              {position?.longitude != null && position.latitude != null && (
                <Source
                  id={`map-geometry-2`}
                  type="geojson"
                  data={{
                    type: "Feature",
                    properties: {},
                    geometry: {
                      type: "Point",
                      coordinates: [
                        Number(position.longitude),
                        Number(position.latitude),
                      ],
                    },
                  }}
                >
                  {currentPointLayer}
                </Source>
              )}
            </ControlledMap>
            <div
              className={twMerge(
                "absolute left-0 top-0 flex h-full w-full gap-2 rounded-xl p-4",
                !positioned &&
                  "items-center justify-center bg-white/50 backdrop-blur-[2px]",
                positioned && "items-end justify-end",
                error != null && "ring-2 ring-error-300",
              )}
            >
              <Button
                size="xs"
                startIcon={positioned ? MarkerPin1 : MarkerPinPlus1}
                className="border-none shadow-sm"
                testId={
                  positioned ? "edit-position-button" : "add-position-button"
                }
                onClick={openModal}
              >
                {positioned ? "Reposition" : "Add position"}
              </Button>
              {positioned && (
                <Button
                  size="xs"
                  startIcon={XClose}
                  className="border-none shadow-sm"
                  testId="delete-position-button"
                  onClick={onDelete}
                >
                  Remove
                </Button>
              )}
            </div>
            <MapPositionModal
              {...modalProps}
              open={modalOpen}
              onOpenChange={setModalOpen}
              onConfirm={onConfirm}
              initialPosition={position}
              zoom={zoom}
            />
          </div>
        )}
      </div>
      <div className="flex justify-between gap-2 px-2 pt-2">
        <Button
          variant="linkgray"
          onClick={toggleFields}
          testId="toggle-map-fields-button"
          startIcon={
            <SwitchHorizontal1
              color={colors.grey[500]}
              width="18px"
              height="18px"
            />
          }
        >
          Use {showFields ? "map" : "coordinates"}
        </Button>
      </div>
      {error != null && (
        <p className="absolute pt-2 text-sm text-error-500">{error}</p>
      )}
    </div>
  )
}
