import {useArchiveAccessionMutation} from "generated/graphql"
import {type ReactNode, type RefObject} from "react"
import {Button} from "@hortis/ui/button"
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@hortis/ui/dialog"
import {useSnackbarStore} from "src/components/snackbar-controller/snackbar-store"
import {onFailure, onSuccess} from "src/notification-snack-utils"

export const TrashAccessionDialog = ({
  id,
  open,
  setOpen,
  onSuccess: onSuccessProp,
  triggerRef,
  children,
}: {
  id: string
  open: boolean
  setOpen: (open: boolean) => void
  onSuccess?: () => void
  triggerRef?: RefObject<HTMLButtonElement>
  children?: ReactNode
}) => {
  const {setSnack} = useSnackbarStore()
  const [{fetching}, archiveAccession] = useArchiveAccessionMutation()

  const handleSubmit = async () => {
    const res = await archiveAccession({
      accessionId: id,
    })

    if (res.data?.archiveAccession?.__typename !== "Accession") {
      onFailure(setSnack)(new Error(`Failed to trash accession`))
      return
    }

    onSuccess(setSnack)("Successfully trashed accession")
    setOpen(false)
    onSuccessProp?.()
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      {children}
      <DialogContent data-cy="trash-accession-dialog" triggerRef={triggerRef}>
        <DialogHeader>
          <DialogTitle>Move to trash?</DialogTitle>
          <DialogDescription>
            This will move 1 accession record and its plant materials to your
            site trash.
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <DialogClose asChild>
            <Button size="lg" fullWidth testId="trash-accession-cancel">
              Cancel
            </Button>
          </DialogClose>
          <Button
            size="lg"
            onClick={handleSubmit}
            loading={fetching}
            variant="warning"
            fullWidth
            testId="trash-accession-button"
          >
            Move to trash
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
