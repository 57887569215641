import {gql} from "graphql-request"

export const RECORD_TAG_FIELDS = gql`
  fragment RecordTagFields on RecordTag {
    id
    name
  }
`

export const COLLECTION_SITE_TAGS = gql`
  ${RECORD_TAG_FIELDS}
  query CollectionSiteTags(
    $organisationSubdomain: String!
    $collectionSiteSlug: String!
    $filters: RecordTagFilters
    $orgFilters: RecordTagFilters
  ) {
    org: organisationBySubdomain(subdomain: $organisationSubdomain) {
      tags(filters: $orgFilters) {
        nodes {
          ...RecordTagFields
        }
      }
      site: collectionSiteBySlug(slug: $collectionSiteSlug) {
        tags(filters: $filters) {
          nodes {
            ...RecordTagFields
          }
        }
      }
    }
  }
`

export const ORG_TAGS = gql`
  ${RECORD_TAG_FIELDS}
  query OrganisationTags(
    $organisationSubdomain: String!
    $filters: RecordTagFilters
  ) {
    org: organisationBySubdomain(subdomain: $organisationSubdomain) {
      tags(filters: $filters) {
        nodes {
          ...RecordTagFields
        }
      }
    }
  }
`

export const UPDATE_TAG = gql`
  ${RECORD_TAG_FIELDS}
  mutation UpdateRecordTag($id: ID!, $input: RecordTagUpdateInput!) {
    updateRecordTag(id: $id, input: $input) {
      success
      recordTag {
        ...RecordTagFields
      }
      errors {
        ... on DuplicateNameError {
          __typename
          message
        }
        ... on SiteTagMergeError {
          __typename
          message
          tags {
            name
            collectionSite {
              id
              name
            }
          }
        }
      }
    }
  }
`

export const ARCHIVE_TAG = gql`
  mutation ArchiveRecordTag($id: ID!) {
    archiveRecordTag(id: $id) {
      success
    }
  }
`

export const CREATE_TAG = gql`
  ${RECORD_TAG_FIELDS}
  mutation CreateRecordTag($input: RecordTagInput!) {
    createRecordTag(input: $input) {
      success
      recordTag {
        ...RecordTagFields
      }
      errors {
        ... on DuplicateNameError {
          __typename
          message
        }
        ... on SiteTagMergeError {
          __typename
          message
          tags {
            name
            collectionSite {
              id
              name
            }
          }
        }
      }
    }
  }
`
