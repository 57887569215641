import {zodResolver} from "@hookform/resolvers/zod"
import {Button} from "@hortis/ui/button"
import {
  SheetBody,
  SheetDescription,
  SheetFooter,
  SheetForm,
  SheetHeader,
  SheetTitle,
} from "@hortis/ui/sheet"
import * as E from "fp-ts/Either"
import * as TE from "fp-ts/TaskEither"
import {identity, pipe} from "fp-ts/function"
import type {ReactElement} from "react"
import {useEffect, useMemo} from "react"
import {FormProvider, useForm} from "react-hook-form"
import {materialErrorMap} from "src/features/create-accession/components/add-plant-material-form/error-map"
import type {PlantMaterialData} from "src/features/create-accession/components/add-plant-material-form/schema"
import {PlantMaterialSchemaZod} from "src/features/create-accession/components/add-plant-material-form/schema"
import type {
  AccessionFieldsFragment,
  MaterialQualifierFormat,
} from "../../../../../../../generated/graphql"
import {Period} from "../../../../../../../generated/graphql"
import {useSnackbarStore} from "../../../../../../components/snackbar-controller/snackbar-store"
import {useCollectionSite} from "../../../../../../utils/hooks/collection-site"
import {createMaterialQualifier} from "../../../../../../utils/material-qualifiers/material-qualifiers"
import * as OE from "../../../../../../utils/option-either"
import {assertAsPositiveInteger} from "../../../../../../utils/positive-integer"
import {useAccessToken} from "../../../../../../utils/use-access-token"
import {PlantMaterialDataFields} from "../../../../../create-accession/components/add-plant-material-form/plant-material-data-fields"
import {showErrorMessage} from "../../../../../create-accession/components/create-accession/utils"
import {processPlantMaterialFormData} from "../../../../../create-accession/utils/process-form-data"
import {createPlantMaterial} from "../../../fetchers"
import type {BaseEditStageProps} from "../types"
import {onCreateMaterialSuccess, onUpdateAccessionFailure} from "../utils"

const createPlantMaterialTask = TE.tryCatchK(createPlantMaterial, E.toError)

export const composeMaterialQualifier = (
  accession: AccessionFieldsFragment,
  materialQualifierFormat?: MaterialQualifierFormat,
) =>
  // TODO: Replace this with the full fledged material qualifier code
  materialQualifierFormat
    ? `${accession.accessionNumber}/${createMaterialQualifier(
        assertAsPositiveInteger((accession.plantMaterial?.length ?? 0) + 1),
        materialQualifierFormat,
      )}`
    : accession.accessionNumber

const createDefaultValues = (
  accession: AccessionFieldsFragment,
  materialQualifierFormat?: MaterialQualifierFormat,
) => ({
  materialQualifier: composeMaterialQualifier(
    accession,
    materialQualifierFormat,
  ),
  firstPresent: undefined,
  firstAbsent: null,
  materialGroup: undefined,
  quantity: null,
  weight: null,
  status: undefined,
  tagNumber: null,
  notes: null,
  position: null,
  massPlanting: false,
  collectionSiteLocationOption: undefined,
  lastObserved: new Date(),
  followUp: {count: 1, period: Period.Years},
  condition: null,
  tags: [],
})

const resolver = zodResolver(PlantMaterialSchemaZod, {
  errorMap: materialErrorMap,
})
export const AddPlantMaterial = ({
  accession,
  requestClose,
  refetch,
  open,
}: BaseEditStageProps): ReactElement => {
  const {setSnack} = useSnackbarStore()

  const materialQualifierFormat = pipe(
    useCollectionSite(),
    OE.map(({materialQualifierFormat}) => materialQualifierFormat),
    OE.match(
      () => undefined,
      () => undefined,
      identity,
    ),
  )

  const formMethods = useForm<PlantMaterialData>({
    defaultValues: createDefaultValues(accession, materialQualifierFormat),
    criteriaMode: "all",
    resolver,
  })

  useEffect(() => {
    formMethods.reset(createDefaultValues(accession, materialQualifierFormat))
  }, [open, formMethods, accession, materialQualifierFormat])

  useEffect(() => {
    if (materialQualifierFormat != null) {
      formMethods.setValue(
        "materialQualifier",
        composeMaterialQualifier(accession, materialQualifierFormat),
      )
    }
  }, [accession, formMethods, formMethods.setValue, materialQualifierFormat])

  const accessToken = useAccessToken()

  const onSubmit = useMemo(
    () =>
      pipe(
        OE.Do,
        OE.apSW("accessToken", accessToken),
        OE.map(({accessToken}) =>
          formMethods.handleSubmit((data) =>
            pipe(
              createPlantMaterialTask(accessToken, {
                accessionId: accession.id,
                plantMaterial: processPlantMaterialFormData(data),
              }),
              TE.match(
                onUpdateAccessionFailure(setSnack),
                onCreateMaterialSuccess(
                  setSnack,
                  "Material successfully created",
                  requestClose,
                  refetch,
                ),
              ),
            )(),
          ),
        ),
      ),
    [formMethods, accessToken, accession, setSnack, refetch, requestClose],
  )

  return pipe(
    OE.sequenceNoneToUndefined({
      onSubmit,
    }),
    E.match(showErrorMessage, ({onSubmit}) => (
      <FormProvider {...formMethods}>
        <SheetForm onSubmit={onSubmit}>
          <SheetHeader>
            <SheetTitle>Add new material</SheetTitle>
            <SheetDescription>{accession.accessionNumber}</SheetDescription>
          </SheetHeader>
          <SheetBody>
            <PlantMaterialDataFields formNamePrefix="" />
          </SheetBody>
          <SheetFooter>
            <Button testId="cancel-button" onClick={requestClose}>
              Cancel
            </Button>
            <Button
              testId={"confirm-button"}
              loading={formMethods.formState.isSubmitting}
              type="submit"
              variant="primary"
            >
              Save
            </Button>
          </SheetFooter>
        </SheetForm>
      </FormProvider>
    )),
  )
}
