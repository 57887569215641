import {gql} from "graphql-request"
import {CORE_COLLECTION_SITE_LOCATION_FIELDS} from "src/features/locations/graphql"
import {RECORD_TAG_FIELDS} from "src/features/tags/graphql"
import {CORE_ORGANISATION_MEMBERS_FIELDS} from "src/features/settings/components/org/graphql"
import {CORE_MATERIAL_FIELDS} from "../material/graphql"

export const CORE_ACCESSION_HISTORY_FIELDS = gql`
  ${CORE_ORGANISATION_MEMBERS_FIELDS}
  fragment AccessionHistoryFields on AccessionHistory {
    createdAt
    wasManualImport
    createdBy {
      ...OrganisationMemberFields
    }
    historicCreatedByInitials

    wasArchived
    wasRestored
    wasDeaccessioned
    wasReinstated

    createdPlantMaterial {
      qualifier
      wasManualImport
    }
    archivedPlantMaterial {
      qualifier
    }
    restoredPlantMaterial {
      qualifier
    }

    fromAccessionDate
    toAccessionDate
    fromProvenanceCategory {
      codeId
      name
    }
    toProvenanceCategory {
      codeId
      name
    }
    fromScientificName
    toScientificName
    fromTaxon {
      id
      scientificName
      archived
    }
    toTaxon {
      id
      scientificName
      archived
    }
    fromMaterialType {
      code
      name
    }
    toMaterialType {
      code
      name
    }
    fromDonor
    toDonor
    fromIpenNumber
    toIpenNumber
    fromNotes
    toNotes
    fromCollectionDate

    toCollectionDate
    fromCollectionCountry {
      codeId
      name
    }
    toCollectionCountry {
      codeId
      name
    }
    fromCollectionStateProvince
    toCollectionStateProvince
    fromCollectionLocality
    toCollectionLocality
    fromCollectionLocation
    toCollectionLocation
    fromCollectionLatitude
    toCollectionLatitude
    fromCollectionLongitude
    toCollectionLongitude
    fromCollectionElevation
    toCollectionElevation
    fromCollectionCollectors
    toCollectionCollectors
    fromCollectionCollectorsFieldNumber
    toCollectionCollectorsFieldNumber
    fromCollectionHabitat
    toCollectionHabitat
    fromCollectionAssociatedTaxa
    toCollectionAssociatedTaxa
    fromCollectionRemarks
    toCollectionRemarks
    fromCollectionCoordinateUncertainty
    toCollectionCoordinateUncertainty
    fromIdentifiedBy
    toIdentifiedBy
    fromDateIdentified
    toDateIdentified
    fromIdentificationQualifier
    toIdentificationQualifier
    fromIdentificationVerificationStatus
    toIdentificationVerificationStatus
    fromIdentificationRemarks
    toIdentificationRemarks
  }
`

export const CORE_ACCESSIONS_FIELDS = gql`
  ${CORE_ACCESSION_HISTORY_FIELDS}
  ${RECORD_TAG_FIELDS}
  ${CORE_COLLECTION_SITE_LOCATION_FIELDS}
  fragment AccessionFields on Accession {
    id
    archived
    accessionNumber
    accessionDate
    scientificName
    taxon {
      id
      scientificName
      commonName
      nativeDistribution
      sharedScientificName {
        classification {
          family
        }
        taxon {
          globalRedListStatus
        }
      }
    }
    provenanceCategoryCode
    provenanceCategory {
      codeId
      name
      ordering
    }
    donor
    ipenNumber
    createdByInitials
    notes
    tagsConnection {
      nodes {
        ...RecordTagFields
      }
    }
    collectionDate
    collectionCountryCode
    collectionCountry {
      codeId
      countryCodeAlpha2
      name
    }
    collectionStateProvince
    collectionLocality
    collectionLocation
    collectionLatitude
    collectionLongitude
    collectionElevation
    collectionCollectors
    collectionCollectorsFieldNumber
    collectionHabitat
    collectionAssociatedTaxa
    collectionRemarks
    collectionCoordinateUncertainty
    identifiedBy
    dateIdentified
    identificationQualifier
    identificationVerificationStatus
    identificationRemarks
    deaccessionDate
    materialType {
      id
      code
      name
    }
    createdBy {
      givenName
      familyName
      id
      avatarUrl
      initials
    }
    plantMaterial(filters: $filters) {
      id
      archived
      status
      qualifier
      materialGroup
      massPlanting
      public
      primaryImage {
        ...MaterialImageFields
      }
      images {
        ...MaterialImageFields
      }
      collectionSiteLocation {
        ...CollectionSiteLocationFields
      }
    }
    historyConnection {
      nodes {
        ...AccessionHistoryFields
      }
    }
  }
`

export const GET_ACCESSION = gql`
  ${CORE_ACCESSIONS_FIELDS}
  query GetAccession(
    $organisationSubdomain: String!
    $collectionSiteSlug: String!
    $accessionNumber: String!
    $filters: PlantMaterialFilter
  ) {
    org: organisationBySubdomain(subdomain: $organisationSubdomain) {
      site: collectionSiteBySlug(slug: $collectionSiteSlug) {
        result: accession(accessionNumber: $accessionNumber) {
          ...AccessionFields
        }
      }
    }
  }
`

export const UPDATE_ACCESSION_DATA = gql`
  mutation UpdateAccessionData(
    $accessionId: ID!
    $updates: AccessionDataUpdates!
  ) {
    updateAccessionData(accessionId: $accessionId, updates: $updates) {
      __typename
      ... on GenericError {
        message
      }
    }
  }
`

export const UPDATE_COLLECTION_DATA = gql`
  mutation UpdateCollectionData(
    $accessionId: ID!
    $updates: CollectionDataUpdates!
  ) {
    updateCollectionData(accessionId: $accessionId, updates: $updates) {
      __typename
      ... on GenericError {
        message
      }
    }
  }
`

export const DEACCESSION_ACCESSION = gql`
  mutation DeaccessionAccession(
    $accessionId: ID!
    $deaccessionDate: Date!
    $setPlantMaterialsToAbsent: Boolean!
  ) {
    deaccessionAccession(
      accessionId: $accessionId
      deaccessionDate: $deaccessionDate
      setPlantMaterialsToAbsent: $setPlantMaterialsToAbsent
    ) {
      id
    }
  }
`

export const REINSTATE_ACCESSION = gql`
  mutation ReinstateAccession($accessionId: ID!) {
    reinstateAccession(accessionId: $accessionId) {
      id
    }
  }
`

export const ARCHIVE_ACCESSION = gql`
  mutation ArchiveAccession($accessionId: ID!, $filters: PlantMaterialFilter) {
    archiveAccession(accessionId: $accessionId) {
      __typename
      ... on Accession {
        ...AccessionFields
      }
      ... on GenericError {
        message
      }
    }
  }
`

export const ARCHIVE_ACCESSIONS = gql`
  mutation ArchiveAccessions($ids: [ID!]!) {
    batchArchiveAccessions(ids: $ids) {
      accessions {
        id
        archived
      }
      success
      errors {
        ... on GenericError {
          message
        }
      }
    }
  }
`

export const RESTORE_ACCESSION = gql`
  mutation RestoreAccession($accessionId: ID!, $filters: PlantMaterialFilter) {
    restoreAccession(accessionId: $accessionId) {
      __typename
      ... on Accession {
        ...AccessionFields
      }
      ... on GenericError {
        message
      }
    }
  }
`

export const CREATE_MATERIAL = gql`
  ${CORE_MATERIAL_FIELDS}
  mutation CreateMaterial(
    $accessionId: ID!
    $plantMaterial: PlantMaterialInput!
    $relatedMaterialsFilters: PlantMaterialFilter
  ) {
    createPlantMaterial(
      accessionId: $accessionId
      plantMaterial: $plantMaterial
    ) {
      __typename
      ... on PlantMaterial {
        ...MaterialFields
      }
      ... on GenericError {
        message
      }
    }
  }
`
export const ADD_TAG_TO_ACCESSIONS = gql`
  mutation AddTagToAccessions($ids: [ID!]!, $tagId: ID!) {
    addTagAccessions(ids: $ids, tagId: $tagId) {
      success
      errors {
        ... on GenericError {
          message
        }
      }
      accessions {
        id
      }
    }
  }
`

export const REMOVE_TAG_FROM_ACCESSIONS = gql`
  mutation RemoveTagFromAccessions($ids: [ID!]!, $tagId: ID!) {
    removeTagAccessions(ids: $ids, tagId: $tagId) {
      success
      errors {
        ... on GenericError {
          message
        }
      }
      accessions {
        id
      }
    }
  }
`
