import type {Dispatch, ReactElement} from "react"
import {Fragment, useCallback} from "react"
import {ProvenanceChipGroup} from "../accessions/provenance-filter/chip-group"
import {FamilyChipGroup} from "../family-filter/chip-group"
import {StringComparatorChipGroup} from "../filter-chip-group/groups/string-comparator"
import {LifeFormsChipGroup} from "../life-forms/chip-group"
import {RedListStatusChipGroup} from "../red-list-status/chip-group"
import {TagsChipGroup} from "../tags-filter/chip-group"
import {UserChipGroup} from "../user-filter/user-chip-group"
import {ConditionChipGroup} from "./condition-filter/chip-group"
import {DateComparatorChipGroup} from "./date-filter/chip-group"
import type {PlantMaterialFilterAction} from "./filter-reducer"
import {FilterActionTypes} from "./filter-reducer"
import type {OpenFilterArgs} from "./filters"
import {LocationFilterChipGroup} from "./location-filter/chip-group"
import {MaterialGroupChipGroup} from "./material-group-filter/chip-group"
import {PhotosFilterChipGroup} from "./photos-filter/chip-group"
import {PublicFilterChipGroup} from "./public-filter/chip-group"
import {QuantityFilterChipGroup} from "./quantity-filter/chip-group"
import {SexFilterChipGroup} from "./sex-filter/chip-group"
import {StatusFilterChipGroup} from "./status-filter/chip-group"
import type {LocalMaterialFilterWithId, MaterialFilterType} from "."

export interface FilterChipGroupProps {
  handleOpenFilter: (args: OpenFilterArgs<MaterialFilterType>) => void
  dispatchFilter: Dispatch<PlantMaterialFilterAction>
}

export const MaterialFilterChipGroup = ({
  filters,
  selectFilter,
  dispatchFilter,
  ...props
}: FilterChipGroupProps & {
  selectFilter?: MaterialFilterType
  filters: Array<LocalMaterialFilterWithId>
}): ReactElement => {
  const deleteFilter = useCallback(
    (id: string) => {
      dispatchFilter({
        type: FilterActionTypes.DELETE_FILTER,
        payload: {id},
      })
    },
    [dispatchFilter],
  )

  const filterGroupHidden = (type: MaterialFilterType) =>
    selectFilter != null && selectFilter !== type

  return (
    <>
      {filters.map((filter) => (
        <Fragment key={filter.id}>
          <LocationFilterChipGroup
            filter={filter.location}
            id={filter.id}
            handleDelete={deleteFilter}
            hidden={filterGroupHidden("location")}
            {...props}
          />
          <StatusFilterChipGroup
            filter={filter.status}
            id={filter.id}
            handleDelete={deleteFilter}
            hidden={filterGroupHidden("status")}
            {...props}
          />
          <MaterialGroupChipGroup
            filter={filter.materialGroup}
            id={filter.id}
            handleDelete={deleteFilter}
            hidden={filterGroupHidden("material-group")}
            {...props}
          />
          <PublicFilterChipGroup
            filter={filter.public}
            id={filter.id}
            handleDelete={deleteFilter}
            hidden={filterGroupHidden("public")}
            {...props}
          />
          <UserChipGroup
            filter={filter.creator}
            filterType="creator"
            id={filter.id}
            handleDelete={deleteFilter}
            hidden={filterGroupHidden("creator")}
            filterLabel="Creator"
            {...props}
          />
          <UserChipGroup
            filter={filter.lastEditor}
            filterType="lastEditor"
            id={filter.id}
            handleDelete={deleteFilter}
            hidden={filterGroupHidden("lastEditor")}
            filterLabel="Last editor"
            {...props}
          />
          <ProvenanceChipGroup
            filter={filter.accession?.provenance}
            id={filter.id}
            handleDelete={deleteFilter}
            hidden={filterGroupHidden("provenance")}
            {...props}
          />
          <RedListStatusChipGroup
            filterType="redListStatus"
            filter={filter.redListStatus}
            id={filter.id}
            handleDelete={deleteFilter}
            hidden={filterGroupHidden("redListStatus")}
            {...props}
          />
          <LifeFormsChipGroup
            filterType="lifeForms"
            filter={filter.lifeForms}
            id={filter.id}
            handleDelete={deleteFilter}
            hidden={filterGroupHidden("lifeForms")}
            {...props}
          />
          <StringComparatorChipGroup
            filter={filter.climate}
            filterType="climate"
            id={filter.id}
            handleDelete={deleteFilter}
            hidden={filterGroupHidden("climate")}
            filterLabel="Climate"
            {...props}
          />
          <StringComparatorChipGroup
            filter={filter.nativeDistribution}
            filterType="nativeDistribution"
            id={filter.id}
            handleDelete={deleteFilter}
            hidden={filterGroupHidden("nativeDistribution")}
            filterLabel="Native distribution"
            {...props}
          />
          <StringComparatorChipGroup
            filter={filter.accession?.taxon?.commonName}
            filterType="commonName"
            id={filter.id}
            handleDelete={deleteFilter}
            hidden={filterGroupHidden("commonName")}
            filterLabel="Common name"
            {...props}
          />
          <StringComparatorChipGroup
            filter={filter.accession?.donor}
            filterType="donor"
            id={filter.id}
            handleDelete={deleteFilter}
            hidden={filterGroupHidden("donor")}
            filterLabel="Donor / supplier"
            {...props}
          />
          <ConditionChipGroup
            filter={filter.condition}
            id={filter.id}
            handleDelete={deleteFilter}
            hidden={filterGroupHidden("condition")}
            {...props}
          />
          <SexFilterChipGroup
            filter={filter.sex}
            id={filter.id}
            handleDelete={deleteFilter}
            hidden={filterGroupHidden("sex")}
            {...props}
          />
          <StringComparatorChipGroup
            filter={filter.tagNumber}
            filterType="tag-number"
            id={filter.id}
            handleDelete={deleteFilter}
            hidden={filterGroupHidden("tag-number")}
            filterLabel="Tag number"
            {...props}
          />
          <StringComparatorChipGroup
            filter={filter.notes}
            filterType="notes"
            id={filter.id}
            handleDelete={deleteFilter}
            hidden={filterGroupHidden("notes")}
            filterLabel="Notes"
            {...props}
          />
          <TagsChipGroup
            filterType="tags"
            filter={filter.tags}
            id={filter.id}
            handleDelete={deleteFilter}
            hidden={filterGroupHidden("tags")}
            {...props}
          />
          <FamilyChipGroup
            filterType="family"
            filter={filter.family}
            id={filter.id}
            handleDelete={deleteFilter}
            hidden={filterGroupHidden("family")}
            {...props}
          />
          <QuantityFilterChipGroup
            filter={filter.massPlanting}
            id={filter.id}
            handleDelete={deleteFilter}
            hidden={filterGroupHidden("quantity")}
            {...props}
          />
          <PhotosFilterChipGroup
            filter={filter.hasImages}
            id={filter.id}
            handleDelete={deleteFilter}
            hidden={filterGroupHidden("photos")}
            {...props}
          />

          <DateComparatorChipGroup
            id={filter.id}
            handleDelete={deleteFilter}
            filter={filter.firstPresent}
            filterType={"firstPresent"}
            filterLabel={"Date added"}
            hidden={filterGroupHidden("firstPresent")}
            {...props}
          />
          <DateComparatorChipGroup
            id={filter.id}
            handleDelete={deleteFilter}
            filter={filter.firstAbsent}
            filterType={"firstAbsent"}
            filterLabel={"Date removed"}
            hidden={filterGroupHidden("firstAbsent")}
            {...props}
          />
          <DateComparatorChipGroup
            id={filter.id}
            handleDelete={deleteFilter}
            filter={filter.lastObserved}
            filterType={"lastObserved"}
            filterLabel={"Last observed"}
            hidden={filterGroupHidden("lastObserved")}
            {...props}
          />
        </Fragment>
      ))}
    </>
  )
}
