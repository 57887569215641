import * as R from "ramda"
import type {TaxaFilter} from "generated/graphql"
import {countStringComparator} from "../count/comparators/string"
import {countTagsFilter} from "../tags-filter/count-filter"
import {countRedListStatusFilter} from "../red-list-status/count-filter"
import {countIdComparator} from "../count/comparators/id"
import {countUserFilter} from "../user-filter/count-user-filter"
import type {TaxaFilterType} from "./types"
import {countNullableScientificNameFilter} from "./validation-filter/count-nullable-scientific-name-filter"

export type TaxaFilterCounts = Record<TaxaFilterType, number> & {
  total: number
}

const createDefaultFilterCounts = (): TaxaFilterCounts => ({
  commonName: 0,
  authorship: 0,
  notes: 0,
  tags: 0,
  total: 0,
  family: 0,
  validation: 0,
  redListStatus: 0,
  nativeDistribution: 0,
  lifeForms: 0,
  climate: 0,
  creator: 0,
  lastEditor: 0,
})

const countTaxaFilter = (filter: TaxaFilter) => {
  const count = createDefaultFilterCounts()
  if (filter.commonName != null) {
    count.commonName += countStringComparator(filter.commonName)
    count.total += 1
  }
  const authorship = filter.or?.[0]?.authorship
  if (authorship != null) {
    count.authorship += countStringComparator(authorship)
    count.total += 1
  }
  if (filter.notes != null) {
    count.notes += countStringComparator(filter.notes)
    count.total += 1
  }
  if (filter.tags != null) {
    count.tags += countTagsFilter(filter.tags)
    count.total += 1
  }
  if (filter.sharedScientificName != null) {
    count.family += countNullableScientificNameFilter(
      filter.sharedScientificName,
    )
    if (filter.sharedScientificName.taxon?.globalRedListStatus != null) {
      count.redListStatus += countRedListStatusFilter(
        filter.sharedScientificName.taxon.globalRedListStatus,
      )
    }
    if (filter.sharedScientificName.taxon?.lifeForms?.id != null) {
      count.lifeForms += countIdComparator(
        filter.sharedScientificName.taxon.lifeForms.id,
      )
    }
    if (filter.sharedScientificName.taxon?.climate != null) {
      count.lifeForms += countStringComparator(
        filter.sharedScientificName.taxon.climate,
      )
    }
    count.total += 1
  }
  const nativeDistribution = filter.or?.[0]?.nativeDistribution
  if (nativeDistribution != null || filter.nativeDistribution != null) {
    count.nativeDistribution += 1
    count.total += 1
  }
  if (filter.createdBy != null) {
    count.creator += countUserFilter(filter.createdBy)
    count.total += 1
  }
  if (filter.updatedBy != null) {
    count.lastEditor += countUserFilter(filter.updatedBy)
    count.total += 1
  }

  return count
}

const sumObjectValues = <T>(...args: Array<T>) =>
  R.reduce(R.mergeWith(R.add), {} as T)(args)

export const countTaxaFilters = (filters: TaxaFilter): TaxaFilterCounts => {
  let count = createDefaultFilterCounts()
  count = sumObjectValues(countTaxaFilter(filters), count)
  if (filters.and != null) {
    for (const filter of filters.and) {
      count = sumObjectValues(countTaxaFilter(filter), count)
    }
  }
  return count
}
