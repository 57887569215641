import * as E from "fp-ts/Either"
import * as NEA from "fp-ts/NonEmptyArray"
import * as _Ord from "fp-ts/Ord"
import type * as S from "fp-ts/Semigroup"
import {flow, pipe} from "fp-ts/function"
import * as Num from "fp-ts/number"
import * as t from "io-ts"
import {
  assertAsPositiveInteger,
  PositiveInteger,
  PositiveIntegerSemigroupSum,
} from "../positive-integer"
import {throwOnValidationError} from "../validation"

/**
 * Copy paste from api repo
 */

const toRelativeCharCode = (start: PositiveInteger) => (char: string) =>
  // eslint-disable-next-line unicorn/prefer-code-point -- we expect this only to apply to A-Z
  char.charCodeAt(0) - start
const fromRelativeCharCode = (start: PositiveInteger) => (charCode: number) =>
  // eslint-disable-next-line unicorn/prefer-code-point -- we expect this apply to A-Z
  String.fromCharCode(charCode + start)

const charStartCode = assertAsPositiveInteger(64) // "@" is 0, due to the bijective base, we never use it. "A" is 1
const totalChars = assertAsPositiveInteger(26) // Full alphabet

// This is safe as we know MaterialQualifiers must start at char code 27 or greater
const toAZCharCode = toRelativeCharCode(charStartCode) as unknown as (
  char: MaterialQualifier,
) => PositiveInteger
const fromAZCharCode = fromRelativeCharCode(charStartCode) as unknown as (
  pos: PositiveInteger,
) => MaterialQualifier

const empty = [] as Array<PositiveInteger>
const toBijectiveBase = (base: PositiveInteger) => {
  const from = (num: PositiveInteger): NEA.NonEmptyArray<PositiveInteger> => {
    const digit = (num % base === 0 ? base : num % base) as PositiveInteger
    const position = Math.floor((num - 1) / base)

    return NEA.concat(NEA.of(digit))(
      PositiveInteger.is(position) ? from(position) : empty,
    )
  }
  return from
}

const fromBijectiveBase = (base: PositiveInteger) => {
  const sumIndices = (sum: number, position: number, index: number) =>
    sum + position * Math.pow(base, index)
  return (numbers: NEA.NonEmptyArray<PositiveInteger>): PositiveInteger =>
    [...numbers].reverse().reduce(sumIndices, 0) as PositiveInteger
}

const toBijectiveBase26 = toBijectiveBase(totalChars)
const fromBijectiveBase26 = fromBijectiveBase(totalChars)

interface MaterialQualifierBrand {
  readonly MaterialQualifier: unique symbol
}
const materialQualifierRegex = /^[A-Z]+$/
export const MaterialQualifier = t.brand(
  t.string,
  (s): s is t.Branded<string, MaterialQualifierBrand> =>
    materialQualifierRegex.test(s),
  "MaterialQualifier",
)
export type MaterialQualifier = t.TypeOf<typeof MaterialQualifier>
export const assertAsMaterialQualifier = flow(
  MaterialQualifier.decode,
  throwOnValidationError,
)

const SemigroupConcat: S.Semigroup<MaterialQualifier> = {
  concat: (first, second) => (first + second) as MaterialQualifier,
}

const joinChars = NEA.concatAll(SemigroupConcat)
const splitChars = (
  qualifier: MaterialQualifier,
): NEA.NonEmptyArray<MaterialQualifier> =>
  qualifier.split("") as NEA.NonEmptyArray<MaterialQualifier>

/**
 * @remarks
 * Why 1 = A and 26 = Z?
 * Under {@link https://en.wikipedia.org/wiki/Bijective_numeration#The_bijective_base-26_system}
 * 0 is meaningless. A bijection means there is a 1:1 mapping between two sets, and
 * most number systems are not bijective, because the digits 42 is the same as 042, 0042 etc.
 * By removing 0 from the number system and counting from 1, order is restored.
 * This is also how spreadsheet style column counting systems work,
 * where 26 is Z and 27 is AA. (i.e. Z + A = AA because 26 + 1 = 1,1 )
 * See {@link https://en.wikipedia.org/wiki/Bijective_numeration#Properties_of_bijective_base-k_numerals}
 * for a chart that makes this clear - cross reference "decimal" with any of the bijective bases (base 3 is good)
 */
export const fromPositiveInteger = flow(
  toBijectiveBase26,
  NEA.map(fromAZCharCode),
  joinChars,
)

export const toPositiveInteger = flow(
  splitChars,
  NEA.map(toAZCharCode),
  fromBijectiveBase26,
)

const SemigroupSum: S.Semigroup<MaterialQualifier> = {
  concat: (first, second) =>
    fromPositiveInteger(
      PositiveIntegerSemigroupSum.concat(
        toPositiveInteger(first),
        toPositiveInteger(second),
      ),
    ),
}

export const MaterialQualifierFromNumber = new t.Type<
  MaterialQualifier,
  PositiveInteger,
  unknown
>(
  "QualifierFromNumber",
  MaterialQualifier.is,
  flow(PositiveInteger.validate, E.map(fromPositiveInteger)),
  toPositiveInteger,
)

const initial = pipe(
  MaterialQualifierFromNumber.decode(1),
  throwOnValidationError,
)

export const getNextMaterialQualifier = (
  qualifier: MaterialQualifier,
): MaterialQualifier => SemigroupSum.concat(qualifier, initial)

export const Ord = pipe(Num.Ord, _Ord.contramap(toPositiveInteger))
