import {
  Button,
  IconButton,
  type ButtonProps,
  type IconButtonProps,
} from "@hortis/ui/button"
import {Plus} from "@hortis/ui/icons"
import type {PopoverContentProps} from "@radix-ui/react-popover"
import type {ReactElement, RefObject} from "react"
import {useRef, useState} from "react"
import {
  Command,
  CommandEmpty,
  CommandInput,
  CommandItem,
  CommandList,
} from "@hortis/ui/command"
import {Popover, PopoverContent, PopoverTrigger} from "src/components/popover"
import {useCollectionSiteSettings} from "src/utils/hooks/collection-site-from-url"
import type {OpenFilterArgs} from "./filters"
import type {MaterialFilterType} from "./types"

const filters: Array<{value: MaterialFilterType; label: string}> = [
  {
    value: "photos",
    label: "Photos",
  },
  {
    value: "commonName",
    label: "Common name",
  },
  {
    value: "family",
    label: "Family",
  },
  {
    value: "provenance",
    label: "Provenance",
  },
  {
    value: "redListStatus",
    label: "IUCN Red List",
  },
  {
    value: "nativeDistribution",
    label: "Native distribution",
  },
  {
    value: "lifeForms",
    label: "Life forms",
  },
  {
    value: "climate",
    label: "Climate",
  },
  {
    value: "material-group",
    label: "Material type",
  },
  {
    value: "status",
    label: "Status",
  },
  {
    value: "location",
    label: "Location",
  },
  {
    value: "quantity",
    label: "Quantity",
  },
  {
    value: "donor",
    label: "Donor/supplier",
  },
  {
    value: "sex",
    label: "Sex",
  },
  {
    value: "tag-number",
    label: "Tag number",
  },
  {
    value: "condition",
    label: "Condition",
  },
  {value: "tags", label: "Tags"},
  {
    value: "lastObserved",
    label: "Last observed",
  },
  {
    value: "public",
    label: "Public",
  },
  {
    value: "firstPresent",
    label: "Date added",
  },
  {
    value: "firstAbsent",
    label: "Date removed",
  },
  {value: "creator", label: "Creator"},
  {value: "lastEditor", label: "Last editor"},
  {value: "notes", label: "Notes"},
]

interface AddPlantMaterialFilterProps {
  setOpenFilter: (args: OpenFilterArgs<MaterialFilterType>) => void
  side?: PopoverContentProps["side"]
  align?: PopoverContentProps["align"]
  buttonProps?: ButtonProps & {
    ref?: RefObject<HTMLButtonElement>
    icon?: IconButtonProps["icon"]
  }
  iconButton?: boolean
}

export const AddPlantMaterialFilter = ({
  setOpenFilter,
  buttonProps,
  iconButton,
  side,
  align = "start",
}: AddPlantMaterialFilterProps): ReactElement => {
  const {tagNumbersEnabled, isPublic, sexEnabled} = useCollectionSiteSettings()
  const [open, setOpen] = useState(false)
  const menuAnchorEl = useRef(null)

  const handleSelect = (value: MaterialFilterType) => {
    setOpenFilter({
      filterType: value,
      anchorElRef: buttonProps?.ref?.current ?? menuAnchorEl.current,
    })
    setOpen(false)
  }
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = (open: boolean) => {
    setOpen(open)
    if (open) {
      setOpenFilter({filterType: false})
    }
  }

  return (
    <Popover open={open} onOpenChange={handleClose}>
      <PopoverTrigger asChild>
        {iconButton === true ? (
          <IconButton
            ref={menuAnchorEl}
            onClick={handleOpen}
            icon={Plus}
            ariaLabel="Add filter"
            size="sm"
            variant="tertiaryGray"
            className="border border-dashed border-grey-200"
            {...buttonProps}
          />
        ) : (
          <Button
            ref={menuAnchorEl}
            onClick={handleOpen}
            testId="add-filter-button"
            size="sm"
            startIcon={Plus}
            {...buttonProps}
          >
            {buttonProps?.children ?? "Add filter"}
          </Button>
        )}
      </PopoverTrigger>
      <PopoverContent side={side} align={align} className="z-50">
        <Command>
          <CommandInput placeholder="Search..." />
          <CommandEmpty>No results</CommandEmpty>
          <CommandList>
            {filters
              .filter(({value}) => (value === "public" ? isPublic : true))
              .filter(({value}) =>
                value === "tag-number" ? tagNumbersEnabled : true,
              )
              .filter(({value}) => (value === "sex" ? sexEnabled : true))
              .map((item) => (
                <CommandItem
                  key={item.value}
                  value={item.value}
                  onSelect={() => {
                    handleSelect(item.value)
                  }}
                >
                  {item.label}
                </CommandItem>
              ))}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
