import {Tag} from "@hortis/ui/tag"
import {S} from "@mobily/ts-belt"
import type {
  AutocompleteRenderGetTagProps,
  AutocompleteRenderGroupParams,
} from "@mui/material/Autocomplete"
import match from "autosuggest-highlight/match"
import parse from "autosuggest-highlight/parse"
import {type TaxonomicRank, type ScientificName} from "generated/graphql"
import type {HTMLAttributes} from "react"
import {Fragment} from "react"
import {CheckVerified1, Plus} from "@hortis/ui/icons"
import {taxonomicStatusColorMap} from "src/features/taxonomy/components/taxon-page/taxonomy/taxonomy"
import {taxonomicRankLabelMap} from "src/utils/label-maps/taxonomic-rank"
import {twMerge} from "tailwind-merge"

export type ScientificNameOption = Pick<
  ScientificName,
  "scientificName" | "id" | "nameSource" | "status" | "authorship" | "rank"
>

export const isOptionEqual = (option: {id: string}, value: {id: string}) =>
  option.id === value.id

export const renderAutoCompleteItem = (settings?: {
  showAuthorship?: boolean
  showStatus?: boolean
  selectedOption?: ScientificNameOption | null
}) =>
  function renderAutoCompleteItem(
    props: HTMLAttributes<HTMLLIElement> & {"data-option-index"?: number},
    option: ScientificNameOption | null,
    {inputValue}: {inputValue: string},
  ) {
    if (option == null) {
      return <Fragment />
    }
    const {...rest} = props
    const matches = match(
      option.scientificName,
      settings?.selectedOption == null
        ? inputValue
        : S.concat(settings.selectedOption.scientificName, ` ${inputValue}`),
      {
        insideWords: true,
      },
    )
    const parts = parse(option.scientificName, matches)
    const isAddOption = option.id.includes("ADD_NEW")

    return (
      <li
        {...rest}
        key={option.id}
        data-cy={
          isAddOption
            ? "create-new-scientific-name-option"
            : "scientific-name-option"
        }
        className={twMerge(
          isAddOption && "!mt-auto bg-white",
          "cursor-pointer [&.Mui-focused]:bg-primary-50",
        )}
      >
        <div
          className={twMerge(
            "flex w-full items-center gap-3 px-3.5 py-3 text-sm",

            isAddOption && props["data-option-index"] !== 0
              ? `border-t border-grey-200`
              : undefined,
          )}
        >
          <div className="flex flex-1 flex-wrap items-center gap-x-2 gap-y-1 truncate">
            {isAddOption && <Plus size="16px" />}
            <div className="truncate">
              {isAddOption
                ? option.scientificName
                : parts.map((part, index) => (
                    <span
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      className={twMerge(part.highlight && "font-semibold")}
                    >
                      {part.text}
                    </span>
                  ))}
            </div>
            {settings?.showAuthorship === true && (
              <div className="truncate text-grey-500">{option.authorship}</div>
            )}
          </div>
          {settings?.showStatus === true && !isAddOption && (
            <CheckVerified1
              size="20px"
              color={taxonomicStatusColorMap[option.status]}
              className="flex-shrink-0"
            />
          )}
        </div>
      </li>
    )
  }

export const renderAutocompleteGroup = ({
  key,
  group,
  children,
}: AutocompleteRenderGroupParams) => (
  <li key={key} data-cy="scientific-name-group-header">
    <div className="sticky top-0 bg-grey-50 px-3.5 py-3 text-xs font-medium text-grey-500">
      {taxonomicRankLabelMap[group as TaxonomicRank]}
    </div>
    <ul className="flex flex-col p-0">{children}</ul>
  </li>
)

export const getOptionLabel = (option: ScientificNameOption | string | null) =>
  option === null || typeof option === "string" ? "" : option.scientificName

export const groupBy = (option: ScientificNameOption) => option.rank

export const renderTags = (
  tags: Array<{id: string; scientificName: string}>,
  getTagProps: AutocompleteRenderGetTagProps,
) => (
  <div className="flex flex-wrap">
    {tags.map((tag, index) => (
      <Tag {...getTagProps({index})} key={tag.id}>
        {tag.scientificName}
      </Tag>
    ))}
  </div>
)
