import type {Theme} from "@mui/material/styles"
import {useTheme} from "@mui/material/styles"
import {useMemo} from "react"
import type {LayerProps} from "react-map-gl"
import {Layer} from "react-map-gl"

export type ThemedLayerProps = (
  theme: Theme,
  opacity?: number,
  color?: string,
) => LayerProps | undefined
export const useThemedLayerProps = (
  layerProps: ThemedLayerProps,
  opacity?: number,
) => {
  const theme = useTheme()
  return useMemo(() => layerProps(theme, opacity), [theme, layerProps, opacity])
}

export const useThemedLayer = (
  layer: ThemedLayerProps,
  key: string,
  opacity?: number,
  color?: string,
) => {
  const theme = useTheme()
  return useMemo((): [
    element: JSX.Element | undefined,
    props: LayerProps | undefined,
  ] => {
    const props = layer(theme, opacity, color)
    return [
      props === undefined ? undefined : <Layer key={key} {...props} />,
      props,
    ]
  }, [theme, layer, opacity, key, color])
}
