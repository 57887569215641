import {Alert, AlertContent, AlertText} from "@hortis/ui/alert"
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@hortis/ui/breadcrumb"
import {Button, IconButton} from "@hortis/ui/button"
import {ContextMenu, ContextMenuTrigger} from "@hortis/ui/context-menu"
import {Drawer, DrawerContent} from "@hortis/ui/drawer"
import {useMediaQuery} from "@hortis/ui/hooks/media-query"
import {
  AlertCircle,
  AlignLeft1,
  FilterLines,
  Map1,
  Plus,
  SearchMd,
  Settings4,
  XClose,
} from "@hortis/ui/icons"
import {Label} from "@hortis/ui/label"
import {Popover, PopoverContent, PopoverTrigger} from "@hortis/ui/popover"
import {Link, getRouteApi} from "@tanstack/react-router"
import type {Row} from "@tanstack/react-table"
import {createColumnHelper} from "@tanstack/react-table"
import {
  PlantMaterialsSearchColumn,
  useGetCollectionSiteFromNamesQuery,
  useMapListMaterialsQuery,
  type MapMaterialListFieldsFragment,
  type PlantMaterialsOrderByInput,
  type PlantMaterialsSearchTerm,
} from "generated/graphql"
import {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import {useSelectAllKeyboardShortcuts} from "src/components/data-table/data-table"
import {useTable} from "src/components/data-table/use-table"
import {
  EmptyState,
  EmptyStateActions,
  EmptyStateBody,
  EmptyStateHeader,
  EmptyStateTitle,
} from "src/components/empty-state"
import {ListFooter} from "src/components/list/list-footer"
import {
  TopBar,
  TopBarActionButtons,
  TopBarContent,
} from "src/components/nav-layout/topbar"
import {useTopbarStore} from "src/components/nav-layout/topbar/topbar-store"
import {Toggle} from "src/components/toggle"
import MaterialsActionMenu from "src/features/actions-menu/variants/materials-action-menu"
import {useDownloadCsv} from "src/features/collection/components/download-accessions/use-download-csv"
import {createMaterialNumber} from "src/features/collection/components/plant-materials/material-number"
import {SearchBar} from "src/features/collection/components/search-bar/search-bar"
import {MaterialsContextMenuContent} from "src/features/context-menu/variants/materials-context-menu"
import {
  AddPlantMaterialFilter,
  PlantMaterialsFilters,
  type MaterialFilterType,
} from "src/features/filters/plant-materials"
import {FilterActionTypes} from "src/features/filters/plant-materials/filter-reducer"
import {MaterialFilterModals} from "src/features/filters/plant-materials/modals/filter-modals"
import {MaterialsSortSelect} from "src/features/filters/plant-materials/sort-select"
import type {MaterialFilters} from "src/features/filters/plant-materials/use-material-filters"
import {useAccessRole} from "src/features/permissions/use-access-role"
import MaterialRecordCard from "src/features/records/components/new-record-card/variants/material-record-card"
import {useMobile} from "src/utils/hooks/media-query"
import {useIsScrolled} from "src/utils/hooks/scrolled"
import {
  getSelectedRowCount,
  getSelectedRowIds,
  useSelect,
} from "src/utils/hooks/tables/select-mode"
import {useToggle} from "src/utils/hooks/toggle"
import {PageDirection, createRelayParams} from "src/utils/pagination"
import {getThumbnailPhotoUrl} from "src/utils/photos"
import {twMerge} from "tailwind-merge"
import {FeaturedIcon} from "src/components/featured-icon/featured-icon"
import {useFindOnMap} from "../../hooks"
import {mapId, searchMenuLabels} from "../../utils"
import {useHoveredListMaterialStore} from "../hovered-list-material-store"
import {MaterialRowSkeleton} from "./material-row/row-skeleton"

const ErrorAlert = () => (
  <Alert color="error" data-cy="error-alert" className="mx-3 my-2">
    <AlertCircle />
    <AlertContent>
      <AlertText>Something went wrong</AlertText>
    </AlertContent>
  </Alert>
)

const ListItem = ({
  row,
  selectMode,
  onClick,
}: {
  row: Row<MapMaterialListFieldsFragment>
  selectMode: boolean
  onClick: (row: Row<MapMaterialListFieldsFragment>) => void
}) => {
  const {setHoveredListMaterial} = useHoveredListMaterialStore()
  const isNotMobile = useMediaQuery("sm")
  const handleMouseEnter = useCallback(() => {
    setHoveredListMaterial({
      id: row.original.id,
      materialQualifier: row.original.qualifier,
      accessionNumber: row.original.accession?.accessionNumber ?? "",
    })
  }, [row, setHoveredListMaterial])

  const handleMouseLeave = useCallback(() => {
    setHoveredListMaterial(undefined)
  }, [setHoveredListMaterial])

  const selected = row.getIsSelected()

  const content = (
    <MaterialRecordCard
      group={row.original.materialGroup}
      scientificName={row.original.accession?.scientificName ?? "-"}
      status={row.original.status}
      selected={selectMode && selected}
      noLocation={row.original.position == null}
      className={twMerge(
        "w-full p-0 transition-none",
        !selected && "group-hover:bg-grey-50",
      )}
      photoUrl={getThumbnailPhotoUrl(row.original)}
      variant="Row"
      location={row.original.collectionSiteLocation}
      size={isNotMobile ? "md" : "sm"}
      materialNumber={
        row.original.accession?.accessionNumber == null
          ? "-"
          : `${row.original.accession.accessionNumber}/${row.original.qualifier}`
      }
    />
  )

  return selectMode ? (
    <button
      type="button"
      className={twMerge(
        "group w-full cursor-pointer rounded-xl border-none border-grey-200 !px-3 py-3 text-left hover:bg-grey-50 hover:transition-none sm:px-6 [&:not(:last-child)]:border-b",
        selected ? "bg-primary-50 hover:bg-primary-50" : null,
      )}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => {
        row.toggleSelected()
      }}
      data-cy="map-list-material-row"
    >
      {content}
    </button>
  ) : (
    <Link
      from="/sites/$siteSlug/map"
      to="/sites/$siteSlug/map/material/$materialNumber"
      onClick={() => {
        onClick(row)
      }}
      params={{
        materialNumber: createMaterialNumber(
          row.original.accession?.accessionNumber ?? "",
          row.original.qualifier,
        ),
      }}
      className={twMerge(
        "group flex w-full cursor-pointer rounded-xl border-none border-grey-200 !px-3 py-3 text-left hover:bg-grey-50 hover:transition-none sm:px-6 [&:not(:last-child)]:border-b",
        selected ? "bg-primary-50 hover:bg-primary-50" : null,
      )}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      data-cy="map-list-material-row"
    >
      {content}
    </Link>
  )
}

type SearchField = keyof typeof searchMenuLabels

interface MaterialListOverlayProps {
  handleSearchSubmit: (searchValue: string, searchField: SearchField) => void
  handleSearchClear: (field: PlantMaterialsSearchColumn) => void
  searchTerm: PlantMaterialsSearchTerm | undefined
  setDrawerCollapsed: (val: boolean) => void
  cursor: string | null
  setCursor: (val: string) => void
  materialFilters: MaterialFilters
  orderBy: PlantMaterialsOrderByInput | undefined
  setOrderBy: (val: PlantMaterialsOrderByInput | undefined) => void
  open: boolean
}

const routeApi = getRouteApi("/_layout/sites/$siteSlug/map")

const columns = [
  createColumnHelper<MapMaterialListFieldsFragment>().accessor("id", {}),
]

export const MaterialListOverlay = forwardRef<
  HTMLDivElement,
  MaterialListOverlayProps
  // eslint-disable-next-line sonarjs/cognitive-complexity
>(function MaterialListOverlay(
  {
    searchTerm,
    handleSearchClear,
    handleSearchSubmit,
    setDrawerCollapsed,
    cursor,
    setCursor,
    materialFilters: filters,
    orderBy,
    setOrderBy,
    open,
  },
  ref,
) {
  const {siteSlug} = routeApi.useParams()
  const {subdomain} = routeApi.useRouteContext()
  const [{data: siteData}] = useGetCollectionSiteFromNamesQuery({
    variables: {
      organisationSubdomain: subdomain,
      collectionSiteSlug: siteSlug,
    },
  })
  const site = siteData?.org?.site
  const isMobile = useMobile()
  const {canEdit} = useAccessRole()
  const findOnMap = useFindOnMap(mapId)
  const [dir, setDir] = useState(PageDirection.Next)
  const {attachRef, ref: containerRef} = useIsScrolled()
  const [loadingDirection, setLoadingDirection] =
    useState<PageDirection | null>(null)
  const [actionsMenuOpen, toggleActionsMenuOpen] = useToggle(false)

  const collapseDrawer = useCallback(() => {
    setDrawerCollapsed(true)
  }, [setDrawerCollapsed])

  const [{data, error, fetching}] = useMapListMaterialsQuery({
    variables: {
      collectionSiteSlug: siteSlug,
      organisationSubdomain: subdomain,
      filters: filters.preparedFilters,
      orderBy,
      searchTerm,
      ...createRelayParams({cursor, dir, rowsPerPage: 15}),
    },
  })

  const setExcludeMaterialFilter = (checked: boolean) => {
    filters.dispatchFilters({
      type: FilterActionTypes.MODIFY_ROOT_FILTER,
      payload: {excludeAbsentMaterial: checked},
    })
  }

  const pageInfo = data?.org?.site?.result?.pageInfo
  const materials = data?.org?.site?.result?.nodes
  const total = data?.org?.site?.total

  const {table} = useTable({
    columns: columns,
    data: useMemo(() => (materials == null ? [] : [...materials]), [materials]),
    tableId: "site-materials-table",
  })
  useSelectAllKeyboardShortcuts(table)

  const {selectMode, toggleSelectMode} = useSelect({
    resetRowSelection: table.resetRowSelection,
    filterCounts: filters.filterCounts,
    sort: orderBy,
    search: searchTerm,
  })

  const onPrevPage = () => {
    if (pageInfo?.startCursor != null && pageInfo.hasPreviousPage) {
      setCursor(pageInfo.startCursor)
      setDir(PageDirection.Prev)
    }
  }

  const onNextPage = () => {
    if (pageInfo?.endCursor != null && pageInfo.hasNextPage) {
      setCursor(pageInfo.endCursor)
      setDir(PageDirection.Next)
    }
  }

  const clearSearch = () => {
    if (searchTerm != null) {
      handleSearchClear(searchTerm.field)
    }
  }

  const handleDownload = useDownloadCsv({
    csvName: "plant-materials",
    path: "/download/materials",
    body: {
      searchTerm,
      filters: {
        ...filters.preparedFilters,
        id: {
          in: table.getSelectedRowModel().rows.map(({original}) => original.id),
        },
      },
    },
  })

  useEffect(() => {
    if (!fetching) {
      if (containerRef != null) {
        containerRef.scrollTop = 0
      }
      setLoadingDirection(null)
    }
  }, [fetching, containerRef])

  const [openFilter, setOpenFilter] = useState<MaterialFilterType | false>(
    false,
  )

  const searchActive = searchTerm != null && searchTerm.value !== ""
  const filterButtonRef = useRef<HTMLButtonElement>(null)

  const setTitle = useTopbarStore((state) => state.setTitle)
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false)

  const handleRowClick = useCallback(
    (row: Row<MapMaterialListFieldsFragment>) => {
      findOnMap(row.original.position)
    },
    [findOnMap],
  )

  const pageTitle = "Map"
  setTitle(pageTitle)

  const selectedRecordsIDs = getSelectedRowIds(table.getState().rowSelection)
  const selectedRecordCount = getSelectedRowCount(table.getState().rowSelection)
  const content = (
    <div
      className="flex h-full flex-col bg-white"
      ref={ref}
      data-cy="material-list-overlay"
    >
      <TopBar className="px-4 lg:px-6">
        <TopBarContent>
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem className="max-w-48">{site?.name}</BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>{pageTitle}</BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        </TopBarContent>
        <TopBarActionButtons>
          <IconButton
            size="xs"
            variant="tertiaryGray"
            onClick={collapseDrawer}
            ariaLabel="Collapse material list"
            testId="collapse-list-button"
            icon={isMobile ? XClose : AlignLeft1}
          />
        </TopBarActionButtons>
      </TopBar>
      <div className="flex gap-2 border-b border-grey-200 px-4 py-4 lg:px-6">
        <SearchBar
          testId="map-list-search-form"
          menuLabels={searchMenuLabels}
          handleSubmitCallback={handleSearchSubmit}
          handleClearCallback={handleSearchClear}
          searchTermOverride={searchTerm}
          searchFieldIfNumber={PlantMaterialsSearchColumn.MaterialNumber}
        />
        <AddPlantMaterialFilter
          setOpenFilter={(type) => {
            setOpenFilter(type.filterType)
          }}
          align="center"
          iconButton
          buttonProps={{
            size: "sm",
            testId: "add-filter-button",
            icon: FilterLines,
            ref: filterButtonRef,
            className: "border",
            variant: "secondaryGray",
          }}
        />
        <MaterialFilterModals
          openFilter={openFilter}
          handleFilterModalClose={() => {
            setOpenFilter(false)
          }}
          filterModalAnchorEl={filterButtonRef}
          dispatchFilters={filters.dispatchFilters}
        />
        <Popover>
          <PopoverTrigger asChild>
            <IconButton
              icon={Settings4}
              ariaLabel="View settings"
              size="sm"
              testId="view-settings-button"
            />
          </PopoverTrigger>
          <PopoverContent className="min-w-44">
            <div className="border-b border-grey-200 px-3.5 py-4">
              <MaterialsSortSelect
                fullWidth
                buttonProps={{size: "xs"}}
                orderBy={orderBy}
                updateOrderBy={setOrderBy}
              />
            </div>
            <Label className="flex items-center justify-between px-3.5 py-4">
              Show absent
              <Toggle
                testId="show-absent-toggle"
                size="xs"
                onChange={(e) => {
                  setExcludeMaterialFilter(!e.target.checked)
                }}
                checked={filters.filters.excludeAbsentMaterial !== true}
              />
            </Label>
          </PopoverContent>
        </Popover>
      </div>
      {filters.filterCounts.total - filters.filterCounts.excludeAbsentMaterial >
        0 && (
        <div className="border-b border-grey-200 px-4 py-4 lg:px-6">
          <PlantMaterialsFilters
            hideAbsentFilter
            excludeAbsentPlantMaterial={
              filters.filters.excludeAbsentMaterial === true
            }
            onChange={setExcludeMaterialFilter}
            filters={filters.filters}
            dispatchFilters={filters.dispatchFilters}
            resetFilters={filters.resetFilters}
            filterCount={filters.filterCounts.total}
            hideClear
          />
        </div>
      )}
      <div className="flex-1 overflow-y-auto" ref={attachRef}>
        <div className="flex flex-col gap-0.5 px-1 py-1 lg:px-3">
          {((materials == null && !fetching) || error != null) && (
            <ErrorAlert />
          )}
          {materials == null && fetching && (
            <>
              <MaterialRowSkeleton />
              <MaterialRowSkeleton />
              <MaterialRowSkeleton />
              <MaterialRowSkeleton />
              <MaterialRowSkeleton />
              <MaterialRowSkeleton />
              <MaterialRowSkeleton />
              <MaterialRowSkeleton />
              <MaterialRowSkeleton />
              <MaterialRowSkeleton />
            </>
          )}
          {table.getRowModel().rows.map((row) => (
            <ContextMenu onOpenChange={setIsContextMenuOpen} key={row.id}>
              <ContextMenuTrigger>
                <ListItem
                  key={row.id}
                  row={row}
                  selectMode={selectMode}
                  onClick={handleRowClick}
                />
              </ContextMenuTrigger>
              <MaterialsContextMenuContent
                isTrash={false}
                matSearch={searchTerm}
                preparedFilters={filters.preparedFilters}
                clickedRow={row.original}
                selectedRecordsIDs={selectedRecordsIDs}
                onRequestDownload={handleDownload}
              />
            </ContextMenu>
          ))}
        </div>
        {materials != null && materials.length === 0 && (
          <EmptyState className="my-12 justify-self-center p-6 md:my-24">
            <EmptyStateHeader>
              <FeaturedIcon>
                {searchActive ? (
                  <SearchMd className="h-5 w-5 text-primary-600" />
                ) : (
                  <Map1 className="h-5 w-5 text-primary-600" />
                )}
              </FeaturedIcon>
              <EmptyStateTitle>No materials found</EmptyStateTitle>
              <EmptyStateBody>
                {searchTerm != null && searchTerm.value !== ""
                  ? `Your search “${
                      searchTerm.value
                    }” did not match any records. Please try again${
                      canEdit ? " or add material" : ""
                    }.`
                  : `Your mapped plant materials will live here.${
                      canEdit
                        ? " Start creating your map by accessioning your material."
                        : ""
                    }`}
              </EmptyStateBody>
            </EmptyStateHeader>
            <EmptyStateActions>
              {searchActive && (
                <Button onClick={clearSearch}>Clear search</Button>
              )}
              {canEdit && (
                <Button
                  asChild
                  variant="primary"
                  startIcon={Plus}
                  data-cy="new-accession-button"
                >
                  <Link
                    from="/sites/$siteSlug/map"
                    to="/sites/$siteSlug/accessions/create"
                  >
                    New accession
                  </Link>
                </Button>
              )}
            </EmptyStateActions>
          </EmptyState>
        )}
      </div>
      <ListFooter
        selectedRowCount={selectedRecordCount}
        handleDownload={handleDownload}
        selectAllRows={() => {
          const currentPageRecordsCount =
            table.getSelectedRowModel().rows.length
          table.toggleAllPageRowsSelected(currentPageRecordsCount <= 0)
        }}
        selectMode={selectMode}
        toggleSelectMode={toggleSelectMode}
        onActionsMenu={toggleActionsMenuOpen}
        total={total}
        onPreviousPage={onPrevPage}
        onNextPage={onNextPage}
        hasPreviousPage={pageInfo?.hasPreviousPage}
        hasNextPage={pageInfo?.hasNextPage}
        previousPageLoading={loadingDirection === PageDirection.Prev}
        nextPageLoading={loadingDirection === PageDirection.Next}
        testIdPrefix="plant-materials"
      />
      {materials != null && selectedRecordCount > 0 && (
        <MaterialsActionMenu
          selectedRecordsIDs={selectedRecordsIDs}
          open={actionsMenuOpen}
          toggleOpen={toggleActionsMenuOpen}
        />
      )}
    </div>
  )

  return isMobile ? (
    <Drawer
      key={String(open)}
      open={open}
      onOpenChange={(e) => {
        setDrawerCollapsed(!e)
      }}
      dismissible={false}
    >
      <DrawerContent className="h-[96%] overflow-hidden shadow-md-up">
        {content}
      </DrawerContent>
    </Drawer>
  ) : (
    <div
      className={twMerge(
        "z-50 h-full w-full max-w-xs self-center shadow-md lg:max-w-[26rem]",
        !open && "hidden",
        !isContextMenuOpen && "pointer-events-auto",
      )}
    >
      {content}
    </div>
  )
})
