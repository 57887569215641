import type {DialogContentTextProps as CoreDialogContentTextProps} from "@mui/material/DialogContentText"
import CoreDialogContentText from "@mui/material/DialogContentText"
import {applyMui} from "../../utils/apply-mui"
import {withTestId} from "../../utils/with-test-id"

export const DialogContentText = applyMui(
  withTestId,
  CoreDialogContentText as (props: CoreDialogContentTextProps) => JSX.Element,
)
export type DialogContentTextProps = CoreDialogContentTextProps
