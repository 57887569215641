import type {WithAuthenticationRequiredOptions} from "@auth0/auth0-react"
import {withAuthenticationRequired as withAuthenticationRequiredAuth0} from "@auth0/auth0-react"
import * as Bool from "fp-ts/boolean"
import {constant, flow} from "fp-ts/function"
import * as O from "fp-ts/Option"
import type {ComponentType} from "react"
import {useAuth0Cookie, useRunningWithCypress} from "./cypress"
import {flowFrom} from "./flow"

/**
 * This matches auth0's defaultOnRedirecting.
 * It's not a React component, it's a function that returns JSX.
 */
const defaultOnRedirecting = (): JSX.Element => <></>

/**
 * This Component should only be seen during Cypress tests
 * where auth has failed for some reason.
 * If you ever see it in local development or in production, something has gone terribly wrong!
 */
const NoTokenFound = () => <div>Error: No cypress auth cookie found!</div>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyRecord = Record<string | number | symbol, any>

type WithAuthenticationRequiredAuth0 = typeof withAuthenticationRequiredAuth0

/**
 * What does this function do? It makes explicit that
 * a normal, non-hook function can be treated as a React component.
 * This is useful if you're trying to match behaviour of a library
 * or another API that accepts a function that produces JSX, but it's not
 * invoked via JSX.
 * Invoking a function directly in a render function runs it immediately,
 * rather than deferring it as with a React component.
 * It also means that it may not be safe to use hooks inside it, as
 * the function may be invoked conditionally.
 */
const asReactComponent: <
  Fn extends (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    props: Record<string | number | symbol, any> | undefined,
  ) => JSX.Element,
>(
  fn: Fn,
) => Fn = (fn) =>
  function Wrapped(props) {
    return fn(props)
  } as typeof fn

/**
 * Chooses the to show either the Component, an error state
 * depending on whether the Cypress auth token is present or not.
 * While waiting, show onRedirecting, like auth0's withAuthenticationRequired.
 */
export const guardCypressToken = <P extends AnyRecord>(
  Component: ComponentType<React.PropsWithChildren<P>>,
  options: WithAuthenticationRequiredOptions = {},
): ((
  ma: O.Option<O.Option<unknown>>,
) => ComponentType<React.PropsWithChildren<P>>) => {
  const {onRedirecting = defaultOnRedirecting} = options
  // This is an awkward hack to let us match Auth0's guard option function `onRedirecting`
  const OnRedirecting = asReactComponent(onRedirecting)

  return O.match(
    constant(OnRedirecting),
    O.matchW(constant(NoTokenFound), constant(Component)),
  )
}

const withAuthenticationRequiredCypress: WithAuthenticationRequiredAuth0 = flow(
  guardCypressToken,
  flowFrom(useAuth0Cookie),
  (useComponent) =>
    function WithAuthenticationRequiredCypress(props) {
      const Component = useComponent()
      return <Component {...props} />
    },
)

/**
 * Choses the right auth guard depending on whether Cypress is present or not.
 * While waiting, show onRedirecting, like auth0's withAuthenticationRequired.
 */
export const chooseCypressOrAuth0 = <P extends AnyRecord>(
  Component: ComponentType<React.PropsWithChildren<P>>,
  options: WithAuthenticationRequiredOptions = {},
): ((ma: O.Option<boolean>) => ComponentType<React.PropsWithChildren<P>>) => {
  const {onRedirecting = defaultOnRedirecting} = options
  // This is an awkward hack to let us match Auth0's guard option function `onRedirecting`
  const OnRedirecting = asReactComponent(onRedirecting)

  return O.matchW(
    constant(OnRedirecting),
    Bool.match(
      constant(withAuthenticationRequiredAuth0(Component, options)),
      constant(withAuthenticationRequiredCypress(Component, options)),
    ),
  )
}

const withCypressOrAuth0: WithAuthenticationRequiredAuth0 = flow(
  chooseCypressOrAuth0,
  flowFrom(useRunningWithCypress),
  (useGuard) =>
    function PotentiallyCypressGuarded(props) {
      const GuardedComponent = useGuard()

      return <GuardedComponent {...props} />
    },
)

export const withAuthentication =
  import.meta.env.NEXT_PUBLIC_CYPRESS_AUTH_MODE === "ALLOW"
    ? withCypressOrAuth0
    : withAuthenticationRequiredAuth0
