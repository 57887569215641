import {colors} from "src/colors"
import type {IconProps} from "./types"

export const SlAddIcon = ({
  color = colors.grey[700],
  ...props
}: IconProps): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path d="M4 12H20H4Z" fill={color} />
      <path
        d="M4 12H20"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12 4V20V4Z" fill={color} />
      <path
        d="M12 4V20"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const AvatarAdd = ({...props}: IconProps): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M12.4999 7.33333V16.6667M7.83325 12H17.1666"
        stroke={colors.grey[400]}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="2.5"
        y="2"
        width="20"
        height="20"
        rx="10"
        stroke={colors.grey[400]}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="1 3"
      />
    </svg>
  )
}
