import type {DateComparator} from "generated/graphql"
import {Fragment} from "react"
import {useDateStringFormatter} from "src/utils/hooks/language"
import {FilterChip} from "../../filter-chip-group"

import type {OpenFilterArgs} from "../filters"
import {
  createDateEqualFilterText,
  createDateRangeFilterText,
} from "./date-filter-text"

export const DateComparatorChipGroup = <T extends string>({
  id,
  filter,
  hidden,
  ...props
}: {
  id: string
  handleOpenFilter: (args: OpenFilterArgs<T>) => void
  handleDelete: (id: string) => void
  filter: DateComparator | undefined
  filterType: T
  filterLabel: string
  hidden?: boolean
}) => {
  const formatDateString = useDateStringFormatter()

  return filter == null || hidden === true ? (
    <Fragment />
  ) : (
    <>
      {filter.eq != null && (
        <FilterChip
          filterId={id}
          filterData={createDateEqualFilterText(filter.eq, formatDateString)}
          operation="is"
          {...props}
        />
      )}
      {filter.gte != null && filter.lte != null && (
        <FilterChip
          filterId={id}
          filterData={createDateRangeFilterText(
            {from: filter.gte, to: filter.lte},
            formatDateString,
          )}
          operation="is between"
          containerClassname="max-h-full"
          contentClassname="flex-wrap text-left overflow-visible whitespace-normal"
          {...props}
        />
      )}
    </>
  )
}
