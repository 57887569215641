import Alert from "@mui/material/Alert"
import {useTheme} from "@mui/material/styles"
import {useGetPublicOrganisationQuery} from "generated/graphql"
import {useCallback} from "react"
import {Helmet} from "react-helmet-async"
import {Link} from "@tanstack/react-router"
import {Button} from "@hortis/ui/button"
import {useOrganisationSubdomainStruct} from "src/utils/hooks/place"
import {Typography} from "../../../../components/typography"
import {AuthCard} from "../auth-card"
import {AuthPageLayout} from "../auth-page-layout"
import {CardFooter} from "../card-footer"
import {OrgInfo} from "../org-info"
import {useErrorDescription} from "./hooks"
import {useRedirectToAuth0} from "./use-redirect-to-auth0"

function Login() {
  const theme = useTheme()
  const searchParamError = useErrorDescription()
  const subdomain = useOrganisationSubdomainStruct()

  const pause = subdomain.data == null
  const [{data: org, fetching}] = useGetPublicOrganisationQuery({
    variables: {subdomain: subdomain.data ?? ""},
    pause,
  })

  const loading = subdomain.loading || fetching
  const {redirectToAuth0, redirectLoading, redirectError} = useRedirectToAuth0({
    subdomain: subdomain.data,
    org,
    searchParamError,
    fetching: loading,
  })

  const error = subdomain.error ?? searchParamError ?? redirectError

  const onLogin = useCallback(() => {
    void redirectToAuth0("login")
  }, [redirectToAuth0])

  const onSignup = useCallback(() => {
    void redirectToAuth0("signup")
  }, [redirectToAuth0])

  return (
    <AuthPageLayout>
      <Helmet>
        <title>Login | Hortis</title>
      </Helmet>
      <AuthCard>
        <OrgInfo org={org?.publicOrganisation ?? null} />
        <div className="flex w-full flex-col gap-6">
          <div className="flex w-full flex-col gap-4">
            {error == null ? null : (
              <Alert
                data-cy="error-alert"
                severity="error"
                sx={{
                  width: "100%",
                }}
              >
                {error instanceof Error ? error.message : error}
              </Alert>
            )}
            <Typography variant="body2">New to Hortis?</Typography>
            <Button
              size="lg"
              variant="primary"
              fullWidth
              onClick={onSignup}
              loading={redirectLoading === "signup"}
              testId="org-signin-button"
              disabled={loading}
            >
              Activate account
            </Button>
          </div>
          <div className="flex w-full flex-col gap-4">
            <Typography variant="body2">Already signed up?</Typography>
            <Button
              size="lg"
              fullWidth
              onClick={onLogin}
              loading={redirectLoading === "login"}
              testId="org-login-button"
              disabled={loading}
            >
              Log in
            </Button>
          </div>
          <Link
            to="/auth/select-organisation"
            style={{
              fontSize: theme.typography.fontSize,
              textDecoration: "none",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            Choose another organization
          </Link>
        </div>
        <CardFooter />
      </AuthCard>
    </AuthPageLayout>
  )
}

export const Component = Login
