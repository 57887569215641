import {F} from "@mobily/ts-belt"
import {useEffect, useMemo, useState} from "react"
import type {ViewStateChangeEvent} from "react-map-gl"
import type {MaplibreRef} from "../../../components/map/utils"
import {useUpdateMap, useUpdateParams} from "../utils"

export const useLatLongZoom = ({ourMap}: {ourMap: MaplibreRef | undefined}) => {
  const [mapMounted, setMapMounted] = useState(false)
  const updateParams = useUpdateParams()
  const updateMap = useUpdateMap()
  const updateLatLng = useMemo(
    () =>
      F.debounce((event: ViewStateChangeEvent) => {
        updateParams(event)
      }, 250),
    [updateParams],
  )

  /**
   * this should be safe - `mapRef`, `latLng` and `zoom` should have stable identities
   * therefore won't retrigger this effect whilst you're navigating around the map.
   * if they did change identity, then the map would spontaneously fly away whilst you are using it!
   * UPDATE: React 18 broke this somehow. Now just run on mount - can no longer navigate back through
   * previous coordinates
   */
  useEffect(() => {
    setMapMounted(true)
    if (!mapMounted) {
      updateMap(ourMap)
    }
  }, [ourMap, mapMounted, updateMap])

  return {updateLatLng}
}
