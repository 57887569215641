import {Badge} from "@hortis/ui/badge"
import {IconButton} from "@hortis/ui/button"
import {AlarmClock, Plus} from "@hortis/ui/icons"
import {add, format} from "date-fns"
import {
  Period,
  PlantMaterialStatus,
  type MaterialFieldsFragment,
} from "generated/graphql"
import * as R from "ramda"
import {useState, type ReactElement} from "react"
import type {Formatter} from "react-timeago"
import TimeAgo from "react-timeago"
import {MakeObservationDialogContent} from "src/components/modals/make-observation"
import {isOverdueFollowup} from "src/components/modals/make-observation/check-overdue-followup"
import {followUpOptions} from "src/components/modals/make-observation/follow-up-options"
import {
  RecordField,
  RecordFieldLabel,
  RecordFieldValue,
} from "src/features/records/components/record-field/new-record-field"
import {
  RecordSection,
  RecordSectionContent,
  RecordSectionContentGroup,
  RecordSectionHeader,
  RecordSectionHeaderButtons,
  RecordSectionTitle,
} from "src/features/records/components/record-section/new-record-section"
import {Dialog, DialogTrigger} from "@hortis/ui/dialog"
import {parseUtcDate} from "src/utils/dates/parse-utc-date"
import {ConditionRating} from "./condition"

export interface MaterialRecordObservationsProps {
  material: MaterialFieldsFragment
  canEdit: boolean
  refetchMaterial: () => void
}
const timeAgoFormatter: Formatter = (value, unit, suffix) => {
  const isDueToday = new Set(["second", "hour", "minute"])
  return isDueToday.has(unit) || (unit === "day" && value < 1)
    ? "Today"
    : `${value} ${unit}${value > 1 ? "s" : ""} ${
        suffix === "ago" ? "overdue" : ""
      }`
}

export const MaterialRecordObservations = ({
  material,
  refetchMaterial,
  canEdit, // eslint-disable-next-line sonarjs/cognitive-complexity
}: MaterialRecordObservationsProps): ReactElement => {
  const [observationModalOpen, setObservationModalOpen] = useState(false)

  return (
    <RecordSection id="observations">
      <RecordSectionHeader>
        <RecordSectionTitle>Observations</RecordSectionTitle>
        {material.followUp &&
          material.lastObserved != null &&
          isOverdueFollowup(
            new Date(material.lastObserved),
            material.followUp,
          ) && (
            <Badge testId="observation-overdue-badge" color="warning">
              <div className="flex items-center gap-1">
                <AlarmClock size="12px" className="h-3 w-3 text-warning-500" />
                <span>Overdue</span>
              </div>
            </Badge>
          )}
        <RecordSectionHeaderButtons>
          {canEdit &&
            !material.archived &&
            material.status !== PlantMaterialStatus.Absent && (
              <Dialog
                open={observationModalOpen}
                onOpenChange={setObservationModalOpen}
              >
                <DialogTrigger asChild>
                  <IconButton
                    onClick={() => {
                      setObservationModalOpen(true)
                    }}
                    ariaLabel="Make obersevation"
                    data-cy="material-observation-section-make-new-observation-button"
                    size="xs"
                    variant="tertiaryGray"
                    icon={Plus}
                  />
                </DialogTrigger>
                <MakeObservationDialogContent
                  onSubmit={() => {
                    refetchMaterial()
                    setObservationModalOpen(false)
                  }}
                  onClose={() => {
                    setObservationModalOpen(false)
                  }}
                  materialId={material.id}
                  defaultValues={material}
                />
              </Dialog>
            )}
        </RecordSectionHeaderButtons>
      </RecordSectionHeader>

      <RecordSectionContent>
        <>
          <RecordSectionContentGroup className="grid lg:grid-rows-2">
            <RecordField data-cy="record-condition">
              <RecordFieldLabel>Condition</RecordFieldLabel>
              <RecordFieldValue>
                {material.condition == null ? (
                  "-"
                ) : (
                  <ConditionRating condition={material.condition} />
                )}
              </RecordFieldValue>
            </RecordField>
            <RecordField data-cy="record-observation-date">
              <RecordFieldLabel>Observation date</RecordFieldLabel>
              <RecordFieldValue>
                {material.lastObserved == null
                  ? "-"
                  : format(parseUtcDate(material.lastObserved), "PPP")}
              </RecordFieldValue>
            </RecordField>

            <RecordField data-cy="record-observation-followup">
              <RecordFieldLabel>Follow-up</RecordFieldLabel>
              <RecordFieldValue>
                {material.followUp == null
                  ? "-"
                  : followUpOptions.find(({value}) =>
                      R.equals(
                        value,
                        R.pick(["period", "count"], material.followUp),
                      ),
                    )?.label ?? "None"}
              </RecordFieldValue>
            </RecordField>
            <RecordField data-cy="record-next-observation">
              <RecordFieldLabel>Next observation</RecordFieldLabel>
              <RecordFieldValue>
                {material.lastObserved == null || material.followUp == null ? (
                  "-"
                ) : (
                  <TimeAgo
                    date={add(new Date(material.lastObserved), {
                      days:
                        material.followUp.period === Period.Days
                          ? material.followUp.count
                          : 0,
                      weeks:
                        material.followUp.period === Period.Weeks
                          ? material.followUp.count
                          : 0,
                      months:
                        material.followUp.period === Period.Months
                          ? material.followUp.count
                          : 0,
                      years:
                        material.followUp.period === Period.Years
                          ? material.followUp.count
                          : 0,
                    })}
                    formatter={timeAgoFormatter}
                  />
                )}
              </RecordFieldValue>
            </RecordField>
          </RecordSectionContentGroup>
        </>
      </RecordSectionContent>
    </RecordSection>
  )
}
