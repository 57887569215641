import {memo, useCallback} from "react"
import {IconButton} from "@hortis/ui/button"
import {Clipboard, Plus} from "@hortis/ui/icons"
import {useSnackbarStore} from "src/components/snackbar-controller/snackbar-store"
import {copyToClipboard} from "src/utils/dom/copy-to-clipboard"
import {Divider} from "src/components/custom/divider"
import {Popup} from "../popup"
import type {PopupProps} from "../popup"

export type CoordinateMenuProps = Omit<PopupProps, "children" | "style">

export const CoordinateMenu = memo(function MemoisedMaterialPopup({
  longitude,
  latitude,
  requestNewMaterial: _requestNewMaterial,
  closeCoordinateMenu,
}: CoordinateMenuProps & {
  requestNewMaterial: () => void
  closeCoordinateMenu: () => void
}) {
  const {setSnack} = useSnackbarStore()

  const handleCopy = useCallback(() => {
    void copyToClipboard(
      `${longitude}, ${latitude}`,
      () => {
        setSnack({
          type: "action",
          data: {text: "Copied coordinates to clipboard"},
        })
      },
      () => {
        setSnack({
          type: "alert",
          data: {
            severity: "error",
            text: "Unable to coordinates - there was an issue with your browser",
          },
        })
      },
    )
  }, [setSnack, longitude, latitude])

  const requestNewMaterial = useCallback(() => {
    _requestNewMaterial()
    closeCoordinateMenu()
  }, [_requestNewMaterial, closeCoordinateMenu])

  return (
    <Popup longitude={longitude} latitude={latitude} focusAfterOpen={false}>
      <div className="relative flex flex-col flex-wrap overflow-hidden rounded-lg border border-grey-100 bg-white py-1">
        <div className="flex items-center gap-2 px-4 py-2 font-sans text-sm text-grey-700">
          {longitude.toFixed(4)}, {latitude.toFixed(4)}
          <IconButton
            onClick={handleCopy}
            ariaLabel="Copy coordinates to clipboard"
            size="xs"
            variant="tertiaryGray"
            icon={Clipboard}
          />
        </div>
        <div className="px-3">
          <Divider />
        </div>
        <div className="flex-1">
          <button
            className="flex w-full items-center gap-3 px-4 py-2.5 text-sm hover:bg-grey-50"
            type="button"
            onClick={requestNewMaterial}
            data-cy="new-material-button"
          >
            <Plus size="16px" />
            New material
          </button>
        </div>
      </div>
    </Popup>
  )
})
