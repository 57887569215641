import {gql} from "graphql-request"
import {TAXA_LIST_FIELDS} from "../taxonomy/components/list/graphql"
import {CORE_ORGANISATION_MEMBERS_FIELDS} from "../settings/components/org/graphql"
import {CORE_MATERIAL_IMAGE_FIELDS} from "../records/material/graphql"

export const CORE_ACCESSIONS_LIST_FIELDS = gql`
  ${CORE_ORGANISATION_MEMBERS_FIELDS}
  fragment AccessionsListFields on Accession {
    id
    accessionNumber
    accessionDate
    archived
    deaccessionDate
    scientificName
    updatedAt
    createdBy {
      ...OrganisationMemberFields
    }
    updatedBy {
      ...OrganisationMemberFields
    }
    materialType {
      name
    }
    tagsConnection {
      nodes {
        id
        name
      }
    }
    notes
    ipenNumber
    taxon {
      scientificName
      commonName
      fullyValidated
      nativeDistribution
      sharedScientificName {
        id
        scientificName
        status
        nameSourceUrl
        nameSource
        rank
        authorship
        taxon {
          nativeDistribution
          climate
          globalRedListStatus
          lifeForms {
            nodes {
              id
              name
            }
          }
        }
      }
    }
    provenanceCategory {
      name
      codeId
    }
    provenanceCategoryCode
    donor
    createdByInitials
    plantMaterial(filters: $plantMaterialFilters) {
      status
      primaryImage {
        ...MaterialImageFields
      }
      images {
        ...MaterialImageFields
      }
    }
  }
`

export const CORE_PLANT_MATERIAL_LIST_FIELDS = gql`
  ${CORE_MATERIAL_IMAGE_FIELDS}
  fragment MaterialsListFields on PlantMaterial {
    id
    qualifier
    status
    archived
    quantity
    massPlanting
    weight
    materialGroup
    createdAt
    firstPresent
    firstAbsent
    updatedAt
    sex
    tagNumber
    public
    createdBy {
      ...OrganisationMemberFields
    }
    updatedBy {
      ...OrganisationMemberFields
    }
    tagsConnection {
      nodes {
        id
        name
      }
    }
    notes
    condition
    lastObserved
    daysOverdueFollowUp
    followUp {
      count
      period
    }
    primaryImage {
      ...MaterialImageFields
    }
    images {
      ...MaterialImageFields
    }
    collectionSiteLocation {
      id
      name
      description
      archived
    }
    accession {
      accessionNumber
      scientificName
      donor
      taxon {
        scientificName
        commonName
        fullyValidated
        nativeDistribution
        sharedScientificName {
          id
          scientificName
          status
          nameSourceUrl
          nameSource
          rank
          authorship
          taxon {
            nativeDistribution
            climate
            globalRedListStatus
            lifeForms {
              nodes {
                id
                name
              }
            }
          }
        }
      }
      provenanceCategory {
        name
        codeId
      }
      provenanceCategoryCode
      donor
    }
  }
`

export const CORE_PAGE_INFO_FIELDS = gql`
  fragment PageInfoFields on PageInfo {
    startCursor
    hasNextPage
    hasPreviousPage
    endCursor
  }
`

export const GET_ACCESSIONS = gql`
  ${CORE_ACCESSIONS_LIST_FIELDS}
  ${CORE_PAGE_INFO_FIELDS}
  query GetAccessions(
    $before: String
    $after: String
    $first: Int
    $last: Int
    $organisationSubdomain: String!
    $collectionSiteSlug: String!
    $orderBy: AccessionsOrderByInput
    $searchTerm: AccessionsSearchTerm
    $filters: AccessionFilter
    $includeArchived: Boolean
    $plantMaterialFilters: PlantMaterialFilter
  ) {
    org: organisationBySubdomain(subdomain: $organisationSubdomain) {
      site: collectionSiteBySlug(slug: $collectionSiteSlug) {
        total: accessionTotal(
          searchTerm: $searchTerm
          filters: $filters
          includeArchived: $includeArchived
        )
        result: accessions(
          before: $before
          after: $after
          first: $first
          last: $last
          orderBy: $orderBy
          searchTerm: $searchTerm
          filters: $filters
          includeArchived: $includeArchived
        ) {
          nodes {
            ...AccessionsListFields
          }
          pageInfo {
            ...PageInfoFields
          }
        }
      }
    }
  }
`

export const GET_PLANT_MATERIALS = gql`
  ${CORE_PLANT_MATERIAL_LIST_FIELDS}
  ${CORE_PAGE_INFO_FIELDS}
  query PlantMaterials(
    $organisationSubdomain: String!
    $collectionSiteSlug: String!
    $orderBy: PlantMaterialsOrderByInput
    $before: String
    $after: String
    $first: Int
    $last: Int
    $filters: PlantMaterialFilter
    $searchTerm: PlantMaterialsSearchTerm
    $includeArchived: Boolean
  ) {
    org: organisationBySubdomain(subdomain: $organisationSubdomain) {
      site: collectionSiteBySlug(slug: $collectionSiteSlug) {
        total: plantMaterialTotal(
          filters: $filters
          searchTerm: $searchTerm
          includeArchived: $includeArchived
        )
        result: plantMaterials(
          before: $before
          after: $after
          first: $first
          last: $last
          orderBy: $orderBy
          filters: $filters
          searchTerm: $searchTerm
          includeArchived: $includeArchived
        ) {
          nodes {
            ...MaterialsListFields
          }
          pageInfo {
            ...PageInfoFields
          }
        }
      }
    }
  }
`

export const GET_ME_GARDEN = gql`
  query GetMeGarden {
    me {
      collectionSites {
        name
        shortenedName
        urlSlug
      }
    }
  }
`

export const GET_SITE_TAXA = gql`
  ${TAXA_LIST_FIELDS}
  query GetSiteTaxa(
    $before: String
    $after: String
    $first: Int
    $last: Int
    $organisationSubdomain: String!
    $collectionSiteSlug: String!
    $searchTerm: TaxaSearchTerm
    $orderBy: TaxaOrderByInput
    $filter: TaxaFilter
  ) {
    org: organisationBySubdomain(subdomain: $organisationSubdomain) {
      site: collectionSiteBySlug(slug: $collectionSiteSlug) {
        total: taxaTotal(searchTerm: $searchTerm, filter: $filter)
        result: taxa(
          before: $before
          after: $after
          first: $first
          last: $last
          searchTerm: $searchTerm
          orderBy: $orderBy
          filter: $filter
        ) {
          nodes {
            ...TaxaListFields
          }
          pageInfo {
            ...PageInfoFields
          }
        }
      }
    }
  }
`
