import {
  useBatchArchiveTaxaMutation,
  useValidateArchiveTaxaQuery,
} from "generated/graphql"
import {useEffect, type ReactNode, type RefObject} from "react"
import {Button} from "@hortis/ui/button"
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@hortis/ui/dialog"
import {useSnackbarStore} from "src/components/snackbar-controller/snackbar-store"
import {onFailure, onSuccess} from "src/notification-snack-utils"

export const TrashTaxaDialog = ({
  taxaIds,
  open,
  setOpen,
  onSuccess: onSuccessProp,
  triggerRef,
  children,
}: {
  taxaIds: ReadonlyArray<string>
  open: boolean
  setOpen: (open: boolean) => void
  onSuccess: () => void
  triggerRef?: RefObject<HTMLButtonElement>
  children?: ReactNode
}) => {
  const {setSnack} = useSnackbarStore()
  const [{fetching: archiveTaxaFetching}, archiveTaxa] =
    useBatchArchiveTaxaMutation()

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setOpen(false)
      }
    }

    document.addEventListener("keydown", down)
    return () =>
      // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
      document.removeEventListener("keydown", down)
  }, [open, setOpen])

  const [
    {data: validateArchiveTaxaRes, fetching: validateArchiveTaxaFetching},
  ] = useValidateArchiveTaxaQuery({
    variables: {
      ids: taxaIds,
    },
    pause: !open,
  })

  const hasAssociatedAccessions =
    validateArchiveTaxaRes?.validateBatchArchiveTaxa.success === false &&
    validateArchiveTaxaRes.validateBatchArchiveTaxa.errors?.some(
      (error) => error.__typename === "AssociatedAccessionsError",
    )

  const handleDeleteTaxa = async () => {
    if (validateArchiveTaxaRes?.validateBatchArchiveTaxa.success !== true) {
      onFailure(setSnack)(new Error(`Failed to trash taxa`))
      return
    }

    const res = await archiveTaxa({
      ids: taxaIds,
    })

    if (res.data?.batchArchiveTaxa.success !== true) {
      onFailure(setSnack)(new Error(`Failed to trash taxa`))
      return
    }

    onSuccess(setSnack)("Successfully trashed taxa")
    setOpen(false)
    onSuccessProp()
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      {children}
      <DialogContent data-cy="trash-taxa-dialog" triggerRef={triggerRef}>
        <DialogHeader>
          <DialogTitle>Move to trash?</DialogTitle>
          <DialogDescription>
            {hasAssociatedAccessions === true
              ? `Please remove the associated accessions from all selected taxa`
              : `This will move ${taxaIds.length} taxa to the trash.`}
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <DialogClose asChild>
            <Button size="lg" fullWidth testId="close-trash-taxa-dialog-button">
              Cancel
            </Button>
          </DialogClose>
          <Button
            size="lg"
            onClick={handleDeleteTaxa}
            disabled={hasAssociatedAccessions}
            loading={validateArchiveTaxaFetching || archiveTaxaFetching}
            variant="warning"
            fullWidth
            testId="trash-taxa-button"
          >
            Move to trash
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
