import CoreTooltip from "@mui/material/Tooltip"
import type {TooltipProps as CoreTooltipProps} from "@mui/material/Tooltip"
import {colors} from "src/colors"
import {shadows} from "src/shadows"
import {withTestId} from "../utils/with-test-id"
import {applyMui} from "../utils/apply-mui"

const coreComponentProps = {
  tooltip: {
    sx: {
      padding: `8px 12px`,
      backgroundColor: colors.white,
      color: colors.grey[800],
      border: `1px solid ${colors.grey[100]}`,
      fontWeight: 700,
      boxShadow: shadows.lg,
    },
  },
}

export const StyledTooltip = (props: CoreTooltipProps) => (
  <CoreTooltip {...props} componentsProps={coreComponentProps} />
)

export type TooltipProps = CoreTooltipProps
export const Tooltip = applyMui(withTestId, StyledTooltip)
