import * as RA from "fp-ts/ReadonlyArray"
import * as Ord from "fp-ts/Ord"
import {pipe} from "fp-ts/function"
import {whenNonNullable} from "./nullable"
import * as MaterialQualifier from "./material-qualifiers/bijective-base-26"

export const sortMaterials = whenNonNullable(
  RA.sort(
    pipe(
      MaterialQualifier.Ord,
      Ord.contramap(
        ({qualifier}: {qualifier: string}) =>
          qualifier as MaterialQualifier.MaterialQualifier,
        // we can't ultimately prove this is safe as we're making an assumption about the API response
        // TODO: could we use MaterialQualifier.MaterialQualifier.is and provide a fallback?
      ),
    ),
  ),
)
