// eslint-disable-next-line no-restricted-imports
import type {Theme} from "@mui/material"
import type {CSSObject as CoreCSSObject} from "@mui/material/styles"
import type {
  AllSystemCSSProperties,
  ResponsiveStyleValue,
  SxProps as CoreSxProps,
  SystemStyleObject as CoreSystemStyleObject,
} from "@mui/system"
import type * as CSS from "csstype"
import * as Monoid from "fp-ts/Monoid"
import * as Struct from "fp-ts/struct"
import type {CSSProperties} from "react"

// from https://github.com/microsoft/TypeScript/issues/34801#issuecomment-1021745881
// as a suggested fix for MUI type checking performance
export type SxProps = CoreSxProps<Theme>

export type SxTheme = Theme

// cut down version of CSSPseudoSelectorProps
type CSSPseudoSelectorPropsJustValues = {
  [K in CSS.Pseudos]?: SxValues
}

// simplified version of SxProps<Theme>
type SxFn = (theme: Theme) => SxValues
export type SystemStyleObject = CoreSystemStyleObject<Theme>

type SxPropsFnSubset = (theme: Theme) => SystemStyleObject

type FromSxFn = (fn: SxFn) => SxPropsFnSubset
export const fromSxFn: FromSxFn = ((fn: SxFn) => fn) as unknown as FromSxFn

// cut down version of SystemCssProperties...
type SystemCssPropertiesJustValues = {
  [K in keyof AllSystemCSSProperties]:
    | ResponsiveStyleValue<AllSystemCSSProperties[K]>
    | SxValues
}

// cut down version of CSSSelectorObject<Theme>
// eslint-disable-next-line @typescript-eslint/consistent-indexed-object-style -- recursive, so we need to use this representation
type CSSSelectorObjectJustValues = {
  [selector: string]: SxValues // TODO: add this union to match AllSystemCSSProperties: | string | number ?
}

/**
 * This is a stripped down version of SystemStyleObject
 * that type checks faster because it only allows plain nested objects,
 * not functions or other more complicated types
 */
type SxValues =
  | SystemCssPropertiesJustValues
  | CSSPseudoSelectorPropsJustValues
  | CSSSelectorObjectJustValues
  | null

type FromSxValues = (val: SxValues) => SystemStyleObject
export const fromSxValues: FromSxValues = ((x: SxValues) =>
  x) as unknown as FromSxValues

export type CSSObject = CoreCSSObject

export const CSSObjectNonNullableMonoid: Monoid.Monoid<CSSObject> = {
  ...Struct.getAssignSemigroup<CSSObject>(),
  empty: {},
}
export const assignCSSObjectNonNullable = CSSObjectNonNullableMonoid.concat
export const concatAllCSSObjectNonNullable = Monoid.concatAll(
  CSSObjectNonNullableMonoid,
)

type CSSObjectNullable = CSSObject | undefined
export const CSSObjectMonoid: Monoid.Monoid<CSSObjectNullable> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- type checking perf hack
  concat: (a: any, b: any): CSSObjectNullable =>
    (a === undefined
      ? b
      : b === undefined
      ? a
      : // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        CSSObjectNonNullableMonoid.concat(a, b)) as CSSObjectNullable,
  empty: undefined,
}
export const assignCSSObject = CSSObjectMonoid.concat
export const concatAllCSSObject = Monoid.concatAll(CSSObjectMonoid)

export const CSSPropertiesNonNullableMonoid: Monoid.Monoid<CSSProperties> = {
  ...Struct.getAssignSemigroup<CSSProperties>(),
  empty: {},
}
export const assignCSSPropertiesNonNullable =
  CSSPropertiesNonNullableMonoid.concat
export const concatAllCSSPropertiesNonNullable = Monoid.concatAll(
  CSSPropertiesNonNullableMonoid,
)

type CSSPropertiesNullable = CSSProperties | undefined
export const CSSPropertiesMonoid: Monoid.Monoid<CSSPropertiesNullable> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- type checking perf hack
  concat: (a: any, b: any): CSSPropertiesNullable =>
    (a === undefined
      ? b
      : b === undefined
      ? a
      : // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        CSSPropertiesNonNullableMonoid.concat(a, b)) as CSSPropertiesNullable,
  empty: undefined,
}
export const assignCSSProperties = CSSPropertiesMonoid.concat
export const concatAllCSSProperties = Monoid.concatAll(CSSPropertiesMonoid)

// inspired by https://mui.com/system/the-sx-prop/#passing-sx-prop
// sx={[
//   {
//     width: 'auto',
//     textDecoration: 'underline',
//   },
//   // You cannot spread `sx` directly because `SxProps` (typeof sx) can be an array.
//   ...(Array.isArray(sx) ? sx : [sx]),
// ]}
/* eslint-disable @typescript-eslint/no-unsafe-assignment -- type checking perf hack */
export const SxPropsNonNullableMonoid: Monoid.Monoid<SxProps> = {
  concat: (a: unknown, b: unknown): SxProps =>
    (Array.isArray(a)
      ? Array.isArray(b)
        ? [...a, ...b]
        : [...a, b]
      : Array.isArray(b)
      ? [a, ...b]
      : [a, b]) as SxProps,
  empty: {},
}
/* eslint-enable @typescript-eslint/no-unsafe-assignment */
export const assignSxPropsNonNullable = SxPropsNonNullableMonoid.concat
export const concatAllSxPropsNonNullable = Monoid.concatAll(
  SxPropsNonNullableMonoid,
)

type SxPropsNullable = SxProps | undefined
/**
 * Optimised for ignoring undefined values
 */
/* eslint-disable @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any -- type checking perf hack */
export const SxPropsMonoid: Monoid.Monoid<SxPropsNullable> = {
  concat: (a: any, b: any): SxPropsNullable =>
    (a === undefined
      ? b
      : b === undefined
      ? a
      : SxPropsNonNullableMonoid.concat(a, b)) as SxPropsNullable,
  empty: undefined,
}
/* eslint-enable @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any */
export const assignSxProps = SxPropsMonoid.concat
export const concatAllSxProps = Monoid.concatAll(SxPropsMonoid)

/**
 * This mitigates a source of major slow down in MUI
 * where you're using the props of another component to override
 * a base component. The `sx` props of both become intersected
 * and massively slow down type checking almost 10 times:
 * from about 90ms to about 700ms
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type OmitSx<Fn extends (props: any) => JSX.Element | null> = Fn extends (
  props: infer Props,
) => infer Result
  ? (props: Omit<Props, "sx">) => Result
  : never
