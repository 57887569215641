import {
  ContextMenuGroup,
  ContextMenuItem,
  ContextMenuLoading,
  ContextMenuSub,
  ContextMenuSubContent,
  ContextMenuSubTrigger,
} from "@hortis/ui/context-menu"
import {Alert, AlertContent, AlertText} from "@hortis/ui/alert"

import {Copy1} from "@hortis/ui/icons/copy-1"
import {useParams, useRouteContext} from "@tanstack/react-router"
import {
  useGetSiteTaxaQuery,
  useGetTaxaQuery,
  type TaxaListFieldsFragment,
} from "generated/graphql"
import {useSnackbarStore} from "src/components/snackbar-controller/snackbar-store"
import {onFailure, onSuccess} from "src/notification-snack-utils"
import {copyToClipboard} from "src/utils/dom/copy-to-clipboard"
import {AlertTriangle} from "@hortis/ui/icons"
import {CircularProgress} from "@hortis/ui/progress"

const ContextSubMenuErrorAlert = ({message}: {message: string}) => (
  <ContextMenuGroup>
    <div className="p-3">
      <Alert color="warning" className="mb-4" data-cy="taxon-tags-alert">
        <AlertTriangle />
        <AlertContent>
          <AlertText>{message}</AlertText>
        </AlertContent>
      </Alert>
    </div>
  </ContextMenuGroup>
)

const TaxonCopyContextMenuSub = ({
  taxa,
  children,
}: {
  taxa: ReadonlyArray<TaxaListFieldsFragment>
  children?: React.ReactNode
}) => {
  const {setSnack} = useSnackbarStore()

  const scientificNames = taxa.map((taxon) => taxon.scientificName).join("\n")
  const commonNames = taxa
    .map((taxon) => taxon.commonName)
    .filter((property): property is string => property != null)
    .join("\n")

  return (
    <ContextMenuSub>
      <ContextMenuSubTrigger data-cy="copy-menu-item">
        <Copy1 className="h-4 w-4 text-grey-500" />
        Copy
      </ContextMenuSubTrigger>
      <ContextMenuSubContent alignOffset={-46}>
        {children == null ? (
          <ContextMenuGroup>
            <ContextMenuItem
              onSelect={() => {
                void copyToClipboard(
                  scientificNames,
                  () => {
                    onSuccess(setSnack)(
                      taxa.length > 1
                        ? `${taxa.length} scientific names copied to clipboard`
                        : `"${scientificNames}" copied to clipboard`,
                    )
                  },
                  () => {
                    onFailure(setSnack)(new Error("Failed to copy"))
                  },
                )
              }}
            >
              <Copy1 className="h-4 w-4 text-grey-500" />
              {`Copy scientific name${taxa.length > 1 ? "s" : ""}`}
            </ContextMenuItem>

            <ContextMenuItem
              onSelect={() => {
                void copyToClipboard(
                  commonNames,
                  () => {
                    onSuccess(setSnack)(
                      taxa.length > 1
                        ? `${taxa.length} common names copied to clipboard`
                        : `"${commonNames}" copied to clipboard`,
                    )
                  },
                  () => {
                    onFailure(setSnack)(new Error("Failed to copy"))
                  },
                )
              }}
            >
              <Copy1 className="h-4 w-4 text-grey-500" />
              {`Copy common name${taxa.length > 1 ? "s" : ""}`}
            </ContextMenuItem>
          </ContextMenuGroup>
        ) : (
          children
        )}
      </ContextMenuSubContent>
    </ContextMenuSub>
  )
}

const OrganisationTaxonCopyContextMenuSub = ({
  selectedTaxaIds,
}: {
  selectedTaxaIds: ReadonlyArray<string>
}) => {
  const {subdomain} = useRouteContext({strict: false})

  const [{data, fetching, error}] = useGetTaxaQuery({
    variables: {
      organisationSubdomain: subdomain,
      last: selectedTaxaIds.length,
      filter: {
        id: {
          in: selectedTaxaIds,
        },
      },
    },
  })

  const selectedTaxa = data?.org?.result?.nodes ?? []

  if (data == null && error != null) {
    return (
      <TaxonCopyContextMenuSub taxa={selectedTaxa}>
        <ContextSubMenuErrorAlert message="Something went wrong." />
      </TaxonCopyContextMenuSub>
    )
  }

  if (data == null && fetching) {
    return (
      <TaxonCopyContextMenuSub taxa={selectedTaxa}>
        <ContextMenuGroup>
          <div className="flex items-center justify-center">
            <CircularProgress size={20} />
          </div>
        </ContextMenuGroup>
      </TaxonCopyContextMenuSub>
    )
  }

  return <TaxonCopyContextMenuSub taxa={selectedTaxa} />
}

const SiteTaxonCopyContextMenuSub = ({
  selectedTaxaIds,
}: {
  selectedTaxaIds: ReadonlyArray<string>
}) => {
  const {siteSlug} = useParams({from: "/_layout/sites/$siteSlug"})
  const {subdomain} = useRouteContext({from: "/_layout/sites/$siteSlug"})

  const [{data, fetching, error}] = useGetSiteTaxaQuery({
    variables: {
      organisationSubdomain: subdomain,
      collectionSiteSlug: siteSlug,
      last: selectedTaxaIds.length,
      filter: {
        id: {
          in: selectedTaxaIds,
        },
      },
    },
  })

  const selectedTaxa = data?.org?.site?.result?.nodes ?? []

  if (data == null && error != null) {
    return (
      <TaxonCopyContextMenuSub taxa={selectedTaxa}>
        <ContextSubMenuErrorAlert message="Something went wrong." />
      </TaxonCopyContextMenuSub>
    )
  }

  if (data == null && fetching) {
    return (
      <TaxonCopyContextMenuSub taxa={selectedTaxa}>
        <ContextMenuLoading />
      </TaxonCopyContextMenuSub>
    )
  }

  return <TaxonCopyContextMenuSub taxa={selectedTaxa} />
}

export {SiteTaxonCopyContextMenuSub, OrganisationTaxonCopyContextMenuSub}
