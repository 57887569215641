import {Link} from "@tanstack/react-router"
import {twMerge} from "tailwind-merge"

interface NavItemBaseProps {
  href: string
  label: string
  testId: string
  onNavigate?: () => void
  indent?: number
}

export const NavItemBase = ({
  href,
  label,
  testId,
  onNavigate,
  indent = 0,
}: NavItemBaseProps) => {
  return (
    <li>
      <Link
        to={href}
        data-cy={testId}
        onClick={onNavigate}
        className={twMerge(
          "flex rounded-[6px] px-3 py-2 text-sm font-medium text-grey-700 outline-none ring-grey-100 transition-colors hover:bg-grey-50 hover:text-grey-900 focus-visible:ring-4 [&.active]:bg-grey-50 [&.active]:text-grey-900",
        )}
        style={{paddingLeft: 48 + indent * 16}}
      >
        {label}
      </Link>
    </li>
  )
}
