import {R} from "@mobily/ts-belt"
import type {usePlantMaterialsQuery} from "generated/graphql"
import type {usePlaceStruct} from "src/utils/hooks/place"

export const processPlantMaterialsQuery = ({
  place,
  plantMaterialsQuery,
}: {
  place: ReturnType<typeof usePlaceStruct>
  plantMaterialsQuery: ReturnType<typeof usePlantMaterialsQuery>[0]
}) => {
  const error = plantMaterialsQuery.error ?? place.error
  if (error != null) {
    return R.Error(error.message)
  }
  const org = plantMaterialsQuery.data?.org
  if (org == null) {
    return R.Error("Organisation field in query result was null")
  }
  const site = org.site
  if (site == null) {
    return R.Error("Site field in query result was null")
  }
  const result = site.result
  if (result == null) {
    return R.Error("Result field in query result was null")
  }
  return R.Ok({
    nodes: result.nodes as Array<(typeof result.nodes)[number]>,
    materialsTotal: site.total,
    pageInfo: result.pageInfo,
  })
}
