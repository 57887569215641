import type {ReactElement} from "react"
import {memo} from "react"
import {colors} from "src/colors"
import {Typography} from "../../../components/typography"

export const CardFooter = memo(function MemoisedCardFooter(): ReactElement {
  return (
    <div className="mt-6">
      <div className="flex gap-4">
        <a href="https://hortis.com/privacy" target="_blank" rel="noreferrer">
          <Typography variant="body2">Privacy</Typography>
        </a>
        <Typography fontSize="10px" color={colors.grey[400]}>
          •
        </Typography>
        <a href="https://hortis.com/terms" target="_blank" rel="noreferrer">
          <Typography variant="body2">Terms</Typography>
        </a>
      </div>
    </div>
  )
})
