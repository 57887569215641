import {constant, flow} from "fp-ts/function"
import * as IOO from "fp-ts/IOOption"
import * as O from "fp-ts/Option"
import {useEffect, useState} from "react"

export const alwaysGetNothing = constant(IOO.none)

const hostnameRegex = /^(?<org>[^.]+)\.(?<domain>[^.]+)\.(?<ext>.+)$/
export const extractHostname = O.fromNullableK(
  (hostname: string): string | undefined => {
    const domain = hostnameRegex.exec(hostname)?.groups?.domain ?? undefined
    const ext = hostnameRegex.exec(hostname)?.groups?.ext ?? undefined
    return domain != null && ext != null ? `${domain}.${ext}` : undefined
  },
)
const lazyExtractHostname = () => extractHostname(window.location.hostname)
export const getHostname: () => IOO.IOOption<string> =
  typeof window === "undefined" ? alwaysGetNothing : () => lazyExtractHostname

export const makeUseHostname = flow(
  getHostname,
  (getHostname) =>
    function useHostname() {
      const [hostname, setHostname] = useState<O.Option<O.Option<string>>>(
        O.none,
      )

      useEffect(() => {
        setHostname(O.some(getHostname()))
      }, [])

      return hostname
    },
)

export const useHostnameWhenPresent = flow(
  flow(makeUseHostname, (x) => x()),
  O.flatten,
  O.toUndefined,
)
