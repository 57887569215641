import {A, D, F, O, pipe} from "@mobily/ts-belt"
import {type SortingState} from "@tanstack/react-table"
import {SortDirection} from "generated/graphql"
import {useCallback, useEffect, useMemo, useState} from "react"
import {useToggle} from "../toggle"

const flipKeyValues = <K extends string, V extends string | undefined>(
  data: Partial<Record<K, V>>,
) =>
  pipe(
    data,
    D.filter((value) => value != null),
    (a) => D.toPairs(a as Record<string, string>),
    A.map(([key, value]) => [value, key] as const),
    D.fromPairs,
  ) as Record<NonNullable<V>, K>

interface UseSortArgs<OrderByField extends string, SearchParams> {
  navigate: (arg: {search: (args: SearchParams) => void}) => void
  sorting: SortingState
  resetSorting: () => void
  setSorting: (state: SortingState) => void
  orderBy: {field: OrderByField; direction: SortDirection} | undefined
  sortColumnMap: Partial<Record<OrderByField, string>>
  sortParamKey?: string
  dirKey?: string
  cursorKey?: string
}

export const useSort = <OrderByField extends string, SearchParams>({
  sorting,
  setSorting,
  resetSorting,
  orderBy,
  navigate,
  sortColumnMap,
  sortParamKey = "orderBy",
  dirKey = "dir",
  cursorKey = "cursor",
}: UseSortArgs<OrderByField, SearchParams>) => {
  const [viewMenuOpen, toggleViewMenuOpen] = useToggle()
  const order = useMemo(
    () =>
      pipe(
        sorting,
        A.head,
        O.flatMap(({id, desc}) => {
          const field = flipKeyValues(sortColumnMap)[id]
          return field == null
            ? O.None
            : O.Some<NonNullable<typeof orderBy>>({
                direction: desc ? SortDirection.Desc : SortDirection.Asc,
                field,
              })
        }),
        O.match(
          (order) => order,
          () => undefined,
        ),
      ),
    [sortColumnMap, sorting],
  )

  const [orderByInitialised, setOrderByInitialised] = useState(false)

  const updateOrderBy = useCallback(
    (order: typeof orderBy) => {
      if (order == null) {
        resetSorting()
      } else {
        const id = sortColumnMap[order.field]
        if (id != null) {
          setSorting([
            {
              id,
              desc: order.direction === SortDirection.Desc,
            },
          ])
        }
      }
    },
    [setSorting, resetSorting, sortColumnMap],
  )

  const updateOrderByFromViewMenu = useCallback(
    (order: typeof orderBy) => {
      updateOrderBy(order)
      toggleViewMenuOpen()
    },
    [updateOrderBy, toggleViewMenuOpen],
  )

  useEffect(() => {
    if (!orderByInitialised) {
      updateOrderBy(orderBy)
    }
    setOrderByInitialised(true)
  }, [orderBy, orderByInitialised, updateOrderBy])

  useEffect(() => {
    if (!F.equals(order, orderBy) && orderByInitialised) {
      navigate({
        search: (prev) => ({
          ...prev,
          [sortParamKey]: order,
          [cursorKey]: undefined,
          [dirKey]: undefined,
        }),
      })
    }
  }, [
    orderBy,
    order,
    updateOrderBy,
    navigate,
    orderByInitialised,
    sortParamKey,
    dirKey,
    cursorKey,
  ])

  return {
    order,
    updateOrderBy,
    viewMenuOpen,
    toggleViewMenuOpen,
    updateOrderByFromViewMenu,
  }
}
