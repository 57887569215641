import create from "zustand"
import type {NotificationActionProps} from "./notification/notification"

export interface AlertSnack {
  type: "alert"
  data: {
    severity: "error"
    text: string
    body?: string
  }
}

export interface ActionSnack {
  type: "action"
  data: {
    text: string
    body?: string
    action?: NotificationActionProps
  }
}

export type Snack = AlertSnack | ActionSnack

export type SetSnack = (snack: Snack | null) => void

interface SnackbarState {
  snack: Snack | null
  setSnack: SetSnack
}

export const useSnackbarStore = create<SnackbarState>((set) => ({
  snack: null,
  setSnack: (snack) => {
    set({snack})
  },
}))
