import {useCallback} from "react"
import {type DateRange} from "react-day-picker"
import {useFormContext} from "react-hook-form"
import type {LocalDateFilter} from "src/features/filters/components/date-filter/schema"
import {FilterTitle} from "src/features/filters/components/modal/filter-title"
import {withTestId} from "src/utils/with-test-id"
import {AlertCircle} from "@hortis/ui/icons"
import {Button} from "@hortis/ui/button"
import {Calendar} from "@hortis/ui/calendar"
import {Select, type SelectOption} from "../select"
import {Alert} from "../untitled/alert"
import {DatePresets} from "./utils"

interface mobileProps {
  title: string
  handleSelectRange: (range: DateRange | undefined) => void
  currentPreset: DatePresets | undefined
  handleCancelButtonClick: () => void
  handleApplyButtonClick: () => void
  shouldShowAlert: boolean
  onPresetSelected: (preset: DatePresets) => void
  deleteFilter?: () => void
}

const selectOptions: Array<SelectOption<DatePresets>> = Object.keys(
  DatePresets,
).map((key) => ({
  value: DatePresets[key as keyof typeof DatePresets],
  label: DatePresets[key as keyof typeof DatePresets],
}))

export const DateComparatorFilterMobile = withTestId<mobileProps>(
  ({
    handleSelectRange,
    title,
    currentPreset,
    handleCancelButtonClick,
    handleApplyButtonClick,
    shouldShowAlert,
    onPresetSelected,
    deleteFilter,
  }) => {
    const {watch} = useFormContext<LocalDateFilter>()
    const value = watch()

    const handleSelectOption = useCallback(
      (value: DatePresets | null): void => {
        if (value) {
          onPresetSelected(value)
        }
      },
      [onPresetSelected],
    )

    return (
      <div className="flex flex-col rounded-lg border border-grey-100 ">
        <div className="flex items-center justify-between p-4 pb-0">
          <FilterTitle title={title} deleteFilter={deleteFilter} />
        </div>
        <div className="flex flex-col gap-3 p-4 pt-2.5">
          {shouldShowAlert && (
            <Alert
              testId={"date-filter-alert"}
              color="error"
              innerClassName="p-0 gap-0"
              contentContainerClassName="gap-0 items-center w-full "
              className=" flex h-9  flex-row items-center gap-0  p-4"
              icon={AlertCircle}
              title={`Select a date to continue`}
            />
          )}
          <Select
            options={selectOptions}
            onChange={handleSelectOption}
            value={currentPreset ?? null}
            testId="date-filter-presets-mobile-menu"
            placeholder="Select a preset"
            className="w-full"
            placement="auto"
          />
        </div>
        <Calendar
          selected={{
            from: value.from ?? undefined,
            to: value.to ?? undefined,
          }}
          onSelect={handleSelectRange}
          mode="range"
          classNames={{
            month: "py-0",
          }}
        />
        <div className="flex gap-3 p-4">
          <Button
            className="h-full w-full"
            type="button"
            onClick={handleCancelButtonClick}
            testId="date-filter-cancel-button"
          >
            Cancel
          </Button>
          <Button
            type="button"
            variant="primary"
            className="h-full w-full"
            onClick={handleApplyButtonClick}
            testId="date-filter-apply-button"
          >
            Apply
          </Button>
        </div>
      </div>
    )
  },
)
