import {
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandLoading,
} from "@hortis/ui/command"
import {A, pipe} from "@mobily/ts-belt"
import {useParams, useRouteContext} from "@tanstack/react-router"
import {
  useGetLocationsQuery,
  useUpdatePlantMaterialsMutation,
} from "generated/graphql"
import {useState} from "react"
import {useSnackbarStore} from "src/components/snackbar-controller/snackbar-store"
import {
  onFailure,
  onSuccess as onSuccessSnack,
} from "src/notification-snack-utils"

interface CommandMenuLocationPageProps {
  selectedMaterials: ReadonlyArray<{id: string; locationId?: string}>
  onSuccess: () => void
}

const CommandMenuLocationPage = ({
  selectedMaterials,
  onSuccess,
}: CommandMenuLocationPageProps) => {
  const {subdomain} = useRouteContext({from: "/_layout/sites/$siteSlug"})
  const {siteSlug} = useParams({from: "/_layout/sites/$siteSlug"})
  const {setSnack} = useSnackbarStore()
  const [_, updatePlantMaterials] = useUpdatePlantMaterialsMutation()
  const [loading, setLoading] = useState<string | false>(false)
  const [{data, fetching}] = useGetLocationsQuery({
    variables: {
      siteSlug,
      subdomain,
    },
  })
  const locations = data?.org?.site?.locationsConnection?.nodes

  const handleLocationSelect = async (locationId: string) => {
    const ids = pipe(
      selectedMaterials,
      A.filter((m) => m.locationId !== locationId),
      A.map(({id}) => id),
    )
    if (ids.length === 0) {
      onSuccess()
      return
    }
    setLoading(locationId)
    const res = await updatePlantMaterials({
      ids,
      input: {
        collectionSiteLocationId: locationId,
      },
    })
    setLoading(false)
    if (res.data?.updatePlantMaterials.success !== true) {
      onFailure(setSnack)(new Error("Failed to update materials"))
      return
    }
    onSuccessSnack(setSnack)(
      `Successfully updated ${
        res.data.updatePlantMaterials.plantMaterials.length
      } material${
        res.data.updatePlantMaterials.plantMaterials.length > 1 ? "s" : ""
      }`,
    )
    onSuccess()
  }

  return (
    <>
      {fetching && locations == null && <CommandLoading />}
      <CommandGroup data-cy="command-menu-location-page">
        {locations?.map((location) => (
          <CommandItem
            key={location.id}
            onSelect={() => handleLocationSelect(location.id)}
            loading={loading === location.id}
            disabled={loading !== false}
            data-cy={`${location.name}-location-menu-item`}
          >
            {location.name}
            <span className="font-normal text-grey-500">
              {location.description}
            </span>
          </CommandItem>
        ))}
      </CommandGroup>
      <CommandEmpty>No results</CommandEmpty>
    </>
  )
}

export {CommandMenuLocationPage}
