import {Badge} from "@hortis/ui/badge"
import {Button} from "@hortis/ui/button"
import {Dialog, DialogTrigger} from "@hortis/ui/dialog"
import {ClockStopwatch, File6, Tag1} from "@hortis/ui/icons"
import {IUCNRedListTag} from "@hortis/ui/iucn-red-list-tag"
import {createColumnHelper} from "@tanstack/react-table"
import {type MaterialsListFieldsFragment} from "generated/graphql"
import {colors} from "src/colors"
import {RestoreMaterialDialogContent} from "src/components/dialog/variants/restore-material"
import {isOverdueFollowup} from "src/components/modals/make-observation/check-overdue-followup"
import {PlantMaterialStatusToColor} from "src/components/status-chip"
import {createMaterialNumber} from "src/features/collection/components/plant-materials/material-number"
import {stopPropagation} from "src/features/collection/components/stop-event-propagation"
import {conditionLabelMap} from "src/utils/label-maps/condition"
import {materialGroupLabelMap} from "src/utils/label-maps/material-group"
import {materialStatusLabelMap} from "src/utils/label-maps/material-status"
import {materialSexLabelMap} from "src/utils/label-maps/material-sex"
import {DataTableCheckboxCell} from "../data-table-checkbox-cell"
import {DataTableCheckboxHeader} from "../data-table-checkbox-header"
import {DataTableColumnHeader} from "../data-table-column-header"
import {DataTableNullableCell} from "../data-table-nullable-cell"
import {DataTablePreviewCell} from "../data-table-preview-cell"
import {DataTableTagsCell} from "../data-table-tags-cell"
import {DataTableMemberCell} from "../data-table-member-cell"
import {DataTablePhotoCell} from "./photo-cell"
import {ProvenanceCell} from "./provenance-cell"

export type MaterialsColumnIds =
  | "Number"
  | "Photos"
  | "Scientific name"
  | "Common name"
  | "Provenance"
  | "IUCN Red List"
  | "Native distribution"
  | "Life forms"
  | "Climate"
  | "Material type"
  | "Status"
  | "Location"
  | "Quantity"
  | "Sex"
  | "Tag number"
  | "Donor / supplier"
  | "Date added"
  | "Date removed"
  | "Last observed"
  | "Days overdue"
  | "Condition"
  | "Plant material tags"
  | "Public"
  | "Last edited"
  | "Creator"
  | "Last editor"
  | "Notes"

const columnHelper = createColumnHelper<MaterialsListFieldsFragment>()

export const baseColumns = [
  columnHelper.accessor("images", {
    id: "Photos" satisfies MaterialsColumnIds,
    header: ({column}) => <DataTableColumnHeader column={column} />,
    cell: ({row}) => <DataTablePhotoCell row={row} />,
    minSize: 92,
    maxSize: 92,
    enableSorting: false,
  }),
  columnHelper.accessor("accession.scientificName", {
    id: "Scientific name" satisfies MaterialsColumnIds,
    header: ({column}) => <DataTableColumnHeader column={column} />,
    cell: (info) => (
      <div className="truncate font-medium text-grey-900">
        {info.getValue()}
      </div>
    ),
    minSize: 164,
    maxSize: 344,
    enableHiding: false,
    enableColumnFilter: false,
  }),
  columnHelper.accessor("accession.taxon.commonName", {
    id: "Common name" satisfies MaterialsColumnIds,
    header: ({column}) => <DataTableColumnHeader column={column} />,
    cell: ({getValue}) => <DataTableNullableCell value={getValue()} />,
    minSize: 164,
    maxSize: 344,
  }),
  columnHelper.accessor("accession.provenanceCategory", {
    id: "Provenance" satisfies MaterialsColumnIds,
    header: ({column}) => <DataTableColumnHeader column={column} />,
    cell: ({getValue}) => <ProvenanceCell value={getValue()} />,
    minSize: 116,
    maxSize: 218,
  }),
  columnHelper.accessor(
    "accession.taxon.sharedScientificName.taxon.globalRedListStatus",
    {
      id: "IUCN Red List" satisfies MaterialsColumnIds,
      header: ({column}) => <DataTableColumnHeader column={column} />,
      cell: ({getValue, row}) => {
        const fullyValidated = row.original.accession?.taxon?.fullyValidated
        const value = getValue()
        return (
          <DataTableNullableCell value={fullyValidated === true ? value : null}>
            {value == null ? null : <IUCNRedListTag status={value} />}
          </DataTableNullableCell>
        )
      },
      minSize: 164,
      maxSize: 344,
    },
  ),
  columnHelper.accessor("accession.taxon.nativeDistribution", {
    id: "Native distribution" satisfies MaterialsColumnIds,
    header: ({column}) => <DataTableColumnHeader column={column} />,
    cell: ({getValue, row, table}) => {
      const fullyValidated = row.original.accession?.taxon?.fullyValidated
      const sharedNativeDistribution =
        row.original.accession?.taxon?.sharedScientificName?.taxon
          ?.nativeDistribution
      const interpretationEnabled =
        table.options.meta?.flags?.["taxaInterpretationEnabled"]

      const value = getValue()
      return (
        <DataTableNullableCell
          value={
            value == null || interpretationEnabled !== true
              ? fullyValidated === true
                ? sharedNativeDistribution
                : null
              : value
          }
        />
      )
    },
    minSize: 164,
    maxSize: 344,
    enableSorting: false,
  }),
  columnHelper.accessor(
    "accession.taxon.sharedScientificName.taxon.lifeForms",
    {
      id: "Life forms" satisfies MaterialsColumnIds,
      header: ({column}) => <DataTableColumnHeader column={column} />,
      cell: ({getValue, row}) => {
        const fullyValidated = row.original.accession?.taxon?.fullyValidated
        const value = getValue()
        const nodes = value?.nodes
        return (
          <DataTableNullableCell
            value={
              fullyValidated === true && nodes != null && nodes.length > 0
                ? nodes.map((lifeForm) => lifeForm.name).join(", ")
                : null
            }
          />
        )
      },
      minSize: 164,
      maxSize: 344,
      enableSorting: false,
    },
  ),
  columnHelper.accessor("accession.taxon.sharedScientificName.taxon.climate", {
    id: "Climate" satisfies MaterialsColumnIds,
    header: ({column}) => <DataTableColumnHeader column={column} />,
    cell: ({getValue, row}) => {
      const fullyValidated = row.original.accession?.taxon?.fullyValidated
      return (
        <DataTableNullableCell
          value={fullyValidated === true ? getValue() : null}
        />
      )
    },
    minSize: 164,
    maxSize: 344,
    enableSorting: false,
  }),
  columnHelper.accessor("materialGroup", {
    id: "Material type" satisfies MaterialsColumnIds,
    header: ({column}) => <DataTableColumnHeader column={column} />,
    cell: ({getValue}) => materialGroupLabelMap[getValue()],
    minSize: 126,
    maxSize: 126,
  }),
  columnHelper.accessor("status", {
    id: "Status" satisfies MaterialsColumnIds,
    header: ({column}) => <DataTableColumnHeader column={column} />,
    cell: ({getValue}) => (
      <Badge
        type="dot"
        testId="status-chip"
        color={PlantMaterialStatusToColor[getValue()]}
      >
        {materialStatusLabelMap[getValue()]}
      </Badge>
    ),
    minSize: 136,
    maxSize: 136,
  }),
  columnHelper.accessor("collectionSiteLocation", {
    id: "Location" satisfies MaterialsColumnIds,
    header: ({column}) => <DataTableColumnHeader column={column} />,
    cell: ({getValue}) => (
      <div className="truncate text-sm">
        <div className="truncate text-grey-900">{getValue()?.name}</div>
        <div className="truncate">{getValue()?.description ?? "-"}</div>
      </div>
    ),
    minSize: 160,
    maxSize: 200,
  }),
  columnHelper.accessor(
    (row) =>
      row.quantity == null ? (row.massPlanting ? "Mass" : null) : row.quantity,
    {
      id: "Quantity" satisfies MaterialsColumnIds,
      header: ({column}) => <DataTableColumnHeader column={column} />,
      cell: ({getValue}) => <DataTableNullableCell value={getValue()} />,
      minSize: 118,
      maxSize: 178,
    },
  ),

  columnHelper.accessor("sex", {
    id: "Sex" satisfies MaterialsColumnIds,
    header: ({column}) => <DataTableColumnHeader column={column} />,
    cell: ({getValue}) => {
      const value = getValue()
      return value == null ? "-" : materialSexLabelMap[value]
    },
    minSize: 164,
    maxSize: 244,
    enableSorting: false,
  }),

  columnHelper.accessor("tagNumber", {
    id: "Tag number" satisfies MaterialsColumnIds,
    header: ({column}) => <DataTableColumnHeader column={column} />,
    cell: ({getValue}) => <DataTableNullableCell value={getValue()} />,
    minSize: 164,
    maxSize: 244,
    enableSorting: false,
  }),
  columnHelper.accessor("accession.donor", {
    id: "Donor / supplier" satisfies MaterialsColumnIds,
    header: ({column}) => <DataTableColumnHeader column={column} />,
    cell: ({getValue}) => <DataTableNullableCell value={getValue()} />,
    minSize: 164,
    maxSize: 244,
  }),
  columnHelper.accessor("firstPresent", {
    id: "Date added" satisfies MaterialsColumnIds,
    header: ({column}) => <DataTableColumnHeader column={column} />,
    cell: ({getValue, table}) => {
      const value = getValue()
      return value == null
        ? "-"
        : table.options.meta?.formatDateTime(value) ?? "-"
    },
    enableSorting: false,
  }),
  columnHelper.accessor("firstAbsent", {
    id: "Date removed" satisfies MaterialsColumnIds,
    header: ({column}) => <DataTableColumnHeader column={column} />,
    cell: ({getValue, table}) => {
      const value = getValue()
      return value == null
        ? "-"
        : table.options.meta?.formatDateTime(value) ?? "-"
    },
    enableSorting: false,
  }),
  columnHelper.accessor("daysOverdueFollowUp", {
    id: "Days overdue" satisfies MaterialsColumnIds,
    meta: {
      invisible: true,
    },
  }),
  columnHelper.accessor("lastObserved", {
    id: "Last observed" satisfies MaterialsColumnIds,
    header: ({column, table}) => {
      const altColumn = table.getColumn("Days overdue")
      return (
        <div className="flex items-center gap-3">
          <div className="w-5" />
          <DataTableColumnHeader column={column} altColumn={altColumn} />
        </div>
      )
    },
    cell: ({getValue, row, table}) => {
      const value = getValue()
      const followUp = row.original.followUp
      const daysOverdue = row.original.daysOverdueFollowUp
      return (
        <div className="flex items-center gap-3 text-sm">
          {value != null &&
          followUp != null &&
          isOverdueFollowup(new Date(value), followUp) ? (
            <ClockStopwatch size="20px" color={colors.warning[500]} />
          ) : (
            <div className="h-5 w-5" />
          )}
          <div>
            <div>
              {value == null ? "-" : table.options.meta?.formatDateTime(value)}
            </div>
            {daysOverdue != null && daysOverdue > 0 && (
              <div className="text-warning-600">
                {daysOverdue} day{daysOverdue === 1 ? "" : "s"} overdue
              </div>
            )}
          </div>
        </div>
      )
    },
  }),
  columnHelper.accessor("condition", {
    id: "Condition" satisfies MaterialsColumnIds,
    header: ({column}) => <DataTableColumnHeader column={column} />,
    cell: ({getValue}) => {
      const value = getValue()
      return value == null ? "-" : conditionLabelMap[value]
    },
    minSize: 120,
    enableSorting: false,
  }),
  columnHelper.accessor("tagsConnection.nodes", {
    id: "Plant material tags" satisfies MaterialsColumnIds,
    header: ({column}) => <DataTableColumnHeader column={column} />,
    cell: ({getValue}) => (
      <DataTableNullableCell value={getValue()}>
        <DataTablePreviewCell
          title="Tags"
          icon={Tag1}
          popoverContent={<DataTableTagsCell tags={getValue()} wrap />}
        >
          <DataTableTagsCell tags={getValue()} />
        </DataTablePreviewCell>
      </DataTableNullableCell>
    ),
    enableSorting: false,
    minSize: 140,
    maxSize: 280,
  }),
  columnHelper.accessor("public", {
    id: "Public" satisfies MaterialsColumnIds,
    header: ({column}) => <DataTableColumnHeader column={column} />,
    cell: ({getValue}) => (
      <DataTableNullableCell value={getValue() ? "Public" : "Private"} />
    ),
    minSize: 100,
    enableSorting: false,
  }),
  columnHelper.accessor("createdBy", {
    id: "Creator",
    header: ({column}) => <DataTableColumnHeader column={column} />,
    cell: ({getValue}) => <DataTableMemberCell member={getValue()} />,
    enableSorting: false,
    minSize: 128,
    maxSize: 178,
  }),
  columnHelper.accessor("updatedBy", {
    id: "Last editor",
    header: ({column}) => <DataTableColumnHeader column={column} />,
    cell: ({getValue}) => <DataTableMemberCell member={getValue()} />,
    enableSorting: false,
    minSize: 128,
    maxSize: 178,
  }),
  columnHelper.accessor("updatedAt", {
    id: "Last edited" satisfies MaterialsColumnIds,
    header: ({column}) => <DataTableColumnHeader column={column} />,
    cell: ({getValue, table}) => {
      const value = getValue()
      return table.options.meta == null
        ? "-"
        : table.options.meta.formatDateTime(value)
    },
    enableColumnFilter: false,
    minSize: 128,
    maxSize: 178,
  }),
  columnHelper.accessor("notes", {
    id: "Notes" satisfies MaterialsColumnIds,
    header: ({column}) => <DataTableColumnHeader column={column} />,
    cell: ({getValue}) => (
      <DataTableNullableCell value={getValue()}>
        <DataTablePreviewCell value={getValue()} title="Notes" icon={File6} />
      </DataTableNullableCell>
    ),
    minSize: 100,
    enableSorting: false,
  }),
]
export const materialColumns = [
  columnHelper.accessor(
    (row) =>
      createMaterialNumber(
        row.accession?.accessionNumber ?? "",
        row.qualifier,
        "/",
      ),
    {
      id: "Number" satisfies MaterialsColumnIds,
      header: ({table, column}) => (
        <DataTableCheckboxHeader table={table}>
          <DataTableColumnHeader column={column} className="min-w-[90px]" />
        </DataTableCheckboxHeader>
      ),
      cell: ({row, table, getValue}) => (
        <DataTableCheckboxCell table={table} row={row}>
          <div
            className="truncate font-medium text-grey-900"
            data-cy="material-number"
          >
            {getValue()}
          </div>
        </DataTableCheckboxCell>
      ),
      meta: {
        sticky: true,
      },
      enableHiding: false,
      enableColumnFilter: false,
      minSize: 156,
    },
  ),
  ...baseColumns,
]

export const trashMaterialColumns = [
  columnHelper.accessor(
    (row) =>
      createMaterialNumber(
        row.accession?.accessionNumber ?? "",
        row.qualifier,
        "/",
      ),
    {
      id: "Number" satisfies MaterialsColumnIds,
      header: ({column}) => (
        <DataTableColumnHeader column={column} className="min-w-[90px]" />
      ),
      cell: ({getValue, row}) => {
        const materialNumber = getValue()
        return (
          <div
            className="flex gap-4 truncate font-medium text-grey-900"
            data-cy="material-number"
          >
            <div>{materialNumber}</div>
            <Dialog>
              <DialogTrigger asChild onClick={stopPropagation}>
                <Button
                  variant="linkcolor"
                  size="sm"
                  className="w-full"
                  testId="restore-material-button"
                >
                  Restore
                </Button>
              </DialogTrigger>
              <RestoreMaterialDialogContent id={row.id} />
            </Dialog>
          </div>
        )
      },
      meta: {
        sticky: true,
      },
      enableHiding: false,
      enableColumnFilter: false,
      minSize: 156,
    },
  ),
  ...baseColumns,
]
