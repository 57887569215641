import {A, D, pipe} from "@mobily/ts-belt"
import {useGetMeWithSitesQuery} from "generated/graphql"
import {useCallback} from "react"
import {useFormContext} from "react-hook-form"
import {Button, IconButton} from "@hortis/ui/button"
import {BasicDatePicker} from "src/components/date-picker"
import {Plus, XClose} from "@hortis/ui/icons"
import {FormSelect, type SelectOption} from "src/components/select"
import {FormTextField} from "src/components/text-field"
import {createFullName} from "src/utils/full-name"
import {useCollectionSiteSettings} from "src/utils/hooks/collection-site-from-url"
import {
  identificationQualifierLabelMap,
  identificationVerificationStatusMap,
} from "src/utils/label-maps/identification"
import type {DeepNullable} from "ts-essentials"
import type {AccessionDataUpdatesZod} from "../schema"

type FormValues = DeepNullable<
  Pick<AccessionDataUpdatesZod, "identificationDetails">
>

export const IdentificationFields = () => {
  const [{data}] = useGetMeWithSitesQuery()
  const {identificationFieldsEnabled} = useCollectionSiteSettings()
  const {watch, setValue} = useFormContext<FormValues>()
  const identificationDetails = watch("identificationDetails")
  const me = data?.me

  const initialiseIdentificationDetails = useCallback(() => {
    setValue("identificationDetails", {
      identifiedBy: me == null ? null : createFullName(me),
      dateIdentified: new Date(),
      identificationVerificationStatus: null,
      identificationQualifier: null,
      identificationRemarks: null,
    })
  }, [setValue, me])

  const clearIdentificationDetails = useCallback(() => {
    setValue("identificationDetails", null)
  }, [setValue])

  return identificationFieldsEnabled || identificationDetails != null ? (
    <div>
      {identificationDetails == null ? (
        <Button
          startIcon={Plus}
          onClick={initialiseIdentificationDetails}
          testId="add-identification-details"
        >
          Add identification details
        </Button>
      ) : (
        <div className="flex flex-col gap-8 rounded-lg border border-grey-200 bg-grey-50 px-4 pb-6 pt-4 shadow-xs sm:px-6 sm:pb-8 sm:pt-6">
          <div className="relative flex items-center justify-between gap-3 font-medium text-grey-900 lg:text-lg">
            Identification details
            <IconButton
              ariaLabel="Remove identification fields"
              onClick={clearIdentificationDetails}
              icon={XClose}
              variant="tertiaryGray"
              className="absolute right-[-4px]"
              testId="remove-identification-details"
            />
          </div>
          <FormTextField
            fullWidth
            name="identificationDetails.identifiedBy"
            id="identified-by"
            label="Identified by"
            testId="identified-by"
            required
          />
          <BasicDatePicker
            label="Date identified"
            testId="date-identified"
            name="identificationDetails.dateIdentified"
            required
          />
          <FormSelect
            name="identificationDetails.identificationVerificationStatus"
            id="identification-verification-status"
            label="Verification status"
            testId="identification-verification-status"
            options={pipe(
              identificationVerificationStatusMap,
              D.toPairs,
              A.map(([value, label]) => ({
                value,
                label,
                supportText: `(${value})`,
              })),
            )}
            required
          />
          <FormSelect
            name="identificationDetails.identificationQualifier"
            id="identification-qualifier"
            label="Identification qualifier"
            testId="identification-qualifier"
            options={pipe(
              identificationQualifierLabelMap,
              D.toPairs,
              A.map(([value, label]) => ({
                value,
                label,
                supportText: `(${value})`,
              })),
              A.prepend<SelectOption<string>>({value: null, label: "None"}),
            )}
          />
          <FormTextField
            fullWidth
            name="identificationDetails.identificationRemarks"
            id="identification-remarks"
            label="Identification remarks"
            multiline
            minRows={2}
            testId="identification-remarks"
          />
        </div>
      )}
    </div>
  ) : (
    <></>
  )
}
