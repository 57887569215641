import {useCallback} from "react"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Tail<A extends Array<any>> = A extends [unknown, ...infer Rest]
  ? Rest
  : never
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Head<A extends Array<any>> = A extends [infer Head, ...Array<unknown>]
  ? Head
  : never

export const useBindCallback = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Callback extends (...args: Array<any>) => any,
>(
  callback: Callback,
  ...args: Parameters<Callback>
): (() => ReturnType<Callback>) =>
  // eslint-disable-next-line react-hooks/exhaustive-deps, @typescript-eslint/no-unsafe-return
  useCallback(() => callback(...args), [callback, ...args])

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createBindCallback = <Args extends Array<any>>(...args: Args) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function useBindCallback<Callback extends (...args: Args) => any>(
    callback: Callback,
  ): () => ReturnType<Callback> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return useCallback(() => callback(...args), [callback])
  }

export const createBindCallbackWithReturnAnnotation: <Result>() => <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Args extends Array<any>,
>(
  ...args: Args
) => (callback: (...args: Args) => Result) => () => Result = () =>
  createBindCallback

export const createBindCallbackWithVoidReturn =
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  createBindCallbackWithReturnAnnotation<void>()

export const useContramapCallback = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Contramapper extends (...args: Array<any>) => any,
  Result,
>(
  contramap: Contramapper,
  callback: (val: ReturnType<Contramapper>) => Result,
): ((...args: Parameters<Contramapper>) => Result) =>
  useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any
    (...args: any) => callback(contramap(...args)),
    [contramap, callback],
  )

export const createContramapCallback = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Contramapper extends (...args: Array<any>) => any,
>(
  contramap: Contramapper,
) =>
  function useContramapCallback<Result>(
    callback: (val: ReturnType<Contramapper>) => Result,
  ): (...args: Parameters<Contramapper>) => Result {
    return useCallback(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any
      (...args: any) => callback(contramap(...args)),
      [callback],
    )
  }

export const createContramapCallbackWithReturnAnnotation: <Result>() => <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Contramapper extends (...args: Array<any>) => any,
>(
  contramap: Contramapper,
) => (
  callback: (val: ReturnType<Contramapper>) => Result,
) => (...args: Parameters<Contramapper>) => Result = () =>
  createContramapCallback

export const createContramapCallbackWithVoidReturn =
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  createContramapCallbackWithReturnAnnotation<void>()

/**
 * @example
 * ```typescript
 * const wrapCallback = (fn: (a: number) => Date, b: string, y: boolean) =>
 *   b.length > 5 && y ? fn(42) : "arbitrary",
 * )
 * // render function
 * const handleClick = useWrapCallback(wrapCallback, myCallback)
 * // type of `handleClick` is (b: string, y: boolean) => Date | "arbitrary"
 * ```
 */
export const useWrapCallback = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Callback extends (...args: Array<any>) => any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Wrapper extends (fn: Callback, ...args: Array<any>) => any,
>(
  callback: Callback,
  wrapper: Wrapper,
): ((...args: Tail<Parameters<Wrapper>>) => ReturnType<Wrapper>) =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument
  useCallback((...args: any) => wrapper(callback, ...args), [callback, wrapper])

/**
 * @example
 * ```typescript
 * const useWrapper = createWrapCallback((fn: (a: number) => Date, b: string, y: boolean) =>
 *   b.length > 5 && y ? fn(42) : "arbitrary",
 * )
 * // render function
 * const handleClick = useWrapper(myCallback)
 * // type of `handleClick` is (b: string, y: boolean) => Date | "arbitrary"
 * ```
 */
export const createWrapCallback =
  <
    Wrapper extends (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      fn: (...args: Array<any>) => any,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...args: Array<any>
    ) => // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any,
  >(
    wrapper: Wrapper,
  ) =>
  (
    callback: Head<Parameters<Wrapper>>,
  ): ((...args: Tail<Parameters<Wrapper>>) => ReturnType<Wrapper>) =>
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument
    useCallback((...args: any) => wrapper(callback, ...args), [callback])
