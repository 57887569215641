import {zodResolver} from "@hookform/resolvers/zod"
import {Button} from "@hortis/ui/button"
import {
  SheetBody,
  SheetDescription,
  SheetFooter,
  SheetForm,
  SheetHeader,
  SheetTitle,
} from "@hortis/ui/sheet"
import * as E from "fp-ts/Either"
import * as TE from "fp-ts/TaskEither"
import {pipe} from "fp-ts/function"
import {useEffect, useMemo} from "react"
import {FormProvider, useForm} from "react-hook-form"
import {materialErrorMap} from "src/features/create-accession/components/add-plant-material-form/error-map"
import type {PlantMaterialSchemaZodInput} from "src/features/create-accession/components/add-plant-material-form/schema"
import {PlantMaterialSchemaZod} from "src/features/create-accession/components/add-plant-material-form/schema"
import {processPlantMaterialFormData} from "src/features/create-accession/utils/process-form-data"
import {parseUtcDate} from "src/utils/dates/parse-utc-date"
import type {MaterialFieldsFragment} from "../../../../../../../generated/graphql"
import {useUpdatePlantMaterialMutation} from "../../../../../../../generated/graphql"
import {useSnackbarStore} from "../../../../../../components/snackbar-controller/snackbar-store"
import type {PlantMaterialDataFieldsProps} from "../../../../../create-accession/components/add-plant-material-form/plant-material-data-fields"
import {PlantMaterialDataFields} from "../../../../../create-accession/components/add-plant-material-form/plant-material-data-fields"
import {
  onUpdateAccessionFailure,
  onUpdateAccessionSuccess,
} from "../../../../accession/components/edit-drawer/utils"
import type {BaseEditStageProps} from "../types"

export const extractMaterialDetailsFormFields = (
  material: MaterialFieldsFragment,
): Partial<PlantMaterialSchemaZodInput> => ({
  materialQualifier: `${material.qualifier}`,
  firstPresent:
    material.firstPresent == null
      ? undefined
      : parseUtcDate(material.firstPresent),
  firstAbsent:
    material.firstAbsent == null
      ? undefined
      : parseUtcDate(material.firstAbsent),
  quantity: material.quantity ?? null,
  weight: material.weight ?? null,
  notes: material.notes ?? null,
  massPlanting: material.massPlanting,
  sex: material.sex ?? null,
  tagNumber: material.tagNumber ?? null,
  materialGroup: material.materialGroup,
  status: material.status,
  collectionSiteLocationOption: material.collectionSiteLocation ?? undefined,
  position:
    material.position == null
      ? null
      : {
          latitude: String(material.position.latitude),
          longitude: String(material.position.longitude),
          elevation:
            material.position.elevation == null
              ? null
              : String(material.position.elevation),
        },
  followUp:
    material.followUp == null
      ? undefined
      : {count: material.followUp.count, period: material.followUp.period},
  condition: material.condition ?? null,
  lastObserved:
    material.lastObserved == null
      ? undefined
      : parseUtcDate(material.lastObserved),
  tags: material.tagsConnection?.nodes.map(({id, name}) => ({id, name})) ?? [],
})

const resolver = zodResolver(PlantMaterialSchemaZod, {
  errorMap: materialErrorMap,
})

export type EditMaterialDetailsProps = Omit<
  BaseEditStageProps,
  "transitionStage"
> & {onSubmit: () => void; open: boolean} & {
  title?: string
  subtitle?: string
} & Pick<
    PlantMaterialDataFieldsProps,
    "hidePositionFields" | "hideObservationFields"
  >

export const EditMaterialSheetForm = ({
  material,
  requestClose,
  onSubmit,
  hideObservationFields,
  hidePositionFields,
  open,
  title,
  subtitle,
}: EditMaterialDetailsProps) => {
  const {setSnack} = useSnackbarStore()
  const [_, updatePlantMaterial] = useUpdatePlantMaterialMutation()
  const formMethods = useForm<PlantMaterialSchemaZodInput>({
    defaultValues: extractMaterialDetailsFormFields(material),
    criteriaMode: "all",
    resolver,
  })

  useEffect(() => {
    formMethods.reset(extractMaterialDetailsFormFields(material))
  }, [formMethods, open, material])

  const handleSubmit = useMemo(
    () =>
      formMethods.handleSubmit((data) =>
        pipe(
          TE.tryCatch(async () => {
            const res = await updatePlantMaterial({
              materialId: material.id,
              updates: processPlantMaterialFormData(
                data as PlantMaterialSchemaZod,
              ),
            })

            if (res.data?.updatePlantMaterial?.__typename === "PlantMaterial") {
              return res
            }
            throw new Error("Failed to update plant material")
          }, E.toError),
          TE.matchW(
            onUpdateAccessionFailure(setSnack),
            onUpdateAccessionSuccess(
              setSnack,
              "Material details updated",
              () => {
                onSubmit()
                requestClose()
              },
            ),
          ),
        )(),
      ),
    [
      formMethods,
      material,
      setSnack,
      onSubmit,
      requestClose,
      updatePlantMaterial,
    ],
  )

  return (
    <FormProvider {...formMethods}>
      <SheetForm onSubmit={handleSubmit}>
        <SheetHeader>
          <SheetTitle>{title ?? "Details"}</SheetTitle>
          <SheetDescription>
            {subtitle ?? "Edit material details"}
          </SheetDescription>
        </SheetHeader>

        <SheetBody>
          <PlantMaterialDataFields
            hidePositionFields={hidePositionFields}
            hideObservationFields={hideObservationFields}
          />
        </SheetBody>
        <SheetFooter>
          <Button testId="cancel-button" onClick={requestClose}>
            Cancel
          </Button>
          <Button
            testId="confirm-button"
            loading={formMethods.formState.isSubmitting}
            type="submit"
            variant="primary"
          >
            Save
          </Button>
        </SheetFooter>
      </SheetForm>
    </FormProvider>
  )
}
