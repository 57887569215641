import {forwardRef} from "react"

type ListHeaderProps = {
  label: string
}

export const ListHeader = forwardRef<HTMLDivElement, ListHeaderProps>(
  function ListHeaderWithRef({label}, ref) {
    return (
      <div
        className="flex items-center border-y border-grey-200 bg-grey-50"
        ref={ref}
      >
        <div className="flex flex-1 items-center px-4 py-2 md:px-6">
          <span className="text-xs font-medium text-grey-500">{label}</span>
        </div>
      </div>
    )
  },
)
