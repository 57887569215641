import {useEffect, useState} from "react"
import Cookies from "js-cookie"

export const getSubdomain = () => {
  const isCypressTest = Cookies.get("cypress-is-running") === "true"
  if (isCypressTest) {
    return "cypress"
  }
  const hostname = window.location.hostname
  const parts = hostname.split(".")
  const subdomain = parts[0]
  return parts.length >= 3 && subdomain != null ? subdomain : null
}

export const useSubdomain = () => {
  const [subdomain, setSubdomain] = useState<string | null>(null)

  useEffect(() => {
    setSubdomain(getSubdomain())
  }, [])

  return subdomain
}
