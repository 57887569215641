import type {NullableUserFilter} from "generated/graphql"
import {countIdComparator} from "../count/comparators/id"

export const countUserFilter = (filter: NonNullable<NullableUserFilter>) => {
  let count = 0
  if (filter.id != null) {
    count += countIdComparator(filter.id)
  }
  if (filter.null != null) {
    count += 1
  }
  return count
}
