import {z} from "zod"

const tab = z
  .enum(["accession", "collection", "materials", "identification"])
  .optional()
  .catch("accession")
export type AccessionTab = z.infer<typeof tab>

export const accessionRouteSchema = z.object({
  tab,
})
