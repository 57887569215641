import type {HTMLAttributes} from "react"
import {Fragment} from "react"
import {twMerge} from "tailwind-merge"
import type {RecordTagFieldsFragment} from "generated/graphql"
import {check} from "src/components/select/check"
import {Typography} from "src/components/typography"
import {SlAddIcon} from "src/components/icons/streamline/add"

export const renderTagAutoCompleteItem = (
  props: HTMLAttributes<HTMLLIElement> & {"data-option-index"?: number},
  option: RecordTagFieldsFragment | null,
) => {
  const {...rest} = props
  const isAddOption = option?.id.includes("ADD_NEW") === true
  return option == null ? (
    <Fragment />
  ) : (
    <li key={option.id} {...rest} style={{padding: 0}}>
      <div
        className={twMerge(
          `flex flex-1 items-center justify-between gap-3 px-[14px] py-2.5`,
          isAddOption && props["data-option-index"] !== 0
            ? `border-t border-grey-200`
            : undefined,
        )}
      >
        <div className="flex items-center gap-3">
          {isAddOption && <SlAddIcon height="20" width="20" />}
          <Typography variant="body2" color="text.primary">
            {option.name}
          </Typography>
        </div>
        {props["aria-selected"] === true && check}
      </div>
    </li>
  )
}
