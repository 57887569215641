import {formatISO} from "date-fns"
import * as Struct from "./struct"

// Used to neutralise any timezone offsets for dates selected as purely dates
export function createDateAsUTC(date: Date) {
  return formatISO(date, {representation: "date"})
}

const dateToString = Struct.refTrans((val): val is Date => val instanceof Date)(
  createDateAsUTC,
)

export const convertDatesToISO = Struct.refMap(dateToString)
