import type {IconProps} from "./types"

export const SlSearchIcon = ({
  style,
  width,
  height,
  color,
  className,
}: IconProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width ?? "24"}
    height={height ?? "24"}
    viewBox="0 0 24 24"
    fill="none"
    style={style}
    className={className}
  >
    <path
      d="M4.5199 12.968C5.18816 14.5404 6.45368 15.783 8.03806 16.4223C9.62244 17.0616 11.3959 17.0454 12.9683 16.3771C14.5407 15.7089 15.7832 14.4433 16.4226 12.859C17.0619 11.2746 17.0457 9.50111 16.3774 7.92871C15.7091 6.35631 14.4436 5.11377 12.8592 4.47444C11.2749 3.83511 9.5014 3.85136 7.92899 4.51962C6.35659 5.18787 5.11405 6.45339 4.47473 8.03778C3.8354 9.62216 3.85165 11.3956 4.5199 12.968V12.968Z"
      stroke={color ?? "#424242"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.0034 15.0031L19.9996 20"
      stroke={color ?? "#424242"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
