import {gql} from "graphql-request"
export const CORE_ORGANISATION_FIELDS = gql`
  fragment MeOrganisationFields on Organisation {
    id
    name
    subdomain
    avatarUrl
    taxaInterpretationEnabled
  }
`
export const CORE_COLLECTION_SITES_FIELDS = gql`
  fragment MeCollectionSitesFields on CollectionSite {
    id
    name
    shortenedName
    urlSlug
    materialQualifierFormat
    mapCenterLongitude
    mapCenterLatitude
    mapZoomLevel
    public
    publicAbout
    publicWebsite
    publicContactEmail
    publicCountry {
      codeId
      name
      countryCodeAlpha2
    }
    publicCountryCode
    publicLocation
    members(filter: {deactivated: false}) {
      id
      initials
      avatarUrl
      email
    }
    ipenNumbersEnabled
    tagNumbersEnabled
    sexEnabled
    identificationFieldsEnabled
    baseMap {
      id
      name
      url
      attribution
      minZoom
      maxZoom
    }
  }
`

export const COLLECTION_SITE_MEMBERSHIP_FIELDS = gql`
  fragment CollectionSiteMembershipFields on CollectionSiteMembership {
    collectionSiteId
    userId
    id
  }
`

export const GET_ME_WITH_SITES = gql`
  ${CORE_ORGANISATION_FIELDS}
  ${CORE_COLLECTION_SITES_FIELDS}
  ${COLLECTION_SITE_MEMBERSHIP_FIELDS}
  query GetMeOrgWithSites {
    me {
      id
      collectionSiteMemberships {
        ...CollectionSiteMembershipFields
      }
      organisation {
        ...MeOrganisationFields
      }
      collectionSites {
        ...MeCollectionSitesFields
      }
    }
  }
`

export const GET_COLLECTION_SITE = gql`
  query GetCollectionSite($id: ID!) {
    collectionSite(id: $id) {
      urlSlug
      name
      id
      shortenedName
      materialQualifierFormat
      baseMap {
        id
        name
        url
        attribution
        minZoom
        maxZoom
      }
      ipenNumbersEnabled
      identificationFieldsEnabled
      tagNumbersEnabled
      sexEnabled
      public
      publicAbout
      publicWebsite
      publicContactEmail
      publicCountry {
        codeId
        name
        countryCodeAlpha2
      }
      publicCountryCode
      publicLocation
    }
  }
`

export const CREATE_SITE_MEMBERSHIP = gql`
  mutation CreateCollectionSiteMembership(
    $input: CreateCollectionSiteMembershipInput!
  ) {
    createCollectionSiteMembership(input: $input) {
      success
    }
  }
`

export const DELETE_SITE_MEMBERSHIP = gql`
  mutation DeleteCollectionSiteMembership($id: String!) {
    deleteCollectionSiteMembership(id: $id) {
      success
    }
  }
`
