import type {DeepNullable} from "ts-essentials"
import type {PlantMaterialSchemaIn} from "src/features/create-accession/components/add-plant-material-form/schema"

export const parseNullableStrToNum = (str: string | undefined | null) =>
  str == null ? undefined : Number(str)

export type EditableFields = DeepNullable<
  Pick<PlantMaterialSchemaIn, "position">
>
export type FormFields = {
  [Key in keyof EditableFields as `${string}${Key}`]: EditableFields[Key]
}
