import {twMerge} from "tailwind-merge"
import type {ReactNode} from "react"

interface ValuePairProps {
  label: ReactNode
  labelClassName?: string
  children: ReactNode
  className?: string
  breakPoint?: "mobile" | "desktop"
  testId?: string
}

export const ValuePair = ({
  children,
  label,
  labelClassName,
  className,
  breakPoint,
  testId,
}: ValuePairProps) => (
  <div
    className={twMerge(
      "flex flex-col gap-1 gap-x-3 text-grey-700",
      breakPoint === "desktop" && "flex-row",
      breakPoint == null && "sm:flex-row",
      className,
    )}
    data-cy={testId}
  >
    <div
      className={twMerge(
        "flex-shrink-0 text-xs text-grey-500",
        breakPoint === "desktop" && "basis-[172px] pt-1",
        breakPoint == null && " sm:basis-[172px] sm:pt-1",
        labelClassName,
      )}
    >
      {label}
    </div>
    {children}
  </div>
)
