import {type TagOptionZod} from "src/features/create-accession/components/add-plant-material-form/schema"

export const mapTagsToIds = <
  T extends {
    tags: Array<TagOptionZod> | null
  },
>({
  tags,
  ...rest
}: T) => ({
  ...rest,
  tags: tags?.map(({id}) => id) ?? null,
})
