import {flow, identity} from "fp-ts/function"
import * as RA from "fp-ts/ReadonlyArray"
import * as RNEA from "fp-ts/ReadonlyNonEmptyArray"
import {nullableFrom} from "./nullable"

/**
 * Turns an array of nullables (Value | null | undefined)
 * into an Option of a NonEmptyArray of Values
 */
const compactToNonEmpty = flow(
  RA.map(nullableFrom(identity)),
  RA.compact,
  RNEA.fromReadonlyArray,
)

export default compactToNonEmpty
