import {toError} from "fp-ts/Either"
import {flow, pipe} from "fp-ts/function"
import {getOrgAndSiteFromNames} from "../fetch-collection-site"
import {flowInto} from "../flow"
import * as OE from "../option-either"
import {createWrappedSWRHook} from "../swr"
import {createAuthedHookWithMap} from "./hook"
import {extractSiteFromOrg, usePlace} from "./place"

export const useCollectionSite = pipe(
  createWrappedSWRHook(getOrgAndSiteFromNames, toError),
  flowInto(OE.chainW(extractSiteFromOrg)),
  createAuthedHookWithMap,
  (useCollectionSite) => () =>
    useCollectionSite(usePlace(), (place) => [
      place === undefined
        ? undefined
        : {
            organisationSubdomain: place.orgName,
            collectionSiteSlug: place.siteSlug,
          },
    ]),
)

export type UseCollectionSiteResult = ReturnType<typeof useCollectionSite>
export type UseCollectionSiteError = OE.ExtractLeft<UseCollectionSiteResult>
export type UseCollectionSiteSuccess = OE.ExtractRight<UseCollectionSiteResult>

export const useValidCollectionSite = flow(
  useCollectionSite,
  OE.noneAndErrorToUndefined,
)
