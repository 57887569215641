import {ChevronDown} from "@hortis/ui/icons"
import {A, O, pipe} from "@mobily/ts-belt"
import type {AutocompleteRenderInputParams} from "@mui/material/Autocomplete"
import Autocomplete from "@mui/material/Autocomplete"
import type {FilterOptionsState} from "@mui/material/useAutocomplete"
import type {
  CollectionSitesFieldsFragment,
  RecordTagFieldsFragment,
  RecordTagFilters,
} from "generated/graphql"
import {
  useCollectionSiteTagsQuery,
  useCreateRecordTagMutation,
  useOrganisationTagsQuery,
} from "generated/graphql"
import type {SyntheticEvent} from "react"
import {useCallback, useState} from "react"
import {useController, useFormContext, useWatch} from "react-hook-form"
import {colors} from "src/colors"
import {useId} from "src/components/field-utils"
import {FormControl} from "src/components/form-control"
import {OptionallyShowHelperText} from "src/components/form-helper-text"
import {useSnackbarStore} from "src/components/snackbar-controller/snackbar-store"
import {TextField} from "src/components/text-field"
import {onFailure} from "src/notification-snack-utils"
import {shadows} from "src/shadows"
import {useCollectionSite} from "src/utils/hooks/collection-site"
import {
  useOrganisationSubdomainStruct,
  usePlaceStruct,
} from "src/utils/hooks/place"
import * as uuid from "uuid"
import {Dialog} from "@hortis/ui/dialog"
import * as OE from "../../../utils/option-either"
import {EditTagDialogContent} from "../edit-modal/edit-modal"
import {filterOptions, getTagOptionLabel, isTagOptionEqual} from "../utils"
import {AutocompleteTag} from "./autocomplete-tag"
import {renderTagAutoCompleteItem} from "./render-autocomplete-item"

const renderTags = () => null

interface TagsAutocompleteProps {
  name: string
  testId?: string
  filters?: RecordTagFilters
  tags: ReadonlyArray<RecordTagFieldsFragment>
  loading: boolean
  site?: CollectionSitesFieldsFragment
  refetchTags: () => void
}

type FormValues = {[Key in string]?: Array<RecordTagFieldsFragment> | null}

const filterOptionsWithNew = (
  options: Array<RecordTagFieldsFragment>,
  params: FilterOptionsState<RecordTagFieldsFragment>,
) => {
  const filtered = filterOptions(options, params)

  const {inputValue} = params
  // Suggest the creation of a new value
  const isExisting = options.some(
    (option) => inputValue.toLowerCase() === option.name.toLowerCase(),
  )
  if (inputValue !== "" && !isExisting) {
    filtered.push({
      id: `ADD_NEW:${inputValue}`,
      name: `Add new tag “${inputValue}”`,
    })
  }

  return filtered
}

export const TagsAutocomplete = ({
  name,
  testId,
  tags,
  loading,
  refetchTags,
  site,
  filters: _,
}: TagsAutocompleteProps) => {
  const id = useId(name)
  const {setSnack} = useSnackbarStore()
  const selectedTags = useWatch<FormValues>({name})
  const [editTag, setEditTag] = useState<RecordTagFieldsFragment | null>(null)
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [, createTag] = useCreateRecordTagMutation()

  const {trigger} = useFormContext()
  const {
    field,
    fieldState: {error},
  } = useController({
    name,
  })

  const onRemoveTag = useCallback(
    (id: string) => {
      field.onChange(selectedTags?.filter((tag) => tag.id !== id))
    },
    [field, selectedTags],
  )

  const onTagRenameSubmit = useCallback(
    (newTag: RecordTagFieldsFragment) => {
      field.onChange(
        selectedTags?.map((tag) => (tag.id === newTag.id ? newTag : tag)) ??
          null,
      )
      refetchTags()
      setEditModalOpen(false)
    },
    [selectedTags, field, refetchTags],
  )

  const openEditModal = useCallback((tag: RecordTagFieldsFragment) => {
    setEditTag(tag)
    setEditModalOpen(true)
  }, [])
  const closeEditModal = useCallback(() => {
    setEditModalOpen(false)
  }, [])

  const handleChange = useCallback(
    (_: SyntheticEvent, value: Array<RecordTagFieldsFragment>) => {
      const newValue = value.find(({id}) => id.includes("ADD_NEW"))

      if (newValue == null) {
        field.onChange(value)
      } else {
        const id = uuid.v4()
        pipe(
          newValue.id.split("ADD_NEW:"),
          A.last,
          O.match(
            async (newTagName) => {
              field.onChange([
                ...value.filter(({id}) => !id.includes("ADD_NEW")),
                {id, name: newTagName} as RecordTagFieldsFragment,
              ])
              const {data} = await createTag({
                input: {
                  collectionSiteId: site?.id ?? null,
                  name: newTagName,
                  id,
                },
              })
              const res = data?.createRecordTag
              if (res?.success === true) {
                refetchTags()
              } else if (
                res?.errors?.[0]?.__typename === "DuplicateNameError" ||
                res?.errors?.[0]?.__typename === "SiteTagMergeError"
              ) {
                onFailure(setSnack)(
                  new Error("A tag with that name already exists"),
                )
                field.onChange(value.filter(({id}) => !id.includes("ADD_NEW")))
              } else {
                field.onChange(value.filter(({id}) => !id.includes("ADD_NEW")))
              }
            },
            () => {
              field.onChange(value.filter(({id}) => !id.includes("ADD_NEW")))
            },
          ),
        )
      }
      if (error != null) {
        void trigger(name)
      }
    },
    [field, createTag, site, refetchTags, error, name, trigger, setSnack],
  )

  const cachedRenderInput = useCallback(
    ({inputProps, InputProps}: AutocompleteRenderInputParams) => (
      <TextField
        fullWidth
        name={name}
        inputProps={inputProps}
        InputProps={InputProps}
        endAdornment={InputProps.endAdornment}
        placeholder="Add tags"
        label={null}
        id={id}
      />
    ),
    [name, id],
  )

  return (
    <div className="flex flex-col gap-3" data-cy={testId}>
      <div className="flex flex-col gap-1.5">
        <label className="self-start text-sm font-medium" htmlFor={id}>
          Tags
        </label>
        {selectedTags != null && selectedTags.length > 0 && (
          <div className="flex flex-wrap gap-2">
            {selectedTags.map((tag) => (
              <AutocompleteTag
                tag={tag}
                key={tag.id}
                editTag={openEditModal}
                removeTag={onRemoveTag}
              />
            ))}
          </div>
        )}
      </div>
      <FormControl error={Boolean(error)}>
        <Autocomplete
          multiple
          renderOption={renderTagAutoCompleteItem}
          options={tags}
          placeholder="Add tags"
          filterOptions={filterOptionsWithNew}
          disableCloseOnSelect
          renderTags={renderTags}
          getOptionLabel={getTagOptionLabel}
          isOptionEqualToValue={isTagOptionEqual}
          popupIcon={<ChevronDown />}
          loading={loading}
          disableClearable
          clearOnBlur
          selectOnFocus
          handleHomeEndKeys
          componentsProps={{
            popper: {
              disablePortal: true,
              sx: {
                marginTop: "2px !important",
                marginBottom: "2px !important",
                "& .MuiAutocomplete-listbox": {
                  padding: "4px 0",
                },
                "& .MuiPaper-root": {
                  border: `1px solid ${colors.grey[100]}`,
                  boxShadow: shadows.lg,
                },
                '& .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"]':
                  {
                    backgroundColor: colors.grey[50],
                  },
                "& .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused":
                  {
                    backgroundColor: colors.grey[100],
                  },
              },
            },
          }}
          fullWidth
          sx={{
            "& label": {display: "none"},
            "& .MuiOutlinedInput-root": {py: "4px"},
            "& .MuiOutlinedInput-root:focus-within": {border: "none"},
            "& .MuiOutlinedInput-notchedOutline": {
              transition: `all 125ms ease`,
              border: `1px solid ${colors.grey[300]}`,
              boxShadow: `0 0 0 0px ${colors.primary[100]}`,
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: `1px solid ${colors.primary[300]}`,
              boxShadow: `0 0 0 4px ${colors.primary[100]}`,
            },
            "& .MuiOutlinedInput-root .MuiAutocomplete-endAdornment": {
              right: "12px",
            },

            '& .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"]':
              {
                backgroundColor: colors.grey[50],
              },
          }}
          onChange={handleChange}
          value={field.value as Array<RecordTagFieldsFragment>}
          renderInput={cachedRenderInput}
          id={id}
        />
        <div className="absolute top-full">
          <OptionallyShowHelperText message={error?.message} />
        </div>
      </FormControl>
      {editTag != null && tags.length > 0 && (
        <Dialog open={editModalOpen} onOpenChange={setEditModalOpen}>
          <EditTagDialogContent
            site={site}
            open={editModalOpen}
            tag={editTag}
            onClose={closeEditModal}
            onSubmit={onTagRenameSubmit}
            existingTags={tags}
          />
        </Dialog>
      )}
    </div>
  )
}

export const TagsAutocompleteAlt = ({
  filters,
  ...props
}: Omit<TagsAutocompleteProps, "loading" | "tags" | "refetchTags">) => {
  const place = usePlaceStruct()
  const site = pipe(useCollectionSite(), OE.toStruct)
  const pause = place.data == null
  const [{data, fetching}, refetchTags] = useCollectionSiteTagsQuery({
    variables: {
      organisationSubdomain: place.data?.orgName ?? "",
      collectionSiteSlug: place.data?.siteSlug ?? "",
      filters,
      orgFilters: {...filters, hasCollectionSiteRelation: {neq: true}},
    },
    pause,
  })
  const orgTags = data?.org?.tags?.nodes ?? []
  const siteTags = data?.org?.site?.tags?.nodes ?? []

  const loading = place.loading || site.data == null || fetching

  const tags = site.data == null ? [] : [...orgTags, ...siteTags]

  return (
    <TagsAutocomplete
      tags={tags}
      loading={loading}
      refetchTags={refetchTags}
      filters={filters}
      site={site.data}
      {...props}
    />
  )
}

export const OrgTagsAutocomplete = ({
  filters,
  ...props
}: Omit<TagsAutocompleteProps, "loading" | "tags" | "refetchTags">) => {
  const subdomain = useOrganisationSubdomainStruct()
  const pause = subdomain.data == null
  const [{data, fetching}, refetchTags] = useOrganisationTagsQuery({
    variables: {
      organisationSubdomain: subdomain.data ?? "",
      filters: {...filters, hasCollectionSiteRelation: {neq: true}},
    },
    pause,
  })
  const tags = data?.org?.tags?.nodes

  const loading = subdomain.loading || fetching

  return (
    <TagsAutocomplete
      {...props}
      tags={tags == null ? [] : tags}
      loading={loading}
      refetchTags={refetchTags}
      filters={filters}
    />
  )
}
