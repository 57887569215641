import type {FC} from "react"
import {cacheExchange, createClient, fetchExchange, Provider} from "urql"
import type {Client} from "urql"
import config from "../config"
import {useAccessToken} from "../utils/use-access-token"
import {authExchange} from "./custom-auth-exchange"

export let urqlClient: undefined | Client

export const UrqlProvider: FC<React.PropsWithChildren> = ({children}) => {
  const accessToken = useAccessToken()

  // want to debug? -> https://github.com/FormidableLabs/urql-devtools-exchange#usage
  const client = createClient({
    url: config.publicApiUrl,
    exchanges: [cacheExchange, authExchange(accessToken), fetchExchange],
    requestPolicy: "network-only",
  })

  urqlClient = client

  return <Provider value={client}>{children}</Provider>
}
