import Grow from "@mui/material/Grow"
import {useEffect, useMemo, useRef} from "react"
import type {ReactElement} from "react"
import Fade from "@mui/material/Fade"
import {twMerge} from "tailwind-merge"
import type {FeaturedIconColor} from "src/components/featured-icon/featured-icon"
import {FeaturedIcon} from "src/components/featured-icon/featured-icon"
import {IconButton, Button} from "@hortis/ui/button"
import {XClose} from "@hortis/ui/icons"
import {Link} from "@tanstack/react-router"

export interface NotificationActionProps {
  text: string
  onClick?: () => void
  href?: string
}

export interface NotificationProps {
  open: boolean
  onClose: () => void
  hideDuration?: number | null
  text: string
  supportingText?: string
  iconProps?: {color: FeaturedIconColor; icon: ReactElement}
  primaryAction?: NotificationActionProps
  secondaryAction?: NotificationActionProps
  testId?: string
}

export const Notification = ({
  open,
  onClose,
  hideDuration,
  text,
  supportingText,
  iconProps,
  primaryAction,
  secondaryAction,
  testId,
}: NotificationProps) => {
  const timeout = useRef<ReturnType<typeof setTimeout> | null>(null)

  useEffect(() => {
    if (open && hideDuration !== null) {
      if (timeout.current != null) {
        clearTimeout(timeout.current)
      }
      timeout.current = setTimeout(() => {
        onClose()
        timeout.current = null
      }, hideDuration ?? 4000)
    }

    return () => {
      if (timeout.current != null) {
        clearTimeout(timeout.current)
      }
      timeout.current = null
    }
  }, [
    open,
    hideDuration,
    onClose,
    text,
    supportingText,
    iconProps,
    primaryAction,
    secondaryAction,
  ])

  const closeButton = useMemo(
    () => (
      <IconButton
        onClick={onClose}
        ariaLabel="close"
        size="sm"
        variant="tertiaryGray"
        icon={XClose}
      />
    ),
    [onClose],
  )

  return (
    <Fade in={open} timeout={150} unmountOnExit>
      <div className="pointer-events-none fixed bottom-0 right-0 z-[1400] w-full bg-gradient-to-t from-black/10 via-white/0 px-2 py-4 md:max-w-lg md:bg-gradient-to-tl md:p-6 md:pl-20 md:pt-20">
        <Grow in={open} timeout={150}>
          <div
            className="pointer-events-auto rounded-lg border border-grey-100 bg-white p-4 shadow-lg"
            data-cy={testId}
          >
            <div
              className={twMerge(
                "flex flex-col gap-3 md:flex-row",
                supportingText == null && "md:items-center",
              )}
            >
              <div className="flex justify-between">
                <div className="ml-[-4px] mt-[-4px]">
                  {iconProps && (
                    <FeaturedIcon size="sm" color={iconProps.color}>
                      {iconProps.icon}
                    </FeaturedIcon>
                  )}
                </div>
                <div className="block md:hidden">{closeButton}</div>
              </div>
              <div className="flex flex-col gap-4 md:flex-1">
                <div className="flex flex-col gap-1">
                  <div className="text-sm font-medium text-grey-900">
                    {text}
                  </div>
                  {supportingText != null && (
                    <div className="text-sm text-grey-500">
                      {supportingText}
                    </div>
                  )}
                </div>
                {(primaryAction != null || secondaryAction != null) && (
                  <div className="flex gap-3">
                    {secondaryAction != null && (
                      <Button
                        variant="linkgray"
                        size="sm"
                        onClick={() => {
                          if (secondaryAction.onClick != null) {
                            secondaryAction.onClick()
                          }
                          onClose()
                        }}
                      >
                        {secondaryAction.href == null ? (
                          secondaryAction.text
                        ) : (
                          <Link to={secondaryAction.href}>
                            {secondaryAction.text}
                          </Link>
                        )}
                      </Button>
                    )}
                    {primaryAction != null && (
                      <Button
                        variant="linkcolor"
                        size="sm"
                        onClick={() => {
                          if (primaryAction.onClick != null) {
                            primaryAction.onClick()
                          }
                          onClose()
                        }}
                        asChild={primaryAction.href != null}
                      >
                        {primaryAction.href == null ? (
                          primaryAction.text
                        ) : (
                          <Link to={primaryAction.href}>
                            {primaryAction.text}
                          </Link>
                        )}
                      </Button>
                    )}
                  </div>
                )}
              </div>
              <div
                className={twMerge(
                  "mr-[-4px] mt-[-4px] hidden md:block",
                  supportingText == null && "md:mr-0 md:mt-0",
                )}
              >
                {closeButton}
              </div>
            </div>
          </div>
        </Grow>
      </div>
    </Fade>
  )
}
