import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@hortis/ui/form"
import {useFormContext} from "react-hook-form"
import {FieldsHeader} from "src/components/forms/fields-header"
import {LocationPicker} from "src/features/locations/components/location-picker"
import {MaterialMapPositionField} from "../map-position-field/map-position-field"

interface PositionFieldsProps {
  namePrefix: string
  hideMap?: boolean
}

export const PositionFields = ({namePrefix, hideMap}: PositionFieldsProps) => {
  const form = useFormContext()
  return (
    <FieldsHeader
      header="Location"
      buttonTestId="add-position-button"
      testId="location-header"
    >
      <div className="flex flex-col gap-6 pt-6">
        <FormField
          control={form.control}
          name={`${namePrefix}collectionSiteLocationOption`}
          render={({field}) => (
            <FormItem>
              <FormLabel required>Location</FormLabel>
              <FormControl>
                <LocationPicker
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- no form typesafety yet
                  value={field.value}
                  // eslint-disable-next-line react/jsx-handler-names
                  onChange={field.onChange}
                  data-cy="location"
                />
              </FormControl>
              <FormMessage data-cy="form-error" />
            </FormItem>
          )}
        />
        {hideMap !== true && (
          <MaterialMapPositionField namePrefix={namePrefix} />
        )}
      </div>
    </FieldsHeader>
  )
}
