import {ButtonGroup} from "@hortis/ui/button/button-group"
import type {BaseMapFieldsFragment} from "generated/graphql"
import {memo} from "react"
import type {ViewState} from "react-map-gl"
import {CenterCollection} from "./center-collection"
import {CenterCurrentPosition} from "./center-current-position"
import {MapZoomButtons} from "./map-zoom-buttons"
import {SelectLayers} from "./select-layers"

type ViewStatePart = Pick<ViewState, "latitude" | "longitude">

export interface MapActionButtonsProps {
  initialViewState?: ViewStatePart
  mapId: string
  baseMap: BaseMapFieldsFragment | null
}

export const MapActionButtons = memo(function MemoizedMapActionButtons({
  initialViewState,
  baseMap,
}: MapActionButtonsProps) {
  return (
    <div className="flex flex-col gap-2 md:gap-4">
      <SelectLayers baseMap={baseMap} />
      <ButtonGroup
        direction="column"
        spacing={0}
        className="shadow-mg border-none"
      >
        {initialViewState == null ? undefined : (
          <CenterCollection {...initialViewState} />
        )}
        <CenterCurrentPosition />
      </ButtonGroup>
      <MapZoomButtons />
    </div>
  )
})
