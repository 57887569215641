import type {FilterOperation} from "src/features/filters/plant-materials"
import type {LocalTagsFilter} from "./schema"

export const extractTagsFilterData = (
  filter: NonNullable<LocalTagsFilter>,
  operation: FilterOperation,
): Array<string> | undefined => {
  switch (operation) {
    case "is": {
      return filter.is?.map(({name}) => name)
    }
    case "isNot": {
      return filter.isNot?.map(({name}) => name)
    }
    case "contains": {
      return filter.contains == null ? undefined : [filter.contains]
    }
    default: {
      return undefined
    }
  }
}
