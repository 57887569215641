import {Globe2} from "@hortis/ui/icons/globe-2"
import {
  type PlantMaterialStatus,
  type ProvenanceCategoryCode,
} from "generated/graphql"
import {colors} from "src/colors"
import {DerivedStatus} from "src/components/derived-status"

import {Badge} from "@hortis/ui/badge"
import {provenanceLabelMap} from "src/utils/label-maps/provenance"
import {twMerge} from "tailwind-merge"
import {
  RecordCard,
  RecordCardContent,
  RecordCardDetails,
  RecordCardOverline,
  RecordCardPhoto,
  RecordCardTitle,
  type RecordCardProps,
} from "../record-card"

type AccessionRecordCardProps = {
  statuses?: Array<PlantMaterialStatus>
  provenanceCategoryCode: ProvenanceCategoryCode
  deaccessioned?: boolean
  accessionNumber: string
  scientificName: string
  photoUrl?: string
  className?: string
  testId?: string
} & Pick<RecordCardProps, "variant" | "selected" | "size">
function AccessionRecordCard({
  statuses = [],
  provenanceCategoryCode,
  deaccessioned = false,
  accessionNumber,
  scientificName,
  photoUrl,
  className,
  ...rest
}: AccessionRecordCardProps) {
  return (
    <RecordCard
      {...rest}
      className={twMerge(deaccessioned && "grayscale", className)}
    >
      <RecordCardPhoto photo={photoUrl} />
      <RecordCardContent>
        <RecordCardOverline data-cy="accession-number">
          {accessionNumber}
        </RecordCardOverline>
        <RecordCardTitle>{scientificName}</RecordCardTitle>

        <RecordCardDetails>
          {deaccessioned ? (
            <Badge color="grey" data-cy="deaccessioned-accession-chip">
              Deaccessioned
            </Badge>
          ) : (
            <DerivedStatus statuses={statuses} />
          )}

          <div className="flex items-center gap-1 py-[3px] text-sm font-medium text-grey-500">
            <Globe2 color={colors.grey[400]} className="h-4 w-4 " />
            {`${provenanceCategoryCode} - ${provenanceLabelMap[provenanceCategoryCode]}`}
          </div>
        </RecordCardDetails>
      </RecordCardContent>
    </RecordCard>
  )
}

export default AccessionRecordCard
