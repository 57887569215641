import {gql} from "graphql-request"

export const CORE_COLLECTION_SITE_LOCATION_FIELDS = gql`
  fragment CollectionSiteLocationFields on CollectionSiteLocation {
    id
    name
    description
    archived
  }
`

export const LOCATION_FIELDS = gql`
  fragment LocationFields on Location {
    id
    name
    description
    archived
  }
`

export const GET_LOCATIONS = gql`
  ${LOCATION_FIELDS}
  query GetLocations(
    $subdomain: String!
    $siteSlug: String!
    $before: String
    $after: String
    $first: Int
    $last: Int
    $filter: LocationFilter
  ) {
    org: organisationBySubdomain(subdomain: $subdomain) {
      site: collectionSiteBySlug(slug: $siteSlug) {
        locationsConnection(
          before: $before
          after: $after
          first: $first
          last: $last
          filter: $filter
        ) {
          nodes {
            ...LocationFields
          }
        }
      }
    }
  }
`

export const CREATE_LOCATION = gql`
  ${LOCATION_FIELDS}
  mutation CreateLocation($input: CreateLocationInput!) {
    createLocation(input: $input) {
      __typename
      success
      location {
        ...LocationFields
      }
      errors {
        ... on DuplicateCollectionSiteLocationCodeError {
          message
        }
      }
    }
  }
`

export const UPDATE_LOCATION = gql`
  ${LOCATION_FIELDS}
  mutation UpdateLocation($id: ID!, $input: UpdateLocationInput!) {
    updateLocation(id: $id, input: $input) {
      __typename
      success
      location {
        ...LocationFields
      }
      errors {
        ... on DuplicateCollectionSiteLocationCodeError {
          message
        }
      }
    }
  }
`

export const ARCHIVE_LOCATION = gql`
  mutation ArchiveCollectionSiteLocation($id: ID!) {
    archiveCollectionSiteLocation(id: $id) {
      success
    }
  }
`

const SINGLE_COLLECTION_SITE_LOCATION_FIELDS = gql`
  fragment SingleCollectionSiteLocationFields on CollectionSiteLocation {
    id
    name
    description
    totalPlantMaterial
    archived
  }
`

export const GET_COLLECTION_SITE_LOCATION = gql`
  ${SINGLE_COLLECTION_SITE_LOCATION_FIELDS}
  query GetCollectionSiteLocation($id: ID!) {
    collectionSiteLocation(id: $id) {
      ...SingleCollectionSiteLocationFields
    }
  }
`
