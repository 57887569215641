import {Outlet, createFileRoute} from "@tanstack/react-router"
import {
  GetCollectionSiteFromNamesDocument,
  type GetCollectionSiteFromNamesQuery,
  type GetCollectionSiteFromNamesQueryVariables,
} from "generated/graphql"
import {useEffect} from "react"
import {useStickyState} from "src/utils/hooks/sticky-state"
import {useClient} from "urql"

export const Route = createFileRoute("/_layout/sites/$siteSlug")({
  component: Component,
})

function Component() {
  const [_, setLastVisitedSiteId] = useStickyState("", "last-visited-siteId")
  const {siteSlug} = Route.useParams()
  const {subdomain} = Route.useRouteContext()
  const client = useClient()

  useEffect(() => {
    const loadData = client
      .query<
        GetCollectionSiteFromNamesQuery,
        GetCollectionSiteFromNamesQueryVariables
      >(GetCollectionSiteFromNamesDocument, {
        organisationSubdomain: subdomain,
        collectionSiteSlug: siteSlug,
      })
      .toPromise()

    void loadData.then(({data: siteData}) => {
      const siteId = siteData?.org?.site?.id
      siteId != null && setLastVisitedSiteId(siteId)
      return siteId
    })
  }, [client, siteSlug, subdomain, setLastVisitedSiteId])

  return <Outlet />
}
