import type {
  GetMeOrgWithSitesQuery,
  GetMeOrgWithSitesQueryVariables,
} from "../../../generated/graphql"
import {noGQLVars} from "../../utils/empty"
import {authedFetcher} from "../../utils/gql-client"
import {createAuthedHook} from "../../utils/hooks/hook"
import * as OE from "../../utils/option-either"
import {createWrappedSWRHook} from "../../utils/swr"
import {GET_ME_WITH_SITES} from "./graphql"

export const fetchMe = authedFetcher<
  GetMeOrgWithSitesQueryVariables,
  GetMeOrgWithSitesQuery
>(GET_ME_WITH_SITES)("GetMeOrgWithSites", noGQLVars)

export const useMeWithSites = createAuthedHook(
  createWrappedSWRHook(fetchMe, OE.toError),
)
