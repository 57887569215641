import {pipe} from "fp-ts/function"
import * as t from "io-ts"
import {mapType} from "../../../utils/io-ts"

export type LngLat = [lng: number, lat: number]

const LngLat: t.TupleC<[lng: t.NumberC, lat: t.NumberC]> = t.tuple([
  t.number,
  t.number,
])
export const decodeToLngLat: (coords: Array<number>) => t.Validation<LngLat> =
  LngLat.decode

export const CoordinatesFromLngLat = pipe(
  LngLat,
  mapType(
    ([longitude, latitude]) => ({
      latitude: latitude,
      longitude: longitude,
    }),
    ({latitude, longitude}): LngLat => [longitude, latitude],
  ),
)
