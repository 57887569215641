import {useSearch} from "@tanstack/react-router"

const defaultErrorDescriptionsMap: Record<string, string> = {
  "user/auto-join":
    "This organisation can't be joined without an invitation. Contact your garden's admin or Hortis support.",
  "user/deactivated":
    "Your account has been deactivated. Please contact your organisation's admin.",
}

export const useErrorDescription = (
  errorDescriptionsMap = defaultErrorDescriptionsMap,
) => {
  const search = useSearch({strict: false})
  const error = "error" in search ? search.error : undefined
  const errorDescription =
    "error_description" in search ? search.error_description : undefined
  return error == null
    ? errorDescription ?? error
    : errorDescriptionsMap[error] ?? errorDescription ?? error
}
