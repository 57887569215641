import {F} from "@mobily/ts-belt"
import {useEffect, useRef} from "react"

export const useDebounce = (
  fn: () => Promise<void> | void,
  timeout: number,
): void => {
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      void fn()
    }, timeout)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [fn, timeout])
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useControlledDebounce = <T extends Array<any>>(
  cb: (...args: T) => void,
  config?: Partial<F.Options>,
) => {
  const ref = useRef(
    F.makeControlledDebounce<T>({delay: 200, leading: true, ...config})(cb),
  )
  return ref.current
}
