import type {OutlinedInputProps} from "@mui/material/OutlinedInput"
import OutlinedInput from "@mui/material/OutlinedInput"
import Typography from "@mui/material/Typography"
import {flow} from "fp-ts/function"
import * as O from "fp-ts/Option"
import * as RA from "fp-ts/ReadonlyArray"
import type {ChangeEvent, HTMLAttributes} from "react"
import {forwardRef, useCallback, useMemo} from "react"
import type {ValidateResult} from "react-hook-form"
import {useController, useFormContext} from "react-hook-form"
import {useMobileDetect} from "src/utils/hooks/mobile-detect"
import {shadows} from "src/shadows"
import {colors} from "src/colors"
import {applyMui} from "../../utils/apply-mui"
import {fromSxValues} from "../../utils/sx"
import {withTestId} from "../../utils/with-test-id"
import {Box} from "../box"
import type {FieldProps} from "../field-types"
import {generateLabelName, useId} from "../field-utils"
import type {FormControlProps} from "../form-control"
import {FormControl} from "../form-control"
import {OptionallyShowHelperText} from "../form-helper-text"
import {AlertCircle} from "../icons/streamline/alert-circle"
import {getRegExp} from "./utils"

// InputProps is a props used to apply props to OutlinedInput's internal <input />
// It's required for components such as autocomplete to get a ref to the input
// See MUI TextField.d.ts to see how its used on the standard TextField implementation
export type TextFieldProps = FieldProps<
  OutlinedInputProps & {
    InputProps?: Partial<OutlinedInputProps>
    inputProps?: OutlinedInputProps["inputProps"] & {"data-cy"?: string}
    initialValue?: string
    numeric?: boolean
    signedNumeric?: boolean
    decimal?: boolean
    saveAsNumber?: boolean
    controlSx?: FormControlProps["sx"]
    labelProps?: HTMLAttributes<HTMLLabelElement>
    alternativeLabel?: boolean
    hiddenLabel?: boolean
    "data-cy"?: string
  }
>

const helperTextStyle = fromSxValues({
  display: "flex",
  flexFlow: "column nowrap",
  "& .MuiFormHelperText-root.Mui-error": {
    color: colors.error[500],
  },
})

const altLabelStyle = fromSxValues({marginBottom: "6px"})
export const ThemedInputLabel = ({
  id,
  required,
  label,
  ...rest
}: {
  id: string | undefined
  required: boolean
  hiddenLabel?: boolean
  label: TextFieldProps["label"]
}): JSX.Element => {
  return (
    <Typography<"label">
      htmlFor={id}
      component="label"
      variant="subtitle2"
      fontWeight="500"
      sx={altLabelStyle}
      {...rest}
    >
      {required && <span className="text-orange-600">* </span>}
      {label}
    </Typography>
  )
}

export const InternalTextField = forwardRef(function InternalTextField(
  {
    label,
    value,
    name,
    disabled,
    errorMessage,
    helperText,
    inputProps,
    startAdornment,
    endAdornment,
    placeholder,
    fullWidth,
    InputProps,
    hiddenLabel,
    onChange,
    numeric = false,
    decimal = false,
    signedNumeric = false,
    controlSx,
    inputRef,
    labelProps,
    error = false,
    required = false,
    id: externalId,
    ...props
  }: TextFieldProps,
  ref,
): JSX.Element {
  const internalId = useId(name)
  const id = externalId ?? internalId
  const inputLabel = generateLabelName(label, required)
  const detectMobile = useMobileDetect()

  const regexp = useMemo(
    () =>
      numeric || signedNumeric
        ? getRegExp({signedNumeric, decimal})
        : undefined,
    [numeric, signedNumeric, decimal],
  )

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
      const value = e.target.value
      if (onChange && (regexp === undefined || regexp.test(value))) {
        onChange(e)
      }
    },
    [onChange, regexp],
  )

  return (
    <FormControl
      className={props.className}
      disabled={disabled}
      error={error}
      variant="outlined"
      fullWidth={fullWidth}
      sx={{
        ...controlSx,
        "& .MuiInputBase-root.MuiOutlinedInput-root": {
          boxShadow: shadows.xs,
          backgroundColor: colors.white,
        },
        "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled": {
          backgroundColor: colors.grey[100],
          color: colors.grey[500],
        },
        "& .MuiOutlinedInput-input": {
          py: "10px",
          px: "14px",
          color: colors.grey[900],
        },
        "& .MuiInputAdornment-root": {
          marginRight: "6px",
        },
        "& .MuiInputBase-root .MuiInputBase-inputMultiline": {
          padding: 0,
        },
        "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
          border: `1px solid ${colors.error[300]}`,
        },
        "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline":
          {
            border: `1px solid ${colors.grey[300]}`,
          },
        "& .MuiOutlinedInput-input:focus-within": {border: "none"},
        "& .MuiOutlinedInput-notchedOutline": {
          transition: `all 125ms ease-out`,
          border: `1px solid ${colors.grey[300]}`,
          boxShadow: `0 0 0 0px ${colors.primary[100]}`,
        },
        "& :not(.Mui-focused, .Mui-error, .Mui-disabled).MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
          {
            border: `1px solid ${colors.grey[400]}`,
          },
        "& .MuiOutlinedInput-root.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline":
          {
            border: `1px solid ${colors.error[300]}`,
            boxShadow: `0 0 0 4px ${colors.error[100]}`,
          },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
          {
            border: `1px solid ${colors.primary[300]}`,
            boxShadow: `0 0 0 4px ${colors.primary[100]}`,
          },
      }}
    >
      {hiddenLabel !== true && (
        <ThemedInputLabel
          id={id}
          required={required}
          label={label}
          {...labelProps}
        />
      )}
      <OutlinedInput
        id={id}
        value={value == null ? "" : String(value)}
        label={inputLabel}
        placeholder={placeholder}
        startAdornment={startAdornment}
        endAdornment={
          error && endAdornment == null ? <AlertCircle /> : endAdornment
        }
        ref={ref}
        inputRef={inputRef}
        name={name}
        onChange={handleChange}
        notched={false}
        inputProps={{
          ...inputProps,
          "aria-label": hiddenLabel === true ? String(label) : undefined,
          inputMode:
            (numeric || signedNumeric) && !detectMobile.isIos()
              ? "numeric"
              : undefined,
        }}
        // this type is huge and really inefficient, so let's try and ignore it
        {...(props as Record<string, unknown>)}
        {...InputProps}
      />
      <OptionallyShowHelperText
        message={helperText}
        testIdPrefix={`${
          props["data-cy"] ?? inputProps?.["data-cy"] ?? ""
        }-helper`}
      />
      <Box sx={helperTextStyle}>
        <OptionallyShowHelperText
          message={errorMessage}
          testIdPrefix={`${
            props["data-cy"] ?? inputProps?.["data-cy"] ?? ""
          }-error`}
        />
      </Box>
    </FormControl>
  )
})

export const TextField = applyMui(withTestId, InternalTextField)

// best we can do because of limitations of react hook form
// and generic string 'literals'
type FormValues = {[Key in string]?: string}

const sanitiseValidateResult = flow(
  RA.filterMap((result: ValidateResult) =>
    result !== undefined &&
    (typeof result === "string" || Array.isArray(result))
      ? O.some(result)
      : O.none,
  ),
  RA.chain((result) => (Array.isArray(result) ? result : [result])),
)

export const FormTextField = applyMui(
  withTestId,
  ({
    initialValue,
    saveAsNumber = false,
    ...props
  }: TextFieldProps): JSX.Element => {
    const {name} = props
    const {control, trigger} = useFormContext<FormValues>()

    const {
      field: {onChange: fieldOnChange, onBlur: fieldOnBlur, ...field},
      fieldState: {error},
    } = useController<FormValues>({
      name,
      control,
      defaultValue: initialValue,
    })

    const errorMessage =
      error?.types === undefined
        ? error?.message
        : sanitiseValidateResult(Object.values(error.types))

    const onBlur = useCallback(() => {
      if (field.value === "-") {
        fieldOnChange("")
      }
      fieldOnBlur()
    }, [fieldOnBlur, field.value, fieldOnChange])

    const onChange = useCallback(
      (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let val: string | number | null = e.target.value

        // Handle string to number conversion
        if (saveAsNumber && val !== "-" && val !== "") {
          val = Number(val)
        }
        if (val === "") {
          val = null
        }

        fieldOnChange(val)

        // We want revalidate to run onChange, but this behaviour only happens if a form onSubmit has occured,
        // so trigger revalidation manually,
        // see: https://github.com/react-hook-form/react-hook-form/discussions/2444
        if (error != null) {
          void trigger(name)
        }
      },
      [fieldOnChange, saveAsNumber, trigger, name, error],
    )

    return (
      <InternalTextField
        // this type is huge and really inefficient, so let's try and ignore it
        {...(props as TextFieldProps)}
        {...field}
        onBlur={onBlur}
        onChange={onChange}
        error={Boolean(error)}
        errorMessage={errorMessage}
      />
    )
  },
)
