import {FormTextField} from "src/components/text-field"

interface PositionFieldsProps {
  namePrefix: string
  onCoordinatePaste: () => void
}

export const PositionFields = ({
  namePrefix,
  onCoordinatePaste,
}: PositionFieldsProps) => (
  <div className="flex flex-col gap-8 rounded-lg border border-grey-200 bg-grey-50 px-4 py-6 shadow-xs md:px-8 md:py-8">
    <FormTextField
      label="Latitude"
      name={`${namePrefix}position.latitude`}
      testId="latitude"
      type="text"
      signedNumeric
      decimal
      onPaste={onCoordinatePaste}
    />
    <FormTextField
      label="Longitude"
      name={`${namePrefix}position.longitude`}
      testId="longitude"
      type="text"
      signedNumeric
      decimal
    />
    <FormTextField
      label="Elevation (m)"
      name={`${namePrefix}position.elevation`}
      testId="elevation"
      type="text"
      signedNumeric
      decimal
    />
  </div>
)
