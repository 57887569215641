import {pipe} from "fp-ts/function"
import type {OptionEither, RecOfOE, SeqWithValues} from "../option-either"
import {
  noneAndErrorToUndefined,
  propagateNoneOrError,
  toSequenceAndUndefinedValues,
} from "../option-either"
import type {UseAccessTokenResult} from "../use-access-token"
import {useAccessToken} from "../use-access-token"

type EnforceNonEmptyRecord<R> = keyof R extends never ? never : R

/* eslint-disable @typescript-eslint/no-explicit-any */
// Important for generic inference of tuples instead of Arrays.
type AnyTuple =
  | [any]
  | [any, any]
  | [any, any, any]
  | [any, any, any, any]
  | [any, any, any, any, any]

type AnyTupleTail = [any] | [any, any] | [any, any, any]
/* eslint-enable @typescript-eslint/no-explicit-any */

/**
 * Run useHook with value after its error or emptiness has been converted to undefined.
 * Propagate error or emptiness from the value, otherwise picking the result of the hook.
 *
 * @example
 * ```typescript
 * function useBarWithFoo() {
 *   const foo = useFoo()
 *   return useHookWith(
 *     useBar,
 *     foo,
 *   )
 * }
 * ```
 */
export const useHookWith = <FirstLeft, FirstRight, SecondLeft, SecondRight>(
  useHook: (
    args: FirstRight | undefined,
  ) => OptionEither<SecondLeft, SecondRight>,
  value: OptionEither<FirstLeft, NonNullable<FirstRight>>,
): OptionEither<FirstLeft | SecondLeft, SecondRight> =>
  propagateNoneOrError(value, useHook(noneAndErrorToUndefined(value)))

/**
 * Run useHook with results of invoking the mapping function with value,
 * converting its error or emptiness converted to undefined.
 * Propagate error or emptiness from the value, otherwise picking the result of the hook.
 *
 * @example
 * ```typescript
 * function useBarWithFoo() {
 *   const foo = useFoo()
 *   return useHookWithMap(
 *     useBar,
 *     foo,
 *     foo => [foo, 42]
 *   )
 * }
 * ```
 */
export const useHookWithMap = <
  Args extends AnyTuple,
  FirstLeft,
  FirstRight,
  SecondLeft,
  SecondRight,
>(
  useHook: (...args: Args) => OptionEither<SecondLeft, SecondRight>,
  value: OptionEither<FirstLeft, FirstRight>,
  map: (value: FirstRight | undefined) => Args,
): OptionEither<FirstLeft | SecondLeft, SecondRight> =>
  propagateNoneOrError(value, useHook(...map(noneAndErrorToUndefined(value))))

/**
 * Curried version of {@link useHookWithMap}
 */
export const createHookWithMap = <
  Args extends AnyTuple,
  SecondLeft,
  SecondRight,
>(
  useHook: (...args: Args) => OptionEither<SecondLeft, SecondRight>,
) =>
  function useCurriedHookWithMap<FirstLeft, FirstRight>(
    value: OptionEither<FirstLeft, FirstRight>,
    map: (value: FirstRight | undefined) => Args,
  ): OptionEither<FirstLeft | SecondLeft, SecondRight> {
    return propagateNoneOrError(
      value,
      useHook(...map(noneAndErrorToUndefined(value))),
    )
  }

type GetSubset<Type extends AnyTuple> = Type extends [
  string | undefined,
  ...infer Rest,
]
  ? Rest
  : never

/**
 * Run useHook with results of invoking the mapping function with a struct,
 * converting the keys' error or emptiness to undefined.
 * Propagate error or emptiness from sequencing the struct,
 * otherwise picking the result of the hook.
 *
 * @example
 * ```typescript
 * function useMyComplexHook() {
 *   const foo = useFoo()
 *   const bar = useBar()
 *   return useHookWithMappedStruct(
 *     useBaz,
 *     { foo, bar },
 *     ({ foo, bar }) => [foo, 42, bar]
 *   )
 * }
 * ```
 */
export const useHookWithMappedStruct = <
  Args extends AnyTuple,
  SecondLeft,
  SecondRight,
  NER extends RecOfOE,
>(
  useHook: (...args: Args) => OptionEither<SecondLeft, SecondRight>,
  struct: EnforceNonEmptyRecord<NER>,
  map: (value: SeqWithValues<NER>[1]) => Args,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): SeqWithValues<NER>[0] extends OptionEither<infer FirstLeft, any>
  ? OptionEither<FirstLeft | SecondLeft, SecondRight>
  : never => {
  const [result, values] = toSequenceAndUndefinedValues(struct)

  return propagateNoneOrError(result, useHook(...map(values)))
}

/**
 * Curried version of {@link useHookWithMappedStruct}
 */
export const createHookWithMappedStruct = <
  Args extends AnyTuple,
  SecondLeft,
  SecondRight,
>(
  useHook: (...args: Args) => OptionEither<SecondLeft, SecondRight>,
) =>
  function useCurriedHookWithMappedStruct<NER extends RecOfOE>(
    struct: EnforceNonEmptyRecord<NER>,
    map: (value: SeqWithValues<NER>[1]) => Args,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): SeqWithValues<NER>[0] extends OptionEither<infer FirstLeft, any>
    ? OptionEither<FirstLeft | SecondLeft, SecondRight>
    : never {
    return useHookWithMappedStruct(useHook, struct, map)
  }

/**
 * Implicitly authed version of {@link useHookWithMap}
 */
export const useAuthedHookWithMap = <
  Args extends [string | undefined, ...AnyTupleTail],
  Subset extends GetSubset<Args>,
  FirstLeft,
  FirstRight,
  SecondLeft,
  SecondRight,
>(
  useHook: (...args: Args) => OptionEither<SecondLeft, SecondRight>,
  value: OptionEither<FirstLeft, FirstRight>,
  map: (value: FirstRight | undefined) => Subset,
) =>
  useHookWithMappedStruct(
    useHook,
    {accessToken: useAccessToken(), value},
    ({accessToken, value}) => [accessToken, ...map(value)] as unknown as Args,
  )

/**
 * Curried version of {@link useAuthedHookWithMap}
 */
export const createAuthedHookWithMap = <
  Args extends [string | undefined, ...AnyTupleTail],
  Subset extends GetSubset<Args>,
  SecondLeft,
  SecondRight,
>(
  useHook: (...args: Args) => OptionEither<SecondLeft, SecondRight>,
) =>
  pipe(
    createHookWithMappedStruct(useHook),
    (useHook) =>
      function useCurriedAuthedHookWithMap<FirstLeft, FirstRight>(
        value: OptionEither<FirstLeft, FirstRight>,
        map: (value: FirstRight | undefined) => Subset,
      ) {
        return useHook(
          {accessToken: useAccessToken(), value},
          ({accessToken, value}) =>
            [accessToken, ...map(value)] as unknown as Args,
        )
      },
  )

const emptyObject = {}

const accessTokenWithNoVars = (
  accessToken: string | undefined,
): [string | undefined, typeof emptyObject] => [accessToken, emptyObject]

/**
 * Implicitly authed hook for GQL operations that accept no variables
 */
export const useAuthedHook = <SecondLeft, SecondRight>(
  useHook: (
    accessToken: string | undefined,
    emptyVariables: Record<string, never>,
  ) => OptionEither<SecondLeft, SecondRight>,
) => useHookWithMap(useHook, useAccessToken(), accessTokenWithNoVars)

const thunk =
  <A extends ReadonlyArray<unknown>, B>(f: (...a: A) => B) =>
  (...a: A) =>
  () =>
    f(...a)

/**
 * Curried version of {@link useAuthedHook}
 */
export const createAuthedHook = thunk(useAuthedHook)

/**
 * Implicitly authed version of {@link useHookWithMappedStruct}
 */
export const useAuthedHookWithMappedStruct = <
  Args extends [string | undefined, ...AnyTupleTail],
  Subset extends GetSubset<Args>,
  SecondLeft,
  SecondRight,
  NER extends RecOfOE,
>(
  useHook: (...args: Args) => OptionEither<SecondLeft, SecondRight>,
  struct: EnforceNonEmptyRecord<NER>,
  map: (value: SeqWithValues<NER>[1]) => Subset,
): SeqWithValues<
  NER & {__accessToken: UseAccessTokenResult}
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
>[0] extends OptionEither<infer FirstLeft, any>
  ? OptionEither<FirstLeft | SecondLeft, SecondRight>
  : never => {
  return useHookWithMappedStruct(
    useHook,
    {...struct, __accessToken: useAccessToken()} as EnforceNonEmptyRecord<
      NER & {__accessToken: UseAccessTokenResult}
    >,
    ({__accessToken, ...values}) =>
      [
        __accessToken,
        ...map(values as SeqWithValues<NER>[1]),
      ] as unknown as Args,
  )
}

/**
 * Curried version of {@link useAuthedHookWithMappedStruct}
 */
export const createAuthedHookWithMappedStruct = <
  Args extends [string | undefined, ...AnyTupleTail],
  Subset extends GetSubset<Args>,
  SecondLeft,
  SecondRight,
>(
  useHook: (...args: Args) => OptionEither<SecondLeft, SecondRight>,
) =>
  function useCurriedAuthedHookWithMappedStruct<NER extends RecOfOE>(
    struct: EnforceNonEmptyRecord<NER>,
    map: (value: SeqWithValues<NER>[1]) => Subset,
  ): SeqWithValues<
    NER & {__accessToken: UseAccessTokenResult}
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  >[0] extends OptionEither<infer FirstLeft, any>
    ? OptionEither<FirstLeft | SecondLeft, SecondRight>
    : never {
    return useAuthedHookWithMappedStruct(useHook, struct, map)
  }
