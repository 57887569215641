import type {ReactElement} from "react"
import {memo} from "react"
import {materialGroupLabelMap} from "src/utils/label-maps/material-group"
import {MaterialGroup} from "generated/graphql"
import {Tooltip} from "../tooltip"
import {IconCircularContainer} from "./icon-circular-container"
import {SlPlantIcon} from "./streamline/plant"
import {SlSeedIcon} from "./streamline/seed"

interface MaterialGroupBadgeProps {
  group: MaterialGroup
  color?: string
  noTooltip?: boolean
}

export const MaterialGroupBadge = memo(function MaterialGroupBadge({
  group,
  color,
  noTooltip,
}: MaterialGroupBadgeProps): ReactElement {
  const icons = (
    <IconCircularContainer backgroundColor="transparent">
      {group === MaterialGroup.Plant ? (
        <SlPlantIcon color={color} />
      ) : /* Currently the 'MaterialGroup' enums are a bit off and can contain more than seed and plant*/
      /* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */
      group === MaterialGroup.Seed ? (
        <SlSeedIcon color={color} />
      ) : null}
    </IconCircularContainer>
  )

  return noTooltip === true ? (
    icons
  ) : (
    <Tooltip
      title={materialGroupLabelMap[group]}
      placement="top"
      describeChild
      tabIndex={0}
    >
      <div>{icons}</div>
    </Tooltip>
  )
})
