import {useRestoreTaxonMutation} from "generated/graphql"
import {
  type ReactNode,
  type RefObject,
  type MouseEventHandler,
  useState,
} from "react"
import {Button} from "@hortis/ui/button"
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@hortis/ui/dialog"
import {useSnackbarStore} from "src/components/snackbar-controller/snackbar-store"
import {stopPropagation} from "src/features/collection/components/stop-event-propagation"
import {onFailure, onSuccess} from "src/notification-snack-utils"

interface RestoreTaxonDialogProps {
  id: string
  children?: ReactNode
  open?: boolean
  setOpen?: (open: boolean) => void
  triggerRef?: RefObject<HTMLButtonElement>
  onRestore?: () => void
}

export const RestoreTaxonDialog = ({
  open: openExternal,
  setOpen: setOpenExternal,
  id,
  children,
  triggerRef,
  onRestore,
}: RestoreTaxonDialogProps) => {
  const [openInternal, setOpenInternal] = useState(false)
  const open = openExternal ?? openInternal
  const setOpen = setOpenExternal ?? setOpenInternal

  const {setSnack} = useSnackbarStore()
  const [_, restoreTaxon] = useRestoreTaxonMutation()

  const handleSubmit: MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.stopPropagation()
    const restoreArchiveResult = await restoreTaxon({
      id,
    })

    if (restoreArchiveResult.data?.restoreTaxon.success !== true) {
      if (
        restoreArchiveResult.data?.restoreTaxon.errors != null &&
        restoreArchiveResult.data.restoreTaxon.errors.length > 0
      ) {
        if (
          //check for duplicate scientific name error
          restoreArchiveResult.data.restoreTaxon.errors.some(
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            (error) => error.__typename === "DuplicateScientificNameError",
          )
        ) {
          onFailure(setSnack)(
            new Error(
              "Failed to restore taxon, a taxon with this scientific name already exists",
            ),
          )
        } else {
          //check for generic errors
          onFailure(setSnack)(
            new Error("Failed to restore taxon, please try again"),
          )
        }
      } else {
        onFailure(setSnack)(
          new Error("Failed to restore taxon, please try again"),
        )
      }
      return
    }

    onSuccess(setSnack)("Successfully restored taxon")
    setOpen(false)
    onRestore?.()
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      {children}
      <DialogContent data-cy="restore-taxon-dialog" triggerRef={triggerRef}>
        <DialogHeader>
          <DialogTitle data-cy="restore-taxon-dialog-title">
            Restore taxon?
          </DialogTitle>
          <DialogDescription data-cy="restore-taxon-dialog-body">
            This will restore 1 taxon record.
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <DialogClose onClick={stopPropagation} asChild>
            <Button size="lg" fullWidth testId="restore-taxon-cancel">
              Cancel
            </Button>
          </DialogClose>
          <Button
            size="lg"
            onClick={handleSubmit}
            variant="primary"
            testId="restore-taxon-confirm"
            fullWidth
          >
            Restore
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
