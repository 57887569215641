import {TwicImg} from "@twicpics/components/react"
import type {ReactElement} from "react"
import {useCallback, useState} from "react"
import {Lightbox, LightboxTrigger} from "src/components/lightbox"
import type {MaterialImageFieldsFragment} from "../../../../../../../generated/graphql"
import {ImageProgress} from "../image-progress"
import type {LocalImage} from "../types"
import {FeaturedChip} from "./featured-chip"

export interface PhotoCardProps {
  materialImage:
    | ({type: "external"} & MaterialImageFieldsFragment)
    | ({type: "local"} & LocalImage)
  featured?: boolean
  photos?: ReadonlyArray<MaterialImageFieldsFragment>
  scientificName?: string
}

export const PhotoCard = ({
  materialImage,
  featured,
  photos,
  scientificName,
}: PhotoCardProps): ReactElement => {
  // eslint-disable-next-line react/hook-use-state
  const [_, setHovered] = useState(false)
  const handleHover = useCallback(() => {
    setHovered(true)
  }, [])
  const handleHoverLeave = useCallback(() => {
    setHovered(false)
  }, [])

  const photo =
    materialImage.type === "external" ? materialImage : materialImage.savedImage

  return (
    <div data-cy="image-card">
      <div className="relative aspect-square overflow-hidden rounded-lg">
        {materialImage.type === "local" && (
          <>
            <img
              src={materialImage.thumb}
              alt={materialImage.alt}
              className="absolute top-0 block size-full object-cover"
              data-cy="local-image"
            />
            <ImageProgress
              complete={materialImage.complete}
              progress={materialImage.progress}
              error={materialImage.error}
            />
          </>
        )}
        {photo != null && (
          <Lightbox photo={photo} photos={photos} title={scientificName}>
            <LightboxTrigger
              onMouseEnter={handleHover}
              onMouseLeave={handleHoverLeave}
              className="block size-full rounded-xl outline-none ring-primary-200/70 focus-visible:ring"
            >
              <TwicImg
                src={`${photo.bucketName}/${photo.fileName}`}
                ratio="1"
                mode="cover"
                eager
                placeholder="none"
                className="transition-transform hover:scale-[102%]"
              />
            </LightboxTrigger>
            <FeaturedChip featured={featured === true} />
          </Lightbox>
        )}
      </div>
    </div>
  )
}
