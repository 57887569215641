import {
  ArrowUpRight,
  BarChartSquare2,
  ChevronRight,
  LifeBuoy1,
  Map1,
  Settings1,
  Table,
} from "@hortis/ui/icons"
import type {MeCollectionSitesFieldsFragment} from "generated/graphql"
import type {ReactElement} from "react"
import {useCallback} from "react"
import {useSettingsReturnToStore} from "src/features/settings/return-to-store"
import {mapSlugToHrefs} from "src/utils/hooks/hrefs"
import {useLocation} from "src/utils/hooks/router"
import {useFirstSiteHrefs} from "../utils"
import {NavItem, NavItemLabel} from "./nav-item"

interface NavListProps {
  onNavigate?: () => void
  site?: MeCollectionSitesFieldsFragment
}

export const SiteNavList = ({onNavigate, site}: NavListProps): ReactElement => {
  const collectionSiteHrefs = useFirstSiteHrefs()
  const hrefs = site == null ? null : mapSlugToHrefs(site.urlSlug)

  return (
    <nav className="flex flex-col gap-0.5">
      {collectionSiteHrefs === undefined ? undefined : (
        <NavItem
          href={`/${hrefs?.dashboard ?? collectionSiteHrefs.dashboard}`}
          onClick={onNavigate}
        >
          <BarChartSquare2 />
          <NavItemLabel label={"Dashboard"} />
        </NavItem>
      )}
      {collectionSiteHrefs === undefined ? undefined : (
        <NavItem
          href={`/${hrefs?.map ?? collectionSiteHrefs.map}`}
          onClick={onNavigate}
          activeOptions={{exact: false}}
        >
          <Map1 />
          <NavItemLabel label={"Map"} />
        </NavItem>
      )}
      {collectionSiteHrefs === undefined ? undefined : (
        <NavItem
          href={`/${hrefs?.collection ?? collectionSiteHrefs.collection}`}
          onClick={onNavigate}
        >
          <Table />
          <NavItemLabel label={"List"} />
        </NavItem>
      )}
    </nav>
  )
}

interface GlobalNavListProps {
  onNavigate?: () => void
}

export const GlobalNavList = ({
  onNavigate,
}: GlobalNavListProps): ReactElement => {
  const location = useLocation()
  const {setReturnTo} = useSettingsReturnToStore()

  const handleNavigate = useCallback(() => {
    setReturnTo(location.pathname)
    if (onNavigate != null) {
      onNavigate()
    }
  }, [onNavigate, setReturnTo, location])

  return (
    <nav className="flex flex-col gap-0.5">
      <NavItem
        href="https://support.hortis.com/support/home"
        target="_blank"
        onClick={onNavigate}
        color="primary"
      >
        <LifeBuoy1 />
        <NavItemLabel label={"Support"} />
        <ArrowUpRight className="h-4 w-4 !text-grey-400" />
      </NavItem>

      <NavItem
        href={`/settings/org/general`}
        onClick={handleNavigate}
        color="primary"
        data-cy="settings-nav-item"
      >
        <Settings1 />
        <NavItemLabel label={"Settings"} />
        <ChevronRight className="h-4 w-4 !text-grey-400" />
      </NavItem>
    </nav>
  )
}
