import {A, D, F, pipe} from "@mobily/ts-belt"
import type {RowSelectionState} from "@tanstack/react-table"
import type {
  AccessionsListFieldsFragment,
  MaterialsListFieldsFragment,
  TaxaListFieldsFragment,
} from "generated/graphql"
import {
  useCallback,
  useEffect,
  useState,
  type SetStateAction,
  type SyntheticEvent,
} from "react"
import {
  useSnackbarStore,
  type SetSnack,
} from "src/components/snackbar-controller/snackbar-store"
import {
  areAllSelected,
  type Item,
} from "src/features/collection/components/are-all-selected"
import {areSomeSelected} from "src/features/collection/components/are-some-selected"
import {fromBooleanEntries} from "src/features/collection/components/plant-materials/materials-table"
import {usePrevious} from "../previous"
import {useToggle} from "../toggle"

export const useSelect = <T, O, S>({
  resetRowSelection,
  filterCounts,
  sort,
  search,
}: {
  resetRowSelection: () => void
  filterCounts: T
  sort: O
  search: S
}) => {
  const prevFilterCounts = usePrevious(filterCounts)
  const prevSort = usePrevious(sort)
  const prevSearch = usePrevious(search)
  const [selectMode, toggleSelectMode] = useToggle()

  const toggleSelectMode_ = useCallback(() => {
    if (selectMode) {
      resetRowSelection()
    }
    toggleSelectMode()
  }, [toggleSelectMode, selectMode, resetRowSelection])

  useEffect(() => {
    if (
      !F.equals(prevFilterCounts, filterCounts) ||
      !F.equals(prevSort, sort) ||
      !F.equals(prevSearch, search)
    ) {
      resetRowSelection()
    }
  }, [
    filterCounts,
    resetRowSelection,
    sort,
    search,
    prevFilterCounts,
    prevSort,
    prevSearch,
  ])

  return {selectMode, toggleSelectMode: toggleSelectMode_}
}

export const getSelectedRowIds = (selectedRows: RowSelectionState) =>
  pipe(
    selectedRows,
    D.filterWithKey((_, value) => value),
    D.keys,
  )

export const getSelectedRowCount = (selectedRows: RowSelectionState) =>
  pipe(
    selectedRows,
    D.filterWithKey((_, value) => value),
    D.values,
    A.length,
  )

export const updateMultiSelection = (
  data: Array<Item> | ReadonlyArray<Item>,
  setState: (value: SetStateAction<Record<string, boolean>>) => void,
) => {
  setState((selected) => {
    const allSelected = areAllSelected(data, selected)
    const someSelected = areSomeSelected(data, selected)

    // if all or some are selected, we want to deselect all
    // if none are selected, we want to select all
    return allSelected || someSelected
      ? fromBooleanEntries(data, ({id}) => [id, false])
      : fromBooleanEntries(data, ({id}) => [id, true])
  })
}

/**  A reusable handler for selection of rows within a table that displays an error snack if the 300 row limit is reached */
const handleMaxRowSelection = ({
  id,
  currentlySelectedRowIds,
  setSelectedRows,
  setSnack,
}: {
  id:
    | TaxaListFieldsFragment["id"]
    | AccessionsListFieldsFragment["id"]
    | MaterialsListFieldsFragment["id"]
  currentlySelectedRowIds: ReadonlyArray<string>
  setSnack: SetSnack
  setSelectedRows: (
    value: React.SetStateAction<Record<string, boolean>>,
  ) => void
}) => {
  if (currentlySelectedRowIds.length < 300) {
    setSelectedRows((selected) => ({
      ...selected,
      [id]: !(selected[id] ?? false),
    }))
  } else {
    setSnack({
      type: "alert",
      data: {
        severity: "error",
        text: "Maximum selection reached (300)",
      },
    })
  }
}

export const useSelectMode = <T extends Item>({
  selectedRows,
  setSelectedRows,
  isMobile,
  items,
}: {
  selectedRows: Record<string, boolean>
  setSelectedRows: React.Dispatch<React.SetStateAction<Record<string, boolean>>>
  isMobile: boolean
  items: Array<T> | ReadonlyArray<T> | undefined | null
}) => {
  const {setSnack} = useSnackbarStore()
  const [isSelectMode, setIsSelectMode] = useState(false)
  const currentlySelectedRowIds = pipe(
    D.filterWithKey(selectedRows, (key, value) => value),
    D.keys,
  )

  const resetSelectedRows = useCallback(() => {
    setSelectedRows({})
  }, [setSelectedRows])

  const handleSelectModeChange = useCallback(() => {
    setIsSelectMode((prev) => {
      if (prev) {
        // Clear selected rows if we leave select mode
        setSelectedRows({})
      }
      return !prev
    })
  }, [setSelectedRows])

  const handleRowSelection = useCallback(
    (_: SyntheticEvent, id: string) => {
      handleMaxRowSelection({
        id,
        currentlySelectedRowIds,
        setSnack,
        setSelectedRows,
      })
    },
    [currentlySelectedRowIds, setSelectedRows, setSnack],
  )

  const handleSelectAll = useCallback(() => {
    if (items != null) {
      updateMultiSelection(items, setSelectedRows)
    }
  }, [items, setSelectedRows])

  useEffect(() => {
    // If we have any selected rows, we are in select mode
    if (currentlySelectedRowIds.length > 0) {
      setIsSelectMode(true)
    } else if (!isMobile) {
      // when we're on mobile, we want to keep the select mode on until the user explicitly turns it off
      setIsSelectMode(false)
    }
  }, [currentlySelectedRowIds.length, isMobile, selectedRows])

  return {
    isSelectMode,
    handleRowSelection,
    resetSelectedRows,
    handleSelectAll,
    handleSelectModeChange,
    currentlySelectedRowIds,
  }
}
