import * as Bool from "fp-ts/boolean"
import * as Eq from "fp-ts/Eq"
import {pipe} from "fp-ts/function"
import * as RA from "fp-ts/ReadonlyArray"

type RegExpSpace = {signedNumeric: boolean; decimal: boolean}

const RegExpSpaceEq: Eq.Eq<RegExpSpace> = Eq.struct({
  signedNumeric: Bool.Eq,
  decimal: Bool.Eq,
})

/**
 * TODO: a name describing the purpose of this RegExp would be useful!
 */
const createRegExp = ({signedNumeric, decimal}: RegExpSpace) =>
  new RegExp(`^${signedNumeric ? "-?" : ""}\\d*${decimal ? "\\.?" : ""}\\d*$`)

const enumeratedRegExpDimensions: ReadonlyArray<RegExpSpace> = pipe(
  RA.Do,
  RA.apS("signedNumeric", [true, false]),
  RA.apS("decimal", [true, false]),
)

/**
 * Creating RegExps is really expensive - creating only a few dynamic RegExps
 * can take anywhere from a few tens to a few hundred milliseconds.
 *
 * This means we don't want create a new RegExp every time a user types a letter!
 *
 * Given the small number of dimensions, let's enumerate each and return
 * a cached RegExp, lazily creating them on demand.
 * The worst part of this function is keeping the predicate at the bottom
 * in sync with the applicatives below - is there a similarly speedy way to
 * generically hash an object without having to spell out each property?
 *
 * TODO: can we find a better name for this? what does this regexp do?
 */
export const getRegExp = pipe(
  enumeratedRegExpDimensions,
  RA.reduceRight(
    (_coordinate: RegExpSpace): RegExp => {
      throw new Error(
        "RegExp not found - does the Eq instance represent all the dimensions?",
      )
    },
    (coordinate, next) => {
      let cachedRegExp: RegExp | undefined = undefined
      return (incoming: typeof coordinate): RegExp => {
        if (RegExpSpaceEq.equals(incoming, coordinate)) {
          return (cachedRegExp ??= createRegExp(coordinate))
        }
        return next(incoming)
      }
    },
  ),
)
