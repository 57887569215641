import * as t from "io-ts"

export const getTwoTupleFromDelimitedString = <Delimiter extends string>(
  delimiter: Delimiter,
) =>
  t.string.pipe(
    new t.Type<[string, string], string, string>(
      // eslint-disable-next-line no-secrets/no-secrets
      `TwoTupleFromStringDelimitedBy${delimiter}`,
      t.tuple([t.string, t.string]).is,
      (string, c) =>
        string.includes(delimiter)
          ? t.success(string.split(delimiter) as [string, string])
          : t.failure(string, c),
      (string) => string.join(delimiter),
    ),
  )
