import {IconButton} from "@hortis/ui/button"
import {ContextMenu, ContextMenuTrigger} from "@hortis/ui/context-menu"
import {FilterLines} from "@hortis/ui/icons"
import {R, pipe} from "@mobily/ts-belt"
import {getRouteApi} from "@tanstack/react-router"
import {useGetSiteTaxaQuery} from "generated/graphql"
import {useCallback, useEffect, useMemo} from "react"
import {List} from "src/components"
import {
  DataTable,
  DataTableFooter,
  DataTableRow,
  DataTableToolbar,
} from "src/components/data-table"
import {taxaColumns} from "src/components/data-table/columns/taxa-columns"
import {useTable} from "src/components/data-table/use-table"
import {ViewButton} from "src/components/data-table/view-button"
import {MobileDrawer} from "src/components/mobile-drawer"
import TaxaActionMenu from "src/features/actions-menu/variants/taxa-action-menu"
import {SearchBar} from "src/features/collection/components/search-bar/search-bar"
import {showErrorMessage} from "src/features/collection/components/show-error-message"
import {TaxaContextMenuContent} from "src/features/context-menu/variants/taxa-context-menu"
import {AddTaxaFilter} from "src/features/filters/taxa/add-taxa-filter"
import {TaxaMobileFilters} from "src/features/filters/taxa/mobile-filters"
import {TaxaFilterModals} from "src/features/filters/taxa/modals/filter-modals"
import {TaxaSortSelect} from "src/features/filters/taxa/sort-select"
import {TaxaFilters} from "src/features/filters/taxa/taxa-filters"
import {useTaxaFilters} from "src/features/filters/taxa/use-taxa-filters"
import {EmptyTaxaList} from "src/features/taxonomy/components/list/empty-list"
import {TaxaMobileList} from "src/features/taxonomy/components/list/mobile-list"
import {TaxaMoreButton} from "src/features/taxonomy/components/list/more-button"
import {taxaSearchMenuLabels} from "src/features/taxonomy/components/list/search-labels"
import {
  defaultColumnVisibility,
  filterColumnMap,
  sortColumnMap,
} from "src/features/taxonomy/components/list/table-config"
import {useMobile} from "src/utils/hooks/media-query"
import {usePlaceStruct} from "src/utils/hooks/place"
import {useFilterState} from "src/utils/hooks/tables/filter-state"
import {usePagination} from "src/utils/hooks/tables/pagination"
import {useRowClick} from "src/utils/hooks/tables/row-click"
import {useTableSearch} from "src/utils/hooks/tables/search"
import {getSelectedRowIds, useSelect} from "src/utils/hooks/tables/select-mode"
import {useSort} from "src/utils/hooks/tables/sort"
import {useToggle} from "src/utils/hooks/toggle"
import {createRelayParams} from "src/utils/pagination"
import {useAccessToken} from "src/utils/use-access-token"
import {useDownloadCsv} from "../download-accessions/use-download-csv"
import {processSiteTaxaQuery} from "./process-site-taxa-query"

interface TaxaListProps {
  downloadRequested: boolean
  resetDownloadRequested: () => void
}

const routeApi = getRouteApi("/_layout/sites/$siteSlug/collection")

export const TaxaList = ({
  downloadRequested,
  resetDownloadRequested, // eslint-disable-next-line sonarjs/cognitive-complexity
}: TaxaListProps) => {
  const isMobile = useMobile({defaultMatches: false})

  const {
    filters,
    preparedFilters,
    dispatchFilters,
    filterCounts,
    resetFilters,
  } = useTaxaFilters({
    persistenceKey: "site-taxa-filters",
  })

  const accessToken = useAccessToken()
  const navigate = routeApi.useNavigate()
  const {taxCursor, taxOrderBy, taxSearch, taxDir, rows} = routeApi.useSearch()
  const rowsPerPage = rows ?? 25

  const place = usePlaceStruct()
  const pause = place.data == null || accessToken._tag === "None"
  const [taxaQuery] = useGetSiteTaxaQuery({
    variables: {
      ...createRelayParams({cursor: taxCursor, dir: taxDir, rowsPerPage}),
      organisationSubdomain: place.data?.orgName ?? "",
      collectionSiteSlug: place.data?.siteSlug ?? "",
      searchTerm: taxSearch,
      orderBy: taxOrderBy,
      filter: preparedFilters,
    },
    pause,
  })

  const loading = (taxaQuery.fetching && taxaQuery.data == null) || pause

  const {error, data} = pipe(
    processSiteTaxaQuery({taxaQuery, place}),
    R.match(
      (data): {data: typeof data | null; error: string | null} => ({
        data,
        error: null,
      }),
      (error) => ({error, data: null}),
    ),
  )

  const handleRowsChange = useCallback(
    (rows: number | null) => {
      void navigate({search: (prev) => ({...prev, rows: rows ?? undefined})})
    },
    [navigate],
  )

  const dataMemo = useMemo(() => data?.nodes ?? [], [data?.nodes])

  const {table, ref, tableRef, mobileListRef} = useTable({
    columns: taxaColumns,
    data: dataMemo,
    tableId: "site-taxonomy-table",
    defaultColumnVisibility,
  })

  const {nextPageLoading, previousPageLoading, onPreviousPage, onNextPage} =
    usePagination({
      navigate,
      pageInfo: data?.pageInfo,
      cursor: taxCursor,
      fetching: taxaQuery.fetching,
      tableEl: tableRef,
      mobileListEl: mobileListRef.current,
      cursorKey: "taxCursor",
      dirKey: "taxDir",
    })

  const {selectMode, toggleSelectMode} = useSelect({
    resetRowSelection: table.resetRowSelection,
    filterCounts,
    sort: table.getState().sorting,
    search: taxSearch,
  })

  const {handleSearchClear, handleSearchSubmit, handleClearSearchAndFilters} =
    useTableSearch({
      navigate,
      search: taxSearch,
      resetSelectedRows: table.resetRowSelection,
      resetFilters,
      searchKey: "taxSearch",
      cursorKey: "taxCursor",
      dirKey: "taxDir",
    })

  const {
    updateOrderBy,
    viewMenuOpen,
    toggleViewMenuOpen,
    updateOrderByFromViewMenu,
  } = useSort({
    navigate,
    sorting: table.getState().sorting,
    resetSorting: table.resetSorting,
    setSorting: table.setSorting,
    orderBy: taxOrderBy,
    sortParamKey: "taxOrderBy",
    cursorKey: "taxCursor",
    dirKey: "taxDir",
    sortColumnMap,
  })

  const [actionsMenuOpen, toggleActionsMenuOpen] = useToggle()

  const selectedRecordsIDs = getSelectedRowIds(table.getState().rowSelection)

  const onRowClick = useRowClick<(typeof dataMemo)[number]>({
    urlFn: ({id}) => `/taxonomy/${id}`,
  })

  const handleDownload = useDownloadCsv({
    csvName: "site-taxa",
    path: "/download/site-taxa",
    body: {
      searchTerm: taxSearch,
      filter: {
        ...preparedFilters,
        id: {
          in: selectedRecordsIDs,
        },
      },
    },
  })

  useEffect(() => {
    if (downloadRequested) {
      void handleDownload()
      resetDownloadRequested()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- due to body prop, handleDownload does not have stable identity
  }, [downloadRequested, resetDownloadRequested])

  const {
    openedFilter,
    filterButtonRef,
    handleFilterModalClose,
    setOpenFilter,
    openedFilterToolbar,
  } = useFilterState({
    filterColumnMap,
    resetColumnFilters: table.resetColumnFilters,
    columnFilters: table.getState().columnFilters,
  })

  const [mobileFiltersExpanded, toggleMobileFiltersExpanded] = useToggle()

  const emptyState = useMemo(
    () => (
      <EmptyTaxaList
        list={data?.nodes ?? undefined}
        searchTerm={taxSearch}
        clearSearch={handleClearSearchAndFilters}
        filtersActive={filterCounts.total > 0}
      />
    ),
    [handleClearSearchAndFilters, data?.nodes, filterCounts.total, taxSearch],
  )

  return (
    <>
      {error != null && !loading ? (
        showErrorMessage(new Error(error))
      ) : isMobile ? (
        <div className="flex h-full flex-col overflow-hidden">
          <List.Toolbar>
            <div className="flex items-center gap-2">
              <SearchBar
                testId="search-form"
                menuLabels={taxaSearchMenuLabels}
                handleSubmitCallback={handleSearchSubmit}
                handleClearCallback={handleSearchClear}
                searchTermOverride={taxSearch}
                className="flex-1"
              />
              <IconButton
                icon={FilterLines}
                ariaLabel="Filters"
                testId="filters-button-mobile"
                onClick={toggleMobileFiltersExpanded}
                size="sm"
              />
              <MobileDrawer
                testId="mobile-drawer"
                open={mobileFiltersExpanded}
                onClose={toggleMobileFiltersExpanded}
                content={
                  <TaxaMobileFilters
                    filters={filters}
                    dispatchFilters={dispatchFilters}
                    resetFilters={resetFilters}
                    filterCount={filterCounts.total}
                    taxaType="site"
                    updateOrderBy={updateOrderBy}
                    orderBy={taxOrderBy}
                  />
                }
              />

              <TaxaMoreButton searchTerm={taxSearch} filter={preparedFilters} />
            </div>
          </List.Toolbar>
          <TaxaMobileList
            table={table}
            selectMode={selectMode}
            emptyState={emptyState}
            testIdPrefix="taxa"
          />
          <List.Footer
            selectedRowCount={selectedRecordsIDs.length}
            handleDownload={handleDownload}
            selectAllRows={() => {
              const currentPageRecordsCount =
                table.getSelectedRowModel().rows.length
              table.toggleAllPageRowsSelected(currentPageRecordsCount <= 0)
            }}
            selectMode={selectMode}
            toggleSelectMode={toggleSelectMode}
            onActionsMenu={toggleActionsMenuOpen}
            onPreviousPage={onPreviousPage}
            onNextPage={onNextPage}
            total={data?.taxaTotal}
            hasPreviousPage={data?.pageInfo.hasPreviousPage}
            hasNextPage={data?.pageInfo.hasNextPage}
            previousPageLoading={previousPageLoading}
            nextPageLoading={nextPageLoading}
            testIdPrefix="taxa"
          />
        </div>
      ) : (
        <>
          <TaxaFilterModals
            openFilter={openedFilterToolbar ?? openedFilter?.id ?? false}
            handleFilterModalClose={handleFilterModalClose}
            filterModalAnchorEl={
              openedFilterToolbar == null
                ? openedFilter?.value
                : filterButtonRef
            }
            dispatchFilters={dispatchFilters}
            taxaType="site"
          />
          <DataTableToolbar className="flex flex-col gap-4">
            <div className="flex flex-col gap-5">
              <div className="flex flex-1 items-center justify-between gap-3">
                <SearchBar
                  testId="search-form"
                  menuLabels={taxaSearchMenuLabels}
                  handleSubmitCallback={handleSearchSubmit}
                  handleClearCallback={handleSearchClear}
                  searchTermOverride={taxSearch}
                  className="max-w-md flex-1"
                />
                <div className="flex items-center gap-3">
                  <AddTaxaFilter
                    setOpenFilter={setOpenFilter}
                    align="end"
                    buttonProps={{
                      variant: "secondaryGray",
                      size: "sm",
                      className: "",
                      testId: "add-filter-button",
                      startIcon: FilterLines,
                      ref: filterButtonRef,
                      children: "Filter",
                    }}
                  />
                  <ViewButton
                    table={table}
                    defaultColumnVisibility={defaultColumnVisibility}
                    open={viewMenuOpen}
                    toggleOpen={toggleViewMenuOpen}
                    aboveMenu={
                      <div className="border-b border-grey-200 px-4 py-4">
                        <TaxaSortSelect
                          fullWidth
                          buttonProps={{size: "xs"}}
                          orderBy={taxOrderBy}
                          updateOrderBy={updateOrderByFromViewMenu}
                        />
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
          </DataTableToolbar>
          {filterCounts.total > 0 && (
            <DataTableToolbar className="flex border-t border-grey-200">
              <TaxaFilters
                filters={filters}
                dispatchFilters={dispatchFilters}
                resetFilters={resetFilters}
                filterCount={filterCounts.total}
                updateOrderBy={updateOrderBy}
                orderBy={taxOrderBy}
                taxaType="site"
              />
            </DataTableToolbar>
          )}
          <DataTable
            table={table}
            ref={ref}
            loading={loading}
            emptyState={emptyState}
          >
            {table.getRowModel().rows.map((row) => (
              <ContextMenu key={row.id}>
                <ContextMenuTrigger asChild>
                  <DataTableRow
                    data-cy="taxa-table-row"
                    key={row.id}
                    row={row}
                    onClick={(e) => {
                      onRowClick(e, row.original)
                    }}
                  />
                </ContextMenuTrigger>

                <TaxaContextMenuContent
                  isOrg={false}
                  isTrash={false}
                  taxSearch={taxSearch}
                  preparedFilters={preparedFilters}
                  clickedRow={row.original}
                  selectedRecordsIDs={selectedRecordsIDs}
                  onRequestDownload={handleDownload}
                />
              </ContextMenu>
            ))}
          </DataTable>
          <DataTableFooter
            table={table}
            onPreviousPage={onPreviousPage}
            onNextPage={onNextPage}
            total={data?.taxaTotal}
            hasPreviousPage={data?.pageInfo.hasPreviousPage}
            hasNextPage={data?.pageInfo.hasNextPage}
            onRequestDownload={handleDownload}
            onActionsMenu={toggleActionsMenuOpen}
            previousPageLoading={previousPageLoading}
            nextPageLoading={nextPageLoading}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsChange}
            testIdPrefix="taxa"
            fetching={taxaQuery.fetching}
          />
        </>
      )}

      {selectedRecordsIDs.length > 0 && (
        <TaxaActionMenu
          isOrg={false}
          selectedRecordsIDs={selectedRecordsIDs}
          open={actionsMenuOpen}
          toggleOpen={toggleActionsMenuOpen}
          onRequestDownload={handleDownload}
        />
      )}
    </>
  )
}
