import {type VariantProps, cva} from "class-variance-authority"
import {
  forwardRef,
  type ElementRef,
  type HTMLAttributes,
  type PropsWithChildren,
} from "react"
import {twMerge} from "tailwind-merge"

const alertVariants = cva(
  "rounded-lg p-4 border flex gap-3 flex-col sm:flex-row",
  {
    variants: {
      color: {
        primary:
          "border-primary-300 text-primary-600 bg-primary-25  [&_.alert-text]:text-primary-700 [&_.alert-actions>button:first-child]:text-primary-500 [&_.alert-actions>button:first-child:hover]:text-primary-600 [&_.alert-actions>button:last-child]:text-primary-700 [&_.alert-actions>button:last-child:hover]:text-primary-900",
        grey: "border-grey-300 text-grey-600 bg-grey-25 [&_.alert-text]:text-grey-700 [&_.alert-actions>button:first-child]:text-grey-500 [&_.alert-actions>button:first-child:hover]:text-grey-600 [&_.alert-actions>button:last-child]:text-grey-700 [&_.alert-actions>button:last-child:hover]:text-grey-900",
        error:
          "border-error-300 text-error-600 bg-error-25 [&_.alert-text]:text-error-700 [&_.alert-actions>button:first-child]:text-error-500 [&_.alert-actions>button:first-child:hover]:text-error-600 [&_.alert-actions>button:last-child]:text-error-700 [&_.alert-actions>button:last-child:hover]:text-error-900",
        warning:
          "border-warning-300 text-warning-600 bg-warning-25 [&_.alert-text]:text-warning-700 [&_.alert-actions>button:first-child]:text-warning-500 [&_.alert-actions>button:first-child:hover]:text-warning-600 [&_.alert-actions>button:last-child]:text-warning-700 [&_.alert-actions>button:last-child:hover]:text-warning-900",
        success:
          "border-success-300 text-success-600 bg-success-25 [&_.alert-text]:text-success-700 [&_.alert-actions>button:first-child]:text-success-500 [&_.alert-actions>button:first-child:hover]:text-success-600 [&_.alert-actions>button:last-child]:text-success-700 [&_.alert-actions>button:last-child:hover]:text-success-900",
      },
    },
    defaultVariants: {
      color: "primary",
    },
  },
)

const Alert = forwardRef<
  ElementRef<"div">,
  PropsWithChildren<HTMLAttributes<HTMLDivElement>> &
    VariantProps<typeof alertVariants>
>(({color, className, ...props}) => {
  return (
    <div className={twMerge(alertVariants({color}), className)} {...props} />
  )
})
// @ts-expect-error -- Reclared forwardRef does not have a displayName
Alert.displayName = "Alert"

const AlertContent = ({
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) => (
  <div className={twMerge("flex flex-col gap-1", className)} {...props} />
)

const AlertText = ({className, ...props}: HTMLAttributes<HTMLDivElement>) => (
  <div
    className={twMerge("alert-text text-sm font-medium", className)}
    {...props}
  />
)

const AlertSupportText = ({
  className,
  ...props
}: HTMLAttributes<HTMLParagraphElement>) => (
  <p className={twMerge("text-sm", className)} {...props} />
)

const AlertActions = ({
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) => (
  <div
    className={twMerge("alert-actions flex gap-3 pt-3", className)}
    {...props}
  />
)

export {Alert, AlertContent, AlertText, AlertSupportText, AlertActions}
