import {gql} from "graphql-request"

export const GET_PERMISSIONS = gql`
  query GetUserPermissions($subdomain: String!, $collectionSiteSlug: String) {
    permissions(
      organisationSubdomain: $subdomain
      collectionSiteSlug: $collectionSiteSlug
    ) {
      isInvalidOrganisation
      isInvalidCollectionSite
      deactivated
    }
  }
`
