import {z} from "zod"

export const errorMap: z.ZodErrorMap = (error, ctx) => {
  if (error.path[0] === "deaccessionDate") {
    return {message: "Please enter a valid date"}
  }
  if (error.message != null) {
    return {message: error.message}
  }
  return {message: ctx.defaultError}
}

export const DeaccessionFormSchema = z.object({
  deaccessionDate: z.date(),
  setPlantMaterialsToAbsent: z.boolean(),
})

export type DeaccessionForm = z.TypeOf<typeof DeaccessionFormSchema>
