import {type VariantProps, cva} from "class-variance-authority"
import {twMerge} from "tailwind-merge"

export const badgeVariants = cva(
  "inline-flex items-center gap-x-1.5 rounded-xl font-medium mix-blend-multiply",
  {
    variants: {
      size: {
        sm: "px-2 py-0.5 text-xs",
        md: "px-2.5 py-0.5 text-sm",
      },
      color: {
        primary: "bg-primary-50",
        error: "bg-error-50",
        success: "bg-success-50",
        warning: "bg-warning-50",
        grey: "bg-grey-100",
      },
    },
    defaultVariants: {
      size: "sm",
      color: "primary",
    },
  },
)

export type BadgeProps = {
  testId?: string
  type?: "dot" | "text" | "icon"
  children: React.ReactNode
} & React.HTMLAttributes<HTMLDivElement> &
  VariantProps<typeof badgeVariants>

export const Badge = ({
  testId,
  color,
  size,
  type = "text",
  children,
  className,
  ...props
}: BadgeProps) => {
  return (
    <div
      {...props}
      className={twMerge(badgeVariants({size, color}), className)}
      data-cy={testId}
    >
      {type === "dot" && (
        <div
          className={twMerge(
            cva("bg-grey-400 m-[1px] h-1.5 w-1.5 rounded-lg", {
              variants: {
                color: {
                  primary: "bg-primary-400",
                  error: "bg-error-400",
                  success: "bg-success-400",
                  warning: "bg-warning-400",
                  grey: "bg-grey-400",
                },
              },
            })({color}),
          )}
        />
      )}
      <span
        className={twMerge(
          cva("truncate", {
            variants: {
              color: {
                primary: "text-primary-700",
                error: "text-error-700",
                success: "text-success-700",
                warning: "text-warning-700",
                grey: "text-grey-700",
              },
            },
          })({color}),
        )}
      >
        {children}
      </span>
    </div>
  )
}
