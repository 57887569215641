import {
  MaterialNumberQueryParam,
  MaterialNumberSlash,
} from "../../../../utils/codecs/material-number"

export type Delimiter = "/" | "_"

/**
 * Shim for picking one of the MaterialNumberCodecs to preserve the API
 */
export const createMaterialNumber = (
  accessionNumber: string,
  materialQualifier: string,
  delimiter: Delimiter = "_",
): string =>
  (delimiter === "_" ? MaterialNumberQueryParam : MaterialNumberSlash).encode({
    accessionNumber,
    materialQualifier,
  })

export const parseMaterialNumber = (
  materialNumber: string,
  delimiter: Delimiter = "_",
): {accessionNumber: string; qualifier: string} | undefined => {
  const parsed = materialNumber.split(delimiter)
  const accessionNumber = parsed[0]
  const qualifier = parsed[1]
  return accessionNumber == null || qualifier == null
    ? undefined
    : {
        accessionNumber,
        qualifier,
      }
}
