import * as SheetPrimitive from "@radix-ui/react-dialog"
import {cva, type VariantProps} from "class-variance-authority"
import {
  type ComponentPropsWithoutRef,
  type ElementRef,
  type HTMLAttributes,
  forwardRef,
} from "react"
import {twMerge} from "tailwind-merge"
import {IconButton} from "../button"

import {XClose} from "../icons"

const Sheet = SheetPrimitive.Root

const SheetTrigger = SheetPrimitive.Trigger

const SheetClose = SheetPrimitive.Close

const SheetPortal = SheetPrimitive.Portal

const SheetOverlay = forwardRef<
  ElementRef<typeof SheetPrimitive.Overlay>,
  ComponentPropsWithoutRef<typeof SheetPrimitive.Overlay>
>(({className, ...props}, ref) => (
  <SheetPrimitive.Overlay
    className={twMerge(
      "data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0  fixed inset-0 z-50 bg-black/30",
      className,
    )}
    {...props}
    ref={ref}
  />
))
// @ts-expect-error -- Reclared forwardRef does not have a displayName
SheetOverlay.displayName = SheetPrimitive.Overlay.displayName

const sheetVariants = cva(
  "fixed z-50 bg-white shadow-lg overflow-hidden transition border-grey-200 ease-in-out data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:duration-300 data-[state=open]:duration-500 outline-none",
  {
    variants: {
      side: {
        top: "inset-x-0 top-0 data-[state=closed]:slide-out-to-top data-[state=open]:slide-in-from-top",
        bottom:
          "inset-x-0 bottom-0 data-[state=closed]:slide-out-to-bottom data-[state=open]:slide-in-from-bottom",
        left: "inset-y-0 left-0 h-full w-11/12 data-[state=closed]:slide-out-to-left data-[state=open]:slide-in-from-left sm:max-w-md",
        right:
          "flex flex-col inset-y-0 right-0 h-full w-11/12 data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right sm:max-w-md",
      },
    },
    defaultVariants: {
      side: "right",
    },
  },
)

interface SheetContentProps
  extends ComponentPropsWithoutRef<typeof SheetPrimitive.Content>,
    VariantProps<typeof sheetVariants> {}

const SheetContent = forwardRef<
  ElementRef<typeof SheetPrimitive.Content>,
  SheetContentProps
>(({side, className, children, ...props}, ref) => (
  <SheetPortal>
    <SheetOverlay />
    <SheetPrimitive.Content
      ref={ref}
      className={twMerge(sheetVariants({side}), className)}
      {...props}
    >
      {children}
    </SheetPrimitive.Content>
  </SheetPortal>
))
// @ts-expect-error -- Reclared forwardRef does not have a displayName
SheetContent.displayName = SheetPrimitive.Content.displayName

const SheetHeader = ({
  className,
  children,
  ...props
}: HTMLAttributes<HTMLDivElement>) => (
  <div
    className={twMerge(
      "border-grey-200 relative flex flex-col border-b px-4 py-4 pr-14 sm:px-6",
      className,
    )}
    {...props}
  >
    {children}
    <SheetClose asChild className="absolute right-3 top-3.5 sm:right-6">
      <IconButton
        icon={XClose}
        ariaLabel="Close"
        size="xs"
        variant="tertiaryGray"
        data-cy="close-sheet"
      />
    </SheetClose>
  </div>
)
SheetHeader.displayName = "SheetHeader"

const SheetForm = ({className, ...props}: HTMLAttributes<HTMLFormElement>) => (
  <form
    className={twMerge("flex h-full flex-col overflow-hidden", className)}
    {...props}
  />
)
const SheetFooter = ({className, ...props}: HTMLAttributes<HTMLDivElement>) => (
  <div
    className={twMerge(
      "border-grey-200 flex w-full flex-col-reverse gap-3 border-t px-4 py-4 sm:flex-row sm:justify-end sm:px-6",
      className,
    )}
    {...props}
  />
)

const SheetTitle = forwardRef<
  ElementRef<typeof SheetPrimitive.Title>,
  ComponentPropsWithoutRef<typeof SheetPrimitive.Title>
>(({className, ...props}, ref) => (
  <SheetPrimitive.Title
    ref={ref}
    className={twMerge("text-grey-900 text-lg font-medium", className)}
    data-cy="sheet-title"
    {...props}
  />
))
// @ts-expect-error -- Reclared forwardRef does not have a displayName
SheetTitle.displayName = SheetPrimitive.Title.displayName

const SheetDescription = forwardRef<
  ElementRef<typeof SheetPrimitive.Description>,
  ComponentPropsWithoutRef<typeof SheetPrimitive.Description>
>(({className, ...props}, ref) => (
  <SheetPrimitive.Description
    ref={ref}
    className={twMerge("text-grey-500 text-sm", className)}
    {...props}
  />
))
// @ts-expect-error -- Reclared forwardRef does not have a displayName
SheetDescription.displayName = SheetPrimitive.Description.displayName

const SheetBody = ({className, ...props}: HTMLAttributes<HTMLDivElement>) => (
  <div
    className={twMerge(
      "flex flex-1 flex-col overflow-y-auto px-4 py-6 sm:px-6",
      className,
    )}
    {...props}
  />
)
SheetBody.displayName = "SheetBody"

export {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetBody,
  SheetFooter,
  SheetHeader,
  SheetOverlay,
  SheetForm,
  SheetPortal,
  SheetTitle,
  SheetTrigger,
}
