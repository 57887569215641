import {type ReactNode} from "react"

export interface DataTableNullableCellProps<TItem> {
  value: TItem | null
  children?: ReactNode
}

export function DataTableNullableCell<TItem>({
  value,
  children,
}: DataTableNullableCellProps<TItem>) {
  return <>{value == null ? "-" : children ?? value}</>
}
