import type {ReactElement} from "react"
import {Button} from "@hortis/ui/button"

interface FilterModalButtonsProps {
  onCancel: () => void
  testIdPrefix?: string
}

export const FilterModalButtons = ({
  onCancel,
  testIdPrefix,
}: FilterModalButtonsProps): ReactElement => {
  return (
    <div className="flex flex-col gap-3 lg:flex-row">
      <Button
        size="sm"
        css={{flex: 1}}
        onClick={onCancel}
        testId={`${
          testIdPrefix === undefined ? "" : `${testIdPrefix}-`
        }cancel-button`}
      >
        Cancel
      </Button>
      <Button
        size="sm"
        variant="primary"
        css={{flex: 1}}
        type="submit"
        testId={`${
          testIdPrefix === undefined ? "" : `${testIdPrefix}-`
        }apply-button`}
      >
        Apply
      </Button>
    </div>
  )
}
