import type {SelectChangeEvent, SelectProps} from "@mui/material/Select"
import Select from "@mui/material/Select"
import {forwardRef, useCallback} from "react"
import {useController, useFormContext} from "react-hook-form"
import {shadows} from "src/shadows"
import {colors} from "src/colors"
import {ChevronDown} from "@hortis/ui/icons"
import {applyMui} from "../../utils/apply-mui"
import {useBindCallback} from "../../utils/hooks/callback"
import type {WithTestIdProps} from "../../utils/with-test-id"
import {withTestId} from "../../utils/with-test-id"
import type {FieldProps} from "../field-types"
import {generateLabelName, useId} from "../field-utils"
import {FormControl} from "../form-control"
import {OptionallyShowHelperText} from "../form-helper-text"
import {ThemedInputLabel} from "../text-field"

// TODO: `SelectProps` is actually generic over the selectable values... are we missing out here?
export type SelectFieldProps<T> = FieldProps<
  SelectProps<T> & {
    testId?: string
    jsonValue?: boolean
  }
>

const InternalSelectField = forwardRef(function InternalSelectField(
  {
    label,
    value,
    name,
    disabled,
    errorMessage,
    helperText,
    inputProps,
    startAdornment,
    endAdornment,
    placeholder,
    error = false,
    required = false,
    ...rest
  }: SelectFieldProps<unknown>,
  ref,
): JSX.Element {
  const internalId = useId(name)
  const id = rest.id ?? internalId
  const inputLabel = generateLabelName(label, required)

  return (
    <FormControl
      disabled={disabled}
      error={error}
      variant="outlined"
      sx={{
        "& .MuiInputBase-root.MuiOutlinedInput-root": {
          backgroundColor: colors.white,
          boxShadow: shadows.xs,
        },
        "& .MuiOutlinedInput-input": {
          py: "10px",
          px: "14px",
          color: colors.grey[900],
        },
        "& .MuiOutlinedInput-input:focus-within": {border: "none"},
        "& .MuiOutlinedInput-notchedOutline": {
          transition: `all 125ms ease-out`,
          border: `1px solid ${colors.grey[300]}`,
          boxShadow: `0 0 0 0px ${colors.primary[100]}`,
        },
        "& :not(.Mui-focused).MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
          {
            border: `1px solid ${colors.grey[400]}`,
          },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
          {
            border: `1px solid ${colors.primary[300]}`,
            boxShadow: `0 0 0 4px ${colors.primary[100]}`,
          },
      }}
    >
      <ThemedInputLabel label={label} id={id} required={required} />
      <Select<unknown>
        native
        id={id}
        value={value}
        ref={ref}
        label={inputLabel}
        placeholder={placeholder}
        inputProps={inputProps}
        startAdornment={startAdornment}
        endAdornment={endAdornment}
        notched={false}
        IconComponent={ChevronDown}
        sx={{
          "& .MuiNativeSelect-icon": {
            right: "14px",
            top: "calc(50% - 0.7rem)",
          },
        }}
        name={name}
        // this type is huge and really inefficient, so let's try and ignore it
        {...(rest as Record<string, unknown>)}
      />
      <OptionallyShowHelperText message={errorMessage} />
      <OptionallyShowHelperText message={helperText} />
    </FormControl>
  )
})

export const SelectField = applyMui(withTestId, InternalSelectField)

// best we can do because of limitations of react hook form
// and generic string 'literals'
type FormValues = {[Key in string]?: string | null}

export const FormSelectField = applyMui(
  withTestId,
  ({jsonValue, ...props}: SelectFieldProps<unknown>): JSX.Element => {
    const {name} = props
    const {control, clearErrors} = useFormContext<FormValues>()

    const {
      field: {value, onChange},
      fieldState: {error},
    } = useController<FormValues>({
      name,
      control,
    })

    const errorMessage = error ? error.message : undefined

    const resetError = useBindCallback(clearErrors, name)

    const handleChange = useCallback(
      (e: SelectChangeEvent<unknown>) => {
        if (e.target.value === "") {
          onChange(null)
        } else {
          onChange(
            jsonValue === true && typeof e.target.value === "string"
              ? JSON.parse(e.target.value)
              : e.target.value,
          )
        }
      },
      [onChange, jsonValue],
    )

    return (
      <InternalSelectField
        {...props}
        value={
          value === null
            ? ""
            : jsonValue === true
            ? JSON.stringify(value)
            : value
        }
        onChange={handleChange}
        error={Boolean(error)}
        errorMessage={errorMessage}
        onFocus={resetError}
      />
    )
  },
) as <T>(
  props: Omit<SelectFieldProps<T>, "data-cy"> & WithTestIdProps,
) => JSX.Element
