import type {withAuthenticationRequired as withAuthenticationRequiredAuth0} from "@auth0/auth0-react"
import type {ComponentType, FC} from "react"
import {withPermissions} from "../features/permissions/components/auth-guard"
import {withAuthentication} from "./with-authentication"
//
type WithAuthenticationRequiredAuth0 = typeof withAuthenticationRequiredAuth0
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyRecord = Record<string | number | symbol, any>

const withNoAuthentication: WithAuthenticationRequiredAuth0 = <
  P extends AnyRecord,
>(
  Component: ComponentType<React.PropsWithChildren<P>>,
) => Component as FC<React.PropsWithChildren<P>>

/**
 * If Cypress' auth workaround is enabled, check Cypress is running
 * and then check for the Cypress access token (both as effects at render time).
 *
 * If Cypress' auth workaround is not enabled, or Cypress is not detected as running,
 * use the Auth0 withAuthenticationRequired guard as usual.
 */
const withAuthenticationRequired: WithAuthenticationRequiredAuth0 =
  import.meta.env.NEXT_PUBLIC_DISABLE_AUTH_GUARDS === "1"
    ? withNoAuthentication
    : (Component, options) =>
        withAuthentication(withPermissions(Component), options)

export default withAuthenticationRequired
