import {forwardRef} from "react"

export const TagsTableHeader = forwardRef<HTMLDivElement>(
  function ListTableHeaderWithRef(_, ref) {
    return (
      <div
        className="col-span-12 grid border-b border-grey-200 bg-grey-50"
        ref={ref}
      >
        <div className="flex items-center px-6 py-3">
          <span className="text-xs font-medium text-grey-500">Tag</span>
        </div>
      </div>
    )
  },
)
