import {match} from "ts-pattern"
import * as uuid from "uuid"
import type {PlantMaterialFilter} from "generated/graphql"
import type {
  MaterialFilterType,
  LocalMaterialFilter,
  LocalMaterialFilterWithId,
} from "./types"

export interface PlantMaterialFilterState
  extends Omit<PlantMaterialFilter, "and"> {
  and: Array<LocalMaterialFilterWithId>
}

export const defaultState: PlantMaterialFilterState = {
  and: [],
}

export enum FilterActionTypes {
  ADD_FILTER,
  DELETE_FILTER,
  MODIFY_FILTER,
  MODIFY_ROOT_FILTER,
  RESET_FILTERS,
  RESET_FILTER_TYPE,
}

export type PlantMaterialFilterAction =
  | {
      type: FilterActionTypes.ADD_FILTER
      payload: LocalMaterialFilter
    }
  | {type: FilterActionTypes.DELETE_FILTER; payload: {id: string}}
  | {
      type: FilterActionTypes.MODIFY_FILTER
      payload: LocalMaterialFilterWithId
    }
  | {
      type: FilterActionTypes.MODIFY_ROOT_FILTER
      payload: Omit<PlantMaterialFilter, "and">
    }
  | {
      type: FilterActionTypes.RESET_FILTERS
      payload?: Partial<PlantMaterialFilterState>
    }
  | {
      type: FilterActionTypes.RESET_FILTER_TYPE
      payload: MaterialFilterType
    }

export function filterReducer(
  state: PlantMaterialFilterState,
  action: PlantMaterialFilterAction,
) {
  return match(action)
    .with({type: FilterActionTypes.ADD_FILTER}, ({payload}) => ({
      ...state,
      and: [...state.and, {...payload, id: uuid.v4()}],
    }))
    .with({type: FilterActionTypes.DELETE_FILTER}, ({payload}) => ({
      ...state,
      and: state.and.filter(({id}) => id !== payload.id),
    }))
    .with({type: FilterActionTypes.MODIFY_FILTER}, ({payload}) => ({
      ...state,
      and: state.and.map((filter) =>
        filter.id === payload.id ? payload : filter,
      ),
    }))
    .with({type: FilterActionTypes.MODIFY_ROOT_FILTER}, ({payload}) => ({
      ...state,
      ...payload,
    }))
    .with({type: FilterActionTypes.RESET_FILTERS}, ({payload}) => ({
      ...defaultState,
      ...payload,
    }))
    .with(
      {type: FilterActionTypes.RESET_FILTER_TYPE},
      ({payload: filterType}) => ({
        ...state,
        and: state.and.filter((filter) =>
          match(filterType)
            .with("status", () => filter.status == null)
            .with("location", () => filter.location == null)
            .with("notes", () => filter.notes == null)
            .with("material-group", () => filter.materialGroup == null)
            .with("tag-number", () => filter.tagNumber == null)
            .with("tags", () => filter.tags == null)
            .with("family", () => filter.family == null)
            .with("condition", () => filter.condition == null)
            .with("sex", () => filter.sex == null)
            .with("quantity", () => filter.massPlanting == null)
            .with("provenance", () => filter.accession?.provenance == null)
            .with("donor", () => filter.accession?.donor == null)
            .with(
              "commonName",
              () => filter.accession?.taxon?.commonName == null,
            )
            .with("photos", () => filter.hasImages == null)
            .with("lastObserved", () => filter.lastObserved == null)
            .with("firstPresent", () => filter.firstPresent == null)
            .with("firstAbsent", () => filter.firstAbsent == null)
            .with("redListStatus", () => filter.redListStatus == null)
            .with("nativeDistribution", () => filter.nativeDistribution == null)
            .with("lifeForms", () => filter.lifeForms == null)
            .with("climate", () => filter.climate == null)
            .with("public", () => filter.public == null)
            .with("creator", () => filter.creator == null)
            .with("lastEditor", () => filter.lastEditor == null)
            .exhaustive(),
        ),
      }),
    )
    .run()
}
