import {IconButton} from "@hortis/ui/button"
import {DialogContent, DialogHeader, DialogTitle} from "@hortis/ui/dialog"
import {Edit5, XClose} from "@hortis/ui/icons"
import {Tag} from "@hortis/ui/tag"
import {type RecordTagFieldsFragment} from "generated/graphql"
import {type RefObject} from "react"

interface RecordTagDialogProps {
  tags: ReadonlyArray<RecordTagFieldsFragment>
  triggerRef?: RefObject<HTMLButtonElement>
  editTags?: () => void
  closeDialog: () => void
  canEdit: boolean
}

export const RecordTagDialog = ({
  tags,
  triggerRef,
  editTags,
  closeDialog,
  canEdit,
}: RecordTagDialogProps) => {
  const onEdit = () => {
    closeDialog()
    editTags?.()
  }
  return (
    <DialogContent
      data-cy="record-tags-dialog"
      className="max-w-[512px] !gap-0"
      triggerRef={triggerRef}
    >
      <DialogHeader>
        <DialogTitle className="flex justify-between border-b border-grey-200 pb-2 ">
          Tags
          <div className="flex gap-2">
            {editTags && canEdit && (
              <IconButton
                ariaLabel={"edit-tags"}
                data-cy={"edit-tags-button"}
                variant="tertiaryGray"
                size={"xs"}
                onClick={onEdit}
                icon={Edit5}
              />
            )}
            <IconButton
              ariaLabel={"close-dialog"}
              data-cy={"close-dialog-button"}
              variant="tertiaryGray"
              onClick={closeDialog}
              size={"xs"}
              icon={XClose}
            />
          </div>
        </DialogTitle>
      </DialogHeader>
      <div className="max-h-[350px] overflow-y-scroll py-6">
        <div>
          <div className="flex flex-wrap gap-2 py-2">
            {tags.map((tag) => (
              <Tag data-cy={`record-tag-${tag.id}`} key={tag.id} size="lg">
                {tag.name}
              </Tag>
            ))}
          </div>
        </div>
      </div>
    </DialogContent>
  )
}
