import {zodResolver} from "@hookform/resolvers/zod"
import {
  useUpdateMaterialImageMutation,
  type MaterialImageFieldsFragment,
} from "generated/graphql"
import {useEffect, useState, type ReactNode} from "react"
import {FormProvider, useForm} from "react-hook-form"
import {Button} from "@hortis/ui/button"
import {BasicDatePicker} from "src/components/date-picker"
import {useSnackbarStore} from "src/components/snackbar-controller/snackbar-store"
import {FormTextField} from "src/components/text-field"
import {onFailure, onSuccess} from "src/notification-snack-utils"
import {convertDatesToISO} from "src/utils/convert-dates-to-iso"
import {parseUtcDate} from "src/utils/dates/parse-utc-date"
import {z} from "zod"
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from ".."

const schema = z.object({
  description: z.string().min(1).nullable(),
  capturedBy: z.string().min(1).nullable(),
  capturedAt: z.date().nullable(),
})
type EditMaterialImageSchema = z.TypeOf<typeof schema>

interface EditPhotoSheetProps {
  initialValue: MaterialImageFieldsFragment
  children: ReactNode
}

const createDefaultValues = (initialValue: MaterialImageFieldsFragment) => ({
  description: initialValue.description ?? null,
  capturedBy: initialValue.capturedBy ?? null,
  capturedAt:
    initialValue.capturedAt == null
      ? null
      : parseUtcDate(initialValue.capturedAt),
})

export const EditPhotoSheet = ({
  initialValue,
  children,
}: EditPhotoSheetProps) => {
  const [open, setOpen] = useState(false)
  const {setSnack} = useSnackbarStore()
  const [{fetching}, updateMaterialImage] = useUpdateMaterialImageMutation()
  const formMethods = useForm<EditMaterialImageSchema>({
    defaultValues: createDefaultValues(initialValue),
    criteriaMode: "all",
    resolver: zodResolver(schema),
  })

  useEffect(() => {
    formMethods.reset(createDefaultValues(initialValue))
  }, [initialValue, formMethods])

  const onSubmit = formMethods.handleSubmit(async (data) => {
    const res = await updateMaterialImage({
      materialImageId: initialValue.id,
      updates: convertDatesToISO(data),
    })

    if (
      res.error != null ||
      res.data?.updateMaterialImage?.__typename === "GenericError"
    ) {
      onFailure(setSnack)(new Error("Failed to update image"))
      return
    }

    onSuccess(setSnack)("Successfully updated image")
    setOpen(false)
  })

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>{children}</SheetTrigger>
      <SheetContent>
        <SheetHeader>
          <SheetTitle>Edit photo</SheetTitle>
        </SheetHeader>
        <FormProvider {...formMethods}>
          <form onSubmit={onSubmit}>
            <div className="flex flex-col gap-5 py-10">
              <FormTextField
                name="description"
                label="Description"
                testId="description-input"
              />
              <FormTextField
                name="capturedBy"
                label="Captured by"
                testId="captured-by-input"
              />
              <BasicDatePicker
                name="capturedAt"
                label="Captured at"
                testId="captured-at-input"
                PopperProps={{disablePortal: true}}
                DialogProps={{disablePortal: true}}
              />
            </div>
            <SheetFooter>
              <SheetClose asChild>
                <Button>Cancel</Button>
              </SheetClose>
              <Button
                type="submit"
                loading={fetching}
                variant="primary"
                testId="edit-photo-confirm-button"
              >
                Save
              </Button>
            </SheetFooter>
          </form>
        </FormProvider>
      </SheetContent>
    </Sheet>
  )
}
