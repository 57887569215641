import {MaterialGroup} from "generated/graphql"
import {useCallback, useState, type ChangeEventHandler} from "react"
import {useFormContext, useWatch} from "react-hook-form"
import {Button} from "@hortis/ui/button"
import {SwitchHorizontal1} from "@hortis/ui/icons"
import {Toggle} from "src/components/toggle"
import {InputAdornment} from "../../../../../components/adornment"
import {FormTextField} from "../../../../../components/text-field/text-field"

interface QuantityWeightFieldProps {
  formNamePrefix: string
}

type QuantityOrWeight = "quantity" | "weight"

const endAdornment = <InputAdornment position="end">grams</InputAdornment>

export const QuantityWeightField = ({
  formNamePrefix,
}: QuantityWeightFieldProps): JSX.Element => {
  const {getValues, setValue} = useFormContext<{
    [string: `${typeof formNamePrefix}materialGroup`]: MaterialGroup | null
    [string: `${typeof formNamePrefix}quantity`]: string | null
    [string: `${typeof formNamePrefix}weight`]: string | null
    [string: `${typeof formNamePrefix}massPlanting`]: boolean
  }>()
  const [quantityOrWeight, setQuantityOrWeight] = useState<QuantityOrWeight>(
    getValues(`${formNamePrefix}weight`) == null ? "quantity" : "weight",
  )
  const massPlanting = useWatch<
    Record<`${typeof formNamePrefix}massPlanting`, boolean>
  >({name: "massPlanting"})
  const materialGroup = useWatch<
    Record<`${typeof formNamePrefix}materialGroup`, MaterialGroup>
  >({name: "materialGroup"})

  // Make sure to reset the opposite value to null after a toggle
  const toggleQuantityOrWeight = useCallback(() => {
    if (quantityOrWeight === "weight") {
      setValue("weight", null)
      setQuantityOrWeight("quantity")
    } else {
      setValue("quantity", null)
      setQuantityOrWeight("weight")
    }
    setValue("massPlanting", false)
  }, [quantityOrWeight, setValue])

  const toggleMassPlanting = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      setValue("massPlanting", e.target.checked)
      setValue("quantity", null)
    },
    [setValue],
  )

  return (
    <div className="realtive flex flex-col items-start gap-3">
      <FormTextField
        controlSx={{display: quantityOrWeight === "quantity" ? "flex" : "none"}}
        label="Quantity"
        name={`${formNamePrefix}quantity`}
        testId="quantity"
        numeric
        fullWidth
        placeholder={massPlanting ? "Mass planting" : undefined}
        disabled={massPlanting}
      />
      <FormTextField
        controlSx={{display: quantityOrWeight === "weight" ? "flex" : "none"}}
        label="Weight"
        name={`${formNamePrefix}weight`}
        endAdornment={endAdornment}
        testId="weight"
        numeric
        decimal
        fullWidth
      />
      <div className="flex w-full items-center justify-between gap-3">
        <Button
          size="xs"
          startIcon={SwitchHorizontal1}
          variant="linkgray"
          testId="quantity-weight-toggle"
          onClick={toggleQuantityOrWeight}
        >
          Use {quantityOrWeight === "quantity" ? "weight" : "quantity"}
        </Button>
        {quantityOrWeight === "quantity" &&
          materialGroup !== MaterialGroup.Seed && (
            <label
              className="flex items-center gap-2 text-sm font-medium text-grey-500"
              data-cy="mass-planting-toggle"
            >
              Mass planting{" "}
              <Toggle
                size="sm"
                onChange={toggleMassPlanting}
                checked={massPlanting}
              />
            </label>
          )}
      </div>
    </div>
  )
}
