import CircularProgress from "@mui/material/CircularProgress"
import {flexRender} from "@tanstack/react-table"
import type {Row, RowData, Table} from "@tanstack/table-core"
import {
  forwardRef,
  type HTMLAttributes,
  useEffect,
  type ForwardedRef,
  type ReactNode,
} from "react"
import {colors} from "src/colors"
import {twMerge} from "tailwind-merge"
import {accessionColumns} from "./columns/accession-columns"

export const getRowId = (row: {id: string}) => row.id

export const useSelectAllKeyboardShortcuts = <TData extends RowData>(
  table: Table<TData>,
  disabled?: boolean,
) => {
  useEffect(() => {
    const isMac = navigator.userAgent.toUpperCase().includes("MAC")
    function onKeyDown(e: KeyboardEvent) {
      const activeElement = document.activeElement
      if (
        (activeElement?.tagName === "INPUT" &&
          ((activeElement as HTMLInputElement).type === "text" ||
            (activeElement as HTMLInputElement).type === "search")) ||
        activeElement?.tagName === "BUTTON"
      ) {
        return
      }
      if (e.key === "Escape") {
        e.stopPropagation()
        table.resetRowSelection()
      }
      if ((e.key === "a" && e.metaKey && isMac) || (e.ctrlKey && !isMac)) {
        table.toggleAllPageRowsSelected(true)
        e.preventDefault()
      }
    }
    if (disabled === true) {
      return
    }
    document.addEventListener("keydown", onKeyDown)
    return () => {
      document.removeEventListener("keydown", onKeyDown)
    }
  }, [table, disabled])
}

declare module "react" {
  function forwardRef<T, P = Record<string, unknown>>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null,
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null
}

export type DataTableProps<TData extends RowData> = {
  table: Table<TData>
  emptyState?: ReactNode
  loading?: boolean
  className?: string
  shortcutsDisabled?: boolean
  children: ReactNode
}

export const DataTable = forwardRef(function DataTableInner<
  TData extends RowData,
>(
  {
    table,
    emptyState,
    loading,
    className,
    shortcutsDisabled,
    children,
  }: DataTableProps<TData>,
  ref: ForwardedRef<HTMLDivElement>,
) {
  useSelectAllKeyboardShortcuts(table, shortcutsDisabled)

  const hasRows = table.getRowModel().rows.length > 0

  return (
    <div
      className={twMerge(
        "z-0 flex h-full flex-col",
        hasRows && "overflow-auto",
        className,
      )}
      ref={ref}
    >
      <table className="border-separate border-spacing-0">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className="group">
              {headerGroup.headers
                .filter(
                  (header) => header.column.columnDef.meta?.invisible !== true,
                )
                .map((header) => (
                  <th
                    key={header.id}
                    className={twMerge(
                      "sticky top-0 z-20 border-y border-grey-200 bg-grey-50 px-6 py-3 text-left text-xs font-medium text-grey-500 transition-colors duration-75",
                      header.column.columnDef.meta?.sticky === true &&
                        "sticky left-0 z-30 border-r",
                      header.column.columnDef.meta?.sticky === true &&
                        header.getContext().table.options.meta
                          ?.isScrolledHorizontal === true &&
                        "!border-r-grey-200",
                      header.column.columnDef.meta?.headerClassName,
                    )}
                    style={{
                      borderRightColor: "transparent",
                      minWidth: header.column.columnDef.minSize,
                      maxWidth: header.column.columnDef.maxSize,
                    }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </th>
                ))}
            </tr>
          ))}
        </thead>
        <tbody className="text-sm">
          {hasRows ? (
            children
          ) : (
            <tr>
              <td colSpan={accessionColumns.length} className="h-24">
                <div className="px-4 md:max-w-[calc(100vw_-_224px)]">
                  {loading === true ? (
                    <div className="flex h-96 items-center justify-center">
                      <CircularProgress
                        size="32px"
                        thickness={4}
                        sx={{color: colors.primary[500]}}
                      />
                    </div>
                  ) : emptyState == null ? (
                    "No results."
                  ) : (
                    emptyState
                  )}
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
})

export const DataTableRow = forwardRef(function DataTableRowInner<
  TData extends RowData,
>(
  {
    row,
    onClick,
    ...props
  }: {row: Row<TData>} & HTMLAttributes<HTMLTableRowElement>,
  ref: ForwardedRef<HTMLTableRowElement>,
) {
  return (
    <tr
      data-state={row.getIsSelected() && "selected"}
      className={twMerge("group", onClick != null && "cursor-pointer")}
      ref={ref}
      onClick={onClick}
      {...props}
    >
      {row
        .getVisibleCells()
        .filter((cell) => cell.column.columnDef.meta?.invisible !== true)
        .map((cell) => (
          <td
            key={cell.id}
            className={twMerge(
              "truncate border-b border-r border-grey-200 bg-white px-6 py-4 text-grey-500 transition-colors duration-75",
              row.getIsSelected()
                ? "bg-primary-50"
                : onClick == null
                ? ""
                : "group-hover:bg-grey-50",
              cell.column.columnDef.meta?.sticky === true &&
                "sticky left-0 z-10",
              cell.column.columnDef.meta?.sticky === true &&
                cell.getContext().table.options.meta?.isScrolledHorizontal ===
                  true &&
                "!border-r-grey-200 shadow-sm",
              cell.column.columnDef.meta?.className,
            )}
            style={{
              borderRightColor: "transparent",
              minWidth: cell.column.columnDef.minSize,
              maxWidth: cell.column.columnDef.maxSize,
            }}
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </td>
        ))}
    </tr>
  )
})
