import {css} from "@emotion/react"

const styles = css`
  .loading-icon-background {
    --a: -71deg;
    --b: 90deg;
    --c: 270deg;
    --size: 28%;
    content: "";
    border-radius: 99px;
    width: 300%;
    height: 300%;
    position: absolute;
    animation:
      1.3s rotate linear infinite,
      2.6s grow-shrink ease-in-out infinite;
    background: radial-gradient(
        var(--size) var(--size) at 50% 50%,
        rgba(255, 255, 255, 0%) 28.4%,
        #fff 100%
      ),
      conic-gradient(
        from var(--a) at 50% 50%,
        rgba(29, 205, 178, 0.37) 0deg,
        rgba(29, 205, 178, 0.5) var(--b),
        rgb(29, 205, 178, 0.4) 125.62500357627869deg,
        rgba(29, 205, 178, 0.29) var(--c),
        rgb(58, 182, 227) 360deg
      );
    z-index: 0;
  }
  .loading-icon::before {
    content: "";
    position: absolute;
    left: 3px;
    top: 3px;
    height: calc(100% - 6px);
    width: calc(100% - 6px);
    background: white;
    border-radius: 99px;
    z-index: 1;
  }

  .loading-icon::after {
    --a: -71deg;
    --b: 90deg;
    --c: 270deg;
    --size: 28%;
    content: "";
    position: relative;
    height: 57.1%;
    width: 57.1%;
    clip-path: url("#svgTextPath");
    animation: 1.3s mesh linear infinite;
    filter: blur(8px);
    transform: scale(1.2);
    background: conic-gradient(
      from var(--a) at 20% 20%,
      rgba(29, 205, 178, 0.5) 0deg,
      rgba(29, 205, 178, 0.8) var(--b),
      rgba(29, 205, 178, 0.7) 125.62500357627869deg,
      rgba(29, 205, 178, 0.7) var(--c),
      rgba(38, 182, 227, 0.65) 360deg
    );
    z-index: 2;
  }

  .loading-icon {
    position: relative;
    display: flex;
    height: 56px;
    width: 56px;
    overflow: hidden;
    background: white;
    justify-content: center;
    align-items: center;
    border-radius: 99px;
  }

  @property --a {
    syntax: "<angle>";
    inherits: false;
    initial-value: -71deg;
  }

  @property --b {
    syntax: "<angle>";
    inherits: false;
    initial-value: 90deg;
  }

  @property --c {
    syntax: "<angle>";
    inherits: false;
    initial-value: 270deg;
  }

  @property --size {
    syntax: "<percentage>";
    inherits: false;
    initial-value: 28%;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes mesh {
    0% {
      --a: -71deg;
      --b: 90deg;
      --c: 270deg;
    }
    50% {
      --b: 12deg;
      --c: 224deg;
    }
    100% {
      --a: 289deg;
      --b: 90deg;
      --c: 270deg;
    }
  }

  @keyframes grow-shrink {
    0% {
      --size: 42%;
    }
    50% {
      --size: 28%;
    }
    100% {
      --size: 42%;
    }
  }
`

export const HortisLoadingLogo = () => {
  return (
    <div css={styles}>
      <div className="loading-icon">
        <div className="loading-icon-background" />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="0"
          height="0"
          viewBox="0 0 32 32"
          fill="none"
        >
          <defs>
            <clipPath id="svgTextPath">
              <path
                d="M16.0001 6.37626C11.1167 6.37626 7.08177 10.0094 6.45015 14.7205C7.54547 14.8616 8.59662 15.1438 9.58482 15.5482C9.75994 12.1564 12.5652 9.46016 16.0001 9.46016C17.3426 9.46016 18.5888 9.872 19.6195 10.5762L21.8447 8.35074C20.2235 7.11204 18.1978 6.37626 16.0001 6.37626Z"
                fill="#2ED3B7"
              />
              <path
                d="M19.2 16C19.2 17.7673 17.7673 19.2 16 19.2C14.2327 19.2 12.8 17.7673 12.8 16C12.8 14.2327 14.2327 12.8 16 12.8C17.7673 12.8 19.2 14.2327 19.2 16Z"
                fill="#2ED3B7"
              />
              <path
                d="M23.6617 10.168L21.4103 12.4196C21.9951 13.3309 22.3561 14.3997 22.4154 15.5483C23.4035 15.1439 24.4547 14.8617 25.55 14.7205C25.3225 13.0232 24.6532 11.4659 23.6617 10.168Z"
                fill="#2ED3B7"
              />
              <path
                d="M14.7432 2.28882e-08C6.92229 0.605495 0.672045 6.81381 0 14.6168H3.22712C3.87854 8.58944 8.69745 3.81169 14.7432 3.22464V2.28882e-08Z"
                fill="#2ED3B7"
              />
              <path
                d="M17.2568 3.22464C23.3025 3.81169 28.1215 8.58944 28.7729 14.6168H32C31.328 6.81382 25.0778 0.605506 17.2568 0V3.22464Z"
                fill="#2ED3B7"
              />
              <path
                d="M31.9857 17.4102C31.3244 25.1571 25.1484 31.3322 17.3965 32V27.1869C17.3965 21.7874 21.7732 17.4102 27.1721 17.4102H31.9857Z"
                fill="#2ED3B7"
              />
              <path
                d="M14.6036 32C6.8517 31.3322 0.675708 25.1571 0.0144618 17.4102H4.82802C10.2269 17.4102 14.6036 21.7874 14.6036 27.1869V32Z"
                fill="#2ED3B7"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
  )
}
