import {FixedSizeList} from "react-window"
import {EmptyList} from "src/components/empty-list"
import {useLayoutHeight} from "src/utils/hooks/layout-height"
import type {DisplayTagsProps} from "../types"
import {TagsTableHeader} from "./tags-table-header"
import {renderTagTableRow} from "./tags-table-row"

const MIN_ROW_HEIGHT = 64

export const TagsDesktop = ({tags, canEdit}: DisplayTagsProps) => {
  const {listContainerRef, listHeaderRef, listHeight} = useLayoutHeight()

  return (
    <div
      ref={listContainerRef}
      className="flex w-full flex-1 flex-col overflow-hidden border border-grey-200 bg-white shadow-sm lg:rounded-lg"
    >
      <div className="grid grid-cols-12">
        <TagsTableHeader ref={listHeaderRef} />
        {listHeight > 0 ? (
          tags == null ? (
            Array.from({length: 8}).map((_, idx) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={idx} />
            ))
          ) : (
            <div className="col-span-12">
              <FixedSizeList
                height={tags.length > 0 ? listHeight : 0} // hacky way to make list invisible with empty results
                width="100%"
                itemSize={MIN_ROW_HEIGHT}
                itemCount={tags.length}
                itemData={{tags, canEdit}}
              >
                {renderTagTableRow}
              </FixedSizeList>
            </div>
          )
        ) : null}
      </div>
      <EmptyList list={tags} message="Please try another query" />
    </div>
  )
}
