import {Slot} from "@radix-ui/react-slot"
import {cva, type VariantProps} from "class-variance-authority"
import type {HTMLAttributes, ReactNode} from "react"
import {forwardRef} from "react"
import {twMerge} from "tailwind-merge"
import {XClose} from "../icons"

const tagVariants = cva(
  "w-fit bg-white text-grey-700 font-medium border border-grey-300 inline-flex items-center rounded-[6px] [&_.tag-button_svg]:h-3 [&_.tag-button_svg]:w-3 relative [&_.tag-button]:absolute [&_.tag-button]:right-1 outline-none focus-visible:border-primary-300 focus-visible:ring-4 ring-primary-100",
  {
    variants: {
      size: {
        sm: "text-xs py-0.5 leading-[18px] gap-1 has-[.tag-button]:pr-[21px] [&_.tag-button]:p-0.5 [&_.tag-button_svg]:h-2.5 [&_.tag-button_svg]:w-2.5",
        md: "text-sm py-0.5 leading-5 gap-[5px] [&_.tag-button]:p-0.5 has-[.tag-button]:pr-[23px]",
        lg: "text-sm py-1 leading-5 gap-1.5 [&_.tag-button]:p-[3px] has-[.tag-button]:pr-[27px]",
      },
      spacing: {
        dot: "",
        text: "",
        icon: "",
      },
    },
    compoundVariants: [
      {
        size: "sm",
        spacing: "dot",
        className: "pl-1.5 pr-2",
      },
      {
        size: "sm",
        spacing: "text",
        className: "px-2",
      },
      {
        size: "sm",
        spacing: "icon",
        className: "pl-1.5 pr-2",
      },
      {
        size: "md",
        spacing: "dot",
        className: "pl-[7px] pr-[9px]",
      },
      {
        size: "md",
        spacing: "text",
        className: "px-[9px]",
      },
      {
        size: "md",
        spacing: "icon",
        className: "pl-[5px] pr-[9px]",
      },
      {
        size: "lg",
        spacing: "dot",
        className: "pl-[9px] pr-2.5 text-sm",
      },
      {
        size: "lg",
        spacing: "text",
        className: "px-2.5",
      },
      {
        size: "lg",
        spacing: "icon",
        className: "pl-[7px] pr-2.5",
      },
    ],
    defaultVariants: {
      size: "md",
      spacing: "text",
    },
  },
)

const TagButton = ({
  className,
  children,
  ...props
}: HTMLAttributes<HTMLButtonElement>) => (
  <button
    type="button"
    className={twMerge(
      "tag-button hover:bg-grey-100 text-grey-400 hover:text-grey-500 rounded transition-colors duration-150",
      className,
    )}
    {...props}
  >
    {children ?? <XClose />}
  </button>
)

const Tag = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> & {
    children: ReactNode
    asChild?: boolean
  } & VariantProps<typeof tagVariants>
>(function Tag({children, asChild, spacing, size, className, ...props}, ref) {
  const Comp = asChild === true ? Slot : "div"
  return (
    <Comp
      ref={ref}
      className={twMerge(tagVariants({spacing, size}), className)}
      {...props}
    >
      {children}
    </Comp>
  )
})

export {Tag, TagButton}
