import {flow} from "@mobily/ts-belt"
import {convertDatesToISO} from "./convert-dates-to-iso"
import {
  convertEmptyStringsToNulls,
  removeEmptyValues,
} from "./remove-empty-values"

export const cleanFormData = flow(
  convertDatesToISO,
  convertEmptyStringsToNulls,
  removeEmptyValues,
)
