import config from "src/config"
import type {
  GetPublicOrganisationQuery,
  GetPublicOrganisationQueryVariables,
  PublicOrganisation,
} from "../../../generated/graphql"
import {fetcher} from "../../utils/gql-client"
import {GET_PUBLIC_ORG} from "./graphql"

export const fetchPublicOrg = (
  vars: GetPublicOrganisationQueryVariables,
  apiUrl?: string,
): Promise<GetPublicOrganisationQuery> =>
  fetcher<GetPublicOrganisationQuery>(
    GET_PUBLIC_ORG,
    "GetPublicOrganisation",
    vars,
    apiUrl,
  )

const fetcherPromise = (
  apiUrl: string,
  subdomain: string,
): Promise<PublicOrganisation> =>
  fetchPublicOrg(
    {
      subdomain,
    },
    apiUrl,
  ).then(({publicOrganisation}) =>
    publicOrganisation == null
      ? Promise.reject(undefined)
      : Promise.resolve(publicOrganisation),
  )

export const checkPublicOrgExists = async (
  apiUrls: Array<string>,
  subdomain: string,
): Promise<undefined | PublicOrganisation> => {
  try {
    const requestUrls = [...apiUrls, config.publicApiUrl]
    return await Promise.any(
      requestUrls.map((url) => fetcherPromise(url, subdomain.toLowerCase())),
    )
  } catch {
    return undefined
  }
}
