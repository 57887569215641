import {match} from "ts-pattern"
import * as uuid from "uuid"
import type {PlantMaterialFilter, TaxaFilter} from "generated/graphql"
import type {
  TaxaFilterType,
  LocalTaxaFilterWithId,
  LocalTaxaFilter,
} from "./types"

export interface TaxaFilterState extends Omit<TaxaFilter, "and"> {
  and: Array<LocalTaxaFilterWithId>
}

export const defaultState: TaxaFilterState = {
  and: [],
}

export enum FilterActionTypes {
  ADD_FILTER,
  DELETE_FILTER,
  MODIFY_FILTER,
  MODIFY_ROOT_FILTER,
  RESET_FILTERS,
  RESET_FILTER_TYPE,
}

export type TaxaFilterAction =
  | {
      type: FilterActionTypes.ADD_FILTER
      payload: LocalTaxaFilter
    }
  | {type: FilterActionTypes.DELETE_FILTER; payload: {id: string}}
  | {
      type: FilterActionTypes.MODIFY_FILTER
      payload: LocalTaxaFilterWithId
    }
  | {
      type: FilterActionTypes.MODIFY_ROOT_FILTER
      payload: Omit<PlantMaterialFilter, "and">
    }
  | {
      type: FilterActionTypes.RESET_FILTERS
      payload?: Partial<TaxaFilterState>
    }
  | {
      type: FilterActionTypes.RESET_FILTER_TYPE
      payload: TaxaFilterType
    }

export function filterReducer(
  state: TaxaFilterState,
  action: TaxaFilterAction,
) {
  return match(action)
    .with({type: FilterActionTypes.ADD_FILTER}, ({payload}) => ({
      ...state,
      and: [...state.and, {...payload, id: uuid.v4()}],
    }))
    .with({type: FilterActionTypes.DELETE_FILTER}, ({payload}) => ({
      ...state,
      and: state.and.filter(({id}) => id !== payload.id),
    }))
    .with({type: FilterActionTypes.MODIFY_FILTER}, ({payload}) => ({
      ...state,
      and: state.and.map((filter) =>
        filter.id === payload.id ? payload : filter,
      ),
    }))
    .with({type: FilterActionTypes.MODIFY_ROOT_FILTER}, ({payload}) => ({
      ...state,
      ...payload,
    }))
    .with({type: FilterActionTypes.RESET_FILTERS}, ({payload}) => ({
      ...defaultState,
      ...payload,
    }))
    .with(
      {type: FilterActionTypes.RESET_FILTER_TYPE},
      ({payload: filterType}) => ({
        ...state,
        and: state.and.filter((filter) =>
          match(filterType)
            .with("commonName", () => filter.notes == null)
            .with("authorship", () => filter.notes == null)
            .with("notes", () => filter.notes == null)
            .with("tags", () => filter.tags == null)
            .with("family", () => filter.family == null)
            .with("validation", () => filter.validation == null)
            .with("redListStatus", () => filter.redListStatus == null)
            .with("nativeDistribution", () => filter.nativeDistribution == null)
            .with("lifeForms", () => filter.lifeForms == null)
            .with("climate", () => filter.climate == null)
            .with("creator", () => filter.creator == null)
            .with("lastEditor", () => filter.lastEditor == null)
            .exhaustive(),
        ),
      }),
    )
    .run()
}
