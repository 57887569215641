import {Badge} from "@hortis/ui/badge"

interface TableTitleProps {
  total?: number
  totalLabel?: string
  pluralTotalLabel?: boolean
  title: string
  subtitle?: string
  singularTotalLabel?: string
}

export const TableTitle = ({
  title,
  subtitle,
  total,
  totalLabel,
  pluralTotalLabel,
  singularTotalLabel,
}: TableTitleProps) => (
  <div className="flex flex-col gap-1 px-4 md:px-6 lg:px-0">
    <div className="flex items-center gap-2">
      <h1 className="text-xl font-medium text-grey-900">{title}</h1>
      {total != null && totalLabel != null && (
        <Badge>
          {`${total} ${
            total === 1 ? singularTotalLabel ?? totalLabel : totalLabel
          }${pluralTotalLabel === true && total !== 1 ? "s" : ""}`}
        </Badge>
      )}
    </div>
    {subtitle != null && <p className="text-sm text-grey-500">{subtitle}</p>}
  </div>
)
