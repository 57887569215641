import {useEffect, useState} from "react"

type DebounceFn = (fn: () => void, timeout: number) => [() => void, () => void]

const debounce: DebounceFn = (fn, timeout) => {
  let timer: NodeJS.Timeout
  const clearTimer = () => {
    clearTimeout(timer)
  }
  return [
    () => {
      clearTimer()
      timer = setTimeout(() => {
        fn()
      }, timeout)
    },
    clearTimer,
  ]
}

const debounceRate = 200

export const useWindowResize = () => {
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
    deltaHeight: 0,
    deltaWidth: 0,
  })

  useEffect(() => {
    const [debounced, clearTimer] = debounce(() => {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
        deltaHeight: window.innerHeight - dimensions.height,
        deltaWidth: window.innerWidth - dimensions.width,
      })
    }, debounceRate)
    window.addEventListener("resize", debounced)

    return () => {
      window.removeEventListener("resize", debounced)
      clearTimer()
    }
  }, [dimensions])

  return {dimensions}
}
