import type {ReactNode} from "react"
import {HortisTextLogo} from "src/components/branding"
import {Card} from "../../../components/card"
import {CardContent} from "../../../components/card-content"
import {Stack} from "../../../components/stack"
import {fromSxFn} from "../../../utils/sx"

const cardStyle = fromSxFn((theme) => ({
  width: "100%",
  flexShrink: 0,
  maxWidth: {xs: theme.spacing(36), sm: theme.spacing(52)},
  "& .MuiCardContent-root:last-child": {
    paddingBottom: theme.spacing(5),
  },
  boxShadow: {xs: theme.shadows[0], sm: theme.shadows[1]},
}))

const cardContentStyle = fromSxFn((theme) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: {xs: theme.spacing(5, 0), sm: theme.spacing(5, 8)},
}))

export const AuthCard = ({children}: {children: ReactNode}): JSX.Element => {
  return (
    <Card sx={cardStyle}>
      <CardContent sx={cardContentStyle}>
        <Stack spacing={4} sx={{width: "100%", alignItems: "center"}}>
          <HortisTextLogo />
          {children}
        </Stack>
      </CardContent>
    </Card>
  )
}
