import * as t from "io-ts"
import {withMessage} from "io-ts-types/withMessage"
import * as z from "zod"
import {validDate} from "../../../../utils/valid-date"
import {StringOrNull} from "../schema/shared"

export const CountryOptionZod = z.object({
  codeId: z.string(),
  name: z.string(),
})
export type CountryOption = z.TypeOf<typeof CountryOptionZod>

export const CollectionDataSchemaZod = z.object({
  collectionDate: z.date().nullable(),
  collectionCollectors: z.string().nullable(),
  collectionCollectorsFieldNumber: z.string().nullable(),
  collectionHabitat: z.string().nullable(),
  collectionAssociatedTaxa: z.string().nullable(),
  collectionRemarks: z.string().nullable(),
  countryOption: CountryOptionZod.nullable(),
  collectionStateProvince: z.string().nullable(),
  collectionLocality: z.string().nullable(),
  collectionLatitude: z.string().nullable(),
  collectionLongitude: z.string().nullable(),
  collectionCoordinateUncertainty: z.number().nullable(),
  collectionElevation: z.string().nullable(),
})

export type CollectionDataZod = z.TypeOf<typeof CollectionDataSchemaZod>

export const CollectionDataSchema = t.type({
  collectionDate: withMessage(
    t.union([validDate, t.null]),
    () => "Please enter a valid collection date",
  ),
  collectionCollectors: StringOrNull,
  collectionCollectorsFieldNumber: StringOrNull,
  collectionHabitat: StringOrNull,
  collectionAssociatedTaxa: StringOrNull,
  collectionRemarks: StringOrNull,
  countryOption: t.union([
    t.null,
    t.type({
      codeId: t.string,
      name: t.string,
    }),
  ]),
  collectionStateProvince: StringOrNull,
  collectionLocality: StringOrNull,
  collectionLatitude: StringOrNull,
  collectionLongitude: StringOrNull,
  collectionCoordinateUncertainty: t.union([t.number, t.null]),
  collectionElevation: StringOrNull,
})

export type CollectionData = t.TypeOf<typeof CollectionDataSchema>
