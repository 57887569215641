import {
  Sheet,
  SheetBody,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from "@hortis/ui/sheet"
import type {NewMaterialFormProps} from "src/features/new-material/new-material"
import {NewMaterialForm} from "src/features/new-material/new-material"

type NewMaterialDrawerProps = NewMaterialFormProps & {
  open: boolean
  onOpenChange: (open: boolean) => void
}

export const NewMaterialDrawer = ({
  open,
  onOpenChange,
  ...props
}: NewMaterialDrawerProps) => {
  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetContent>
        <SheetHeader>
          <SheetTitle>New material</SheetTitle>
          <SheetDescription>
            Add a new material to an existing accession.
          </SheetDescription>
        </SheetHeader>
        <SheetBody>
          <NewMaterialForm onwardHref={undefined} {...props} />
        </SheetBody>
      </SheetContent>
    </Sheet>
  )
}
