import type {UseNavigateResult} from "@tanstack/react-router"
import type {SetSnack} from "src/components/snackbar-controller/snackbar-store"
import * as Path from "../../utils/path"
import {createMaterialNumber} from "../collection/components/plant-materials/material-number"

export const onCreateMaterialSuccess =
  ({
    onwardHref,
    materialsHref,
    navigate,
    setSnack,
  }: {
    onwardHref: string | undefined
    materialsHref: string | undefined
    navigate: UseNavigateResult<string>
    setSnack: SetSnack
  }) =>
  ({
    qualifier,
    accessionNumber,
  }: {
    accessionNumber: string
    qualifier: string
  }) => {
    if (materialsHref !== undefined) {
      if (onwardHref != null) {
        void navigate({to: `/${onwardHref}`})
      }

      setSnack({
        type: "action",
        data: {
          text: "Material created",
          body: createMaterialNumber(accessionNumber, qualifier, "/"),
          action: {
            text: "View material",
            href: `/${Path.concat(
              materialsHref,
              encodeURIComponent(`${accessionNumber}_${qualifier}`),
            )}`,
          },
        },
      })
    }
  }
