import {type AccessionFieldsFragment} from "generated/graphql"
import {useCallback, useMemo, useState} from "react"
import {useDateStringFormatter} from "../../../../utils/hooks/language"
import {ActivityItem} from "../../../records/components/record-activity/activity-item"
import {parseAccessionHistory} from "../../../records/components/record-activity/parse-accession-history"
import {ACTIVITY_PAGE_SIZE} from "../../../records/components/record-activity/utils"

export const useAccessionHistory = (
  accession: AccessionFieldsFragment | undefined,
) => {
  const [activityPage, setActivityPage] = useState(1)

  const showMoreActivity = useCallback(() => {
    setActivityPage((x) => x + 1)
  }, [])
  const showLessActivity = useCallback(() => {
    setActivityPage(1)
  }, [])

  const formatDateString = useDateStringFormatter()

  const fullHistory = useMemo(
    () =>
      accession?.historyConnection?.nodes == null
        ? null
        : parseAccessionHistory(
            accession.historyConnection.nodes,
            formatDateString,
            undefined,
          ).map((props, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <ActivityItem key={i} {...props} />
          )),
    [accession, formatDateString],
  )
  const accessionHistory = useMemo(
    () =>
      accession?.historyConnection?.nodes == null
        ? null
        : parseAccessionHistory(
            accession.historyConnection.nodes,
            formatDateString,
            activityPage * ACTIVITY_PAGE_SIZE,
          ).map((props, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <ActivityItem key={i} {...props} />
          )),
    [accession, activityPage, formatDateString],
  )

  return {
    activityPage,
    showMoreActivity,
    showLessActivity,
    accessionHistory,
    fullHistory,
  }
}
