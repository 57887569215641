import {zodResolver} from "@hookform/resolvers/zod"
import {Avatar} from "@hortis/ui/avatar"
import {Button} from "@hortis/ui/button"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@hortis/ui/form"
import {Check} from "@hortis/ui/icons"
import {Users1} from "@hortis/ui/icons/users-1"
import {Popover, PopoverContent, PopoverTrigger} from "@hortis/ui/popover"
import {Tag} from "@hortis/ui/tag"
import {useParams, useRouteContext} from "@tanstack/react-router"
import {
  useGetOrganisationMembersQuery,
  useSiteMembersQuery,
} from "generated/graphql"
import {useState} from "react"
import {useForm} from "react-hook-form"
import {formatMemberName} from "src/components/data-table/data-table-member-cell"
import {CollapsableRadioButton, RadioGroup} from "src/components/radio"
import {FilterTitle} from "src/features/filters/components/modal/filter-title"
import {twMerge} from "tailwind-merge"
import {match} from "ts-pattern"
import {z} from "zod"
import {
  Command,
  CommandEmpty,
  CommandInput,
  CommandItem,
  CommandList,
  CommandGroup,
  CommandLoading,
} from "@hortis/ui/command"
import type {FilterModalProps} from "../components/modal/filter-modal"
import {FilterModal} from "../components/modal/filter-modal"
import {FilterModalButtons} from "../components/modal/modal-buttons"
import type {FilterFormProps} from "../plant-materials/modals/types"

const UserPicker = ({
  value,
  onChange,
  "data-cy": dataCy,
}: {
  value: Array<UserOption> | undefined | null
  onChange: (val: Array<UserOption> | undefined | null) => void
  "data-cy"?: string
}) => {
  const [open, setOpen] = useState(false)
  const {subdomain} = useRouteContext({strict: false})
  const params = useParams({strict: false})
  const siteSlug = "siteSlug" in params ? params.siteSlug : undefined
  const [{data, fetching}] = useSiteMembersQuery({
    variables: {
      organisationSubdomain: subdomain,
      collectionSiteSlug: siteSlug ?? "",
    },
    pause: siteSlug == null,
  })
  const [{data: orgData, fetching: orgFetching}] =
    useGetOrganisationMembersQuery({
      variables: {organisationSubdomain: subdomain},
    })

  const loading = fetching || orgFetching

  const handleChange = (user: UserOption) => {
    if (value?.some((u) => u.id === user.id) === true) {
      onChange(value.filter((u) => u.id !== user.id))
    } else {
      onChange([...(value ?? []), user])
    }
    setOpen(false)
  }

  const members = data?.org?.site?.members
  const orgMembers = orgData?.org?.members?.filter(
    (member) =>
      members?.some((siteMember) => siteMember.id === member.id) !== true,
  )

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger className="mx-auto w-fit" asChild>
        <Button
          className={twMerge(
            "min-h-[44px] w-full justify-between !px-2.5 !py-2 text-grey-500",
          )}
          testId={dataCy}
        >
          {value == null || value.length === 0 ? (
            <div className="flex items-center gap-2 px-1">
              <Users1 className="h-4 w-4" />
              Pick a member
            </div>
          ) : (
            <div className="flex flex-wrap items-center gap-2">
              {value.map((user) => (
                <Tag key={user.id} spacing="icon">
                  <Avatar
                    size="xxxs"
                    src={user.avatarUrl ?? undefined}
                    textContent={user.name}
                  />
                  {user.name}
                </Tag>
              ))}
            </div>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="z-[99999] w-full min-w-[var(--radix-popover-trigger-width)]">
        <Command
          filter={(_, search, keywords) => {
            const extendValue = keywords?.join(" ") ?? ""
            if (extendValue.toLowerCase().includes(search.toLowerCase())) {
              return 1
            }
            return 0
          }}
        >
          <CommandInput placeholder="Search..." />
          <CommandEmpty>No results</CommandEmpty>
          {loading && <CommandLoading />}
          <CommandList>
            <CommandGroup
              heading={
                members == null ||
                members.length === 0 ||
                orgMembers == null ||
                orgMembers.length === 0
                  ? ""
                  : "Site members"
              }
            >
              {members?.map((member) => {
                const name = formatMemberName(member)
                const selected = value?.some((user) => user.id === member.id)

                return (
                  <CommandItem
                    key={member.id}
                    value={member.id}
                    keywords={[name]}
                    onSelect={() => {
                      handleChange({
                        id: member.id,
                        name,
                        avatarUrl: member.avatarUrl,
                      })
                    }}
                    data-cy="user-picker-item"
                  >
                    <Avatar
                      src={member.avatarUrl ?? undefined}
                      size="xs"
                      textContent={name}
                    />
                    <span className="flex-1 truncate">{name}</span>
                    {selected === true && (
                      <Check className="text-primary-600" />
                    )}
                  </CommandItem>
                )
              })}
            </CommandGroup>
            <CommandGroup
              heading={
                members == null ||
                members.length === 0 ||
                orgMembers == null ||
                orgMembers.length === 0
                  ? ""
                  : "Organisation members"
              }
            >
              {orgMembers?.map((member) => {
                const name = formatMemberName(member)
                const selected = value?.some((user) => user.id === member.id)
                return (
                  <CommandItem
                    key={member.id}
                    value={member.id}
                    keywords={[name]}
                    onSelect={() => {
                      handleChange({
                        id: member.id,
                        name,
                        avatarUrl: member.avatarUrl,
                      })
                    }}
                    data-cy="user-picker-item"
                  >
                    <Avatar
                      src={member.avatarUrl ?? undefined}
                      size="xs"
                      textContent={name}
                    />
                    <span className="flex-1 truncate">{name}</span>
                    {selected === true && (
                      <Check className="text-primary-600" />
                    )}
                  </CommandItem>
                )
              })}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}

export const userOptionSchema = z.object(
  {
    id: z.string().uuid(),
    name: z.string(),
    avatarUrl: z.string().url().nullish(),
  },
  {invalid_type_error: "Please select a user"},
)
type UserOption = z.infer<typeof userOptionSchema>

const formSchema = z.object({
  is: z
    .array(userOptionSchema)
    // eslint-disable-next-line sonarjs/no-duplicate-string
    .min(1, {message: "Please select a member"})
    .nullish(),

  isNot: z
    .array(userOptionSchema)
    .min(1, {message: "Please select a member"})
    .nullish(),
})

type FormSchema = z.infer<typeof formSchema>
export type LocalUserFilter = FormSchema

interface UserFilterFormProps {
  addModifyFilter: (changes: LocalUserFilter) => void
  deleteFilter: (() => void) | undefined
  initialValue?: LocalUserFilter
  title: string
}

export const UserFilterForm = ({
  initialValue = {},
  addModifyFilter,
  deleteFilter,
  onClose,
  title,
}: Omit<FilterFormProps, "dispatchFilters" | "initialValue"> &
  UserFilterFormProps &
  FilterModalProps) => {
  const [operation, setOperation] = useState<keyof FormSchema>(
    initialValue.isNot == null ? "is" : "isNot",
  )
  const form = useForm<z.infer<typeof formSchema>>({
    defaultValues: initialValue,
    resolver: zodResolver(formSchema),
  })

  function onSubmit(values: z.infer<typeof formSchema>) {
    if (values.is == null && values.isNot == null) {
      form.setError(operation, {message: "Please select a member"})
      return
    }
    addModifyFilter(
      match(operation)
        .with("is", () => ({is: values.is}))
        .with("isNot", () => ({isNot: values.isNot}))
        .exhaustive(),
    )
    onClose()
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="mb-4 flex flex-col gap-4">
          <FilterTitle title={title} deleteFilter={deleteFilter} />
          <RadioGroup
            value={operation}
            onChange={setOperation}
            label="Operation"
          >
            {(props) => (
              <>
                <CollapsableRadioButton
                  selectedValue="is"
                  testId="is-radio"
                  label="Is"
                  {...props}
                >
                  <FormField
                    control={form.control}
                    name="is"
                    render={({field}) => (
                      <FormItem>
                        <FormControl>
                          <UserPicker
                            value={field.value}
                            // eslint-disable-next-line react/jsx-handler-names
                            onChange={field.onChange}
                            data-cy="is-user-picker"
                          />
                        </FormControl>
                        <FormMessage data-cy="form-error" />
                      </FormItem>
                    )}
                  />
                </CollapsableRadioButton>
                <CollapsableRadioButton
                  selectedValue="isNot"
                  testId="is-not-radio"
                  label="Is not"
                  {...props}
                >
                  <FormField
                    control={form.control}
                    name="isNot"
                    render={({field}) => (
                      <FormItem>
                        <FormControl>
                          <UserPicker
                            value={field.value}
                            // eslint-disable-next-line react/jsx-handler-names
                            onChange={field.onChange}
                            data-cy="is-not-user-picker"
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </CollapsableRadioButton>
              </>
            )}
          </RadioGroup>
        </div>
        <FilterModalButtons onCancel={onClose} testIdPrefix="user-filter" />
      </form>
    </Form>
  )
}

// Seperated from form component to make sure popover unmount causes state reset
export const UserFilterModal = ({
  open,
  onClose,
  anchorEl,
  ...props
}: Omit<FilterFormProps, "initialValue" | "dispatchFilters"> &
  FilterModalProps &
  UserFilterFormProps) => {
  return (
    <FilterModal anchorEl={anchorEl} open={open} onClose={onClose}>
      <UserFilterForm open={open} onClose={onClose} {...props} />
    </FilterModal>
  )
}
