import type {IconProps} from "./types"

export const SlOrganisationIcon = ({
  style,
  width,
  height,
  color,
  className,
}: //eslint-disable-next-line sonarjs/cognitive-complexity
IconProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width ?? "24"}
    height={height ?? "24"}
    viewBox="0 0 24 24"
    fill="none"
    style={style}
    className={className}
  >
    <path
      d="M19.8002 7.80005H4.2002V21H19.8002V7.80005Z"
      stroke={color ?? "#424242"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.6002 17.3999H11.4002C11.0819 17.3999 10.7767 17.5263 10.5517 17.7514C10.3266 17.9764 10.2002 18.2816 10.2002 18.5999V20.9999H13.8002V18.5999C13.8002 18.2816 13.6738 17.9764 13.4487 17.7514C13.2237 17.5263 12.9185 17.3999 12.6002 17.3999Z"
      stroke={color ?? "#424242"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.6001 10.2V11.4"
      stroke={color ?? "#424242"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.2002 10.2V11.4"
      stroke={color ?? "#424242"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.7998 10.2V11.4"
      stroke={color ?? "#424242"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.3999 10.2V11.4"
      stroke={color ?? "#424242"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.6001 13.8V15"
      stroke={color ?? "#424242"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.2002 13.8V15"
      stroke={color ?? "#424242"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.7998 13.8V15"
      stroke={color ?? "#424242"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.3999 13.8V15"
      stroke={color ?? "#424242"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 7.80005H21"
      stroke={color ?? "#424242"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.6634 3H6.337C6.06943 3.00003 5.80954 3.08948 5.59864 3.25414C5.38774 3.41881 5.23793 3.64923 5.173 3.9088L4.2002 7.8H19.8002L18.8274 3.9088C18.7625 3.64923 18.6126 3.41881 18.4017 3.25414C18.1908 3.08948 17.931 3.00003 17.6634 3V3Z"
      stroke={color ?? "#424242"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.6001 17.3999V18.5999"
      stroke={color ?? "#424242"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.3999 17.3999V18.5999"
      stroke={color ?? "#424242"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
