import {useTheme} from "@mui/material/styles"
import type {ReactElement, SyntheticEvent} from "react"
import {useCallback, useEffect, useRef, useState} from "react"
import {colors} from "src/colors"
import {shadows} from "src/shadows"
import {SlSwipeHorizontalIcon} from "../../../../components/icons/streamline/swipe-horizontal"
import {Tab, Tabs} from "../../../../components/tabs"
import {Typography} from "../../../../components/typography"
import {fromSxFn} from "../../../../utils/sx"

interface TabProps<TabValue extends string | number> {
  label: string
  value: TabValue
}

export interface TagTabsProps<TabValue extends string | number> {
  currentTab: TabValue | false
  tabs: Array<TabProps<TabValue>> | ReadonlyArray<TabProps<TabValue>>
  onTabChange: (val: TabValue) => void
}

const recordTabsStyle = fromSxFn(() => ({
  "&": {
    minHeight: "auto",
    backgroundColor: colors.grey[50],
    borderRadius: "8px",
    display: "inline-flex",
    border: `1px solid ${colors.grey[100]}`,
  },
  "& .MuiTabs-flexContainer": {
    gap: "8px",
    padding: "4px",
  },
  "& .MuiButtonBase-root": {
    borderRadius: "6px",
    textTransform: "none",
    minWidth: "auto",
    minHeight: "36px",
    transition: `all 125ms ease`,
    flexDirection: "column",
    alignItems: "center",
    border: `1px solid transparent`,
    padding: "8px 12px",
  },
  "& .MuiButtonBase-root:hover:not(.Mui-selected)": {
    color: colors.grey[500],
  },
  "& .MuiButtonBase-root:focus-visible": {
    border: `2px solid blue`,
  },
  "& .MuiButtonBase-root.Mui-selected": {
    color: colors.grey[700],
    backgroundColor: colors.white,
    boxShadow: shadows.sm,
  },
  "& .MuiTabs-indicator": {
    display: "none",
  },
}))

export const TagTabs = <TabValue extends string | number>({
  tabs,
  currentTab,
  onTabChange,
}: TagTabsProps<TabValue>): ReactElement => {
  const theme = useTheme()
  const containerRef = useRef<HTMLButtonElement | null>(null)
  const [showHelper, setShowHelper] = useState(true)
  const onChange = useCallback(
    (_e: SyntheticEvent, value: TabValue) => {
      onTabChange(value)
    },
    [onTabChange],
  )

  // Used to handle whether to show the scroll helper text
  useEffect(() => {
    const scrollerEl = containerRef.current?.querySelector(".MuiTabs-scroller")
    const contentEl = containerRef.current?.querySelector(
      ".MuiTabs-flexContainer",
    )

    const checkIfScrolled: EventListenerOrEventListenerObject = (e) => {
      if (e.target && (e.target as Element).scrollLeft > 0) {
        setShowHelper(false)
      }
    }

    const checkContainerScrollable = () => {
      if (
        contentEl != null &&
        scrollerEl != null &&
        contentEl.scrollWidth <= scrollerEl.clientWidth
      ) {
        setShowHelper(false)
      } else {
        setShowHelper(true)
      }
    }

    if (scrollerEl != null) {
      checkContainerScrollable()
      window.addEventListener("resize", checkContainerScrollable)
      scrollerEl.addEventListener("scroll", checkIfScrolled)

      return () => {
        window.removeEventListener("resize", checkContainerScrollable)
        scrollerEl.removeEventListener("scroll", checkIfScrolled)
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {}
  }, [])

  return (
    <div className="relative">
      <div
        style={{
          position: "absolute",
          right: theme.spacing(2),
          top: 0,
          transform: `translateY(-100%)`,
          alignItems: "center",
          display: "flex",
          gap: 0.5,
          transition: "opacity 100ms ease",
          opacity: showHelper ? "1" : "0",
        }}
      >
        <SlSwipeHorizontalIcon />
        <Typography variant="caption">Scroll</Typography>
      </div>
      <Tabs
        ref={containerRef}
        value={currentTab}
        onChange={onChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
        sx={recordTabsStyle}
      >
        {tabs.map(({label, value}) => (
          <Tab
            tabIndex={0}
            disableRipple
            key={label}
            label={label}
            iconPosition="start"
            testId={`${value}-tab`}
            value={value}
          />
        ))}
      </Tabs>
    </div>
  )
}
