import {isNonEmpty} from "../../count/is-non-empty"
import type {LocalAccessionFilter} from "../types"

export const countMaterialReceivedFilter = (
  filter: NonNullable<LocalAccessionFilter["materialType"]>,
) => {
  let count = 0
  if (isNonEmpty(filter.is)) {
    count += 1
  }
  if (isNonEmpty(filter.isNot)) {
    count += 1
  }
  return count
}
