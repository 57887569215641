import {Button} from "@hortis/ui/button"
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@hortis/ui/dialog"
import {A, pipe} from "@mobily/ts-belt"
import {
  MaterialGroup,
  PlantMaterialStatus,
  useUpdatePlantMaterialsMutation,
} from "generated/graphql"
import {useState} from "react"
import {useSnackbarStore} from "src/components/snackbar-controller/snackbar-store"
import {
  onFailure,
  onSuccess as onSuccessSnack,
} from "src/notification-snack-utils"

interface MakePublicDialogProps {
  selectedMaterials: ReadonlyArray<{
    id: string
    public: boolean
    materialGroup: MaterialGroup
    status: PlantMaterialStatus
  }>
  onSuccess: () => void
  open: boolean
  onOpenChange: (open: boolean) => void
}

const MakePublicDialog = ({
  selectedMaterials,
  onSuccess,
  open,
  onOpenChange,
}: MakePublicDialogProps) => {
  const {setSnack} = useSnackbarStore()
  const [_, updatePlantMaterials] = useUpdatePlantMaterialsMutation()
  const [loading, setLoading] = useState<boolean>(false)

  const onSubmit = async () => {
    const ids = pipe(
      selectedMaterials,
      A.filter((m) => !m.public),
      A.filter((m) => m.materialGroup === MaterialGroup.Plant),
      A.filter((m) => m.status === PlantMaterialStatus.Present),
      A.map(({id}) => id),
    )
    if (ids.length === 0) {
      onSuccess()
      return
    }
    setLoading(true)
    const res = await updatePlantMaterials({
      ids,
      input: {
        public: true,
      },
    })
    setLoading(false)
    if (res.data?.updatePlantMaterials.success !== true) {
      onFailure(setSnack)(new Error("Failed to update materials"))
      return
    }
    onSuccessSnack(setSnack)(
      `Successfully updated ${
        res.data.updatePlantMaterials.plantMaterials.length
      } material${
        res.data.updatePlantMaterials.plantMaterials.length > 1 ? "s" : ""
      }`,
    )
    onSuccess()
  }

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent data-cy="make-public-dialog">
        <DialogHeader>
          <DialogTitle>
            Are you sure you want to make{" "}
            {selectedMaterials.length === 1
              ? "this material"
              : "these materials"}{" "}
            public?
          </DialogTitle>
          <DialogDescription>
            {selectedMaterials.length === 1 ? "It" : "They"} will be displayed
            on your public site. Non-present and non-plant materials will be
            skipped.
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <DialogClose asChild>
            <Button size="lg" fullWidth>
              Cancel
            </Button>
          </DialogClose>
          <Button
            size="lg"
            variant="primary"
            loading={loading}
            fullWidth
            testId="make-public-confirm"
            onClick={onSubmit}
          >
            Confirm
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export {MakePublicDialog}
