import {useEffect, useRef} from "react"

export const useShiftHeld = () => {
  const shiftHeld = useRef(false)

  useEffect(() => {
    function checkShift(e: KeyboardEvent) {
      if (e.key === "Shift") {
        shiftHeld.current = true
      }
    }
    document.addEventListener("keydown", checkShift)
    return () => {
      document.removeEventListener("keydown", checkShift)
    }
  }, [])

  useEffect(() => {
    function checkShift(e: KeyboardEvent) {
      if (e.key === "Shift") {
        shiftHeld.current = false
      }
    }
    document.addEventListener("keyup", checkShift)
    return () => {
      document.removeEventListener("keyup", checkShift)
    }
  }, [])

  return shiftHeld
}
