import {LoadingPage} from "@hortis/ui/loading"
import {createFileRoute} from "@tanstack/react-router"
import {useEffect} from "react"
import {authenticatingSchema} from "src/features/auth/components/routes"
import {SelectOrganisation} from "src/features/auth/components/select-organisation"
import RootRedirect from "src/features/organisation/components/root-redirect"
import {useOrganisationSubdomainStruct} from "src/utils/hooks/place"

export const Route = createFileRoute("/")({
  component: Component,
  validateSearch: (search) => authenticatingSchema.parse(search),
})

const domainsToForceLogin = new Set(["app", "app-dev"])

function Component() {
  const {error_description} = Route.useSearch()
  const navigate = Route.useNavigate()
  const subdomain = useOrganisationSubdomainStruct()

  useEffect(() => {
    // If auth0 has presented any errors, send user back to login
    if (error_description != null) {
      void navigate({to: "/auth/login", search: {error: error_description}})
    }
  }, [error_description, navigate])

  if (error_description != null || subdomain.loading) {
    return <LoadingPage />
  }

  if (subdomain.data == null || domainsToForceLogin.has(subdomain.data)) {
    return <SelectOrganisation />
  }

  return <RootRedirect />
}
