import type {SyntheticEvent} from "react"

export const stopEventPropagation = (e: SyntheticEvent | Event): void => {
  e.preventDefault()
  e.stopPropagation()
}
export const stopPropagation = (e: SyntheticEvent | Event): void => {
  e.stopPropagation()
}
export const preventDefault = (e: SyntheticEvent | Event): void => {
  e.preventDefault()
}
