import {
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
} from "@hortis/ui/dropdown-menu"
import {Copy1} from "@hortis/ui/icons/copy-1"
import {type MaterialFieldsFragment} from "generated/graphql"
import {useSnackbarStore} from "src/components/snackbar-controller/snackbar-store"
import {createMaterialNumber} from "src/features/collection/components/plant-materials/material-number"
import {onFailure, onSuccess} from "src/notification-snack-utils"
import {copyToClipboard} from "src/utils/dom/copy-to-clipboard"

export const MaterialCopyDropdownMenuSub = ({
  material,
}: {
  material: MaterialFieldsFragment
}) => {
  const {setSnack} = useSnackbarStore()
  return material.accession == null ? null : (
    <DropdownMenuSub>
      <DropdownMenuSubTrigger>
        <Copy1 className="h-4 w-4 text-grey-500" />
        Copy
      </DropdownMenuSubTrigger>
      <DropdownMenuSubContent alignOffset={-46}>
        <DropdownMenuGroup>
          <DropdownMenuItem
            onSelect={() => {
              void copyToClipboard(
                createMaterialNumber(
                  material.accession?.accessionNumber ?? "",
                  material.qualifier,
                  "/",
                ),
                () => {
                  onSuccess(setSnack)(
                    `"${createMaterialNumber(
                      material.accession?.accessionNumber ?? "",
                      material.qualifier,
                      "/",
                    )}" copied to clipboard`,
                  )
                },
                () => {
                  //eslint-disable-next-line sonarjs/no-duplicate-string
                  onFailure(setSnack)(new Error("Failed to copy"))
                },
              )
            }}
          >
            <Copy1 className="h-4 w-4 text-grey-500" />
            Copy material number
          </DropdownMenuItem>
          <DropdownMenuItem
            onSelect={() => {
              void copyToClipboard(
                material.accession?.accessionNumber ?? "",
                () => {
                  onSuccess(setSnack)(
                    `"${
                      material.accession?.accessionNumber ?? ""
                    }" copied to clipboard`,
                  )
                },
                () => {
                  onFailure(setSnack)(new Error("Failed to copy"))
                },
              )
            }}
          >
            <Copy1 className="h-4 w-4 text-grey-500" />
            Copy accession number
          </DropdownMenuItem>
          <DropdownMenuItem
            onSelect={() => {
              void copyToClipboard(
                material.accession?.taxon?.scientificName ?? "",
                () => {
                  onSuccess(setSnack)(
                    `"${
                      material.accession?.taxon?.scientificName ?? ""
                    }" copied to clipboard`,
                  )
                },
                () => {
                  onFailure(setSnack)(new Error("Failed to copy"))
                },
              )
            }}
          >
            <Copy1 className="h-4 w-4 text-grey-500" />
            Copy scientific name
          </DropdownMenuItem>
          {material.accession.taxon?.commonName != null && (
            <DropdownMenuItem
              onSelect={() => {
                void copyToClipboard(
                  material.accession?.taxon?.commonName ?? "",
                  () => {
                    onSuccess(setSnack)(
                      `"${
                        material.accession?.taxon?.commonName ?? ""
                      }" copied to clipboard`,
                    )
                  },
                  () => {
                    onFailure(setSnack)(new Error("Failed to copy"))
                  },
                )
              }}
            >
              <Copy1 className="h-4 w-4 text-grey-500" />
              Copy common name
            </DropdownMenuItem>
          )}
        </DropdownMenuGroup>
      </DropdownMenuSubContent>
      <DropdownMenuSeparator />
    </DropdownMenuSub>
  )
}
