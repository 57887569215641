import {IconButton} from "@hortis/ui/button"
import {ContextMenu, ContextMenuTrigger} from "@hortis/ui/context-menu"
import {FilterLines} from "@hortis/ui/icons"
import {R, pipe} from "@mobily/ts-belt"
import {
  type UseNavigateResult,
  useNavigate,
  useSearch,
} from "@tanstack/react-router"
import {
  PlantMaterialsSearchColumn,
  usePlantMaterialsQuery,
} from "generated/graphql"
import {useCallback, useEffect, useMemo, useRef, useState} from "react"
import {List} from "src/components"
import {
  DataTable,
  DataTableFooter,
  DataTableRow,
  DataTableToolbar,
} from "src/components/data-table"
import type {MaterialsColumnIds} from "src/components/data-table/columns/material-columns"
import {
  materialColumns,
  trashMaterialColumns,
} from "src/components/data-table/columns/material-columns"
import {useTable} from "src/components/data-table/use-table"
import {ViewButton} from "src/components/data-table/view-button"
import {MobileDrawer} from "src/components/mobile-drawer"
import {Toggle} from "src/components/toggle"
import MaterialsActionMenu from "src/features/actions-menu/variants/materials-action-menu"
import {searchMenuLabels} from "src/features/collection-map/utils"
import {SearchBar} from "src/features/collection/components/search-bar/search-bar"
import {showErrorMessage} from "src/features/collection/components/show-error-message"
import {MaterialsContextMenuContent} from "src/features/context-menu/variants/materials-context-menu"
import {
  AddPlantMaterialFilter,
  PlantMaterialsFilters,
  PlantMaterialsMobileFilters,
} from "src/features/filters/plant-materials"
import {FilterActionTypes} from "src/features/filters/plant-materials/filter-reducer"
import {MaterialFilterModals} from "src/features/filters/plant-materials/modals/filter-modals"
import {MaterialsSortSelect} from "src/features/filters/plant-materials/sort-select"
import {useMaterialFilters} from "src/features/filters/plant-materials/use-material-filters"
import {type CollectionSiteSettings} from "src/utils/hooks/collection-site-from-url"
import type {CollectionSiteHrefs} from "src/utils/hooks/hrefs"
import {useMobile} from "src/utils/hooks/media-query"
import {usePlaceStruct} from "src/utils/hooks/place"
import {useFilterState} from "src/utils/hooks/tables/filter-state"
import {usePagination} from "src/utils/hooks/tables/pagination"
import {useRowClick} from "src/utils/hooks/tables/row-click"
import {useTableSearch} from "src/utils/hooks/tables/search"
import {getSelectedRowIds, useSelect} from "src/utils/hooks/tables/select-mode"
import {useSort} from "src/utils/hooks/tables/sort"
import {useToggle} from "src/utils/hooks/toggle"
import {createRelayParams} from "src/utils/pagination"
import {useAccessToken} from "src/utils/use-access-token"
import * as Path from "../../../../utils/path"
import {TrashMaterialsDialog} from "../archive-records/trash-materials-dialog"
import {useDownloadCsv} from "../download-accessions/use-download-csv"
import {EmptyList} from "./empty-list"
import {createMaterialNumber} from "./material-number"
import {MaterialsMobileList} from "./materials-mobile-list-new"
import {MaterialsUtilityMoreButton} from "./materials-utility-more-button"
import {processPlantMaterialsQuery} from "./process-materials-query"
import {
  defaultColumnVisibility,
  filterColumnMap,
  sortColumnMap,
} from "./table-config"

interface MaterialsListProps {
  downloadRequested: boolean
  resetDownloadRequested: () => void
  collectionSiteHrefs: CollectionSiteHrefs | undefined
  collectionSiteSettings: CollectionSiteSettings
  isTrash?: boolean
}

export const MaterialsList = ({
  downloadRequested,
  resetDownloadRequested,
  collectionSiteHrefs,
  collectionSiteSettings,
  isTrash = false,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
MaterialsListProps) => {
  const isMobile = useMobile({defaultMatches: false})

  const {
    filters,
    preparedFilters,
    dispatchFilters,
    filterCounts,
    resetFilters,
  } = useMaterialFilters({
    initialState: {
      excludeAbsentMaterial: !isTrash,
      archived: isTrash ? {eq: true} : undefined,
    },
    persistenceKey: isTrash
      ? "materials-trash-list-filters"
      : "materials-list-filters",
  })
  const setExcludeMaterialFilter = useCallback(
    (checked: boolean) => {
      dispatchFilters({
        type: FilterActionTypes.MODIFY_ROOT_FILTER,
        payload: {excludeAbsentMaterial: checked},
      })
    },
    [dispatchFilters],
  )

  const accessToken = useAccessToken()
  const navigate = useNavigate({
    from: isTrash ? "/sites/$siteSlug/trash" : "/sites/$siteSlug/collection",
  }) as UseNavigateResult<string> // A workaround, there may need to be a refactor to prevent the need for this conditional `from`.
  const search = useSearch({
    strict: false,
  })
  const rows = "rows" in search ? search.rows : undefined
  const matSearch = "matSearch" in search ? search.matSearch : undefined
  const matCursor = "matCursor" in search ? search.matCursor : undefined
  const matDir = "matDir" in search ? search.matDir : undefined
  const matOrderBy = "matOrderBy" in search ? search.matOrderBy : undefined

  const rowsPerPage = rows ?? 25

  const place = usePlaceStruct()
  const pause = place.data == null || accessToken._tag === "None"
  const [plantMaterialsQuery] = usePlantMaterialsQuery({
    variables: {
      ...createRelayParams({cursor: matCursor, dir: matDir, rowsPerPage}),
      organisationSubdomain: place.data?.orgName ?? "",
      collectionSiteSlug: place.data?.siteSlug ?? "",
      searchTerm: matSearch,
      orderBy: matOrderBy,
      filters: preparedFilters,
      includeArchived: isTrash,
    },
    pause,
  })

  const loading =
    (plantMaterialsQuery.fetching && plantMaterialsQuery.data == null) || pause

  const {error, data} = pipe(
    processPlantMaterialsQuery({plantMaterialsQuery, place}),
    R.match(
      (data): {data: typeof data | null; error: string | null} => ({
        data,
        error: null,
      }),
      (error) => ({error, data: null}),
    ),
  )

  const handleRowsChange = useCallback(
    (rows: number | null) => {
      void navigate({search: (prev) => ({...prev, rows: rows ?? undefined})})
    },
    [navigate],
  )

  const dataMemo = useMemo(() => data?.nodes ?? [], [data?.nodes])

  const disabledColumnIds = useMemo(() => {
    const disabledColumns: Array<MaterialsColumnIds> = []
    if (!collectionSiteSettings.tagNumbersEnabled) {
      disabledColumns.push("Tag number")
    }
    if (!collectionSiteSettings.sexEnabled) {
      disabledColumns.push("Sex")
    }
    if (!collectionSiteSettings.isPublic) {
      disabledColumns.push("Public")
    }
    return disabledColumns
  }, [collectionSiteSettings])

  const {table, ref, tableRef, mobileListRef} = useTable({
    columns: isTrash ? trashMaterialColumns : materialColumns,
    data: dataMemo,
    tableId: "site-materials-table",
    defaultColumnVisibility,
    disabledColumnIds,
  })

  const {nextPageLoading, previousPageLoading, onPreviousPage, onNextPage} =
    usePagination({
      navigate,
      pageInfo: data?.pageInfo,
      cursor: matCursor,
      fetching: plantMaterialsQuery.fetching,
      tableEl: tableRef,
      mobileListEl: mobileListRef.current,
      cursorKey: "matCursor",
      dirKey: "matDir",
    })

  const {selectMode, toggleSelectMode} = useSelect({
    resetRowSelection: table.resetRowSelection,
    filterCounts,
    sort: table.getState().sorting,
    search: matSearch,
  })

  const {handleSearchClear, handleSearchSubmit, handleClearSearchAndFilters} =
    useTableSearch({
      navigate,
      search: matSearch,
      resetSelectedRows: table.resetRowSelection,
      resetFilters,
      searchKey: "matSearch",
      cursorKey: "matCursor",
      dirKey: "matDir",
    })

  const {
    updateOrderBy,
    viewMenuOpen,
    toggleViewMenuOpen,
    updateOrderByFromViewMenu,
  } = useSort({
    navigate,
    sorting: table.getState().sorting,
    resetSorting: table.resetSorting,
    setSorting: table.setSorting,
    orderBy: matOrderBy,
    sortParamKey: "matOrderBy",
    cursorKey: "matCursor",
    dirKey: "matDir",
    sortColumnMap,
  })

  const [trashDialogOpen, setTrashDialogOpen] = useState(false)
  const [actionsMenuOpen, toggleActionsMenuOpen] = useToggle()

  const trashButtonRef = useRef<HTMLButtonElement | null>(null)

  const selectedRecordsIDs = getSelectedRowIds(table.getState().rowSelection)

  const onRowClick = useRowClick<(typeof dataMemo)[number]>({
    urlFn: ({qualifier, accession}) =>
      `/${Path.concat(
        collectionSiteHrefs?.plantMaterials ?? "",
        encodeURIComponent(
          createMaterialNumber(accession?.accessionNumber ?? "", qualifier),
        ),
      )}`,
  })

  const handleDownload = useDownloadCsv({
    csvName: "plant-materials",
    path: "/download/materials",
    body: {
      searchTerm: matSearch,
      filters: {
        ...preparedFilters,
        id: {
          in: selectedRecordsIDs,
        },
      },
    },
  })

  useEffect(() => {
    if (downloadRequested) {
      void handleDownload()
      resetDownloadRequested()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- due to body prop, handleDownload does not have stable identity
  }, [downloadRequested, resetDownloadRequested])

  const {
    openedFilter,
    filterButtonRef,
    handleFilterModalClose,
    setOpenFilter,
    openedFilterToolbar,
  } = useFilterState({
    filterColumnMap,
    resetColumnFilters: table.resetColumnFilters,
    columnFilters: table.getState().columnFilters,
  })

  const [mobileFiltersExpanded, toggleMobileFiltersExpanded] = useToggle()

  const emptyState = useMemo(
    () => (
      <EmptyList
        isTrash={isTrash}
        type="material"
        list={data?.nodes ?? undefined}
        searchTerm={matSearch}
        clearSearch={handleClearSearchAndFilters}
        filtersActive={
          filterCounts.total - filterCounts.excludeAbsentMaterial > 0
        }
      />
    ),
    [
      isTrash,
      data?.nodes,
      matSearch,
      handleClearSearchAndFilters,
      filterCounts.total,
      filterCounts.excludeAbsentMaterial,
    ],
  )

  return (
    <>
      {error != null && !loading ? (
        showErrorMessage(new Error(error))
      ) : isMobile ? (
        <div className="flex h-full flex-col overflow-hidden">
          <List.Toolbar>
            <div className="flex items-center gap-2">
              <SearchBar
                testId="search-form"
                menuLabels={searchMenuLabels}
                handleSubmitCallback={handleSearchSubmit}
                handleClearCallback={handleSearchClear}
                searchTermOverride={matSearch}
                className="flex-1"
                searchFieldIfNumber={PlantMaterialsSearchColumn.MaterialNumber}
              />
              <IconButton
                icon={FilterLines}
                ariaLabel="Filters"
                testId="filters-button-mobile"
                onClick={toggleMobileFiltersExpanded}
                size="sm"
              />
              <MobileDrawer
                testId="mobile-drawer"
                open={mobileFiltersExpanded}
                onClose={toggleMobileFiltersExpanded}
                content={
                  <PlantMaterialsMobileFilters
                    excludeAbsentPlantMaterial={
                      filters.excludeAbsentMaterial === true
                    }
                    onChange={setExcludeMaterialFilter}
                    filters={filters}
                    dispatchFilters={dispatchFilters}
                    resetFilters={resetFilters}
                    filterCount={filterCounts.total}
                    updateOrderBy={updateOrderBy}
                    orderBy={matOrderBy}
                  />
                }
              />

              <MaterialsUtilityMoreButton
                searchTerm={matSearch}
                filters={preparedFilters}
              />
            </div>
          </List.Toolbar>
          <MaterialsMobileList
            table={table}
            selectMode={selectMode}
            emptyState={emptyState}
          />
          <List.Footer
            selectedRowCount={selectedRecordsIDs.length}
            handleDownload={handleDownload}
            selectAllRows={() => {
              const currentPageRecordsCount =
                table.getSelectedRowModel().rows.length
              table.toggleAllPageRowsSelected(currentPageRecordsCount <= 0)
            }}
            selectMode={selectMode}
            toggleSelectMode={toggleSelectMode}
            onPreviousPage={onPreviousPage}
            onNextPage={onNextPage}
            onActionsMenu={toggleActionsMenuOpen}
            onTrashRecords={() => {
              setTrashDialogOpen(true)
            }}
            trashButtonRef={trashButtonRef}
            total={data?.materialsTotal}
            hasPreviousPage={data?.pageInfo.hasPreviousPage}
            hasNextPage={data?.pageInfo.hasNextPage}
            previousPageLoading={previousPageLoading}
            nextPageLoading={nextPageLoading}
            testIdPrefix="materials"
            isTrash={isTrash}
          />
        </div>
      ) : (
        <>
          <MaterialFilterModals
            openFilter={openedFilterToolbar ?? openedFilter?.id ?? false}
            handleFilterModalClose={handleFilterModalClose}
            filterModalAnchorEl={
              openedFilterToolbar == null
                ? openedFilter?.value
                : filterButtonRef
            }
            dispatchFilters={dispatchFilters}
          />
          <DataTableToolbar className="flex flex-col gap-4">
            <div className="flex flex-col gap-5">
              <div className="flex flex-1 items-center justify-between gap-3">
                <SearchBar
                  testId="search-form"
                  menuLabels={searchMenuLabels}
                  handleSubmitCallback={handleSearchSubmit}
                  handleClearCallback={handleSearchClear}
                  searchTermOverride={matSearch}
                  className="max-w-md flex-1"
                  searchFieldIfNumber={
                    PlantMaterialsSearchColumn.MaterialNumber
                  }
                />
                <div className="flex items-center gap-3">
                  <AddPlantMaterialFilter
                    setOpenFilter={setOpenFilter}
                    align="end"
                    buttonProps={{
                      size: "sm",
                      testId: "add-filter-button",
                      startIcon: FilterLines,
                      ref: filterButtonRef,
                      children: "Filter",
                    }}
                  />
                  <ViewButton
                    table={table}
                    testId="materials-view-button"
                    defaultColumnVisibility={defaultColumnVisibility}
                    open={viewMenuOpen}
                    toggleOpen={toggleViewMenuOpen}
                    aboveMenu={
                      <div>
                        <div className="border-b border-grey-200 px-4 py-4">
                          <MaterialsSortSelect
                            fullWidth
                            buttonProps={{size: "xs"}}
                            orderBy={matOrderBy}
                            updateOrderBy={updateOrderByFromViewMenu}
                          />
                        </div>
                        <label // eslint-disable-line jsx-a11y/label-has-associated-control
                          className="flex items-center justify-between border-b border-grey-200 px-3.5 py-4"
                        >
                          Show absent
                          <Toggle
                            testId="show-absent-toggle"
                            size="xs"
                            onChange={(e) => {
                              setExcludeMaterialFilter(!e.target.checked)
                            }}
                            checked={filters.excludeAbsentMaterial !== true}
                          />
                        </label>
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
          </DataTableToolbar>
          {filterCounts.total - filterCounts.excludeAbsentMaterial > 0 && (
            <DataTableToolbar className="flex border-t border-grey-200">
              <PlantMaterialsFilters
                hideAbsentFilter
                excludeAbsentPlantMaterial={
                  filters.excludeAbsentMaterial === true
                }
                onChange={setExcludeMaterialFilter}
                filters={filters}
                dispatchFilters={dispatchFilters}
                resetFilters={resetFilters}
                filterCount={filterCounts.total}
              />
            </DataTableToolbar>
          )}
          <DataTable
            table={table}
            ref={ref}
            loading={loading}
            emptyState={emptyState}
          >
            {table.getRowModel().rows.map((row) => {
              return (
                <ContextMenu key={row.id}>
                  <ContextMenuTrigger asChild>
                    <DataTableRow
                      data-cy="materials-table-row"
                      row={row}
                      onClick={(e) => {
                        onRowClick(e, row.original)
                      }}
                    />
                  </ContextMenuTrigger>
                  <MaterialsContextMenuContent
                    isTrash={isTrash}
                    matSearch={matSearch}
                    preparedFilters={preparedFilters}
                    clickedRow={row.original}
                    selectedRecordsIDs={selectedRecordsIDs}
                    onRequestDownload={handleDownload}
                  />
                </ContextMenu>
              )
            })}
          </DataTable>
          <DataTableFooter
            table={table}
            onPreviousPage={onPreviousPage}
            onNextPage={onNextPage}
            total={data?.materialsTotal}
            hasPreviousPage={data?.pageInfo.hasPreviousPage}
            hasNextPage={data?.pageInfo.hasNextPage}
            onRequestDownload={handleDownload}
            onActionsMenu={toggleActionsMenuOpen}
            onTrashRecords={() => {
              setTrashDialogOpen(true)
            }}
            trashButtonRef={trashButtonRef}
            previousPageLoading={previousPageLoading}
            nextPageLoading={nextPageLoading}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsChange}
            testIdPrefix="materials"
            fetching={plantMaterialsQuery.fetching}
          />
        </>
      )}

      {selectedRecordsIDs.length > 0 && (
        <>
          <TrashMaterialsDialog
            setOpen={setTrashDialogOpen}
            open={trashDialogOpen}
            materialsIds={selectedRecordsIDs}
            triggerRef={trashButtonRef}
            onSuccess={() => {
              table.resetRowSelection()
              toggleSelectMode()
            }}
          />
          <MaterialsActionMenu
            selectedRecordsIDs={selectedRecordsIDs}
            open={actionsMenuOpen}
            toggleOpen={toggleActionsMenuOpen}
            onRequestDownload={handleDownload}
          />
        </>
      )}
    </>
  )
}
