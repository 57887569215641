import {zodResolver} from "@hookform/resolvers/zod"
import {NameSource, TaxonomicRank, TaxonomicStatus} from "generated/graphql"
import {z} from "zod"

export const scientificNameOption = z.object(
  {
    id: z.string().uuid(),
    scientificName: z.string(),
    authorship: z.string().nullish(),
    status: z.nativeEnum(TaxonomicStatus),
    nameSource: z.nativeEnum(NameSource),
    rank: z.nativeEnum(TaxonomicRank),
  },
  {invalid_type_error: "Please select a taxon"},
)

export const familyFilterSchema = z.object({
  is: z.array(scientificNameOption).optional(),
  isNot: z.array(scientificNameOption).optional(),
  hasNoValue: z.boolean().optional(),
})

export const familyFilterResolver = zodResolver(familyFilterSchema)

export type LocalFamilyFilter = z.TypeOf<typeof familyFilterSchema>
