import {Divider} from "src/components/custom/divider"
import {FilterLines} from "@hortis/ui/icons"

export interface FilterDrawerTitleProps {
  title: string
}

export const FilterDrawerTitle = ({title}: FilterDrawerTitleProps) => (
  <>
    <div className="flex items-center gap-3 pb-3">
      <FilterLines size="20px" />
      <div className="font-medium text-grey-900">{title}</div>
    </div>
    <Divider spacing={2} />
  </>
)
