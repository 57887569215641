import {PlantMaterialSchemaZod} from "src/features/create-accession/components/add-plant-material-form/schema"
import {z} from "zod"

const AccessionOption = z.object({
  id: z.string().uuid(),
  accessionNumber: z.string(),
  scientificName: z.string(),
})

export type AccessionOption = z.infer<typeof AccessionOption>

export const newMaterialSchema = PlantMaterialSchemaZod.and(
  z.object({accessionOption: AccessionOption}),
)

export type NewMaterialSchema = z.TypeOf<typeof newMaterialSchema>
export type NewMaterialSchemaInput = z.input<typeof newMaterialSchema>
