import {useMemo} from "react"
import {A, O} from "@mobily/ts-belt"
import {pipe} from "fp-ts/function"
import type {MaterialFieldsFragment} from "generated/graphql"

export const getThumbnailPhoto = (
  material:
    | Pick<MaterialFieldsFragment, "images" | "primaryImage">
    | undefined
    | null,
) =>
  material?.primaryImage == null
    ? pipe(material?.images, O.fromNullable, O.flatMap(A.head), O.toNullable)
    : material.primaryImage

export const useThumbnailPhoto = (
  material: MaterialFieldsFragment | undefined | null,
) => useMemo(() => getThumbnailPhoto(material), [material])

export const getThumbnailPhotoUrl = (
  material:
    | Pick<MaterialFieldsFragment, "images" | "primaryImage">
    | undefined
    | null,
) => {
  const photo = getThumbnailPhoto(material)
  return photo == null ? undefined : `${photo.bucketName}/${photo.fileName}`
}
