import {zodResolver} from "@hookform/resolvers/zod"
import {Button} from "@hortis/ui/button"
import {
  SheetBody,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from "@hortis/ui/sheet"
import * as E from "fp-ts/Either"
import {pipe} from "fp-ts/function"
import {useCreateLocationMutation} from "generated/graphql"
import {useEffect} from "react"
import type {UseFormProps} from "react-hook-form"
import {FormProvider, useForm} from "react-hook-form"
import {FormTextField} from "src/components/text-field"
import {z} from "zod"
import {useSnackbarStore} from "../../../../components/snackbar-controller/snackbar-store"
import {onFailure, onSuccess} from "../../../../notification-snack-utils"
import {useCollectionSite} from "../../../../utils/hooks/collection-site"
import * as OE from "../../../../utils/option-either"
import {showErrorMessage} from "../../../create-accession/components/create-accession/utils"
import type {CurrentLocation} from "./drawer-context"
import type {LocationFieldsProps} from "./types"
const errorMap: z.ZodErrorMap = (error, ctx) => {
  if (error.path[0] === "name") {
    return {message: "Please enter a location name"}
  }
  if (error.message != null) {
    return {message: error.message}
  }
  return {message: ctx.defaultError}
}

export const AddLocationSchema = z.object({
  name: z.string().min(1),
  description: z.string().min(1).nullable(),
})
export type AddLocationSchema = z.TypeOf<typeof AddLocationSchema>

const resolver: UseFormProps<AddLocationSchema>["resolver"] = zodResolver(
  AddLocationSchema,
  {errorMap},
)

export const NewLocationSheetContent = ({
  onClose,
  initialValue,
  onLocationCreation,
  open,
}: LocationFieldsProps<Omit<CurrentLocation, "id" | "archived">>) => {
  const {setSnack} = useSnackbarStore()
  const collectionSite = useCollectionSite()
  const [_, createLocation] = useCreateLocationMutation()
  const formMethods = useForm<AddLocationSchema>({
    defaultValues: {
      name: initialValue?.name ?? "",
      description: initialValue?.description ?? null,
    },
    criteriaMode: "all",
    resolver,
  })

  useEffect(() => {
    formMethods.reset({
      name: initialValue?.name ?? "",
      description: initialValue?.description ?? null,
    })
  }, [open, formMethods, initialValue])

  const onSubmit = pipe(
    OE.Do,
    OE.apSW("collectionSite", collectionSite),
    OE.map(({collectionSite}) =>
      formMethods.handleSubmit(async (data, e) => {
        e?.preventDefault()
        const res = await createLocation({
          input: {
            collectionSiteId: collectionSite.id,
            ...data,
          },
        })
        if (res.data?.createLocation.success === true) {
          onSuccess(setSnack)(`Location ${data.name} created`)
          if (res.data.createLocation.location != null) {
            onLocationCreation(res.data.createLocation.location)
          }
        } else {
          onFailure(setSnack)(new Error("Failed to create location"))
        }
      }),
    ),
  )

  return pipe(
    OE.sequenceNoneToUndefined({
      onSubmit,
    }),
    E.match(showErrorMessage, ({onSubmit}) => (
      <FormProvider {...formMethods}>
        <SheetContent asChild data-cy="location-drawer">
          <form
            onSubmit={(e) => {
              e.stopPropagation()
              e.preventDefault()
              if (onSubmit != null) {
                void onSubmit(e)
              }
            }}
          >
            <SheetHeader>
              <SheetTitle>New location</SheetTitle>
              <SheetDescription>
                Add a new location to your collection site
              </SheetDescription>
            </SheetHeader>
            <SheetBody className="gap-6">
              <FormTextField
                name="name"
                label="Name"
                required
                testId="location-name-input"
              />
              <FormTextField
                name="description"
                label="Description"
                testId="location-description-input"
              />
            </SheetBody>
            <SheetFooter>
              <Button onClick={onClose} testId="cancel-button">
                Cancel
              </Button>
              <Button
                variant="primary"
                type="submit"
                loading={formMethods.formState.isSubmitting}
                testId="create-location-button"
              >
                Create
              </Button>
            </SheetFooter>
          </form>
        </SheetContent>
      </FormProvider>
    )),
  )
}
