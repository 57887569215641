import type {Interpolation, Theme} from "@emotion/react"
import {twMerge} from "tailwind-merge"
import {useCallback} from "react"

export interface RadioGroupProps<T> {
  label: string
  value: T
  onChange: (val: T) => void
  children: (props: {value: T; onChange: (val: T) => void}) => JSX.Element
  rootCss?: Interpolation<Theme>
  showLabel?: boolean
  className?: string
}

export const RadioGroup = <T,>({
  label,
  value,
  onChange,
  children,
  rootCss,
  showLabel,
  className,
}: RadioGroupProps<T>) => {
  const handleChange = useCallback(
    (val: T) => {
      onChange(val)
    },
    [onChange],
  )

  return (
    <fieldset css={rootCss} className={className}>
      <legend
        className={twMerge(
          `mb-2 text-sm font-medium text-grey-900`,
          showLabel === true ? undefined : `hidden`,
        )}
      >
        {label}
      </legend>
      {typeof children === "function" &&
        children({value, onChange: handleChange})}
    </fieldset>
  )
}
