import * as RA from "fp-ts/ReadonlyArray"
import {flow, pipe} from "fp-ts/function"
import * as O from "fp-ts/Option"
import {useMeWithSites} from "../../features/organisation/fetchers"
import {mapSlugToHrefs, useCollectionSiteHrefs} from "../../utils/hooks/hrefs"
import * as OE from "../../utils/option-either"

const useMeFirstSiteHrefs = flow(
  useMeWithSites,
  OE.chainNullableToErrorK(new Error("No site available"))(({me}) =>
    pipe(me?.collectionSites, O.fromNullable, O.chain(RA.head), O.toUndefined),
  ),
  OE.map(({urlSlug}) => mapSlugToHrefs(urlSlug)),
  OE.noneAndErrorToUndefined,
)

/**
 * First uses URL slug hrefs, if a slug can't be found,
 * creates site hrefs based on user's first collection site found
 * on the 'me' query
 */
export const useFirstSiteHrefs = () => {
  const meFirstSite = useMeFirstSiteHrefs()
  const hrefs = useCollectionSiteHrefs()

  return pipe(
    hrefs,
    O.fromNullable,
    O.fold(
      () => meFirstSite,
      () => hrefs,
    ),
  )
}
