import {IconButton} from "@hortis/ui/button"
import {DialogContent, DialogHeader, DialogTitle} from "@hortis/ui/dialog"
import {Plus, XClose} from "@hortis/ui/icons"
import {TwicImg} from "@twicpics/components/react"
import {type MaterialFieldsFragment} from "generated/graphql"
import {type RefObject} from "react"
import {Lightbox, LightboxTrigger} from "src/components/lightbox"

interface RecordPhotosDialogProps {
  setOpen: (open: boolean) => void
  openUploadDialog: () => void
  material: MaterialFieldsFragment
  triggerRef?: RefObject<HTMLButtonElement>
  canEdit: boolean
}

export const RecordPhotosDialog = ({
  setOpen,
  openUploadDialog,
  material,
  triggerRef,
  canEdit,
}: RecordPhotosDialogProps) => {
  return (
    <DialogContent
      data-cy="show-all-material-photos-dialog"
      className="max-w-[650px] !gap-0"
      triggerRef={triggerRef}
    >
      <DialogHeader>
        <DialogTitle className="flex justify-between border-b border-grey-200 pb-2 ">
          Photos
          <div className="flex gap-2">
            {canEdit && !material.archived && (
              <IconButton
                ariaLabel={"add-record-photo"}
                data-cy={"add-record-photo-button"}
                variant="tertiaryGray"
                onClick={() => {
                  setOpen(false)
                  openUploadDialog()
                }}
                size={"xs"}
                icon={Plus}
              />
            )}
            <IconButton
              ariaLabel={"close-dialog"}
              data-cy={"close-dialog-button"}
              variant="tertiaryGray"
              onClick={() => {
                setOpen(false)
              }}
              size={"xs"}
              icon={XClose}
            />
          </div>
        </DialogTitle>
      </DialogHeader>
      <div className="max-h-[350px] overflow-y-scroll py-6">
        <div className="grid grid-cols-1 gap-2 md:grid-cols-4">
          {material.images?.map((image) => (
            <div key={image.id}>
              <Lightbox
                photo={image}
                photos={material.images}
                title={material.id}
              >
                <LightboxTrigger className="block size-full overflow-hidden rounded-xl outline-none ring-primary-200/70 focus-visible:ring">
                  <TwicImg
                    src={`${image.bucketName}/${image.fileName}`}
                    mode="cover"
                    ratio={"1"}
                    eager
                    placeholder="none"
                    className="transition-transform hover:scale-[102%]"
                  />
                </LightboxTrigger>
              </Lightbox>
            </div>
          ))}
        </div>
      </div>
    </DialogContent>
  )
}
