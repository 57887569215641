import * as t from "io-ts"
import {NonEmptyString} from "io-ts-types/NonEmptyString"
import {withMessage} from "io-ts-types/withMessage"

interface EmptyStringBrand {
  readonly EmptyString: unique symbol
}

type EmptyString = t.Branded<string, EmptyStringBrand>

const EmptyString = t.brand(
  t.string,
  (s): s is EmptyString => s === "",
  "EmptyString",
)

export const StringOrNull = t.union([t.string, t.null])
export const NonEmptyStringOrNull = t.union([NonEmptyString, t.null])

const NumberOrEmptyString = t.union([t.number, EmptyString])

export const SignedNumber = withMessage(
  NumberOrEmptyString,
  () => "Please complete negative number or remove it",
)
