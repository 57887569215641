export const copyToClipboard = async (
  text: string,
  onSuccess: () => void,
  onFailure: (e: Error) => void,
) => {
  try {
    if ("clipboard" in navigator) {
      await navigator.clipboard.writeText(text)
      onSuccess()
    } else {
      throw new Error("Clipboard was unavailable")
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      onFailure(error)
    }
  }
}
