import {Badge} from "@hortis/ui/badge"
import {Button} from "@hortis/ui/button"
import {Dialog, DialogTrigger} from "@hortis/ui/dialog"
import {useMediaQuery} from "@hortis/ui/hooks/media-query"
import {Link} from "@tanstack/react-router"
import type {MaterialFieldsFragment} from "generated/graphql"
import {useCallback, useState, type ReactElement} from "react"
import {RelatedRecordsDialog} from "src/components/dialog/variants/related-records"
import {createMaterialNumber} from "src/features/collection/components/plant-materials/material-number"
import MaterialRecordCard from "src/features/records/components/new-record-card/variants/material-record-card"
import {
  RecordSection,
  RecordSectionContent,
  RecordSectionContentGroup,
  RecordSectionHeader,
  RecordSectionHeaderButtons,
  RecordSectionTitle,
} from "src/features/records/components/record-section/new-record-section"
import {useCollectionSiteHrefs} from "src/utils/hooks/hrefs"
import {getThumbnailPhotoUrl} from "src/utils/photos"
import {twMerge} from "tailwind-merge"

export interface MaterialRecordRelatedRecordsProps {
  material: MaterialFieldsFragment
  isMapPage?: boolean
}

export const MaterialRecordRelatedRecords = ({
  material,
  isMapPage = false,
}: MaterialRecordRelatedRecordsProps): ReactElement => {
  const isNotMobile = useMediaQuery("sm")

  const [viewAllRecords, setViewAllRecords] = useState(false)
  const collectionSiteHrefs = useCollectionSiteHrefs()

  const closeViewAllRecordsDialog = useCallback(() => {
    setViewAllRecords(false)
  }, [])

  const relatedMaterials = material.accession?.plantMaterial?.filter(
    ({id}) => id !== material.id,
  )

  return (
    <RecordSection id="related-records">
      <RecordSectionHeader>
        <RecordSectionTitle>Related records</RecordSectionTitle>
        {relatedMaterials && relatedMaterials.length > 0 && (
          <>
            <Badge testId="related-records-count" color="primary">
              {relatedMaterials.length}
            </Badge>
            <RecordSectionHeaderButtons>
              <Dialog open={viewAllRecords} onOpenChange={setViewAllRecords}>
                <DialogTrigger asChild>
                  <Button
                    data-cy="view-all-related-records"
                    size="xs"
                    variant="tertiaryGray"
                    className="font-medium"
                  >
                    View all
                  </Button>
                </DialogTrigger>
                <RelatedRecordsDialog setOpen={setViewAllRecords}>
                  {relatedMaterials.map((relatedMaterial) => (
                    <Link
                      onClick={closeViewAllRecordsDialog}
                      key={relatedMaterial.id}
                      to="/sites/$siteSlug/materials/$materialNumber"
                      from="/sites/$siteSlug/materials/$materialNumber"
                      params={{
                        materialNumber: createMaterialNumber(
                          material.accession?.accessionNumber ?? "-",
                          relatedMaterial.qualifier,
                        ),
                      }}
                    >
                      <MaterialRecordCard
                        className="-ml-3"
                        materialNumber={`${
                          material.accession?.accessionNumber ?? "-"
                        }/${relatedMaterial.qualifier}`}
                        scientificName={
                          material.accession?.scientificName ?? "-"
                        }
                        location={relatedMaterial.collectionSiteLocation}
                        photoUrl={getThumbnailPhotoUrl(relatedMaterial)}
                        group={relatedMaterial.materialGroup}
                        status={relatedMaterial.status}
                        size={isNotMobile && !isMapPage ? "md" : "sm"}
                        variant="Row"
                      />
                    </Link>
                  ))}
                </RelatedRecordsDialog>
              </Dialog>
            </RecordSectionHeaderButtons>
          </>
        )}
      </RecordSectionHeader>

      <RecordSectionContent>
        <RecordSectionContentGroup
          className={twMerge(
            "material-related-records grid w-full grid-cols-1 md:grid-cols-2",
            isMapPage && "flex flex-col",
          )}
        >
          {relatedMaterials && relatedMaterials.length > 0 ? (
            relatedMaterials.slice(0, 4).map((relatedMaterial) => {
              return (
                collectionSiteHrefs !== undefined && (
                  <Link
                    key={relatedMaterial.id}
                    to={`/${
                      collectionSiteHrefs.plantMaterials
                    }/${encodeURIComponent(
                      createMaterialNumber(
                        material.accession?.accessionNumber ?? "-",
                        relatedMaterial.qualifier,
                      ),
                    )}`}
                  >
                    <MaterialRecordCard
                      className="-ml-3 -mt-3"
                      materialNumber={`${
                        material.accession?.accessionNumber ?? "-"
                      }/${relatedMaterial.qualifier}`}
                      scientificName={material.accession?.scientificName ?? "-"}
                      photoUrl={getThumbnailPhotoUrl(relatedMaterial)}
                      group={relatedMaterial.materialGroup}
                      status={relatedMaterial.status}
                      size={isNotMobile ? "md" : "sm"}
                      variant="Row"
                      location={relatedMaterial.collectionSiteLocation}
                    />
                  </Link>
                )
              )
            })
          ) : (
            <div className="flex flex-col">
              <p className="text-base font-medium text-grey-900">
                No related records
              </p>
              <p className="text-sm font-normal text-grey-500">
                The associated accession does not have any other materials.
              </p>
            </div>
          )}
        </RecordSectionContentGroup>
      </RecordSectionContent>
    </RecordSection>
  )
}
