import {gql} from "graphql-request"

export const GET_PUBLIC_ORG = gql`
  query GetPublicOrganisation($subdomain: String!) {
    publicOrganisation(subdomain: $subdomain) {
      name
      subdomain
      avatarUrl
    }
  }
`
