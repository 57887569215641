import type {PlantMaterialFilter} from "generated/graphql"
import {Fragment} from "react"
import {FilterChip} from "src/features/filters/filter-chip-group"
import type {OpenFilterArgs} from "../filters"
import type {MaterialFilterType} from "../types"

export const PhotosFilterChipGroup = ({
  id,
  filter,
  hidden,
  ...props
}: {
  id: string
  handleOpenFilter: (args: OpenFilterArgs<MaterialFilterType>) => void
  handleDelete: (id: string) => void
  filter: PlantMaterialFilter["hasImages"]
  hidden?: boolean
}) =>
  filter == null || hidden === true ? (
    <Fragment />
  ) : (
    <>
      {filter.eq === true && (
        <FilterChip
          filterLabel="Photos"
          filterId={id}
          filterType="photos"
          operation="available"
          {...props}
        />
      )}
      {filter.neq != null && (
        <FilterChip
          filterLabel="Photos"
          filterId={id}
          filterType="photos"
          operation="missing"
          {...props}
        />
      )}
    </>
  )
