import {Button} from "@hortis/ui/button"
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@hortis/ui/dialog"
import {useUpdatePlantMaterialMutation} from "generated/graphql"
import {useState} from "react"
import {useSnackbarStore} from "src/components/snackbar-controller/snackbar-store"
import {
  onFailure,
  onSuccess as onSuccessSnack,
} from "src/notification-snack-utils"

interface MakePrivateDialogProps {
  id: string
  onSuccess: () => void
  open: boolean
  onOpenChange: (open: boolean) => void
}

const MakePrivateDialog = ({
  id,
  onSuccess,
  open,
  onOpenChange,
}: MakePrivateDialogProps) => {
  const {setSnack} = useSnackbarStore()
  const [_, updatePlantMaterial] = useUpdatePlantMaterialMutation()
  const [loading, setLoading] = useState<boolean>(false)

  const onSubmit = async () => {
    setLoading(true)
    const res = await updatePlantMaterial({
      materialId: id,
      updates: {
        public: false,
      },
    })
    setLoading(false)
    if (res.data?.updatePlantMaterial?.__typename !== "PlantMaterial") {
      onFailure(setSnack)(new Error("Failed to update materials"))
      return
    }
    onSuccessSnack(setSnack)(`Successfully updated material`)
    onOpenChange(false)
    onSuccess()
  }

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent data-cy="make-private-dialog">
        <DialogHeader>
          <DialogTitle>
            Are you sure you want to make this material private?
          </DialogTitle>
          <DialogDescription>
            It will no longer be displayed on this public site.
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <DialogClose asChild>
            <Button size="lg" fullWidth>
              Cancel
            </Button>
          </DialogClose>
          <Button
            size="lg"
            variant="primary"
            loading={loading}
            fullWidth
            testId="make-private-confirm"
            onClick={onSubmit}
          >
            Confirm
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export {MakePrivateDialog}
