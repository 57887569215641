import {
  SheetBody,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from "@hortis/ui/sheet"
import {useMemo} from "react"
import {
  ProvenanceCategoryCode,
  type AccessionFieldsFragment,
} from "../../../../../../../generated/graphql"
import {OverviewCard} from "../../../../components/overview-card"
import {extractAccessionDataFormFields} from "../edit-accession-data"
import {extractCollectionDataFormFields} from "../edit-collection-data"
import type {BaseEditStageProps} from "../types"
import {useAccessionEditStageCallback} from "../types"
import {countFields} from "../utils"

const countCollectionDataFields = (accession: AccessionFieldsFragment) => {
  const collectionFields = Object.values(
    extractCollectionDataFormFields(accession),
  )

  return {
    count: countFields(collectionFields),
    total: collectionFields.length,
  }
}

const countAccessionDataFields = (accession: AccessionFieldsFragment) => {
  const accessionFields = Object.values(
    extractAccessionDataFormFields(accession),
  )

  return {
    count: countFields(accessionFields),
    total: accessionFields.length,
  }
}

export const AccessionRecordOverview = ({
  accession,
  transitionStage,
}: BaseEditStageProps) => {
  const collectionDataFields = useMemo(
    () => countCollectionDataFields(accession),
    [accession],
  )
  const accessionDataFields = useMemo(
    () => countAccessionDataFields(accession),
    [accession],
  )

  const {
    editAccessionData,
    editCollectionData,
    plantMaterial,
    addPlantMaterial,
  } = useAccessionEditStageCallback(transitionStage)

  const hasPlantMaterial = useMemo(
    () => accession.plantMaterial != null && accession.plantMaterial.length > 0,
    [accession],
  )

  return (
    <>
      <SheetHeader>
        <SheetTitle>Edit accession</SheetTitle>
        <SheetDescription>{accession.accessionNumber}</SheetDescription>
      </SheetHeader>
      <SheetBody className="gap-4">
        <OverviewCard
          title="Details"
          subtitle={
            accessionDataFields.count === 0
              ? "Not started"
              : `${accessionDataFields.count} / ${accessionDataFields.total} fields complete`
          }
          onClick={editAccessionData}
          testIdPrefix="accession-data"
        />
        {accession.provenanceCategoryCode !== ProvenanceCategoryCode.G &&
          accession.provenanceCategoryCode !== ProvenanceCategoryCode.U && (
            <OverviewCard
              title="Wild collection"
              subtitle={
                collectionDataFields.count === 0
                  ? "Not started"
                  : `${collectionDataFields.count} / ${collectionDataFields.total} fields complete`
              }
              onClick={editCollectionData}
              testIdPrefix="collection-data"
            />
          )}
        <OverviewCard
          title="Material"
          subtitle={
            accession.plantMaterial != null && hasPlantMaterial
              ? `${accession.plantMaterial.length} total`
              : "No material"
          }
          onClick={
            accession.plantMaterial != null && hasPlantMaterial
              ? plantMaterial
              : addPlantMaterial
          }
          testIdPrefix="plant-material"
        />
      </SheetBody>
    </>
  )
}
