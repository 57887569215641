import {Button, IconButton} from "@hortis/ui/button"
import {Dialog, DialogTrigger} from "@hortis/ui/dialog"
import {Edit5} from "@hortis/ui/icons"
import {Tag} from "@hortis/ui/tag"
import type {RecordTagFieldsFragment} from "generated/graphql"
import {
  RecordSection,
  RecordSectionContent,
  RecordSectionHeader,
  RecordSectionHeaderButtons,
  RecordSectionTitle,
} from "src/features/records/components/record-section/new-record-section"
import {useState} from "react"
import {RecordTagDialog} from "src/components/dialog/variants/record-tags"

export const RecordTagSection = ({
  tags,
  requestEdit,
  canEdit,
}: {
  tags: ReadonlyArray<RecordTagFieldsFragment> | undefined | null
  requestEdit?: () => void
  canEdit: boolean
}) => {
  const [recordTagDialogOpen, setRecordTagDialogOpen] = useState(false)

  const closeDialog = () => {
    setRecordTagDialogOpen(false)
  }

  return (
    <RecordSection
      id="tags"
      className="material-record-section"
      data-cy="record-tags"
    >
      <RecordSectionHeader>
        <RecordSectionTitle>Tags</RecordSectionTitle>
        <RecordSectionHeaderButtons>
          {canEdit && (
            <IconButton
              onClick={requestEdit}
              ariaLabel="Edit section"
              data-cy="section-edit-button"
              size="xs"
              variant="tertiaryGray"
              icon={Edit5}
            />
          )}
        </RecordSectionHeaderButtons>
      </RecordSectionHeader>

      <RecordSectionContent className="items-start gap-2">
        <div>
          {tags == null || tags.length === 0 ? (
            "-"
          ) : (
            <div className="flex flex-wrap gap-2">
              {tags.map((tag) => (
                <Tag data-cy={`record-tag-${tag.id}`} key={tag.id} size="sm">
                  {tag.name}
                </Tag>
              ))}
            </div>
          )}
        </div>
        {tags && tags.length > 10 && (
          <Dialog
            open={recordTagDialogOpen}
            onOpenChange={setRecordTagDialogOpen}
          >
            <DialogTrigger>
              <Button
                data-cy="view-all-tags-button"
                size="sm"
                variant="linkgray"
                className="font-medium text-primary-700"
              >
                View all
              </Button>
            </DialogTrigger>
            <RecordTagDialog
              closeDialog={closeDialog}
              tags={tags}
              editTags={requestEdit}
              canEdit={canEdit}
            />
          </Dialog>
        )}
      </RecordSectionContent>
    </RecordSection>
  )
}
