import {IconButton} from "@hortis/ui/button"
import {ButtonGroup} from "@hortis/ui/button/button-group"
import {Minus, Plus} from "@hortis/ui/icons"
import {Tooltip, TooltipContent, TooltipTrigger} from "@hortis/ui/tooltip"
import {useMaplibre} from "src/components/map/utils"
import {mapId} from "src/features/collection-map/utils"
import {useMobile} from "src/utils/hooks/media-query"

export const MapZoomButtons = () => {
  const isMobile = useMobile()
  const {[mapId]: ourMap} = useMaplibre()

  return isMobile ? (
    <></>
  ) : (
    <ButtonGroup
      direction="column"
      className="shadow-mg pointer-events-auto border-none"
    >
      <Tooltip>
        <TooltipTrigger asChild>
          <IconButton
            ariaLabel="Zoom in"
            variant="tertiaryGray"
            onClick={() => {
              ourMap?.zoomIn()
            }}
            size={{xs: "sm", md: "md"}}
            icon={Plus}
            iconProps={{
              className: "!stroke-grey-700 !hover:stroke-grey-800",
            }}
          />
        </TooltipTrigger>
        <TooltipContent side="left">Zoom in</TooltipContent>
      </Tooltip>
      <Tooltip>
        <TooltipTrigger asChild>
          <IconButton
            ariaLabel="Zoom out"
            variant="tertiaryGray"
            onClick={() => {
              ourMap?.zoomOut()
            }}
            size={{xs: "sm", md: "md"}}
            icon={Minus}
            iconProps={{
              className: "!stroke-grey-700 !hover:stroke-grey-800",
            }}
          />
        </TooltipTrigger>
        <TooltipContent side="left">Zoom out</TooltipContent>
      </Tooltip>
    </ButtonGroup>
  )
}
