import {AlertCircle} from "@hortis/ui/icons"
import {pipe} from "@mobily/ts-belt"
import {createRouter} from "@tanstack/react-router"
import {Helmet} from "react-helmet-async"
import {PageError} from "./components/empty-state/page-error"
import {routeTree} from "./routeTree.gen"
import {getSubdomain} from "./utils/hooks/subdomain"

export const router = createRouter({
  routeTree,
  context: {
    subdomain: pipe(getSubdomain(), (subdomain) => {
      if (subdomain == null) {
        throw new Error("Could not find subdomain")
      }
      return subdomain
    }),
  },
  defaultErrorComponent: PageError,
  defaultNotFoundComponent: () => {
    return (
      <div className="flex h-full items-center justify-center bg-grey-50">
        <Helmet>
          <title>404 | Hortis</title>
        </Helmet>
        <div className="flex flex-col items-center space-y-2 rounded-2xl border border-grey-200 bg-white px-6 py-4 text-center shadow-xs">
          <div className="flex items-center gap-2">
            <AlertCircle />
            <h1 className="font-header font-semibold text-grey-900">
              Page not found
            </h1>
          </div>
          <p className="text-sm text-grey-500">
            The page you are looking for does not exist.
          </p>
        </div>
      </div>
    )
  },
})

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router
  }
}
