import {useCallback} from "react"
import type {MaplibreRef} from "src/components/map/utils"
import {useResize} from "src/utils/hooks/resize"

export const useMapResize = (map?: MaplibreRef) => {
  const onResize = useCallback(() => {
    // Resize seems to require a delay to use the new viewport size
    setTimeout(() => {
      if (map != null) {
        map.resize()
      }
    }, 50)
  }, [map])

  useResize(onResize)
}
