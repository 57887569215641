import type {RecordTagFilter} from "generated/graphql"
import {countIdComparator} from "../count/comparators/id"
import {countStringComparator} from "../count/comparators/string"

export const countTagsFilter = (filter: NonNullable<RecordTagFilter>) => {
  let count = 0
  if (filter.id != null) {
    count += countIdComparator(filter.id)
  }
  if (filter.name != null) {
    count += countStringComparator(filter.name)
  }
  return count
}
