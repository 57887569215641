import differenceInYears from "date-fns/differenceInYears/index"
import differenceInDays from "date-fns/differenceInDays/index"
import differenceInWeeks from "date-fns/differenceInWeeks/index"
import differenceInMonths from "date-fns/differenceInMonths/index"
import {match} from "ts-pattern"
import {Period} from "generated/graphql"
import type {FollowUp} from "generated/graphql"

export const isOverdueFollowup = (
  lastObserved: Date,
  followUp: FollowUp,
  followUpDate?: Date,
) => {
  const currentDate = followUpDate ?? new Date()
  return match(followUp.period)
    .with(
      Period.Days,
      () => differenceInDays(lastObserved, currentDate) <= -followUp.count,
    )
    .with(
      Period.Weeks,
      () => differenceInWeeks(lastObserved, currentDate) <= -followUp.count,
    )
    .with(
      Period.Months,
      () => differenceInMonths(lastObserved, currentDate) <= -followUp.count,
    )
    .with(
      Period.Years,
      () => differenceInYears(lastObserved, currentDate) <= -followUp.count,
    )
    .run()
}
