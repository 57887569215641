import {twMerge} from "tailwind-merge"
import type {CSSProperties} from "react"
import type {Interpolation, Theme} from "@emotion/react"
import {colors} from "../../theme/colors"
import type {ButtonSize, ButtonVariant} from "./types"

const baseButtonClasses =
  "relative inline-flex items-center justify-center cursor-pointer font-medium transition-all duration-150 will-change-[padding] ring-primary-100 disabled:cursor-not-allowed text-center truncate [&>*]:transition-opacity outline-none focus-visible:ring"
const baseIconButtonClasses =
  "relative inline-flex justify-center items-center cursor-pointer transition-all duration-150 disabled:cursor-default ring-primary-100 outline-none focus-visible:ring"

const secondaryGrayClasses =
  "ring-grey-200 ring-opacity-50 border-grey-300 border bg-white disabled:bg-white text-grey-700 hover:text-grey-800 hover:bg-grey-50 shadow-xs disabled:border-grey-200 disabled:text-grey-300 focus-visible:ring"
const primaryClasses =
  "shadow-xs bg-primary-600 text-white hover:bg-primary-700 disabled:bg-primary-200"
const tertiaryGrayClasses =
  "text-grey-500 border border-grey-100/0 hover:bg-grey-50 hover:text-grey-600 disabled:text-grey-300 disabled:hover:bg-white/0 focus-visible:ring-grey-200/75"
const tertiaryColorClasses =
  "text-primary-700 border border-primary-100/0 hover:bg-primary-50 hover:text-primary-800 disabled:text-grey-300"
const secondaryColorClasses =
  "bg-primary-50 text-primary-700 hover:text-primary-800 hover:bg-primary-100 disabled:bg-primary-25 disabled:text-primary-300"
const destructiveClasses =
  "bg-error-600 text-white hover:bg-error-700 disabled:bg-error-200 focus-visible:ring-error-100"
const linkColorClasses =
  "!p-0 text-primary-700 hover:text-primary-800 disabled:text-grey-300 !rounded-none"
const linkGrayClasses =
  "!p-0 text-grey-500 hover:text-grey-600 disabled:text-grey-300 !rounded-none"

const warningClasses =
  "shadow-xs bg-warning-600 text-white hover:bg-warning-700 disabled:bg-warning-200 outline-none focus-visible:ring ring-warning-300"

export const buttonClasses: Record<ButtonVariant, string> = {
  primary: twMerge(baseButtonClasses, primaryClasses),
  secondaryGray: twMerge(baseButtonClasses, secondaryGrayClasses),
  secondaryColor: twMerge(baseButtonClasses, secondaryColorClasses),
  tertiaryGray: twMerge(baseButtonClasses, tertiaryGrayClasses),
  tertiaryColor: twMerge(baseButtonClasses, tertiaryColorClasses),
  destructive: twMerge(baseButtonClasses, destructiveClasses),
  linkcolor: twMerge(baseButtonClasses, linkColorClasses),
  linkgray: twMerge(baseButtonClasses, linkGrayClasses),
  warning: twMerge(baseButtonClasses, warningClasses),
}

export const buttonIconSizing: Record<ButtonSize, Interpolation<Theme>> = {
  xs: {width: "16px", height: "16px"},
  sm: {width: "20px", height: "20px"},
  md: {width: "20px", height: "20px"},
  lg: {width: "20px", height: "20px"},
  xl: {width: "20px", height: "20px"},
  "2xl": {width: "20px", height: "24px"},
}

export const buttonSizing: Record<ButtonSize, Interpolation<Theme>> = {
  xs: {
    padding: "6px 12px",
    gap: "6px",
    fontSize: "0.75rem",
    lineHeight: "1.125rem",
    borderRadius: "6px",
  },
  sm: {
    padding: "8px 14px",
    gap: "8px",
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
    borderRadius: "8px",
  },
  md: {
    padding: "10px 16px",
    gap: "8px",
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
    borderRadius: "8px",
  },
  lg: {
    padding: "10px 18px",
    gap: "8px",
    fontSize: "1rem",
    lineHeight: "1.5rem",
    borderRadius: "8px",
  },
  xl: {
    padding: "12px 20px",
    gap: "8px",
    fontSize: "1rem",
    lineHeight: "1.5rem",
    borderRadius: "8px",
  },
  "2xl": {
    padding: "16px 28px",
    gap: "12px",
    fontSize: "1.125rem",
    lineHeight: "1.75rem",
    borderRadius: "8px",
  },
}

export const loadingSpinnerColor: Record<ButtonVariant, string> = {
  primary: colors.white,
  secondaryGray: colors.grey[500],
  secondaryColor: colors.primary[700],
  tertiaryGray: colors.primary[600],
  tertiaryColor: colors.primary[700],
  destructive: colors.white,
  linkcolor: colors.primary[600],
  linkgray: colors.grey[500],
  warning: colors.white,
}

export const loadingSpinnerSize: Record<ButtonSize, CSSProperties> = {
  xs: {width: 13, height: 13},
  sm: {width: 14, height: 14},
  md: {width: 16, height: 16},
  lg: {width: 18, height: 18},
  xl: {width: 20, height: 20},
  "2xl": {width: 22, height: 22},
}

export const iconButtonStyles: Record<ButtonVariant, string> = {
  primary: twMerge(baseIconButtonClasses, primaryClasses),
  secondaryGray: twMerge(baseIconButtonClasses, secondaryGrayClasses),
  secondaryColor: twMerge(baseIconButtonClasses, secondaryColorClasses),
  tertiaryGray: twMerge(baseIconButtonClasses, tertiaryGrayClasses),
  tertiaryColor: twMerge(baseIconButtonClasses, tertiaryColorClasses),
  destructive: twMerge(baseIconButtonClasses, destructiveClasses),
  linkcolor: twMerge(baseIconButtonClasses, linkColorClasses),
  linkgray: twMerge(baseIconButtonClasses, linkGrayClasses),
  warning: twMerge(baseIconButtonClasses, warningClasses),
}

export const iconButtonSizing: Record<ButtonSize, Interpolation<Theme>> = {
  xs: {padding: "8px", borderRadius: "6px"},
  sm: {padding: "8px", borderRadius: "8px"},
  md: {padding: "10px", borderRadius: "8px"},
  lg: {padding: "12px", borderRadius: "8px"},
  xl: {padding: "14px", borderRadius: "8px"},
  "2xl": {padding: "16px", borderRadius: "8px"},
}
