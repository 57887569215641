import {Button, IconButton} from "@hortis/ui/button"
import {Check, RefreshCw5, Settings4} from "@hortis/ui/icons"
import type {RowData, Table, VisibilityState} from "@tanstack/react-table"
import {useCallback, useRef, type ReactNode} from "react"
import {colors} from "src/colors"
import {useToggle} from "src/utils/hooks/toggle"
import {Menu} from "../menu"
import {Tooltip} from "../tooltip"

export interface ViewButtonProps<TData extends RowData> {
  table: Table<TData>
  defaultColumnVisibility?: VisibilityState
  aboveMenu?: ReactNode
  testId?: string
  open?: boolean
  toggleOpen?: () => void
}

export const ViewButton = <TData extends RowData>({
  table,
  defaultColumnVisibility,
  aboveMenu,
  testId,
  open,
  toggleOpen,
}: ViewButtonProps<TData>) => {
  const viewButtonRef = useRef<HTMLButtonElement | null>(null)
  const [viewMenuOpen, toggleViewMenuOpen] = useToggle()
  const resetColumns = useCallback(() => {
    table.setColumnVisibility(defaultColumnVisibility ?? {})
    if (toggleOpen == null) {
      toggleViewMenuOpen()
    } else {
      toggleOpen()
    }
  }, [table, toggleViewMenuOpen, defaultColumnVisibility, toggleOpen])

  return (
    <>
      <Button
        onClick={toggleOpen ?? toggleViewMenuOpen}
        ref={viewButtonRef}
        startIcon={Settings4}
        size="sm"
        testId={testId}
      >
        View
      </Button>
      <Menu
        anchorOrigin={{vertical: "bottom", horizontal: "right"}}
        transformOrigin={{vertical: "top", horizontal: "right"}}
        anchorEl={viewButtonRef}
        onClose={toggleOpen ?? toggleViewMenuOpen}
        open={open ?? viewMenuOpen}
        className="[&_.MuiPaper-root]:min-w-[232px]"
        aboveMenu={
          <div className="sticky top-0 z-10 bg-white text-xs font-medium text-grey-500">
            {aboveMenu}
            <div className="flex items-center justify-between gap-3 px-3.5 pb-1 pt-3">
              Columns
              <Tooltip title="Reset columns">
                <IconButton
                  onClick={resetColumns}
                  ariaLabel="Reset columns"
                  size="xs"
                  variant="tertiaryGray"
                  icon={RefreshCw5}
                />
              </Tooltip>
            </div>
          </div>
        }
        options={table
          .getAllColumns()
          .filter(
            (column) =>
              column.accessorFn != null &&
              column.getCanHide() &&
              column.columnDef.meta?.invisible !== true,
          )
          .map((column) => ({
            value: column.id,
            label: (
              <div className="flex flex-1 items-center justify-between gap-3">
                <span className="truncate">{column.id}</span>
                {column.getIsVisible() && (
                  <Check color={colors.primary[600]} size="20px" />
                )}
              </div>
            ),
            onClick: () => {
              column.toggleVisibility()
            },
          }))}
      />
    </>
  )
}
