import {pipe, R} from "@mobily/ts-belt"
import * as z from "zod"
import {ManualAccessionNumberError} from "generated/graphql"

const accessionNumberErrorMessages: Record<ManualAccessionNumberError, string> =
  {
    [ManualAccessionNumberError.DuplicateAccessionNumber]:
      "Accession number already exists",
    [ManualAccessionNumberError.InvalidAccessionNumber]:
      "Manual accession numbers cannot be the same format as generated accession numbers",
    [ManualAccessionNumberError.InvalidCharacters]:
      "Accession number contains invalid characters",
  }

type MakeValidationReq = (
  accessionNumber: string,
) => Promise<R.Result<ReadonlyArray<ManualAccessionNumberError>, Error>>

export const validateAccessionNumber_ =
  (makeValidationReq: MakeValidationReq) =>
  async (accessionNumber: string, ctx: z.RefinementCtx) => {
    try {
      pipe(
        await makeValidationReq(accessionNumber),
        R.match(
          (errors) => {
            errors.forEach((error) => {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: accessionNumberErrorMessages[error],
              })
            })
          },
          (error) => {
            throw error
          },
        ),
      )
    } catch {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Unable to validate accession number`,
      })
    }
  }
