import {colors} from "src/colors"
import type {IconProps} from "./types"

export const SwitchHorizontal1 = ({
  color = colors.grey[700],
  ...props
}: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M16.6663 14.1667H3.33301M3.33301 14.1667L6.66634 10.8333M3.33301 14.1667L6.66634 17.5M3.33301 5.83333H16.6663M16.6663 5.83333L13.333 2.5M16.6663 5.83333L13.333 9.16667"
        stroke={color}
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
