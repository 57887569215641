import {CommandEmpty, CommandGroup, CommandItem} from "@hortis/ui/command"
import {Download1, Tag1, Trash3} from "@hortis/ui/icons"
import {useCallback, useState} from "react"
import {
  CommandMenuCollectionSiteTagsPage,
  CommandMenuOrganisationTagsPage,
} from "src/components/command-menu/command-menu-pages/tags/taxon-tags"
import {match} from "ts-pattern"
import {ActionsMenu} from "../actions-menu"

type TaxaActionMenuProps = {
  openTrashRecordModal?: (open: boolean) => void
  onRequestDownload: () => void
  selectedRecordsIDs: ReadonlyArray<string>
  open: boolean
  toggleOpen: () => void
  isOrg: boolean
}

type Page = "tags" | "actions"

const TaxaActionMenu = ({
  open,
  toggleOpen,
  onRequestDownload,
  openTrashRecordModal,
  selectedRecordsIDs,
  isOrg,
}: TaxaActionMenuProps) => {
  const [search, setSearch] = useState("")
  const [menuPage, setMenuPage] = useState<Page>("actions")
  const clearSearch = () => {
    setSearch("")
  }

  const resetMenu = useCallback(() => {
    setMenuPage("actions")
    clearSearch()
  }, [])
  const closeAndResetMenu = useCallback(() => {
    toggleOpen()
    resetMenu()
  }, [resetMenu, toggleOpen])

  return (
    <ActionsMenu
      open={open}
      title={`${selectedRecordsIDs.length} taxa selected`}
      closeAndResetMenu={closeAndResetMenu}
      search={search}
      setSearch={setSearch}
      placeholder={match(menuPage)
        .with("actions", () => "Search actions...")
        .with("tags", () => "Add / remove tags...")
        .exhaustive()}
    >
      {match(menuPage)
        .with("actions", () => (
          <>
            <CommandEmpty>No results</CommandEmpty>
            <CommandGroup>
              <CommandItem
                onSelect={() => {
                  setMenuPage("tags")
                  clearSearch()
                }}
                data-cy="tags-menu-item"
              >
                <Tag1 className="text-grey-500" />
                Add / remove tags
              </CommandItem>
              <CommandItem
                onSelect={() => {
                  onRequestDownload()
                  closeAndResetMenu()
                }}
                data-cy="download-menu-item"
              >
                <Download1 className="text-grey-500" />
                Download
              </CommandItem>
              {openTrashRecordModal && (
                <CommandItem
                  onSelect={() => {
                    openTrashRecordModal(true)
                    closeAndResetMenu()
                  }}
                  data-cy="trash-menu-item"
                >
                  <Trash3 className="text-grey-500" />
                  Move to trash
                </CommandItem>
              )}
            </CommandGroup>
          </>
        ))
        .with("tags", () =>
          isOrg ? (
            <CommandMenuOrganisationTagsPage
              selectedRecordsIDs={selectedRecordsIDs}
              search={search}
              clearSearch={clearSearch}
            />
          ) : (
            <CommandMenuCollectionSiteTagsPage
              selectedRecordsIDs={selectedRecordsIDs}
              search={search}
              clearSearch={clearSearch}
            />
          ),
        )
        .exhaustive()}
    </ActionsMenu>
  )
}

export default TaxaActionMenu
