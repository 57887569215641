import {useCallback} from "react"
import {useNavigate} from "@tanstack/react-router"
import {Button, IconButton} from "@hortis/ui/button"
import {Divider} from "src/components/custom/divider"
import {XClose} from "@hortis/ui/icons"
import {SlExitIcon} from "../../../components/icons/streamline/exit"
import {SettingsNav} from "./settings-nav"

interface DrawerContentProps {
  onClose?: () => void
  returnTo: string
  mobile?: boolean
}

export const DrawerContent = ({
  returnTo,
  onClose,
  mobile,
}: DrawerContentProps) => {
  const navigate = useNavigate()

  const navigateToReturnTo = useCallback(() => {
    void navigate({to: returnTo})
  }, [returnTo, navigate])

  return (
    <>
      <div className="sticky top-0 z-50 bg-white">
        <div className="flex items-center justify-between px-6 py-4">
          <div className="text-xl font-medium">Settings</div>
          {onClose != null && (
            <IconButton
              size="sm"
              variant="tertiaryGray"
              onClick={onClose}
              ariaLabel="Close"
              icon={XClose}
            />
          )}
        </div>
        <div className="flex flex-col gap-3 px-2">
          <Button
            variant="tertiaryGray"
            startIcon={<SlExitIcon width="20px" height="20px" />}
            className="w-full justify-start"
            size="sm"
            onClick={navigateToReturnTo}
          >
            Exit settings
          </Button>
          <Divider className="mx-4" />
        </div>
      </div>
      <div className="px-2 pt-6">
        <SettingsNav onNavigate={onClose} mobile={mobile} />
      </div>
    </>
  )
}
