import type {DateComparator} from "generated/graphql"

export enum DatePresets {
  Today = "Today",
  Yesterday = "Yesterday",
  Last7Days = "Last 7 days",
  ThisMonth = "This month",
  LastMonth = "Last month",
  ThisYear = "This year",
  LastYear = "Last year",
}

export const initialDateFilterValue = (filter: DateComparator | undefined) => {
  return filter?.eq == null
    ? filter?.lte != null && filter.gte != null
      ? {
          from: new Date(filter.gte),
          to: new Date(filter.lte),
        }
      : {
          from: undefined,
          to: undefined,
        }
    : {
        from: new Date(filter.eq),
        to: new Date(filter.eq),
      }
}
