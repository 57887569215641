import type {Interpolation, Theme} from "@emotion/react"
import {Slot, Slottable} from "@radix-ui/react-slot"
import type {ButtonHTMLAttributes, ReactElement, ReactNode} from "react"
import {forwardRef} from "react"
import {twMerge} from "tailwind-merge"
import type {IconProps} from "../icons/types"
import {CircularProgress} from "../progress"
import {
  buttonIconSizing,
  iconButtonSizing,
  iconButtonStyles,
  loadingSpinnerColor,
  loadingSpinnerSize,
} from "./styles"
import type {ButtonSize, ButtonVariant, ResponsiveButtonSize} from "./types"
import {
  buildResponsiveSizeStyle,
  buildResponsiveSpinnerSizeStyle,
} from "./utils"

export type IconButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: ButtonVariant
  ariaLabel: string
  size?: ButtonSize | ResponsiveButtonSize
  testId?: string
  containerCss?: Interpolation<Theme>
  external?: boolean
  icon?: ReactElement | ((props: IconProps) => ReactElement)
  iconProps?: IconProps
  loading?: boolean
  asChild?: boolean
  children?: ReactNode
}

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  function IconButton(
    {
      ariaLabel,
      size = "md",
      variant = "secondaryGray",
      testId,
      containerCss,
      className,
      icon: Icon,
      iconProps = {},
      loading,
      disabled,
      asChild,
      children,
      ...props
    },
    ref,
  ) {
    const Comp = asChild === true ? Slot : "button"
    return (
      <Comp
        type="button"
        className={twMerge(iconButtonStyles[variant], className)}
        css={[buildResponsiveSizeStyle(size, iconButtonSizing), containerCss]}
        aria-label={ariaLabel}
        data-cy={testId}
        disabled={loading === true || disabled === true}
        ref={ref}
        {...props}
      >
        <Slottable>{children}</Slottable>
        {loading === true ? (
          <div css={buildResponsiveSizeStyle(size, buttonIconSizing)}>
            <CircularProgress
              css={{
                ...buildResponsiveSpinnerSizeStyle(size, loadingSpinnerSize),
                color: loadingSpinnerColor[variant],
              }}
              style={{width: undefined, height: undefined}}
            />
          </div>
        ) : Icon instanceof Function ? (
          <Icon
            {...iconProps}
            css={buildResponsiveSizeStyle(size, buttonIconSizing)}
            className={twMerge("stroke-current", iconProps.className)}
          />
        ) : (
          Icon
        )}
      </Comp>
    )
  },
)
