import {flow} from "fp-ts/function"
import * as O from "fp-ts/Option"
import * as A from "fp-ts/Array"
import * as Rec from "fp-ts/Record"

export const isNonNullable = <X>(x: X): x is NonNullable<X> =>
  x !== undefined && x !== null

export const filterNonNullable: <X>(as: Array<X>) => Array<NonNullable<X>> =
  A.filter(isNonNullable)
export const filterNonNullableRec: <X>(
  fa: Record<string, X>,
) => Record<string, NonNullable<X>> = Rec.filter(isNonNullable)

/**
 * Turns any function into one that accepts nullables and returns an Option.
 * A bit like map for nullables, but the return type is an Option.
 *
 * @remarks
 * Mainly useful where TypeScript inference would normally fail.
 *
 * @example
 * ```
 * // Failure... Type 'unknown' is not assignable to type 'number'.
 * const noInference = flow(O.fromNullable, O.map((x: number) => x + 1))
 * // type is (...a: readonly unknown[]) => O.Option<number>
 *
 * // Success!
 * const hasInference = nullableFrom((x: number) => x + 1)
 * // type is (possiblyNull: number | null | undefined) => O.Option<number>
 * ```
 *
 * @example
 * ```
 * nullableFrom(Reader.ask<Date>())
 * // (possiblyNull: Date | null | undefined) => O.Option<Date>
 * ```
 *
 * nullableFrom(identity) === O.fromNullable
 *
 * @see {@link https://gcanti.github.io/fp-ts/modules/Option.ts.html#fromnullable }
 */
export const nullableFrom: <A, B = A>(
  fn: (a: A) => B,
) => (possiblyNull: A | null | undefined) => O.Option<B> = (fn) =>
  flow(O.fromNullable, O.map(fn))

/**
 * Like `nullableFrom`, but returns a value or undefined.
 * @see {@link nullableFrom}
 */
export const whenNonNullable = <A, B>(fn: (a: A) => B) =>
  flow((a: A | undefined | null) => O.fromNullable(a), O.map(fn), O.toUndefined)
