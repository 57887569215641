import {zodResolver} from "@hookform/resolvers/zod"
import {Button} from "@hortis/ui/button"
import {DatePicker} from "@hortis/ui/date-picker"
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@hortis/ui/dialog"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@hortis/ui/form"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@hortis/ui/select"
import {A, pipe} from "@mobily/ts-belt"
import {Condition, useUpdatePlantMaterialsMutation} from "generated/graphql"
import {useState} from "react"
import {useForm} from "react-hook-form"
import {useSnackbarStore} from "src/components/snackbar-controller/snackbar-store"
import {
  onFailure,
  onSuccess as onSuccessSnack,
} from "src/notification-snack-utils"
import {createDateAsUTC} from "src/utils/convert-dates-to-iso"
import {z} from "zod"

const FormSchema = z.object({
  dateObserved: z.date({
    required_error: "Date observed is required",
  }),
  condition: z.nativeEnum(Condition, {required_error: "Condition is required"}),
})

interface CommandMenuStatusPageProps {
  selectedMaterials: ReadonlyArray<{id: string}>
  onSuccess: () => void
  open: boolean
  onOpenChange: (open: boolean) => void
}

const ObservationDialog = ({
  selectedMaterials,
  onSuccess,
  open,
  onOpenChange,
}: CommandMenuStatusPageProps) => {
  const {setSnack} = useSnackbarStore()
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {dateObserved: new Date()},
  })
  const [_, updatePlantMaterials] = useUpdatePlantMaterialsMutation()
  const [loading, setLoading] = useState<boolean>(false)

  const onSubmit = async (data: z.infer<typeof FormSchema>) => {
    const ids = pipe(
      selectedMaterials,
      A.map(({id}) => id),
    )
    if (ids.length === 0) {
      onSuccess()
      return
    }
    setLoading(true)
    const res = await updatePlantMaterials({
      ids,
      input: {
        condition: data.condition,
        lastObserved: createDateAsUTC(data.dateObserved),
      },
    })
    setLoading(false)
    if (res.data?.updatePlantMaterials.success !== true) {
      onFailure(setSnack)(new Error("Failed to update materials"))
      return
    }
    onSuccessSnack(setSnack)(
      `Successfully updated ${
        res.data.updatePlantMaterials.plantMaterials.length
      } material${
        res.data.updatePlantMaterials.plantMaterials.length > 1 ? "s" : ""
      }`,
    )
    onSuccess()
  }

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent data-cy="make-observation-dialog">
        <Form {...form}>
          <form className="space-y-8" onSubmit={form.handleSubmit(onSubmit)}>
            <div className="space-y-6">
              <DialogHeader>
                <DialogTitle>Make observation</DialogTitle>
                <DialogDescription>
                  Please enter the condition of the materials and the date they
                  were observed.
                </DialogDescription>
              </DialogHeader>
              <FormField
                control={form.control}
                name="condition"
                render={({field}) => (
                  <FormItem>
                    <FormLabel>Condition</FormLabel>
                    <Select
                      // eslint-disable-next-line react/jsx-handler-names
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger data-cy="condition-select">
                          <SelectValue placeholder="Select a condition" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem
                          value="EXCELLENT"
                          data-cy="excellent-select-option"
                        >
                          Excellent
                        </SelectItem>
                        <SelectItem value="GOOD">Good</SelectItem>
                        <SelectItem value="FAIR">Fair</SelectItem>
                        <SelectItem value="POOR">Poor</SelectItem>
                        <SelectItem value="VERY_POOR">Very poor</SelectItem>
                      </SelectContent>
                      <FormMessage />
                    </Select>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="dateObserved"
                render={({field}) => (
                  <FormItem>
                    <FormLabel>Date observed</FormLabel>
                    <DatePicker data-cy="date-observed-picker" {...field} />
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <DialogFooter>
              <DialogClose asChild>
                <Button size="lg" fullWidth>
                  Cancel
                </Button>
              </DialogClose>
              <Button
                size="lg"
                variant="primary"
                type="submit"
                loading={loading}
                fullWidth
                testId="make-observation-confirm"
              >
                Confirm
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}

export {ObservationDialog}
