import Collapse from "@mui/material/Collapse"
import {useCallback} from "react"
import type {PropsWithChildren} from "react"
import {twMerge} from "tailwind-merge"

export interface RadioButtonProps<T> {
  label: string
  selectedValue: T
  value: T
  onChange: (val: T) => void
  testId?: string
  className?: string
}

export const RadioButton = <T,>({
  label,
  value,
  selectedValue,
  onChange,
  testId,
  className,
}: RadioButtonProps<T>) => {
  const handleChange = useCallback(() => {
    onChange(selectedValue)
  }, [selectedValue, onChange])
  return (
    <label
      className={twMerge(
        "flex cursor-pointer items-center gap-1.5 text-sm font-medium",
        className,
      )}
    >
      <input
        data-cy={testId}
        className="m-0 grid h-4 w-4 flex-shrink-0 cursor-pointer appearance-none place-content-center rounded-lg border border-grey-300 outline-none ring-primary-100 transition-transform before:h-1.5 before:w-1.5 before:scale-0 before:rounded-lg before:bg-primary-600 before:duration-150 before:content-[''] checked:border-primary-600 checked:bg-primary-50 checked:before:scale-100 hover:border-primary-600 hover:bg-primary-100 focus-visible:border-primary-300 focus-visible:ring"
        type="radio"
        checked={value === selectedValue}
        onChange={handleChange}
      />
      <span
        data-cy={testId == null ? undefined : `${testId}-label`}
        className="truncate"
      >
        {label}
      </span>
    </label>
  )
}

export const CollapsableRadioButton = <T,>({
  value,
  selectedValue,
  children,
  ...props
}: PropsWithChildren<RadioButtonProps<T>>) => {
  return (
    <>
      <RadioButton value={value} selectedValue={selectedValue} {...props} />
      <Collapse in={value === selectedValue} className="mb-3 mt-1.5">
        {children}
      </Collapse>
    </>
  )
}
