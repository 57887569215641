import {Button} from "@hortis/ui/button"
import {ChevronRight} from "@hortis/ui/icons"
import {LoadingPage} from "@hortis/ui/loading"
import Alert from "@mui/material/Alert"
import {useTheme} from "@mui/material/styles"
import type {ChangeEvent, FormEventHandler, ReactElement} from "react"
import {useCallback, useEffect, useState} from "react"
import {Helmet} from "react-helmet-async"
import config from "src/config"
import {usePersistedDomain} from "src/features/organisation/persist-user-domain"
import {useHostnameWhenPresent} from "src/utils/hooks/hostname"
import {z} from "zod"
import {useSearch} from "@tanstack/react-router"
import {TextField} from "../../../components/text-field"
import {Typography} from "../../../components/typography"
import {checkPublicOrgExists} from "../fetchers"
import {AuthCard} from "./auth-card"
import {AuthPageLayout} from "./auth-page-layout"
import {OrgInfo} from "./org-info"
import {usePublicOrg} from "./use-public-org"

export const selectOrganisationSchema = z.object({
  redirect: z.enum(["root"]).optional().catch(undefined),
})

const regions = ["eu", "au", "us", "uk"]
const prodUrls = regions.map((r) => `https://api-prod-${r}.hortis.com/graphql`)
const devUrls = regions.map((r) => `https://api-dev-${r}.hortis.com/graphql`)
const apiUrls = [...prodUrls, ...devUrls]

export const SelectOrganisation = (): ReactElement => {
  const theme = useTheme()
  const [orgSubdomain, setOrgSubdomain] = useState("")
  const [err, setErr] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const hostname = useHostnameWhenPresent()
  const search = useSearch({strict: false})
  const redirect = "redirect" in search ? search.redirect : undefined

  const persistedDomain = usePersistedDomain()
  const {data: org} = usePublicOrg(
    persistedDomain?.domain,
    persistedDomain?.apiUrl,
  )

  const onOrgSubdomainChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setOrgSubdomain(e.target.value)
    },
    [],
  )

  const redirectToLogin = useCallback(
    async (options?: {
      subdomainOverride?: string
      redirectToRoot?: boolean
    }) => {
      const subdomain = options?.subdomainOverride ?? orgSubdomain
      setLoading(true)
      const org = await checkPublicOrgExists(apiUrls, subdomain)

      if (org == null) {
        setErr("Unable to find organization")
        setLoading(false)
      } else {
        const url = `https://${org.subdomain?.toLowerCase() ?? ""}.hortis${
          config.publicApiUrl.includes("local") ? "app" : ""
        }.com`
        if (options?.redirectToRoot === true) {
          location.assign(url)
        } else {
          location.assign(`${url}/auth/login`)
        }
      }
    },
    [orgSubdomain],
  )

  const onSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    async (e) => {
      e.preventDefault()
      await redirectToLogin()
    },
    [redirectToLogin],
  )

  // Handle redirecting to the root of the organisation if the user is already logged in
  const shouldRedirect = persistedDomain != null && redirect === "root"
  useEffect(() => {
    if (shouldRedirect) {
      void redirectToLogin({
        subdomainOverride: persistedDomain.domain,
        redirectToRoot: true,
      })
    }
  }, [persistedDomain, redirectToLogin, shouldRedirect])

  if (shouldRedirect) {
    return <LoadingPage />
  }

  return (
    <AuthPageLayout>
      <Helmet>
        <title>Select organization | Hortis</title>
      </Helmet>
      <AuthCard>
        <Typography variant="h5" textAlign="center">
          Log in
        </Typography>
        {err !== null && (
          <Alert
            data-cy="error-alert"
            severity="error"
            sx={{width: "100%", marginBottom: theme.spacing(3)}}
          >
            {err}
          </Alert>
        )}
        {org != null && persistedDomain != null && (
          <div className="flex w-full max-w-full flex-col gap-4 border-b border-grey-200 pb-8">
            <Typography variant="body1" color="text.secondary">
              You&apos;re already logged in to:
            </Typography>
            <a
              href={`https://${persistedDomain.domain}.${
                hostname ?? "hortis.com"
              }`}
              className="group flex flex-1 items-center justify-between gap-3"
              data-cy="persisted-org-link"
            >
              <OrgInfo org={org.publicOrganisation ?? null} horizontal />
              <ChevronRight className="mr-3 transition group-hover:translate-x-2" />
            </a>
          </div>
        )}
        <form
          onSubmit={onSubmit}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className="flex flex-col gap-4 pb-4">
            <TextField
              placeholder="subdomain"
              name="org-subdomain"
              label="Use your organization URL"
              value={orgSubdomain}
              fullWidth
              onChange={onOrgSubdomainChange}
              endAdornment={
                <Typography variant="subtitle1" color="text.secondary">
                  .hortis.com
                </Typography>
              }
              testId="org-subdomain-input"
            />
            <Button
              variant="primary"
              fullWidth
              loading={loading}
              type="submit"
              testId="org-signin-button"
            >
              Continue
            </Button>
          </div>
        </form>
      </AuthCard>
    </AuthPageLayout>
  )
}

export const Component = SelectOrganisation
