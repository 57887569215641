import {Dialog, type DialogProps} from "@radix-ui/react-dialog"
import {Command as CommandPrimitive} from "cmdk"
import {
  forwardRef,
  type ComponentPropsWithoutRef,
  type ElementRef,
  type HTMLAttributes,
  useRef,
  type MutableRefObject,
  useEffect,
} from "react"
import {twMerge} from "tailwind-merge"
import create from "zustand"
import {match} from "ts-pattern"
import {type VariantProps, cva} from "class-variance-authority"
import {Checkbox} from "../checkbox"
import {getOperatingSystem} from "../hooks/operating-system-detect"
import {DialogContent} from "../dialog"
import {CircularProgress} from "../progress"

const commandVariants = cva(
  "flex h-full flex-col overflow-hidden rounded-md [&_[cmdk-group-heading]]:py-1.5 [&_[cmdk-group-heading]]:text-grey-500 max-h-[calc(var(--radix-popover-content-available-height)_-_8px)]",
  {
    variants: {
      size: {
        md: "[&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-items]]:space-y-0.5 [&_[cmdk-group-heading]]:text-xs [&_[cmdk-list]]:h-[calc(var(--cmdk-list-height)_+_8px)]",
        lg: "[&_[cmdk-group-heading]]:px-2.5 [&_[cmdk-list]]:py-4 [&_[cmdk-list]]:px-2 [&_[cmdk-list]]:max-h-[300px] [&_[cmdk-input]]:px-4 [&_[cmdk-input]]:text-base [&_[cmdk-input]]:py-4 [&_[cmdk-group-items]]:space-y-1 [&_[cmdk-item]]:px-2.5 [&_[cmdk-item]]:py-2.5 [&_[cmdk-list]]:h-[calc(var(--cmdk-list-height)_+_32px)]",
      },
    },
    defaultVariants: {size: "md"},
  },
)

const Command = forwardRef<
  ElementRef<typeof CommandPrimitive>,
  ComponentPropsWithoutRef<typeof CommandPrimitive> &
    VariantProps<typeof commandVariants>
>(({className, size, ...props}, ref) => (
  <CommandPrimitive
    ref={ref}
    className={twMerge(commandVariants({size}), className)}
    {...props}
  />
))
// @ts-expect-error -- Reclared forwardRef does not have a displayName
Command.displayName = CommandPrimitive.displayName

interface CommandDialogProps extends DialogProps {
  className?: string
}

interface CommmandHeaderProps {
  title: string
}

const useCommandDialogInputStore = create<{
  inputRef: MutableRefObject<HTMLInputElement | null> | null
  setDialogInputRef: (ref: MutableRefObject<HTMLInputElement | null>) => void
}>((set) => ({
  inputRef: null,
  setDialogInputRef: (ref) => {
    set({inputRef: ref})
  },
}))

const CommandDialog = ({
  children,
  onOpenChange,
  ...props
}: CommandDialogProps) => {
  const {inputRef} = useCommandDialogInputStore()
  return (
    <Dialog onOpenChange={onOpenChange} {...props}>
      <DialogContent
        className="z-50 w-full max-w-[calc(100%_-_32px)] overscroll-y-auto p-0 shadow-lg sm:p-0 md:max-w-[640px]"
        onKeyDown={(e) => {
          if (e.key === "Escape") {
            onOpenChange?.(false)
            e.stopPropagation()
          }
        }}
        onFocus={(e) => {
          if (e.target.role === "dialog") {
            e.preventDefault()
            inputRef?.current?.focus()
          }
        }}
      >
        {children}
      </DialogContent>
    </Dialog>
  )
}
const CommandHeader = ({title}: CommmandHeaderProps) => {
  return (
    <div className="text-grey-500 px-[18px] pb-2 pt-4 text-sm font-normal">
      <span className="border-grey-100 bg-grey-50 rounded-md border px-1 py-[2px] ">
        {title}
      </span>
    </div>
  )
}
CommandHeader.displayName = "CommandHeader"

const CommandCheckboxItem = forwardRef<
  ElementRef<typeof CommandPrimitive.Item>,
  ComponentPropsWithoutRef<typeof Checkbox> & {
    onSelect: (val: string) => void
    isLoading?: boolean
  }
>(
  (
    {
      className,
      checked,
      indeterminate,
      onChange,
      onSelect,
      children,
      isLoading = false,
      disabled,
      ...props
    },
    ref,
  ) => (
    <CommandPrimitive.Item
      ref={ref}
      onSelect={onSelect}
      disabled={disabled}
      data-cy="command-checkbox-item"
      className={twMerge(
        "text-grey-900 hover:bg-grey-100 data-[selected=true]:bg-grey-100 flex cursor-pointer items-center gap-2 rounded-md px-3 py-2.5 font-medium transition-all duration-100",
        isLoading && "text-opacity-50",
        className,
      )}
    >
      <Checkbox
        {...props}
        disabled={disabled ?? isLoading}
        checked={checked}
        indeterminate={indeterminate}
        onChange={onChange}
        tabIndex={-1}
      />
      {children}
      {isLoading && <CircularProgress size={16} className="ml-auto" />}
    </CommandPrimitive.Item>
  ),
)
// @ts-expect-error -- Reclared forwardRef does not have a displayName
CommandCheckboxItem.displayName = "CommandCheckboxItem"

const CommandInput = forwardRef<
  ElementRef<typeof CommandPrimitive.Input>,
  ComponentPropsWithoutRef<typeof CommandPrimitive.Input>
>(({className, ...props}, ref) => {
  const storedRef = useRef<HTMLInputElement | null>(null)
  const {setDialogInputRef} = useCommandDialogInputStore()
  useEffect(() => {
    setDialogInputRef(storedRef)
  }, [setDialogInputRef])
  const setRef = (newRef: HTMLInputElement) => {
    storedRef.current = newRef
    if (typeof ref === "function") {
      ref(newRef)
    }
  }
  return (
    <CommandPrimitive.Input
      ref={setRef}
      className={twMerge(
        "border-grey-200 ring-primary-300/50 placeholder:text-grey-500 flex rounded-none rounded-t-md border-b bg-transparent px-3 py-2.5 text-sm outline-none disabled:cursor-not-allowed disabled:opacity-50",
        className,
      )}
      {...props}
    />
  )
})
// @ts-expect-error -- Reclared forwardRef does not have a displayName
CommandInput.displayName = CommandPrimitive.Input.displayName

const CommandList = forwardRef<
  ElementRef<typeof CommandPrimitive.List>,
  ComponentPropsWithoutRef<typeof CommandPrimitive.List>
>(({className, ...props}, ref) => (
  <CommandPrimitive.List
    ref={ref}
    className={twMerge(
      "overflow-y-scroll",
      "no-scrollbar text-grey-900 h-[calc(var(--cmdk-list-height)_+_32px)] px-1 py-1 text-left text-sm font-medium transition-all",
      className,
    )}
    {...props}
  />
))
// @ts-expect-error -- Reclared forwardRef does not have a displayName
CommandList.displayName = CommandPrimitive.List.displayName

const CommandEmpty = forwardRef<
  ElementRef<typeof CommandPrimitive.Empty>,
  ComponentPropsWithoutRef<typeof CommandPrimitive.Empty>
>((props, ref) => (
  <CommandPrimitive.Empty
    ref={ref}
    className="text-grey-900 flex flex-col px-3 py-3 text-sm font-medium"
    {...props}
  />
))
// @ts-expect-error -- Reclared forwardRef does not have a displayName
CommandEmpty.displayName = CommandPrimitive.Empty.displayName

const CommandGroup = forwardRef<
  ElementRef<typeof CommandPrimitive.Group>,
  ComponentPropsWithoutRef<typeof CommandPrimitive.Group>
>(({className, ...props}, ref) => (
  <CommandPrimitive.Group ref={ref} className={twMerge(className)} {...props} />
))
// @ts-expect-error -- Reclared forwardRef does not have a displayName
CommandGroup.displayName = CommandPrimitive.Group.displayName

const CommandLoading = forwardRef<
  ElementRef<typeof CommandPrimitive.Loading>,
  ComponentPropsWithoutRef<typeof CommandPrimitive.Loading>
>(({className, ...props}, ref) => (
  <CommandPrimitive.Loading
    ref={ref}
    className={twMerge("flex items-center justify-center", className)}
    {...props}
  >
    <CircularProgress size={20} />
  </CommandPrimitive.Loading>
))
// @ts-expect-error -- Reclared forwardRef does not have a displayName
CommandLoading.displayName = CommandPrimitive.Loading.displayName

const CommandSeparator = forwardRef<
  ElementRef<typeof CommandPrimitive.Separator>,
  ComponentPropsWithoutRef<typeof CommandPrimitive.Separator>
>(({className, ...props}, ref) => (
  <CommandPrimitive.Separator
    ref={ref}
    className={twMerge("bg-border -mx-1 h-px", className)}
    {...props}
  />
)) // @ts-expect-error -- Reclared forwardRef does not have a displayName
CommandSeparator.displayName = CommandPrimitive.Separator.displayName

const CommandShortcut = ({
  className,
  ...props
}: HTMLAttributes<HTMLSpanElement>) => {
  return (
    <span
      className={twMerge(
        "border-grey-100 bg-grey-50 text-grey-500 ml-auto hidden rounded-[4px] border px-1 py-0.5 text-sm font-normal tracking-widest md:flex",
        className,
      )}
      {...props}
    />
  )
}
CommandShortcut.displayName = "CommandShortcut"

type CommandMenuItem = {
  value: string
  label: string
  icon?: React.ReactNode
  onSelect?: () => void
  shortcut?: Shortcut
}

type CommandMenuGroup<T> = {
  heading: string
  value: T
  items: ReadonlyArray<CommandMenuItem>
}

export type Shortcut = {key: string; modifier?: "meta" | "shift"} | string

const createShortcutLabel = (shortcut: Shortcut) =>
  typeof shortcut === "string"
    ? shortcut
    : `${match(shortcut.modifier)
        .with("shift", () => "⇧")
        .with("meta", () => (getOperatingSystem() === "mac" ? "⌘" : "Ctrl+"))
        .with(undefined, () => "")
        .exhaustive()}${shortcut.key.toUpperCase()}`

const CommandItem = forwardRef<
  ElementRef<typeof CommandPrimitive.Item>,
  ComponentPropsWithoutRef<typeof CommandPrimitive.Item> & {
    loading?: boolean
    shortcut?: Shortcut
  }
>(
  (
    {
      className,
      value,
      onSelect,
      children,
      loading,
      disabled,
      shortcut,
      ...props
    },
    ref,
  ) => {
    // eslint-disable-next-line sonarjs/cognitive-complexity
    useEffect(() => {
      const keyDownHandler = (e: KeyboardEvent) => {
        if (shortcut == null || onSelect == null || disabled === true) {
          return
        }
        if (
          typeof shortcut === "string" &&
          e.key.toLowerCase() === shortcut.toLowerCase() &&
          !e.metaKey &&
          !e.shiftKey
        ) {
          e.preventDefault()
          onSelect(value ?? "")
          return
        }
        if (
          typeof shortcut === "object" &&
          e.key.toLowerCase() === shortcut.key.toLowerCase()
        ) {
          if (shortcut.modifier === "meta" && !e.metaKey) {
            return
          }
          if (shortcut.modifier === "shift" && !e.shiftKey) {
            return
          }
          if (shortcut.modifier == null && (e.metaKey || e.shiftKey)) {
            return
          }
          e.preventDefault()
          onSelect(value ?? "")
        }
      }
      document.addEventListener("keydown", keyDownHandler)
      return () => {
        document.removeEventListener("keydown", keyDownHandler)
      }
    }, [shortcut, props, disabled, onSelect, value])

    return (
      <CommandPrimitive.Item
        ref={ref}
        className={twMerge(
          "text-grey-900 hover:bg-grey-100 data-[selected='true']:bg-grey-100 flex cursor-pointer items-center gap-2 rounded-md px-2 py-2 font-medium data-[disabled='true']:text-opacity-50",
          className,
        )}
        disabled={disabled}
        onSelect={onSelect}
        value={value}
        data-cy={`${value ?? ""}-menu-item`}
        {...props}
      >
        {children}
        <div className="ml-auto flex items-center gap-3">
          {loading === true && <CircularProgress size={16} />}
          {shortcut != null && (
            <CommandShortcut>{createShortcutLabel(shortcut)}</CommandShortcut>
          )}
        </div>
      </CommandPrimitive.Item>
    )
  },
)
// @ts-expect-error -- Reclared forwardRef does not have a displayName
CommandItem.displayName = CommandPrimitive.Item.displayName

export {
  Command,
  CommandCheckboxItem,
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandHeader,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
  CommandShortcut,
  CommandLoading,
  type CommandMenuGroup,
}
