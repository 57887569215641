// it's mui-x, but it's fine because:
// (from https://mui.com/x/react-date-pickers/migration-lab/#license)
// Most of our components remains MIT and are accessible for free in @mui/x-date-pickers.
// The range-picker components: DateRangePicker, DateRangePickerDay, DesktopDateRangePicker, MobileDateRangePicker and StaticDateRangePicker were marked as "intended for MUI X Pro" in our documentation and are now part of MUI X Pro.
// If you are using one of these components, you will have to take a Pro license in order to migrate to @mui/x-date-pickers-pro (see the Pricing page for more information).
import type {TextFieldProps as MuiTextFieldProps} from "@mui/material/TextField"
import {LocalizationProvider} from "@mui/x-date-pickers"
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns"
import {
  DatePicker,
  type DatePickerProps as PrimitiveDatePickerProps,
} from "@mui/x-date-pickers/DatePicker"
import type {Locale} from "date-fns"
import deLocale from "date-fns/locale/de/index"
import enAULocale from "date-fns/locale/en-AU/index"
import enGBLocale from "date-fns/locale/en-GB/index"
import enNZLocale from "date-fns/locale/en-NZ/index"
import enUSLocale from "date-fns/locale/en-US/index"
import frLocale from "date-fns/locale/fr/index"
import norwayLocale from "date-fns/locale/nb/index"
import * as O from "fp-ts/Option"
import * as RA from "fp-ts/ReadonlyArray"
import {flow} from "fp-ts/function"
import {useCallback} from "react"
import {useController, useFormContext} from "react-hook-form"
import {
  Calendar,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
} from "@hortis/ui/icons"
import {nullableFrom} from "../../utils/nullable"
import {useLanguages} from "../../utils/hooks/language"
import {TextField} from "../text-field/text-field"

const slashMask = "__/__/____"
const dotMask = "__.__.____"

// https://stackoverflow.com/questions/13268930/where-can-i-find-a-list-of-language-region-codes
/**
 * Why do I have to provide a mask?
 * Otherwise MUI will reject the locale with:
 * `The mask "__/__/____" you passed is not valid for the format used P. Falling down to uncontrolled not-masked input`
 * It's unclear if there's a way for this to work automatically, but for now this is copying https://mui.com/x/react-date-pickers/date-picker/#localization
 */
const localeConfigMap: Record<string, {locale: Locale; mask: string}> = {
  "en-US": {locale: enUSLocale, mask: slashMask},
  "en-GB": {locale: enGBLocale, mask: slashMask},
  "en-AU": {locale: enAULocale, mask: slashMask},
  "en-NZ": {locale: enNZLocale, mask: slashMask},
  "de-DE": {locale: deLocale, mask: dotMask},
  "fr-FR": {locale: frLocale, mask: slashMask},
  "no-NO": {locale: norwayLocale, mask: dotMask},
}

const findLocaleConfigFromLanguages = flow(
  nullableFrom(
    RA.findFirstMap((language: string) =>
      O.fromNullable(localeConfigMap[language]),
    ),
  ),
  O.flatten,
  O.toUndefined,
)

export const useLocaleConfig = flow(useLanguages, findLocaleConfigFromLanguages)

type DatePickerProps<TInputDate, TDate> = {
  name: string
  label: string
  required?: boolean
  initialValue?: Date | string | null
  className?: string
  testId?: string
  onDateChange?: (date: Date | null, keyboardInputValue?: string) => void
} & Partial<PrimitiveDatePickerProps<TInputDate, TDate>>

// best we can do because of limitations of react hook form
// and generic string 'literals'
type FormValues = {[Key in string]?: string | Date | null}

/**
 * Makes a best effort attempt to format the date in the most-preferred locale of the user.
 * Each format has to be explicitly enunciated, see `localeConfigMap`!
 */
export const BasicDatePicker = <TInputDate, TDate>({
  name,
  label,
  required,
  initialValue,
  onDateChange,
  className,
  testId,
  ...rest
}: DatePickerProps<TInputDate, TDate>) => {
  const {trigger} = useFormContext<FormValues>()
  const {
    field: {value, onChange: fieldOnChange},
    fieldState: {error},
  } = useController<FormValues>({
    name,
    defaultValue: initialValue,
  })

  const onChange = useCallback(
    (date: TDate | null, keyboardInputValue?: string) => {
      onDateChange?.(date as Date | null, keyboardInputValue)
      fieldOnChange(date, keyboardInputValue)
      if (error != null) {
        void trigger(name)
      }
    },
    [fieldOnChange, onDateChange, trigger, error, name],
  )

  const cachedRenderInput = useCallback(
    (params: Omit<MuiTextFieldProps, "name" | "required">) => (
      // @ts-expect-error Hack to put the (outlined input) botsoft-frontend text field in here instead of @mui/material/TextField
      // inputProps is concatinated with rest as it contains the data-cy attribute from withTestId
      <TextField
        {...params}
        className={className}
        sx={{
          "& .MuiOutlinedInput-input": {
            paddingRight: "4px",
          },
        }}
        inputProps={{...params.inputProps, "data-cy": testId}}
        name={name}
        required={required}
        error={Boolean(error)}
        errorMessage={error ? error.message : undefined}
      />
    ),
    [name, required, error, className, testId],
  )

  const localeConfig = useLocaleConfig()

  // from https://mui.com/x/react-date-pickers/date-picker/#localization
  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      locale={localeConfig?.locale}
    >
      <DatePicker
        {...rest}
        mask={localeConfig?.mask}
        label={label}
        value={(value ?? null) as TInputDate | null}
        onChange={onChange}
        renderInput={cachedRenderInput}
        desktopModeMediaQuery="@media (min-width: 0px)"
        PopperProps={{disablePortal: true}}
        components={{
          OpenPickerIcon: Calendar,
          LeftArrowIcon: ChevronLeft,
          RightArrowIcon: ChevronRight,
          SwitchViewIcon: ChevronDown,
        }}
      />
    </LocalizationProvider>
  )
}
