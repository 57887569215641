import type {ReactNode} from "react"
import {Popup as PopupGL} from "react-map-gl"
import type {PopupProps as PopupGLProps} from "react-map-gl"

export type PopupProps = {
  longitude: number
  latitude: number
  onClose?: () => void
  children: ReactNode
} & PopupGLProps

export const Popup = ({
  longitude,
  latitude,
  onClose,
  children,
  ...props
}: PopupProps) => {
  return (
    <PopupGL
      className="heyo"
      longitude={longitude}
      latitude={latitude}
      closeOnClick={false}
      onClose={onClose}
      closeButton={false}
      {...props}
    >
      {children}
    </PopupGL>
  )
}
