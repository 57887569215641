import {pipe} from "@mobily/ts-belt"
import * as RNEA from "fp-ts/ReadonlyNonEmptyArray"
import type {MaterialType} from "../../../../../../generated/graphql"
import {FormSelectField} from "../../../../../components/select-field/select-field"
import {FormTextField as TextField} from "../../../../../components/text-field/text-field"
import * as OE from "../../../../../utils/option-either"
import {showErrorMessage} from "../../create-accession/utils"
import type {useMaterialTypes} from "../../create-accession/utils"

const renderOptions = RNEA.map(
  ({code: _code, id, name}: MaterialType): JSX.Element => (
    <option key={id} value={id}>
      {name}
    </option>
  ),
)

const materialTypePlaceholder = (
  <TextField
    label="Material received"
    name="material-type-placeholder"
    disabled
    placeholder="Loading material types"
  />
)

interface MaterialTypesSelectProps {
  materialTypes: ReturnType<typeof useMaterialTypes>
}

export const MaterialTypesSelect = ({
  materialTypes,
}: MaterialTypesSelectProps) => {
  return pipe(
    materialTypes,
    OE.match(
      () => materialTypePlaceholder,
      showErrorMessage,
      (
        materialTypes: RNEA.ReadonlyNonEmptyArray<MaterialType>,
      ): JSX.Element => (
        <FormSelectField
          name="materialTypeId"
          id="material-type"
          label="Material received"
          testId="material-type"
          required
        >
          {renderOptions(materialTypes)}
        </FormSelectField>
      ),
    ),
  )
}
