import {ProvenanceCategoryCode} from "generated/graphql"
import {
  ContentsTable,
  ContentsTableItem,
} from "src/features/records/components/table-of-contents"
import {type RecordSectionId} from "src/features/records/components/table-of-contents/use-records-table-of-contents"

const sectionAnchors: Array<RecordSectionId> = [
  "details",
  "wild-collection",
  "taxon",
  "identification",
  "materials",
  "activity",
  "tags",
  "notes",
]
const AccessionContentsTable = ({
  provenanceCategoryCode,
  identificationFieldsEnabled,
}: {
  provenanceCategoryCode: ProvenanceCategoryCode
  identificationFieldsEnabled: boolean
}) => {
  return (
    <ContentsTable sectionAnchors={sectionAnchors}>
      <ContentsTableItem href="details">Details</ContentsTableItem>
      <ContentsTableItem href="tags">Tags</ContentsTableItem>
      <ContentsTableItem href="notes">Notes</ContentsTableItem>
      {provenanceCategoryCode !== ProvenanceCategoryCode.G &&
        provenanceCategoryCode !== ProvenanceCategoryCode.U && (
          <ContentsTableItem href="wild-collection">
            Wild collection
          </ContentsTableItem>
        )}

      <ContentsTableItem href="taxon">Taxon</ContentsTableItem>

      {identificationFieldsEnabled && (
        <ContentsTableItem href="identification">
          Identification
        </ContentsTableItem>
      )}

      <ContentsTableItem href="materials">Materials</ContentsTableItem>
      <ContentsTableItem href="activity">Activity</ContentsTableItem>
    </ContentsTable>
  )
}

export {AccessionContentsTable}
