import {Dialog, DialogContent} from "@hortis/ui/dialog"
import {useMediaQuery} from "@hortis/ui/hooks/media-query"
import {Popover, PopoverAnchor, PopoverContent} from "@hortis/ui/popover"
import type {PropsWithChildren, RefObject} from "react"
import {twMerge} from "tailwind-merge"
import type {FilterPopoverProps} from "./filter-popover"

export type FilterModalProps = PropsWithChildren<
  Omit<FilterPopoverProps, "anchorEl"> & {
    anchorEl?: RefObject<HTMLElement | null>
    className?: string
  }
>

export const FilterModal = ({
  open,
  onClose,
  anchorEl,
  children,
  className,
}: FilterModalProps) => {
  const isDesktop = useMediaQuery("sm")
  const handleClose = () => {
    if (anchorEl != null) {
      anchorEl.current?.focus()
    }
    onClose()
  }
  return anchorEl == null || !isDesktop ? (
    <Dialog open={open} onOpenChange={onClose} modal>
      <DialogContent className={twMerge("z-[10000]", className)}>
        {children}
      </DialogContent>
    </Dialog>
  ) : (
    <Popover open={open}>
      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment */}
      <PopoverAnchor virtualRef={anchorEl as any} />
      <PopoverContent
        className={twMerge("min-w-72 max-w-80 px-6 py-4", className)}
        onPointerDownOutside={handleClose}
        onEscapeKeyDown={handleClose}
        onInteractOutside={(e) => {
          if (e.type === "dismissableLayer.focusOutside") {
            return
          }
          handleClose()
        }}
      >
        {children}
      </PopoverContent>
    </Popover>
  )
}
