import * as A from "fp-ts/Array"
import type {UseQueryExecute} from "urql"
import type {CreateMaterialMutation} from "../../../../../../generated/graphql"
import type {SetSnack} from "../../../../../components/snackbar-controller/snackbar-store"

export const onUpdateAccessionSuccess =
  (
    setSnack: SetSnack,
    text: string,
    callback: null | (() => Promise<void> | void),
  ) =>
  () => {
    setSnack({
      type: "action",
      data: {
        text,
      },
    })

    if (callback != null) {
      void callback()
    }
  }

export const onUpdateAccessionFailure =
  (setSnack: SetSnack) => (error: Error) => {
    setSnack({
      type: "alert",
      data: {
        severity: "error",
        text: error.message,
      },
    })
  }

export const onCreateMaterialSuccess =
  (
    setSnack: SetSnack,
    text: string,
    requestClose: () => void,
    refetch: UseQueryExecute,
  ) =>
  (_: CreateMaterialMutation) => {
    setSnack({
      type: "action",
      data: {
        text,
      },
    })

    refetch()

    requestClose()
  }

export const countFields = A.reduce(0, (prev, curr) =>
  curr == null ? prev : prev + 1,
)
