import {zodResolver} from "@hookform/resolvers/zod"
import * as A from "fp-ts/Array"
import {RedListStatus} from "generated/graphql"
import {useMemo} from "react"
import {FormProvider} from "react-hook-form"
import {
  OrgRedListStatusAutocomplete,
  SiteRedListStatusAutocomplete,
} from "src/components/autocomplete/red-list-status-autocomplete"
import {CollapsableRadioButton, RadioGroup} from "src/components/radio"
import {FilterTitle} from "src/features/filters/components/modal/filter-title"
import type {FilterFormProps} from "src/features/filters/plant-materials/modals/types"
import {match} from "ts-pattern"
import {z} from "zod"
import type {FilterModalProps} from "../components/modal/filter-modal"
import {FilterModal} from "../components/modal/filter-modal"
import {FilterModalButtons} from "../components/modal/modal-buttons"
import {FilterModalError} from "../components/modal/modal-error"
import {useFilterForm} from "../components/modal/use-filter-form"
import {determineInitialOperation} from "../determine-initial-operation"

const schema = z.object({
  is: z.array(z.nativeEnum(RedListStatus)).nullish(),
  isNot: z.array(z.nativeEnum(RedListStatus)).nullish(),
})

export type LocalRedListStatusFilter = z.infer<typeof schema>

interface RedListStatusFilterFormProps {
  addModifyFilter: (changes: {redListStatus: LocalRedListStatusFilter}) => void
  deleteFilter: (() => void) | undefined
  initialValue?: LocalRedListStatusFilter
  filterBy?: "org" | "site"
}

export const RedListStatusFilterForm = ({
  initialValue = {},
  addModifyFilter,
  deleteFilter,
  onClose,
  filterBy = "org",
}: Omit<FilterFormProps, "dispatchFilters" | "initialValue"> &
  RedListStatusFilterFormProps) => {
  const {error, setError, operation, handleRadioChange, formMethods} =
    useFilterForm({
      resolver: zodResolver(schema),
      defaultValues: initialValue,
      initialOperation: determineInitialOperation<
        keyof LocalRedListStatusFilter
      >(initialValue, "is"),
    })

  const onSubmit = useMemo(
    () =>
      formMethods.handleSubmit((values) => {
        match(operation)
          .with("is", () => {
            if (values.is != null && A.isNonEmpty(values.is)) {
              addModifyFilter({redListStatus: {is: values.is}})
              onClose()
            } else {
              setError("Select a tag to continue")
            }
          })
          .with("isNot", () => {
            if (values.isNot != null && A.isNonEmpty(values.isNot)) {
              addModifyFilter({redListStatus: {isNot: values.isNot}})
              onClose()
            } else {
              setError("Select a tag to continue")
            }
          })
          .run()
      }),
    [formMethods, operation, onClose, addModifyFilter, setError],
  )

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={onSubmit}>
        <div className="mb-4 flex flex-col gap-4">
          <FilterTitle title="IUCN Red List" deleteFilter={deleteFilter} />
          <FilterModalError error={error} />
          <RadioGroup
            value={operation}
            onChange={handleRadioChange}
            label="Operation"
          >
            {(props) => (
              <>
                <CollapsableRadioButton
                  selectedValue="is"
                  testId="red-list-status-is-radio"
                  label="Is"
                  {...props}
                >
                  {filterBy === "org" ? (
                    <OrgRedListStatusAutocomplete
                      name="is"
                      testId="red-list-status-is-input"
                    />
                  ) : (
                    <SiteRedListStatusAutocomplete
                      name="is"
                      testId="red-list-status-is-input"
                    />
                  )}
                </CollapsableRadioButton>
                <CollapsableRadioButton
                  selectedValue="isNot"
                  testId="red-list-status-is-not-radio"
                  label="Is not"
                  {...props}
                >
                  {filterBy === "org" ? (
                    <OrgRedListStatusAutocomplete
                      name="isNot"
                      testId="red-list-status-is-not-input"
                    />
                  ) : (
                    <SiteRedListStatusAutocomplete
                      name="isNot"
                      testId="red-list-status-is-not-input"
                    />
                  )}
                </CollapsableRadioButton>
              </>
            )}
          </RadioGroup>
        </div>
        <FilterModalButtons onCancel={onClose} testIdPrefix="red-list-status" />
      </form>
    </FormProvider>
  )
}

// Seperated from form component to make sure popover unmount causes state reset
export const RedListStatusFilterModal = ({
  open,
  onClose,
  anchorEl,
  ...props
}: Omit<FilterFormProps, "initialValue" | "dispatchFilters"> &
  FilterModalProps &
  RedListStatusFilterFormProps) => {
  return (
    <FilterModal anchorEl={anchorEl} open={open} onClose={onClose}>
      <RedListStatusFilterForm onClose={onClose} {...props} />
    </FilterModal>
  )
}
