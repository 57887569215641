import {flow, constant, identity} from "fp-ts/function"
import type {CSSProperties, ReactElement} from "react"
import {memo} from "react"
import type {ProvenanceCategoryCode} from "../../../generated/graphql"
import {useProvenanceCategories} from "../../features/create-accession/components/create-accession/utils"
import * as OE from "../../utils/option-either"
import {Tooltip} from "../tooltip"
import {IconCircularContainer} from "./icon-circular-container"

interface ProvenanceBadgeProps {
  provenance: ProvenanceCategoryCode
  provenanceName: string
  withoutCircle?: boolean
  style?: CSSProperties
}

const provenances = {
  W: (
    <svg width="20" height="14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.9 10.68 2.58 1.15a.63.63 0 0 0-.3-.38.75.75 0 0 0-.44-.13.9.9 0 0 0-.56.19.66.66 0 0 0-.24.53.84.84 0 0 0 .05.29l3.92 10.99c.06.18.17.32.33.43.16.09.33.13.5.13.18 0 .35-.04.5-.13.17-.1.28-.25.34-.43l3.32-9.3 3.29 9.3c.06.18.17.32.33.43.16.09.33.13.5.13a1 1 0 0 0 .52-.13c.16-.1.27-.25.34-.43l3.9-11a.99.99 0 0 0 .05-.3.6.6 0 0 0-.24-.5.77.77 0 0 0-.53-.2c-.16 0-.3.04-.43.13a.65.65 0 0 0-.28.38l-3.34 9.56-3.3-9.44a.88.88 0 0 0-.32-.44.79.79 0 0 0-.94 0 .79.79 0 0 0-.32.42L5.9 10.68Z"
        fill="#345855"
      />
    </svg>
  ),
  Z: (
    <svg width="10" height="14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M.47 12.9c.15.13.35.2.59.2H9.1c.23 0 .42-.06.57-.19a.7.7 0 0 0 .2-.54c0-.21-.06-.4-.2-.52a.83.83 0 0 0-.57-.2H2.39l7.1-10.11c.14-.2.22-.4.22-.6a.7.7 0 0 0-.23-.52.78.78 0 0 0-.57-.22H1.06a.83.83 0 0 0-.57.2.7.7 0 0 0-.21.54c0 .21.06.4.2.52.15.13.35.2.58.2h6.5L.47 11.75c-.14.2-.21.4-.21.61 0 .2.07.38.2.52Z"
        fill="#345855"
      />
    </svg>
  ),
  G: (
    <svg width="12" height="15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.22 11.18a5.3 5.3 0 0 0 2.14 2.28c.93.52 2.04.78 3.3.78.7 0 1.39-.06 2.08-.18a8.16 8.16 0 0 0 1.76-.49.96.96 0 0 0 .54-.38c.1-.17.16-.4.16-.7V8a.68.68 0 0 0-.71-.71H7.12a.8.8 0 0 0-.55.18.6.6 0 0 0-.22.49c0 .2.07.36.22.49a.8.8 0 0 0 .55.19h2.53v3.68c-.89.31-1.87.47-2.94.47-1.48 0-2.6-.44-3.35-1.3-.75-.87-1.13-2.15-1.13-3.85 0-1.67.38-2.93 1.11-3.8.75-.88 1.82-1.32 3.22-1.32a4.94 4.94 0 0 1 3.38 1.25c.2.15.39.24.56.24.18 0 .33-.08.44-.22a.85.85 0 0 0 .17-.51 1 1 0 0 0-.34-.7c-.5-.5-1.12-.87-1.85-1.12a6.85 6.85 0 0 0-2.38-.4 6.2 6.2 0 0 0-3.21.8 5.21 5.21 0 0 0-2.11 2.28 7.82 7.82 0 0 0-.73 3.5c0 1.36.24 2.54.73 3.53Z"
        fill="#345855"
      />
    </svg>
  ),
  U: (
    <svg width="12" height="14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 13.14c-1.65 0-2.9-.43-3.76-1.3C1.4 10.99.96 9.72.96 8.05V.97c0-.23.07-.41.2-.54A.7.7 0 0 1 1.7.22c.22 0 .4.07.52.22.14.13.2.3.2.54v7.18c0 1.23.3 2.16.9 2.79.61.62 1.5.93 2.68.93 1.18 0 2.07-.3 2.67-.93.6-.63.9-1.56.9-2.8V.99c0-.23.06-.41.2-.54a.73.73 0 0 1 .54-.22.72.72 0 0 1 .74.76v7.05c0 1.67-.44 2.94-1.3 3.82-.85.86-2.1 1.3-3.75 1.3Z"
        fill="#345855"
      />
    </svg>
  ),
}

const staticStyle: CSSProperties = {display: "flex", alignItems: "center"}

export const QualifiedProvenanceBadge = memo(function QualifiedProvenanceBadge({
  provenance,
  provenanceName,
  withoutCircle = false,
  style,
}: ProvenanceBadgeProps): ReactElement {
  const icon = provenances[provenance]

  return (
    <Tooltip title={provenanceName} placement="top" describeChild tabIndex={0}>
      <div
        style={style === undefined ? staticStyle : {...staticStyle, ...style}}
      >
        {withoutCircle ? (
          icon
        ) : (
          <IconCircularContainer backgroundColor="transparent">
            {icon}
          </IconCircularContainer>
        )}
      </div>
    </Tooltip>
  )
})

type IdentifyMatchingProvenanceCategory = () => (
  category: ProvenanceCategoryCode,
) => string

const fallback: IdentifyMatchingProvenanceCategory = constant(identity)

const useMatchingProvenanceCategory: IdentifyMatchingProvenanceCategory = flow(
  useProvenanceCategories,
  OE.match(
    fallback,
    fallback,
    (provenanceCategories) => (code) =>
      provenanceCategories.find((category) => category.codeId === code)?.name ??
      code,
  ),
)

/**
 * Fetches the provenance name as a convenience - do not use in a long list!
 * Use QualifiedProvenanceBadge instead and grab the provenance name ahead of time in a larger gql query
 */
export const ProvenanceBadge = memo(function ProvenanceBadge(
  args: Omit<ProvenanceBadgeProps, "provenanceName">,
): ReactElement {
  const {provenance} = args
  const provenanceName = useMatchingProvenanceCategory()(provenance)
  return <QualifiedProvenanceBadge {...args} provenanceName={provenanceName} />
})
