import type {NullableUserFilter} from "generated/graphql"
import type {LocalUserFilter} from "./user-filter-modal"

export const transformUserFilter = (
  filter: LocalUserFilter | undefined,
): NullableUserFilter | undefined =>
  filter == null
    ? undefined
    : {
        id: {
          in: filter.is?.map(({id}) => id),
          nin: filter.isNot?.map(({id}) => id),
        },
      }
