import {type MouseEventHandler, useCallback} from "react"
import type {ButtonProps} from "@hortis/ui/button"
import {Button, IconButton} from "@hortis/ui/button"
import {Dialog} from "src/components/dialog-mui"
import {
  FeaturedIcon,
  type FeaturedIconProps,
} from "src/components/featured-icon/featured-icon"
import {XClose} from "@hortis/ui/icons"

export type ModalProps = {
  onClose: () => void
  open: boolean
  body: string
  title: string
  primaryButtonProps?: ButtonProps
  secondaryButtonProps?: ButtonProps
  testId?: string
  featuredIconProps?: FeaturedIconProps
  showCloseButton?: boolean
}

export const Modal = ({
  onClose,
  body,
  primaryButtonProps,
  secondaryButtonProps,
  title,
  testId,
  featuredIconProps,
  showCloseButton,
  open,
}: ModalProps) => {
  const testIdPrefix = testId == null ? "" : `${testId}-`
  const handleOnClose = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      e.stopPropagation()
      onClose()
    },
    [onClose],
  )

  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      sx={{"& .MuiDialog-paper": {maxWidth: "400px"}}}
      PaperProps={{className: "!shadow-xl !rounded-xl"}}
      TransitionProps={{unmountOnExit: true}}
    >
      <div className="flex flex-col gap-8 " data-cy={testId}>
        <div className="flex flex-col gap-5">
          {featuredIconProps != null && <FeaturedIcon {...featuredIconProps} />}
          {showCloseButton != null && (
            <IconButton
              icon={XClose}
              ariaLabel="Close"
              onClick={handleOnClose}
              variant="tertiaryGray"
              className="absolute right-4 top-4"
              size={"md"}
            />
          )}
          <div className="flex flex-col gap-2">
            <div
              className="text-lg font-medium text-grey-900"
              data-cy={`${testIdPrefix}title`}
            >
              {title}
            </div>
            <p
              className="text-sm text-grey-500"
              data-cy={`${testIdPrefix}body`}
            >
              {body}
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-3 sm:flex-row">
          {secondaryButtonProps && (
            <Button
              fullWidth
              size="lg"
              onClick={handleOnClose}
              testId={`${testIdPrefix}secondary-button`}
              {...secondaryButtonProps}
            />
          )}
          {primaryButtonProps && (
            <Button
              fullWidth
              size="lg"
              variant="primary"
              testId={`${testIdPrefix}primary-button`}
              {...primaryButtonProps}
            />
          )}
        </div>
      </div>
    </Dialog>
  )
}
