import {twMerge} from "tailwind-merge"
import {colors} from "src/colors"
import {FilterLines} from "@hortis/ui/icons"

interface FilterButtonContentProps {
  count: number
}

export const FilterButtonContent = ({count}: FilterButtonContentProps) => (
  <div className="relative p-[1px]">
    <FilterLines color={colors.grey[700]} className="h-5 w-5" />
    <div
      className={twMerge(
        "absolute right-[-4px] top-[-4px] z-10 h-1.5 w-1.5 scale-0 rounded-lg bg-primary-400 transition-transform",
        "before:absolute before:left-0 before:top-0 before:z-0 before:h-1.5 before:w-1.5 before:animate-pulse before:rounded-lg before:ring-[3px] before:ring-primary-200/50",
        count > 0 && "scale-100",
      )}
    />
  </div>
)
