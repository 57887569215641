import {PhotoPlaceholder} from "@hortis/ui/photo-placeholder"
import {TwicImg} from "@twicpics/components/react"
import {memo} from "react"
import {useGetMaterial} from "src/features/records/material/fetchers"
import {useThumbnailPhoto} from "src/utils/photos"
import {createMaterialNumber} from "../../../features/collection/components/plant-materials/material-number"
import type {PopupProps} from "../popup"
import {Popup} from "../popup"

export interface MaterialPopupProps
  extends Omit<PopupProps, "children" | "style"> {
  scientificName: string
  qualifier: string
  accessionNumber: string
}

export const MaterialPopup = memo(function MemoisedMaterialPopup({
  longitude,
  latitude,
  scientificName,
  accessionNumber,
  qualifier,
}: MaterialPopupProps) {
  const [{data}] = useGetMaterial({variables: {qualifier, accessionNumber}})
  const material = data?.org?.site?.result
  const thumbnailPhoto = useThumbnailPhoto(material)

  return (
    <Popup longitude={longitude} latitude={latitude} focusAfterOpen={false}>
      <div className="relative flex items-center gap-4 overflow-hidden rounded-2xl bg-white p-3 pr-6 font-sans shadow-lg">
        {thumbnailPhoto == null ? (
          <PhotoPlaceholder size="md" className="rounded-xl" />
        ) : (
          <TwicImg
            src={`${thumbnailPhoto.bucketName}/${thumbnailPhoto.fileName}`}
            className="h-20 w-20 overflow-hidden rounded-xl"
            ratio="1"
          />
        )}
        <div className="flex flex-col gap-0.5 truncate">
          <p className="truncate text-sm text-grey-500">
            {createMaterialNumber(accessionNumber, qualifier, "/")}
          </p>
          <p className="truncate font-header text-base font-medium text-grey-900">
            {scientificName}
          </p>
          <p className="truncate text-sm text-grey-500">
            {material?.accession?.taxon?.commonName ?? "-"}
          </p>
        </div>
      </div>
    </Popup>
  )
})
