import {useEffect} from "react"
import {
  Command,
  CommandDialog,
  CommandHeader,
  CommandInput,
  CommandList,
} from "@hortis/ui/command"

export interface SelectedRecordWithTags {
  tagIds: Array<string>
  recordId: string
}
export interface ActionMenuProps {
  open: boolean
  title: string
  closeAndResetMenu: () => void
  search: string
  setSearch: (search: string) => void
  children: React.ReactNode
  placeholder?: string
}

const filterCreateListItem = (item: string, query: string) => {
  if (item === "+add+") {
    return 1
  }
  if (item.toLowerCase().includes(query.trim().toLowerCase())) {
    return 1
  }
  return 0
}

const ActionsMenu = ({
  title,
  open,
  closeAndResetMenu,
  search,
  setSearch,
  children,
  placeholder,
}: ActionMenuProps) => {
  const handleOpenChange = () => {
    closeAndResetMenu()
  }
  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault()
        closeAndResetMenu()
      }
      if (open && e.key === "Escape") {
        closeAndResetMenu()
      }
    }

    document.addEventListener("keydown", down)
    return () =>
      // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
      document.removeEventListener("keydown", down)
  }, [closeAndResetMenu, open])

  const handleOnValueChange = (value: string) => {
    const trimmedValue = value.trim()
    setSearch(trimmedValue === "" ? trimmedValue : value)
  }

  return (
    <CommandDialog open={open} onOpenChange={handleOpenChange}>
      <Command
        filter={filterCreateListItem}
        data-cy="actions-menu-dialog"
        size="lg"
      >
        <CommandHeader title={title} />
        <CommandInput
          placeholder={placeholder ?? "Find..."}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          value={search}
          onValueChange={handleOnValueChange}
          data-cy="actions-menu-search-filter"
        />
        <CommandList>{children}</CommandList>
      </Command>
    </CommandDialog>
  )
}

export {ActionsMenu}
