import {AvatarGroup} from "@hortis/ui/avatar-group"
import {Button, IconButton} from "@hortis/ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@hortis/ui/dropdown-menu"
import {DotsVertical, ExternalLink1, Logout1, Settings1} from "@hortis/ui/icons"
import {Link} from "@tanstack/react-router"
import type {MeCollectionSitesFieldsFragment} from "generated/graphql"
import type {ReactElement} from "react"
import {useCallback, useState} from "react"
import {ChevonRight} from "src/components/icons/streamline/chevron-right"
import {MultipleUsers} from "src/components/icons/streamline/multiple-users"
import {UserPlus1} from "src/components/icons/streamline/user-plus"
import {useSnackbarStore} from "src/components/snackbar-controller/snackbar-store"
import type {AccessRoleUtils} from "src/features/permissions/use-access-role"
import {onFailure} from "src/notification-snack-utils"
import {mapSlugToHrefs} from "src/utils/hooks/hrefs"
import {useStickyState} from "src/utils/hooks/sticky-state"

interface Props {
  collectionSite: MeCollectionSitesFieldsFragment
  joinSite?: (siteId: string) => void
  leaveSite?: (siteId: string) => void
  hideLeave?: boolean
  accessRoleUtils?: AccessRoleUtils
}

export const CollectionSiteTile = ({
  collectionSite,
  joinSite,
  leaveSite,
  hideLeave,
  accessRoleUtils,
}: Props): ReactElement => {
  const hrefs = mapSlugToHrefs(collectionSite.urlSlug)
  const {setSnack} = useSnackbarStore()
  const [_, setLastVisitedSiteId] = useStickyState("", "last-visited-siteId")
  const [menuOpen, setMenuOpen] = useState(false)

  const handleOpenSite = useCallback(() => {
    setLastVisitedSiteId(collectionSite.id)
  }, [collectionSite.id, setLastVisitedSiteId])

  const handleJoinSite = useCallback(() => {
    if (joinSite == null) {
      onFailure(setSnack)(new Error("Failed to join site"))
      return
    }
    setMenuOpen(false)
    joinSite(collectionSite.id)
  }, [collectionSite, joinSite, setSnack])

  const handleLeaveSite = useCallback(() => {
    if (leaveSite == null) {
      onFailure(setSnack)(new Error("Failed to leave site"))
      return
    }
    setMenuOpen(false)
    leaveSite(collectionSite.id)
  }, [collectionSite, leaveSite, setSnack])

  return (
    <div className="relative flex flex-col gap-4 rounded-xl border border-grey-200 bg-white p-4 shadow-xs transition-colors sm:gap-6 sm:p-6 md:flex-row md:items-center">
      <div className="flex flex-col gap-0.5 truncate pr-10 sm:flex-1">
        <div
          className="truncate pt-0.5 font-medium text-grey-900"
          data-cy="collection-name"
        >
          {collectionSite.name}
        </div>
        {collectionSite.members != null && (
          <div className="flex flex-wrap items-center gap-x-3 gap-y-1.5">
            <div className="flex items-center gap-1.5 text-sm font-medium text-grey-500">
              <MultipleUsers />
              {collectionSite.members.length > 0
                ? collectionSite.members.length
                : "No"}{" "}
              member
              {collectionSite.members.length === 1 ? "" : "s"}
            </div>
            <Button
              variant="tertiaryGray"
              size="xs"
              className="ml-[-2px]"
              asChild
            >
              <Link
                to={`/settings/sites/$siteSlug/members`}
                params={{siteSlug: collectionSite.urlSlug}}
              >
                <AvatarGroup avatars={collectionSite.members} />
              </Link>
            </Button>
          </div>
        )}
      </div>
      <div className="flex flex-1 items-center gap-2 md:flex-grow-0">
        {accessRoleUtils?.canEdit === true && (
          <div className="absolute right-4 top-4 sm:right-6 sm:top-6 md:relative md:right-0 md:top-0">
            <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
              <DropdownMenuTrigger asChild>
                <IconButton
                  size="sm"
                  variant="tertiaryGray"
                  ariaLabel="More actions"
                  testId="more-actions-button"
                  icon={DotsVertical}
                />
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                {joinSite != null && (
                  <>
                    <DropdownMenuItem data-cy="open-site-menu-item" asChild>
                      <Link
                        to="/sites/$siteSlug/dashboard"
                        params={{siteSlug: collectionSite.urlSlug}}
                      >
                        <ExternalLink1 className="h-4 w-4 text-grey-500" />
                        Open site
                      </Link>
                    </DropdownMenuItem>
                    <DropdownMenuSeparator />
                  </>
                )}
                {leaveSite != null && hideLeave !== true && (
                  <>
                    <DropdownMenuItem
                      data-cy="leave-site-menu-item"
                      onSelect={handleLeaveSite}
                    >
                      <Logout1 className="h-4 w-4 text-grey-500" />
                      Leave site
                    </DropdownMenuItem>
                    <DropdownMenuSeparator />
                  </>
                )}
                <DropdownMenuItem data-cy="site-settings-menu-item" asChild>
                  <Link
                    to="/settings/sites/$siteSlug/general"
                    params={{siteSlug: collectionSite.urlSlug}}
                  >
                    <Settings1 className="h-4 w-4 text-grey-500" />
                    Site settings
                  </Link>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        )}
        {joinSite == null ? (
          <Button
            onClick={handleOpenSite}
            size="sm"
            endIcon={ChevonRight}
            testId="open-site-button"
            fullWidth
            asChild
          >
            <Link to={`/${hrefs.dashboard}`}>Open</Link>
          </Button>
        ) : (
          <Button
            size="sm"
            endIcon={<UserPlus1 />}
            fullWidth
            testId="join-site-button"
            onClick={handleJoinSite}
          >
            Join
          </Button>
        )}
      </div>
    </div>
  )
}
