export const identificationQualifiers = ["cf.", "aff.", "?"] as const
export const identificationQualifierLabelMap: Record<string, string> = {
  "cf.": "Compares with taxon",
  "aff.": "Affinity with taxon",
  "?": "Uncertain",
} as const
export type IdentificationQualifier = (typeof identificationQualifiers)[number]

export const identificationVerificationStatuses = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
] as const
export type IdentificationVerificationStatus =
  (typeof identificationVerificationStatuses)[number]
export const identificationVerificationStatusMap: Record<string, string> = {
  "0": "Not determined",
  "1": "By comparison",
  "2": "By taxonomist",
  "3": "By taxonomic specialist",
  "4": "Type material",
  "5": "By breeder",
} as const
