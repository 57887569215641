import {A, D, pipe} from "@mobily/ts-belt"
import InputBase from "@mui/material/InputBase"
import {styled} from "@mui/material/styles"
import type {
  ChangeEvent,
  KeyboardEvent as ReactKeyboardEvent,
  MutableRefObject,
  ReactElement,
  RefObject,
  SyntheticEvent,
} from "react"
import {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react"
import {Button} from "@hortis/ui/button"
import {ChevronDown} from "@hortis/ui/icons"
import {InputAdornment} from "../../../../components/adornment"
import {Menu} from "../../../../components/menu/menu"
import {useBindCallback} from "../../../../utils/hooks/callback"
import {useSmallMobile} from "../../../../utils/hooks/media-query"
import type {CSSObject, SxProps} from "../../../../utils/sx"
import {SearchEndAdornments} from "./search-end-adornments"
import type {MenuLabels} from "./types"

const SearchFieldSelector = styled("div")(
  ({theme}): CSSObject => ({
    color: theme.palette.text.secondary,
    padding: theme.spacing(0),
    position: "relative",
    display: "inline",
    flexGrow: 0,
  }),
)

export const SearchBorder = styled("div", {
  shouldForwardProp: (prop) =>
    prop !== "responsive" && prop !== "transparentBorder",
})<{responsive?: boolean; transparentBorder?: boolean}>(
  ({theme, transparentBorder = false}): CSSObject => ({
    position: "relative",
    padding: "3px",
    borderRadius: theme.shape.borderRadiusString,
    backgroundColor: theme.palette.background.paper,
    transition: "all 150ms ease",
    boxShadow: "0px 1px 2px 0px #1018280D",
    "&:hover": {
      "&:before": {
        border: `1px solid ${theme.palette.gray[400]}`,
      },
    },
    "&:focus-within": {
      "&:before": {
        border: `1px solid #5FE9D0`,
      },
      boxShadow: `0 0 0 3px rgba(155, 255, 231, 0.5)`,
    },
    width: "100%",
    "&:before": {
      transition: "all 150ms ease",
      borderRadius: theme.shape.borderRadiusString,
      border: `1px solid ${
        transparentBorder ? "transparent" : theme.palette.gray[300]
      }`,
      content: `""`,
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
  }),
)

const webkitPropertiesToHide = [
  "-webkit-search-decoration",
  "-webkit-search-cancel-button",
  "-webkit-search-results-button",
  "-webkit-search-results-decoration",
]

const withoutSearchDecoration = Object.fromEntries(
  webkitPropertiesToHide.map((k) => [`&::${k}`, {display: "none"}] as const),
)

export const StyledInputBase = styled(InputBase)(
  (): CSSObject => ({
    color: "inherit",
    width: "100%",
    height: "100%",
    "& .MuiInputBase-input": {
      ...withoutSearchDecoration,
      WebkitAppearance: "none",
      // vertical padding + font size from searchIcon
      width: "100%",
    },
  }),
)

const searchValueReducer = (
  _: string,
  action: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string,
) => (typeof action === "string" ? action : action.target.value)

type EnforceNonEmptyRecord<R> = keyof R extends never ? never : R

type SearchBarProps<P extends MenuLabels> = {
  menuLabels: EnforceNonEmptyRecord<P>
  handleSubmitCallback: (
    searchValue: string,
    searchField: keyof EnforceNonEmptyRecord<P>,
  ) => void
  handleClearCallback: (field: keyof EnforceNonEmptyRecord<P>) => void
  testId?: string
  searchTermOverride?: {
    value: string
    field: keyof EnforceNonEmptyRecord<P>
  }
  transparentBorder?: boolean
  containerStyles?: SxProps
  searchFieldIfNumber?: keyof EnforceNonEmptyRecord<P>
  inputRef?: RefObject<HTMLInputElement>
  className?: string
}

type SearchBar = <P extends MenuLabels>(
  props: SearchBarProps<P>,
) => ReactElement

const pickLatest = <Value,>(_state: Value, action: Value) => action

const firstKey = <Key extends string>(
  labels: EnforceNonEmptyRecord<Record<Key, unknown>>,
) => Object.keys(labels)[0] as Key

const searchBarInputProps = {
  "aria-label": "search",
  "data-cy": "search-input",
  spellCheck: false,
  /*
   * Change text on enter key to "search" on virtual keyboards
   *
   * https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/enterkeyhint
   */
  enterKeyHint: "search",
}

const StartAdornments = memo(function MemoisedStartAdornment({
  menuAnchorEl,
  menuEl,
  open,
  options,
  handleOpen,
  handleClose,
  searchFieldLabel,
}: {
  menuAnchorEl: MutableRefObject<null>
  menuEl: MutableRefObject<null>
  open: boolean
  options: ReadonlyArray<{
    value: string
    label: string
    onClick: () => void
    testId: string
  }>
  handleOpen: () => void
  handleClose: () => void
  searchFieldLabel?: string
}) {
  return (
    <InputAdornment position="start">
      {options.length > 1 && (
        <SearchFieldSelector>
          <Button
            testId="search-field-button"
            ref={menuAnchorEl}
            variant="tertiaryGray"
            size="xs"
            className="!rounded !px-2.5 !py-1.5"
            onClick={handleOpen}
            endIcon={ChevronDown}
          >
            {searchFieldLabel}
          </Button>
          <Menu
            ref={menuEl}
            open={open}
            onClose={handleClose}
            options={options}
            anchorEl={menuAnchorEl}
          />
        </SearchFieldSelector>
      )}
    </InputAdornment>
  )
})

const keyCodes = new Set(["Enter"])
export const SearchBar = memo(function MemoisedSearchBar<P extends MenuLabels>({
  menuLabels,
  handleSubmitCallback,
  handleClearCallback,
  testId,
  searchTermOverride,
  transparentBorder,
  containerStyles,
  searchFieldIfNumber,
  inputRef: _inputRef,
  className,
}: SearchBarProps<P>) {
  type Field = keyof typeof menuLabels
  type Labels = typeof menuLabels
  const [searchField, setSearchField] = useReducer<
    (_state: Field, action: Field) => Field,
    Labels
  >(
    pickLatest, // Dispatch
    menuLabels, // initialState
    firstKey, // initializer
  )
  const [searchValue, setSearchValue] = useReducer(searchValueReducer, "")

  const onValueChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string) => {
      if (
        searchFieldIfNumber != null &&
        typeof e !== "string" &&
        /^\d/.test(e.target.value)
      ) {
        setSearchField(searchFieldIfNumber)
      }
      setSearchValue(e)
    },
    [searchFieldIfNumber],
  )

  useEffect(() => {
    if (searchTermOverride !== undefined) {
      setSearchValue(searchTermOverride.value)
      setSearchField(searchTermOverride.field)
    }
  }, [searchTermOverride, setSearchValue, setSearchField])

  const [open, setOpen] = useState(false)
  const menuAnchorEl = useRef(null)
  const menuEl = useRef(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const isSmallMobile = useSmallMobile()

  const handleSubmit = useCallback(() => {
    if (searchValue === "") {
      handleClearCallback(searchField)
    } else {
      handleSubmitCallback(searchValue, searchField)
    }
  }, [searchValue, searchField, handleClearCallback, handleSubmitCallback])

  const handleClear = useCallback(
    (_e: unknown) => {
      // focus in input when clicking clear
      if (_inputRef == null) {
        inputRef.current?.focus()
      } else {
        _inputRef.current?.focus()
      }
      handleClearCallback(searchField)
      setSearchValue("")
    },
    [searchField, handleClearCallback, _inputRef],
  )

  const handleFormSubmit = useCallback(
    (e: SyntheticEvent<HTMLFormElement>) => {
      // Act as if the user had clicked clear for empty strings
      handleSubmit()
      e.preventDefault() // stop page reloading
    },
    [handleSubmit],
  )

  const handleButtonSubmit = useCallback(
    (_e: unknown) => {
      handleSubmit()
    },
    [handleSubmit],
  )

  const handleCloseTouchDeviceKeyboard = useCallback(
    (e: ReactKeyboardEvent<HTMLInputElement>) => {
      if (keyCodes.has(e.key) && "ontouchstart" in window) {
        // focus in input when clicking clear
        if (_inputRef == null) {
          inputRef.current?.blur()
        } else {
          _inputRef.current?.blur()
        }
      }
    },
    [_inputRef],
  )

  const handleOpen = useBindCallback(setOpen, true)
  const handleClose = useBindCallback(setOpen, false)

  const options = useMemo(
    () =>
      pipe(
        menuLabels,
        D.toPairs, // as we can't know the keys we use toPairs
        A.map(([key, {labels}]) => ({
          value: labels.name,
          label: labels.name,
          onClick: () => {
            setOpen(false)
            setSearchField(key)
          },
          testId: key,
        })),
      ),
    [menuLabels],
  )

  const placeholder = isSmallMobile
    ? menuLabels[searchField]?.placeholders.shortName
    : menuLabels[searchField]?.placeholders.name

  const searchFieldLabel = isSmallMobile
    ? menuLabels[searchField]?.labels.shortName
    : menuLabels[searchField]?.labels.name

  return (
    <form data-cy={testId} onSubmit={handleFormSubmit} className={className}>
      <SearchBorder
        responsive
        transparentBorder={transparentBorder}
        sx={containerStyles}
      >
        <StyledInputBase
          inputRef={_inputRef ?? inputRef}
          startAdornment={
            <StartAdornments
              menuEl={menuEl}
              menuAnchorEl={menuAnchorEl}
              handleOpen={handleOpen}
              handleClose={handleClose}
              searchFieldLabel={searchFieldLabel}
              open={open}
              options={options}
            />
          }
          endAdornment={
            <SearchEndAdornments
              showClear={searchValue !== ""}
              handleClear={handleClear}
              handleSubmit={handleButtonSubmit}
            />
          }
          value={searchValue}
          onChange={onValueChange}
          onKeyUp={handleCloseTouchDeviceKeyboard}
          data-cy="search-input-wrapper"
          placeholder={placeholder ?? "Search"}
          inputProps={searchBarInputProps}
          type="search"
        />
      </SearchBorder>
    </form>
  )
}) as SearchBar
