import type {ReactElement} from "react"
import {useCallback, useMemo, useRef, useState} from "react"
import {Divider} from "src/components/custom/divider"
import {ToggleChip} from "src/components/toggle/toggle-chip"
import {FilterDrawerTitle} from "src/features/filters/components/filter-drawer-title"
import {useMobile} from "src/utils/hooks/media-query"
import type {OpenFilterArgs} from "../plant-materials/filters"
import {AddAccessionFilter} from "./add-accession-filter"
import {AccessionFilterChipGroup} from "./chip-group"
import {AccessionFilterModals} from "./filter-modals/filter-modals"
import {AccessionsSortSelect} from "./sort-select"
import type {AccessionFilterType, AccessionsFiltersProps} from "./types"

export const AccessionsMobileFilters = ({
  filters,
  dispatchFilters,
  excludeDeaccessionedAccessions,
  onChange,
  orderBy,
  updateOrderBy,
}: AccessionsFiltersProps): ReactElement => {
  const [openFilter, setOpenFilter] = useState<false | AccessionFilterType>(
    false,
  )
  const [editFilterId, setEditFilterId] = useState<string | null>(null)
  const filterModalAnchorEl = useRef<HTMLElement | null>(null)
  const editFilter = useMemo(
    () =>
      editFilterId == null
        ? undefined
        : filters.and.find(({id}) => id === editFilterId),
    [filters, editFilterId],
  )

  const handleOpenFilter = useCallback(
    ({
      filterType,
      anchorElRef,
      editFilterId,
    }: OpenFilterArgs<AccessionFilterType>) => {
      setEditFilterId(editFilterId ?? null)
      if (anchorElRef != null) {
        filterModalAnchorEl.current = anchorElRef
      }
      setOpenFilter(filterType)
    },
    [],
  )

  const handleFilterModalClose = useCallback(() => {
    setOpenFilter(false)
  }, [])

  const isMobile = useMobile()

  return (
    <div className="flex h-full max-h-screen min-h-[460px] flex-1 flex-col pb-4">
      <FilterDrawerTitle title="Accessions" />
      <div className="pb-6 pt-4">
        <label className="hidden" htmlFor="mobile-sort-select">
          Sort by
        </label>
        <AccessionsSortSelect
          orderBy={orderBy}
          updateOrderBy={updateOrderBy}
          id="mobile-sort-select"
          fullWidth
        />
      </div>
      <Divider />
      <div className="py-4 font-medium text-grey-900">Filters</div>
      <div className="flex flex-wrap gap-2 lg:flex-col">
        <ToggleChip
          onChange={onChange}
          checked={excludeDeaccessionedAccessions}
          testId="deaccessioned-accessions-switch-mobile"
        >
          Exclude &apos;deaccessioned&apos; material
        </ToggleChip>
        <AccessionFilterChipGroup
          filters={filters.and}
          dispatchFilter={dispatchFilters}
          handleOpenFilter={handleOpenFilter}
        />
        <AddAccessionFilter
          side={isMobile ? "top" : "bottom"}
          setOpenFilter={handleOpenFilter}
        />
      </div>
      <AccessionFilterModals
        editFilter={editFilter}
        openFilter={openFilter}
        handleFilterModalClose={handleFilterModalClose}
        filterModalAnchorEl={isMobile ? undefined : filterModalAnchorEl}
        dispatchFilters={dispatchFilters}
      />
    </div>
  )
}
