import {useCallback, useMemo} from "react"
import {usePersistedReducer} from "src/utils/hooks/persisted-reducer"
import type {TaxaFilter} from "generated/graphql"
import {flattenTagsFilter} from "../tags-filter"
import {flattenFamilyFilter} from "../family-filter/flatten-family-filter"
import {transformRedListStatusFilter} from "../red-list-status/transform"
import {transformNativeDistributionFilter} from "../native-distribution/transform"
import {transformLifeFormsFilter} from "../life-forms/transform"
import {transformClimateFilter} from "../climate/transform"
import {transformAuthorshipFilter} from "../authorship/transform"
import {transformUserFilter} from "../user-filter/transform-user-filter"
import {countTaxaFilters} from "./count-filters"
import type {TaxaFilterState} from "./filter-reducer"
import {defaultState, FilterActionTypes, filterReducer} from "./filter-reducer"
import type {TaxaFilterType} from "./types"
import {flattenValidationFilter} from "./validation-filter/flatten-validation-filter"

export const useTaxaFilters = (args?: {
  initialState?: Partial<TaxaFilterState>
  persistenceKey?: string
}) => {
  const {initialState, persistenceKey} = args ?? {}
  const [filters, dispatchFilters] = usePersistedReducer(
    filterReducer,
    {
      ...defaultState,
      ...initialState,
    },
    persistenceKey,
  )

  const preparedFilters = useMemo<TaxaFilter>(
    () => ({
      ...filters,
      and: filters.and.map(
        ({
          id: _,
          validation,
          tags,
          family,
          authorship,
          sharedScientificName,
          redListStatus,
          nativeDistribution,
          lifeForms,
          climate,
          creator,
          lastEditor,
          ...rest
        }) => ({
          ...transformAuthorshipFilter(authorship),
          tags: flattenTagsFilter(tags),
          sharedScientificName: {
            ...sharedScientificName,
            ...flattenValidationFilter(validation),
            classification: flattenFamilyFilter(family),
          },
          ...transformRedListStatusFilter(redListStatus),
          ...transformNativeDistributionFilter(nativeDistribution),
          ...transformLifeFormsFilter(lifeForms),
          ...transformClimateFilter(climate),
          createdBy: transformUserFilter(creator),
          updatedBy: transformUserFilter(lastEditor),
          ...rest,
        }),
      ),
    }),
    [filters],
  )

  const filterCounts = useMemo(
    () => countTaxaFilters(preparedFilters),
    [preparedFilters],
  )

  const resetFilters = useCallback(
    (filterType?: TaxaFilterType) => {
      if (filterType == null || typeof filterType !== "string") {
        dispatchFilters({
          type: FilterActionTypes.RESET_FILTERS,
          payload: initialState,
        })
      } else {
        dispatchFilters({
          type: FilterActionTypes.RESET_FILTER_TYPE,
          payload: filterType,
        })
      }
    },
    [initialState, dispatchFilters],
  )

  return {
    filters,
    preparedFilters,
    filterCounts,
    resetFilters,
    dispatchFilters,
  }
}

export type TaxaFilters = ReturnType<typeof useTaxaFilters>
