import {Badge} from "@hortis/ui/badge"
import {AlarmClock} from "@hortis/ui/icons"
import {
  ContentsTable,
  ContentsTableItem,
} from "src/features/records/components/table-of-contents"
import {type RecordSectionId} from "src/features/records/components/table-of-contents/use-records-table-of-contents"

interface MaterialContentsTableProps {
  isOverdue: boolean
  isSeed: boolean
}

const sectionAnchors: Array<RecordSectionId> = [
  "details",
  "location",
  "accession-taxon",
  "photos",
  "observations",
  "activity",
  "related-records",
  "tags",
  "notes",
]
const MaterialContentsTable = ({
  isOverdue,
  isSeed,
}: MaterialContentsTableProps) => {
  return (
    <ContentsTable sectionAnchors={sectionAnchors}>
      <ContentsTableItem href="details">Details</ContentsTableItem>
      <ContentsTableItem href="location">Location</ContentsTableItem>
      <ContentsTableItem href="tags">Tags</ContentsTableItem>
      <ContentsTableItem href="notes">Notes</ContentsTableItem>
      <ContentsTableItem href="accession-taxon">
        Accession/Taxon
      </ContentsTableItem>
      <ContentsTableItem href="photos">Photos</ContentsTableItem>
      {!isSeed && (
        <ContentsTableItem href="observations">
          Observations
          {isOverdue && (
            <Badge color="warning" className="pl-1.5">
              <div className="flex items-center justify-start gap-1 ">
                <AlarmClock className="h-3 w-3 text-warning-500" />
                <span>Overdue</span>
              </div>
            </Badge>
          )}
        </ContentsTableItem>
      )}
      <ContentsTableItem href="activity">Activity</ContentsTableItem>
      <ContentsTableItem href="related-records">
        Related records
      </ContentsTableItem>
    </ContentsTable>
  )
}

export {MaterialContentsTable}
