import {Button, IconButton, type ButtonProps} from "@hortis/ui/button"
import {Plus} from "@hortis/ui/icons"
import {type PopoverContentProps} from "@radix-ui/react-popover"
import {useRef, useState, type RefObject} from "react"
import {
  Command,
  CommandEmpty,
  CommandInput,
  CommandItem,
  CommandList,
} from "@hortis/ui/command"
import {Popover, PopoverContent, PopoverTrigger} from "src/components/popover"
import type {OpenFilterArgs} from "../plant-materials/filters"
import type {TaxaFilterType} from "./types"

const filters: Array<{value: TaxaFilterType; label: string}> = [
  {
    value: "authorship",
    label: "Authorship",
  },
  {
    value: "commonName",
    label: "Common name",
  },
  {
    value: "validation",
    label: "Validation",
  },
  {
    value: "family",
    label: "Family",
  },
  {
    value: "redListStatus",
    label: "IUCN Red List",
  },
  {
    value: "nativeDistribution",
    label: "Native distribution",
  },
  {
    value: "lifeForms",
    label: "Life forms",
  },
  {
    value: "climate",
    label: "Climate",
  },
  {value: "tags", label: "Tags"},
  {value: "notes", label: "Notes"},
  {value: "creator", label: "Creator"},
  {value: "lastEditor", label: "Last editor"},
]

interface AddTaxaFilterProps {
  setOpenFilter: (args: OpenFilterArgs<TaxaFilterType>) => void
  side?: PopoverContentProps["side"]
  align?: PopoverContentProps["align"]
  buttonProps?: ButtonProps & {ref?: RefObject<HTMLButtonElement>}
  iconButton?: boolean
}

export const AddTaxaFilter = ({
  setOpenFilter,
  buttonProps,
  iconButton,
  side,
  align = "start",
}: AddTaxaFilterProps) => {
  const [open, setOpen] = useState(false)
  const menuAnchorEl = useRef(null)

  const handleSelect = (value: TaxaFilterType) => {
    setOpenFilter({
      filterType: value,
      anchorElRef: buttonProps?.ref?.current ?? menuAnchorEl.current,
    })
    setOpen(false)
  }
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = (open: boolean) => {
    setOpen(open)
    if (open) {
      setOpenFilter({filterType: false})
    }
  }

  return (
    <Popover open={open} onOpenChange={handleClose}>
      <PopoverTrigger asChild>
        {iconButton === true ? (
          <IconButton
            ref={menuAnchorEl}
            onClick={handleOpen}
            icon={Plus}
            ariaLabel="Add filter"
            size="sm"
            variant="tertiaryGray"
            className="border border-dashed border-grey-200"
            {...buttonProps}
          />
        ) : (
          <Button
            ref={menuAnchorEl}
            onClick={handleOpen}
            testId="add-filter-button"
            size="sm"
            className="!px-2"
            startIcon={Plus}
            {...buttonProps}
          >
            {buttonProps?.children ?? "Add filter"}
          </Button>
        )}
      </PopoverTrigger>
      <PopoverContent side={side} align={align} className="z-20">
        <Command>
          <CommandInput placeholder="Search..." />
          <CommandEmpty>No results</CommandEmpty>
          <CommandList>
            {filters.map((item) => (
              <CommandItem
                key={item.value}
                value={item.value}
                onSelect={() => {
                  handleSelect(item.value)
                }}
              >
                {item.label}
              </CommandItem>
            ))}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
