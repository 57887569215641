import type {DeepNullable} from "ts-essentials"
import {BasicDatePicker} from "src/components/date-picker"
import {FieldsHeader} from "src/components/forms/fields-header"
import {conditionOptions} from "src/components/modals/make-observation/condition-options"
import {followUpOptions} from "src/components/modals/make-observation/follow-up-options"
import {FormSelectField} from "src/components/select-field"
import type {PlantMaterialData} from "../schema"

interface ObservationFieldsProps {
  namePrefix: string
}

type EditableFields = DeepNullable<Pick<PlantMaterialData, "position">>
export type FormFields = {
  [Key in keyof EditableFields as `${string}${Key}`]: EditableFields[Key]
}

export const ObservationFields = ({namePrefix}: ObservationFieldsProps) => {
  return (
    <FieldsHeader header="Observation" testId="observation-header">
      <div className="flex flex-col gap-6 pt-6">
        <BasicDatePicker
          label="Date observed"
          name={`${namePrefix}lastObserved`}
          testId="last-observed"
          required
        />
        <FormSelectField
          label="Follow-up"
          name={`${namePrefix}followUp`}
          testId="follow-up"
          required
          jsonValue
        >
          {followUpOptions.map(({value, label}) => (
            <option value={JSON.stringify(value)} key={JSON.stringify(value)}>
              {label}
            </option>
          ))}
        </FormSelectField>
        <FormSelectField
          label="Condition"
          name={`${namePrefix}condition`}
          testId="condition"
        >
          <option aria-label="None" value="" />
          {conditionOptions.map(({value, label}) => (
            <option value={value} key={value}>
              {label}
            </option>
          ))}
        </FormSelectField>
      </div>
    </FieldsHeader>
  )
}
