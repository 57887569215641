import type {ReactElement} from "react"
import {Fragment, useEffect, useRef} from "react"
import {useNavigate} from "@tanstack/react-router"
import {useSnackbarStore} from "src/components/snackbar-controller/snackbar-store"
import {AccessRole} from "generated/graphql"
import {useAccessRole} from "./use-access-role"

interface Props {
  redirectUrl?: string
  Component: () => ReactElement
}

export function redirectNonEditRole({Component, redirectUrl = "/"}: Props) {
  return function RedirectNonEditRole(): ReactElement {
    const redirecting = useRef(false)
    const {setSnack} = useSnackbarStore()
    const accessRole = useAccessRole()
    const navigate = useNavigate()

    useEffect(() => {
      if (!redirecting.current && accessRole.min(AccessRole.Editor) === false) {
        redirecting.current = true

        void navigate({to: redirectUrl})
        setSnack({
          type: "alert",
          data: {
            severity: "error",
            text: "This page is not available for your access role",
          },
        })
      }
    }, [navigate, accessRole, accessRole.role, redirecting, setSnack])

    return accessRole.min(AccessRole.Editor) === false ? (
      <Fragment />
    ) : (
      <Component />
    )
  }
}
