import {R} from "@mobily/ts-belt"
import type {useGetTaxaQuery} from "generated/graphql"
import type {useOrganisationSubdomainStruct} from "src/utils/hooks/place"

export const processTaxaQuery = ({
  organisationSubdomain,
  taxaQuery,
}: {
  organisationSubdomain: ReturnType<typeof useOrganisationSubdomainStruct>
  taxaQuery: ReturnType<typeof useGetTaxaQuery>[0]
}) => {
  const error = taxaQuery.error ?? organisationSubdomain.error
  if (error != null) {
    return R.Error(error.message)
  }
  const org = taxaQuery.data?.org
  if (org == null) {
    return R.Error("Organisation field in query result was null")
  }
  const result = org.result
  if (result == null) {
    return R.Error("Result field in query result was null")
  }
  return R.Ok({
    nodes: result.nodes as Array<(typeof result.nodes)[number]>,
    taxaTotal: org.total,
    pageInfo: result.pageInfo,
  })
}
