import {
  Alert,
  AlertContent,
  AlertSupportText,
  AlertText,
} from "@hortis/ui/alert"
import {
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuSeparator,
  useContextMenuCloseFix,
} from "@hortis/ui/context-menu"
import {
  AlertTriangle,
  ArrowUpRight,
  Download1,
  Edit4,
  Tag1,
  Trash3,
} from "@hortis/ui/icons"
import {Browser} from "@hortis/ui/icons/browser"
import {CircularProgress} from "@hortis/ui/progress"
import {
  type TaxaFilter,
  type TaxaListFieldsFragment,
  type TaxaSearchTerm,
  useAddTagToTaxaMutation,
  useGetTaxonQuery,
  useRemoveTagFromTaxaMutation,
} from "generated/graphql"
import {useCallback, useState} from "react"

import {Sheet, SheetContent} from "@hortis/ui/sheet"
import {useSnackbarStore} from "src/components/snackbar-controller/snackbar-store"
import {TrashTaxaDialog} from "src/features/collection/components/archive-records/trash-taxa-dialog"
import {useDownloadCsv} from "src/features/collection/components/download-accessions/use-download-csv"
import {useAccessRole} from "src/features/permissions/use-access-role"
import {EditTaxonSheetForm} from "src/features/taxonomy/components/taxon-page/edit/drawer"
import {onFailure, onSuccess} from "src/notification-snack-utils"
import {
  OrganisationTaxonCopyContextMenuSub,
  SiteTaxonCopyContextMenuSub,
} from "../copy-submenu/variants/taxon-copy-context-menu-sub"
import {
  CollectionSiteTaxaTagsDialog,
  OrganisationTaxaTagsDialog,
} from "../dialogs/tags-dialog"

interface TaxaContextMenuProps {
  onRequestDownload?: () => void
  isTrash: boolean
  selectedRecordsIDs: ReadonlyArray<string>
  clickedRow: TaxaListFieldsFragment
  taxSearch: TaxaSearchTerm | undefined
  preparedFilters: TaxaFilter
  isOrg: boolean
}

export const TaxaContextMenuContent = ({
  selectedRecordsIDs,
  isTrash,
  clickedRow,
  onRequestDownload,
  taxSearch,
  preparedFilters,
  isOrg, // eslint-disable-next-line sonarjs/cognitive-complexity
}: TaxaContextMenuProps) => {
  const [trashDialogOpen, setTrashDialogOpen] = useState(false)
  const [tagsDialogOpen, setTagsDialogOpen] = useState(false)

  const [editOpen, setEditOpen] = useState(false)

  const {setSnack} = useSnackbarStore()
  const {canEdit} = useAccessRole()

  const {handleClose} = useContextMenuCloseFix()

  const showEditDrawer = selectedRecordsIDs.length <= 1

  const [_, addTagToTaxa] = useAddTagToTaxaMutation()
  const [__, removeTagFromTaxa] = useRemoveTagFromTaxaMutation()

  const applyTagToSelectedRecords = useCallback(
    async (tagId: string) => {
      const res = await addTagToTaxa({
        tagId,
        ids:
          selectedRecordsIDs.length > 0 ? selectedRecordsIDs : [clickedRow.id],
      })

      const addTagSuccessful = res.data?.addTagTaxa.success ?? false
      if (addTagSuccessful) {
        onSuccess(setSnack)(
          `Tag added to ${
            selectedRecordsIDs.length > 0
              ? `${selectedRecordsIDs.length} taxa`
              : "taxon"
          }`,
        )
        // refetchTags()
      } else {
        onFailure(setSnack)(new Error("Failed to add tag to taxa"))
      }
      return addTagSuccessful
    },

    [addTagToTaxa, clickedRow.id, selectedRecordsIDs, setSnack],
  )

  const onRequestSingleDownload = useDownloadCsv({
    csvName: "taxa",
    path: "/download/site-taxa",
    body: {
      searchTerm: taxSearch,
      filter: {
        ...preparedFilters,
        id: {
          eq: clickedRow.id,
        },
      },
    },
  })

  const removeTagFromSelectedRecords = useCallback(
    async (tagId: string) => {
      const res = await removeTagFromTaxa({
        tagId,
        ids:
          selectedRecordsIDs.length > 0 ? selectedRecordsIDs : [clickedRow.id],
      })

      const removeTagSuccessful = res.data?.removeTagTaxa.success ?? false

      if (removeTagSuccessful) {
        onSuccess(setSnack)(
          `Tag removed from ${
            selectedRecordsIDs.length > 0
              ? `${selectedRecordsIDs.length} taxa`
              : "taxon"
          }`,
        )
        // refetchTags()
      } else {
        onFailure(setSnack)(new Error("Failed to remove tag from taxa"))
      }
      return removeTagSuccessful
    },
    [clickedRow.id, removeTagFromTaxa, selectedRecordsIDs, setSnack],
  )

  return (
    <>
      <ContextMenuContent data-cy="taxon-list-context-menu">
        {!isTrash && canEdit && (
          <>
            <ContextMenuItem
              onSelect={() => {
                setTagsDialogOpen(true)
              }}
              data-cy="tags-menu-item"
            >
              <Tag1 className="h-4 w-4 text-grey-500" />
              Add / remove tags
            </ContextMenuItem>
            <ContextMenuSeparator />
          </>
        )}
        {selectedRecordsIDs.length <= 1 && !isTrash && canEdit && (
          <>
            <ContextMenuItem
              onSelect={() => {
                setEditOpen(true)
              }}
              data-cy="edit-menu-item"
            >
              <Edit4 className="h-4 w-4 text-grey-500" />
              Edit
            </ContextMenuItem>
            <ContextMenuSeparator />
          </>
        )}

        {onRequestDownload != null && !isTrash && (
          <>
            <ContextMenuItem
              onSelect={() => {
                if (selectedRecordsIDs.length > 0) {
                  onRequestDownload()
                } else {
                  void onRequestSingleDownload()
                }
              }}
              data-cy="download-menu-item"
            >
              <Download1 className="h-4 w-4 text-grey-500" />
              Download
            </ContextMenuItem>
            <ContextMenuSeparator />
          </>
        )}
        {isOrg && (
          <OrganisationTaxonCopyContextMenuSub
            selectedTaxaIds={
              selectedRecordsIDs.length > 0
                ? selectedRecordsIDs
                : [clickedRow.id]
            }
          />
        )}
        {!isOrg && (
          <SiteTaxonCopyContextMenuSub
            selectedTaxaIds={
              selectedRecordsIDs.length > 0
                ? selectedRecordsIDs
                : [clickedRow.id]
            }
          />
        )}

        {!isTrash && canEdit && (
          <>
            <ContextMenuSeparator />
            <ContextMenuItem
              onSelect={() => {
                setTrashDialogOpen(true)
              }}
              data-cy="trash-menu-item"
            >
              <Trash3 className="h-4 w-4 text-grey-500" />
              Move to trash
            </ContextMenuItem>
          </>
        )}

        {selectedRecordsIDs.length <= 1 && (
          <>
            <ContextMenuSeparator />
            <ContextMenuItem
              onClick={() => {
                window.open(`/taxonomy/${clickedRow.id}`, "_blank")
              }}
              data-cy="open-new-tab-menu-item"
            >
              <ArrowUpRight className="h-4 w-4 text-grey-500" />
              Open in new tab
            </ContextMenuItem>
            <ContextMenuItem
              onClick={() => {
                window.open(
                  `/taxonomy/${clickedRow.id}`,
                  "_blank",
                  "width=full,height=full",
                )
              }}
              data-cy="open-new-window-menu-item"
            >
              <Browser className=" h-4 w-4 text-grey-500" />
              Open in new window
            </ContextMenuItem>
          </>
        )}
      </ContextMenuContent>
      <TrashTaxaDialog
        open={trashDialogOpen}
        setOpen={(open) => {
          setTrashDialogOpen(open)
          if (!open) {
            handleClose()
          }
        }}
        taxaIds={
          selectedRecordsIDs.length > 0 ? selectedRecordsIDs : [clickedRow.id]
        }
        onSuccess={() => {
          setTrashDialogOpen(false)
          handleClose()
        }}
      />
      {isOrg ? (
        <OrganisationTaxaTagsDialog
          removeTagFromSelectedRecords={removeTagFromSelectedRecords}
          applyTagToSelectedRecords={applyTagToSelectedRecords}
          selectedRecordsIDs={selectedRecordsIDs}
          title={
            selectedRecordsIDs.length > 1
              ? `${selectedRecordsIDs.length} taxa selected`
              : `${clickedRow.id}`
          }
          open={tagsDialogOpen}
          onOpenChange={(open) => {
            setTagsDialogOpen(open)
            if (!open) {
              handleClose()
            }
          }}
        />
      ) : (
        <CollectionSiteTaxaTagsDialog
          removeTagFromSelectedRecords={removeTagFromSelectedRecords}
          applyTagToSelectedRecords={applyTagToSelectedRecords}
          title={
            selectedRecordsIDs.length > 1
              ? `${selectedRecordsIDs.length} taxa selected`
              : `${clickedRow.id}`
          }
          open={tagsDialogOpen}
          onOpenChange={(open) => {
            setTagsDialogOpen(open)
            if (!open) {
              handleClose()
            }
          }}
          selectedRecordsIDs={selectedRecordsIDs}
        />
      )}

      {showEditDrawer && (
        <EditTaxonSheet
          taxonId={clickedRow.id}
          open={editOpen}
          onOpenChange={(open) => {
            setEditOpen(open)
            if (!open) {
              handleClose()
            }
          }}
        />
      )}
    </>
  )
}

const EditTaxonSheet = ({
  taxonId,
  open,
  onOpenChange,
}: {
  taxonId: string
  open: boolean
  onOpenChange: (open: boolean) => void
}) => {
  const [
    {data: taxonData, error: taxonError, fetching: taxonFetching},
    refetchTaxon,
  ] = useGetTaxonQuery({
    variables: {
      id: taxonId,
    },
    pause: !open,
  })
  const taxon = taxonData?.taxon
  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetContent data-cy="taxon-edit-drawer">
        {taxon == null && taxonFetching && (
          <div className="flex h-full w-full items-center justify-center">
            <CircularProgress className="h-5 w-5 text-primary-500" />
          </div>
        )}

        {taxon == null && taxonError != null && (
          <div className="p-3">
            <Alert color="warning" className="mb-4" data-cy="taxon-edit-alert">
              <AlertTriangle />
              <AlertContent>
                <AlertText>An error occurred</AlertText>
                <AlertSupportText>{taxonError.message}</AlertSupportText>
              </AlertContent>
            </Alert>
          </div>
        )}
        {taxon != null && (
          <EditTaxonSheetForm
            open={open}
            requestClose={() => {
              onOpenChange(false)
            }}
            refetchTaxon={refetchTaxon}
            taxon={taxon}
          />
        )}
      </SheetContent>
    </Sheet>
  )
}
