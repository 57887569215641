import {IconButton} from "@hortis/ui/button"
import {ExternalLink1} from "@hortis/ui/icons"
import {IUCNRedListTag} from "@hortis/ui/iucn-red-list-tag"
import {useNavigate} from "@tanstack/react-router"
import {type MaterialFieldsFragment} from "generated/graphql"
import {useCallback} from "react"

import {
  RecordField,
  RecordFieldLabel,
  RecordFieldValue,
} from "src/features/records/components/record-field/new-record-field"

import {
  RecordSection,
  RecordSectionContent,
  RecordSectionContentGroup,
  RecordSectionHeader,
  RecordSectionHeaderButtons,
  RecordSectionTitle,
} from "src/features/records/components/record-section/new-record-section"

interface MaterialRecordTaxonProps {
  material: MaterialFieldsFragment
}

export const MaterialRecordTaxon = ({material}: MaterialRecordTaxonProps) => {
  const navigate = useNavigate({
    from: "/sites/$siteSlug/materials/$materialNumber",
  })

  const routeToTaxon = useCallback(() => {
    if (material.accession?.taxon != null) {
      void navigate({
        to: "/taxonomy/$taxonId",
        params: {taxonId: material.accession.taxon.id},
      })
    }
  }, [material, navigate])

  return (
    <RecordSection id="accession-taxon">
      <RecordSectionHeader>
        <RecordSectionTitle>Taxon</RecordSectionTitle>
        <RecordSectionHeaderButtons>
          <IconButton
            onClick={routeToTaxon}
            ariaLabel="Go to taxon"
            data-cy="go-to-taxon-button"
            size="xs"
            variant="tertiaryGray"
            icon={ExternalLink1} //used ExternalLink1 instead of ExternalLink2
          />
        </RecordSectionHeaderButtons>
      </RecordSectionHeader>

      <RecordSectionContent>
        <RecordSectionContentGroup className="flex flex-col gap-4">
          <RecordField data-cy="taxon-family">
            <RecordFieldLabel>Family</RecordFieldLabel>
            <RecordFieldValue>
              {material.accession?.taxon?.sharedScientificName?.classification
                ?.family ?? "-"}
            </RecordFieldValue>
          </RecordField>
          <RecordField data-cy="iucn-status">
            <RecordFieldLabel>IUCN Red List</RecordFieldLabel>
            <RecordFieldValue>
              {material.accession?.taxon?.sharedScientificName?.taxon
                ?.globalRedListStatus ? (
                <IUCNRedListTag
                  status={
                    material.accession.taxon.sharedScientificName.taxon
                      .globalRedListStatus
                  }
                />
              ) : (
                "-"
              )}
            </RecordFieldValue>
          </RecordField>
          <RecordField data-cy="native-distribution">
            <RecordFieldLabel>Native distribution</RecordFieldLabel>
            <RecordFieldValue>
              {material.accession?.taxon?.nativeDistribution ?? "-"}
            </RecordFieldValue>
          </RecordField>
        </RecordSectionContentGroup>
      </RecordSectionContent>
    </RecordSection>
  )
}
