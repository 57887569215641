import * as A from "fp-ts/Array"
import type {CSSProperties} from "react"
import {memo} from "react"
import {Skeleton} from "../../../../components/skeleton"

export type Column = (rowIndex: number) => JSX.Element

interface SkeletonBodyProps {
  rows: number
  columns: number
  withCheckbox?: boolean
  columnConfig?: Array<Column>
}

const checkboxSkeleton = (
  <div role="cell">
    <Skeleton
      variant="rectangular"
      width="24px"
      height="24px"
      animation={false}
    />
  </div>
)

const rowStyle: CSSProperties = {display: "contents"}

const emptyArray: Array<Column> = []
export const SkeletonBody = memo(function SkeletonBody({
  rows,
  columns,
  columnConfig = emptyArray,
  withCheckbox = true,
}: SkeletonBodyProps): JSX.Element {
  const selectedColumns = withCheckbox
    ? columnConfig.slice(0, columns - 1)
    : columnConfig.slice(0, columns)

  return (
    <>
      {Array.from({length: rows}).map((_, rowIndex) => (
        // eslint-disable-next-line react/no-array-index-key
        <div role="row" key={rowIndex} style={rowStyle}>
          {withCheckbox ? checkboxSkeleton : null}
          {A.flap(rowIndex)(selectedColumns)}
        </div>
      ))}
    </>
  )
})
