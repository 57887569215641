import {Alert, AlertActions, AlertContent, AlertText} from "@hortis/ui/alert"
import {Button} from "@hortis/ui/button"
import {Dialog, DialogTrigger} from "@hortis/ui/dialog"
import {useMediaQuery} from "@hortis/ui/hooks/media-query"
import {Trash3} from "@hortis/ui/icons"
import {Link} from "@tanstack/react-router"
import {ProvenanceCategoryCode} from "generated/graphqlSDK"
import type {ReactElement} from "react"
import {useState} from "react"
import {RestoreAccessionDialogContent} from "src/components/dialog/variants/restore-accession"
import AccessionRecordPageHeader from "src/components/record-page-header/accession-record-page-header"
import {AccessionContentsTable} from "src/features/accession-page/accession-contents-table/accession-contents-table"
import {AccessionRecordActivity} from "src/features/accession-page/sections/accession-activity"
import {AccessionRecordIdentification} from "src/features/accession-page/sections/accession-identification"
import {AccessionRecordMaterials} from "src/features/accession-page/sections/accession-materials/accession-materials"
import {AccessionRecordDetails} from "src/features/accession-page/sections/accession-record-details"
import {AccessionRecordTaxon} from "src/features/accession-page/sections/accession-taxon/accession-taxon"
import {AccessionWildCollection} from "src/features/accession-page/sections/accession-wild-collection"
import {useAccessRole} from "src/features/permissions/use-access-role"
import {useCollectionSiteSettings} from "src/utils/hooks/collection-site-from-url"
import type {UseQueryExecute} from "urql"
import {RecordTagSection} from "src/features/records/components/record-section/variants/record-tag-section"
import {RecordNotesSection} from "src/features/records/components/record-section/variants/record-notes-section"
import type {AccessionFieldsFragment} from "../../../../../../generated/graphql"
import {
  AccessionEditStageCallback,
  type AccessionEditStage,
} from "../edit-drawer/types"

const TrashRecordAlert = ({
  accessionId,
  canEdit,
}: {
  accessionId: string
  canEdit: boolean
}) => {
  const [open, setOpen] = useState(false)

  return (
    <Alert color="warning" className="mb-4" data-cy="trash-record-alert">
      <Trash3 />
      <AlertContent>
        <AlertText>This record is in the trash</AlertText>
        <AlertActions>
          <Button variant="linkgray">
            <Link
              to="/sites/$siteSlug/trash"
              from="/sites/$siteSlug"
              search={{tab: "accessions"}}
            >
              View trash
            </Link>
          </Button>
          {canEdit && (
            <Dialog open={open} onOpenChange={setOpen}>
              <DialogTrigger asChild>
                <Button testId="restore-record-button" variant="linkgray">
                  Restore
                </Button>
              </DialogTrigger>
              <RestoreAccessionDialogContent
                onSuccess={() => {
                  setOpen(false)
                }}
                id={accessionId}
              />
            </Dialog>
          )}
        </AlertActions>
      </AlertContent>
    </Alert>
  )
}

export interface AccessionContentsProps {
  accession: AccessionFieldsFragment
  requestEdit: (editStage?: AccessionEditStage) => void
  refetch: UseQueryExecute
  addNewMaterial: () => void
}

export const AccessionContents = ({
  accession,
  requestEdit,
  refetch,
  addNewMaterial,
}: AccessionContentsProps): ReactElement => {
  const roleUtils = useAccessRole()
  const isXlScreen = useMediaQuery("xl")

  const {ipenNumbersEnabled, identificationFieldsEnabled} =
    useCollectionSiteSettings()

  const canEdit = roleUtils.canEdit && !accession.archived

  const editDetails =
    AccessionEditStageCallback.useEditAccessionData(requestEdit)

  return (
    <div className="flex flex-col gap-6 px-4 lg:gap-8 lg:px-8">
      {accession.archived && (
        <TrashRecordAlert
          accessionId={accession.id}
          canEdit={roleUtils.canEdit}
        />
      )}
      <AccessionRecordPageHeader
        handleCreateNewMaterial={addNewMaterial}
        accession={accession}
        requestEdit={requestEdit}
        refetch={refetch}
        canEdit={canEdit}
      />

      <div className="grid lg:gap-12 xl:grid-cols-[188px_auto]">
        {isXlScreen && (
          <div className="sticky top-3 self-start">
            <AccessionContentsTable
              identificationFieldsEnabled={identificationFieldsEnabled}
              provenanceCategoryCode={accession.provenanceCategoryCode}
            />
          </div>
        )}
        <div className="flex flex-col gap-6 lg:gap-8 [&_.record-section-content-group]:sm:grid-cols-2">
          <AccessionRecordDetails
            accession={accession}
            ipenNumbersEnabled={ipenNumbersEnabled}
            requestEdit={requestEdit}
            canEdit={canEdit}
          />
          <RecordTagSection
            tags={accession.tagsConnection?.nodes}
            requestEdit={canEdit ? editDetails : undefined}
            canEdit={canEdit}
          />
          <RecordNotesSection
            notes={accession.notes}
            requestEdit={canEdit ? editDetails : undefined}
            canEdit={canEdit}
          />
          {accession.provenanceCategoryCode !== ProvenanceCategoryCode.G &&
            accession.provenanceCategoryCode !== ProvenanceCategoryCode.U && (
              <AccessionWildCollection
                accession={accession}
                requestEdit={requestEdit}
                canEdit={canEdit}
              />
            )}
          <AccessionRecordTaxon accession={accession} />
          {identificationFieldsEnabled && (
            <AccessionRecordIdentification
              accession={accession}
              requestEdit={requestEdit}
              canEdit={canEdit}
            />
          )}

          <AccessionRecordMaterials
            accession={accession}
            canEdit={canEdit}
            handleCreateNewMaterial={addNewMaterial}
          />
          <AccessionRecordActivity accession={accession} />
        </div>
      </div>
    </div>
  )
}
