import * as A from "fp-ts/Array"
import {useMemo} from "react"
import {FormProvider} from "react-hook-form"
import {match} from "ts-pattern"
import {CollapsableRadioButton, RadioGroup} from "src/components/radio"
import {FilterTitle} from "src/features/filters/components/modal/filter-title"
import {determineInitialOperation} from "src/features/filters/plant-materials/modals/determine-initial-operation"
import type {FilterFormProps} from "src/features/filters/plant-materials/modals/types"
import {TagsAutocomplete} from "src/features/tags/tags-autocomplete"
import type {RecordTagFilters} from "generated/graphql"
import {FormTextField} from "src/components/text-field"
import type {FilterModalProps} from "../components/modal/filter-modal"
import {FilterModal} from "../components/modal/filter-modal"
import {FilterModalButtons} from "../components/modal/modal-buttons"
import {FilterModalError} from "../components/modal/modal-error"
import {useFilterForm} from "../components/modal/use-filter-form"
import type {LocalTagsFilter} from "./schema"
import {tagFilterResolver} from "./schema"

const createDefaultValues = (initialValue?: NonNullable<LocalTagsFilter>) => {
  const defaultValues = {
    is: initialValue?.is == null ? [] : [...initialValue.is],
    isNot: initialValue?.isNot == null ? [] : [...initialValue.isNot],
    contains: initialValue?.contains ?? undefined,
  }
  return {
    defaultValues,
    initialOperation: determineInitialOperation(defaultValues, "is"),
  }
}

interface TagFilterFormProps {
  addModifyFilter: (changes: {tags?: LocalTagsFilter}) => void
  deleteFilter: (() => void) | undefined
  tagFilters?: RecordTagFilters
  initialValue?: {tags?: LocalTagsFilter}
}

export const TagsFilterForm = ({
  initialValue,
  addModifyFilter,
  deleteFilter,
  onClose,
  tagFilters,
}: Omit<FilterFormProps, "dispatchFilters" | "initialValue"> &
  TagFilterFormProps) => {
  const {error, setError, operation, handleRadioChange, formMethods} =
    useFilterForm({
      resolver: tagFilterResolver,
      ...createDefaultValues(initialValue?.tags ?? undefined),
    })

  const onSubmit = useMemo(
    () =>
      formMethods.handleSubmit((values) => {
        match(operation)
          .with("is", () => {
            if (A.isNonEmpty(values.is)) {
              addModifyFilter({tags: {is: values.is}})
              onClose()
            } else {
              setError("Select a tag to continue")
            }
          })
          .with("isNot", () => {
            if (A.isNonEmpty(values.isNot)) {
              addModifyFilter({tags: {isNot: values.isNot}})
              onClose()
            } else {
              setError("Select a tag to continue")
            }
          })
          .with("contains", () => {
            if (values.contains == null) {
              setError("Enter a value to continue")
            } else {
              addModifyFilter({
                tags: {
                  contains: values.contains,
                },
              })
              onClose()
            }
          })
          .run()
      }),
    [formMethods, operation, onClose, addModifyFilter, setError],
  )

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={onSubmit}>
        <div className="mb-4 flex flex-col gap-4">
          <FilterTitle title="Tags" deleteFilter={deleteFilter} />
          <FilterModalError error={error} />
          <RadioGroup
            value={operation}
            onChange={handleRadioChange}
            label="Operation"
          >
            {(props) => (
              <>
                <CollapsableRadioButton
                  selectedValue="is"
                  testId="tags-is-radio"
                  label="Is"
                  {...props}
                >
                  <TagsAutocomplete
                    name="is"
                    testId="tags-is-input"
                    filters={tagFilters}
                  />
                </CollapsableRadioButton>
                <CollapsableRadioButton
                  selectedValue="isNot"
                  testId="tags-is-not-radio"
                  label="Is not"
                  {...props}
                >
                  <TagsAutocomplete
                    name="isNot"
                    testId="tags-is-not-input"
                    filters={tagFilters}
                  />
                </CollapsableRadioButton>
                <CollapsableRadioButton
                  selectedValue="contains"
                  testId="tags-contains-radio"
                  label="Contains"
                  {...props}
                >
                  <FormTextField
                    name="contains"
                    testId="tags-contains-input"
                    label="Contains"
                    hiddenLabel
                    fullWidth
                  />
                </CollapsableRadioButton>
              </>
            )}
          </RadioGroup>
        </div>
        <FilterModalButtons onCancel={onClose} testIdPrefix="tags" />
      </form>
    </FormProvider>
  )
}

// Seperated from form component to make sure popover unmount causes state reset
export const TagsFilterModal = ({
  open,
  onClose,
  anchorEl,
  ...props
}: Omit<FilterFormProps, "initialValue" | "dispatchFilters"> &
  FilterModalProps &
  TagFilterFormProps) => {
  return (
    <FilterModal anchorEl={anchorEl} open={open} onClose={onClose}>
      <TagsFilterForm onClose={onClose} {...props} />
    </FilterModal>
  )
}
