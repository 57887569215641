import {contramapComponent} from "./profunctor"
import type {ReactFn} from "./apply-mui"
export interface WithTestIdProps {
  testId?: string
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Enriched<Props> = (Props extends {"data-cy"?: any}
  ? Props extends {"data-cy"?: string | undefined}
    ? Props
    : {[Key in Exclude<keyof Props, "data-cy">]: Props[Key]} // an alternative is `never`, but this produces friendlier type errors
  : Props) & {testId?: never; "data-cy"?: string}

/**
 * Wrap a component so it easier to test with cypress.
 * @param Component - a React component that can assign a data-cy prop to an HTML element
 * @returns a React component that accepts a testId prop
 */
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- the annotation should be a copy of the normally inferred value
export const withTestId: <Props>(
  Component: Enriched<Props> extends never ? never : ReactFn<Enriched<Props>>,
) => (props: Omit<Props, "data-cy"> & WithTestIdProps) => JSX.Element =
  contramapComponent(
    <Props,>(
      props: Omit<Props, "data-cy"> & WithTestIdProps,
    ): Enriched<Props> => {
      if (props.testId !== undefined) {
        /**
         * TODO: there is a potential bug here - if `testId` is present but undefined,
         * it will be passed through unaltered - which can result in it being applied to DOM elements
         * causing this error:
         * `React does not recognize the `testId` prop on a DOM element.`
         * We probably want to check if `'testId' in props` but I don't know if this is a safe change
         * at the moment - it probably is, but needs double checking!
         * */

        const {testId, ...rest} = props
        return {...rest, "data-cy": testId} as unknown as Enriched<Props>
      }
      return props as Enriched<Props>
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) as unknown as any
