import {Button} from "@hortis/ui/button"
import {useAccessRole} from "src/features/permissions/use-access-role"
import {MapOverlay} from "./map-overlay"

export const NoInitialViewMapOverlay = ({
  setInitialView,
}: {
  setInitialView: () => void
}) => {
  const {canEdit} = useAccessRole()
  return (
    <MapOverlay className="pointer-events-auto flex items-center justify-center backdrop-blur">
      <div className="flex max-w-sm flex-col gap-6 rounded-xl bg-white p-6 text-base shadow-lg">
        <div className="space-y-2">
          <div className="font-medium text-grey-900">No initial view</div>
          <p className="text-sm text-grey-500" data-cy="no-initial-view-body">
            This site does not have an initial view.
            {canEdit && " Set one to begin mapping."}
          </p>
        </div>
        {canEdit && (
          <Button
            variant="primary"
            className="self-end"
            onClick={setInitialView}
            testId="set-initial-view-button"
            size="sm"
          >
            Set initial view
          </Button>
        )}
      </div>
    </MapOverlay>
  )
}
