import * as A from "fp-ts/Array"
import {memo, useCallback} from "react"
import type {FieldError} from "react-hook-form"
import {useController, useFormContext} from "react-hook-form"
import {colors} from "src/colors"
import {shadows} from "src/shadows"
import {applyMui} from "../../utils/apply-mui"
import {useBindCallback} from "../../utils/hooks/callback"
import {contramapComponent} from "../../utils/profunctor"
import type {SxProps} from "../../utils/sx"
import {assignSxProps, fromSxFn, fromSxValues} from "../../utils/sx"
import {Box} from "../box"
import type {ChipProps} from "../chip/chip"
import {Chip} from "../chip/chip"
import {FormControl} from "../form-control"
import {OptionallyShowHelperText} from "../form-helper-text"
import {Typography} from "../typography"

export enum ChipGridStylingPreset {
  RESPONSIVE_GRID,
  BASIC_GRID,
  FLEX_WRAP,
}

const chipGridStylePresets: Record<ChipGridStylingPreset, SxProps> = {
  [ChipGridStylingPreset.RESPONSIVE_GRID]: fromSxValues({
    display: "grid",
    gridTemplateColumns: {
      xs: "repeat(1, 1fr)",
      sm: "repeat(2, 1fr)",
      md: "repeat(4, 1fr)",
    },
  }),
  [ChipGridStylingPreset.BASIC_GRID]: fromSxValues({
    display: "grid",
    gridTemplateColumns: `repeat(auto-fit, minmax(180px, 1fr))`,
  }),
  [ChipGridStylingPreset.FLEX_WRAP]: fromSxValues({
    display: "flex",
    flexWrap: "wrap",
  }),
}

export type ChipGridValue<Multiple> = Multiple extends undefined | false
  ? ChipData["value"] | null
  : Array<ChipData["value"]>

interface ChipData {
  label: string
  value: string
}

interface ChipGridProps<Multiple extends boolean | undefined> {
  label?: string
  required?: boolean
  chips: Array<ChipData> | ReadonlyArray<ChipData>
  multiple?: Multiple
  sx?: SxProps
  onChange?: (value: ChipGridValue<Multiple>) => void
  onFocus?: () => void
  onBlur?: () => void
  selected?: ChipGridValue<Multiple>
  stylingPreset?: ChipGridStylingPreset
  error?: FieldError
  testId?: string
  id?: string
}

export interface ChipGridFieldProps<Multiple extends boolean | undefined>
  extends ChipGridProps<Multiple> {
  name: string
  initialValue?: ChipGridValue<Multiple>
}

const defaultEventHandler = () => {
  return undefined
}

const defaultOnChange = () => {
  return null
}

const chipFormatting = fromSxValues({
  flex: 1,
  flexBasis: "125px",
  "&:active": {boxShadow: 0},
  transition: "background-color 100ms ease, color 100ms ease",
  borderRadius: "8px",
  paddingY: "10px",
  height: "auto",
  border: `1px solid ${colors.grey[300]}`,
  boxShadow: `${shadows.xs}`,
  fontWeight: 500,
  "&.MuiChip-filled": {
    backgroundColor: `${colors.primary[50]}`,
    border: `1px solid ${colors.primary[300]}`,
    color: `${colors.primary[700]}`,
  },
  "&.MuiChip-root:not(.MuiChip-filled):focus-visible": {
    backgroundColor: colors.white,
  },
  "&.MuiChip-root:focus-visible": {
    border: `1px solid ${colors.primary[300]}`,
    boxShadow: `0 0 0 4px ${colors.primary[100]}`,
  },
})

const valuedChip = <Value,>() =>
  contramapComponent(function useContramap({
    value,
    onClick,
    ...rest
  }: Omit<ChipProps, "sx" | "onClick"> & {
    onClick: (value: Value) => void
    value: Value
    name?: string
  }): ChipProps {
    return {
      ...rest,
      sx: chipFormatting,
      onClick: useBindCallback(onClick, value),
    }
  })

const StringValuedChip = applyMui(valuedChip<string>(), Chip)

const DisplayError = memo(function MemoisedDisplayError({
  message,
}: {
  message: string | undefined
}): JSX.Element {
  return (
    <div>
      <OptionallyShowHelperText message={message} />
    </div>
  )
})

const requiredBoxWarning = <span className="text-orange-600">*</span>

const Title = memo(function MemoisedTitle({
  title,
  required,
}: {
  title: string
  required: boolean
}): JSX.Element {
  return (
    <Typography variant="body2" color={"text.primary"} fontWeight="500">
      {required ? <>{requiredBoxWarning} </> : null}
      {title}
    </Typography>
  )
})

const boxStyle = fromSxFn((theme) => ({
  display: "flex",
  gap: theme.spacing(1, 1.5),
  padding: `0`,
  gridTemplateColumns: `repeat(auto-fit, minmax(200px, 1fr))`,
}))

const emptyArray: Array<string> = []
export const ChipGrid = (props: ChipGridProps<boolean>) => {
  const {
    label,
    chips,
    sx,
    multiple = false,
    selected = multiple ? emptyArray : null,
    onChange = defaultOnChange,
    onBlur = defaultEventHandler,
    onFocus = defaultEventHandler,
    required = false,
    error,
    id,
    stylingPreset,
    testId: dataCy,
  } = props

  const hasError = Boolean(error)

  const handleChange = useCallback(
    (value: ChipData["value"]): void => {
      if (multiple && Array.isArray(selected)) {
        const newSelected = [...selected]
        const idx = newSelected.indexOf(value)
        if (idx === -1) {
          onChange([...newSelected, value])
        } else {
          onChange(A.unsafeDeleteAt(idx, selected))
        }
      } else if (selected === value) {
        onChange(null)
      } else {
        onChange(value)
      }
    },
    [onChange, selected, multiple],
  )

  // TODO: Add aria keyboard controls / labels
  return (
    <FormControl
      error={hasError}
      fullWidth
      id={id}
      className="flex flex-col gap-1.5"
    >
      {label === undefined ? null : <Title title={label} required={required} />}
      <Box
        sx={assignSxProps(
          boxStyle,
          assignSxProps(
            stylingPreset == null ? {} : chipGridStylePresets[stylingPreset],
            sx,
          ),
        )}
        data-cy={dataCy}
        onBlur={onBlur}
        onFocus={onFocus}
      >
        {chips.map((chipProps) => {
          const chipSelected = Array.isArray(selected)
            ? selected.includes(chipProps.value)
            : selected === chipProps.value
          return (
            <StringValuedChip
              key={`chip-grid-${chipProps.label}`}
              label={chipProps.label}
              color={chipSelected ? "primary" : "default"}
              variant={chipSelected ? "filled" : "outlined"}
              onClick={handleChange}
              value={chipProps.value}
            />
          )
        })}
      </Box>
      <DisplayError message={error?.message} />
    </FormControl>
  )
}

// best we can do because of limitations of react hook form
// and generic string 'literals'
type FormValues = {[Key in string]?: string | Array<string> | null}

export const FormChipGrid = (
  props: ChipGridFieldProps<boolean>,
): JSX.Element => {
  const {name, initialValue} = props

  const {control, clearErrors} = useFormContext<FormValues>()

  const {
    fieldState: {error},
    field: {onChange, onBlur, value},
  } = useController<FormValues>({
    name,
    control,
    defaultValue: initialValue,
  })

  const resetError = useBindCallback(clearErrors, name)

  return (
    <ChipGrid
      {...props}
      id={name}
      selected={value}
      onChange={onChange}
      onFocus={resetError}
      onBlur={onBlur}
      error={error}
    />
  )
}
