import {useMediaQuery} from "@hortis/ui/hooks/media-query"
import {Link} from "@tanstack/react-router"
import type {RowData, Table} from "@tanstack/table-core"
import {type TaxaListFieldsFragment} from "generated/graphql"
import {forwardRef, type ForwardedRef, type ReactElement} from "react"
import {List} from "src/components"
import TaxonRecordCard from "src/features/records/components/new-record-card/variants/taxon-record-card"

export interface TaxaMobileListProps<TData extends RowData> {
  selectMode: boolean
  table: Table<TData>
  emptyState?: ReactElement
  testIdPrefix?: string
}

export const TaxaMobileList = forwardRef(function TaxaMobileListInner<
  TData extends TaxaListFieldsFragment,
>(
  {selectMode, table, emptyState, testIdPrefix}: TaxaMobileListProps<TData>,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const isNotMobile = useMediaQuery("sm")
  return (
    <List.List
      items={table.getRowModel().rows}
      className="px-0 py-1 md:grid md:grid-cols-2"
      emptyState={emptyState}
      ref={ref}
    >
      {table.getRowModel().rows.map(({original: taxon, ...row}) => {
        const content = (
          <TaxonRecordCard
            scientificName={taxon.scientificName}
            iucnRedListStatus={
              taxon.sharedScientificName?.taxon?.globalRedListStatus
            }
            commonName={taxon.commonName}
            size={isNotMobile ? "md" : "sm"}
            variant="Row"
            selected={row.getIsSelected()}
          />
        )

        return selectMode ? (
          <button
            key={taxon.id}
            type="button"
            className="w-full text-left"
            onClick={() => {
              row.toggleSelected()
            }}
            data-cy={
              testIdPrefix == null ? "list-row" : `${testIdPrefix}-list-row`
            }
          >
            {content}
          </button>
        ) : (
          <Link
            key={taxon.id}
            to="/taxonomy/$taxonId"
            params={{
              taxonId: taxon.id,
            }}
            data-cy={
              testIdPrefix == null ? "list-row" : `${testIdPrefix}-list-row`
            }
          >
            {content}
          </Link>
        )
      })}
    </List.List>
  )
})
