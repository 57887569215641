import {z} from "zod"

const tab = z
  .enum(["material", "photos", "related"])
  .optional()
  .catch("material")
export type MaterialTab = z.infer<typeof tab>

export const materialRouteSchema = z.object({
  tab,
})
