import * as RA from "fp-ts/ReadonlyArray"
import {areEqual} from "react-window"
import type {FC} from "react"
import {memo} from "react"
import {pipe} from "fp-ts/function"
import * as O from "fp-ts/Option"
import type {ListChildComponentProps} from "react-window"
import type {RecordTagFieldsFragment} from "generated/graphql"

type Tags = {
  tags: ReadonlyArray<RecordTagFieldsFragment>
  canEdit: boolean
}

export type TagRowProps = Omit<Tags, "tags"> & {tag: Tags["tags"][0]}

export function renderTagRowWithComponent(
  Component: FC<React.PropsWithChildren<TagRowProps>>,
) {
  return memo(function MemoisedDisplayLocationRow({
    index,
    style,
    data,
  }: ListChildComponentProps<Tags>) {
    return pipe(
      data.tags,
      RA.lookup(index),
      O.map((tag) => (
        <span style={style} key={index}>
          <Component key={index} tag={tag} canEdit={data.canEdit} />
        </span>
      )),
      O.toNullable,
    )
  }, areEqual)
}
