import {Badge} from "@hortis/ui/badge"
import {Button, IconButton} from "@hortis/ui/button"
import {Dialog, DialogTrigger} from "@hortis/ui/dialog"
import {Plus} from "@hortis/ui/icons"
import {A, pipe} from "@mobily/ts-belt"
import {type AxiosProgressEvent} from "axios"
import {
  type MaterialFieldsFragment,
  type MaterialImageFieldsFragment,
} from "generated/graphql"
import {useCallback, useEffect, useState} from "react"
import {RecordPhotosDialog} from "src/components/dialog/variants/record-photos"
import {UploadPhotosDialog} from "src/components/dialog/variants/upload-photos"
import {
  RecordSection,
  RecordSectionContent,
  RecordSectionHeader,
  RecordSectionHeaderButtons,
  RecordSectionTitle,
} from "src/features/records/components/record-section/new-record-section"
import {PhotoCard} from "src/features/records/material/components/material-images-tab/photo-card"
import {
  type LocalImage,
  type StagedImage,
} from "src/features/records/material/components/material-images-tab/types"
import {ImageDropzone} from "./new-image-dropzone"

interface MaterialRecordPhotosProps {
  material: MaterialFieldsFragment
  refetchMaterial: () => void
  setUploadDialogOpen: (open: boolean) => void
  uploadDialogOpen: boolean
  canEdit: boolean
}

export const MaterialRecordPhotos = ({
  canEdit,
  material,
  refetchMaterial,
  uploadDialogOpen,
  setUploadDialogOpen,
}: MaterialRecordPhotosProps) => {
  const [images, setImages] = useState<Array<StagedImage>>([])
  const [viewAllPhotos, setViewAllPhotos] = useState(false)

  const photos = pipe(
    [...(material.images ?? []), ...images.map((image) => image.savedImage)],
    A.filter((image): image is MaterialImageFieldsFragment => image != null),
  )

  const handleProgress = useCallback(
    // eslint-disable-next-line sonarjs/cognitive-complexity
    (e: AxiosProgressEvent, imageId: string) => {
      const progressPercent =
        e.total == null ? 0 : Math.floor((e.loaded / e.total) * 100)

      setImages((x) =>
        x.map((image) => {
          if (image.id === imageId) {
            /*
             * An attempt to throttle uploads that have large chunks relative
             * to the total file size and a slow connection.
             * This reduced the UX as the progress jumps straight to say 80%
             * due to the large chunk and then halts because of the slow connection
             */
            if (
              (image.progress == null || image.progress === 0) &&
              progressPercent > 50
            ) {
              setTimeout(() => {
                setImages((x) =>
                  x.map((image) =>
                    image.localId === imageId
                      ? {
                          ...image,
                          progress:
                            // Make sure we're not going to lower the progress
                            image.progress != null &&
                            image.progress > progressPercent
                              ? image.progress
                              : progressPercent,
                        }
                      : image,
                  ),
                )
              }, 1250)

              // Make initial progress by half of the initial large progress percent
              return {...image, progress: Math.floor(progressPercent / 2)}
            }
            // If the first chunk is below 50% progress, continue as normal
            return {...image, progress: progressPercent}
          }
          return image
        }),
      )
    },
    [setImages],
  )

  const handleError = useCallback(
    (error: string, imageId: string) => {
      setImages((x) =>
        x.map((image) =>
          image.localId === imageId ? {error, ...image} : image,
        ),
      )
    },
    [setImages],
  )

  const handleComplete = useCallback(
    (photo: MaterialImageFieldsFragment, imageId: string) => {
      setImages((x) =>
        x.map(
          (image): LocalImage =>
            image.localId === imageId
              ? {
                  ...image,
                  savedImage: photo,
                  id: photo.id,
                  progress: 100,
                }
              : image,
        ),
      )

      // Wait to set 'complete' so that the progress can animate to 100%
      setTimeout(() => {
        setImages((x) =>
          x.map(
            (image): LocalImage =>
              image.localId === imageId
                ? {
                    ...image,
                    complete: true,
                  }
                : image,
          ),
        )
      }, 400)
      refetchMaterial()
    },
    [setImages, refetchMaterial],
  )

  const setImagesFromDialog = useCallback(
    (images: Array<StagedImage>) => {
      setUploadDialogOpen(false)
      setImages(images)
    },
    [setUploadDialogOpen],
  )

  const openUploadPhotoDialog = useCallback(() => {
    setUploadDialogOpen(true)
  }, [setUploadDialogOpen])

  // Revaldiate material data on mount
  useEffect(() => {
    refetchMaterial()
  }, [refetchMaterial])

  useEffect(() => {
    // Update any stale local photos
    if (
      material.images != null &&
      material.images.length > 0 &&
      images.length > 0
    ) {
      setImages((x) =>
        x.map((localPhoto) => {
          const newPhoto = material.images?.find(({id}) => id === localPhoto.id)

          return newPhoto == null
            ? localPhoto
            : {...localPhoto, savedImage: newPhoto}
        }),
      )
    }
  }, [material, images.length])

  return (
    <RecordSection id="photos">
      <RecordSectionHeader>
        <RecordSectionTitle>Photos</RecordSectionTitle>
        {material.images && material.images.length > 0 && (
          <>
            <Badge color="primary" testId="material-photos-count">
              {material.images.length}
            </Badge>
            <RecordSectionHeaderButtons>
              <Dialog open={viewAllPhotos} onOpenChange={setViewAllPhotos}>
                <DialogTrigger asChild>
                  <Button
                    data-cy="view-all-photos-button"
                    size="xs"
                    variant="tertiaryGray"
                  >
                    View all
                  </Button>
                </DialogTrigger>
                <RecordPhotosDialog
                  setOpen={setViewAllPhotos}
                  openUploadDialog={openUploadPhotoDialog}
                  material={material}
                  canEdit={canEdit}
                />
              </Dialog>
              {canEdit && !material.archived && (
                <IconButton
                  onClick={openUploadPhotoDialog}
                  ariaLabel="Add new image"
                  data-cy="add-new-image-button"
                  size="xs"
                  variant="tertiaryGray"
                  icon={Plus}
                />
              )}
            </RecordSectionHeaderButtons>
          </>
        )}
      </RecordSectionHeader>

      <RecordSectionContent>
        {(material.images != null && material.images.length > 0) ||
        images.length > 0 ? (
          <div className="photo-grid grid grid-cols-2 gap-3 sm:grid-cols-4">
            {material.images
              ?.filter((image) => !images.map(({id}) => id).includes(image.id))
              .slice(0, 4)
              .map((image) => {
                return (
                  <PhotoCard
                    materialImage={{type: "external", ...image}}
                    key={image.id}
                    featured={image.isPrimaryImage === true}
                    scientificName={
                      material.accession?.scientificName ?? undefined
                    }
                    photos={photos}
                  />
                )
              })}

            {images.map((image) => (
              <PhotoCard
                materialImage={{type: "local", ...image}}
                key={image.localId}
                featured={image.savedImage?.isPrimaryImage === true}
                scientificName={material.accession?.scientificName ?? undefined}
                photos={photos}
              />
            ))}
          </div>
        ) : (
          <>
            {canEdit && !material.archived ? (
              <div data-cy="empty-dropzone" className="flex h-[164px]">
                <ImageDropzone
                  materialId={material.id}
                  images={images}
                  setImages={setImages}
                  handleProgress={handleProgress}
                  handleComplete={handleComplete}
                  handleError={handleError}
                />
              </div>
            ) : (
              <div className="flex flex-col">
                <p className="text-base font-medium text-grey-900">No photos</p>
                <p className="text-sm font-normal text-grey-500">
                  This material does not have any photos yet.
                </p>
              </div>
            )}
          </>
        )}
      </RecordSectionContent>
      <UploadPhotosDialog
        open={uploadDialogOpen}
        setOpen={setUploadDialogOpen}
        materialId={material.id}
        images={images}
        setImages={setImagesFromDialog}
        handleProgress={handleProgress}
        handleComplete={handleComplete}
        handleError={handleError}
      />
    </RecordSection>
  )
}
