import {css} from "@emotion/react"
import {type ChangeEvent, type HTMLProps} from "react"
import {twMerge} from "tailwind-merge"
import {colors} from "../colors"

const styles = css`
  &::before {
    opacity: 0;
    width: 20px;
    height: 20px;
    transform: scale(0);
    transform-origin: center;
    background-repeat: no-repeat;
    background-position: center;
    content: "";
    transition: 100ms all ease;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='9' viewBox='0 0 12 9' fill='none'><path d='M10.6654 1.5L4.2487 7.91667L1.33203 5' stroke='%230E9384' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/></svg>");
  }

  &:disabled::before {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='9' viewBox='0 0 12 9' fill='none'><path d='M10.6654 1.5L4.2487 7.91667L1.33203 5' stroke='%23D2D6DB' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/></svg>");
  }

  &:not(.indeterminate):checked::before {
    opacity: 1;
    transform: scale(1);
  }

  &.indeterminate {
    opacity: 1;
    transform: scale(1);
    width: 20px;
    height: 20px;
    transition: 100ms all ease;
    border: 1px solid ${colors.primary[600]};
    background-color: ${colors.primary[50]};
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%' viewBox='0 0 1 1'><line x1='0.25' y1='0.5' x2='0.75' y2='0.5' stroke='%230E9384' stroke-width='0.1' stroke-linecap='round'/></svg>");
  }

  &.indeterminate:disabled {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%' viewBox='0 0 1 1'><line x1='0.25' y1='0.5' x2='0.75' y2='0.5' stroke='%23D2D6DB' stroke-width='0.1' stroke-linecap='round'/></svg>");
    border: 1px solid ${colors.grey[300]};
    background-color: ${colors.grey[100]};
  }
`

export type CheckboxProps = Omit<HTMLProps<HTMLInputElement>, "onChange"> & {
  checked: boolean
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  ariaLabel?: string
  indeterminate?: boolean
}

export const Checkbox = ({
  ariaLabel,
  checked,
  className,
  indeterminate,
  ...props
}: CheckboxProps) => {
  return (
    <input
      type="checkbox"
      css={styles}
      aria-label={ariaLabel}
      checked={checked}
      className={twMerge(
        "border-grey-300 ring-primary-100 checked:border-primary-600 checked:bg-primary-50 hover:border-primary-600 hover:bg-primary-100 disabled:border-grey-300 disabled:bg-grey-100 [&:not(:checked,:hover):focus-visible]:border-primary-300 relative m-0 grid h-5 w-5 flex-shrink-0 cursor-pointer appearance-none place-content-center rounded-[6px] border bg-white outline-none transition-all duration-100 focus-visible:ring-4 disabled:cursor-not-allowed",
        className,
        indeterminate === true ? "indeterminate" : "",
      )}
      {...props}
    />
  )
}
