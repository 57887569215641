export const createFullName = ({
  givenName,
  familyName,
}: {
  givenName?: string | null | undefined
  familyName?: string | null | undefined
}) =>
  givenName == null
    ? null
    : familyName == null
    ? givenName
    : `${givenName} ${familyName}`
