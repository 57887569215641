import {
  Alert,
  AlertContent,
  AlertSupportText,
  AlertText,
} from "@hortis/ui/alert"
import {
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuSeparator,
  useContextMenuCloseFix,
} from "@hortis/ui/context-menu"
import {
  AlertTriangle,
  ArrowUpRight,
  Download1,
  Edit4,
  Eye,
  EyeOff,
  Route,
  Tag1,
  Trash3,
} from "@hortis/ui/icons"
import {Browser} from "@hortis/ui/icons/browser"
import {CalendarPlus2} from "@hortis/ui/icons/calendar-plus-2"
import {Coins3} from "@hortis/ui/icons/coins-3"
import {CircularProgress} from "@hortis/ui/progress"
import {useParams, useRouteContext} from "@tanstack/react-router"
import {
  type MapMaterialListFieldsFragment,
  MaterialGroup,
  type MaterialsListFieldsFragment,
  type PlantMaterialFilter,
  type PlantMaterialsSearchTerm,
  PlantMaterialStatus,
  useAddTagToMaterialsMutation,
  useGetMaterialQuery,
  usePlantMaterialsQuery,
  useRemoveTagFromMaterialsMutation,
} from "generated/graphql"
import {useCallback, useState} from "react"
import {MakePrivateDialog} from "src/components/command-menu/command-menu-pages/make-private-dialog"
import {MakePublicDialog} from "src/components/command-menu/command-menu-pages/make-public-dialog"
import {ObservationDialog} from "src/components/command-menu/command-menu-pages/observation"

import {Sheet, SheetContent} from "@hortis/ui/sheet"
import {useSnackbarStore} from "src/components/snackbar-controller/snackbar-store"
import {TrashMaterialsDialog} from "src/features/collection/components/archive-records/trash-materials-dialog"
import {useDownloadCsv} from "src/features/collection/components/download-accessions/use-download-csv"
import {createMaterialNumber} from "src/features/collection/components/plant-materials/material-number"
import {useAccessRole} from "src/features/permissions/use-access-role"
import {EditMaterialSheetForm} from "src/features/records/material/components/edit-drawer/edit-details"
import {onFailure, onSuccess} from "src/notification-snack-utils"
import {MaterialCopyContextMenuSub} from "../copy-submenu/variants/material-copy-context-menu-sub"
import {LocationDialog} from "../dialogs/location-dialog"
import {StatusDialog} from "../dialogs/status-dialog"
import {CollectionSiteTagsDialog} from "../dialogs/tags-dialog"

interface MaterialsContextMenuProps {
  onRequestDownload?: () => void
  isTrash: boolean
  selectedRecordsIDs: ReadonlyArray<string>
  clickedRow: MaterialsListFieldsFragment | MapMaterialListFieldsFragment
  matSearch: PlantMaterialsSearchTerm | undefined
  preparedFilters: PlantMaterialFilter
}

const MaterialsContextMenuContent = ({
  selectedRecordsIDs,
  isTrash,
  clickedRow,
  onRequestDownload,
  matSearch,
  preparedFilters, // eslint-disable-next-line sonarjs/cognitive-complexity
}: MaterialsContextMenuProps) => {
  const [observationDialogOpen, setObservationDialogOpen] = useState(false)
  const [makePublicDialogOpen, setMakePublicDialogOpen] = useState(false)
  const [makePrivateDialogOpen, setMakePrivateDialogOpen] = useState(false)
  const [trashDialogOpen, setTrashDialogOpen] = useState(false)
  const [tagsDialogOpen, setTagsDialogOpen] = useState(false)
  const [statusDialogOpen, setStatusDialogOpen] = useState(false)
  const [locationDialogOpen, setLocationDialogOpen] = useState(false)
  const [editOpen, setEditOpen] = useState(false)

  // eslint-disable-next-line sonarjs/no-duplicate-string
  const {siteSlug} = useParams({from: "/_layout/sites/$siteSlug"})
  const {subdomain} = useRouteContext({from: "/_layout/sites/$siteSlug"})

  const [{data, error, fetching}] = usePlantMaterialsQuery({
    variables: {
      organisationSubdomain: subdomain,
      collectionSiteSlug: siteSlug,
      last: selectedRecordsIDs.length,
      filters: {
        id: {
          in: selectedRecordsIDs,
        },
      },
    },
  })

  const selectedRows = data?.org?.site?.result?.nodes ?? []
  const selectedMaterials =
    selectedRows.length > 0 ? selectedRows : [clickedRow]

  const {setSnack} = useSnackbarStore()
  const {canEdit} = useAccessRole()

  const {handleClose} = useContextMenuCloseFix()

  const showEditDrawer = selectedRows.length <= 1

  // We show the make public option if there are multiple selected rows or if the clicked row is not public
  const showMakePublic =
    selectedRows.length > 1 ||
    (!clickedRow.public &&
      clickedRow.materialGroup === MaterialGroup.Plant &&
      clickedRow.status === PlantMaterialStatus.Present)

  // We show the make private option if there are multiple selected rows or if the clicked row is public
  const showMakePrivate = selectedRows.length > 1 || clickedRow.public

  const [_, addTagToMaterials] = useAddTagToMaterialsMutation()
  const [__, removeTagFromMaterials] = useRemoveTagFromMaterialsMutation()

  const applyTagToSelectedRecords = useCallback(
    async (tagId: string) => {
      const res = await addTagToMaterials({
        tagId,
        ids:
          selectedRecordsIDs.length > 0 ? selectedRecordsIDs : [clickedRow.id],
      })

      const addTagSuccessful = res.data?.addTagPlantMaterials.success ?? false
      if (addTagSuccessful) {
        onSuccess(setSnack)(
          `Tag added to ${
            selectedRecordsIDs.length > 0
              ? `${selectedRecordsIDs.length}materials`
              : "material"
          }`,
        )
      } else {
        onFailure(setSnack)(new Error("Failed to add tag to materials"))
      }
      return addTagSuccessful
    },

    [addTagToMaterials, clickedRow.id, selectedRecordsIDs, setSnack],
  )

  const onRequestSingleDownload = useDownloadCsv({
    csvName: "plant-materials",
    path: "/download/materials",
    body: {
      searchTerm: matSearch,
      filters: {
        ...preparedFilters,
        id: {
          eq: clickedRow.id,
        },
      },
    },
  })

  const removeTagFromSelectedRecords = useCallback(
    async (tagId: string) => {
      const res = await removeTagFromMaterials({
        tagId,
        ids:
          selectedRecordsIDs.length > 0 ? selectedRecordsIDs : [clickedRow.id],
      })

      const removeTagSuccessful =
        res.data?.removeTagPlantMaterials.success ?? false

      if (removeTagSuccessful) {
        onSuccess(setSnack)(
          `Tag removed from ${
            selectedRecordsIDs.length > 0
              ? `${selectedRecordsIDs.length}materials`
              : "material"
          }`,
        )
      } else {
        onFailure(setSnack)(new Error("Failed to remove tag from materials"))
      }
      return removeTagSuccessful
    },
    [clickedRow.id, removeTagFromMaterials, selectedRecordsIDs, setSnack],
  )

  return (
    <>
      <ContextMenuContent data-cy="material-list-context-menu">
        {!isTrash && canEdit && (
          <>
            <ContextMenuItem
              onSelect={() => {
                setObservationDialogOpen(true)
              }}
              data-cy="makeObservation-menu-item"
            >
              <CalendarPlus2 className="h-4 w-4 text-grey-500" />
              Make observation
            </ContextMenuItem>
            <ContextMenuItem
              onSelect={() => {
                setTagsDialogOpen(true)
              }}
              data-cy="tags-menu-item"
            >
              <Tag1 className="h-4 w-4 text-grey-500" />
              Add / remove tags
            </ContextMenuItem>
            <ContextMenuItem
              onSelect={() => {
                setLocationDialogOpen(true)
              }}
              data-cy="location-menu-item"
            >
              <Route className="h-4 w-4 text-grey-500" />
              Change location
            </ContextMenuItem>
            <ContextMenuItem
              onSelect={() => {
                setStatusDialogOpen(true)
              }}
              data-cy="status-menu-item"
            >
              <Coins3 className="h-4 w-4 text-grey-500" />
              Change status
            </ContextMenuItem>
            <>
              {(showMakePrivate || showMakePublic) && <ContextMenuSeparator />}
              {showMakePublic && (
                <ContextMenuItem
                  onSelect={() => {
                    setMakePublicDialogOpen(true)
                  }}
                  data-cy="make-public-menu-item"
                >
                  <Eye className="h-4 w-4 text-grey-500" />
                  Make Public
                </ContextMenuItem>
              )}
              {showMakePrivate && (
                <ContextMenuItem
                  onSelect={() => {
                    setMakePrivateDialogOpen(true)
                  }}
                  data-cy="make-private-menu-item"
                >
                  <EyeOff className="h-4 w-4 text-grey-500" />
                  Make Private
                </ContextMenuItem>
              )}
            </>
          </>
        )}
        {canEdit && <ContextMenuSeparator />}
        {selectedRows.length <= 1 && !isTrash && canEdit && (
          <>
            <ContextMenuItem
              onSelect={() => {
                setEditOpen(true)
              }}
              data-cy="edit-menu-item"
            >
              <Edit4 className="h-4 w-4 text-grey-500" />
              Edit
            </ContextMenuItem>
            <ContextMenuSeparator />
          </>
        )}

        {onRequestDownload != null && !isTrash && (
          <>
            <ContextMenuItem
              onSelect={() => {
                if (selectedRows.length > 0) {
                  onRequestDownload()
                } else {
                  void onRequestSingleDownload()
                }
              }}
              data-cy="download-menu-item"
            >
              <Download1 className="h-4 w-4 text-grey-500" />
              Download
            </ContextMenuItem>
            <ContextMenuSeparator />
          </>
        )}
        {selectedMaterials.length > 0 && (
          <MaterialCopyContextMenuSub materials={selectedMaterials} />
        )}

        {!isTrash && canEdit && (
          <>
            <ContextMenuSeparator />
            <ContextMenuItem
              onSelect={() => {
                setTrashDialogOpen(true)
              }}
              data-cy="trash-menu-item"
            >
              <Trash3 className="h-4 w-4 text-grey-500" />
              Move to trash
            </ContextMenuItem>
          </>
        )}

        {selectedRows.length <= 1 && (
          <>
            <ContextMenuSeparator />
            <ContextMenuItem
              onClick={() => {
                window.open(
                  `/sites/${siteSlug}/materials/${createMaterialNumber(
                    clickedRow.accession?.accessionNumber ?? "",
                    clickedRow.qualifier,
                  )}`,
                  "_blank",
                )
              }}
              data-cy="open-new-tab-menu-item"
            >
              <ArrowUpRight className="h-4 w-4 text-grey-500" />
              Open in new tab
            </ContextMenuItem>
            <ContextMenuItem
              onClick={() => {
                window.open(
                  `/sites/${siteSlug}/materials/${createMaterialNumber(
                    clickedRow.accession?.accessionNumber ?? "",
                    clickedRow.qualifier,
                  )}`,
                  "_blank",
                  "width=full,height=full",
                )
              }}
              data-cy="open-new-window-menu-item"
            >
              <Browser className=" h-4 w-4 text-grey-500" />
              Open in new window
            </ContextMenuItem>
          </>
        )}
      </ContextMenuContent>
      <ObservationDialog
        open={observationDialogOpen}
        onOpenChange={(open) => {
          setObservationDialogOpen(open)
          if (!open) {
            handleClose()
          }
        }}
        onSuccess={() => {
          setObservationDialogOpen(false)
          handleClose()
        }}
        selectedMaterials={selectedMaterials}
      />
      <MakePublicDialog
        open={makePublicDialogOpen}
        onOpenChange={(open) => {
          setMakePublicDialogOpen(open)
          if (!open) {
            handleClose()
          }
        }}
        onSuccess={() => {
          setMakePublicDialogOpen(false)
          handleClose()
        }}
        selectedMaterials={selectedMaterials}
      />
      <MakePrivateDialog
        open={makePrivateDialogOpen}
        onOpenChange={(open) => {
          setMakePrivateDialogOpen(open)
          if (!open) {
            handleClose()
          }
        }}
        onSuccess={() => {
          setMakePrivateDialogOpen(false)
          handleClose()
        }}
        selectedMaterials={selectedMaterials}
      />
      <TrashMaterialsDialog
        open={trashDialogOpen}
        setOpen={(open) => {
          setTrashDialogOpen(open)
          if (!open) {
            handleClose()
          }
        }}
        materialsIds={
          selectedRecordsIDs.length > 0 ? selectedRecordsIDs : [clickedRow.id]
        }
        onSuccess={() => {
          setTrashDialogOpen(false)
          handleClose()
        }}
      />
      <CollectionSiteTagsDialog
        query={{data, error, fetching}}
        selectedRows={selectedRows}
        title={
          selectedRows.length > 1
            ? `${selectedRows.length} materials selected`
            : `${clickedRow.accession?.accessionNumber ?? ""}/${
                clickedRow.qualifier
              }`
        }
        open={tagsDialogOpen}
        onOpenChange={(open) => {
          setTagsDialogOpen(open)
          if (!open) {
            handleClose()
          }
        }}
        applyTagToSelectedRecords={applyTagToSelectedRecords}
        removeTagFromSelectedRecords={removeTagFromSelectedRecords}
      />

      <StatusDialog
        selectedRows={selectedMaterials}
        open={statusDialogOpen}
        onOpenChange={(open) => {
          setStatusDialogOpen(open)
          if (!open) {
            handleClose()
          }
        }}
        title={
          selectedRows.length > 1
            ? `${selectedRows.length} materials selected`
            : `${clickedRow.accession?.accessionNumber ?? ""}/${
                clickedRow.qualifier
              }`
        }
        handleSuccess={() => {
          setStatusDialogOpen(false)
          handleClose()
        }}
      />

      <LocationDialog
        selectedRows={selectedMaterials}
        open={locationDialogOpen}
        onOpenChange={(open) => {
          setLocationDialogOpen(open)
          if (!open) {
            handleClose()
          }
        }}
        title={
          selectedRows.length > 1
            ? `${selectedRows.length} materials selected`
            : `${clickedRow.accession?.accessionNumber ?? ""}/${
                clickedRow.qualifier
              }`
        }
        handleSuccess={() => {
          setLocationDialogOpen(false)
          handleClose()
        }}
      />
      {showEditDrawer && (
        <EditMaterialSheet
          open={editOpen}
          clickedRow={clickedRow}
          onOpenChange={(open) => {
            setEditOpen(open)
            if (!open) {
              handleClose()
            }
          }}
        />
      )}
    </>
  )
}

const EditMaterialSheet = ({
  open,
  onOpenChange,
  clickedRow,
}: {
  open: boolean
  onOpenChange: (open: boolean) => void
  clickedRow: MaterialsListFieldsFragment | MapMaterialListFieldsFragment
}) => {
  const {siteSlug} = useParams({from: "/_layout/sites/$siteSlug"})
  const {subdomain} = useRouteContext({from: "/_layout/sites/$siteSlug"})
  const [
    {data: materialData, error: materialError, fetching: materialFetching},
    refetchMaterial,
  ] = useGetMaterialQuery({
    variables: {
      organisationSubdomain: subdomain,
      collectionSiteSlug: siteSlug,
      accessionNumber: clickedRow.accession?.accessionNumber ?? "",
      qualifier: clickedRow.qualifier,
    },
    pause: clickedRow.accession?.accessionNumber == null || !open,
  })
  const material = materialData?.org?.site?.result

  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetContent>
        {material == null && materialFetching && (
          <div className="flex h-full w-full items-center justify-center">
            <CircularProgress className="h-5 w-5 text-primary-500" />
          </div>
        )}
        {material == null && materialError != null && (
          <div className="p-3">
            <Alert
              color="warning"
              className="mb-4"
              data-cy="material-edit-alert"
            >
              <AlertTriangle />
              <AlertContent>
                <AlertText>An error occurred</AlertText>
                <AlertSupportText>{materialError.message}</AlertSupportText>
              </AlertContent>
            </Alert>
          </div>
        )}
        {material != null && (
          <EditMaterialSheetForm
            title="Edit Material"
            subtitle={createMaterialNumber(
              clickedRow.accession?.accessionNumber ?? "",
              clickedRow.qualifier,
              "/",
            )}
            material={material}
            requestClose={() => {
              onOpenChange(false)
            }}
            onSubmit={refetchMaterial}
            open={open}
          />
        )}
      </SheetContent>
    </Sheet>
  )
}

export {MaterialsContextMenuContent}
