import {A, D, pipe} from "@mobily/ts-belt"
import CircularProgress from "@mui/material/CircularProgress"
import {type RowData, type Table} from "@tanstack/react-table"
import {type RefObject} from "react"
import {colors} from "src/colors"
import {useAccessRole} from "src/features/permissions/use-access-role"
import {useOperatingSystemDetect} from "src/utils/hooks/os-detect"
import {twMerge} from "tailwind-merge"
import {ChevronLeft, ChevronRight, Download1, Trash3} from "@hortis/ui/icons"
import {Button, IconButton} from "@hortis/ui/button"
import {Select} from "../select"
import {Tooltip} from "../tooltip"

const defaultRowsPerPageOptions = [25, 50, 100] as const

export type DataTableFooterProps<TData extends RowData> = {
  table: Table<TData>
  hasNextPage?: boolean
  hasPreviousPage?: boolean
  onPreviousPage?: () => void
  onNextPage?: () => void
  onActionsMenu?: () => void
  previousPageLoading?: boolean
  nextPageLoading?: boolean
  total?: number | null
  rowsPerPageOptions?: Array<number> | ReadonlyArray<number>
  rowsPerPage?: number
  onRowsPerPageChange?: (rowsPerPage: number | null) => void
  onRequestDownload?: () => void
  onTrashRecords?: () => void
  className?: string
  testIdPrefix?: string
  fetching?: boolean
  trashButtonRef?: RefObject<HTMLButtonElement>
}

export const DataTableFooter = <TData extends RowData>({
  hasNextPage,
  hasPreviousPage,
  onPreviousPage,
  onNextPage,
  onActionsMenu,
  previousPageLoading,
  nextPageLoading,
  total,
  table,
  rowsPerPage,
  rowsPerPageOptions = defaultRowsPerPageOptions,
  onRowsPerPageChange,
  onRequestDownload,
  onTrashRecords,
  className,
  fetching,
  testIdPrefix: _testIdPrefix,
  trashButtonRef,
}: DataTableFooterProps<TData>) => {
  const {canEdit} = useAccessRole()
  const {isMac} = useOperatingSystemDetect()
  const commandKey = isMac() ? "⌘" : "CTRL+"

  const totalSelected = pipe(
    table.getState().rowSelection,
    D.filterWithKey((_, value) => value),
    D.values,
    A.length,
  )
  const testIdPrefix = _testIdPrefix == null ? "" : `${_testIdPrefix}-`

  return (
    <div
      className={twMerge(
        "flex h-16 items-center justify-between gap-3 border-t border-grey-200 px-6 pb-4 pt-3",
        className,
      )}
    >
      <div className="flex items-center gap-5 text-sm font-medium text-grey-700">
        <span data-cy={`${testIdPrefix}table-total`}>
          {total ?? table.getFilteredRowModel().rows.length} total
        </span>
        {totalSelected > 0 ? (
          <div
            className="flex items-center"
            data-cy={`${testIdPrefix}table-select-mode-menu`}
          >
            <div className="border-l border-grey-200 px-5">
              {totalSelected} selected
            </div>
            <div className="flex gap-3">
              <Tooltip title="Download CSV">
                <IconButton
                  icon={Download1}
                  variant="tertiaryGray"
                  size="sm"
                  ariaLabel="Download selected rows"
                  onClick={onRequestDownload}
                  testId="download-selected-rows-button"
                />
              </Tooltip>
              {onTrashRecords && canEdit && (
                <Tooltip title="Move to trash">
                  <IconButton
                    icon={Trash3}
                    variant="tertiaryGray"
                    size="sm"
                    ariaLabel="Move selected rows to trash"
                    onClick={onTrashRecords}
                    testId="trash-selected-rows-button"
                    ref={trashButtonRef}
                  />
                </Tooltip>
              )}
              {canEdit && (
                <Button
                  onClick={onActionsMenu}
                  size={"sm"}
                  shortcut={`${commandKey}K`}
                  testId="actions-menu-button"
                  className="text-sm"
                >
                  Actions
                </Button>
              )}
            </div>
          </div>
        ) : null}
        {previousPageLoading !== true &&
          nextPageLoading !== true &&
          fetching === true && (
            <CircularProgress
              size="20px"
              sx={{color: colors.primary[500]}}
              thickness={5}
            />
          )}
      </div>
      <div className="flex items-center gap-5">
        {onRowsPerPageChange != null && rowsPerPage != null && (
          <label className="flex items-center gap-2 text-sm text-grey-500">
            Rows:{" "}
            <Select
              value={rowsPerPage}
              onChange={onRowsPerPageChange}
              buttonProps={{size: "sm"}}
              options={rowsPerPageOptions.map((row) => ({
                value: row,
                label: String(row),
              }))}
            />
          </label>
        )}
        <div className="flex items-center gap-3">
          <IconButton
            ariaLabel="Previous page"
            testId="pagination-prev-button"
            size="sm"
            icon={ChevronLeft}
            onClick={onPreviousPage}
            disabled={hasPreviousPage !== true || nextPageLoading === true}
            loading={previousPageLoading === true}
          />
          <IconButton
            ariaLabel="Next page"
            size="sm"
            testId="pagination-next-button"
            icon={ChevronRight}
            onClick={onNextPage}
            disabled={hasNextPage !== true || previousPageLoading === true}
            loading={nextPageLoading === true}
          />
        </div>
      </div>
    </div>
  )
}
