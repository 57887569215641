import type {NullableScientificNameFilter} from "generated/graphql"
import {countClassificationFilter} from "../../family-filter/count-family-filter"

export const countNullableScientificNameFilter = (
  filter: NullableScientificNameFilter,
) => {
  let count = 0
  if (filter.classification != null) {
    count += countClassificationFilter(filter.classification)
  }
  if (filter.rank != null) {
    count += 1
  }
  if (filter.status != null) {
    count += 1
  }
  if (
    filter.null != null &&
    (filter.null.eq != null || filter.null.neq != null)
  ) {
    count += 1
  }
  return count
}
