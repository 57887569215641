import type {StatusFilter} from "generated/graphql"
import {isNonEmpty} from "../../count/is-non-empty"

export const countStatusFilter = (filter: NonNullable<StatusFilter>) => {
  let count = 0
  if (isNonEmpty(filter.is)) {
    count += 1
  }
  if (isNonEmpty(filter.isNot)) {
    count += 1
  }
  return count
}
