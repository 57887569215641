import {AccessionsColumn} from "generated/graphql"
import type {AccessionsColumnIds} from "src/components/data-table/columns/accession-columns"
import type {AccessionFilterType} from "src/features/filters/accessions/types"

export const defaultColumnVisibility: Record<AccessionsColumnIds, boolean> = {
  Number: true,
  "Scientific name": true,
  "Common name": false,
  Provenance: true,
  "Material received": false,
  "Date accessioned": false,
  "Date deaccessioned": false,
  "Material status": true,
  "IUCN Red List": false,
  "Native distribution": false,
  "Life forms": false,
  Climate: false,
  "Donor / supplier": true,
  "IPEN number": false,
  "Accession tags": true,
  "Last edited": false,
  Notes: true,
  Creator: false,
  "Last editor": false,
}

export const sortColumnMap: Partial<
  Record<AccessionsColumn, AccessionsColumnIds>
> = {
  [AccessionsColumn.ScientificName]: "Scientific name",
  [AccessionsColumn.CommonName]: "Common name",
  [AccessionsColumn.AccessionNum]: "Number",
  [AccessionsColumn.DonorSupplier]: "Donor / supplier",
  [AccessionsColumn.AccessionDate]: "Date accessioned",
  [AccessionsColumn.LastUpdated]: "Last edited",
  [AccessionsColumn.ProvenanceCategoryCode]: "Provenance",
  [AccessionsColumn.GlobalRedListStatus]: "IUCN Red List",
}

export const filterColumnMap: Partial<
  Record<AccessionsColumnIds, AccessionFilterType>
> = {
  Notes: "notes",
  "Accession tags": "tags",
  Provenance: "provenance",
  "Donor / supplier": "donor",
  "Material received": "materialReceived",
  "Common name": "commonName",
  "IPEN number": "ipenNumber",
  "Date accessioned": "accessionDate",
  "Date deaccessioned": "deaccessionDate",
  "IUCN Red List": "redListStatus",
  "Native distribution": "nativeDistribution",
  "Life forms": "lifeForms",
  Climate: "climate",
  "Last editor": "lastEditor",
  Creator: "creator",
}
