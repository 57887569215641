import {colors} from "src/colors"
import type {IconProps} from "./types"

export const SlAlertCircleIcon = ({
  style,
  width,
  height,
  color,
  className,
}: IconProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width ?? "22"}
    height={height ?? "22"}
    viewBox="0 0 22 22"
    fill="none"
    style={style}
    className={className}
  >
    <path
      d="M11 15C10.934 15 10.8696 15.0195 10.8148 15.0562C10.76 15.0928 10.7172 15.1449 10.692 15.2058C10.6668 15.2667 10.6602 15.3337 10.673 15.3984C10.6859 15.463 10.7176 15.5224 10.7643 15.569C10.8109 15.6157 10.8703 15.6474 10.9349 15.6603C10.9996 15.6731 11.0666 15.6665 11.1275 15.6413C11.1884 15.6161 11.2405 15.5733 11.2771 15.5185C11.3137 15.4637 11.3333 15.3993 11.3333 15.3333C11.3333 15.2449 11.2982 15.1601 11.2357 15.0976C11.1731 15.0351 11.0884 15 11 15Z"
      fill={color ?? "#F44336"}
      stroke={color ?? "#F44336"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 12.3333V5"
      stroke={color ?? "#F44336"}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M1 11C1 13.6522 2.05357 16.1957 3.92893 18.0711C5.8043 19.9464 8.34784 21 11 21C13.6522 21 16.1957 19.9464 18.0711 18.0711C19.9464 16.1957 21 13.6522 21 11C21 8.34784 19.9464 5.8043 18.0711 3.92893C16.1957 2.05357 13.6522 1 11 1C8.34784 1 5.8043 2.05357 3.92893 3.92893C2.05357 5.8043 1 8.34784 1 11V11Z"
      stroke={color ?? "#F44336"}
      strokeWidth="1.5"
    />
  </svg>
)

export const AlertCircle = ({
  color = colors.error[500],
  ...props
}: IconProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    width="20"
    height="20"
    fill="none"
    {...props}
  >
    <path
      d="M9.99984 6.6665V9.99984M9.99984 13.3332H10.0082M18.3332 9.99984C18.3332 14.6022 14.6022 18.3332 9.99984 18.3332C5.39746 18.3332 1.6665 14.6022 1.6665 9.99984C1.6665 5.39746 5.39746 1.6665 9.99984 1.6665C14.6022 1.6665 18.3332 5.39746 18.3332 9.99984Z"
      stroke={color}
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
