import type {
  ChangeEventHandler,
  KeyboardEvent as ReactKeyboardEvent,
} from "react"
import {memo, useCallback, useRef, useState} from "react"
import {IconButton} from "@hortis/ui/button"
import {XClose} from "@hortis/ui/icons"
import {Tooltip} from "src/components/tooltip"
import {InputAdornment} from "../../../../components/adornment"
import {SlSearchIcon} from "../../../../components/icons/streamline/search"
import {fromSxValues} from "../../../../utils/sx"
import {
  SearchBorder,
  StyledInputBase,
} from "../../../collection/components/search-bar/search-bar"

interface SearchBarProps {
  value: string
  onChange: (val: string) => void
  onClearSearch: () => void
  placeholder?: string
  testId?: string
}

const searchIcon = (
  <div className="flex h-8 w-8 items-center justify-center">
    <SlSearchIcon />
  </div>
)

const inputBaseStyle = fromSxValues({paddingLeft: 1.375, paddingRight: 0.375})

const searchBarInputProps = {
  "aria-label": "search",
  spellCheck: false,
  /*
   * Change text on enter key to "search" on virtual keyboards
   *
   * https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/enterkeyhint
   */
  enterKeyHint: "search",
}

const keyCodes = new Set(["Enter"])
export const SearchBar = memo(function SearchBar({
  value,
  onChange,
  placeholder,
  onClearSearch,
  testId,
}: SearchBarProps) {
  const inputRef = useRef<HTMLInputElement>()
  const [hovered, setHovered] = useState(false)
  const [focused, setFocused] = useState(false)

  const handleSearchChange = useCallback<
    ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  >(
    (e) => {
      // TODO: Check why this focus is not working
      inputRef.current?.focus()
      onChange(e.target.value)
    },
    [onChange],
  )

  const handleMouseOver = useCallback(() => {
    setHovered(true)
  }, [])
  const handleMouseLeave = useCallback(() => {
    setHovered(false)
  }, [])
  const handleFocus = useCallback(() => {
    setFocused(true)
  }, [])
  const handleBlur = useCallback(() => {
    setFocused(false)
  }, [])

  const showClear = (hovered || focused) && value !== ""

  const handleCloseTouchDeviceKeyboard = useCallback(
    (e: ReactKeyboardEvent<HTMLInputElement>) => {
      if (keyCodes.has(e.key) && "ontouchstart" in window) {
        // focus in input when clicking clear
        inputRef.current?.blur()
      }
    },
    [],
  )
  return (
    <SearchBorder>
      <StyledInputBase
        sx={inputBaseStyle}
        value={value}
        onChange={handleSearchChange}
        type="search"
        inputRef={inputRef}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
        onKeyUp={handleCloseTouchDeviceKeyboard}
        placeholder={placeholder}
        data-cy={testId}
        inputProps={searchBarInputProps}
        endAdornment={
          <InputAdornment position="end">
            {showClear && (
              <Tooltip title="Clear">
                <IconButton
                  ariaLabel="Clear"
                  onClick={onClearSearch}
                  icon={XClose}
                  variant="tertiaryGray"
                  size="sm"
                />
              </Tooltip>
            )}
            {searchIcon}
          </InputAdornment>
        }
      />
    </SearchBorder>
  )
})
