import {useCallback, useEffect, useState} from "react"

export const useIsScrolled = () => {
  const [node, setNode] = useState<HTMLElement | null>(null)
  const [isScrolledVertical, setIsScrolledVertical] = useState(false)
  const [isScrolledHorizontal, setIsScrolledHorizontal] = useState(false)

  const attachRef = useCallback((node: HTMLElement | null) => {
    setNode(node)
  }, [])

  useEffect(() => {
    const handleScroll = (_: Event) => {
      if (node != null) {
        setIsScrolledVertical(node.scrollTop > 0)
        setIsScrolledHorizontal(node.scrollLeft > 0)
      }
    }
    node?.addEventListener("scroll", handleScroll)

    return () => {
      node?.removeEventListener("scroll", handleScroll)
    }
  }, [node])

  return {
    isScrolled: isScrolledVertical,
    isScrolledHorizontal: isScrolledHorizontal,
    attachRef,
    ref: node,
  }
}
