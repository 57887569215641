import {LoadingPage} from "@hortis/ui/loading"
import {Outlet, createFileRoute} from "@tanstack/react-router"
import {Suspense} from "react"
import {NavLayout} from "src/components/nav-layout/nav-layout"

export const Route = createFileRoute("/_layout")({
  component: () => (
    <NavLayout>
      <Suspense fallback={<LoadingPage />}>
        <Outlet />
      </Suspense>
    </NavLayout>
  ),
})
