import {useCallback, useEffect, useState} from "react"
import {Layer, Source} from "react-map-gl"
import {colors} from "src/colors"
import {
  circleRadiusStyle,
  circleStrokeWidthStyle,
} from "src/components/map/material-clusters/material-cluster-layers"
import {useSnackbarStore} from "src/components/snackbar-controller/snackbar-store"
import {useMapOptionsStore} from "./map-options-store"

const positionOptions = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
}

// Source: https://stackoverflow.com/a/37794326
const metersToPixelsAtMaxZoom = (meters: number, latitude: number) =>
  meters / 0.075 / Math.cos((latitude * Math.PI) / 180)

export const CurrentPositionLayer = () => {
  const {setDevicePositionLayerEnabled, devicePositionLayerEnabled} =
    useMapOptionsStore()
  const {setSnack} = useSnackbarStore()
  const [currentDevicePosition, setCurrentDevicePosition] = useState<
    GeolocationPosition | undefined
  >()

  const getCurrentPositionSuccess = useCallback<PositionCallback>((pos) => {
    setCurrentDevicePosition(pos)
  }, [])

  const getCurrentPositionError = useCallback<PositionErrorCallback>(() => {
    setDevicePositionLayerEnabled(false)
    setSnack({
      type: "alert",
      data: {severity: "error", text: "Unable to get location"},
    })
  }, [setSnack, setDevicePositionLayerEnabled])

  const getCurrentPosition = useCallback(() => {
    navigator.geolocation.getCurrentPosition(
      getCurrentPositionSuccess,
      getCurrentPositionError,
      positionOptions,
    )
  }, [getCurrentPositionSuccess, getCurrentPositionError])

  useEffect(() => {
    const interval = setInterval(() => {
      getCurrentPosition()
    }, 500)

    if (!devicePositionLayerEnabled) {
      clearInterval(interval)
    }

    return () => {
      clearInterval(interval)
    }
  }, [getCurrentPosition, devicePositionLayerEnabled])

  return (
    <Source
      id="current-position"
      type="geojson"
      data={{
        type: "FeatureCollection",
        features:
          currentDevicePosition == null
            ? []
            : [
                {
                  type: "Feature",
                  properties: {},
                  geometry: {
                    type: "Point",
                    coordinates: [
                      currentDevicePosition.coords.longitude,
                      currentDevicePosition.coords.latitude,
                    ],
                  },
                },
              ],
      }}
    >
      <Layer
        id="current-position-point2"
        type="circle"
        paint={{
          // eslint-disable-next-line sonarjs/no-duplicate-string
          "circle-color": colors["blue-light"][300],
          "circle-opacity": 0.2,
          "circle-radius": {
            stops: [
              [0, 0],
              [
                20,
                currentDevicePosition == null
                  ? 0
                  : metersToPixelsAtMaxZoom(
                      currentDevicePosition.coords.accuracy,
                      currentDevicePosition.coords.latitude,
                    ),
              ],
            ],
            base: 2,
          },
          "circle-stroke-width": circleStrokeWidthStyle,
          "circle-stroke-opacity": 0.4,
          "circle-stroke-color": colors["blue-light"][300],
        }}
      />
      <Layer
        id="current-position-point"
        type="circle"
        paint={{
          "circle-stroke-opacity": 0.9,
          "circle-color": colors["blue-light"][400],
          "circle-radius": circleRadiusStyle,
          "circle-stroke-width": circleStrokeWidthStyle,
          "circle-stroke-color": colors.white,
        }}
      />
    </Source>
  )
}
