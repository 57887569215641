import {useCallback} from "react"
import {useNavigate, useSearch} from "@tanstack/react-router"
import type {PlantMaterialsSearchColumn} from "../../../../generated/graphql"
import type {searchMenuLabels} from "../utils"

type SearchField = keyof typeof searchMenuLabels

export const useMapSearch = () => {
  const navigate = useNavigate({from: "/sites/$siteSlug/map"})
  const {searchField, searchValue} = useSearch({
    from: "/_layout/sites/$siteSlug/map",
  })

  const handleSubmit = useCallback(
    (searchValue: string, searchField: SearchField) => {
      void navigate({
        search: {
          searchValue,
          searchField,
        },
      })
    },
    [navigate],
  )

  const handleClear = useCallback(
    (searchField: PlantMaterialsSearchColumn) => {
      void navigate({search: {searchValue: "", searchField}})
    },
    [navigate],
  )

  return {
    searchTerm:
      searchField != null && searchValue != null
        ? {field: searchField, value: searchValue}
        : undefined,
    handleSubmit,
    handleClear,
  }
}
