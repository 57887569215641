import {createRoot} from "react-dom/client"
// import {NewVersionPrompt} from "../src/components/new-version-prompt"
// import {useServiceWorker} from "../src/utils/service-worker/service-worker"
import {StrictMode} from "react"
import {App} from "./app"

// eslint-disable-next-line unicorn/prefer-query-selector, @typescript-eslint/no-non-null-assertion
createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <App />
  </StrictMode>,
)
