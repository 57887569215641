import type {
  GetMeQuery,
  GetMeQueryVariables,
} from "../../../../generated/graphql"
import {noGQLVars} from "../../../utils/empty"
import {authedFetcher} from "../../../utils/gql-client"
import {createAuthedHook} from "../../../utils/hooks/hook"
import {toError} from "../../../utils/option-either"
import {createWrappedSWRHook} from "../../../utils/swr"
import {GET_ME} from "./graphql"

export const fetchMe = authedFetcher<GetMeQueryVariables, GetMeQuery>(GET_ME)(
  "GetMe",
  noGQLVars,
)

export const useMe = createAuthedHook(createWrappedSWRHook(fetchMe, toError))
