import type {FC} from "react"
import {createContext, useContext} from "react"
import type {Config} from "src/config"
import config from "src/config"

const EnvironmentContext = createContext<Config>(config)

export const EnvironmentProvider: FC<React.PropsWithChildren> = ({
  children,
}) => (
  <EnvironmentContext.Provider value={config}>
    {children}
  </EnvironmentContext.Provider>
)

export function useEnvironment(): Config {
  return useContext(EnvironmentContext)
}
