import {F, pipe, R} from "@mobily/ts-belt"
import type {Property} from "csstype"
import type {ReactNode} from "react"
import {useGetMapConfigQuery} from "generated/graphql"
import {Skeleton} from "src/components/skeleton"
import type {LayerType} from "src/features/collection-map/components/types"
import {
  useOrganisationSubdomainStruct,
  usePlaceStruct,
} from "src/utils/hooks/place"
import {useMapPadding} from "src/features/collection-map/components/use-map-padding"
import type {MapProps} from "src/components/map/map"
import {Map} from "../../../../../components/map"

const mapId = "preview-map"

export const useFetchMapData = () => {
  const organisationSubdomain = useOrganisationSubdomainStruct()
  const place = usePlaceStruct()
  const [result] = useGetMapConfigQuery({
    variables: {
      organisationSubdomain: organisationSubdomain.data ?? "",
      collectionSiteSlug: place.data?.siteSlug ?? "",
    },
    pause: organisationSubdomain.loading,
  })

  if (result.fetching || organisationSubdomain.loading) {
    return R.Error(
      <Skeleton
        width="100%"
        height="100%"
        sx={{"&.MuiSkeleton-root": {transform: "none"}}}
        animation="pulse"
      />,
    )
  }

  const error = result.error ?? place.error
  if (error != null) {
    return R.Error(<>Error: {error.message}</>)
  }

  const mapKey = result.data?.org?.map?.key
  if (mapKey == null) {
    return R.Error(<>Failed to load map key</>)
  }

  const site = result.data?.org?.site

  return R.Ok({
    mapKey,
    siteLatitude: site?.mapCenterLatitude,
    siteZoomLevel: site?.mapZoomLevel,
    siteLongitude: site?.mapCenterLongitude,
    baseMap: site?.baseMap,
  })
}

export const ControlledMap = ({
  latitude,
  longitude,
  zoom,
  children,
  cursor,
  staticMap,
  useSiteCoordinates,
  mapProps,
}: {
  latitude?: number
  longitude?: number
  zoom?: number
  cursor?: Property.Cursor
  children?: ReactNode
  staticMap?: boolean
  layerType?: LayerType
  useSiteCoordinates?: boolean
  mapProps?: MapProps
}) => {
  const padding = useMapPadding({mode: "view", drawerCollapsed: true})
  return pipe(
    useFetchMapData(),
    R.match(({mapKey, siteLatitude, siteLongitude, siteZoomLevel}) => {
      let jointViewState = {
        latitude: latitude ?? 0,
        longitude: longitude ?? 0,
        zoom: zoom ?? 17,
        bearing: 0,
        pitch: 0,
        padding,
        width: 100,
        height: 100,
      }

      if (
        useSiteCoordinates === true &&
        siteLatitude != null &&
        siteLongitude != null
      ) {
        jointViewState = {
          ...jointViewState,
          latitude: siteLatitude,
          longitude: siteLongitude,
        }
      }
      if (siteZoomLevel != null && zoom == null) {
        jointViewState = {
          ...jointViewState,
          zoom: siteZoomLevel,
        }
      }
      return (
        <div
          css={{".mapboxgl-canvas": {borderRadius: "12px"}}}
          className="h-full w-full overflow-hidden"
        >
          <Map
            attributionControl={false}
            {...mapProps}
            mapStyle={`https://api.maptiler.com/maps/basic/style.json?key=${mapKey}`}
            style={{
              width: "100%",
              height: "100%",
            }}
            initialViewState={jointViewState}
            viewState={staticMap === true ? jointViewState : undefined}
            id={mapId}
            cursor={cursor}
          >
            {children}
          </Map>
        </div>
      )
    }, F.identity),
  )
}
