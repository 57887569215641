import type {ProvenanceFilter} from "generated/graphql"
import {isNonEmpty} from "../../count/is-non-empty"

export const countProvenanceFilter = (
  filter: NonNullable<ProvenanceFilter>,
) => {
  let count = 0
  if (isNonEmpty(filter.and)) {
    count += 1
  }
  if (isNonEmpty(filter.or)) {
    count += 1
  }
  return count
}
