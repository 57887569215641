import {PhotoPlaceholder} from "@hortis/ui/photo-placeholder"
import {TwicImg} from "@twicpics/components/react"
import type {MaterialImageFieldsFragment} from "generated/graphql"
import {type HTMLAttributes} from "react"
import {Lightbox, LightboxTrigger} from "src/components/lightbox"
import type {useThumbnailPhoto} from "src/utils/photos"
import {twMerge} from "tailwind-merge"

interface RecordPageHeaderProps {
  children: React.ReactNode
}

const RecordPageHeader = ({
  children,
  ...props
}: RecordPageHeaderProps & HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className="record-page-header flex flex-col gap-4 bg-white" {...props}>
      {children}
    </div>
  )
}

interface RecordPageHeaderThumbnailProps {
  thumbnail: ReturnType<typeof useThumbnailPhoto> | null | undefined
  additionalPhotos:
    | ReadonlyArray<MaterialImageFieldsFragment>
    | undefined
    | null
  photoTitle?: string
  grayscale: boolean
  handleUploadImage?: () => void
}

const RecordPageHeaderThumbnail = ({
  thumbnail,
  additionalPhotos,
  photoTitle = "",
  grayscale,
  handleUploadImage,
}: RecordPageHeaderThumbnailProps) => {
  return (
    <div className="record-page-header-thumbnail h-[188px] w-full">
      {thumbnail == null ? (
        <PhotoPlaceholder
          size="lg"
          className="h-full w-full"
          handleUploadImage={handleUploadImage}
        />
      ) : (
        <Lightbox
          photo={thumbnail}
          photos={additionalPhotos}
          title={photoTitle}
        >
          <LightboxTrigger className="block size-full overflow-hidden rounded-xl outline-none ring-primary-200/70 focus-visible:ring">
            <TwicImg
              src={`${thumbnail.bucketName}/${thumbnail.fileName}`}
              eager
              focus="auto"
              mode="cover"
              className={twMerge(
                "h-[188px] overflow-hidden rounded-xl transition-transform hover:scale-[102%]",
                grayscale && "grayscale",
              )}
            />
          </LightboxTrigger>
        </Lightbox>
      )}
    </div>
  )
}

const RecordPageHeaderTrashRecordAlert = ({
  children,
}: {
  children: React.ReactNode
}) => {
  return <div>{children}</div>
}

const RecordPageHeaderContent = ({children}: {children: React.ReactNode}) => {
  return <div className="flex w-full flex-col gap-3">{children}</div>
}

const RecordPageHeaderPropertiesSummary = ({
  children,
}: {
  children: React.ReactNode
}) => (
  <div
    data-cy="record-header-summary"
    className="flex flex-row flex-wrap items-center gap-2"
  >
    {children}
  </div>
)

const RecordPageHeaderTitleGroup = ({
  children,
  ...props
}: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className="mb-1 flex flex-col gap-0.5" {...props}>
      {children}
    </div>
  )
}
const RecordPageHeaderTitle = ({
  children,
  ...props
}: HTMLAttributes<HTMLDivElement>) => {
  return (
    <p
      data-cy="record-header-title"
      className="title-text text-2xl font-semibold text-grey-900"
      {...props}
    >
      {children}
    </p>
  )
}
const RecordPageHeaderSubtitle = ({
  children,
  ...props
}: HTMLAttributes<HTMLDivElement>) => {
  return (
    <p
      data-cy="record-header-subtitle"
      className="subtitle-text text-base font-normal text-grey-500"
      {...props}
    >
      {children}
    </p>
  )
}

const RecordPageHeaderButtons = ({
  children,
  className,
}: {children: React.ReactNode} & HTMLAttributes<HTMLDivElement>) => {
  return <div className={twMerge("flex gap-3", className)}>{children}</div>
}

export {
  RecordPageHeader,
  RecordPageHeaderButtons,
  RecordPageHeaderContent,
  RecordPageHeaderPropertiesSummary,
  RecordPageHeaderThumbnail,
  RecordPageHeaderTitleGroup,
  RecordPageHeaderTitle,
  RecordPageHeaderSubtitle,
  RecordPageHeaderTrashRecordAlert,
}
