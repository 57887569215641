import {FixedSizeList} from "react-window"
import {FillSpace} from "src/components/fill-space"
import {ListHeader} from "src/features/settings/components/mobile-list/list-header"
import {useLayoutHeight} from "src/utils/hooks/layout-height"
import {EmptyList} from "src/components/empty-list"
import type {DisplayTagsProps} from "../types"
import {renderTagListRow} from "./tags-mobile-row"

const MIN_ROW_HEIGHT = 64

export const TagsMobile = ({tags, canEdit}: DisplayTagsProps) => {
  const {listContainerRef, listHeaderRef, listHeight} = useLayoutHeight()
  return (
    <div ref={listContainerRef} className="flex w-full flex-1 flex-col">
      <div className="flex flex-1 flex-col">
        <ListHeader ref={listHeaderRef} label="Tag" />
        {listHeight > 0 ? (
          <FillSpace>
            {tags == null ? (
              Array.from({length: 6}).map((_, idx) => (
                //eslint-disable-next-line react/no-array-index-key -- not to worry as it's a temporary skeleton
                <div key={idx} />
              ))
            ) : (
              <FixedSizeList
                height={tags.length > 0 ? listHeight : 0} // hacky way to make list invisible with empty results
                width="100%"
                itemSize={MIN_ROW_HEIGHT}
                itemCount={tags.length}
                itemData={{tags, canEdit}}
              >
                {renderTagListRow}
              </FixedSizeList>
            )}
            <EmptyList list={tags} message="Please try another query" />
          </FillSpace>
        ) : null}
      </div>
    </div>
  )
}
