import {Slot, Slottable} from "@radix-ui/react-slot"
import type {Interpolation, Theme} from "@emotion/react"
import type {ButtonHTMLAttributes, PropsWithChildren, ReactElement} from "react"
import {forwardRef} from "react"
import {twMerge} from "tailwind-merge"
import type {IconProps} from "../icons/types"
import {CircularProgress} from "../progress"
import {
  buttonClasses,
  buttonIconSizing,
  buttonSizing,
  loadingSpinnerColor,
  loadingSpinnerSize,
} from "./styles"
import type {ButtonSize, ButtonVariant, ResponsiveButtonSize} from "./types"
import {
  buildResponsiveSizeStyle,
  buildResponsiveSpinnerSizeStyle,
} from "./utils"

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  PropsWithChildren<{
    variant?: ButtonVariant
    startIcon?: ReactElement | ((props: IconProps) => ReactElement)
    endIcon?: ReactElement | ((props: IconProps) => ReactElement)
    loading?: boolean
    size?: ButtonSize | ResponsiveButtonSize
    disabled?: boolean
    containerCss?: Interpolation<Theme>
    testId?: string
    fullWidth?: boolean
    shortcut?: string
    asChild?: boolean
    error?: boolean
  }>

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  function Button(
    {
      children,
      size = "md",
      variant = "secondaryGray",
      startIcon: StartIcon,
      endIcon: EndIcon,
      loading,
      disabled,
      containerCss,
      testId,
      fullWidth,
      className,
      shortcut,
      asChild,
      error: _,
      ...props
    },
    ref,
  ) {
    const Comp = asChild === true ? Slot : "button"

    return (
      <Comp
        type="button"
        css={[buildResponsiveSizeStyle(size, buttonSizing), containerCss]}
        className={twMerge(
          buttonClasses[variant],
          fullWidth === true ? `w-full` : undefined,
          loading === true
            ? `text-white/0 [&>:not(.progress)]:opacity-0`
            : `[&>:not(.progress)]:opacity-100`,
          className,
        )}
        disabled={loading === true || disabled === true}
        data-cy={testId}
        ref={ref}
        {...props}
      >
        {StartIcon instanceof Function ? (
          <StartIcon
            css={buildResponsiveSizeStyle(size, buttonIconSizing)}
            className="stroke-current"
          />
        ) : (
          StartIcon
        )}
        <Slottable>{children}</Slottable>
        {EndIcon instanceof Function ? (
          <EndIcon
            css={buildResponsiveSizeStyle(size, buttonIconSizing)}
            className="stroke-current"
          />
        ) : (
          EndIcon
        )}
        {shortcut != null && (
          <span className="border-grey-100 bg-grey-50 rounded-md border px-1 py-0.5 text-sm">
            {shortcut}
          </span>
        )}
        {loading === true && (
          <CircularProgress
            css={{
              ...buildResponsiveSpinnerSizeStyle(size, loadingSpinnerSize),
              position: "absolute",
              color: loadingSpinnerColor[variant],
            }}
            style={{height: undefined, width: undefined}}
          />
        )}
      </Comp>
    )
  },
)
