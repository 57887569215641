import type {SyntheticEvent} from "react"
import {memo, useCallback} from "react"
import {twMerge} from "tailwind-merge"
import {Checkbox} from "@hortis/ui/checkbox"
import type {MaterialsListFieldsFragment} from "../../../../../generated/graphql"
import {
  MaterialGroupBadge,
  QualifiedProvenanceBadge,
} from "../../../../components/icons"
import {SlChevronRightIcon} from "../../../../components/icons/streamline/chevron-right"
import {StatusChip} from "../../../../components/status-chip"
import {useDateStringFormatter} from "../../../../utils/hooks/language"
import {stopEventPropagation} from "../stop-event-propagation"
import {StyledTableRow} from "../table-styling"
import {createMaterialNumber} from "./material-number"

const DateAdded = memo(function MemoisedDateAdded({
  date,
}: {
  date: string | null | undefined
}) {
  const formatDateString = useDateStringFormatter()
  return (
    <div role="cell" className="date-added">
      {date != null && formatDateString(date)}
    </div>
  )
})

export const MaterialRow = memo(function MemoisedMaterialRow({
  prevRowSelected,
  nextRowSelected,
  rowSelected,
  plantMaterial,
  handleRowClick,
  handleRowSelection,
  testId,
  isSelectMode,
}: {
  prevRowSelected: boolean
  nextRowSelected: boolean
  rowSelected: boolean
  plantMaterial: MaterialsListFieldsFragment
  handleRowClick: (
    _: SyntheticEvent,
    plantMaterial: MaterialsListFieldsFragment,
  ) => void
  handleRowSelection: (
    e: SyntheticEvent,
    plantMaterialId: MaterialsListFieldsFragment["id"],
  ) => void
  testId?: string
  isSelectMode: boolean
}): JSX.Element {
  const materialNumber = createMaterialNumber(
    plantMaterial.accession?.accessionNumber ?? "",
    plantMaterial.qualifier,
    "/",
  )

  return (
    <StyledTableRow
      role="row"
      data-cy={testId}
      prevRowSelected={prevRowSelected}
      nextRowSelected={nextRowSelected}
      rowSelected={rowSelected}
      onClick={useCallback(
        (event: SyntheticEvent) => {
          if (isSelectMode) {
            return
          }
          handleRowClick(event, plantMaterial)
        },
        [handleRowClick, isSelectMode, plantMaterial],
      )}
    >
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events */}
      <div
        role="cell"
        className="checkbox-padding"
        onClick={stopEventPropagation}
      >
        <Checkbox
          checked={rowSelected}
          onChange={useCallback(
            (event: SyntheticEvent) => {
              handleRowSelection(event, plantMaterial.id)
            },
            [handleRowSelection, plantMaterial],
          )}
          ariaLabel="Select plant material"
        />
      </div>
      <div
        role="cell"
        className="material-number"
        data-cy="material-number"
      >{`${materialNumber}`}</div>
      <div role="cell" className="scientific-name">
        {plantMaterial.accession?.scientificName}
      </div>
      <div role="cell" className="material-group">
        <MaterialGroupBadge group={plantMaterial.materialGroup} />
      </div>
      <div role="cell" className="provenance">
        {plantMaterial.accession?.provenanceCategoryCode != null &&
        plantMaterial.accession.provenanceCategory?.name != null ? (
          <QualifiedProvenanceBadge
            provenance={plantMaterial.accession.provenanceCategoryCode}
            provenanceName={plantMaterial.accession.provenanceCategory.name}
          />
        ) : undefined}
      </div>
      <div role="cell" className="status">
        <StatusChip status={plantMaterial.status} testId="status-chip" />
      </div>
      <div
        role="cell"
        className={twMerge(
          "location",
          plantMaterial.collectionSiteLocation?.archived === true &&
            "line-through",
        )}
      >
        {plantMaterial.collectionSiteLocation?.name}
      </div>
      <div role="cell" className="quantity">
        {plantMaterial.quantity == null
          ? plantMaterial.massPlanting
            ? "Mass"
            : null
          : plantMaterial.quantity}
      </div>
      <div role="cell" className="donor-supplier">
        {plantMaterial.accession?.donor}
      </div>
      <DateAdded date={plantMaterial.firstPresent} />
      <div role="cell">
        <div className="chevron-box">
          {isSelectMode ? null : <SlChevronRightIcon />}
        </div>
      </div>
    </StyledTableRow>
  )
})
