import CoreAddIcon from "@mui/icons-material/Add"
import CoreAddRoundedIcon from "@mui/icons-material/AddRounded"
import CoreArrowBackIcon from "@mui/icons-material/ArrowBack"
import CoreArrowForwardIcon from "@mui/icons-material/ArrowForward"
import CoreChevronRightIcon from "@mui/icons-material/ChevronRight"
import CoreClearIcon from "@mui/icons-material/Clear"
import CoreCloseIcon from "@mui/icons-material/Close"
import CoreCloudDownloadIcon from "@mui/icons-material/CloudDownload"
import CoreDeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined"
import CoreExpandLessIcon from "@mui/icons-material/ExpandLess"
import CoreExpandMoreIcon from "@mui/icons-material/ExpandMore"
import CoreFilterAltIcon from "@mui/icons-material/FilterAlt"
import {
  default as CoreFilterAltRoundedIcon,
  default as CoreFilterIcon,
} from "@mui/icons-material/FilterAltRounded"
import CoreInfoOutlined from "@mui/icons-material/InfoOutlined"
import CoreLocationOnIcon from "@mui/icons-material/LocationOn"
import CoreMoreHorizIcon from "@mui/icons-material/MoreHoriz"
import CoreMoreVertIcon from "@mui/icons-material/MoreVert"
import CoreSwapHorizIcon from "@mui/icons-material/SwapHoriz"
import CoreVisibility from "@mui/icons-material/Visibility"
import CoreVisibilityOff from "@mui/icons-material/VisibilityOff"
import CoreWarningAmber from "@mui/icons-material/WarningAmber"
import CoreArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import CoreKeyboardArrowDownRounded from "@mui/icons-material/KeyboardArrowDownRounded"
import type {SvgIconProps} from "@mui/material/SvgIcon"
import CoreSvgIcon from "@mui/material/SvgIcon"
import {applyMui} from "../../utils/apply-mui"
import {withTestId} from "../../utils/with-test-id"

export type IconProps = SvgIconProps

export const SvgIcon = applyMui(withTestId, CoreSvgIcon)
export const ArrowForwardIcon = applyMui(withTestId, CoreArrowForwardIcon)
export const ArrowBackIcon = applyMui(withTestId, CoreArrowBackIcon)
export const Visibility = applyMui(withTestId, CoreVisibility)
export const VisibilityOff = applyMui(withTestId, CoreVisibilityOff)
export const WarningAmber = applyMui(withTestId, CoreWarningAmber)
export const CloseIcon = applyMui(withTestId, CoreCloseIcon)
export const InfoOutlined = applyMui(withTestId, CoreInfoOutlined)
export const ChevronRightIcon = applyMui(withTestId, CoreChevronRightIcon)
export const SwapHorizIcon = applyMui(withTestId, CoreSwapHorizIcon)
export const CloudDownloadIcon = applyMui(withTestId, CoreCloudDownloadIcon)
export const MoreVertIcon = applyMui(withTestId, CoreMoreVertIcon)
export const AddIcon = applyMui(withTestId, CoreAddIcon)
export const AddRoundedIcon = applyMui(withTestId, CoreAddRoundedIcon)
export const MoreHorizIcon = applyMui(withTestId, CoreMoreHorizIcon)
export const ExpandMoreIcon = applyMui(withTestId, CoreExpandMoreIcon)
export const ExpandLessIcon = applyMui(withTestId, CoreExpandLessIcon)
export const FilterIcon = applyMui(withTestId, CoreFilterIcon)
export const FilterAltIcon = applyMui(withTestId, CoreFilterAltIcon)
export const LocationOnIcon = applyMui(withTestId, CoreLocationOnIcon)
export const FilterAltRoundedIcon = applyMui(
  withTestId,
  CoreFilterAltRoundedIcon,
)
export const ClearIcon = applyMui(withTestId, CoreClearIcon)
export const ArrowBackIosIcon = applyMui(withTestId, CoreArrowBackIosIcon)
export const KeyboardArrowDownRounded = applyMui(
  withTestId,
  CoreKeyboardArrowDownRounded,
)
export const DeleteIcon = applyMui(withTestId, CoreDeleteOutlineOutlinedIcon)
