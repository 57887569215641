import {pipe} from "fp-ts/function"
import * as O from "fp-ts/Option"
import config from "src/config"

interface Props {
  inject: (gtmApiKey: string) => string
}

export const useGetGtmApiGKey = () => pipe(config.gtmApiKey, O.fromNullable)
export const InlineScriptWithConfig = ({inject}: Props): JSX.Element => {
  const gtmApiKey = useGetGtmApiGKey()

  return pipe(
    gtmApiKey,
    O.fold(
      () => <></>,
      (gtmApiKey) => (
        <script
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: inject(gtmApiKey),
          }}
        />
      ),
    ),
  )
}

export const InlineNoScriptWithConfig = ({inject}: Props): JSX.Element => {
  const gtmApiKey = useGetGtmApiGKey()

  return pipe(
    gtmApiKey,
    O.fold(
      () => <></>,
      (gtmApiKey) => (
        <noscript
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: inject(gtmApiKey),
          }}
        />
      ),
    ),
  )
}
