import {isFuture} from "date-fns"
import {ProvenanceCategoryCode} from "generated/graphql"
import {taxonOption} from "src/features/taxonomy/components/new-taxon/schema"
import {
  identificationQualifiers,
  identificationVerificationStatuses,
} from "src/utils/label-maps/identification"
import * as z from "zod"
import {tagOptionZod} from "../add-plant-material-form/schema"
import {validateAccessionNumber} from "../validate-accession-number"

export const identificationDetailsSchema = z.object({
  identifiedBy: z
    .string({invalid_type_error: "Please enter an identified by"})
    .nonempty(),
  dateIdentified: z
    .date()
    .min(new Date(1500, 1, 1))
    .refine((date) => !isFuture(date), {
      message: "Date cannot be in the future",
    }),
  identificationVerificationStatus: z.enum(identificationVerificationStatuses, {
    invalid_type_error: "Please select an verification status",
  }),
  identificationQualifier: z.enum(identificationQualifiers).nullable(),
  identificationRemarks: z.string().nonempty().nullable(),
})

export type IdentificationDetails = z.TypeOf<typeof identificationDetailsSchema>

export const materialReceivedOption = z.object(
  {
    id: z.string().uuid(),
    code: z.string().min(1, {message: "Material code must be greater than 1"}),
    name: z.string().nullable().optional(),
  },
  {invalid_type_error: "Please select a material received"},
)

export type MaterialReceivedOption = z.TypeOf<typeof materialReceivedOption>

export const AccessionDataUpdateSchemaZod = z.object({
  accessionDate: z
    .date()
    .min(new Date(1500, 1, 1), "Accession year must be greater than 1500"),
  materialTypeId: z.string(),
  taxonOption: taxonOption,
  provenanceCategoryCode: z.nativeEnum(ProvenanceCategoryCode),
  donor: z.string().nullable(),
  notes: z.string().nullable(),
  ipenNumber: z.string().nonempty().nullable(),
  identificationDetails: identificationDetailsSchema.nullish(),
  tags: z.array(tagOptionZod).nullable(),
})

export type AccessionDataUpdatesZod = z.TypeOf<
  typeof AccessionDataUpdateSchemaZod
>

export const AccessionDataSchemaZod = AccessionDataUpdateSchemaZod.merge(
  z.object({
    accessionNumber: z
      .string()
      .min(3, {message: "Accession number must be at least 3 characters"})
      .superRefine(validateAccessionNumber),
    generateAccessionNumber: z.boolean(),
  }),
)

export type AccessionDataZod = z.TypeOf<typeof AccessionDataSchemaZod>
