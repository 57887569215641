import {Outlet, createFileRoute} from "@tanstack/react-router"
import SettingsNavLayout from "../features/settings/layout/layout"

export const Route = createFileRoute("/settings")({
  component: () => (
    <SettingsNavLayout>
      <Outlet />
    </SettingsNavLayout>
  ),
})
