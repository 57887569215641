import * as E from "fp-ts/Either"
import {flow} from "fp-ts/function"
import type {MapGeoJSONFeature} from "maplibre-gl"
import type {MapboxGeoJSONFeature} from "react-map-gl"
import {IOPlantMaterialGeoJsonProperties} from "../../../features/collection-map/fetchers"
import type {SafeFeature, SafePoint} from "./types"

export const decodeProperties = flow(
  IOPlantMaterialGeoJsonProperties.decode,
  E.chainW(
    E.fromNullableK(new Error("No plant material data"))(({adHoc}) => adHoc),
  ),
)

export const getFirstFeature = (event: {
  features?: Array<MapboxGeoJSONFeature | MapGeoJSONFeature> | undefined
}): SafeFeature | undefined => event.features?.[0]

export const getFeaturePoint = flow(
  getFirstFeature,
  (feature): SafePoint | undefined =>
    feature?.geometry.type === "Point" ? (feature as SafePoint) : undefined,
)
