import {A, O, pipe} from "@mobily/ts-belt"
import {type Row, type RowData, type Table} from "@tanstack/react-table"
import type {ReactNode} from "react"
import {Checkbox} from "@hortis/ui/checkbox"

export function selectRange<T>(rows: Array<Row<T>>, index: number) {
  const previousSelectedRange = pipe(
    rows,
    A.slice(0, index + 1),
    A.reverse,
    (rows) =>
      pipe(
        rows,
        A.getIndexBy((row) => row.getIsSelected()),
        O.match(
          (idx) => rows.slice(0, idx),
          () => [],
        ),
      ),
  )
  const nextSelectedRange = pipe(rows, A.sliceToEnd(index), (rows) =>
    pipe(
      rows,
      A.getIndexBy((row) => row.getIsSelected()),
      O.match(
        (idx) => rows.slice(0, idx),
        () => [],
      ),
    ),
  )

  let selectedRows = rows.slice(index, index + 1)

  if (previousSelectedRange.length === 0 && nextSelectedRange.length > 0) {
    selectedRows = nextSelectedRange
  } else if (
    nextSelectedRange.length === 0 &&
    previousSelectedRange.length > 0
  ) {
    selectedRows = previousSelectedRange
  } else if (nextSelectedRange.length > 0 && previousSelectedRange.length > 0) {
    selectedRows =
      previousSelectedRange.length <= nextSelectedRange.length
        ? previousSelectedRange
        : nextSelectedRange
  }

  return selectedRows
}

export interface DataTableCehckboxCellProps<TData extends RowData> {
  table: Table<TData>
  row: Row<TData>
  children?: ReactNode
}

export function DataTableCheckboxCell<TData extends RowData>({
  table,
  row,
  children,
}: DataTableCehckboxCellProps<TData>) {
  const handleSelection = (selected: boolean) => {
    table.setRowSelection((state) =>
      table.options.meta?.shiftHeld?.current === true
        ? {
            ...state,
            ...selectRange(table.getRowModel().rows, row.index).reduce(
              (acc, row) => {
                return {...acc, [row.id]: selected}
              },
              {},
            ),
          }
        : {...state, [row.id]: selected},
    )
  }
  return (
    <div className="flex items-center gap-3">
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div
        className="absolute left-0 top-0 h-full w-14"
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          handleSelection(!row.getIsSelected())
        }}
      />
      <Checkbox
        checked={row.getIsSelected()}
        onClick={(e) => {
          e.stopPropagation()
        }}
        onChange={(e) => {
          handleSelection(e.target.checked)
        }}
        aria-label="Select row"
      />
      {children}
    </div>
  )
}
