import {A, O, pipe} from "@mobily/ts-belt"
import {type ColumnFiltersState} from "@tanstack/react-table"
import type {MutableRefObject} from "react"
import {useCallback, useMemo, useRef, useState} from "react"

interface UseListFilterStateArgs<FilterType> {
  resetColumnFilters: () => void
  columnFilters: ColumnFiltersState
  filterColumnMap: Record<string, FilterType>
}

export const useFilterState = <FilterType extends string>({
  resetColumnFilters,
  columnFilters,
  filterColumnMap,
}: UseListFilterStateArgs<FilterType>) => {
  const openedFilter = pipe(
    columnFilters,
    A.head,
    O.filter(
      (filter) =>
        typeof filter.value === "object" &&
        filter.value != null &&
        "current" in filter.value,
    ),
    O.flatMap(({value, id}) => {
      const mappedColumnId = filterColumnMap[id]
      return mappedColumnId == null
        ? O.None
        : O.Some({value, id: mappedColumnId} as {
            id: FilterType
            value: MutableRefObject<HTMLElement>
          })
    }),
    O.match(
      (filter) => filter,
      () => null,
    ),
  )

  const filterButtonRef = useRef<HTMLButtonElement>(null)
  const [openedFilterToolbar, setOpenedFilterToolbar] =
    useState<FilterType | null>(null)

  const handleFilterModalClose = useMemo(
    () =>
      openedFilterToolbar == null
        ? resetColumnFilters
        : () => {
            setOpenedFilterToolbar(null)
          },
    [openedFilterToolbar, resetColumnFilters],
  )

  const setOpenFilter = useCallback(
    ({filterType}: {filterType: FilterType | false}) => {
      setOpenedFilterToolbar(filterType === false ? null : filterType)
    },
    [],
  )

  return {
    openedFilter,
    filterButtonRef,
    handleFilterModalClose,
    setOpenFilter,
    openedFilterToolbar,
  }
}
