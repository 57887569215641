import {Condition} from "generated/graphql"

export const conditionLabelMap: Record<Condition, string> = {
  [Condition.Excellent]: "Excellent",
  [Condition.Good]: "Good",
  [Condition.Fair]: "Fair",
  [Condition.Poor]: "Poor",
  [Condition.VeryPoor]: "Very poor",
}

export const conditionLabelDotColorMap: Record<Condition, string> = {
  [Condition.Excellent]: "bg-primary-400",
  [Condition.Good]: "bg-green-light-400",
  [Condition.Fair]: "bg-warning-400",
  [Condition.Poor]: "bg-orange-400",
  [Condition.VeryPoor]: "bg-error-400",
}
