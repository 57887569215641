import {colors} from "src/colors"
import type {IconProps} from "./types"

export const Profile = ({
  color = colors.grey[700],
  ...props
}: IconProps): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M8.0625 7.5C8.0625 8.54429 8.47734 9.54581 9.21577 10.2842C9.95419 11.0227 10.9557 11.4375 12 11.4375C13.0443 11.4375 14.0458 11.0227 14.7842 10.2842C15.5227 9.54581 15.9375 8.54429 15.9375 7.5C15.9375 6.45571 15.5227 5.45419 14.7842 4.71577C14.0458 3.97734 13.0443 3.5625 12 3.5625C10.9557 3.5625 9.95419 3.97734 9.21577 4.71577C8.47734 5.45419 8.0625 6.45571 8.0625 7.5V7.5Z"
        stroke={color}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.6875 20.4375C4.6875 18.4981 5.45792 16.6381 6.82928 15.2668C8.20064 13.8954 10.0606 13.125 12 13.125C13.9394 13.125 15.7994 13.8954 17.1707 15.2668C18.5421 16.6381 19.3125 18.4981 19.3125 20.4375"
        stroke={color}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
