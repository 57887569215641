import type {IconProps} from "./types"

export const SlSwipeHorizontalIcon = ({
  style,
  width,
  height,
  color,
  className,
}: IconProps): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? "20"}
      height={height ?? "20"}
      viewBox="0 0 20 20"
      fill="none"
      className={className}
      style={style}
    >
      <path
        d="M9.07324 15.75L6.54724 13.688C6.38406 13.5574 6.26177 13.3827 6.19489 13.1846C6.12801 12.9866 6.11934 12.7735 6.16991 12.5707V12.5707C6.20957 12.4121 6.28433 12.2644 6.38868 12.1386C6.49303 12.0127 6.6243 11.9119 6.77281 11.8436C6.92133 11.7752 7.08329 11.7411 7.24674 11.7437C7.4102 11.7463 7.57099 11.7856 7.71724 11.8587L8.49991 12.25V7.75C8.49991 7.48478 8.60526 7.23043 8.7928 7.04289C8.98034 6.85536 9.23469 6.75 9.49991 6.75V6.75C9.76513 6.75 10.0195 6.85536 10.207 7.04289C10.3946 7.23043 10.4999 7.48478 10.4999 7.75V10.75L11.8286 10.972C12.2956 11.0498 12.7198 11.2907 13.0259 11.652C13.3319 12.0132 13.4999 12.4712 13.4999 12.9447V15.75"
        stroke={color ?? "#424242"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 6.75H6"
        stroke={color ?? "#424242"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 4.25L2.5 6.75L5 9.25"
        stroke={color ?? "#424242"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 6.75H17.5"
        stroke={color ?? "#424242"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 4.25L17.5 6.75L15 9.25"
        stroke={color ?? "#424242"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
