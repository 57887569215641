import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@hortis/ui/breadcrumb"
import {Button, IconButton} from "@hortis/ui/button"
import {Dialog} from "@hortis/ui/dialog"
import {
  Drawer,
  DrawerContent,
  DrawerHandle,
  DrawerRemoveScroll,
} from "@hortis/ui/drawer"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@hortis/ui/dropdown-menu"
import {useMediaQuery} from "@hortis/ui/hooks/media-query"
import {
  AlignLeft1,
  ArrowLeft,
  DotsHorizontal,
  Edit5,
  ExternalLink1,
  Eye,
  EyeOff,
  Folder,
  GitBranch1,
  MarkerPin4,
  RefreshCw5,
  Tag1,
  Trash3,
} from "@hortis/ui/icons"
import {Grid3} from "@hortis/ui/icons/grid-3"
import {MarkerPin1} from "@hortis/ui/icons/marker-pin-1"
import {MarkerPinOff1} from "@hortis/ui/icons/marker-pin-off-1"
import {MarkerPinPlus1} from "@hortis/ui/icons/marker-pin-plus-1"
import {Sheet, SheetContent} from "@hortis/ui/sheet"
import {Skeleton} from "@hortis/ui/skeleton"
import {Link, createFileRoute} from "@tanstack/react-router"
import {
  MaterialGroup,
  PlantMaterialStatus,
  useGetCollectionSiteFromNamesQuery,
} from "generated/graphql"
import {useState} from "react"
import {RestoreMaterialDialogContent} from "src/components/dialog/variants/restore-material"
import {TrashMaterialDialogContent} from "src/components/dialog/variants/trash-material"
import {MakeObservationDialogContent} from "src/components/modals/make-observation"
import {
  TopBar,
  TopBarActionButtons,
  TopBarContent,
} from "src/components/nav-layout/topbar"
import {
  RecordPageHeader,
  RecordPageHeaderButtons,
  RecordPageHeaderContent,
  RecordPageHeaderSubtitle,
  RecordPageHeaderThumbnail,
  RecordPageHeaderTitle,
  RecordPageHeaderTitleGroup,
} from "src/components/record-page-header"
import {useMapContext} from "src/features/collection-map/map-store"
import {useFindOnMap} from "src/features/collection-map/hooks"
import {mapId} from "src/features/collection-map/utils"
import {
  createMaterialNumber,
  parseMaterialNumber,
} from "src/features/collection/components/plant-materials/material-number"
import {MakePrivateDialog} from "src/features/material-page/header/make-private-dialog"
import {MakePublicDialog} from "src/features/material-page/header/make-public-dialog"
import {MaterialCopyDropdownMenuSub} from "src/features/material-page/header/material-copy-dropdown-menu-sub"
import {MaterialRecordPropertySummary} from "src/features/material-page/header/material-record-property-summary"
import {MaterialRecordAccession} from "src/features/material-page/sections/material-record-accession"
import {MaterialRecordActivity} from "src/features/material-page/sections/material-record-activity"
import {MaterialRecordDetails} from "src/features/material-page/sections/material-record-details"
import {MaterialRecordLocation} from "src/features/material-page/sections/material-record-location"
import {MaterialRecordObservations} from "src/features/material-page/sections/material-record-observations"
import {MaterialRecordPhotos} from "src/features/material-page/sections/material-record-photos"
import {MaterialRecordRelatedRecords} from "src/features/material-page/sections/material-record-related-records"
import {MaterialRecordTaxon} from "src/features/material-page/sections/material-record-taxon"
import {useAccessRole} from "src/features/permissions/use-access-role"
import MaterialRecordCard from "src/features/records/components/new-record-card/variants/material-record-card"
import {
  RecordField,
  RecordLabelSkeleton,
} from "src/features/records/components/record-field/new-record-field"
import {RecordSectionContentGroup} from "src/features/records/components/record-section/new-record-section"
import {RecordNotesSection} from "src/features/records/components/record-section/variants/record-notes-section"
import {RecordTagSection} from "src/features/records/components/record-section/variants/record-tag-section"
import {EditMaterialSheetForm} from "src/features/records/material/components/edit-drawer/edit-details"
import {
  HierarchyContent,
  HierarchyListGroup,
  HierarchyPopover,
  HierarchyTrigger,
} from "src/features/records/material/components/hierarchy-popover"
import HierarchyListItem from "src/features/records/material/components/hierarchy-popover/hierachy-list-item"
import {MaterialTrashRecordAlert} from "src/features/records/material/components/material-contents"
import {useGetMaterial} from "src/features/records/material/fetchers"
import {useCollectionSiteSettings} from "src/utils/hooks/collection-site-from-url"
import {useMobile} from "src/utils/hooks/media-query"
import {useIsScrolled} from "src/utils/hooks/scrolled"
import {getThumbnailPhotoUrl, useThumbnailPhoto} from "src/utils/photos"
import {twMerge} from "tailwind-merge"

export const Route = createFileRoute(
  "/_layout/sites/$siteSlug/map/material/$materialNumber",
)({
  component: Component,
})

// eslint-disable-next-line sonarjs/cognitive-complexity
function Component() {
  const {requestEdit, collapseSidebar, sidebarOpen} = useMapContext()
  const {siteSlug, materialNumber} = Route.useParams()
  const {subdomain} = Route.useRouteContext()
  const [snap, setSnap] = useState<number | string | null>("224px")
  const findOnMap = useFindOnMap(mapId)
  const isMobile = useMobile({defaultMatches: true})
  const {attachRef} = useIsScrolled()
  const {canEdit} = useAccessRole()
  const [observationModalOpen, setObservationModalOpen] = useState(false)
  const [editDrawerOpen, setEditDrawerOpen] = useState(false)
  const [makePublicDialogOpen, setMakePublicDialogOpen] = useState(false)
  const [makePrivateDialogOpen, setMakePrivateDialogOpen] = useState(false)
  const [trashDialogOpen, setTrashDialogOpen] = useState(false)
  const [uploadPhotoDialogOpen, setUploadPhotoDialogOpen] = useState(false)
  const isNotMobile = useMediaQuery("sm")
  const {isPublic: isSitePublic} = useCollectionSiteSettings()
  const [restoreDialogOpen, setRestoreDialogOpen] = useState(false)

  const materialNumberParts = parseMaterialNumber(materialNumber)

  const [{data}, refetchMaterial] = useGetMaterial({
    variables: {
      accessionNumber: materialNumberParts?.accessionNumber ?? "",
      qualifier: materialNumberParts?.qualifier ?? "",
      relatedMaterialsFilters: {},
    },
    pause: materialNumberParts == null,
  })

  const [{data: siteData}] = useGetCollectionSiteFromNamesQuery({
    variables: {
      organisationSubdomain: subdomain,
      collectionSiteSlug: siteSlug,
    },
  })

  const material = data?.org?.site?.result ?? undefined
  const isPublic = material?.public ?? false
  const site = siteData?.org?.site

  const isPresentPlant =
    material?.status === PlantMaterialStatus.Present &&
    material.materialGroup === MaterialGroup.Plant

  const thumbnailImage = useThumbnailPhoto(material)

  const navigate = Route.useNavigate()

  const recordHeaderButtons =
    material == null ? (
      <Skeleton className="h-6 w-48" />
    ) : (
      <RecordPageHeaderButtons>
        {canEdit && !material.archived && (
          <>
            <Button
              startIcon={
                material.position == null ? MarkerPinPlus1 : MarkerPin1
              }
              onClick={() => {
                requestEdit(material)
              }}
              size="xs"
              className="!rounded-md"
              testId="update-position-button"
            >
              {material.position == null ? "Add position" : "Reposition"}
            </Button>
            <Button
              size={"xs"}
              startIcon={Edit5}
              onClick={() => {
                setEditDrawerOpen(true)
              }}
              className="!rounded-md"
              data-cy="edit-material-button"
            >
              Edit
            </Button>
          </>
        )}

        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              data-cy="action-menu-button"
              endIcon={DotsHorizontal}
              size="xs"
              className="!rounded-md !p-2"
            />
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {material.materialGroup === MaterialGroup.Plant &&
              material.status !== PlantMaterialStatus.Absent &&
              !material.archived && (
                <>
                  <DropdownMenuGroup>
                    <DropdownMenuItem
                      data-cy="make-observation-button"
                      onClick={() => {
                        setObservationModalOpen(true)
                      }}
                    >
                      <Grid3 className="h-4 w-4 text-grey-700" />
                      New observation
                    </DropdownMenuItem>
                  </DropdownMenuGroup>
                  <DropdownMenuSeparator />
                </>
              )}
            <DropdownMenuGroup>
              {material.position != null && (
                <DropdownMenuItem
                  onClick={() => {
                    findOnMap(material.position)
                  }}
                  data-cy="menu-option-find-on-map"
                >
                  <MarkerPin4 className="h-4 w-4 text-grey-500" />
                  Open on map
                </DropdownMenuItem>
              )}
              {material.accession != null && (
                <DropdownMenuItem asChild>
                  <Link
                    from="/sites/$siteSlug/map"
                    to="/sites/$siteSlug/materials/$materialNumber"
                    params={{
                      materialNumber: createMaterialNumber(
                        material.accession.accessionNumber,
                        material.qualifier,
                      ),
                    }}
                  >
                    <ExternalLink1 className="h-4 w-4 text-grey-500" />
                    Open record
                  </Link>
                </DropdownMenuItem>
              )}
            </DropdownMenuGroup>
            <DropdownMenuSeparator />
            <MaterialCopyDropdownMenuSub material={material} />
            {canEdit && (
              <>
                {isPresentPlant && !material.archived && isSitePublic && (
                  <>
                    <DropdownMenuGroup>
                      {isPublic && (
                        <DropdownMenuItem
                          data-cy="make-private-menu-item"
                          onClick={() => {
                            setMakePrivateDialogOpen(true)
                          }}
                        >
                          <EyeOff className="h-4 w-4 text-grey-500" />
                          Make private
                        </DropdownMenuItem>
                      )}

                      {!isPublic && (
                        <DropdownMenuItem
                          data-cy="make-public-menu-item"
                          onClick={() => {
                            setMakePublicDialogOpen(true)
                          }}
                        >
                          <Eye className="h-4 w-4 text-grey-500" />
                          Make public
                        </DropdownMenuItem>
                      )}
                    </DropdownMenuGroup>
                    <DropdownMenuSeparator />
                  </>
                )}
                <DropdownMenuGroup>
                  {!material.archived && (
                    <DropdownMenuItem
                      onClick={() => {
                        setTrashDialogOpen(true)
                      }}
                      testId="archive-material-menu-item"
                    >
                      <Trash3 className="h-4 w-4 text-grey-500" />
                      Delete
                    </DropdownMenuItem>
                  )}
                  {material.archived && (
                    <DropdownMenuItem
                      onClick={() => {
                        setRestoreDialogOpen(true)
                      }}
                      testId="restore-material-menu-item"
                    >
                      <RefreshCw5 className="h-4 w-4 text-grey-500" />
                      Restore
                    </DropdownMenuItem>
                  )}
                </DropdownMenuGroup>
              </>
            )}
          </DropdownMenuContent>
        </DropdownMenu>
        <Dialog
          open={observationModalOpen}
          onOpenChange={setObservationModalOpen}
        >
          <MakeObservationDialogContent
            onSubmit={() => {
              refetchMaterial()
              setObservationModalOpen(false)
            }}
            onClose={() => {
              setObservationModalOpen(false)
            }}
            materialId={material.id}
            defaultValues={material}
          />
        </Dialog>
        <Dialog open={restoreDialogOpen} onOpenChange={setRestoreDialogOpen}>
          <RestoreMaterialDialogContent
            onSuccess={() => {
              setRestoreDialogOpen(false)
            }}
            id={material.id}
          />
        </Dialog>
      </RecordPageHeaderButtons>
    )

  const menuContent =
    material == null ? (
      <div className="grid gap-6 lg:gap-12">
        <div className="flex flex-col gap-6 [&_.record-section-content-group]:lg:grid-flow-row [&_.record-section-content-group]:lg:grid-cols-2">
          <div className="flex flex-col gap-8">
            {/* Details section */}
            <div>
              <div className="mb-4 border-b border-grey-200 pb-2">
                <Skeleton className="h-5 w-48" />
              </div>
              <div className="flex flex-col gap-6 lg:gap-8">
                <RecordSectionContentGroup className="grid gap-4 lg:grid-cols-2">
                  <RecordField>
                    <RecordLabelSkeleton className="h-5" />
                  </RecordField>
                  <RecordField>
                    <RecordLabelSkeleton className="h-5" />
                  </RecordField>
                  <RecordField>
                    <RecordLabelSkeleton className="h-5" />
                  </RecordField>
                  <RecordField>
                    <RecordField>
                      <RecordLabelSkeleton className="h-5" />
                    </RecordField>
                    <RecordLabelSkeleton className="h-5" />
                  </RecordField>
                </RecordSectionContentGroup>
                <RecordSectionContentGroup>
                  <RecordField>
                    <RecordLabelSkeleton className="h-5" />
                  </RecordField>
                </RecordSectionContentGroup>
                <RecordSectionContentGroup>
                  <RecordField>
                    <RecordLabelSkeleton className="h-5" />
                  </RecordField>
                </RecordSectionContentGroup>
              </div>
            </div>

            {/* Related Records */}
            <div>
              <div className="mb-4 border-b border-grey-200 pb-2">
                <Skeleton className="h-5 w-48" />
              </div>
              <RecordSectionContentGroup className="grid w-full grid-cols-1 md:grid-cols-2">
                <Skeleton className="h-28" />
                <Skeleton className="h-28" />
                <Skeleton className="h-28" />
                <Skeleton className="h-28" />
              </RecordSectionContentGroup>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <>
        <div className="grid pb-4 lg:gap-12">
          <div className="flex flex-col gap-6 [&_.photo-grid]:grid-cols-2 [&_.record-section-content-group]:sm:grid-cols-2">
            <MaterialRecordDetails
              material={material}
              requestEdit={() => {
                setEditDrawerOpen(true)
              }}
              canEdit={canEdit}
            />
            <MaterialRecordLocation
              material={material}
              requestEdit={() => {
                setEditDrawerOpen(true)
              }}
              canEdit={canEdit}
              hideMap
              onShowOnMap={() => {
                findOnMap(material.position)
              }}
            />
            <RecordTagSection
              tags={material.tagsConnection?.nodes}
              requestEdit={
                canEdit && !material.archived
                  ? () => {
                      setEditDrawerOpen(true)
                    }
                  : undefined
              }
              canEdit={canEdit && !material.archived}
            />
            <RecordNotesSection
              notes={material.notes}
              canEdit={canEdit}
              requestEdit={() => {
                setEditDrawerOpen(true)
              }}
            />
            <div className="grid w-full gap-8">
              <MaterialRecordAccession material={material} />
              <MaterialRecordTaxon material={material} />
            </div>
            <MaterialRecordPhotos
              canEdit={canEdit}
              material={material}
              refetchMaterial={refetchMaterial}
              uploadDialogOpen={uploadPhotoDialogOpen}
              setUploadDialogOpen={setUploadPhotoDialogOpen}
            />
            {material.materialGroup === MaterialGroup.Plant && (
              <MaterialRecordObservations
                material={material}
                canEdit={canEdit}
                refetchMaterial={refetchMaterial}
              />
            )}
            <MaterialRecordActivity material={material} />
            <MaterialRecordRelatedRecords material={material} isMapPage />
          </div>
        </div>

        <Sheet open={editDrawerOpen} onOpenChange={setEditDrawerOpen}>
          <SheetContent>
            <EditMaterialSheetForm
              onSubmit={refetchMaterial}
              material={material}
              open={editDrawerOpen}
              requestClose={() => {
                setEditDrawerOpen(false)
              }}
              hideObservationFields
              hidePositionFields
            />
          </SheetContent>
        </Sheet>

        {canEdit && (
          <>
            <Dialog open={trashDialogOpen} onOpenChange={setTrashDialogOpen}>
              <TrashMaterialDialogContent
                id={material.id}
                onSuccess={() => {
                  setTrashDialogOpen(false)
                  refetchMaterial()
                }}
              />
            </Dialog>

            <MakePrivateDialog
              open={makePrivateDialogOpen}
              onOpenChange={setMakePrivateDialogOpen}
              id={material.id}
              onSuccess={refetchMaterial}
            />
            <MakePublicDialog
              open={makePublicDialogOpen}
              onOpenChange={setMakePublicDialogOpen}
              id={material.id}
              onSuccess={refetchMaterial}
            />
          </>
        )}
      </>
    )

  return isMobile ? (
    <Drawer
      key={String(open)}
      open
      onOpenChange={(open) => {
        if (!open) {
          void navigate({
            from: "/sites/$siteSlug/map/material/$materialNumber",
            to: "/sites/$siteSlug/map",
          })
        }
      }}
      modal={false}
      shouldScaleBackground={false}
      snapPoints={["190px", 1]}
      activeSnapPoint={snap}
      setActiveSnapPoint={setSnap}
      scrollLockTimeout={50}
    >
      <DrawerContent
        className={twMerge(
          "h-full max-h-full shadow-md-up",
          snap === 1 && "rounded-t-none",
        )}
        onInteractOutside={(e) => {
          e.stopPropagation()
          e.preventDefault()
        }}
      >
        <DrawerHandle />
        <DrawerRemoveScroll
          className={twMerge(
            "h-full px-4 pb-4 xl:px-6",
            snap === 1 && "overflow-y-scroll",
            snap !== 1 && "overflow-clip",
          )}
        >
          <div ref={attachRef} className="flex w-full flex-col bg-white">
            <div className="flex flex-col gap-2 pb-4">
              {material == null ? (
                <Skeleton className="w-ful h-24" />
              ) : (
                <>
                  {material.archived && (
                    <MaterialTrashRecordAlert
                      materialId={material.id}
                      canEdit={canEdit}
                    />
                  )}
                  <MaterialRecordCard
                    noLocation={material.position == null}
                    group={material.materialGroup}
                    status={material.status}
                    materialNumber={createMaterialNumber(
                      material.accession?.accessionNumber ?? "",
                      material.qualifier,
                    )}
                    scientificName={material.accession?.scientificName ?? "-"}
                    variant={"Row"}
                    size={isNotMobile ? "md" : "sm"}
                    className="-ml-3 hover:bg-white"
                    photoUrl={getThumbnailPhotoUrl(material)}
                    location={material.collectionSiteLocation}
                  />
                </>
              )}
              {recordHeaderButtons}
            </div>
            {menuContent}
          </div>
        </DrawerRemoveScroll>
      </DrawerContent>
    </Drawer>
  ) : (
    <div
      key={material?.id}
      className={twMerge(
        "pointer-events-auto z-50 flex h-full w-full max-w-xs flex-col self-center bg-white shadow-md lg:max-w-[26rem]",
        !sidebarOpen && "hidden",
      )}
    >
      <TopBar>
        <TopBarContent className="gap-3">
          <IconButton
            size="sm"
            variant="linkgray"
            testId="material-details-close-button"
            className="bg-opacity-0 hover:bg-opacity-50"
            asChild
            icon={ArrowLeft}
            ariaLabel="Back to list"
          >
            <Link
              from="/sites/$siteSlug/map/material/$materialNumber"
              to="/sites/$siteSlug/map"
            />
          </IconButton>
          {material == null ? (
            <Skeleton className="h-5 w-48" />
          ) : (
            <Breadcrumb>
              <BreadcrumbList>
                <BreadcrumbItem className="max-w-24">
                  {site?.name}
                </BreadcrumbItem>
                <BreadcrumbSeparator />
                <BreadcrumbItem>
                  <Button asChild variant="linkgray">
                    <Link
                      from="/sites/$siteSlug/map/material/$materialNumber"
                      to="/sites/$siteSlug/map"
                    >
                      Map
                    </Link>
                  </Button>
                </BreadcrumbItem>
                <BreadcrumbSeparator />
                <BreadcrumbItem>
                  <HierarchyPopover>
                    <HierarchyTrigger>
                      {material.accession?.accessionNumber}/{material.qualifier}
                    </HierarchyTrigger>
                    <HierarchyContent>
                      <HierarchyListItem
                        root
                        to="/taxonomy/$taxonId"
                        params={{taxonId: material.accession?.taxon?.id}}
                      >
                        <GitBranch1 className="h-4 w-4 text-grey-500" />
                        <span className="text-sm font-medium text-grey-900">
                          {material.accession?.taxon?.scientificName ?? "-"}
                        </span>
                      </HierarchyListItem>
                      <HierarchyListGroup indent>
                        <HierarchyListItem
                          to="/sites/$siteSlug/accessions/$accessionNumber"
                          params={{
                            accessionNumber:
                              material.accession?.accessionNumber,
                          }}
                        >
                          <Folder className="h-4 w-4 text-grey-500" />
                          <span className="text-sm font-medium text-grey-900">
                            {material.accession?.accessionNumber ?? "-"}
                          </span>
                        </HierarchyListItem>
                        <HierarchyListGroup indent>
                          {material.accession?.plantMaterial?.map(
                            (plantMaterial) => {
                              const selected = plantMaterial.id === material.id
                              return (
                                <HierarchyListItem
                                  to="/sites/$siteSlug/map/material/$materialNumber"
                                  params={{
                                    materialNumber: createMaterialNumber(
                                      // Optional chained in above map
                                      material.accession?.accessionNumber ?? "",
                                      plantMaterial.qualifier,
                                    ),
                                  }}
                                  selected={selected}
                                  key={plantMaterial.id}
                                >
                                  <Tag1
                                    className={twMerge(
                                      "h-4 w-4 text-grey-500",
                                      selected && "text-primary-500",
                                    )}
                                  />
                                  <span
                                    className={twMerge(
                                      "text-sm font-medium text-grey-900",
                                      selected && "text-primary-500",
                                    )}
                                  >
                                    {`Material ${plantMaterial.qualifier}`}
                                  </span>
                                </HierarchyListItem>
                              )
                            },
                          )}
                        </HierarchyListGroup>
                      </HierarchyListGroup>
                    </HierarchyContent>
                  </HierarchyPopover>
                </BreadcrumbItem>
              </BreadcrumbList>
            </Breadcrumb>
          )}
        </TopBarContent>
        <TopBarActionButtons>
          <IconButton
            size="xs"
            variant="tertiaryGray"
            onClick={() => {
              collapseSidebar()
            }}
            icon={AlignLeft1}
            ariaLabel="Collapse material details"
            testId="material-details-collapse-button"
            className="bg-opacity-0 hover:bg-opacity-50"
          />
        </TopBarActionButtons>
      </TopBar>
      <div className="flex h-full flex-col overflow-y-scroll px-6 py-6">
        <div
          ref={attachRef}
          className="flex h-full w-full flex-col gap-6 bg-white"
        >
          {material == null ? (
            <RecordPageHeader>
              <div className="record-page-header-thumbnail h-[188px] w-full ">
                <Skeleton className="h-full w-full" />
              </div>
              <RecordPageHeaderContent>
                <div className="flex flex-row gap-2">
                  <Skeleton className="h-5 w-12" />
                  <Skeleton className="h-5 w-12" />
                  <Skeleton className="h-5 w-12" />
                </div>
                <div className="mb-1 mt-2 flex flex-col gap-2">
                  <p className="text-2xl font-semibold text-grey-900 lg:text-3xl">
                    <Skeleton className="h-8 w-48" />
                  </p>
                  <p className="text-base font-normal text-grey-500 lg:text-lg">
                    <Skeleton className="h-7 w-32" />
                  </p>
                </div>
                <Skeleton className="h-8 w-48" />
              </RecordPageHeaderContent>
            </RecordPageHeader>
          ) : (
            <>
              {material.archived && (
                <MaterialTrashRecordAlert
                  materialId={material.id}
                  canEdit={canEdit}
                />
              )}
              <RecordPageHeader>
                <RecordPageHeaderThumbnail
                  grayscale={material.archived || material.firstAbsent !== null}
                  thumbnail={thumbnailImage}
                  additionalPhotos={material.images}
                  photoTitle={material.accession?.scientificName ?? ""}
                  handleUploadImage={undefined}
                />
                <RecordPageHeaderContent>
                  <MaterialRecordPropertySummary
                    canEdit={canEdit}
                    material={material}
                    refetchMaterial={refetchMaterial}
                  />
                  <RecordPageHeaderTitleGroup>
                    <RecordPageHeaderTitle>
                      <div className="flex items-center gap-2">
                        {material.accession?.scientificName ?? "-"}
                        {material.position == null && (
                          <MarkerPinOff1 className="h-4 w-4 shrink-0 text-grey-500" />
                        )}
                      </div>
                    </RecordPageHeaderTitle>
                    {material.accession?.taxon?.commonName != null && (
                      <RecordPageHeaderSubtitle>
                        {material.accession.taxon.commonName}
                      </RecordPageHeaderSubtitle>
                    )}
                  </RecordPageHeaderTitleGroup>
                  {recordHeaderButtons}
                </RecordPageHeaderContent>
              </RecordPageHeader>
            </>
          )}
          {menuContent}
        </div>
      </div>
    </div>
  )
}
