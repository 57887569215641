import CoreTabs from "@mui/material/Tabs"
import CoreTab from "@mui/material/Tab"
import type {TabsProps as CoreTabsProps} from "@mui/material/Tabs"
import type {TabProps as CoreTabProps} from "@mui/material/Tab"
import {withTestId} from "../../utils/with-test-id"
import {applyMui} from "../../utils/apply-mui"

export type TabsProps = CoreTabsProps
export type TabProps = CoreTabProps
export const Tabs = applyMui(withTestId, CoreTabs)
export const Tab = applyMui(withTestId, CoreTab)
