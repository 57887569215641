import {IconButton} from "@hortis/ui/button"
import {Menu2} from "@hortis/ui/icons/menu-2"
import {type ReactNode} from "react"
import {UserProfileRow} from "../user-profile-row/user-profile-row"

type MobileTopBarProps = {
  onMenuClick: () => void
  drawerOpen: boolean
  children: ReactNode | null
}
const MobileTopBar = ({
  children,
  drawerOpen,
  onMenuClick,
}: MobileTopBarProps) => {
  return (
    <div
      data-cy="mobile-topbar"
      className="flex w-full items-center justify-between border-b border-grey-200 bg-white px-4 py-3"
    >
      <IconButton
        className="!p-2"
        size={"xs"}
        variant="linkgray"
        icon={Menu2}
        data-cy="mobile-menu-button"
        onClick={onMenuClick}
        ariaLabel={
          drawerOpen ? "Close mobile navigation" : "Open mobile navigation"
        }
      />

      <div className="text-base font-medium text-grey-900 ">{children}</div>
      <UserProfileRow size="sm" showName={false} />
    </div>
  )
}

export {MobileTopBar}
