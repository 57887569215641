import {zodResolver} from "@hookform/resolvers/zod"
import type {RelationExistsComparator} from "generated/graphql"
import {useMemo} from "react"
import {FormProvider} from "react-hook-form"
import {RadioButton, RadioGroup} from "src/components/radio"
import {FilterTitle} from "src/features/filters/components/modal/filter-title"
import {match} from "ts-pattern"
import {z} from "zod"
import {
  FilterModal,
  type FilterModalProps,
} from "../../components/modal/filter-modal"
import {FilterModalButtons} from "../../components/modal/modal-buttons"
import {FilterModalError} from "../../components/modal/modal-error"
import {useFilterForm} from "../../components/modal/use-filter-form"
import type {FilterFormProps} from "../modals/types"

type InitialValue = RelationExistsComparator | null | undefined

const createDefaultValues = (initialValue: InitialValue) => {
  const defaultValues = {
    photosAvailable: initialValue?.eq ?? undefined,
    photosMissing: initialValue?.neq ?? undefined,
  }
  return {
    defaultValues,
    initialOperation:
      defaultValues.photosMissing === true
        ? ("photosMissing" as const)
        : ("photosAvailable" as const),
  }
}

const resolver = zodResolver(
  z.object({
    photosAvailable: z.boolean().nullable().optional(),
    photosMissing: z.boolean().nullable().optional(),
  }),
)

interface PhotosFilterFormProps {
  addModifyFilter: (changes: RelationExistsComparator) => void
  deleteFilter: (() => void) | undefined
  initialValue: InitialValue
  filterTitle: string
  testIdPrefix?: string
}

const PhotosFilterForm = ({
  addModifyFilter,
  deleteFilter,
  initialValue,
  onClose,
  filterTitle,
  testIdPrefix: _testIdPrefix,
}: Omit<FilterFormProps, "dispatchFilters" | "initialValue"> &
  PhotosFilterFormProps) => {
  const {error, operation, handleRadioChange, formMethods} = useFilterForm({
    resolver,
    ...createDefaultValues(initialValue),
  })

  const onSubmit = useMemo(
    () =>
      formMethods.handleSubmit(() => {
        match(operation)
          .with("photosAvailable", () => {
            addModifyFilter({
              eq: true,
            })
            onClose()
          })
          .with("photosMissing", () => {
            addModifyFilter({
              neq: true,
            })
            onClose()
          })
          .exhaustive()
      }),
    [formMethods, operation, onClose, addModifyFilter],
  )

  const testIdPrefix =
    _testIdPrefix ?? filterTitle.toLowerCase().replaceAll(" ", "-")

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={onSubmit}>
        <div className="mb-4 flex flex-col gap-4">
          <FilterTitle title={filterTitle} deleteFilter={deleteFilter} />
          <FilterModalError error={error} />
          <RadioGroup
            value={operation}
            onChange={handleRadioChange}
            label="Operation"
          >
            {(props) => (
              <div className="flex flex-col gap-3 pb-2">
                <RadioButton
                  label="Photos available"
                  selectedValue="photosAvailable"
                  testId={`${testIdPrefix}-available-radio`}
                  {...props}
                />
                <RadioButton
                  label="Photos missing"
                  selectedValue="photosMissing"
                  testId={`${testIdPrefix}-missing-radio`}
                  {...props}
                />
              </div>
            )}
          </RadioGroup>
        </div>
        <FilterModalButtons onCancel={onClose} testIdPrefix={testIdPrefix} />
      </form>
    </FormProvider>
  )
}

// Seperated from form component to make sure popover unmount causes state reset
export const PhotosFilterModal = ({
  open,
  onClose,
  anchorEl,
  ...props
}: Omit<FilterFormProps, "initialValue" | "dispatchFilters"> &
  FilterModalProps &
  PhotosFilterFormProps) => (
  <FilterModal anchorEl={anchorEl} open={open} onClose={onClose}>
    <PhotosFilterForm onClose={onClose} {...props} />
  </FilterModal>
)
