import {UploadCloud2} from "@hortis/ui/icons"
import Alert from "@mui/material/Alert"
import {type AxiosProgressEvent} from "axios"
import * as A from "fp-ts/Array"
import {type MaterialImageFieldsFragment} from "generated/graphql"
import type {Dispatch, ReactElement, SetStateAction} from "react"
import {useCallback, useMemo, useState} from "react"
import {FeaturedIcon} from "src/components/featured-icon/featured-icon"
import {useSnackbarStore} from "src/components/snackbar-controller/snackbar-store"
import type {StagedImage} from "src/features/records/material/components/material-images-tab/types"
import {useMobile} from "src/utils/hooks/media-query"
import {twMerge} from "tailwind-merge"
import {useFileSelect} from "../../../records/material/components/material-images-tab/use-file-select"

interface ImageUploadProps {
  materialId: string
  images: Array<StagedImage>
  setImages:
    | Dispatch<SetStateAction<Array<StagedImage>>>
    | ((images: Array<StagedImage>) => void)
  handleProgress?: (e: AxiosProgressEvent, imageId: string) => void
  handleError?: (error: string, imageId: string) => void
  handleComplete: (result: MaterialImageFieldsFragment, imageId: string) => void
  testId?: string
  disabled?: boolean
}

export const ImageDropzone = ({
  materialId,
  images,
  setImages,
  testId,
  handleComplete,
  handleProgress,
  disabled,
  handleError,
}: ImageUploadProps): ReactElement => {
  const isMobile = useMobile()
  const {setSnack} = useSnackbarStore()

  const [isHovering, setIsHovering] = useState(false)

  const handleMouseOver = useCallback(() => {
    setIsHovering(true)
  }, [])

  const handleMouseOut = useCallback(() => {
    setIsHovering(false)
  }, [])
  const handleFileRejection = useCallback(
    (errors: Array<string>) => {
      if (isMobile && A.isNonEmpty(errors)) {
        setSnack({
          type: "alert",
          data: {
            severity: "error",
            text: errors[0],
          },
        })
      }
    },
    [setSnack, isMobile],
  )

  const {
    getRootProps,
    getInputProps,
    // isDragActive,
    errors,
  } = useFileSelect({
    images,
    setImages,
    materialId,
    handleProgress,
    handleComplete,
    handleError,
    handleFileRejection,
  })

  const errorAlerts = useMemo(
    () =>
      errors.length > 0 ? (
        <div className="mt-4 flex flex-col items-center">
          {errors.map((errorMessage) => (
            <Alert
              data-cy="photo-dropzone-error"
              key={errorMessage}
              severity="error"
              variant="standard"
              sx={{boxShadow: "none"}}
            >
              {errorMessage}
            </Alert>
          ))}
        </div>
      ) : null,
    [errors],
  )

  return (
    <div
      {...getRootProps()}
      data-cy={testId}
      onMouseEnter={handleMouseOver}
      onMouseLeave={handleMouseOut}
      className={twMerge(
        "flex h-full w-full cursor-pointer flex-col items-center justify-center gap-3 self-stretch rounded-lg border border-dashed border-grey-200 bg-white px-6 py-4 transition hover:border-solid",
        isHovering &&
          disabled !== true &&
          "border-primary-300 bg-primary-25 text-primary-600",
        disabled === true && " cursor-default border-grey-200 bg-grey-25",
      )}
    >
      <input disabled={disabled} {...getInputProps()} />
      <FeaturedIcon
        className="transition"
        color={isHovering && disabled !== true ? "primary" : "gray"}
      >
        <UploadCloud2 size={20} />
      </FeaturedIcon>
      <div>
        <h6
          className={twMerge(
            "text-sm text-grey-500 transition",
            isHovering && disabled !== true && "text-primary-600",
            disabled === true && "text-grey-500",
          )}
        >
          <span
            className={twMerge(
              "font-medium text-primary-700 transition",
              disabled === true && "text-grey-300",
            )}
          >
            Click to upload{" "}
          </span>
          or drag and drop
        </h6>
        <p className="text-xs font-normal ">SVG, PNG, JPG or GIF (max. 10MB)</p>
      </div>
      {errorAlerts}
    </div>
  )
}
