import {Alert, AlertContent, AlertText} from "@hortis/ui/alert"
import {CommandEmpty, CommandGroup, CommandItem} from "@hortis/ui/command"
import {AlertTriangle, Download1, Tag1, Trash3} from "@hortis/ui/icons"
import {useParams, useRouteContext} from "@tanstack/react-router"
import {useGetAccessionsQuery} from "generated/graphql"
import {useCallback, useState} from "react"
import CommandMenuAccessionTagsPage from "src/components/command-menu/command-menu-pages/tags/accession-tags"
import {match} from "ts-pattern"
import {ActionsMenu} from "../actions-menu"

type AccessionsActionMenuProps = {
  openTrashRecordModal?: (open: boolean) => void
  onRequestDownload: () => void
  selectedRecordsIDs: ReadonlyArray<string>
  open: boolean
  toggleOpen: () => void
}

type Page = "tags" | "actions"

const AccessionsActionMenu = ({
  open,
  toggleOpen,
  onRequestDownload,
  openTrashRecordModal,
  selectedRecordsIDs,
}: AccessionsActionMenuProps) => {
  const {subdomain} = useRouteContext({from: "/_layout/sites/$siteSlug"})
  const {siteSlug} = useParams({from: "/_layout/sites/$siteSlug"})

  const [{data, error}] = useGetAccessionsQuery({
    variables: {
      organisationSubdomain: subdomain,
      collectionSiteSlug: siteSlug,
      last: selectedRecordsIDs.length,
      filters: {
        id: {
          in: selectedRecordsIDs,
        },
      },
    },
    pause: !open,
  })

  const [search, setSearch] = useState("")
  const [menuPage, setMenuPage] = useState<Page>("actions")
  const clearSearch = () => {
    setSearch("")
  }

  const resetMenu = useCallback(() => {
    setMenuPage("actions")
    clearSearch()
  }, [])
  const closeAndResetMenu = useCallback(() => {
    toggleOpen()
    resetMenu()
  }, [resetMenu, toggleOpen])

  const selectedRows = data?.org?.site?.result?.nodes ?? []

  return (
    <ActionsMenu
      open={open}
      title={`${selectedRecordsIDs.length} accessions selected`}
      closeAndResetMenu={closeAndResetMenu}
      search={search}
      setSearch={setSearch}
      placeholder={match(menuPage)
        .with("actions", () => "Search actions...")
        .with("tags", () => "Add / remove tags...")
        .exhaustive()}
    >
      {data == null && error != null ? (
        <CommandGroup>
          <div className="p-3">
            <Alert
              color="warning"
              className="mb-4"
              data-cy="accession-action-menu-alert"
            >
              <AlertTriangle />
              <AlertContent>
                <AlertText>Something went wrong.</AlertText>
              </AlertContent>
            </Alert>
          </div>
        </CommandGroup>
      ) : (
        match(menuPage)
          .with("actions", () => (
            <>
              <CommandEmpty>No results</CommandEmpty>
              <CommandGroup>
                <CommandItem
                  onSelect={() => {
                    setMenuPage("tags")
                    clearSearch()
                  }}
                  data-cy="tags-menu-item"
                >
                  <Tag1 className="text-grey-500" />
                  Add / remove tags
                </CommandItem>

                <CommandItem
                  onSelect={() => {
                    onRequestDownload()
                    closeAndResetMenu()
                  }}
                  data-cy="download-menu-item"
                >
                  <Download1 className="text-grey-500" />
                  Download
                </CommandItem>

                {openTrashRecordModal && (
                  <CommandItem
                    onSelect={() => {
                      openTrashRecordModal(true)
                      closeAndResetMenu()
                    }}
                    data-cy="trash-menu-item"
                  >
                    <Trash3 className="text-grey-500" />
                    Move to trash
                  </CommandItem>
                )}
              </CommandGroup>
            </>
          ))
          .with("tags", () => (
            <CommandMenuAccessionTagsPage
              menuPage={menuPage}
              open={open}
              selectedRows={selectedRows}
              selectedRecordsIDs={selectedRecordsIDs}
              search={search}
              clearSearch={clearSearch}
            />
          ))
          .exhaustive()
      )}
    </ActionsMenu>
  )
}

export default AccessionsActionMenu
