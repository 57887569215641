import {A, O, pipe} from "@mobily/ts-belt"
import type {AccessionsListFieldsFragment} from "generated/graphql"

export const getRecordPhotos = (
  accessions:
    | ReadonlyArray<Pick<AccessionsListFieldsFragment, "plantMaterial">>
    | undefined
    | null,
) => {
  if (accessions == null) {
    return {thumbnail: null, photos: null}
  }

  const materials = pipe(
    accessions,
    A.filterMap((a) =>
      a.plantMaterial == null ? O.None : O.Some(a.plantMaterial),
    ),
    A.flat,
  )

  // Get the primary image from the first material that has a primary image
  const primaryImage = pipe(
    materials,
    A.filterMap((m) =>
      m.primaryImage == null ? O.None : O.Some(m.primaryImage),
    ),
    A.head,
  )

  return {
    photos: pipe(
      materials,
      A.map(({images}) => images),
      A.filter((p): p is NonNullable<typeof p> => p != null),
      A.flat,
    ),
    thumbnail: pipe(
      primaryImage,
      O.match(
        (image) => image,
        // If there is no primary image, use the first image from the first material that has images
        () =>
          pipe(
            materials,
            A.filterMap((m) => {
              return m.images == null ? O.None : O.Some(m.images)
            }),
            A.flat,
            A.head,
            O.match(
              (image) => image,
              () => null,
            ),
          ),
      ),
    ),
  }
}

export const getRecordPhotosThumbnailUrl = (
  accessions: Parameters<typeof getRecordPhotos>[0],
) => {
  const {thumbnail} = getRecordPhotos(accessions)
  return thumbnail == null
    ? undefined
    : `${thumbnail.bucketName}/${thumbnail.fileName}`
}
