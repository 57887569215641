import {Link, type LinkOptions} from "@tanstack/react-router"
import {twMerge} from "tailwind-merge"

interface HierarchyListItemProps extends LinkOptions {
  children: React.ReactNode
  className?: string
  selected?: boolean
  root?: boolean
  testId?: string
}

const HierarchyListItem = ({
  children,
  className,
  selected = false,
  root = false,
  testId,
  ...props
}: HierarchyListItemProps) => {
  return (
    <Link
      {...props}
      className={twMerge("hierarchy-list-item relative block", className)}
      data-cy={testId ?? "hierarchy-list-item"}
    >
      {!root && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="24"
          viewBox="0 0 14 25"
          fill="none"
          className="absolute -left-[13.5px] -top-0.5 bg-none stroke-grey-200"
        >
          <path
            d="M1 1L1 19C1 21.2091 2.79086 23 5 23H13"
            strokeWidth="1.5"
            strokeLinecap="square"
          />
        </svg>
      )}
      <div
        className={twMerge(
          "my-0.5 flex h-10 w-fit items-center gap-3 truncate rounded-lg px-3.5 py-2.5 transition-colors hover:bg-grey-50",
          selected &&
            "bg-primary-50 hover:bg-primary-100 [&_span]:text-primary-700 [&_svg]:text-primary-400  ",
        )}
      >
        {children}
      </div>
    </Link>
  )
}

export default HierarchyListItem
