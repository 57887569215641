import * as t from "io-ts"
import {NonEmptyString} from "io-ts-types"
import * as E from "fp-ts/Either"
import {pipe} from "fp-ts/function"
import {formatValidationErrors} from "io-ts-reporters"

const zeroOrOne = t.keyof({
  "0": null,
  "1": null,
})

const optionalString = t.union([NonEmptyString, t.undefined])

/**
 * Don't use t.partial for optional env vars.
 * If an env var is truly optional,
 * (which means webpack's Define plugin won't necessarily replace it in the build...
 * think carefully if you want this) then it must be `t.union(type, t.undefined])`.
 *
 * By having all env vars required (undefined or otherwise), this forces `envVars`
 * to be kept in sync with the set of environment variables we actually expect.
 *
 * Don't include private variables in here, i.e. anything NEXT_FOO instead of NEXT_PUBLIC_FOO
 * as currently /vars prints all of these out
 * (and other page that includes the default export config in markup)
 */
const IOEnv = t.type({
  NEXT_PUBLIC_API_URL: NonEmptyString,
  NEXT_PUBLIC_AUTH_CLIENT_ID: NonEmptyString,
  NEXT_PUBLIC_AUTH_AUDIENCE: NonEmptyString,
  NEXT_PUBLIC_AUTH_DOMAIN: NonEmptyString,
  NEXT_PUBLIC_ENV_STAGE: NonEmptyString,
  // Enables auth debugging widget, won't appear in build otherwise
  NEXT_PUBLIC_AUTH_DEBUGGER: zeroOrOne,
  // Turns off auth guards entirely, still requires access token for authed end points
  NEXT_PUBLIC_DISABLE_AUTH_GUARDS: zeroOrOne,
  // Turns on Cypress auth workaround, loading a dummy token from a cookie
  NEXT_PUBLIC_CYPRESS_AUTH_MODE: t.keyof({ALLOW: null, DISALLOW: null}),
  NEXT_PUBLIC_BUILD_NUMBER: optionalString,
  NEXT_PUBLIC_BUILD_HASH: optionalString,
  NEXT_PUBLIC_BUGSNAG_API_KEY: optionalString,
  NEXT_PUBLIC_GTM_API_KEY: optionalString,
})

type Env = t.TypeOf<typeof IOEnv>

export interface ValidationFailure {
  message: string
  explanation: string
}

export interface Config {
  publicApiUrl: string
  publicAuthDomain: string
  publicAuthClientId: string
  publicAuthAudience: string
  envStage: string
  buildNumber?: string
  buildHash?: string
  bugsnagApiKey?: string
  gtmApiKey?: string
}

const mapEnvVarsToConfig = (env: Env): Config => ({
  publicApiUrl: env.NEXT_PUBLIC_API_URL,
  publicAuthDomain: env.NEXT_PUBLIC_AUTH_DOMAIN,
  publicAuthClientId: env.NEXT_PUBLIC_AUTH_CLIENT_ID,
  publicAuthAudience: env.NEXT_PUBLIC_AUTH_AUDIENCE,
  envStage: env.NEXT_PUBLIC_ENV_STAGE,
  buildNumber: env.NEXT_PUBLIC_BUILD_NUMBER,
  buildHash: env.NEXT_PUBLIC_BUILD_HASH,
  bugsnagApiKey: env.NEXT_PUBLIC_BUGSNAG_API_KEY,
  gtmApiKey: env.NEXT_PUBLIC_GTM_API_KEY,
})

// eslint-disable-next-line no-secrets/no-secrets
/* 
Note: In order to keep server-only secrets safe, Next.js replaces import.meta.env.* with the correct values at build time. This means that process.env is not a standard JavaScript object, so you’re not able to use object destructuring. Environment variables must be referenced e.g. process.env.NEXT_PUBLIC_
This also means that client side import.meta.env.NEXT_PUBLIC_ vars are replaced at run build time by webpack. This means that in order to validate that they have values or the correct types we need to map them to and object that can be validated as process.env is not available to interogate client side.
*/

export const envVars: Record<keyof Env, unknown> = {
  NEXT_PUBLIC_API_URL: import.meta.env.NEXT_PUBLIC_API_URL as unknown,
  NEXT_PUBLIC_AUTH_CLIENT_ID: import.meta.env
    .NEXT_PUBLIC_AUTH_CLIENT_ID as unknown,
  NEXT_PUBLIC_AUTH_AUDIENCE: import.meta.env
    .NEXT_PUBLIC_AUTH_AUDIENCE as unknown,
  NEXT_PUBLIC_AUTH_DOMAIN: import.meta.env.NEXT_PUBLIC_AUTH_DOMAIN as unknown,
  NEXT_PUBLIC_ENV_STAGE: import.meta.env.NEXT_PUBLIC_ENV_STAGE as unknown,
  NEXT_PUBLIC_DISABLE_AUTH_GUARDS: import.meta.env
    .NEXT_PUBLIC_DISABLE_AUTH_GUARDS as unknown,
  NEXT_PUBLIC_CYPRESS_AUTH_MODE: import.meta.env
    .NEXT_PUBLIC_CYPRESS_AUTH_MODE as unknown,
  NEXT_PUBLIC_AUTH_DEBUGGER: import.meta.env
    .NEXT_PUBLIC_AUTH_DEBUGGER as unknown,
  NEXT_PUBLIC_BUILD_NUMBER: import.meta.env.NEXT_PUBLIC_BUILD_NUMBER as unknown,
  NEXT_PUBLIC_BUILD_HASH: import.meta.env.NEXT_PUBLIC_BUILD_HASH as unknown,
  NEXT_PUBLIC_BUGSNAG_API_KEY: import.meta.env
    .NEXT_PUBLIC_BUGSNAG_API_KEY as unknown,
  NEXT_PUBLIC_GTM_API_KEY: import.meta.env.NEXT_PUBLIC_GTM_API_KEY as unknown,
}

export default pipe(
  IOEnv.decode(envVars),
  E.mapLeft(formatValidationErrors),
  E.getOrElseW((errors) => {
    throw new Error(errors.join("\n"))
  }),
  mapEnvVarsToConfig,
)
