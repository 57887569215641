import {useCallback, useEffect, useRef, useState} from "react"
import {useSmallDesktop} from "src/utils/hooks/media-query"

export type RecordSectionId =
  | "details"
  | "accession-taxon"
  | "photos"
  | "observations"
  | "activity"
  | "related-records"
  | "wild-collection"
  | "taxon"
  | "identification"
  | "materials"
  | "derived-data"
  | "interpretation"
  | "location"
  | "tags"
  | "notes"

const useRecordTableOfContents = (sectionAnchors: Array<string>) => {
  const isSmallDesktop = useSmallDesktop()
  const scrollTimeout = useRef<ReturnType<typeof setTimeout>>()
  const observer = useRef<IntersectionObserver>()
  const blockObserver = useRef<boolean>(false)
  const [selected, setSelected] = useState<RecordSectionId>("details")
  const [scrollingToAnchor, setScrollingToAnchor] = useState(false)

  useEffect(() => {
    /*
     * Sort observation entries into the order of the page sections
     * and set the selected tab to the first visible section
     */
    const handleObsever: IntersectionObserverCallback = (entries) => {
      if (blockObserver.current) {
        return
      }
      entries.sort(
        (a, b) =>
          sectionAnchors.indexOf(a.target.id) -
          sectionAnchors.indexOf(b.target.id),
      )
      for (const entry of entries) {
        if (entry.isIntersecting) {
          const index = sectionAnchors.indexOf(entry.target.id)
          if (index !== -1) {
            setSelected(entry.target.id as RecordSectionId)
            break
          }
        }
      }
    }

    /*
     * Only observe the top 10% of the viewport on xl screens and top 30% on smaller screens
     * Use multiple thresholds to avoid missing target during fast scroll while observer is blocked
     */
    observer.current = new IntersectionObserver(handleObsever, {
      rootMargin: `0% 0% ${isSmallDesktop ? "-70%" : "-90%"} 0%`,
      threshold: [0, 0.25, 0.5, 0.75, 1],
    })

    const elements = document.querySelectorAll(
      sectionAnchors.map((anchor) => `#${anchor}`).join(", "),
    )
    elements.forEach((elem) => observer.current?.observe(elem))

    return () => observer.current?.disconnect()
  }, [isSmallDesktop, sectionAnchors])

  /*
   * Block observer events when scrolling to anchor from handleSelect.
   * This prevents tabs flickering as the page smooth scrolls to the anchor
   */
  useEffect(() => {
    if (!scrollingToAnchor) {
      return
    }

    const handleScroll = () => {
      clearTimeout(scrollTimeout.current)
      scrollTimeout.current = setTimeout(function () {
        removeEventListener("scroll", handleScroll)
        blockObserver.current = false
        setScrollingToAnchor(false)
      }, 100)
    }

    addEventListener("scroll", handleScroll)
    blockObserver.current = true

    return () => {
      removeEventListener("scroll", handleScroll)
    }
  }, [scrollingToAnchor])

  const handleSelect = useCallback(
    (href: RecordSectionId) => {
      setSelected(href)
      const anchor = sectionAnchors.find((anchor) => anchor === href)
      if (anchor != null) {
        document
          .querySelector(`#${anchor}`)
          ?.scrollIntoView({behavior: "smooth"})
        setScrollingToAnchor(true)
      }
    },
    [sectionAnchors],
  )

  return [selected, handleSelect] as const
}

export default useRecordTableOfContents
