import {lazy, Suspense} from "react"
import {LoadingPage} from "@hortis/ui/loading"
import type {MapProps as _MapProps} from "./map"

export type MapProps = _MapProps

const LazyMap = lazy(() => import("./map"))
export const Map = (props: MapProps) => {
  return (
    <Suspense fallback={<LoadingPage />}>
      <LazyMap {...props} />
    </Suspense>
  )
}
