import type {ComponentProps} from "react"
import {DayPicker, type DateRange} from "react-day-picker"
import {twMerge} from "tailwind-merge"
import {ChevronLeft, ChevronRight} from "../icons"

export const areDatesEqual = (date1: Date, date2: Date): boolean => {
  return date1.toLocaleDateString() === date2.toLocaleDateString()
}

export const isDateInRange = (
  date: Date,
  selectedRange: DateRange | undefined,
): boolean => {
  if (
    selectedRange == null ||
    selectedRange.from == null ||
    selectedRange.to == null
  ) {
    return false
  }
  return date >= selectedRange.from && date <= selectedRange.to
}

const IconRight = () => <ChevronRight />
const IconLeft = () => <ChevronLeft />

type CalendarProps = ComponentProps<typeof DayPicker>

export const Calendar = ({className, classNames, ...props}: CalendarProps) => {
  const today = new Date()

  const selectedRange =
    typeof props.selected === "object" && "from" in props.selected
      ? props.selected
      : undefined

  const condition =
    selectedRange?.from != null &&
    selectedRange.to != null &&
    !areDatesEqual(selectedRange.from, selectedRange.to)

  return (
    <DayPicker
      fixedWeeks
      showOutsideDays
      weekStartsOn={1}
      modifiers={{
        mondays: {dayOfWeek: [1]},
        sundays: {dayOfWeek: [0]},
        today: today,
      }}
      defaultMonth={
        selectedRange?.from == null
          ? props.selected instanceof Date
            ? props.selected
            : undefined
          : selectedRange.from
      }
      className={twMerge("z-0 w-fit", className)}
      classNames={{
        months: twMerge("flex flex-col sm:flex-row w-fit ", classNames?.months),
        month: twMerge(
          "space-y-2 p-6 pt-5",
          props.numberOfMonths != null &&
            props.numberOfMonths > 1 &&
            "first:border-r border-grey-200",
          classNames?.month,
        ),
        caption: "flex justify-center relative items-center h-9",
        caption_label:
          "absolute bottom-0 te left-0 right-0 top-1 flex items-center justify-center ",
        nav: "space-x-1 flex items-center rounded-md h-full justify-between p-0.5 w-full",
        nav_button_previous:
          "!absolute left-0.5 p-2 rounded-md text-grey-500 hover:text-grey-700",
        nav_button_next:
          "!absolute right-0.5 rounded-md p-2 text-grey-500 hover:text-grey-700",
        table: "text-sm ",
        head_row: "flex text-sm ",
        head_cell: twMerge(
          "m-0 flex h-10 items-center justify-center p-0 bor w-10 !font-medium",
        ),
        row: "flex mt-1",
        cell: "relative h-10 w-10 p-0 flex items-center justify-center font-normal text-grey-700 m-0",
        day: "h-full w-full p-0 text-center outline-0 rounded-full focus-visible:ring-2 ring-grey-300/50",
        day_selected:
          "bg-primary-600 rounded-full text-white hover:bg-primary-600 !ring-primary-300/50",
        day_disabled: "text-grey-300 hover:bg-white",
        day_outside: "text-grey-400",
        button:
          "focus-visible:ring-4 focus-visible:ring-grey-300/50 outline-none hover:bg-grey-50",
      }}
      modifiersClassNames={{
        range_start:
          // adds a psuedo element to the right of the range start which connects it to the rest
          twMerge(
            "text-white !rounded-3xl",
            condition &&
              selectedRange.from?.getDay() !== 0 &&
              "after:content-[' '] after:text-primary-700 after:absolute after:top-0 after:left-[50%] after:bg-primary-50 after:w-10 after:h-10 after:z-[-1]",
          ),
        range_end:
          // adds a psuedo element to the left of the range end which connects it to the rest
          twMerge(
            "text-white !rounded-3xl",
            condition &&
              selectedRange.to?.getDay() !== 1 &&
              "after:content-[' '] after:text-primary-700 after:absolute after:top-0 after:right-[50%] after:bg-primary-50 after:w-10 after:h-10 after:z-[-1]",
          ),
        today:
          "before:content-[' '] before:text-primary-700 before:absolute before:bottom-2 before:left-[46%] before:bg-primary-600 before:w-1 before:h-1 before:z-[1] before:rounded-full",
        mondays: "rounded-l-full",
        sundays: "rounded-r-full",
        range_middle: "!bg-primary-50 !text-grey-700 rounded-none",
      }}
      components={{
        IconRight,
        IconLeft,
      }}
      {...props}
    />
  )
}
