import {useCallback} from "react"
import {SortDirection, TaxaColumn} from "generated/graphql"
import type {TaxaOrderByInput} from "generated/graphql"
import type {SelectProps} from "src/components/select"
import {Select} from "src/components/select"
import {SortLabel} from "../plant-materials/sort-select"
import type {TaxaFiltersProps} from "./types"

const options = [
  {
    value: null,
    label: "None",
  },
  {
    value: {
      field: TaxaColumn.ScientificName,
      direction: SortDirection.Asc,
    },
    label: <SortLabel label="Scientific name" subLabel="(A to Z)" />,
  },
  {
    value: {
      field: TaxaColumn.ScientificName,
      direction: SortDirection.Desc,
    },
    label: <SortLabel label="Scientific name" subLabel="(Z to A)" />,
  },
  {
    value: {
      field: TaxaColumn.CommonName,
      direction: SortDirection.Asc,
    },
    label: <SortLabel label="Common name" subLabel="(A to Z)" />,
  },
  {
    value: {
      field: TaxaColumn.CommonName,
      direction: SortDirection.Desc,
    },
    label: <SortLabel label="Common name" subLabel="(Z to A)" />,
  },
  {
    value: {
      field: TaxaColumn.Authorship,
      direction: SortDirection.Asc,
    },
    label: <SortLabel label="Authorship" subLabel="(A to Z)" />,
  },
  {
    value: {
      field: TaxaColumn.Authorship,
      direction: SortDirection.Desc,
    },
    label: <SortLabel label="Authorship" subLabel="(Z to A)" />,
  },
  {
    value: {
      field: TaxaColumn.FamilyName,
      direction: SortDirection.Asc,
    },
    label: <SortLabel label="Family" subLabel="(A to Z)" />,
  },
  {
    value: {
      field: TaxaColumn.FamilyName,
      direction: SortDirection.Desc,
    },
    label: <SortLabel label="Family" subLabel="(Z to A)" />,
  },
  {
    value: {
      field: TaxaColumn.LastUpdated,
      direction: SortDirection.Asc,
    },
    label: <SortLabel label="Last edited" subLabel="(Oldest first)" />,
  },
  {
    value: {
      field: TaxaColumn.LastUpdated,
      direction: SortDirection.Desc,
    },
    label: <SortLabel label="Last edited" subLabel="(Newest first)" />,
  },
]

export const TaxaSortSelect = ({
  orderBy,
  updateOrderBy,
  ...props
}: Pick<TaxaFiltersProps, "orderBy" | "updateOrderBy"> &
  Partial<SelectProps<TaxaOrderByInput | null>>) => {
  const onOrderByChange = useCallback(
    (val: TaxaOrderByInput | null) => {
      updateOrderBy(val ?? undefined)
    },
    [updateOrderBy],
  )

  return (
    <Select
      {...props}
      options={options}
      onChange={onOrderByChange}
      value={orderBy ?? null}
      placeholder="Sort by"
    />
  )
}
