// eslint-disable-next-line import/no-unresolved -- this is a virtual module generated by Vite
import {useRegisterSW} from "virtual:pwa-register/react"
import {useCallback, useEffect, useState} from "react"
import {colors} from "src/colors"
import {Stars3} from "@hortis/ui/icons"
import {Notification} from "./snackbar-controller/notification/notification"

const THIRTY_MINUTES_IN_MS = 30 * 60 * 1000

export function NewVersionPrompt() {
  const [loadedDurationMet, setLoadedDurationMet] = useState(false)
  const {
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW()

  const handleClose = useCallback(() => {
    setNeedRefresh(false)
  }, [setNeedRefresh])

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoadedDurationMet(true)
    }, THIRTY_MINUTES_IN_MS)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  const showPrompt = needRefresh && loadedDurationMet

  return (
    <Notification
      open={showPrompt}
      onClose={handleClose}
      hideDuration={null}
      text="New version available"
      iconProps={{
        color: "primary",
        icon: <Stars3 color={colors.primary[600]} size="20px" />,
      }}
      supportingText="A new version of Hortis is available. Make sure to save your progress before reloading."
      primaryAction={{text: "Reload", onClick: updateServiceWorker}}
    />
  )
}
