import {Button} from "@hortis/ui/button"
import {
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@hortis/ui/dialog"
import {useRestoreAccessionMutation} from "generated/graphql"
import {type MouseEventHandler} from "react"
import {useSnackbarStore} from "src/components/snackbar-controller/snackbar-store"
import {stopPropagation} from "src/features/collection/components/stop-event-propagation"
import {onFailure, onSuccess} from "src/notification-snack-utils"

interface RestoreAccessionDialogProps {
  id: string
  onSuccess?: () => void
}

export const RestoreAccessionDialogContent = ({
  id,
  onSuccess: onSuccessProp,
}: RestoreAccessionDialogProps) => {
  const {setSnack} = useSnackbarStore()
  const [{fetching}, restoreAccession] = useRestoreAccessionMutation()

  const handleSubmit: MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.stopPropagation()
    const res = await restoreAccession({
      accessionId: id,
    })

    if (res.data?.restoreAccession?.__typename !== "Accession") {
      onFailure(setSnack)(new Error("Failed to restore accession"))
      return
    }

    onSuccess(setSnack)("Successfully restored accession")
    onSuccessProp?.()
  }

  return (
    <DialogContent data-cy="restore-accession-dialog">
      <DialogHeader>
        <DialogTitle data-cy="restore-accession-dialog-title">
          Restore accession?
        </DialogTitle>
        <DialogDescription data-cy="restore-accession-dialog-body">
          This will restore 1 accession record.
        </DialogDescription>
      </DialogHeader>
      <DialogFooter>
        <DialogClose onClick={stopPropagation} asChild>
          <Button size="lg" fullWidth testId="restore-accession-cancel">
            Cancel
          </Button>
        </DialogClose>
        <Button
          size="lg"
          onClick={handleSubmit}
          variant="primary"
          loading={fetching}
          fullWidth
          testId="restore-accession-confirm"
        >
          Restore
        </Button>
      </DialogFooter>
    </DialogContent>
  )
}
