export {flexRender, getCoreRowModel, useReactTable} from "@tanstack/react-table"
import {
  type ColumnFilter,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table"
import type {
  RowData,
  RowSelectionState,
  TableOptions,
  Updater,
  VisibilityState,
} from "@tanstack/table-core"
import type {MutableRefObject} from "react"
import {useCallback, useMemo, useRef, useState} from "react"
import {
  useDateStringFormatter,
  type DateStringFormatter,
} from "src/utils/hooks/language"
import {useIsScrolled} from "src/utils/hooks/scrolled"
import {useShiftHeld} from "src/utils/hooks/shift-held"
import {useStickyState} from "src/utils/hooks/sticky-state"
import {getSelectedRowCount} from "src/utils/hooks/tables/select-mode"
import {A, pipe} from "@mobily/ts-belt"
import {useOrganisationSettings} from "src/utils/hooks/organisation-from-domain"
import {useSnackbarStore} from "../snackbar-controller/snackbar-store"

declare module "@tanstack/table-core" {
  interface ColumnMeta<TData extends RowData, TValue> {
    className?: string
    headerClassName?: string
    sticky?: boolean
    invisible?: boolean
    _?: Array<TData | TValue> // To avoid unused generics
  }
}
declare module "@tanstack/table-core" {
  interface TableMeta<TData extends RowData> {
    isScrolledHorizontal?: boolean
    shiftHeld?: MutableRefObject<boolean>
    formatDateTime: DateStringFormatter
    flags?: Record<string, boolean>
    _?: Array<TData> // To avoid unused generics
  }
}

export type UseTableProps<TData extends RowData> = Omit<
  TableOptions<TData>,
  "getCoreRowModel"
> & {
  tableId: string
  defaultColumnVisibility?: VisibilityState
  disabledColumnIds?: Array<string>
  metaFlags?: Record<string, boolean>
}

export const useTable = <TData extends RowData & {id: string}>({
  tableId,
  defaultColumnVisibility,
  disabledColumnIds,
  columns,
  metaFlags,
  ...props
}: UseTableProps<TData>) => {
  const shiftHeld = useShiftHeld()
  const mobileListRef = useRef(null)
  const {setSnack} = useSnackbarStore()
  const {isScrolledHorizontal, attachRef, ref} = useIsScrolled()
  const [rowSelection, setRowSelection] = useState({})
  const [columnFilters, setColumnFilters] = useState<Array<ColumnFilter>>([])
  const [columnVisibility, setColumnVisibility] =
    useStickyState<VisibilityState>(defaultColumnVisibility ?? {}, tableId)
  const formatDateTime = useDateStringFormatter({
    dateStyle: "medium",
  })
  const organisationSettings = useOrganisationSettings()

  const filteredColumns = useMemo(
    () =>
      disabledColumnIds == null
        ? columns
        : (pipe(
            columns,
            A.filter(({id}) => id == null || !disabledColumnIds.includes(id)),
          ) as typeof columns),
    [columns, disabledColumnIds],
  )

  const onRowSelectionChange = useCallback(
    (onChangeFn: Updater<RowSelectionState>) => {
      if (typeof onChangeFn !== "function") {
        setRowSelection(onChangeFn)
        return
      }
      const newSelection = onChangeFn(rowSelection)
      if (getSelectedRowCount(newSelection) < 300) {
        setRowSelection(newSelection)
      } else {
        setSnack({
          type: "alert",
          data: {text: "Max selection reached (300)", severity: "error"},
        })
      }
    },
    [rowSelection, setSnack],
  )

  const table = useReactTable({
    ...props,
    columns: filteredColumns,
    getRowId: (row) => row.id,
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange,
    onColumnVisibilityChange: setColumnVisibility,
    onColumnFiltersChange: setColumnFilters,
    state: {
      rowSelection,
      columnVisibility: {...defaultColumnVisibility, ...columnVisibility},
      columnFilters,
    },
    meta: {
      isScrolledHorizontal,
      shiftHeld,
      formatDateTime,
      flags: {...metaFlags, ...organisationSettings},
    },
  })

  return {table, ref: attachRef, tableRef: ref, mobileListRef}
}
