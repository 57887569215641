import CoreTableContainer from "@mui/material/TableContainer"
import type {TableContainerProps} from "@mui/material/TableContainer"
import CoreTable from "@mui/material/Table"
import type {TableProps} from "@mui/material/Table"
import CoreTableRow from "@mui/material/TableRow"
import type {TableRowProps} from "@mui/material/TableRow"
import CoreTableCell from "@mui/material/TableCell"
import type {TableCellProps} from "@mui/material/TableCell"
import CoreTableHead from "@mui/material/TableHead"
import type {TableHeadProps} from "@mui/material/TableHead"
import CoreTableBody from "@mui/material/TableBody"
import type {TableBodyProps} from "@mui/material/TableBody"
import {applyMui} from "../../utils/apply-mui"
import {withTestId} from "../../utils/with-test-id"

export const TableContainer: (props: TableContainerProps) => JSX.Element =
  CoreTableContainer

export const Table = applyMui(
  withTestId,
  CoreTable as (props: TableProps) => JSX.Element,
)

export const TableRow = applyMui(
  withTestId,
  CoreTableRow as (props: TableRowProps) => JSX.Element,
)
export const TableCell = applyMui(
  withTestId,
  CoreTableCell as (props: TableCellProps) => JSX.Element,
)

export const TableHead = applyMui(
  withTestId,
  CoreTableHead as (props: TableHeadProps) => JSX.Element,
)

export const TableBody = applyMui(
  withTestId,
  CoreTableBody as (props: TableBodyProps) => JSX.Element,
)
