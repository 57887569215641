import {z} from "zod"
import create from "zustand"
import type {LayerType} from "./types"

export type SetDevicePositionLayerEnabled = (enabled: boolean) => void
export type SetMapLayerType = (layerType: LayerType) => void

interface MapOptionsState {
  devicePositionLayerEnabled: boolean
  setDevicePositionLayerEnabled: SetDevicePositionLayerEnabled
  layerType: LayerType
  setMapLayerType: SetMapLayerType
}

export const useMapOptionsStore = create<MapOptionsState>((set) => ({
  devicePositionLayerEnabled:
    window.localStorage.getItem("devicePositionLayerEnabled") === "true",
  setDevicePositionLayerEnabled: (enabled) => {
    set({devicePositionLayerEnabled: enabled})
    window.localStorage.setItem("devicePositionLayerEnabled", String(enabled))
  },
  layerType: (() => {
    const res = z
      .enum(["osm", "bing", "custom"])
      .safeParse(window.localStorage.getItem("mapLayerType"))

    return res.success ? res.data : "osm"
  })(),
  setMapLayerType: (layerType) => {
    set({layerType})
    window.localStorage.setItem("mapLayerType", layerType)
  },
}))
