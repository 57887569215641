import type {Theme} from "@mui/material/styles"
import type {CirclePaint} from "mapbox-gl"
import type {LayerProps} from "react-map-gl"
import {colors} from "src/colors"

const sourceName = "geometry"

export const themedClusterLayer = (
  theme: Theme,
  opacity?: number,
): LayerProps => ({
  id: "clusters",
  type: "circle",
  source: sourceName,
  filter: ["has", "point_count"],
  paint: {
    "circle-color": theme.palette.background.paper,
    "circle-radius": 16,
    "circle-stroke-width": 3,
    "circle-stroke-color": theme.palette.background.paper,
    "circle-stroke-opacity": opacity ?? 0.5,
    "circle-opacity": opacity ?? 1,
  },
})

export const themedClusterCountLayer = (
  theme: Theme,
  opacity?: number,
): LayerProps => ({
  id: "cluster-count",
  type: "symbol",
  source: sourceName,
  filter: ["has", "point_count"],
  layout: {
    "text-field": "{point_count_abbreviated}",
    "text-font": ["Inter Bold", "Helvetica", "Arial", "sans-serif"],
    "text-size": 12,
  },
  paint: {
    "icon-opacity": opacity ?? 1,
    "text-opacity": opacity ?? 1,
    "text-color": theme.palette.grey[900],
  },
})

export const circleRadiusStyle: CirclePaint["circle-radius"] = [
  "interpolate",
  ["exponential", 0.5],
  ["zoom"],
  17,
  4,
  18,
  6,
  19,
  8,
  20,
  10,
]

export const circleStrokeWidthStyle: CirclePaint["circle-stroke-width"] = [
  "interpolate",
  // Set the exponential rate of change to 0.5
  ["exponential", 0.5],
  ["zoom"],
  17,
  2,
  18,
  3,
  19,
  4,
  20,
  5,
]

export const themedUnclusteredPointLayerOrange = (
  theme: Theme,
  opacity?: number,
  // color?: string,
): LayerProps => ({
  id: "unclustered-point-orange",
  type: "circle",
  source: "map-geometry-2",
  filter: ["!", ["has", "point_count"]],
  paint: {
    "circle-opacity": opacity ?? 1,
    "circle-stroke-opacity": opacity ?? 0.9,
    "circle-color": colors.yellow[400],

    "circle-radius": circleRadiusStyle,
    "circle-stroke-width": circleStrokeWidthStyle,
    "circle-stroke-color": theme.palette.background.default,
  },
})

export const themedUnclusteredPointLayer = (
  theme: Theme,
  opacity?: number,
): LayerProps => ({
  id: "unclustered-point",
  type: "circle",
  source: sourceName,
  filter: ["!", ["has", "point_count"]],
  paint: {
    "circle-opacity": opacity ?? 1,
    "circle-stroke-opacity": opacity ?? 0.9,
    "circle-color": [
      "case",
      ["any", ["boolean", ["feature-state", "hover"], false]],
      colors.primary[400],
      colors.primary[700],
    ],
    "circle-radius": circleRadiusStyle,
    "circle-stroke-width": circleStrokeWidthStyle,
    "circle-stroke-color": theme.palette.background.default,
  },
})

export const themedHighlightedUnclusteredPointLayer =
  (
    values:
      | {
          accessionNumber: string
          materialQualifier: string
        }
      | undefined,
  ) =>
  (theme: Theme, opacity?: number): LayerProps | undefined =>
    values === undefined
      ? undefined
      : {
          id: "highlighted-unclustered-point",
          type: "circle",
          source: sourceName,
          filter: [
            "all",
            ["!", ["has", "point_count"]],
            [
              "==",
              ["get", "accessionNumber", ["get", "adHoc"]],
              values.accessionNumber,
            ],
            [
              "==",
              ["get", "qualifier", ["get", "adHoc"]],
              values.materialQualifier,
            ],
          ],
          paint: {
            "circle-opacity": opacity ?? 1,
            "circle-stroke-opacity": opacity ?? 0.9,
            "circle-color": [
              "case",
              ["boolean", ["feature-state", "hover"], false],
              colors.yellow[400],
              colors.yellow[500],
            ],
            "circle-radius": circleRadiusStyle,
            "circle-stroke-width": circleStrokeWidthStyle,
            "circle-stroke-color": theme.palette.background.default,
          },
        }
