import type {AxiosResponse} from "axios"
import axios from "axios"
import config from "../config"
import {stringifyObjectInOrder} from "./stringify"
import withCommonHeaders from "./with-common-headers"

export type Variables = Record<string, unknown>

export interface GraphQLError {
  message: string
  locations: Array<{line: number; column: number}>
  path: Array<string>
}

export interface GraphQLResponse<T> {
  data?: T
  errors?: Array<GraphQLError>
  status: number
  [key: string]: unknown
}

// Adding Bearer token to GraphQLClient

/*
 httpClient.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem(BEARER_TOKEN);
  config.headers["Authorization"] = `Bearer ${accessToken}`;
  return config;
});
*/

function extractErrorMessage<T>({errors, status}: GraphQLResponse<T>): string {
  if (errors && errors.length > 0 && errors[0] !== undefined) {
    return errors[0].message
  }
  return `GraphQLError (code: ${status})`
}

export const client = axios.create()

export const NO_DATA_RETURNED = "No data was returned"
export const NO_ACCESS_TOKEN = "Not logged in"

export type StringLiteral<Name extends string> = Exclude<string, Name>

export type AuthedFetcher<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Args extends Array<any>,
  Result,
  Name extends StringLiteral<Name>,
> = {
  fetch: (...args: Args) => Promise<Result>
  deriveKey: (...args: Args) => Array<unknown> | null
}

export const authedFetcher =
  <Vars extends Variables, Result>(query: string) =>
  /**
   * @param deriveKeyFromVariables - this function should serialise the GQL arguments into an array of strings. When in doubt, leave it out!
   *
   * @remarks
   * If deriveKeyFromVariables is throwing information away,
   * this *will* cause bugs retrieving stale or incorrect data from the cache
   * and it won't be obvious why.
   * This function exists for two reasons:
   * 1. to alias superficially different variable sets (e.g. by sorting the keys or by rounding numbers)
   * 2. allow for faster performance by serialising variables without having to stringify them
   * It's a really important function to get right, so if it doesn't need to be provided, it shouldn't be.
   */
  <Name extends StringLiteral<Name>>(
    name: Name,
    deriveKeyFromVariables: (
      vars: Vars,
    ) => ReadonlyArray<unknown> = stringifyObjectInOrder,
  ): AuthedFetcher<
    [accessToken: string | undefined, variables: Vars | undefined],
    Result,
    Name
  > => {
    return {
      deriveKey: (
        accessToken: string | undefined,
        variables: Vars | undefined,
      ) =>
        accessToken !== undefined && variables !== undefined
          ? [query, name, accessToken, ...deriveKeyFromVariables(variables)]
          : null,
      fetch: async (accessToken, variables) => {
        // TODO: remove this check and just let it fail?
        if (accessToken === undefined) {
          throw new Error(
            "Access token was undefined, check 'deriveKey' function",
          )
        }

        const {data: result}: AxiosResponse<GraphQLResponse<Result>> =
          await client.post(
            config.publicApiUrl,
            {
              operationName: name,
              query,
              variables,
            },
            withCommonHeaders({
              Authorization: `Bearer ${accessToken}`,
            }),
          )

        const {errors, data} = result

        if (errors !== undefined) {
          throw new Error(extractErrorMessage<Result>(result))
        }
        if (data === undefined) {
          throw new Error(NO_DATA_RETURNED)
        }

        return data
      },
    }
  }

export async function fetcher<T>(
  query: string,
  operationName: string,
  variables?: Variables,
  apiUrl?: string,
): Promise<T> {
  const {data: result}: AxiosResponse<GraphQLResponse<T>> = await client.post(
    apiUrl ?? config.publicApiUrl,
    {
      operationName,
      query,
      variables,
    },
    withCommonHeaders(),
  )

  const {errors, data} = result

  if (errors !== undefined) {
    throw new Error(extractErrorMessage<T>(result))
  }
  if (data === undefined) {
    throw new Error(NO_DATA_RETURNED)
  }

  return data
}
