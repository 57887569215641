import useSwr from "swr"
import {fetchPublicOrg} from "../fetchers"

export const usePublicOrg = (
  subdomain: string | undefined,
  apiUrl?: string | undefined,
) =>
  useSwr(
    subdomain == null ? null : ["public-org", subdomain],
    (_, subdomain: string | undefined) =>
      fetchPublicOrg(
        {
          subdomain: subdomain ?? "",
        },
        apiUrl,
      ),
  )
