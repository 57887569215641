import {zodResolver} from "@hookform/resolvers/zod"
import type {
  NullableStringComparator,
  RecordTagFilters,
} from "generated/graphql"
import {useMemo} from "react"
import {FormProvider} from "react-hook-form"
import {
  CollapsableRadioButton,
  RadioButton,
  RadioGroup,
} from "src/components/radio"
import {
  OrgDonorFieldAutocomplete,
  SiteDonorFieldAutocomplete,
} from "src/features/create-accession/components/accession-data-form/donor-field-autocomplete/donor-field-autocomplete"
import {FilterTitle} from "src/features/filters/components/modal/filter-title"
import {determineInitialOperation} from "src/features/filters/plant-materials/modals/determine-initial-operation"
import type {FilterFormProps} from "src/features/filters/plant-materials/modals/types"
import {match} from "ts-pattern"
import {z} from "zod"
import type {FilterModalProps} from "../components/modal/filter-modal"
import {FilterModal} from "../components/modal/filter-modal"
import {FilterModalButtons} from "../components/modal/modal-buttons"
import {FilterModalError} from "../components/modal/modal-error"
import {useFilterForm} from "../components/modal/use-filter-form"

type InitialValue = NullableStringComparator | null | undefined

const resolver = zodResolver(
  z.object({
    contains: z.string().nullable().optional(),
    hasNoValue: z.boolean().nullable().optional(),
  }),
)

const createDefaultValues = (initialValue: InitialValue) => {
  const defaultValues = {
    contains: initialValue?.contains ?? undefined,
    hasNoValue: initialValue?.null ?? undefined,
  }
  return {
    defaultValues,
    initialOperation: determineInitialOperation(defaultValues, "contains"),
  }
}

interface DonorFilterFormProps {
  addModifyFilter: (changes: NullableStringComparator) => void
  deleteFilter: (() => void) | undefined
  tagFilters?: RecordTagFilters
  initialValue?: InitialValue
  donorsAssociatedWith: "site" | "org"
}

export const DonorsFilterForm = ({
  initialValue,
  addModifyFilter,
  deleteFilter,
  onClose,
  donorsAssociatedWith,
}: Omit<FilterFormProps, "dispatchFilters" | "initialValue"> &
  DonorFilterFormProps) => {
  const {error, setError, operation, handleRadioChange, formMethods} =
    useFilterForm({
      resolver,
      ...createDefaultValues(initialValue),
    })

  const onSubmit = useMemo(
    () =>
      formMethods.handleSubmit((values) => {
        match(operation)
          .with("contains", () => {
            if (values.contains == null) {
              setError("Enter a value to continue")
            } else {
              addModifyFilter({
                contains: values.contains,
              })
              onClose()
            }
          })
          .with("hasNoValue", () => {
            addModifyFilter({
              null: true,
            })
            onClose()
          })
          .run()
      }),
    [formMethods, operation, onClose, addModifyFilter, setError],
  )

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={onSubmit}>
        <div className="mb-4 flex flex-col gap-4">
          <FilterTitle title="Donor / supplier" deleteFilter={deleteFilter} />
          <FilterModalError error={error} />
          <RadioGroup
            value={operation}
            onChange={handleRadioChange}
            label="Operation"
          >
            {(props) => (
              <>
                <CollapsableRadioButton
                  selectedValue="contains"
                  testId={`donor-contains-radio`}
                  label="Contains"
                  {...props}
                >
                  {donorsAssociatedWith === "site" ? (
                    <SiteDonorFieldAutocomplete
                      name={"contains"}
                      id={"donor-contains-input"}
                      testId="donor-contains-text-field"
                    />
                  ) : (
                    <OrgDonorFieldAutocomplete
                      name={"contains"}
                      id={"donor-contains-input"}
                      testId="donor-contains-text-field"
                      label={null}
                    />
                  )}
                </CollapsableRadioButton>
                <RadioButton
                  label="Has no value"
                  selectedValue="hasNoValue"
                  testId={`donor-has-no-value-radio`}
                  {...props}
                />
              </>
            )}
          </RadioGroup>
        </div>
        <FilterModalButtons onCancel={onClose} testIdPrefix="donor" />
      </form>
    </FormProvider>
  )
}

// Seperated from form component to make sure popover unmount causes state reset
export const DonorsFilterModal = ({
  open,
  onClose,
  anchorEl,
  ...props
}: Omit<FilterFormProps, "initialValue" | "dispatchFilters"> &
  FilterModalProps &
  DonorFilterFormProps) => {
  return (
    <FilterModal anchorEl={anchorEl} open={open} onClose={onClose}>
      <DonorsFilterForm onClose={onClose} {...props} />
    </FilterModal>
  )
}
