import type {
  BooleanComparator,
  NullableStringComparator,
  ProvenanceFilter,
} from "generated/graphql"
import type {Dispatch, RefObject} from "react"
import {DateComparatorFilterModal} from "src/components/date-comparator-filter"
import {initialDateFilterValue} from "src/components/date-comparator-filter/utils"
import {DonorsFilterModal} from "../../accessions/donors-filter"
import {ProvenanceFilterModal} from "../../accessions/provenance-filter/modal"
import {NullableStringFilterModal} from "../../components/nullable-string-filter/modal"
import {FamilyFilterModal} from "../../family-filter/modal"
import {LifeFormsFilterModal} from "../../life-forms/modal"
import {RedListStatusFilterModal} from "../../red-list-status/modal"
import {TagsFilterModal} from "../../tags-filter"
import {UserFilterModal} from "../../user-filter/user-filter-modal"
import {ConditionFilterModal} from "../condition-filter"
import type {PlantMaterialFilterAction} from "../filter-reducer"
import {LocationFilterModal} from "../location-filter"
import {MaterialGroupFilterModal} from "../material-group-filter"
import {PhotosFilterModal} from "../photos-filter/modal"
import {PublicFilterModal} from "../public-filter/modal"
import {QuantityFilterModal} from "../quantity-filter/modal"
import {SexFilterModal} from "../sex-filter"
import {StatusFilterModal} from "../status-filter"
import type {LocalMaterialFilterWithId, MaterialFilterType} from "../types"
import {useDispatchFilter} from "./use-dispatch-filter"

interface FilterModalsProps {
  filterModalAnchorEl?: RefObject<HTMLElement | null>
  editFilter?: LocalMaterialFilterWithId | undefined
  openFilter: MaterialFilterType | false
  dispatchFilters: Dispatch<PlantMaterialFilterAction>
  handleFilterModalClose: () => void
}

export const MaterialFilterModals = ({
  filterModalAnchorEl,
  openFilter,
  editFilter,
  dispatchFilters,
  handleFilterModalClose,
}: FilterModalsProps) => {
  const props = {
    initialValue: editFilter,
    onClose: handleFilterModalClose,
    anchorEl: filterModalAnchorEl,
    dispatchFilters: dispatchFilters,
  }
  const {addModifyFilter, deleteFilter} = useDispatchFilter({
    dispatchFilters,
    id: editFilter?.id,
    onClose: handleFilterModalClose,
  })

  return (
    <>
      <LocationFilterModal {...props} open={openFilter === "location"} />
      <StatusFilterModal {...props} open={openFilter === "status"} />
      <ConditionFilterModal {...props} open={openFilter === "condition"} />
      <SexFilterModal {...props} open={openFilter === "sex"} />
      <PublicFilterModal
        {...props}
        addModifyFilter={(filter) => {
          addModifyFilter({public: filter})
        }}
        initialValue={editFilter?.public}
        deleteFilter={deleteFilter}
        open={openFilter === "public"}
      />
      <RedListStatusFilterModal
        {...props}
        initialValue={editFilter?.redListStatus}
        addModifyFilter={addModifyFilter}
        deleteFilter={deleteFilter}
        open={openFilter === "redListStatus"}
        filterBy="site"
      />
      <UserFilterModal
        {...props}
        initialValue={editFilter?.creator}
        addModifyFilter={(creator) => {
          addModifyFilter({creator})
        }}
        deleteFilter={deleteFilter}
        open={openFilter === "creator"}
        title="Creator"
      />
      <UserFilterModal
        {...props}
        initialValue={editFilter?.lastEditor}
        addModifyFilter={(lastEditor) => {
          addModifyFilter({lastEditor})
        }}
        deleteFilter={deleteFilter}
        open={openFilter === "lastEditor"}
        title="Last editor"
      />
      <ProvenanceFilterModal
        {...props}
        open={openFilter === "provenance"}
        addModifyFilter={(filter: ProvenanceFilter) => {
          addModifyFilter({accession: {provenance: filter}})
        }}
        deleteFilter={deleteFilter}
        initialValue={editFilter?.accession?.provenance}
      />
      <NullableStringFilterModal
        {...props}
        initialValue={editFilter?.accession?.taxon?.commonName}
        addModifyFilter={(filter: NullableStringComparator) => {
          addModifyFilter({accession: {taxon: {commonName: filter}}})
        }}
        deleteFilter={deleteFilter}
        open={openFilter === "commonName"}
        filterTitle="Common name"
        testIdPrefix="commonname"
      />
      <DonorsFilterModal
        {...props}
        open={openFilter === "donor"}
        initialValue={editFilter?.accession?.donor}
        addModifyFilter={(filter: NullableStringComparator) => {
          addModifyFilter({accession: {donor: filter}})
        }}
        deleteFilter={deleteFilter}
        donorsAssociatedWith="site"
      />
      <MaterialGroupFilterModal
        {...props}
        open={openFilter === "material-group"}
      />
      <NullableStringFilterModal
        {...props}
        deleteFilter={deleteFilter}
        initialValue={editFilter?.tagNumber}
        addModifyFilter={(filter: NullableStringComparator) => {
          addModifyFilter({tagNumber: filter})
        }}
        open={openFilter === "tag-number"}
        filterTitle="Tag number"
      />
      <NullableStringFilterModal
        {...props}
        initialValue={editFilter?.notes}
        addModifyFilter={(filter: NullableStringComparator) => {
          addModifyFilter({notes: filter})
        }}
        deleteFilter={deleteFilter}
        open={openFilter === "notes"}
        filterTitle="Notes"
      />
      <TagsFilterModal
        {...props}
        addModifyFilter={addModifyFilter}
        deleteFilter={deleteFilter}
        open={openFilter === "tags"}
      />
      <FamilyFilterModal
        {...props}
        addModifyFilter={addModifyFilter}
        deleteFilter={deleteFilter}
        open={openFilter === "family"}
        familiesAssociatedWith="site"
      />
      <QuantityFilterModal
        {...props}
        initialValue={editFilter?.massPlanting}
        addModifyFilter={(filter: BooleanComparator) => {
          addModifyFilter({
            massPlanting: filter,
          })
        }}
        filterTitle="Quantity"
        deleteFilter={deleteFilter}
        open={openFilter === "quantity"}
      />
      <PhotosFilterModal
        {...props}
        initialValue={editFilter?.hasImages}
        addModifyFilter={(filter: BooleanComparator) => {
          addModifyFilter({
            hasImages: filter,
          })
        }}
        filterTitle="Photos"
        deleteFilter={deleteFilter}
        open={openFilter === "photos"}
      />
      <DateComparatorFilterModal
        {...props}
        deleteFilter={deleteFilter}
        addModifyFilter={(filter) => {
          addModifyFilter({lastObserved: filter})
        }}
        initialValue={initialDateFilterValue(editFilter?.lastObserved)}
        open={openFilter === "lastObserved"}
        filterTitle="Last observed"
      />
      <NullableStringFilterModal
        {...props}
        initialValue={editFilter?.nativeDistribution}
        addModifyFilter={(filter) => {
          addModifyFilter({
            nativeDistribution: filter,
          })
        }}
        deleteFilter={deleteFilter}
        open={openFilter === "nativeDistribution"}
        testIdPrefix="nativedistribution"
        filterTitle="Native distribution"
      />
      <LifeFormsFilterModal
        {...props}
        initialValue={editFilter?.lifeForms}
        addModifyFilter={addModifyFilter}
        deleteFilter={deleteFilter}
        open={openFilter === "lifeForms"}
        filterBy="site"
      />
      <NullableStringFilterModal
        {...props}
        initialValue={editFilter?.climate}
        addModifyFilter={(filter) => {
          addModifyFilter({
            climate: filter,
          })
        }}
        deleteFilter={deleteFilter}
        open={openFilter === "climate"}
        filterTitle="Climate"
      />
      <DateComparatorFilterModal
        {...props}
        deleteFilter={deleteFilter}
        addModifyFilter={(filter) => {
          addModifyFilter({firstPresent: filter})
        }}
        initialValue={initialDateFilterValue(editFilter?.firstPresent)}
        open={openFilter === "firstPresent"}
        filterTitle="Date added"
      />
      <DateComparatorFilterModal
        {...props}
        deleteFilter={deleteFilter}
        addModifyFilter={(filter) => {
          addModifyFilter({firstAbsent: filter})
        }}
        initialValue={initialDateFilterValue(editFilter?.firstAbsent)}
        open={openFilter === "firstAbsent"}
        filterTitle="Date removed"
      />
    </>
  )
}
