import type {ReactElement} from "react"
import {useCallback, useMemo, useState} from "react"
import {Divider} from "src/components/custom/divider"
import {ToggleChip} from "src/components/toggle/toggle-chip"
import {useMobile} from "src/utils/hooks/media-query"
import {FilterDrawerTitle} from "../components/filter-drawer-title"
import {AddPlantMaterialFilter} from "./add-plant-material-filter"
import {MaterialFilterChipGroup} from "./filter-chip-group"
import type {OpenFilterArgs} from "./filters"
import {MaterialFilterModals} from "./modals/filter-modals"
import {MaterialsSortSelect} from "./sort-select"
import type {MaterialFilterType, PlantMaterialsFiltersProps} from "./types"

export const PlantMaterialsMobileFilters = ({
  updateOrderBy,
  orderBy,
  excludeAbsentPlantMaterial,
  onChange,
  filters,
  dispatchFilters,
}: PlantMaterialsFiltersProps): ReactElement => {
  const isMobile = useMobile()
  const [openFilter, setOpenFilter] = useState<false | MaterialFilterType>(
    false,
  )

  const [editFilterId, setEditFilterId] = useState<string | null>(null)
  const editFilter = useMemo(
    () =>
      editFilterId == null
        ? undefined
        : filters.and.find(({id}) => id === editFilterId),
    [filters, editFilterId],
  )

  const handleOpenFilter = useCallback(
    ({filterType, editFilterId}: OpenFilterArgs<MaterialFilterType>) => {
      setEditFilterId(editFilterId ?? null)
      setOpenFilter(filterType)
    },
    [],
  )
  const handleClose = useCallback(() => {
    setOpenFilter(false)
  }, [])

  return (
    <div className="flex h-full max-h-screen min-h-[460px] flex-1 flex-col pb-4">
      <FilterDrawerTitle title="Plant materials" />
      <div className="pb-6 pt-4">
        <label className="hidden" htmlFor="mobile-sort-select">
          Sort by
        </label>
        <MaterialsSortSelect
          orderBy={orderBy}
          updateOrderBy={updateOrderBy}
          id="mobile-sort-select"
          fullWidth
        />
      </div>
      <Divider />
      <div className="py-4 font-medium text-grey-900">Filters</div>
      <div className="flex flex-wrap gap-2 lg:flex-col">
        <ToggleChip
          checked={excludeAbsentPlantMaterial}
          onChange={onChange}
          testId="absent-material-switch-mobile"
        >
          Exclude &apos;absent&apos; material
        </ToggleChip>
        <MaterialFilterChipGroup
          handleOpenFilter={handleOpenFilter}
          filters={filters.and}
          dispatchFilter={dispatchFilters}
        />
        <AddPlantMaterialFilter
          side={isMobile ? "top" : "bottom"}
          setOpenFilter={handleOpenFilter}
        />
      </div>
      <MaterialFilterModals
        editFilter={editFilter}
        dispatchFilters={dispatchFilters}
        openFilter={openFilter}
        handleFilterModalClose={handleClose}
      />
    </div>
  )
}
