import * as E from "fp-ts/Either"
import {flow, pipe} from "fp-ts/function"
import * as RNEA from "fp-ts/ReadonlyNonEmptyArray"
import type {ProvenanceCategory} from "../../../../../../generated/graphql"
import {
  ChipGridStylingPreset,
  FormChipGrid,
} from "../../../../../components/chip-grid"
import * as OE from "../../../../../utils/option-either"
import {provenanceCategoryOrd} from "../../../../../utils/provenance-categories"
import {
  showErrorMessage,
  useProvenanceCategories,
} from "../../create-accession/utils"

const mapProvenanceCategories = flow(
  RNEA.sort(provenanceCategoryOrd),
  RNEA.map((provenanceCategory: ProvenanceCategory) => ({
    label: provenanceCategory.name,
    value: provenanceCategory.codeId,
  })),
)

interface ProvenanceCategoriesProps {
  responsiveChips?: boolean
}

export const ProvenanceCategories = ({
  responsiveChips,
}: ProvenanceCategoriesProps) =>
  pipe(
    OE.sequenceNoneToUndefined({
      provenanceCategories: useProvenanceCategories(),
    }),
    E.match(showErrorMessage, ({provenanceCategories}) => (
      <FormChipGrid
        name="provenanceCategoryCode"
        stylingPreset={
          responsiveChips === undefined
            ? ChipGridStylingPreset.BASIC_GRID
            : ChipGridStylingPreset.RESPONSIVE_GRID
        }
        chips={
          provenanceCategories
            ? mapProvenanceCategories(provenanceCategories)
            : []
        }
        label="Provenance"
        testId="provenance-chip-buttons"
        required
      />
    )),
  )
