import {D, pipe} from "@mobily/ts-belt"
import {useTheme} from "@mui/material/styles"
import type {Options} from "@mui/material/useMediaQuery"
import useMediaQuery from "@mui/material/useMediaQuery"

export type Breakpoints = "xs" | "sm" | "md" | "lg" | "xl"

export const breakpoints: Record<Breakpoints, number> = {
  xs: 0,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
}

export const mediaBp = pipe(
  breakpoints,
  D.map((bp) => `@media (min-width: ${bp}px)`),
)

export const useSmallMobile = (options?: Options): boolean =>
  useMediaQuery(useTheme().breakpoints.down("sm"), options)

export const useMobile = (options?: Options): boolean =>
  useMediaQuery(useTheme().breakpoints.down(breakpoints.lg), options)

/**
 * TODO: 375 is a bit of a magic number - how does it relate to useSmallMobile and useMobile?
 */
export const useSmallDisplay = (options?: Options): boolean =>
  useMediaQuery(useTheme().breakpoints.down(breakpoints.sm), options)

/**
 * TODO: how does 768 relate to 'md'?
 */
export const useMobileDisplay = (options?: Options): boolean =>
  useMediaQuery(useTheme().breakpoints.down(breakpoints.md), options)

export const useTabletDisplay = (options?: Options): boolean =>
  useMediaQuery(useTheme().breakpoints.down(breakpoints.lg), options)

export const useSmallDesktop = (options?: Options): boolean =>
  useMediaQuery(useTheme().breakpoints.down(breakpoints.xl), options)
