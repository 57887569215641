import type {HTMLAttributes} from "react"
import {twMerge} from "tailwind-merge"
import {cva, type VariantProps} from "class-variance-authority"

const dotVariants = cva("rounded-full flex-shrink-0", {
  variants: {
    color: {
      primary: "bg-primary-500",
      grey: "bg-grey-500",
      error: "bg-error-500",
      warning: "bg-warning-500",
      success: "bg-success-500",
    },
    size: {
      sm: "h-1 w-1",
      md: "h-1.5 w-1.5",
      lg: "h-2 w-2",
    },
  },
  defaultVariants: {
    color: "primary",
    size: "md",
  },
})

const Dot = ({
  color,
  size,
  className,
  ...props
}: HTMLAttributes<HTMLDivElement> & VariantProps<typeof dotVariants>) => (
  <div className={twMerge(dotVariants({color, size}), className)} {...props} />
)

export {Dot}
