import {IconButton} from "@hortis/ui/button"
import {DotsHorizontal} from "@hortis/ui/icons"
import type {
  PlantMaterialFilter,
  PlantMaterialsSearchTerm,
} from "generated/graphql"
import type {ReactElement} from "react"
import {useCallback, useMemo, useRef, useState} from "react"
import {useAccessRole} from "src/features/permissions/use-access-role"
import {SlAddIcon} from "../../../../components/icons/streamline/add"
import {Menu} from "../../../../components/menu"
import {Typography} from "../../../../components/typography"
import {useCollectionSiteHrefs} from "../../../../utils/hooks/hrefs"
import {DownloadCsvMenuItem} from "../download-csv-menu-item"

const newAccessionLabel = (
  <div className="flex items-center gap-2">
    <SlAddIcon width="20" height="20" />
    <Typography variant="body2" color="text.primary">
      New material
    </Typography>
  </div>
)

const anchorOrigin = {
  vertical: "bottom",
  horizontal: "right",
} as const

const transformOrigin = {
  vertical: "top",
  horizontal: "right",
} as const

export const MaterialsUtilityMoreButton = ({
  searchTerm,
  filters,
}: {
  searchTerm: PlantMaterialsSearchTerm | undefined
  filters: PlantMaterialFilter | undefined
}): ReactElement => {
  const [open, setOpen] = useState(false)
  const collectionSiteHrefs = useCollectionSiteHrefs()
  const {canEdit} = useAccessRole()
  const menuOptions = useMemo(
    () => [
      ...(collectionSiteHrefs == null || !canEdit
        ? []
        : [
            {
              label: newAccessionLabel,
              value: "new-material",
              href: `/${collectionSiteHrefs.materialsNew}`,
            },
          ]),
      {
        label: (
          <DownloadCsvMenuItem
            body={{searchTerm, filters}}
            csvName="plant-materials"
            path="/download/materials"
          />
        ),
        value: "download",
      },
    ],
    [collectionSiteHrefs, canEdit, searchTerm, filters],
  )
  const buttonRef = useRef(null)

  const closeMenu = useCallback(() => {
    setOpen(false)
  }, [])
  const toggleMenu = useCallback(() => {
    setOpen((x) => !x)
  }, [])

  return (
    <div ref={buttonRef} data-cy="materials-more-button">
      <IconButton
        ref={buttonRef}
        ariaLabel="More"
        onClick={toggleMenu}
        size="sm"
        icon={DotsHorizontal}
      />
      <Menu
        open={open}
        onClose={closeMenu}
        anchorEl={buttonRef}
        options={menuOptions}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      />
    </div>
  )
}
