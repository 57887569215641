import {zodResolver} from "@hookform/resolvers/zod"
import {useCallback, useEffect} from "react"
import type {FormEventHandler} from "react"
import type {UseFormProps} from "react-hook-form"
import {FormProvider, useForm} from "react-hook-form"
import {z} from "zod"
import type {
  CollectionSitesFieldsFragment,
  RecordTagFieldsFragment,
  RecordTagInput,
  RecordTagUpdateInput,
} from "generated/graphql"
import {
  useCreateRecordTagMutation,
  useUpdateRecordTagMutation,
} from "generated/graphql"
import {Button} from "@hortis/ui/button"
import {FeaturedIcon} from "src/components/featured-icon/featured-icon"
import {Tags} from "src/components/icons/streamline/tags"
import {useSnackbarStore} from "src/components/snackbar-controller/snackbar-store"
import type {SetSnack} from "src/components/snackbar-controller/snackbar-store"
import {FormTextField} from "src/components/text-field"
import {onFailure, onSuccess} from "src/notification-snack-utils"
import type {Nullable} from "src/types/nullable"
import {DialogContent, DialogFooter, DialogHeader} from "@hortis/ui/dialog"

type ExistingTags =
  | Array<RecordTagFieldsFragment>
  | ReadonlyArray<RecordTagFieldsFragment>
  | null

const handleUpdateTag = async ({
  tagId,
  input,
  updateTag,
  setSnack,
  onSubmit,
  onDuplicateNameError,
}: {
  tagId: string
  input: RecordTagUpdateInput
  updateTag: ReturnType<typeof useUpdateRecordTagMutation>[1]
  setSnack: SetSnack
  onSubmit: (newTag: RecordTagFieldsFragment) => void
  onDuplicateNameError: () => void
}) => {
  const _res = await updateTag({
    id: tagId,
    input,
  })
  const res = _res.data?.updateRecordTag
  if (res?.success === true && res.recordTag != null) {
    onSuccess(setSnack)("Successfully updated tag")
    onSubmit(res.recordTag)
  } else if (res?.errors?.[0]?.__typename === "DuplicateNameError") {
    onDuplicateNameError()
  } else {
    throw new Error("Failed to update tag")
  }
}

const handleCreateTag = async ({
  input,
  createTag,
  setSnack,
  onSubmit,
  onDuplicateNameError,
}: {
  input: RecordTagInput
  createTag: ReturnType<typeof useCreateRecordTagMutation>[1]
  setSnack: SetSnack
  onSubmit: (newTag: RecordTagFieldsFragment) => void
  onDuplicateNameError: () => void
}) => {
  const _res = await createTag({
    input: input,
  })
  const res = _res.data?.createRecordTag
  if (res?.success === true && res.recordTag != null) {
    onSuccess(setSnack)("Successfully created tag")
    onSubmit(res.recordTag)
  } else if (
    res?.errors?.[0]?.__typename === "DuplicateNameError" ||
    res?.errors?.[0]?.__typename === "SiteTagMergeError"
  ) {
    onDuplicateNameError()
  } else {
    throw new Error("Failed to created tag")
  }
}

const isExistingTagName = ({
  name,
  tags,
  defaultValue,
}: {
  name: string
  tags?: ExistingTags
  defaultValue: string | null | undefined
}) => {
  // Perform a case-insensitive check of the incoming name vs the default value, if the names are the same but the case is different, we should not consider this a duplicate
  if (
    defaultValue != null &&
    name.toLowerCase() === defaultValue.toLowerCase()
  ) {
    return name === defaultValue
  }

  return tags == null
    ? false
    : tags.some((tag) => tag.name.toLowerCase() === name.toLowerCase())
}

const TagUpdateSchema = z.object({name: z.string().min(1)})
type TagUpdateSchema = Nullable<z.TypeOf<typeof TagUpdateSchema>>

const resolver: UseFormProps<TagUpdateSchema>["resolver"] =
  zodResolver(TagUpdateSchema)

export type TagEditFormProps = {
  existingTags?: ExistingTags
  tag?: RecordTagFieldsFragment
  onSubmit: (newTag: RecordTagFieldsFragment) => void
  onClose: () => void
  site: CollectionSitesFieldsFragment | undefined | null
  open: boolean
}

export const EditTagDialogContent = ({
  tag,
  onClose,
  onSubmit,
  existingTags,
  site,
  open,
}: TagEditFormProps) => {
  const {setSnack} = useSnackbarStore()
  const [, updateTag] = useUpdateRecordTagMutation()
  const [, createTag] = useCreateRecordTagMutation()
  const formMethods = useForm<TagUpdateSchema>({
    defaultValues: {name: tag?.name ?? null},
    criteriaMode: "all",
    resolver,
  })

  useEffect(() => {
    formMethods.reset({name: tag?.name ?? null})
  }, [open, formMethods, tag])

  const setDuplicateNameError = useCallback(() => {
    formMethods.setError("name", {
      type: "validate",
      message: "A tag with that name already exists",
    })
  }, [formMethods])

  const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    (e) => {
      e.stopPropagation()
      return formMethods.handleSubmit(async (data) => {
        try {
          if (
            data.name != null &&
            isExistingTagName({
              name: data.name,
              tags: existingTags,
              defaultValue: formMethods.formState.defaultValues?.name,
            })
          ) {
            setDuplicateNameError()
            return
          }
          if (tag != null) {
            await handleUpdateTag({
              setSnack,
              input: data,
              tagId: tag.id,
              onSubmit,
              updateTag,
              onDuplicateNameError: setDuplicateNameError,
            })
          } else if (site !== undefined && data.name != null) {
            await handleCreateTag({
              input: {name: data.name, collectionSiteId: site?.id},
              setSnack,
              onSubmit,
              createTag,
              onDuplicateNameError: setDuplicateNameError,
            })
          } else {
            throw new Error("Could not determine tag action")
          }
        } catch {
          onFailure(setSnack)(
            new Error(`Failed to ${tag == null ? "create" : "update"} tag`),
          )
        }
      })(e)
    },
    [
      formMethods,
      setSnack,
      tag,
      updateTag,
      onSubmit,
      createTag,
      site,
      existingTags,
      setDuplicateNameError,
    ],
  )

  return (
    <FormProvider {...formMethods}>
      <DialogContent asChild>
        <form className="flex flex-col gap-8" onSubmit={handleSubmit}>
          <DialogHeader>
            <FeaturedIcon>
              <Tags />
            </FeaturedIcon>
            <FormTextField
              label="Tag name"
              name="name"
              placeholder="Eg. Repotted"
              data-cy="tag-name-input"
            />
          </DialogHeader>
          <DialogFooter>
            <Button containerCss={{flex: 1}} size="lg" onClick={onClose}>
              Cancel
            </Button>
            <Button
              containerCss={{flex: 1}}
              size="lg"
              variant="primary"
              type="submit"
              testId="tag-form-submit"
            >
              Submit
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </FormProvider>
  )
}
