import {R} from "@mobily/ts-belt"
import type {CombinedError} from "urql"
import type {GetMeOrgWithSitesQuery} from "../../../../generated/graphql"

const invalidOrganisation = (
  orgSubdomain?: string | null,
  urlSubdomain?: string,
) => urlSubdomain != null && urlSubdomain !== orgSubdomain

export const processSitesData = ({
  data,
  error,
  subdomain,
}: {
  data: GetMeOrgWithSitesQuery | undefined
  error: CombinedError | undefined
  subdomain: string | undefined
}) => {
  const collectionSites = data?.me?.collectionSites
  const organisation = data?.me?.organisation
  const collectionSiteMemberships = data?.me?.collectionSiteMemberships

  if (error != null) {
    return R.Error(error.message)
  }
  if (data?.me == null || organisation == null) {
    return R.Error("Unable to load organisation")
  }
  if (invalidOrganisation(organisation.subdomain, subdomain)) {
    return R.Error("Invalid organisation name")
  }

  return R.Ok({
    collectionSites: collectionSites ?? [],
    organisation,
    collectionSiteMemberships: collectionSiteMemberships ?? [],
    userId: data.me.id,
  })
}
