import {IconButton} from "@hortis/ui/button"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@hortis/ui/dialog"
import {XClose} from "@hortis/ui/icons"
import {type AxiosProgressEvent} from "axios"
import {type MaterialImageFieldsFragment} from "generated/graphql"
import {type RefObject} from "react"
import {ImageDropzone} from "src/features/material-page/sections/material-record-photos/new-image-dropzone"
import type {
  LocalImage,
  StagedImage,
} from "src/features/records/material/components/material-images-tab/types"

interface UploadPhotosDialogProps {
  open: boolean
  setOpen: (open: boolean) => void
  setImages: (images: Array<StagedImage>) => void
  handleProgress: (e: AxiosProgressEvent, imageId: string) => void
  handleComplete: (photo: MaterialImageFieldsFragment, imageId: string) => void
  handleError: (error: string, imageId: string) => void
  triggerRef?: RefObject<HTMLButtonElement>
  images: Array<LocalImage>
  materialId: string
}

export const UploadPhotosDialog = ({
  open,
  setOpen,
  images,
  materialId,
  setImages,
  handleProgress,
  handleComplete,
  handleError,
  triggerRef,
}: UploadPhotosDialogProps) => {
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent
        data-cy="upload-photos-dialog"
        className="max-w-[650px] !gap-0"
        triggerRef={triggerRef}
      >
        <DialogHeader>
          <DialogTitle className="flex justify-between border-b border-grey-200 pb-2 ">
            Upload photos
            <div className="flex gap-2">
              <IconButton
                ariaLabel={"close-dialog"}
                data-cy={"close-dialog-button"}
                variant="tertiaryGray"
                onClick={() => {
                  setOpen(false)
                }}
                size={"xs"}
                icon={XClose}
              />
            </div>
          </DialogTitle>
        </DialogHeader>
        <div className="h-[250px] py-6">
          <ImageDropzone
            materialId={materialId}
            images={images}
            setImages={setImages}
            handleProgress={handleProgress}
            handleComplete={handleComplete}
            handleError={handleError}
          />
        </div>
      </DialogContent>
    </Dialog>
  )
}
