import {twMerge} from "tailwind-merge"
import {colors} from "../colors"
import {Tag} from "../tag"

type RedListStatus =
  | "CONSERVATION_DEPENDENT"
  | "CRITICALLY_ENDANGERED"
  | "DATA_DEFICIENT"
  | "ENDANGERED"
  | "EXTINCT"
  | "EXTINCT_IN_THE_WILD"
  | "LEAST_CONCERN"
  | "NEAR_THREATENED"
  | "NOT_EVALUATED"
  | "VULNERABLE"

export const redListStatusLabelMap: Record<RedListStatus, string> = {
  CRITICALLY_ENDANGERED: "Critically Endangered",
  ENDANGERED: "Endangered",
  VULNERABLE: "Vulnerable",
  NEAR_THREATENED: "Near Threatened",
  LEAST_CONCERN: "Least Concern",
  DATA_DEFICIENT: "Data Deficient",
  NOT_EVALUATED: "Not Evaluated",
  EXTINCT: "Extinct",
  EXTINCT_IN_THE_WILD: "Extinct in the Wild",
  CONSERVATION_DEPENDENT: "Conservation Dependent",
}

export const redListStatusShortLabelMap: Record<RedListStatus, string> = {
  CRITICALLY_ENDANGERED: "CR",
  ENDANGERED: "EN",
  VULNERABLE: "VU",
  NEAR_THREATENED: "NT",
  LEAST_CONCERN: "LC",
  DATA_DEFICIENT: "DD",
  NOT_EVALUATED: "NE",
  EXTINCT: "EX",
  EXTINCT_IN_THE_WILD: "EW",
  CONSERVATION_DEPENDENT: "CD",
}

export const redListStatusClassNames: Record<RedListStatus, string> = {
  EXTINCT: "bg-grey-900 text-white border-grey-900",
  EXTINCT_IN_THE_WILD: "bg-grey-600 text-white border-grey-600",
  CRITICALLY_ENDANGERED: "bg-error-500 text-white border-error-500",
  ENDANGERED: "bg-orange-500 text-white border-orange-500",
  VULNERABLE: "bg-yellow-500 text-white border-yellow-500",
  NEAR_THREATENED: "bg-green-light-500 text-white border-green-light-500",
  LEAST_CONCERN: "bg-green-light-700 text-white border-green-light-700",
  DATA_DEFICIENT: "bg-grey-300 text-grey-800 border-grey-300",
  NOT_EVALUATED: "bg-grey-100 text-grey-800 border-grey-100",
  CONSERVATION_DEPENDENT: "bg-teal-700 text-white border-teal-700",
}

export const redListStatusColorMap: Record<RedListStatus, string> = {
  EXTINCT: colors.grey[900],
  EXTINCT_IN_THE_WILD: colors.grey[600],
  CRITICALLY_ENDANGERED: colors.error[500],
  ENDANGERED: colors.orange[500],
  VULNERABLE: colors.yellow[500],
  NEAR_THREATENED: colors["green-light"][500],
  LEAST_CONCERN: colors["green-light"][700],
  DATA_DEFICIENT: colors.grey[300],
  NOT_EVALUATED: colors.grey[100],
  CONSERVATION_DEPENDENT: colors.teal[700],
}

export interface IUCNRedListTagProps {
  status: RedListStatus
  short?: boolean
}

export const IUCNRedListTag = ({
  status,
  short = false,
}: IUCNRedListTagProps) => {
  return (
    <Tag size="sm" className={twMerge(redListStatusClassNames[status])}>
      {short
        ? redListStatusShortLabelMap[status]
        : redListStatusLabelMap[status]}
    </Tag>
  )
}
