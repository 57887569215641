import {memo, useCallback} from "react"
import type {ViewState} from "react-map-gl"
import {IconButton} from "@hortis/ui/button"
import {RefreshCw5} from "@hortis/ui/icons"
import {TooltipTrigger, Tooltip, TooltipContent} from "@hortis/ui/tooltip"
import {useMapPaddingStore} from "src/features/collection-map/components/map-padding-store"
import {useMaplibre} from "../utils"

type ViewStatePart = Pick<ViewState, "latitude" | "longitude">

export const CenterCollection = memo(function MemoisedCenterCollection(
  viewState: ViewStatePart & Partial<ViewState>,
) {
  const {current} = useMaplibre()
  const {mapPadding} = useMapPaddingStore()

  const handleClick = useCallback(() => {
    const {latitude: lat, longitude: lng} = viewState
    current?.flyTo({
      ...viewState,
      center: {lng, lat},
      padding: mapPadding,
    })
  }, [current, viewState, mapPadding])

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <IconButton
          testId="center-on-garden-button"
          size={{xs: "sm", md: "md"}}
          variant="tertiaryGray"
          onClick={handleClick}
          ariaLabel="Center collection on map"
          icon={RefreshCw5}
          className="pointer-events-auto text-grey-700 hover:text-grey-800"
        />
      </TooltipTrigger>
      <TooltipContent side="left">Re-center site</TooltipContent>
    </Tooltip>
  )
})
