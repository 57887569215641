import {Skeleton} from "src/components/skeleton"

export const MaterialRowSkeleton = () => {
  return (
    <div className="flex w-full gap-3 p-3 md:gap-4">
      <div className="flex size-12 items-center justify-center rounded-[8px] bg-grey-100 md:size-20" />
      <div className="flex flex-col">
        <Skeleton height="22px" width="90px" animation="wave" />
        <Skeleton height="32px" width="172px" animation="wave" />
        <Skeleton height="24px" width="190px" animation="wave" />
      </div>
    </div>
  )
}
