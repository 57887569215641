/* Don't need to worry about contents of array types */
import * as A from "fp-ts/Array"
import type {FilterOperation} from "../types"

export const determineInitialOperation = <T, C extends string>(
  values: {
    is?: Array<T>
    isNot?: Array<T>
    contains?: Array<C> | C
    hasNoValue?: boolean
  },
  initialOperation: FilterOperation,
) => {
  if (
    values.is != null &&
    (Array.isArray(values.is) ? A.isNonEmpty(values.is) : true)
  ) {
    return "is"
  }
  if (
    values.isNot != null &&
    (Array.isArray(values.isNot) ? A.isNonEmpty(values.isNot) : true)
  ) {
    return "isNot"
  }
  if (
    values.contains != null &&
    (Array.isArray(values.contains) ? A.isNonEmpty(values.contains) : true)
  ) {
    return "contains"
  }
  if (values.hasNoValue === true) {
    return "hasNoValue"
  }
  return initialOperation
}
