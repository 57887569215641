import {flow} from "fp-ts/function"
import * as O from "fp-ts/Option"
import * as Path from "../path"
import {useCollectionSiteSlug} from "./collection-site-slug"

export const mapSlugToHrefs = (collectionSiteName: string) => {
  return {
    dashboard: Path.concatAll("sites")([collectionSiteName, "dashboard"]),
    trash: Path.concatAll("sites")([collectionSiteName, "trash"]),
    collection: Path.concatAll("sites")([collectionSiteName, "collection"]),
    settings: Path.concatAll("settings")([
      "sites",
      collectionSiteName,
      "general",
    ]),
    members: Path.concatAll("settings")([
      "sites",
      collectionSiteName,
      "members",
    ]),
    plantMaterialCollection: `${Path.concatAll("sites")([
      collectionSiteName,
      "collection",
    ])}?tab=materials`,
    accessionCollection: `${Path.concatAll("sites")([
      collectionSiteName,
      "collection",
    ])}?tab=accessions`,
    taxaCollection: `${Path.concatAll("sites")([
      collectionSiteName,
      "collection",
    ])}?tab=taxa`,
    map: Path.concatAll("sites")([collectionSiteName, "map"]),
    accessions: Path.concatAll("sites")([collectionSiteName, "accessions"]),
    accessionsCreate: Path.concatAll("sites")([
      collectionSiteName,
      "accessions",
      "create",
    ]),
    materialsNew: Path.concatAll("sites")([
      collectionSiteName,
      "materials",
      "new",
    ]),
    // TODO: this page doesn't exist yet!
    plantMaterials: Path.concatAll("sites")([collectionSiteName, "materials"]),
  }
}

export type CollectionSiteHrefs = ReturnType<typeof mapSlugToHrefs>

const _useCollectionSiteHrefs = flow(
  useCollectionSiteSlug,
  O.map(O.map(mapSlugToHrefs)),
)

export const useCollectionSiteHrefs = flow(
  _useCollectionSiteHrefs,
  O.flatten,
  O.toUndefined,
)

export type UseCollectionSiteHrefsResult = ReturnType<
  typeof useCollectionSiteHrefs
>
