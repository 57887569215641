import {Button} from "@hortis/ui/button"
import {Mark} from "@hortis/ui/icons"
import {useMapOptionsStore} from "src/features/collection-map/components/map-options-store"
import {CurrentPositionLayer} from "src/features/collection-map/components/current-position-layer"
import type {BaseMapFieldsFragment} from "generated/graphql"
import {SelectLayers} from "../actions/select-layers"
import {useCenterDevicePosition} from "../actions/center-current-position"
import {MapOverlay} from "./map-overlay"

export const SetInitialViewMapOverlay = ({
  confirmInitialView,
  baseMap,
}: {
  confirmInitialView: () => void
  baseMap: BaseMapFieldsFragment | null
}) => {
  const {devicePositionLayerEnabled} = useMapOptionsStore()
  const {centerDevicePosition} = useCenterDevicePosition()
  return (
    <MapOverlay className="items-end p-3 ring-4 ring-inset ring-primary-300/70 md:flex-col lg:p-4">
      {devicePositionLayerEnabled && <CurrentPositionLayer />}
      <SelectLayers baseMap={baseMap} />
      <div className="pointer-events-auto flex flex-col gap-6 self-start rounded-xl bg-white p-6 text-base shadow-lg sm:max-w-xs">
        <div className="space-y-2">
          <div className="font-medium text-grey-900">Set initial view</div>
          <p className="text-sm text-grey-500">
            This view will be shown when opening the site’s map.
          </p>
        </div>
        <div className="flex flex-col gap-3 sm:flex-row sm:self-end">
          <Button startIcon={Mark} size="sm" onClick={centerDevicePosition}>
            Center on device
          </Button>
          <Button
            variant="primary"
            onClick={confirmInitialView}
            size="sm"
            testId="confirm-initial-view-button"
          >
            Confirm
          </Button>
        </div>
      </div>
    </MapOverlay>
  )
}
