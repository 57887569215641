import {
  useRouterState,
  type AnyRouter,
  type RegisteredRouter,
  type RouterState,
} from "@tanstack/react-router"

export function useLocation<
  TRouter extends AnyRouter = RegisteredRouter,
  TLocationState = RouterState<TRouter["routeTree"]>["location"],
  TSelected = TLocationState,
>(opts?: {select?: (state: TLocationState) => TSelected}): TSelected {
  return useRouterState({
    select: (state) =>
      opts?.select
        ? opts.select(state.location as TLocationState)
        : (state.location as TSelected),
  })
}
