import CircularProgress from "@mui/material/CircularProgress"
import Fade from "@mui/material/Fade"
import {memo} from "react"
import {colors} from "src/colors"
import {SlAlertCircleIcon} from "src/components/icons/streamline/alert-circle"
import {Typography} from "src/components/typography"
import {twMerge} from "tailwind-merge"

export type ImageProgressVariant = "profile" | "organisation"

const variantClassnames: Record<ImageProgressVariant, string> = {
  profile: "flex h-16 w-16 rounded-[999px]",
  organisation: "flex h-16 w-16 rounded-lg",
}

export const ImageProgress = memo(function ImageProgress({
  complete,
  progress,
  error,
  colourOverride,
  variant,
}: {
  complete: boolean
  progress: number | undefined
  error: string | undefined
  colourOverride?: boolean
  variant?: ImageProgressVariant
}) {
  return (
    <Fade in={!complete} timeout={300}>
      <div
        className={twMerge(
          "flex-items-center pointer-events-none absolute h-full w-full items-center justify-center p-2 ",

          variant == null ? "bottom-0 left-0" : variantClassnames[variant],
        )}
        style={{
          backgroundColor:
            error == null ? "rgba(0,0,0,0.35)" : "rgba(245,109,99,0.35)",
        }}
        data-cy="local-image-progress-overlay"
      >
        {error == null ? (
          <>
            <CircularProgress
              size={38}
              variant="determinate"
              value={progress}
              color="primary"
              data-cy="local-image-progress-indicator"
              thickness={5}
              sx={{
                color:
                  colourOverride == null
                    ? "rgba(255,255,255,0.8)"
                    : colors.primary[600],
              }}
            />
          </>
        ) : (
          <div
            className="flex items-center gap-2 rounded-lg p-2"
            style={{
              backgroundColor: `rgba(255,224,222,0.8)`,
            }}
            data-cy="local-image-progress-error"
          >
            <SlAlertCircleIcon />
            <Typography variant="body2" color="error.dark" mr={0.5}>
              Upload failed
            </Typography>
          </div>
        )}
      </div>
    </Fade>
  )
})
