import distance from "@turf/distance"
import {pipe} from "fp-ts/function"
import * as OL from "monocle-ts/Optional"
import type {ViewStateChangeEvent} from "react-map-gl"
import {useNavigate, useSearch} from "@tanstack/react-router"
import type {
  GetCollectionGeometryQuery,
  PlantMaterialGeoJsonFeature,
} from "../../../generated/graphql"
import {PlantMaterialsSearchColumn} from "../../../generated/graphql"
import type {MaplibreRef} from "../../components/map/utils"

export const mapId = "hortis-map"

const featuresLens = pipe(
  OL.id<GetCollectionGeometryQuery>(),
  OL.prop("org"),
  OL.fromNullable,
  OL.prop("site"),
  OL.fromNullable,
  OL.prop("plantMaterialsGeometry"),
  OL.fromNullable,
  OL.prop("features"),
  OL.fromNullable,
)

export const setFeature = (
  materialId: string,
  geometryMaterial: PlantMaterialGeoJsonFeature,
) =>
  pipe(
    featuresLens,
    OL.modify((features) =>
      features.map((feature) =>
        feature.properties.adHoc?.id === materialId
          ? geometryMaterial
          : feature,
      ),
    ),
  )

export const addFeature = (geometryMaterial: PlantMaterialGeoJsonFeature) =>
  pipe(
    featuresLens,
    OL.modify((features) => {
      // This spread is inefficient with large geometry sets
      return [...features, geometryMaterial]
    }),
  )

export const searchMenuLabels = {
  [PlantMaterialsSearchColumn.ScientificName]: {
    labels: {
      shortName: "Name",
      name: "Name",
    },
    placeholders: {
      shortName: "Search names",
      name: "Search names",
    },
  },
  [PlantMaterialsSearchColumn.MaterialNumber]: {
    labels: {
      shortName: "Number",
      name: "Number",
    },
    placeholders: {
      shortName: "Search numbers",
      name: "Search numbers",
    },
  },
} as const

export const useUpdateParams = () => {
  const navigate = useNavigate({from: "/sites/$siteSlug/map"})
  /**
   * Only update the query params if they've deviated by the
   * current position by a significant amount.
   * This should help prevent render thrashing
   */
  const MIN_PARAM_UPDATE_DISTANCE_IN_METERS = 1
  const MIN_PARAM_UPDATE_ZOOM_DIFFERENCE = 0.1
  return ({
    viewState: {latitude: newLatitude, longitude: newLongitude, zoom: newZoom},
  }: ViewStateChangeEvent) => {
    void navigate({
      replace: true,
      search: ({latLng, zoom, ...rest}) => ({
        ...rest,
        latLng:
          latLng == null
            ? {lat: newLatitude, lng: newLongitude}
            : distance([newLongitude, newLatitude], [latLng.lng, latLng.lat], {
                units: "meters",
              }) > MIN_PARAM_UPDATE_DISTANCE_IN_METERS
            ? {lat: newLatitude, lng: newLongitude}
            : undefined,
        zoom:
          zoom == null
            ? newZoom
            : Math.abs(newZoom - zoom) > MIN_PARAM_UPDATE_ZOOM_DIFFERENCE
            ? newZoom
            : zoom,
      }),
    })
  }
}

export const useUpdateMap = () => {
  const {latLng, zoom} = useSearch({from: "/_layout/sites/$siteSlug/map"})
  const MIN_FLY_TO_DISTANCE_IN_METERS = 3
  const MIN_FLY_TO_ZOOM_DIFFERENCE = 0.5
  /**
   * Only fly to a new location if it's significantly different from our current position...
   * this not only feels better but prevents render thrashing by reducing instances where
   * flying invokes `updateLatLng`, which then re-invokes this function (including then changing the zoom)
   * TODO: this feels pretty rough at the moment - worth revisiting!
   * should in fact clicking back / forwards take you to the plant material location
   * instead of the lat lng, which could be anywhere?
   */
  // `flyTo`'s types are incorrect - `zoom` can be optional, but it cannot be undefined - surprise, that's a mysterious runtime error!
  return (map: MaplibreRef | undefined) =>
    map?.flyTo({
      ...(zoom != null &&
      Math.abs(zoom - map.getZoom()) > MIN_FLY_TO_ZOOM_DIFFERENCE
        ? {zoom}
        : {}),
      ...(latLng != null &&
      distance(map.getCenter().toArray(), [latLng.lng, latLng.lat], {
        units: "meters",
      }) > MIN_FLY_TO_DISTANCE_IN_METERS
        ? {
            center: {
              lng: latLng.lng,
              lat: latLng.lat,
            },
            padding: {left: 0, top: 0, right: 0, bottom: 0},
          }
        : {}),
    })
}
