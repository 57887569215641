import type {DialogProps as CoreDialogProps} from "@mui/material/Dialog"
import CoreDialog from "@mui/material/Dialog"
import {styled} from "@mui/material/styles"
import {experimental_sx as sx} from "@mui/system"
import {applyMui} from "../../utils/apply-mui"
import {withTestId} from "../../utils/with-test-id"

const StyledDialog = styled(CoreDialog)(({theme}) =>
  sx({
    "& .MuiBackdrop-root": {
      backgroundColor: `rgba(56, 66, 80, 0.20)`,
      backdropFilter: `blur(8px)`,
    },
    "& .MuiDialog-paper": {
      margin: 2,
      px: {xs: 2, sm: 3, md: 3},
      py: {xs: 2, sm: 3, md: 3},
      width: "100%",
      boxShadow: theme.shadows[2],
      borderRadius: theme.spacing(2),
    },
  }),
)

export const Dialog = applyMui(
  withTestId,
  StyledDialog as (props: CoreDialogProps) => JSX.Element,
)
export type DialogProps = CoreDialogProps
