import {css, Global} from "@emotion/react"
import {colors} from "src/colors"

const customStyles = css({
  html: {
    fontFamily: `${["Inter", "Helvetica", "Arial", "sans-serif"].join(
      ",",
    )} !important`,
  },
  body: {
    WebkitTapHighlightColor: colors.grey[100],
    fontFamily: ["Inter", "Helvetica", "Arial", "sans-serif"].join(","),
    WebkitFontSmoothing: "antialiased",
    MozOsxFontSmoothing: "grayscale",
  },
})

const GlobalStyles = () => <Global styles={customStyles} />

export default GlobalStyles
