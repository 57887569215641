import * as E from "fp-ts/lib/Either.js"
import {flow, identity} from "fp-ts/lib/function.js"
import * as RNEA from "fp-ts/lib/ReadonlyNonEmptyArray.js"
import type * as Semi from "fp-ts/lib/Semigroup.js"
import * as t from "io-ts/lib/index.js"
import type {MapObj, Prefixer} from "./key-mapping.js"
import {createPrefixedKeyMapping, prefixKeys} from "./key-mapping.js"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const prefixStruct = <StructType extends Record<string, t.Type<any>>>(
  obj: StructType,
) => {
  const Type = t.type(obj)
  type Obj = t.TypeOf<typeof Type>
  return <Prefix extends string>(prefix: Prefix) => {
    type MappedObj = MapObj<Obj, Prefixer<Prefix>>
    type MappedStructType = MapObj<StructType, Prefixer<Prefix>>

    const mapStruct = prefixKeys<StructType, MappedStructType>(prefix)
    const mapObj = prefixKeys<Obj, MappedObj>(prefix)
    const keyMap = createPrefixedKeyMapping(prefix)(obj)
    const MappedType = t.type(mapStruct(obj))

    return new t.Type<Obj, MappedObj, unknown>(
      `PrefixedWith${prefix}`,
      Type.is,
      // @ts-expect-error Hard to convince TS this is safe!
      flow(MappedType.validate, E.map(keyMap)),
      mapObj,
    )
  }
}
export const optional = <A, B>(type: t.Type<A, B>) =>
  t.union([t.undefined, type])

export const mapType =
  <I, O, A extends I, B>(f: (a: A) => B, g: (b: B) => A) =>
  (fa: t.Type<A, O, I>): t.Type<B, O, I> =>
    fa.pipe(
      new t.Type<B, A, A>(
        `Mapped${fa.name}`,
        (val): val is B => true,
        (val, _c) => t.success(f(val)),
        g,
      ),
    )

export const extractProp = <Key extends string, O, Val>(
  key: Key,
): ((input: t.Type<Record<Key, Val>, O>) => t.Type<Val, O>) =>
  mapType(
    (obj) => obj[key],
    (val) => ({[key]: val}) as Record<Key, Val>,
  )

export const extractPartialProp = <Key extends string, O, Val>(
  key: Key,
): ((
  input: t.Type<Partial<Record<Key, Val>>, O>,
) => t.Type<Val | undefined, O>) =>
  mapType(
    (obj) => obj[key],
    (val) =>
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- hard to convince TS this could be undefined
      (val === undefined ? {} : {[key]: val}) as Partial<Record<Key, Val>>,
  )

const unknownPredicate = (_val: unknown): _val is unknown => false
export const getFailingType = ({name, error}: {name: string; error: string}) =>
  new t.Type<unknown>(
    name,
    unknownPredicate,
    (val, context) => t.failure(val, context, error),
    identity,
  )

const intersectionSemigroup: Semi.Semigroup<t.Mixed> = {
  concat: (a, b) => t.intersection([a, b]),
}
export const getIntersectionSemigroup = <
  A extends t.Mixed,
>(): Semi.Semigroup<A> => intersectionSemigroup as unknown as Semi.Semigroup<A>

const unionSemigroup: Semi.Semigroup<t.Mixed> = {
  concat: (a, b) => t.union([a, b]),
}
export const getUnionSemigroup = <A extends t.Mixed>(): Semi.Semigroup<A> =>
  unionSemigroup as unknown as Semi.Semigroup<A>

export const failingTypeSemigroup =
  getIntersectionSemigroup<ReturnType<typeof getFailingType>>()

export const FailingTypeRNEAConcatAll = RNEA.concatAll(failingTypeSemigroup)
