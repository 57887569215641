import type {TaxaFilter} from "generated/graphql"
import type {LocalLifeFormsFilter} from "./modal"

export const transformLifeFormsFilter = (
  filter: LocalLifeFormsFilter | undefined,
): TaxaFilter =>
  filter == null
    ? {}
    : {
        fullyValidated:
          filter.is != null && filter.is.length > 0 ? {eq: true} : undefined,
        sharedScientificName: {
          taxon: {
            lifeForms: {
              id: {
                in: filter.is?.map(({id}) => id),
                nin: filter.isNot?.map(({id}) => id),
              },
            },
          },
        },
      }
