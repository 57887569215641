import {constant, flow} from "fp-ts/function"
import * as IOO from "fp-ts/IOOption"
import * as O from "fp-ts/Option"
import {useEffect, useState} from "react"

export const alwaysGetNothing = constant(IOO.none)

/**
 * Unbelievably basic cookie fetching
 * we'll want to replace this with a library like js-cookie
 */
export const getCookie: (key: string) => IOO.IOOption<string> =
  typeof window === "undefined"
    ? alwaysGetNothing
    : (key) => () =>
        O.fromNullable(
          window.document.cookie
            .split("; ")
            .find((row) => row.startsWith(`${key}=`))
            ?.split("=")[1],
        )

export const makeUseCookie = flow(
  getCookie,
  (getCookie) =>
    function useCookie() {
      const [cookieValue, setCookieValue] = useState<
        O.Option<O.Option<string>>
      >(O.none)

      useEffect(() => {
        setCookieValue(O.some(getCookie()))
      }, [])

      return cookieValue
    },
)

export const useCookie = flow(makeUseCookie, (x) => x())
