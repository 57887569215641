import {useEffect, useState} from "react"

const isBrowser = typeof window !== "undefined"

export function useStickyState<T>(
  defaultValue: T,
  key: string,
  validator?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    parse: (searchObj: Record<string, any>) => T
  },
) {
  const [value, setValue] = useState<T>(() => {
    const stickyValue = isBrowser ? window.localStorage.getItem(key) : null
    if (validator != null) {
      return stickyValue == null
        ? defaultValue
        : // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          validator.parse(JSON.parse(stickyValue))
    }
    return stickyValue == null ? defaultValue : (JSON.parse(stickyValue) as T)
  })

  useEffect(() => {
    if (isBrowser) {
      window.localStorage.setItem(key, JSON.stringify(value))
    }
  }, [key, value])

  return [value, setValue] as const
}
