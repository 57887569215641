import {Layer, Source} from "react-map-gl"
import type {BaseMap} from "generated/graphql"

interface CustomBaseMapLayerProps {
  show: boolean
  baseMap: Pick<BaseMap, "url" | "minZoom" | "maxZoom" | "attribution">
}

export const CustomBaseMapLayer = ({
  baseMap,
  show,
}: CustomBaseMapLayerProps) => {
  // This is required as react-map-gl source breaks at runtime
  // if minzoom or maxzoom is undefined, so conditionally
  // add them to the props and spread them in.
  let props = {}
  if (baseMap.minZoom != null) {
    props = {
      ...props,
      minzoom: baseMap.minZoom,
    }
  }
  if (baseMap.maxZoom != null) {
    props = {
      ...props,
      maxzoom: baseMap.maxZoom,
    }
  }
  if (baseMap.attribution != null) {
    props = {
      ...props,
      attribution: baseMap.attribution,
    }
  }
  return (
    <>
      <Source
        type="geojson"
        data={{
          type: "FeatureCollection",
          features: [],
        }}
      >
        <Layer id="base-layer" type="symbol" />
      </Source>
      <Source
        {...props}
        id="custom-raster-tiles"
        type="raster"
        tiles={[baseMap.url]}
        key={`${baseMap.url}${baseMap.minZoom ?? ""}${baseMap.maxZoom ?? ""}${
          baseMap.attribution ?? ""
        }`}
      >
        {show && (
          <Layer
            beforeId="base-layer"
            id="custom-simple-raster"
            type="raster"
            source="custom-raster-tiles"
          />
        )}
      </Source>
    </>
  )
}
