import {Badge} from "@hortis/ui/badge"
import {Button} from "@hortis/ui/button"
import {colors} from "@hortis/ui/colors"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "@hortis/ui/dropdown-menu"
import {
  DotsHorizontal,
  Edit5,
  ExternalLink1,
  Plus,
  RefreshCw5,
  Trash3,
} from "@hortis/ui/icons"
import {Globe2} from "@hortis/ui/icons/globe-2"
import {FolderClosed} from "@hortis/ui/icons/folder-closed"
import {Link} from "@tanstack/react-router"
import type {
  AccessionFieldsFragment,
  PlantMaterialStatus,
} from "generated/graphql"
import {useCallback, useRef, useState} from "react"
import {DerivedStatus} from "src/components/derived-status"
import {RestoreAccessionDialogContent} from "src/components/dialog/variants/restore-accession"
import {TrashAccessionDialog} from "src/components/dialog/variants/trash-accession"
import {DeaccessionDialogContent} from "src/features/records/accession/components/deaccession-dialog"
import {
  AccessionEditStageCallback,
  type AccessionEditStage,
} from "src/features/records/accession/components/edit-drawer/types"
import {ReinstateDialogContent} from "src/features/records/accession/components/reinstate-dialog"
import {provenanceLabelMap} from "src/utils/label-maps/provenance"
import {useAccessToken} from "src/utils/use-access-token"
import type {UseQueryExecute} from "urql"
import {Dialog} from "@hortis/ui/dialog"
import {getRecordPhotos} from "src/features/taxonomy/components/taxon-page/header/get-record-photos"
import {copyToClipboard} from "src/utils/dom/copy-to-clipboard"
import {Copy1} from "@hortis/ui/icons/copy-1"
import {onFailure, onSuccess} from "src/notification-snack-utils"
import * as OE from "../../utils/option-either"
import {useSnackbarStore} from "../snackbar-controller/snackbar-store"
import {
  RecordPageHeader,
  RecordPageHeaderButtons,
  RecordPageHeaderContent,
  RecordPageHeaderPropertiesSummary,
  RecordPageHeaderSubtitle,
  RecordPageHeaderThumbnail,
  RecordPageHeaderTitle,
  RecordPageHeaderTitleGroup,
} from "."

type AccessionRecordPageHeaderProps = {
  accession: AccessionFieldsFragment
  requestEdit: (stage?: AccessionEditStage) => void
  refetch: UseQueryExecute
  canEdit: boolean
  handleCreateNewMaterial: () => void
}

// eslint-disable-next-line sonarjs/cognitive-complexity
function AccessionRecordPageHeader({
  accession,
  requestEdit,
  refetch,
  canEdit,
  handleCreateNewMaterial,
}: AccessionRecordPageHeaderProps) {
  const menuAnchorEl = useRef(null)
  const {setSnack} = useSnackbarStore()
  const openEditOverview = AccessionEditStageCallback.useOverview(requestEdit)

  const openEdit = useCallback(() => {
    openEditOverview()
  }, [openEditOverview])

  const [deaccessionDialogOpen, setDeaccessionDialogOpen] = useState(false)

  const openDeaccessionDialog = useCallback(() => {
    setDeaccessionDialogOpen(true)
  }, [])

  const closeDeaccessionDialog = useCallback(() => {
    setDeaccessionDialogOpen(false)
  }, [])

  const [reinstateDialogOpen, setReinstateDialogOpen] = useState(false)

  const openReinstateDialog = useCallback(() => {
    setReinstateDialogOpen(true)
  }, [])

  const closeReinstateDialog = useCallback(() => {
    setReinstateDialogOpen(false)
  }, [])

  const accessToken = OE.noneAndErrorToUndefined(useAccessToken())

  const [trashDialogOpen, setTrashDialogOpen] = useState(false)
  const [restoreDialogOpen, setRestoreDialogOpen] = useState(false)

  const materialStatuses =
    accession.plantMaterial?.map(({status}): PlantMaterialStatus => status) ??
    []
  const {thumbnail, photos} = getRecordPhotos([accession])

  return (
    <RecordPageHeader className="flex flex-col gap-4 lg:flex-row lg:gap-12 xl:grid xl:grid-cols-[188px_auto] [&_.record-page-header-thumbnail]:lg:max-w-[188px]">
      <RecordPageHeaderThumbnail
        grayscale={accession.archived || accession.deaccessionDate != null}
        thumbnail={thumbnail}
        additionalPhotos={photos}
        photoTitle={accession.scientificName ?? undefined}
      />
      <RecordPageHeaderContent>
        <RecordPageHeaderPropertiesSummary>
          {accession.deaccessionDate == null ? (
            <DerivedStatus statuses={materialStatuses} />
          ) : (
            <Badge color="grey" testId="deaccessioned-accession-chip">
              Deaccessioned
            </Badge>
          )}

          <div
            className="flex items-center gap-1 py-[3px] text-sm font-medium text-grey-500"
            data-cy="provenance-details"
          >
            <Globe2 color={colors.grey[400]} className="h-4 w-4" />
            {accession.provenanceCategory?.codeId &&
              `${accession.provenanceCategory.codeId} - ${
                provenanceLabelMap[accession.provenanceCategory.codeId]
              }`}
          </div>
        </RecordPageHeaderPropertiesSummary>

        <RecordPageHeaderTitleGroup>
          <RecordPageHeaderTitle>
            {accession.scientificName ?? "-"}
          </RecordPageHeaderTitle>
          <RecordPageHeaderSubtitle>
            {accession.taxon?.commonName ?? "-"}
          </RecordPageHeaderSubtitle>
        </RecordPageHeaderTitleGroup>
        <RecordPageHeaderButtons className="lg:order-first lg:self-end">
          {canEdit &&
            !accession.archived &&
            accession.deaccessionDate == null && (
              <Button
                data-cy="new-material-button"
                onClick={handleCreateNewMaterial}
                size={"xs"}
                className="!rounded-md"
                startIcon={Plus}
              >
                New Material
              </Button>
            )}
          {canEdit && !accession.archived && (
            <Button
              data-cy="edit-accession-button"
              size={"xs"}
              onClick={openEdit}
              startIcon={Edit5}
              className="!rounded-md"
            >
              Edit
            </Button>
          )}

          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button
                data-cy="action-menu-button"
                endIcon={DotsHorizontal}
                size={"xs"}
                className="!rounded-md !p-2"
              />
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {accession.taxon == null ? undefined : (
                <DropdownMenuItem asChild testId="go-to-taxon-menu-item">
                  <Link
                    to="/taxonomy/$taxonId"
                    params={{taxonId: accession.taxon.id}}
                  >
                    <ExternalLink1 className="h-4 w-4 text-grey-500" />
                    Go to taxon
                  </Link>
                </DropdownMenuItem>
              )}
              <DropdownMenuSub>
                <DropdownMenuSeparator />
                <DropdownMenuSubTrigger>
                  <Copy1 className="h-4 w-4 text-grey-500" />
                  Copy
                </DropdownMenuSubTrigger>
                <DropdownMenuSubContent>
                  <DropdownMenuGroup>
                    <DropdownMenuItem
                      onSelect={() => {
                        void copyToClipboard(
                          accession.accessionNumber,
                          () => {
                            onSuccess(setSnack)(
                              `"${accession.accessionNumber}" copied to clipboard`,
                            )
                          },
                          () => {
                            //eslint-disable-next-line sonarjs/no-duplicate-string
                            onFailure(setSnack)(new Error("Failed to copy"))
                          },
                        )
                      }}
                    >
                      <Copy1 className="h-4 w-4 text-grey-500" />
                      Copy accession number
                    </DropdownMenuItem>
                    {accession.taxon != null && (
                      <DropdownMenuItem
                        onSelect={() => {
                          void copyToClipboard(
                            accession.taxon?.scientificName ?? "",
                            () => {
                              onSuccess(setSnack)(
                                `"${
                                  accession.taxon?.scientificName ?? ""
                                }" copied to clipboard`,
                              )
                            },
                            () => {
                              onFailure(setSnack)(new Error("Failed to copy"))
                            },
                          )
                        }}
                      >
                        <Copy1 className="h-4 w-4 text-grey-500" />
                        Copy scientific name
                      </DropdownMenuItem>
                    )}
                    {accession.taxon?.commonName != null && (
                      <DropdownMenuItem
                        onSelect={() => {
                          void copyToClipboard(
                            accession.taxon?.commonName ?? "",
                            () => {
                              onSuccess(setSnack)(
                                `"${
                                  accession.taxon?.commonName ?? ""
                                }" copied to clipboard`,
                              )
                            },
                            () => {
                              onFailure(setSnack)(new Error("Failed to copy"))
                            },
                          )
                        }}
                      >
                        <Copy1 className="h-4 w-4 text-grey-500" />
                        Copy common name
                      </DropdownMenuItem>
                    )}
                  </DropdownMenuGroup>
                </DropdownMenuSubContent>
              </DropdownMenuSub>
              {canEdit && (
                <>
                  {accession.deaccessionDate == null && !accession.archived && (
                    <>
                      <DropdownMenuSeparator />
                      <DropdownMenuItem
                        testId="deaccession-menu-item"
                        onClick={openDeaccessionDialog}
                      >
                        <FolderClosed className="h-4 w-4 text-grey-500" />
                        Deaccession
                      </DropdownMenuItem>
                    </>
                  )}

                  {accession.deaccessionDate != null && !accession.archived && (
                    <>
                      <DropdownMenuSeparator />
                      <DropdownMenuItem
                        testId="reinstate-menu-item"
                        onClick={openReinstateDialog}
                      >
                        <RefreshCw5 className="h-4 w-4 text-grey-500" />
                        Reinstate
                      </DropdownMenuItem>
                    </>
                  )}

                  {!accession.archived && (
                    <DropdownMenuItem
                      testId="archive-accession-menu-item"
                      onClick={() => {
                        setTrashDialogOpen(true)
                      }}
                    >
                      <Trash3 className="h-4 w-4 text-grey-500" />
                      Delete
                    </DropdownMenuItem>
                  )}
                </>
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        </RecordPageHeaderButtons>
      </RecordPageHeaderContent>
      {canEdit && (
        <>
          {accessToken == null ? undefined : (
            <Dialog
              open={deaccessionDialogOpen}
              onOpenChange={setDeaccessionDialogOpen}
            >
              <DeaccessionDialogContent
                accessionId={accession.id}
                accessToken={accessToken}
                materialStatuses={materialStatuses}
                open={deaccessionDialogOpen}
                refetch={refetch}
                onClose={closeDeaccessionDialog}
              />
            </Dialog>
          )}
          {accessToken != null && (
            <Dialog
              open={reinstateDialogOpen}
              onOpenChange={setReinstateDialogOpen}
            >
              <ReinstateDialogContent
                accessionId={accession.id}
                accessToken={accessToken}
                refetch={refetch}
                onClose={closeReinstateDialog}
              />
            </Dialog>
          )}
          <TrashAccessionDialog
            open={trashDialogOpen}
            setOpen={setTrashDialogOpen}
            id={accession.id}
            onSuccess={refetch}
            triggerRef={menuAnchorEl}
          />
          <Dialog open={restoreDialogOpen} onOpenChange={setRestoreDialogOpen}>
            <RestoreAccessionDialogContent
              id={accession.id}
              onSuccess={refetch}
            />
          </Dialog>
        </>
      )}
    </RecordPageHeader>
  )
}

export default AccessionRecordPageHeader
