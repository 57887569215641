import {zodResolver} from "@hookform/resolvers/zod"
import * as A from "fp-ts/Array"
import {useMemo} from "react"
import {FormProvider} from "react-hook-form"
import {match} from "ts-pattern"
import {z} from "zod"
import {FilterTitle} from "src/features/filters/components/modal/filter-title"
import {FormChipGrid} from "src/components/chip-grid"
import type {PlantMaterialFilter} from "generated/graphql"
import {MaterialGroup} from "generated/graphql"
import {CollapsableRadioButton, RadioGroup} from "src/components/radio"
import {materialGroupChips} from "../../../create-accession/components/add-plant-material-form/plant-material-data-fields"
import type {FilterModalProps} from "../../components/modal/filter-modal"
import {FilterModal} from "../../components/modal/filter-modal"
import {FilterModalButtons} from "../../components/modal/modal-buttons"
import {FilterModalError} from "../../components/modal/modal-error"
import type {FilterFormProps} from "../modals/types"
import {useDispatchFilter} from "../modals/use-dispatch-filter"
import {determineInitialOperation} from "../modals/determine-initial-operation"
import {useFilterForm} from "../../components/modal/use-filter-form"

const createDefaultValues = (
  initialValue?: NonNullable<PlantMaterialFilter["materialGroup"]>,
) => {
  const defaultValues = {
    is:
      initialValue?.or == null
        ? []
        : initialValue.or.flatMap(({eq}) => eq).filter((val) => val != null),
    isNot:
      initialValue?.or == null
        ? []
        : initialValue.or.flatMap(({neq}) => neq).filter((val) => val != null),
  }
  return {
    defaultValues,
    initialOperation: determineInitialOperation(defaultValues, "is"),
  }
}

const plantMaterialStatus = z.nativeEnum(MaterialGroup)
const resolver = zodResolver(
  z.object({
    is: z.array(plantMaterialStatus),
    isNot: z.array(plantMaterialStatus),
  }),
)

export const MaterialGroupFilterForm = ({
  initialValue,
  onClose,
  dispatchFilters,
}: FilterFormProps) => {
  const {error, setError, operation, handleRadioChange, formMethods} =
    useFilterForm({
      resolver,
      ...createDefaultValues(initialValue?.materialGroup ?? undefined),
    })
  const {addModifyFilter, deleteFilter} = useDispatchFilter({
    dispatchFilters,
    id: initialValue?.id,
    onClose,
  })

  const onSubmit = useMemo(
    () =>
      formMethods.handleSubmit((values) => {
        match(operation)
          .with("is", () => {
            if (A.isNonEmpty(values.is)) {
              addModifyFilter({
                materialGroup: {
                  or: values.is.map((val) => ({
                    eq: val,
                  })),
                },
              })
              onClose()
            } else {
              setError("Select a material type to continue")
            }
          })
          .with("isNot", () => {
            if (A.isNonEmpty(values.isNot)) {
              addModifyFilter({
                materialGroup: {
                  or: values.isNot.map((val) => ({
                    neq: val,
                  })),
                },
              })
              onClose()
            } else {
              setError("Select a material type to continue")
            }
          })
          .run()
      }),
    [formMethods, operation, onClose, addModifyFilter, setError],
  )

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={onSubmit}>
        <div className="mb-4 flex flex-col gap-4">
          <FilterTitle title="Material type" deleteFilter={deleteFilter} />
          <FilterModalError error={error} />
          <RadioGroup
            value={operation}
            onChange={handleRadioChange}
            label="Operation"
          >
            {(props) => (
              <>
                <CollapsableRadioButton
                  selectedValue="is"
                  testId="material-group-is-radio"
                  label="Is"
                  {...props}
                >
                  <FormChipGrid
                    name="is"
                    testId="material-group-is-chip-grid"
                    chips={materialGroupChips}
                    multiple
                  />
                </CollapsableRadioButton>
                <CollapsableRadioButton
                  selectedValue="isNot"
                  testId="material-group-is-not-radio"
                  label="Is not"
                  {...props}
                >
                  <FormChipGrid
                    name="isNot"
                    testId="material-group-is-not-chip-grid"
                    chips={materialGroupChips}
                    multiple
                  />
                </CollapsableRadioButton>
              </>
            )}
          </RadioGroup>
        </div>
        <FilterModalButtons onCancel={onClose} testIdPrefix="material-group" />
      </form>
    </FormProvider>
  )
}

// Seperated from form component to make sure popover unmount causes state reset
export const MaterialGroupFilterModal = ({
  open,
  onClose,
  anchorEl,
  ...props
}: FilterFormProps & FilterModalProps) => (
  <FilterModal anchorEl={anchorEl} open={open} onClose={onClose}>
    <MaterialGroupFilterForm onClose={onClose} {...props} />
  </FilterModal>
)
