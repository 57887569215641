import {useCallback, useEffect, useMemo, useState} from "react"
import type {DeepPartial, FieldValues, UseFormProps} from "react-hook-form"
import {useForm} from "react-hook-form"
import type {FilterOperation} from "../../plant-materials"

export const useFilterForm = <T extends FieldValues, O = FilterOperation>({
  defaultValues: defaultValues_,
  resolver,
  initialOperation,
}: {
  defaultValues: DeepPartial<T>
  resolver: UseFormProps<T>["resolver"]
  initialOperation: O
}) => {
  const defaultValues = useMemo(() => defaultValues_, [defaultValues_])
  const [error, setError] = useState<null | string>(null)
  const [operation, setOperation] = useState<O>(initialOperation)

  const formMethods = useForm<T>({
    defaultValues,
    criteriaMode: "all",
    resolver,
  })

  const handleRadioChange = useCallback((val: O) => {
    setOperation(val)
  }, [])

  useEffect(() => {
    if (formMethods.formState.isDirty) {
      setError(null)
    }
  }, [formMethods.formState.isDirty])

  return {
    error,
    setError,
    operation,
    setOperation,
    handleRadioChange,
    formMethods,
  }
}
