import type {AutocompleteRenderGetTagProps} from "@mui/material/Autocomplete"
import {
  type RedListStatus,
  useGetOrgRedListStatusCountsQuery,
  useGetSiteRedListStatusCountsQuery,
} from "generated/graphql"
import {colors} from "src/colors"
import {FormAutocomplete} from "src/components/autocomplete"
import {
  useOrganisationSubdomainStruct,
  usePlaceStruct,
} from "src/utils/hooks/place"
import {Tag} from "@hortis/ui/tag"
import {redListStatusLabelMap} from "@hortis/ui/iucn-red-list-tag"

export const renderTags = (
  tags: Array<RedListStatus>,
  getTagProps: AutocompleteRenderGetTagProps,
) => (
  <div className="flex flex-wrap">
    {tags.map((tag, index) => (
      <Tag {...getTagProps({index})} key={tag}>
        {redListStatusLabelMap[tag]}
      </Tag>
    ))}
  </div>
)

interface RedListStatusAutocompleteProps {
  name: string
  testId?: string
  options: ReadonlyArray<RedListStatus>
  loading?: boolean
}

export const RedListStatusAutocomplete = ({
  name,
  testId,
  options,
  loading,
}: RedListStatusAutocompleteProps) => {
  return (
    <FormAutocomplete
      multiple
      testId={testId}
      options={options}
      getOptionLabel={(option) => redListStatusLabelMap[option]}
      label={null}
      sx={{
        "& label": {display: "none"},
        "& .MuiOutlinedInput-root": {py: "4px"},
        "& .MuiOutlinedInput-notchedOutline": {
          transition: `all 125ms ease`,
          border: `1px solid ${colors.grey[300]}`,
          boxShadow: `0 0 0 0px ${colors.primary[100]}`,
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          border: `1px solid ${colors.primary[300]}`,
          boxShadow: `0 0 0 4px ${colors.primary[100]}`,
        },
      }}
      renderTags={renderTags}
      fullWidth
      loading={loading}
      name={name}
      componentsProps={{popper: {disablePortal: true}}}
    />
  )
}

export const OrgRedListStatusAutocomplete = ({
  name,
  testId,
}: Pick<RedListStatusAutocompleteProps, "name" | "testId">) => {
  const subdomain = useOrganisationSubdomainStruct()
  const pause = subdomain.data == null
  const [{data, fetching}] = useGetOrgRedListStatusCountsQuery({
    variables: {
      organisationSubdomain: subdomain.data ?? "",
    },
    pause,
  })
  const lifeForms = data?.org?.redListStatusCounts?.map(
    ({redListStatus}) => redListStatus,
  )

  return (
    <RedListStatusAutocomplete
      name={name}
      testId={testId}
      loading={fetching || subdomain.loading}
      options={lifeForms ?? []}
    />
  )
}

export const SiteRedListStatusAutocomplete = ({
  name,
  testId,
}: Pick<RedListStatusAutocompleteProps, "name" | "testId">) => {
  const place = usePlaceStruct()
  const pause = place.data == null
  const [{data, fetching}] = useGetSiteRedListStatusCountsQuery({
    variables: {
      organisationSubdomain: place.data?.orgName ?? "",
      collectionSiteSlug: place.data?.siteSlug ?? "",
    },
    pause,
  })
  const lifeForms = data?.org?.site?.redListStatusCounts?.map(
    ({redListStatus}) => redListStatus,
  )

  return (
    <RedListStatusAutocomplete
      name={name}
      testId={testId}
      loading={fetching || place.loading}
      options={lifeForms ?? []}
    />
  )
}
