import * as ContextMenuPrimitive from "@radix-ui/react-context-menu"
import {twMerge} from "tailwind-merge"
import {forwardRef, useCallback, useRef, type SyntheticEvent} from "react"
import {Check, ChevronRight, Circle} from "../icons"
import {CircularProgress} from "../progress"

const stopEventPropagation = (e: SyntheticEvent | Event): void => {
  e.preventDefault()
  e.stopPropagation()
}

/**
 * Radix UI leaves pointer-events none on body when dialog is closed
 *
 * This serves to address the issue with the context menu automatically adding the pointer-events: none style to the body element when a nested dialog is opened & closed.
 *
 * See issues here - https://github.com/radix-ui/primitives/issues/1241
 */
const useContextMenuCloseFix = () => {
  const mutationRef = useRef<MutationObserver>()
  const handleClose = useCallback(() => {
    mutationRef.current = new MutationObserver((e) => {
      const target = e.at(-1)?.target

      if (!(target instanceof HTMLElement)) {
        mutationRef.current?.disconnect()
        return
      }

      if (target.style.pointerEvents === "none") {
        document.body.style.pointerEvents = ""

        mutationRef.current?.disconnect()
      }
    })

    mutationRef.current.observe(document.body, {
      attributes: true,
      attributeFilter: ["style"],
    })
  }, [])

  return {handleClose, mutationRef}
}

const ContextMenu = ContextMenuPrimitive.Root

const ContextMenuTrigger = ContextMenuPrimitive.Trigger

const ContextMenuGroup = ContextMenuPrimitive.Group

const ContextMenuPortal = ContextMenuPrimitive.Portal

const ContextMenuSub = ContextMenuPrimitive.Sub

const ContextMenuRadioGroup = ContextMenuPrimitive.RadioGroup

const ContextMenuSubTrigger = forwardRef<
  React.ElementRef<typeof ContextMenuPrimitive.SubTrigger>,
  React.ComponentPropsWithoutRef<typeof ContextMenuPrimitive.SubTrigger> & {
    inset?: boolean
  }
>(({className, inset = false, children, ...props}, ref) => (
  <ContextMenuPrimitive.SubTrigger
    ref={ref}
    className={twMerge(
      "focus:bg-grey-100 text-grey-900 data-[state=open]:bg-grey-100 flex cursor-default select-none items-center gap-3 rounded-md px-3 py-2 text-sm font-medium outline-none transition-colors data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
      inset && "pl-8",
      className,
    )}
    {...props}
  >
    {children}
    <ChevronRight className="text-grey-400 ml-auto h-4 w-4" />
  </ContextMenuPrimitive.SubTrigger>
))

// @ts-expect-error -- Reclared forwardRef does not have a displayName
ContextMenuSubTrigger.displayName = ContextMenuPrimitive.SubTrigger.displayName

const ContextMenuSubContent = forwardRef<
  React.ElementRef<typeof ContextMenuPrimitive.SubContent>,
  React.ComponentPropsWithoutRef<typeof ContextMenuPrimitive.SubContent>
>(({className, ...props}, ref) => (
  <ContextMenuPrimitive.SubContent
    ref={ref}
    className={twMerge(
      "data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 border-grey-200 z-50  min-w-[8rem] overflow-hidden rounded-md border bg-white p-1 shadow-md",
      className,
    )}
    {...props}
  />
))
// @ts-expect-error -- Reclared forwardRef does not have a displayName
ContextMenuSubContent.displayName = ContextMenuPrimitive.SubContent.displayName

const ContextMenuContent = forwardRef<
  React.ElementRef<typeof ContextMenuPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof ContextMenuPrimitive.Content>
>(({className, ...props}, ref) => (
  <ContextMenuPrimitive.Portal>
    <ContextMenuPrimitive.Content
      // Prevents conflicts with other dialogs e.g CommandDialog & Sheets
      onCloseAutoFocus={stopEventPropagation}
      ref={ref}
      className={twMerge(
        "animate-in fade-in-80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 border-grey-200 z-50 min-w-[10rem] overflow-hidden rounded-md border bg-white p-1 shadow-md",
        className,
      )}
      {...props}
    />
  </ContextMenuPrimitive.Portal>
))
// @ts-expect-error -- Reclared forwardRef does not have a displayName
ContextMenuContent.displayName = ContextMenuPrimitive.Content.displayName

const ContextMenuItem = forwardRef<
  React.ElementRef<typeof ContextMenuPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof ContextMenuPrimitive.Item> & {
    inset?: boolean
  }
>(({className, inset = false, ...props}, ref) => (
  <ContextMenuPrimitive.Item
    ref={ref}
    className={twMerge(
      "text-grey-900 focus:bg-grey-100 data-[selected=true]:bg-grey-100 flex cursor-pointer items-center gap-3 rounded-md px-3 py-2 text-sm font-medium outline-none transition-all duration-100 ",
      inset && "pl-8",
      className,
    )}
    {...props}
  />
))
// @ts-expect-error -- Reclared forwardRef does not have a displayName
ContextMenuItem.displayName = ContextMenuPrimitive.Item.displayName

const ContextMenuCheckboxItem = forwardRef<
  React.ElementRef<typeof ContextMenuPrimitive.CheckboxItem>,
  React.ComponentPropsWithoutRef<typeof ContextMenuPrimitive.CheckboxItem>
>(({className, children, checked, ...props}, ref) => (
  <ContextMenuPrimitive.CheckboxItem
    ref={ref}
    className={twMerge(
      "relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
      className,
    )}
    checked={checked}
    {...props}
  >
    <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
      <ContextMenuPrimitive.ItemIndicator>
        <Check className="h-4 w-4" />
      </ContextMenuPrimitive.ItemIndicator>
    </span>
    {children}
  </ContextMenuPrimitive.CheckboxItem>
))
// @ts-expect-error -- Reclared forwardRef does not have a displayName
ContextMenuCheckboxItem.displayName =
  ContextMenuPrimitive.CheckboxItem.displayName

const ContextMenuRadioItem = forwardRef<
  React.ElementRef<typeof ContextMenuPrimitive.RadioItem>,
  React.ComponentPropsWithoutRef<typeof ContextMenuPrimitive.RadioItem>
>(({className, children, ...props}, ref) => (
  <ContextMenuPrimitive.RadioItem
    ref={ref}
    className={twMerge(
      "relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
      className,
    )}
    {...props}
  >
    <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
      <ContextMenuPrimitive.ItemIndicator>
        <Circle className="h-2 w-2 fill-current" />
      </ContextMenuPrimitive.ItemIndicator>
    </span>
    {children}
  </ContextMenuPrimitive.RadioItem>
))
// @ts-expect-error -- Reclared forwardRef does not have a displayName
ContextMenuRadioItem.displayName = ContextMenuPrimitive.RadioItem.displayName

const ContextMenuLabel = forwardRef<
  React.ElementRef<typeof ContextMenuPrimitive.Label>,
  React.ComponentPropsWithoutRef<typeof ContextMenuPrimitive.Label> & {
    inset?: boolean
  }
>(({className, inset = false, ...props}, ref) => (
  <ContextMenuPrimitive.Label
    ref={ref}
    className={twMerge(
      "px-2 py-1.5 text-sm font-semibold",
      inset && "pl-8",
      className,
    )}
    {...props}
  />
))
// @ts-expect-error -- Reclared forwardRef does not have a displayName
ContextMenuLabel.displayName = ContextMenuPrimitive.Label.displayName

const ContextMenuSeparator = forwardRef<
  React.ElementRef<typeof ContextMenuPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof ContextMenuPrimitive.Separator>
>(({className, ...props}, ref) => (
  <ContextMenuPrimitive.Separator
    ref={ref}
    className={twMerge("bg-grey-200 -mx-1 my-1 h-px", className)}
    {...props}
  />
))
// @ts-expect-error -- Reclared forwardRef does not have a displayName
ContextMenuSeparator.displayName = ContextMenuPrimitive.Separator.displayName

const ContextMenuShortcut = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLSpanElement>) => {
  return (
    <span
      className={twMerge("ml-auto text-xs tracking-widest", className)}
      {...props}
    />
  )
}

ContextMenuShortcut.displayName = "ContextMenuShortcut"

const ContextMenuLoading = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <ContextMenuGroup>
      <div
        className={twMerge("flex items-center justify-center", className)}
        {...props}
      >
        <CircularProgress size={20} />
      </div>
    </ContextMenuGroup>
  )
}

export {
  ContextMenu,
  ContextMenuCheckboxItem,
  ContextMenuContent,
  ContextMenuLoading,
  ContextMenuGroup,
  ContextMenuItem,
  ContextMenuLabel,
  ContextMenuPortal,
  ContextMenuRadioGroup,
  ContextMenuRadioItem,
  ContextMenuSeparator,
  ContextMenuShortcut,
  ContextMenuSub,
  ContextMenuSubContent,
  ContextMenuSubTrigger,
  ContextMenuTrigger,
  useContextMenuCloseFix,
}
