import type {FollowUp} from "generated/graphql"
import {AlertTriangle} from "@hortis/ui/icons"
import {colors} from "src/colors"
import {isOverdueFollowup} from "./check-overdue-followup"
// import {AlertTriangle} from "src/components/icons/streamline/alert-triangle"

interface OverdueFollowupProps {
  lastObserved?: string | null
  followUp?: FollowUp | null
  reversed?: boolean
}

export const OverdueFollowupAlert = ({
  lastObserved,
  followUp: followUpProp,
  reversed,
}: OverdueFollowupProps) => {
  const followUp =
    followUpProp == null
      ? null
      : {count: followUpProp.count, period: followUpProp.period}

  const showAlert =
    lastObserved != null && followUp != null
      ? isOverdueFollowup(new Date(lastObserved), followUp)
      : false

  return showAlert && followUp != null ? (
    <p
      className="flex items-center gap-1 text-sm font-medium text-warning-600 "
      data-cy="overdue-followup-alert"
    >
      {reversed === true ? (
        <>
          <AlertTriangle color={colors.warning[500]} size={"20px"} />
          Overdue
        </>
      ) : (
        <>
          Overdue
          <AlertTriangle color={colors.warning[500]} size={"20px"} />
        </>
      )}
    </p>
  ) : null
}
