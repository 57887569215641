import type {DialogProps} from "@mui/material/Dialog"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import type {DialogTitleProps} from "@mui/material/DialogTitle"
import CoreDialogTitle from "@mui/material/DialogTitle"
import type {ReactNode} from "react"
import {memo} from "react"
import type {ButtonProps} from "@hortis/ui/button"
import {Button} from "@hortis/ui/button"
import {Link} from "@tanstack/react-router"
import {useSmallDisplay} from "../utils/hooks/media-query"
import {fromSxFn} from "../utils/sx"
import {Typography} from "./typography/typography"

const dialogContentStyle = fromSxFn((theme) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
}))

const BaseDialogContents = ({children}: {children: ReactNode}): JSX.Element => {
  return <DialogContent sx={dialogContentStyle}>{children}</DialogContent>
}

interface BaseDialogProps {
  title: string
  children: ReactNode
  open: boolean
  actionButtons: Array<
    ButtonProps & {key: string | number; href?: string; target?: string}
  >
  onClose?: () => void
  testId?: string
}

const BaseDialogActions = ({
  actionButtons: buttons,
}: Pick<BaseDialogProps, "actionButtons">): JSX.Element => {
  return (
    <DialogActions>
      <div className="flex w-full flex-col gap-2 sm:flex-row sm:justify-end">
        {buttons.map(({key, href, target, ...props}) =>
          href == null ? (
            <Button key={key} {...props} />
          ) : (
            <Button key={key} asChild {...props}>
              <Link to={href} target={target}>
                {props.children}
              </Link>
            </Button>
          ),
        )}
      </div>
    </DialogActions>
  )
}

const dialogTitleStyle = fromSxFn((theme) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
}))

const DialogTitle: (props: DialogTitleProps) => JSX.Element = CoreDialogTitle

const BaseDialogBody = memo(function MemoisedBaseDialogBody({
  title,
  children,
  actionButtons,
}: Pick<BaseDialogProps, "title" | "children" | "actionButtons">): JSX.Element {
  return (
    <>
      <DialogTitle id="base-dialog-title" sx={dialogTitleStyle}>
        <Typography fontSize="20px" fontWeight="600">
          {title}
        </Typography>
      </DialogTitle>
      <BaseDialogContents>{children}</BaseDialogContents>
      <BaseDialogActions actionButtons={actionButtons} />
    </>
  )
})

const mobileStyle: DialogProps["PaperProps"] = {
  sx: fromSxFn((theme) => ({
    margin: theme.spacing(2),
    padding: 1,
  })),
}

const largerStyle: DialogProps["PaperProps"] = {
  sx: fromSxFn((theme) => ({
    margin: theme.spacing(3),
    padding: 1,
  })),
}

export const BaseDialog = ({
  open,
  onClose,
  testId,
  ...rest
}: BaseDialogProps): JSX.Element => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="base-dialog-title"
      PaperProps={useSmallDisplay() ? mobileStyle : largerStyle}
      data-cy={testId}
    >
      <BaseDialogBody {...rest} />
    </Dialog>
  )
}
