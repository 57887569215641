import {ChevronDown} from "@hortis/ui/icons"
import {twMerge} from "tailwind-merge"

interface NavGroupHeaderProps {
  className?: string
  children: React.ReactNode
}

const NavGroupHeader = ({children, className}: NavGroupHeaderProps) => {
  return (
    <div
      className={twMerge(
        "flex items-center justify-between gap-2 rounded-[6px] px-3 py-2 text-xs font-medium hover:bg-grey-50",
        className,
      )}
    >
      {children}

      <ChevronDown className="h-4 w-4 shrink-0 text-grey-400" />
    </div>
  )
}

export default NavGroupHeader
