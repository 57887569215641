import {gql} from "graphql-request"
import {CORE_COLLECTION_SITE_LOCATION_FIELDS} from "src/features/locations/graphql"
import {CORE_ORGANISATION_MEMBERS_FIELDS} from "src/features/settings/components/org/graphql"
import {RECORD_TAG_FIELDS} from "src/features/tags/graphql"

export const CORE_MATERIAL_IMAGE_FIELDS = gql`
  fragment MaterialImageFields on MaterialImage {
    id
    fileName
    bucketName
    description
    capturedAt
    capturedBy
    uploadedAt
    uploadedBy {
      id
      avatarUrl
      givenName
      familyName
      initials
    }
    manualImport
    isPrimaryImage
  }
`

export const CORE_MATERIAL_HISTORY_FIELDS = gql`
  ${CORE_ORGANISATION_MEMBERS_FIELDS}
  fragment MaterialHistoryFields on PlantMaterialHistory {
    createdAt
    wasManualImport
    createdBy {
      ...OrganisationMemberFields
    }
    historicCreatedByInitials
    wasArchived
    wasRestored
    fromStatus
    toStatus
    fromMaterialGroup
    toMaterialGroup
    fromCollectionSiteLocationId
    fromCollectionSiteLocation {
      code
      name
      archived
    }
    toCollectionSiteLocationId
    toCollectionSiteLocation {
      code
      name
      archived
    }
    fromQuantity
    toQuantity
    fromMassPlanting
    toMassPlanting
    fromWeight
    toWeight
    fromFirstPresent
    toFirstPresent
    fromFirstAbsent
    toFirstAbsent
    fromPosition {
      longitude
      latitude
      elevation
    }
    toPosition {
      longitude
      latitude
      elevation
    }
    fromSex
    toSex
    fromTagNumber
    toTagNumber
    fromNotes
    toNotes
    fromFollowUp {
      count
      period
    }
    toFollowUp {
      count
      period
    }
    fromLastObserved
    toLastObserved
    fromCondition
    toCondition
    madePublic
    madePrivate
  }
`

export const CORE_MATERIAL_FIELDS = gql`
  ${CORE_MATERIAL_IMAGE_FIELDS}
  ${CORE_MATERIAL_HISTORY_FIELDS}
  ${RECORD_TAG_FIELDS}
  ${CORE_COLLECTION_SITE_LOCATION_FIELDS}
  fragment MaterialFields on PlantMaterial {
    id
    archived
    qualifier
    quantity
    massPlanting
    weight
    status
    firstPresent
    firstAbsent
    materialGroup
    notes
    sex
    tagNumber
    public
    collectionSiteLocation {
      ...CollectionSiteLocationFields
    }
    tagsConnection {
      nodes {
        ...RecordTagFields
      }
    }
    lastObserved
    daysOverdueFollowUp
    followUp {
      count
      period
    }
    condition
    createdBy {
      givenName
      familyName
      id
      avatarUrl
      initials
    }
    primaryImage {
      ...MaterialImageFields
    }
    images {
      ...MaterialImageFields
    }
    position {
      latitude
      longitude
      elevation
    }
    accession {
      id
      accessionNumber
      donor
      accessionDate
      scientificName
      taxon {
        id
        scientificName
        commonName
        nativeDistribution
        sharedScientificName {
          taxon {
            globalRedListStatus
          }
          classification {
            family
          }
        }
      }
      provenanceCategory {
        codeId
        name
        ordering
      }
      plantMaterial(filters: $relatedMaterialsFilters) {
        id
        archived
        status
        qualifier
        materialGroup
        massPlanting
        public
        primaryImage {
          ...MaterialImageFields
        }
        images {
          ...MaterialImageFields
        }
        collectionSiteLocation {
          id
          name
          code
          archived
        }
        accession {
          scientificName
        }
      }
    }
    historyConnection {
      nodes {
        ...MaterialHistoryFields
      }
    }
  }
`

export const GET_MATERIAL = gql`
  ${CORE_MATERIAL_FIELDS}
  query GetMaterial(
    $organisationSubdomain: String!
    $collectionSiteSlug: String!
    $accessionNumber: String!
    $qualifier: String!
    $relatedMaterialsFilters: PlantMaterialFilter
  ) {
    org: organisationBySubdomain(subdomain: $organisationSubdomain) {
      site: collectionSiteBySlug(slug: $collectionSiteSlug) {
        result: plantMaterial(
          accessionNumber: $accessionNumber
          qualifier: $qualifier
        ) {
          ...MaterialFields
        }
      }
    }
  }
`

export const UPDATE_PLANT_MATERIAL = gql`
  ${CORE_MATERIAL_FIELDS}
  mutation UpdatePlantMaterial(
    $materialId: ID!
    $updates: PlantMaterialUpdates!
    $relatedMaterialsFilters: PlantMaterialFilter
  ) {
    updatePlantMaterial(materialId: $materialId, updates: $updates) {
      __typename
      ... on PlantMaterial {
        ...MaterialFields
      }
      ... on GenericError {
        message
      }
    }
  }
`

export const UPDATE_MATERIAL_IMAGE = gql`
  ${CORE_MATERIAL_IMAGE_FIELDS}
  mutation UpdateMaterialImage(
    $materialImageId: ID!
    $updates: MaterialImageUpdates!
  ) {
    updateMaterialImage(materialImageId: $materialImageId, updates: $updates) {
      __typename
      ... on MaterialImage {
        ...MaterialImageFields
      }
      ... on GenericError {
        message
      }
    }
  }
`

export const ARCHIVE_MATERIAL = gql`
  mutation ArchiveMaterial(
    $materialId: ID!
    $relatedMaterialsFilters: PlantMaterialFilter
  ) {
    archivePlantMaterial(materialId: $materialId) {
      __typename
      ... on PlantMaterial {
        ...MaterialFields
      }
      ... on GenericError {
        message
      }
    }
  }
`

export const ARCHIVE_MATERIALS = gql`
  mutation ArchiveMaterials($ids: [ID!]!) {
    archivePlantMaterials(ids: $ids) {
      plantMaterials {
        id
        archived
      }
      errors {
        ... on GenericError {
          message
        }
      }
      success
    }
  }
`

export const RESTORE_MATERIAL = gql`
  mutation RestoreMaterial(
    $materialId: ID!
    $relatedMaterialsFilters: PlantMaterialFilter
  ) {
    restorePlantMaterial(materialId: $materialId) {
      __typename
      ... on PlantMaterial {
        ...MaterialFields
      }
      ... on GenericError {
        message
      }
    }
  }
`

export const SET_PRIMARY_IMAGE = gql`
  ${CORE_MATERIAL_IMAGE_FIELDS}
  mutation SetPrimaryImage($materialImageId: ID!) {
    setPrimaryImage(materialImageId: $materialImageId) {
      __typename
      ... on MaterialImage {
        ...MaterialImageFields
      }
      ... on GenericError {
        message
      }
    }
  }
`
export const UNSET_PRIMARY_IMAGE = gql`
  ${CORE_MATERIAL_IMAGE_FIELDS}
  mutation UnsetPrimaryImage($materialImageId: ID!) {
    unsetPrimaryImage(materialImageId: $materialImageId) {
      __typename
      ... on MaterialImage {
        ...MaterialImageFields
      }
      ... on GenericError {
        message
      }
    }
  }
`

export const UPLOAD_MATERIAL_IMAGE = gql`
  ${CORE_MATERIAL_IMAGE_FIELDS}
  mutation UploadMaterialImage(
    $plantMaterialId: ID!
    $image: MaterialImageUpload!
  ) {
    uploadMaterialImage(plantMaterialId: $plantMaterialId, image: $image) {
      ... on MaterialImage {
        __typename
        ...MaterialImageFields
      }
    }
  }
`

export const ARCHIVE_MATERIAL_IMAGE = gql`
  ${CORE_MATERIAL_IMAGE_FIELDS}
  mutation ArchiveMaterialImage($materialImageId: ID!) {
    archiveMaterialImage(materialImageId: $materialImageId) {
      __typename
      ... on MaterialImage {
        ...MaterialImageFields
      }
      ... on GenericError {
        message
      }
    }
  }
`
export const ADD_TAG_TO_MATERIALS = gql`
  mutation AddTagToMaterials($tagId: ID!, $ids: [ID!]!) {
    addTagPlantMaterials(tagId: $tagId, ids: $ids) {
      success
      plantMaterials {
        id
      }
      errors {
        ... on GenericError {
          message
        }
      }
    }
  }
`
export const REMOVE_TAG_FROM_MATERIALS = gql`
  mutation RemoveTagFromMaterials($tagId: ID!, $ids: [ID!]!) {
    removeTagPlantMaterials(tagId: $tagId, ids: $ids) {
      success
      plantMaterials {
        id
      }
      errors {
        ... on GenericError {
          message
        }
      }
    }
  }
`
