import {Button} from "@hortis/ui/button"
import {Plus} from "@hortis/ui/icons"
import {useGetOrgCollectionSitesQuery} from "generated/graphql"
import {memo, useState} from "react"
import {colors} from "src/colors"
import {SlLandscapeIcon} from "src/components/icons/streamline/landscape"
import {SlOrganisationIcon} from "src/components/icons/streamline/organisation"
import {Profile} from "src/components/icons/streamline/profile"
import {useAccessRole} from "src/features/permissions/use-access-role"
import {useOrganisationSubdomainBasic} from "src/utils/hooks/place"
import {NewSiteDialog} from "../../components/new-site-drawer/new-site-drawer"
import {CollectionSitesNav} from "./collection-sites-nav"
import {NavItemBase} from "./nav-item-base"

interface SettingsNavProps {
  onNavigate?: () => void
  mobile?: boolean
}

export const SettingsNav = memo(function SettingsNav({
  onNavigate,
  mobile,
}: SettingsNavProps) {
  const [newSiteDrawerOpen, setNewSiteDrawerOpen] = useState(false)
  const {canEdit, min, AccessRole} = useAccessRole()
  const subdomain = useOrganisationSubdomainBasic()
  const [{data, fetching, error}] = useGetOrgCollectionSitesQuery({
    variables: {organisationSubdomain: subdomain ?? ""},
    pause: subdomain == null,
  })
  const sites = data?.org?.collectionSites

  return (
    <nav data-cy="settings-navigation">
      <div className="flex flex-col gap-1">
        <div className="flex items-center gap-3.5 px-3 py-2 text-sm font-medium text-grey-500">
          <SlOrganisationIcon
            color={colors.grey[500]}
            width="20px"
            height="20px"
          />
          Organization
        </div>
        <ul className="flex flex-col gap-0.5">
          <NavItemBase
            href="/settings/org/general"
            label="General"
            testId={`${fetching ? "loading-" : ""}org-overview-link`}
            onNavigate={onNavigate}
          />
          <NavItemBase
            href="/settings/org/members"
            label="Members"
            testId={`${fetching ? "loading-" : ""}org-members-link`}
            onNavigate={onNavigate}
          />
          {canEdit && (
            <NavItemBase
              href="/settings/org/billing"
              label="Billing"
              testId={`${fetching ? "loading-" : ""}org-billing-link`}
              onNavigate={onNavigate}
            />
          )}
          <NavItemBase
            href="/settings/org/tags"
            label="Tags"
            testId={`${fetching ? "loading-" : ""}org-tags-link`}
            onNavigate={onNavigate}
          />
        </ul>
      </div>
      <div className="flex flex-col gap-1 pt-3">
        <div className="flex items-center gap-3.5 px-3 py-2 text-sm font-medium text-grey-500">
          <Profile color={colors.grey[500]} width="20px" height="20px" />
          Account
        </div>
        <ul className="flex flex-col gap-0.5">
          <NavItemBase
            href="/settings/account/profile"
            label="Profile"
            testId={`${fetching ? "loading-" : ""}account-profile-link`}
            onNavigate={onNavigate}
          />
          <NavItemBase
            href="/settings/account/preferences"
            label="Preferences"
            testId={`${fetching ? "loading-" : ""}account-preferences-link`}
            onNavigate={onNavigate}
          />
        </ul>
      </div>
      <div className="flex flex-col gap-1 pt-3">
        <div className="flex items-center gap-3.5 px-3 py-2 text-sm font-medium text-grey-500">
          <SlLandscapeIcon
            color={colors.grey[500]}
            width="20px"
            height="20px"
          />
          Collection sites
        </div>
        {min(AccessRole.Admin) === true && (
          <div className="py-1">
            <Button
              size="sm"
              fullWidth
              className="justify-start !gap-3 !pl-3"
              startIcon={Plus}
              onClick={() => {
                setNewSiteDrawerOpen(true)
              }}
              testId={`new-site-button${mobile === true ? "-mobile" : ""}`}
            >
              New site
            </Button>
            <NewSiteDialog
              open={newSiteDrawerOpen}
              onOpenChange={setNewSiteDrawerOpen}
            />
          </div>
        )}
        <div className="flex flex-col gap-0.5">
          <CollectionSitesNav
            sites={sites}
            error={error}
            onNavigate={onNavigate}
            canEdit={canEdit}
          />
        </div>
      </div>
    </nav>
  )
})
