import {twMerge} from "tailwind-merge"
import {HortisLogo} from "@hortis/ui/icons"
import type {AccessionHistoryFieldsFragment} from "../../../../../generated/graphql"
import type {DateStringFormatter} from "../../../../utils/hooks/language"
import {formatDateString} from "../../../collection/components/format-date-string"
import type {ActivityItemProps} from "./activity-item"
import {
  BoldTypography,
  getCoreProps,
  getUserDisplayName,
  parseHistory,
  Typography,
} from "./utils"

const parsers: Array<
  (
    history: AccessionHistoryFieldsFragment,
    formatDateString: DateStringFormatter,
  ) => ActivityItemProps | null
> = [
  (history) =>
    history.wasArchived != null && history.wasArchived
      ? {
          ...getCoreProps(history),
          message: (
            <Typography>
              <BoldTypography>{getUserDisplayName(history)}</BoldTypography>{" "}
              deleted the record
            </Typography>
          ),
        }
      : null,
  (history) =>
    history.wasRestored != null && history.wasRestored
      ? {
          ...getCoreProps(history),
          message: (
            <Typography>
              <BoldTypography>{getUserDisplayName(history)}</BoldTypography>{" "}
              restored the record
            </Typography>
          ),
        }
      : null,
  (history) =>
    history.wasDeaccessioned != null && history.wasDeaccessioned
      ? {
          ...getCoreProps(history),
          message: (
            <Typography>
              <BoldTypography>{getUserDisplayName(history)}</BoldTypography>{" "}
              deaccessioned the record
            </Typography>
          ),
        }
      : null,
  (history) =>
    history.wasReinstated != null && history.wasReinstated
      ? {
          ...getCoreProps(history),
          message: (
            <Typography>
              <BoldTypography>{getUserDisplayName(history)}</BoldTypography>{" "}
              reinstated the record
            </Typography>
          ),
        }
      : null,
  (history) =>
    history.createdPlantMaterial == null
      ? null
      : {
          ...getCoreProps(history),
          icon: history.createdPlantMaterial.wasManualImport ? (
            <div className="flex h-6 w-6 items-center justify-center">
              <HortisLogo size="22px" />
            </div>
          ) : undefined,
          message: history.createdPlantMaterial.wasManualImport ? (
            <Typography>
              Plant material{" "}
              <BoldTypography>
                {history.createdPlantMaterial.qualifier}
              </BoldTypography>{" "}
              was imported into Hortis
            </Typography>
          ) : (
            <Typography>
              <BoldTypography>{getUserDisplayName(history)}</BoldTypography>{" "}
              created plant material{" "}
              <BoldTypography>
                {history.createdPlantMaterial.qualifier}
              </BoldTypography>
            </Typography>
          ),
        },
  (history) =>
    history.archivedPlantMaterial == null
      ? null
      : {
          ...getCoreProps(history),
          message: (
            <Typography>
              <BoldTypography>{getUserDisplayName(history)}</BoldTypography>{" "}
              archived plant material{" "}
              <BoldTypography>
                {history.archivedPlantMaterial.qualifier}
              </BoldTypography>
            </Typography>
          ),
        },
  (history) =>
    history.restoredPlantMaterial == null
      ? null
      : {
          ...getCoreProps(history),
          message: (
            <Typography>
              <BoldTypography>{getUserDisplayName(history)}</BoldTypography>{" "}
              restored plant material{" "}
              <BoldTypography>
                {history.restoredPlantMaterial.qualifier}
              </BoldTypography>
            </Typography>
          ),
        },
  (history) =>
    history.fromAccessionDate == null && history.toAccessionDate == null
      ? null
      : {
          ...getCoreProps(history),
          message: (
            <Typography>
              <BoldTypography>{getUserDisplayName(history)}</BoldTypography>{" "}
              changed the accession date from{" "}
              <BoldTypography>
                {history.fromAccessionDate == null
                  ? "Empty"
                  : formatDateString(history.fromAccessionDate)}
              </BoldTypography>{" "}
              to{" "}
              <BoldTypography>
                {" "}
                {history.toAccessionDate == null
                  ? "Empty"
                  : formatDateString(history.toAccessionDate)}
              </BoldTypography>
            </Typography>
          ),
        },
  (history) =>
    history.fromProvenanceCategory == null &&
    history.toProvenanceCategory == null
      ? null
      : {
          ...getCoreProps(history),
          message: (
            <Typography>
              <BoldTypography>{getUserDisplayName(history)}</BoldTypography>{" "}
              changed the provenance category from{" "}
              <BoldTypography>
                {history.fromProvenanceCategory == null
                  ? "Empty"
                  : history.fromProvenanceCategory.name}
              </BoldTypography>{" "}
              to{" "}
              <BoldTypography>
                {" "}
                {history.toProvenanceCategory == null
                  ? "Empty"
                  : history.toProvenanceCategory.name}
              </BoldTypography>
            </Typography>
          ),
        },
  // Deprecated: Replaced with taxon history
  // (history) =>
  //   history.fromScientificName == null && history.toScientificName == null
  //     ? null
  //     : {
  //         ...getCoreProps(history),
  //         message: (
  //           <Typography>
  //             <BoldTypography>{getUserDisplayName(history)}</BoldTypography>{" "}
  //             changed the scientific name from{" "}
  //             <BoldTypography>
  //               {history.fromScientificName == null
  //                 ? "Empty"
  //                 : history.fromScientificName}
  //             </BoldTypography>{" "}
  //             to{" "}
  //             <BoldTypography>
  //               {" "}
  //               {history.toScientificName == null
  //                 ? "Empty"
  //                 : history.toScientificName}
  //             </BoldTypography>
  //           </Typography>
  //         ),
  //       },
  (history) =>
    history.fromTaxon == null && history.toTaxon == null
      ? null
      : {
          ...getCoreProps(history),
          message: (
            <Typography>
              <BoldTypography>{getUserDisplayName(history)}</BoldTypography>{" "}
              changed the taxon from{" "}
              <BoldTypography>
                {history.fromTaxon == null ? (
                  "Empty"
                ) : (
                  <span
                    className={twMerge(
                      history.fromTaxon.archived && "line-through",
                    )}
                  >
                    {history.fromTaxon.scientificName}
                  </span>
                )}
              </BoldTypography>{" "}
              to{" "}
              <BoldTypography>
                {" "}
                {history.toTaxon == null ? (
                  "Empty"
                ) : (
                  <span
                    className={twMerge(
                      history.toTaxon.archived && "line-through",
                    )}
                  >
                    {history.toTaxon.scientificName}
                  </span>
                )}
              </BoldTypography>
            </Typography>
          ),
        },
  (history) =>
    history.fromMaterialType == null && history.toMaterialType == null
      ? null
      : {
          ...getCoreProps(history),
          message: (
            <Typography>
              <BoldTypography>{getUserDisplayName(history)}</BoldTypography>{" "}
              changed the material received from{" "}
              <BoldTypography>
                {history.fromMaterialType == null
                  ? "Empty"
                  : history.fromMaterialType.name}
              </BoldTypography>{" "}
              to{" "}
              <BoldTypography>
                {" "}
                {history.toMaterialType == null
                  ? "Empty"
                  : history.toMaterialType.name}
              </BoldTypography>
            </Typography>
          ),
        },
  (history) =>
    history.fromDonor == null && history.toDonor == null
      ? null
      : {
          ...getCoreProps(history),
          message: (
            <Typography>
              <BoldTypography>{getUserDisplayName(history)}</BoldTypography>{" "}
              changed the donor from{" "}
              <BoldTypography>
                {history.fromDonor == null ? "Empty" : history.fromDonor}
              </BoldTypography>{" "}
              to{" "}
              <BoldTypography>
                {" "}
                {history.toDonor == null ? "Empty" : history.toDonor}
              </BoldTypography>
            </Typography>
          ),
        },
  (history) =>
    history.fromIpenNumber == null && history.toIpenNumber == null
      ? null
      : {
          ...getCoreProps(history),
          message: (
            <Typography>
              <BoldTypography>{getUserDisplayName(history)}</BoldTypography>{" "}
              changed the IPEN number from{" "}
              <BoldTypography>
                {history.fromIpenNumber == null
                  ? "Empty"
                  : history.fromIpenNumber}
              </BoldTypography>{" "}
              to{" "}
              <BoldTypography>
                {" "}
                {history.toIpenNumber == null ? "Empty" : history.toIpenNumber}
              </BoldTypography>
            </Typography>
          ),
        },
  (history) =>
    history.fromNotes == null && history.toNotes == null
      ? null
      : {
          ...getCoreProps(history),
          message: (
            <Typography>
              <BoldTypography>{getUserDisplayName(history)}</BoldTypography>{" "}
              changed the notes
            </Typography>
          ),
        },
  (history) =>
    history.fromCollectionDate == null && history.toCollectionDate == null
      ? null
      : {
          ...getCoreProps(history),
          message: (
            <Typography>
              <BoldTypography>{getUserDisplayName(history)}</BoldTypography>{" "}
              changed the collection date from{" "}
              <BoldTypography>
                {history.fromCollectionDate == null
                  ? "Empty"
                  : formatDateString(history.fromCollectionDate)}
              </BoldTypography>{" "}
              to{" "}
              <BoldTypography>
                {" "}
                {history.toCollectionDate == null
                  ? "Empty"
                  : formatDateString(history.toCollectionDate)}
              </BoldTypography>
            </Typography>
          ),
        },
  (history) =>
    history.fromCollectionCollectors == null &&
    history.toCollectionCollectors == null
      ? null
      : {
          ...getCoreProps(history),
          message: (
            <Typography>
              <BoldTypography>{getUserDisplayName(history)}</BoldTypography>{" "}
              changed the collector(s) from{" "}
              <BoldTypography>
                {history.fromCollectionCollectors == null
                  ? "Empty"
                  : history.fromCollectionCollectors}
              </BoldTypography>{" "}
              to{" "}
              <BoldTypography>
                {" "}
                {history.toCollectionCollectors == null
                  ? "Empty"
                  : history.toCollectionCollectors}
              </BoldTypography>
            </Typography>
          ),
        },
  (history) =>
    history.fromCollectionCollectorsFieldNumber == null &&
    history.toCollectionCollectorsFieldNumber == null
      ? null
      : {
          ...getCoreProps(history),
          message: (
            <Typography>
              <BoldTypography>{getUserDisplayName(history)}</BoldTypography>{" "}
              changed the collector(s) reference from{" "}
              <BoldTypography>
                {history.fromCollectionCollectorsFieldNumber == null
                  ? "Empty"
                  : history.fromCollectionCollectorsFieldNumber}
              </BoldTypography>{" "}
              to{" "}
              <BoldTypography>
                {" "}
                {history.toCollectionCollectorsFieldNumber == null
                  ? "Empty"
                  : history.toCollectionCollectorsFieldNumber}
              </BoldTypography>
            </Typography>
          ),
        },
  (history) =>
    history.fromCollectionHabitat == null && history.toCollectionHabitat == null
      ? null
      : {
          ...getCoreProps(history),
          message: (
            <Typography>
              <BoldTypography>{getUserDisplayName(history)}</BoldTypography>{" "}
              changed the collection habitat from{" "}
              <BoldTypography>
                {history.fromCollectionHabitat == null
                  ? "Empty"
                  : history.fromCollectionHabitat}
              </BoldTypography>{" "}
              to{" "}
              <BoldTypography>
                {" "}
                {history.toCollectionHabitat == null
                  ? "Empty"
                  : history.toCollectionHabitat}
              </BoldTypography>
            </Typography>
          ),
        },
  (history) =>
    history.fromCollectionAssociatedTaxa == null &&
    history.toCollectionAssociatedTaxa == null
      ? null
      : {
          ...getCoreProps(history),
          message: (
            <Typography>
              <BoldTypography>{getUserDisplayName(history)}</BoldTypography>{" "}
              changed the collection associated taxa from{" "}
              <BoldTypography>
                {history.fromCollectionAssociatedTaxa == null
                  ? "Empty"
                  : history.fromCollectionAssociatedTaxa}
              </BoldTypography>{" "}
              to{" "}
              <BoldTypography>
                {" "}
                {history.toCollectionAssociatedTaxa == null
                  ? "Empty"
                  : history.toCollectionAssociatedTaxa}
              </BoldTypography>
            </Typography>
          ),
        },
  (history) =>
    history.fromCollectionRemarks == null && history.toCollectionRemarks == null
      ? null
      : {
          ...getCoreProps(history),
          message: (
            <Typography>
              <BoldTypography>{getUserDisplayName(history)}</BoldTypography>{" "}
              changed the collection remarks from{" "}
              <BoldTypography>
                {history.fromCollectionRemarks == null
                  ? "Empty"
                  : history.fromCollectionRemarks}
              </BoldTypography>{" "}
              to{" "}
              <BoldTypography>
                {" "}
                {history.toCollectionRemarks == null
                  ? "Empty"
                  : history.toCollectionRemarks}
              </BoldTypography>
            </Typography>
          ),
        },
  (history) =>
    history.fromCollectionCoordinateUncertainty == null &&
    history.toCollectionCoordinateUncertainty == null
      ? null
      : {
          ...getCoreProps(history),
          message: (
            <Typography>
              <BoldTypography>{getUserDisplayName(history)}</BoldTypography>{" "}
              changed the coordinate uncertainty from{" "}
              <BoldTypography>
                {history.fromCollectionCoordinateUncertainty == null
                  ? "Empty"
                  : history.fromCollectionCoordinateUncertainty}
              </BoldTypography>{" "}
              to{" "}
              <BoldTypography>
                {" "}
                {history.toCollectionCoordinateUncertainty == null
                  ? "Empty"
                  : history.toCollectionCoordinateUncertainty}
              </BoldTypography>
            </Typography>
          ),
        },
  (history) =>
    history.fromCollectionCountry == null && history.toCollectionCountry == null
      ? null
      : {
          ...getCoreProps(history),
          message: (
            <Typography>
              <BoldTypography>{getUserDisplayName(history)}</BoldTypography>{" "}
              changed the collection country from{" "}
              <BoldTypography>
                {history.fromCollectionCountry == null
                  ? "Empty"
                  : history.fromCollectionCountry.name}
              </BoldTypography>{" "}
              to{" "}
              <BoldTypography>
                {" "}
                {history.toCollectionCountry == null
                  ? "Empty"
                  : history.toCollectionCountry.name}
              </BoldTypography>
            </Typography>
          ),
        },
  (history) =>
    history.fromCollectionStateProvince == null &&
    history.toCollectionStateProvince == null
      ? null
      : {
          ...getCoreProps(history),
          message: (
            <Typography>
              <BoldTypography>{getUserDisplayName(history)}</BoldTypography>{" "}
              changed the collection province / region from{" "}
              <BoldTypography>
                {history.fromCollectionStateProvince == null
                  ? "Empty"
                  : history.fromCollectionStateProvince}
              </BoldTypography>{" "}
              to{" "}
              <BoldTypography>
                {" "}
                {history.toCollectionStateProvince == null
                  ? "Empty"
                  : history.toCollectionStateProvince}
              </BoldTypography>
            </Typography>
          ),
        },
  (history) =>
    history.fromCollectionLocality == null &&
    history.toCollectionLocality == null
      ? null
      : {
          ...getCoreProps(history),
          message: (
            <Typography>
              <BoldTypography>{getUserDisplayName(history)}</BoldTypography>{" "}
              changed the collection locality from{" "}
              <BoldTypography>
                {history.fromCollectionLocality == null
                  ? "Empty"
                  : history.fromCollectionLocality}
              </BoldTypography>{" "}
              to{" "}
              <BoldTypography>
                {" "}
                {history.toCollectionLocality == null
                  ? "Empty"
                  : history.toCollectionLocality}
              </BoldTypography>
            </Typography>
          ),
        },
  (history) =>
    history.fromCollectionLatitude == null &&
    history.toCollectionLatitude == null
      ? null
      : {
          ...getCoreProps(history),
          message: (
            <Typography>
              <BoldTypography>{getUserDisplayName(history)}</BoldTypography>{" "}
              changed the collection latitude from{" "}
              <BoldTypography>
                {history.fromCollectionLatitude == null
                  ? "Empty"
                  : history.fromCollectionLatitude}
              </BoldTypography>{" "}
              to{" "}
              <BoldTypography>
                {" "}
                {history.toCollectionLatitude == null
                  ? "Empty"
                  : history.toCollectionLatitude}
              </BoldTypography>
            </Typography>
          ),
        },
  (history) =>
    history.fromCollectionLongitude == null &&
    history.toCollectionLongitude == null
      ? null
      : {
          ...getCoreProps(history),
          message: (
            <Typography>
              <BoldTypography>{getUserDisplayName(history)}</BoldTypography>{" "}
              changed the collection longitude from{" "}
              <BoldTypography>
                {history.fromCollectionLongitude == null
                  ? "Empty"
                  : history.fromCollectionLongitude}
              </BoldTypography>{" "}
              to{" "}
              <BoldTypography>
                {" "}
                {history.toCollectionLongitude == null
                  ? "Empty"
                  : history.toCollectionLongitude}
              </BoldTypography>
            </Typography>
          ),
        },
  (history) =>
    history.fromCollectionElevation == null &&
    history.toCollectionElevation == null
      ? null
      : {
          ...getCoreProps(history),
          message: (
            <Typography>
              <BoldTypography>{getUserDisplayName(history)}</BoldTypography>{" "}
              changed the collection elevation from{" "}
              <BoldTypography>
                {history.fromCollectionElevation == null
                  ? "Empty"
                  : history.fromCollectionElevation}
              </BoldTypography>{" "}
              to{" "}
              <BoldTypography>
                {" "}
                {history.toCollectionElevation == null
                  ? "Empty"
                  : history.toCollectionElevation}
              </BoldTypography>
            </Typography>
          ),
        },
  (history) =>
    history.fromIdentifiedBy == null && history.toIdentifiedBy == null
      ? null
      : {
          ...getCoreProps(history),
          message: (
            <Typography>
              <BoldTypography>{getUserDisplayName(history)}</BoldTypography>{" "}
              changed identifed by from{" "}
              <BoldTypography>
                {history.fromIdentifiedBy == null
                  ? "Empty"
                  : history.fromIdentifiedBy}
              </BoldTypography>{" "}
              to{" "}
              <BoldTypography>
                {" "}
                {history.toIdentifiedBy == null
                  ? "Empty"
                  : history.toIdentifiedBy}
              </BoldTypography>
            </Typography>
          ),
        },
  (history) =>
    history.fromDateIdentified == null && history.toDateIdentified == null
      ? null
      : {
          ...getCoreProps(history),
          message: (
            <Typography>
              <BoldTypography>{getUserDisplayName(history)}</BoldTypography>{" "}
              changed the date identified from{" "}
              <BoldTypography>
                {history.fromDateIdentified == null
                  ? "Empty"
                  : formatDateString(history.fromDateIdentified)}
              </BoldTypography>{" "}
              to{" "}
              <BoldTypography>
                {" "}
                {history.toDateIdentified == null
                  ? "Empty"
                  : formatDateString(history.toDateIdentified)}
              </BoldTypography>
            </Typography>
          ),
        },
  (history) =>
    history.fromIdentificationVerificationStatus == null &&
    history.toIdentificationVerificationStatus == null
      ? null
      : {
          ...getCoreProps(history),
          message: (
            <Typography>
              <BoldTypography>{getUserDisplayName(history)}</BoldTypography>{" "}
              changed identification verification status from{" "}
              <BoldTypography>
                {history.fromIdentificationVerificationStatus == null
                  ? "Empty"
                  : history.fromIdentificationVerificationStatus}
              </BoldTypography>{" "}
              to{" "}
              <BoldTypography>
                {" "}
                {history.toIdentificationVerificationStatus == null
                  ? "Empty"
                  : history.toIdentificationVerificationStatus}
              </BoldTypography>
            </Typography>
          ),
        },
  (history) =>
    history.fromIdentificationQualifier == null &&
    history.toIdentificationQualifier == null
      ? null
      : {
          ...getCoreProps(history),
          message: (
            <Typography>
              <BoldTypography>{getUserDisplayName(history)}</BoldTypography>{" "}
              changed identification qualifier from{" "}
              <BoldTypography>
                {history.fromIdentificationQualifier == null
                  ? "Empty"
                  : history.fromIdentificationQualifier}
              </BoldTypography>{" "}
              to{" "}
              <BoldTypography>
                {" "}
                {history.toIdentificationQualifier == null
                  ? "Empty"
                  : history.toIdentificationQualifier}
              </BoldTypography>
            </Typography>
          ),
        },
  (history) =>
    history.fromIdentificationRemarks == null &&
    history.toIdentificationRemarks == null
      ? null
      : {
          ...getCoreProps(history),
          message: (
            <Typography>
              <BoldTypography>{getUserDisplayName(history)}</BoldTypography>{" "}
              changed the identification remarks
            </Typography>
          ),
        },
]

export const parseAccessionHistory = parseHistory(parsers)
