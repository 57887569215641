import {Button} from "@hortis/ui/button"
import {Dialog, DialogTrigger} from "@hortis/ui/dialog"
import type {MaterialFieldsFragment} from "generated/graphql"
import {useState, type ReactElement} from "react"
import {RecordActivityDialog} from "src/components/dialog/variants/record-activity"
import {ACTIVITY_PAGE_SIZE} from "src/features/records/components/record-activity/utils"
import {
  RecordSection,
  RecordSectionContent,
  RecordSectionContentGroup,
  RecordSectionHeader,
  RecordSectionTitle,
} from "src/features/records/components/record-section/new-record-section"
import {useMaterialHistory} from "../material-record-details/use-material-history"

export interface MaterialRecordActivityProps {
  material: MaterialFieldsFragment
}

export const MaterialRecordActivity = ({
  material,
}: MaterialRecordActivityProps): ReactElement => {
  const [activityModalOpen, setActivityModalOpen] = useState(false)
  const {activityPage, materialHistory, fullHistory} =
    useMaterialHistory(material)

  return (
    <RecordSection id="activity">
      <RecordSectionHeader>
        <RecordSectionTitle>Activity</RecordSectionTitle>
      </RecordSectionHeader>

      <RecordSectionContent>
        <RecordSectionContentGroup className="gap-0">
          {materialHistory?.map((activity, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={i}>{activity}</div>
          ))}
          <div>
            {materialHistory != null &&
            materialHistory.length >= activityPage * ACTIVITY_PAGE_SIZE &&
            activityPage !== -1 ? (
              <Dialog
                open={activityModalOpen}
                onOpenChange={setActivityModalOpen}
              >
                <DialogTrigger>
                  <Button
                    data-cy="activity-view-all-button"
                    size="sm"
                    variant="linkgray"
                    className="text-primary-700"
                  >
                    View all
                  </Button>
                </DialogTrigger>
                <RecordActivityDialog
                  setOpen={setActivityModalOpen}
                  recordHistory={fullHistory}
                />
              </Dialog>
            ) : null}
          </div>
        </RecordSectionContentGroup>
      </RecordSectionContent>
    </RecordSection>
  )
}
