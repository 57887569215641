import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@hortis/ui/dropdown-menu"
import {ChevronDown, Edit3, XClose} from "@hortis/ui/icons"
import {Tag} from "@hortis/ui/tag"
import type {RecordTagFieldsFragment} from "generated/graphql"
import {useRef, useState} from "react"

export interface AutocompleteTagProps {
  tag: RecordTagFieldsFragment
  editTag: (tag: RecordTagFieldsFragment) => void
  removeTag: (id: string) => void
}

export const AutocompleteTag = ({
  tag,
  editTag,
  removeTag,
}: AutocompleteTagProps) => {
  const anchorRef = useRef(null)
  const [open, setOpen] = useState(false)

  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger>
        <Tag key={tag.id} size="lg" ref={anchorRef} data-cy="autocomplete-tag">
          {tag.name}
          <ChevronDown className="h-3 w-3 text-grey-400" />
        </Tag>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className="z-[9999]"
        onSelect={() => {
          setOpen(false)
        }}
      >
        <DropdownMenuItem
          onSelect={() => {
            removeTag(tag.id)
          }}
          data-cy="remove-tag-menu-item"
        >
          <XClose className="h-4 w-4 text-grey-500" />
          Remove tag
        </DropdownMenuItem>
        <DropdownMenuItem
          onSelect={() => {
            editTag(tag)
          }}
          data-cy="rename-tag-menu-item"
        >
          <Edit3 className="h-4 w-4 text-grey-500" />
          Rename tag
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
