import {keyframes, styled, useTheme} from "@mui/material/styles"
import {useEffect, useRef, useState} from "react"
import type {MapboxMap} from "react-map-gl"
import {colors} from "src/colors"

const rotate = keyframes`
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 108
  }
`

const StyledSvg = styled("svg")(() => ({
  "& .cycle": {
    animation: `${rotate} 6s linear infinite`,
  },
  "& .flow": {
    animation: `${rotate} 12s linear infinite`,
  },
  // These help the svg render on iOS
  position: "relative",
  zIndex: 99,
}))

export type CenterOverlayType = "circle" | "target"

export function CenterOverlay({
  map,
  type = "circle",
}: {
  map?: MapboxMap
  type?: CenterOverlayType
}) {
  const theme = useTheme()
  const [dimensions, setDimensions] = useState<{width: number; height: number}>(
    {width: 0, height: 0},
  )
  const zoom = map?.getZoom()
  const observer = useRef<ResizeObserver>()

  const origin = map?.project(map.getCenter())

  // Listen for map resize events ie. when nav drawer collapses
  useEffect(() => {
    const mapContainer = map?.getContainer()

    function updateDimensions() {
      setDimensions({
        width: mapContainer?.clientWidth ?? 0,
        height: mapContainer?.clientHeight ?? 0,
      })
    }

    if (mapContainer != null && observer.current == null) {
      updateDimensions()
      observer.current = new ResizeObserver(updateDimensions)
      observer.current.observe(mapContainer)
    }

    return () => {
      observer.current?.disconnect()
    }
  }, [observer, map])

  useEffect(() => {
    setDimensions((x) => x)
  }, [dimensions])

  return (
    <>
      <StyledSvg
        width={dimensions.width}
        height={dimensions.height}
        viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}
      >
        {type === "circle" && (
          <circle
            r={Math.max(((zoom ?? 16) - 16) * 10, 28)}
            fill="transparent"
            stroke={colors.yellow[500]}
            strokeWidth="2"
            strokeLinecap="round"
            strokeDasharray="6 6"
            cx={origin?.x}
            cy={origin?.y}
            className="cycle"
          />
        )}
        {type === "target" && (
          <>
            <line
              stroke={colors.blue[600]}
              strokeWidth="2"
              strokeLinecap="round"
              strokeOpacity="0.7"
              strokeDasharray="2 6"
              x1={(origin?.x ?? 0) - 30}
              y1={origin?.y}
              x2={(origin?.x ?? 0) + 30}
              y2={origin?.y}
              className="flow"
            />
            <line
              stroke={colors.blue[600]}
              strokeWidth="2"
              strokeLinecap="round"
              strokeOpacity="0.7"
              strokeDasharray="2 6"
              x1={origin?.x}
              y1={(origin?.y ?? 0) - 30}
              x2={origin?.x}
              y2={(origin?.y ?? 0) + 30}
              className="flow"
            />
          </>
        )}
        <circle
          r={Math.max(((zoom ?? 16) - 16) * 3, 6)}
          fill={type === "circle" ? colors.yellow[500] : colors.blue[600]}
          stroke={theme.palette.primary.contrastText}
          strokeWidth={Math.max(((zoom ?? 16) - 14) * 0.8, 3)}
          cx={origin?.x}
          cy={origin?.y}
        />
      </StyledSvg>
    </>
  )
}
