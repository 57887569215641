import {useCallback} from "react"
import {twMerge} from "tailwind-merge"
import type {DatePresets} from "./utils"

interface DatePickerListItemProps {
  onClick: (preset: DatePresets) => void
  title: DatePresets
  isSelected: boolean
}

export const DatePickerListItem = ({
  onClick,
  title,
  isSelected,
}: DatePickerListItemProps) => {
  const handleClicked = useCallback(() => {
    onClick(title)
  }, [onClick, title])

  return (
    <button
      type="button"
      onClick={handleClicked}
      data-cy={"date-filter-preset"}
      className={twMerge(
        "rounded-md px-4 py-2.5 !text-left text-sm",
        isSelected && "bg-grey-50  font-medium",
      )}
    >
      {title}
    </button>
  )
}
