import {createFileRoute} from "@tanstack/react-router"
import {
  Component,
  selectOrganisationSchema,
} from "src/features/auth/components/select-organisation"

export const Route = createFileRoute("/auth/select-organisation")({
  component: Component,
  validateSearch: (search) => selectOrganisationSchema.parse(search),
})
