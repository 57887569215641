import {IconButton} from "@hortis/ui/button"
import {DialogContent, DialogHeader, DialogTitle} from "@hortis/ui/dialog"
import {Edit5, XClose} from "@hortis/ui/icons"

import {type RefObject} from "react"

interface RecordNotesDialogProps {
  notes: string
  triggerRef?: RefObject<HTMLButtonElement>
  editNotes?: () => void
  closeDialog: () => void
  canEdit: boolean
}

export const RecordNotesDialog = ({
  notes,
  editNotes,
  triggerRef,
  closeDialog,
  canEdit,
}: RecordNotesDialogProps) => {
  const onEdit = () => {
    closeDialog()
    editNotes?.()
  }

  return (
    <DialogContent
      data-cy="record-notes-dialog"
      className="max-w-[512px] !gap-0"
      triggerRef={triggerRef}
    >
      <DialogHeader>
        <DialogTitle className="flex justify-between border-b border-grey-200 pb-2 ">
          Notes
          <div className="flex gap-2">
            {editNotes && canEdit && (
              <IconButton
                ariaLabel="edit-tags"
                variant="tertiaryGray"
                data-cy="edit-notes-button"
                size={"xs"}
                icon={Edit5}
                onClick={onEdit}
              />
            )}
            <IconButton
              ariaLabel="close-dialog"
              data-cy="close-dialog-button"
              variant="tertiaryGray"
              size={"xs"}
              onClick={closeDialog}
              icon={XClose}
            />
          </div>
        </DialogTitle>
      </DialogHeader>
      <div className="max-h-[350px] overflow-y-scroll break-all py-6 text-sm">
        {notes}
      </div>
    </DialogContent>
  )
}
