import type {AccessionFilter} from "generated/graphql"
import type {LocalAccessionFilter} from "../types"

export const flattenMaterialReceivedFilter = (
  filter: LocalAccessionFilter["materialType"],
): AccessionFilter["materialType"] =>
  filter == null
    ? undefined
    : {
        id: {
          in: filter.is?.map(({id}) => id),
          nin: filter.isNot?.map(({id}) => id),
        },
      }
