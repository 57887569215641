import {zodResolver} from "@hookform/resolvers/zod"
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@hortis/ui/breadcrumb"
import {Button} from "@hortis/ui/button"
import {XCloseSm} from "@hortis/ui/icons"
import {useNavigate} from "@tanstack/react-router"
import {useCallback, useEffect, useMemo, useState} from "react"
import {Helmet} from "react-helmet-async"
import {FormProvider, useForm} from "react-hook-form"
import {AccessionAutocomplete} from "src/components/accession-autocomplete"
import {AlertDialog} from "src/components/alert-dialog"
import {
  TopBar,
  TopBarActionButtons,
  TopBarContent,
} from "src/components/nav-layout/topbar"
import {useTopbarStore} from "src/components/nav-layout/topbar/topbar-store"
import {useSnackbarStore} from "src/components/snackbar-controller/snackbar-store"
import type {PositionZodInput} from "src/features/create-accession/components/add-plant-material-form/schema"
import {useCollectionSiteHrefs} from "src/utils/hooks/hrefs"
import {usePlaceBasic} from "src/utils/hooks/place"
import {useScrollToError} from "src/utils/hooks/scroll-to-error"
import withAuthenticationRequired from "src/utils/with-authentication-required"
import {SheetFooter} from "@hortis/ui/sheet"
import type {
  MaterialFieldsFragment,
  MaterialQualifierFormat,
} from "../../../generated/graphql"
import {
  Period,
  useCreateMaterialMutation,
  useGetAccessionQuery,
  useGetCollectionSiteFromNamesQuery,
} from "../../../generated/graphql"
import {onFailure} from "../../notification-snack-utils"
import type {Nullable} from "../../types/nullable"
import {materialErrorMap} from "../create-accession/components/add-plant-material-form/error-map"
import {PlantMaterialDataFields} from "../create-accession/components/add-plant-material-form/plant-material-data-fields"
import {processPlantMaterialFormData} from "../create-accession/utils/process-form-data"
import {redirectNonEditRole} from "../permissions/requires-edit-role"
import {composeMaterialQualifier} from "../records/accession/components/edit-drawer/add-plant-material"
import {SettingsSectionHeader} from "../settings/components/settings-section-header"
import {onCreateMaterialSuccess} from "./on-create-material-success"
import {
  newMaterialSchema,
  type NewMaterialSchema,
  type NewMaterialSchemaInput,
} from "./schema"

interface InitialValues {
  position?: PositionZodInput
}

const createDefaultValues = (
  values?: InitialValues,
): Nullable<NewMaterialSchemaInput> => ({
  materialQualifier: undefined,
  firstPresent: null,
  firstAbsent: null,
  materialGroup: null,
  quantity: null,
  weight: null,
  status: null,
  sex: null,
  tagNumber: null,
  notes: null,
  massPlanting: false,
  position: values?.position ?? null,
  collectionSiteLocationOption: null,
  lastObserved: new Date(),
  followUp: {count: 1, period: Period.Years},
  condition: null,
  tags: [],
  accessionOption: null,
})

const resolver = zodResolver(newMaterialSchema, {errorMap: materialErrorMap})

export type NewMaterialFormProps = {
  site?: {materialQualifierFormat: MaterialQualifierFormat}
  setAlertOpen?: (open: boolean) => void
  alertOpen?: boolean
  initialValues?: InitialValues
  onSubmit?: (newMaterial: MaterialFieldsFragment) => void
  onwardHref?: string
  responsiveChips?: boolean
}

export const NewMaterialForm = ({
  site: _site,
  setAlertOpen: _setAlertOpenProp,
  alertOpen: _alertOpenProp,
  initialValues,
  onSubmit,
  onwardHref,
  responsiveChips,
}: NewMaterialFormProps) => {
  const navigate = useNavigate()
  const place = usePlaceBasic()
  const hrefs = useCollectionSiteHrefs()
  // eslint-disable-next-line react/hook-use-state
  const [_alertOpen, _setAlertOpen] = useState(false)
  const setAlertOpen = _setAlertOpenProp ?? _setAlertOpen
  const alertOpen = _alertOpenProp ?? _alertOpen
  const [{data: siteData}] = useGetCollectionSiteFromNamesQuery({
    variables: {
      organisationSubdomain: place?.orgName ?? "",
      collectionSiteSlug: place?.siteSlug ?? "",
    },
    pause: place == null && _site != null,
  })
  const site = _site ?? siteData?.org?.site
  const {setSnack} = useSnackbarStore()
  const formMethods = useForm<Nullable<NewMaterialSchemaInput>>({
    defaultValues: createDefaultValues(initialValues),
    criteriaMode: "all",
    resolver,
  })
  const accessionOption = formMethods.watch("accessionOption")

  const [{data: accessionData}] = useGetAccessionQuery({
    variables: {
      accessionNumber: accessionOption?.accessionNumber ?? "",
      collectionSiteSlug: place?.siteSlug ?? "",
      organisationSubdomain: place?.orgName ?? "",
    },
    pause: accessionOption == null || place == null,
  })
  const accession = accessionData?.org?.site?.result
  const [, createMaterial] = useCreateMaterialMutation()
  const closeAlert = useCallback(() => {
    setAlertOpen(false)
  }, [setAlertOpen])
  const openAlert = useCallback(() => {
    setAlertOpen(true)
  }, [setAlertOpen])

  const {onError} = useScrollToError(formMethods)

  const handleSubmit = useMemo(
    () =>
      formMethods.handleSubmit(async (_data) => {
        try {
          const {accessionOption, ...data} = _data as NewMaterialSchema
          const res = await createMaterial({
            accessionId: accessionOption.id,
            plantMaterial: processPlantMaterialFormData(data),
          })
          if (res.data?.createPlantMaterial?.__typename === "PlantMaterial") {
            onCreateMaterialSuccess({
              setSnack,
              navigate,
              onwardHref: onwardHref,
              materialsHref: hrefs?.plantMaterials,
            })({
              accessionNumber: accessionOption.accessionNumber,
              qualifier: res.data.createPlantMaterial.qualifier,
            })
            if (onSubmit != null) {
              onSubmit(res.data.createPlantMaterial)
            }
          } else {
            throw new Error("Failed to create material")
          }
        } catch {
          onFailure(setSnack)(new Error(`Failed to create material`))
        }
      }, onError),
    [
      createMaterial,
      formMethods,
      setSnack,
      navigate,
      hrefs,
      onError,
      onSubmit,
      onwardHref,
    ],
  )

  useEffect(() => {
    if (accession != null && site != null) {
      formMethods.setValue(
        "materialQualifier",
        composeMaterialQualifier(accession, site.materialQualifierFormat),
      )
    }
  }, [accession, formMethods, site])

  useEffect(() => {
    if (
      accessionOption == null &&
      formMethods.getValues("materialQualifier") != null
    ) {
      formMethods.setValue("materialQualifier", undefined)
    }
  }, [accessionOption, formMethods])

  return (
    <FormProvider {...formMethods}>
      <form className="flex flex-col gap-12" onSubmit={handleSubmit}>
        <div className="flex flex-col gap-8">
          <AccessionAutocomplete
            name="accessionOption"
            testId="accession-option"
          />
          <div className="pt-2">
            <PlantMaterialDataFields
              formNamePrefix=""
              responsiveChips={responsiveChips}
            />
          </div>
        </div>
        <SheetFooter className="!px-0">
          <Button
            size="lg"
            onClick={openAlert}
            testId="cancel-new-material-button"
          >
            Discard
          </Button>
          <Button
            variant="primary"
            size="lg"
            type="submit"
            loading={formMethods.formState.isSubmitting}
            testId="create-material-button"
          >
            Save
          </Button>
        </SheetFooter>
      </form>
      {hrefs === undefined ? undefined : (
        <AlertDialog
          open={alertOpen}
          title="Exit before saving?"
          body="The data you've entered will be discarded if you exit before saving the material"
          cancelLabel="Cancel"
          onCancel={closeAlert}
          continueLabel="Exit"
          continueTo={`/${hrefs.collection}`}
        />
      )}
    </FormProvider>
  )
}

const NewMaterial = redirectNonEditRole({
  Component: function NewMaterial() {
    const [alertOpen, setAlertOpen] = useState(false)
    const hrefs = useCollectionSiteHrefs()
    const place = usePlaceBasic()
    const setMobileTitle = useTopbarStore((state) => state.setTitle)
    const pageTitle = "New material"
    setMobileTitle(pageTitle)

    const [{data: siteData}] = useGetCollectionSiteFromNamesQuery({
      variables: {
        organisationSubdomain: place?.orgName ?? "",
        collectionSiteSlug: place?.siteSlug ?? "",
      },
      pause: place == null,
    })
    const site = siteData?.org?.site

    const openAlert = useCallback(() => {
      setAlertOpen(true)
    }, [setAlertOpen])

    return (
      <div className="flex h-full flex-col overflow-auto bg-grey-50">
        <Helmet>
          <title>New material | Hortis</title>
        </Helmet>
        {site != null && (
          <div className="sticky top-0 z-50 hidden md:block">
            <TopBar>
              <TopBarContent>
                <Breadcrumb>
                  <BreadcrumbList>
                    <BreadcrumbItem data-cy="breadcrumb-site-name">
                      {site.name}
                    </BreadcrumbItem>
                    <BreadcrumbSeparator />
                    <BreadcrumbItem data-cy="breadcrumb-page-title">
                      {pageTitle}
                    </BreadcrumbItem>
                  </BreadcrumbList>
                </Breadcrumb>
              </TopBarContent>
              <TopBarActionButtons>
                <Button
                  size={"xs"}
                  startIcon={<XCloseSm className="h-4 w-4" />}
                  onClick={openAlert}
                  testId="exit-new-material-button"
                >
                  Discard
                </Button>
              </TopBarActionButtons>
            </TopBar>
          </div>
        )}

        <div className="md:p-8 md:pb-20 lg:p-10 lg:pb-10">
          <div className="mx-auto flex w-full max-w-[878px] flex-col items-stretch gap-8 self-center border-grey-200 bg-paper p-4 py-10 md:rounded-lg md:border md:p-8 md:shadow-sm lg:p-16 xl:px-24 xl:py-16">
            <SettingsSectionHeader
              title="New material"
              subtitle="Add a new plant material to an existing accession."
            />
            <NewMaterialForm
              site={site ?? undefined}
              setAlertOpen={setAlertOpen}
              alertOpen={alertOpen}
              onwardHref={hrefs?.collection}
              responsiveChips
            />
          </div>
        </div>
      </div>
    )
  },
})

export const Component = withAuthenticationRequired(NewMaterial, {
  onRedirecting: function OnRedirect() {
    return <NewMaterial />
  },
})
