import {useArchiveMaterialsMutation} from "generated/graphql"
import {useEffect, type ReactNode, type RefObject} from "react"
import {Button} from "@hortis/ui/button"
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@hortis/ui/dialog"
import {useSnackbarStore} from "src/components/snackbar-controller/snackbar-store"
import {onFailure, onSuccess} from "src/notification-snack-utils"

export const TrashMaterialsDialog = ({
  materialsIds,
  open,
  setOpen,
  onSuccess: onSuccessProp,
  triggerRef,
  children,
}: {
  materialsIds: ReadonlyArray<string>
  open: boolean
  setOpen: (open: boolean) => void
  onSuccess?: () => void
  triggerRef?: RefObject<HTMLButtonElement>
  children?: ReactNode
}) => {
  const {setSnack} = useSnackbarStore()

  const [{fetching}, archiveMaterials] = useArchiveMaterialsMutation()

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setOpen(false)
      }
    }

    document.addEventListener("keydown", down)
    return () =>
      // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
      document.removeEventListener("keydown", down)
  }, [open, setOpen])

  const handleArchiveMaterials = async () => {
    const res = await archiveMaterials({
      ids: materialsIds,
    })

    if (res.data?.archivePlantMaterials.success !== true) {
      onFailure(setSnack)(new Error(`Failed to trash plant materials`))
      return
    }

    onSuccess(setSnack)(
      `Successfully trashed ${
        materialsIds.length === 1 ? "plant material" : "plant materials"
      }`,
    )
    setOpen(false)
    onSuccessProp?.()
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      {children}
      <DialogContent data-cy="trash-materials-dialog" triggerRef={triggerRef}>
        <DialogHeader>
          <DialogTitle>Move to trash?</DialogTitle>
          <DialogDescription>
            {`This will move ${materialsIds.length} plant material ${
              materialsIds.length === 1 ? "record" : "records"
            } to your site trash.`}
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <DialogClose asChild>
            <Button size="lg" fullWidth>
              Cancel
            </Button>
          </DialogClose>
          <Button
            size="lg"
            onClick={handleArchiveMaterials}
            loading={fetching}
            variant="warning"
            testId="trash-materials-button"
            fullWidth
          >
            Move to trash
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
