import type {DialogContentProps as CoreDialogContentProps} from "@mui/material/DialogContent"
import CoreDialogContent from "@mui/material/DialogContent"
import {styled} from "@mui/material/styles"
import {applyMui} from "../../utils/apply-mui"
import {withTestId} from "../../utils/with-test-id"

const StyledDialogContent = styled(CoreDialogContent)({
  padding: 0,
})

export const DialogContent = applyMui(
  withTestId,
  StyledDialogContent as (props: CoreDialogContentProps) => JSX.Element,
)
export type DialogContentProps = CoreDialogContentProps
