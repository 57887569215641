import * as E from "fp-ts/Either"
import {flow} from "fp-ts/function"
import * as O from "fp-ts/Option"
import * as R from "fp-ts/Reader"
import type {GetAllProvenanceCategoriesQuery} from "../../../../generated/graphql"
import compactToNonEmpty from "../../../utils/compact-to-non-empty"

type ProvenanceCategoriesQuerySubset = Pick<
  GetAllProvenanceCategoriesQuery,
  "provenanceCategories"
>

const sanitiseServerErrors = E.mapLeft(
  () => "Error fetching provenance categories!",
)

const compactProvenanceCategories = E.chainOptionK(
  () => "No provenance categories exist!",
)(
  flow(
    R.ask<ProvenanceCategoriesQuerySubset>(),
    O.fromNullableK(({provenanceCategories}) => provenanceCategories),
    O.chain(compactToNonEmpty),
  ),
)

/**
 * @param provenanceCategoriesQueryResult - possibly loading, possibly failed query result
 * @returns possibly loading, error message or non empty array of valid provenance categories
 */
export const processProvenanceCategories = O.map(
  flow(
    R.ask<E.Either<unknown, ProvenanceCategoriesQuerySubset>>(),
    sanitiseServerErrors,
    compactProvenanceCategories,
  ),
)
