import create from "zustand"
import type {MaterialParam} from "./types"

export type SetHoveredListMaterial = (
  padding: MaterialParam | undefined,
) => void

export interface HoveredListMaterialState {
  hoveredMaterial: MaterialParam | undefined
  setHoveredListMaterial: SetHoveredListMaterial
}

export const useHoveredListMaterialStore = create<HoveredListMaterialState>(
  (set) => ({
    hoveredMaterial: undefined,
    setHoveredListMaterial: (hoveredMaterial) => {
      set({hoveredMaterial})
    },
  }),
)
