import type {HTMLAttributes} from "react"
import {twMerge} from "tailwind-merge"

const TopBar = ({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      className={twMerge(
        "flex h-[57px] w-full items-center border-b border-grey-200 bg-white px-6 py-4",
        className,
      )}
      {...props}
    >
      {children}
    </div>
  )
}

const TopBarActionButtons = ({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={twMerge("ml-auto flex gap-2", className)} {...props}>
      {children}
    </div>
  )
}

const TopBarContent = ({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={twMerge("flex items-center gap-6", className)} {...props}>
      {children}
    </div>
  )
}

const TopBarStartButton = ({children}: {children: React.ReactNode}) => {
  return <div>{children}</div>
}

export {TopBar, TopBarActionButtons, TopBarContent, TopBarStartButton}
