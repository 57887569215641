import {twMerge} from "tailwind-merge"
import {Avatar} from "../avatar"

interface AvatarGroupProps {
  avatars: ReadonlyArray<{
    avatarUrl?: string | null | undefined
    initials?: string | null | undefined
    id: string
    email: string
  }>
  totalShown?: number
}

export const AvatarGroup = ({avatars, totalShown = 5}: AvatarGroupProps) => {
  const extraAvatars = avatars.length - totalShown
  return (
    <div className="flex">
      {avatars
        .slice(0, totalShown)
        .map(({id, avatarUrl, initials, email}, idx) => (
          <Avatar
            key={id}
            className={twMerge(
              idx !== 0 && "ml-[-8px]",
              "box-content !h-6 !w-6",
            )}
            textContent={initials?.toUpperCase() ?? email.toUpperCase()}
            src={avatarUrl ?? undefined}
          />
        ))}
      {extraAvatars > 1 && (
        <div className="bg-grey-100 text-grey-600 z-10 ml-[-8px] box-content flex h-6 w-6 items-center justify-center rounded-lg border border-white text-xs font-medium">
          +{extraAvatars}
        </div>
      )}
    </div>
  )
}
