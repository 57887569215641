import {IconButton} from "@hortis/ui/button"
import {Edit5} from "@hortis/ui/icons"
import {format} from "date-fns"
import type {AccessionFieldsFragment, MaterialGroup} from "generated/graphql"
import {type ReactElement} from "react"
import {
  RecordSection,
  RecordSectionContent,
  RecordSectionContentGroup,
  RecordSectionHeader,
  RecordSectionHeaderButtons,
  RecordSectionTitle,
} from "src/features/records/components/record-section/new-record-section"
import {parseUtcDate} from "src/utils/dates/parse-utc-date"
import {MaterialGroupBadge} from "../../../../components/icons"
import type {AccessionEditStage} from "../../../records/accession/components/edit-drawer/types"
import {AccessionEditStageCallback} from "../../../records/accession/components/edit-drawer/types"
import {
  RecordField,
  RecordFieldLabel,
  RecordFieldValue,
} from "../../../records/components/record-field/new-record-field"

export interface AccessionRecordDetailsProps {
  accession: AccessionFieldsFragment
  requestEdit: (stage?: AccessionEditStage) => void
  ipenNumbersEnabled: boolean
  canEdit: boolean
}

export const AccessionRecordDetails = ({
  accession,
  requestEdit,
  ipenNumbersEnabled,
  canEdit,
}: AccessionRecordDetailsProps): ReactElement => {
  const editAccessionData =
    AccessionEditStageCallback.useEditAccessionData(requestEdit)

  return (
    <RecordSection id={"details"}>
      <RecordSectionHeader>
        <RecordSectionTitle>Details</RecordSectionTitle>
        <RecordSectionHeaderButtons>
          {canEdit && !accession.archived && (
            <IconButton
              onClick={editAccessionData}
              ariaLabel="Edit section"
              data-cy="section-edit-button"
              size="xs"
              variant="tertiaryGray"
              icon={Edit5}
            />
          )}
        </RecordSectionHeaderButtons>
      </RecordSectionHeader>

      <RecordSectionContent>
        <RecordSectionContentGroup className="grid gap-4 lg:grid-cols-2 ">
          <RecordField data-cy="accession-date">
            <RecordFieldLabel>Accession date</RecordFieldLabel>
            <RecordFieldValue>
              {accession.accessionDate == null
                ? "-"
                : format(parseUtcDate(accession.accessionDate), "PPP")}
            </RecordFieldValue>
          </RecordField>

          {accession.materialType == null ? null : (
            <RecordField data-cy="material-received">
              <RecordFieldLabel>Material received</RecordFieldLabel>
              <RecordFieldValue>
                <div className="flex items-center gap-4">
                  <span>{accession.materialType.name}</span>
                  <MaterialGroupBadge
                    group={
                      accession.materialType.code.toUpperCase() as MaterialGroup
                    }
                  />
                </div>
              </RecordFieldValue>
            </RecordField>
          )}

          {accession.deaccessionDate == null ? null : (
            <RecordField data-cy="deaccession-date">
              <RecordFieldLabel>Deaccession date</RecordFieldLabel>
              <RecordFieldValue>
                {format(parseUtcDate(accession.deaccessionDate), "PPP")}
              </RecordFieldValue>
            </RecordField>
          )}

          <RecordField data-cy="donor-supplier">
            <RecordFieldLabel>Donor / supplier</RecordFieldLabel>
            <RecordFieldValue> {accession.donor ?? "-"}</RecordFieldValue>
          </RecordField>

          {ipenNumbersEnabled && (
            <RecordField data-cy="ipen-number">
              <RecordFieldLabel>IPEN number</RecordFieldLabel>
              <RecordFieldValue>{accession.ipenNumber ?? "-"}</RecordFieldValue>
            </RecordField>
          )}
        </RecordSectionContentGroup>
      </RecordSectionContent>
    </RecordSection>
  )
}
