import {PlantMaterialsColumn} from "generated/graphql"
import {type MaterialsColumnIds} from "src/components/data-table/columns/material-columns"
import {type MaterialFilterType} from "src/features/filters/plant-materials"

export const defaultColumnVisibility: Record<MaterialsColumnIds, boolean> = {
  Number: true,
  Photos: true,
  "Scientific name": true,
  "Common name": false,
  Provenance: true,
  "Material type": false,
  Status: true,
  Location: true,
  Quantity: true,
  Sex: false,
  "Tag number": false,
  "Donor / supplier": true,
  "Date added": false,
  "Date removed": false,
  "Last observed": true,
  "Days overdue": false,
  Condition: true,
  "Plant material tags": true,
  "Last edited": false,
  Notes: true,
  "IUCN Red List": false,
  "Native distribution": false,
  Climate: false,
  "Life forms": false,
  Public: true,
  Creator: false,
  "Last editor": false,
}

export const sortColumnMap: Partial<
  Record<PlantMaterialsColumn, MaterialsColumnIds>
> = {
  [PlantMaterialsColumn.AccessionNum]: "Number",
  [PlantMaterialsColumn.ScientificName]: "Scientific name",
  [PlantMaterialsColumn.CommonName]: "Common name",
  [PlantMaterialsColumn.Status]: "Status",
  [PlantMaterialsColumn.Quantity]: "Quantity",
  [PlantMaterialsColumn.DateAdded]: "Date added",
  [PlantMaterialsColumn.LocationCode]: "Location",
  [PlantMaterialsColumn.DaysOverdueFollowUp]: "Days overdue",
  [PlantMaterialsColumn.LastUpdated]: "Last edited",
  [PlantMaterialsColumn.DonorSupplier]: "Donor / supplier",
  [PlantMaterialsColumn.ProvenanceCategoryCode]: "Provenance",
  [PlantMaterialsColumn.MaterialGroup]: "Material type",
  [PlantMaterialsColumn.LastObserved]: "Last observed",
  [PlantMaterialsColumn.GlobalRedListStatus]: "IUCN Red List",
}

export const filterColumnMap: Partial<
  Record<MaterialsColumnIds, MaterialFilterType>
> = {
  Notes: "notes",
  "Plant material tags": "tags",
  Status: "status",
  "Material type": "material-group",
  Location: "location",
  "Tag number": "tag-number",
  Condition: "condition",
  Quantity: "quantity",
  Provenance: "provenance",
  "Common name": "commonName",
  Photos: "photos",
  "Donor / supplier": "donor",
  "Last observed": "lastObserved",
  "Date added": "firstPresent",
  "Date removed": "firstAbsent",
  "IUCN Red List": "redListStatus",
  "Life forms": "lifeForms",
  Climate: "climate",
  "Native distribution": "nativeDistribution",
  Public: "public",
  Creator: "creator",
  "Last editor": "lastEditor",
  Sex: "sex",
}
