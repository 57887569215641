import type {NullableStringComparator} from "generated/graphql"
import type {Dispatch, RefObject} from "react"
import {NullableStringFilterModal} from "../../components/nullable-string-filter/modal"
import {FamilyFilterModal} from "../../family-filter/modal"
import {TagsFilterModal} from "../../tags-filter"
import type {TaxaFilterAction} from "../filter-reducer"
import type {LocalTaxaFilterWithId, TaxaFilterType} from "../types"
import {ValidationFilterModal} from "../validation-filter/modal"
import {RedListStatusFilterModal} from "../../red-list-status/modal"
import {LifeFormsFilterModal} from "../../life-forms/modal"
import {UserFilterModal} from "../../user-filter/user-filter-modal"
import {useDispatchFilter} from "./use-dispatch-filter"

interface FilterModalsProps {
  filterModalAnchorEl?: RefObject<HTMLElement | null>
  editFilter?: LocalTaxaFilterWithId | undefined
  openFilter: TaxaFilterType | false
  dispatchFilters: Dispatch<TaxaFilterAction>
  handleFilterModalClose: () => void
  taxaType: "org" | "site"
}

export const TaxaFilterModals = ({
  filterModalAnchorEl,
  openFilter,
  editFilter,
  dispatchFilters,
  handleFilterModalClose,
  taxaType,
}: FilterModalsProps) => {
  const props = {
    initialValue: editFilter,
    onClose: handleFilterModalClose,
    anchorEl: filterModalAnchorEl,
    dispatchFilters: dispatchFilters,
  }
  const {addModifyFilter, deleteFilter} = useDispatchFilter({
    dispatchFilters,
    id: editFilter?.id,
    onClose: handleFilterModalClose,
  })

  return (
    <>
      <NullableStringFilterModal
        {...props}
        initialValue={editFilter?.commonName}
        addModifyFilter={(filter: NullableStringComparator) => {
          addModifyFilter({commonName: filter})
        }}
        deleteFilter={deleteFilter}
        open={openFilter === "commonName"}
        filterTitle="Common name"
        testIdPrefix="commonname"
      />
      <NullableStringFilterModal
        {...props}
        initialValue={editFilter?.authorship}
        addModifyFilter={(filter: NullableStringComparator) => {
          addModifyFilter({authorship: filter})
        }}
        deleteFilter={deleteFilter}
        open={openFilter === "authorship"}
        filterTitle="Authorship"
      />
      <UserFilterModal
        {...props}
        initialValue={editFilter?.creator}
        addModifyFilter={(creator) => {
          addModifyFilter({creator})
        }}
        deleteFilter={deleteFilter}
        open={openFilter === "creator"}
        title="Creator"
      />
      <UserFilterModal
        {...props}
        initialValue={editFilter?.lastEditor}
        addModifyFilter={(lastEditor) => {
          addModifyFilter({lastEditor})
        }}
        deleteFilter={deleteFilter}
        open={openFilter === "lastEditor"}
        title="Last editor"
      />
      <NullableStringFilterModal
        {...props}
        initialValue={editFilter?.nativeDistribution}
        addModifyFilter={(filter) => {
          addModifyFilter({
            nativeDistribution: filter,
          })
        }}
        deleteFilter={deleteFilter}
        open={openFilter === "nativeDistribution"}
        testIdPrefix="nativedistribution"
        filterTitle="Native distribution"
      />
      <NullableStringFilterModal
        {...props}
        initialValue={editFilter?.climate}
        addModifyFilter={(filter) => {
          addModifyFilter({
            climate: filter,
          })
        }}
        deleteFilter={deleteFilter}
        open={openFilter === "climate"}
        filterTitle="Climate"
      />
      <NullableStringFilterModal
        {...props}
        initialValue={editFilter?.notes}
        addModifyFilter={(filter: NullableStringComparator) => {
          addModifyFilter({notes: filter})
        }}
        deleteFilter={deleteFilter}
        open={openFilter === "notes"}
        filterTitle="Notes"
      />
      <TagsFilterModal
        {...props}
        addModifyFilter={addModifyFilter}
        deleteFilter={deleteFilter}
        open={openFilter === "tags"}
        tagFilters={{hasTaxaRelations: {eq: true}}}
      />
      <LifeFormsFilterModal
        {...props}
        initialValue={editFilter?.lifeForms}
        addModifyFilter={addModifyFilter}
        deleteFilter={deleteFilter}
        open={openFilter === "lifeForms"}
      />
      <RedListStatusFilterModal
        {...props}
        initialValue={editFilter?.redListStatus}
        addModifyFilter={addModifyFilter}
        deleteFilter={deleteFilter}
        open={openFilter === "redListStatus"}
      />
      <FamilyFilterModal
        {...props}
        addModifyFilter={addModifyFilter}
        deleteFilter={deleteFilter}
        open={openFilter === "family"}
        familiesAssociatedWith={taxaType}
      />
      <ValidationFilterModal
        {...props}
        addModifyFilter={addModifyFilter}
        deleteFilter={deleteFilter}
        open={openFilter === "validation"}
        initialValue={editFilter?.validation}
      />
    </>
  )
}
