import {useRef, useEffect, useState} from "react"

export function usePrevious<T>(value: T) {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export function usePreviousSync<T>(value: T) {
  const [current, setCurrent] = useState<T>(value)
  const [previous, setPrevious] = useState<T | null>(null)

  if (value !== current) {
    setPrevious(current)
    setCurrent(value)
  }

  return previous
}
