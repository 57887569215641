import {Button} from "@hortis/ui/button"
import {List} from "@hortis/ui/icons"

export const ShowListButton = ({
  onClick,
  testId,
}: {
  onClick: () => void
  testId?: string
}) => (
  <div className="pointer-events-auto">
    <Button
      startIcon={List}
      size={{xs: "sm", md: "md"}}
      onClick={onClick}
      testId={testId}
      className="shadow-mg border-none"
    >
      Show list
    </Button>
  </div>
)
