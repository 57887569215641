import type {ReactNode} from "react"
import {Fragment} from "react"
import useIdMui from "@mui/utils/useId"

// TODO: in future possibly replace with React built in
// https://www.benmvp.com/blog/new-react-use-opaque-identifier-hook-coming-soon/
// https://github.com/facebook/react/pull/17322
export const useId = (
  name: string,
  idOverride?: string,
): string | undefined => {
  const id = useIdMui(idOverride)
  return id === undefined ? id : `${name}-${id}`
}

export const generateLabelName = (
  name: ReactNode,
  required: boolean,
): JSX.Element => (required ? <>* {name}</> : <>{name}</>)
