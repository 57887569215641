import {useOrganisationSubdomainBasic} from "src/utils/hooks/place"
import {BillingStatus, useGetSubscriptionQuery} from "generated/graphql"
import {Fragment} from "react"
import {differenceInDays} from "date-fns"
import {ClockStopwatch} from "@hortis/ui/icons"
import {Alert, type AlertProps} from "./alert"

export const TrialAlert = (props: Partial<AlertProps>) => {
  const subdomain = useOrganisationSubdomainBasic()
  const pause = subdomain == null
  const [{data}] = useGetSubscriptionQuery({
    variables: {organisationSubdomain: subdomain ?? ""},
    pause,
  })

  const org = data?.org

  if (org == null) {
    return <Fragment />
  }

  const {trialEnd, billingAccountStatus} = org

  if (billingAccountStatus === BillingStatus.Active) {
    return <Fragment />
  }

  const trialDaysLeft =
    trialEnd == null
      ? null
      : Math.ceil(differenceInDays(new Date(trialEnd), new Date()))

  if (trialDaysLeft != null && trialDaysLeft > 7) {
    return <Fragment />
  }

  const trialEndingSoon =
    trialDaysLeft != null && trialDaysLeft <= 7 && trialDaysLeft > 0

  return (
    <Alert
      testId="trial-alert"
      color="warning"
      buttonProps={{children: "Manage plan", href: "/settings/org/billing"}}
      icon={trialEndingSoon ? ClockStopwatch : undefined}
      title={
        trialEndingSoon
          ? `Your trial ends in ${trialDaysLeft} day${
              trialDaysLeft === 1 ? "" : "s"
            }`
          : "Your trial has ended"
      }
      body={
        trialEndingSoon
          ? "Add a subscription plan to avoid disruption. "
          : "Add a subscription plan to unlock full functionality."
      }
      {...props}
    />
  )
}
