import {Fragment} from "react"
import {FilterChip} from "../filter-chip-group"
import type {OpenFilterArgs} from "../plant-materials/filters"
import type {LocalLifeFormsFilter} from "./modal"

export const LifeFormsChipGroup = <T extends string>({
  id,
  filter,
  hidden,
  ...props
}: {
  id: string
  handleOpenFilter: (args: OpenFilterArgs<T>) => void
  handleDelete: (id: string) => void
  filter: LocalLifeFormsFilter | undefined
  filterType: T
  hidden?: boolean
}) =>
  filter == null || hidden === true ? (
    <Fragment />
  ) : (
    <>
      {filter.is != null && filter.is.length > 0 && (
        <FilterChip
          filterLabel="Life forms"
          filterId={id}
          filterData={filter.is.map(({name}) => name)}
          operation="is"
          testIdPrefix="life-forms-is"
          {...props}
        />
      )}
      {filter.isNot != null && filter.isNot.length > 0 && (
        <FilterChip
          filterLabel="IUCN Red List"
          filterId={id}
          filterData={filter.isNot.map(({name}) => name)}
          operation="is not"
          testIdPrefix="life-forms-is-not"
          {...props}
        />
      )}
    </>
  )
