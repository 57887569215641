import {D, pipe} from "@mobily/ts-belt"
import {cleanFormData} from "src/utils/forms"
import {mapTagsToIds} from "src/utils/forms/map-tags-to-ids"
import {mapTaxonOptionToId} from "src/utils/forms/map-taxon-option-to-id"
import {
  AccessionNumberFormat,
  type CreateAccession,
} from "../../../../generated/graphql"
import {convertDatesToISO} from "../../../utils/convert-dates-to-iso"
import type {
  CollectionSiteLocationOption,
  PlantMaterialData,
} from "../components/add-plant-material-form/schema"
import type {CountryOption} from "../components/collection-data-form/schema"
import type {CreateAccessionFormSchema} from "../components/schema"
import {type IdentificationDetails} from "../components/accession-data-form/schema"

export const mapCollectionSiteLocationOptionToId = <
  T extends {collectionSiteLocationOption: CollectionSiteLocationOption},
>({
  collectionSiteLocationOption,
  ...rest
}: T) => ({
  ...rest,
  collectionSiteLocationId: collectionSiteLocationOption.id,
})

export const mapIdentificationDetails = <
  T extends {
    identificationDetails?: IdentificationDetails | null | undefined
  },
>({
  identificationDetails,
  ...rest
}: T) =>
  ({
    ...rest,
    identifiedBy: identificationDetails?.identifiedBy ?? null,
    dateIdentified:
      //eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      identificationDetails?.dateIdentified?.toISOString() ?? null,
    identificationVerificationStatus:
      identificationDetails?.identificationVerificationStatus ?? null,
    identificationQualifier:
      identificationDetails?.identificationQualifier ?? null,
    identificationRemarks: identificationDetails?.identificationRemarks ?? null,
  }) satisfies Partial<CreateAccession>

export const processPlantMaterialFormData = (data: PlantMaterialData) =>
  pipe(
    data,
    D.deleteKeys(["tempId", "materialQualifier"]),
    mapTagsToIds,
    mapCollectionSiteLocationOptionToId,
    convertDatesToISO,
    cleanFormData,
  )

export const mapPlantMaterials = <
  T extends {
    plantMaterials: Array<PlantMaterialData>
  },
>({
  plantMaterials,
  ...rest
}: T) =>
  ({
    ...rest,
    plantMaterial: plantMaterials.map(processPlantMaterialFormData),
  }) satisfies Partial<CreateAccession>

export const mapFormPlantMaterialLocation = (
  plantMaterial: CreateAccessionFormSchema["plantMaterials"][number],
) => {
  const {collectionSiteLocationOption, tags, ...rest} = plantMaterial
  return {
    ...rest,
    collectionSiteLocationId: collectionSiteLocationOption.id,
    tags: tags.map(({id}) => id),
  }
}

export const mapCountryOptionToId = <
  T extends {countryOption: CountryOption | null},
>({
  countryOption,
  ...rest
}: T) =>
  ({
    ...rest,
    collectionCountryCode: countryOption?.codeId ?? null,
  }) satisfies Partial<CreateAccession>

export const mapAccessionNumber = <
  T extends {accessionNumber: string; generateAccessionNumber: boolean},
>({
  accessionNumber,
  generateAccessionNumber,
  ...rest
}: T) =>
  generateAccessionNumber
    ? rest
    : ({
        ...rest,
        accessionNumberFormat: AccessionNumberFormat.Exogenous,
        exogenousAccessionNumber: accessionNumber,
      } satisfies Partial<CreateAccession>)

export const processAccessionFormData = (data: CreateAccessionFormSchema) =>
  pipe(
    data,
    mapTagsToIds,
    mapIdentificationDetails,
    mapTaxonOptionToId,
    mapCountryOptionToId,
    mapAccessionNumber,
    mapPlantMaterials,
    cleanFormData,
  )
