import type {FormHelperTextProps} from "@mui/material/FormHelperText"
import FormHelperText from "@mui/material/FormHelperText"
import * as RA from "fp-ts/ReadonlyArray"
import {applyToMui} from "../../utils/apply-mui"
import {isReadonlyArray} from "../../utils/array"

const OptionallyShowSingleHelperText = applyToMui(FormHelperText)(
  (Component: (props: FormHelperTextProps) => JSX.Element) =>
    function PotentiallyDisplayErrorMessage({
      message,
      testIdPrefix,
    }: {
      message: string | undefined
      testIdPrefix?: string
    }): JSX.Element | null {
      return message !== undefined && message.length > 0 ? (
        <Component
          className="!text-sm"
          style={{
            marginTop: "6px",
            marginLeft: 0,
            marginRight: 0,
          }}
          data-cy={testIdPrefix == null ? undefined : `${testIdPrefix}-message`}
        >
          {message}
        </Component>
      ) : null
    },
)

const renderHelperTexts = (testIdPrefix?: string) =>
  RA.map((message: string) => (
    <OptionallyShowSingleHelperText
      key={message}
      message={message}
      testIdPrefix={testIdPrefix}
    />
  ))

export const OptionallyShowHelperText = ({
  message,
  testIdPrefix,
}: {
  message: ReadonlyArray<string> | string | undefined
  testIdPrefix?: string
}): JSX.Element =>
  isReadonlyArray(message) ? (
    <>{renderHelperTexts(testIdPrefix)(message)}</>
  ) : (
    <OptionallyShowSingleHelperText
      message={message}
      testIdPrefix={testIdPrefix}
    />
  )
