import CircularProgress from "@mui/material/CircularProgress"
import {
  forwardRef,
  type ForwardedRef,
  type ReactElement,
  type ReactNode,
} from "react"
import {colors} from "src/colors"
import {twMerge} from "tailwind-merge"

declare module "react" {
  function forwardRef<T, P = Record<string, unknown>>(
    render: (props: P, ref: React.Ref<T>) => ReactElement | null,
  ): (props: P & React.RefAttributes<T>) => ReactElement | null
}

export interface ListProps<Item> {
  children: ReactNode
  emptyState?: ReactElement
  loading?: boolean
  items: Array<Item>
  className?: string
}

export const List = forwardRef(function ListInner<Item>(
  {children, emptyState, loading, items, className}: ListProps<Item>,
  ref: ForwardedRef<HTMLDivElement>,
) {
  return (
    <div
      className={twMerge(
        "flex h-full flex-col gap-0.5 overflow-auto px-1 py-1",
        className,
      )}
      ref={ref}
    >
      {items.length > 0 ? (
        children
      ) : loading === true ? (
        <div className="flex h-96 items-center justify-center">
          <CircularProgress
            size="28px"
            thickness={4}
            sx={{color: colors.primary[500]}}
          />
        </div>
      ) : emptyState == null ? (
        "No results"
      ) : (
        emptyState
      )}
    </div>
  )
})
