import {Badge} from "@hortis/ui/badge"
import {IconButton} from "@hortis/ui/button"
import {Edit5} from "@hortis/ui/icons"
import {format} from "date-fns"
import {type AccessionFieldsFragment} from "generated/graphql"
import {
  type AccessionEditStage,
  AccessionEditStageCallback,
} from "src/features/records/accession/components/edit-drawer/types"
import {
  RecordField,
  RecordFieldLabel,
  RecordFieldValue,
} from "src/features/records/components/record-field/new-record-field"
import {
  RecordSection,
  RecordSectionContent,
  RecordSectionContentGroup,
  RecordSectionHeader,
  RecordSectionHeaderButtons,
  RecordSectionTitle,
} from "src/features/records/components/record-section/new-record-section"
import {parseUtcDate} from "src/utils/dates/parse-utc-date"

interface AccessionWildCollectionProps {
  accession: AccessionFieldsFragment
  requestEdit: (stage?: AccessionEditStage) => void
  canEdit: boolean
}

export const AccessionWildCollection = ({
  accession,
  requestEdit,
  canEdit,
}: AccessionWildCollectionProps) => {
  const editCollectionData =
    AccessionEditStageCallback.useEditCollectionData(requestEdit)
  return (
    <RecordSection id={"wild-collection"}>
      <RecordSectionHeader>
        <RecordSectionTitle>Wild collection</RecordSectionTitle>
        {accession.collectionCountry?.countryCodeAlpha2 != null && (
          <Badge>
            <div className="flex items-start justify-start gap-1.5">
              <img
                className="h-4 w-4 rounded-full"
                src={`https://flagcdn.com/w20/${accession.collectionCountry.countryCodeAlpha2.toLowerCase()}.png`}
                alt={`${accession.collectionCountry.name} flag`}
              />

              <span className="text-primary-700">
                {accession.collectionCountry.countryCodeAlpha2}
              </span>
            </div>
          </Badge>
        )}
        <RecordSectionHeaderButtons>
          {canEdit && !accession.archived && (
            <IconButton
              onClick={editCollectionData}
              ariaLabel="Edit section"
              data-cy="section-edit-button"
              size="xs"
              variant="tertiaryGray"
              icon={Edit5}
            />
          )}
        </RecordSectionHeaderButtons>
      </RecordSectionHeader>
      <RecordSectionContent>
        <RecordSectionContentGroup className="grid">
          <RecordField data-cy="collection-date">
            <RecordFieldLabel>Collection date</RecordFieldLabel>
            <RecordFieldValue>
              {accession.collectionDate == null
                ? "-"
                : format(parseUtcDate(accession.collectionDate), "PPP")}
            </RecordFieldValue>
          </RecordField>
          <RecordField data-cy="collection-collectors">
            <RecordFieldLabel>Collectors(s)</RecordFieldLabel>
            <RecordFieldValue>
              {accession.collectionCollectors ?? "-"}
            </RecordFieldValue>
          </RecordField>
          <RecordField data-cy="collection-collectors-reference">
            <RecordFieldLabel>Collectors(s) reference</RecordFieldLabel>
            <RecordFieldValue>
              {accession.collectionCollectorsFieldNumber ?? "-"}
            </RecordFieldValue>
          </RecordField>
          <RecordField data-cy="collection-habitat">
            <RecordFieldLabel>Habitat</RecordFieldLabel>
            <RecordFieldValue>
              {accession.collectionHabitat ?? "-"}
            </RecordFieldValue>
          </RecordField>
          <RecordField data-cy="collection-associated-taxa">
            <RecordFieldLabel>Associated taxa</RecordFieldLabel>
            <RecordFieldValue>
              {accession.collectionAssociatedTaxa ?? "-"}
            </RecordFieldValue>
          </RecordField>
          <RecordField data-cy="collection-remarks">
            <RecordFieldLabel>Remarks</RecordFieldLabel>
            <RecordFieldValue>
              {accession.collectionRemarks ?? "-"}
            </RecordFieldValue>
          </RecordField>
        </RecordSectionContentGroup>

        <div className="border-b border-dashed border-grey-200" />

        <RecordSectionContentGroup className="grid">
          <RecordField data-cy="collection-country">
            <RecordFieldLabel>Country</RecordFieldLabel>
            <RecordFieldValue>
              {accession.collectionCountry?.name ?? "-"}
            </RecordFieldValue>
          </RecordField>
          <RecordField data-cy="collection-province-region">
            <RecordFieldLabel>Province / region</RecordFieldLabel>
            <RecordFieldValue>
              {accession.collectionStateProvince ?? "-"}
            </RecordFieldValue>
          </RecordField>
          <RecordField data-cy="collection-locality">
            <RecordFieldLabel>Locality</RecordFieldLabel>
            <RecordFieldValue>
              {accession.collectionLocality ?? "-"}
            </RecordFieldValue>
          </RecordField>
          <RecordField data-cy="collection-coordinates">
            <RecordFieldLabel>Coordinates</RecordFieldLabel>
            {accession.collectionLatitude != null &&
            accession.collectionLongitude != null ? (
              <RecordFieldValue>
                {`${accession.collectionLatitude}, ${accession.collectionLongitude}`}
              </RecordFieldValue>
            ) : (
              "-"
            )}
          </RecordField>
          <RecordField data-cy="collection-uncertainty">
            <RecordFieldLabel>Uncertainty (m)</RecordFieldLabel>
            <RecordFieldValue>
              {accession.collectionCoordinateUncertainty ?? "-"}
            </RecordFieldValue>
          </RecordField>

          <RecordField data-cy="collection-elevation">
            <RecordFieldLabel>Elevation (m)</RecordFieldLabel>
            <RecordFieldValue>
              {accession.collectionElevation ?? "-"}
            </RecordFieldValue>
          </RecordField>
        </RecordSectionContentGroup>
      </RecordSectionContent>
    </RecordSection>
  )
}
