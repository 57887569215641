import type {PlantMaterialFilter} from "generated/graphql"
import * as R from "ramda"
import {countProvenanceFilter} from "../accessions/provenance-filter/count-filter"
import {countDateComparator} from "../count/comparators/date"
import {countStringComparator} from "../count/comparators/string"
import {countClassificationFilter} from "../family-filter/count-family-filter"
import {countTagsFilter} from "../tags-filter/count-filter"
import {countRedListStatusFilter} from "../red-list-status/count-filter"
import {countIdComparator} from "../count/comparators/id"
import {countUserFilter} from "../user-filter/count-user-filter"
import {countConditionFilter} from "./condition-filter/count-filter"
import {countLocationFilter} from "./location-filter/count-filter"
import {countMaterialGroupFilter} from "./material-group-filter/count-filter"
import {countRelationExistsComparator} from "./photos-filter/count-relation-exists-comparator"
import {countBooleanComparator} from "./quantity-filter/count-boolean-comparator"
import {countStatusFilter} from "./status-filter/count-filter"
import type {MaterialFilterType} from "./types"
import {countSexFilter} from "./sex-filter/count-filter"

export type FilterCounts = Record<MaterialFilterType, number> & {
  total: number
  excludeAbsentMaterial: number
}

const createDefaultFilterCounts = (): FilterCounts => ({
  location: 0,
  status: 0,
  condition: 0,
  "material-group": 0,
  notes: 0,
  "tag-number": 0,
  total: 0,
  tags: 0,
  excludeAbsentMaterial: 0,
  quantity: 0,
  family: 0,
  provenance: 0,
  donor: 0,
  commonName: 0,
  photos: 0,
  lastObserved: 0,
  firstAbsent: 0,
  firstPresent: 0,
  redListStatus: 0,
  nativeDistribution: 0,
  lifeForms: 0,
  climate: 0,
  public: 0,
  creator: 0,
  lastEditor: 0,
  sex: 0,
})

// eslint-disable-next-line sonarjs/cognitive-complexity
export const countMaterialFilter = (filter: PlantMaterialFilter) => {
  const count = createDefaultFilterCounts()
  if (filter.excludeAbsentMaterial === true) {
    count.excludeAbsentMaterial += 1
    count.total += 1
  }
  if (filter.location != null) {
    count.location += countLocationFilter(filter.location)
    count.total += 1
  }
  if (filter.status != null) {
    count.status += countStatusFilter(filter.status)
    count.total += 1
  }
  if (filter.public != null) {
    count.quantity += countBooleanComparator(filter.public)
    count.total += 1
  }
  if (filter.massPlanting != null) {
    count.quantity += countBooleanComparator(filter.massPlanting)
    count.total += 1
  }
  if (filter.hasImages != null) {
    count.photos += countRelationExistsComparator(filter.hasImages)
    count.total += 1
  }
  if (filter.materialGroup != null) {
    count["material-group"] += countMaterialGroupFilter(filter.materialGroup)
    count.total += 1
  }

  if (filter.condition != null) {
    count.condition += countConditionFilter(filter.condition)
    count.total += 1
  }
  if (filter.sex != null) {
    count.sex += countSexFilter(filter.sex)
    count.total += 1
  }
  if (filter.lastObserved != null) {
    count.lastObserved += countDateComparator(filter.lastObserved)
    count.total += 1
  }

  if (filter.firstPresent != null) {
    count.firstPresent += countDateComparator(filter.firstPresent)
    count.total += 1
  }
  if (filter.firstAbsent != null) {
    count.firstAbsent += countDateComparator(filter.firstAbsent)
    count.total += 1
  }
  if (filter.accession?.provenance != null) {
    count.provenance += countProvenanceFilter(filter.accession.provenance)
    count.total += 1
  }
  if (filter.accession?.donor != null) {
    count.donor += countStringComparator(filter.accession.donor)
    count.total += 1
  }
  if (filter.notes != null) {
    count.notes += countStringComparator(filter.notes)
    count.total += 1
  }
  if (filter.accession?.taxon?.commonName != null) {
    count.commonName += countStringComparator(filter.accession.taxon.commonName)
    count.total += 1
  }
  if (filter.tagNumber != null) {
    count["tag-number"] += countStringComparator(filter.tagNumber)
    count.total += 1
  }
  const tagsAndFilter = filter.and?.[0]?.tags
  if (tagsAndFilter != null) {
    count["tags"] += countTagsFilter(tagsAndFilter)
    count.total += 1
  }
  const tagsOrFilter = filter.or?.[0]?.tags
  if (tagsOrFilter != null) {
    count["tags"] += countTagsFilter(tagsOrFilter)
    count.total += 1
  }

  if (filter.accession?.taxon?.sharedScientificName?.classification != null) {
    count.family += countClassificationFilter(
      filter.accession.taxon.sharedScientificName.classification,
    )
    count.total += 1
  }
  if (
    filter.accession?.taxon?.sharedScientificName?.taxon?.globalRedListStatus !=
    null
  ) {
    count.redListStatus += countRedListStatusFilter(
      filter.accession.taxon.sharedScientificName.taxon.globalRedListStatus,
    )
    count.total += 1
  }
  const nativeDistribution =
    filter.accession?.taxon?.or?.[0]?.nativeDistribution
  if (
    nativeDistribution != null ||
    filter.accession?.taxon?.nativeDistribution != null
  ) {
    count.nativeDistribution += 1
    count.total += 1
  }
  if (
    filter.accession?.taxon?.sharedScientificName?.taxon?.lifeForms?.id != null
  ) {
    count.lifeForms += countIdComparator(
      filter.accession.taxon.sharedScientificName.taxon.lifeForms.id,
    )
    count.total += 1
  }
  if (filter.accession?.taxon?.sharedScientificName?.taxon?.climate != null) {
    count.lifeForms += countStringComparator(
      filter.accession.taxon.sharedScientificName.taxon.climate,
    )
    count.total += 1
  }
  if (filter.createdBy != null) {
    count.creator += countUserFilter(filter.createdBy)
    count.total += 1
  }
  if (filter.updatedBy != null) {
    count.lastEditor += countUserFilter(filter.updatedBy)
    count.total += 1
  }
  return count
}

const sumObjectValues = <T>(...args: Array<T>) =>
  R.reduce(R.mergeWith(R.add), {} as T)(args)

export const countMaterialFilters = (
  filters: PlantMaterialFilter,
): FilterCounts => {
  let count = createDefaultFilterCounts()
  count = sumObjectValues(countMaterialFilter(filters), count)
  if (filters.and != null) {
    for (const filter of filters.and) {
      count = sumObjectValues(countMaterialFilter(filter), count)
    }
  }
  return count
}
