import * as Apply from "fp-ts/Apply"
import * as R from "fp-ts/Reader"
import * as Rec from "fp-ts/Record"
import {pipe} from "fp-ts/function"
import type {UseQueryExecute} from "urql"
import type {AccessionFieldsFragment} from "../../../../../../generated/graphql"
import {createBindCallbackWithVoidReturn} from "../../../../../utils/hooks/callback"
import {camelCasePrefix} from "../../../../../utils/struct"

export enum AccessionEditStage {
  overview,
  editAccessionData,
  editCollectionData,
  plantMaterial,
  addPlantMaterial,
}
const mapToCallback = Rec.map((stage: AccessionEditStage) =>
  createBindCallbackWithVoidReturn(stage),
)
export const AccessionEditStageCallback = pipe(
  AccessionEditStage,
  camelCasePrefix("use"),
  mapToCallback,
)
export const useAccessionEditStageCallback = Apply.sequenceS(R.Apply)(
  mapToCallback(AccessionEditStage),
)

export interface BaseEditStageProps {
  accession: AccessionFieldsFragment
  transitionStage: (stage: AccessionEditStage) => void
  requestClose: () => void
  refetch: UseQueryExecute
  open: boolean
}
