import {Button} from "@hortis/ui/button"
import {TwicImg} from "@twicpics/components/react"
import type {MutableRefObject} from "react"
import {useCallback, useState} from "react"
import {useGetMaterial} from "src/features/records/material/fetchers"
import {getThumbnailPhoto} from "src/utils/photos"
import {useMediaQuery} from "@hortis/ui/hooks/media-query"
import {Drawer, DrawerContent} from "@hortis/ui/drawer"
import {useMountAware} from "../../../utils/hooks/mount-aware"
import {createMaterialNumber} from "../../collection/components/plant-materials/material-number"
import type {MapPositioningMaterial} from "./types"

interface EditDetailsOverlayProps {
  editingMaterial: MapPositioningMaterial
  cancelEdit: () => void
  confirmEdit: () => Promise<void>
}

export const EditDetailsOverlay = ({
  editingMaterial,
  cancelEdit,
  confirmEdit,
}: EditDetailsOverlayProps) => {
  const isDesktop = useMediaQuery("sm")
  const [loading, setLoading] = useState(false)

  const [{data}] = useGetMaterial({
    variables: {
      accessionNumber: editingMaterial.accession?.accessionNumber ?? "",
      qualifier: editingMaterial.qualifier,
      relatedMaterialsFilters: {},
    },
    pause: editingMaterial.accession == null,
  })

  const plantMaterial = data?.org?.site?.result
  const thumbnail = getThumbnailPhoto(plantMaterial)

  const handleConfirmEdit = useMountAware(
    useCallback(
      async (mounted: MutableRefObject<boolean>) => {
        setLoading(true)
        await confirmEdit()
        if (mounted.current) {
          setLoading(false)
        }
      },
      [confirmEdit],
    ),
  )

  const content = (
    <div className="space-y-4">
      <div className="space-y-3">
        <p className="text-sm font-medium text-grey-500">Positioning</p>
        <div className="flex items-center gap-3">
          {thumbnail != null && (
            <TwicImg
              className="h-10 w-10 flex-shrink-0 overflow-hidden rounded-md"
              src={`${thumbnail.bucketName}/${thumbnail.fileName}`}
              ratio="1"
              eager
            />
          )}
          <div className="truncate">
            <p className="text-sm font-medium text-grey-500">
              {createMaterialNumber(
                editingMaterial.accession?.accessionNumber ?? "",
                editingMaterial.qualifier,
                "/",
              )}
            </p>
            <p className="truncate break-all text-base font-medium text-grey-900">
              {editingMaterial.accession?.scientificName ?? ""}
            </p>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-3">
        <Button onClick={cancelEdit} testId="cancel-update-position" size="sm">
          Cancel
        </Button>
        <Button
          variant="primary"
          testId="confirm-update-position"
          size="sm"
          onClick={handleConfirmEdit}
          loading={loading}
        >
          Confirm
        </Button>
      </div>
    </div>
  )

  return isDesktop ? (
    <div className="pointer-events-auto z-50 m-6 w-full max-w-xs self-center rounded-xl border border-grey-200 bg-white p-6 shadow-md animate-in fade-in">
      {content}
    </div>
  ) : (
    <Drawer open onClose={cancelEdit} modal={false}>
      <DrawerContent className="px-4 py-6 shadow-md-up">
        {content}
      </DrawerContent>
    </Drawer>
  )
}
