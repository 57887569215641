import type {NullableStringComparator, TaxaFilter} from "generated/graphql"

export const transformClimateFilter = (
  filter: NullableStringComparator | undefined,
): TaxaFilter =>
  filter == null
    ? {}
    : {
        fullyValidated: filter.contains == null ? undefined : {eq: true},
        sharedScientificName: {
          taxon: {climate: {contains: filter.contains, null: filter.null}},
        },
      }
