import {Tag} from "@hortis/ui/tag"
import type {RecordTagFieldsFragment} from "generated/graphql"
import {twMerge} from "tailwind-merge"

export interface DataTableTagsCellProps {
  tags: ReadonlyArray<RecordTagFieldsFragment> | undefined | null
  wrap?: boolean
}

export function DataTableTagsCell({tags, wrap}: DataTableTagsCellProps) {
  return (
    <div
      className={twMerge(
        "flex min-h-[24px] gap-1",
        wrap === true && "flex-wrap",
      )}
    >
      {tags == null || tags.length === 0
        ? "-"
        : tags.map(({name, id}) => (
            <Tag key={id} size="sm">
              {name}
            </Tag>
          ))}
    </div>
  )
}
