import type {FilterOperation} from "../../plant-materials"
import type {LocalAccessionFilter} from "../types"

export const extractMaterialReceivedData = (
  filter: NonNullable<LocalAccessionFilter["materialType"]>,
  operation: FilterOperation,
): Array<string> | undefined => {
  switch (operation) {
    case "is": {
      return filter.is?.map(({name, code}) => name ?? code)
    }
    case "isNot": {
      return filter.isNot?.map(({name, code}) => name ?? code)
    }
    default: {
      return undefined
    }
  }
}
