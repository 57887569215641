import {useMediaQuery} from "@hortis/ui/hooks/media-query"
import {Link} from "@tanstack/react-router"
import type {RowData, Table} from "@tanstack/table-core"
import {
  ProvenanceCategoryCode,
  type AccessionsListFieldsFragment,
} from "generated/graphql"
import {forwardRef, type ForwardedRef, type ReactElement} from "react"
import {List} from "src/components"
import AccessionRecordCard from "src/features/records/components/new-record-card/variants/accession-record-card"
import {getRecordPhotosThumbnailUrl} from "src/features/taxonomy/components/taxon-page/header/get-record-photos"

export interface AccessionsMobileListProps<TData extends RowData> {
  selectMode: boolean
  table: Table<TData>
  emptyState?: ReactElement
}

export const AccessionsMobileList = forwardRef(
  function AccessionsMobileListInner<
    TData extends AccessionsListFieldsFragment,
  >(
    {selectMode, table, emptyState}: AccessionsMobileListProps<TData>,
    ref: ForwardedRef<HTMLDivElement>,
  ) {
    const isNotMobile = useMediaQuery("sm")
    return (
      <List.List
        items={table.getRowModel().rows}
        emptyState={emptyState}
        ref={ref}
      >
        {table.getRowModel().rows.map(({original: accession, ...row}) => {
          const content = (
            <AccessionRecordCard
              statuses={accession.plantMaterial?.map(
                (material) => material.status,
              )}
              accessionNumber={accession.accessionNumber}
              provenanceCategoryCode={
                accession.provenanceCategory?.codeId ?? ProvenanceCategoryCode.G // not sure what to default to here
              }
              photoUrl={getRecordPhotosThumbnailUrl([accession])}
              scientificName={accession.scientificName ?? "-"}
              size={isNotMobile ? "md" : "sm"}
              variant="Row"
              selected={row.getIsSelected()}
            />
          )

          return selectMode ? (
            <button
              key={accession.id}
              type="button"
              className="w-full text-left"
              onClick={() => {
                row.toggleSelected()
              }}
              data-cy="accessions-list-row"
            >
              {content}
            </button>
          ) : (
            <Link
              key={accession.id}
              from="/sites/$siteSlug"
              to="/sites/$siteSlug/accessions/$accessionNumber"
              params={{
                accessionNumber: accession.accessionNumber,
              }}
              data-cy="accessions-list-row"
            >
              {content}
            </Link>
          )
        })}
      </List.List>
    )
  },
)
