import {useCallback} from "react"
import type {GeographicPosition} from "generated/graphql"
import {useMaplibre} from "src/components/map/utils"
import {useMapPaddingStore} from "./components/map-padding-store"

export const useFindOnMap = (mapId: string) => {
  const {[mapId]: ourMap} = useMaplibre()
  const {mapPadding} = useMapPaddingStore()
  return useCallback(
    (position: GeographicPosition | undefined | null) => {
      if (ourMap != null && position != null) {
        ourMap.flyTo({
          center: {
            lng: position.longitude,
            lat: position.latitude,
          },
          zoom: 18.5,
          padding: mapPadding,
        })
      }
    },
    [ourMap, mapPadding],
  )
}
