import {Fragment} from "react"
import {FilterChip} from "../filter-chip-group"
import type {OpenFilterArgs} from "../plant-materials/filters"
import {extractTagsFilterData} from "./extract-tags-filter-data"
import type {LocalTagsFilter} from "./schema"

export const TagsChipGroup = <T extends string>({
  id,
  filter,
  hidden,
  ...props
}: {
  id: string
  handleOpenFilter: (args: OpenFilterArgs<T>) => void
  handleDelete: (id: string) => void
  filter: LocalTagsFilter | undefined
  filterType: T
  hidden?: boolean
}) =>
  filter == null || hidden === true ? (
    <Fragment />
  ) : (
    <>
      {filter.is != null && filter.is.length > 0 && (
        <FilterChip
          filterLabel="Tags"
          filterId={id}
          filterData={extractTagsFilterData(filter, "is")}
          operation="is"
          {...props}
        />
      )}
      {filter.isNot != null && filter.isNot.length > 0 && (
        <FilterChip
          filterLabel="Tags"
          filterId={id}
          filterData={extractTagsFilterData(filter, "isNot")}
          operation="is not"
          {...props}
        />
      )}
      {filter.contains != null && (
        <FilterChip
          filterLabel="Tags"
          filterId={id}
          filterData={extractTagsFilterData(filter, "contains")}
          operation="contains"
          {...props}
        />
      )}
    </>
  )
