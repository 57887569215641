import type {IconProps} from "./types"

export const SlDeleteIcon = ({
  style,
  width,
  height,
  color: customColor,
  className,
}: IconProps): JSX.Element => {
  const color = customColor ?? "#424242"
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? "24"}
      height={height ?? "24"}
      viewBox="0 0 24 24"
      fill="none"
      style={style}
      className={className}
    >
      <path
        d="M3 6.27271H21"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.8409 3H10.1591C9.8336 3 9.52144 3.1293 9.29128 3.35946C9.06113 3.58962 8.93182 3.90178 8.93182 4.22727V6.27273H15.0682V4.22727C15.0682 3.90178 14.9389 3.58962 14.7087 3.35946C14.4786 3.1293 14.1664 3 13.8409 3Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.1591 16.7045V10.5681"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.8409 16.7045V10.5681"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.6127 19.8709C17.59 20.1784 17.4515 20.4658 17.2253 20.6753C16.9991 20.8848 16.702 21.0008 16.3936 21H7.60636C7.29805 21.0008 7.00086 20.8848 6.77466 20.6753C6.54845 20.4658 6.41003 20.1784 6.38727 19.8709L5.25 6.27271H18.75L17.6127 19.8709Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
