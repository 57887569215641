import {keyframes} from "@emotion/react"
import * as TooltipPrimitive from "@radix-ui/react-tooltip"
import {forwardRef} from "react"
import {twMerge} from "tailwind-merge"

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`
const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.8);
  }
`

const TooltipProvider = TooltipPrimitive.Provider

const Tooltip = TooltipPrimitive.Root

const TooltipTrigger = TooltipPrimitive.Trigger

const TooltipContent = forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({className, sideOffset = 4, children, ...props}, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={twMerge(
      "bg-grey-900 z-50 hidden w-fit max-w-80 origin-[var(--radix-popover-content-transform-origin)] flex-col gap-1 whitespace-pre-wrap rounded-lg px-3 py-2 text-xs font-medium text-white shadow-lg has-[:nth-child(4)]:py-3 lg:flex",
      className,
    )}
    css={{
      animation: `0.1s ${fadeIn} ease`,
      '&[data-state="closed"]': {animation: `0.1s ${fadeOut} ease`},
    }}
    {...props}
  >
    <TooltipPrimitive.Arrow className="fill-grey-900" />
    {children}
  </TooltipPrimitive.Content>
))
// @ts-expect-error -- Reclared forwardRef does not have a displayName
TooltipContent.displayName = TooltipPrimitive.Content.displayName

export {Tooltip, TooltipContent, TooltipProvider, TooltipTrigger}
