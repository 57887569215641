import {TaxonomicRank, useGetSharedScientificNameQuery} from "generated/graphql"
import {useCallback, useEffect, useMemo, useState} from "react"
import {useFormContext} from "react-hook-form"
import {FormTextField} from "src/components/text-field"
import {Toggle} from "src/components/toggle"
import {type TaxonFields} from "./schema"

export const NativeDistributionField = () => {
  const {watch, setValue} = useFormContext<TaxonFields>()
  const value = watch("nativeDistribution")
  const sharedScientificName = watch("sharedScientificName")
  const [overridden, setOverridden] = useState(value != null)

  const [{data}] = useGetSharedScientificNameQuery({
    variables: {id: sharedScientificName?.id ?? ""},
    pause: sharedScientificName == null,
  })
  const sharedNativeDistribution = useMemo(
    () => data?.sharedScientificName?.taxon?.nativeDistribution,
    [data],
  )
  const showOverride = useMemo(
    () =>
      sharedScientificName != null &&
      sharedScientificName.rank !== TaxonomicRank.Genus &&
      sharedNativeDistribution != null,
    [sharedScientificName, sharedNativeDistribution],
  )

  useEffect(() => {
    setOverridden(value != null)
  }, [sharedNativeDistribution, value])

  const toggleOverride = useCallback(() => {
    if (overridden) {
      setValue("nativeDistribution", null)
    }
    setOverridden(!overridden)
  }, [overridden, setValue])

  return (
    <div className="flex flex-col gap-1.5">
      <FormTextField
        fullWidth
        name="nativeDistribution"
        id="native-distribution"
        label="Native distribution"
        testId="native-distribution"
        disabled={!overridden && showOverride}
        InputProps={
          !overridden && showOverride
            ? {value: sharedNativeDistribution}
            : undefined
        }
      />
      {showOverride && (
        /* eslint-disable-next-line jsx-a11y/label-has-associated-control -- Using child input */
        <label className="flex items-center gap-2 text-sm font-medium">
          <Toggle
            checked={overridden}
            onChange={toggleOverride}
            testId="override-native-distribution-toggle"
          />
          Override native distribution
        </label>
      )}
    </div>
  )
}
