import type {MaterialSex} from "generated/graphql"
import {materialSexLabelMap} from "src/utils/label-maps/material-sex"
import type {FilterOperation, LocalMaterialFilter} from "../types"

export const extractSexFilterData = (
  filter: NonNullable<LocalMaterialFilter["sex"]>,
  operation: FilterOperation,
): Array<string> | undefined => {
  const isFilters = filter.or
    ?.flatMap(({eq}) => eq)
    .filter((val): val is MaterialSex => val != null)
  const isNotFilters = filter.or
    ?.flatMap(({neq}) => neq)
    .filter((val): val is MaterialSex => val != null)

  switch (operation) {
    case "is": {
      return isFilters?.map((materialSex) => materialSexLabelMap[materialSex])
    }
    case "isNot": {
      return isNotFilters?.map((status) => materialSexLabelMap[status])
    }
    default: {
      return undefined
    }
  }
}
