import type {Dispatch, ReactElement} from "react"
import {Fragment, useCallback} from "react"
import {DateComparatorChipGroup} from "../components/date-filter/chip-group"
import {FamilyChipGroup} from "../family-filter/chip-group"
import {StringComparatorChipGroup} from "../filter-chip-group/groups/string-comparator"
import {FilterActionTypes} from "../plant-materials/filter-reducer"
import type {OpenFilterArgs} from "../plant-materials/filters"
import {TagsChipGroup} from "../tags-filter/chip-group"
import {RedListStatusChipGroup} from "../red-list-status/chip-group"
import {LifeFormsChipGroup} from "../life-forms/chip-group"
import {UserChipGroup} from "../user-filter/user-chip-group"
import type {AccessionFilterAction} from "./filter-reducer"
import {MaterialReceivedFilterChipGroup} from "./material-received/chip-group"
import {ProvenanceChipGroup} from "./provenance-filter/chip-group"
import type {AccessionFilterType, LocalAccessionFilterWithId} from "./types"

interface FilterChipGroupProps {
  handleOpenFilter: (args: OpenFilterArgs<AccessionFilterType>) => void
  dispatchFilter: Dispatch<AccessionFilterAction>
}

export const AccessionFilterChipGroup = ({
  filters,
  selectFilter,
  dispatchFilter,
  ...props
}: FilterChipGroupProps & {
  selectFilter?: AccessionFilterType
  filters: Array<LocalAccessionFilterWithId>
}): ReactElement => {
  const deleteFilter = useCallback(
    (id: string) => {
      dispatchFilter({
        type: FilterActionTypes.DELETE_FILTER,
        payload: {id},
      })
    },
    [dispatchFilter],
  )

  const filterGroupHidden = (type: AccessionFilterType) =>
    selectFilter != null && selectFilter !== type

  return (
    <>
      {filters.map((filter) => (
        <Fragment key={filter.id}>
          <StringComparatorChipGroup
            filter={filter.taxon?.commonName}
            filterType="commonName"
            id={filter.id}
            handleDelete={deleteFilter}
            hidden={filterGroupHidden("commonName")}
            filterLabel="Common name"
            {...props}
          />
          <StringComparatorChipGroup
            filter={filter.notes}
            filterType="notes"
            id={filter.id}
            handleDelete={deleteFilter}
            hidden={filterGroupHidden("notes")}
            filterLabel="Notes"
            {...props}
          />
          <TagsChipGroup
            filterType="tags"
            filter={filter.tags}
            id={filter.id}
            handleDelete={deleteFilter}
            hidden={filterGroupHidden("tags")}
            {...props}
          />
          <StringComparatorChipGroup
            filter={filter.nativeDistribution}
            filterType="nativeDistribution"
            id={filter.id}
            handleDelete={deleteFilter}
            hidden={filterGroupHidden("nativeDistribution")}
            filterLabel="Native distribution"
            {...props}
          />
          <UserChipGroup
            filter={filter.creator}
            filterType="creator"
            id={filter.id}
            handleDelete={deleteFilter}
            hidden={filterGroupHidden("creator")}
            filterLabel="Creator"
            {...props}
          />
          <UserChipGroup
            filter={filter.lastEditor}
            filterType="lastEditor"
            id={filter.id}
            handleDelete={deleteFilter}
            hidden={filterGroupHidden("lastEditor")}
            filterLabel="Last editor"
            {...props}
          />
          <LifeFormsChipGroup
            filterType="lifeForms"
            filter={filter.lifeForms}
            id={filter.id}
            handleDelete={deleteFilter}
            hidden={filterGroupHidden("lifeForms")}
            {...props}
          />
          <StringComparatorChipGroup
            filter={filter.climate}
            filterType="climate"
            id={filter.id}
            handleDelete={deleteFilter}
            hidden={filterGroupHidden("climate")}
            filterLabel="Climate"
            {...props}
          />
          <FamilyChipGroup
            filterType="family"
            filter={filter.family}
            id={filter.id}
            handleDelete={deleteFilter}
            hidden={filterGroupHidden("family")}
            {...props}
          />
          <ProvenanceChipGroup
            filter={filter.provenance}
            id={filter.id}
            handleDelete={deleteFilter}
            hidden={filterGroupHidden("provenance")}
            {...props}
          />
          <StringComparatorChipGroup
            filter={filter.donor}
            filterType="donor"
            id={filter.id}
            handleDelete={deleteFilter}
            hidden={filterGroupHidden("donor")}
            filterLabel="Donor / supplier"
            {...props}
          />
          <StringComparatorChipGroup
            filter={filter.ipenNumber}
            filterType="ipenNumber"
            id={filter.id}
            handleDelete={deleteFilter}
            hidden={filterGroupHidden("ipenNumber")}
            filterLabel="IPEN number"
            {...props}
          />
          <RedListStatusChipGroup
            filterType="redListStatus"
            filter={filter.redListStatus}
            id={filter.id}
            handleDelete={deleteFilter}
            hidden={filterGroupHidden("redListStatus")}
            {...props}
          />
          <MaterialReceivedFilterChipGroup
            filter={filter.materialType}
            id={filter.id}
            handleDelete={deleteFilter}
            hidden={filterGroupHidden("materialReceived")}
            {...props}
          />
          <DateComparatorChipGroup
            id={filter.id}
            handleDelete={deleteFilter}
            filter={filter.accessionDate}
            filterType={"accessionDate"}
            filterLabel={"Accession date"}
            hidden={filterGroupHidden("accessionDate")}
            {...props}
          />
          <DateComparatorChipGroup
            id={filter.id}
            handleDelete={deleteFilter}
            filter={filter.deaccessionDate}
            filterType={"deaccessionDate"}
            filterLabel={"Deaccession date"}
            hidden={filterGroupHidden("deaccessionDate")}
            {...props}
          />
        </Fragment>
      ))}
    </>
  )
}
