import Alert from "@mui/material/Alert"
import Collapse from "@mui/material/Collapse"
import {useTheme} from "@mui/material/styles"
import type {ReactElement} from "react"

export const FilterModalError = ({
  error,
}: {
  error: string | null
}): ReactElement => {
  const theme = useTheme()
  return (
    <Collapse in={error !== null} timeout={300} unmountOnExit>
      <Alert
        data-cy="filter-error"
        severity="error"
        sx={{
          paddingTop: theme.spacing(0.5),
          paddingBottom: theme.spacing(0.5),
          alignItems: "center",
        }}
      >
        {error}
      </Alert>
    </Collapse>
  )
}
