import {Fragment} from "react"
import {FilterChip} from "src/features/filters/filter-chip-group"
import type {OpenFilterArgs} from "../filters"
import type {MaterialFilterType, LocalMaterialFilter} from "../types"
import {extractStatusFilterData} from "./extract-filter-data"

export const StatusFilterChipGroup = ({
  id,
  filter,
  hidden,
  ...props
}: {
  id: string
  handleOpenFilter: (args: OpenFilterArgs<MaterialFilterType>) => void
  handleDelete: (id: string) => void
  filter: LocalMaterialFilter["status"]
  hidden?: boolean
}) =>
  filter == null || hidden === true ? (
    <Fragment />
  ) : (
    <>
      {filter.is != null && filter.is.length > 0 && (
        <FilterChip
          filterLabel="Status"
          filterId={id}
          filterType="status"
          filterData={extractStatusFilterData(filter, "is")}
          operation="is"
          {...props}
        />
      )}
      {filter.isNot != null && filter.isNot.length > 0 && (
        <FilterChip
          filterLabel="Status"
          filterId={id}
          filterType="status"
          filterData={extractStatusFilterData(filter, "isNot")}
          operation="is not"
          {...props}
        />
      )}
    </>
  )
