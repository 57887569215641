import type {
  NullableStringComparator,
  ProvenanceFilter,
} from "generated/graphql"
import type {Dispatch, RefObject} from "react"
import {DateComparatorFilterModal} from "src/components/date-comparator-filter"
import {initialDateFilterValue} from "src/components/date-comparator-filter/utils"
import {NullableStringFilterModal} from "../../components/nullable-string-filter/modal"
import {FamilyFilterModal} from "../../family-filter/modal"
import {TagsFilterModal} from "../../tags-filter"
import {DonorsFilterModal} from "../donors-filter"
import type {AccessionFilterAction} from "../filter-reducer"
import {MaterialReceivedFilterModal} from "../material-received"
import {ProvenanceFilterModal} from "../provenance-filter/modal"
import type {AccessionFilterType, LocalAccessionFilterWithId} from "../types"
import {RedListStatusFilterModal} from "../../red-list-status/modal"
import {LifeFormsFilterModal} from "../../life-forms/modal"
import {UserFilterModal} from "../../user-filter/user-filter-modal"
import {useDispatchFilter} from "./use-dispatch-filter"

interface FilterModalsProps {
  filterModalAnchorEl?: RefObject<HTMLElement | null>
  editFilter?: LocalAccessionFilterWithId | undefined
  openFilter: AccessionFilterType | false
  dispatchFilters: Dispatch<AccessionFilterAction>
  handleFilterModalClose: () => void
}

export const AccessionFilterModals = ({
  filterModalAnchorEl,
  openFilter,
  editFilter,
  dispatchFilters,
  handleFilterModalClose,
}: FilterModalsProps) => {
  const props = {
    initialValue: editFilter,
    onClose: handleFilterModalClose,
    anchorEl: filterModalAnchorEl,
    dispatchFilters: dispatchFilters,
  }
  const {addModifyFilter, deleteFilter} = useDispatchFilter({
    dispatchFilters,
    id: editFilter?.id,
    onClose: handleFilterModalClose,
  })

  return (
    <>
      <ProvenanceFilterModal
        {...props}
        open={openFilter === "provenance"}
        addModifyFilter={(filter: ProvenanceFilter) => {
          addModifyFilter({provenance: filter})
        }}
        deleteFilter={deleteFilter}
        initialValue={editFilter?.provenance}
      />
      <NullableStringFilterModal
        {...props}
        initialValue={editFilter?.taxon?.commonName}
        addModifyFilter={(filter: NullableStringComparator) => {
          addModifyFilter({taxon: {commonName: filter}})
        }}
        deleteFilter={deleteFilter}
        open={openFilter === "commonName"}
        filterTitle="Common name"
        testIdPrefix="commonname"
      />
      <DonorsFilterModal
        {...props}
        open={openFilter === "donor"}
        initialValue={editFilter?.donor}
        addModifyFilter={(filter: NullableStringComparator) => {
          addModifyFilter({donor: filter})
        }}
        deleteFilter={deleteFilter}
        donorsAssociatedWith="site"
      />
      <UserFilterModal
        {...props}
        initialValue={editFilter?.creator}
        addModifyFilter={(creator) => {
          addModifyFilter({creator})
        }}
        deleteFilter={deleteFilter}
        open={openFilter === "creator"}
        title="Creator"
      />
      <UserFilterModal
        {...props}
        initialValue={editFilter?.lastEditor}
        addModifyFilter={(lastEditor) => {
          addModifyFilter({lastEditor})
        }}
        deleteFilter={deleteFilter}
        open={openFilter === "lastEditor"}
        title="Last editor"
      />
      <NullableStringFilterModal
        {...props}
        initialValue={editFilter?.nativeDistribution}
        addModifyFilter={(filter) => {
          addModifyFilter({
            nativeDistribution: filter,
          })
        }}
        deleteFilter={deleteFilter}
        open={openFilter === "nativeDistribution"}
        testIdPrefix="nativedistribution"
        filterTitle="Native distribution"
      />
      <RedListStatusFilterModal
        {...props}
        initialValue={editFilter?.redListStatus}
        addModifyFilter={addModifyFilter}
        deleteFilter={deleteFilter}
        open={openFilter === "redListStatus"}
        filterBy="site"
      />
      <LifeFormsFilterModal
        {...props}
        initialValue={editFilter?.lifeForms}
        addModifyFilter={addModifyFilter}
        deleteFilter={deleteFilter}
        open={openFilter === "lifeForms"}
        filterBy="site"
      />
      <NullableStringFilterModal
        {...props}
        initialValue={editFilter?.climate}
        addModifyFilter={(filter) => {
          addModifyFilter({
            climate: filter,
          })
        }}
        deleteFilter={deleteFilter}
        open={openFilter === "climate"}
        filterTitle="Climate"
      />
      <NullableStringFilterModal
        {...props}
        deleteFilter={deleteFilter}
        initialValue={editFilter?.ipenNumber}
        addModifyFilter={(filter: NullableStringComparator) => {
          addModifyFilter({ipenNumber: filter})
        }}
        filterTitle="IPEN number"
        open={openFilter === "ipenNumber"}
        testIdPrefix="ipennumber"
      />
      <MaterialReceivedFilterModal
        {...props}
        open={openFilter === "materialReceived"}
      />
      <NullableStringFilterModal
        {...props}
        initialValue={editFilter?.notes}
        addModifyFilter={(filter: NullableStringComparator) => {
          addModifyFilter({notes: filter})
        }}
        deleteFilter={deleteFilter}
        open={openFilter === "notes"}
        filterTitle="Notes"
      />
      <TagsFilterModal
        {...props}
        addModifyFilter={addModifyFilter}
        deleteFilter={deleteFilter}
        open={openFilter === "tags"}
      />
      <FamilyFilterModal
        {...props}
        addModifyFilter={addModifyFilter}
        deleteFilter={deleteFilter}
        open={openFilter === "family"}
        familiesAssociatedWith="site"
      />
      <DateComparatorFilterModal
        {...props}
        deleteFilter={deleteFilter}
        addModifyFilter={(filter) => {
          addModifyFilter({accessionDate: filter})
        }}
        initialValue={initialDateFilterValue(editFilter?.accessionDate)}
        open={openFilter === "accessionDate"}
        filterTitle="Accession date"
      />
      <DateComparatorFilterModal
        {...props}
        deleteFilter={deleteFilter}
        addModifyFilter={(filter) => {
          addModifyFilter({deaccessionDate: filter})
        }}
        initialValue={initialDateFilterValue(editFilter?.deaccessionDate)}
        open={openFilter === "deaccessionDate"}
        filterTitle="Deaccession date"
      />
    </>
  )
}
