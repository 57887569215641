import * as A from "fp-ts/Array"
import {TaxonomicRank, TaxonomicStatus} from "generated/graphql"
import {useMemo} from "react"
import {FormProvider} from "react-hook-form"
import {ChipGridStylingPreset, FormChipGrid} from "src/components/chip-grid"
import {
  CollapsableRadioButton,
  RadioButton,
  RadioGroup,
} from "src/components/radio"
import {FilterTitle} from "src/features/filters/components/modal/filter-title"
import {match} from "ts-pattern"
import type {FilterModalProps} from "../../components/modal/filter-modal"
import {FilterModal} from "../../components/modal/filter-modal"
import {FilterModalButtons} from "../../components/modal/modal-buttons"
import {FilterModalError} from "../../components/modal/modal-error"
import {useFilterForm} from "../../components/modal/use-filter-form"
import {validationFilterResolver, type LocalValidationFilter} from "./schema"

export const rankChips = [
  {label: "Genus", value: TaxonomicRank.Genus},
  {label: "Species", value: TaxonomicRank.Species},
  {label: "Infraspecies", value: TaxonomicRank.Infraspecies},
]

export const statusChips = [
  {label: "Accepted", value: TaxonomicStatus.Accepted},
  {label: "Synonym", value: TaxonomicStatus.Synonym},
  {label: "Unplaced", value: TaxonomicStatus.Unplaced},
]

type Operation = "isRank" | "isStatus" | "isUnvalidated"
const createDefaultValues = (
  initialValue?: NonNullable<LocalValidationFilter>,
) => {
  const defaultValues = {
    isRank: initialValue?.isRank == null ? [] : initialValue.isRank,
    isStatus: initialValue?.isStatus == null ? [] : initialValue.isStatus,
    isUnvalidated: initialValue?.isUnvalidated ?? false,
  }
  return {
    defaultValues,
    initialOperation:
      defaultValues.isRank.length > 0
        ? ("isRank" as Operation)
        : defaultValues.isStatus.length > 0
        ? ("isStatus" as Operation)
        : defaultValues.isUnvalidated
        ? ("isUnvalidated" as Operation)
        : ("isRank" as Operation),
  }
}

interface ValidationFilterFormProps {
  addModifyFilter: (changes: {validation?: LocalValidationFilter}) => void
  deleteFilter: (() => void) | undefined
  initialValue?: LocalValidationFilter | null | undefined
  onClose: () => void
}

export const ValidationFilterForm = ({
  initialValue,
  onClose,
  addModifyFilter,
  deleteFilter,
}: ValidationFilterFormProps) => {
  const {error, setError, operation, handleRadioChange, formMethods} =
    useFilterForm({
      resolver: validationFilterResolver,
      ...createDefaultValues(initialValue ?? undefined),
    })

  const onSubmit = useMemo(
    () =>
      formMethods.handleSubmit((values) => {
        match(operation)
          .with("isRank", () => {
            if (A.isNonEmpty(values.isRank)) {
              addModifyFilter({
                validation: {
                  isRank: values.isRank,
                },
              })
              onClose()
            } else {
              setError("Select a rank")
            }
          })
          .with("isStatus", () => {
            if (A.isNonEmpty(values.isStatus)) {
              addModifyFilter({
                validation: {
                  isStatus: values.isStatus,
                },
              })
              onClose()
            } else {
              setError("Select a status")
            }
          })
          .with("isUnvalidated", () => {
            addModifyFilter({
              validation: {
                isUnvalidated: true,
              },
            })
            onClose()
          })
          .run()
      }),
    [formMethods, operation, onClose, addModifyFilter, setError],
  )

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={onSubmit}>
        <div className="mb-4 flex flex-col gap-4">
          <FilterTitle title="Validation" deleteFilter={deleteFilter} />
          <FilterModalError error={error} />
          <RadioGroup
            value={operation}
            onChange={handleRadioChange}
            label="Operation"
          >
            {(props) => (
              <>
                <CollapsableRadioButton
                  selectedValue="isRank"
                  testId="validation-is-rank-radio"
                  label="Rank is"
                  {...props}
                >
                  <FormChipGrid
                    name="isRank"
                    testId="validation-is-rank-chip-grid"
                    stylingPreset={ChipGridStylingPreset.BASIC_GRID}
                    chips={rankChips}
                    multiple
                  />
                </CollapsableRadioButton>
                <CollapsableRadioButton
                  selectedValue="isStatus"
                  testId="validation-is-status-radio"
                  label="Status is"
                  {...props}
                >
                  <FormChipGrid
                    name="isStatus"
                    testId="validation-is-status-chip-grid"
                    stylingPreset={ChipGridStylingPreset.BASIC_GRID}
                    chips={statusChips}
                    multiple
                  />
                </CollapsableRadioButton>
                <RadioButton
                  label="Is unvalidated"
                  selectedValue="isUnvalidated"
                  testId="validation-is-unvalidated-radio"
                  {...props}
                />
              </>
            )}
          </RadioGroup>
        </div>
        <FilterModalButtons onCancel={onClose} testIdPrefix="validation" />
      </form>
    </FormProvider>
  )
}

// Seperated from form component to make sure popover unmount causes state reset
export const ValidationFilterModal = ({
  open,
  onClose,
  anchorEl,
  ...props
}: FilterModalProps & ValidationFilterFormProps) => (
  <FilterModal anchorEl={anchorEl} open={open} onClose={onClose}>
    <ValidationFilterForm onClose={onClose} {...props} />
  </FilterModal>
)
