import {match} from "ts-pattern"
import type {z} from "zod"

export const materialErrorMap: z.ZodErrorMap = (error, ctx) =>
  match(error)
    .with({code: "invalid_type"}, (error) => {
      if (error.path.includes("accessionOption") && error.received === "null") {
        return {message: "Please select an accession"}
      }
      if (error.path.includes("latitude") && error.received === "null") {
        return {message: "Please input a valid latitude"}
      }
      if (error.path.includes("longitude") && error.received === "null") {
        return {message: "Please input a valid longitude"}
      }
      return {message: ctx.defaultError}
    })
    .otherwise(() => {
      return {message: ctx.defaultError}
    })
