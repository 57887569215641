import {Badge} from "@hortis/ui/badge"
import * as RA from "fp-ts/ReadonlyArray"
import * as R from "fp-ts/Record"
import {SemigroupSum} from "fp-ts/number"
import type {ReactElement} from "react"
import {materialStatusLabelMap} from "src/utils/label-maps/material-status"
import {PlantMaterialStatus} from "../../../generated/graphql"
import {StatusChip} from "../status-chip"

const countStatuses = R.fromFoldableMap(SemigroupSum, RA.Foldable)

const incrementStatus = (
  status: PlantMaterialStatus,
): [PlantMaterialStatus, number] => [status, 1]

const tallyStatuses = (statuses: ReadonlyArray<PlantMaterialStatus>) =>
  countStatuses(statuses, incrementStatus)

const statusOrder = [
  PlantMaterialStatus.Present,
  PlantMaterialStatus.Transient,
  PlantMaterialStatus.Unknown,
  PlantMaterialStatus.Absent,
]

export interface DerivedStatusProps {
  statuses: ReadonlyArray<PlantMaterialStatus>
  fullLabels?: boolean
}

export const DerivedStatus = ({
  statuses,
  fullLabels = false,
}: DerivedStatusProps): ReactElement => {
  const statusTally = tallyStatuses(statuses)

  return (
    <div className="flex gap-1">
      {statuses.length > 0 ? (
        statusOrder.map((status) =>
          statusTally[status] === undefined ? null : (
            <StatusChip
              key={status}
              status={status}
              data-cy={`derived-status-chip`}
              label={`${String(statusTally[status])}${
                fullLabels ? ` ${materialStatusLabelMap[status]}` : ""
              }`}
              statusAsTooltip={!fullLabels}
            />
          ),
        )
      ) : (
        <Badge color="grey">No material</Badge>
      )}
    </div>
  )
}
