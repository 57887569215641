import {Button} from "@hortis/ui/button"
import {File6, Tag1} from "@hortis/ui/icons"
import {IUCNRedListTag} from "@hortis/ui/iucn-red-list-tag"
import {DialogTrigger} from "@radix-ui/react-dialog"
import {createColumnHelper} from "@tanstack/react-table"
import {TaxonomicRank, type TaxaListFieldsFragment} from "generated/graphql"
import {RestoreTaxonDialog} from "src/components/dialog/variants/restore-taxon"
import {NameValidationPopover} from "src/components/popover/name-validation-popover"
import {stopPropagation} from "src/features/collection/components/stop-event-propagation"
import {DataTableCheckboxCell} from "../data-table-checkbox-cell"
import {DataTableCheckboxHeader} from "../data-table-checkbox-header"
import {DataTableColumnHeader} from "../data-table-column-header"
import {DataTableMemberCell} from "../data-table-member-cell"
import {DataTableNullableCell} from "../data-table-nullable-cell"
import {DataTablePreviewCell} from "../data-table-preview-cell"
import {DataTableTagsCell} from "../data-table-tags-cell"

const columnHelper = createColumnHelper<TaxaListFieldsFragment>()

export type TaxaColumnIds =
  | "Scientific name"
  | "Common name"
  | "Authorship"
  | "Validation"
  | "Family"
  | "IUCN Red List"
  | "Native distribution"
  | "Life forms"
  | "Climate"
  | "Taxon tags"
  | "Notes"
  | "Creator"
  | "Last editor"
  | "Last edited"

const baseTaxaColumns = [
  columnHelper.accessor("authorship", {
    id: "Authorship" satisfies TaxaColumnIds,
    header: ({column}) => <DataTableColumnHeader column={column} />,
    cell: ({getValue, row}) => {
      const value = getValue()
      const sharedAuthorship =
        row.original.sharedScientificName?.rank === TaxonomicRank.Genus
          ? null
          : row.original.sharedScientificName?.authorship
      return <DataTableNullableCell value={value ?? sharedAuthorship} />
    },
    minSize: 164,
    maxSize: 344,
  }),
  columnHelper.accessor("commonName", {
    id: "Common name" satisfies TaxaColumnIds,
    header: ({column}) => <DataTableColumnHeader column={column} />,
    cell: ({getValue}) => <DataTableNullableCell value={getValue()} />,
    minSize: 164,
    maxSize: 344,
  }),
  columnHelper.accessor("sharedScientificName", {
    id: "Validation" satisfies TaxaColumnIds,
    header: ({column}) => <DataTableColumnHeader column={column} />,
    cell: ({getValue, row}) => {
      const sharedScientificName = getValue()
      const fullyValidated = row.original.fullyValidated
      return (
        <DataTableNullableCell value={sharedScientificName}>
          {sharedScientificName == null ? null : (
            <NameValidationPopover
              sharedScientificName={sharedScientificName}
              fullyValidated={fullyValidated}
            />
          )}
        </DataTableNullableCell>
      )
    },
    enableSorting: false,
    minSize: 164,
    maxSize: 344,
  }),
  columnHelper.accessor("sharedScientificName.classification.family", {
    id: "Family" satisfies TaxaColumnIds,
    header: ({column}) => <DataTableColumnHeader column={column} />,
    cell: ({getValue}) => <DataTableNullableCell value={getValue()} />,
    minSize: 164,
    maxSize: 344,
  }),
  // eslint-disable-next-line no-secrets/no-secrets -- not a secret
  columnHelper.accessor("sharedScientificName.taxon.globalRedListStatus", {
    id: "IUCN Red List" satisfies TaxaColumnIds,
    header: ({column}) => <DataTableColumnHeader column={column} />,
    cell: ({getValue, row}) => {
      const fullyValidated = row.original.fullyValidated
      const value = getValue()
      return (
        <DataTableNullableCell value={fullyValidated ? value : null}>
          {value == null ? null : <IUCNRedListTag status={value} />}
        </DataTableNullableCell>
      )
    },
    minSize: 164,
    maxSize: 344,
  }),
  columnHelper.accessor("nativeDistribution", {
    id: "Native distribution" satisfies TaxaColumnIds,
    header: ({column}) => <DataTableColumnHeader column={column} />,
    cell: ({getValue, row, table}) => {
      const fullyValidated = row.original.fullyValidated
      const sharedNativeDistribution =
        row.original.sharedScientificName?.taxon?.nativeDistribution
      const interpretationEnabled =
        table.options.meta?.flags?.["taxaInterpretationEnabled"]

      const value = getValue()
      return (
        <DataTableNullableCell
          value={
            value == null || interpretationEnabled !== true
              ? fullyValidated
                ? sharedNativeDistribution
                : null
              : value
          }
        />
      )
    },
    minSize: 164,
    maxSize: 344,
    enableSorting: false,
  }),
  columnHelper.accessor("sharedScientificName.taxon.lifeForms", {
    id: "Life forms" satisfies TaxaColumnIds,
    header: ({column}) => <DataTableColumnHeader column={column} />,
    cell: ({getValue, row}) => {
      const fullyValidated = row.original.fullyValidated
      const value = getValue()
      const nodes = value?.nodes
      return (
        <DataTableNullableCell
          value={
            fullyValidated && nodes != null && nodes.length > 0
              ? nodes.map((lifeForm) => lifeForm.name).join(", ")
              : null
          }
        />
      )
    },
    minSize: 164,
    maxSize: 344,
    enableSorting: false,
  }),
  columnHelper.accessor("sharedScientificName.taxon.climate", {
    id: "Climate" satisfies TaxaColumnIds,
    header: ({column}) => <DataTableColumnHeader column={column} />,
    cell: ({getValue, row}) => {
      const fullyValidated = row.original.fullyValidated
      return (
        <DataTableNullableCell value={fullyValidated ? getValue() : null} />
      )
    },
    minSize: 164,
    maxSize: 344,
    enableSorting: false,
  }),
  columnHelper.accessor("tagsConnection.nodes", {
    id: "Taxon tags" satisfies TaxaColumnIds,
    header: ({column}) => <DataTableColumnHeader column={column} />,
    cell: ({getValue}) => (
      <DataTableNullableCell value={getValue()}>
        <DataTablePreviewCell
          title="Tags"
          icon={Tag1}
          popoverContent={<DataTableTagsCell tags={getValue()} wrap />}
        >
          <DataTableTagsCell tags={getValue()} />
        </DataTablePreviewCell>
      </DataTableNullableCell>
    ),
    enableSorting: false,
    minSize: 140,
    maxSize: 280,
  }),
  columnHelper.accessor("createdBy", {
    id: "Creator" satisfies TaxaColumnIds,
    header: ({column}) => <DataTableColumnHeader column={column} />,
    cell: ({getValue}) => <DataTableMemberCell member={getValue()} />,
    enableSorting: false,
    minSize: 128,
    maxSize: 178,
  }),
  columnHelper.accessor("updatedBy", {
    id: "Last editor" satisfies TaxaColumnIds,
    header: ({column}) => <DataTableColumnHeader column={column} />,
    cell: ({getValue}) => <DataTableMemberCell member={getValue()} />,
    enableSorting: false,
    minSize: 128,
    maxSize: 178,
  }),
  columnHelper.accessor("updatedAt", {
    id: "Last edited" satisfies TaxaColumnIds,
    header: ({column}) => <DataTableColumnHeader column={column} />,
    cell: ({getValue, table}) =>
      table.options.meta?.formatDateTime(getValue()) ?? "-",
    enableColumnFilter: false,
    minSize: 128,
    maxSize: 178,
  }),
  columnHelper.accessor("notes", {
    id: "Notes" satisfies TaxaColumnIds,
    header: ({column}) => <DataTableColumnHeader column={column} />,
    cell: ({getValue}) => (
      <DataTableNullableCell value={getValue()}>
        <DataTablePreviewCell value={getValue()} title="Notes" icon={File6} />
      </DataTableNullableCell>
    ),
    minSize: 100,
    enableSorting: false,
  }),
]

export const taxaColumns = [
  columnHelper.accessor("scientificName", {
    id: "Scientific name" satisfies TaxaColumnIds,
    header: ({table, column}) => (
      <DataTableCheckboxHeader table={table}>
        <DataTableColumnHeader column={column} />
      </DataTableCheckboxHeader>
    ),
    cell: ({row, table, getValue}) => (
      <DataTableCheckboxCell table={table} row={row}>
        <div className="font-medium text-grey-900" data-cy="scientific-name">
          {getValue()}
        </div>
      </DataTableCheckboxCell>
    ),
    meta: {
      sticky: true,
    },
    minSize: 164,
    maxSize: 344,
    enableHiding: false,
    enableColumnFilter: false,
  }),
  ...baseTaxaColumns,
]

export const trashTaxaColumns = [
  columnHelper.accessor("id", {
    header: () => null,
    cell: ({getValue}) => {
      const id = getValue()
      return (
        <RestoreTaxonDialog id={id}>
          <DialogTrigger asChild onClick={stopPropagation}>
            <Button
              variant="linkcolor"
              size="sm"
              className="w-full"
              testId="restore-taxon-button"
            >
              Restore
            </Button>
          </DialogTrigger>
        </RestoreTaxonDialog>
      )
    },

    minSize: 104,
    maxSize: 104,
    meta: {
      sticky: true,
    },
    enableHiding: false,
    enableColumnFilter: false,
  }),
  columnHelper.accessor("scientificName", {
    id: "Scientific name" satisfies TaxaColumnIds,
    header: ({column}) => <DataTableColumnHeader column={column} />,
    cell: ({getValue}) => <DataTableNullableCell value={getValue()} />,
    minSize: 164,
    maxSize: 344,
    enableHiding: false,
    enableColumnFilter: false,
    meta: {
      className: "font-medium text-gray-900",
    },
  }),
  ...baseTaxaColumns,
]
