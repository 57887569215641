import type * as Monoid from "fp-ts/Monoid"
import * as A from "fp-ts/Array"

export const getExpandingArrayMonoid = <A>(
  monoid: Monoid.Monoid<A>,
): Monoid.Monoid<Array<A>> => ({
  concat: (first: Array<A>, second: Array<A>) =>
    A.zipWith(
      [
        ...first,
        ...A.replicate(Math.max(0, second.length - first.length), monoid.empty),
      ],
      [
        ...second,
        ...A.replicate(Math.max(0, first.length - second.length), monoid.empty),
      ],
      monoid.concat,
    ),
  empty: [],
})

/**
 * Based on Array.isArray
 * Works around @see {@link https://github.com/microsoft/TypeScript/issues/17002}
 */
export const isReadonlyArray = <A, B>(
  arg: ReadonlyArray<A> | B,
): arg is ReadonlyArray<A> => Array.isArray(arg)
