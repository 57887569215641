import {zodResolver} from "@hookform/resolvers/zod"
import * as A from "fp-ts/Array"
import {useMemo} from "react"
import {FormProvider} from "react-hook-form"
import {match} from "ts-pattern"
import {z} from "zod"
import {FilterTitle} from "src/features/filters/components/modal/filter-title"
import {CollapsableRadioButton, RadioGroup} from "src/components/radio"
import {ChipGridStylingPreset, FormChipGrid} from "src/components/chip-grid"
import {PlantMaterialStatus} from "generated/graphql"
import type {PlantMaterialFilter} from "generated/graphql"
import {statusChips} from "../../../create-accession/components/add-plant-material-form/plant-material-data-fields"
import type {FilterModalProps} from "../../components/modal/filter-modal"
import {FilterModal} from "../../components/modal/filter-modal"
import {FilterModalButtons} from "../../components/modal/modal-buttons"
import {FilterModalError} from "../../components/modal/modal-error"
import {determineInitialOperation} from "../modals/determine-initial-operation"
import type {FilterFormProps} from "../modals/types"
import {useDispatchFilter} from "../modals/use-dispatch-filter"
import {useFilterForm} from "../../components/modal/use-filter-form"

const createDefaultValues = (
  initialValue?: NonNullable<PlantMaterialFilter["status"]>,
) => {
  const defaultValues = {
    is: initialValue?.is == null ? [] : [...initialValue.is],
    isNot: initialValue?.isNot ? [...initialValue.isNot] : [],
  }
  return {
    defaultValues,
    initialOperation: determineInitialOperation(defaultValues, "is"),
  }
}

const plantMaterialStatus = z.nativeEnum(PlantMaterialStatus)
const resolver = zodResolver(
  z.object({
    is: z.array(plantMaterialStatus),
    isNot: z.array(plantMaterialStatus),
  }),
)

export const StatusFilterForm = ({
  initialValue,
  onClose,
  dispatchFilters,
}: FilterFormProps) => {
  const {error, setError, operation, handleRadioChange, formMethods} =
    useFilterForm({
      resolver,
      ...createDefaultValues(initialValue?.status ?? undefined),
    })
  const {addModifyFilter, deleteFilter} = useDispatchFilter({
    dispatchFilters,
    id: initialValue?.id,
    onClose,
  })

  const onSubmit = useMemo(
    () =>
      formMethods.handleSubmit((values) => {
        match(operation)
          .with("is", () => {
            if (A.isNonEmpty(values.is)) {
              addModifyFilter({
                status: {
                  is: values.is,
                },
              })
              onClose()
            } else {
              setError("Select a status to continue")
            }
          })
          .with("isNot", () => {
            if (A.isNonEmpty(values.isNot)) {
              addModifyFilter({
                status: {
                  isNot: values.isNot,
                },
              })
              onClose()
            } else {
              setError("Select a status to continue")
            }
          })
          .run()
      }),
    [formMethods, operation, onClose, addModifyFilter, setError],
  )

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={onSubmit}>
        <div className="mb-4 flex flex-col gap-4">
          <FilterTitle title="Status" deleteFilter={deleteFilter} />
          <FilterModalError error={error} />
          <RadioGroup
            value={operation}
            onChange={handleRadioChange}
            label="Operation"
          >
            {(props) => (
              <>
                <CollapsableRadioButton
                  selectedValue="is"
                  testId="status-is-radio"
                  label="Is"
                  {...props}
                >
                  <FormChipGrid
                    name="is"
                    stylingPreset={ChipGridStylingPreset.FLEX_WRAP}
                    testId="status-is-chip-grid"
                    chips={statusChips}
                    multiple
                    sx={{
                      gridTemplateColumns: `repeat(auto-fit, minmax(125px, 1fr))`,
                    }}
                  />
                </CollapsableRadioButton>
                <CollapsableRadioButton
                  selectedValue="isNot"
                  testId="status-is-not-radio"
                  label="Is not"
                  {...props}
                >
                  <FormChipGrid
                    name="isNot"
                    stylingPreset={ChipGridStylingPreset.FLEX_WRAP}
                    testId="status-is-not-chip-grid"
                    chips={statusChips}
                    multiple
                    sx={{
                      gridTemplateColumns: `repeat(auto-fit, minmax(125px, 1fr))`,
                    }}
                  />
                </CollapsableRadioButton>
              </>
            )}
          </RadioGroup>
        </div>
        <FilterModalButtons onCancel={onClose} testIdPrefix="status" />
      </form>
    </FormProvider>
  )
}

// Seperated from form component to make sure popover unmount causes state reset
export const StatusFilterModal = ({
  open,
  onClose,
  anchorEl,
  ...props
}: FilterFormProps & FilterModalProps) => (
  <FilterModal anchorEl={anchorEl} open={open} onClose={onClose}>
    <StatusFilterForm onClose={onClose} {...props} />
  </FilterModal>
)
