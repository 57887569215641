import {Fragment} from "react"
import {FilterChip} from "src/features/filters/filter-chip-group"
import type {OpenFilterArgs} from "../../plant-materials/filters"
import type {AccessionFilterType, LocalAccessionFilter} from "../types"
import {extractMaterialReceivedData} from "./extract-material-received-filter"

export const MaterialReceivedFilterChipGroup = ({
  id,
  filter,
  hidden,
  ...props
}: {
  id: string
  handleOpenFilter: (args: OpenFilterArgs<AccessionFilterType>) => void
  handleDelete: (id: string) => void
  filter: LocalAccessionFilter["materialType"]
  hidden?: boolean
}) =>
  filter == null || hidden === true ? (
    <Fragment />
  ) : (
    <>
      {filter.is != null && filter.is.length > 0 && (
        <FilterChip
          filterLabel="Material received"
          filterId={id}
          filterType="materialReceived"
          filterData={extractMaterialReceivedData(filter, "is")}
          operation="is"
          {...props}
        />
      )}
      {filter.isNot != null && filter.isNot.length > 0 && (
        <FilterChip
          filterLabel="Material received"
          filterId={id}
          filterType="materialReceived"
          filterData={extractMaterialReceivedData(filter, "isNot")}
          operation="is not"
          {...props}
        />
      )}
    </>
  )
