import {keyframes} from "@emotion/react"
import {forwardRef} from "react"
import * as PopoverPrimitive from "@radix-ui/react-popover"
import {twMerge} from "tailwind-merge"

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`
const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.8);
  }
`

const Popover = PopoverPrimitive.Root

const PopoverTrigger = PopoverPrimitive.Trigger

const PopoverContent = forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content>
>(({className, align = "center", sideOffset = 4, ...props}, ref) => (
  <PopoverPrimitive.Portal>
    <PopoverPrimitive.Content
      ref={ref}
      align={align}
      sideOffset={sideOffset}
      className={twMerge(
        "w-fit origin-[var(--radix-popover-content-transform-origin)] rounded-md border border-grey-200 bg-white shadow-lg",
        className,
      )}
      css={{
        '&[data-state="open"]': {animation: `0.1s ${fadeIn} ease`},
        '&[data-state="closed"]': {animation: `0.1s ${fadeOut} ease`},
      }}
      {...props}
    />
  </PopoverPrimitive.Portal>
))

// @ts-expect-error -- Reclared forwardRef does not have a displayName
PopoverContent.displayName = PopoverPrimitive.Content.displayName

export {Popover, PopoverTrigger, PopoverContent}
