import {Fragment} from "react"
import {useWatch} from "react-hook-form"
import {FormControl} from "../../../components/form-control"
import {FormLabel} from "../../../components/form-label"
import {fromSxValues} from "../../../utils/sx"

interface LabeledSwitchProps {
  primaryLabel: string
  secondaryLabel?: string
  name?: string
}

const formLabelStyle = fromSxValues({position: "relative", flex: 1})

export const LabeledSwitch = ({
  primaryLabel,
  secondaryLabel,
  name,
}: LabeledSwitchProps): JSX.Element => {
  const value = useWatch<Record<string, string | null>>({name: name ?? ""})

  return value == null ? (
    <Fragment />
  ) : (
    <div>
      <FormControl fullWidth>
        <FormLabel htmlFor="accession-number" sx={formLabelStyle}>
          <div className="flex flex-col gap-1.5">
            <div className="text-sm font-medium text-grey-700">
              {primaryLabel}
            </div>
            <div className="text-grey-500">
              {secondaryLabel != null || name == null
                ? secondaryLabel
                : `${value}`}{" "}
              (Automatic)
            </div>
          </div>
        </FormLabel>
      </FormControl>
    </div>
  )
}
