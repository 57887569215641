import type {Property} from "csstype"
import * as E from "fp-ts/Either"
import * as O from "fp-ts/Option"
import {pipe} from "fp-ts/function"
import {useCallback, useRef, useState} from "react"
import type {MapLayerMouseEvent} from "react-map-gl"
import type {
  MapMode,
  MaterialDetailsProps,
} from "../../../features/collection-map/components/types"
import * as OE from "../../../utils/option-either"
import {useThemedLayer} from "../theme-layer-hook"
import {useMaplibre} from "../utils"
import type {CoordinateMenuProps} from "./coordinate-menu"
import {CoordinatesFromLngLat} from "./lng-lat"
import {
  themedClusterCountLayer,
  themedClusterLayer,
  themedUnclusteredPointLayer,
} from "./material-cluster-layers"
import type {MaterialPopupProps} from "./material-popup"
import {decodeProperties, getFeaturePoint} from "./utils"
import {isCluster, zoomOnCluster} from "./zoom-on-cluster"

interface useClustersProps {
  mapId: string
  onPointClick?: (materialDetailsProps: MaterialDetailsProps) => void
  closeMaterialDetails?: () => void
  coordinateMenuProps?: CoordinateMenuProps | null
  openCoordinateMenu?: (props: CoordinateMenuProps) => void
  closeCoordinateMenu?: () => void
  mode?: MapMode
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {}

export const useClusters = ({
  mapId,
  onPointClick,
  closeMaterialDetails,
  coordinateMenuProps,
  openCoordinateMenu,
  closeCoordinateMenu,
  mode = "view",
}: // eslint-disable-next-line sonarjs/cognitive-complexity
useClustersProps) => {
  const {[mapId]: ourMap} = useMaplibre()
  const popupHover = useRef(false)
  const hoveredPointId = useRef<string | number | undefined>(undefined)
  // const [popupHover, setPopupHover] = useState(false)
  const [popupProps, setPopupProps] = useState<Pick<
    MaterialPopupProps,
    | "scientificName"
    | "qualifier"
    | "accessionNumber"
    | "latitude"
    | "longitude"
  > | null>(null)

  const [cursor, setCursor] = useState<Property.Cursor>("auto")

  const handleClick = useCallback(
    (event: MapLayerMouseEvent) => {
      if (mode !== "view") {
        return
      }
      const point = getFeaturePoint(event)
      if (point != null) {
        closeCoordinateMenu?.()
        if (isCluster(point)) {
          void zoomOnCluster(ourMap, point)
        }

        if (onPointClick != null) {
          onPointClick(
            pipe(
              E.Do,
              E.apS("properties", decodeProperties(point.properties)),
              E.map(({properties: {accessionNumber, qualifier}}) => ({
                accessionNumber,
                materialQualifier: qualifier,
              })),
              // TODO: is swallowing the error here a good idea? Should this be surfaced to `onPointClick`?
              E.getOrElseW(() => undefined),
            ),
          )
        }
        // eslint-disable-next-line unicorn/no-negated-condition
      } else if (coordinateMenuProps != null) {
        closeCoordinateMenu?.()
      } else {
        openCoordinateMenu?.({
          latitude: event.lngLat.lat,
          longitude: event.lngLat.lng,
        })
        if (closeMaterialDetails != null) {
          closeMaterialDetails()
        }
      }
    },
    [
      onPointClick,
      ourMap,
      coordinateMenuProps,
      closeCoordinateMenu,
      openCoordinateMenu,
      closeMaterialDetails,
      mode,
    ],
  )

  const onMouseEnter = useCallback(() => {
    setCursor("pointer")
  }, [])

  const onMouseMove = useCallback(
    (event: MapLayerMouseEvent) => {
      // eslint-disable-next-line sonarjs/no-duplicate-string
      if (ourMap?.getLayer("unclustered-point") != null) {
        if (hoveredPointId.current != null) {
          ourMap.setFeatureState(
            {source: "geometry", id: hoveredPointId.current},
            {hover: false},
          )
        }
        const features = ourMap.queryRenderedFeatures(
          [event.point.x, event.point.y],
          {
            layers: ["unclustered-point"],
          },
        )
        const point = getFeaturePoint({features})

        if (point == null) {
          setPopupProps(null)
          return
        }

        pipe(
          OE.Do,
          OE.apS(
            "properties",
            OE.fromOption(O.fromEither(decodeProperties(point.properties))),
          ),
          OE.apSW(
            "coordinates",
            OE.fromEither(
              CoordinatesFromLngLat.decode(point.geometry.coordinates),
            ),
          ),
          OE.map(
            ({
              coordinates: {latitude, longitude},
              properties: {scientificName, accessionNumber, qualifier},
            }) => ({
              longitude,
              latitude,
              scientificName,
              accessionNumber,
              qualifier,
            }),
          ),
          OE.match(
            () => noop,
            (error) => () => {
              // eslint-disable-next-line no-console -- TODO: does this indicate a larger problem? should this be reported to bugsnag?
              console.warn("Error during mouse enter", error)
            },
            (info) => () => {
              hoveredPointId.current = point.id
              // Conditions required to prevent re-render on every mouse move event
              if (JSON.stringify(info) !== JSON.stringify(popupProps)) {
                setPopupProps(info)
              }
              ourMap.setFeatureState(
                {source: "geometry", id: point.id},
                {hover: true},
              )
            },
          ),
        )()
      }
    },
    [ourMap, popupProps],
  )

  const onMouseLeave = useCallback(() => {
    if (
      ourMap?.getLayer("unclustered-point") != null &&
      hoveredPointId.current != null
    ) {
      ourMap.setFeatureState(
        {source: "geometry", id: hoveredPointId.current},
        {hover: false},
      )
      hoveredPointId.current = undefined
    }
    setCursor("auto")
  }, [ourMap])

  const layerOpacity = mode === "editMaterial" ? 0.5 : undefined

  const clusterLayer = useThemedLayer(
    themedClusterLayer,
    "cluster-layer",
    layerOpacity,
  )
  const clusterCountLayer = useThemedLayer(
    themedClusterCountLayer,
    "cluster-count-layer",
    layerOpacity,
  )
  const unclusteredPointLayer = useThemedLayer(
    themedUnclusteredPointLayer,
    "unclustered-point",
    layerOpacity,
  )

  return {
    popupProps,
    handleClick,
    clusterLayer,
    clusterCountLayer,
    unclusteredPointLayer,
    cursor,
    onMouseMove,
    onMouseLeave,
    onMouseEnter,
    popupHover,
    hoveredPointId,
  }
}
