import type {Interpolation, Theme} from "@emotion/react"
import {A, D, pipe} from "@mobily/ts-belt"
import type {CSSProperties} from "react"
import {mediaBp} from "../../utils/breakpoints"
import type {ButtonSize, ResponsiveButtonSize} from "./types"

export const buildResponsiveSizeStyle = (
  sizes: ResponsiveButtonSize | ButtonSize,
  sizeStyles: Record<ButtonSize, Interpolation<Theme>>,
) =>
  typeof sizes === "string"
    ? sizeStyles[sizes]
    : (pipe(
        sizes,
        D.mapWithKey((bp, size) =>
          size == null ? {} : {[mediaBp[bp]]: sizeStyles[size]},
        ),
        D.values,
        (values) =>
          values.length > 0 ? A.reduce(values, {}, D.merge) : sizeStyles["md"],
      ) as Interpolation<Theme>)

export const buildResponsiveSpinnerSizeStyle = (
  sizes: ResponsiveButtonSize | ButtonSize,
  sizeStyles: Record<ButtonSize, CSSProperties>,
) =>
  typeof sizes === "string"
    ? sizeStyles[sizes]
    : (pipe(
        sizes,
        D.mapWithKey((bp, size) =>
          size == null ? {} : {[bp]: sizeStyles[size]},
        ),
        D.values,
        (values) =>
          values.length > 0 ? A.reduce(values, {}, D.merge) : sizeStyles["md"],
      ) as CSSProperties)
