import {Button} from "@hortis/ui/button"
import {
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@hortis/ui/dialog"
import {useRestoreMaterialMutation} from "generated/graphql"
import {type MouseEventHandler} from "react"
import {useSnackbarStore} from "src/components/snackbar-controller/snackbar-store"
import {stopPropagation} from "src/features/collection/components/stop-event-propagation"
import {onFailure, onSuccess} from "src/notification-snack-utils"

export const RestoreMaterialDialogContent = ({
  id,
  onSuccess: onSuccessProp,
}: {
  id: string
  onSuccess?: () => void
}) => {
  const {setSnack} = useSnackbarStore()
  const [{fetching}, restoreMaterial] = useRestoreMaterialMutation()

  const handleSubmit: MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.stopPropagation()
    const restoreArchiveResult = await restoreMaterial({
      materialId: id,
    })

    if (
      restoreArchiveResult.data?.restorePlantMaterial?.__typename !==
      "PlantMaterial"
    ) {
      onFailure(setSnack)(new Error("Failed to restore plant material"))
      return
    }

    onSuccess(setSnack)("Successfully restored plant material")
    onSuccessProp?.()
  }

  return (
    <DialogContent data-cy="restore-material-dialog">
      <DialogHeader>
        <DialogTitle data-cy="restore-material-dialog-title">
          Restore plant material?
        </DialogTitle>
        <DialogDescription data-cy="restore-material-dialog-body">
          This will restore 1 plant material record.
        </DialogDescription>
      </DialogHeader>
      <DialogFooter>
        <DialogClose onClick={stopPropagation} asChild>
          <Button size="lg" fullWidth testId="restore-material-cancel">
            Cancel
          </Button>
        </DialogClose>
        <Button
          size="lg"
          onClick={handleSubmit}
          variant="primary"
          loading={fetching}
          fullWidth
          testId="restore-material-confirm"
        >
          Restore
        </Button>
      </DialogFooter>
    </DialogContent>
  )
}
