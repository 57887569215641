import match from "autosuggest-highlight/match"
import parse from "autosuggest-highlight/parse"
import {useGetOrgDonorsQuery, useGetSiteDonorsQuery} from "generated/graphql"
import {
  Fragment,
  useCallback,
  useState,
  type HTMLAttributes,
  type SyntheticEvent,
} from "react"
import {FormAutocomplete} from "src/components/autocomplete"
import {useControlledDebounce} from "src/utils/hooks/debounce"
import {usePlaceStruct} from "src/utils/hooks/place"
import {twMerge} from "tailwind-merge"

interface DonorFieldAutocompleteProps {
  name: string
  testId?: string
  id: string
  onInputChange: (_: SyntheticEvent, newValue: string) => void
  loading?: boolean
  label: string | null
  options: ReadonlyArray<string>
}

const isOptionEqual = (option: string, value: string) => option === value

const renderAutoCompleteItem = (
  props: HTMLAttributes<HTMLLIElement> & {"data-option-index"?: number},
  option: string | null,
  {inputValue}: {inputValue: string},
) => {
  if (option == null) {
    return <Fragment />
  }
  const matches = match(option, inputValue, {
    insideWords: true,
    findAllOccurrences: true,
  })

  const {...rest} = props
  const parts = parse(option, matches)

  return (
    <li
      {...rest}
      key={JSON.stringify(parts)}
      style={{padding: 0}}
      data-cy="donor-option"
    >
      <div className={"flex flex-1 items-center gap-2 px-[14px] py-2.5"}>
        <div color="text.primary" className="text-sm">
          {parts.map((part, index) => (
            <span
              // eslint-disable-next-line react/no-array-index-key
              key={`${index}-${part.text}}`}
              className={twMerge(part.highlight && "font-semibold")}
            >
              {part.text}
            </span>
          ))}
        </div>
      </div>
    </li>
  )
}

export const DonorFieldAutocomplete = ({
  id,
  name,
  testId,
  onInputChange,
  loading,
  options,
  label,
}: DonorFieldAutocompleteProps) => {
  return (
    <FormAutocomplete
      id={id}
      renderOption={renderAutoCompleteItem}
      freeSolo
      openOnFocus
      required={false}
      options={options}
      label={label}
      loading={loading}
      onInputChange={onInputChange}
      isOptionEqualToValue={isOptionEqual}
      fullWidth
      testId={testId}
      name={name}
      sx={{
        "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
          paddingRight: "8px !important",
        },
      }}
      componentsProps={{popper: {disablePortal: true}}}
    />
  )
}

export const OrgDonorFieldAutocomplete = ({
  name,
  testId,
  id,
  label,
}: {
  name: string
  id: string
  testId?: string
  label: string | null
}) => {
  const [inputValue, setInputValue] = useState("")
  const place = usePlaceStruct()

  const [{data: donorsData, fetching: donorsFetching}] = useGetOrgDonorsQuery({
    variables: {
      organisationSubdomain: place.data?.orgName ?? "",
      searchTerm: inputValue,
      last: 30,
    },
  })

  const inputChange = useControlledDebounce(
    (newValue: string) => {
      setInputValue(newValue)
    },
    {delay: 100},
  )

  const onInputChange = useCallback(
    (_: SyntheticEvent, newValue: string) => {
      inputChange.schedule(newValue)
    },
    [inputChange],
  )

  const availableDonorOptions =
    donorsData?.org?.donors?.nodes.map(({name}) => name) ?? []

  return (
    <DonorFieldAutocomplete
      name={name}
      testId={testId}
      id={id}
      label={label}
      onInputChange={onInputChange}
      loading={donorsFetching && availableDonorOptions.length === 0}
      options={availableDonorOptions}
    />
  )
}
export const SiteDonorFieldAutocomplete = ({
  name,
  testId,
  id,
}: {
  name: string
  id: string
  testId?: string
}) => {
  const place = usePlaceStruct()
  const [inputValue, setInputValue] = useState("")

  const [{data: donorsData, fetching: donorsFetching}] = useGetSiteDonorsQuery({
    variables: {
      collectionSiteSlug: place.data?.siteSlug ?? "",
      organisationSubdomain: place.data?.orgName ?? "",
      searchTerm: inputValue,
      last: 30,
    },
  })

  const inputChange = useControlledDebounce(
    (newValue: string) => {
      setInputValue(newValue)
    },
    {delay: 100},
  )

  const onInputChange = useCallback(
    (_: SyntheticEvent, newValue: string) => {
      inputChange.schedule(newValue)
    },
    [inputChange],
  )

  const availableDonorOptions =
    donorsData?.org?.site?.donors?.nodes.map(({name}) => name) ?? []

  return (
    <DonorFieldAutocomplete
      label={null}
      name={name}
      testId={testId}
      id={id}
      onInputChange={onInputChange}
      loading={donorsFetching && availableDonorOptions.length === 0}
      options={availableDonorOptions}
    />
  )
}
