import {MaterialQualifierFormat} from "../../../generated/graphql"
import type {PositiveInteger} from "../positive-integer"
import * as BijectiveBase26 from "./bijective-base-26"
import * as Numeric99 from "./numeric-99"

export const createMaterialQualifier = (
  num: PositiveInteger,
  format: MaterialQualifierFormat,
) =>
  format === MaterialQualifierFormat.BijectiveBase_26
    ? BijectiveBase26.fromPositiveInteger(num)
    : Numeric99.fromPositiveInteger(num)
