import type {ClassificationFilter} from "generated/graphql"
import {countStringComparator} from "../count/comparators/string"

export const countClassificationFilter = (filter: ClassificationFilter) => {
  let count = 0
  for (const classificationFilter of Object.values(filter)) {
    if (classificationFilter != null) {
      count += countStringComparator(classificationFilter)
    }
  }
  return count
}
