import {zodResolver} from "@hookform/resolvers/zod"
import {Button} from "@hortis/ui/button"
import {
  SheetBody,
  SheetFooter,
  SheetForm,
  SheetHeader,
  SheetTitle,
} from "@hortis/ui/sheet"
import * as E from "fp-ts/Either"
import * as TE from "fp-ts/TaskEither"
import {pipe} from "fp-ts/function"
import {useEffect, useMemo} from "react"
import {FormProvider, useForm} from "react-hook-form"
import type {AccessionDataUpdatesZod} from "src/features/create-accession/components/accession-data-form/schema"
import {AccessionDataUpdateSchemaZod} from "src/features/create-accession/components/accession-data-form/schema"
import {mapIdentificationDetails} from "src/features/create-accession/utils/process-form-data"
import {type Nullable} from "src/types/nullable"
import {parseUtcDate} from "src/utils/dates/parse-utc-date"
import {cleanFormData} from "src/utils/forms"
import {mapTagsToIds} from "src/utils/forms/map-tags-to-ids"
import {mapTaxonOptionToId} from "src/utils/forms/map-taxon-option-to-id"
import {
  type IdentificationQualifier,
  type IdentificationVerificationStatus,
} from "src/utils/label-maps/identification"
import {type DeepNullable} from "ts-essentials"
import type {AccessionFieldsFragment} from "../../../../../../../generated/graphql"
import {useSnackbarStore} from "../../../../../../components/snackbar-controller/snackbar-store"
import * as OE from "../../../../../../utils/option-either"
import {useAccessToken} from "../../../../../../utils/use-access-token"
import {AccessionDataFields} from "../../../../../create-accession/components/accession-data-form/accession-data-fields"
import {showErrorMessage} from "../../../../../create-accession/components/create-accession/utils"
import {updateAccessionData} from "../../../fetchers"
import type {BaseEditStageProps} from "../types"
import {onUpdateAccessionFailure, onUpdateAccessionSuccess} from "../utils"

const updateAccessionDataTask = TE.tryCatchK(updateAccessionData, E.toError)

export const extractAccessionDataFormFields = ({
  identifiedBy,
  dateIdentified,
  identificationVerificationStatus,
  identificationQualifier,
  identificationRemarks,
  ...accession
}: AccessionFieldsFragment): Nullable<
  DeepNullable<AccessionDataUpdatesZod>
> => ({
  accessionDate:
    accession.accessionDate == null
      ? null
      : parseUtcDate(accession.accessionDate),
  provenanceCategoryCode: accession.provenanceCategoryCode,
  taxonOption: accession.taxon ?? null,
  materialTypeId: accession.materialType?.id ?? null,
  donor: accession.donor ?? null,
  notes: accession.notes ?? null,
  tags: accession.tagsConnection?.nodes.map(({id, name}) => ({id, name})) ?? [],
  ipenNumber: accession.ipenNumber ?? null,
  identificationDetails:
    identifiedBy != null ||
    dateIdentified != null ||
    identificationVerificationStatus != null ||
    identificationQualifier != null ||
    identificationRemarks != null
      ? {
          identifiedBy: identifiedBy ?? null,
          dateIdentified:
            dateIdentified == null ? null : parseUtcDate(dateIdentified),
          identificationVerificationStatus:
            identificationVerificationStatus as IdentificationVerificationStatus,
          identificationQualifier:
            identificationQualifier as IdentificationQualifier,
          identificationRemarks: identificationRemarks ?? null,
        }
      : null,
})

const resolver = zodResolver(AccessionDataUpdateSchemaZod)

export const EditAccessionData = ({
  open,
  accession,
  requestClose,
  refetch,
}: BaseEditStageProps) => {
  const {setSnack} = useSnackbarStore()
  const formMethods = useForm<Nullable<DeepNullable<AccessionDataUpdatesZod>>>({
    defaultValues: extractAccessionDataFormFields(accession),
    criteriaMode: "all",
    resolver,
  })
  const accessToken = useAccessToken()

  useEffect(() => {
    formMethods.reset(extractAccessionDataFormFields(accession))
  }, [open, formMethods, accession])

  const onSubmit = useMemo(
    () =>
      pipe(
        OE.Do,
        OE.apSW("accessToken", accessToken),
        OE.map(({accessToken}) =>
          formMethods.handleSubmit((data) => {
            return pipe(
              updateAccessionDataTask(accessToken, {
                accessionId: accession.id,
                updates: pipe(
                  data as AccessionDataUpdatesZod,
                  mapTaxonOptionToId,
                  mapTagsToIds,
                  mapIdentificationDetails,
                  cleanFormData,
                ),
              }),
              TE.match(
                onUpdateAccessionFailure(setSnack),
                onUpdateAccessionSuccess(
                  setSnack,
                  "Accession data updated",
                  () => {
                    refetch()
                    requestClose()
                  },
                ),
              ),
            )()
          }),
        ),
      ),
    [formMethods, accessToken, accession, setSnack, refetch, requestClose],
  )

  return pipe(
    OE.sequenceNoneToUndefined({
      onSubmit,
    }),
    E.match(showErrorMessage, ({onSubmit}) => (
      <FormProvider {...formMethods}>
        <SheetForm onSubmit={onSubmit}>
          <SheetHeader>
            <SheetTitle>Details</SheetTitle>
          </SheetHeader>
          <SheetBody>
            <AccessionDataFields accessionNumber={accession.accessionNumber} />
          </SheetBody>
          <SheetFooter>
            <Button testId="cancel-button" onClick={requestClose}>
              Cancel
            </Button>
            <Button
              testId="confirm-button"
              loading={formMethods.formState.isSubmitting}
              type="submit"
              variant="primary"
            >
              Save
            </Button>
          </SheetFooter>
        </SheetForm>
      </FormProvider>
    )),
  )
}
