import {Alert, AlertContent, AlertText} from "@hortis/ui/alert"
import {CommandEmpty, CommandGroup, CommandItem} from "@hortis/ui/command"
import {
  AlertTriangle,
  Download1,
  Eye,
  EyeOff,
  Route,
  Tag1,
  Trash3,
} from "@hortis/ui/icons"
import {CalendarPlus2} from "@hortis/ui/icons/calendar-plus-2"
import {Coins3} from "@hortis/ui/icons/coins-3"
import {useParams, useRouteContext} from "@tanstack/react-router"
import {usePlantMaterialsQuery} from "generated/graphql"
import {useCallback, useState} from "react"
import {CommandMenuLocationPage} from "src/components/command-menu/command-menu-pages/location"
import {MakePrivateDialog} from "src/components/command-menu/command-menu-pages/make-private-dialog"
import {MakePublicDialog} from "src/components/command-menu/command-menu-pages/make-public-dialog"
import {ObservationDialog} from "src/components/command-menu/command-menu-pages/observation"
import {CommandMenuStatusPage} from "src/components/command-menu/command-menu-pages/status"
import {CommandMenuMaterialTagsPage} from "src/components/command-menu/command-menu-pages/tags/material-tags"
import {TrashMaterialsDialog} from "src/features/collection/components/archive-records/trash-materials-dialog"
import {useCollectionSiteSettings} from "src/utils/hooks/collection-site-from-url"
import {match} from "ts-pattern"
import {ActionsMenu} from "../actions-menu"

type MaterialsActionMenuProps = {
  onRequestDownload?: () => void
  selectedRecordsIDs: ReadonlyArray<string>
  open: boolean
  toggleOpen: () => void
}

type Page = "tags" | "actions" | "changeStatus" | "changeLocation"

const MaterialsActionMenu = ({
  open,
  toggleOpen,
  onRequestDownload,
  selectedRecordsIDs,
}: MaterialsActionMenuProps) => {
  const {subdomain} = useRouteContext({from: "/_layout/sites/$siteSlug"})
  const {siteSlug} = useParams({from: "/_layout/sites/$siteSlug"})

  const [{data, error}] = usePlantMaterialsQuery({
    variables: {
      organisationSubdomain: subdomain,
      collectionSiteSlug: siteSlug,
      last: selectedRecordsIDs.length,
      filters: {
        id: {
          in: selectedRecordsIDs,
        },
      },
    },
    pause: !open,
  })

  const {isPublic} = useCollectionSiteSettings()

  const [observationDialogOpen, setObservationDialogOpen] = useState(false)
  const [makePublicDialogOpen, setMakePublicDialogOpen] = useState(false)
  const [makePrivateDialogOpen, setMakePrivateDialogOpen] = useState(false)
  const [trashDialogOpen, setTrashDialogOpen] = useState(false)

  const selectedRows = data?.org?.site?.result?.nodes ?? []

  const [search, setSearch] = useState("")
  const [menuPage, setMenuPage] = useState<Page>("actions")
  const clearSearch = () => {
    setSearch("")
  }

  const resetMenu = useCallback(() => {
    setMenuPage("actions")
    setObservationDialogOpen(false)
    setMakePublicDialogOpen(false)
    setMakePrivateDialogOpen(false)
    setTrashDialogOpen(false)
    clearSearch()
  }, [])
  const closeAndResetMenu = useCallback(() => {
    toggleOpen()
    resetMenu()
  }, [resetMenu, toggleOpen])
  const handleSuccess = useCallback(() => {
    closeAndResetMenu()
  }, [closeAndResetMenu])

  return (
    <>
      <ActionsMenu
        open={open}
        title={`${selectedRecordsIDs.length} plant materials selected`}
        closeAndResetMenu={closeAndResetMenu}
        search={search}
        setSearch={setSearch}
        placeholder={match(menuPage)
          .with("actions", () => "Search actions...")
          .with("tags", () => "Add / remove tags...")
          .with("changeStatus", () => "Change status...")
          .with("changeLocation", () => "Change location...")
          .exhaustive()}
      >
        {data == null && error != null ? (
          <CommandGroup>
            <div className="p-3">
              <Alert
                color="warning"
                className="mb-4"
                data-cy="material-action-menu-alert"
              >
                <AlertTriangle />
                <AlertContent>
                  <AlertText>Something went wrong.</AlertText>
                </AlertContent>
              </Alert>
            </div>
          </CommandGroup>
        ) : (
          match(menuPage)
            .with("actions", () => (
              <>
                <CommandEmpty>No results</CommandEmpty>
                <CommandGroup>
                  <CommandItem
                    onSelect={() => {
                      setObservationDialogOpen(true)
                    }}
                    data-cy="makeObservation-menu-item"
                  >
                    <CalendarPlus2 className="text-grey-500" />
                    Make observation
                  </CommandItem>
                  <CommandItem
                    onSelect={() => {
                      setMenuPage("tags")
                      clearSearch()
                    }}
                    data-cy="tags-menu-item"
                  >
                    <Tag1 className="text-grey-500" />
                    Add / remove tags
                  </CommandItem>
                  <CommandItem
                    onSelect={() => {
                      setMenuPage("changeStatus")
                      clearSearch()
                    }}
                    data-cy="changeStatus-menu-item"
                  >
                    <Coins3 className="text-grey-500" />
                    Change status
                  </CommandItem>
                  <CommandItem
                    onSelect={() => {
                      setMenuPage("changeLocation")
                      clearSearch()
                    }}
                    data-cy="changeLocation-menu-item"
                  >
                    <Route className="text-grey-500" />
                    Change location
                  </CommandItem>
                  {isPublic && (
                    <CommandItem
                      onSelect={() => {
                        setMakePublicDialogOpen(true)
                      }}
                      data-cy="makePublic-menu-item"
                    >
                      <Eye className="text-grey-500" />
                      Make public
                    </CommandItem>
                  )}
                  {isPublic && (
                    <CommandItem
                      onSelect={() => {
                        setMakePrivateDialogOpen(true)
                      }}
                      data-cy="makePrivate-menu-item"
                    >
                      <EyeOff className="text-grey-500" />
                      Make private
                    </CommandItem>
                  )}
                  {onRequestDownload != null && (
                    <CommandItem
                      onSelect={() => {
                        onRequestDownload()
                        closeAndResetMenu()
                      }}
                      data-cy="download-menu-item"
                    >
                      <Download1 className="text-grey-500" />
                      Download
                    </CommandItem>
                  )}
                  <CommandItem
                    onSelect={() => {
                      setTrashDialogOpen(true)
                    }}
                    data-cy="trash-menu-item"
                  >
                    <Trash3 className="text-grey-500" />
                    Move to trash
                  </CommandItem>
                </CommandGroup>
              </>
            ))
            .with("tags", () => (
              <CommandMenuMaterialTagsPage
                menuPage={menuPage}
                open={open}
                selectedRows={selectedRows}
                selectedRecordsIDs={selectedRecordsIDs}
                search={search}
                clearSearch={clearSearch}
              />
            ))
            .with("changeStatus", () => (
              <CommandMenuStatusPage
                onSuccess={handleSuccess}
                selectedMaterials={selectedRows}
              />
            ))
            .with("changeLocation", () => (
              <CommandMenuLocationPage
                onSuccess={handleSuccess}
                selectedMaterials={selectedRows}
              />
            ))
            .exhaustive()
        )}
      </ActionsMenu>
      <ObservationDialog
        open={observationDialogOpen}
        onOpenChange={setObservationDialogOpen}
        onSuccess={handleSuccess}
        selectedMaterials={selectedRows}
      />
      <MakePublicDialog
        open={makePublicDialogOpen}
        onOpenChange={setMakePublicDialogOpen}
        onSuccess={handleSuccess}
        selectedMaterials={selectedRows}
      />
      <MakePrivateDialog
        open={makePrivateDialogOpen}
        onOpenChange={setMakePrivateDialogOpen}
        onSuccess={handleSuccess}
        selectedMaterials={selectedRows}
      />
      <TrashMaterialsDialog
        setOpen={setTrashDialogOpen}
        open={trashDialogOpen}
        materialsIds={selectedRecordsIDs}
        onSuccess={handleSuccess}
      />
    </>
  )
}

export default MaterialsActionMenu
