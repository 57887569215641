import {Fragment} from "react"
import {FilterChip} from "../../filter-chip-group"
import type {OpenFilterArgs} from "../filters"
import type {MaterialFilterType, LocalMaterialFilter} from "../types"
import {extractConditionFilterData} from "./extract-condition-filter-data"

export const ConditionChipGroup = ({
  id,
  filter,
  hidden,
  ...props
}: {
  id: string
  handleOpenFilter: (args: OpenFilterArgs<MaterialFilterType>) => void
  handleDelete: (id: string) => void
  filter: LocalMaterialFilter["condition"]
  hidden?: boolean
}) => {
  const isFilters = filter?.or
    ?.flatMap(({eq}) => eq)
    .filter((val) => val != null)
  const isNotFilters = filter?.or
    ?.flatMap(({neq}) => neq)
    .filter((val) => val != null)

  return filter == null || hidden === true ? (
    <Fragment />
  ) : (
    <>
      {isFilters != null && isFilters.length > 0 && (
        <FilterChip
          filterId={id}
          filterType="condition"
          filterData={extractConditionFilterData(filter, "is")}
          operation="is"
          filterLabel="Condition"
          {...props}
        />
      )}
      {isNotFilters != null && isNotFilters.length > 0 && (
        <FilterChip
          filterId={id}
          filterType="condition"
          filterData={extractConditionFilterData(filter, "isNot")}
          operation="is not"
          filterLabel="Condition"
          {...props}
        />
      )}
    </>
  )
}
