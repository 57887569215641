import Alert from "@mui/material/Alert"
import {useTheme} from "@mui/material/styles"
import {Helmet} from "react-helmet-async"
import {Button} from "@hortis/ui/button"
import {Link} from "@tanstack/react-router"
import {AuthCard} from "./auth-card"
import {AuthPageLayout} from "./auth-page-layout"
import {CardFooter} from "./card-footer"
import {useErrorDescription} from "./login/hooks"

const errorDescriptionsMap: Record<string, string> = {
  access_denied:
    "We couldn't find a record for your hortis account id when trying to log you in. Contact your garden's admin or Hortis support.",
}

function ErrorPage() {
  const theme = useTheme()
  const errorDescription = useErrorDescription(errorDescriptionsMap)

  return (
    <AuthPageLayout>
      <Helmet>
        <title>Error | Hortis</title>
      </Helmet>
      <div>
        {errorDescription == null ? null : (
          <Alert
            data-cy="error-alert"
            severity="error"
            sx={{
              width: "100%",
              marginBottom: theme.spacing(2),
              maxWidth: theme.spacing(38),
            }}
          >
            {errorDescription}
          </Alert>
        )}
      </div>

      <AuthCard>
        <div className="pb-6">
          <Button
            size="md"
            variant="primary"
            fullWidth
            testId="signin-button"
            asChild
          >
            <Link to="/auth/select-organisation">Log in</Link>
          </Button>
        </div>
        <Button
          style={{
            fontSize: theme.typography.fontSize,
            textDecoration: "none",
          }}
          asChild
        >
          <a href="https://support.hortis.com" target="_blank" rel="noreferrer">
            Contact support
          </a>
        </Button>
      </AuthCard>
      <CardFooter />
    </AuthPageLayout>
  )
}

export const Component = ErrorPage
