export enum PageDirection {
  Next = "NEXT",
  Prev = "PREV",
}

export const createRelayParams = ({
  dir = PageDirection.Next,
  cursor,
  rowsPerPage,
}: {
  dir?: PageDirection | undefined | null
  cursor: string | undefined | null
  rowsPerPage: number
}) => ({
  after: dir === PageDirection.Next ? cursor : undefined,
  before: dir === PageDirection.Prev ? cursor : undefined,
  last: dir === PageDirection.Next ? rowsPerPage : undefined,
  first: dir === PageDirection.Prev ? rowsPerPage : undefined,
})
