import {toError} from "fp-ts/Either"
import {pipe} from "fp-ts/function"
import type {
  ArchiveAccessionMutation,
  ArchiveAccessionMutationVariables,
  CreateMaterialMutation,
  CreateMaterialMutationVariables,
  DeaccessionAccessionMutation,
  DeaccessionAccessionMutationVariables,
  GetAccessionQuery,
  GetAccessionQueryVariables,
  ReinstateAccessionMutation,
  ReinstateAccessionMutationVariables,
  RestoreAccessionMutation,
  RestoreAccessionMutationVariables,
  UpdateAccessionDataMutation,
  UpdateAccessionDataMutationVariables,
  UpdateCollectionDataMutation,
  UpdateCollectionDataMutationVariables,
} from "../../../../generated/graphql"
import {flowInto} from "../../../utils/flow"
import {authedFetcher} from "../../../utils/gql-client"
import {createAuthedHookWithMappedStruct} from "../../../utils/hooks/hook"
import {extractSiteFromOrg, usePlace} from "../../../utils/hooks/place"
import * as OE from "../../../utils/option-either"
import type {GetSWRConfiguration} from "../../../utils/swr"
import {createWrappedSWRHook} from "../../../utils/swr"
import {useAccessToken} from "../../../utils/use-access-token"
import {
  ARCHIVE_ACCESSION,
  CREATE_MATERIAL,
  DEACCESSION_ACCESSION,
  GET_ACCESSION,
  REINSTATE_ACCESSION,
  RESTORE_ACCESSION,
  UPDATE_ACCESSION_DATA,
  UPDATE_COLLECTION_DATA,
} from "./graphql"

const accession = authedFetcher<GetAccessionQueryVariables, GetAccessionQuery>(
  GET_ACCESSION,
)("GetAccession")

const _useAccession = createWrappedSWRHook(accession, toError)
export const useAccession = pipe(
  _useAccession,
  flowInto(OE.chainW(extractSiteFromOrg)),
  createAuthedHookWithMappedStruct,
  (useAccession) =>
    (
      accessionNumber?: GetAccessionQueryVariables["accessionNumber"],
      filters?: GetAccessionQueryVariables["filters"],
      config?: GetSWRConfiguration<typeof _useAccession>,
    ) => {
      return useAccession({place: usePlace()}, ({place}) => [
        place !== undefined && accessionNumber !== undefined
          ? {
              organisationSubdomain: place.orgName,
              collectionSiteSlug: place.siteSlug,
              accessionNumber,
              filters,
            }
          : undefined,
        config,
      ])
    },
)
export type UseAccessionResult = ReturnType<typeof useAccession>

export const useRefetchAccession = (
  accessionNumber?: GetAccessionQueryVariables["accessionNumber"],
  filters?: GetAccessionQueryVariables["filters"],
) => {
  const accessToken = useAccessToken()
  const place = usePlace()

  return pipe(
    OE.Do,
    OE.apSW("accessToken", accessToken),
    OE.apSW("place", place),
    OE.matchW(
      () => null,
      () => null,
      ({accessToken, place}) => {
        const vars =
          accessionNumber === undefined
            ? undefined
            : {
                organisationSubdomain: place.orgName,
                collectionSiteSlug: place.siteSlug,
                accessionNumber,
                filters,
              }

        return async () => {
          await _useAccession.prefetch(accessToken, vars)
        }
      },
    ),
  )
}

export const updateAccessionData = authedFetcher<
  UpdateAccessionDataMutationVariables,
  UpdateAccessionDataMutation
>(UPDATE_ACCESSION_DATA)("UpdateAccessionData").fetch

export const updateCollectionData = authedFetcher<
  UpdateCollectionDataMutationVariables,
  UpdateCollectionDataMutation
>(UPDATE_COLLECTION_DATA)("UpdateCollectionData").fetch

export const deaccessionAccession = authedFetcher<
  DeaccessionAccessionMutationVariables,
  DeaccessionAccessionMutation
>(DEACCESSION_ACCESSION)("DeaccessionAccession").fetch

export const reinstateAccession = authedFetcher<
  ReinstateAccessionMutationVariables,
  ReinstateAccessionMutation
>(REINSTATE_ACCESSION)("ReinstateAccession").fetch

export const archiveAccession = authedFetcher<
  ArchiveAccessionMutationVariables,
  ArchiveAccessionMutation
>(ARCHIVE_ACCESSION)("ArchiveAccession").fetch

export const restoreAccession = authedFetcher<
  RestoreAccessionMutationVariables,
  RestoreAccessionMutation
>(RESTORE_ACCESSION)("RestoreAccession").fetch

export const createPlantMaterial = authedFetcher<
  CreateMaterialMutationVariables,
  CreateMaterialMutation
>(CREATE_MATERIAL)("CreateMaterial").fetch
