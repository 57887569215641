import {zodResolver} from "@hookform/resolvers/zod"
import {TaxonomicRank, TaxonomicStatus} from "generated/graphql"
import {z} from "zod"

export const validationFilterSchema = z.object({
  isRank: z.array(z.nativeEnum(TaxonomicRank)).optional(),
  isStatus: z.array(z.nativeEnum(TaxonomicStatus)).optional(),
  isUnvalidated: z.boolean().optional(),
})

export const validationFilterResolver = zodResolver(validationFilterSchema)

export type LocalValidationFilter = z.TypeOf<typeof validationFilterSchema>
