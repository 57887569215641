import type {
  NameSource,
  TaxonFieldsFragment,
  TaxonomicStatus,
} from "generated/graphql"
import {colors} from "src/colors"
import {IconButton} from "@hortis/ui/button"
import {HelpCircle} from "@hortis/ui/icons"
import {useToggle} from "src/utils/hooks/toggle"
import {taxonomicRankLabelMap} from "src/utils/label-maps/taxonomic-rank"
import {Classification} from "../classification"
import {ValuePair} from "../value-pair"
import {NameValidationModal} from "./name-validation-modal"
import {NameSourceButton} from "./source-buttons"

export const taxonomicStatusDescriptionMap: Record<TaxonomicStatus, string> = {
  ACCEPTED: " is accepted",
  SYNONYM: " is a synonym",
  UNPLACED: " is unplaced",
}

export const taxonomicStatusTextColorMap: Record<TaxonomicStatus, string> = {
  ACCEPTED: "text-primary-600",
  SYNONYM: "text-warning-600",
  UNPLACED: "text-grey-600",
}

export const taxonomicStatusColorMap: Record<TaxonomicStatus, string> = {
  ACCEPTED: colors.primary[500],
  SYNONYM: colors.warning[500],
  UNPLACED: colors.grey[500],
}

const nameSourceLabelMap: Record<NameSource, string> = {
  POWO: "POWO",
  COL: "Catalogue of Life",
  IPNI: "International Plant Names Index",
  WIKIPEDIA: "Wikipedia",
}

interface TaxonomyProps {
  taxon: TaxonFieldsFragment
}

export const Taxonomy = ({taxon}: TaxonomyProps) => {
  const [genusValidationModalOpen, toggleGenusValidationModalOpen] = useToggle()
  return (
    <>
      <div className="flex flex-col gap-5 sm:gap-1.5">
        <ValuePair label="Scientific name">
          <p className="truncate">{taxon.scientificName}</p>
        </ValuePair>
        {(taxon.authorship != null ||
          taxon.sharedScientificName?.authorship == null) && (
          <ValuePair label="Authorship">
            <p className="truncate" data-cy="authorship-display">
              {taxon.authorship ?? "—"}
            </p>
          </ValuePair>
        )}
        <ValuePair label="Preferred common name">
          <p className="truncate">{taxon.commonName ?? "—"}</p>
        </ValuePair>
      </div>
      {taxon.sharedScientificName != null && (
        <div className="flex flex-col gap-3 border-t border-dashed border-grey-200 pt-5">
          <div className="flex items-center justify-between gap-3">
            <div className="flex items-center gap-1.5">
              <p className="text-sm font-medium">
                Data from{" "}
                {nameSourceLabelMap[taxon.sharedScientificName.nameSource]}
              </p>
              <IconButton
                ariaLabel="Name validation information"
                size="xs"
                variant="tertiaryGray"
                testId="name-validation-info-button"
                onClick={toggleGenusValidationModalOpen}
                icon={HelpCircle}
              />
            </div>
            <NameValidationModal
              open={genusValidationModalOpen}
              onClose={toggleGenusValidationModalOpen}
            />
            <NameSourceButton
              url={taxon.sharedScientificName.nameSourceUrl}
              nameSource={taxon.sharedScientificName.nameSource}
              status={taxon.sharedScientificName.status}
            />
          </div>
          <div className="flex flex-col gap-5 sm:gap-1.5">
            <ValuePair
              labelClassName="sm:pt-0.5"
              label={
                <>
                  {taxonomicRankLabelMap[taxon.sharedScientificName.rank]}
                  {
                    taxonomicStatusDescriptionMap[
                      taxon.sharedScientificName.status
                    ]
                  }
                </>
              }
            >
              <div className="relative flex h-6 items-center gap-1 truncate">
                <p className="truncate" data-cy="shared-scientific-name">
                  {taxon.sharedScientificName.scientificName}
                </p>
              </div>
            </ValuePair>
            <ValuePair label="Authorship">
              <p className="truncate" data-cy="shared-authorship">
                {taxon.sharedScientificName.authorship ?? "-"}
              </p>
            </ValuePair>
            {taxon.sharedScientificName.classification?.family != null && (
              <ValuePair label="Family">
                <p className="truncate" data-cy="family-name">
                  {taxon.sharedScientificName.classification.family}
                </p>
              </ValuePair>
            )}
            {taxon.sharedScientificName.classification != null && (
              <ValuePair label="Classification" data-cy="classification">
                <Classification
                  classification={taxon.sharedScientificName.classification}
                />
              </ValuePair>
            )}
          </div>
        </div>
      )}
      {taxon.organisation?.taxaInterpretationEnabled === true && (
        <div
          className="flex flex-col gap-3 border-t border-dashed border-grey-200  pt-3"
          data-cy="taxon-interpretation-fields"
        >
          <h4 className="py-1.5 text-sm font-medium text-grey-700">
            Interpretation
          </h4>
          <div className="flex flex-col gap-5  sm:gap-1.5">
            <ValuePair label="Description">
              <p data-cy="interpretation-description">
                {taxon.interpretationDescription ?? "—"}
              </p>
            </ValuePair>
            <ValuePair label="Native distribution">
              <p data-cy="native-distribution">
                {taxon.nativeDistribution ?? "—"}
              </p>
            </ValuePair>
          </div>
        </div>
      )}
    </>
  )
}
