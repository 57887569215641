import {Avatar} from "@hortis/ui/avatar"
import type {OrganisationMemberFieldsFragment} from "generated/graphql"
import {DataTableNullableCell} from "./data-table-nullable-cell"

export const formatMemberName = ({
  email,
  givenName,
  familyName,
}: Pick<
  OrganisationMemberFieldsFragment,
  "email" | "givenName" | "familyName"
>) =>
  (givenName == null
    ? email.split("@")[0]
    : `${givenName}${familyName == null ? "" : ` ${familyName}`}`) ?? "-"

export function DataTableMemberCell({
  member,
}: {
  member: OrganisationMemberFieldsFragment | undefined | null
}) {
  const name = member == null ? undefined : formatMemberName(member)
  return (
    <DataTableNullableCell value={member}>
      <div className="flex items-center gap-2 truncate text-grey-700">
        <Avatar
          size="xxs"
          src={member?.avatarUrl ?? undefined}
          textContent={name}
        />
        <span className="truncate">{name}</span>
      </div>
    </DataTableNullableCell>
  )
}
