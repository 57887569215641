import {SlAddIcon} from "src/components/icons/streamline/add"
import {useSmallDisplay} from "src/utils/hooks/media-query"
import {Button} from "@hortis/ui/button"
import {FormTextField as TextField} from "../../../../components/text-field/text-field"

const buttonStartIcon = <SlAddIcon />

interface OptionalNotesProps {
  notesAdded: boolean
  onAddNotes: () => void
  name: string
  testId?: string
  addButtonTestId?: string
}

export const OptionalNotes = ({
  notesAdded,
  onAddNotes,
  name,
  testId,
  addButtonTestId,
}: OptionalNotesProps) => {
  const isSmallDisplay = useSmallDisplay()

  return (
    <div>
      {notesAdded ? (
        <TextField
          name={name}
          label="Notes"
          multiline
          minRows={2}
          maxRows={6}
          testId={testId}
          fullWidth
        />
      ) : (
        <Button
          size="sm"
          onClick={onAddNotes}
          startIcon={buttonStartIcon}
          fullWidth={isSmallDisplay}
          testId={addButtonTestId ?? "add-notes"}
        >
          Add Notes
        </Button>
      )}
    </div>
  )
}
