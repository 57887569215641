import type {DeprecatedLocationFilter} from "generated/graphql"
import {isNonEmpty} from "../../count/is-non-empty"

export const countLocationFilter = (
  filter: NonNullable<DeprecatedLocationFilter>,
) => {
  let count = 0
  if (isNonEmpty(filter.is)) {
    count += 1
  }
  if (isNonEmpty(filter.isNot)) {
    count += 1
  }
  if (isNonEmpty(filter.contains)) {
    count += 1
  }
  return count
}
