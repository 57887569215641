import {IconButton} from "@hortis/ui/button"
import {Target2} from "@hortis/ui/icons/target-2"
import {memo, useCallback} from "react"
import {Tooltip, TooltipContent, TooltipTrigger} from "@hortis/ui/tooltip"
import {useMapOptionsStore} from "src/features/collection-map/components/map-options-store"
import {useMapPaddingStore} from "src/features/collection-map/components/map-padding-store"
import {useSnackbarStore} from "src/components/snackbar-controller/snackbar-store"
import {useMaplibre} from "../utils"

const positionOptions = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
}

export const useCenterDevicePosition = () => {
  const {setDevicePositionLayerEnabled} = useMapOptionsStore()
  const {current} = useMaplibre()
  const {mapPadding} = useMapPaddingStore()
  const {setSnack} = useSnackbarStore()

  const getCurrentPositionSuccess = useCallback<PositionCallback>(
    (pos) => {
      setDevicePositionLayerEnabled(true)
      current?.flyTo({
        center: {lng: pos.coords.longitude, lat: pos.coords.latitude},
        zoom: 17,
        padding: mapPadding,
      })
    },
    [current, mapPadding, setDevicePositionLayerEnabled],
  )

  const getCurrentPositionError = useCallback<PositionErrorCallback>(() => {
    setDevicePositionLayerEnabled(false)
    setSnack({
      type: "alert",
      data: {severity: "error", text: "Unable to get location"},
    })
  }, [setSnack, setDevicePositionLayerEnabled])

  const centerDevicePosition = useCallback(() => {
    navigator.geolocation.getCurrentPosition(
      getCurrentPositionSuccess,
      getCurrentPositionError,
      positionOptions,
    )
  }, [getCurrentPositionSuccess, getCurrentPositionError])

  return {centerDevicePosition}
}

export const CenterCurrentPosition = memo(
  function MemoisedCenterCurrentPosition() {
    const {centerDevicePosition} = useCenterDevicePosition()

    return (
      <Tooltip>
        <TooltipTrigger asChild>
          <IconButton
            onClick={centerDevicePosition}
            variant="tertiaryGray"
            ariaLabel="Center on device"
            size={{xs: "sm", md: "md"}}
            className="pointer-events-auto text-grey-700 hover:text-grey-800"
            icon={Target2}
          />
        </TooltipTrigger>
        <TooltipContent side="left">Center on device</TooltipContent>
      </Tooltip>
    )
  },
)
