import {Button} from "@hortis/ui/button"
import {
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@hortis/ui/dialog"
import * as E from "fp-ts/Either"
import * as TE from "fp-ts/TaskEither"
import {pipe} from "fp-ts/function"
import {useMemo} from "react"
import {type UseQueryExecute} from "urql"
import {useSnackbarStore} from "../../../../../components/snackbar-controller/snackbar-store"
import {reinstateAccession} from "../../fetchers"
import {
  onUpdateAccessionFailure,
  onUpdateAccessionSuccess,
} from "../edit-drawer/utils"

const reinstateTask = TE.tryCatchK(reinstateAccession, E.toError)

interface ReinstateDialogProps {
  accessionId: string
  accessToken: string
  refetch: UseQueryExecute
  onClose: () => void
}

export const ReinstateDialogContent = ({
  accessionId,
  accessToken,
  refetch,
  onClose,
}: ReinstateDialogProps) => {
  const {setSnack} = useSnackbarStore()

  const onSubmit = useMemo(
    () => async () => {
      await pipe(
        reinstateTask(accessToken, {accessionId}),
        TE.match(
          onUpdateAccessionFailure(setSnack),
          onUpdateAccessionSuccess(
            setSnack,
            "Accession has been reinstated",
            refetch,
          ),
        ),
      )()

      onClose()
    },
    [accessToken, accessionId, onClose, refetch, setSnack],
  )

  return (
    <DialogContent data-cy="reinstate-dialog">
      <DialogHeader>
        <DialogTitle>Reinstate accession?</DialogTitle>
        <DialogDescription>
          Reinstating an accession will return it to the collection. The status
          of any associated plant materials will not change.
        </DialogDescription>
      </DialogHeader>
      <DialogFooter>
        <Button onClick={onClose} testId="cancel-reinstate-dialog">
          Cancel
        </Button>
        <Button
          variant="primary"
          type="submit"
          onClick={onSubmit}
          data-cy="submit-reinstate-dialog"
        >
          Reinstate
        </Button>
      </DialogFooter>
    </DialogContent>
  )
}
