import {TaxonomicRank} from "generated/graphql"

export const taxonomicRankLabelMap: Record<TaxonomicRank, string> = {
  [TaxonomicRank.Class]: "Class",
  [TaxonomicRank.Family]: "Family",
  [TaxonomicRank.Genus]: "Genus",
  [TaxonomicRank.Infraspecies]: "Infraspecies",
  [TaxonomicRank.Kingdom]: "Kingdom",
  [TaxonomicRank.Order]: "Order",
  [TaxonomicRank.Phylum]: "Phylum",
  [TaxonomicRank.Section]: "Section",
  [TaxonomicRank.Series]: "Series",
  [TaxonomicRank.Species]: "Species",
  [TaxonomicRank.Subclass]: "Subclass",
  [TaxonomicRank.Subfamily]: "Subfamily",
  [TaxonomicRank.Subgenus]: "Subgenus",
  [TaxonomicRank.Subkingdom]: "Subkingdom",
  [TaxonomicRank.Suborder]: "Suborder",
  [TaxonomicRank.Subphylum]: "Subphylum",
  [TaxonomicRank.Subsection]: "Subsection",
  [TaxonomicRank.Subseries]: "Subseries",
  [TaxonomicRank.Subspecies]: "Subspecies",
  [TaxonomicRank.Subtribe]: "Subtribe",
  [TaxonomicRank.Superorder]: "Superorder",
  [TaxonomicRank.Tribe]: "Tribe",
}
