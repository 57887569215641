import {type ReactNode} from "react"

export interface ListToolbarProps {
  children: ReactNode
}

export const ListToolbar = ({children}: ListToolbarProps) => (
  <div className="flex flex-col items-stretch gap-3 border-b border-grey-200 px-3 py-3">
    {children}
  </div>
)
