import type {
  AutocompleteProps,
  AutocompleteRenderInputParams,
} from "@mui/material/Autocomplete"
import Autocomplete from "@mui/material/Autocomplete"
import type {AutocompleteValue} from "@mui/material/useAutocomplete"
import type {ForwardedRef, MutableRefObject, ReactNode} from "react"
import {forwardRef, useCallback} from "react"
import {useController, useFormContext} from "react-hook-form"
import {colors} from "src/colors"
import {shadows} from "src/shadows"
import {concatAllSxProps, type SxProps} from "../../utils/sx"
import {useId} from "../field-utils"
import {TextField} from "../text-field/text-field"

export interface AutocompleteFieldProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> extends Omit<
    AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
    "renderInput" | "sx"
  > {
  name: string
  label: string | null
  required?: boolean
  options: Array<T> | ReadonlyArray<T>
  initialValue?: T
  testId?: string
  sx?: SxProps
  startAdornment?: ReactNode
  endAdornment?: ReactNode
  disableFormOnChange?: boolean
  inputRef?: MutableRefObject<HTMLInputElement | null>
}

// const defaultRenderOption = <T,>(
//   props: HTMLAttributes<HTMLLIElement>,
//   option: T,
// ) => <li {...props}>{typeof option === "string" && option}</li>

const identityLabel = <T,>(val: T) => (typeof val === "string" ? val : "")

function FormAutocompleteInner<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>(
  {
    name,
    label,
    options,
    value,
    initialValue,
    renderOption,
    getOptionLabel,
    testId: dataCy,
    onInputChange,
    placeholder,
    startAdornment,
    endAdornment,
    onChange,
    disableFormOnChange,
    required = false,
    inputRef,
    ...props
  }: AutocompleteFieldProps<T, Multiple, DisableClearable, FreeSolo>,
  ref: ForwardedRef<HTMLDivElement>,
): JSX.Element {
  const id = useId(name)

  const {control, trigger} = useFormContext()
  const {
    field,
    fieldState: {error},
  } = useController({
    name,
    defaultValue: initialValue,
    control,
  })

  type AutocompleteOnChange = Exclude<
    AutocompleteFieldProps<T, Multiple, DisableClearable, FreeSolo>["onChange"],
    undefined
  >
  const handleChange = useCallback<AutocompleteOnChange>(
    (_event, value, reason, details): void => {
      if (disableFormOnChange !== true) {
        field.onChange(value)
      }
      if (onChange != null) {
        onChange(_event, value, reason, details)
      }
    },
    [field, onChange, disableFormOnChange],
  )

  type AutocompleteOnInputChange = Exclude<
    AutocompleteFieldProps<
      T,
      Multiple,
      DisableClearable,
      FreeSolo
    >["onInputChange"],
    undefined
  >
  const handleInputChange = useCallback<AutocompleteOnInputChange>(
    (event, value, reason): void => {
      if (onInputChange != null) {
        onInputChange(event, value, reason)
      }
      if (
        props.freeSolo === true &&
        props.multiple !== true &&
        disableFormOnChange !== true
      ) {
        field.onChange(value)
      }
      // We want revalidate to run onChange, but this behaviour only happens if a form onSubmit has occured,
      // so trigger revalidation manually,
      // see: https://github.com/react-hook-form/react-hook-form/discussions/2444
      if (error != null) {
        void trigger(name)
      }
    },
    [
      field,
      props.freeSolo,
      props.multiple,
      onInputChange,
      disableFormOnChange,
      trigger,
      error,
      name,
    ],
  )

  const cachedRenderInput = useCallback(
    ({inputProps, InputProps}: AutocompleteRenderInputParams) => {
      const setRef = (ref: HTMLInputElement | null) => {
        if (inputRef != null) {
          inputRef.current = ref
        }
        if ("ref" in inputProps) {
          const inputPropsRef =
            inputProps.ref as MutableRefObject<HTMLInputElement | null>
          inputPropsRef.current = ref
        }
      }
      return (
        <TextField
          fullWidth
          name={name}
          inputProps={
            dataCy === undefined
              ? {...inputProps, ref: setRef}
              : {...inputProps, "data-cy": dataCy, ref: setRef}
          }
          InputProps={{
            ...InputProps,
            startAdornment: startAdornment ?? InputProps.startAdornment,
            endAdornment: endAdornment ?? InputProps.endAdornment,
          }}
          endAdornment={InputProps.endAdornment}
          startAdornment={startAdornment}
          label={label}
          errorMessage={error?.message}
          error={Boolean(error)}
          required={required}
          placeholder={placeholder}
          id={id}
        />
      )
    },
    [
      name,
      required,
      label,
      dataCy,
      id,
      placeholder,
      error,
      startAdornment,
      endAdornment,
      inputRef,
    ],
  )

  return (
    <Autocomplete
      // this type is huge and really inefficient, so let's try and ignore it
      {...(props as Record<string, unknown>)}
      onInputChange={handleInputChange}
      onChange={handleChange}
      ref={ref}
      value={
        value === undefined
          ? (field.value as
              | AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>
              | undefined)
          : value
      }
      clearOnBlur={false}
      options={options}
      getOptionLabel={getOptionLabel ?? identityLabel}
      renderOption={renderOption}
      renderInput={cachedRenderInput}
      sx={concatAllSxProps([
        {
          "& .MuiOutlinedInput-root": {py: "2.5px"},
          '& .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"]':
            {
              backgroundColor: colors.grey[50],
            },
          "& .MuiOutlinedInput-root .MuiAutocomplete-endAdornment": {
            right: "16px",
          },
        },
        props.sx,
      ])}
      componentsProps={{
        ...props.componentsProps,
        popper: {
          disablePortal: true,
          sx: {
            marginTop: "2px !important",
            marginBottom: "2px !important",
            "& .MuiAutocomplete-listbox": {
              padding: "4px 0",
            },
            "& .MuiPaper-root": {
              border: `1px solid ${colors.grey[100]}`,
              boxShadow: shadows.lg,
            },
            '& .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"]':
              {
                backgroundColor: colors.grey[50],
              },
            "& .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused": {
              backgroundColor: colors.grey[100],
            },
          },
          ...props.componentsProps?.popper,
        },
      }}
      id={id}
    />
  )
}

export const FormAutocomplete = forwardRef(FormAutocompleteInner)

export function getFormAutocomplete<T>() {
  return function PartiallyAppliedFormAutocomplete<
    Multiple extends boolean | undefined = undefined,
    DisableClearable extends boolean | undefined = undefined,
    FreeSolo extends boolean | undefined = undefined,
  >(
    props: AutocompleteFieldProps<T, Multiple, DisableClearable, FreeSolo>,
  ): JSX.Element {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- this type is huge and really inefficient, so let's try and ignore it
    return <FormAutocomplete {...(props as any)} />
  }
}
