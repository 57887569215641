import {Skeleton} from "@hortis/ui/skeleton"
import type {HTMLAttributes} from "react"
import {twMerge} from "tailwind-merge"

const RecordField = ({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      className={twMerge("record-field flex flex-col gap-1", className)}
      {...props}
    >
      {children}
    </div>
  )
}

const RecordFieldValue = ({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) => {
  return (
    <span
      className={twMerge("text-sm font-normal text-grey-900", className)}
      {...props}
    >
      {children ?? "-"}
    </span>
  )
}

const RecordFieldLabel = ({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) => {
  return (
    <p
      className={twMerge(
        "h-6 w-40 shrink-0 text-sm font-medium text-grey-500",
        className,
      )}
      {...props}
    >
      {children}
    </p>
  )
}

const RecordLabelSkeleton = ({className}: {className: string}) => (
  <RecordFieldLabel>
    <Skeleton className={className} />
  </RecordFieldLabel>
)
const RecordValueSkeleton = ({className}: {className: string}) => (
  <RecordFieldValue>
    <Skeleton className={className} />
  </RecordFieldValue>
)

export {
  RecordField,
  RecordFieldLabel,
  RecordFieldValue,
  RecordLabelSkeleton,
  RecordValueSkeleton,
}
