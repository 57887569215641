import * as O from "fp-ts/Option"
import type {MapLibreEvent} from "maplibre-gl"
import {memo, useEffect, useState} from "react"
import {useRunningWithCypress} from "../../utils/cypress"
import {useMaplibre} from "./utils"

type MapStatus = "initial" | "idle" | "flying"

// Rounds a number to given precision and removes trailing zeroes
const roundCoordinate = (val: number, precision = 10) =>
  Number.parseFloat(val.toFixed(precision))

const InternalCypressMapObserver = ({testIdPrefix}: {testIdPrefix: string}) => {
  const {current} = useMaplibre()
  const [center, setCenter] = useState<{lng: number; lat: number}>()
  const [mapStatus, setMapStatus] = useState<MapStatus>("initial")

  useEffect(() => {
    type GenericEvent = MapLibreEvent
    if (mapStatus === "initial" && current?.areTilesLoaded() === true) {
      const {lng, lat} = current.getCenter()
      setMapStatus("idle")
      setCenter({lng: roundCoordinate(lng), lat: roundCoordinate(lat)})
    }

    const handleMoveStart = (_event: unknown) => {
      setMapStatus("flying")
      setCenter(undefined)
    }
    current?.on("movestart", handleMoveStart)

    const handleMoveEnd = (event: GenericEvent) => {
      const {lng, lat} = event.target.getCenter()
      setMapStatus("idle")
      setCenter({lng: roundCoordinate(lng), lat: roundCoordinate(lat)})
    }
    current?.on("moveend", handleMoveEnd)

    const center = current?.getCenter()
    if (center) {
      setCenter({
        lng: roundCoordinate(center.lng),
        lat: roundCoordinate(center.lat),
      })
    }

    return () => {
      current?.off("movestart", handleMoveStart)
      current?.off("moveend", handleMoveEnd)
    }
  }, [current, mapStatus])

  return (
    <div
      data-cy={testIdPrefix}
      style={{
        display: "block",
        position: "absolute",
        top: "60px",
        right: "75px",
      }}
    >
      <div data-cy={`${testIdPrefix}-center`}>
        Map center:{" "}
        {center === undefined
          ? "unavailable"
          : `lat: ${center.lat}, lng: ${center.lng}`}
      </div>
      <div>
        Map status: <span data-cy={`${testIdPrefix}-status`}>{mapStatus}</span>
      </div>
    </div>
  )
}

/**
 * Include as a map child
 */
export const CypressMapObserver = memo(function MemoisedCypressMapObserver({
  testIdPrefix,
}: {
  testIdPrefix: string
}): JSX.Element {
  return O.toUndefined(useRunningWithCypress()) ?? false ? (
    <InternalCypressMapObserver testIdPrefix={testIdPrefix} />
  ) : (
    <></>
  )
})
