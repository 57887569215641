import type {PageInfo} from "generated/graphql"
import {useCallback, useState, useEffect} from "react"
import {PageDirection} from "../../pagination"

interface UsePaginationArgs<T> {
  navigate: (arg: {search: (args: T) => void}) => void
  pageInfo: PageInfo | undefined
  cursor: string | null | undefined
  fetching: boolean
  tableEl: HTMLElement | null
  mobileListEl: HTMLElement | null
  cursorKey?: string
  dirKey?: string
}

export const usePagination = <T>({
  pageInfo,
  navigate,
  fetching,
  tableEl,
  mobileListEl,
  cursorKey = "cursor",
  dirKey = "dir",
}: UsePaginationArgs<T>) => {
  const [loadingDirection, setLoadingDirection] =
    useState<PageDirection | null>(null)

  const onPreviousPage = useCallback(() => {
    const cursor = pageInfo?.startCursor
    if (cursor != null && pageInfo?.hasPreviousPage === true) {
      navigate({
        search: (prev) => ({
          ...prev,
          [cursorKey]: cursor,
          [dirKey]: PageDirection.Prev,
        }),
      })
      setLoadingDirection(PageDirection.Prev)
    }
  }, [pageInfo, navigate, cursorKey, dirKey])

  const onNextPage = useCallback(() => {
    const cursor = pageInfo?.endCursor
    if (cursor != null && pageInfo?.hasNextPage === true) {
      navigate({
        search: (prev) => ({
          ...prev,
          [cursorKey]: cursor,
          [dirKey]: PageDirection.Next,
        }),
      })
      setLoadingDirection(PageDirection.Next)
    }
  }, [pageInfo, navigate, cursorKey, dirKey])

  useEffect(() => {
    if (!fetching) {
      if (tableEl != null) {
        tableEl.scrollTop = 0
      }
      if (mobileListEl != null) {
        mobileListEl.scrollTop = 0
      }
      setLoadingDirection(null)
    }
  }, [fetching, tableEl, mobileListEl])

  return {
    onPreviousPage,
    onNextPage,
    loadingDirection,
    nextPageLoading: loadingDirection === PageDirection.Next && fetching,
    previousPageLoading: loadingDirection === PageDirection.Prev && fetching,
  }
}
