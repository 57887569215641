import type {HTMLAttributes} from "react"
import {twMerge} from "tailwind-merge"

export const Skeleton = ({
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) => (
  <div
    className={twMerge("bg-grey-200 animate-pulse rounded-md", className)}
    {...props}
  />
)
