import {toError} from "fp-ts/Either"
import {pipe} from "fp-ts/function"
import {useEffect, useCallback} from "react"
import {useDebounce} from "src/utils/hooks/debounce"
import {PageDirection} from "src/utils/pagination"
import type {
  AccessionsOrderByInput,
  AccessionsSearchTerm,
  GetAccessionsQuery,
  GetAccessionsQueryVariables,
  GetMeGardenQuery,
  GetMeGardenQueryVariables,
  MapListMaterialsQuery,
  MapListMaterialsQueryVariables,
  PlantMaterialFilter,
  PlantMaterialsOrderByInput,
  PlantMaterialsQuery,
  PlantMaterialsQueryVariables,
  PlantMaterialsSearchTerm,
} from "../../../generated/graphql"
import {AccessionsColumn, SortDirection} from "../../../generated/graphql"
import {noGQLVars} from "../../utils/empty"
import {flowInto} from "../../utils/flow"
import {authedFetcher} from "../../utils/gql-client"
import {createAuthedHook, useHookWithMappedStruct} from "../../utils/hooks/hook"
import {extractSiteFromOrg, usePlace} from "../../utils/hooks/place"
import * as OE from "../../utils/option-either"
import type {GetSWRConfiguration} from "../../utils/swr"
import {createWrappedSWRHook} from "../../utils/swr"
import {useAccessToken} from "../../utils/use-access-token"
import {GET_MAP_LIST_MATERIALS} from "../collection-map/graphql"
import {GET_ACCESSIONS, GET_ME_GARDEN, GET_PLANT_MATERIALS} from "./graphql"

const noop = () => Promise.resolve(undefined)

const prefetchAccessionPages = async (
  prefetch: (
    ...args: Parameters<(typeof _useAccessions)["prefetch"]>
  ) => Promise<unknown>,
  {
    limit,
    accessToken,
    organisationSubdomain,
    collectionSiteSlug,
    startCursor,
    endCursor,
    searchTerm,
    orderBy,
    filters,
    plantMaterialFilters,
    hasNextPage = false,
    hasPreviousPage = false,
  }: {
    limit: number
    accessToken: string
    organisationSubdomain: string
    collectionSiteSlug: string
    startCursor: string | null | undefined
    endCursor: string | null | undefined
    orderBy?: AccessionsOrderByInput
    searchTerm?: AccessionsSearchTerm | null
    filters: PlantMaterialFilter | null | undefined
    plantMaterialFilters: PlantMaterialFilter | null | undefined
    hasNextPage?: boolean
    hasPreviousPage?: boolean
  },
) => {
  await Promise.all([
    endCursor != null && hasNextPage
      ? prefetch(accessToken, {
          // @NOTE: The order of these args needs to match the order provided to the actual
          // non-prefetched query, otherwise the SWR key won't match and these cached prefetches
          // won't be used - the object key order should be ensured with a stable sort in the future
          organisationSubdomain,
          collectionSiteSlug,
          after: endCursor,
          last: limit,
          searchTerm,
          filters,
          plantMaterialFilters,
          orderBy,
        })
      : undefined,
    startCursor != null && hasPreviousPage
      ? prefetch(accessToken, {
          // See above @NOTE
          organisationSubdomain,
          collectionSiteSlug,
          before: startCursor,
          first: limit,
          searchTerm,
          filters,
          plantMaterialFilters,
          orderBy,
        })
      : undefined,
  ])
}

const prefetchMaterialPages = async (
  prefetch: (
    ...args: Parameters<(typeof _usePlantMaterials)["prefetch"]>
  ) => Promise<unknown>,
  {
    limit,
    accessToken,
    organisationSubdomain,
    collectionSiteSlug,
    filters,
    startCursor,
    endCursor,
    searchTerm,
    orderBy,
    hasNextPage = false,
    hasPreviousPage = false,
  }: {
    limit: number
    accessToken: string
    organisationSubdomain: string
    collectionSiteSlug: string
    filters: PlantMaterialFilter
    startCursor: string | null | undefined
    endCursor: string | null | undefined
    orderBy?: PlantMaterialsOrderByInput
    searchTerm?: PlantMaterialsSearchTerm | null
    hasNextPage?: boolean
    hasPreviousPage?: boolean
  },
) => {
  await Promise.all([
    endCursor != null && hasNextPage
      ? prefetch(accessToken, {
          // See above @NOTE
          organisationSubdomain,
          collectionSiteSlug,
          after: endCursor,
          last: limit,
          searchTerm,
          filters,
          orderBy,
        })
      : undefined,
    startCursor != null && hasPreviousPage
      ? prefetch(accessToken, {
          // See above @NOTE
          organisationSubdomain,
          collectionSiteSlug,
          before: startCursor,
          first: limit,
          searchTerm,
          filters,
          orderBy,
        })
      : undefined,
  ])
}

const accessions = authedFetcher<
  GetAccessionsQueryVariables,
  GetAccessionsQuery
>(GET_ACCESSIONS)("GetAccessions")

const _useAccessions = createWrappedSWRHook(accessions, toError)
export const useAccessions = pipe(
  _useAccessions,
  flowInto(OE.chainW(extractSiteFromOrg)),
  (useAccessions) =>
    (
      pagination: {
        cursor: string | undefined | null
        limit: number
        page: PageDirection
      },
      config?: GetSWRConfiguration<typeof _useAccessions>,
      searchTerm?: GetAccessionsQueryVariables["searchTerm"],
      filters?: GetAccessionsQueryVariables["filters"],
      plantMaterialFilters?: GetAccessionsQueryVariables["plantMaterialFilters"],
      orderBy?: AccessionsOrderByInput,
      // eslint-disable-next-line sonarjs/cognitive-complexity
    ) => {
      const accessToken = useAccessToken()
      const place = usePlace()

      const search = searchTerm?.value === "" ? null : searchTerm

      const accessions = useHookWithMappedStruct(
        useAccessions,
        {accessToken, place},
        ({accessToken, place}) => [
          accessToken,
          place === undefined
            ? undefined
            : {
                organisationSubdomain: place.orgName,
                collectionSiteSlug: place.siteSlug,
                after:
                  pagination.page === PageDirection.Next
                    ? pagination.cursor
                    : undefined,
                before:
                  pagination.page === PageDirection.Prev
                    ? pagination.cursor
                    : undefined,
                last:
                  pagination.page === PageDirection.Next
                    ? pagination.limit
                    : undefined,
                first:
                  pagination.page === PageDirection.Prev
                    ? pagination.limit
                    : undefined,
                searchTerm: search,
                filters,
                plantMaterialFilters,
                orderBy: orderBy ?? {
                  field: AccessionsColumn.AccessionNum,
                  direction: SortDirection.Desc,
                },
              },
          config,
        ],
      )

      const limit = pagination.limit

      // Pre-fetching next/prev page
      // This currently duplicates requests based on PageDirection
      // Even if it's the same data if the direction has changed it'll refetch
      useEffect(() => {
        pipe(
          OE.Do,
          OE.apSW("accessToken", accessToken),
          OE.apSW("place", place),
          OE.apSW("accessions", accessions),
          OE.matchW(noop, noop, ({accessToken, place, accessions: {result}}) =>
            prefetchAccessionPages(_useAccessions.prefetch, {
              limit,
              accessToken,
              organisationSubdomain: place.orgName,
              collectionSiteSlug: place.siteSlug,
              startCursor: result?.pageInfo.startCursor,
              endCursor: result?.pageInfo.endCursor,
              hasNextPage: result?.pageInfo.hasNextPage,
              hasPreviousPage: result?.pageInfo.hasPreviousPage,
              searchTerm: search,
              orderBy: {
                field: AccessionsColumn.AccessionNum,
                direction: SortDirection.Desc,
              },
              filters,
              plantMaterialFilters,
            }),
          ),
        ).catch((error: unknown) => {
          // eslint-disable-next-line no-console
          console.error("Failure when prefetching accessions", error)
        })
      }, [
        limit,
        accessToken,
        place,
        accessions,
        search,
        filters,
        plantMaterialFilters,
      ])

      return accessions
    },
)
export type UseAccessionsResult = ReturnType<typeof useAccessions>
export type UseAccessionsError = OE.ExtractLeft<UseAccessionsResult>
export type UseAccessionsSuccess = OE.ExtractRight<UseAccessionsResult>

export interface UsePlantMaterialsArgs {
  pagination: {
    cursor: string | undefined | null
    limit: number
    page: PageDirection
  }
  filters: PlantMaterialFilter
  config?: GetSWRConfiguration<typeof _usePlantMaterials>
  searchTerm?: PlantMaterialsQueryVariables["searchTerm"]
  orderBy?: PlantMaterialsOrderByInput
}

const plantMaterials = authedFetcher<
  PlantMaterialsQueryVariables,
  PlantMaterialsQuery
>(GET_PLANT_MATERIALS)("PlantMaterials")

const _usePlantMaterials = createWrappedSWRHook(plantMaterials, toError)
export const usePlantMaterials = pipe(
  _usePlantMaterials,
  flowInto(OE.chainW(extractSiteFromOrg)),
  (usePlantMaterials) =>
    ({
      pagination,
      filters,
      config,
      searchTerm,
      orderBy,
    }: UsePlantMaterialsArgs) => {
      const accessToken = useAccessToken()
      const place = usePlace()

      const search = searchTerm?.value === "" ? null : searchTerm

      const plantMaterials = useHookWithMappedStruct(
        usePlantMaterials,
        {accessToken, place},
        ({accessToken, place}) => [
          accessToken,
          place === undefined
            ? undefined
            : {
                organisationSubdomain: place.orgName,
                collectionSiteSlug: place.siteSlug,
                after:
                  pagination.page === PageDirection.Next
                    ? pagination.cursor
                    : undefined,
                before:
                  pagination.page === PageDirection.Prev
                    ? pagination.cursor
                    : undefined,
                last:
                  pagination.page === PageDirection.Next
                    ? pagination.limit
                    : undefined,
                first:
                  pagination.page === PageDirection.Prev
                    ? pagination.limit
                    : undefined,
                filters,
                searchTerm: search,
                orderBy: orderBy,
              },
          config,
        ],
      )

      const limit = pagination.limit

      // Pre-fetching next/prev page
      // This currently duplicates requests based on PageDirection
      // Even if it's the same data if the direction has changed it'll refetch
      useEffect(() => {
        pipe(
          OE.Do,
          OE.apSW("accessToken", accessToken),
          OE.apSW("place", place),
          OE.apSW("plantMaterials", plantMaterials),
          OE.matchW(
            noop,
            noop,
            ({accessToken, place, plantMaterials: {result}}) =>
              prefetchMaterialPages(_usePlantMaterials.prefetch, {
                limit,
                accessToken,
                organisationSubdomain: place.orgName,
                collectionSiteSlug: place.siteSlug,
                startCursor: result?.pageInfo.startCursor,
                endCursor: result?.pageInfo.endCursor,
                hasNextPage: result?.pageInfo.hasNextPage,
                hasPreviousPage: result?.pageInfo.hasPreviousPage,
                filters,
                searchTerm: search,
                orderBy,
              }),
          ),
        ).catch((error: unknown) => {
          // eslint-disable-next-line no-console
          console.error("Failure when prefetching materials", error)
        })
      }, [limit, accessToken, place, plantMaterials, filters, search, orderBy])

      return plantMaterials
    },
)

export type UsePlantMaterialsResult = ReturnType<typeof usePlantMaterials>
export type UsePlantMaterialsError = OE.ExtractLeft<UsePlantMaterialsResult>
export type UsePlantMaterialsSuccess = OE.ExtractRight<UsePlantMaterialsResult>

const mapListMaterials = authedFetcher<
  MapListMaterialsQueryVariables,
  MapListMaterialsQuery
>(GET_MAP_LIST_MATERIALS)("MapListMaterials")

export interface UseMapListMaterialsArgs {
  pagination: {
    cursor: string | undefined | null
    limit: number
    page: PageDirection
  }
  filters: PlantMaterialFilter
  config?: GetSWRConfiguration<typeof _useMapListMaterials>
  searchTerm?: PlantMaterialsQueryVariables["searchTerm"]
  orderBy?: PlantMaterialsOrderByInput
}

const _useMapListMaterials = createWrappedSWRHook(mapListMaterials, toError)
export const useMapListMaterials = pipe(
  _useMapListMaterials,
  flowInto(OE.chainW(extractSiteFromOrg)),

  (usePlantMaterials) =>
    ({
      pagination,
      filters,
      config,
      searchTerm,
      orderBy,
    }: UseMapListMaterialsArgs) => {
      const accessToken = useAccessToken()
      const place = usePlace()

      const search = searchTerm?.value === "" ? null : searchTerm

      const plantMaterials = useHookWithMappedStruct(
        usePlantMaterials,
        {accessToken, place},

        ({accessToken, place}) => [
          accessToken,
          place === undefined
            ? undefined
            : {
                organisationSubdomain: place.orgName,
                collectionSiteSlug: place.siteSlug,
                after:
                  pagination.page === PageDirection.Next
                    ? pagination.cursor
                    : undefined,
                before:
                  pagination.page === PageDirection.Prev
                    ? pagination.cursor
                    : undefined,
                last:
                  pagination.page === PageDirection.Next
                    ? pagination.limit
                    : undefined,
                first:
                  pagination.page === PageDirection.Prev
                    ? pagination.limit
                    : undefined,
                filters,
                searchTerm: search,
                orderBy: orderBy,
              },
          config,
        ],
      )

      const limit = pagination.limit

      // Pre-fetching next/prev page
      // This currently duplicates requests based on PageDirection
      // Even if it's the same data if the direction has changed it'll refetch
      useDebounce(
        useCallback(
          () =>
            pipe(
              OE.Do,
              OE.apSW("accessToken", accessToken),
              OE.apSW("place", place),
              OE.apSW("plantMaterials", plantMaterials),
              OE.matchW(
                noop,
                noop,
                ({accessToken, place, plantMaterials: {result}}) =>
                  prefetchMaterialPages(_useMapListMaterials.prefetch, {
                    limit,
                    accessToken,
                    organisationSubdomain: place.orgName,
                    collectionSiteSlug: place.siteSlug,
                    startCursor: result?.pageInfo.startCursor,
                    endCursor: result?.pageInfo.endCursor,
                    hasNextPage: result?.pageInfo.hasNextPage,
                    hasPreviousPage: result?.pageInfo.hasPreviousPage,
                    filters,
                    searchTerm: search,
                    orderBy,
                  }),
              ),
            ).catch((error: unknown) => {
              // eslint-disable-next-line no-console
              console.error(
                "Failure when prefetching map list materials",
                error,
              )
            }),
          [limit, accessToken, place, plantMaterials, filters, search, orderBy],
        ),
        1000,
      )

      return plantMaterials
    },
)

export type UseMapListMaterialsResult = ReturnType<typeof useMapListMaterials>
export type UseMapListMaterialsError = OE.ExtractLeft<UseMapListMaterialsResult>
export type UseMapListMaterialsSuccess =
  OE.ExtractRight<UseMapListMaterialsResult>

export const fetchMeGarden = authedFetcher<
  GetMeGardenQueryVariables,
  GetMeGardenQuery
>(GET_ME_GARDEN)("GetMeGarden", noGQLVars)

export const useMeGarden = createAuthedHook(
  createWrappedSWRHook(fetchMeGarden, toError),
)
