import {colors} from "src/colors"
import type {IconProps} from "./types"

export const Tags = ({
  color = colors.primary[600],
  ...props
}: IconProps): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_878_45635)">
        <path
          d="M0.883789 2.1333V7.34747C0.88371 8.01034 1.14689 8.6461 1.61546 9.11497L11.2496 18.75C11.484 18.9843 11.8019 19.116 12.1334 19.116C12.4648 19.116 12.7827 18.9843 13.0171 18.75L18.7496 13.0166C18.984 12.7822 19.1156 12.4643 19.1156 12.1329C19.1156 11.8014 18.984 11.4835 18.7496 11.2491L9.11546 1.61497C8.64659 1.1464 8.01082 0.883221 7.34796 0.883301H2.13379C1.80227 0.883301 1.48433 1.015 1.24991 1.24942C1.01549 1.48384 0.883789 1.80178 0.883789 2.1333V2.1333Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.00879 5.2583C4.00879 5.42245 4.04112 5.585 4.10394 5.73666C4.16676 5.88831 4.25883 6.02611 4.37491 6.14218C4.49098 6.25826 4.62878 6.35033 4.78043 6.41315C4.93209 6.47597 5.09464 6.5083 5.25879 6.5083C5.42294 6.5083 5.58549 6.47597 5.73714 6.41315C5.8888 6.35033 6.0266 6.25826 6.14267 6.14218C6.25875 6.02611 6.35082 5.88831 6.41364 5.73666C6.47646 5.585 6.50879 5.42245 6.50879 5.2583C6.50879 5.09415 6.47646 4.9316 6.41364 4.77995C6.35082 4.62829 6.25875 4.49049 6.14267 4.37442C6.0266 4.25834 5.8888 4.16627 5.73714 4.10345C5.58549 4.04063 5.42294 4.0083 5.25879 4.0083C5.09464 4.0083 4.93209 4.04063 4.78043 4.10345C4.62878 4.16627 4.49098 4.25834 4.37491 4.37442C4.25883 4.49049 4.16676 4.62829 4.10394 4.77995C4.04112 4.9316 4.00879 5.09415 4.00879 5.2583Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_878_45635">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
