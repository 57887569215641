import {createContext, useContext} from "react"
import type {RecordTagFieldsFragment} from "../../../../../generated/graphql"

type OpenEditDrawerHandler = {
  openEditDrawer: (props: CurrentTag) => void
  openDeleteModal: (tag: RecordTagFieldsFragment) => void
} | null

export type CurrentTag = Omit<RecordTagFieldsFragment, "__typename">
export const DrawerContext = createContext<OpenEditDrawerHandler>(null)
export function useDrawContext(): OpenEditDrawerHandler {
  return useContext(DrawerContext)
}
