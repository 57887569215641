import {pipe} from "fp-ts/function"
import type {UseQueryArgs, UseQueryResponse} from "urql"
import type {GetMaterialQueryVariables} from "../../../../generated/graphql"
import {useGetMaterialQuery} from "../../../../generated/graphql"
import {usePlace} from "../../../utils/hooks/place"
import * as OE from "../../../utils/option-either"

type useMaterialVars = Omit<
  GetMaterialQueryVariables,
  "organisationSubdomain" | "collectionSiteSlug"
>

export type UrqlRefetch = UseQueryResponse[1]

export const useGetMaterial = (
  vars: Omit<UseQueryArgs<useMaterialVars>, "query"> & {
    variables: useMaterialVars
  },
) => {
  const place = pipe(usePlace(), OE.toStruct)
  const pause = place.loading || vars.pause === true

  const [res, refetchMaterial] = useGetMaterialQuery({
    ...vars,
    pause,
    variables: {
      organisationSubdomain: place.data?.orgName ?? "",
      collectionSiteSlug: place.data?.siteSlug ?? "",
      ...vars.variables,
    },
  })

  const error =
    place.error == null
      ? res.error == null
        ? res.data?.org?.site?.result == null && !res.fetching && !pause
          ? {type: "MATERIAL_NOT_FOUND" as const}
          : undefined
        : res.error
      : place.error

  return [{...res, error}, refetchMaterial] as const
}
