import type {KeyboardEvent as ReactKeyboardEvent, SyntheticEvent} from "react"
import {memo, useCallback} from "react"
import {IconButton} from "@hortis/ui/button"
import {SearchMd, XClose} from "@hortis/ui/icons"
import {InputAdornment} from "../../../../components/adornment"
import {Tooltip} from "../../../../components/tooltip"

export type SearchEndAdornmentEvent =
  | SyntheticEvent<HTMLElement | MouseEvent>
  | ReactKeyboardEvent<HTMLElement>

interface Props {
  showClear: boolean
  handleClear: (_e: unknown) => void
  handleSubmit: (_e: unknown) => void
}

export const SearchEndAdornments = memo(function SearchEndAdornments({
  showClear,
  handleClear,
  handleSubmit,
}: Props) {
  const handleSubmitCallback = useCallback(
    (e: SearchEndAdornmentEvent) => {
      handleSubmit(e)
      e.preventDefault()
    },
    [handleSubmit],
  )

  return (
    <InputAdornment position="end" className="flex items-center gap-0.5 pr-0.5">
      {showClear ? (
        <Tooltip title="Clear" placement="top">
          <IconButton
            onClick={handleClear}
            testId="search-clear"
            ariaLabel="Clear"
            size="sm"
            className="!rounded !p-1"
            variant="tertiaryGray"
            icon={XClose}
          />
        </Tooltip>
      ) : null}
      <Tooltip title="Search" placement="top">
        <IconButton
          ariaLabel="Search"
          testId="search-button"
          type="submit"
          size="sm"
          className="!rounded !p-1"
          variant="tertiaryGray"
          onClick={handleSubmitCallback}
          icon={SearchMd}
        />
      </Tooltip>
    </InputAdornment>
  )
})
