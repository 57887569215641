import type {ReactElement} from "react"
import {useCallback, useMemo, useRef, useState} from "react"
import {Button} from "@hortis/ui/button"
import {ToggleChip} from "src/components/toggle/toggle-chip"
import {AddPlantMaterialFilter} from "./add-plant-material-filter"
import {MaterialFilterChipGroup} from "./filter-chip-group"
import {MaterialFilterModals} from "./modals/filter-modals"
import type {MaterialFilterType, PlantMaterialsFiltersProps} from "./types"

export interface OpenFilterArgs<T> {
  filterType: T | false
  anchorElRef?: HTMLElement | null
  editFilterId?: string
}

export const PlantMaterialsFilters = ({
  excludeAbsentPlantMaterial,
  onChange,
  filters,
  dispatchFilters,
  resetFilters,
  filterCount,
  hideAbsentFilter,
  hideClear,
}: Omit<PlantMaterialsFiltersProps, "orderBy" | "updateOrderBy"> & {
  hideClear?: boolean
}): ReactElement => {
  const [openFilter, setOpenFilter] = useState<false | MaterialFilterType>(
    false,
  )
  const [editFilterId, setEditFilterId] = useState<string | null>(null)
  const filterModalAnchorEl = useRef<HTMLElement | null>(null)
  const editFilter = useMemo(
    () =>
      editFilterId == null
        ? undefined
        : filters.and.find(({id}) => id === editFilterId),
    [filters, editFilterId],
  )

  const resetAllFilters = useCallback(() => {
    resetFilters()
  }, [resetFilters])

  const handleOpenFilter = useCallback(
    ({
      filterType,
      anchorElRef,
      editFilterId,
    }: OpenFilterArgs<MaterialFilterType>) => {
      setEditFilterId(editFilterId ?? null)
      if (anchorElRef != null) {
        filterModalAnchorEl.current = anchorElRef
      }
      setOpenFilter(filterType)
    },
    [],
  )
  const handleFilterModalClose = useCallback(() => {
    setOpenFilter(false)
  }, [])

  return (
    <div className="flex flex-1 items-start justify-between gap-3">
      <div className="flex flex-1 flex-wrap items-center gap-3">
        {hideAbsentFilter !== true && (
          <ToggleChip
            onChange={onChange}
            checked={excludeAbsentPlantMaterial}
            testId="absent-material-switch"
          >
            Exclude &apos;absent&apos; material
          </ToggleChip>
        )}
        <MaterialFilterChipGroup
          handleOpenFilter={handleOpenFilter}
          filters={filters.and}
          dispatchFilter={dispatchFilters}
        />
        <AddPlantMaterialFilter setOpenFilter={handleOpenFilter} iconButton />
        <MaterialFilterModals
          editFilter={editFilter}
          openFilter={openFilter}
          handleFilterModalClose={handleFilterModalClose}
          filterModalAnchorEl={filterModalAnchorEl}
          dispatchFilters={dispatchFilters}
        />
      </div>
      {hideClear !== true && (
        <div className="flex items-center gap-3">
          {filterCount > 1 && (
            <Button
              onClick={resetAllFilters}
              className="flex-shrink-0"
              variant="tertiaryColor"
              size="sm"
            >
              Clear filters
            </Button>
          )}
        </div>
      )}
    </div>
  )
}
