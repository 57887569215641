import {type DateStringFormatter} from "src/utils/hooks/language"

export const createDateEqualFilterText = (
  text: string,
  formatDateString: DateStringFormatter,
) => {
  const date = new Date(text)
  return [formatDateString(date.toISOString()) ?? date.toLocaleDateString()]
}
export const createDateRangeFilterText = (
  filter: {from: string; to: string},
  formatDateString: DateStringFormatter,
) => {
  const {from, to} = filter
  const fromDate = new Date(from)
  const toDate = new Date(to)
  return [
    `${
      formatDateString(fromDate.toISOString()) ?? fromDate.toLocaleDateString()
    } - ${
      formatDateString(toDate.toISOString()) ?? toDate.toLocaleDateString()
    }`,
  ]
}
