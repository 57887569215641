import {
  ContextMenuGroup,
  ContextMenuItem,
  ContextMenuSub,
  ContextMenuSubContent,
  ContextMenuSubTrigger,
} from "@hortis/ui/context-menu"

import {Copy1} from "@hortis/ui/icons/copy-1"
import {type AccessionsListFieldsFragment} from "generated/graphql"
import {useSnackbarStore} from "src/components/snackbar-controller/snackbar-store"
import {onFailure, onSuccess} from "src/notification-snack-utils"
import {copyToClipboard} from "src/utils/dom/copy-to-clipboard"

type AccessionlListFragmentWithTaxon = Omit<
  AccessionsListFieldsFragment,
  "taxon"
> & {
  taxon: NonNullable<AccessionsListFieldsFragment["taxon"]>
}

function joinAccessionProperties<T>(
  accessions: ReadonlyArray<AccessionlListFragmentWithTaxon>,
  propertyExtractor: (
    accession: AccessionlListFragmentWithTaxon,
  ) => T | null | undefined,
): string {
  return accessions
    .map(propertyExtractor)
    .filter((property): property is T => property != null)
    .join("\n")
}

export const AccessionCopyContextMenuSub = ({
  accessions,
}: {
  accessions: ReadonlyArray<AccessionsListFieldsFragment>
}) => {
  const {setSnack} = useSnackbarStore()

  const filteredAccessions = accessions.filter(
    (accession): accession is AccessionlListFragmentWithTaxon =>
      accession.taxon != null,
  )

  const accessionNumbers = accessions
    .map((accession) => accession.accessionNumber)
    .join("\n")

  const scientificNames = joinAccessionProperties(
    filteredAccessions,
    (accession) => accession.taxon.scientificName,
  )
  const commonNames = joinAccessionProperties(
    filteredAccessions,
    (accession) => accession.taxon.commonName,
  )

  return (
    <ContextMenuSub>
      <ContextMenuSubTrigger data-cy="copy-menu-item">
        <Copy1 className="h-4 w-4 text-grey-500" />
        Copy
      </ContextMenuSubTrigger>
      <ContextMenuSubContent alignOffset={-46}>
        <ContextMenuGroup>
          <ContextMenuItem
            onSelect={() => {
              void copyToClipboard(
                accessionNumbers,
                () => {
                  onSuccess(setSnack)(
                    accessions.length > 1
                      ? `${accessions.length} accession numbers copied to clipboard`
                      : `"${accessionNumbers}" copied to clipboard`,
                  )
                },
                () => {
                  // eslint-disable-next-line sonarjs/no-duplicate-string
                  onFailure(setSnack)(new Error("Failed to copy"))
                },
              )
            }}
          >
            <Copy1 className="h-4 w-4 text-grey-500" />
            {`Copy accession number${accessions.length > 1 ? "s" : ""}`}
          </ContextMenuItem>
          <ContextMenuItem
            onSelect={() => {
              void copyToClipboard(
                scientificNames,
                () => {
                  onSuccess(setSnack)(
                    accessions.length > 1
                      ? `${accessions.length} scientific names copied to clipboard`
                      : `"${scientificNames}" copied to clipboard`,
                  )
                },
                () => {
                  onFailure(setSnack)(new Error("Failed to copy"))
                },
              )
            }}
          >
            <Copy1 className="h-4 w-4 text-grey-500" />
            {`Copy scientific name${accessions.length > 1 ? "s" : ""}`}
          </ContextMenuItem>

          <ContextMenuItem
            onSelect={() => {
              void copyToClipboard(
                commonNames,
                () => {
                  onSuccess(setSnack)(
                    accessions.length > 1
                      ? `${accessions.length} common names copied to clipboard`
                      : `"${commonNames}" copied to clipboard`,
                  )
                },
                () => {
                  onFailure(setSnack)(new Error("Failed to copy"))
                },
              )
            }}
          >
            <Copy1 className="h-4 w-4 text-grey-500" />
            {`Copy common name${accessions.length > 1 ? "s" : ""}`}
          </ContextMenuItem>
        </ContextMenuGroup>
      </ContextMenuSubContent>
    </ContextMenuSub>
  )
}
