import {zodResolver} from "@hookform/resolvers/zod"
import {useMemo} from "react"
import {FormProvider} from "react-hook-form"
import {match} from "ts-pattern"
import {z} from "zod"
import {FilterTitle} from "src/features/filters/components/modal/filter-title"
import {FormTextField} from "src/components/text-field"
import {
  CollapsableRadioButton,
  RadioButton,
  RadioGroup,
} from "src/components/radio"
import type {NullableStringComparator} from "generated/graphql"
import type {FilterModalProps} from "../modal/filter-modal"
import {FilterModal} from "../modal/filter-modal"
import {FilterModalButtons} from "../modal/modal-buttons"
import {FilterModalError} from "../modal/modal-error"
import type {FilterFormProps} from "../../plant-materials/modals/types"
import {determineInitialOperation} from "../../plant-materials/modals/determine-initial-operation"
import {useFilterForm} from "../modal/use-filter-form"

type InitialValue = NullableStringComparator | null | undefined

const createDefaultValues = (initialValue: InitialValue) => {
  const defaultValues = {
    contains: initialValue?.contains ?? undefined,
    hasNoValue: initialValue?.null ?? undefined,
  }
  return {
    defaultValues,
    initialOperation: determineInitialOperation(defaultValues, "contains"),
  }
}

const schema = z.object({
  contains: z.string().nullable().optional(),
  hasNoValue: z.boolean().nullable().optional(),
})

export type LocalNullableStringFilter = z.TypeOf<typeof schema>

interface NotesFilterFormProps {
  addModifyFilter: (changes: NullableStringComparator) => void
  deleteFilter: (() => void) | undefined
  initialValue: InitialValue
  filterTitle: string
  testIdPrefix?: string
}

const NullableStringFilterForm = ({
  addModifyFilter,
  deleteFilter,
  initialValue,
  onClose,
  filterTitle,
  testIdPrefix: _testIdPrefix,
}: Omit<FilterFormProps, "dispatchFilters" | "initialValue"> &
  NotesFilterFormProps) => {
  const {error, setError, operation, handleRadioChange, formMethods} =
    useFilterForm({
      resolver: zodResolver(schema),
      ...createDefaultValues(initialValue),
    })

  const onSubmit = useMemo(
    () =>
      formMethods.handleSubmit((values) => {
        match(operation)
          .with("contains", () => {
            if (values.contains == null) {
              setError("Enter a value to continue")
            } else {
              addModifyFilter({
                contains: values.contains,
              })
              onClose()
            }
          })
          .with("hasNoValue", () => {
            addModifyFilter({
              null: true,
            })
            onClose()
          })
          .run()
      }),
    [formMethods, operation, onClose, addModifyFilter, setError],
  )

  const testIdPrefix =
    _testIdPrefix ?? filterTitle.toLowerCase().replaceAll(" ", "-")

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={onSubmit}>
        <div className="mb-4 flex flex-col gap-4">
          <FilterTitle title={filterTitle} deleteFilter={deleteFilter} />
          <FilterModalError error={error} />
          <RadioGroup
            value={operation}
            onChange={handleRadioChange}
            label="Operation"
          >
            {(props) => (
              <>
                <CollapsableRadioButton
                  selectedValue="contains"
                  testId={`${testIdPrefix}-contains-radio`}
                  label="Contains"
                  {...props}
                >
                  <FormTextField
                    name="contains"
                    label="Contains"
                    fullWidth
                    hiddenLabel
                    testId={`${testIdPrefix}-contains-text-field`}
                  />
                </CollapsableRadioButton>
                <RadioButton
                  label="Has no value"
                  selectedValue="hasNoValue"
                  testId={`${testIdPrefix}-has-no-value-radio`}
                  {...props}
                />
              </>
            )}
          </RadioGroup>
        </div>
        <FilterModalButtons onCancel={onClose} testIdPrefix={testIdPrefix} />
      </form>
    </FormProvider>
  )
}

// Seperated from form component to make sure popover unmount causes state reset
export const NullableStringFilterModal = ({
  open,
  onClose,
  anchorEl,
  ...props
}: Omit<FilterFormProps, "initialValue" | "dispatchFilters"> &
  FilterModalProps &
  NotesFilterFormProps) => (
  <FilterModal anchorEl={anchorEl} open={open} onClose={onClose}>
    <NullableStringFilterForm onClose={onClose} {...props} />
  </FilterModal>
)
