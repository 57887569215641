import {Button} from "@hortis/ui/button"
import {ChevronRight, Plus, SearchMd, Table, Trash3} from "@hortis/ui/icons"
import {Link} from "@tanstack/react-router"
import type {
  AccessionsSearchTerm,
  PlantMaterialsSearchTerm,
  TaxaSearchTerm,
} from "generated/graphql"
import {Fragment} from "react"
import {
  EmptyState,
  EmptyStateActions,
  EmptyStateBody,
  EmptyStateHeader,
  EmptyStateTitle,
} from "src/components/empty-state"
import {FeaturedIcon} from "src/components/featured-icon/featured-icon"
import {useAccessRole} from "src/features/permissions/use-access-role"
import {useCollectionSiteHrefs} from "src/utils/hooks/hrefs"

type InputType = "accession" | "material" | "taxon"
const getEmptyStateBody = (
  type: InputType,
  searchTerm:
    | PlantMaterialsSearchTerm
    | AccessionsSearchTerm
    | TaxaSearchTerm
    | undefined,
  filtersActive: boolean | undefined,
  canEdit: boolean,
  // eslint-disable-next-line sonarjs/cognitive-complexity
) =>
  searchTerm != null && searchTerm.value !== ""
    ? `Your search “${
        searchTerm.value
      }” did not match any records. Please try again${
        canEdit ? ` or create a new ${type}` : ""
      }.`
    : filtersActive === true
    ? `Your filters did not match any records. Please try again${
        canEdit ? ` or create a new ${type}` : ""
      }.`
    : `Your ${
        type === "accession"
          ? "accessions"
          : type === "material"
          ? "materials"
          : "taxa"
      } will live here.${
        canEdit ? ` Start creating your own by clicking “New ${type}”.` : ""
      }`

interface EmptyMaterialListProps<T> {
  list: Array<T> | ReadonlyArray<T> | undefined
  searchTerm:
    | PlantMaterialsSearchTerm
    | AccessionsSearchTerm
    | TaxaSearchTerm
    | undefined
  clearSearch: () => void
  type: InputType
  filtersActive?: boolean
  isTrash?: boolean
}

const pluralizeType = (type: InputType) => {
  if (type === "accession") {
    return "accessions"
  } else if (type === "material") {
    return "materials"
  } else {
    return "taxa"
  }
}

const generateHref = ({
  hrefs,
  type,
}: {
  hrefs: NonNullable<ReturnType<typeof useCollectionSiteHrefs>>
  type: InputType
}) => {
  if (type === "accession") {
    return hrefs.accessionsCreate
  } else if (type === "material") {
    return hrefs.materialsNew
  } else {
    return `/taxonomy/new`
  }
}
const trashPageHref = ({
  hrefs,
  type,
}: {
  hrefs: NonNullable<ReturnType<typeof useCollectionSiteHrefs>>
  type: Pick<InputType, Exclude<keyof InputType, "taxon">>
}) => {
  return type === "accession"
    ? hrefs.accessionCollection
    : hrefs.plantMaterialCollection
}

export const EmptyList = <T,>({
  list,
  searchTerm,
  clearSearch,
  type,
  filtersActive,
  isTrash = false, // eslint-disable-next-line sonarjs/cognitive-complexity
}: EmptyMaterialListProps<T>) => {
  const {canEdit} = useAccessRole()
  const hrefs = useCollectionSiteHrefs()

  const searchActive =
    (searchTerm != null && searchTerm.value !== "") || filtersActive === true

  const pluralizedType = pluralizeType(type)

  return list != null && list.length === 0 && hrefs !== undefined ? (
    <div
      className="mx-auto my-12 max-w-md justify-self-center p-6 md:my-24"
      data-cy={`${type}-empty-list`}
    >
      {isTrash ? (
        <EmptyState>
          <EmptyStateHeader>
            <FeaturedIcon>
              <Trash3 className="stroke-primary-600" />
            </FeaturedIcon>
            <EmptyStateTitle>No {pluralizedType} in the trash</EmptyStateTitle>
            <EmptyStateBody>
              {searchActive
                ? getEmptyStateBody(type, searchTerm, filtersActive, canEdit)
                : `Your trash bin is currently empty. Any ${pluralizedType} moved to the trash will appear here.`}
            </EmptyStateBody>
          </EmptyStateHeader>
          <EmptyStateActions>
            {searchActive && (
              <Button onClick={clearSearch} testId="reset-search-button">
                Reset search & filters
              </Button>
            )}
            {canEdit && (
              <Button
                asChild
                onClick={clearSearch}
                testId={`all-${type}-button`}
                endIcon={ChevronRight}
                variant="primary"
              >
                <Link
                  to={`/${trashPageHref({hrefs, type})}`}
                  from="/sites/$siteSlug/trash"
                >
                  Go to all {pluralizedType}
                </Link>
              </Button>
            )}
          </EmptyStateActions>
        </EmptyState>
      ) : (
        <EmptyState>
          <EmptyStateHeader>
            <FeaturedIcon>
              {searchActive ? (
                <SearchMd className="text-primary-600" />
              ) : (
                <Table className="text-primary-600" />
              )}
            </FeaturedIcon>
            <EmptyStateTitle>{`No ${pluralizedType} found`}</EmptyStateTitle>
            <EmptyStateBody>
              {getEmptyStateBody(type, searchTerm, filtersActive, canEdit)}
            </EmptyStateBody>
          </EmptyStateHeader>
          <EmptyStateActions>
            {searchActive && (
              <Button onClick={clearSearch} testId="reset-search-button">
                Reset search & filters
              </Button>
            )}
            {canEdit && (
              <Button
                asChild
                onClick={clearSearch}
                testId={`new-${type}-button`}
                variant="primary"
                startIcon={Plus}
              >
                <Link
                  to={`/${generateHref({hrefs, type})}`}
                  from="/sites/$siteSlug/collection"
                >
                  New {type}
                </Link>
              </Button>
            )}
          </EmptyStateActions>
        </EmptyState>
      )}
    </div>
  ) : (
    <Fragment />
  )
}
