import Drawer from "@mui/material/Drawer"
import type {ReactNode} from "react"
import {useFirstSiteHrefs} from "../../../components/nav-layout/utils"
import withAuthenticationRequired from "../../../utils/with-authentication-required"
import {useSettingsReturnToStore} from "../return-to-store"
import {DrawerContent} from "./drawer-content"
import {MobileLayout} from "./mobile-layout"

const useReturnTo = () => {
  const {returnTo} = useSettingsReturnToStore()
  const collectionSiteHrefs = useFirstSiteHrefs()

  /*
   * First check global store for a return to, fallback to user's first site dashboard
   * finally if all that fails, default to root url
   */
  return (
    returnTo ??
    (collectionSiteHrefs?.dashboard == null
      ? "/"
      : `/${collectionSiteHrefs.dashboard}`)
  )
}

const SettingsNavLayout = ({children}: {children?: ReactNode}): JSX.Element => {
  return (
    <div className="flex h-full min-h-[calc(var(--vh,1vh)*100)]">
      <div className="hidden lg:block">
        <Drawer
          data-cy="nav-drawer"
          variant="permanent"
          className="w-56"
          PaperProps={{className: "!z-50 w-56 border-grey-200 shadow-xs"}}
        >
          <DrawerContent returnTo={useReturnTo()} />
        </Drawer>
      </div>
      <div className="flex flex-1 flex-col items-center">
        <MobileLayout returnTo={useReturnTo()} />
        <main className="flex h-full w-full flex-1 flex-col items-center">
          {children}
        </main>
      </div>
    </div>
  )
}

export default withAuthenticationRequired(SettingsNavLayout, {
  onRedirecting: function OnRedirect() {
    return <SettingsNavLayout />
  },
})
