import {pipe} from "fp-ts/function"
import * as Rec from "fp-ts/Record"
import type {MaterialFieldsFragment} from "../../../../../../generated/graphql"
import {createBindCallbackWithVoidReturn} from "../../../../../utils/hooks/callback"
import {camelCasePrefix} from "../../../../../utils/struct"

export enum MaterialEditStage {
  overview,
  editDetails,
}
export const MaterialEditStageCallback = pipe(
  MaterialEditStage,
  camelCasePrefix("use"),
  Rec.map((stage: MaterialEditStage) =>
    createBindCallbackWithVoidReturn(stage),
  ),
)

export interface BaseEditStageProps {
  material: MaterialFieldsFragment
  transitionStage: (stage: MaterialEditStage) => void
  requestClose: () => void
}
