import {
  ChevronLeft,
  ChevronRight,
  Edit4,
  InfoCircle,
  Star1,
  Trash3,
  XClose,
} from "@hortis/ui/icons"
import {TwicImg} from "@twicpics/components/react"
import {
  useArchiveMaterialImageMutation,
  useSetPrimaryImageMutation,
  useUnsetPrimaryImageMutation,
  type MaterialImageFieldsFragment,
} from "generated/graphql"
import {useState, type KeyboardEventHandler, type ReactNode} from "react"
import {useAccessRole} from "src/features/permissions/use-access-role"
import {ValuePair} from "src/features/taxonomy/components/taxon-page/value-pair"
import {onFailure, onSuccess} from "src/notification-snack-utils"
import {useMobile} from "src/utils/hooks/media-query"
import {Button, IconButton} from "@hortis/ui/button"
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@hortis/ui/dialog"
import {Tooltip, TooltipContent, TooltipTrigger} from "@hortis/ui/tooltip"
import {EditPhotoSheet} from "../sheet/variants/edit-photo"
import {useSnackbarStore} from "../snackbar-controller/snackbar-store"

interface DeleteDialogProps {
  materialImageId: string
  children: ReactNode
  onDelete?: (id: string) => void
}

export const DeleteDialog = ({
  materialImageId,
  children,
  onDelete,
}: DeleteDialogProps) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [{fetching: archiveMaterialImageFetching}, archiveMaterialImage] =
    useArchiveMaterialImageMutation()
  const {setSnack} = useSnackbarStore()

  const handleDeletePhoto = async () => {
    const res = await archiveMaterialImage({materialImageId})
    if (
      res.error != null ||
      res.data?.archiveMaterialImage?.__typename !== "MaterialImage"
    ) {
      onFailure(setSnack)(res.error ?? new Error("Failed to delete photo"))
      return
    }
    onSuccess(setSnack)("Successfully deleted photo")
    setDeleteDialogOpen(false)
    if (onDelete != null) {
      onDelete(materialImageId)
    }
  }

  return (
    <Dialog open={deleteDialogOpen} onOpenChange={setDeleteDialogOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Delete photo</DialogTitle>
          <DialogDescription>
            Are you sure you want to delete this photo?
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <DialogClose asChild>
            <Button size="lg">Cancel</Button>
          </DialogClose>
          <Button
            variant="destructive"
            size="lg"
            onClick={handleDeletePhoto}
            loading={archiveMaterialImageFetching}
            testId="confirm-delete-photo-button"
          >
            Delete
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

const mobileButtonStyles = {
  className:
    "bg-transparent disabled:bg-transparent border-none hover:bg-grey-900/30",
  iconProps: {className: "stroke-grey-300"},
}

type Photo = MaterialImageFieldsFragment

export interface LightboxProps {
  title?: string
  photos?: Array<Photo> | ReadonlyArray<Photo> | undefined | null
  photo: Photo
  children: ReactNode
}

const LightboxTrigger = DialogTrigger

// eslint-disable-next-line sonarjs/cognitive-complexity
const Lightbox = ({title, photos, photo, children}: LightboxProps) => {
  const {canEdit} = useAccessRole()
  const isMobile = useMobile()
  const [open, setOpen] = useState(false)
  const [direction, setDirection] = useState<"left" | "right">("right")
  const [loading, setLoading] = useState(false)
  const [currentPhotoId, setCurrentPhotoId] = useState(photo.id)
  const [{fetching: setPrimaryImageFetching}, setPrimaryImage] =
    useSetPrimaryImageMutation()
  const [{fetching: unsetPrimaryImageFetching}, unsetPrimaryImage] =
    useUnsetPrimaryImageMutation()

  const multi = photos != null && photos.length > 1
  const photoIndex = photos?.findIndex(({id}) => id === currentPhotoId) ?? 0
  const currentPhoto = multi
    ? photos.find(({id}) => id === currentPhotoId) ?? photo
    : photo

  const src = `${currentPhoto.bucketName}/${currentPhoto.fileName}`

  const onOpenChange = (open: boolean) => {
    if (!open) {
      setCurrentPhotoId(photo.id)
    }
    setOpen(open)
  }

  const handleBack = () => {
    setCurrentPhotoId(photos?.[photoIndex - 1]?.id ?? photo.id)
    setDirection("left")
  }

  const handleNext = () => {
    setCurrentPhotoId(photos?.[photoIndex + 1]?.id ?? photo.id)
    setDirection("right")
  }

  const onKeyDown: KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (multi) {
      if (e.key === "ArrowLeft" && photoIndex > 0) {
        handleBack()
        setDirection("left")
      } else if (e.key === "ArrowRight" && photoIndex < photos.length - 1) {
        handleNext()
        setDirection("right")
      }
    }
  }

  const showBack = multi && photoIndex > 0
  const showNext = multi && photoIndex < photos.length - 1
  const isFeatured = currentPhoto.isPrimaryImage === true

  const infoContent = (
    <>
      <div className="flex items-center justify-between gap-3 border-b border-grey-200 py-3 pl-6 pr-4">
        <span
          className="truncate font-medium text-grey-900"
          data-cy="photo-lightbox-title"
        >
          {title}
        </span>
        <DialogClose asChild>
          <IconButton
            icon={XClose}
            ariaLabel="Close"
            variant="tertiaryGray"
            testId="lightbox-close-button"
            size="sm"
          />
        </DialogClose>
      </div>
      <div className="flex flex-1 flex-col gap-5 p-6">
        <ValuePair label="Description" breakPoint="mobile">
          {currentPhoto.description ?? "-"}
        </ValuePair>
        <ValuePair label="Captured by" breakPoint="mobile">
          {currentPhoto.capturedBy ?? "-"}
        </ValuePair>
        <ValuePair label="Captured at" breakPoint="mobile">
          {currentPhoto.capturedAt ?? "-"}
        </ValuePair>
        <ValuePair label="Uploaded by" breakPoint="mobile">
          {currentPhoto.uploadedBy?.givenName ?? "-"}
        </ValuePair>
      </div>
    </>
  )

  const toggleFeatured = async () => {
    // eslint-disable-next-line unicorn/prefer-ternary
    if (isFeatured) {
      await unsetPrimaryImage({
        materialImageId: currentPhoto.id,
      })
    } else {
      await setPrimaryImage({
        materialImageId: currentPhoto.id,
      })
    }
  }

  const toggleFeaturedFetching =
    setPrimaryImageFetching || unsetPrimaryImageFetching

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      {children}
      {isMobile ? (
        <DialogContent
          onKeyDown={onKeyDown}
          className="size-full w-full max-w-none p-0 sm:p-0"
        >
          <div className="relative flex w-full flex-1 items-center justify-center overflow-hidden bg-grey-900">
            <TwicImg
              src={src}
              mode="cover"
              className="absolute size-full [&_.twic-w]:size-full [&_img]:size-full"
              preTransform="quality=1"
            />
            <TwicImg
              src={src}
              mode="contain"
              className="size-full [&_.twic-w]:flex [&_.twic-w]:max-h-full [&_.twic-w]:items-center [&_.twic-w]:justify-center [&_.twic-w]:overflow-hidden [&_.twic-w]:bg-grey-900/70 [&_.twic-w]:backdrop-blur-lg [&_.twic-w]:lg:rounded-l-lg [&_img]:max-h-full"
              ratio="none"
              onStateChange={(state) => {
                setLoading(state.state === "loading")
              }}
            />
            <DialogClose asChild className="absolute right-4 top-4">
              <IconButton
                {...mobileButtonStyles}
                icon={XClose}
                ariaLabel="Close"
                variant="tertiaryGray"
                size="sm"
                testId="lightbox-close-button"
              />
            </DialogClose>
          </div>
          <div
            className="absolute bottom-0 flex w-full items-center justify-between p-4"
            data-cy="photo-lightbox-actions"
          >
            {showBack ? (
              <IconButton
                {...mobileButtonStyles}
                icon={ChevronLeft}
                ariaLabel="Previous photo"
                size="lg"
                onClick={handleBack}
                loading={loading && direction === "left"}
                disabled={loading}
              />
            ) : (
              <div className="size-11" />
            )}
            {canEdit && (
              <>
                <Dialog>
                  <DialogTrigger asChild>
                    <IconButton
                      {...mobileButtonStyles}
                      icon={InfoCircle}
                      ariaLabel="Info"
                      size="lg"
                    />
                  </DialogTrigger>
                  <DialogContent className="fixed bottom-0 left-0 right-0 top-auto w-full transform-none !animate-none rounded-b-none rounded-t-md p-0 pb-20">
                    {infoContent}
                  </DialogContent>
                </Dialog>
                <IconButton
                  {...mobileButtonStyles}
                  ariaLabel={`Set as ${isFeatured ? "not " : ""}featured`}
                  onClick={toggleFeatured}
                  icon={
                    isFeatured ? (
                      <Star1
                        className="fill-grey-300 stroke-grey-300"
                        size="20px"
                      />
                    ) : (
                      Star1
                    )
                  }
                  testId="lightbox-toggle-featured-button"
                  loading={toggleFeaturedFetching}
                />
                <EditPhotoSheet initialValue={currentPhoto}>
                  <IconButton
                    {...mobileButtonStyles}
                    icon={Edit4}
                    ariaLabel="Edit"
                    size="lg"
                    testId="lightbox-edit-button"
                  />
                </EditPhotoSheet>
                <DeleteDialog materialImageId={currentPhoto.id}>
                  <IconButton
                    {...mobileButtonStyles}
                    icon={Trash3}
                    ariaLabel="Delete"
                    size="lg"
                    testId="lightbox-delete-button"
                  />
                </DeleteDialog>
              </>
            )}
            {showNext ? (
              <IconButton
                {...mobileButtonStyles}
                icon={ChevronRight}
                size="lg"
                ariaLabel="Next photo"
                onClick={handleNext}
                loading={loading && direction === "right"}
                disabled={loading}
              />
            ) : (
              <div className="size-11" />
            )}
          </div>
        </DialogContent>
      ) : (
        <DialogContent
          className="flex h-[calc(100%_-_32px)] max-h-full w-[calc(100%_-_32px)] max-w-full flex-row overflow-hidden p-0 outline-none sm:gap-0 sm:p-0"
          onKeyDown={onKeyDown}
        >
          <div className="relative flex h-full flex-1 items-center justify-center overflow-hidden bg-grey-900">
            <TwicImg
              src={src}
              mode="cover"
              className="absolute size-full [&_.twic-w]:size-full [&_img]:size-full"
              preTransform="quality=1"
              placeholder="none"
            />
            <TwicImg
              src={src}
              mode="contain"
              className="size-full [&_.twic-w]:flex [&_.twic-w]:max-h-full [&_.twic-w]:items-center [&_.twic-w]:justify-center [&_.twic-w]:overflow-hidden [&_.twic-w]:bg-grey-900/70 [&_.twic-w]:backdrop-blur-lg [&_.twic-w]:lg:rounded-l-lg [&_img]:max-h-full"
              ratio="none"
              placeholder="none"
              onStateChange={(state) => {
                setLoading(state.state === "loading")
              }}
            />
            {showBack ? (
              <IconButton
                icon={ChevronLeft}
                size="lg"
                className="absolute left-6 border-none shadow-lg"
                ariaLabel="Previous photo"
                onClick={handleBack}
                loading={loading && direction === "left"}
                disabled={loading}
              />
            ) : null}
            {showNext ? (
              <IconButton
                icon={ChevronRight}
                size="lg"
                className="absolute right-6 border-none shadow-lg"
                ariaLabel="Next photo"
                onClick={handleNext}
                loading={loading && direction === "right"}
                disabled={loading}
              />
            ) : null}
          </div>
          <div className="flex w-96 flex-shrink-0 flex-col">
            {infoContent}
            {canEdit && (
              <div
                className="flex items-center gap-3 border-t border-grey-200 px-6 py-4"
                data-cy="photo-lightbox-actions"
              >
                <Tooltip>
                  <TooltipTrigger asChild>
                    <IconButton
                      size="sm"
                      ariaLabel={`Set as ${isFeatured ? "not " : ""}featured`}
                      onClick={toggleFeatured}
                      icon={
                        isFeatured ? (
                          <Star1
                            className="fill-yellow-400 stroke-yellow-400"
                            size="20px"
                          />
                        ) : (
                          Star1
                        )
                      }
                      loading={toggleFeaturedFetching}
                      testId="lightbox-toggle-featured-button"
                    />
                  </TooltipTrigger>
                  <TooltipContent>
                    Set as {isFeatured ? "not " : ""}featured
                  </TooltipContent>
                </Tooltip>
                <EditPhotoSheet initialValue={currentPhoto}>
                  <Button
                    startIcon={Edit4}
                    size="sm"
                    testId="lightbox-edit-button"
                  >
                    Edit
                  </Button>
                </EditPhotoSheet>
                <DeleteDialog materialImageId={currentPhoto.id}>
                  <Button
                    startIcon={Trash3}
                    size="sm"
                    testId="lightbox-delete-button"
                  >
                    Delete
                  </Button>
                </DeleteDialog>
              </div>
            )}
          </div>
        </DialogContent>
      )}
    </Dialog>
  )
}

export {Lightbox, LightboxTrigger}
