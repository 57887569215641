import {format} from "date-fns"
import type {HTMLAttributes} from "react"
import {useState} from "react"
import {twMerge} from "tailwind-merge"
import {Button} from "../button"
import {Calendar} from "../calendar"
import {Dialog, DialogContent, DialogTrigger} from "../dialog"
import {FormControl} from "../form"
import {useMediaQuery} from "../hooks/media-query"
import {Calendar as CalendarIcon} from "../icons"
import {Popover, PopoverContent, PopoverTrigger} from "../popover"

export const DatePicker = ({
  value,
  onChange,
  ...props
}: {
  value: Date | undefined
  onChange: (val: Date | undefined) => void
} & HTMLAttributes<HTMLButtonElement>) => {
  const isDesktop = useMediaQuery("lg")
  const [open, setOpen] = useState(false)

  const handleSelect = (val: Date | undefined) => {
    if (val != null) {
      onChange(val)
    }
    setOpen(false)
  }

  return isDesktop ? (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <FormControl>
          <Button
            className={twMerge("justify-between", !value && "text-grey-500")}
            endIcon={CalendarIcon}
            {...props}
          >
            {value ? format(value, "PPP") : <span>Pick a date</span>}
          </Button>
        </FormControl>
      </PopoverTrigger>
      <PopoverContent className="z-50 w-auto p-0" align="start">
        <Calendar
          data-cy="date-picker-calendar"
          mode="single"
          selected={value}
          onSelect={handleSelect}
          disabled={(date) =>
            date > new Date() || date < new Date("1900-01-01")
          }
          initialFocus
        />
      </PopoverContent>
    </Popover>
  ) : (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <FormControl>
          <Button
            className={twMerge("justify-between", !value && "text-grey-500")}
            endIcon={CalendarIcon}
            {...props}
          >
            {value ? format(value, "PPP") : <span>Pick a date</span>}
          </Button>
        </FormControl>
      </DialogTrigger>
      <DialogContent className="w-auto p-0">
        <Calendar
          data-cy="date-picker-calendar"
          mode="single"
          selected={value}
          onSelect={handleSelect}
          disabled={(date) =>
            date > new Date() || date < new Date("1900-01-01")
          }
          initialFocus
        />
      </DialogContent>
    </Dialog>
  )
}
