import {colors} from "src/colors"
import type {IconProps} from "./types"

export const SlChevronRightIcon = ({
  style,
  width,
  height,
  color,
  className,
}: IconProps): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? "24"}
      height={height ?? "24"}
      viewBox="0 0 24 24"
      fill="none"
      className={className}
      style={style}
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M9.46997 5L16.1402 11.6702C16.1835 11.7135 16.2179 11.7649 16.2414 11.8215C16.2649 11.8781 16.2769 11.9387 16.2769 12C16.2769 12.0613 16.2649 12.1219 16.2414 12.1785C16.2179 12.2351 16.1835 12.2865 16.1402 12.3298L9.46997 19"
        stroke={color ?? "#424242"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

// UntitledUI
export const ChevonRight = ({
  color = colors.grey[700],
  ...props
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M7.5 15L12.5 10L7.5 5"
      stroke={color}
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
