import type {NullableStringComparator, TaxaFilter} from "generated/graphql"

export const transformAuthorshipFilter = (
  filter: NullableStringComparator | undefined | null,
): TaxaFilter =>
  filter == null
    ? {}
    : {
        or:
          filter.contains == null
            ? null
            : [
                {
                  authorship: {contains: filter.contains},
                },
                {
                  authorship: {null: true},
                  sharedScientificName: {
                    authorship: {contains: filter.contains},
                  },
                },
              ],
        ...(filter.null === true
          ? {
              authorship: {null: true},
              sharedScientificName: {
                authorship: {null: true},
              },
            }
          : {}),
      }
