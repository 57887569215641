import {Button} from "@hortis/ui/button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandLoading,
} from "@hortis/ui/command"
import {Check, ChevronDown, Plus} from "@hortis/ui/icons"
import {Popover, PopoverContent, PopoverTrigger} from "@hortis/ui/popover"
import {Tag} from "@hortis/ui/tag"
import {useParams, useRouteContext} from "@tanstack/react-router"
import {useGetLocationsQuery} from "generated/graphql"
import {useState} from "react"
import {twMerge} from "tailwind-merge"
import {Dialog} from "@hortis/ui/dialog"
import type {LocationOption} from "src/features/create-accession/components/add-plant-material-form/schema"
import {NewLocationSheetContent} from "../location-drawer"

export const LocationPicker = ({
  value,
  onChange,
  "data-cy": dataCy,
}: {
  value: LocationOption | undefined | null
  onChange: (val: LocationOption | undefined | null) => void
  "data-cy"?: string
}) => {
  const [open, setOpen] = useState(false)
  const [initialLocationName, setInitialLocationName] = useState<
    string | undefined
  >()
  const [search, setSearch] = useState("")
  const [drawerOpen, setDrawerOpen] = useState(false)
  // eslint-disable-next-line sonarjs/no-duplicate-string
  const {subdomain} = useRouteContext({from: "/_layout/sites/$siteSlug"})
  const {siteSlug} = useParams({from: "/_layout/sites/$siteSlug"})
  const [{data, fetching}, refetch] = useGetLocationsQuery({
    variables: {
      subdomain,
      siteSlug,
    },
  })

  const handleChange = (location: LocationOption) => {
    if (value?.id === location.id) {
      onChange(null)
    } else {
      onChange(location)
    }
    setOpen(false)
  }

  const locations = data?.org?.site?.locationsConnection?.nodes

  return (
    <>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger className="mx-auto w-fit" asChild>
          <Button
            className={twMerge(
              "min-h-[44px] w-full justify-between !px-[14px] !py-2 text-grey-500",
            )}
            testId={dataCy}
          >
            {value == null ? (
              <div className="flex flex-1 items-center justify-between gap-2">
                Pick a location <ChevronDown className="text-grey-500" />
              </div>
            ) : (
              <div className="flex flex-1 flex-wrap items-center gap-2">
                <span className="truncate text-grey-700">{value.name}</span>
                <span className="flex-1 truncate text-left font-normal text-grey-500">
                  {value.description}
                </span>
                <ChevronDown className="text-grey-500" />
              </div>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="z-[99999] w-full min-w-[var(--radix-popover-trigger-width)]">
          <Command
            filter={(value, search, keywords) => {
              const extendValue = keywords?.join(" ") ?? ""
              if (
                extendValue.toLowerCase().includes(search.toLowerCase()) ||
                value === "new-location"
              ) {
                return 1
              }
              return 0
            }}
          >
            <CommandInput
              placeholder="Search..."
              value={search}
              onValueChange={setSearch}
            />
            <CommandEmpty>No results</CommandEmpty>
            {fetching && <CommandLoading />}
            <CommandList>
              <CommandGroup>
                {locations?.map((location) => (
                  <CommandItem
                    key={location.id}
                    value={location.id}
                    keywords={[location.name, location.description ?? ""]}
                    onSelect={() => {
                      handleChange({
                        id: location.id,
                        name: location.name,
                        description: location.description,
                      })
                      setSearch("")
                    }}
                    data-cy="location-picker-item"
                  >
                    <span className="truncate">{location.name}</span>
                    <span className="flex-1 truncate font-normal text-grey-500">
                      {location.description}
                    </span>
                    {value?.id === location.id && (
                      <Check className="text-primary-600" />
                    )}
                  </CommandItem>
                ))}
                {locations == null ||
                locations.length === 0 ||
                (search !== "" &&
                  !locations.some(
                    (l) => l.name.toLowerCase() === search.toLowerCase(),
                  )) ? (
                  <CommandItem
                    value="new-location"
                    onSelect={() => {
                      setOpen(false)
                      setDrawerOpen(true)
                      setInitialLocationName(search)
                      setSearch("")
                    }}
                    data-cy="location-picker-new-location-item"
                  >
                    <Plus className="text-grey-500" />
                    <div>
                      New location
                      {search !== "" && (
                        <>
                          :{" "}
                          <span className="font-normal text-grey-500">
                            &apos;{search}&apos;
                          </span>
                        </>
                      )}
                    </div>
                  </CommandItem>
                ) : null}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
      <Dialog open={drawerOpen} onOpenChange={setDrawerOpen}>
        <NewLocationSheetContent
          open={drawerOpen}
          onClose={() => {
            setDrawerOpen(false)
          }}
          initialValue={
            initialLocationName == null
              ? undefined
              : {name: initialLocationName}
          }
          onLocationCreation={(location) => {
            onChange(location)
            setDrawerOpen(false)
            refetch()
          }}
        />
      </Dialog>
    </>
  )
}

export const LocationMultiPicker = ({
  value,
  onChange,
  "data-cy": dataCy,
}: {
  value: Array<LocationOption> | undefined | null
  onChange: (val: Array<LocationOption> | undefined | null) => void
  "data-cy"?: string
}) => {
  const [open, setOpen] = useState(false)
  const {subdomain} = useRouteContext({from: "/_layout/sites/$siteSlug"})
  const {siteSlug} = useParams({from: "/_layout/sites/$siteSlug"})
  const [{data, fetching}] = useGetLocationsQuery({
    variables: {
      subdomain,
      siteSlug,
    },
  })

  const handleChange = (location: LocationOption) => {
    if (value?.some((u) => u.id === location.id) === true) {
      onChange(value.filter((u) => u.id !== location.id))
    } else {
      onChange([...(value ?? []), location])
    }
    setOpen(false)
  }

  const locations = data?.org?.site?.locationsConnection?.nodes

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger className="mx-auto w-fit" asChild>
        <Button
          className={twMerge(
            "min-h-[44px] w-full justify-between !px-2.5 !py-2 text-grey-500",
          )}
          testId={dataCy}
        >
          {value == null || value.length === 0 ? (
            <div className="flex flex-1 items-center gap-2 px-1">
              Pick a location
            </div>
          ) : (
            <div className="flex flex-wrap items-center gap-2">
              {value.map((location) => (
                <Tag key={location.id} spacing="icon">
                  {location.name}
                </Tag>
              ))}
            </div>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="z-[99999] w-full min-w-[var(--radix-popover-trigger-width)]">
        <Command
          filter={(_, search, keywords) => {
            const extendValue = keywords?.join(" ") ?? ""
            if (extendValue.toLowerCase().includes(search.toLowerCase())) {
              return 1
            }
            return 0
          }}
        >
          <CommandInput placeholder="Search..." />
          <CommandEmpty>No results</CommandEmpty>
          {fetching && <CommandLoading />}
          <CommandList>
            <CommandGroup>
              {locations?.map((location) => (
                <CommandItem
                  key={location.id}
                  value={location.id}
                  keywords={[location.name, location.description ?? ""]}
                  onSelect={() => {
                    handleChange({
                      id: location.id,
                      name: location.name,
                      description: location.description,
                    })
                  }}
                  data-cy="user-picker-item"
                >
                  <span className="truncate">{location.name}</span>
                  <span className="flex-1 truncate font-normal text-grey-500">
                    {location.description}
                  </span>
                  {value?.some((user) => user.id === location.id) === true && (
                    <Check className="text-primary-600" />
                  )}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
