import {A, F, pipe} from "@mobily/ts-belt"
import {type AutocompleteInputChangeReason} from "@mui/material/Autocomplete"
import {
  ScientificNameSortColumn,
  SortDirection,
  TaxonomicRank,
  useGetMultiRankScientificNamesQuery,
  type GetMultiRankScientificNamesQuery,
} from "generated/graphql"
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  type FocusEventHandler,
  type KeyboardEventHandler,
  type SyntheticEvent,
} from "react"
import {useFormContext} from "react-hook-form"
import {FormAutocomplete} from "src/components/autocomplete"
import {IconButton} from "@hortis/ui/button"
import {ChevronDown, HelpCircle, InfoCircle} from "@hortis/ui/icons"
import {NameValidationModal} from "src/features/taxonomy/components/taxon-page/taxonomy/name-validation-modal"
import {NameSourceButton} from "src/features/taxonomy/components/taxon-page/taxonomy/source-buttons"
import {
  taxonomicStatusDescriptionMap,
  taxonomicStatusTextColorMap,
} from "src/features/taxonomy/components/taxon-page/taxonomy/taxonomy"
import {useControlledDebounce} from "src/utils/hooks/debounce"
import {useToggle} from "src/utils/hooks/toggle"
import {taxonomicRankLabelMap} from "src/utils/label-maps/taxonomic-rank"
import {match} from "ts-pattern"
import {twMerge} from "tailwind-merge"
import {Tag, TagButton} from "@hortis/ui/tag"
import {Alert} from "src/components/untitled/alert"
import {
  genusRegex,
  infraspeciesRegex,
  prependScientificNamePart,
  processScientificName,
  processScientificNameGenusForSearch,
  processScientificNameInfraspeciesForSearch,
  processScientificNameSpeciesForSearch,
  removeScientificName,
  removeScientificNameSubstring,
  speciesRegex,
} from "./process-utils"
import {
  getOptionLabel,
  groupBy,
  isOptionEqual,
  renderAutoCompleteItem,
  renderAutocompleteGroup,
  type ScientificNameOption,
} from "./utils"

const createOptions = ({
  data,
  scientificName,
  selectedSharedScientificName,
}: {
  data: GetMultiRankScientificNamesQuery | undefined
  scientificName: string | null
  selectedSharedScientificName: ScientificNameOption | null
}): ReadonlyArray<ScientificNameOption> => {
  const genera = data?.genera?.nodes ?? [] // eslint-disable-line @typescript-eslint/no-unnecessary-condition -- types don't match up, could be nullable
  const species = data?.species?.nodes ?? [] // eslint-disable-line @typescript-eslint/no-unnecessary-condition -- types don't match up, could be nullable
  const infraspecies = data?.infraspecies?.nodes ?? [] // eslint-disable-line @typescript-eslint/no-unnecessary-condition -- types don't match up, could be nullable

  if (scientificName == null || scientificName === "") {
    return []
  }

  const processedScientificName = processScientificName(scientificName)
  const inputEndsWithSpace = scientificName.endsWith(" ")

  const showInfraspecies =
    infraspeciesRegex.test(processedScientificName) ||
    (speciesRegex.test(processedScientificName) && inputEndsWithSpace)
  const showSpecies =
    speciesRegex.test(processedScientificName) ||
    (genusRegex.test(processedScientificName) && inputEndsWithSpace)

  if (selectedSharedScientificName != null) {
    return match(selectedSharedScientificName.rank)
      .with(TaxonomicRank.Species, () => infraspecies)
      .with(TaxonomicRank.Genus, () =>
        showInfraspecies ? A.concat(infraspecies, species) : species,
      )
      .otherwise(() => [])
  }

  return pipe(
    [],
    F.ifElse(() => showInfraspecies, A.concat(infraspecies), F.identity),
    F.ifElse(() => showSpecies, A.concat(species), F.identity),
    A.concat(genera),
  )
}

interface TaxonScientificNameAutocompleteProps {
  name: string
  testId?: string
  required?: boolean
  focusOnMount?: boolean
}

export const TaxonScientificNameAutocomplete = ({
  name,
  testId,
  required,
  focusOnMount,
}: TaxonScientificNameAutocompleteProps) => {
  const [blurredOnce, setBlurredOnce] = useState(false)
  const [open, setOpen] = useState(false)

  const {setValue, watch} = useFormContext<{
    sharedScientificName: ScientificNameOption | null
    scientificName: string | null
  }>()
  const selectedSharedScientificName = watch("sharedScientificName")
  const scientificName = watch("scientificName")
  const [helpModalOpen, toggleHelpModalOpen] = useToggle()
  const [inputValue, setInputValue] = useState(
    selectedSharedScientificName == null
      ? scientificName ?? ""
      : removeScientificNameSubstring(
          scientificName ?? "",
          selectedSharedScientificName.scientificName,
        ),
  )
  const generaSearchTerm = useMemo(
    () =>
      scientificName == null
        ? null
        : processScientificNameGenusForSearch(scientificName),
    [scientificName],
  )
  const speciesSearchTerm = useMemo(
    () =>
      scientificName == null
        ? null
        : processScientificNameSpeciesForSearch(scientificName),
    [scientificName],
  )
  const infraspeciesSearchTerm = useMemo(
    () =>
      scientificName == null
        ? null
        : processScientificNameInfraspeciesForSearch(scientificName),
    [scientificName],
  )
  const [{data}] = useGetMultiRankScientificNamesQuery({
    variables: {
      last: 50,
      generaSearchTerm,
      speciesSearchTerm,
      infraspeciesSearchTerm,
      orderBy: {
        direction: SortDirection.Asc,
        field: ScientificNameSortColumn.Status,
      },
    },
    pause: scientificName == null || scientificName === "",
  })

  const changeNameField = useControlledDebounce(
    (newValue: string) => {
      setValue("scientificName", newValue)
    },
    {delay: 100},
  )

  useEffect(() => {
    changeNameField.schedule(
      prependScientificNamePart(
        selectedSharedScientificName?.scientificName,
        inputValue,
      ),
    )
  }, [inputValue, changeNameField, selectedSharedScientificName])

  const onInputChange = useCallback(
    (
      _: SyntheticEvent,
      newValue: string,
      reason: AutocompleteInputChangeReason,
    ) => {
      if (reason === "reset") {
        setInputValue("")
      } else {
        setInputValue(newValue)
      }
    },
    [],
  )

  const onChange = useCallback(
    (_: SyntheticEvent, val: ScientificNameOption | string | null) => {
      if (
        val != null &&
        typeof val !== "string" &&
        !val.id.includes("ADD_NEW")
      ) {
        setValue("sharedScientificName", val)
        setValue(
          "scientificName",
          removeScientificName(inputValue, val.scientificName),
        )
        setInputValue(removeScientificName(inputValue, val.scientificName))
      } else if (
        typeof val !== "string" &&
        val?.id.includes("ADD_NEW") === true
      ) {
        setValue("scientificName", inputValue)
        setInputValue(inputValue)
        setValue("sharedScientificName", null)
        setOpen(false)
        setBlurredOnce(true)
      }
    },
    [setValue, inputValue],
  )

  const options = useMemo(
    () =>
      createOptions({
        data,
        scientificName,
        selectedSharedScientificName,
      }),
    [data, scientificName, selectedSharedScientificName],
  )

  const handleOpen = useCallback(() => {
    setOpen(true)
  }, [])
  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const onClearSelected = useCallback(() => {
    const newInputValue = prependScientificNamePart(
      selectedSharedScientificName?.scientificName,
      inputValue,
    )

    setInputValue(newInputValue)
    setValue("sharedScientificName", null)
    setOpen(true)
  }, [selectedSharedScientificName?.scientificName, inputValue, setValue])

  const onKeyDown = useCallback<KeyboardEventHandler<HTMLDivElement>>(
    (e) => {
      if (
        e.key === "Backspace" &&
        selectedSharedScientificName != null &&
        inputValue === ""
      ) {
        onClearSelected()
        e.preventDefault()
      }
    },
    [onClearSelected, inputValue, selectedSharedScientificName],
  )

  const renderOption = useMemo(
    () =>
      renderAutoCompleteItem({
        showAuthorship: true,
        showStatus: true,
        selectedOption: selectedSharedScientificName,
      }),
    [selectedSharedScientificName],
  )

  const onBlur = useCallback<FocusEventHandler<HTMLDivElement>>(() => {
    // Set form state immediately, rather than debounced on blur
    setValue(
      "scientificName",
      prependScientificNamePart(
        selectedSharedScientificName?.scientificName,
        inputValue,
      ),
    )
    setBlurredOnce(true)
  }, [inputValue, selectedSharedScientificName, setValue])

  const inputRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if (focusOnMount === true) {
      inputRef.current?.focus()
    }
  }, [focusOnMount])

  return (
    <div className="flex flex-col gap-2">
      <FormAutocomplete
        renderOption={renderOption}
        onChange={onChange}
        freeSolo
        inputRef={inputRef}
        openOnFocus
        required={required}
        value={null}
        open={options.length > 0 && open}
        disableFormOnChange
        autoHighlight
        onOpen={handleOpen}
        onClose={handleClose}
        options={options}
        label={"Scientific name"}
        groupBy={groupBy}
        onBlur={onBlur}
        filterOptions={F.identity}
        handleHomeEndKeys
        renderGroup={renderAutocompleteGroup}
        inputValue={inputValue}
        onInputChange={onInputChange}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={isOptionEqual}
        onKeyDown={onKeyDown}
        startAdornment={
          selectedSharedScientificName == null ? null : (
            <Tag data-cy="scientific-name-tag" className="flex-shrink truncate">
              {selectedSharedScientificName.scientificName}
              <TagButton
                onClick={onClearSelected}
                data-cy="scientific-name-tag-delete"
              />
            </Tag>
          )
        }
        endAdornment={
          <>
            <NameValidationModal
              open={helpModalOpen}
              onClose={toggleHelpModalOpen}
            />
            <IconButton
              variant="tertiaryGray"
              size="xs"
              ariaLabel="Open"
              icon={<ChevronDown size="18px" />}
              onClick={open ? handleClose : handleOpen}
            />
            <IconButton
              variant="tertiaryGray"
              size="xs"
              ariaLabel="Help"
              onClick={toggleHelpModalOpen}
              icon={<HelpCircle size="18px" />}
            />
          </>
        }
        fullWidth
        testId={testId}
        name={name}
        className=""
        componentsProps={{
          popper: {className: "[&_.MuiAutocomplete-listbox]:!p-0"},
        }}
        sx={{
          flexWrap: "none",
          "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
            paddingRight: "6px !important",
          },
        }}
      />
      {selectedSharedScientificName == null ? (
        blurredOnce ? (
          <Alert
            title="Validate scientific name"
            body="This will add additional taxonomic data to your new taxon record."
            testId="name-unvalidated-warning"
            color="warning"
            icon={InfoCircle}
          />
        ) : null
      ) : (
        <div className="flex items-center justify-between gap-3">
          <div
            className={twMerge(
              "truncate text-sm font-medium",
              taxonomicStatusTextColorMap[selectedSharedScientificName.status],
            )}
          >
            {taxonomicRankLabelMap[selectedSharedScientificName.rank]}
            {taxonomicStatusDescriptionMap[selectedSharedScientificName.status]}
          </div>
          <NameSourceButton
            nameSource={selectedSharedScientificName.nameSource}
            status={selectedSharedScientificName.status}
          />
        </div>
      )}
    </div>
  )
}
