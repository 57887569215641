import type {PropsWithChildren} from "react"
import {IconButton} from "@hortis/ui/button"
import {Divider} from "src/components/custom/divider"
import {Minus, Plus} from "@hortis/ui/icons"

export interface FieldsHeaderProps {
  header: string
  subheader?: string
  expanded?: boolean
  onExpandToggle?: () => void
  buttonTestId?: string
  testId?: string
}

export const FieldsHeader = ({
  header,
  subheader,
  expanded,
  onExpandToggle,
  children,
  buttonTestId,
  testId,
}: PropsWithChildren<FieldsHeaderProps>) => {
  const showExpanded = expanded != null
  return (
    <div className="flex flex-col" data-cy={testId}>
      <div className="flex justify-between gap-4">
        <div className="flex flex-col gap-1 py-3">
          <h2 className="text-base font-medium text-grey-900">{header}</h2>
          {subheader != null && (
            <p className="pb-1 text-sm text-grey-500">{subheader}</p>
          )}
        </div>
        {showExpanded && (
          <IconButton
            ariaLabel={expanded ? "remove" : "add"}
            variant="tertiaryGray"
            size="sm"
            onClick={onExpandToggle}
            data-cy={buttonTestId}
            icon={expanded ? <Minus /> : <Plus />}
          />
        )}
      </div>
      {(!showExpanded || expanded) && (
        <>
          <Divider />
          {children}
        </>
      )}
    </div>
  )
}
