import {Layer, Source} from "react-map-gl"

interface BingLayerProps {
  show: boolean
}

export const BingLayer = ({show}: BingLayerProps) => (
  <>
    <Source
      type="geojson"
      data={{
        type: "FeatureCollection",
        features: [],
      }}
      id="yep"
    >
      <Layer id="base-layer" type="symbol" />
    </Source>
    <Source
      id="raster-tiles"
      type="raster"
      tiles={[
        "https://ecn.t0.tiles.virtualearth.net/tiles/a{quadkey}.jpeg?g=587&mkt=en-gb&n=z",
        "https://ecn.t1.tiles.virtualearth.net/tiles/a{quadkey}.jpeg?g=587&mkt=en-gb&n=z",
        "https://ecn.t2.tiles.virtualearth.net/tiles/a{quadkey}.jpeg?g=587&mkt=en-gb&n=z",
        "https://ecn.t3.tiles.virtualearth.net/tiles/a{quadkey}.jpeg?g=587&mkt=en-gb&n=z",
      ]}
      maxzoom={20}
    >
      {show && (
        <Layer
          beforeId="base-layer"
          id="simple-raster"
          type="raster"
          source="raster-tiles"
        />
      )}
    </Source>
  </>
)
