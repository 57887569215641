import type {SwitchUnstyledProps} from "@mui/base/SwitchUnstyled"
import {useCallback} from "react"
import * as uuid from "uuid"
import {Toggle} from "./toggle"

export type ToggleProps = Omit<SwitchUnstyledProps, "checked" | "onChange"> & {
  checked: boolean
  onChange: (checked: boolean) => void
  testId?: string
}

export const ToggleChip = ({
  children,
  checked,
  onChange,
  testId,
  ...props
}: ToggleProps) => {
  const id = uuid.v4()
  const handleChange = useCallback(() => {
    onChange(!checked)
  }, [onChange, checked])

  return (
    <div
      onClick={handleChange}
      aria-hidden="true"
      className="inline-flex cursor-pointer items-center gap-3 rounded-lg border border-grey-300 py-2 pl-4 pr-3"
      data-cy={testId}
    >
      <label htmlFor={id} className="cursor-pointer text-sm">
        {children}
        <span
          className="hidden"
          data-cy={testId == null ? undefined : `${testId}-state-label`}
        >
          {checked ? " enabled" : " disabled"}
        </span>
      </label>
      <Toggle {...props} id={id} onChange={handleChange} checked={checked} />
    </div>
  )
}
