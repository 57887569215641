import type {ReactElement} from "react"
import {AccessionScientificNameAutocomplete} from "src/components/autocomplete/scientific-names/accession-scientific-name-autocomplete-old"
import {TagsAutocompleteAlt} from "src/features/tags/tags-autocomplete-alt/tags-autocomplete-alt"
import {useCollectionSiteSettings} from "src/utils/hooks/collection-site-from-url"
import {BasicDatePicker} from "../../../../components/date-picker/date-picker"
import {FormTextField as TextField} from "../../../../components/text-field/text-field"
import {LabeledSwitch} from "../labeled-switch"
import {OptionalNotes} from "../optional-notes"
import {useOptionalNotes} from "../optional-notes/use-optional-notes"
import type {Fields} from "./accession-data-form"
import {AccessionNumberField} from "./accession-number-field"
import {useGeneratedAccessionNumber} from "./accession-number-field/use-generated-accession-number"
import {OrgDonorFieldAutocomplete} from "./donor-field-autocomplete/donor-field-autocomplete"
import {IdentificationFields} from "./identification-fields/identification-fields"
import {MaterialTypesSelect} from "./material-types-select"
import {useMaterialTypes} from "./material-types-select/use-material-types"
import {ProvenanceCategories} from "./provenance-categories"

interface AccessionDataFieldsProps {
  responsiveChips?: boolean
  accessionNumber?: string
}

export const AccessionDataFields = ({
  responsiveChips,
  accessionNumber,
}: AccessionDataFieldsProps): ReactElement => {
  const materialTypes = useMaterialTypes()
  const [notesFieldVisible, handleShowNotes] = useOptionalNotes<Fields>("notes")
  const {isGenerated, onToggleChange, onDateChange} =
    useGeneratedAccessionNumber()
  const {ipenNumbersEnabled} = useCollectionSiteSettings()

  return (
    <>
      <div className="flex flex-col gap-8">
        <BasicDatePicker
          onDateChange={onDateChange}
          label="Accession date"
          testId="accession-date"
          name="accessionDate"
        />
        {accessionNumber == null ? (
          <AccessionNumberField
            isGenerated={isGenerated}
            onToggleChange={onToggleChange}
          />
        ) : (
          <LabeledSwitch
            primaryLabel="Accession number"
            secondaryLabel={accessionNumber}
          />
        )}
        <AccessionScientificNameAutocomplete
          name="taxonOption"
          id="scientific-name"
          testId="scientific-name"
          required
        />
        <IdentificationFields />
        <ProvenanceCategories responsiveChips={responsiveChips} />
        <MaterialTypesSelect materialTypes={materialTypes} />
        <OrgDonorFieldAutocomplete
          name="donor"
          id="donor"
          label="Donor / supplier"
          testId="donor-supplier"
        />
        {ipenNumbersEnabled && (
          <TextField
            fullWidth
            name="ipenNumber"
            id="ipen-number"
            label="IPEN number"
            testId="ipen-number"
          />
        )}
        <TagsAutocompleteAlt name="tags" testId="accession-tags" />
        <OptionalNotes
          notesAdded={notesFieldVisible}
          onAddNotes={handleShowNotes}
          testId="accession-notes"
          name="notes"
        />
      </div>
    </>
  )
}
