import {D, pipe} from "@mobily/ts-belt"
import {not} from "fp-ts/Predicate"
import type * as NEA from "fp-ts/NonEmptyArray"
import * as Record from "fp-ts/Record"

type HasNull<T> = null extends Exclude<T, NonNullable<T>> ? true : false
type HasUndef<T> = undefined extends Exclude<T, NonNullable<T>> ? true : false

type IsPossiblyEmpty<Value> = HasNull<Value> extends true
  ? true
  : HasUndef<Value> extends true
  ? true
  : // Any string will do, should already be validated by zod anyway
  Value extends string
  ? false
  : // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Value extends Array<any>
  ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Value extends NEA.NonEmptyArray<any>
    ? false
    : true
  : false

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyRecord = Record<string | number | symbol, any>

type PartialPick<Struct extends AnyRecord, Keys extends keyof Struct> = Omit<
  Struct,
  Keys
> &
  Partial<Pick<Struct, Keys>>

type FilterEmpties<T extends AnyRecord> = PartialPick<
  T,
  keyof {
    [Key in keyof T as IsPossiblyEmpty<T[Key]> extends true
      ? Key
      : never]: T[Key]
  }
>

export const isEmpty = (value: unknown): boolean =>
  value === undefined || value === ""

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const removeEmptyValues: <T extends AnyRecord>(
  data: T,
) => FilterEmpties<T> =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Record.filter(not(isEmpty)) as any

export const convertEmptyStringsToNulls = <T extends AnyRecord>(data: T) =>
  pipe(
    data,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    D.map((value) => (value === "" ? null : value)),
  ) as T & {[Key in keyof T]: T[Key] extends "" ? null : T[Key]}
